import { useState, useEffect } from 'react';
import CreateLogin from '../../components/login-component/create-login';
import PasswordChanged from '../../components/login-component/password-changed';
import PasswordExpireOtp from '../../components/login-component/password-expire-otp';
import PasswordExpire from '../../components/login-component/password-expire';
import LoginOtpVerification from '../../components/login-component/set-password-otp-verification';
import Login from '../../components/login-component/login';
import OtpVerification from '../../components/login-component/otp-verification';
import CreateNewPassword from '../../components/login-component/create-new-password';
import '../../styles/login/user-login-main.scss';


export default function UsersLoginMain() {
    const [loginStage, setLoginStage] = useState<string>("login");
    const [userMail, setUserMail] = useState<string>("");
    const [passwordMail, setPasswordMail] = useState<string>("");
    const [userPassword, setUserPassword] = useState<string>("");
    const [updatedPassword, setUpdatedPassword] = useState<string>("")
    const [isForgetPassword, setIsForgetPassword] = useState<boolean>(false);
    const [isPasswordSetup, setIsPasswordSetup] = useState<boolean>(false);
    const [otpAttemptsRemaining, setOtpAttemptsRemaining] = useState(3);
    const [loginOtpAttemptsRemaining, setLoginOtpAttemptsRemaining] = useState(3);
    const [resetOtpAttemptsRemaining, setResetOtpAttemptsRemaining] = useState(3);



    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const userName = params.get('token');

        if (userName) {
            setLoginStage("createLogin");
        }
    }, []);

    // to block back button
    window.history.pushState(null, '', window.location.href);
    // Listen for the popstate event
    window.onpopstate = function (event: PopStateEvent) {
        window.history.go(1);
    };

    return (
        <>
            <div className='userlogin-main'>
                <div className='login-logo'>
                    <div className='fingertip-logo'></div>
                </div>
                <div className='login-components'>
                    {
                        loginStage === "createLogin" &&
                        <CreateLogin handleclick={(val) => { setLoginStage(val) }}
                            UserMailFunction={(val) => { setUserMail(val) }}
                            UserPasswordFunction={(val) => { setUserPassword(val) }}
                            setOtpAttemptsRemaining={(val) => { setOtpAttemptsRemaining(val) }}
                        />
                    }
                    {
                        loginStage === "LoginOtpVerification" &&
                        <LoginOtpVerification
                            handleclick={(val) => { setLoginStage(val) }}
                            userMail={userMail}
                            userPassword={userPassword}
                            otpAttemptsRemaining={otpAttemptsRemaining}
                            setIsPasswordSetup={setIsPasswordSetup}
                        />
                    }
                    {
                        loginStage === "passwordChanged" &&
                        <PasswordChanged handleclick={(val) => { setLoginStage(val) }}
                            isPasswordSetup={isPasswordSetup}
                        />
                    }
                    {
                        loginStage === "login" &&
                        <Login
                            setIsForgetPassword={setIsForgetPassword}
                            userMail={userMail}
                            setIsPasswordSetup={setIsPasswordSetup}
                            currentLoginstage={loginStage}
                            PasswordFunction={(val) => { setPasswordMail(val) }}
                            handleclick={(val) => { setLoginStage(val) }}
                            setLoginOtpAttemptsRemaining={(val) => {

                                setLoginOtpAttemptsRemaining(val)
                            }}



                        />
                    }
                    {
                        loginStage === "otpVerification" &&
                        <OtpVerification
                            loginOtpAttemptsRemaining={loginOtpAttemptsRemaining}
                            isForgetPassword={isForgetPassword}
                            loginStage={loginStage}
                            passwordMail={passwordMail}
                            handleclick={(val) => { setLoginStage(val) }}

                        />
                    }
                    {
                        loginStage === "createNewPassword" &&
                        <CreateNewPassword
                            passwordMail={passwordMail}
                            handleclick={(val) => {
                                setLoginStage(val)
                                setIsForgetPassword(false)
                            }}
                        />
                    }
                    {
                        loginStage === "passwordExpire" &&
                        <PasswordExpire
                            passwordMail={passwordMail}
                            setIsForgetPassword={setIsForgetPassword}
                            setUpdatedPassword={(val) => { setUpdatedPassword(val) }}
                            SetPasswordFunction={(val) => { setPasswordMail(val) }}
                            setResetOtpAttemptsRemaining={(val) => {

                                setResetOtpAttemptsRemaining(val)
                            }}
                            handleclick={(val) => {
                                setLoginStage(val)
                                setIsForgetPassword(false)
                            }}
                        />
                    }
                    {
                        loginStage === "passwordExpireOtp" &&
                        <PasswordExpireOtp
                            resetOtpAttemptsRemaining={resetOtpAttemptsRemaining}
                            isForgetPassword={isForgetPassword}
                            updatedPassword={updatedPassword}
                            loginStage={loginStage}
                            passwordMail={passwordMail}
                            handleclick={(val) => { setLoginStage(val) }}
                        />
                    }
                </div>
            </div>
            <div className='footer-transcendz'>{"Powered by Transcendz Paysol Pvt Limited"}</div>
        </>
    )
}