import { useState, useEffect } from 'react'
import MainPageCard from '../../common-component/cards/main-page-card'
import NavigateHeader from '../../common-component/navigate-header'
import RadioOrCheckbox from '../../common-component/form-elements/radio-or-checkbox'
import DropdownComponent from '../../common-component/form-elements/dropdown-component'
import InputComponent from '../../common-component/form-elements/input-component'
import ButtonComponent from '../../common-component/form-elements/button-component'
import useAxiosPrivate from "../../../services/hooks/useaxios-private";
import masterCardImg from "../../../assets/images/mastercard-img.png";
import visaCardImg from "../../../assets/images/visa-img.png";
import rupayCardImg from "../../../assets/images/rupay-img.png";
import amexCardImg from "../../../assets/images/amex-img.png";
import rupeeImg from "../../../assets/images/ruppees-img.png";
import { v4 as uuidv4 } from 'uuid';
import AlertBox from '../../common-component/alert-box'
import Changesmodal from '../../common-component/modals/changes-modal'
import TextArea from '../../common-component/form-elements/text-area'
import "../../../styles/approval/card-approval/form.scss"
import Loading from '../../common-component/modals/loading-screen'
import { useDispatch } from "react-redux";
import { resetFormModified } from "../../../redux/action";
import CardFormTableGrid from '../../../config/organisation/card/card-form-grid'
import { ReactComponent as CardOverview } from '../../../assets/icons/card-overview.svg'
import { ReactComponent as ControlCentre } from '../../../assets/icons/contol-centre.svg'
import { ReactComponent as Approval } from '../../../assets/icons/approval.svg'
import { ReactComponent as Correct } from '../../../assets/icons/correct.svg'
import { ReactComponent as InProgerss } from '../../../assets/icons/in-progerss.svg'
import { ReactComponent as NotWork } from '../../../assets/icons/not-work.svg'
import useAuth from "../../../services/hooks/useauth";
import { BsPlusLg } from 'react-icons/bs'
import AddCardCategory from '../../common-component/modals/card-category-add-modal'
import AddExpense from '../../common-component/modals/expense-add-model'
import AddExpenseValue from '../../common-component/modals/expense-add-model'
import OtpVerifyModal from '../../common-component/modals/otp-verify-modal'
import "../../../styles/approval/card-approval/form.scss"
import { IoIosEye, IoIosEyeOff } from 'react-icons/io'



interface FormProps {
    userNavigateFunction: (val: string) => void;
    userNavigate: string;
    isEditMode: boolean;
    modeFunction: (val: boolean) => void;
    getEditMode: (value: boolean) => void;
    editData: any
}

export interface CardDataType {
    cardType: string,
    nameOnCard: string;
    cardNumber: string;
    cardCategory: string;
    issuer: string;
    issuerName: string;
    validFrom: string;
    validThru: string;
    creditPeriod: string;
    billingCycle: string;
    cardMaxLimit: string;
    cardMaxLimitInr: string;
    isActive: string;
    remarks: string;
    customerLimits: string;
    dailyVolumeMaximumAmount: string;
    singleTransactionMaximumAmount: string;
    // transactionMappingDepartment: string;
    // transactionMappingMaxLimit: string;
    // transactionMappingPayments: string;
    // transactionMappingExpense: string;
    approvedBy: string;
    status: string;
    declineRemarks: string;
    organisationId: string;
    companyBankId: string


    [key: string]: string;
}

export interface bankValidationRulesInterface {
    [key: string]: {
        [key: string]: {
            regex: RegExp | string;
            field: string;
            shouldNotBe: string;
        };
    }
}



const Form: React.FC<FormProps> = ({ userNavigateFunction, getEditMode, isEditMode, editData, modeFunction }) => {
    const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
    const [componentNavigator, setComponentNavigator] = useState("form-page");
    const [priorityValues, setPriorityValues] = useState<any[]>([]);
    const [showType, setShowType] = useState("warning") // error message showType
    const [showCardNumberMessage, setShowCardNumberMessage] = useState("warning") // error message showType
    const [showMessage, setShowMessage] = useState<string>("") // error message showMessage
    const [showAlertBox, setShowAlertBox] = useState(false) // error message ShowAlertBox
    const [bankValues, setBankValues] = useState<any[]>([]);
    const [payments, setPayments] = useState<any[]>([]);
    const [departmentValues, setDepartmentValues] = useState<any[]>([]);
    const [cardCategory, setCardCategory] = useState<any[]>([]);
    const [expenseValue, setExpenseValue] = useState<any[]>([]);
    const [cardType, setCardType] = useState<any[]>([]);
    const [processName, setProcessName] = useState<string>("Yes");
    const [isHidden, setIsHidden] = useState<boolean>(false);
    const [showMaskedCardNumber, setShowMaskedCardNumber] = useState<string>("");




    // const [department, setDepartment] = useState<any[]>([]);
    const [isFormDirty, setIsFormDirty] = useState(false);
    const [otpVerification, setOtpVerification] = useState<boolean>(false);
    const [isDataRetrieved, setIsDataRetrieved] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isLocked, setIsLocked] = useState<boolean>(false);

    const [addCardCategory, setAddCardCategory] = useState<boolean>(false)
    const [addExpense, setAddExpense] = useState<boolean>(false)
    const [userDataErrors, setUserDataErrors] = useState<Record<string, boolean>>({});
    const [showChangesModal, setShowChangesModal] = useState(false);
    const [cardImg, setCardImg] = useState('');
    const [currentFormDataEdit, setCurrentFormDataEdit] = useState(false);
    const [currentDirector, setCurrentDirector] = useState<number>(0);
    const [isCardNumberDisabled, setIsCardNumberDisabled] = useState<boolean>(true);



    const [organisationCardData, setOrganisationCardData] = useState<CardDataType>({
        cardType: "Commercial Card",
        nameOnCard: "",
        cardNumber: "",
        cardCategory: "",
        cardCategoryId: "",
        issuer: "",
        issuerName: "",
        validFrom: "",
        validThru: "",
        cvv: "",
        creditPeriod: "",
        billingCycle: "",
        cardMaxLimit: "",
        cardMaxLimitInr: "INR",
        isActive: "Yes",
        remarks: "",
        customerLimits: "No",
        dailyVolumeMaximumAmount: "",
        dailyMaximumAmountInr: "INR",
        singleTransactionMaximumAmount: "",
        singleMaximumAmountInr: "INR",
        // transactionMappingDepartment: "",
        // transactionMappingMaxLimit: "",
        // transactionMappingPayments: "",
        // transactionMappingExpense: "",
        status: "",
        declineRemarks: "",
        maxLimitInr: "INR",
        approvedBy: "",
        organisationId: "",
        companyBankId: "",
    });
    const [transactionMappingData, setTransactionMappingData] = useState<any>({
        transactionMappingDepartment: "",
        transactionMappingDepartmentName: "",
        transactionMappingMaxLimit: "",
        transactionMappingPayments: "",
        transactionMappingExpense: "",
        expenseId: "",

    })
    const [transactionMapping, setTransactionMapping] = useState<any[]>([

    ])

    const [iconStatus, setIconStatus] = useState({
        organisationDetails: "inprogress",
        adminDetails: "notwork",
        approval: "notwork"

    })

    const { auth } = useAuth();
    const dispatch = useDispatch();
    const axiosPrivate = useAxiosPrivate();


    const bankValidationRules: bankValidationRulesInterface = {
        Card_details: {
            nameOnCard: {
                regex: /^[a-zA-Z][A-Za-z ]+$/,
                field: "mandatory",
                shouldNotBe: ""
            },
            cardNumber: {
                regex: !isEditMode ? (organisationCardData.cardNumber && organisationCardData.cardNumber.substring(0, 1)) == "3" ? /^[3-6]\d{3} \d{6} \d{5}$/ : /^[3-6]\d{3} \d{4} \d{4} \d{4}$/ : "",
                field: "mandatory",
                shouldNotBe: ""
            },
            cardCategory: {
                regex: "",
                field: "mandatory",
                shouldNotBe: ""
            },
            issuer: {
                regex: "",
                field: "mandatory",
                shouldNotBe: ""
            },
            validThru: {
                regex: /^(0[1-9]|1[0-2])\/([0-9]{2})$/,
                field: "mandatory",
                shouldNotBe: ""
            },
            creditPeriod: {
                regex: /^[0-9]+$/,
                field: "mandatory",
                shouldNotBe: ""
            },
            // cvv: {
            //     regex: /^[0-9]+$/,
            //     field: "mandatory",
            //     shouldNotBe: ""
            // },
            billingCycle: {
                regex: /^(2[01]|[1][0-9]|[2][0-8]|0?[1-9])$/,
                field: "mandatory",
                shouldNotBe: ""
            },

            isActive: {
                regex: "",
                field: "mandatory",
                shouldNotBe: ""

            },

            remarks: {
                regex: (organisationCardData.isActive === "No" && isEditMode) ? /^[^\s].*/ : "",
                field: (organisationCardData.isActive === "No" && isEditMode) ? "mandatory" : "",
                shouldNotBe: ""
            },
            declineRemarks: {
                regex: (organisationCardData.status === "Declined" && isEditMode) ? /^[^\s].*/ : "",
                field: (organisationCardData.status === "Declined" && isEditMode) ? "mandatory" : "",
                shouldNotBe: ""
            },

        }
    }

    useEffect(() => {
        // fetchPriorityValues();
        fetchBankValues();
        paymentsValue();
        fetchDepartmentValues();
        fetchData(`/companyadmin/organisation/card-category`, setCardCategory, "CardCategoryDetails");
        fetchExpenseData(`/companyadmin/organisation/card-expense`, setExpenseValue, "CardExpenseDetails")
    }, []);


    useEffect(() => {
        if (!isEditMode) return;

        if (editData.status === "Approved" || editData.status === "Declined") {
            setIconStatus({
                ...iconStatus,
                organisationDetails: "correct",
                adminDetails: "correct",
                approval: "correct"
            })
        } else {
            setIconStatus({
                ...iconStatus,
                organisationDetails: "correct",
                adminDetails: "correct",
                approval: "inprogress"
            })
        }
        setOrganisationCardData({
            cardType: editData.cardType,
            nameOnCard: editData.nameOnCard,
            cardNumber: editData.cardNumber,
            cardCategory: editData.cardCategory,
            cardCategoryId: editData.cardCategoryId,
            cvv: editData.cvv,
            issuer: editData.issuer,
            issuerName: editData.issuerName,
            validFrom: editData.validFrom,
            validThru: editData.validThru,
            creditPeriod: editData.creditPeriod,
            billingCycle: editData.billingCycle,
            cardMaxLimit: editData.cardMaxLimit,
            cardMaxLimitInr: editData.cardMaxLimitInr,
            transactionMappingDepartmentName: editData.transactionMappingDepartmentName,
            isActive: editData.isActive,
            remarks: editData.remarks || "",
            customerLimits: editData.customerLimits,
            dailyVolumeMaximumAmount: editData.dailyVolumeMaximumAmount,
            dailyMaximumAmountInr: editData.dailyMaximumAmountInr,
            singleTransactionMaximumAmount: editData.singleTransactionMaximumAmount,
            singleMaximumAmountInr: editData.singleMaximumAmountInr,
            // transactionMappingDepartment: editData.transactionMappingDepartment,
            // transactionMappingMaxLimit: editData.transactionMappingMaxLimit,
            // transactionMappingPayments: editData.transactionMappingPayments,
            // transactionMappingExpense: editData.transactionMappingExpense,
            approvedBy: editData.approvedBy,
            status: editData.status,
            declineRemarks: editData.declineRemarks,
            organisationId: editData.organisationId,
            companyBankId: editData.companyBankId,
            expenseId: editData.expenseId,
            maxLimitInr: editData.maxLimitInr,
        });
        getExistingTransation(editData.cardId);
        setShowMaskedCardNumber(editData.cardNumber)



    }, [isEditMode]);

    const getExistingTransation = async (cardId: any) => {
        try {
            const response = await axiosPrivate.get(`companyadmin/organisation/card-transaction-mapping/${cardId}`);
            if (response.status === 200) {
                setTransactionMapping(response.data.CardTransactionMappingDetails)
            }
        } catch (error) {
        }
    }



    // dropdown value for priority
    // const fetchPriorityValues = async () => {
    //     try {
    //         const response = await axiosPrivate.get("priority");
    //         if (response.status === 200) {
    //             const dropdownValues = response.data.priority.map((item: any) => ({
    //                 label: item.priorityName,
    //                 value: item.priorityName,
    //                 id: item.priorityId,
    //             }));

    //             const selectOption = {
    //                 label: "Select",
    //                 value: "",
    //                 id: "",
    //             };

    //             const bankValuesWithSelectOption = [selectOption, ...dropdownValues];

    //             setPriorityValues(bankValuesWithSelectOption);
    //         }
    //     } catch (error) {
    //     }
    // };

    // dropdown value for bank
    const fetchBankValues = async () => {
        try {
            const response = await axiosPrivate.get("bank");
            if (response.status === 200) {
                const dropdownValues = response.data.bank.map((item: any) => ({
                    label: item.bankName,
                    value: item.bankName,
                    id: item.bankId,
                }));
                setBankValues(dropdownValues);
            }
        } catch (error) {
        }
    };

    const fetchDepartmentValues = async () => {
        try {
            const response = await axiosPrivate.get("companyadmin/organisation/card-master/department");
            if (response.status === 200) {
                const dropdownValues = response.data.departmentDetails.map((item: any) => ({
                    label: item.departmentName,
                    value: item.departmentName,
                    id: item.departmentId,
                }));
                setDepartmentValues(dropdownValues);
            }
        } catch (error) {
        }
    };

    const paymentsValue = () => {
        const defaultPaymentValue = [
            {
                label: "Select",
                value: "",
            },
            {
                label: "Vendor",
                value: "Vendor",
            },
            {
                label: "GST",
                value: "GST",
            },
            {
                label: "Direct Tax",
                value: "Direct Tax",
            },
            {
                label: "Utility",
                value: "Utility",
            },
        ];
        const dropdownValues = [...defaultPaymentValue];
        setPayments(dropdownValues)



    }

    // Regux validation
    const updatedIsValueValidAll: Record<string, boolean> = {};
    const validateForm = (): boolean => {
        const updatedFieldValidity: Record<string, boolean> = {};
        Object.keys(bankValidationRules.Card_details).forEach((field) => {
            const rule = bankValidationRules.Card_details[field];
            const value = organisationCardData[field];
            // Check if the field value meets the conditions based on rule.field and rule.shouldNotBe
            const isValueValid = (rule.field === "mandatory") ? (value !== rule.shouldNotBe) : true;
            // If a regular expression is provided, test the field value against it
            const isRegexValid = rule.regex !== "" ? new RegExp(rule.regex).test(value) : true;
            updatedFieldValidity[field] = !isValueValid || !isRegexValid;
            updatedIsValueValidAll[field] = !isValueValid
        });

        setUserDataErrors(updatedFieldValidity);
        const isFormValid = Object.keys(updatedFieldValidity).every((keys) => {
            return !updatedFieldValidity[keys]
        }
        );

        return isFormValid;
    };

    // Timeout function for messages
    const clearAleart = (status: any) => {
        const timer = setTimeout(() => {
            setShowAlertBox(false);
            setShowMessage("");
            if (status === 200) {
                userNavigateFunction("grid")
            }
            clearTimeout(timer);
        }, 5000);
    }


    async function goNext() {
        const isValid = validateForm();
        if (componentNavigator === "form-page") {
            if (isValid) {
                try {
                    await axiosPrivate.post('/companyadmin/organisation/card-master/check-cardNumber', { "cardNumber": organisationCardData.cardNumber, "cardId": isEditMode ? editData.cardId : "" })
                    setComponentNavigator("control-page");
                    setIconStatus({
                        ...iconStatus,
                        organisationDetails: "correct",
                    })
                }
                catch (error: any) {
                    setShowAlertBox(true)
                    setShowType("warning")
                    setShowMessage(error.response.data.error)
                    clearAleart("")
                }

            } else {
                setShowAlertBox(true)
                setShowType("danger")
                setShowMessage("Mandatory field(s) should not be left blank.")
                clearAleart("")
            }
        } else if (componentNavigator === "control-page") {
            setComponentNavigator("approval-page");

        }


        else {
            setShowAlertBox(true)
            setShowType("danger")
            setShowMessage(Object.values(updatedIsValueValidAll).every((values) => !values) ? "Some of the field(s) are not in required format." : "Mandatory field(s) should not be left blank.")
            clearAleart("")

        }
    }

    async function organisationBankDataSave() {
        const isValid = validateForm();

        // if (isValid) {
        setIsLoading(true)
        try {
            let res: any = "";
            const dataToSend = {
                ...organisationCardData,
                page: "approval",
                isCardNumberDisabled

            }

            if (isEditMode) {
                res = await axiosPrivate.patch(`/companyadmin/organisation/card-master/update/${editData.cardId}`, { "cardDetails": { "cardOverView": dataToSend, "transactionMapping": transactionMapping, } })
            }


            if (res.status === 200) {
                // if (organisationCardData.status === "Approved") {
                //     CardTokenization()
                // }
                setIsLoading(false)
                setShowAlertBox(true)
                getEditMode(false)
                setShowType("success")
                setShowMessage(res.data.message)
                clearAleart(res.status)
                dispatch(resetFormModified(false));
                // userNavigateFunction("grid")
            }
        } catch (error: any) {
            setIsLoading(false)
            if (error.status === 500) {
                setShowAlertBox(true);
                setShowType("danger")
                setShowMessage(error.response.data.error)
                clearAleart("")

            } else if (error.code === "ERR_NETWORK") {
                setShowAlertBox(true);
                setShowType("danger")
                setShowMessage(error.response.data.error)
                clearAleart("")
            } else {
                setShowAlertBox(true);
                setShowType("danger")
                setShowMessage(error.response.data.error)
                clearAleart("")
            }
        }
        // } else {
        //     setShowAlertBox(true)
        //     setShowType("warning")
        //     setShowMessage(Object.values(updatedIsValueValidAll).every((values) => !values) ? "Some of the field(s) are not in required format." : "Mandatory field(s) should not be left blank.")
        //     clearAleart("")
        // }

    }

    function retriveData(value: any, index: number) {
        setCurrentFormDataEdit(true)
        setIsDataRetrieved(true);
        setCurrentDirector(index)
        dispatch(resetFormModified(true));
        setIsFormDirty(true);
        setTransactionMappingData({
            transactionMappingDepartmentName: value.transactionMappingDepartmentName,
            transactionMappingMaxLimit: value.transactionMappingMaxLimit,
            transactionMappingPayments: value.transactionMappingPayments,
            transactionMappingExpense: value.transactionMappingExpense,
        })



        //     if (Object.values(value).length !== 3) {
        //         setTransactionMapping([
        //             ...value,
        //             ...transactionMapping,
        //             {
        //                 transactionMappingDepartment: value.transactionMappingDepartment,
        //                 transactionMappingMaxLimit: value.transactionMappingMaxLimit,
        //                 transactionMappingPayments: value.transactionMappingPayments,
        //                 transactionMappingExpense: value.transactionMappingExpense,
        //                 index:index
        //             }
        //         ]);
        //     } else {
        //         setTransactionMapping([
        //             ...transactionMapping,
        //             {
        //                 transactionMappingDepartment: "",
        //                 transactionMappingMaxLimit: "",
        //                 transactionMappingPayments: "",
        //                 transactionMappingExpense: "",
        //                 index: 0
        //             }
        //         ]);
        //     }
    }

    async function CardTokenization() {
        try {
            const cardNumber = organisationCardData.cardNumber.replace(/\s/g, '');
            const [month, year] = organisationCardData.validThru.split('/')
            await axiosPrivate.post('fiserv/card/tokenisation/assignToken', {
                "oriinalcardNumber": organisationCardData.cardNumber,
                "cardNumber": cardNumber,
                "expMonth": month,
                "expYear": year,
                "cvv": organisationCardData.cvv

            })
        }
        catch {

        }
    }

    async function resendOtp() {
        let res;
        try {

            res = await axiosPrivate.post('companyadmin/bank/resend-otp-update/approval', {
                approvedBy: auth.token, pageName: "cardApproval",
                processName: processName, currentDataId: editData.cardId

            });

            if (res.status === 200) {
                if (componentNavigator === "form-page") {
                    setShowCardNumberMessage("success")
                } else {
                    setShowType("success")
                }
                setShowAlertBox(true)
                setShowMessage(res.data.message)
                clearAleart("")
            }
        } catch (error: any) {
            if (componentNavigator === "form-page") {
                setShowCardNumberMessage("danger")

            } else {

                setShowType("danger")
            }
            setShowAlertBox(true);
            setShowMessage(error.response.data.error)
            clearAleart("")
        }


    }
    const transactionMappingSave = () => {

        if (currentFormDataEdit) {
            let data: any = [];
            transactionMapping.length > 0 && transactionMapping.map((ele: any, i: number) => {
                if (i === currentDirector) {
                    data.push(transactionMappingData)
                } else {
                    data.push(ele)
                }
            })
            setTransactionMapping(data)
            //   setFormData(
            //     {
            //       dateOfBirth: "",
            //       gender: "Select",
            //       email: "",
            //       contactNumber: "",
            //       maskedAadhaarNumber: "",
            //       country: "",
            //       street: "",
            //       state: "Select",
            //       city: "Select",
            //       pincode: "",
            //       address: "",
            //       DirectorName: "",
            //       isAuthSignatory: "",
            //       DirectorDin: "",
            //       DirectorDesignation: "",
            //       directorPan: "",
            //       index: 0
            //     }
            //   )
            setIsFormDirty(false);
            setCurrentFormDataEdit(false);
            setTransactionMappingData({
                transactionMappingDepartment: "",
                transactionMappingMaxLimit: "",
                transactionMappingPayments: "",
                transactionMappingExpense: "",
            })

        } else {
            let newrecord = transactionMapping
            newrecord.push({ ...transactionMappingData })
            setTransactionMapping(newrecord)

            setIsFormDirty(false);
            setTransactionMappingData({
                transactionMappingDepartment: "",
                transactionMappingMaxLimit: "",
                transactionMappingPayments: "",
                transactionMappingExpense: "",
            })
        }
        setCurrentFormDataEdit(false);
    }

    const organisationBankDataCancel = () => {
        if (isFormDirty) {
            setShowChangesModal(true)
        } else {
            setOrganisationCardData({
                cardType: "",
                nameOnCard: "",
                cardNumber: "",
                cardCategory: "",
                issuer: "",
                validFrom: "",
                issuerName: "",
                validThru: "",
                creditPeriod: "",
                billingCycle: "",
                cardMaxLimit: "",
                cardMaxLimitInr: "",
                cvv: "",
                isActive: "",
                remarks: "",
                customerLimits: "",
                dailyVolumeMaximumAmount: "",
                dailyMaximumAmountInr: "",
                singleTransactionMaximumAmount: "",
                singleMaximumAmountInr: "",
                transactionMappingDepartment: "",
                transactionMappingMaxLimit: "",
                transactionMappingPayments: "",
                transactionMappingExpense: "",
                maxLimitInr: "",
                approvedBy: "",
                status: "",
                declineRemarks: "",
                organisationId: "",
                companyBankId: "",
            })
            modeFunction(false)
            userNavigateFunction("grid")
        }
    }

    const handleCardNumberChange = (value: string) => {
        const number = value.replace(/\D/g, "");
        let formattedNumber = "";
        if (number.substring(0, 1) === "3") {
            formattedNumber = number
                .replace(/(\d{4})(\d{6})(\d{5})/, "$1 $2 $3")
                .trim();
        } else {
            formattedNumber = number
                .replace(/(\d{4})(?=\d)/g, "$1 ")
                .trim();
        }

        setOrganisationCardData((prevState) => ({
            ...prevState,
            cardNumber: formattedNumber,
        }));
    };

    useEffect(() => {
        const firstChar = organisationCardData.cardNumber.charAt(0);
        if (firstChar === "3") {
            setCardImg(amexCardImg);
        } else if (firstChar === "4") {
            setCardImg(visaCardImg);
        } else if (firstChar === "5") {
            setCardImg(masterCardImg);
        } else if (firstChar === "6") {
            setCardImg(rupayCardImg);
        } else {
            setCardImg('');
        }
    }, [organisationCardData.cardNumber])


    useEffect(() => {
        if (organisationCardData.transactionMappingDepartment !== "" &&
            organisationCardData.transactionMappingMaxLimit !== ""
        ) {
            setIsDisabled(false)
        } else {
            setIsDisabled(true)
        }
    }, [organisationCardData.transactionMappingDepartment, organisationCardData.transactionMappingMaxLimit])


    // const fetchData = async (url: string, setData: (data: any[]) => void, nestedProperty: string) => {
    //     try {
    //         const response = await axiosPrivate.get(url);
    //         if (response.status === 200) {
    //             const dropdownValues = response.data[nestedProperty].map((item: any) => ({
    //                 label: item.departmentName,
    //                 value: item.departmentName,
    //                 id: item.departmentId,
    //             }));
    //             const sortedData = [...dropdownValues].sort((a, b) => a.label.localeCompare(b.label));
    //             setData(sortedData);
    //         }
    //     } catch (error) {
    //     }
    // };
    const fetchData = async (url: string, setData: (data: any[]) => void, nestedProperty: string) => {
        try {

            const response = await axiosPrivate.get(url);
            if (response.status === 200) {

                const dropdownValues = response.data[nestedProperty].map((item: any) => ({
                    label: item.cardCategoryName,
                    value: item.cardCategoryName,
                    id: item.cardCategoryId,
                }));
                const sortedData = [...dropdownValues].sort((a, b) => a.label.localeCompare(b.label));

                setData(sortedData);
            }
        } catch (error) {
        }
    };

    const fetchExpenseData = async (url: string, setData: (data: any[]) => void, nestedProperty: string) => {
        try {

            const response = await axiosPrivate.get(url);
            if (response.status === 200) {

                const dropdownValues = response.data[nestedProperty].map((item: any) => ({
                    label: item.expenseName,
                    value: item.expenseName,
                    id: item.expenseNameId,
                }));
                const selectOption = {
                    label: "Select",
                    value: "Select" ? "" : "Select"
                };
                const sortedData = [selectOption, ...dropdownValues].sort((a, b) => a.label.localeCompare(b.label));

                setData(sortedData);
            }
        } catch (error) {
        }
    };

    const orginationMenueClick = () => {
        const isFormValid = validateForm()
        if (isFormValid) {
            setComponentNavigator("form-page")
            isEditMode && setIconStatus({ ...iconStatus, adminDetails: "correct" })
        } else {
            setShowAlertBox(true);
            setShowType("danger")
            setShowMessage("Mandatory field(s) should not be left blank.")
            clearAleart("")
        }
        setComponentNavigator("form-page")
    }

    const adminDetailsIcon = () => {
        const isFormValid = validateForm()

        if (isFormValid) {
            setComponentNavigator("control-page")
            if (isEditMode) {
                setIconStatus({ ...iconStatus, organisationDetails: "correct", adminDetails: "correct", })
            } else {
                setIconStatus({ ...iconStatus, organisationDetails: "correct", adminDetails: "inprogress" })
            }
        } else {
            setShowAlertBox(true);
            setShowType("danger")
            setShowMessage("Mandatory field(s) should not be left blank.")
            clearAleart("")
        }
    }

    const approvalIcon = () => {
        const isFormValid = validateForm()
        if (isFormValid) {
            setComponentNavigator("approval-page")
            if (isEditMode) {
                setIconStatus({ ...iconStatus, organisationDetails: "correct", adminDetails: "correct" })
            } else {
                setIconStatus({ ...iconStatus, organisationDetails: "correct", adminDetails: "correct" })
            }
        } else {
            setShowAlertBox(true);
            setShowType("danger")
            setShowMessage("Mandatory field(s) should not be left blank.")
            clearAleart("")
        }
    }
    useEffect(() => {
        setIsDisabled(Object.values(transactionMappingData).every(item => item !== "") ? false : true);
    }, [transactionMappingData]);

    const formatAmount = (amount: any) => {
        const onlyNumber = amount.toString().replace(/[^0-9]/g, "");
        const parsedValue = parseInt(onlyNumber);
        const formattedValue = isNaN(parsedValue) ? "" : parsedValue.toLocaleString('en-IN');
        return formattedValue;
    }


    async function showCardNumberOtpModel() {
        try {

            let res = await axiosPrivate.post('companyadmin/bank/otp-update/approval', {
                approvedBy: auth.token, pageName: "cardApproval",
                processName: processName, currentDataId: editData.cardId
            });
            if (res.status === 200) {
                setOtpVerification(true)
                setShowAlertBox(true)
                setShowCardNumberMessage("success")
                setShowMessage(res.data.message)
                setTimeout(() => {
                    setShowAlertBox(false);
                }, 5000);
            }
        } catch (error: any) {
            setShowAlertBox(true);
            setShowCardNumberMessage("danger")
            setShowMessage(error.response.data.error)
            setTimeout(() => {
                setShowAlertBox(false);
            }, 5000);
        }
    }

    async function ShowCardNumber() {
        try {
            const response = await axiosPrivate.get(`/companyadmin/organisation/card-master/get-cardNumber/${editData.cardId}`);
            if (response.status === 200) {
                setOrganisationCardData((prevState) => ({
                    ...prevState,
                    cardNumber: response.data.cardNumber,
                }));
                setIsHidden(true)
                setIsCardNumberDisabled(false)
            }

        } catch (error) {
        }
    }

    return (
        <>
            <MainPageCard>
                <NavigateHeader
                    style={{
                        cursor: "pointer"
                    }}
                    firstValue={"Approval"}
                    secondValue={"Card"} navigatePage={() => { organisationBankDataCancel() }} />
                <div className='form-top-main'>
                    <div>
                        <div className='leftnavigation-form'>
                            <div className='card-stepper'>
                                <div className='testing-stepper testing-stepper-card'>
                                    <div className='contents'>
                                        <CardOverview
                                            className={`left-icon ${![""].includes(iconStatus.organisationDetails) && "active"}`}
                                            onClick={() => {
                                                orginationMenueClick()
                                            }}
                                        />
                                        <div
                                            className='content-click'
                                            style={(componentNavigator === "form-page") ? { color: '#0055D4', fontWeight: 'bold', fontSize: '18px' } : {}}
                                            onClick={() => {
                                                orginationMenueClick()
                                            }}
                                        >
                                            Card Overview<span className='star-span'>*</span>
                                        </div>
                                        <div className='tick-image-green'>
                                            {(iconStatus.organisationDetails === "inprogress") && <InProgerss />}
                                            {iconStatus.organisationDetails === "correct" && <Correct />}
                                        </div>
                                        <div className={`vertical-line-stepper`} >
                                        </div>
                                    </div>
                                    <div className='contents'>
                                        <ControlCentre
                                            className={`left-icon ${!["notwork"].includes(iconStatus.adminDetails) && "active"}`}
                                            onClick={() => {
                                                adminDetailsIcon()
                                            }} />
                                        <div
                                            className='content-click'
                                            style={(componentNavigator === "control-page") ? { color: '#0055D4', fontWeight: 'bold', fontSize: '18px' } : {}}
                                            onClick={() => {
                                                adminDetailsIcon()
                                            }}
                                        >
                                            Control Centre
                                        </div>
                                        <div className='tick-image-green'>
                                            {iconStatus.adminDetails === "notwork" && <NotWork />}
                                            {(iconStatus.adminDetails === "inprogress") && <InProgerss />}
                                            {iconStatus.adminDetails === "correct" && <Correct />}
                                        </div>
                                        <div className={`vertical-line-stepper`} >
                                        </div>
                                    </div>
                                    <div className='contents'>
                                        <Approval
                                            className={`left-icon ${!["notwork"].includes(iconStatus.approval) && "active"}`}
                                            onClick={() => {
                                                approvalIcon()
                                            }} />
                                        <div
                                            className='content-click'
                                            style={(componentNavigator === "approval-page") ? { color: '#0055D4', fontWeight: 'bold', fontSize: '18px' } : {}}
                                            onClick={() => {
                                                approvalIcon()
                                            }}
                                        >
                                            Approval<span className='star-span'>*</span>
                                        </div>

                                        <div className='tick-image-green'>
                                            {iconStatus.approval === "notwork" && <NotWork />}
                                            {(iconStatus.approval === "inprogress") && <InProgerss />}
                                            {iconStatus.approval === "correct" && <Correct />}
                                        </div>

                                    </div>

                                </div>
                            </div>

                            {componentNavigator === "form-page" &&

                                <div>
                                    <div className='card-overview-header'
                                        style={isEditMode ?
                                            { pointerEvents: "none", opacity: 0.5 } : { pointerEvents: "auto", opacity: 1 }}
                                    >{"Card Overview"}</div>
                                    {/* <div className='form-top-main'> */}
                                    <div className='form-input' >
                                        <div className={'dropdown-user'} key={uuidv4()} style={isEditMode ?
                                            { pointerEvents: "none", opacity: 0.5 } : { pointerEvents: "auto", opacity: 1 }}>
                                            <DropdownComponent
                                                options={cardType}
                                                title={"Card Type"}
                                                required={true}
                                                isDisabled={true}
                                                // border={userDataErrors.issuer ? "1px solid red" : "1px solid #A9C3DC"}
                                                getData={(val) => {
                                                    setIsFormDirty(true)
                                                    setOrganisationCardData((prevState) => ({
                                                        ...prevState,
                                                        issuer: val.value,
                                                    }));
                                                    dispatch(resetFormModified(true));
                                                }}
                                                defaultValue={[{ label: "Commercial Card", value: "Commercial Card" }]}
                                            />
                                        </div>
                                        <div style={isEditMode ?
                                            { pointerEvents: "none", opacity: 0.5 } : { pointerEvents: "auto", opacity: 1 }}>
                                            <InputComponent
                                                height={"40px"}
                                                width={"300px"}
                                                margin={"0px 60px 30px 0px"}
                                                padding={"0px 0px 0px 10px"}
                                                border={userDataErrors.nameOnCard ? "1px solid red" : "1px solid #A9C3DC"}
                                                borderRadius={"0px"}
                                                backgroundColor={"white"}
                                                color={"black"}
                                                type={"mobile"}
                                                maxLength={100}
                                                readOnly
                                                inputTitle={"Name on Card"}
                                                placeHolder={"Enter Name on Card"}
                                                required={true}
                                                inputValue={organisationCardData.nameOnCard}
                                                getUser={(value: any) => {
                                                    setIsFormDirty(true)
                                                    const onlyAlphabet = value.replace(/[^a-zA-Z ]/g, "")
                                                    setOrganisationCardData((prevState) => ({
                                                        ...prevState,
                                                        nameOnCard: onlyAlphabet,
                                                    }));
                                                    dispatch(resetFormModified(true));
                                                    isEditMode && setIconStatus({ ...iconStatus, organisationDetails: "inprogress" })
                                                }}
                                            />
                                        </div>
                                        <div className='cardNumber-show-icon'>

                                            <div className='mastercard-main' style={isEditMode ?
                                                { pointerEvents: "none", opacity: 0.5 } : { pointerEvents: "auto", opacity: 1 }}>
                                                <InputComponent
                                                    height={"40px"}
                                                    width={"300px"}
                                                    margin={"0px 10px 30px 0px"}
                                                    padding={"0px 0px 0px 10px"}
                                                    border={userDataErrors.cardNumber ? "1px solid red" : "1px solid #A9C3DC"}
                                                    borderRadius={"0px"}
                                                    backgroundColor={"white"}
                                                    color={"black"}
                                                    type={"mobile"}
                                                    maxLength={19}
                                                    readOnly
                                                    inputTitle={"Card Number"}
                                                    placeHolder={"Enter Card Number"}
                                                    required={true}
                                                    inputValue={organisationCardData.cardNumber}
                                                    getUser={(value: any) => {
                                                        setIsFormDirty(true)
                                                        handleCardNumberChange(value)
                                                        dispatch(resetFormModified(true));
                                                        isEditMode && setIconStatus({ ...iconStatus, organisationDetails: "inprogress" })
                                                    }}

                                                />
                                                {cardImg && <img className='master-card' src={cardImg} alt="" />}
                                            </div>
                                            {isEditMode &&
                                                <div className='cardNumber-eye-icon' onClick={() => {
                                                    if (!isHidden) {
                                                        showCardNumberOtpModel()
                                                    } else {
                                                        setIsHidden(false)
                                                        setIsCardNumberDisabled(true)
                                                        setOrganisationCardData((prevState) => ({
                                                            ...prevState,
                                                            cardNumber: showMaskedCardNumber,
                                                        }));
                                                    }
                                                }}>
                                                    {otpVerification ?
                                                        <IoIosEyeOff color='#004E9C' height={"14px"} /> :
                                                        <IoIosEye color='#004E9C' height={"14px"} />
                                                    }
                                                    <span className='tooltip-text'>Show/Hide Card Number</span>
                                                </div>
                                            }
                                        </div>

                                    </div>
                                    <div className='form-input'
                                        style={isEditMode ?
                                            { pointerEvents: "none", opacity: 0.5 } : { pointerEvents: "auto", opacity: 1 }}
                                    >
                                        <div className='cardCatrgory-plus'>
                                            <div className={userDataErrors.cardCategory ? 'setError-cetegory' : 'dropdown-user-category'} key={uuidv4()}>
                                                <DropdownComponent
                                                    options={cardCategory}
                                                    title={"Card Category"}
                                                    required={true}
                                                    isDisabled={true}
                                                    // border={userDataErrors.cardCategory ? "1px solid red" : "1px solid #A9C3DC"}
                                                    getData={(val) => {
                                                        setIsFormDirty(true)
                                                        setOrganisationCardData((prevState) => ({
                                                            ...prevState,
                                                            cardCategory: val.value,
                                                            cardCategoryId: val.id

                                                        }));

                                                        dispatch(resetFormModified(true));
                                                        isEditMode && setIconStatus({ ...iconStatus, organisationDetails: "inprogress" })
                                                    }}
                                                    defaultValue={[{ label: organisationCardData.cardCategory ? organisationCardData.cardCategory : "Select", value: organisationCardData.cardCategory }]}
                                                />
                                            </div>
                                        </div>
                                        <div className={userDataErrors.issuer ? 'setError-issuer' : 'dropdown-user'} key={uuidv4()}>
                                            <DropdownComponent
                                                options={bankValues}
                                                title={"Issuer"}
                                                required={true}
                                                isDisabled={true}
                                                // border={userDataErrors.issuer ? "1px solid red" : "1px solid #A9C3DC"}
                                                getData={(val) => {
                                                    setIsFormDirty(true)
                                                    setOrganisationCardData((prevState) => ({
                                                        ...prevState,
                                                        issuer: val.id,
                                                        issuerName: val.value
                                                    }));
                                                    dispatch(resetFormModified(true));
                                                    isEditMode && setIconStatus({ ...iconStatus, organisationDetails: "inprogress" })
                                                }}
                                                defaultValue={[{ label: organisationCardData.issuerName ? organisationCardData.issuerName : "Select", value: organisationCardData.issuerName }]}
                                            />
                                        </div>
                                        <InputComponent
                                            height={"40px"}
                                            width={"300px"}
                                            margin={"0px 60px 30px 0px"}
                                            padding={"0px 0px 0px 10px"}
                                            border={userDataErrors.validFrom ? "1px solid red" : "1px solid #A9C3DC"}
                                            borderRadius={"0px"}
                                            backgroundColor={"white"}
                                            color={"black"}
                                            type={"text"}
                                            readOnly
                                            maxLength={5}
                                            inputTitle={"Valid From"}
                                            placeHolder={"MM / YY"}
                                            required={false}
                                            inputValue={organisationCardData.validFrom}
                                            getUser={(value: any) => {
                                                setIsFormDirty(true)
                                                const validFrom = value
                                                    .replace(/[^0-9]/g, "")
                                                    .replace(/(.{2})(?=.)/, "$1/");
                                                setOrganisationCardData((prevState) => ({
                                                    ...prevState,
                                                    validFrom: validFrom,
                                                }));
                                                dispatch(resetFormModified(true));
                                                isEditMode && setIconStatus({ ...iconStatus, organisationDetails: "inprogress" })
                                            }}
                                        />



                                    </div>
                                    <div className='form-input'
                                        style={isEditMode ?
                                            { pointerEvents: "none", opacity: 0.5 } : { pointerEvents: "auto", opacity: 1 }}
                                    >
                                        <InputComponent
                                            height={"40px"}
                                            width={"300px"}
                                            margin={"0px 60px 30px 0px"}
                                            padding={"0px 0px 0px 10px"}
                                            border={userDataErrors.validThru ? "1px solid red" : "1px solid #A9C3DC"}
                                            borderRadius={"0px"}
                                            backgroundColor={"white"}
                                            color={"black"}
                                            type={"text"}
                                            maxLength={5}
                                            readOnly
                                            inputTitle={"Valid Thru"}
                                            placeHolder={"MM / YY"}
                                            required={true}
                                            inputValue={organisationCardData.validThru}
                                            getUser={(value: any) => {
                                                setIsFormDirty(true)

                                                const validThru = value
                                                    .replace(/[^0-9]/g, "")
                                                    .replace(/(.{2})(?=.)/, "$1/");
                                                setOrganisationCardData((prevState) => ({
                                                    ...prevState,
                                                    validThru: validThru,
                                                }));
                                                dispatch(resetFormModified(true));
                                                isEditMode && setIconStatus({ ...iconStatus, organisationDetails: "inprogress" })
                                            }}
                                        />
                                        <div className='Credit-period'>
                                            <InputComponent
                                                height={"40px"}
                                                width={"230px"}
                                                margin={"0px 60px 30px 0px"}
                                                padding={"0px 0px 0px 10px"}
                                                border={userDataErrors.creditPeriod ? "1px solid red" : "1px solid #A9C3DC"}
                                                borderRadius={"0px"}
                                                backgroundColor={"white"}
                                                color={"black"}
                                                type={"text"}
                                                maxLength={2}
                                                readOnly
                                                inputTitle={"Credit Period"}
                                                placeHolder={"Enter Credit Period"}
                                                required={true}
                                                inputValue={organisationCardData.creditPeriod}
                                                getUser={(value: any) => {
                                                    setIsFormDirty(true)

                                                    const onlyNumber = value.replace(/[^0-9]/g, "")
                                                    setOrganisationCardData((prevState) => ({
                                                        ...prevState,
                                                        creditPeriod: onlyNumber,
                                                    }));
                                                    dispatch(resetFormModified(true));
                                                    isEditMode && setIconStatus({ ...iconStatus, organisationDetails: "inprogress" })
                                                }}

                                            />
                                            <div className='credit-period-days'
                                            // style={{ border: userDataErrors.creditPeriod ? "1px solid red" : "1px solid #A9C3DC" }}
                                            >Days</div>
                                        </div>
                                        <div className='Credit-period'>
                                            <InputComponent
                                                height={"40px"}
                                                width={"180px"}
                                                margin={"0px 60px 30px 0px"}
                                                padding={"0px 0px 0px 10px"}
                                                border={userDataErrors.billingCycle ? "1px solid red" : "1px solid #A9C3DC"}
                                                borderRadius={"0px"}
                                                backgroundColor={"white"}
                                                color={"black"}
                                                type={"text"}
                                                maxLength={2}
                                                readOnly
                                                inputTitle={"Billing Cycle"}
                                                placeHolder={"Enter Billing Cycle"}
                                                required={true}
                                                inputValue={organisationCardData.billingCycle}
                                                getUser={(value: any) => {
                                                    setIsFormDirty(true)

                                                    const onlyNumber = value.replace(/[^0-9]/g, "")
                                                    setOrganisationCardData((prevState) => ({
                                                        ...prevState,
                                                        billingCycle: onlyNumber,
                                                    }));
                                                    dispatch(resetFormModified(true));
                                                    isEditMode && setIconStatus({ ...iconStatus, organisationDetails: "inprogress" })
                                                }}

                                            />
                                            <div className='billing-cycle'
                                            // style={{ border: userDataErrors.billingCycle ? "1px solid red" : "1px solid #A9C3DC" }}
                                            >Every Month</div>
                                        </div>
                                    </div>
                                    <div className='form-input'>
                                        <div className='dropdown-input'
                                            style={isEditMode ?
                                                { pointerEvents: "none", opacity: 0.5 } : { pointerEvents: "auto", opacity: 1 }}
                                        >
                                            <InputComponent
                                                height={"41px"}
                                                width={"200px"}
                                                margin={"0px 60px 30px 0px"}
                                                padding={"0px 5px 0px 30px"}
                                                textAlign={"right"}
                                                border={userDataErrors.cardMaxLimit ? "1px solid red" : "1px solid #A9C3DC"}
                                                borderRadius={"0px"}
                                                backgroundColor={"white"}
                                                color={"black"}
                                                type={"mobile"}
                                                maxLength={10}
                                                readOnly
                                                inputTitle={"Card Max Limit"}
                                                placeHolder={"Enter Card Max Limit"}
                                                required={false}
                                                inputValue={formatAmount(organisationCardData.cardMaxLimit)}
                                                getUser={(value: any) => {
                                                    setIsFormDirty(true)
                                                    const onlyNumber = value.replace(/[^0-9]/g, "");

                                                    setOrganisationCardData((prevState) => ({
                                                        ...prevState,
                                                        cardMaxLimit: onlyNumber,
                                                    }));
                                                    dispatch(resetFormModified(true));
                                                    isEditMode && setIconStatus({ ...iconStatus, organisationDetails: "inprogress" })
                                                }}
                                            />

                                            <div className='dropdown-position card-min'>
                                                <div className={userDataErrors.cardMaxLimitInr ? 'setError' : 'dropdown-card'}>
                                                    <DropdownComponent
                                                        options={bankValues}
                                                        title={""}
                                                        required={false}
                                                        isDisabled={true}
                                                        // border={userDataErrors.cardMaxLimitInr ? "1px solid red" : "1px solid #A9C3DC"}
                                                        getData={(val) => {
                                                            setIsFormDirty(true)
                                                            setOrganisationCardData((prevState) => ({
                                                                ...prevState,
                                                                cardMaxLimitInr: val.value,
                                                            }));
                                                            dispatch(resetFormModified(true));
                                                        }}
                                                        defaultValue={[{ label: organisationCardData.cardMaxLimitInr ? organisationCardData.cardMaxLimitInr : "INR", value: organisationCardData.cardMaxLimitInr }]}
                                                    />
                                                </div>
                                            </div>
                                            <div>
                                                <img className='rupee-symbol-position' src={rupeeImg} alt="" />
                                            </div>
                                        </div>
                                        {/* <div className='card-cvv'>
                                            <InputComponent
                                                height={"40px"}
                                                width={"300px"}
                                                margin={"0px 60px 30px 0px"}
                                                padding={"0px 0px 0px 10px"}
                                                border={userDataErrors.cvv ? "1px solid red" : "1px solid #A9C3DC"}
                                                borderRadius={"0px"}
                                                backgroundColor={"white"}
                                                color={"black"}
                                                type={"password"}
                                                maxLength={3}
                                                inputTitle={"CVV"}
                                                placeHolder={"***"}
                                                required={true}
                                                inputValue={organisationCardData.cvv}
                                                getUser={(value: any) => {
                                                    setIsFormDirty(true)

                                                    const cvv = value
                                                        .replace(/[^0-9]/g, "")
                                                    setOrganisationCardData((prevState) => ({
                                                        ...prevState,
                                                        cvv: cvv,
                                                    }));
                                                    dispatch(resetFormModified(true));
                                                    isEditMode && setIconStatus({ ...iconStatus, organisationDetails: "inprogress" })
                                                }}
                                            />
                                        </div> */}
                                    </div>

                                </div>
                            }
                            {componentNavigator === "control-page" &&
                                <div className='control-page-main' >
                                    <div style={isEditMode ?
                                        { pointerEvents: "none", opacity: 0.5 } : { pointerEvents: "auto", opacity: 1 }}>
                                        <div className='card-overview-header'>{"Control Centre"}</div>
                                        <p className='transaction-limits-title'>{"Transaction Limits"}</p>
                                        <div className='form-input'>
                                            <div className='radio-input user-form-radio-input'>

                                                <div className='radio-btn-transction-limit'>
                                                    <p>Custom Limits</p>
                                                    <RadioOrCheckbox
                                                        value={"Yes"}
                                                        type={"radio"}
                                                        name={"customLimits"}
                                                        disabled={true}
                                                        margin={"0px 30px 0px 0px"}
                                                        checkedValue={organisationCardData.customerLimits}
                                                        getVal={(val) => {
                                                            setIsFormDirty(true)
                                                            setOrganisationCardData((prevState) => ({
                                                                ...prevState,
                                                                customerLimits: val,
                                                                remarks: ""
                                                            }));
                                                            dispatch(resetFormModified(true));
                                                        }}
                                                    />
                                                    <div>
                                                        <RadioOrCheckbox
                                                            value={"No"}
                                                            name={"customlimits"}
                                                            type={"radio"}
                                                            disabled={true}
                                                            checkedValue={organisationCardData.customerLimits}
                                                            getVal={(val) => {
                                                                setIsFormDirty(true)
                                                                setOrganisationCardData((prevState) => ({
                                                                    ...prevState,
                                                                    customerLimits: val,
                                                                    dailyVolumeMaximumAmount: val === "No" ? "" : prevState.dailyVolumeMaximumAmount,
                                                                    singleTransactionMaximumAmount: val === "No" ? "" : prevState.singleTransactionMaximumAmount,

                                                                }));
                                                                dispatch(resetFormModified(true));
                                                            }}

                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='transaction-control'
                                            style={organisationCardData.customerLimits === "No" ?
                                                { pointerEvents: "none", opacity: 0.5 } : { pointerEvents: "auto", opacity: 1 }
                                            }
                                        >
                                            <div className='form-input'>
                                                <div>
                                                    <p className='transaction-limits-control'>Daily Volume</p>
                                                    <div className='mt-20 dropdown-input'>
                                                        <div>
                                                            <InputComponent
                                                                height={"41px"}
                                                                width={"248px"}
                                                                margin={"0px 60px 30px 0px"}
                                                                padding={"0px 5px 0px 30px"}
                                                                textAlign={"right"}
                                                                border={userDataErrors.dailyVolumeMaximumAmount ? "1px solid red" : "1px solid #A9C3DC"}
                                                                borderRadius={"0px"}
                                                                backgroundColor={"white"}
                                                                color={"black"}
                                                                type={"mobile"}
                                                                readOnly
                                                                disabled={organisationCardData.customerLimits === "No" ? true : false}
                                                                maxLength={10}
                                                                inputTitle={"Maximum Amount"}
                                                                placeHolder={"Enter Maximum Amount"}
                                                                required={false}
                                                                inputValue={formatAmount(organisationCardData.dailyVolumeMaximumAmount)}
                                                                getUser={(value: any) => {

                                                                    setIsFormDirty(true)
                                                                    const number = value.replace(/[^0-9]/g, "")

                                                                    setOrganisationCardData((prevState) => ({
                                                                        ...prevState,
                                                                        dailyVolumeMaximumAmount: number,

                                                                    }));
                                                                    dispatch(resetFormModified(true));

                                                                }}
                                                            />
                                                        </div>
                                                        <div className='dropdown-position card-min'>
                                                            <div className={userDataErrors.dailyMaximumAmountInr ? 'setError' : 'dropdown-card'} key={uuidv4()}>
                                                                <DropdownComponent
                                                                    options={bankValues}
                                                                    title={""}
                                                                    required={false}
                                                                    isDisabled={true}
                                                                    // border={userDataErrors.dailyMaximumAmountInr ? "1px solid red" : "1px solid #A9C3DC"}
                                                                    getData={(val) => {
                                                                        setIsFormDirty(true)
                                                                        setOrganisationCardData((prevState) => ({
                                                                            ...prevState,
                                                                            dailyMaximumAmountInr: val.value,
                                                                        }));
                                                                        dispatch(resetFormModified(true));
                                                                    }}
                                                                    defaultValue={[{ label: organisationCardData.dailyMaximumAmountInr ? organisationCardData.dailyMaximumAmountInr : "INR", value: organisationCardData.dailyMaximumAmountInr }]}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <img className='rupee-symbol' src={rupeeImg} alt="" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <p className='transaction-limits-control'>Single Transaction</p>
                                                    <div className='mt-20 dropdown-input'>
                                                        <div>
                                                            <InputComponent
                                                                height={"41px"}
                                                                width={"248px"}
                                                                margin={"0px 60px 30px 0px"}
                                                                padding={"0px 5px 0px 30px"}
                                                                textAlign={"right"}
                                                                border={userDataErrors.singleTransactionMaximumAmount ? "1px solid red" : "1px solid #A9C3DC"}
                                                                borderRadius={"0px"}
                                                                backgroundColor={"white"}
                                                                color={"black"}
                                                                type={"mobile"}
                                                                readOnly
                                                                disabled={organisationCardData.customerLimits === "No" ? true : false}
                                                                maxLength={10}
                                                                inputTitle={"Maximum Amount"}
                                                                placeHolder={"Enter Maximum Amount"}
                                                                required={false}
                                                                inputValue={formatAmount(organisationCardData.singleTransactionMaximumAmount)}
                                                                getUser={(value: any) => {
                                                                    setIsFormDirty(true)
                                                                    const number = value.replace(/[^0-9]/g, "")
                                                                    setOrganisationCardData((prevState) => ({
                                                                        ...prevState,
                                                                        singleTransactionMaximumAmount: number,
                                                                    }));
                                                                    dispatch(resetFormModified(true));
                                                                }}
                                                            />
                                                        </div>
                                                        <div className='dropdown-position card-min'>
                                                            <div className={userDataErrors.singleMaximumAmountInr ? 'setError' : 'dropdown-card'} key={uuidv4()}>
                                                                <DropdownComponent
                                                                    options={bankValues}
                                                                    title={""}
                                                                    required={false}
                                                                    isDisabled={true}
                                                                    // border={userDataErrors.singleMaximumAmountInr ? "1px solid red" : "1px solid #A9C3DC"}
                                                                    getData={(val) => {
                                                                        setIsFormDirty(true)
                                                                        setOrganisationCardData((prevState) => ({
                                                                            ...prevState,
                                                                            singleMaximumAmountInr: val.value,
                                                                        }));
                                                                        dispatch(resetFormModified(true));
                                                                    }}
                                                                    defaultValue={[{ label: organisationCardData.singleMaximumAmountInr ? organisationCardData.singleMaximumAmountInr : "INR", value: organisationCardData.singleMaximumAmountInr }]}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <img className='rupee-symbol' src={rupeeImg} alt="" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <p className='transaction-limits-mapping'>{"Transaction Limits Mapping"}</p>
                                        <div className='mt-20'>
                                            <div className='form-input'>

                                                <div className={userDataErrors.transactionMappingDepartment ? 'setError' : 'dropdown-user'} key={uuidv4()}>
                                                    <DropdownComponent
                                                        options={departmentValues}
                                                        title={"Department/ Unit/ SBU"}
                                                        required={false}
                                                        isDisabled={true}
                                                        // border={userDataErrors.transactionMappingDepartment ? "1px solid red" : "1px solid #A9C3DC"}
                                                        getData={(val) => {
                                                            setIsFormDirty(true)
                                                            setTransactionMappingData((prevState: any) => ({
                                                                ...prevState,
                                                                transactionMappingDepartment: val.id,
                                                                transactionMappingDepartmentName: val.value
                                                            }));
                                                            dispatch(resetFormModified(true));
                                                        }}
                                                        defaultValue={[{ label: transactionMappingData.transactionMappingDepartmentName ? transactionMappingData.transactionMappingDepartmentName : "Select", value: transactionMappingData.transactionMappingDepartmentName }]}
                                                    />
                                                </div>
                                                <div className='dropdown-input'>
                                                    <InputComponent
                                                        height={"41px"}
                                                        width={"248px"}
                                                        margin={"0px 60px 30px 0px"}
                                                        padding={"0px 5px 0px 30px"}
                                                        textAlign={"right"}
                                                        border={userDataErrors.transactionMappingMaxLimit ? "1px solid red" : "1px solid #A9C3DC"}
                                                        borderRadius={"0px"}
                                                        backgroundColor={"white"}
                                                        color={"black"}
                                                        type={"mobile"}
                                                        maxLength={10}
                                                        readOnly
                                                        inputTitle={"Max Limit"}
                                                        placeHolder={"Enter Max Limit"}
                                                        required={false}
                                                        inputValue={formatAmount(transactionMappingData.transactionMappingMaxLimit)}
                                                        getUser={(value: any) => {
                                                            setIsFormDirty(true)
                                                            const number = value.replace(/[^i0-9]/g, "")
                                                            setTransactionMappingData((prevState: any) => ({
                                                                ...prevState,
                                                                transactionMappingMaxLimit: number,
                                                            }));
                                                            dispatch(resetFormModified(true));
                                                        }}
                                                    />
                                                    <div className='dropdown-position card-min'>
                                                        <div className={userDataErrors.maxLimitInr ? 'setError' : 'dropdown-card'} key={uuidv4()}>
                                                            <DropdownComponent
                                                                options={bankValues}
                                                                title={""}
                                                                required={false}
                                                                isDisabled={true}
                                                                // border={userDataErrors.maxLimitInr ? "1px solid red" : "1px solid #A9C3DC"}
                                                                getData={(val) => {
                                                                    setIsFormDirty(true)
                                                                    setOrganisationCardData((prevState) => ({
                                                                        ...prevState,
                                                                        maxLimitInr: val.value,
                                                                    }));
                                                                    dispatch(resetFormModified(true));
                                                                }}
                                                                defaultValue={[{ label: organisationCardData.maxLimitInr ? organisationCardData.maxLimitInr : "INR", value: organisationCardData.maxLimitInr }]}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <img className='rupee-symbol' src={rupeeImg} alt="" />
                                                    </div>

                                                </div>
                                                <div className={userDataErrors.transactionMappingPayments ? 'setError' : 'dropdown-user'} key={uuidv4()}>
                                                    <DropdownComponent
                                                        options={payments}
                                                        title={"Payments"}
                                                        required={false}
                                                        isDisabled={true}
                                                        // border={userDataErrors.transactionMappingDepartment ? "1px solid red" : "1px solid #A9C3DC"}
                                                        getData={(val) => {
                                                            setIsFormDirty(true)
                                                            setTransactionMappingData((prevState: any) => ({
                                                                ...prevState,
                                                                transactionMappingPayments: val.value,
                                                            }));
                                                            dispatch(resetFormModified(true));
                                                        }}
                                                        defaultValue={[{ label: transactionMappingData.transactionMappingPayments ? transactionMappingData.transactionMappingPayments : "Select", value: transactionMappingData.transactionMappingPayments }]}
                                                    />
                                                </div>
                                                <div className='cardCatrgory-plus'>
                                                    <div className={userDataErrors.transactionMappingExpense ? 'setError' : 'dropdown-user-expense'} key={uuidv4()}>
                                                        <DropdownComponent
                                                            options={expenseValue}
                                                            title={"Expense Type"}
                                                            required={false}
                                                            isDisabled={true}
                                                            // border={userDataErrors.cardCategory ? "1px solid red" : "1px solid #A9C3DC"}
                                                            getData={(val) => {
                                                                setIsFormDirty(true)
                                                                setTransactionMappingData((prevState: any) => ({
                                                                    ...prevState,
                                                                    transactionMappingExpense: val.value,
                                                                    expenseId: val.id
                                                                }));
                                                                dispatch(resetFormModified(true));
                                                            }}
                                                            defaultValue={[{ label: transactionMappingData.transactionMappingExpense ? transactionMappingData.transactionMappingExpense : "Select", value: transactionMappingData.transactionMappingExpense }]}
                                                        />
                                                    </div>
                                                    <div className='issuer-plus' onClick={() => {
                                                        setAddExpense(true)
                                                    }}>
                                                        <BsPlusLg color='#004E9C' height={"14px"} />
                                                    </div>

                                                </div>
                                                <div className='add'>
                                                    <ButtonComponent
                                                        title={isDataRetrieved ? "Update" : "Add"}
                                                        height={"50px"}
                                                        width={"150px"}
                                                        // disabled={isDisabled}
                                                        disabled={isDisabled}
                                                        backgroundColor={"#0055D4"}
                                                        color={"white"}
                                                        margin={"0px 8px"}
                                                        className={isDisabled ? "button-component-hover disabled" : "button-component-hover common-btn"}
                                                        handleClick={() => {
                                                            transactionMappingSave()
                                                            setIsDataRetrieved(false)
                                                        }
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <CardFormTableGrid
                                            retriveData={(value: any, index: number) => {
                                                retriveData(value, index)
                                            }}
                                            transactionMapping={transactionMapping}
                                            showmodal={() => {

                                            }}

                                        />
                                    </div>
                                </div>

                            }

                            {componentNavigator === "approval-page" &&
                                <div>
                                    <p className='cardmaster-approval'>Card Status</p>
                                    <div className='form-input'>
                                        <div style={{ width: "auto" }}>
                                        </div>
                                        <div className='radio-input user-form-radio-input'>
                                            <p>Is Active<span>*</span></p>
                                            <div className='radio-btn-main'>
                                                <RadioOrCheckbox
                                                    value={"Yes"}
                                                    name={"ActiveCard"}
                                                    type={"radio"}
                                                    margin={"0px 30px 0px 0px"}
                                                    checkedValue={organisationCardData.isActive}
                                                    getVal={(val) => {
                                                        setIsFormDirty(true)
                                                        setOrganisationCardData((prevState) => ({
                                                            ...prevState,
                                                            isActive: val,
                                                            remarks: ""
                                                        }));
                                                        setProcessName("activation")
                                                        setUserDataErrors({ remarks: false })
                                                        dispatch(resetFormModified(true));
                                                    }}
                                                />
                                                <div>
                                                    <RadioOrCheckbox
                                                        value={"No"}
                                                        type={"radio"}
                                                        name={"Activecard"}
                                                        checkedValue={organisationCardData.isActive}
                                                        getVal={(val) => {
                                                            setIsFormDirty(true)
                                                            setOrganisationCardData((prevState) => ({
                                                                ...prevState,
                                                                isActive: val,
                                                            }));
                                                            setProcessName("deactivation")
                                                            dispatch(resetFormModified(true));
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    {organisationCardData.isActive !== "Yes" &&
                                        <div style={organisationCardData.isActive === "No" ?
                                            { pointerEvents: "auto", opacity: 1 } : { pointerEvents: "none", opacity: 0.5 }
                                        }>
                                            <InputComponent
                                                height={"40px"}
                                                width={windowWidth > 1250 ? "520px" : "350px"}
                                                margin={"0px 60px 30px 0px"}
                                                padding={"0px 0px 0px 10px"}
                                                border={userDataErrors.remarks ? "1px solid red" : "1px solid #A9C3DC"}
                                                borderRadius={"0px"}
                                                backgroundColor={"white"}
                                                color={"black"}
                                                maxLength={250}
                                                type={"text"}
                                                disabled={organisationCardData.isActive === "Yes" ? true : false}
                                                inputTitle={"Remarks"}
                                                required={true}
                                                placeHolder={"Reason"}
                                                inputValue={organisationCardData.remarks}
                                                getUser={(value: any) => {
                                                    setIsFormDirty(true)
                                                    setOrganisationCardData((prevState) => ({
                                                        ...prevState,
                                                        remarks: value,
                                                    }));
                                                    dispatch(resetFormModified(true));
                                                }}
                                            />
                                        </div>
                                    }
                                    <p className='cardmaster-approval'>Approval</p>
                                    <div>
                                        <div className='approval-request'>{"This new request required your approval."}</div>
                                        <div className='radio-btn-transction-limit'>
                                            <div className='radio-input user-form-radio-input'>
                                                <div className='radio-btn-transction-limit'>
                                                    <RadioOrCheckbox
                                                        value={"Approved"}
                                                        type={"radio"}
                                                        name={"customLimits"}
                                                        label={"Approve"}
                                                        margin={"0px 30px 0px 0px"}
                                                        checkedValue={organisationCardData.status}
                                                        getVal={(val) => {
                                                            setIsFormDirty(true)
                                                            setOrganisationCardData((prevState) => ({
                                                                ...prevState,
                                                                status: val,
                                                                declineRemarks: ""
                                                            }));
                                                            setProcessName("approve")
                                                            setUserDataErrors({ declineRemarks: false })
                                                            dispatch(resetFormModified(true));
                                                        }}
                                                    />
                                                    <div>
                                                        <RadioOrCheckbox
                                                            value={"Declined"}
                                                            name={"customlimits"}
                                                            label={"Decline"}
                                                            type={"radio"}
                                                            checkedValue={organisationCardData.status}
                                                            getVal={(val) => {
                                                                setIsFormDirty(true)
                                                                setOrganisationCardData((prevState) => ({
                                                                    ...prevState,
                                                                    status: val,
                                                                }));
                                                                dispatch(resetFormModified(true));
                                                            }}

                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div style={
                                            organisationCardData.status === "Declined" ?
                                                { pointerEvents: "auto", opacity: 1 } : { pointerEvents: "none", opacity: 0.5 }
                                        }>
                                            <InputComponent
                                                height={"50px"}
                                                width={"700px"}
                                                margin={"0px 60px 30px 0px"}
                                                padding={"0px 0px 0px 10px"}
                                                border={userDataErrors.declineRemarks ? "1px solid red" : "1px solid #A9C3DC"}
                                                borderRadius={"0px"}
                                                backgroundColor={"white"}
                                                color={"black"}
                                                maxLength={250}
                                                type={"text"}
                                                disabled={organisationCardData.status === "Declined" ? false : true}
                                                inputTitle={"Reason for Decline"}
                                                required={true}
                                                placeHolder={"Reason"}
                                                inputValue={organisationCardData.declineRemarks}
                                                getUser={(value: any) => {
                                                    setIsFormDirty(true)
                                                    setOrganisationCardData((prevState) => ({
                                                        ...prevState,
                                                        declineRemarks: value,
                                                    }));
                                                    dispatch(resetFormModified(true));
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            }

                        </div>
                    </div>
                </div>
                <div className='footer-card'>
                    <ButtonComponent
                        title={"Cancel"}
                        height={"50px"}
                        width={"150px"}
                        margin={"0px 8px"}
                        backgroundColor={"#888888"}
                        color={"white"}
                        className={"button-component-hover cancel"}
                        handleClick={organisationBankDataCancel}
                    />
                    <ButtonComponent
                        title={
                            (isEditMode && componentNavigator === "form-page" || componentNavigator === "control-page") ? "Next" : "Save"
                        }
                        height={"50px"}
                        width={"150px"}
                        backgroundColor={"#0055D4"}
                        disabled={showMessage && showType === "success" ? true : false}
                        color={"white"}
                        margin={"0px 8px"}
                        className={showMessage && showType === "success" ? "button-component-hover disabled" : "button-component-hover common-btn"}
                        handleClick={async () => {

                            const isValid = validateForm();
                            if (componentNavigator === "approval-page" && organisationCardData.status === "Declined" && organisationCardData.declineRemarks === "") {

                                setShowMessage(Object.values(updatedIsValueValidAll).every((values) => !values) ? "Some of the field(s) are not in required format." : "Mandatory field(s) should not be left blank.")
                                setShowAlertBox(true)
                                setShowType("danger")
                                clearAleart("")

                                return;

                            } else if (componentNavigator === "approval-page" && organisationCardData.isActive === "No" && organisationCardData.remarks === "") {
                                setShowMessage(Object.values(updatedIsValueValidAll).every((values) => !values) ? "Some of the field(s) are not in required format." : "Mandatory field(s) should not be left blank.")
                                setShowAlertBox(true)
                                setShowType("danger")
                                clearAleart("")

                                return;
                            }
                            if (isValid) {
                                if (componentNavigator === "approval-page" && ((organisationCardData.status === "Approved") || (editData.isActive !== organisationCardData.isActive) && (isFormDirty === true))) {

                                    try {

                                        let res = await axiosPrivate.post('companyadmin/bank/otp-update/approval', {
                                            approvedBy: auth.token, pageName: "cardApproval",
                                            processName: processName, currentDataId: editData.cardId
                                        });
                                        if (res.status === 200) {
                                            setOtpVerification(true)
                                            setShowAlertBox(true)
                                            setShowType("success")
                                            setShowMessage(res.data.message)
                                            clearAleart("")
                                        }
                                    } catch (error: any) {
                                        setShowAlertBox(true);
                                        setShowType("danger")
                                        setShowMessage(error.response.data.error)
                                        clearAleart("")
                                    }
                                } else {
                                    componentNavigator === "form-page" ? goNext() : componentNavigator === "control-page" ? goNext() : organisationBankDataSave()
                                }

                            } else {

                                componentNavigator === "form-page" ? goNext() : componentNavigator === "control-page" ? goNext() : organisationBankDataSave()
                            }



                        }}

                    />


                </div>
            </MainPageCard >
            {
                showAlertBox &&
                <div className='alert-warp'>
                    <AlertBox type={showCardNumberMessage == "success" || showCardNumberMessage == "danger" ? showCardNumberMessage : showType} message={showMessage} />
                </div>

            }
            {
                isLoading &&
                <Loading />
            }
            {
                showChangesModal &&
                <Changesmodal
                    closeModal={() => setShowChangesModal(false)}
                    leavePage={() => {
                        setShowChangesModal(false);
                        setIsFormDirty(false)
                        userNavigateFunction("grid")
                        getEditMode(false)
                    }}
                    handleClose={() => setShowChangesModal(false)} />
            }
            {addCardCategory &&
                <AddCardCategory
                    organisationCardData={
                        organisationCardData
                    }
                    clearValue={() => {
                        setAddCardCategory(false)
                    }}
                    getDepartment={() => {
                        fetchData(`/companyadmin/organisation/card-category`, setCardCategory, "CardCategoryDetails");
                    }}
                    setCardCategory={setCardCategory}
                    cardCategory={cardCategory}
                />

            }
            {addExpense &&
                <AddExpenseValue
                    organisationCardData={
                        organisationCardData
                    }
                    transactionMapping={transactionMapping}
                    setTransactionMappingData={() => {

                    }}
                    clearValue={() => {
                        setAddExpense(false)
                    }}
                    getExpenseType={() => {
                        fetchExpenseData(`/companyadmin/organisation/card-expense`, setExpenseValue, "CardExpenseDetails")
                    }}
                    setExpenseValue={setExpenseValue}
                    setExpenseValueGrid={() => {

                    }}
                    expenseValue={expenseValue}
                />

            }
            {
                otpVerification &&
                <div className='otp-model'>
                    <OtpVerifyModal
                        otpVerify={resendOtp}
                        leavePage={() => {
                            setOtpVerification(false)
                            userNavigateFunction("form")
                        }
                        }
                        isLocked={isLocked}
                        setIsLocked={setIsLocked}
                        getStatus={async (stringFromArray: any) => {
                            let res;
                            try {

                                res = await axiosPrivate.post('companyadmin/bank/verify-otp/update', {
                                    otp: stringFromArray,
                                    approvedBy: auth.token,

                                });
                                if (res.status === 200) {
                                    if (componentNavigator === "form-page") {
                                        ShowCardNumber()
                                        setOtpVerification(false)
                                    } else {
                                        organisationBankDataSave()
                                    }
                                    setIsLocked(true)
                                }
                            } catch (error: any) {
                                if (componentNavigator === "form-page") {
                                    setShowCardNumberMessage("danger")
                                } else {
                                    setShowType("danger")
                                }
                                setShowAlertBox(true);
                                setShowMessage(error.response.data.error)
                                clearAleart("")
                            }

                            // organisationBankDataSave()
                            // userNavigateFunction("grid")
                        }
                        }
                    />
                </div>
            }

        </>
    )
}

export default Form;