import React, { useState } from 'react';
import '../../styles/login-error/login-error.scss'
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import LoginErrorImage from '../../assets/images/login-error.jpg';


export default function AccountError() {
    const [isError, setIsError] = useState<boolean>(true)
    const location = useLocation();

    const navigate = useNavigate();

    return (
        <div className='MailExpire-container'>
            <div className='login-logo'>
                <div className='fingertip-logo'></div>
            </div>
            <div>
                <img src={LoginErrorImage} alt="" className='mail-expire-image' />
            </div>
            <div className={isError ? 'error-content' : 'success-content'} dangerouslySetInnerHTML={{ __html: location.state.message }} />

            <div className='resend-email' onClick={() => navigate("/login")}>
                Back To Login
            </div>
        </div >
    )
}
