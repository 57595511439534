import { useEffect, useRef, useState } from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
 import { Bar, getElementAtEvent } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface propsType {
  width: string;
  height: string;
  options: any;
  data: any;
  dataAvailable?: any;
  barLabel?: any;
  barData?: any;
  orginalBarchartData?: any;
  clickedEvent?: any;
  HandleBarChartClcik?: (barValue:string,label:string)=>void;
}

function VerticalBarChart({ width, height, options, data,HandleBarChartClcik, orginalBarchartData ,dataAvailable,barLabel,barData,clickedEvent}: propsType) {  
  const chartRef:any = useRef();
  const [chartData, setChartData] = useState<any>({});
  const [clickedEventStatus, setClickedEventStatus] = useState<any>(true);
  const billGenerationDateData = {
    labels: barLabel,
    datasets: [{
        barPercentage: 1.1,
        barThickness: 30,
        data: barData,
        backgroundColor: ["#0C7B00", "#02548F", "#C2498F", "#009A9A", "#0C7B00"],
        borderColor: ["#0C7B00", "#02548F", "#C2498F", "#009A9A", "#0C7B00"],
        borderWidth: 5
    }]
};

  useEffect(() => {   
    setChartData(data)
  }, [data])

  useEffect(()=>{
    if(dataAvailable){
      setChartData(billGenerationDateData)
    }
  },[barLabel,barData])
  useEffect(()=>{
if(clickedEvent !== undefined){
  setClickedEventStatus(false)
}
  },[])
 
  const onClick = (event: any) => {
    if (chartRef.current && getElementAtEvent(chartRef.current, event)[0] && clickedEventStatus) {
      const selectedIndex:any =  getElementAtEvent(chartRef.current, event)[0].index;
      const selectedDatasetIndex:any = getElementAtEvent(chartRef.current, event)[0].datasetIndex;     
      const selectBarValue = orginalBarchartData.datasets[selectedDatasetIndex].label
      const selectLabel = orginalBarchartData.labels[selectedIndex]

      HandleBarChartClcik && HandleBarChartClcik(selectBarValue,selectLabel)
    }
  };
  
  return (
    <div className='vertical-bar-chart-container' style={{ width: width, height: height }}>
      {
        Object.keys(chartData).length > 0 &&
        <Bar options={options} data={chartData} ref={chartRef} onClick={onClick} />
      }
    </div>
  );
}

export default VerticalBarChart;

// const selectDataSetValue = data.datasets[selectedDatasetIndex].data[selectedIndex]
// useEffect(() => {
  //   const generateColor = () => {
  //     return '#' + Math.random().toString(16).substr(-6);
  //   };
  //   const colors = [];
  //   const number = [];
  //   for (let i = 0; i < Object.keys(data.labels).length; i += 1) {
  //     colors.push({ hexCode: generateColor() });
  //     number.push(Math.floor(Math.random() * 100))
  //   }  

  //   setChartData({
  //     ...chartData,
  //     datasets: [
  //      ...chartData.datasets,
  //       {
  //       ...chartData.datasets[0],
  //       data: number,
  //       backgroundColor: colors.map((elem) => (elem.hexCode)),
  //       borderColor: colors.map((elem) => (elem.hexCode)),
  //     }]
  //   })
  // }, [data])