

import React, { useEffect, useState } from 'react'
import "../../styles/view-table.scss";
import IconButton from '../../components/common-component/form-elements/icon-button';
import DeleteModal from '../../components/common-component/modals/delete-modal';
import "../../styles/onboarding/company/grid-icon.scss"



interface Props {
    subscriptionMain: any;
    retriveData: (value: any, index: number) => void;

}
function SubscriptionValidityGrid({ subscriptionMain, retriveData, }: Props) {
    const [showDeleteModel, setShowDeleteModel] = useState<boolean>(false)
    console.log(subscriptionMain)
    function formatDate(isoDateString: any) {
        const date = new Date(isoDateString);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    }
    return (
        <>
            <div className='subscriptionTable' style={{ marginTop: "40px", marginBottom: "40px" }}>
                <div className='headings'>
                    <div className='headingTitle' style={{ paddingLeft: "20px" }}>Subscription</div>
                    <div className='headingTitle' style={{ textAlign: "center" }}>Valid From</div>
                    <div className='headingTitle' style={{ textAlign: "center" }}>Valid To</div>
                    <div className='headingTitle' style={{ paddingLeft: "5.3%" }}>Is Active</div>
                    <div className='headingTitle' style={{ paddingLeft: "12%" }}>Action</div>
                </div>
                <div className='bodyContents'>
                    {
                        !subscriptionMain || subscriptionMain?.length === 0 ?
                            <div>
                                <div className='style-grid'>No records to show</div>
                            </div>
                            :
                            subscriptionMain?.map((data: any, index: number) => (
                                <div className='tableDatas'>
                                    <div className='middle-icons' style={{ width: "225px", paddingLeft: "20px" }}>{data.Subscription}</div>
                                    <div className='middle-icons' style={{ textAlign: "center" }}>{formatDate(data.from)}</div>
                                    <div className='middle-icons' style={{ textAlign: "center" }}>{formatDate(data.to)}</div>
                                    <div className='middle-icons' style={{ paddingLeft: "6%" }}>{data.isActive}</div>
                                    <div className='last-icon flexcls' style={{ paddingLeft: "12%" }}>
                                        {/* <IconButton
                                            iconName={"Edit"}
                                            height={"40px"}
                                            width={"40px"}
                                            fontSize={"30px"}
                                            margin={"0px 8px"}
                                            color={"white"}
                                            backgroundColor={"#0055D4"}
                                            hover={true}
                                            handleClick={() => {
                                                retriveData(data, index)
                                            }}
                                        /> */}
                                        <div style={{ opacity: 0.4, pointerEvents: "none" }}>
                                            <IconButton
                                                // className={}
                                                iconName={"Delete"}
                                                height={"40px"}
                                                width={"40px"}
                                                disabled={true}
                                                fontSize={"25px"}
                                                margin={"0px 8px"}
                                                color={"white"}
                                                backgroundColor={"#D60000"}
                                                hover={false}
                                                handleClick={() => {
                                                    // setShowDeleteModel(true)
                                                }}
                                            />
                                        </div>

                                    </div>
                                </div>
                            ))
                    }
                </div>
            </div>
            {
                showDeleteModel &&
                <div >
                    <DeleteModal
                        getDelete={() => { }}
                        clearValue={(value) => { setShowDeleteModel(value) }}
                        modelType={"grid-delete"}
                    />
                </div>
            }
        </>
    )
}

export default SubscriptionValidityGrid;
// 