import React from 'react'
import SideMainNavbar from './side-navbar/side-main-navbar'
import Header from './top-header/header'
import "../styles/layouts/layout.scss"

interface LayoutProps {
  children?: React.ReactNode;
  setActiveTitleMyaccount: (val: string) => void;
  setDocumentationNav: (val: string) => void;
  documentationNav: string;
}

function Layout({ children, setActiveTitleMyaccount, documentationNav, setDocumentationNav }: LayoutProps) {
  return (
    <div className='layout-component' style={documentationNav === "Documentation" ? { display: "unset" } : {}}>
      {
        documentationNav !== "Documentation" ?
          <div className='sidebar'><SideMainNavbar /></div>
          :
          ""
      }
      <div className={documentationNav === "Documentation" ? "header documentation-header-doc" : "header"}><Header documentationNav={documentationNav} setDocumentationNav={setDocumentationNav} setActiveTitleMyaccount={setActiveTitleMyaccount} /></div>
      <div className='layout-center' style={documentationNav === "Documentation" ? { marginLeft: "0", marginTop: "0" } : {}}>{children}</div>
    </div>
  )
}

export default Layout;