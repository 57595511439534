import React, { useState, useEffect, useRef } from 'react'
import NavigateHeader from '../../../components/common-component/navigate-header'
import '../../../styles/onboarding/company/company-form.scss'
import { BsFillCheckCircleFill } from 'react-icons/bs';
import CompanyOverview from './company-form-lists/company-overview'
import Address, { ChildRef } from './company-form-lists/address'
import AdminDetails from './company-form-lists/admin-details'
import DirectorsInfo from './company-form-lists/directors-info'
import StatutoryDetails from './company-form-lists/statutory-details'
import BankRelationshipManager from './company-form-lists/bank-relationship-manager'
import PointOfContact from './company-form-lists/point-of-contact'
import SubscriptionValidity from './company-form-lists/subscription-validity'
import Privilege from './company-form-lists/privilege'
import ButtonComponent from '../../common-component/form-elements/button-component';
import { BsBuildingExclamation } from 'react-icons/bs';
import { BsPersonFillGear } from 'react-icons/bs';
import { BsBank2 } from 'react-icons/bs';
import { BsFillPersonLinesFill } from 'react-icons/bs';
import { BsFileEarmarkCheckFill } from 'react-icons/bs';
import { CgTimelapse } from 'react-icons/cg';
import { IoMdContacts } from 'react-icons/io';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapLocationDot } from '@fortawesome/free-solid-svg-icons';
import { LuBarChart3 } from "react-icons/lu";

//svg
import { ReactComponent as AddressImage } from '../../../src/assets/icons/address.svg'
import { ReactComponent as exciseImage } from '../../../src/assets/icons/address.svg'
import { ReactComponent as CompanyOverviewImg } from '../../../src/assets/icons/company-overview.svg'
import { ReactComponent as DirectorsImg } from '../../../src/assets/icons/director-info.svg'
import { ReactComponent as StatutoryDetailsImg } from '../../../src/assets/icons/statutory-details.svg'
import { ReactComponent as BankRelationshipImg } from '../../../../src/assets/icons/bank-relationship.svg'
import { ReactComponent as AdminDetailsImg } from '../../../src/assets/icons/admin-details.svg'
import { ReactComponent as Approval } from '../../../../src/assets/icons/approvalcompany.svg'
import { ReactComponent as SubscriptionValidityImg } from '../../../../src/assets/icons/subscription-validity.svg'
import { ReactComponent as Notworking } from '../../../../src/assets/icons/not-work.svg'
import { ReactComponent as Progress } from '../../../../src/assets/icons/in-progerss.svg'
import { ReactComponent as Correct } from '../../../../src/assets/icons/correct.svg'
import { ReactComponent as CompanyMid } from '../../../../src/assets/icons/company_mid_creation.svg'
import Loading from '../../common-component/modals/loading-screen';
import AlertBox from '../../common-component/alert-box';
import { useHref } from 'react-router-dom';
import Changesmodal from '../../common-component/modals/changes-modal';
import { useDispatch, useSelector } from "react-redux";
import { resetFormModified } from "../../../redux/action";
import useAxiosPrivate from '../../../services/hooks/useaxios-private';
import ApprovalProcess from './company-form-lists/approval-process';
import VendorBankApproval from './company-form-lists/privilege';
import ExciseDetails from './company-form-lists/excise-details';
import CompanyMIDCreation from '../../onboarding/company-form-lists/mid-creation';

interface Props {
  onboardingViewfun: (val: any) => void;
  getEditData: (data: any) => void;
  editData: any;
  isEditMode: boolean
}

export default function CompanyForm({ onboardingViewfun, getEditData, editData, isEditMode }: Props) {

  const [showChangesModalcancel, setShowChangesModalcancel] = useState(false);
  const [showChangesModalEdit, setShowChangesModalEdit] = useState(false);
  const [isFormDirty, setIsFormDirty] = useState(false);

  const [showPage, setShowPage] = useState("CompanyOverview");
  const [setNextNav, setSetNextNav] = useState("");

  const [mainApiData, setMainApiData] = useState<any>({
    companyData: {}
  });
  const [showType, setShowType] = useState("warning") // error message showType
  const [showMessage, setShowMessage] = useState<string>("") // error message showMessage
  const [showAlertBox, setShowAlertBox] = useState(false) // error message ShowAlertBox

  const [directorApiData, setDirectorApiData] = useState<any>([]);

  const [statutoryDetailsMain, setStatutoryDetailsMain] = useState<any>([]);
  const [excsieDetailsMain, setExcsieDetailsMain] = useState<any>([]);
  const [bankRelationshipMain, setBankRelationshipMain] = useState<any>([]);
  const [adminDetailsMain, setAdminDetailsMain] = useState<any>([]);
  const [businessContactMain, setBusinessContactMain] = useState<any>([]);
  const [subscriptionMain, setSubscriptionMain] = useState<any>([]);
  const [approvalDataMain, setApprovalDataMain] = useState<any>([]);

  const [companyOverviewPan, setCompanyOverviewPan] = useState<any>();
  const [chooseType, setChooseType] = useState<any>("PAN");
  const [directorInfoPan, setDirectorInfoPan] = useState<any>();
  const [singleDirectorInfoPan, setSingleDirectorInfoPan] = useState<any>({});
  const [singleDirectorAddressInfoPan, setSingleDirectorAddressInfoPan] = useState<any>({});
  const [loadingScreen, setLoadingScreen] = useState<boolean>(false);
  const sideStatusRef = useRef<ChildRef>(null);
  const isFormModified = useSelector((state: any) => state.isFormModified);
  const axiosPrivate = useAxiosPrivate();
  const dispatch = useDispatch();

  const [iconStatus, setIconStatus] = useState<any>({
    companyIcon: "selected",
    addressIcon: "notworking",
    directorIcon: "notworking",
    statutoryIcon: "notworking",
    relationshipIcon: "notworking",
    adminIcon: "notworking",
    vendorBankApprovalIcon: "notworking",
    businessIcon: "notworking",
    subscriptionIcon: "notworking",
    approvalIcon: "notworking",
    ExciseDetailsIcon: "notworking",
    companyMidIcon: "notworking",
  });



  useEffect(() => {
    if (!isEditMode) return;
    setStatutoryDetailsMain(editData.statutory)
    setAdminDetailsMain(editData.adminDetails)
    setBankRelationshipMain(editData.accountManager)
    setApprovalDataMain(editData.companyApproval)
    setSubscriptionMain(editData.subscription)
    setDirectorApiData(editData.directors)
    setExcsieDetailsMain(editData.exciseDetails)
    setMainApiData(editData.CompanyData)
    setIconStatus({
      companyIcon: "completed",
      addressIcon: "completed",
      directorIcon: "completed",
      statutoryIcon: "completed",
      relationshipIcon: bankRelationshipMain?.length > 0 ? "completed" : "selected",
      adminIcon: "completed",
      vendorBankApprovalIcon: "completed",
      businessIcon: "completed",
      subscriptionIcon: subscriptionMain?.length > 0 ? "completed" : "selected",
      approvalIcon: approvalDataMain?.length > 0 ? "completed" : "selected",
      ExciseDetailsIcon: excsieDetailsMain?.length > 0 ? "completed" : "selected",
      companyMidIcon: "completed",
    });
  }, [editData]);


  async function fetchDataFunction(companyPan: any, chooseType: any) {
    setLoadingScreen(true)
    try {
      const res = await axiosPrivate.post("company-info", { CompanyValue: companyPan, ChooseType: chooseType }).then((res) => {

        if (res.status === 200) {
          setLoadingScreen(false)
          setMainApiData({
            ...mainApiData,
            companyData: res.data.results.companyData,
          })
          setBankRelationshipMain(res.data.results.accountManager)
          setAdminDetailsMain(res.data.results.adminDetails)
          // setBusinessContactMain(res.data.results.otherBusiness)
          setSubscriptionMain(res.data.results.subscription)
          setDirectorApiData(res.data.results.directorData)
          setStatutoryDetailsMain(res.data.results.gstinData)
          setApprovalDataMain(res.data.results.companyApproval)
          setShowAlertBox(true);
          setShowType("success");
          setShowMessage("Company record has been fetched and filled successfully.");
          clearAlert('');
          // setIsFormDirty(false)
          // dispatch(resetFormModified(false));
        }
      });
    } catch (error: any) {
      setLoadingScreen(false)
      setShowAlertBox(true);
      setShowType("danger");
      setShowMessage(error.response.data.error);
      clearAlert('');
    }
  }

  async function fetchDirectorDataFunction(directorPan: any) {
    setLoadingScreen(true)
    try {

      const res = await axiosPrivate.post("pan-card/details", { "pan": directorPan }).then((res) => {
        if (res.status === 200) {
          setLoadingScreen(false)
          setSingleDirectorInfoPan({
            "panData": {

              dateOfBirth: res.data.panData.dateOfBirth,
              gender: res.data.panData.gender,
              email: res.data.panData.email.toLowerCase().replace(/(\d{4})(?=\d)/g, "$1 ").trim(),
              contactNumber: res.data.panData.contactNumber,
              maskedAadhaarNumber: res.data.panData.maskedAadhaarNumber,
              country: res.data.panData.country,
              street: res.data.panData.street,
              state: res.data.panData.state,
              city: res.data.panData.city,
              pincode: res.data.panData.pincode,
              address: res.data.panData.address,
              DirectorName: res.data.panData.name,
              directorPan: res.data.panData.directorPan,
            },
            "address": {
              city: res.data.address.city,
              state: res.data.address.state,
              pincode: res.data.address.pincode
            }
          })
          setShowAlertBox(true);
          setShowType("success");
          setShowMessage("PAN Data has been fetched and filled successfully.");
          clearAlert('');
        }

      })
    } catch (error) {
      setLoadingScreen(false)
      setShowAlertBox(true);
      setShowType("danger");
      setShowMessage("Could not fetch PAN data. Enter values manually to complete this operation.");
      clearAlert('');
    }
  }

  async function getUserData() {
    await axiosPrivate.get(`/companyadmin/company-approval`)
      .then(response => {
        getEditData(response.data.results);
      })
      .catch(error => {
        console.error(error);
      });
  }


  async function sendDataApi() {
    const address = {
      "Pincode": mainApiData.companyData?.CompanyRegPinCode,
      "country": mainApiData.companyData?.Country,
      "CompanyRegState": mainApiData.companyData?.CompanyRegState,
      "CompanyRocCity": mainApiData.companyData?.CompanyRocCity,
      "CompanyRegCity": mainApiData.companyData?.CompanyRegCity,
      "CompanyMcaStatus": mainApiData.companyData?.CompanyMcaStatus,
      "CompanyEmail": mainApiData.companyData?.CompanyEmail,
      "CompanyWebsite": mainApiData.companyData?.CompanyWebsite,
      "CompanyFullAddress": mainApiData.companyData?.CompanyFullAddress,
      "ContactNumber": mainApiData.companyData?.ContactNumber,
      "street_1": mainApiData.companyData?.street_1,
      "street_2": mainApiData.companyData?.street_2,
      "ContactPersonName": mainApiData.companyData?.ContactPersonName,
    }
    const finalData = {
      "ChooseType": chooseType,
      "CompanyValue": mainApiData.CompanyValue,
      "companyData": { ...mainApiData.companyData },
      "address": { ...address },
      "directors": [...directorApiData],
      "excise": [...excsieDetailsMain],
      "statutory": [...statutoryDetailsMain],
      "accountManager": [...bankRelationshipMain],
      "adminDetails": [...adminDetailsMain],
      // "otherBusiness":Array.isArray(businessContactMain) ? [...businessContactMain]:[],
      "subscription": [...subscriptionMain],
      "companyApproval": [...approvalDataMain]
    }
    setLoadingScreen(true)
    try {
      const res = isEditMode ? await axiosPrivate.patch(`companyadmin/company-approval/${editData?.CompanyData?.companyData?.id}`, finalData) : await axiosPrivate.post("bank-admin/company", finalData)
      if (res.status === 200) {
        setLoadingScreen(false)
        setShowAlertBox(true);
        setShowType("success");
        setShowMessage(res.data.message);
        clearAlert(res.status);
        dispatch(resetFormModified(false));
        getUserData();
        setIconStatus({
          companyIcon: "completed",
          addressIcon: "completed",
          directorIcon: "completed",
          statutoryIcon: "completed",
          relationshipIcon: bankRelationshipMain?.length > 0 ? "completed" : "selected",
          adminIcon: "completed",
          vendorBankApprovalIcon: "completed",
          businessIcon: "completed",
          subscriptionIcon: subscriptionMain?.length > 0 ? "completed" : "selected",
          approvalIcon: approvalDataMain?.length > 0 ? "completed" : "selected",
          ExciseDetailsIcon: excsieDetailsMain?.length > 0 ? "completed" : "selected",
        });
      }
    }
    catch (error: any) {
      setLoadingScreen(false)
      setShowAlertBox(true);
      setShowType("danger");
      setShowMessage(error.response.data.message);
      clearAlert('');
    }
  }


  const clearAlert = (status: any) => {
    const timer = setTimeout(() => {
      setShowAlertBox(false);
      setShowMessage("");
      if (status === 200) {
        // (!isEditMode || showPage === "AdminDetails") && onboardingViewfun("grid");
      }
      clearTimeout(timer);
    }, 5000);
  }

  const handleSideBar = (next: any) => {
    sideStatusRef.current!.next(next);
  }

  const handleStepClick = (step: any) => () => {
    if (iconStatus[step.icon] === "notworking") return;
    if (!isEditMode && iconStatus[step.icon] !== "completed") {
      if (isFormModified && showPage !== step.page) {
        if (!isEditMode) {
          setShowChangesModalEdit(true);
          setSetNextNav(step.page);
        }
      } else {
        // handleSideBar(step.page);
        setShowPage(step.page);
      }
    } else {
      if (isFormModified && showPage !== step.page) {
        setShowChangesModalEdit(true);
        setSetNextNav(step.page);
      } else {
        setShowPage(step.page);
      }
    }
  };

  const navPageDestination = () => {
    setShowPage(setNextNav);
    setShowChangesModalEdit(false);
    dispatch(resetFormModified(false));
    setSetNextNav('');
    getUserData()
  };
  const steps = [
    { image: <BsBuildingExclamation style={{ color: "#0055d4", fontSize: "25px", marginTop: "3px" }} />, heading: "Company Overview", page: "CompanyOverview", icon: "companyIcon", isManditary: true },
    { image: <FontAwesomeIcon icon={faMapLocationDot} style={{ color: "#0055d4", fontSize: "25px", marginTop: "3px" }} />, heading: "Address", page: "Address", icon: "addressIcon", isManditary: true },
    { image: <BsFillPersonLinesFill style={{ color: "#0055d4", fontSize: "25px", marginTop: "3px" }} />, heading: "Directors Info", page: "DirectorsInfo", icon: "directorIcon", isManditary: true },
    { image: <BsFileEarmarkCheckFill style={{ color: "#0055d4", fontSize: "25px", marginTop: "3px" }} />, heading: "Statutory Details", page: "StatutoryDetails", icon: "statutoryIcon", isManditary: true },
    { image: <LuBarChart3 style={{ color: "#0055d4", fontSize: "25px", marginTop: "3px" }} />, heading: "Excise Details", page: "ExciseDetails", icon: "ExciseDetailsIcon" },
    { image: <IoMdContacts style={{ color: "#0055d4", fontSize: "28px", marginTop: "3px" }} />, heading: "Account's Manager", page: "BankRelationshipManager", icon: "relationshipIcon" },
    { image: <BsPersonFillGear style={{ color: "#0055d4", fontSize: "27px", marginTop: "3px" }} />, heading: "Admin Details", page: "AdminDetails", icon: "adminIcon", isManditary: true },
    { image: <CgTimelapse style={{ color: "#0055d4", fontSize: "27px", marginTop: "5px" }} />, heading: "Subscription Validity", page: "SubscriptionValidity", icon: "subscriptionIcon" },
    { image: <Approval style={{ color: "#0055d4", width: "28px", height: "28px", marginTop: "4px" }} />, heading: "Approval Process", page: "ApprovalProcess", icon: "approvalIcon" },
    { image: <CompanyMid style={{ color: "#0055d4", margin: "0px -5px 0px 0px" }} />, heading: "MID Creation", page: "CompanyMidCreation", icon: "companyMidIcon", isManditary: false },
  ];


  return (
    <div className='company-form'>
      <div className='navigation-header' style={{ height: "10vh", }}>
        <div className='left-header'>
          <NavigateHeader firstValue={"Organisation"}
            secondValue={"Company"}
          />
        </div>
      </div>
      <div className='form-contents' style={{ height: "63vh", width: '100%', }}>
        <div style={{ width: '300px', height: '63vh' }}>
          <div className='side-navigation-main-company'>
            <div className='position-stepper-list'>
              {steps.map((step, index) => (
                <div className='stepper-main' style={{ height: "58px" }} key={index}>
                  <div className='flex-image-text' onClick={handleStepClick(step)}>
                    <div>{step.image}</div>
                    <div className='heading-icon'>
                      <div className={`stepper-heading ${showPage === step.page ? "active" : ""}`}>
                        {step.heading}
                        {step.isManditary &&
                          <span style={{ color: "red" }}>*</span>
                        }
                      </div>
                    </div>
                  </div>
                  <div className='stepper-icon'>
                    {iconStatus[step.icon] === "notworking" && <Notworking className='icon-tick' />}
                    {iconStatus[step.icon] === "selected" && <Progress className='icon-tick' />}
                    {iconStatus[step.icon] === "completed" && <Correct className='icon-tick' />}
                  </div>
                  {index !== steps.length - 1 &&
                    <div className='stepper-line' style={{ top: "42px" }}></div>
                  }
                </div>
              ))}
            </div>
          </div>
        </div>
        {
          showPage === "CompanyOverview" &&
          <CompanyOverview
            setIsFormDirty={setIsFormDirty}
            ref={sideStatusRef}
            mainApiData={mainApiData}
            setMainApiData={setMainApiData}
            setShowPage={setShowPage}
            showPage={showPage}
            iconStatus={iconStatus}
            setIconStatus={setIconStatus}
            fetchDatas={(value) => {
              fetchDataFunction(companyOverviewPan, chooseType)
            }}
            sendDataApi={sendDataApi}
            onboardingViewfun={onboardingViewfun}
            companyOverviewPan={companyOverviewPan}
            setCompanyOverviewPan={setCompanyOverviewPan}
            setChooseType={setChooseType}
            chooseType={chooseType}
            isEditMode={isEditMode}
            editData={editData}
          />
        }
        {
          showPage === "Address" &&
          <Address
            ref={sideStatusRef}
            mainApiData={mainApiData}
            setMainApiData={setMainApiData}
            onboardingViewfun={onboardingViewfun}
            sendDataApi={sendDataApi}
            setShowPage={setShowPage}
            iconStatus={iconStatus}
            editData={editData}
            setIconStatus={setIconStatus}
            isEditMode={isEditMode}
          />
        }
        {
          showPage === "DirectorsInfo" &&
          <DirectorsInfo
            ref={sideStatusRef}
            mainApiData={mainApiData}

            setShowPage={setShowPage}
            iconStatus={iconStatus}
            setIconStatus={setIconStatus}
            onboardingViewfun={onboardingViewfun}
            directorApiData={directorApiData}
            editData={editData}
            fetchDirectorPan={(value) => {
              fetchDirectorDataFunction(value)
            }}
            setDirectorApiData={(val: any) => {
              setDirectorApiData(val)
            }}
            sendDataApi={sendDataApi}
            setDirectorInfoPan={(val: string) => {
              setDirectorInfoPan(val)
            }}
            directorInfoPan={directorInfoPan}
            singleDirectorInfoPan={singleDirectorInfoPan}
            setSingleDirectorInfoPan={setSingleDirectorInfoPan}
            singleDirectorAddressInfoPan={singleDirectorAddressInfoPan}
            isEditMode={isEditMode}
          />
        }
        {
          showPage === "StatutoryDetails" &&
          <StatutoryDetails
            ref={sideStatusRef}
            sendDataApi={sendDataApi}
            mainApiData={mainApiData}

            editData={editData}
            setShowPage={setShowPage}
            iconStatus={iconStatus}
            setIconStatus={setIconStatus}
            onboardingViewfun={onboardingViewfun}
            statutoryDetailsMain={statutoryDetailsMain}
            setStatutoryDetailsMain={setStatutoryDetailsMain}
            isEditMode={isEditMode}
          />
        }
        {
          showPage === "ExciseDetails" &&
          <ExciseDetails
            ref={sideStatusRef}
            sendDataApi={sendDataApi}
            mainApiData={mainApiData}

            editData={editData}
            setShowPage={setShowPage}
            iconStatus={iconStatus}
            setIconStatus={setIconStatus}
            onboardingViewfun={onboardingViewfun}
            excsieDetailsMain={excsieDetailsMain}
            setExcsieDetailsMain={setExcsieDetailsMain}
            isEditMode={isEditMode}
          />
        }
        {
          showPage === "BankRelationshipManager" &&
          <BankRelationshipManager
            // ref={sideStatusRef}
            bankRelationshipMain={bankRelationshipMain}
            setBankRelationshipMain={setBankRelationshipMain}
            sendDataApi={sendDataApi}
            editData={editData}
            setShowPage={setShowPage}
            iconStatus={iconStatus}
            setIconStatus={setIconStatus}
            onboardingViewfun={onboardingViewfun}
            isEditMode={isEditMode}
          />
        }
        {
          showPage === "AdminDetails" &&
          <AdminDetails
            ref={sideStatusRef}
            sendDataApi={sendDataApi}
            editData={editData}
            setShowPage={setShowPage}
            iconStatus={iconStatus}
            setIconStatus={setIconStatus}
            onboardingViewfun={onboardingViewfun}
            setAdminDetailsMain={setAdminDetailsMain}
            adminDetailsMain={adminDetailsMain}
            isEditMode={isEditMode}
          />
        }
        {/* {
          showPage === "VendorBankApproval" &&
          <VendorBankApproval
            onboardingViewfun={onboardingViewfun}
            isEditMode={isEditMode}
            sendDataApi={sendDataApi}
            setShowPage={setShowPage}
            iconStatus={iconStatus}
            setIconStatus={setIconStatus}
            mainApiData={mainApiData}

          />
        } */}
        {
          showPage === "SubscriptionValidity" &&
          <SubscriptionValidity
            editData={editData}
            setShowPage={setShowPage}
            setSubscriptionMain={setSubscriptionMain}
            subscriptionMain={subscriptionMain}
            onboardingViewfun={onboardingViewfun}
            sendDataApi={sendDataApi}
            isEditMode={isEditMode}
            iconStatus={iconStatus}
            setIconStatus={setIconStatus}
          />
        }
        {
          showPage === "ApprovalProcess" &&
          <ApprovalProcess
            approvalDataMain={approvalDataMain}
            setApprovalDataMain={setApprovalDataMain}
            sendDataApi={sendDataApi}
            editData={editData}
            setShowPage={setShowPage}
            iconStatus={iconStatus}
            setIconStatus={setIconStatus}
            onboardingViewfun={onboardingViewfun}
            isEditMode={isEditMode}
          />
        }
         {
          showPage === "CompanyMidCreation" &&
          <CompanyMIDCreation
            mainApiData={mainApiData}
            adminDetailsMain={adminDetailsMain}
            statutoryDetailsMain={statutoryDetailsMain}
          />
        }
      </div>
      {
        loadingScreen &&
        <Loading />
      }
      {
        showAlertBox &&
        <div className='alert-warp'>
          <AlertBox type={showType} message={showMessage} />
        </div>

      }
      {
        showChangesModalcancel &&
        <Changesmodal
          closeModal={() => setShowChangesModalcancel(false)}
          leavePage={() => {
            setShowChangesModalcancel(false);
            dispatch(resetFormModified(false));
            setIsFormDirty(false)
            onboardingViewfun("grid")
            // getEditMode(false)
          }}
          handleClose={() => setShowChangesModalcancel(false)} />
      }
      {
        showChangesModalEdit &&
        <Changesmodal
          closeModal={() => setShowChangesModalEdit(false)}
          leavePage={navPageDestination}
          handleClose={() => setShowChangesModalEdit(false)} />
      }
    </div>
  )
}


