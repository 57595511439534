import React, { useEffect, useState } from 'react'
import NavigateHeader from '../../common-component/navigate-header'
import MainPageCard from '../../common-component/cards/main-page-card'
import "../../../styles/payable/vendor/group-pay-vendor.scss"
import SelectPaymentMethod from './select-payment-method'
import { cardDetails } from './vendor-form';
import { axiosPrivate } from '../../../middleware/axois-api';
import Loading from '../../common-component/modals/loading-screen';
import AlertBox from '../../common-component/alert-box';
import AgGrid from '../../common-component/grids-and-tables/ag-grid';
import { DateConverterAgGrid } from '../../../helpers/capitalizetext';
import { AmountRenderer, formatAmount } from '../../../helpers/amountRenderer';
import CustomEllipsisRenderer from '../../../config/dashboard/customers-vs-transaction-value-config';
import ButtonComponent from '../../common-component/form-elements/button-component'
import PaymentResultModal from './payment-result-popup'
import UpiwaitingModal from '../../common-component/modals/upi-waiting-modal'
import RupayOtpModal from '../../common-component/modals/rupay-otp-modal'

interface props {
  vendorVewFun: (val: any) => void
  groupInvoices: any
  currentActor: string

}

export default function GroupPay({ vendorVewFun, groupInvoices, currentActor }: props) {
  const [activeTab, SetActiveTab] = useState<number>(1); //setcurrent tab
  const [cards, setCards] = useState<cardDetails[]>([]); //store company cards
  const [loadingScreen, setLoadingScreen] = useState<boolean>(false) //Loading Screen
  const [showMessage, setShowMessage] = useState<string>("") //AlertBox Message State
  const [showType, setShowType] = useState<string>("warning") //AlertBox warning Statef
  const [status, setStatus] = useState<string>("")
  const [showAlertBox, setShowAlertBox] = useState<boolean>(false) //AlertBox open State
  const [messagePopUp, setMessagePopUp] = useState<boolean>(false);
  const [openRupayOtpModal, setOpenRupayOtpModal] = useState<boolean>(false)
  const [openUpiWaitingModal, setOpenUpiWaitingModal] = useState<boolean>(false)
  const [transactionCharges, setTransactionCharges] = useState<any>();
  const [total, setTotal] = useState<any>();
  const [Banks, setBanks] = useState<any[]>([]);
  const [selectedPaymentMethod, SetSelectedPaymentMethod] = useState({
    PaymentMethod: "Corporate Card",
    virtualcardId: "",
    cardId: "",
    bankCode: "",
    bankName: "",
    UpiCode: ""
  }); // store PaymentMethod & catd id 
  const [rupaytranctionDetails, setRupaytranctionDetails] = useState<any>({});
  const [upiTranctionDetails, setUpiTranctionDetails] = useState<any>({});

  const [selectedCard, setselectedCard] = useState<any>({});
  const [Upis, setUpis] = useState<any[]>([]);
  const vendorColumnData = [
    {
      field: 'vendorName', headerName: 'Vendor Name', minWidth: 300, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer,
    },
    {
      field: 'invoiceNumber', headerName: 'Invoice Number', minWidth: 180, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer,
    },
    {
      field: 'invoiceDate', headerName: 'Invoice Date', minWidth: 180, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: DateConverterAgGrid,
    },
    {
      field: 'dueDate', headerName: 'Due Date', minWidth: 180, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: DateConverterAgGrid,
    },
    {
      field: 'paymentAmount', headerName: 'Payment Amount', headerClass: 'header-right-align', minWidth: 200, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'right' }, filtertype: "number", cellRendererFramework: AmountRenderer,
    },
    {
      field: 'amountToVendor', headerName: 'Amount to Vendor', headerClass: 'header-right-align', minWidth: 200, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'right' }, filtertype: "number", cellRendererFramework: AmountRenderer,
    },
    {
      field: 'paid', headerName: 'Amount Paid', headerClass: 'header-right-align', minWidth: 200, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'right' }, filtertype: "number", cellRendererFramework: AmountRenderer,
    },
    {
      field: 'overdue', headerName: 'Overdue', headerClass: 'header-right-align', minWidth: 200, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'right' }, filtertype: "number", cellRendererFramework: AmountRenderer,
    },
    {
      field: 'approvalStatus', headerName: 'Approval Status', minWidth: 180, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer, cellClassRules: {
        'success-cell': (params: any) => params.value === 'Completed',
        // 'submitted-cell': (params: any) => params.value === 'Submitted',
        'denied-cell': (params: any) => params.value === 'Declined',
        'registered-cell': (params: any) => params.value === 'Processing',
      },
    },
    {
      field: 'paymentStatus', headerName: 'Payment Status', minWidth: 180, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer, cellClassRules: {
        'success-cell': (params: any) => params.value === 'Fully Paid',
        'submitted-cell': (params: any) => params.value === 'Initiated',
        'denied-cell': (params: any) => params.value === 'Failed',
        'registered-cell': (params: any) => params.value === 'Not Initiated' || params.value === 'Initiated',
      },
    },
    // {
    //     field: 'viewInvoice',
    //     headerName: 'View Invoice',
    //     minWidth: 150,
    //     resizable: false,
    //     suppressMovable: true,
    //     filter: false,
    //     suppressSizeToFit: false,
    //     cellStyle: { textAlign: 'center', color: "#0055d5" },
    //     filtertype: "text",
    //     cellRendererFramework: cellEyeIcon,
    //     cellRendererParams: (params: any) => {
    //         const handleIconClick = async () => {
    //             try {
    //                 const response = await axiosPrivate.get(`/vendor-payable/invoices/${params.data.invoicePayableId}`);
    //                 SetEditData && SetEditData(response.data, false);
    //                 setVendorEyeShowModal(!vendorEyeShowmodal);
    //             } catch (error) {
    //             }
    //         };

    //         return {
    //             handleIconClick: handleIconClick
    //         };
    //     }
    // }
  ]

  useEffect(() => {
    fetchCard();
    getTransactionCharges();
    fetchBanks();
    getUpi();
  }, []);

  const getUpi = async () => {
    try {
      const response = await axiosPrivate.get(`/list/upi-id`);
      if (response.status === 200) {

        const dropdownExpenceDD = response.data.upis.map((item: any) => ({
          label: item.decrypted_upi,
          value: item.decrypted_upi,
          id: item.upi_id,
        }));


        const sortedExpenceDD = [...dropdownExpenceDD].sort((a, b) => a.label.localeCompare(b.label));
        setUpis(sortedExpenceDD);
      }
    } catch (error) {
    }
  };

  useEffect(() => {
    let displayCard = cards.find((data: any) => selectedPaymentMethod.cardId === data.cardId);
    setselectedCard(displayCard);
  }, [selectedPaymentMethod.cardId]);

  const getTransactionCharges = async () => {
    try {
      const res = await axiosPrivate.get(`/customer-onboarding/card-details`);
      let amountDebitFor = selectedPaymentMethod.PaymentMethod === "Corporate Card" ? res.data.getData.commercialCard : selectedPaymentMethod.PaymentMethod === "Net Banking" ? res.data.getData.netbanking : 0
      if (res.status === 200) {
        let transactionAmount = (parseFloat(groupInvoices.reduce((acc: any, obj: any) => { return acc + parseFloat(obj.amountToVendor); }, 0)) * parseFloat(amountDebitFor)) / 100
        let transactionGst = (transactionAmount * parseFloat(res.data.getData.Gst)) / 100
        let transactionCharges = (transactionAmount + transactionGst).toFixed(2)
        let total = (parseFloat(transactionCharges) + parseFloat(groupInvoices.reduce((acc: any, obj: any) => { return acc + parseFloat(obj.amountToVendor); }, 0))).toFixed(2)
        setTransactionCharges(transactionCharges);
        setTotal(total);
      }
    }
    catch (error: any) {
    }
  }


  // Warning Msg clearAleart
  const clearAleart = (status: any) => {
    const timer = setTimeout(() => {
      setShowAlertBox(false)
      setShowMessage("");
      if (status === "grid") {
        vendorVewFun("grid")
        // dispatch(resetFormModified(false));
      }
      clearTimeout(timer);
    }, 5000);
  };

  const fetchCard = async () => {
    setLoadingScreen(true)
    try {
      const response = await axiosPrivate.get(`/vendor-payable/card-details/get`);
      setCards(response.data.decryptCardDetails)
      setLoadingScreen(false);
    } catch (error) {
      setLoadingScreen(false);;
    }
  };

  const paymentMethodValidator = () => {
    const { PaymentMethod, cardId, virtualcardId, bankName, UpiCode } = selectedPaymentMethod;

    if (PaymentMethod === "") {
      return { result: false, message: "Select Payment Method" };
    }

    if (PaymentMethod === "Corporate Card") {
      return { result: cardId !== "", message: "Select a Corporate card to proceed" };
    }

    if (PaymentMethod === "Virtual Card") {
      return { result: virtualcardId !== "", message: "Select a virtual card to proceed" };
    }

    if (PaymentMethod === "Net Banking") {
      return { result: bankName !== "", message: "Select a bank to proceed" };

    }
    if (PaymentMethod === "UPI") {
      return { result: UpiCode !== "", message: "Select a UPI to proceed" };

    }

    // Handle unknown PaymentMethod
    return { result: false, message: "Select Payment Method" };
  };
  // function for select payment next button
  const paymentNext = async () => {
    let goNext = paymentMethodValidator();
    if (goNext.result) {
      try {
        const response =
          await axiosPrivate.patch(`/vendor-payable/invoice/validate-payable-card`, {
            cardId: selectedPaymentMethod.cardId,
            expenseType: "",
            amountToVendor: (groupInvoices.reduce((acc: any, obj: any) => { return acc + parseFloat(obj.amountToVendor); }, 0)).toString()
          });
        if (response.status === 200) {
          if (response.data.isValid) {
            SetActiveTab(2);

          } else {
            setShowAlertBox(true);
            setShowType("warning")
            setShowMessage(response.data.message)
            clearAleart('');
          }
        }
      } catch (error: any) {
        setShowAlertBox(true)
        setShowType("warning")
        setShowMessage(error.message)
        setTimeout(() => {
          setShowAlertBox(false);
        }, 5000);
      }
    } else {
      setShowAlertBox(true);
      setShowType("danger")
      setShowMessage(goNext.message)
      clearAleart('');
    }
  };

  const paymentBack = () => {
    vendorVewFun("grid");
  };

  const cRes = async (ipgTransactionId: any, payment_history_id: any) => {
    const resMethod: any = await axiosPrivate.post(`/vendor-payable/invoice/method-form/${ipgTransactionId}`, { payment_history_id }, {
      headers: {
        'Content-Type': 'multipart/form-data',  // Set the content type for file uploads
      },
    });
    if (resMethod.data.response.cReq) {
      let page = `<html><form id="acs-form" name="frm" method="POST" action="${resMethod.data.response.storedAcsURL}">
    <input type="hidden" name="creq" value="${resMethod.data.response.cReq}">
    <input type="hidden" name="termUrl" value="${resMethod.data.response.termUrl}">
    <input style="visibility: hidden;" type="submit" id="submit-acs-form" />
    </form><script> document.getElementById("acs-form").submit();</script></html>`;
      // var popupWindow: any = window.open('', '_blank');
      // popupWindow.document.write(page)
      document.open();
      document.write(page);
      document.close();
    } else {
      setShowAlertBox(true)
      setShowType("warning")
      setShowMessage("Transaction Failed")
      clearAleart('');
    }
  };

  const callPayment = async (res: any) => {
    console.log(res)
    if (res?.data.response.message === "Method form execution") {
      const page = `<html>${res.data.response.methodForm}</html>`;
      const newTab: any = window.open('', '_blank');
      newTab.document.write(page);
      setTimeout(async () => {
        newTab.close();
        cRes(res?.data.response.ipgTransactionId, res?.data.response.payment_history_id);
      }, 1000);
    } else if (res?.data.response.message === "cRes form execution") {
      let page = `<html>
                            <form id="acs-form" name="frm" method="POST" action="${res.data.response.storedAcsURL}">
                                <input type="hidden" name="creq" value="${res.data.response.cReq}">
                                <input type="hidden" name="termUrl" value="${res.data.response.termUrl}">
                                <input style="visibility: hidden;" type="submit" id="submit-acs-form" />
                            </form><script> document.getElementById("acs-form").submit();</script>
                        </html>`;
      // var popupWindow: any = window.open('', '_blank');
      // popupWindow.document.write(page)
      document.open();
      document.write(page);
      document.close();
    } else if (res?.data.response.message === "Lyra charge created") {
      // @ts-ignore
      threeds.authenticate({
        uuid: res?.data.response.transactionUuid,
        callback: async (err: any) => {
          if (err) {
            console.log("Authentication failed due to a technical error: " + err);
            setLoadingScreen(false)
            setShowType("Authentication failed due to a technical error:")
            // let causes = (res?.data.response.causes).join()
            setShowMessage(err.message)
            setMessagePopUp(true)
            setStatus('Failed')
          } else {
            try {
              const uuidRes: any = await axiosPrivate.post(`/vendor-payable/invoice/lyra-payment-status/${res?.data.response.uuid}`);
              setMessagePopUp(true)
              if (uuidRes.data.status === "PAID") {
                setMessagePopUp(true)
                setShowType("Payment Success!")
                setShowMessage(`Your transaction has been executed flawlessly.`)
                setStatus('Paid')
              } else {
                setMessagePopUp(true)
                setStatus('Failed')
                setShowType("Payment Declined")
                setShowMessage(`We regret to inform you that the transaction has been declined. Feel free to retry later.`)
              }
            } catch (error) {
              console.error("Error while checking payment status:", error);
            }
          }
        }
      });
    } else if (res?.data.response.message === "Netbanking Redirect" || res?.data.response.message === "Rupay Card Redirect") {
      let formData = "";
      Object.keys(res?.data.response.params).map((key: string) => {
        formData += ` <input type="hidden" name="${key}" value="${res?.data.response.params[key]}" />`;
      });
      // console.log(formData)
      let page = `<html>
                            <form id="acs-form" name="frm" method="POST" action="${res?.data.response.url}">
                                ${formData}
                                <input style="visibility: hidden;" type="submit" id="submit-acs-form" />
                            </form><script> document.getElementById("acs-form").submit();</script>
                        </html>`;
      // var popupWindow: any = window.open('', '_blank');
      // popupWindow.document.write(page)
      console.log(page)
      document.open();
      document.write(page);
      document.close();

      // try {
      //     const netbankingRes = await axios.post(res?.data.response.url, formData, res?.data.response.axiosConfig);
      //     console.log("Response Status:", netbankingRes.status);
      //     console.log(netbankingRes.data);
      // } catch (error) {
      //     console.log("Axios Error:", error);
      // }
    } else if (res?.data.response.message === "Rupay H2HOTP") {
      setOpenRupayOtpModal(true);
      setRupaytranctionDetails(res?.data.response)
    } else if (res?.data.response.message === "UPI charge created") {
      setOpenUpiWaitingModal(true);
      setUpiTranctionDetails(res?.data.response);
    } else if (res?.data.response) {
      setLoadingScreen(false)
      setShowType(res?.data.response.message)
      let causes = res?.data.response.causes ? (res?.data.response.causes).join() : ""
      setShowMessage(causes)
      setMessagePopUp(true)
      // clearAleart('');
    }

    // else if (res?.data.response.message == "Net Banking Execution") {
    //     let reqJson = res.data.response.reqJson;
    //     reqJson.consumerData.responseHandler = handleInternetBankingResponse;
    //     // @ts-ignore
    //     $.pnCheckout(reqJson);
    //     if (reqJson.features.enableNewWindowFlow) {
    //         // @ts-ignore
    //         pnCheckoutShared.openNewWindow();
    //     }
    // }
  }


  const groupPayInvoice = async () => {
    setLoadingScreen(true);
    try {
      const res = await axiosPrivate.patch(`/vendor-payable/invoice/update-group-payment`,
        {
          selectedPaymentMethod: selectedPaymentMethod,
          approvalStatus: {
            status: "Approved",
            remarks: "",
          },
          groupInvoices: groupInvoices
        }
      );
      if (res.status === 200) {
        setLoadingScreen(false);
        callPayment(res);
      }
    }
    catch (error: any) {
      setLoadingScreen(false)
      setShowAlertBox(true)
      setShowType("warning")
      setShowMessage(error.response.data.message)
      clearAleart('');
    }
  };
  const paymentToBack = () => {
    SetActiveTab(1)
  };

  const fetchBanks = async () => {
    setLoadingScreen(true)
    try {
      const response = await axiosPrivate.get(`/vendor-payable/banks/get`);
      if (response.status === 200) {
        let allBanks = response.data.banks.map((item: any) => ({
          label: item.bankName,
          value: item.bankName,
          id: item.bankCode,
        }));
        setBanks(allBanks)
      }
      setLoadingScreen(false);
    } catch (error) {
      setLoadingScreen(false);;
    }
  };
  return (
    <>
      <MainPageCard>
        <NavigateHeader firstValue={"Payable"}
          secondValue={"Vendor"}
          // navigatePage={isFormModified ? () => { setShowChangesModal(true) } : enterInvoiceBack}
          navigatePage={() => { }}
          style={{ cursor: "pointer" }}
        />
        {/* </div> */}
        {
          activeTab === 1 &&
          <SelectPaymentMethod
            cards={cards}
            Banks={Banks}
            getUpi={() => getUpi()}
            Upis={Upis}
            selectedPaymentMethod={selectedPaymentMethod}
            SetSelectedPaymentMethod={(val: any) => {
              SetSelectedPaymentMethod(val)

            }}
            paymentNext={paymentNext}
            paymentBack={paymentBack}
          />
        }
        {
          activeTab === 2 &&

          <div className='group-grid-view'>
            {/* style={{ marginTop: "45px" }} */}
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              <div className={"payment-details-main"}  >
                <p className={"payment-details-heading"}>{"Payment Details"}</p>
                <div className={"payment-details-sub"}>
                  <p>{"Payment Method"}</p>
                  <p>: {selectedPaymentMethod.PaymentMethod}</p>
                </div>
                {
                  selectedPaymentMethod.PaymentMethod === "Corporate Card" &&
                  <div className={"payment-details-sub"} key={selectedCard?.cardNumber}>
                    <p>{"Card Number"}</p>
                    <p>: {selectedCard?.cardNumber}</p>
                  </div>
                }
                {
                  selectedPaymentMethod.PaymentMethod === "Corporate Card" &&
                  <div className={"payment-details-sub"} key={selectedCard?.validThru}>
                    <p>{"Card Valid upto"}</p>
                    <p>: {selectedCard?.validThru}</p>
                  </div>
                }
                {
                  selectedPaymentMethod.PaymentMethod === "Net Banking" &&
                  // <>
                  <div className={"payment-details-sub"} key={selectedPaymentMethod?.bankName}>
                    <p>{"Bank"}</p>
                    <p>: {selectedPaymentMethod?.bankName}</p>
                  </div>
                  // </> 
                }
                {
                  selectedPaymentMethod.PaymentMethod === "UPI" &&
                  <div className={"payment-details-sub"} key={selectedPaymentMethod?.UpiCode}>
                    <p>{"UPI ID"}</p>
                    <p>: {selectedPaymentMethod?.UpiCode}</p>
                  </div>
                }
                <div className={"payment-details-sub"}>
                  <p>{"Amount"}</p>
                  <p>
                    <div className='display-as-amount'>
                      <div>: </div>
                      <div>{formatAmount(groupInvoices.reduce((acc: any, obj: any) => { return acc + parseFloat(obj.amountToVendor); }, 0))}</div>
                    </div>
                  </p>
                </div>
                <div className={"payment-details-sub"}>
                  <p>{"Transaction Charges"}</p>
                  <p>
                    <div className='display-as-amount'>
                      <div>: </div>
                      <div>{formatAmount(transactionCharges)}</div>
                    </div>
                  </p>
                </div>
                <div className={"payment-details-sub"}>
                  <p>{"Total"}</p>
                  <p>
                    <div className='display-as-amount'>
                      <div>: </div>
                      <div>{formatAmount(total)}</div>
                    </div> </p>
                </div>
                {/* <div className={"payment-details-sub"}>
                                <p>{"UPI"}</p>
                                <p>: {invoiceDetails.upi}</p>
                            </div> */}
              </div>
            </div>
            <AgGrid
              fileName={"Payment-processes-details"}
              tabelRowData={groupInvoices}
              tableColumnData={currentActor === "singleUser" ? vendorColumnData.filter((obj: any) => obj.field !== "approvalStatus") : vendorColumnData}
              displaylength={18}
            />
            <div className='footer-vendor-pb'>
              <ButtonComponent
                title={"Back"}
                height={"50px"}
                width={"150px"}
                backgroundColor={"#0055D4"}
                color={"white"}
                margin={"0px 8px"}
                className={"button-component common-btn"}
                handleClick={() => {
                  paymentToBack();
                }}
              />
              <ButtonComponent
                title={"Make Payment"}
                height={"50px"}
                width={"150px"}
                backgroundColor={"#0055D4"}
                color={"white"}
                margin={"0px 8px"}
                className={"button-component common-btn"}
                handleClick={() => {
                  groupPayInvoice();
                }}
              />
            </div>
          </div>
        }


      </ MainPageCard>
      {
        loadingScreen &&
        <Loading />
      }
      {
        showAlertBox &&
        <AlertBox type={showType} message={showMessage} />
      }
      {
        messagePopUp &&
        <PaymentResultModal
          transactionsID={''}
          closeModal={() => { vendorVewFun("grid") }}
          handleOk={() => vendorVewFun("grid")}
          message={showMessage}
          type={showType}
          status={status}
        // modelType={"grid-delete"}
        />
      }
      {
        openRupayOtpModal &&
        <RupayOtpModal
          executeResponce={(res: any) => {
            setMessagePopUp(true);
            if (res.status === "PAID" || res.status === "Paid") {
              setShowType("Payment Success!")
              setShowMessage(`Your transaction has been executed flawlessly.`)
            } else {

              setShowType("Payment Declined")
              setShowMessage(`We regret to inform you that the transaction has been declined. Feel free to retry later.`)
            }

          }}
          clearValue={(val: boolean) => { setOpenRupayOtpModal(val) }}
          tranctionFailed={() => vendorVewFun("grid")}
          rupaytranctionDetails={rupaytranctionDetails}
        />
      }
      {
        openUpiWaitingModal &&
        <UpiwaitingModal
          clearValue={(val: boolean) => {
            setOpenUpiWaitingModal(val)
          }}
          executeResponce={(res: any) => {
            setMessagePopUp(true);
            if (res.status === "PAID" || res.status === "Paid") {
              setShowType("Payment Success!")
              setShowMessage(`Your transaction has been executed flawlessly.`)
            } else {

              setShowType("Payment Declined")
              setShowMessage(`We regret to inform you that the transaction has been declined. Feel free to retry later.`)
            }

          }}
          upiTranctionDetails={upiTranctionDetails}
        />
      }
    </>
  )
}
