import { useState, useEffect } from 'react'
import MainPageCard from '../../common-component/cards/main-page-card'
import NavigateHeader from '../../common-component/navigate-header'
import RadioOrCheckbox from '../../common-component/form-elements/radio-or-checkbox'
import DropdownComponent from '../../common-component/form-elements/dropdown-component'
import InputComponent from '../../common-component/form-elements/input-component'
import ButtonComponent from '../../common-component/form-elements/button-component'
import useAxiosPrivate from "../../../services/hooks/useaxios-private";
import masterCardImg from "../../../assets/images/mastercard-img.png";
import visaCardImg from "../../../assets/images/visa-img.png";
import rupayCardImg from "../../../assets/images/rupay-img.png";
import amexCardImg from "../../../assets/images/amex-img.png";
import rupeeImg from "../../../assets/images/ruppees-img.png";
import { v4 as uuidv4 } from 'uuid';
import AlertBox from '../../common-component/alert-box'
import Changesmodal from '../../common-component/modals/changes-modal'
import TextArea from '../../common-component/form-elements/text-area'
import "../../../styles/organisation/card/form.scss"
import Loading from '../../common-component/modals/loading-screen'
import { useDispatch } from "react-redux";
import { resetFormModified } from "../../../redux/action";
import CardFormTableGrid from '../../../config/organisation/card/card-form-grid'
import { ReactComponent as CardOverview } from '../../../assets/icons/card-overview.svg'
import { ReactComponent as ControlCentre } from '../../../assets/icons/contol-centre.svg'
import { ReactComponent as Correct } from '../../../assets/icons/correct.svg'
import { ReactComponent as InProgerss } from '../../../assets/icons/in-progerss.svg'
import { ReactComponent as NotWork } from '../../../assets/icons/not-work.svg'
import { BsPlusLg } from 'react-icons/bs'
import AddCardCategory from '../../common-component/modals/card-category-add-modal'
import AddExpense from '../../common-component/modals/expense-add-model'
import AddExpenseValue from '../../common-component/modals/expense-add-model'
import DeleteModal from '../../common-component/modals/delete-modal'
import { IoIosEye, IoIosEyeOff } from 'react-icons/io'
import OtpVerifyModal from '../../common-component/modals/otp-verify-modal'
import useAuth from "../../../services/hooks/useauth";





interface FormProps {
    userNavigateFunction: (val: string) => void;
    userNavigate: string;
    isEditMode: boolean;
    modeFunction: (val: boolean) => void;
    getEditMode: (value: boolean) => void;
    editData: any
}

export interface CardDataType {
    cardType: string,
    nameOnCard: string;
    cardNumber: string;
    cardCategory: string;
    issuer: string;
    issuerName: string;
    validFrom: string;
    validThru: string;
    creditPeriod: string;
    billingCycle: string;
    cardMaxLimit: any;
    cardMaxLimitInr: any;
    isActive: string;
    remarks: string;
    customerLimits: string;
    dailyVolumeMaximumAmount: any;
    declineRemarks: string;
    singleTransactionMaximumAmount: any;
    // transactionMappingDepartment: string;
    // transactionMappingMaxLimit: string;
    // transactionMappingPayments: string;
    // transactionMappingExpense: string;
    approvedBy: string;
    status: string;
    organisationId: string;
    companyBankId: string


    [key: string]: string;
}

export interface cardValidationRulesInterface {
    [key: string]: {
        [key: string]: {
            regex: RegExp | string;
            field: string;
            shouldNotBe: string;
        };
    }
}



const Form: React.FC<FormProps> = ({ userNavigateFunction, getEditMode, isEditMode, editData, modeFunction }) => {
    const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
    const [componentNavigator, setComponentNavigator] = useState("form-page");
    const [priorityValues, setPriorityValues] = useState<any[]>([]);
    const [showType, setShowType] = useState("warning") // error message showType
    const [showCardNumberMessage, setShowCardNumberMessage] = useState("warning") // error message showType
    const [showMessage, setShowMessage] = useState<string>("") // error message showMessage
    const [showAlertBox, setShowAlertBox] = useState(false) // error message ShowAlertBox
    const [bankValues, setBankValues] = useState<any[]>([]);
    const [payments, setPayments] = useState<any[]>([]);
    const [departmentValues, setDepartmentValues] = useState<any[]>([]);
    const [cardCategory, setCardCategory] = useState<any[]>([]);
    const [expenseValue, setExpenseValue] = useState<any[]>([]);
    const [expenseValueGrid, setExpenseValueGrid] = useState<any[]>([]);
    const [cardType, setCardType] = useState<any[]>([]);

    // const [department, setDepartment] = useState<any[]>([]);
    const [isFormDirty, setIsFormDirty] = useState(false);
    const [isDataRetrieved, setIsDataRetrieved] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [addCardCategory, setAddCardCategory] = useState<boolean>(false)
    const [addExpense, setAddExpense] = useState<boolean>(false)
    const [userDataErrors, setUserDataErrors] = useState<Record<string, boolean>>({});
    const [showChangesModal, setShowChangesModal] = useState(false);
    const [cardImg, setCardImg] = useState('');
    const [currentFormDataEdit, setCurrentFormDataEdit] = useState(false);
    const [showDeleteModel, setShowDeleteModel] = useState<boolean>(false)
    const [deleteIndex, setDeleteIndex] = useState<number>(0)
    const [currentDeletVal, setCurrentDeletVal] = useState<any>({});
    const [otpVerification, setOtpVerification] = useState<boolean>(false);
    const [isLocked, setIsLocked] = useState<boolean>(false);
    const [isHidden, setIsHidden] = useState<boolean>(false);
    const [isCardNumberDisabled, setIsCardNumberDisabled] = useState<boolean>(true);
    const [processName, setProcessName] = useState<string>("Yes");
    const [showMaskedCardNumber, setShowMaskedCardNumber] = useState<string>("");





    const [currentDirector, setCurrentDirector] = useState<number>(0);


    const [organisationCardData, setOrganisationCardData] = useState<CardDataType>({
        cardType: "Commercial Card",
        nameOnCard: "",
        cardNumber: "",
        cardCategory: "",
        cardCategoryId: "",
        issuer: "",
        issuerName: "",
        validFrom: "",
        validThru: "",
        creditPeriod: "",
        billingCycle: "",
        cardMaxLimit: "",
        cardMaxLimitInr: "INR",
        isActive: "Yes",
        remarks: "",
        declineRemarks: "",
        customerLimits: "No",
        dailyVolumeMaximumAmount: "",
        dailyMaximumAmountInr: "INR",
        singleTransactionMaximumAmount: "",
        singleMaximumAmountInr: "INR",
        // transactionMappingDepartment: "",
        // transactionMappingMaxLimit: "",
        // transactionMappingPayments: "",
        // transactionMappingExpense: "",
        status: "",
        maxLimitInr: "INR",
        approvedBy: "",
        organisationId: "",
        companyBankId: "",
    });
    const [transactionMappingData, setTransactionMappingData] = useState<any>({
        transactionMappingDepartment: "",
        transactionMappingDepartmentName: "",
        transactionMappingMaxLimit: "",
        transactionMappingPayments: "",
        transactionMappingExpense: "",
        expenseId: "",
        transactionMappingId: ""
    })
    const [transactionMapping, setTransactionMapping] = useState<any[]>([])

    const [iconStatus, setIconStatus] = useState({
        organisationDetails: "inprogress",
        adminDetails: "notwork",
    })

    const { auth } = useAuth();
    const dispatch = useDispatch();
    const axiosPrivate = useAxiosPrivate();

    // regux to check mandatory fields while clicking "next" and "submit for approval" button 
    const cardValidationRules: cardValidationRulesInterface = {
        Card_details: {
            nameOnCard: {
                regex: /^[a-zA-Z][A-Za-z ]+$/,
                field: "mandatory",
                shouldNotBe: ""
            },
            cardNumber: {
                //(organisationCardData.cardNumber && organisationCardData.cardNumber.substring(0, 1)) == "3" ? /^[3-6]\d{3} \d{6} \d{5}$/ : /^[3-6]\d{3} \d{4} \d{4} \d{4}$/
                regex: !isEditMode ? (organisationCardData.cardNumber && organisationCardData.cardNumber.substring(0, 1)) == "3" ? /^[3-6]\d{3} \d{6} \d{5}$/ : /^[3-6]\d{3} \d{4} \d{4} \d{4}$/ : "",
                field: "mandatory",
                shouldNotBe: ""
            },
            cardCategory: {
                regex: "",
                field: "mandatory",
                shouldNotBe: ""
            },
            issuer: {
                regex: "",
                field: "mandatory",
                shouldNotBe: ""
            },
            validFrom: {
                regex: organisationCardData.validFrom !== "" ? /^(0[1-9]|1[0-2])\/([0-9]{2})$/ : "",
                field: "",
                shouldNotBe: ""
            },
            validThru: {
                regex: /^(0[1-9]|1[0-2])\/([0-9]{2})$/,
                field: "mandatory",
                shouldNotBe: ""
            },
            creditPeriod: {
                regex: /^[0-9]+$/,
                field: "mandatory",
                shouldNotBe: ""
            },
            billingCycle: {
                regex: /^(2[01]|[1][0-9]|[2][0-8]|0?[1-9])$/,
                field: "mandatory",
                shouldNotBe: ""
            },

            isActive: {
                regex: "",
                field: "mandatory",
                shouldNotBe: ""

            },
            remarks: {
                regex: (organisationCardData.isActive === "No" && isEditMode) ? /^[^\s].*/ : "",
                field: (organisationCardData.isActive === "No" && isEditMode) ? "mandatory" : "",
                shouldNotBe: ""
            },

        }
    }

    // useEffect for dropdown functions
    useEffect(() => {
        // fetchPriorityValues();
        fetchBankValues();
        paymentsValue();
        fetchDepartmentValues();
        fetchData(`/companyadmin/organisation/card-category`, setCardCategory, "CardCategoryDetails");
        fetchExpenseData(`/companyadmin/organisation/card-expense`, setExpenseValue, "CardExpenseDetails")
    }, []);

    //useEffect for retreving data while edit the form
    useEffect(() => {
        if (!isEditMode) return;
        setIconStatus({
            ...iconStatus,
            organisationDetails: "correct",
            adminDetails: "correct",
        })
        setOrganisationCardData({
            cardType: editData.cardType,
            nameOnCard: editData.nameOnCard,
            cardNumber: editData.cardNumber,
            cardCategory: editData.cardCategory,
            cardCategoryId: editData.cardCategoryId,
            issuer: editData.issuer,
            issuerName: editData.issuerName,
            validFrom: editData.validFrom,
            validThru: editData.validThru,
            creditPeriod: editData.creditPeriod,
            declineRemarks: editData.declineRemarks,
            billingCycle: editData.billingCycle,
            cardMaxLimit: editData.cardMaxLimit,
            cardMaxLimitInr: editData.cardMaxLimitInr,
            isActive: editData.isActive,
            remarks: editData.remarks || "",
            customerLimits: editData.customerLimits,
            transactionMappingDepartmentName: editData.transactionMappingDepartmentName,
            dailyVolumeMaximumAmount: editData.dailyVolumeMaximumAmount,
            dailyMaximumAmountInr: editData.dailyMaximumAmountInr,
            singleTransactionMaximumAmount: editData.singleTransactionMaximumAmount,
            singleMaximumAmountInr: editData.singleMaximumAmountInr,
            approvedBy: editData.approvedBy,
            expenseId: editData.expenseId,
            status: editData.status,
            organisationId: editData.organisationId,
            companyBankId: editData.companyBankId,
            maxLimitInr: editData.maxLimitInr,
        });
        getExistingTransation(editData.cardId
        );
        setShowMaskedCardNumber(editData.cardNumber)
    }, [isEditMode]);

    // useEffect for change card logo in card number input 
    useEffect(() => {
        const firstChar = organisationCardData.cardNumber.charAt(0);
        if (firstChar === "3") {
            setCardImg(amexCardImg);
        } else if (firstChar === "4") {
            setCardImg(visaCardImg);
        } else if (firstChar === "5") {
            setCardImg(masterCardImg);
        } else if (firstChar === "6") {
            setCardImg(rupayCardImg);
        } else {
            setCardImg('');
        }
    }, [organisationCardData.cardNumber])

    // useEffect for disable the "add" button in Control Centre form view 
    useEffect(() => {
        if (organisationCardData.transactionMappingDepartment !== "" &&
            organisationCardData.transactionMappingMaxLimit !== ""
        ) {
            setIsDisabled(false)
        } else {
            setIsDisabled(true)
        }
    }, [organisationCardData.transactionMappingDepartment, organisationCardData.transactionMappingMaxLimit])

    // useEffect for disable the "add" button in Control Centre form view 
    useEffect(() => {
        if (transactionMappingData.transactionMappingDepartment !== "" && transactionMappingData.transactionMappingMaxLimit !== "") {
            setIsDisabled(false)
        } else {
            setIsDisabled(true)
        }
    }, [transactionMappingData.transactionMappingDepartment, transactionMappingData.transactionMappingMaxLimit]);


    // function To get the data through cardId for Transaction Limits Mapping grid
    const getExistingTransation = async (cardId: any) => {
        try {
            const response = await axiosPrivate.get(`companyadmin/organisation/card-transaction-mapping/${cardId}`);
            if (response.status === 200) {
                setTransactionMapping(response.data.CardTransactionMappingDetails)

            }
        } catch (error) {
        }
    }

    // function for bank dropdown
    const fetchBankValues = async () => {
        try {
            const response = await axiosPrivate.get("/card-issuer-bank");
            if (response.status === 200) {
                const dropdownValues = response.data.bank.map((item: any) => ({
                    label: item.bankName,
                    value: item.bankName,
                    id: item.bankId,
                }));

                const sortedData = [...dropdownValues].sort((a, b) => a.label.localeCompare(b.label));
                setBankValues(sortedData);
            }
        } catch (error) {
        }
    };

    // function for department dropdown
    const fetchDepartmentValues = async () => {
        try {
            const response = await axiosPrivate.get("companyadmin/organisation/card-master/department");
            if (response.status === 200) {
                const dropdownValues = response.data.departmentDetails.map((item: any) => ({
                    label: item.departmentName,
                    value: item.departmentName,
                    id: item.departmentId,
                }));
                setDepartmentValues(dropdownValues);
            }
        } catch (error) {
        }
    };

    // function for payment dropdown
    const paymentsValue = () => {
        const defaultPaymentValue = [

            {
                label: "Select",
                value: "",
            },
            {
                label: "Vendor",
                value: "Vendor",
            },
            {
                label: "GST",
                value: "GST",
            },
            {
                label: "Direct Tax",
                value: "Direct Tax",
            },
            {
                label: "Utility",
                value: "Utility",
            },
        ];
        const dropdownValues = [...defaultPaymentValue];
        setPayments(dropdownValues)



    }

    // Regux validation
    const updatedIsValueValidAll: Record<string, boolean> = {};
    const validateForm = (): boolean => {
        const updatedFieldValidity: Record<string, boolean> = {};
        Object.keys(cardValidationRules.Card_details).forEach((field) => {
            const rule = cardValidationRules.Card_details[field];
            const value = organisationCardData[field];
            // Check if the field value meets the conditions based on rule.field and rule.shouldNotBe
            const isValueValid = (rule.field === "mandatory") ? (value !== rule.shouldNotBe) : true;
            // If a regular expression is provided, test the field value against it
            const isRegexValid = rule.regex !== "" ? new RegExp(rule.regex).test(value) : true;
            updatedFieldValidity[field] = !isValueValid || !isRegexValid;
            updatedIsValueValidAll[field] = !isValueValid
        });

        setUserDataErrors(updatedFieldValidity);
        const isFormValid = Object.keys(updatedFieldValidity).every((keys) => {
            return !updatedFieldValidity[keys]
        }
        );

        return isFormValid;
    };

    // Timeout function for messages
    const clearAleart = (status: any) => {
        const timer = setTimeout(() => {
            setShowAlertBox(false);
            setShowMessage("");
            getEditMode(false)
            if (status === 200) {
                userNavigateFunction("grid")
            }
            clearTimeout(timer);
        }, 5000);
    }


    // function for "Next" button when click in Card Overview form
    async function goNext() {
        const isValid = validateForm();

        const isExpireDate = areYearsValid(organisationCardData.validFrom, organisationCardData.validThru)
        if (isValid) {
            if (!isExpireDate) {

                setUserDataErrors({ validThru: true, validFrom: true })
                setShowAlertBox(true)
                setShowType("danger")
                setShowMessage(Object.values(updatedIsValueValidAll).every((values) => !values) ? "Some of the field(s) are not in required format." : "Mandatory field(s) should not be left blank.")
                clearAleart("")
            } else {

                try {
                    await axiosPrivate.post('/companyadmin/organisation/card-master/check-cardNumber', { "cardNumber": organisationCardData.cardNumber, "cardId": isEditMode ? editData.cardId : "" })

                    setComponentNavigator("control-page");
                    setIconStatus({
                        ...iconStatus,
                        organisationDetails: "correct",
                        adminDetails: "inprogress"
                    })
                }
                catch (error: any) {
                    setShowAlertBox(true)
                    setShowType("warning")
                    setShowMessage(error.response.data.error)
                    clearAleart("")
                }
            }
        } else {

            setShowAlertBox(true)
            setShowType("danger")
            setShowMessage(Object.values(updatedIsValueValidAll).every((values) => !values) ? "Some of the field(s) are not in required format." : "Mandatory field(s) should not be left blank.")
            clearAleart("")
        }
    }

    // function for "Submit for Approval" button when click in Control Centre form
    async function organisationCardDataSave() {

        const isLimitCheck = areLimitValid(organisationCardData.cardMaxLimit, organisationCardData.dailyVolumeMaximumAmount, organisationCardData.singleTransactionMaximumAmount, transactionMappingData.transactionMappingMaxLimit)

        let islimitCheck = false
        switch (true) {
            case organisationCardData.dailyVolumeMaximumAmount && (parseInt(organisationCardData.dailyVolumeMaximumAmount) > parseInt(organisationCardData.cardMaxLimit)):
                setUserDataErrors({ dailyVolumeMaximumAmount: true })
                setShowAlertBox(true)
                setShowType("danger")
                setShowMessage("Daily volume amount cannot exceed Card Max Limit.")
                clearAleart("")
                islimitCheck = false
                return;
                break;
            case organisationCardData.singleTransactionMaximumAmount && (parseInt(organisationCardData.singleTransactionMaximumAmount) > parseInt(organisationCardData.cardMaxLimit)):
                setUserDataErrors({ singleTransactionMaximumAmount: true })
                setShowAlertBox(true)
                setShowType("danger")
                setShowMessage("Single transaction amount cannot exceed Card Max Limit.")
                clearAleart("")
                islimitCheck = false
                return;
                break;
            case organisationCardData.singleTransactionMaximumAmount && organisationCardData.dailyVolumeMaximumAmount && (parseInt(organisationCardData.singleTransactionMaximumAmount) > parseInt(organisationCardData.dailyVolumeMaximumAmount)):
                setUserDataErrors({ singleTransactionMaximumAmount: true, dailyVolumeMaximumAmount: true })
                setShowAlertBox(true)
                setShowType("danger")
                setShowMessage("Single transaction amount cannot exceed daily volume amount.")
                clearAleart("")
                islimitCheck = false
                return;
                break;
            case transactionMappingData.transactionMappingMaxLimit && (parseInt(transactionMappingData.transactionMappingMaxLimit) > parseInt(organisationCardData.cardMaxLimit)):
                setUserDataErrors({ transactionMappingMaxLimit: true })

                setShowAlertBox(true)
                setShowType("danger")
                setShowMessage("Department max limit cannot exceed Card Max Limit.")
                clearAleart("")
                islimitCheck = false
                return;
                break;
            default:
                islimitCheck = true
                break;
        }
        setIsLoading(true)
        if (islimitCheck) {
            setUserDataErrors({ singleTransactionMaximumAmount: false, dailyVolumeMaximumAmount: false, transactionMappingMaxLimit: false, })
            try {
                const dataToSend = {
                    ...organisationCardData,
                    status: "Pending Approval",
                    approvedBy: "",
                    page: "master",
                    isCardNumberDisabled
                }
                const res = isEditMode ?

                    await axiosPrivate.patch(`/companyadmin/organisation/card-master/update/${editData.cardId}`, { "cardDetails": { "cardOverView": dataToSend, "transactionMapping": transactionMapping, } }) :
                    await axiosPrivate.post('/companyadmin/organisation/card-master', { "cardDetails": { "cardOverView": dataToSend, "transactionMapping": transactionMapping, } })
                if (res.status === 200) {
                    setIsLoading(false)
                    setShowAlertBox(true)

                    setShowType("success")
                    setShowMessage(res.data.message)
                    clearAleart(res.status)
                    dispatch(resetFormModified(false));
                    // userNavigateFunction("grid")
                }
            } catch (error: any) {
                setIsLoading(false)
                if (error.status === 500) {
                    setShowAlertBox(true);
                    setShowType("danger")
                    setShowMessage(error.response.data.error)
                    clearAleart("")

                } else if (error.code === "ERR_NETWORK") {
                    setShowAlertBox(true);
                    setShowType("danger")
                    setShowMessage(error.response.data.error)
                    clearAleart("")
                } else {
                    setShowAlertBox(true);
                    setShowType("danger")
                    setShowMessage(error.response.data.error)
                    clearAleart("")
                }
            }
        }
        else {
            setShowAlertBox(true)
            setShowType("danger")
            setShowMessage(Object.values(updatedIsValueValidAll).every((values) => !values) ? "Some of the field(s) are not in required format." : "Mandatory field(s) should not be left blank.")
            clearAleart("")
        }

    }

    // function To delete the data in Transaction Limits Mapping grid
    const TransactionMappingDelete = async () => {
        // setDeleteIndex(index);
        // setCurrentDeletVal(val);

        if (currentDeletVal.transactionMappingId) {
            try {
                const response = await axiosPrivate.delete(`/companyadmin/organisation/card-transaction-mapping/delete/${currentDeletVal.transactionMappingId}`);
                if (response.status === 200) {
                    setShowDeleteModel(false);
                    const newArray = [...transactionMapping];
                    newArray.splice(deleteIndex, 1);
                    setTransactionMapping(newArray);
                    setShowAlertBox(true);
                    setShowType("success")
                    setShowMessage(response.data.message)
                    clearAleart("")
                }
            } catch (error: any) {
                setShowAlertBox(true);
                setShowType("success")
                setShowMessage(error.response.data.error)
                clearAleart("")
            }
        } else {
            const newArray = [...transactionMapping];
            newArray.splice(deleteIndex, 1);
            setTransactionMapping(newArray)
            setShowDeleteModel(false);
            setShowAlertBox(true);
            setShowType("success")
            setShowMessage("The selected record(s) have been deleted.")
            clearAleart("")
        }
    };

    // function To edit the data in Transaction Limits Mapping grid
    function retriveData(value: any, index: number) {
        setCurrentFormDataEdit(true)
        setIsDataRetrieved(true);
        setCurrentDirector(index)
        dispatch(resetFormModified(true));
        setIsFormDirty(true);
        setTransactionMappingData({
            transactionMappingDepartment: value.transactionMappingDepartment,
            transactionMappingDepartmentName: value.transactionMappingDepartmentName,
            transactionMappingMaxLimit: value.transactionMappingMaxLimit,
            transactionMappingPayments: value.transactionMappingPayments,
            transactionMappingExpense: value.transactionMappingExpense,
            expenseId: value.expenseId,
            transactionMappingId: value.transactionMappingId
        })
    }

    // function for "Add" button to add a value in Transaction Limits Mapping grid
    const transactionMappingSave = () => {
        if (transactionMappingData.transactionMappingMaxLimit && (parseInt(transactionMappingData.transactionMappingMaxLimit) > parseInt(organisationCardData.cardMaxLimit))) {
            setUserDataErrors({ transactionMappingMaxLimit: true })
            setShowAlertBox(true)
            setShowType("danger")
            setShowMessage("Department max limit cannot exceed Card Max Limit.")
            clearAleart("")
            return;
        }
        let existingDataStatus = false
        transactionMapping.length > 0 && transactionMapping.map((e: any, i: number) => {

            if ((e.transactionMappingDepartment == transactionMappingData.transactionMappingDepartment) && (e.transactionMappingPayments == transactionMappingData.transactionMappingPayments) && (e.expenseId == transactionMappingData.expenseId)) {
                if (currentFormDataEdit && (i === currentDirector)) return;
                existingDataStatus = true
                setShowAlertBox(true);
                setUserDataErrors({ transactionMappingDepartment: true, transactionMappingPayments: true, transactionMappingExpense: true })
                setShowType("danger")
                setShowMessage("Entered combination of transaction limits mapping already exists.")
                clearAleart("")
            }
        })
        if (existingDataStatus) return;
        if (!existingDataStatus) {

            setUserDataErrors({ transactionMappingDepartment: false, transactionMappingPayments: false, transactionMappingExpense: false })
            if (currentFormDataEdit) {
                let data: any = [];
                transactionMapping.length > 0 && transactionMapping.map((ele: any, i: number) => {
                    if (i === currentDirector) {
                        data.push(transactionMappingData)
                    } else {
                        data.push(ele)
                    }
                })
                setTransactionMapping(data)
                setIsDataRetrieved(false)
                setIsFormDirty(false);
                setCurrentFormDataEdit(false);
                setTransactionMappingData({
                    transactionMappingDepartmentName: "",
                    transactionMappingMaxLimit: "",
                    transactionMappingPayments: "",
                    transactionMappingExpense: "",
                    expenseId: ""
                })
                setShowAlertBox(true);
                setShowType("success")
                setShowMessage("Transaction limits mapping data updated successfully.")
                clearAleart("")
            } else {
                let newrecord = transactionMapping
                newrecord.push({ ...transactionMappingData })
                setTransactionMapping(newrecord)
                setIsDataRetrieved(false)
                setIsFormDirty(false);
                setTransactionMappingData({
                    transactionMappingDepartmentName: "",
                    transactionMappingMaxLimit: "",
                    transactionMappingPayments: "",
                    transactionMappingExpense: "",
                    expenseId: ""
                })
            }
            setCurrentFormDataEdit(false);
        } else {
            setShowAlertBox(true);
            setShowType("danger")
            setShowMessage("Mandatory field(s) should not be left blank.")
            clearAleart("")
        }
    }

    const organisationBankDataCancel = () => {
        if (isFormDirty) {
            setShowChangesModal(true)
        } else {
            setOrganisationCardData({
                cardType: "",
                nameOnCard: "",
                cardNumber: "",
                cardCategory: "",
                cardCategoryId: "",
                issuer: "",
                issuerName: "",
                validFrom: "",
                validThru: "",
                creditPeriod: "",
                billingCycle: "",
                cardMaxLimit: "",
                cardMaxLimitInr: "",
                declineRemarks: "",
                isActive: "",
                remarks: "",
                customerLimits: "",
                dailyVolumeMaximumAmount: "",
                dailyMaximumAmountInr: "",
                singleTransactionMaximumAmount: "",
                singleMaximumAmountInr: "",
                transactionMappingDepartment: "",
                transactionMappingMaxLimit: "",
                transactionMappingPayments: "",
                transactionMappingExpense: "",
                maxLimitInr: "",
                expenseId: "",
                approvedBy: "",
                status: "",
                organisationId: "",
                companyBankId: "",
            })
            modeFunction(false)
            userNavigateFunction("grid")
        }
    }

    const handleCardNumberChange = (value: string) => {
        const number = value.replace(/\D/g, "");
        let formattedNumber = "";
        if (number.substring(0, 1) === "3") {
            formattedNumber = number
                .replace(/(\d{4})(\d{6})(\d{5})/, "$1 $2 $3")
                .trim();
        } else {
            formattedNumber = number
                .replace(/(\d{4})(?=\d)/g, "$1 ")
                .trim();
        }

        setOrganisationCardData((prevState) => ({
            ...prevState,
            cardNumber: formattedNumber,
        }));
    };

    const fetchData = async (url: string, setData: (data: any[]) => void, nestedProperty: string) => {
        try {

            const response = await axiosPrivate.get(url);
            if (response.status === 200) {

                const dropdownValues = response.data[nestedProperty].map((item: any) => ({
                    label: item.cardCategoryName,
                    value: item.cardCategoryName,
                    id: item.cardCategoryId,
                }));
                const sortedData = [...dropdownValues].sort((a, b) => a.label.localeCompare(b.label));

                setData(sortedData);
            }
        } catch (error) {
        }
    };

    const fetchExpenseData = async (url: string, setData: (data: any[]) => void, nestedProperty: string) => {
        try {

            const response = await axiosPrivate.get(url);
            if (response.status === 200) {

                const dropdownValues = response.data[nestedProperty].map((item: any) => ({
                    label: item.expenseName,
                    value: item.expenseName,
                    id: item.expenseId,
                }));

                const addDropdownValues = {
                    label: "Select",
                    value: "Select" ? "" : "Select"
                }
                const sortedData = [addDropdownValues, ...dropdownValues].sort((a, b) => a.label.localeCompare(b.label));

                setData(sortedData);
                if (setData === setExpenseValue) {
                    setExpenseValueGrid(dropdownValues);
                }

            }
        } catch (error) {
        }
    };

    const orginationMenueClick = () => {
        const isFormValid = validateForm()
        if (isFormValid) {

            setComponentNavigator("form-page")
            isEditMode && setIconStatus({ ...iconStatus, adminDetails: "correct" })

        } else {
            setShowAlertBox(true);
            setShowType("danger")
            setShowMessage("Mandatory field(s) should not be left blank.")
            clearAleart("")
        }
        setComponentNavigator("form-page")
        dispatch(resetFormModified(true));
    }

    const areYearsValid = (validFrom: any, validThru: any) => {
        if (validThru) {

            const validFromYear = validFrom ? parseInt(validFrom.split('/')[1], 10) : null;
            const validThruYear = parseInt(validThru.split('/')[1], 10);

            if (validFromYear !== null) {
                return validThruYear > validFromYear;
            } else {
                return true; // validFrom is optional and not provided, so it's considered valid
            }
        } else {
            return false;
        }
    };

    const areLimitValid = (maxLimit: any, dailyVolume: any, singleTransaction: any, transactionMappingMaxlimit: any) => {
        return dailyVolume > maxLimit && singleTransaction > maxLimit && singleTransaction > dailyVolume && transactionMappingMaxlimit > maxLimit && transactionMappingMaxlimit > singleTransaction;
    };

    const formatAmount = (amount: any) => {
        if (amount) {

            const onlyNumber = amount?.toString().replace(/[^0-9]/g, "");
            const parsedValue = parseInt(onlyNumber);
            const formattedValue = isNaN(parsedValue) ? "" : parsedValue.toLocaleString('en-IN');
            return formattedValue;
        }
        return ""

    }
    function cancel() {
        if (componentNavigator === "control-page") {
            setComponentNavigator("form-page")
        }
    }
    async function resendOtp() {
        let res;
        try {

            res = await axiosPrivate.post('companyadmin/bank/resend-otp-update/approval', {
                approvedBy: auth.token, pageName: "cardApproval",
                processName: processName, currentDataId: editData.cardId

            });

            if (res.status === 200) {
                setShowAlertBox(true)
                setShowCardNumberMessage("success")
                setShowMessage(res.data.message)
                clearAleart("")
            }
        } catch (error: any) {
            setShowAlertBox(true);
            setShowCardNumberMessage("danger")
            setShowMessage(error.response.data.error)
            clearAleart("")
        }


    }

    async function showCardNumberOtpModel() {
        try {

            let res = await axiosPrivate.post('companyadmin/bank/otp-update/approval', {
                approvedBy: auth.token, pageName: "cardApproval",
                processName: processName, currentDataId: editData.cardId
            });
            if (res.status === 200) {
                setOtpVerification(true)
                setShowAlertBox(true)
                setShowCardNumberMessage("success")
                setShowMessage(res.data.message)
                setTimeout(() => {
                    setShowAlertBox(false);
                }, 5000);
            }
        } catch (error: any) {
            setShowAlertBox(true);
            setShowCardNumberMessage("danger")
            setShowMessage(error.response.data.error)
            setTimeout(() => {
                setShowAlertBox(false);
            }, 5000);
        }
    }

    async function ShowCardNumber() {
        try {
            const response = await axiosPrivate.get(`/companyadmin/organisation/card-master/get-cardNumber/${editData.cardId}`);
            if (response.status === 200) {
                setOrganisationCardData((prevState) => ({
                    ...prevState,
                    cardNumber: response.data.cardNumber,
                }));
                setIsHidden(true)
                setIsCardNumberDisabled(false)
            }

        } catch (error) {
        }
    }

    return (
        <>
            <MainPageCard>
                <NavigateHeader
                    style={{
                        cursor: "pointer"
                    }}
                    firstValue={"Organisation"}
                    secondValue={"Card Details"} navigatePage={() => { organisationBankDataCancel() }} />
                <div className='form-top-main'>
                    <div>
                        <div className='leftnavigation-form'>
                            <div className='card-stepper'>
                                <div className='testing-stepper testing-stepper-card'>
                                    <div className='contents'>
                                        <CardOverview
                                            className={`left-icon ${![""].includes(iconStatus.organisationDetails) && "active"}`}
                                            style={(!isEditMode) ? { pointerEvents: "none", cursor: "none" } : {}}
                                            onClick={() => {
                                                orginationMenueClick()
                                            }}
                                        />
                                        <div
                                            className={`content-click ${componentNavigator === "form-page" ? "active-page" : ""}`}
                                            style={(!isEditMode) ? { pointerEvents: "none", cursor: "none" } : {}}
                                            onClick={() => {
                                                orginationMenueClick()
                                            }}
                                        >
                                            Card Overview<span className='star-span'>*</span>
                                        </div>
                                        <div className='tick-image-green'>
                                            {(iconStatus.organisationDetails === "inprogress") && <InProgerss />}
                                            {iconStatus.organisationDetails === "correct" && <Correct />}
                                        </div>
                                        <div className={`vertical-line-stepper`} >
                                        </div>
                                    </div>
                                    <div className='contents'>
                                        <ControlCentre
                                            className={`left-icon ${![""].includes(iconStatus.adminDetails) && "active"}`}
                                            style={(!isEditMode) ? { pointerEvents: "none", cursor: "none" } : {}}
                                            onClick={() => {
                                                goNext()
                                            }} />
                                        <div
                                            className={`content-click ${componentNavigator === "control-page" ? "active-page" : ""}`}
                                            style={(!isEditMode) ? { pointerEvents: "none", cursor: "none" } : {}}
                                            onClick={() => {
                                                goNext()
                                            }}
                                        >
                                            Control Centre
                                        </div>
                                        <div className='tick-image-green'>
                                            {iconStatus.adminDetails === "notwork" && <NotWork />}
                                            {(iconStatus.adminDetails === "inprogress") && <InProgerss />}
                                            {iconStatus.adminDetails === "correct" && <Correct />}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {componentNavigator === "form-page" &&
                                <div className='card-overview-scroll'>
                                    <div className='card-overview-header'>{"Card Overview"}</div>
                                    {/* <div className='form-top-main'> */}
                                    <div className='form-input'>
                                        <div className={'dropdown-user'} key={uuidv4()}>
                                            <DropdownComponent
                                                options={cardType}
                                                title={"Card Type"}
                                                required={true}
                                                isDisabled={true}
                                                // border={userDataErrors.issuer ? "1px solid red" : "1px solid #A9C3DC"}
                                                getData={(val) => {
                                                    setIsFormDirty(true)
                                                    setOrganisationCardData((prevState) => ({
                                                        ...prevState,
                                                        issuer: val.value,
                                                    }));
                                                    dispatch(resetFormModified(true));
                                                }}
                                                defaultValue={[{ label: "Commercial Card", value: "Commercial Card" }]}
                                            />
                                        </div>
                                        <InputComponent
                                            height={"40px"}
                                            width={"300px"}
                                            margin={"0px 60px 30px 0px"}
                                            padding={"0px 0px 0px 10px"}
                                            border={userDataErrors.nameOnCard ? "1px solid red" : "1px solid #A9C3DC"}
                                            borderRadius={"0px"}
                                            backgroundColor={"white"}
                                            color={"black"}
                                            type={"mobile"}
                                            // disabled={organisationCardData.status === "Pending Approval"}
                                            maxLength={100}
                                            inputTitle={"Name on Card"}
                                            placeHolder={"Enter Name on Card"}
                                            required={true}
                                            inputValue={organisationCardData.nameOnCard}
                                            getUser={(value: any) => {
                                                setIsFormDirty(true)
                                                const onlyAlphabet = value.replace(/[^a-zA-Z ]/g, "")
                                                setOrganisationCardData((prevState) => ({
                                                    ...prevState,
                                                    nameOnCard: onlyAlphabet,
                                                }));
                                                dispatch(resetFormModified(true));
                                                isEditMode && setIconStatus({ ...iconStatus, organisationDetails: "inprogress" })
                                            }}
                                        />
                                        <div className='cardNumber-show-icon'>
                                            <div className='mastercard-main' style={isEditMode && isCardNumberDisabled ? { opacity: "0.5" } : { opacity: "1" }}>
                                                <InputComponent
                                                    height={"40px"}
                                                    width={"300px"}
                                                    margin={"0px 10px 30px 0px"}
                                                    padding={"0px 0px 0px 10px"}
                                                    border={userDataErrors.cardNumber ? "1px solid red" : "1px solid #A9C3DC"}
                                                    borderRadius={"0px"}
                                                    backgroundColor={"white"}
                                                    color={"black"}
                                                    type={"mobile"}
                                                    disabled={isEditMode && isCardNumberDisabled}
                                                    maxLength={19}
                                                    inputTitle={"Card Number"}
                                                    placeHolder={"Enter Card Number"}
                                                    required={true}
                                                    inputValue={organisationCardData.cardNumber}
                                                    getUser={(value: any) => {
                                                        setIsFormDirty(true)
                                                        handleCardNumberChange(value)
                                                        dispatch(resetFormModified(true));
                                                        isEditMode && setIconStatus({ ...iconStatus, organisationDetails: "inprogress" })
                                                    }}

                                                />
                                                {cardImg && <img className='master-card' src={cardImg} alt="" />}
                                            </div>
                                            {isEditMode &&
                                                <div className='cardNumber-eye-icon' onClick={() => {
                                                    if (!isHidden) {
                                                        showCardNumberOtpModel()
                                                    } else {
                                                        setIsHidden(false)
                                                        setIsCardNumberDisabled(true)
                                                        setOrganisationCardData((prevState) => ({
                                                            ...prevState,
                                                            cardNumber: showMaskedCardNumber,
                                                        }));
                                                    }
                                                }}>
                                                    {otpVerification ?
                                                        <IoIosEyeOff color='#004E9C' height={"14px"} /> :
                                                        <IoIosEye color='#004E9C' height={"14px"} />
                                                    }
                                                    <span className='tooltip-text'>Show/Hide Card Number</span>
                                                </div>
                                            }
                                        </div>

                                    </div>
                                    <div className='form-input'>
                                        <div className='cardCatrgory-plus'>
                                            <div className={userDataErrors.cardCategory ? 'setError-cetegory' : 'dropdown-user-category'} key={uuidv4()}>
                                                <DropdownComponent
                                                    options={cardCategory}
                                                    title={"Card Category"}
                                                    required={true}
                                                    // isDisabled={organisationCardData.status === "Pending Approval"}
                                                    // border={userDataErrors.cardCategory ? "1px solid red" : "1px solid #A9C3DC"}
                                                    getData={(val) => {
                                                        setIsFormDirty(true)
                                                        setOrganisationCardData((prevState) => ({
                                                            ...prevState,
                                                            cardCategory: val.value,
                                                            cardCategoryId: val.id

                                                        }));
                                                        dispatch(resetFormModified(true));
                                                        isEditMode && setIconStatus({ ...iconStatus, organisationDetails: "inprogress" })
                                                    }}
                                                    defaultValue={[{ label: organisationCardData.cardCategory ? organisationCardData.cardCategory : "Select", value: organisationCardData.cardCategory }]}
                                                />
                                            </div>
                                        </div>
                                        <div className={userDataErrors.issuer ? 'setError-issuer' : 'dropdown-user'} key={uuidv4()}>
                                            <DropdownComponent
                                                options={bankValues}
                                                title={"Issuer"}
                                                required={true}
                                                // isDisabled={organisationCardData.status === "Pending Approval"}
                                                // border={userDataErrors.issuer ? "1px solid red" : "1px solid #A9C3DC"}
                                                getData={(val) => {
                                                    setIsFormDirty(true)
                                                    setOrganisationCardData((prevState) => ({
                                                        ...prevState,
                                                        issuer: val.id,
                                                        issuerName: val.value
                                                    }));
                                                    dispatch(resetFormModified(true));
                                                    isEditMode && setIconStatus({ ...iconStatus, organisationDetails: "inprogress" })
                                                }}
                                                defaultValue={[{ label: organisationCardData.issuerName ? organisationCardData.issuerName : "Select", value: organisationCardData.issuerName }]}
                                            />
                                        </div>
                                        <InputComponent
                                            height={"40px"}
                                            width={"300px"}
                                            margin={"0px 60px 30px 0px"}
                                            padding={"0px 0px 0px 10px"}
                                            border={userDataErrors.validFrom ? "1px solid red" : "1px solid #A9C3DC"}
                                            borderRadius={"0px"}
                                            backgroundColor={"white"}
                                            // disabled={organisationCardData.status === "Pending Approval"}
                                            color={"black"}
                                            type={"text"}
                                            maxLength={5}
                                            inputTitle={"Valid From"}
                                            placeHolder={"MM / YY"}
                                            required={false}
                                            inputValue={organisationCardData.validFrom}
                                            getUser={(value: any) => {
                                                setIsFormDirty(true)
                                                const validFrom = value
                                                    .replace(/[^0-9]/g, "")
                                                    .replace(/(.{2})(?=.)/, "$1/");
                                                setOrganisationCardData((prevState) => ({
                                                    ...prevState,
                                                    validFrom: validFrom,
                                                }));
                                                dispatch(resetFormModified(true));
                                                isEditMode && setIconStatus({ ...iconStatus, organisationDetails: "inprogress" })
                                            }}
                                        />



                                    </div>
                                    <div className='form-input'>
                                        <InputComponent
                                            height={"40px"}
                                            width={"300px"}
                                            margin={"0px 60px 30px 0px"}
                                            padding={"0px 0px 0px 10px"}
                                            border={userDataErrors.validThru ? "1px solid red" : "1px solid #A9C3DC"}
                                            borderRadius={"0px"}
                                            backgroundColor={"white"}
                                            // disabled={organisationCardData.status === "Pending Approval"}
                                            color={"black"}
                                            type={"text"}
                                            maxLength={5}
                                            inputTitle={"Valid Thru"}
                                            placeHolder={"MM / YY"}
                                            required={true}
                                            inputValue={organisationCardData.validThru}
                                            getUser={(value: any) => {
                                                setIsFormDirty(true)

                                                const validThru = value
                                                    .replace(/[^0-9]/g, "")
                                                    .replace(/(.{2})(?=.)/, "$1/");
                                                setOrganisationCardData((prevState) => ({
                                                    ...prevState,
                                                    validThru: validThru,
                                                }));
                                                dispatch(resetFormModified(true));
                                                isEditMode && setIconStatus({ ...iconStatus, organisationDetails: "inprogress" })
                                            }}
                                        />
                                        <div className='Credit-period'>
                                            <div>
                                                <InputComponent
                                                    height={"40px"}
                                                    width={"230px"}
                                                    margin={"0px 60px 30px 0px"}
                                                    padding={"0px 0px 0px 10px"}
                                                    border={userDataErrors.creditPeriod ? "1px solid red" : "1px solid #A9C3DC"}
                                                    borderRadius={"0px"}
                                                    backgroundColor={"white"}
                                                    // disabled={organisationCardData.status === "Pending Approval"}
                                                    color={"black"}
                                                    type={"text"}
                                                    maxLength={2}
                                                    inputTitle={"Credit Period"}
                                                    placeHolder={"Enter Credit Period"}
                                                    required={true}
                                                    inputValue={organisationCardData.creditPeriod}
                                                    getUser={(value: any) => {
                                                        setIsFormDirty(true)

                                                        const onlyNumber = value.replace(/[^0-9]/g, "")
                                                        setOrganisationCardData((prevState) => ({
                                                            ...prevState,
                                                            creditPeriod: onlyNumber,
                                                        }));
                                                        dispatch(resetFormModified(true));
                                                        isEditMode && setIconStatus({ ...iconStatus, organisationDetails: "inprogress" })
                                                    }}

                                                />
                                            </div>
                                            <div className='credit-period-days'
                                            // style={{ border: userDataErrors.creditPeriod ? "1px solid red" : "1px solid #A9C3DC" }}
                                            >Days</div>
                                        </div>
                                        <div className='Credit-period'>
                                            <InputComponent
                                                height={"40px"}
                                                width={"180px"}
                                                margin={"0px 60px 30px 0px"}
                                                padding={"0px 0px 0px 10px"}
                                                border={userDataErrors.billingCycle ? "1px solid red" : "1px solid #A9C3DC"}
                                                borderRadius={"0px"}
                                                backgroundColor={"white"}
                                                // disabled={organisationCardData.status === "Pending Approval"}
                                                color={"black"}
                                                type={"text"}
                                                maxLength={2}
                                                inputTitle={"Billing Cycle"}
                                                placeHolder={"Enter Billing Cycle"}
                                                required={true}
                                                inputValue={organisationCardData.billingCycle}
                                                getUser={(value: any) => {
                                                    setIsFormDirty(true)

                                                    const onlyNumber = value.replace(/[^0-9]/g, "")
                                                    setOrganisationCardData((prevState) => ({
                                                        ...prevState,
                                                        billingCycle: onlyNumber,
                                                    }));
                                                    dispatch(resetFormModified(true));
                                                    isEditMode && setIconStatus({ ...iconStatus, organisationDetails: "inprogress" })
                                                }}

                                            />
                                            <div className='billing-cycle'
                                            // style={{ border: userDataErrors.billingCycle ? "1px solid red" : "1px solid #A9C3DC" }}
                                            >Every Month</div>
                                        </div>
                                        <div className='dropdown-input'>
                                            <InputComponent
                                                height={"41px"}
                                                width={"200px"}
                                                margin={"0px 60px 30px 0px"}
                                                padding={"0px 5px 0px 30px"}
                                                textAlign={"right"}
                                                border={userDataErrors.cardMaxLimit ? "1px solid red" : "1px solid #A9C3DC"}
                                                borderRadius={"0px"}
                                                backgroundColor={"white"}
                                                // disabled={organisationCardData.status === "Pending Approval"}
                                                color={"black"}
                                                type={"mobile"}
                                                maxLength={10}
                                                inputTitle={"Card Max Limit"}
                                                placeHolder={"Enter Card Max Limit"}
                                                required={false}
                                                inputValue={formatAmount(organisationCardData.cardMaxLimit)}
                                                getUser={(value: any) => {
                                                    setIsFormDirty(true)
                                                    const onlyNumber = value.replace(/[^0-9]/g, "");

                                                    setOrganisationCardData((prevState: any) => ({
                                                        ...prevState,
                                                        cardMaxLimit: onlyNumber,
                                                    }));
                                                    dispatch(resetFormModified(true));
                                                    isEditMode && setIconStatus({ ...iconStatus, organisationDetails: "inprogress" })
                                                }}
                                            />

                                            <div className='dropdown-position card-min'>
                                                <div className={userDataErrors.cardMaxLimitInr ? 'setError' : 'dropdown-card'}>
                                                    <DropdownComponent
                                                        options={bankValues}
                                                        title={""}
                                                        required={false}
                                                        isDisabled={true}
                                                        // border={userDataErrors.cardMaxLimitInr ? "1px solid red" : "1px solid #A9C3DC"}
                                                        getData={(val) => {
                                                            setIsFormDirty(true)
                                                            setOrganisationCardData((prevState) => ({
                                                                ...prevState,
                                                                cardMaxLimitInr: val.value,
                                                            }));
                                                            dispatch(resetFormModified(true));
                                                        }}
                                                        defaultValue={[{ label: organisationCardData.cardMaxLimitInr ? organisationCardData.cardMaxLimitInr : "INR", value: organisationCardData.cardMaxLimitInr }]}
                                                    />
                                                </div>
                                            </div>
                                            <div>
                                                <img className='rupee-symbol-position' src={rupeeImg} alt="" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='form-input'
                                        style={organisationCardData.status === "Pending Approval" || organisationCardData.status === "Approved" || organisationCardData.status === "Declined" ?
                                            { pointerEvents: "none", opacity: 0.5 } : { pointerEvents: "auto", opacity: 1 }}
                                    >
                                        <div style={{ width: "auto" }}>

                                        </div>
                                        <div className='radio-input user-form-radio-input'>
                                            <p>Is Active<span>*</span></p>
                                            <div className='radio-btn-main'>
                                                <RadioOrCheckbox
                                                    value={"Yes"}
                                                    name={"ActiveCard"}
                                                    type={"radio"}
                                                    margin={"0px 30px 0px 0px"}
                                                    disabled={true}
                                                    checkedValue={organisationCardData.isActive}
                                                    getVal={(val) => {
                                                        setIsFormDirty(true)
                                                        setOrganisationCardData((prevState) => ({
                                                            ...prevState,
                                                            isActive: val,
                                                            remarks: ""
                                                        }));
                                                        dispatch(resetFormModified(true));
                                                    }}
                                                />
                                                <div style={
                                                    !isEditMode && isEditMode ?
                                                        { pointerEvents: "auto", opacity: 1 } : { pointerEvents: "none", opacity: 0.5 }

                                                }>
                                                    <RadioOrCheckbox
                                                        value={"No"}
                                                        type={"radio"}
                                                        name={"Activecard"}
                                                        disabled={true}
                                                        checkedValue={organisationCardData.isActive}
                                                        getVal={(val) => {
                                                            setIsFormDirty(true)
                                                            setOrganisationCardData((prevState) => ({
                                                                ...prevState,
                                                                isActive: val,
                                                            }));
                                                            dispatch(resetFormModified(true));
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        {organisationCardData.isActive === "No" &&
                                            <div style={
                                                { pointerEvents: "none", opacity: 0.5 }
                                            }>
                                                <InputComponent
                                                    height={"40px"}
                                                    width={windowWidth > 1250 ? "520px" : "350px"}
                                                    margin={"0px 60px 30px 0px"}
                                                    padding={"0px 0px 0px 10px"}
                                                    border={userDataErrors.remarks ? "1px solid red" : "1px solid #A9C3DC"}
                                                    borderRadius={"0px"}
                                                    backgroundColor={"white"}
                                                    color={"black"}
                                                    maxLength={250}
                                                    type={"text"}
                                                    disabled={true}
                                                    inputTitle={"Remarks"}
                                                    required={true}
                                                    placeHolder={"Reason"}
                                                    inputValue={organisationCardData.remarks}
                                                    getUser={(value: any) => {
                                                        setIsFormDirty(true)
                                                        setOrganisationCardData((prevState) => ({
                                                            ...prevState,
                                                            remarks: value,
                                                        }));
                                                        dispatch(resetFormModified(true));
                                                    }}
                                                />
                                            </div>
                                        }
                                    </div>
                                    {isEditMode && organisationCardData.status !== "Pending Approval" &&
                                        <>
                                            <div style={isEditMode ?
                                                { pointerEvents: "none", opacity: 1 } : { pointerEvents: "auto", opacity: 1 }}>
                                                <div className='bank-approval'>
                                                    <div className='approval-title'>
                                                        {organisationCardData.status === "Declined" ? (
                                                            <p>Your request to add a card master record has been declined.</p>
                                                        ) : (<p>Your request to add a card master record has been approved.</p>)}
                                                    </div>
                                                    <div className='approval-radio-input'>
                                                        <div className='approval-radio-btn'
                                                            style={{ pointerEvents: "none", opacity: 0.5 }}
                                                        >
                                                            <RadioOrCheckbox
                                                                value={"Approved"}
                                                                type={"radio"}
                                                                margin={"0px 30px 0px 0px"}
                                                                disabled={true}
                                                                checkedValue={organisationCardData.status}
                                                                getVal={(val) => {
                                                                    setIsFormDirty(true)
                                                                    setOrganisationCardData((prevState) => ({
                                                                        ...prevState,
                                                                        status: val,
                                                                        declineRemarks: ""
                                                                    }));
                                                                    dispatch(resetFormModified(true));
                                                                    setIsDisabled(false)
                                                                }}
                                                            />
                                                            <div>
                                                                <RadioOrCheckbox
                                                                    value={"Declined"}
                                                                    type={"radio"}
                                                                    disabled={true}
                                                                    checkedValue={organisationCardData.status}
                                                                    getVal={(val) => {
                                                                        setIsFormDirty(true)
                                                                        setOrganisationCardData((prevState) => ({
                                                                            ...prevState,
                                                                            status: val,
                                                                        }));
                                                                        dispatch(resetFormModified(true));
                                                                        setIsDisabled(false)
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='decline-reason '>
                                                    <div className='reason-area'
                                                        style={{ pointerEvents: "none", opacity: 0.5 }}
                                                    >
                                                        <InputComponent
                                                            height={"60px"}
                                                            width={"90%"}
                                                            margin={"10px 60px 30px 0px"}
                                                            padding={"0px 0px 0px 10px"}
                                                            border={userDataErrors.declineRemarks ? "1px solid red" : "1px solid #A9C3DC"}
                                                            borderRadius={"0px"}
                                                            backgroundColor={"white"}
                                                            color={"black"}
                                                            maxLength={250}
                                                            type={"text"}
                                                            readOnly
                                                            disabled={organisationCardData.status === "Declined" ? false : true}
                                                            inputTitle={"Reason for Declining Request"}
                                                            required={true}
                                                            placeHolder={"Reason"}
                                                            inputValue={organisationCardData.declineRemarks}
                                                            getUser={(value: any) => {
                                                                setIsFormDirty(true)
                                                                setOrganisationCardData((prevState) => ({
                                                                    ...prevState,
                                                                    declineRemarks: value,
                                                                }));
                                                                dispatch(resetFormModified(true));
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    }
                                </div>
                            }
                            {componentNavigator === "control-page" &&
                                <div className='control-page-main'>
                                    <div>
                                        <div className='card-overview-header'>{"Control Centre"}</div>
                                        <p className='transaction-limits-title'>{"Transaction Limits"}</p>
                                        <div className='form-input'>
                                            <div className='radio-input user-form-radio-input'>

                                                <div className='radio-btn-transction-limit'>
                                                    <p>Custom Limits</p>
                                                    <RadioOrCheckbox
                                                        value={"Yes"}
                                                        type={"radio"}
                                                        name={"customLimits"}
                                                        // disabled={organisationCardData.status === "Pending Approval"}
                                                        margin={"0px 30px 0px 0px"}
                                                        checkedValue={organisationCardData.customerLimits}
                                                        getVal={(val) => {
                                                            setIsFormDirty(true)
                                                            setOrganisationCardData((prevState) => ({
                                                                ...prevState,
                                                                customerLimits: val,
                                                                remarks: ""
                                                            }));
                                                            dispatch(resetFormModified(true));
                                                        }}
                                                    />
                                                    <div>
                                                        <RadioOrCheckbox
                                                            value={"No"}
                                                            name={"customlimits"}
                                                            type={"radio"}
                                                            // disabled={organisationCardData.status === "Pending Approval"}
                                                            checkedValue={organisationCardData.customerLimits}
                                                            getVal={(val) => {
                                                                setIsFormDirty(true)
                                                                setOrganisationCardData((prevState) => ({
                                                                    ...prevState,
                                                                    customerLimits: val,
                                                                    dailyVolumeMaximumAmount: val === "No" ? "" : prevState.dailyVolumeMaximumAmount,
                                                                    singleTransactionMaximumAmount: val === "No" ? "" : prevState.singleTransactionMaximumAmount,

                                                                }));
                                                                dispatch(resetFormModified(true));
                                                            }}

                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='transaction-control'
                                            style={organisationCardData.customerLimits === "No" ?
                                                { pointerEvents: "none", opacity: 0.5 } : { pointerEvents: "auto", opacity: 1 }
                                            }
                                        >
                                            <div className='form-input'>
                                                <div>
                                                    <p className='transaction-limits-control'>Daily Volume</p>
                                                    <div className='mt-20 dropdown-input'>
                                                        <div>
                                                            <InputComponent
                                                                height={"41px"}
                                                                width={"248px"}
                                                                margin={"0px 60px 30px 0px"}
                                                                padding={"0px 5px 0px 30px"}
                                                                textAlign={"right"}
                                                                border={userDataErrors.dailyVolumeMaximumAmount ? "1px solid red" : "1px solid #A9C3DC"}
                                                                borderRadius={"0px"}
                                                                backgroundColor={"white"}
                                                                color={"black"}
                                                                type={"mobile"}
                                                                disabled={organisationCardData.customerLimits === "No" ? true : false}
                                                                maxLength={10}
                                                                inputTitle={"Maximum Amount"}
                                                                placeHolder={"Enter Maximum Amount"}
                                                                required={false}
                                                                inputValue={formatAmount(organisationCardData.dailyVolumeMaximumAmount)}
                                                                getUser={(value: any) => {

                                                                    setIsFormDirty(true)
                                                                    const number = value.replace(/[^0-9]/g, "")
                                                                    setOrganisationCardData((prevState) => ({
                                                                        ...prevState,
                                                                        dailyVolumeMaximumAmount: number,

                                                                    }));
                                                                    dispatch(resetFormModified(true));

                                                                }}
                                                            />
                                                        </div>
                                                        <div className='dropdown-position card-min'>
                                                            <div className={userDataErrors.dailyMaximumAmountInr ? 'setError' : 'dropdown-card'} key={uuidv4()}>
                                                                <DropdownComponent
                                                                    options={bankValues}
                                                                    title={""}
                                                                    required={false}
                                                                    isDisabled={true}
                                                                    // border={userDataErrors.dailyMaximumAmountInr ? "1px solid red" : "1px solid #A9C3DC"}
                                                                    getData={(val) => {
                                                                        setIsFormDirty(true)
                                                                        setOrganisationCardData((prevState) => ({
                                                                            ...prevState,
                                                                            dailyMaximumAmountInr: val.value,
                                                                        }));
                                                                        dispatch(resetFormModified(true));
                                                                    }}
                                                                    defaultValue={[{ label: organisationCardData.dailyMaximumAmountInr ? organisationCardData.dailyMaximumAmountInr : "INR", value: organisationCardData.dailyMaximumAmountInr }]}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <img className='rupee-symbol' src={rupeeImg} alt="" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <p className='transaction-limits-control'>Single Transaction</p>
                                                    <div className='mt-20 dropdown-input'>
                                                        <div>
                                                            <InputComponent
                                                                height={"41px"}
                                                                width={"248px"}
                                                                margin={"0px 60px 30px 0px"}
                                                                padding={"0px 5px 0px 30px"}
                                                                textAlign={"right"}
                                                                border={userDataErrors.singleTransactionMaximumAmount ? "1px solid red" : "1px solid #A9C3DC"}
                                                                borderRadius={"0px"}
                                                                backgroundColor={"white"}
                                                                color={"black"}
                                                                type={"mobile"}
                                                                disabled={organisationCardData.customerLimits === "No" ? true : false}
                                                                maxLength={10}
                                                                inputTitle={"Maximum Amount"}
                                                                placeHolder={"Enter Maximum Amount"}
                                                                required={false}
                                                                inputValue={formatAmount(organisationCardData.singleTransactionMaximumAmount)}
                                                                getUser={(value: any) => {
                                                                    setIsFormDirty(true)
                                                                    const number = value.replace(/[^0-9]/g, "")


                                                                    setOrganisationCardData((prevState) => ({
                                                                        ...prevState,
                                                                        singleTransactionMaximumAmount: number,
                                                                    }));
                                                                    dispatch(resetFormModified(true));
                                                                }}
                                                            />
                                                        </div>
                                                        <div className='dropdown-position card-min'>
                                                            <div className={userDataErrors.singleMaximumAmountInr ? 'setError' : 'dropdown-card'} key={uuidv4()}>
                                                                <DropdownComponent
                                                                    options={bankValues}
                                                                    title={""}
                                                                    required={false}
                                                                    isDisabled={true}
                                                                    // border={userDataErrors.singleMaximumAmountInr ? "1px solid red" : "1px solid #A9C3DC"}
                                                                    getData={(val) => {
                                                                        setIsFormDirty(true)
                                                                        setOrganisationCardData((prevState) => ({
                                                                            ...prevState,
                                                                            singleMaximumAmountInr: val.value,
                                                                        }));
                                                                        dispatch(resetFormModified(true));
                                                                    }}
                                                                    defaultValue={[{ label: organisationCardData.singleMaximumAmountInr ? organisationCardData.singleMaximumAmountInr : "INR", value: organisationCardData.singleMaximumAmountInr }]}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <img className='rupee-symbol' src={rupeeImg} alt="" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <p className='transaction-limits-mapping'>{"Transaction Limits Mapping"}</p>
                                        <div className='mt-20'>
                                            <div className='form-input ml-5'>

                                                <div className={userDataErrors.transactionMappingDepartment ? 'setError-expense' : 'dropdown-user'} key={uuidv4()}>
                                                    <DropdownComponent
                                                        options={departmentValues}
                                                        title={"Department/ Unit/ SBU"}
                                                        required={false}
                                                        // isDisabled={organisationCardData.status === "Pending Approval"}
                                                        // border={userDataErrors.transactionMappingDepartment ? "1px solid red" : "1px solid #A9C3DC"}
                                                        getData={(val) => {
                                                            setIsFormDirty(true)
                                                            setTransactionMappingData((prevState: any) => ({
                                                                ...prevState,
                                                                transactionMappingDepartment: val.id,
                                                                transactionMappingDepartmentName: val.value

                                                            }));
                                                            dispatch(resetFormModified(true));
                                                        }}
                                                        defaultValue={[{ label: transactionMappingData.transactionMappingDepartmentName ? transactionMappingData.transactionMappingDepartmentName : "Select", value: transactionMappingData.transactionMappingDepartmentName }]}
                                                    />
                                                </div>
                                                <div className='dropdown-input'>
                                                    <InputComponent
                                                        height={"41px"}
                                                        width={"248px"}
                                                        margin={"0px 60px 30px 0px"}
                                                        padding={"0px 5px 0px 30px"}
                                                        textAlign={"right"}
                                                        border={userDataErrors.transactionMappingMaxLimit ? "1px solid red" : "1px solid #A9C3DC"}
                                                        borderRadius={"0px"}
                                                        backgroundColor={"white"}
                                                        color={"black"}
                                                        type={"mobile"}
                                                        // disabled={organisationCardData.status === "Pending Approval"}
                                                        maxLength={10}
                                                        inputTitle={"Max Limit"}
                                                        placeHolder={"Enter Max Limit"}
                                                        required={false}
                                                        inputValue={formatAmount(transactionMappingData.transactionMappingMaxLimit)}
                                                        getUser={(value: any) => {
                                                            setIsFormDirty(true)
                                                            const number = value.replace(/[^0-9]/g, "")

                                                            setTransactionMappingData((prevState: any) => ({
                                                                ...prevState,
                                                                transactionMappingMaxLimit: number,
                                                            }));
                                                            dispatch(resetFormModified(true));
                                                        }}
                                                    />
                                                    <div className='dropdown-position card-min'>
                                                        <div className={userDataErrors.maxLimitInr ? 'setError' : 'dropdown-card'} key={uuidv4()}>
                                                            <DropdownComponent
                                                                options={bankValues}
                                                                title={""}
                                                                required={false}
                                                                isDisabled={true}

                                                                // border={userDataErrors.maxLimitInr ? "1px solid red" : "1px solid #A9C3DC"}
                                                                getData={(val) => {
                                                                    setIsFormDirty(true)
                                                                    setOrganisationCardData((prevState) => ({
                                                                        ...prevState,
                                                                        maxLimitInr: val.value,
                                                                    }));
                                                                    dispatch(resetFormModified(true));
                                                                }}
                                                                defaultValue={[{ label: organisationCardData.maxLimitInr ? organisationCardData.maxLimitInr : "INR", value: organisationCardData.maxLimitInr }]}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <img className='rupee-symbol' src={rupeeImg} alt="" />
                                                    </div>

                                                </div>
                                                <div className={userDataErrors.transactionMappingPayments ? 'setError-expense' : 'dropdown-user'} key={uuidv4()}>
                                                    <DropdownComponent
                                                        options={payments}
                                                        title={"Payments"}
                                                        required={false}
                                                        // isDisabled={organisationCardData.status === "Pending Approval"}
                                                        // border={userDataErrors.transactionMappingDepartment ? "1px solid red" : "1px solid #A9C3DC"}
                                                        getData={(val) => {
                                                            setIsFormDirty(true)
                                                            setTransactionMappingData((prevState: any) => ({
                                                                ...prevState,
                                                                transactionMappingPayments: val.value,
                                                            }));
                                                            dispatch(resetFormModified(true));
                                                        }}
                                                        defaultValue={[{ label: transactionMappingData.transactionMappingPayments ? transactionMappingData.transactionMappingPayments : "Select", value: transactionMappingData.transactionMappingPayments }]}
                                                    />
                                                </div>
                                                <div className='cardCatrgory-plus'>
                                                    <div className={userDataErrors.transactionMappingExpense ? 'setError-expense' : 'dropdown-user-expense'} key={uuidv4()}>
                                                        <DropdownComponent
                                                            options={expenseValue}
                                                            title={"Expense Type"}
                                                            required={false}
                                                            // isDisabled={organisationCardData.status === "Pending Approval"}
                                                            // border={userDataErrors.cardCategory ? "1px solid red" : "1px solid #A9C3DC"}
                                                            getData={(val) => {
                                                                setIsFormDirty(true)
                                                                setTransactionMappingData((prevState: any) => ({
                                                                    ...prevState,
                                                                    transactionMappingExpense: val.value,
                                                                    expenseId: val.id
                                                                }));
                                                                dispatch(resetFormModified(true));
                                                            }}
                                                            defaultValue={[{ label: transactionMappingData.transactionMappingExpense ? transactionMappingData.transactionMappingExpense : "Select", value: transactionMappingData.transactionMappingExpense }]}
                                                        />
                                                    </div>
                                                    <div className='issuer-plus' onClick={() => {
                                                        setAddExpense(true)
                                                    }}>
                                                        <BsPlusLg color='#004E9C' height={"14px"} />
                                                    </div>

                                                </div>
                                                <div className='add'>
                                                    <ButtonComponent
                                                        title={isDataRetrieved ? "Update" : "Add"}
                                                        height={"50px"}
                                                        width={"150px"}
                                                        // disabled={isDisabled}
                                                        disabled={isDisabled}
                                                        backgroundColor={"#0055D4"}
                                                        color={"white"}
                                                        margin={"0px 8px"}
                                                        className={isDisabled ? "button-component-hover disabled" : "button-component-hover common-btn"}
                                                        handleClick={() => {

                                                            transactionMappingSave()

                                                        }
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <CardFormTableGrid
                                            retriveData={(value: any, index: number) => {
                                                retriveData(value, index)
                                            }}
                                            transactionMapping={transactionMapping}
                                            showmodal={(val: any, index: any) => {
                                                setShowDeleteModel(true);
                                                setDeleteIndex(index);
                                                setCurrentDeletVal(val);
                                            }}
                                        />
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div className='footer-card'>
                    <ButtonComponent
                        title={"Cancel"}
                        height={"50px"}
                        width={"150px"}
                        margin={"0px 8px"}
                        backgroundColor={"#888888"}
                        color={"white"}
                        className={"button-component-hover cancel"}
                        handleClick={organisationBankDataCancel}
                    />
                    {!isEditMode && (componentNavigator !== "form-page") &&
                        <ButtonComponent
                            title={"Previous"}
                            height={"50px"}
                            width={"150px"}
                            margin={"0px 8px"}
                            backgroundColor={"#888888"}
                            color={"white"}
                            className={"button-component-hover cancel"}
                            handleClick={cancel}
                        />
                    }
                    <ButtonComponent
                        title={(isEditMode && componentNavigator === "form-page") ? "Next" :
                            (componentNavigator === "form-page") ? "Next" : "Submit for Approval"}
                        height={"50px"}
                        width={"190px"}
                        backgroundColor={"#0055D4"}
                        disabled={componentNavigator === "control-page" && (showMessage && showType === "success") ? true : false}
                        color={"white"}
                        margin={"0px 8px"}
                        className={componentNavigator === "control-page" && (showMessage && showType === "success") ? "button-component-hover disabled" : "button-component common-btn"}
                        handleClick={
                            componentNavigator === "form-page" ? goNext : organisationCardDataSave
                        }
                    />
                </div>
            </MainPageCard >
            {
                showAlertBox &&
                <div className='alert-warp'>
                    <AlertBox type={showCardNumberMessage == "success" || showCardNumberMessage == "danger" ? showCardNumberMessage : showType} message={showMessage} />
                </div>

            }
            {
                isLoading &&
                <Loading />
            }
            {
                showChangesModal &&
                <Changesmodal
                    closeModal={() => setShowChangesModal(false)}
                    leavePage={() => {
                        setShowChangesModal(false);
                        setIsFormDirty(false)
                        userNavigateFunction("grid")
                        getEditMode(false)
                    }}
                    handleClose={() => setShowChangesModal(false)} />
            }
            {addExpense &&
                <AddExpenseValue
                    organisationCardData={
                        organisationCardData
                    }
                    transactionMapping={transactionMapping}
                    setTransactionMappingData={setTransactionMappingData}
                    clearValue={() => {
                        setAddExpense(false)
                    }}
                    getExpenseType={() => {
                        fetchExpenseData(`/companyadmin/organisation/card-expense`, setExpenseValue, "CardExpenseDetails")
                    }}
                    setExpenseValue={setExpenseValue}
                    setExpenseValueGrid={setExpenseValueGrid}
                    expenseValue={expenseValueGrid}
                />

            }
            {
                showDeleteModel &&
                <div >
                    <DeleteModal
                        getDelete={() => { TransactionMappingDelete() }}

                        clearValue={(value) => {
                            setShowDeleteModel(value);
                            setCurrentDeletVal({})
                        }}
                        modelType={"grid-delete"}

                    />
                </div>
            }
            {
                otpVerification &&
                <div className='otp-model'>
                    <OtpVerifyModal
                        otpVerify={resendOtp}
                        leavePage={() => {
                            setOtpVerification(false)
                            userNavigateFunction("form")
                        }
                        }
                        isLocked={isLocked}
                        setIsLocked={setIsLocked}
                        getStatus={async (stringFromArray: any) => {
                            let res;
                            try {

                                res = await axiosPrivate.post('companyadmin/bank/verify-otp/update', {
                                    otp: stringFromArray,
                                    approvedBy: auth.token,

                                });
                                if (res.status === 200) {
                                    setIsLocked(true)
                                    ShowCardNumber()
                                    setOtpVerification(false)
                                }
                            } catch (error: any) {
                                setShowAlertBox(true);
                                setShowCardNumberMessage("danger")
                                setShowMessage(error.response.data.error)
                                setTimeout(() => {
                                    setShowAlertBox(false);
                                }, 5000);
                            }

                            // organisationCardDataSave()
                            // userNavigateFunction("grid")
                        }
                        }
                    />
                </div>
            }

        </>
    )
}

export default Form;