
import React, { useState, useEffect, useRef } from 'react'
import MainPageCard from '../common-component/cards/main-page-card';
import IconButton from '../common-component/form-elements/icon-button';
import AgGrid, { AgGridRef } from '../common-component/grids-and-tables/ag-grid';
import { virtualCardColumnData, rowDatathis } from '../../config/virtualcard/virtualcard-grid';
import '../../styles/virtualcard/grid.scss'
import useAxiosPrivate from '../../services/hooks/useaxios-private';
import GridFilter from '../common-component/grids-and-tables/grid-filter';
import { v4 as uuidv4 } from 'uuid';
import FileUpload from '../common-component/modals/file-upload-modal';
import DeleteModal from '../common-component/modals/delete-modal';
import AlertBox from '../common-component/alert-box';
import NavigateHeader from '../common-component/navigate-header';
import { Filter } from '../../helpers/filtterFunction';


interface Props {
    virtualCard: (val: string) => void;
    getEditData?: ((name: string, data: any) => void) | undefined;
    // getDelete?: () => {};
    getEditMode: (value: boolean) => void;

}
interface IFilterValue {
    [key: string]: {
        columnName: string;
        filterType: string;
        filterWord: string;
    };
}

export default function Grid({ virtualCard, getEditMode, getEditData }: Props) {
    const gridDataDownloadRef = useRef<AgGridRef>(null);
    const [showType, setShowType] = useState("warning") // error message showType
    const [showMessage, setShowMessage] = useState<string>("") // error message showMessage
    const [showAlertBox, setShowAlertBox] = useState(false) // error message ShowAlertBox
    const [rowData, setRowData] = useState<any>([]);
    const [curruntData, setCurruntData] = useState<any>();
    const [fileUpload, setFileUpload] = useState<boolean>(false);
    const [filter, setFilter] = useState(false);
    const [filterRestore, setFilterRestore] = useState<IFilterValue>({})
    const [orginalRowData, setOrginalRowData] = useState<any>([]);
    const [filterMethod, setFilterMethod] = useState("")
    const [showDeleteModel, setShowDeleteModel] = useState<boolean>(false)
    const [uploadedDataStore, setuploadedDataStore] = useState<any>([]);
    const axiosPrivate = useAxiosPrivate();

    useEffect(() => {
        fetchData();
    }, []);

    // Get data for grid API
    const fetchData = async () => {
        try {
            const response = await axiosPrivate.get('virtual-card/table-data/get');
            setRowData(response.data.decryptData);
            setOrginalRowData(response.data.decryptData)
        } catch (error) {
        }
    };


    async function getUserData(data: any) {
        // if (data.status === "Processing") {
        //     return
        // }
        await axiosPrivate.get(`/virtual-card/form/get/${data.id}`)
            .then(response => {
                console.log(response.data.decryptData,"545656")
                getEditData && getEditData("form", response.data.decryptData);
            })
            .catch(error => {
                console.error(error);
            });
    }

   // Function for Filter values
   const handleFilter = (value: any, filterType: string) => {
    Filter(value, filterType, orginalRowData, setRowData)
}


    // handle download grid
    const handleDownloadExcel = () => {
        gridDataDownloadRef.current!.onBtExport();
    }

    const clearValue = (val: any) => {
        setFileUpload(val);
    }
    // get virtualCard  data for doubleclick
    // function getUserData(data: any) {
    //     // if(data.status ==="Waiting for Approval") return;
    //     modeFunction(true)
    //     getEditData && getEditData("form", data);

    // }
    // current data update
    const getCurruntData = (val: any) => {
        setCurruntData(val);
    }

    // Timeout function for messages
    const clearAleart = () => {
        const timer = setTimeout(() => {
            setShowAlertBox(false);
            setShowMessage("");
            clearTimeout(timer);
        }, 5000);
    }

    // Delete function for delete data in grid
    const getDelete = async () => {
        if (curruntData.length === 1) {
            axiosPrivate.delete(`companyadmin/bank/delete/${curruntData[0].companyBankId}`)
                .then(response => {
                    if (response.status === 200) {
                        setShowAlertBox(true)
                        setShowType("success")
                        setShowMessage(response.data.message)
                        clearAleart()
                        setCurruntData([])
                        getEditMode(false)
                        setShowDeleteModel(false)
                        fetchData()
                    }
                })
                .catch(error => {
                    setShowAlertBox(true);
                    setShowType("warning")
                    setShowMessage(error.response.data.error)
                    setShowDeleteModel(false)
                    clearAleart()
                });
        } else {
            let idList: any = [];
            curruntData.map((e: any, i: number) => {
                idList.push({
                    id: e.companyBankId
                })
            })
            try {
                const res = await axiosPrivate.delete("companyadmin/bank/multiple-delete", {
                    data: {
                        data: idList
                    }
                })

                if (res.status === 200) {
                    setShowAlertBox(true)
                    setShowType("success")
                    setShowMessage(res.data.message)
                    clearAleart()
                    getEditMode(false)
                    setCurruntData([])
                    setShowDeleteModel(false)
                    fetchData()
                }
            } catch (error: any) {
                setShowAlertBox(true)
                setShowType("danger")
                setShowMessage(error.response.data.error)
                clearAleart()
                setShowDeleteModel(false)
            }
        }
    };

    // upload bank data 
    const getUploadedUserData = (val: any) => {
        setuploadedDataStore(val);
    }


    return (
        <MainPageCard>
            <div className='header-main'>
                <div className='navigater'>
                    <div className='navigate-header-component'>
                        <NavigateHeader firstValue={"Organisation"}
                            style={{ cursor: "pointer" }}
                            secondValue={"Virtual Card Details"}
                        />
                        {/* <ul className='bread-crumbs' >
                            <li
                                onClick={() => {
                                }}><span>Virtual Card</span>
                            </li>
                        </ul> */}
                    </div>
                </div>

                <div className='icon-buttons'>
                    <IconButton
                        iconName={"Filter"}
                        height={"40px"}
                        width={"40px"}
                        fontSize={"30px"}
                        margin={"0px 8px"}
                        color={"white"}
                        backgroundColor={"#0055D4"}
                        hover={true}
                        handleClick={(event: React.MouseEvent) => {
                            event?.stopPropagation()
                            setFilter(!filter);
                          }}
                    />
                    <IconButton
                        iconName={"Delete"}
                        height={"40px"}
                        width={"40px"}
                        fontSize={"25px"}
                        margin={"0px 8px"}
                        color={"white"}
                        backgroundColor={"#D60000"}
                        hover={false}
                        opacity={"0.5"}
                        cursor={"not-allowed"}
                        handleClick={() => {
                            // if (curruntData?.length > 0) {
                            //     setShowDeleteModel
                            //         (!showDeleteModel)
                            // }
                        }}
                    />
                    <IconButton
                        iconName={"Request"}
                        height={"40px"}
                        width={"100px"}
                        fontSize={"25px"}
                        margin={"0px 8px"}
                        color={"white"}
                        backgroundColor={"#0055D4"}
                        hover={true}
                        handleClick={() => {
                            virtualCard("form")
                        }}
                    />
                    <IconButton
                        iconName={"Upload"}
                        height={"40px"}
                        width={"40px"}
                        fontSize={"25px"}
                        margin={"0px 0px 0px 8px"}
                        color={"white"}
                        backgroundColor={"#0055D4"}
                        hover={false}
                        opacity={"0.5"}
                        cursor={"not-allowed"}
                        handleClick={() => {
                            setFileUpload(false);
                        }}
                    />
                    <div>
                        <IconButton
                            iconName={"Download"}
                            height={"40px"}
                            width={"40px"}
                            fontSize={"25px"}
                            margin={"0px 8px"}
                            color={"white"}
                            backgroundColor={"#0055D4"}
                            hover={true}
                            handleClick={handleDownloadExcel}
                        />
                    </div>
                </div>
            </div>
            <div>
                <AgGrid
                    ref={gridDataDownloadRef}
                    fileName={"VirtualCard"}
                    tabelRowData={rowData}
                    tableColumnData={virtualCardColumnData}
                    doubleClickUpdate={() => { getEditData && getEditData("form", "") }}
                    getEdit={(data) => getUserData(data)}
                    getCurruntData={getCurruntData}

                />
            </div>
            {
                filter &&
                <div className='filter' key={uuidv4()}>
                    <GridFilter
                        filterRestore={filterRestore}
                        filterMethod={filterMethod}
                        tabelRowData={rowData}
                        data={virtualCardColumnData.slice(1)}
                        handleClick={(Value: any, filterType: string) => {
                            if (filterType !== "") {
                                setFilterRestore(Value)
                                setFilter(!filter)
                                setFilterMethod(filterType)
                                handleFilter(Value, filterType)
                                setCurruntData([]);

                            } else {
                                setFilterMethod("")
                                setFilterRestore({})
                                setFilter(!filter)
                                setRowData(orginalRowData)
                                setCurruntData([]);
                            }
                        }}
                    />
                </div>
            }
            {
                fileUpload &&
                <FileUpload clearValue={clearValue} headerData={virtualCardColumnData} templateName={"User"} getUploadedUserData={getUploadedUserData} />
            }
            {
                showDeleteModel &&
                <div >
                    <DeleteModal
                        getDelete={getDelete}
                        clearValue={(value) => { setShowDeleteModel(value) }}
                        modelType={"grid-delete"}

                    />
                </div>
            }
            {
                showAlertBox &&
                <div className='alert-warp'>
                    <AlertBox type={showType} message={showMessage} />
                </div>
            }


        </MainPageCard>
    )
}
