import { useEffect, useState } from 'react'
import ProductAdminDashboard from '../../components/dashboard/product-admin-dashboard';
import useAuth from '../../services/hooks/useauth';
import BankAdminDashboard from '../../components/dashboard/bank-admin-dashboard';
import useAxiosPrivate from "../../services/hooks/useaxios-private";
import CompanyAdminDashboard from '../../components/dashboard/company-admin-dashboard/company-admin-dashboard';
import '../../styles/dashboard/dashboard.scss';

function Dashboard() {
  const [navbarTitle, setNavbarTitle] = useState("");
  const { auth } = useAuth();
  const axiosPrivate = useAxiosPrivate();

  useEffect(() => {
    if (auth.UserPrivilege) {
      setNavbarTitle(auth.UserPrivilege)
    } else {
      fetchData()
    }
  }, [])

  const fetchData = async () => {
    try {
      const response: any = await axiosPrivate.get('/user/get-privilege');
      setNavbarTitle(response.data.UserPrivilege)
    } catch (error) {
      console.log(error);
    }
  }

  return (
    //UserLogDashboard
    <div className='dashboard-component'>
      {
        (navbarTitle === "FingertipAdmin" || navbarTitle === "Customer maker" || navbarTitle === "Customer authorizer") &&
        <ProductAdminDashboard />
      }
      {
        (navbarTitle === "BankAdmin") &&
        <BankAdminDashboard />
      }
      {
        (navbarTitle === "ApprovedCompanyAdmin" || navbarTitle === "Approver" || navbarTitle === "Checker" || navbarTitle === "Maker" || navbarTitle === "Recommender") &&
        <CompanyAdminDashboard />
      }
    </div>
  )
}

export default Dashboard;