import React, { useState, useEffect, useRef } from "react";
import MainPageCard from "../../../common-component/cards/main-page-card";
import NavigateHeader from "../../../common-component/navigate-header";
import IconButton from "../../../common-component/form-elements/icon-button";
import AgGrid, {
  AgGridRef,
} from "../../../common-component/grids-and-tables/ag-grid";
import useAxiosPrivate from "../../../../services/hooks/useaxios-private";
import {
  vendorBankApprovalData,
  rowDatathis,
} from "../../../../config/product-admin/vendor-bank-approval/vendor-bank-approval-config";
import FileUpload from "../../../common-component/modals/file-upload-modal";
import DeleteModal from "../../../common-component/modals/delete-modal";
import GridFilter from "../../../common-component/grids-and-tables/grid-filter";
import { v4 as uuidv4 } from "uuid";
import AlertBox from "../../../common-component/alert-box";
import "../../../../styles/approval/vendor-bank-approval/grid.scss";
import { Filter } from "../../../../helpers/filtterFunction";

interface Props {
  userNavigateFunction: (val: string) => void;
  getEditData?: ((name: string, data: any) => void) | undefined;
  getDelete?: () => void;
  modeFunction: (val: boolean) => void;
  getEditMode: (value: boolean) => void;
}
interface IFilterValue {
  [key: string]: {
    columnName: string;
    filterType: string;
    filterWord: string;
  };
}

function ApprovalGrid({
  userNavigateFunction,
  getDelete,
  getEditMode,
  modeFunction,
  getEditData,
}: Props) {
  const [showType, setShowType] = useState("warning"); // error message showType
  const [showMessage, setShowMessage] = useState<string>(""); // error message showMessage
  const [showAlertBox, setShowAlertBox] = useState(false); // error message ShowAlertBox
  const gridDataDownloadRef = useRef<AgGridRef>(null);
  const [rowData, setRowData] = useState<any>([]);
  const [curruntData, setCurruntData] = useState<any>();
  const [fileUpload, setFileUpload] = useState<boolean>(false);
  const [filter, setFilter] = useState(false);
  const [filterRestore, setFilterRestore] = useState<IFilterValue>({});
  const [orginalRowData, setOrginalRowData] = useState<any>([]);
  const [filterMethod, setFilterMethod] = useState("");
  const [showDeleteModel, setShowDeleteModel] = useState<boolean>(false);
  const [uploadedDataStore, setuploadedDataStore] = useState<any>([]);

  const axiosPrivate = useAxiosPrivate();

  useEffect(() => {
    fetchData();

  }, []);

  // Get data for grid API
  const fetchData = async () => {
    try {
      const response = await axiosPrivate.get("vendor/approvals");
      setRowData(response.data.data);
      setOrginalRowData(response.data.data);
    } catch (error) { }
  };

  // Function for Filter values
  const handleFilter = (value: any, filterType: string) => {
    Filter(value, filterType, orginalRowData, setRowData);
  };

  // upload bank data
  const getUploadedUserData = (val: any) => {
    setuploadedDataStore(val);
  };

  // handle download grid
  const handleDownloadExcel = () => {
    gridDataDownloadRef.current!.onBtExport();
  };

  // this function for bulkUpload
  const clearValue = (val: any) => {
    setFileUpload(val);
  };

  // get bank data for doubleclick
  async function getUserData(data: any) {
    modeFunction(true);
    try {
      const response = await axiosPrivate.get(
        `vendor/approvals/${data.vendorApprovalId}`
      );
      data = response.data.data;
      getEditData && getEditData("form", data);
    } catch (error) { }
  }
  // current data update
  const getCurruntData = (val: any) => {
    setCurruntData(val);
  };

  // Timeout function for messages
  const clearAleart = () => {
    const timer = setTimeout(() => {
      setShowAlertBox(false);
      setShowMessage("");
      clearTimeout(timer);
    }, 2000);
  };

  // Delete function for delete data in grid
  getDelete = async () => {
    if (curruntData.length === 1) {
      axiosPrivate
        .patch(`vendor/approvals/${curruntData[0].vendorApprovalId}`)
        .then((response) => {
          if (response.status === 200) {
            setShowAlertBox(true);
            setShowType("success");
            setShowMessage(response.data.message);
            clearAleart();
            setCurruntData([]);
            getEditMode(false);
            setShowDeleteModel(false);
            fetchData();
          }
        })
        .catch((error) => {
          setShowAlertBox(true);
          setShowType("warning");
          setShowMessage(error.response.data.error);
          setShowDeleteModel(false);
          clearAleart();
        });
    } else {
      let idList: any = [];
      curruntData.map((e: any, i: number) => {
        idList.push(e.vendorApprovalId);
      });
      try {
        const res = await axiosPrivate.patch(
          "vendor/approval/multiple-record/delete",
          {
            ids: idList,
          }
        );

        if (res.status === 200) {
          setShowAlertBox(true);
          setShowType("success");
          setShowMessage(res.data.message);
          clearAleart();
          getEditMode(false);
          setCurruntData([]);
          setShowDeleteModel(false);
          fetchData();
        }
      } catch (error: any) {
        setShowAlertBox(true);
        setShowType("danger");
        setShowMessage(error.response.data.error);
        clearAleart();
        setShowDeleteModel(false);
      }
    }
  };


  return (
    <MainPageCard>
      <div className="header-main">
        <div className="navigater">
          <NavigateHeader
            style={{
              cursor: "default",
            }}
            firstValue={"Approval"}
            secondValue={"VB Master"}
          />
        </div>

        <div className="icon-buttons">
          <IconButton
            iconName={"Filter"}
            height={"40px"}
            width={"40px"}
            fontSize={"30px"}
            margin={"0px 8px"}
            color={"white"}
            backgroundColor={"#0055D4"}
            hover={true}
            handleClick={(event: React.MouseEvent) => {
              event?.stopPropagation();
              setFilter(!filter);
            }}
          />
          <IconButton
            iconName={"Delete"}
            height={"40px"}
            width={"40px"}
            fontSize={"25px"}
            margin={"0px 8px"}
            color={"white"}
            backgroundColor={"#D60000"}
            hover={false}
            opacity={"0.5"}
            cursor={"default"}
            handleClick={() => {
              // if () {
              //   setShowDeleteModel(!showDeleteModel);
              // }
            }}
          />
          <IconButton
            iconName={"Add"}
            height={"40px"}
            width={"75px"}
            fontSize={"25px"}
            margin={"0px 8px"}
            color={"white"}
            opacity={"0.5"}
            disabled={true}
            cursor={"not-allowed"}
            backgroundColor={"#0055D4"}
            hover={false}
            handleClick={() => {
              // userNavigateFunction("form");
            }}
          />
          <IconButton
            iconName={"Upload"}
            height={"40px"}
            width={"40px"}
            fontSize={"25px"}
            margin={"0px 0px 0px 8px"}
            color={"white"}
            backgroundColor={"#0055D4"}
            hover={false}
            opacity={"0.5"}
            cursor={"not-allowed"}
            handleClick={() => {
              setFileUpload(false);
              // setuploadedDataGridShow(true);
            }}
          />
          <div>
            <IconButton
              iconName={"Download"}
              height={"40px"}
              width={"40px"}
              fontSize={"25px"}
              margin={"0px 8px"}
              color={"white"}
              backgroundColor={"#0055D4"}
              hover={true}
              handleClick={handleDownloadExcel}
            />
          </div>
        </div>
      </div>
      <div>
        <AgGrid
          ref={gridDataDownloadRef}
          fileName={"VendorBankApproval"}
          tabelRowData={rowData}
          tableColumnData={vendorBankApprovalData}
          doubleClickUpdate={() => {
            modeFunction(false);
          }}
          getEdit={(data) => getUserData(data)}
          getCurruntData={getCurruntData}
        />
      </div>
      {filter && (
        <div className="filter" key={uuidv4()}>
          <GridFilter
            filterRestore={filterRestore}
            filterMethod={filterMethod}
            tabelRowData={rowData}
            data={vendorBankApprovalData.slice(1)}
            handleClick={(Value: any, filterType: string) => {
              if (filterType !== "") {
                setFilterRestore(Value);
                setFilter(!filter);
                setFilterMethod(filterType);
                handleFilter(Value, filterType);
                setCurruntData([]);
              } else {
                setFilterMethod("");
                setFilterRestore({});
                setFilter(!filter);
                setRowData(orginalRowData);
                setCurruntData([]);
              }
            }}
          />
        </div>
      )}
      {fileUpload && (
        <FileUpload
          clearValue={clearValue}
          headerData={vendorBankApprovalData}
          templateName={"User"}
          getUploadedUserData={getUploadedUserData}
          excelFileName={"VendorBankApprovalData"}
        />
      )}

      {showDeleteModel && (
        <div>
          <DeleteModal
            getDelete={getDelete}
            clearValue={(value) => {
              setShowDeleteModel(value);
            }}
            modelType={"grid-delete"}
          />
        </div>
      )}
      {showAlertBox && (
        <div className="alert-warp">
          <AlertBox type={showType} message={showMessage} />
        </div>
      )}
    </MainPageCard>
  );
}

export default ApprovalGrid;