import React, { useEffect, useState, useRef } from 'react'
import MainPageCard from '../../common-component/cards/main-page-card';
import NavigateHeader from '../../common-component/navigate-header';
import IconButton from '../../common-component/form-elements/icon-button';
import AgGrid, { AgGridRef } from '../../common-component/grids-and-tables/ag-grid';
import { customerApprovalColumnData, rowDatathis } from '../../../config/approval/customer-approval/customer-grid';
import { axiosPrivate } from '../../../middleware/axois-api';
import AlertBox from '../../common-component/alert-box';
import DeleteModal from '../../common-component/modals/delete-modal';
import { v4 as uuidv4 } from 'uuid';
import GridFilter from '../../common-component/grids-and-tables/grid-filter';
import FileUpload from '../../common-component/modals/file-upload-modal';
import OtpVerifyModal from '../../common-component/modals/otp-verify-modal';
import useAuth from '../../../services/hooks/useauth';
import Loading from '../../common-component/modals/loading-screen';
import { Filter } from '../../../helpers/filtterFunction';
interface Props {
  onboardingViewfun: (val: any) => void;
  getEditData: (nav: any, data: any) => void;
  addMode: () => void;

}

interface IFilterValue {
  [key: string]: {
    columnName: string;
    filterType: string;
    filterWord: string;
  };
}
export default function CustomerGrid({ onboardingViewfun, getEditData, addMode }: Props) {
  const [curruntData, setCurruntData] = useState<any>([]);
  const [filter, setFilter] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [rowData, setRowData] = useState<any>([]);
  const companyDownloadRef = useRef<AgGridRef>(null);
  const [orginalRowData, setOrginalRowData] = useState<any>([]);
  const [filterRestore, setFilterRestore] = useState<IFilterValue>({})
  const [filterMethod, setFilterMethod] = useState("")
  const [uploadedDataStore, setuploadedDataStore] = useState<any>([]);
  const [showType, setShowType] = useState("warning") // error message showType
  const [showMessage, setShowMessage] = useState<string>("") // error message showMessage
  const [showAlertBox, setShowAlertBox] = useState(false) // error message ShowAlertBox
  const [fileUpload, setFileUpload] = useState(false);
  const [otpVerification, setOtpVerification] = useState<boolean>(false)
  const [isLocked, setIsLocked] = useState<boolean>(false);
  const [showDeleteModel, setShowDeleteModel] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { auth } = useAuth();
  useEffect(() => {
    fetchData()
  }, []);


  const clearValue = (val: any) => {
    setFileUpload(val);
  }

  async function getUserData(id: number) {
    await axiosPrivate.get(`companyadmin/organisation/get-detailsby-customerOverviewid/${id}`)
      .then(response => {
        if (getEditData) {
          getEditData("form", response.data)
        }
      })
      .catch(error => {
      });
  }


  async function resendOtp() {
    try {
      const res = await axiosPrivate.post('companyadmin/bank/resend-otp-update/approval', {
        approvedBy: auth.token

      });
      if (res.status === 200) {
        setShowAlertBox(true)
        setShowType("success")
        setShowMessage(res.data.message)
        clearAleart()

        // updateData()
      }
    } catch (error: any) {
      setShowAlertBox(true);
      setShowType("danger")
      setShowMessage(error.response.data.error)
      clearAleart()
    }
  }


  // Function for Filter values
  const handleFilter = (value: any, filterType: string) => {
    Filter(value, filterType, orginalRowData, setRowData)
  }


  // clearAleart
  const clearAleart = () => {
    const timer = setTimeout(() => {
      setShowAlertBox(false);
      setShowMessage("");
      // setOtpVerification(false) 
      clearTimeout(timer);
    }, 5000);
  }
  // handle download grid
  const handleDownloadExcel = () => {
    companyDownloadRef.current!.onBtExport();
  }

  // delete function
  const getDelete = async () => {
    if (curruntData.length === 1) {
      axiosPrivate.delete(`/companyadmin/organisation/delete-customer/${curruntData[0].customerOverviewId}`)
        .then(response => {
          if (response.status === 200) {
            setShowAlertBox(true)
            setShowType("success")
            setShowMessage(response.data.message)
            clearAleart()
            setShowDeleteModal(false)
            fetchData()
            setCurruntData([]);
            setOtpVerification(false)
          }
        })
        .catch(error => {
          setShowAlertBox(true);
          setShowType("warning")
          setShowMessage(error.response.data.error)
          setShowDeleteModal(false)
          setOtpVerification(false)
          clearAleart()
        });
    } else {
      let idList: any = [];
      curruntData.map((e: any, i: number) => {
        idList.push({
          id: e.customerOverviewId
        })
      })
      try {
        const res = await axiosPrivate.post("companyadmin/organisation/delete-multiple-customer", {
          data: idList
        })
        if (res.status === 200) {
          setShowAlertBox(true)
          setShowType("success")
          setShowMessage(res.data.message);
          clearAleart();
          setShowDeleteModal(false)
          fetchData()
          setCurruntData([]);
          setOtpVerification(false)
          setShowDeleteModal(false)

        }
      } catch (error: any) {
        setShowAlertBox(true)
        setShowType("danger")
        setShowMessage(error.response.data.error)
        clearAleart()
        setShowDeleteModal(false)
      }
    }
  };


  // current data update
  const getCurruntData = (val: any) => {
    setCurruntData(val);
  }

  // upload bank data 
  const getUploadedUserData = (val: any) => {
    setuploadedDataStore(val);
  }




  const fetchData = async () => {
    try {
      const response = await axiosPrivate.get('companyadmin/organisation/get-customer');
      setRowData(response.data.customerDetails);
      setOrginalRowData(response.data.customerDetails)
    } catch (error) {
      setRowData([]);
      setOrginalRowData([])
    }
  };


  return (
    <MainPageCard>
      <div className='header-grid'>
        <div className='left-header'>
          <NavigateHeader firstValue={"Approval"}
            secondValue={"Customer"}
          />
        </div>
        <div className='icon-buttons'>
          <IconButton
            iconName={"Filter"}
            height={"40px"}
            width={"40px"}
            fontSize={"30px"}
            margin={"0px 8px"}
            color={"white"}
            backgroundColor={"#0055D4"}
            hover={true}
            handleClick={(event: React.MouseEvent) => {
              event?.stopPropagation()
              setFilter(!filter);
            }}
          />
          <IconButton
            iconName={"Delete"}
            height={"40px"}
            width={"40px"}
            fontSize={"25px"}
            margin={"0px 8px"}
            color={"white"}
            // hover={false}
            // opacity={"0.5"}
            // cursor={"not-allowed"}
            backgroundColor={"#D60000"}
            hover={curruntData.length > 0}
            opacity={curruntData.length > 0 ? "1" : "0.5"}
            cursor={curruntData.length > 0 ? "pointer" : "default"}
            handleClick={() => {
              if (curruntData.length > 0) {
                setShowDeleteModel(!showDeleteModel)
              }
            }}
          />

          <IconButton
            iconName={"Add"}
            height={"40px"}
            width={"75px"}
            fontSize={"25px"}
            margin={"0px 8px"}
            color={"white"}
            backgroundColor={"#0055D4"}
            hover={false}
            opacity={"0.5"}
            cursor={"not-allowed"}
            handleClick={() => {
              // onboardingViewfun("form");
              // addMode();
            }}
          />
          <IconButton
            iconName={"Upload"}
            height={"40px"}
            width={"40px"}
            fontSize={"25px"}
            margin={"0px 0px 0px 8px"}
            color={"white"}
            backgroundColor={"#0055D4"}
            hover={false}
            opacity={"0.5"}
            cursor={"not-allowed"}
            handleClick={() => {
              //  setFileUpload(true) 
            }}
          />
          <IconButton
            iconName={"Download"}
            height={"40px"}
            width={"40px"}
            fontSize={"25px"}
            margin={"0px 8px"}
            color={"white"}
            backgroundColor={"#0055D4"}
            hover={true}
            handleClick={() => {
              handleDownloadExcel()
            }}
          />
        </div>
      </div>
      <div>
        <AgGrid
          tabelRowData={rowData}
          tableColumnData={customerApprovalColumnData}
          ref={companyDownloadRef}
          fileName={"CustomerData"}
          getCurruntData={getCurruntData}
          doubleClickUpdate={() => { getEditData && getEditData("form", "") }}
          getEdit={(data) => {
            getUserData(data.customerOverviewId)
          }
          }
        // getCurruntData={getCurruntData}
        />
      </div>


      {
        filter &&
        <div className='filter' key={uuidv4()}>
          <GridFilter
            filterRestore={filterRestore}
            filterMethod={filterMethod}
            tabelRowData={rowData}
            data={customerApprovalColumnData.slice(1)}
            handleClick={(Value: any, filterType: string) => {
              if (filterType !== "") {
                setFilterRestore(Value)
                setFilter(!filter)
                setFilterMethod(filterType)
                handleFilter(Value, filterType)
                setCurruntData([]);

              } else {
                setFilterMethod("")
                setFilterRestore({})
                setFilter(!filter)
                setRowData(orginalRowData)
                setCurruntData([]);
              }
            }}
          />
        </div>
      }
      {
        fileUpload &&
        <FileUpload clearValue={clearValue} headerData={customerApprovalColumnData} templateName={"User"} getUploadedUserData={getUploadedUserData} />
      }
      {
        otpVerification &&
        <OtpVerifyModal
          otpVerify={resendOtp}
          leavePage={() => {
            setOtpVerification(false)
            onboardingViewfun("grid");

          }}
          isLocked={false}
          setIsLocked={setIsLocked}
          getStatus={async (stringFromArray) => {
            let res;
            try {

              res = await axiosPrivate.post('companyadmin/bank/verify-otp/update', {
                otp: stringFromArray,
                approvedBy: auth.token,

              });
              if (res.status === 200) {
                getDelete()
                clearAleart()
                // setOtpVerification(false)
              }
            } catch (error: any) {
              setShowAlertBox(true);
              setShowType("danger")
              setShowMessage(error.response.data.error)
              clearAleart()
            }
          }
          }
        />
      }
      {
        isLoading &&
        <Loading />
      }

      {
        showDeleteModel &&
        <div >
          <DeleteModal
            getDelete={async () => {
              setIsLoading(true)
              let currentDataValue = curruntData[0].companyBankId

              try {
                const res = await axiosPrivate.post("companyadmin/bank/otp-update/approval", {
                  approvedBy: auth.token,

                })
                if (res.status === 200) {
                  setOtpVerification(true)
                  setShowDeleteModel(false)
                  setIsLoading(false)
                  setShowAlertBox(true)
                  setShowType("success")
                  setShowMessage(res.data.message)
                  clearAleart()

                }

              } catch (error: any) {
                setIsLoading(false)
                setShowAlertBox(true);
                setShowType("danger")
                setShowMessage(error.response.data.error)
                clearAleart()
              }
            }}
            clearValue={(value) => { setShowDeleteModel(value) }}
            modelType={"grid-delete"}

          />

        </div>
      }
      {
        showAlertBox &&
        <div className='alert-warp'>
          <AlertBox type={showType} message={showMessage} />
        </div>
      }
    </MainPageCard>
  )
}
