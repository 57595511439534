import CompanyForm from '../../components/organisation/company/company-form'
import CompanyGrid from '../../components/organisation/company/company-grid'
import React, { Component } from 'react'
import { axiosPrivate } from '../../middleware/axois-api';

interface StateValues {
  onboardingView: string;
  editData: any;
  isEditMode: boolean;
}
export default class Company extends Component<{}, StateValues> {

  constructor(props: StateValues) {
    super(props);
    this.state = {
      onboardingView: "form",
      editData: {},
      isEditMode: true,
    };
    this.addMode = this.addMode.bind(this);
    this.loggedCompany = this.loggedCompany.bind(this);
  }
  componentDidMount(): void {
    this.loggedCompany();
  }

  async loggedCompany() {
    await axiosPrivate.get(`/companyadmin/company-approval`)
      .then((response:any) => {
        this.setState({
          editData: { ...response.data.results },
        })
      })
      .catch(error => {
        console.error(error);
      });
  }
  addMode = () => {
    this.setState({
      editData: {},
      isEditMode: true,
    })
  }

render() {
  return (
    <div>
      {
        this.state.onboardingView === "form" &&
        <CompanyForm
          onboardingViewfun={(val) => {
            this.setState({ onboardingView: val })
          }}
          editData={this.state.editData}
          isEditMode={this.state.isEditMode}

          getEditData={(data: any) => this.setState({
            editData: { ...data },
          })}
        />
      }
      {/* {
          this.state.onboardingView === "grid" &&
          <CompanyGrid
            onboardingViewfun={(val) => {
              this.setState({ onboardingView: val })
            }}
            addMode={this.addMode}
            getEditData={(nav: any, data: any) => this.setState({
              onboardingView: nav,
              editData: { ...data },
              isEditMode: true,
            })}

          />
        } */}


    </div>

  )
}
}

