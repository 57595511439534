import { useRef, useState, useEffect } from 'react'
import Card from '../common-component/cards/card';
import ChartAgGrid, { AgGridRef } from '../common-component/charts/chart-ag-grid';
import useAxiosPrivate from '../../services/hooks/useaxios-private';
import { coloumDataSample } from '../../config/dashboard/pa-transaction-history-config';
import { v4 as uuidv4 } from 'uuid';
import '../../styles/dashboard/organization-vs-companies.scss';

interface propsType {
  dashBoardWidth?: any;
  setOpenOneDashboard?: any;
  isOpenEmpty?: boolean;
  setIsOpenEmpty?: any;
}

function PaTransactionHistory({ dashBoardWidth, setOpenOneDashboard, isOpenEmpty, setIsOpenEmpty }: propsType) {
  // gridIcon 
  const [tableColumnData, setTableColumnData] = useState<any[]>([]);
  const [tableRowData, setTableRowData] = useState<any[]>([]);
  // axiosPrivate
  const axiosPrivate = useAxiosPrivate();
  // useref for grid
  const gridDataDownloadRef = useRef<AgGridRef>(null);
  const width = window.innerWidth;

  // initialApiCall
  useEffect(() => {
    getBarDataForApi()
  }, [])

  // chartApiFunctions
  async function getBarDataForApi() {
    try {
      const res = await axiosPrivate.post(`product-admin/transaction-history/report-data`);
      if (res.status === 200) {
        handleConvertTableData(res)
      }
    } catch (error) {
      console.log(`Error:${error}`)
    }
  }

  // handleGridViewIconFunctions
  function handleConvertTableData(data: any) {
    let addColumnData: any[] = [];
    let addRowData: any[] = [];

    Object.keys(data.data.transactionHistory[0]).map((dataset: any) => {
      if (["Company Name", "Name/Business", "Date"].includes(dataset)) {
        addColumnData.push({
          ...coloumDataSample,
          cellStyle: { textAlign: 'left' },
          field: dataset,
          headerName:  dataset,
        })
      } else if (["Amount", "Transaction Charge", "Total Amount"].includes(dataset)) {
        addColumnData.push({
          ...coloumDataSample,
          cellStyle: { textAlign: 'right' },
          headerClass: 'header-right-transaction',
          field: dataset,
          headerName: `${dataset} ₹`,
        })
      } else if (["Status","InvoiceId"].includes(dataset)) {
        addColumnData.push({
          ...coloumDataSample,
          cellStyle: { textAlign: 'left' },
          minWidth: 150,
          field: dataset,
          headerName: dataset.includes("InvoiceId") ? "Invoice Id" :dataset,
        })
      } else {
        addColumnData.push({
          ...coloumDataSample,
          field: dataset,
          headerName: dataset,
        })
      }
    })

    data.data.transactionHistory.map((dataset: any, i: number) => {
      let rowData: any = {};
      Object.keys(dataset).map((elem: any) => {
        Object.assign(rowData, { [elem]: dataset[elem] === null ? "" : dataset[elem] });
      })
      addRowData.push(rowData);
    })

    setTableColumnData(addColumnData)
    setTableRowData(addRowData)
  }

  //download
  const handleDownloadExcel = () => {
    gridDataDownloadRef.current!.onBtExport();
  }

  return (
    <div className='organization-vs-companies-component'>
      <div className='charts-main'>
        <Card
          width={!isOpenEmpty || (width < 1869 && width > 1601) || (width < 1601) ? dashBoardWidth.cardWidth : "1510px"}
          height={isOpenEmpty ? "400px" : dashBoardWidth.cardHeight}
          isOpenEmpty={isOpenEmpty}
          setIsOpenEmpty={setIsOpenEmpty}
          currentChart={"paTransactionHistory"}
          chartHeading={"Transaction History"}
          isGroupbyIconDisabled={true}
          isSaveFilterIconDisabled={true}
          isModelNeed={true}
          isFilterNeed={true}
          isGridNeed={true}
          setOpenOneDashboard={setOpenOneDashboard}
          isOpenGrid={true}
          handleDownloadExcel={handleDownloadExcel}
        >
          <div key={uuidv4()}>
            <ChartAgGrid
              fileName={"Dashboard Report"}
              ref={gridDataDownloadRef}
              tabelRowData={tableRowData}
              tableColumnData={tableColumnData}
              className={!isOpenEmpty ? 'ag-theme-alpine-chart-single table-align-transaction' : 'ag-theme-alpine-chart table-align-transaction'}
            />
          </div>
        </Card>
      </div>
    </div>
  )
}

export default PaTransactionHistory;