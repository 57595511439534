import React, { useEffect, useState } from 'react'
import { BiPlus, BiMinus } from "react-icons/bi";
import "../../../styles/view-table.scss";
import IconButton from '../../../components/common-component/form-elements/icon-button';
import DeleteModal from '../../../components/common-component/modals/delete-modal';
import "../../../styles/onboarding/company/grid-icon.scss"




interface Props {
    statutoryDetails: any;
    retriveData: (value: any, index: number) => void;
    showmodal: (value: any, index: number) => void;
  mainApiData: any;

}

function StatutoryDetailsGrid({ statutoryDetails,mainApiData, retriveData, showmodal }: Props) {
    return (
        <>
            <div className='view-table-component' style={{ marginTop: "40px", marginBottom: "40px", height: "205px", overflowY: "auto" }} >
                <table>
                    <thead style={{ zIndex: "0" }}>
                        {/* <tr> */}
                        <th className='align-differ' >Company GSTIN</th>
                        {/* <th className='tan' style={{ minWidth: "215px" }}>Company Tan</th> */}
                        <th className='status' style={{ paddingLeft: "5px" }}>Status</th>
                        <th className='' style={{ paddingLeft: "5px" }}>Company Reg State</th>
                        <th style={{ paddingLeft: "5px", minWidth: "150px", textAlign: "center" }}>State Code</th>
                        <th className='' style={{ minWidth: "90px", textAlign: "center", paddingLeft: "30px" }}>Action</th>
                        {/* </tr> */}
                    </thead>
                    <tbody >
                        {!statutoryDetails || statutoryDetails?.length === 0 ?
                            <tr className='style-grid-differ'>
                                <td></td>
                                <td></td>
                                <td>No records to show</td>
                                <td></td>
                                <td></td>
                            </tr> :
                            statutoryDetails?.map((data: any, index: number) => (
                                // <div >
                                <tr className='main-body-header' style={{ marginTop: "0px" }} key={index}>
                                    <td className='align-differ'>{data.document_id}</td>
                                    {/* <td className='tan'>{data.CompanyTan}</td> */}
                                    <td className='status' style={{ paddingLeft: "5px" }}>{data.status}</td>
                                    <td className='' style={{ paddingLeft: "5px" }}>{data.state}</td>
                                    <td className='middle-icons' style={{ minWidth: "150px", textAlign: "center" }}>{data.state_code}</td>
                                    <td className='last-icon flexcls'>
                                        <IconButton
                                            iconName={"Edit"}
                                            height={"40px"}
                                            width={"40px"}
                                            fontSize={"30px"}
                                            margin={"0px 8px"}
                                            color={"white"}
                                            backgroundColor={"#0055D4"}
                                            hover={mainApiData?.kycApprovalStatus === "Approved" ? false : true}

                                            disabled={mainApiData?.kycApprovalStatus === "Approved" ? true : false}

                                            handleClick={() => {
                                                retriveData(data, index)
                                            }}
                                        />
                                        <IconButton
                                            iconName={"Delete"}
                                            height={"40px"}
                                            width={"40px"}
                                            fontSize={"25px"}
                                            margin={"0px 8px"}
                                            color={"white"}
                                            backgroundColor={"#D60000"}
                                            hover={mainApiData?.kycApprovalStatus === "Approved" ? false : true}

                                            disabled={mainApiData?.kycApprovalStatus === "Approved" ? true : false}

                                            handleClick={() => {
                                                showmodal(data, index)
                                            }}
                                        />
                                    </td>
                                </tr>
                                // </div>
                            ))}
                    </tbody>
                </table>
            </div>
            {/* {
                showDeleteModel &&
                <div >
                    <DeleteModal
                        getDelete={() => { }}
                        clearValue={(value) => { setShowDeleteModel(value) }}
                        modelType={"grid-delete"}
                    />
                </div>
            } */}
        </>
    )
}

export default StatutoryDetailsGrid;