import React from 'react';
import '../../../styles/common-component/switch-button.scss';

interface propsType {
    isOn: boolean;
    id: string;
    handleToggle: () => void;
}

const SwitchButton = ({ isOn, handleToggle, id }: propsType) => {
  
    return (
        <>
            <input
                checked={isOn}
                onChange={() => handleToggle()}
                className={"react-switch-checkbox"}
                id={`react-switch-new-${id}`}
                type="checkbox"
            />
            <label
                style={{ background: isOn ? "#0055D4" : "#E2E2E2" }}
                className="react-switch-label"
                htmlFor={`react-switch-new-${id}`}
            >
                <span className={`react-switch-button`} />
            </label>
        </>
    );
};

export default SwitchButton;