import React, { useEffect, useState } from 'react';
import MainPageCard from '../../common-component/cards/main-page-card';
import NavigateHeader from '../../common-component/navigate-header';
import '../../../styles/payable/direct-tax/direct-tax-form.scss';
import InputComponent from '../../common-component/form-elements/input-component';
import ButtonComponent from '../../common-component/form-elements/button-component';
import DropdownComponent from '../../common-component/form-elements/dropdown-component';
import { v4 as uuidv4 } from 'uuid'
import { axiosPrivate } from '../../../middleware/axois-api';
import Changesmodal from '../../common-component/modals/changes-modal';
import AlertBox from '../../common-component/alert-box';
import Loading from '../../common-component/modals/loading-screen';
import DirectTaxApprovalModel from '../../common-component/modals/direct-tax-approval-model';
import { useDispatch } from 'react-redux';
import { resetFormModified } from '../../../redux/action';
interface Props {
    directTaxViewfun: (val: any) => void;
    title?: string;
    buttonNameValue?: string;
    formDataById?: any;
}
export default function DirectTaxForm({ directTaxViewfun, title, buttonNameValue, formDataById }: Props) {

    const [selectionChallan, setSelectionChallan] = useState<any>('Challan 280');
    const [challanTotalAmount, setChallanTotalAmount] = useState<any>();
    const [challanNumberList, setChallanNumberList] = useState(["Challan 280", "Challan 281", "Challan 282", "Challan 283"]);
    const [challanDetails, setChallanDetails] = useState<any>({
        // "pan": "",
        // "tan": "",
        // "companyName": "",
        // "majorHeadCode": "",
        // "majorHeadCodeId": "",
        // "minorHeadCode": "",
        // "minorHeadCodeId": "",
        // "referenceNumber": "",
        // "assessmentYear": "",
        // "incomeTaxAmount": "",
        // "taxAmount": "",
        // "surchargeAmount": "",
        // "educationCessAmount": "",
        // "interestAmount": "",
        // "penaltyAmount": "",
        // "otherAmount": "",
        // "totalAmount": "",
    });
    // const [paymentDetails, setPaymentDetails] = useState<any>({
    //     "incomeTaxAmount": "",
    //     "taxAmount": "",
    //     "surchargeAmount": "",
    //     "educationCessAmount": "",
    //     "interestAmount": "",
    //     "penaltyAmount": "",
    //     "otherAmount": "",
    //     "totalAmount": "",
    // });
    const [majorHeadCodeDatas, setMajorHeadCodeDatas] = useState([]);
    const [minorHeadCodeDatas, setMinorHeadCodeDatas] = useState([]);
    const [assessmentYears, setAssessmentYears] = useState([]);
    const [alertMessageData, setAlertMessageData] = useState({
        type: "",
        message: ""
    });
    const [changesModal, setChangesModal] = useState(false);
    const [showAlertBox, setShowAlertBox] = useState(false);
    const [isFormDirty, setIsFormDirty] = useState(false);
    const [loadingScreen, setLoadingScreen] = useState(false);
    const [approvalModal, setApprovalModal] = useState(false);
    const [movingAnotherStateName, setMovingAnotherStateName] = useState('');
    const [directTaxError, setDirectTaxError] = useState({
        "majorHeadCode": false,
        "minorHeadCode": false,
        "assessmentYear": false,
        "incomeTaxAmount": false,
        "taxAmount": false,
    });

    async function saveDirectTaxData() {
        const isValidForm = formValidation()
        if (isValidForm) {
            const finalFormDatas = { ...challanDetails, challanNo: selectionChallan, totalAmount: challanTotalAmount, paymentStatus: "Pending", approvalStatus: "Pending Approval" }
            try {
                const res = await axiosPrivate.post('/directtax-payable/create-datas', { directTaxData: finalFormDatas })
                if (res.status == 200) {
                    setApprovalModal(true)
                }

            } catch (error: any) {
                console.log("Error in saveDirectTaxData Function");
                if (error.response.data.success == false) {
                    setShowAlertBox(true)
                    setAlertMessageData({ type: "danger", message: error.response.data.message })
                    clearAlert()
                }
            }

        }
    }
    async function updateDirectTaxData(id: any) {
        const isValidForm = formValidation()
        if (isValidForm) {
            const finalFormDatas = {
                ...challanDetails, challanNo: selectionChallan, totalAmount: challanTotalAmount, paymentStatus: "Pending", approvalStatus: "Pending Approval"
            }
            try {
                const res = await axiosPrivate.patch(`/directtax-payable/update-datas/${id}`, { directTaxData: finalFormDatas })
                if (res.status == 200) {
                    setApprovalModal(true)
                }

            } catch (error: any) {
                console.log("Error in saveDirectTaxData Function");
                if (error.response.data.success == false) {
                    setShowAlertBox(true)
                    setAlertMessageData({ type: "danger", message: error.response.data.message })
                    clearAlert()
                }
            }
        }
    }

    // function to show thousand separator
    const formatAmount = (amount: any) => {
        if (amount && amount !== ".") {
            const onlyNumber = amount.toString().replace(/[^0-9.]/g, "");
            // Handle empty input
            if (onlyNumber === "") {
                return "";
            }
            // Split input into integer and decimal parts
            const parts = onlyNumber.split(".");
            const integerPart = parts[0];
            const decimalPart = parts[1] || "";
            // Format the integer part with commas
            const formattedInteger = parseFloat(integerPart).toLocaleString('en-IN');
            // Handle complete decimal input (e.g., "5000.50")
            if (decimalPart !== "") {
                return `${formattedInteger}.${decimalPart}`;
            }
            // Handle incomplete decimal input (e.g., "5000.")
            if (amount.toString().endsWith(".")) {
                return `${formattedInteger}.`;
            }
            // Return formatted integer
            return formattedInteger;
        }
        return "";
    };
    async function getChallanInitialData() {
        try {
            const res = await axiosPrivate.get('/directtax-payable/get-pan-tan')
            const { panNumber, tanNumber, companyName } = res.data;
            setChallanDetails({ ...challanDetails, pan: panNumber, tan: tanNumber, companyName: companyName })
        } catch (error) {
            console.log("Error in GetChallanInitialData function");
        }
    }
    async function getMajorMinorCodeDropdownData() {
        try {
            const minor = await axiosPrivate.post('/directtax-payable/get-minor-dropdown-datas', { challanNo: selectionChallan })
            setMinorHeadCodeDatas(minor.data.data.minorDropDownValues)
            const major = await axiosPrivate.post('/directtax-payable/get-major-dropdown-datas', { challanNo: selectionChallan })
            setMajorHeadCodeDatas(major.data.data.majorDropDownValues)
        } catch (error) {
            console.log("Error in getMajorMinorCodeDropdownData Function");
        }
    }
    async function getAssessmentYearDropdownData() {
        try {
            const minor = await axiosPrivate.get('/directtax-payable/get-assessment-year')
            setAssessmentYears(minor.data.assessmentYears)

        } catch (error) {
            console.log("Error in getAssessmentYearDropdownData Function");
        }
    }
    const clearAlert = () => {
        setTimeout(() => {
            setShowAlertBox(false);
            setAlertMessageData({ type: "", message: "" });
        }, 3000);
    }
    function formValidation() {
        let validationField = [
            "majorHeadCode", "minorHeadCode", "assessmentYear", "incomeTaxAmount", "taxAmount"
        ]
        let directTaxErrorCopy: any = { ...directTaxError };
        let finalStatus = [];
        for (const data of validationField) {
            console.log(challanDetails[data]);
            if (selectionChallan == "Challan 280" || selectionChallan == "Challan 281") {
                if (
                    (
                        challanDetails[data] == "" || challanDetails[data] == "Select" || (
                            data == 'incomeTaxAmount' && ["0.0", "0.00", "0", "00"].includes(challanDetails[data])
                        )
                    ) && data !== "taxAmount"
                ) {

                    setShowAlertBox(true)
                    setAlertMessageData({ type: "danger", message: "Mandatory field(s) should not be left blank." })
                    directTaxErrorCopy[data] = true;
                    finalStatus.push(true)
                    clearAlert()
                } else {
                    finalStatus.push(false)
                    directTaxErrorCopy[data] = false;
                }
            }
            if (selectionChallan == "Challan 282" || selectionChallan == "Challan 283") {
                if ((challanDetails[data] == "" || challanDetails[data] == "Select") && data !== "incomeTaxAmount") {
                    setShowAlertBox(true)
                    setAlertMessageData({ type: "danger", message: "Mandatory field(s) should not be left blank." })
                    directTaxErrorCopy[data] = true;
                    finalStatus.push(true)
                    clearAlert()
                } else {
                    finalStatus.push(false)
                    directTaxErrorCopy[data] = false;
                }
            }
        }
        setDirectTaxError(directTaxErrorCopy)
        return finalStatus.includes(true) ? false : true
    }
    function clearFormData() {
        setChallanDetails({
            "majorHeadCode": "Select",
            "minorHeadCode": "Select",
            "referenceNumber": "",
            "assessmentYear": "Select",
            "incomeTaxAmount": "",
            "taxAmount": "",
            "surchargeAmount": "",
            "educationCessAmount": "",
            "interestAmount": "",
            "penaltyAmount": "",
            "otherAmount": "",
            "totalAmount": "",
        })
        setDirectTaxError({
            "majorHeadCode": false,
            "minorHeadCode": false,
            "assessmentYear": false,
            "incomeTaxAmount": false,
            "taxAmount": false,
        })
        setIsFormDirty(false)
        dispatch(resetFormModified(false));
    }

    useEffect(() => {
        if (formDataById.length == 0) {
            getChallanInitialData()
        }
    }, [])

    useEffect(() => {
        getMajorMinorCodeDropdownData()
        getAssessmentYearDropdownData()
        if (formDataById.length == 0) {
            getChallanInitialData()
        }
    }, [selectionChallan])
    useEffect(() => {
        if (Object.keys(formDataById).length > 0 && isFormDirty == false) {
            setChallanDetails({
                // ...challanDetails,
                "pan": formDataById.panNo,
                "tan": formDataById.tanNo,
                "companyName": formDataById.companyName,
                "majorHeadCode": formDataById.majorHeadCode,
                "majorHeadCodeId": formDataById.majorHeadCodeId,
                "minorHeadCode": formDataById.minorHeadCode,
                "minorHeadCodeId": formDataById.minorHeadCodeId,
                "referenceNumber": formDataById.referenceNumber,
                "assessmentYear": formDataById.assessmentYear,
                "incomeTaxAmount": formDataById.incomeTaxAmount,
                "taxAmount": formDataById.taxAmount,
                "surchargeAmount": formDataById.surchargeAmount,
                "educationCessAmount": formDataById.educationCessAmount,
                "interestAmount": formDataById.interestAmount,
                "penaltyAmount": formDataById.penaltyAmount,
                "otherAmount": formDataById.otherAmount,
                "totalAmount": formDataById.totalAmount,
            })
            setChallanTotalAmount(formDataById.totalAmount)
            setSelectionChallan(`Challan ${formDataById.challanNo}`)
        }
    }, [formDataById])

    useEffect(() => {
        if (formDataById.length == 0) {
            const TotalAmount = (
                (challanDetails.incomeTaxAmount ? Number(challanDetails.incomeTaxAmount) : 0)
                + (challanDetails.taxAmount ? Number(challanDetails.taxAmount) : 0)
                + (challanDetails.surchargeAmount ? Number(challanDetails.surchargeAmount) : 0)
                + (challanDetails.educationCessAmount ? Number(challanDetails.educationCessAmount) : 0)
                + (challanDetails.interestAmount ? Number(challanDetails.interestAmount) : 0)
                + (challanDetails.penaltyAmount ? Number(challanDetails.penaltyAmount) : 0)
                + (challanDetails.otherAmount ? Number(challanDetails.otherAmount) : 0)
            )
            const amountValue = TotalAmount.toFixed(2)
            setChallanTotalAmount(amountValue.toString())
        } else {
            if (isFormDirty) {
                const TotalAmount = (
                    (['Challan 280', 'Challan 281'].includes(selectionChallan) ? (challanDetails.incomeTaxAmount !== "NaN" ? Number((challanDetails.incomeTaxAmount).replaceAll(',', '')) : 0) : 0)
                    + (['Challan 282', 'Challan 283'].includes(selectionChallan) ? (challanDetails.taxAmount !== "NaN" ? Number((challanDetails.taxAmount).replaceAll(',', '')) : 0) : 0)
                    + (challanDetails.surchargeAmount !== "NaN" ? Number((challanDetails.surchargeAmount).replaceAll(',', '')) : 0)
                    + (challanDetails.educationCessAmount !== "NaN" ? Number((challanDetails.educationCessAmount).replaceAll(',', '')) : 0)
                    + (challanDetails.interestAmount !== "NaN" ? Number((challanDetails.interestAmount).replaceAll(',', '')) : 0)
                    + (challanDetails.penaltyAmount !== "NaN" ? Number((challanDetails.penaltyAmount).replaceAll(',', '')) : 0)
                    + (challanDetails.otherAmount !== "NaN" ? Number((challanDetails.otherAmount).replaceAll(',', '')) : 0)
                )
                const amountValue = TotalAmount.toFixed(2)
                setChallanTotalAmount(amountValue.toString())
            }
        }
    }, [challanDetails, formDataById])
    const dispatch = useDispatch();
    return (
        <>
            <MainPageCard>
                <div style={{ width: "100%", height: "80px" }} >
                    <NavigateHeader
                        style={{ cursor: "pointer" }}
                        firstValue={title ? title : "Payable"}
                        secondValue={"Direct Tax"}
                        navigatePage={() => {
                            if (isFormDirty) {
                                setChangesModal(true);
                            } else {
                                directTaxViewfun("grid")
                            }
                        }}
                    />
                </div>
                <div style={{ height: "63vh", overflowX: "auto" }}>
                    <div className='challan-numbers-list' >
                        {
                            challanNumberList.map((data) => (
                                <div onClick={() => {
                                    if (formDataById.length == 0) {
                                        if (isFormDirty) {
                                            setChangesModal(true);
                                            setMovingAnotherStateName(data)
                                        } else {
                                            clearFormData()
                                            setSelectionChallan(data)
                                        }
                                    }
                                }} className={selectionChallan == data ? "challan-selected" : "challan-unselected"} style={{ cursor: Object.keys(formDataById).length > 0 ? "default" : 'pointer' }}>{data}</div>
                            ))
                        }
                    </div>
                    <div className='directtax-challan-details'>
                        <div className='challan-details-header'>
                            {"Challan Details"}
                        </div>
                        <div className='challan-details-form' >
                            <div className='first-row'>
                                {
                                    selectionChallan !== "Challan 281" ?
                                        <div style={{ opacity: "0.4" }}>
                                            <InputComponent
                                                height={"41px"}
                                                width={"300px"}
                                                margin={"0px 60px 30px 0px"}
                                                padding={"0px 5px 0px 10px"}
                                                // border={approvalDataErrors.max ? "1px solid red" : "1px solid #A9C3DC"}
                                                border={"1px solid #A9C3DC"}
                                                borderRadius={"0px"}
                                                backgroundColor={"white"}
                                                color={"black"}
                                                type={"mobile"}
                                                textAlign={"left"}
                                                // maxLength={10}
                                                inputTitle={"PAN"}
                                                placeHolder={"Enter PAN"}
                                                required={true}
                                                inputValue={challanDetails.pan}
                                                disabled={true}
                                                getUser={(value: any) => {
                                                    // const maxAmount = value.replace(/[^0-9]/g, "").replace(/(\..*)\./g, '$1').replace(/^(\d*\.\d{0,2}).*/, '$1');
                                                    // setApprovalData({
                                                    //     ...approvalData,
                                                    //     max: maxAmount
                                                    // });
                                                    // setIsFormDirty(true)
                                                    // dispatch(resetFormModified(true));
                                                }}
                                            />
                                        </div>
                                        :
                                        <div style={{ opacity: "0.4" }} >
                                            <InputComponent
                                                height={"41px"}
                                                width={"300px"}
                                                margin={"0px 60px 30px 0px"}
                                                padding={"0px 5px 0px 10px"}
                                                // border={approvalDataErrors.max ? "1px solid red" : "1px solid #A9C3DC"}g
                                                border={"1px solid #A9C3DC"}
                                                borderRadius={"0px"}
                                                backgroundColor={"white"}
                                                color={"black"}
                                                type={"mobile"}
                                                textAlign={"left"}
                                                // maxLength={10}
                                                inputTitle={"TAN"}
                                                placeHolder={"Enter TAN"}
                                                required={true}
                                                inputValue={challanDetails.tan}
                                                disabled={true}
                                                getUser={(value: any) => {
                                                    // const maxAmount = value.replace(/[^0-9]/g, "").replace(/(\..*)\./g, '$1').replace(/^(\d*\.\d{0,2}).*/, '$1');
                                                    // setApprovalData({
                                                    //     ...approvalData,
                                                    //     max: maxAmount
                                                    // });
                                                    // setIsFormDirty(true)
                                                    // dispatch(resetFormModified(true));
                                                }}
                                            />
                                        </div>
                                }

                                <div style={{ opacity: "0.4" }}>
                                    <InputComponent
                                        height={"41px"}
                                        width={"300px"}
                                        margin={"0px 60px 30px 0px"}
                                        padding={"0px 5px 0px 10px"}
                                        // border={approvalDataErrors.max ? "1px solid red" : "1px solid #A9C3DC"}
                                        border={"1px solid #A9C3DC"}
                                        borderRadius={"0px"}
                                        backgroundColor={"white"}
                                        color={"black"}
                                        type={"mobile"}
                                        textAlign={"left"}
                                        // maxLength={10}
                                        inputTitle={"Company Name"}
                                        placeHolder={"Enter Company Name"}
                                        required={true}
                                        inputValue={challanDetails.companyName}
                                        disabled={true}
                                        getUser={(value: any) => {
                                            // const maxAmount = value.replace(/[^0-9]/g, "").replace(/(\..*)\./g, '$1').replace(/^(\d*\.\d{0,2}).*/, '$1');
                                            // setApprovalData({
                                            //     ...approvalData,
                                            //     max: maxAmount
                                            // });
                                            // setIsFormDirty(true)
                                            // dispatch(resetFormModified(true));
                                        }}
                                    />
                                </div>
                                <div style={{ width: "300px" }} key={uuidv4()} className={directTaxError.majorHeadCode ? 'setErrorcompany' : "dropdown-width"}>
                                    <DropdownComponent
                                        options={majorHeadCodeDatas}
                                        title={"Major Head Code (Tax Applicable)"}
                                        isDisabled={false}
                                        required={true}
                                        getData={(val) => {
                                            setIsFormDirty(true)
                                            dispatch(resetFormModified(true));
                                            setChallanDetails({ ...challanDetails, majorHeadCode: val.value, majorHeadCodeId: val.id })
                                            // setIsFormDirty(true)
                                            // if (val.value !== "Select") {
                                            //     setOrganisationBankData((prevState) => ({
                                            //         ...prevState,
                                            //         priority: val.value,
                                            //     }));
                                            //     dispatch(resetFormModified(true));
                                            // }
                                        }}
                                        // defaultValue={{ label: "Select", value: "Select" }}
                                        defaultValue={[{ label: challanDetails.majorHeadCode ? challanDetails.majorHeadCode : "Select", value: challanDetails.majorHeadCode ? challanDetails.majorHeadCode : "Select" }]}
                                    />
                                </div>
                            </div>
                            <div className='second-row'>
                                <div style={{ width: "300px", marginRight: "60px" }} key={uuidv4()} className={directTaxError.minorHeadCode ? 'setErrorcompany' : "dropdown-width"}>
                                    <DropdownComponent
                                        options={minorHeadCodeDatas}
                                        title={"Minor Head Code (Type of Payment)"}
                                        isDisabled={false}
                                        required={true}
                                        getData={(val) => {
                                            setIsFormDirty(true)
                                            dispatch(resetFormModified(true));
                                            setChallanDetails({ ...challanDetails, minorHeadCode: val.value, minorHeadCodeId: val.id })

                                        }}
                                        defaultValue={[{ label: challanDetails.minorHeadCode ? challanDetails.minorHeadCode : "Select", value: challanDetails.minorHeadCode ? challanDetails.minorHeadCode : "Select" }]}
                                    />
                                </div>
                                <div>
                                    <InputComponent
                                        height={"41px"}
                                        width={"300px"}
                                        margin={"0px 60px 30px 0px"}
                                        padding={"0px 5px 0px 10px"}
                                        // border={approvalDataErrors.max ? "1px solid red" : "1px solid #A9C3DC"}
                                        border={"1px solid #A9C3DC"}
                                        borderRadius={"0px"}
                                        backgroundColor={"white"}
                                        color={"black"}
                                        type={"mobile"}
                                        textAlign={"left"}
                                        // maxLength={10}
                                        inputTitle={"Reference Number"}
                                        placeHolder={"Enter Reference Number"}
                                        required={false}
                                        inputValue={challanDetails.referenceNumber}
                                        // disabled={true}
                                        getUser={(value: any) => {
                                            setIsFormDirty(true)
                                            dispatch(resetFormModified(true));
                                            setChallanDetails({ ...challanDetails, referenceNumber: value })
                                        }}
                                    />
                                </div>
                                <div style={{ width: "300px" }} key={uuidv4()} className={directTaxError.assessmentYear ? 'setErrorcompany' : "dropdown-width"}>
                                    <DropdownComponent
                                        options={assessmentYears}
                                        title={"Assessment Year"}
                                        isDisabled={false}
                                        required={true}
                                        getData={(val) => {
                                            setIsFormDirty(true)
                                            dispatch(resetFormModified(true));
                                            setChallanDetails({ ...challanDetails, assessmentYear: val.value })

                                        }}
                                        defaultValue={[{ label: challanDetails.assessmentYear ? challanDetails.assessmentYear : "Select", value: challanDetails.assessmentYear ? challanDetails.assessmentYear : "Select" }]}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='payment-details'>
                        <div className='payment-details-header'>
                            {"Payment Details"}
                        </div>
                        <div className='payment-details-form'>
                            <div className='first-row'>
                                {
                                    selectionChallan == "Challan 280" || selectionChallan == "Challan 281" ?
                                        <div>
                                            <InputComponent
                                                height={"41px"}
                                                width={"300px"}
                                                margin={"0px 60px 30px 0px"}
                                                padding={"0px 10px 0px 10px"}
                                                // border={approvalDataErrors.max ? "1px solid red" : "1px solid #A9C3DC"}
                                                border={directTaxError.incomeTaxAmount ? "1px solid red" : "1px solid #A9C3DC"}
                                                borderRadius={"0px"}
                                                backgroundColor={"white"}
                                                color={"black"}
                                                type={"mobile"}
                                                textAlign={"right"}
                                                // maxLength={10}
                                                inputTitle={"Income Tax Amount(₹)"}
                                                placeHolder={"0.00"}
                                                required={true}
                                                inputValue={formatAmount(challanDetails.incomeTaxAmount)}
                                                // disabled={true}
                                                getUser={(value: any) => {
                                                    setIsFormDirty(true)
                                                    dispatch(resetFormModified(true));
                                                    if (value.startsWith('.')) {
                                                        value = value.substring(1);
                                                    }
                                                    let baseAmount = value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, '$1').replace(/^(\d*\.\d{0,2}).*/, '$1');

                                                    setChallanDetails({ ...challanDetails, incomeTaxAmount: baseAmount })
                                                }}
                                            />
                                        </div>
                                        :
                                        <div>
                                            <InputComponent
                                                height={"41px"}
                                                width={"300px"}
                                                margin={"0px 60px 30px 0px"}
                                                padding={"0px 10px 0px 10px"}
                                                // border={approvalDataErrors.max ? "1px solid red" : "1px solid #A9C3DC"}
                                                border={directTaxError.taxAmount ? "1px solid red" : "1px solid #A9C3DC"}
                                                borderRadius={"0px"}
                                                backgroundColor={"white"}
                                                color={"black"}
                                                type={"mobile"}
                                                textAlign={"right"}
                                                // maxLength={10}
                                                inputTitle={"Tax Amount(₹)"}
                                                placeHolder={"0.00"}
                                                required={true}
                                                inputValue={formatAmount(challanDetails.taxAmount)}
                                                // disabled={true}
                                                getUser={(value: any) => {
                                                    setIsFormDirty(true)
                                                    dispatch(resetFormModified(true));
                                                    if (value.startsWith('.')) {
                                                        value = value.substring(1);
                                                    }
                                                    let baseAmount = value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, '$1').replace(/^(\d*\.\d{0,2}).*/, '$1');
                                                    setChallanDetails({ ...challanDetails, taxAmount: baseAmount })
                                                }}
                                            />
                                        </div>
                                }
                                <div>
                                    <InputComponent
                                        height={"41px"}
                                        width={"300px"}
                                        margin={"0px 60px 30px 0px"}
                                        padding={"0px 10px 0px 10px"}
                                        // border={approvalDataErrors.max ? "1px solid red" : "1px solid #A9C3DC"}
                                        border={"1px solid #A9C3DC"}
                                        borderRadius={"0px"}
                                        backgroundColor={"white"}
                                        color={"black"}
                                        type={"mobile"}
                                        textAlign={"right"}
                                        // maxLength={10}
                                        inputTitle={"Surcharge Amount(₹)"}
                                        placeHolder={"0.00"}
                                        required={false}
                                        inputValue={formatAmount(challanDetails.surchargeAmount)}
                                        // disabled={true}
                                        getUser={(value: any) => {
                                            setIsFormDirty(true)
                                            dispatch(resetFormModified(true));
                                            if (value.startsWith('.')) {
                                                value = value.substring(1);
                                            }
                                            let baseAmount = value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, '$1').replace(/^(\d*\.\d{0,2}).*/, '$1');

                                            setChallanDetails({ ...challanDetails, surchargeAmount: baseAmount })
                                        }}
                                    />
                                </div>

                                {
                                    selectionChallan !== "Challan 282" ?
                                        <div>
                                            <InputComponent
                                                height={"41px"}
                                                width={"300px"}
                                                margin={"0px 60px 30px 0px"}
                                                padding={"0px 10px 0px 10px"}
                                                // border={approvalDataErrors.max ? "1px solid red" : "1px solid #A9C3DC"}
                                                border={"1px solid #A9C3DC"}
                                                borderRadius={"0px"}
                                                backgroundColor={"white"}
                                                color={"black"}
                                                type={"mobile"}
                                                textAlign={"right"}
                                                // maxLength={10}
                                                inputTitle={"Education Cess Amount(₹)"}
                                                placeHolder={"0.00"}
                                                required={false}
                                                inputValue={formatAmount(challanDetails.educationCessAmount)}
                                                // disabled={true}
                                                getUser={(value: any) => {
                                                    setIsFormDirty(true)
                                                    dispatch(resetFormModified(true));
                                                    if (value.startsWith('.')) {
                                                        value = value.substring(1);
                                                    }
                                                    let baseAmount = value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, '$1').replace(/^(\d*\.\d{0,2}).*/, '$1');

                                                    setChallanDetails({ ...challanDetails, educationCessAmount: baseAmount })
                                                }}
                                            />
                                        </div>
                                        :
                                        <div>
                                            <InputComponent
                                                height={"41px"}
                                                width={"300px"}
                                                margin={"0px 60px 30px 0px"}
                                                padding={"0px 10px 0px 10px"}
                                                // border={approvalDataErrors.max ? "1px solid red" : "1px solid #A9C3DC"}
                                                border={"1px solid #A9C3DC"}
                                                borderRadius={"0px"}
                                                backgroundColor={"white"}
                                                color={"black"}
                                                type={"mobile"}
                                                textAlign={"right"}
                                                // maxLength={10}
                                                inputTitle={"Interest Amount(₹)"}
                                                placeHolder={"0.00"}
                                                required={false}
                                                inputValue={formatAmount(challanDetails.interestAmount)}
                                                // disabled={true}
                                                getUser={(value: any) => {
                                                    setIsFormDirty(true)
                                                    dispatch(resetFormModified(true));
                                                    if (value.startsWith('.')) {
                                                        value = value.substring(1);
                                                    }
                                                    let baseAmount = value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, '$1').replace(/^(\d*\.\d{0,2}).*/, '$1');

                                                    setChallanDetails({ ...challanDetails, interestAmount: baseAmount })
                                                }}
                                            />
                                        </div>
                                }

                            </div>
                            <div className='second-row'>
                                {
                                    selectionChallan !== "Challan 282" &&
                                    <div>
                                        <InputComponent
                                            height={"41px"}
                                            width={"300px"}
                                            margin={"0px 60px 30px 0px"}
                                            padding={"0px 10px 0px 10px"}
                                            // border={approvalDataErrors.max ? "1px solid red" : "1px solid #A9C3DC"}
                                            border={"1px solid #A9C3DC"}
                                            borderRadius={"0px"}
                                            backgroundColor={"white"}
                                            color={"black"}
                                            type={"mobile"}
                                            textAlign={"right"}
                                            // maxLength={10}
                                            inputTitle={"Interest Amount(₹)"}
                                            placeHolder={"0.00"}
                                            required={false}
                                            inputValue={formatAmount(challanDetails.interestAmount)}
                                            // disabled={true}
                                            getUser={(value: any) => {
                                                setIsFormDirty(true)
                                                dispatch(resetFormModified(true));
                                                if (value.startsWith('.')) {
                                                    value = value.substring(1);
                                                }
                                                let baseAmount = value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, '$1').replace(/^(\d*\.\d{0,2}).*/, '$1');

                                                setChallanDetails({ ...challanDetails, interestAmount: baseAmount })
                                            }}
                                        />
                                    </div>
                                }
                                <div>
                                    <InputComponent
                                        height={"41px"}
                                        width={"300px"}
                                        margin={"0px 60px 30px 0px"}
                                        padding={"0px 10px 0px 10px"}
                                        // border={approvalDataErrors.max ? "1px solid red" : "1px solid #A9C3DC"}
                                        border={"1px solid #A9C3DC"}
                                        borderRadius={"0px"}
                                        backgroundColor={"white"}
                                        color={"black"}
                                        type={"mobile"}
                                        textAlign={"right"}
                                        // maxLength={10}
                                        inputTitle={"Penalty Amount(₹)"}
                                        placeHolder={"0.00"}
                                        required={false}
                                        inputValue={formatAmount(challanDetails.penaltyAmount)}
                                        // disabled={true}
                                        getUser={(value: any) => {
                                            setIsFormDirty(true)
                                            dispatch(resetFormModified(true));
                                            if (value.startsWith('.')) {
                                                value = value.substring(1);
                                            }
                                            let baseAmount = value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, '$1').replace(/^(\d*\.\d{0,2}).*/, '$1');

                                            setChallanDetails({ ...challanDetails, penaltyAmount: baseAmount })
                                        }}
                                    />
                                </div>
                                {
                                    selectionChallan == "Challan 282" &&
                                    <div>
                                        <InputComponent
                                            height={"41px"}
                                            width={"300px"}
                                            margin={"0px 60px 30px 0px"}
                                            padding={"0px 10px 0px 10px"}
                                            // border={approvalDataErrors.max ? "1px solid red" : "1px solid #A9C3DC"}
                                            border={"1px solid #A9C3DC"}
                                            borderRadius={"0px"}
                                            backgroundColor={"white"}
                                            color={"black"}
                                            type={"mobile"}
                                            textAlign={"right"}
                                            // maxLength={10}
                                            inputTitle={"Other Amount(₹)"}
                                            placeHolder={"0.00"}
                                            required={false}
                                            inputValue={formatAmount(challanDetails.otherAmount)}
                                            // disabled={true}
                                            getUser={(value: any) => {
                                                setIsFormDirty(true)
                                                dispatch(resetFormModified(true));
                                                if (value.startsWith('.')) {
                                                    value = value.substring(1);
                                                }
                                                let baseAmount = value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, '$1').replace(/^(\d*\.\d{0,2}).*/, '$1');

                                                setChallanDetails({ ...challanDetails, otherAmount: baseAmount })
                                            }}
                                        />
                                    </div>
                                }
                                {
                                    (selectionChallan == "Challan 280" || selectionChallan == "Challan 283") ?

                                        <div>
                                            <InputComponent
                                                height={"41px"}
                                                width={"300px"}
                                                margin={"0px 60px 30px 0px"}
                                                padding={"0px 10px 0px 10px"}
                                                // border={approvalDataErrors.max ? "1px solid red" : "1px solid #A9C3DC"}
                                                border={"1px solid #A9C3DC"}
                                                borderRadius={"0px"}
                                                backgroundColor={"white"}
                                                color={"black"}
                                                type={"mobile"}
                                                textAlign={"right"}
                                                // maxLength={10}
                                                inputTitle={"Other Amount(₹)"}
                                                placeHolder={"0.00"}
                                                required={false}
                                                inputValue={formatAmount(challanDetails.otherAmount)}
                                                // disabled={true}
                                                getUser={(value: any) => {
                                                    setIsFormDirty(true)
                                                    dispatch(resetFormModified(true));
                                                    if (value.startsWith('.')) {
                                                        value = value.substring(1);
                                                    }
                                                    let baseAmount = value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, '$1').replace(/^(\d*\.\d{0,2}).*/, '$1');
                                                    setChallanDetails({ ...challanDetails, otherAmount: baseAmount })
                                                    // const maxAmount = value.replace(/[^0-9]/g, "").replace(/(\..*)\./g, '$1').replace(/^(\d*\.\d{0,2}).*/, '$1');
                                                    // setApprovalData({
                                                    //     ...approvalData,
                                                    //     max: maxAmount
                                                    // });
                                                    // setIsFormDirty(true)
                                                    // dispatch(resetFormModified(true));
                                                }}
                                            />
                                        </div>
                                        :
                                        <div style={{ opacity: "0.4" }}>
                                            <InputComponent
                                                height={"41px"}
                                                width={"300px"}
                                                margin={"0px 60px 30px 0px"}
                                                padding={"0px 10px 0px 10px"}
                                                // border={approvalDataErrors.max ? "1px solid red" : "1px solid #A9C3DC"}
                                                border={"1px solid #A9C3DC"}
                                                borderRadius={"0px"}
                                                backgroundColor={"white"}
                                                color={"black"}
                                                type={"mobile"}
                                                textAlign={"right"}
                                                // maxLength={10}
                                                inputTitle={"Total Amount(₹)"}
                                                placeHolder={"0.00"}
                                                required={false}
                                                inputValue={formatAmount(challanTotalAmount)}
                                                disabled={true}
                                                getUser={(value: any) => {
                                                    // setIsFormDirty(true)
                                                    // if (value.startsWith('.')) {
                                                    //     value = value.substring(1);
                                                    // }
                                                    // let baseAmount = value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, '$1').replace(/^(\d*\.\d{0,2}).*/, '$1');

                                                    // setChallanDetails({ ...challanDetails, totalAmount: baseAmount })
                                                }}
                                            />
                                        </div>
                                }


                            </div>
                            {
                                (selectionChallan == "Challan 280" || selectionChallan == "Challan 283") &&
                                <div className='third-row'>
                                    <div style={{ opacity: "0.4" }} key={uuidv4()}>
                                        <InputComponent
                                            height={"41px"}
                                            width={"300px"}
                                            margin={"0px 60px 30px 0px"}
                                            padding={"0px 10px 0px 10px"}
                                            // border={approvalDataErrors.max ? "1px solid red" : "1px solid #A9C3DC"}
                                            border={"1px solid #A9C3DC"}
                                            borderRadius={"0px"}
                                            backgroundColor={"white"}
                                            color={"black"}
                                            type={"mobile"}
                                            textAlign={"right"}
                                            // maxLength={10}
                                            inputTitle={"Total Amount(₹)"}
                                            placeHolder={"0.00"}
                                            required={false}
                                            inputValue={formatAmount(challanTotalAmount)}
                                            disabled={true}
                                            getUser={(value: any) => {
                                                setIsFormDirty(true)
                                                dispatch(resetFormModified(true));
                                                if (value.startsWith('.')) {
                                                    value = value.substring(1);
                                                }
                                                let baseAmount = value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, '$1').replace(/^(\d*\.\d{0,2}).*/, '$1');

                                                if (formDataById.length == 0) {
                                                    setChallanDetails({ ...challanDetails, totalAmount: baseAmount })
                                                } else {
                                                    setChallanDetails({ ...formDataById, totalAmount: baseAmount })
                                                }
                                                // const maxAmount = value.replace(/[^0-9]/g, "").replace(/(\..*)\./g, '$1').replace(/^(\d*\.\d{0,2}).*/, '$1');
                                                // setApprovalData({
                                                //     ...approvalData,
                                                //     max: maxAmount
                                                // });
                                                // setIsFormDirty(true)
                                                // dispatch(resetFormModified(true));
                                            }}
                                        />
                                    </div>
                                </div>
                            }
                        </div>

                    </div>
                </div>
                <div className='footer-user' style={{ marginTop: "20px" }}>
                    <ButtonComponent
                        title={"Cancel"}
                        height={"50px"}
                        width={"150px"}
                        margin={"0px 8px"}
                        backgroundColor={"#888888"}
                        color={"white"}
                        className={"button-component-hover cancel"}
                        handleClick={() => {
                            if (isFormDirty) {
                                setChangesModal(true);
                                setMovingAnotherStateName('grid')
                            } else {
                                directTaxViewfun("grid")
                            }
                        }}
                    />

                    <ButtonComponent
                        title={buttonNameValue ? buttonNameValue : "Submit for approval"}
                        height={"50px"}
                        width={"200px"}
                        // disabled={isDisabled || showType === "success"}
                        backgroundColor={"#0055D4"}
                        color={"white"}
                        margin={"0px 8px"}
                        // className={isDisabled || showType === "success" ? "button-component-hover disabled" : "button-component common-btn"}
                        className={"button-component common-btn"}
                        handleClick={() => {
                            if (buttonNameValue) {
                                directTaxViewfun('form')

                            }
                            if (Object.keys(formDataById).length > 0) {
                                updateDirectTaxData(formDataById.payableDirectTaxId)
                            } else {
                                saveDirectTaxData()
                            }
                        }}
                    />

                </div>
            </MainPageCard>
            {
                showAlertBox &&
                <div className='alert-warp'>
                    <AlertBox type={alertMessageData.type} message={alertMessageData.message} />
                </div>
            }
            {
                changesModal &&
                <Changesmodal
                    closeModal={() => {
                        setChangesModal(false);
                    }
                    }
                    handleClose={() => {
                        setChangesModal(false);
                    }}
                    leavePage={() => {
                        if (movingAnotherStateName.includes('Challan')) {
                            setSelectionChallan(movingAnotherStateName)
                            setChangesModal(false);
                            clearFormData()
                        } else {
                            directTaxViewfun("grid")
                        }
                    }
                        //  returnGird()
                    } />
            }
            {
                approvalModal &&
                <div className='alert-warp'>
                    <DirectTaxApprovalModel
                        clearValue={(value) => {
                            setApprovalModal(false)
                        }}
                        gstView={(val) => {
                            directTaxViewfun(val)
                        }}
                        setLoadingScreen={(val) => { setLoadingScreen(val) }}
                        loadingScreen={() => { }}
                    />
                </div>
            }
            {
                loadingScreen &&
                <Loading />
            }
        </>
    )
}
