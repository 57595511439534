import { useEffect, useState } from 'react';
import axios from 'axios';
import InputComponent from '../common-component/form-elements/input-component';
import ButtonComponent from '../common-component/form-elements/button-component';
import '../../styles/login/create-login.scss';
import { IoIosEye, IoIosEyeOff } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';
import useAxiosPrivate from '../../services/hooks/useaxios-private';
import { encryptToken } from '../../services/utils/token-utils';



interface propsType {
    handleclick: (val: string) => void;
    UserMailFunction?: (val: any) => void;
    UserPasswordFunction?: (val: any) => void;
    setOtpAttemptsRemaining?: (val: any) => void;
}

export default function CreateLogin({ handleclick, UserMailFunction, UserPasswordFunction, setOtpAttemptsRemaining }: propsType) {
    const [username, setUsername] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const [showPassword, setShowPassword] = useState<boolean>()
    const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>()
    const [confirmPassword, setConfirmPassword] = useState<string>('')
    const [userGmail, setUserGmail] = useState<string | null>('')
    const [decryptedEmail, setDecryptedEmail] = useState<string>('')
    const [errorMessage, setErrorMessage] = useState<string>('')
    const [disabled, setDisabled] = useState<boolean>(true)
    const [isPasswordValid, setIsPasswordValid] = useState<boolean>(false)
    const [isConfirmPasswordValid, setIsConfirmPasswordValid] = useState<boolean>(false)

    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const userName = params.get('token');
        setUserGmail(userName)
    }, []);

    useEffect(() => {
        if (userGmail !== "") {
            getData()
        };
    }, [userGmail])


    async function getData() {

        try {

            const res = await axiosPrivate.post('email/decrypt', { "token": userGmail });
            if (res.data.alreadyAnUser) {
                navigate("/login");
                window.location.href = "/login";
            } else {
                setDecryptedEmail(res.data.email);
            }
        } catch (error: any) {
            if (error.response.status === 401 || error.response.status === 403) {
                navigate("/loginerror?token=" + userGmail);
            }
        }

    }

    async function submitData() {
        const minLength = 12;
        const hasLowerCase = /[a-z]/.test(password);
        const hasUpperCase = /[A-Z]/.test(password);
        const hasSpecialChar = /^(?=.*[~!@#$%_-])[a-zA-Z0-9~!@#$%_-]+$/.test(password);
        const hasNumber = /\d/.test(password);
        if (
            password.length < minLength ||
            !hasLowerCase ||
            !hasUpperCase ||
            !hasSpecialChar ||
            !hasNumber
        ) return;

        try {
            const res = await axiosPrivate.post('user/set-password', { "password": encryptToken(password), "confirmPassword": encryptToken(confirmPassword), "email": encryptToken(decryptedEmail) })
            if (res.status === 200) {
                handleclick("LoginOtpVerification");
                if (UserMailFunction) {
                    UserMailFunction(decryptedEmail)
                }
                if (UserPasswordFunction) {
                    UserPasswordFunction(password)
                }
                if (setOtpAttemptsRemaining) {
                    setOtpAttemptsRemaining(res.data.otpRemainingCount);
                }
            }
        } catch (error: any) {

        }

    }
    function isValidPassword(password: string, confirmPassword: string) {
        const minLength = 12;
        const hasLowerCase = /[a-z]/.test(password);
        const hasUpperCase = /[A-Z]/.test(password);
        const hasSpecialChar = /^(?=.*[~!@#$%_-])[a-zA-Z0-9~!@#$%_-]+$/.test(password);
        const hasNumber = /\d/.test(password);

        if (
            password.length < minLength ||
            !hasLowerCase ||
            !hasUpperCase ||
            !hasSpecialChar ||
            !hasNumber || password !== confirmPassword
        ) {
            setDisabled(true);
        }
        else {
            setDisabled(false);
        }
    }
    const toggleShowPassword = () => {

        setShowPassword(!showPassword);
    };
    const toggleShowConfirmPassword = () => {
        if (password !== "") {
            setShowConfirmPassword(!showConfirmPassword);

        }
    };
    const handleKeyPress = (e: any) => {
        if (e.keyCode === 32) {
            e.preventDefault();
        }
    }
    const handleKeyDown = (e: any) => {
        if (e.keyCode === 32) {
            e.preventDefault();
        }
        if (e.key === 'Enter') {
            submitData()
        }
    };


    return (
        <div className='login'>
            <div className='title'>{"Set Up"}</div>
            <div className='username'>
                <InputComponent
                    height={"40px"}
                    width={"100%"}
                    padding={"10px 10px"}
                    border={"none"}
                    backgroundColor={"#011D60"}
                    borderRadius={"5px"}
                    readOnly={true}
                    type={"text"}
                    color={"white"}
                    placeHolder={"User Mail"}
                    inputValue={decryptedEmail}
                    getUser={(val: any) => { setUsername(val) }}
                />
            </div>

            <div className='password'>
                <InputComponent
                    height={"40px"}
                    width={"100%"}
                    padding={"10px 10px"}
                    border={"none"}
                    borderRadius='5px'
                    blockCopyPaste={true}
                    handleKeyDown={handleKeyPress}
                    type={showPassword ? 'text' : 'password'}
                    color={"white"}
                    maxLength={16}
                    autoFocus={true}
                    backgroundColor={"#011D60"}
                    placeHolder={"Password"}
                    getUser={(val: any) => { setPassword(val); isValidPassword(val, confirmPassword); }}
                    inputValue={password}
                    points={[
                        { name: "Atleast 12 characters", pattern: "12 character" },
                        { name: "Atleast one lower case", pattern: "lowercase" },
                        { name: "Atleast one upper case", pattern: "uppercase" },
                        { name: "Atleast one number", pattern: "number" },
                        { name: "Atleast one special character <br /> (Allowed: ~, !, @, #, $, %, _, -)", pattern: "specialCharacter" },
                    ]}
                    fieldStatus={(val: any) => { setIsPasswordValid(val) }}


                />
                {showPassword ? (
                    <IoIosEyeOff className='show-icon' onClick={toggleShowPassword} />
                ) : (
                    <IoIosEye className='show-icon' onClick={toggleShowPassword} />
                )}




            </div>
            <div className='password'>
                <InputComponent
                    height={"40px"}
                    width={"100%"}
                    padding={"10px 10px"}
                    border={"none"}
                    disabled={(password === "" || isPasswordValid) ? true : false}
                    borderRadius='5px'
                    blockCopyPaste={true}
                    type={showConfirmPassword ? 'text' : 'password'}
                    color={"white"}
                    handleKeyDown={handleKeyDown}
                    backgroundColor={"#011D60"}
                    placeHolder={"Confirm Password"}
                    showTooltipTitle={false}
                    maxLength={16}
                    inputValue={confirmPassword}
                    getUser={(val: any) => { setConfirmPassword(val); isValidPassword(password, val); }}
                    points={[
                        { name: "Password do not match", pattern: "confirmpassword" },
                    ]}
                    password={password}
                    fieldStatus={(val: any) => { setIsConfirmPasswordValid(val) }}
                />

                {showConfirmPassword ? (
                    <IoIosEyeOff className={(password === "" || isPasswordValid) ? `show-icon disabled-icon` : `show-icon`} onClick={(password === "" || isPasswordValid) ? () => { } : toggleShowConfirmPassword} />
                ) : (
                    <IoIosEye className={(password === "" || isPasswordValid) ? `show-icon disabled-icon` : `show-icon`} onClick={(password === "" || isPasswordValid) ? () => { } : toggleShowConfirmPassword} />
                )}
            </div>

            <div className='login-btn'>
                <ButtonComponent
                    title={"Next"}
                    disabled={disabled}
                    height={"40px"}
                    width={"100%"}
                    boxShadow={"0px 10px 20px #0066FF80"}
                    backgroundColor={"#FFFFFF"}
                    color={"#0055D4"}
                    handleClick={() => {
                        submitData();
                    }}
                    className={disabled ? "button-component-hover disabled" : "button-component-hover"}
                />
            </div>
            {errorMessage && <p className='error'>{errorMessage}</p>}
        </div>
    )
}
