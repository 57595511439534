import { TiLocationArrow } from 'react-icons/ti';
import React, { useEffect, useRef, useState } from 'react';
import { FiUser } from 'react-icons/fi';
import profile from "../../assets/icons/profile.svg"
import { MdArrowDropDown, MdNotificationsNone, MdArrowDropUp } from 'react-icons/md';
import { GrPowerShutdown } from 'react-icons/gr';
import { v4 as uuidv4 } from 'uuid'
import { removeCookie } from '../../services/utils/cookie-utils';
import useAuth from '../../services/hooks/useauth';
import { useNavigate } from 'react-router-dom';
import DropdownComponent from '../../components/common-component/form-elements/dropdown-component';
import useAxiosPrivate from '../../services/hooks/useaxios-private';
import ButtonComponent from '../../components/common-component/form-elements/button-component';
import Changesmodal from '../../components/common-component/modals/changes-modal';
import { useDispatch, useSelector } from 'react-redux';
import { resetFormModified } from '../../redux/action';
import { ReactComponent as Information } from '../../assets/icons/Information.svg'
import { ReactComponent as ToDo } from '../../assets/icons/Remainder.svg'
import { ReactComponent as Greater } from '../../assets/icons/back.svg'
import SessionTimeoutmodal from '../../components/common-component/modals/session-timeout-modal';
import Tooltip from 'rc-tooltip';
import { ReactComponent as Notification } from '../../assets/icons/notification_tick.svg'
import 'rc-tooltip/assets/bootstrap.css';
import IconButton from '../../components/common-component/form-elements/icon-button';
import InputComponent from '../../components/common-component/form-elements/input-component';
import logo from '../../assets/images/loginlogo.png'
import io from 'socket.io-client';
import '../../styles/layouts/header.scss';
import '../../styles/documentation/documentation.scss'
import { Socket_Io } from '../../middleware/axois-api';
import { encryptToken } from '../../services/utils/token-utils';
import { HiOutlineCurrencyRupee } from 'react-icons/hi';
import { BsCurrencyRupee } from 'react-icons/bs';
import TransactionShowModal from '../../components/common-component/modals/transaction-show-modal';


interface props {
    setActiveTitleMyaccount: (val: string) => void;
    setDocumentationNav: (val: string) => void;
    documentationNav: string;
}

export default function Header({ setActiveTitleMyaccount, setDocumentationNav, documentationNav }: props) {

    const { auth, setAuth } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    interface iconsInterface {
        [key: string]: JSX.Element;
    }

    const icons: iconsInterface = {
        "ToDo": <ToDo />,
        "FYI": <Information />
    }

    const [options, setOptions] = useState<any>([])
    const [userProfileUrl, setUserProfileUrl] = useState<string>()
    const [userName, setUserName] = useState<string>("")
    const [companyName, setCompanyName] = useState<string>("")
    const [companyId, setCompanyId] = useState<any>()
    const [userType, setUserType] = useState<string>("")
    const [userDesignation, setUserDesignation] = useState<string>("")
    const [showMyAccount, setShowMyAccount] = useState<boolean>(false)
    const [showNotification, setShowNotification] = useState<boolean>(false)
    const [showLogoutModal, setShowLogoutModal] = useState(false);
    const [dropDownValue, setDropDownValue] = useState("")
    const [getEmail, setGetEmail] = useState("")
    const [dropDownLabel, setDropDownLabel] = useState('Select Option')
    const [showChangesModal, setShowChangesModal] = useState(false);
    const [showGoToChangesModal, setShowGoToChangesModal] = useState(false);
    const isFormModified = useSelector((state: any) => state.isFormModified);
    const [notification, setNotification] = useState<any>([])
    const [currentPath, setCurrentPath] = useState<any>("")
    const [showPopup, setShowPopup] = useState(false);
    const [showTransactionModal, setShowTransactionModal] = useState(false);
    const [countdown, setCountdown] = useState(1 * 60);
    const [transactionDetails, setTransactionDetails] = useState<any>({})
    const [rateCardDetails, setRateCardDetails] = useState<any>([])

    let timeoutId: any = null;
    let logoutTimeoutId: any = null;
    const userDetailRef = useRef<HTMLDivElement | null>(null);

    // console.log(axiosPrivate)
    // values for user privilege

    const privilegeOptionsMap: any = {
        FingertipAdmin: [
            // { value: '/dashboard', label: 'Dashboard' },
            { value: '/product-admin/organisation', label: 'Organisation', pbiTitle: "Fingertip Admin" },
            { value: '/bank-admin/onboarding/company', label: 'Company', pbiTitle: "Fingertip Admin" },
            { value: '/product-admin/user', label: 'Users', pbiTitle: "Fingertip Admin" },
            { value: '/product-admin/Customer-onboarding', label: 'KYC Onboarding', pbiTitle: "Fingertip Admin" },
            { value: '/product-admin/vendor-onboarding', label: 'Vendor Onboarding', pbiTitle: "Fingertip Admin" },
            { value: '/product-admin/vendor-bank-approval', label: 'VB Master', pbiTitle: "Fingertip Admin" },
            { value: '/tally-integration', label: 'Tally', pbiTitle: "Fingertip Admin" },
        ],
        BankAdmin: [
            { value: '/dashboard', label: 'Dashboard', pbiTitle: "Onboarding" },
            { value: '/bank-admin/onboarding/company', label: 'Company', pbiTitle: "Onboarding" },
            { value: '/product-admin/user', label: 'Users', pbiTitle: "Onboarding" },
        ],
        // "Vendor Admin": [
        //     { value: '/vendor/login', label: 'Vendor', pbiTitle: "Onboarding" },
        // ],
        ApprovedCompany: [
            { value: '/organisation/company', label: 'Company', pbiTitle: "Organisation" },
            { value: '/organisation/kycUpload', label: 'KYC', pbiTitle: "Organisation", },
            { value: '/product-admin/user', label: 'Users', pbiTitle: "Organisation", },
            { value: '/organisation/card', label: 'Card Details', pbiTitle: "Organisation", },
            { value: '/organisation/vendor', label: 'Vendor Details', pbiTitle: "Organisation", },
            { value: '/virtualcard', label: 'Virtual Card Details', pbiTitle: "Organisation", },
            { value: '/organisation/bank', label: 'Bank Details', pbiTitle: "Organisation", },
            { value: '/organisation/customer', label: 'Customer Details', pbiTitle: "Organisation", },
            { value: '/approval/bank', label: 'Bank', pbiTitle: "Approval" },
            { value: '/approval/card', label: 'Card', pbiTitle: "Approval" },
            { value: '/virtualcardApproval', label: 'Virtual Card', pbiTitle: "Approval" },
            { value: '/approval/vendor', label: 'Vendor', pbiTitle: "Approval" },
            { value: '/approval/customer', label: 'Customer', pbiTitle: "Approval" },
            { value: "/gst", label: 'GST', pbiTitle: "Payable" },
            { value: "/payable/utility", label: 'Utility', pbiTitle: "Payable" },
            { value: "/payable/excise-payment", label: 'Excise', pbiTitle: "Payable" },
            { value: "/payable/direct-tax", label: 'Direct Tax', pbiTitle: "Payable" },
            { value: "/payable/vendor", label: 'Vendor', pbiTitle: "Payable" },
            { value: "/payable/approver/vendor", label: 'Vendor Payment', pbiTitle: "Approval" },
            { value: "/excise-payment/approval", label: 'Excise Payment', pbiTitle: "Approval" },
            { value: "/direct-tax/approval", label: 'Direct Tax Payment', pbiTitle: "Approval" },
            { value: "/approval/utility", label: 'Utility Payment', pbiTitle: "Approval" },
            { value: "/gst/approval", label: 'GST Payment', pbiTitle: "Approval" },
        ],
        ApprovedCompanyAdmin: [
            { value: '/organisation/company', label: 'Company', pbiTitle: "Organisation" },
            { value: '/organisation/kycUpload', label: 'KYC', pbiTitle: "Organisation", },
            { value: '/product-admin/user', label: 'Users', pbiTitle: "Organisation", },
            { value: '/organisation/card', label: 'Card Details', pbiTitle: "Organisation", },
            { value: '/organisation/vendor', label: 'Vendor Details', pbiTitle: "Organisation", },
            { value: '/virtualcard', label: 'Virtual Card Details', pbiTitle: "Organisation", },
            { value: '/organisation/bank', label: 'Bank Details', pbiTitle: "Organisation", },
            { value: '/organisation/customer', label: 'Customer Details', pbiTitle: "Organisation", },
            { value: '/approval/bank', label: 'Bank', pbiTitle: "Approval" },
            { value: '/approval/card', label: 'Card', pbiTitle: "Approval" },
            { value: '/virtualcardApproval', label: 'Virtual Card', pbiTitle: "Approval" },
            { value: '/approval/vendor', label: 'Vendor', pbiTitle: "Approval" },
            { value: '/approval/customer', label: 'Customer', pbiTitle: "Approval" },
            { value: "/gst", label: 'GST', pbiTitle: "Payable" },
            { value: "/payable/utility", label: 'Utility', pbiTitle: "Payable" },
            { value: "/payable/excise-payment", label: 'Excise', pbiTitle: "Payable" },
            { value: "/payable/direct-tax", label: 'Direct Tax', pbiTitle: "Payable" },
            { value: "/payable/vendor", label: 'Vendor', pbiTitle: "Payable" },
            { value: "/payable/approver/vendor", label: 'Vendor Payment', pbiTitle: "Approval" },
            { value: "/excise-payment/approval", label: 'Excise Payment', pbiTitle: "Approval" },
            { value: "/direct-tax/approval", label: 'Direct Tax Payment', pbiTitle: "Approval" },
            { value: "/approval/utility", label: 'Utility Payment', pbiTitle: "Approval" },
            { value: "/gst/approval", label: 'GST Payment', pbiTitle: "Approval" },
        ],
        UnapprovedCompany: [
            { value: '/organisation/company', label: 'Company' },
            { value: '/organisation/kycUpload', label: 'KYC' },
        ],
        UnapprovedCompanyAdmin: [
            { value: '/organisation/company', label: 'Company' },
            { value: '/organisation/kycUpload', label: 'KYC' },
        ],
        Approver: [
            { value: '/organisation/company', label: 'Company', pbiTitle: "Organisation" },
            { value: '/organisation/kycUpload', label: 'KYC', pbiTitle: "Organisation", },
            { value: '/product-admin/user', label: 'Users', pbiTitle: "Organisation", },
            { value: '/organisation/card', label: 'Card Details', pbiTitle: "Organisation", },
            { value: '/organisation/vendor', label: 'Vendor Details', pbiTitle: "Organisation", },
            { value: '/virtualcard', label: 'Virtual Card Details', pbiTitle: "Organisation", },
            { value: '/organisation/bank', label: 'Bank Details', pbiTitle: "Organisation", },
            { value: '/organisation/customer', label: 'Customer Details', pbiTitle: "Organisation", },
            { value: '/approval/bank', label: 'Bank', pbiTitle: "Approval" },
            { value: '/approval/card', label: 'Card', pbiTitle: "Approval" },
            { value: '/virtualcardApproval', label: 'Virtual Card', pbiTitle: "Approval" },
            { value: '/approval/vendor', label: 'Vendor', pbiTitle: "Approval" },
            { value: '/approval/customer', label: 'Customer', pbiTitle: "Approval" },
            { value: "/gst", label: 'GST', pbiTitle: "Payable" },
            { value: "/payable/utility", label: 'Utility', pbiTitle: "Payable" },
            { value: "/payable/excise-payment", label: 'Excise', pbiTitle: "Payable" },
            { value: "/payable/direct-tax", label: 'Direct Tax', pbiTitle: "Payable" },
            { value: "/payable/vendor", label: 'Vendor', pbiTitle: "Payable" },
            { value: "/payable/approver/vendor", label: 'Vendor Payment', pbiTitle: "Approval" },
            { value: "/excise-payment/approval", label: 'Excise Payment', pbiTitle: "Approval" },
            { value: "/direct-tax/approval", label: 'Direct Tax Payment', pbiTitle: "Approval" },
            { value: "/approval/utility", label: 'Utility Payment', pbiTitle: "Approval" },
            { value: "/gst/approval", label: 'GST Payment', pbiTitle: "Approval" },
        ],
        Maker: [
            { value: '/organisation/company', label: 'Company', pbiTitle: "Organisation" },
            { value: '/organisation/kycUpload', label: 'KYC', pbiTitle: "Organisation", },
            { value: '/product-admin/user', label: 'Users', pbiTitle: "Organisation", },
            { value: '/organisation/card', label: 'Card Details', pbiTitle: "Organisation", },
            { value: '/organisation/vendor', label: 'Vendor Details', pbiTitle: "Organisation", },
            { value: '/virtualcard', label: 'Virtual Card Details', pbiTitle: "Organisation", },
            { value: '/organisation/bank', label: 'Bank Details', pbiTitle: "Organisation", },
            { value: '/organisation/customer', label: 'Customer Details', pbiTitle: "Organisation", },
            { value: '/approval/bank', label: 'Bank', pbiTitle: "Approval" },
            { value: '/approval/card', label: 'Card', pbiTitle: "Approval" },
            { value: '/virtualcardApproval', label: 'Virtual Card', pbiTitle: "Approval" },
            { value: '/approval/vendor', label: 'Vendor', pbiTitle: "Approval" },
            { value: '/approval/customer', label: 'Customer', pbiTitle: "Approval" },
            { value: "/gst", label: 'GST', pbiTitle: "Payable" },
            { value: "/payable/utility", label: 'Utility', pbiTitle: "Payable" },
            { value: "/payable/excise-payment", label: 'Excise', pbiTitle: "Payable" },
            { value: "/payable/direct-tax", label: 'Direct Tax', pbiTitle: "Payable" },
            { value: "/payable/vendor", label: 'Vendor', pbiTitle: "Payable" },
            { value: "/payable/approver/vendor", label: 'Vendor Payment', pbiTitle: "Approval" },
            { value: "/excise-payment/approval", label: 'Excise Payment', pbiTitle: "Approval" },
            { value: "/direct-tax/approval", label: 'Direct Tax Payment', pbiTitle: "Approval" },
            { value: "/approval/utility", label: 'Utility Payment', pbiTitle: "Approval" },
            { value: "/gst/approval", label: 'GST Payment', pbiTitle: "Approval" },
        ],
        Checker: [
            { value: '/organisation/company', label: 'Company', pbiTitle: "Organisation" },
            { value: '/organisation/kycUpload', label: 'KYC', pbiTitle: "Organisation", },
            { value: '/product-admin/user', label: 'Users', pbiTitle: "Organisation", },
            { value: '/organisation/card', label: 'Card Details', pbiTitle: "Organisation", },
            { value: '/organisation/vendor', label: 'Vendor Details', pbiTitle: "Organisation", },
            { value: '/virtualcard', label: 'Virtual Card Details', pbiTitle: "Organisation", },
            { value: '/organisation/bank', label: 'Bank Details', pbiTitle: "Organisation", },
            { value: '/organisation/customer', label: 'Customer Details', pbiTitle: "Organisation", },
            { value: '/approval/bank', label: 'Bank', pbiTitle: "Approval" },
            { value: '/approval/card', label: 'Card', pbiTitle: "Approval" },
            { value: '/virtualcardApproval', label: 'Virtual Card', pbiTitle: "Approval" },
            { value: '/approval/vendor', label: 'Vendor', pbiTitle: "Approval" },
            { value: '/approval/customer', label: 'Customer', pbiTitle: "Approval" },
            { value: "/gst", label: 'GST', pbiTitle: "Payable" },
            { value: "/payable/utility", label: 'Utility', pbiTitle: "Payable" },
            { value: "/payable/excise-payment", label: 'Excise', pbiTitle: "Payable" },
            { value: "/payable/direct-tax", label: 'Direct Tax', pbiTitle: "Payable" },
            { value: "/payable/vendor", label: 'Vendor', pbiTitle: "Payable" },
            { value: "/payable/approver/vendor", label: 'Vendor Payment', pbiTitle: "Approval" },
            { value: "/excise-payment/approval", label: 'Excise Payment', pbiTitle: "Approval" },
            { value: "/direct-tax/approval", label: 'Direct Tax Payment', pbiTitle: "Approval" },
            { value: "/approval/utility", label: 'Utility Payment', pbiTitle: "Approval" },
            { value: "/gst/approval", label: 'GST Payment', pbiTitle: "Approval" },
        ],
        Recommender: [
            { value: '/organisation/company', label: 'Company', pbiTitle: "Organisation" },
            { value: '/organisation/kycUpload', label: 'KYC', pbiTitle: "Organisation", },
            { value: '/product-admin/user', label: 'Users', pbiTitle: "Organisation", },
            { value: '/organisation/card', label: 'Card Details', pbiTitle: "Organisation", },
            { value: '/organisation/vendor', label: 'Vendor Details', pbiTitle: "Organisation", },
            { value: '/virtualcard', label: 'Virtual Card Details', pbiTitle: "Organisation", },
            { value: '/organisation/bank', label: 'Bank Details', pbiTitle: "Organisation", },
            { value: '/organisation/customer', label: 'Customer Details', pbiTitle: "Organisation", },
            { value: '/approval/bank', label: 'Bank', pbiTitle: "Approval" },
            { value: '/approval/card', label: 'Card', pbiTitle: "Approval" },
            { value: '/virtualcardApproval', label: 'Virtual Card', pbiTitle: "Approval" },
            { value: '/approval/vendor', label: 'Vendor', pbiTitle: "Approval" },
            { value: '/approval/customer', label: 'Customer', pbiTitle: "Approval" },
            { value: "/gst", label: 'GST', pbiTitle: "Payable" },
            { value: "/payable/utility", label: 'Utility', pbiTitle: "Payable" },
            { value: "/payable/excise-payment", label: 'Excise', pbiTitle: "Payable" },
            { value: "/payable/direct-tax", label: 'Direct Tax', pbiTitle: "Payable" },
            { value: "/payable/vendor", label: 'Vendor', pbiTitle: "Payable" },
            { value: "/payable/approver/vendor", label: 'Vendor Payment', pbiTitle: "Approval" },
            { value: "/excise-payment/approval", label: 'Excise Payment', pbiTitle: "Approval" },
            { value: "/direct-tax/approval", label: 'Direct Tax Payment', pbiTitle: "Approval" },
            { value: "/approval/utility", label: 'Utility Payment', pbiTitle: "Approval" },
            { value: "/gst/approval", label: 'GST Payment', pbiTitle: "Approval" },
        ],
        'BI Reports': [
            { value: '/product-admin/organisation', label: 'Organisation' },
        ],
        'Customer maker': [
            // { value: '/dashboard', label: 'Dashboard' },
            { value: '/bank-admin/onboarding/company', label: 'Company', pbiTitle: "Customer Maker" },
            { value: '/organisation/kycUpload', label: 'KYC', pbiTitle: "Customer Maker" },
        ],
        'Customer authorizer': [
            // { value: '/dashboard', label: 'Dashboard' },
            { value: '/product-admin/Customer-onboarding', label: 'KYC Onboarding', pbiTitle: "Customer Authorizer" },
        ],
    };

    useEffect(() => {
        fetchPrivillageData();
    }, []);


    const fetchPrivillageData = async () => {
        try {
            const response = await axiosPrivate.get('/user/get-privilege');
            if (response.status === 200) {
                setUserDesignation(response?.data.UserGroup)
                setUserName(response?.data.Name);
                setCompanyName(response?.data.OrgName.orgName);
                setCompanyId(response?.data.OrgName.orgId);
                setGetEmail(response?.data.email);
                setUserProfileUrl(response?.data.image);
                setUserType(response?.data.UserType);
            }
            const resFeatures = await axiosPrivate.get(`/product-admin/user/features`);

            const selectedOptions: any[] = privilegeOptionsMap[response?.data.UserPrivilege] || [];

            const pbiNames = resFeatures.data.features.map((item: any) => item.pbiName);


            const filteredApprovedCompany = selectedOptions.filter(item => (pbiNames.includes(item.label) && pbiNames.includes(item.label)));

            const filteredData = selectedOptions.filter(item => {
                for (const criteria of resFeatures.data.features) {
                    if (item.pbiTitle === criteria.pbiTitle && item.label === criteria.pbiName) {
                        return true;
                    }
                }
                return false;
            });
            filteredData.map((item: any) => {
                if (item.label == "Vendor") {
                    item.label = item.pbiTitle + " - " + item.label;
                }
            });
            setOptions(filteredData);

        } catch (error) {
        }
    };

    useEffect(() => {
        getUserNotification();
    }, [auth.token]);

    // USeEffect for session timeout
    useEffect(() => {

        // Initial setup of the timeout
        timeoutId = setTimeout(showPopupIfNeeded, 9 * 60 * 1000); // 9 minutes
        // Add event listeners to track user activity
        window.addEventListener('click', resetTimeout);
        window.addEventListener('keydown', resetTimeout);

        document.getElementById("sessionTimeout")?.addEventListener("click", resetSessionTimeout);

        // Cleanup function to remove event listeners
        return () => {
            clearTimeout(timeoutId);
            clearInterval(logoutTimeoutId);
            window.removeEventListener('click', resetTimeout);
            window.removeEventListener('keydown', resetTimeout);

            document.getElementById("sessionTimeout")?.removeEventListener("click", resetSessionTimeout);
        };
    }, []);

    // Use Effect for close states for my account and notification 
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {

            const classNamesToExclude = ["rc-tooltip-inner", "notification-heading", "notificationRemainder", "notification-remainder-main", "notification-count", "notification-name", "notification-read", "Empty-Notification", "Icons", ""];
            if (event.target instanceof HTMLElement) {
                const clickedElement = event.target;

                // Check if clicked element is an SVG element or has className corresponding to SVG icon
                const isSVGElement = (clickedElement as any).ownerSVGElement;

                // Check if the clicked element's class name or it's an SVG element
                if (userDetailRef.current && !userDetailRef.current.contains(event.target as Node) && !classNamesToExclude.includes(clickedElement.className) && !isSVGElement) {
                    setShowMyAccount(false);
                    setShowNotification(false);
                }
            }
        };
        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    //Browser logout 
    useEffect(() => {
        // const socket = io('http://localhost:3000/');
        const socket = io(Socket_Io);
        const userEmail = auth.email;
        socket.emit('userLoggedIn', userEmail);
        socket.on('userLoggedIn', (email: string) => {
            if (email == userEmail) {
                navigate("/login")
            }
        });
        return () => {
            socket.disconnect();
        };
    }, []);

    // Function for close states for my account and notification 
    const handleUserDetailClick = (event: React.MouseEvent) => {
        event.stopPropagation();
        setShowMyAccount(!showMyAccount);
        setShowNotification(false);
    };

    // Function for getting user's profile, name, designation


    // Function for usernotification
    const getUserNotification = async () => {
        try {
            const res = await axiosPrivate.get(`/user/all/notifications/unread`)
            setNotification(res.data.notifications)
        } catch (error) {
        }
    };

    // Function for  logout the user's account
    async function handleLogOut() {
        try {
            const res = await axiosPrivate.post(`logout/${auth.userSessionId}`, { "email": encryptToken(getEmail), "sessionId": auth.sessionRecordId })
            removeCookie('token');
            navigate("/login");
            dispatch(resetFormModified(false));
            setDocumentationNav("")

        } catch (error) {
            removeCookie('token');
            navigate("/login");
            dispatch(resetFormModified(false));
        }
    }

    // Function for close the logout model 
    const handleClose = () => {
        setShowLogoutModal(false);
    }

    // Function for open logout confirmation model
    const handleLogoutModel = () => {
        setShowLogoutModal(true);
        setShowMyAccount(false);
        setShowNotification(false);
    }

    // Function for reset the default my account component
    const handleClick = () => {
        setDocumentationNav("")
        navigate('/user-profile');
        setShowMyAccount(false)
        setActiveTitleMyaccount("signinOptions")
        setShowChangesModal(false)
        dispatch(resetFormModified(false));
    }

    // Function for Navigate page based goto dropdown values
    const navigatePage = () => {
        navigate(dropDownValue)
        if (dropDownValue !== "") {
            navigate(dropDownValue)
            dispatch(resetFormModified(false));
            setDropDownValue("")
            setDropDownLabel('Select Option')
        }
    }

    // Function for leave page 
    const leaveGoToChangesModal = () => {
        // setShowChangesModal(false);
        setShowGoToChangesModal(false)
        navigate(dropDownValue);
        setDropDownValue("")
        setDropDownLabel('Select Option')
        dispatch(resetFormModified(false));
    }

    // Function for Mark as Read single
    const handleSingleMarkAsRead = async (Id: any) => {
        try {
            const response = await axiosPrivate.patch(`/user/notification/single-read/${Id}`);
            if (response.status === 200) {

                getUserNotification()
            }
        } catch (error: any) {
        }
    };

    // Function for Mark as read All
    const handleMarkAsRead = async (Id: any) => {
        try {
            const response = await axiosPrivate.patch(`/user/notification/mark-all-as-read`);
            getUserNotification()
            // setShowNotification(!showNotification);
        } catch (error: any) {
        }
    };

    // Function for open the Notification icon 
    const handleClickNotificationIcon = (event: React.MouseEvent) => {
        event.stopPropagation();
        setShowNotification(!showNotification);
        setShowMyAccount(false);
        getUserNotification()
    }

    // Function to reset the timeout
    const handleResetTimeout = () => {
        setShowPopup(false)
    };

    // Function to show the popup
    const showPopupIfNeeded = () => {
        setShowPopup(true);

        logoutTimeoutId = setInterval(() => {
            setCountdown(prevCountdown => {
                if (prevCountdown <= 0) {
                    // When the countdown reaches 0, perform the logout or other actions
                    setDocumentationNav("")
                    handleLogOut();
                    return 0;
                }
                return prevCountdown - 1;
            });
        }, 1000);

    };

    // Event listener for user activity
    const resetTimeout = () => {

        var el = document.querySelector(".session-modal");
        let isHidden = false;
        if (el) {
            var style = window.getComputedStyle(el);
            isHidden = style.display === 'none'
        }
        if (isHidden) {
            clearInterval(timeoutId);
            clearInterval(logoutTimeoutId);
            setCountdown(1 * 60);
            timeoutId = setTimeout(showPopupIfNeeded, 9 * 60 * 1000); // 9 minutes
        }
    };

    //Function for stay button
    const resetSessionTimeout = () => {
        clearInterval(timeoutId);
        clearInterval(logoutTimeoutId);
        setCountdown(1 * 60);
        timeoutId = setTimeout(showPopupIfNeeded, 9 * 60 * 1000); // 9 minutes
    };

    // Function for navigate to documentation
    function documentation() {
        if (documentationNav === "") {
            setCurrentPath(window.location.pathname)
            setDocumentationNav("Documentation")
            navigate("/documentation");
        } else {
            // setDocumentationNav("")
            // navigate(currentPath)
        }
    }

    // Function for back navigate from documentation to dashboard
    function back() {
        setDocumentationNav("")
        navigate("/dashboard")
    }

    // useEffect(() => {
    //     if (currentPath !== "/documentation") {
    //         setDocumentationNav("")
    //     }else{
    //         setDocumentationNav("Documentation")
    //     }
    // }, [currentPath])

    //to block back button
    window.history.pushState(null, '', window.location.href);
    window.onpopstate = function (event: PopStateEvent) {
        window.history.go(1);
    };

    // remove token while click browser refresh
    // useEffect(() => {
    //     const handleUnload = () => {
    //         removeCookie('token');
    //     };
    //     window.addEventListener('unload', handleUnload);
    //     return () => {
    //         window.removeEventListener('unload', handleUnload);
    //     };
    // }, []);


    // UseEffect function for keyboard shortcuts...
    useEffect(() => {
        const handleKeyDown = (event: any) => {
            if (event.ctrlKey && (event.key === 'l' || event.key === 'L')) {
                event.preventDefault();
                setShowLogoutModal((prev) => !prev);
            }
            if (event.ctrlKey && event.shiftKey && (event.key === 'p' || event.key === 'P')) {
                event.preventDefault();
                getUserNotification()
                setShowNotification((prev) => !prev)
            }
        };
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    // Function for get transaction datas---------------->
    const handleTransactionData = async () => {
        try {
            const response = await axiosPrivate.get(`/get/company/transaction-charges/details/${companyId}`)
            if (response.status === 200) {
                setTransactionDetails(response.data.transactionData)
                setRateCardDetails(response.data?.rateCardDetails)
            }
        } catch (error: any) {
            console.log(error)
        }
    }

    return (
        <>
            <div className='header-container' style={documentationNav === "Documentation" ? { width: "100%" } : {}}>
                {/* 75% */}
                <div className='leftside-options' style={documentationNav === "Documentation" ? { width: "55%" } : { width: "70%" }}>
                    <div className='goto-selection' style={{ width: "100%" }}>
                        {
                            documentationNav === "Documentation" ?
                                <div style={{ display: "flex", gap: "60px", width: "100%", alignItems: "center" }}>
                                    <div className='header-wrap' onClick={back} style={{ cursor: "pointer" }} >
                                        <img className='fingertip-logo' draggable='false' src={logo} alt="" />
                                    </div>
                                    {/* <div style={{ width: "100%" }}>
                                        <InputComponent
                                            height={"60px"}
                                            width={"97%"}
                                            margin={"0px"}
                                            padding={"0px 10px 0px 45px"}
                                            borderRadius={"5px"}
                                            backgroundColor={"white"}
                                            color={"black"}
                                            type={"text"}
                                            placeHolder={"Search the Fingertip features"}
                                            inputTitle={""}
                                            required={true}
                                            maxLength={254}
                                            className={"address-input"}
                                            inputValue={''}
                                            inputField={"search"}
                                            border={"1px solid #A9C3DC"}
                                            getUser={(value: any) => {

                                            }}
                                        />
                                    </div> */}
                                </div>
                                :
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <div className='goto-text'>{'Go to'}</div>
                                    <div className='options-dropdown' key={uuidv4()}>
                                        <DropdownComponent
                                            options={options}
                                            defaultValue={[{ value: dropDownValue, label: dropDownLabel }]}
                                            getData={(value) => {
                                                setDropDownValue(value.value)
                                                setDropDownLabel(value.label)
                                            }}
                                        />
                                    </div>
                                    <div className='goto-submit' style={dropDownValue === "" ? { pointerEvents: "none", opacity: "0.5" } : {}}>

                                        <TiLocationArrow
                                            style={{ margin: "0px 0px 2px 2px", fontSize: "24px" }}
                                            className={"svg-icon"}
                                            onClick={() => {
                                                isFormModified ? setShowGoToChangesModal(true) : navigatePage()
                                            }}
                                        />
                                    </div>

                                    <div className='heading-company-name'>
                                        {userDesignation === "Vendor Admin" ? "" : companyName}
                                    </div>
                                </div>
                        }

                    </div>

                </div>
                <div className='rightside-options'>
                    {(userType === "CompanyUser" && userDesignation !== "Vendor Admin") &&
                        <div className='timer' onClick={(val: any) => { setShowTransactionModal(true) }} style={{ cursor: "pointer" }}>
                            <BsCurrencyRupee />
                        </div>
                    }
                    {
                        (userDesignation !== "Vendor Admin") &&
                        <div className='timer' onClick={handleClickNotificationIcon} >
                            <MdNotificationsNone style={{ fontSize: "24px" }} />
                            <div className={"notification-main-count"}>{notification.length}</div>
                        </div>
                    }

                    <div className='profile-icon' ref={userDetailRef} onClick={(val) => { setShowMyAccount(!showMyAccount) }} style={{ border: "none" }}>
                        {/* <FaUserAlt className='user-face-icon' /> */}
                        {userProfileUrl ? <img src={userProfileUrl} className='user-profile-image' alt={"Profile picture"} draggable={"false"} />
                            : <img src={profile} className='user-profile-image' alt={"Profile Image"} draggable={"false"} />}
                    </div>

                    <div className='user-detail' onClick={handleUserDetailClick}>
                        <b>{userDesignation}</b> <br />{userName}
                    </div>

                    <div
                        onClick={handleUserDetailClick}>
                        {
                            showMyAccount === false ?
                                <MdArrowDropDown
                                    style={{ fontSize: "35px", color: "#B3CAE1", cursor: "pointer" }}
                                    onClick={handleUserDetailClick} /> :
                                <MdArrowDropUp
                                    style={{ fontSize: "35px", color: "#B3CAE1", cursor: "pointer" }}
                                    onClick={handleUserDetailClick} />
                        }

                    </div>
                    {
                        (userDesignation !== "Vendor Admin") &&
                        <div>
                            <IconButton
                                iconName={"Documentation"}
                                height={"40px"}
                                width={"40px"}
                                fontSize={"30px"}
                                margin={"0px 8px"}
                                color={"white"}
                                backgroundColor={""}
                                hover={false}
                                handleClick={documentation}
                            />
                        </div>
                    }

                    <div className='power-btn' onClick={handleLogoutModel}>
                        <div>
                            <GrPowerShutdown style={{ fontSize: "24px" }} />
                        </div>
                    </div>
                </div>

                {showMyAccount && !showNotification && <div className='user-profile-account'
                    onClick={isFormModified ? () => { setShowChangesModal(true) } : () => { handleClick() }} >
                    <FiUser className='user-profile-icon' />
                    <div className='user-account'>My Account</div>
                </div>}

                {showNotification && !showMyAccount &&
                    <div className='notification-remainder-main'>
                        <div className='notificationRemainder'>
                            <div className='notification-heading'>
                                <div className='notification-sub'><b className='notification-name'>{"Notifications"}</b><p className='notification-count'>{notification.length}</p></div>
                                <div className='notification-read'
                                    onClick={handleMarkAsRead}> {"Mark all as read"} </div>
                            </div>
                        </div>

                        {
                            notification.length === 0 ? <div className={"Empty-Notification"}>{"No notification"}</div> :
                                <div style={{ overflow: "auto", height: "340px", overflowX: "hidden" }}>
                                    {notification.map((data: any, i: any) => (
                                        <>
                                            <div className='payment-remainder' key={i}>
                                                <div style={{ display: "flex", alignItems: "flex-start", gap: "15px" }}>
                                                    <div className='Icons'>
                                                        {icons[data.messageType]}  {/* {icons[data.type]} */}
                                                    </div>
                                                    <div>
                                                        {/* <b>{data.title}</b> */}
                                                        <p>{data.message}</p>
                                                    </div>
                                                </div>
                                                <div className="icon-button-container" style={{ cursor: "pointer" }} >
                                                    <Tooltip
                                                        placement="right"
                                                        overlay={"Mark as read"}
                                                        overlayClassName="custom-tooltip"
                                                    >
                                                        <div onClick={() => handleSingleMarkAsRead(data.notificationId)}>
                                                            <Notification />
                                                        </div>
                                                    </Tooltip>
                                                </div>
                                            </div>
                                            <hr key={`hr-${i}`} />
                                        </>
                                    ))}
                                </div>
                        }
                        <ButtonComponent
                            title={"Read all notifications"}
                            height={"30px"}
                            width={""}
                            backgroundColor={"#0055D4"}
                            color={"#FFFFFF"}
                            className={"button-component"}
                            handleClick={() => {
                                navigate("/notification");
                                setShowNotification(false);
                                setDocumentationNav("");
                            }} />

                    </div >
                }
            </div >

            {
                showLogoutModal && <Changesmodal
                    closeModal={handleLogOut}
                    leavePage={() => { setShowLogoutModal(false) }}
                    type={"logout"}
                    handleClose={handleClose}
                />
            }

            {
                showChangesModal &&
                <Changesmodal
                    closeModal={() => setShowChangesModal(false)}
                    leavePage={() => handleClick()}
                    handleClose={() => setShowChangesModal(false)} />
            }
            {
                showGoToChangesModal &&
                <Changesmodal
                    closeModal={() => setShowGoToChangesModal(false)}
                    leavePage={() => leaveGoToChangesModal()}
                    handleClose={() => setShowGoToChangesModal(false)} />
            }

            {
                showTransactionModal &&
                <TransactionShowModal
                    transactionDetails={transactionDetails}
                    rateCardDetails={rateCardDetails}
                    handleTransactionData={handleTransactionData}
                    clearValue={(val: boolean) => { setShowTransactionModal(val); }}
                />
            }

            <div className={"session-modal"} style={{ display: showPopup ? "block" : "none" }}>
                <SessionTimeoutmodal
                    closeModal={handleResetTimeout}
                    leavePage={handleLogOut}
                    countdown={countdown}
                />
            </div>
        </>
    )
}
