import react, { useState } from 'react';
import '../../styles/login/password-changed.scss';
import ButtonComponent from '../common-component/form-elements/button-component';
import success from '../../assets/gif-files/success.gif';

interface Props {
    handleclick: (val: any) => void;
    isPasswordSetup: boolean
}
export default function PasswordChanged({ handleclick, isPasswordSetup }: Props) {
    const [passwordSetup, setPasswordSetup] = useState<boolean>(isPasswordSetup)

    return (
        <div className='password-changed-container'>
            <div className='success-gif'><img src={success} alt={""} /></div>
            <div className='content'>
                {!passwordSetup && <div>{"Password Changed"}</div>}
                {!passwordSetup && <div>{"Your password has been changed successfully"}</div>}
                {passwordSetup && <div>{"Password Setup"}</div>}
                {passwordSetup && <div>{"Your password has been Setup successfully"}</div>}
            </div>
            <div className='login-btn'>
                <ButtonComponent
                    title={"Login"}
                    height={"40px"}
                    width={"100%"}
                    boxShadow={"0px 10px 20px #0066FF80"}
                    backgroundColor={"#FFFFFF"}
                    color={"#0055D4"}
                    handleClick={() => {
                        window.history.pushState(null, "", window.location.href.split("?")[0]);
                        handleclick("login")
                        // window.location.href = window.location.origin + window.location.pathname 
                    }}
                    className={"button-component-hover"}
                />
            </div>
        </div>
    )
}
