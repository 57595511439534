import { createStore } from "redux";

// Initial state
const initialState = {
    isFormModified: false,
};

// Reducer
const formReducer = (state = initialState, action: { type: any; payload: any; }) => {
    switch (action.type) {
        case "RESET_FORM_MODIFIED":
            return {
                ...state,
                isFormModified: action.payload,
            };
        default:
            return state;
    }
};

// Create the store
const store = createStore(formReducer);

export default store;
