import React, { useEffect, useRef, useState, } from 'react';
import '../../../styles/modals/department-add-modal.scss';
import { RxCross1 } from 'react-icons/rx';
import ButtonComponent from '../form-elements/button-component';
import IconButton from '../form-elements/icon-button';
import InputComponent from '../form-elements/input-component';
import DeleteModal from './delete-modal';
import AlertBox from '../alert-box';
import useAxiosPrivate from '../../../services/hooks/useaxios-private';


interface Styles {
    clearValue: (val: any) => void;
    getRocCity?: () => void;
    fetchData: any;
    setRocCityValues: any;
    setCityValues: any;
    // transactionMapping: any[];
    rocCityValues: any[];
    vendorBankApprovalData: any;
    setVendorBankApprovalData: any;
}



export default function AddRocCity({
    clearValue, getRocCity, rocCityValues, setRocCityValues, setCityValues, fetchData, setVendorBankApprovalData, vendorBankApprovalData,
}: Styles) {

    const [rocCityName, setRocCityName] = useState<string>("")
    const [editIndex, setEditIndex] = useState<number>()
    const [deletIndex, setDeletIndex] = useState<number>()
    const [isRocCityEditMode, setIsRocCityEditMode] = useState<boolean>(false)
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
    const [showType, setShowType] = useState("warning") // error message showType
    const [showMessage, setShowMessage] = useState<string>("") // error message showMessage
    const [showAlertBox, setShowAlertBox] = useState(false) // error message ShowAlertBox
    const [currentRocCity, setCurrentRocCity] = useState()

    const axiosPrivate = useAxiosPrivate();

    const saveRocCity = async () => {
        // /^[a-zA-Z][a-zA-Z - /]*$/
        if (rocCityName !== "" && new RegExp(/^[a-zA-Z][a-zA-Z/ -]*$/).test(rocCityName)) {
            if (rocCityName === currentRocCity && isRocCityEditMode) {
                setIsRocCityEditMode(false)
                setRocCityName("")
                setShowAlertBox(true);
                setShowType("success");
                setShowMessage("ROC City updated successfully.")
                clearAleart();
                return;
            }
            try {
                const response = isRocCityEditMode ?
                    await axiosPrivate.patch(`vendor/roc-city/update/${editIndex}`, { rocCity: rocCityName, }) :
                    await axiosPrivate.post("vendor/roc-city/addition", { rocCity: rocCityName, stateId: vendorBankApprovalData.state });
                if (response.status === 200) {
                    setIsRocCityEditMode(false)
                    setRocCityName("")
                    setShowAlertBox(true);
                    setShowType("success");
                    setShowMessage(response.data.message)
                    clearAleart();
                    getRocCity && getRocCity()

                    // fetchData(`/state/roc-cities/${vendorBankApprovalData.state}`, setCityValues, 'RocCity', "rocCityId", "name");
                    fetchData(`/state/roc-cities`, setCityValues, 'RocCity', "rocCityId", "name");
                    setVendorBankApprovalData((prevState: any) => ({
                        ...prevState,
                        cityName: "",
                        city: "",
                    }));

                }
            } catch (error: any) {
                setShowAlertBox(true);
                setShowType("danger");
                setShowMessage(error.response.data.error)
                clearAleart();
            }
        } else {
            setShowAlertBox(true);
            setShowType("danger");
            setShowMessage(rocCityName === "" ?
                "Mandatory field(s) should not be left blank" :
                "Some of the field(s) are not in required format")
            clearAleart();

        }
    }

    const clearAleart = () => {
        const timer = setTimeout(() => {
            setShowAlertBox(false);
            setShowMessage("");
            clearTimeout(timer);
        }, 5000);
    }

    const editRocCity = async (value: string, index: number) => {
        setEditIndex(index);
        setRocCityName(value);
        setIsRocCityEditMode(true);
    };

    const deleteRocCity = async () => {
        try {
            let data = rocCityValues.filter((x) => x.city == deletIndex)
            if (data.length > 0) {
                setShowAlertBox(true);
                setShowType("danger");
                setShowMessage("Dependency Conflict! The selected record(s) cannot be deleted.")
                clearAleart();
                setShowDeleteModal(false);
                return;
            }
            const response = await axiosPrivate.delete(`vendor/roc-city/delete/${deletIndex}`);
            if (response.status === 200) {
                setShowDeleteModal(false);
                getRocCity && getRocCity();
                setShowAlertBox(true);
                setShowType("success");
                setShowMessage(response.data.message)
                clearAleart();
                setVendorBankApprovalData((prevState: any) => ({
                    ...prevState,
                    cityName: "",
                    city: "",
                }));
                setShowDeleteModal(false);
                fetchData(`/state/roc-cities`, setCityValues, 'RocCity', "rocCityId", "name");
            }
        } catch (error: any) {
            setShowAlertBox(true);
            setShowType("danger");
            setShowMessage(error.response.data.error)
            clearAleart();
            setShowDeleteModal(false);
        }

    }

    const ConfremationDelete = async (value: string, index: number) => {
        setDeletIndex(index);
        setShowDeleteModal(true);
    };


    return (
        <div className={"department-add-modal"}>
            <div className={"container-add-modal"}>
                <div className={"close-icon"}>
                    <span className={"cross-icon"}>
                        <RxCross1 onClick={() => clearValue(false)
                        } style={{ fontSize: "40px" }} />
                    </span>
                </div>
                <div className='sub-container'>
                    <div className='department'>
                        <InputComponent
                            height={"40px"}
                            width={"350px"}
                            margin={"0px 10px 30px 0px"}
                            padding={"0px 0px 0px 10px"}
                            border={"1px solid #A9C3DC"}
                            borderRadius={"0px"}
                            backgroundColor={"white"}
                            color={"black"}
                            type={"text"}
                            maxLength={100}
                            inputTitle={"ROC City"}
                            placeHolder={"Enter City"}
                            required={true}
                            inputValue={rocCityName}
                            getUser={(value: any) => {
                                const finalvalue = value.replace(/[^a-zA-Z -]/g, '')
                                setRocCityName(finalvalue)
                            }}
                        />

                        <ButtonComponent
                            title={isRocCityEditMode ? "Update" : "Save"}
                            height={"40px"}
                            width={"130px"}
                            backgroundColor={"#0055D4"}
                            color={"white"}
                            margin={"0px 8px"}
                            disabled={rocCityName === ""}
                            className={rocCityName === "" ? "button-component disabled" : "button-component common-btn"}
                            handleClick={() => {
                                saveRocCity();

                            }}
                        />
                    </div>

                    <div className='view-table-component' style={{ marginTop: "20px", maxHeight: '300px', overflowY: 'auto' }}>
                        <table key={rocCityValues.length}>
                            <thead>
                                <th className='privilege'>City</th>
                                <th className='access' style={{ paddingLeft: "27%" }}>Action</th>
                            </thead>
                            <tbody>
                                {
                                    rocCityValues.length === 0 ?
                                        <tr className='expensetype-style-grid-differ'>
                                            {/* <td></td> */}
                                            <td colSpan={2}>No records to show</td>
                                            {/* <td></td> */}
                                        </tr>
                                        :
                                        rocCityValues.map((data, index) => (
                                            <tr className='main-body-header' key={index} style={{ justifyContent: "space-between", padding: "" }}>
                                                <td className='first-name' style={{ padding: "5px", paddingLeft: "30px" }}>{data.value}</td>
                                                <td className='last-icon' style={{ display: "flex", justifyContent: "flex-end", padding: "5px" }}>
                                                    <IconButton
                                                        iconName={"Edit"}
                                                        height={"40px"}
                                                        width={"40px"}
                                                        fontSize={"30px"}
                                                        margin={"0px 8px"}
                                                        color={"white"}
                                                        backgroundColor={"#0055D4"}
                                                        hover={true}
                                                        handleClick={() => {
                                                            editRocCity(data.value, data.id);
                                                            setCurrentRocCity(data.value)
                                                        }}
                                                    />
                                                    <IconButton
                                                        iconName={"Delete"}
                                                        height={"40px"}
                                                        width={"40px"}
                                                        fontSize={"25px"}
                                                        margin={"0px 8px"}
                                                        color={"white"}
                                                        backgroundColor={"#D60000"}
                                                        hover={true}
                                                        handleClick={() => {
                                                            ConfremationDelete(data.value, data.id);
                                                        }}
                                                    />
                                                </td>
                                            </tr>
                                        ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            {
                showDeleteModal &&
                <div>
                    <DeleteModal
                        getDelete={deleteRocCity}
                        clearValue={(value) => { setShowDeleteModal(value) }}
                        modelType={"grid-delete"}
                    />
                </div>
            }
            {
                showAlertBox &&
                <div className='alert-warp'>
                    <AlertBox type={showType} message={showMessage} />
                </div>
            }
        </div>
    )
}



