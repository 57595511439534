import { RxCross1 } from 'react-icons/rx';
import InputComponent from '../common-component/form-elements/input-component';
import ButtonComponent from '../common-component/form-elements/button-component';
import IconButton from '../common-component/form-elements/icon-button';
import '../../styles/dashboard/save-filter-modal.scss';
interface propsType {
    allSaveFilterTableData: any;
    storeInputData: any;
    isApplyButton: any;
    hide: () => void;
    storeSaveFilterInputData: (value: string, type: string) => void;
    handleSaveFilterAddButton: () => void;
    handleSaveFilterClearButton: () => void;
    handleApplyButton: (data: any) => void;
    setShowDeleteModal: (id: number) => void;
}

function SaveFilterModal({
    hide,
    storeSaveFilterInputData,
    handleSaveFilterAddButton,
    handleSaveFilterClearButton,
    allSaveFilterTableData,
    storeInputData,
    setShowDeleteModal,
    handleApplyButton,
    isApplyButton
}: propsType) {

    return (
        <div className={"save-filter-modal"}>
            <div className={"container-add-modal"}>
                <div className={"close-icon"}>
                    <span className={"cross-icon"}>
                        <RxCross1
                            onClick={() => { hide() }}
                            style={{ fontSize: "40px" }}
                        />
                    </span>
                </div>
                <div className='save-filter-header-main'>
                    <p className='save-filter'>{"Save Filter"}</p>
                    <div className='enter-name'>
                        <InputComponent
                            height={"40px"}
                            width={"300px"}
                            margin={"0px 30px 30px 0px"}
                            padding={"0px 0px 0px 10px"}
                            border={"1px solid #A9C3DC"}
                            borderRadius={"0px"}
                            backgroundColor={"white"}
                            color={"black"}
                            type={"text"}
                            maxLength={100}
                            inputTitle={"Name"}
                            placeHolder={"Enter Name"}
                            required={true}
                            inputValue={storeInputData.name}
                            getUser={(value: any) => { storeSaveFilterInputData(value, "name") }}
                        />
                        <InputComponent
                            height={"40px"}
                            width={"300px"}
                            margin={"0px 10px 30px 0px"}
                            padding={"0px 0px 0px 10px"}
                            border={"1px solid #A9C3DC"}
                            borderRadius={"0px"}
                            backgroundColor={"white"}
                            color={"black"}
                            type={"text"}
                            maxLength={100}
                            inputTitle={"Description"}
                            placeHolder={"Enter Description"}
                            required={false}
                            inputValue={storeInputData.description}
                            getUser={(value: any) => { storeSaveFilterInputData(value, "description") }}
                        />
                    </div>
                    <ButtonComponent
                        title={"Add"}
                        height={"40px"}
                        width={"130px"}
                        backgroundColor={"#0055D4"}
                        color={"white"}
                        margin={"0px 0px"}
                        disabled={false}
                        className={"button-component common-btn"}
                        handleClick={() => { handleSaveFilterAddButton() }}
                    />
                </div>
                <div className='filter-table-component'>
                    <table>
                        <thead>
                            <tr>
                                <th className='privilege'>Name</th>
                                <th className='Description'>Description</th>
                                <th className='access'>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                allSaveFilterTableData.length === 0 ?
                                    <tr className='style-grid-differ'>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td>No records to show</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    :
                                    allSaveFilterTableData.map((data: any, index: number) => (
                                        <tr
                                            className='main-body-header'
                                            key={index}
                                        >
                                            <td className='first-name'>{data.name}</td>
                                            <td className='first-name'>{data.description}</td>
                                            <td className='last-icon'>
                                                <ButtonComponent
                                                    title={isApplyButton === data.id ? "Applied" : "Apply"}
                                                    height={"40px"}
                                                    width={"130px"}
                                                    backgroundColor={isApplyButton === data.id ? "#200160" : "#0055D4"}
                                                    color={"white"}
                                                    margin={"0px 0px"}
                                                    disabled={isApplyButton === data.id}
                                                    className={isApplyButton === data.id ? "button-component" : "button-component common-btn"}
                                                    handleClick={() => { handleApplyButton(data) }}
                                                />
                                                <IconButton
                                                    iconName={"Delete"}
                                                    height={"40px"}
                                                    width={"40px"}
                                                    fontSize={"25px"}
                                                    margin={"0px 8px"}
                                                    color={"white"}
                                                    backgroundColor={"#D60000"}
                                                    hover={isApplyButton !== data.id}
                                                    opacity={isApplyButton !== data.id ? "1" : "0.5"}
                                                    cursor={isApplyButton !== data.id ? "pointer" : "default"}
                                                    handleClick={() => { isApplyButton !== data.id && setShowDeleteModal(data.id) }}
                                                />
                                            </td>
                                        </tr>
                                    ))
                            }
                        </tbody>
                    </table>
                </div>
                <div className='fotter-btn'>
                    <ButtonComponent
                        title={"Clear Filter"}
                        height={"40px"}
                        width={"130px"}
                        backgroundColor={"#0055D4"}
                        color={"white"}
                        margin={"0px 0px"}
                        disabled={false}
                        className={"button-component common-btn"}
                        handleClick={() => { handleSaveFilterClearButton() }}
                    />
                </div>
            </div>
        </div>
    )
}

export default SaveFilterModal;