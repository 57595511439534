import React from 'react';
import loadingimage from '../../../assets/images/Loding-spinner.png'
import '../../../styles/common-component/loading-screen.scss'

const Loading = () => {
    return (
        <div className="loading-container">
            <div className='sub-Loading'>
                <img src={loadingimage} alt="Loading..." className='loading-image' />
                <h2>Loading...</h2>
            </div>
        </div>
    );
};

export default Loading;