import React from 'react'
import RadioOrCheckbox from '../common-component/form-elements/radio-or-checkbox';
import InputComponent from '../common-component/form-elements/input-component';
import '../../styles/virtualcard/approve-modal.scss'




interface Props {

    approvaldata: (value: any, type: string) => void;
    approvalStatus: any
}

export default function Approvemodal({ approvalStatus, approvaldata }: Props) {

    return (
        <>
            <div className='virtual-approval-main'>
                <div className='virtualCard-approval'>
                    <div className='approval-title-virtualcard'>
                        {/* <p>This new request required your approval.</p> */}

                        {approvalStatus.status === "Declined" ? (<p>Your request to add a Virtual card master record has been declined.</p>) : (<p>Your request to add a Virtual card master record has been approved.</p>)}
                    </div>
                    <div className='approval-radio-input' style={
                        { pointerEvents: "auto", opacity: 0.5, }
                    }>
                        <div className='approval-radio-btn'>
                            <RadioOrCheckbox

                                value={"Approved"}
                                type={"radio"}
                                name={"Approved"}
                                disabled={true}
                                margin={"0px 30px 0px 0px"}
                                checkedValue={approvalStatus.status}
                                getVal={(val) => {
                                    // setIsFormDirty(true)
                                    approvaldata(val, "status");
                                    approvaldata("", "declineRemarks");
                                    // dispatch(resetFormModified(true));
                                    // setIsDisabled(false)
                                }}
                            />
                            <div>
                                <RadioOrCheckbox
                                    value={"Declined"}
                                    type={"radio"}
                                    name={"Declined"}
                                    disabled={true}
                                    checkedValue={approvalStatus.status}
                                    getVal={(val) => {
                                        // setIsFormDirty(true)
                                        approvaldata(val, "status");
                                        // dispatch(resetFormModified(true));
                                        // setIsDisabled(false)
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='decline-reason'>
                    <div className='reason-area'
                        style={
                            { pointerEvents: "none", opacity: 0.5 }
                        }
                    >
                        <InputComponent
                            height={"60px"}
                            width={"90%"}
                            margin={"10px 60px 30px 0px"}
                            padding={"0px 0px 0px 10px"}
                            border={"1px solid #A9C3DC"}
                            borderRadius={"0px"}
                            backgroundColor={"white"}
                            color={"black"}
                            maxLength={250}
                            type={"text"}
                            disabled={true}
                            inputTitle={"Reason for Declining Request"}
                            required={true}
                            placeHolder={"Reason"}
                            inputValue={approvalStatus.declineRemarks}
                            getUser={(value: any) => {
                                // setIsFormDirty(true)
                                approvaldata(value, "declineRemarks");
                                // dispatch(resetFormModified(true));
                            }}
                        />
                    </div>
                </div>
            </div>
        </>



    )
}
