import { RxCross1 } from 'react-icons/rx';
import SwitchButton from '../common-component/form-elements/switch-button';
import ButtonComponent from '../common-component/form-elements/button-component';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import DownloadModalDragAndDrop from './download-modal-drag-and-drop';
import "../../styles/dashboard/download-chart-data-modal.scss";
import { useEffect } from 'react';

interface propsType {
  storeGroupByOption: string;
  storeFilterOptions: any;
  switchBtnOverAll: boolean;
  switchBtnDetail: boolean;
  checkBoxData: any;
  checkedHeaderdData: any;
  moveHeader: any;
  handleCheckBox: (e: any, value: string) => void;
  handleDownloadBtn: () => void;
  setSwitchBtnOverAll: () => void;
  setSwitchBtnDetail: () => void;
  hide: () => void;
}

function DownloadChartDataModal({
  hide,
  storeGroupByOption,
  storeFilterOptions,
  switchBtnOverAll,
  setSwitchBtnOverAll,
  setSwitchBtnDetail,
  switchBtnDetail,
  handleDownloadBtn,
  checkBoxData,
  checkedHeaderdData,
  moveHeader,
  handleCheckBox
}: propsType) {

  useEffect(() => {
    document.body.style.overflow = 'hidden';
  }, [])
  return (
    <div className={"download-chart-modal"}>
      <div className={"container-add-modal"}>
        <div className={"close-icon"}>
          <span className={"cross-icon"}>
            <RxCross1
              onClick={() => {
                hide()
                document.body.style.overflow = 'auto';
              }}
              style={{ fontSize: "40px" }}
            />
          </span>
        </div>
        <div className='body-main'>
          <p className='report-setting'>{"Report Setting"}</p>
          <div className='search-main'>
            <p className='search-creteria'>{"Search Creteria"}</p>
            <div className='filter-data-main'>
              <div className='group-main'>
                <p className='group'>{"Group by :"}</p>
                <p className='group-data'>{storeGroupByOption}</p>
              </div>
              <div className='group-main'>
                <p className='filter-by'>{"Filter by :"}</p>
                <div className='filter-map'>
                  {
                    storeFilterOptions.map((elem: any, i: number) => {
                      return (
                        <p key={i}>
                          {`${elem.selectedValue} (${elem.cardTitle})`}
                          <span>{storeFilterOptions.length !== (i + 1) ? " +" : ""}</span>
                        </p>
                      )
                    })
                  }
                </div>
              </div>
            </div>
          </div>
          <div className='export-main'>
            <p className='export'>{"Export"}</p>
            <div className='show-overall-report'>
              <p>{"Show Overall Report"}</p>
              <SwitchButton
                isOn={switchBtnOverAll}
                id={"1-organization-vs-companies"}
                handleToggle={() => setSwitchBtnOverAll()}
              />
            </div>
            <div className='show-detail-report' style={{ borderColor: switchBtnDetail ? "#0055D4" : "#A9C3DC" }}>
              <div className='show-detail-report-main'>
                <p>{"Show Detail Report"}</p>
                <SwitchButton
                  id={"2-organization-vs-companies"}
                  isOn={switchBtnDetail}
                  handleToggle={() => setSwitchBtnDetail()}
                />
              </div>
              <div
                className='choose-field-main'
                style={{
                  opacity: switchBtnDetail ? "1" : "0.5",
                  pointerEvents: switchBtnDetail ? "visible" : "none"
                }}>
                <p className='choose-field'>{"Choose Field to add to report"}</p>
                <div className='bottom-choose-field'>
                  <div className='check-box-main'>
                    {
                      Object.keys(checkBoxData).map((elem: any, i: number) => {
                        return (
                          <div className='map-checkbox' key={i}>
                            <input
                              type={"checkbox"}
                              checked={checkBoxData[elem]}
                              id={`${elem}-${i}`}
                              onChange={(e) => { handleCheckBox(e, elem) }}
                            />
                            <label htmlFor={`${elem}-${i}`}>{elem}</label>
                          </div>
                        )
                      })
                    }
                  </div>
                  <div className='drag-drop-main' >
                    <DndProvider backend={HTML5Backend}>
                      {
                        checkedHeaderdData.length > 0 &&
                        checkedHeaderdData.map((headerName: any, i: number) => {
                          return (
                            <DownloadModalDragAndDrop
                              key={headerName.id}
                              index={i}
                              id={headerName.id}
                              text={headerName.name}
                              moveHeader={moveHeader}
                            />
                          )
                        }
                        )
                      }
                    </DndProvider>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='footer-btn'>
            <ButtonComponent
              title={"Download"}
              height={"40px"}
              width={"130px"}
              backgroundColor={"#0055D4"}
              color={"white"}
              margin={"0px 0px"}
              disabled={false}
              className={"button-component common-btn"}
              handleClick={() => {
                handleDownloadBtn()
                document.body.style.overflow = 'auto'
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default DownloadChartDataModal;