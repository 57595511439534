import React, { useEffect, useState } from 'react'
import "../../styles/view-table.scss";
import IconButton from '../../components/common-component/form-elements/icon-button';
import DeleteModal from '../../components/common-component/modals/delete-modal';
import "../../styles/onboarding/company/grid-icon.scss"
import "../../styles/onboarding/company/company-form.scss"



interface Props {
    // tableDatas: TableValues[];
    employeeDetailsAll: any[];
    retriveData: (value: any, index: number) => void;
    checkDelete: (id: any, index: any) => void;
    clearValue: (value: any) => void;
}

function EmployeeDetailsGrid({ employeeDetailsAll, retriveData, checkDelete, clearValue }: Props) {
    const [showDeleteModel, setShowDeleteModel] = useState<boolean>(false)
    const [idData, setIdData] = useState()
    const [deleteIndex, setDeleteIndex] = useState<number>()



    return (
        <>
            <div className='view-table-component' style={{ marginTop: "40px", marginBottom: "40px", height: "auto", overflow: "auto" }}>
                <table>
                    <thead>
                        {/* <tr> */}
                        <th className='access' style={{ paddingLeft: "20px" }}>Employee Name</th>
                        <th className='access'>Employee ID</th>
                        <th className='access'>Department</th>
                        <th className='access'>Designation</th>
                        <th className='access'>Email ID</th>
                        <th className='access'>Contact Number</th>
                        <th className='access' style={{ textAlign: "center" }}>Is Active</th>
                        <th className='access' style={{ textAlign: "center" }}>Action</th>
                        {/* </tr> */}
                    </thead>
                    <tbody >
                        {
                            employeeDetailsAll.length === 0 ?
                                <tr className='style-grid-differ'>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td>No records to show</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr> :
                                (employeeDetailsAll) && employeeDetailsAll.map((data: any, index: number) => (
                                    <tr className='main-body-header' key={index} >
                                        <td className='middle-icons' style={{ paddingLeft: "20px" }}>{data.employeeName}</td>
                                        <td className='middle-icons'>{data.employeeId}</td>
                                        <td className='middle-icons'>{data.department}</td>
                                        <td className='middle-icons'>{data.designation}</td> 
                                        <td className='middle-icons'  style={{ paddingRight: "20px" }}>{data.emailId.toLowerCase()}</td>
                                        <td className='middle-icons'>{data.contactNumber}</td>
                                        <td className='middle-icons' style={{ textAlign: "center" }}>{data.status}</td>
                                        <td className='' style={{ textAlign: "center", display: "flex", justifyContent: "center", padding: "5px", paddingRight: "10px" }}>
                                            <IconButton
                                                iconName={"Edit"}
                                                height={"40px"}
                                                width={"40px"}
                                                fontSize={"30px"}
                                                margin={"0px 8px"}
                                                color={"white"}
                                                backgroundColor={"#0055D4"}
                                                hover={true}
                                                handleClick={() => {
                                                    retriveData(data, index)
                                                }}
                                            />
                                            <IconButton
                                                iconName={"Delete"}
                                                height={"40px"}
                                                width={"40px"}
                                                fontSize={"25px"}
                                                margin={"0px 8px"}
                                                color={"white"}
                                                backgroundColor={"#D60000"}
                                                hover={true}
                                                handleClick={() => {
                                                    setShowDeleteModel(true)
                                                    setIdData(data.id);
                                                    setDeleteIndex(index);

                                                }}
                                            />
                                        </td>
                                    </tr>
                                ))
                        }

                    </tbody>
                </table>
            </div>
            {
                showDeleteModel &&
                <div >
                    <DeleteModal
                        getDelete={() => {
                            setShowDeleteModel(false)
                            checkDelete(idData, deleteIndex)
                            // clearValue(false)
                        }}
                        clearValue={(value) => { setShowDeleteModel(value) }}
                        modelType={"grid-delete"}
                    />
                </div>
            }
        </>
    )
}

export default EmployeeDetailsGrid;