import CustomEllipsisRenderer from "../users/users-aggrid-column-config"

export const TallyColumnData = [
    {
        field: 'companyName', headerName: 'Company Name', minWidth: 280, maxWidth: 360, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, filtertype: "text", cellStyle: { textAlign: 'left' }, cellRendererFramework: CustomEllipsisRenderer,
    },
    {
        field: 'vendorName', headerName: 'Vendor Name', minWidth: 280, maxWidth: 360, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, filtertype: "text", cellStyle: { textAlign: 'left' }, cellRendererFramework: CustomEllipsisRenderer,
    },
    {
        field: 'voucherCategory', headerName: 'Voucher Category', minWidth: 280, maxWidth: 360, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, filtertype: "text", cellStyle: { textAlign: 'left' }, cellRendererFramework: CustomEllipsisRenderer,
    },
    {
        field: 'voucherDate', headerName: 'Voucher Date', minWidth: 280, maxWidth: 360, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, filtertype: "text", cellStyle: { textAlign: 'left' }, cellRendererFramework: CustomEllipsisRenderer,
    },
    {
        field: 'partyLedgerName', headerName: 'Party Ledger Name', minWidth: 280, maxWidth: 360, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, filtertype: "text", cellStyle: { textAlign: 'left' }, cellRendererFramework: CustomEllipsisRenderer,
    },
    {
        field: 'supplierInvoiceNumber', headerName: 'Supplier Invoice Number', minWidth: 280, maxWidth: 360, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, filtertype: "text", cellStyle: { textAlign: 'left' }, cellRendererFramework: CustomEllipsisRenderer,
    },
    {
        field: 'supplierInvoiceDate', headerName: 'Supplier Invoice Date', minWidth: 280, maxWidth: 360, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, filtertype: "text", cellStyle: { textAlign: 'left' }, cellRendererFramework: CustomEllipsisRenderer,
    },
    {
        field: 'narration', headerName: 'Narration', minWidth: 280, maxWidth: 360, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, filtertype: "text", cellStyle: { textAlign: 'left' }, cellRendererFramework: CustomEllipsisRenderer,
    },
    {
        field: 'pgRefNumber', headerName: 'PG Ref Number', minWidth: 280, maxWidth: 580, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, filtertype: "text", cellStyle: { textAlign: 'left' }, cellRendererFramework: CustomEllipsisRenderer,
    },
    {
        field: 'pgRefStatus', headerName: 'PG Ref Status', minWidth: 280, maxWidth: 580, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, filtertype: "text", cellStyle: { textAlign: 'left' }, cellRendererFramework: CustomEllipsisRenderer,
    },
    {
        field: 'pgDateTime', headerName: 'PG Date Time', minWidth: 280, maxWidth: 580, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, filtertype: "text", cellStyle: { textAlign: 'left' }, cellRendererFramework: CustomEllipsisRenderer,
    },
    {
        field: 'payoutRefStatus', headerName: 'Payout Ref Status', minWidth: 280, maxWidth: 580, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, filtertype: "text", cellStyle: { textAlign: 'left' }, cellRendererFramework: CustomEllipsisRenderer,
    },
    {
        field: 'payoutRefNumber', headerName: 'Payout Ref Number', minWidth: 280, maxWidth: 580, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, filtertype: "text", cellStyle: { textAlign: 'left' }, cellRendererFramework: CustomEllipsisRenderer,
    },
    {
        field: 'payoutRefDateTime', headerName: 'Payout Ref Date Time', minWidth: 280, maxWidth: 580, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, filtertype: "text", cellStyle: { textAlign: 'left' }, cellRendererFramework: CustomEllipsisRenderer,
    },
    {
        field: 'pgOrderNumber', headerName: 'PG Order Number', minWidth: 280, maxWidth: 580, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, filtertype: "text", cellStyle: { textAlign: 'left' }, cellRendererFramework: CustomEllipsisRenderer,
    },
    {
        field: 'tallyPostDateTime', headerName: 'Tally Post Date Time', minWidth: 280, maxWidth: 580, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, filtertype: "text", cellStyle: { textAlign: 'left' }, cellRendererFramework: CustomEllipsisRenderer,
    },
    {
        field: 'tallyVoucherRefNo', headerName: 'Tally Voucher Ref No', minWidth: 280, maxWidth: 580, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, filtertype: "text", cellStyle: { textAlign: 'left' }, cellRendererFramework: CustomEllipsisRenderer,
    },
    {
        field: 'tallyPostStatus', headerName: 'Sync Status', minWidth: 280, maxWidth: 360, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, filtertype: "text", cellStyle: { textAlign: 'left' }, cellClassRules: {
            'success-cell': (params: any) => params.value === 'Sync',
            'denied-cell': (params: any) => params.value === 'Not Synced',
        }, cellRendererFramework: CustomEllipsisRenderer,
    },
]

export const tallyRowData = [
    {}
]