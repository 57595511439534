import { ReactComponent as Filter } from '../../assets/icons/filter.svg'
import "../../styles/dashboard/side-filter-tooltip.scss";
interface propType {
    storeFilterOptions: any;
    setOpenRightSideFilter: () => void;
}

function SideFilterTooltip({ setOpenRightSideFilter, storeFilterOptions }: propType) {

    return (
        <div className='side-fillter-tooltip-component' onClick={() => { setOpenRightSideFilter() }}>
            <div className='filter-header'>
                <div>
                    <Filter className='icon' />
                </div>
                <p>{"Filters and slicers affecting this visual"}</p>
            </div>
            <div className={'filter-body'}>
                <p className='include'>{"Included (1)"}</p>
                <div  className='filter-options-map'>
                    {
                        storeFilterOptions.map((elem: any, i: number) => {
                            return (
                                <p key={i}>
                                    {`${elem.selectedValue} (${elem.cardTitle})`}
                                    <span>{storeFilterOptions.length !== (i + 1) ? " +" : ""}</span>
                                </p>
                            )
                        })
                    }
                </div>
            </div>
            <div className='triangle'></div>
        </div>
    )
}

export default SideFilterTooltip