import React, { useEffect, useState, forwardRef, useImperativeHandle, useRef } from 'react'
import InputComponent from '../../../common-component/form-elements/input-component'
import IconButton from '../../../common-component/form-elements/icon-button'
import DropdownComponent from '../../../common-component/form-elements/dropdown-component'
import ButtonComponent from '../../../common-component/form-elements/button-component';
import { v4 as uuidv4 } from 'uuid'
import useAxiosPrivate from "../../../../services/hooks/useaxios-private";
import AlertBox from '../../../common-component/alert-box';
import { useDispatch, useSelector } from "react-redux";
import { resetFormModified } from "../../../../redux/action";
import Changesmodal from '../../../common-component/modals/changes-modal';
import moment from 'moment';
import RadioOrCheckbox from '../../../common-component/form-elements/radio-or-checkbox';
import { BsPlusLg } from 'react-icons/bs';
import AddVendorType from '../../../common-component/modals/vendor-type-modal';
// import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';
import Chart from 'chart.js/auto';

interface DropdownItem {
    label: string;
    value: string;
    id: string
}
export interface ChildRef {
    next: (val: any) => void;
}
interface props {
    fetchDatas: (value: any, chooseType: any) => void;
    companyOverviewPan: string;
    setCompanyOverviewPan: (value: any) => void;
    onboardingViewfun: (val: any) => void;
    mainApiData: any;
    setMainApiData: (val: any) => void;
    setIsFormDirty: (val: any) => void;
    setChooseType: (val: any) => void;
    sendDataApi: () => void;
    iconStatus: any
    setShowPage: (val: any) => void;
    setIconStatus: (val: any) => void;
    chooseType: any;
    showPage: any;
    isEditMode: boolean;
    editData: any;
    vendorDetails: any;
    setVendorDetails: any;
    stepperStatus: any;
    setStepperStatus: any;
    saveVendorDatas: any


}
let pancardno = "AAGCV3794D";
let directorpan = 'DUQPA2883C';

const BasicDetails = forwardRef<ChildRef, props>(({ mainApiData, setIsFormDirty, showPage, setShowPage, setIconStatus, sendDataApi, editData, iconStatus, isEditMode, onboardingViewfun, setMainApiData, fetchDatas, companyOverviewPan, setCompanyOverviewPan, setChooseType, chooseType, vendorDetails, setVendorDetails, stepperStatus, setStepperStatus, saveVendorDatas }, ref) => {
    const [constitutionData, setConstitutionData] = useState<DropdownItem[]>([])
    const [businessData, setBusinessData] = useState<DropdownItem[]>([])
    const [billingCycleData, setBillingCycleData] = useState<DropdownItem[]>([])
    const [subCategoryData, setSubCategoryData] = useState<DropdownItem[]>([])
    const [vendorTypeData, setVendorTypeData] = useState<DropdownItem[]>([])
    const [basicDetailsErrors, setBasicDetailsErrors] = useState<Record<string, boolean>>({})
    const [showType, setShowType] = useState("warning") // error message showType
    const [showMessage, setShowMessage] = useState<string>("") // error message showMessage
    const [showAlertBox, setShowAlertBox] = useState(false) // error message ShowAlertBox
    const isFormModified = useSelector((state: any) => state.isFormModified);
    const dispatch = useDispatch();
    const [showChangesModal, setShowChangesModal] = useState<boolean>(false);
    const [errorChoosetype, setErrorChoosetype] = useState<boolean>(false);
    const [addVendorType, setAddVendorType] = useState<boolean>(false);
    const [basicDetailspercentage, setBasicDetailspercentage] = useState<any>(0);

    useEffect(() => {
        getConstitutionData()
        getBusinessData()
        getSubCategoryData()
        getBillingCycleData()
    }, [])

    const axiosPrivate = useAxiosPrivate();
    useImperativeHandle(ref, () => {
        return {
            next: next
        }
    });
    async function getConstitutionData() {
        try {
            const response = await axiosPrivate.get("/bank-admin/company/dropdown/constituion")
            const ddRegionVal = response.data.companyConstitutions.map((e: any, i: number) => {
                const dropdownItem: DropdownItem = {
                    label: e.constitution,
                    value: e.constitution,
                    id: e.id
                };

                return dropdownItem;
            })
            const sortedData = [...ddRegionVal].sort((a, b) => a.label.localeCompare(b.label));
            setConstitutionData(sortedData)
        }
        catch (error) {
        }
    }
    async function getBusinessData() {
        try {
            const response = await axiosPrivate.get("/bank-admin/company/dropdown/buisnessCategory")
            const ddRegionVal = response.data.buisnessCategory.map((e: any, i: number) => {
                const dropdownItem: DropdownItem = {
                    label: e.buisnessCategory,
                    value: e.buisnessCategory,
                    id: e.id
                };
                return dropdownItem;
            })
            const sortedData = [...ddRegionVal].sort((a, b) => a.label.localeCompare(b.label));
            setBusinessData(sortedData)
        }
        catch (error) {
        }
    }
    async function getBillingCycleData() {
        try {
            const response = await axiosPrivate.get("/companyadmin/organisation/billing-cycle")
            const ddRegionVal = response.data.billingCycle.map((e: any, i: number) => {
                const dropdownItem: DropdownItem = {
                    label: e.billingCycleName,
                    value: e.billingCycleName,
                    id: e.billingCycleId
                };

                return dropdownItem;
            })
            const defaultValue = {
                label: "Select",
                value: "Select",
                id: ""
            }
            const sortedData = [defaultValue, ...ddRegionVal];
            setBillingCycleData(sortedData)
        }
        catch (error) {
        }
    }
    async function getSubCategoryData() {
        try {
            const response = await axiosPrivate.get("/bank-admin/company/dropdown/sub-category")
            const ddRegionVal = response.data.subCategory.map((e: any, i: number) => {
                const dropdownItem: DropdownItem = {
                    label: e.subCategory,
                    value: e.subCategory,
                    id: e.id
                };
                return dropdownItem;
            })
            const sortedData = [...ddRegionVal].sort((a, b) => a.label.localeCompare(b.label));
            setSubCategoryData(sortedData)
        }
        catch (error) {
        }
    }

    useEffect(() => {
        if (isEditMode) return;
        // setMainApiData({
        //     ...mainApiData,
        //     isActive: "Yes"
        // })
    }, [])


    const userValidationRules: any = {

        emailId: {
            regex: vendorDetails?.basicDetails?.emailId !== "" ? '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$' : "",
            field: "mandatory",
            shouldNotBe: ""
        },
        contactNumber: {
            regex: vendorDetails?.basicDetails?.contactNumber !== "" ? /^[0-9]{10}$/ : "",
            field: "mandatory",
            shouldNotBe: ""
        },
        // vendorCode: {
        //     regex: /^[A-Z0-9-]+$/,
        //     field: "mandatory",
        //     shouldNotBe: ""
        // },
        // tan: {
        //     regex: mainApiData?.tan ? /^[A-Z]{4}[0-9]{5}[A-Z]{1}$/ : "",
        //     field: "",
        //     shouldNotBe: ""
        // },
        // companyConstitution: {
        //     regex: "",
        //     field: "mandatory",
        //     shouldNotBe: ""
        // },
        // businessCategory: {
        //     regex: "",
        //     field: "mandatory",
        //     shouldNotBe: ""
        // },
        // typesOfBillingCycle: {
        //     regex: "",
        //     field: "",
        //     shouldNotBe: ""
        // },

        // CompanyCin: {
        //     regex: (mainApiData && mainApiData?.mainApiData.CompanyCin) ? /\b[A-Z]\d{5}[A-Z]{2}\d{4}[A-Z]{3}\d{6}\b/ : "",
        //     field: (mainApiData && !mainApiData?.vendorOverView?.CompanyCin && !mainApiData?.vendorOverView?.CompanyLLPIN) ? "mandatory" : "",
        //     shouldNotBe: ""
        // },
        // CompanyLLPIN: {
        //     regex: (mainApiData && mainApiData?.vendorOverView?.CompanyLLPIN) ? /\b[A-Z]{3}-\d{4}\b/ : "",
        //     field: (mainApiData && !mainApiData?.vendorOverView?.CompanyCin && !mainApiData?.vendorOverView?.CompanyLLPIN) ? "mandatory" : "",
        //     shouldNotBe: ""
        // },
        // noOfdays: {
        //     regex: (mainApiData?.typesOfBillingCycle === "Days") ? /^(0[1-9]|[1-9]\d*)$/ : "",
        //     field: (mainApiData?.typesOfBillingCycle === "Days") ? "mandatory" : "",
        //     shouldNotBe: ""
        // },
        // isActive: {
        //     regex: "",
        //     field: "mandatory",
        //     shouldNotBe: ""
        // },
        // remarks: {
        //     regex: (mainApiData?.isActive === "No") ? /^[^\s].*/ : "",
        //     field: (mainApiData?.isActive === "No") ? "mandatory" : "",
        //     shouldNotBe: ""
        // },

    }

    const updatedIsValueValidAll: Record<string, boolean> = {};
    const validateForm = (): boolean => {
        const updatedFieldValidity: Record<string, boolean> = {};
        const isMandatoryfalse = true;
        const isRegexfalse = true;
        Object.keys(userValidationRules).forEach((field) => {
            const rule = userValidationRules[field];
            const value = vendorDetails.basicDetails[field];
            const isValueValid = (rule.field === "mandatory") ? (value ? (value !== rule.shouldNotBe) : false) : true;
            const isRegexValid = rule.regex !== "" ? new RegExp(rule.regex).test(value) : true;
            updatedFieldValidity[field] = !isValueValid || !isRegexValid;
            updatedIsValueValidAll[field] = !isValueValid;
        });
        setBasicDetailsErrors(updatedFieldValidity);
        const isFormValid = Object.keys(updatedFieldValidity).every((keys) => {
            return !updatedFieldValidity[keys]
        });
        return isFormValid;
    };

    function handelFectch() {
        let regexPattern;
        if (vendorDetails?.basicDetails?.chooseType.label === "CIN") {
            regexPattern = /\b[A-Z]\d{5}[A-Z]{2}\d{4}[A-Z]{3}\d{6}\b/;
        } else if (vendorDetails?.basicDetails?.chooseType.label === "PAN") {
            regexPattern = /[A-Z]{5}\d{4}[A-Z]{1}/;
        } else if (vendorDetails?.basicDetails?.chooseType.label === "LLPIN") {
            regexPattern = /\b[A-Z]{3}-\d{4}\b/;
        } else {
            return;
        }
        const isMatch = regexPattern.test(vendorDetails?.basicDetails.chooseTypeValue);
        const isFormValid = validateForm();
        if (isMatch && isFormValid) {
            fetchDatas(vendorDetails?.basicDetails.chooseTypeValue, vendorDetails?.basicDetails?.chooseType.label);
            setErrorChoosetype(false)
        } else {
            if (!isFormValid) {
                setShowAlertBox(true);
                setShowType("danger");
                if (!isMatch) {
                    setErrorChoosetype(true);
                    (vendorDetails?.basicDetails.chooseTypeValue == "" || vendorDetails?.basicDetails.chooseTypeValue == undefined) ?
                        setShowMessage(vendorDetails?.basicDetails?.chooseType.label + " should not be empty.") :
                        setShowMessage(vendorDetails?.basicDetails?.chooseType.label + " is not in required format.");
                } else {
                    setShowMessage('Some of the field(s) are not in required format.');
                    setErrorChoosetype(false);
                }
                clearAlert('');
            } else {
                setShowAlertBox(true);
                setShowType("danger");
                (vendorDetails?.basicDetails.chooseTypeValue == "" || vendorDetails?.basicDetails.chooseTypeValue == undefined) ?
                    setShowMessage(vendorDetails?.basicDetails?.chooseType.label + " should not be empty.") :
                    setShowMessage(vendorDetails?.basicDetails?.chooseType.label + " is not in required format.");
                clearAlert('');
                setErrorChoosetype(true)
            }
        }

    }

    async function duplicateCheck() {
        const finalData = {
            "vendorOverView": {
                "vendorName": mainApiData?.vendorName ? mainApiData?.vendorName : "",
                "vendorCode": mainApiData?.vendorCode ? mainApiData?.vendorCode : "",
                "tan": mainApiData?.tan ? mainApiData?.tan : "",
                "chooseType": mainApiData?.chooseType ? mainApiData?.chooseType : "",
                "chooseTypeValue": mainApiData?.chooseTypeValue ? mainApiData?.chooseTypeValue : "",
                "vendorOverviewId": editData?.vendorOverView?.vendorOverviewId ? editData?.vendorOverView?.vendorOverviewId : ""
            }
        }
        let statusMessage: boolean = false;
        try {
            const res = await axiosPrivate.post("companyadmin/organisation/vendor/duplicate-checking", finalData)

            if (res.status === 200) {
                statusMessage = true;
                setBasicDetailsErrors({
                    vendorName: false,
                    vendorCode: false

                })
            }
        }
        catch (error: any) {
            setShowAlertBox(true)
            setShowType("danger")
            setShowMessage(error.response.data.error)
            clearAlert("")
            statusMessage = false;
            const errorData = error.response?.data.error;
            setBasicDetailsErrors({
                vendorName: errorData === "vendorName" ? true : false,
                vendorCode: errorData === "vendorCode" ? true : false
            })

        }
        return statusMessage;
    }

    const clearAlert = (status: any) => {
        const timer = setTimeout(() => {
            setShowAlertBox(false);
            setShowMessage("");
            if (status === 400) {

            }
            clearTimeout(timer);
        }, 5000);
    }
    async function next(next: any = "") {
        const isFormValid = validateForm();
        if (isFormValid) {
            saveVendorDatas()
            // setShowPage('VendorOverview')
            // const Duplicate = await duplicateCheck();
            // if (Duplicate) {
            //     if (isEditMode) {
            //         sendDataApi();
            //         setIconStatus({
            //             ...iconStatus,
            //             companyIcon: "completed",
            //         })
            //         dispatch(resetFormModified(false));
            //     } else {
            //         setShowPage(next != "" ? next : "BankDetails")
            //         if (next != "") {
            //             switch (next) {
            //                 case "VendorOverview":
            //                     setIconStatus({
            //                         ...iconStatus,
            //                         companyIcon: "selected",
            //                     })
            //                     break;
            //                 case "BankDetails":
            //                     setIconStatus({
            //                         ...iconStatus,
            //                         companyIcon: "completed",
            //                         adminIcon: "selected",
            //                     })
            //                     break;
            //                 case "Address":
            //                     setIconStatus({
            //                         ...iconStatus,
            //                         companyIcon: "selected",
            //                         addressIcon: "completed",
            //                     })
            //                     break;
            //                 case "DirectorsInfo":
            //                     setIconStatus({
            //                         ...iconStatus,
            //                         directorIcon: "selected",
            //                         addressIcon: "completed"
            //                     })
            //                     break;
            //                 case "StatutoryDetails":
            //                     setIconStatus({
            //                         ...iconStatus,
            //                         statutoryIcon: "selected",
            //                         directorIcon: "completed"
            //                     })
            //                     break;
            //                 case "PointOfContact":
            //                     setIconStatus({
            //                         ...iconStatus,
            //                         PointOfContactIcon: "selected",
            //                         statutoryIcon: "completed"
            //                     })
            //                     break;
            //                 default:
            //                     break;
            //             }
            //         } else {
            //             setIconStatus({
            //                 ...iconStatus,
            //                 companyIcon: "completed",
            //                 adminIcon: "selected"
            //             })
            //         }
            //     }
            // } else {

            // }
            setIsFormDirty(false);
        } else {
            setShowAlertBox(true);
            setShowType("danger");
            setShowMessage(Object.values(updatedIsValueValidAll).every((values) => !values) ?
                "Some of the field(s) are not in required format." : "Mandatory field(s) should not be left blank.")
            clearAlert('');
        }
    }


    function sendToVendor() {
        const isFormValid = validateForm();
        if (isFormValid) {

        } else {
            setShowAlertBox(true);
            setShowType("danger");
            setShowMessage(Object.values(updatedIsValueValidAll).every((values) => !values) ?
                "Some of the field(s) are not in required format." : "Mandatory field(s) should not be left blank.")
            clearAlert('');
        }
    }

    useEffect(() => {
        fetchData(`companyadmin/vendor/vendor-types`, setVendorTypeData, 'vendorTypes');

    }, [])
    //, id: any, representName: any
    const fetchData = async (url: string, setData: (data: any[]) => void, nestedProperty: string) => {
        try {
            const response = await axiosPrivate.get(url);
            if (response.status === 200) {
                const dropdownValues = response.data[nestedProperty].map((item: any) => ({
                    label: item.vendorType,
                    value: item.vendorType,
                    id: item.id,
                }));
                const selectValue = {
                    label: "Select",
                    value: "",
                    id: ""
                }
                const sortedData = [selectValue, ...dropdownValues].sort((a, b) => a.label.localeCompare(b.label));
                setData(sortedData);

            }
        } catch (error) {
        }
    };

    function returnGird() {
        onboardingViewfun("grid")
        dispatch(resetFormModified(false));
    }
    let type: any = [{ label: "PAN", value: "PAN" }, { label: "CIN", value: "CIN" }, { label: "LLPIN", value: "LLPIN" }]

    // percentage chart
    const chartRef: any = useRef(null);

    useEffect(() => {
        const data = {
            value: basicDetailspercentage,
            max: 100,
            label: "Progress"
        };

        const config: any = {
            type: 'doughnut',
            data: {
                datasets: [{
                    data: [data.value, data.max - data.value],
                    backgroundColor: [basicDetailspercentage == 100 ? "green" : '#D2A100', '#0055D4'],
                    borderWidth: 0
                }]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                cutoutPercentage: 85,
                rotation: -90,
                circumference: 180,
                tooltips: {
                    enabled: false
                },
                legend: {
                    display: false
                },
                animation: {
                    animateRotate: true,
                    animateScale: false
                },
                title: {
                    display: true,
                    text: data.label,
                    fontSize: 16
                }
            }
        };

        if (chartRef.current) {
            const chartCtx = chartRef.current.getContext('2d');
            const gaugeChart = new Chart(chartCtx, config);

            // Cleanup on unmount
            return () => {
                gaugeChart.destroy();
            };
        }
    }, [basicDetailspercentage]);
    // percentage chart

    useEffect(() => {
        let count = 0;
        let mandatoryFields = ['emailId', 'contactNumber']
        Object.keys(vendorDetails?.basicDetails)?.forEach((item: any) => {
            if (vendorDetails?.basicDetails[item] !== "" && mandatoryFields.includes(item)) {
                count++;
                setStepperStatus({ ...stepperStatus, basicDetails: "working" })
            }
        });
        let percentage: any = (count / 2) * 100
        if (percentage.toFixed(0) == 100) {
            setStepperStatus({ ...stepperStatus, basicDetails: "completed" })
        }
        setBasicDetailspercentage(percentage.toFixed(0))
    }, [vendorDetails?.basicDetails])
    console.log(vendorDetails)
    return (
        <>
            {/* <div style={{ background: "#F8F8F8", height: "110px", width: "190px", position: "absolute", right: "0px", top: "0px", display: "flex", borderRadius: "0px 10px" }}>
                <div style={{ position: 'relative', height: '95px', width: '135px', left: "30px", top: "0px" }}>
                    <canvas ref={chartRef}></canvas>
                    <div style={{ width: "40px", position: "absolute", left: "50px", top: "58px", textAlign: "center" }}><strong style={{ fontSize: "16px" }}>{basicDetailspercentage}%</strong></div>
                    <div style={{ position: "absolute", left: "10px", fontSize: "16px", bottom: "-12px" }}>0%</div>
                    <div style={{ position: "absolute", right: "-4px", fontSize: "16px", bottom: "-11px" }}>100%</div>
                </div>
            </div> */}
            <div className='form-wrapper'>
                <div className='form-area'
                // style={{ pointerEvents: "none", opacity: 0.5 }}
                >
                    <div style={{ pointerEvents: "none", opacity: 0.5 }}>
                        <div className='company-heading'>
                            Basic Details
                        </div>
                        <div className='form-main-end'>
                            <div className='input-fetch-flex' >
                                <div className='mail-icon-vendor-input' style={{ marginRight: "45px" }}>
                                    <InputComponent
                                        height={"40px"}
                                        width={"300px"}
                                        margin={"0px 0px 45px 0px"}
                                        padding={"0px 40px 0px 10px"}
                                        borderRadius={"0px"}
                                        backgroundColor={"white"}
                                        autoFocus
                                        // disabled={isEditMode && (mainApiData?.status === "Pending Approval") ? true : false}
                                        color={"black"}
                                        type={"text"}
                                        placeHolder={"Enter Email ID"}
                                        inputTitle={"Email ID"}
                                        required={true}
                                        maxLength={254}
                                        className={"gmail-input"}
                                        inputField={"gmail"}
                                        disabled={true}
                                        border={basicDetailsErrors.emailId ? "1px solid red" : "1px solid #A9C3DC"}
                                        inputValue={vendorDetails?.basicDetails.emailId}
                                        getUser={(value: any) => {
                                            const removeInitialSpace = value.replace(/^\s+/, "");
                                            setVendorDetails({
                                                ...vendorDetails,
                                                basicDetails: {
                                                    ...vendorDetails.basicDetails,
                                                    emailId: removeInitialSpace
                                                }
                                            })
                                            setIsFormDirty(true)
                                            dispatch(resetFormModified(true));
                                        }}
                                    />
                                </div>
                                <div style={{ marginRight: "45px", marginBottom: "15px" }}>
                                    <InputComponent
                                        height={"40px"}
                                        width={"300px"}
                                        margin={"0px 0px 30px 0px"}
                                        padding={"0px 0px 0px 70px"}
                                        borderRadius={"0px"}
                                        backgroundColor={"white"}
                                        // disabled={isEditMode && (mainApiData?.status === "Pending Approval") ? true : false}
                                        color={"black"}
                                        type={"mobile"}
                                        placeHolder={"Enter Contact Number"}
                                        maxLength={10}
                                        inputTitle={"Contact Number"}
                                        required={true}
                                        className={"mobilenumber-input"}
                                        disabled={true}
                                        border={basicDetailsErrors?.contactNumber ? "1px solid red" : "1px solid #A9C3DC"}
                                        inputValue={vendorDetails?.basicDetails.contactNumber}
                                        getUser={(value: any) => {
                                            const removeInitialSpace = value.replace(/^\s+/, "").replace(/\D/g, "");
                                            setVendorDetails({
                                                ...vendorDetails,
                                                basicDetails: {
                                                    ...vendorDetails.basicDetails,
                                                    contactNumber: removeInitialSpace
                                                }
                                            })
                                            setIsFormDirty(true)
                                            dispatch(resetFormModified(true));
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='form-main-wrapper'>
                            <div key={uuidv4()} style={{ marginRight: "45px" }} className={basicDetailsErrors.companyConstitution ? 'setErrorcompany' : "dropdown-width"}>
                                <DropdownComponent
                                    width={"300px"}
                                    required={false}
                                    // isDisabled={isEditMode && (mainApiData?.status === "Pending Approval") ? true : false}
                                    title={"Choose PAN/LLPIN/CIN"}
                                    isDisabled={true}
                                    defaultValue={vendorDetails?.basicDetails?.chooseType ? [vendorDetails?.basicDetails?.chooseType] : [{ label: "Select", value: "Select" }]}
                                    options={[{ label: "PAN", value: "PAN" }, { label: "CIN", value: "CIN" }, { label: "LLPIN", value: "LLPIN" }]}
                                    getData={(value: any) => {
                                        setIsFormDirty(true)
                                        dispatch(resetFormModified(true));
                                        setVendorDetails({
                                            ...vendorDetails,
                                            basicDetails: {
                                                ...vendorDetails.basicDetails,
                                                chooseType: value,
                                            },
                                        })
                                    }

                                    }

                                />
                            </div>
                            <div className='input-fetch-flex'>
                                <div style={(isEditMode || (vendorDetails?.basicDetails?.emailId == "" || vendorDetails?.basicDetails?.contactNumber == "")) ? { pointerEvents: "none", opacity: 0.5 } : {}}>
                                    <InputComponent
                                        height={"40px"}
                                        width={"250px"}
                                        padding={"0px 0px 0px 10px"}
                                        margin={"0px 0px 30px 0px"}
                                        border={errorChoosetype ? "1px solid red" : "1px solid #A9C3DC"}
                                        borderRadius={"0px"}
                                        backgroundColor={"white"}
                                        disabled={true}
                                        color={"black"}
                                        type={"text"}
                                        maxLength={
                                            vendorDetails?.basicDetails?.chooseType.label === "CIN" ? 21 :
                                                vendorDetails?.basicDetails?.chooseType.label === "PAN" ? 10 :
                                                    vendorDetails?.basicDetails?.chooseType.label === "LLPIN" ? 8 : undefined
                                        }
                                        placeHolder={`Enter Vendor ${((vendorDetails?.basicDetails?.chooseType.label !== (undefined)) && (vendorDetails?.basicDetails?.chooseType.label !== "")) ? vendorDetails?.basicDetails?.chooseType.label : "PAN"}`}
                                        inputTitle={`Vendor ${((vendorDetails?.basicDetails?.chooseType.label !== (undefined)) && (vendorDetails?.basicDetails?.chooseType.label !== "")) ? vendorDetails?.basicDetails?.chooseType.label : "PAN"}`}
                                        inputValue={vendorDetails?.basicDetails?.chooseTypeValue}
                                        getUser={(value) => {
                                            const userRes = value.toUpperCase().replace(/^\s+/, "")
                                            setIsFormDirty(true)
                                            dispatch(resetFormModified(true));
                                            setVendorDetails({
                                                ...vendorDetails,
                                                basicDetails: {
                                                    ...vendorDetails.basicDetails,
                                                    chooseTypeValue: userRes,
                                                },
                                            })
                                        }}
                                    />
                                </div>
                                <div style={
                                    // { opacity: (vendorDetails?.basicDetails?.emailId == "" || vendorDetails?.basicDetails?.contactNumber == "") ? '0.5' : '' }
                                    (vendorDetails?.basicDetails?.emailId == "" || vendorDetails?.basicDetails?.contactNumber == "") ? { pointerEvents: "none", opacity: 0.5, marginRight: "10px" } : {}
                                }>
                                    <IconButton
                                        iconName={"Fetch"}
                                        height={"40px"}
                                        width={"100px"}
                                        fontSize={""}
                                        color={""}
                                        border={""}
                                        borderRadius={"0px"}
                                        disabled={(vendorDetails?.basicDetails?.emailId == "" || vendorDetails?.basicDetails?.contactNumber == "") ? true : false}
                                        backgroundColor={"#0055D4"}
                                        hover={false}
                                        margin={"4px 0px 0px 0px"}
                                        handleClick={() => {
                                            if (isEditMode || (vendorDetails?.basicDetails?.emailId == "" || vendorDetails?.basicDetails?.contactNumber == "")) return;
                                            handelFectch()
                                        }}
                                    />
                                </div>
                            </div>

                        </div>
                        <div className='form-main-wrapper'>
                            <div style={{ marginRight: "45px" }}>
                                <InputComponent
                                    height={"40px"}
                                    width={"300px"}
                                    margin={"0px 0px 30px 0px"}
                                    padding={"0px 0px 0px 10px"}
                                    border={basicDetailsErrors && basicDetailsErrors.tan ? "1px solid red" : "1px solid #A9C3DC"}
                                    borderRadius={"0px"}
                                    backgroundColor={"white"}
                                    color={"black"}
                                    // disabled={isEditMode && (mainApiData?.status === "Pending Approval") ? true : false}
                                    type={"text"}
                                    maxLength={50}
                                    disabled={true}
                                    inputValue={vendorDetails.basicDetails.merchantId}
                                    placeHolder={"Enter Merchant Id"}
                                    inputTitle={"Merchant Id"}
                                    getUser={(value: any) => {


                                    }}
                                />
                            </div>
                            {/* <div style={{ marginRight: "45px", marginTop: "40px" }} >
                                <RadioOrCheckbox
                                    disabled={true}
                                    value={"Send request to vendor"}
                                    type={"checkbox"}
                                    name={"Send request to vendor"}
                                    checkedValue={vendorDetails.basicDetails.sendVendorIsChecked}
                                    getVal={(value) => {
                                        if (vendorDetails.basicDetails.sendVendor === value) {
                                            setVendorDetails({
                                                ...vendorDetails,
                                                basicDetails: {
                                                    ...vendorDetails.basicDetails,
                                                    sendVendorIsChecked: 'No',
                                                },
                                            })

                                        } else {
                                            setVendorDetails({
                                                ...vendorDetails,
                                                basicDetails: {
                                                    ...vendorDetails.basicDetails,
                                                    sendVendorIsChecked: value,
                                                },
                                            })
                                        }
                                        
                                    }}
                                    />


                            </div> */}
                                    {/*  sendToVendor() */ }
                        </div>
                        {
                            isEditMode && (mainApiData?.status === "Approved" || mainApiData?.status === "Declined") &&
                            <>
                                <div style={isEditMode ?
                                    { pointerEvents: "none", opacity: 1, width: "95%" } : {}}>
                                    <div className='bank-approval'>
                                        <div className='approval-title'>
                                            {mainApiData?.status === "Declined" ? (
                                                <p>Your request to add a vendor master record has been declined.</p>
                                            ) : (<p>Your request to add a vendor master record has been approved.</p>)}
                                        </div>
                                        <div className='approval-radio-input'>
                                            <div className='approval-radio-btn'
                                                style={{ pointerEvents: "none", opacity: 0.5 }}
                                            >
                                                <RadioOrCheckbox
                                                    value={"Approved"}
                                                    name={"ActiveVendor"}
                                                    type={"radio"}
                                                    disabled={true}

                                                    margin={"0px 30px 0px 0px"}
                                                    checkedValue={mainApiData?.status}
                                                    getVal={(value) => {
                                                        setIsFormDirty(true)

                                                        setMainApiData({
                                                            ...mainApiData,
                                                            status: value,
                                                            declineRemarks: ""
                                                        });

                                                        dispatch(resetFormModified(true));
                                                    }}
                                                />
                                                <div>
                                                    <RadioOrCheckbox
                                                        value={"Declined"}
                                                        type={"radio"}
                                                        disabled={true}

                                                        checkedValue={mainApiData?.status}
                                                        getVal={(value) => {
                                                            setIsFormDirty(true)

                                                            setMainApiData({
                                                                ...mainApiData,
                                                                status: value,

                                                            });

                                                            dispatch(resetFormModified(true));
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='decline-reason '>
                                        <div className='reason-area'
                                            style={{ pointerEvents: "none", opacity: 0.5 }}
                                        >
                                            <InputComponent
                                                height={"60px"}
                                                width={"90%"}
                                                margin={"10px 60px 30px 0px"}
                                                padding={"0px 0px 0px 10px"}
                                                border={basicDetailsErrors.declineRemarks ? "1px solid red" : "1px solid #A9C3DC"}
                                                borderRadius={"0px"}
                                                backgroundColor={"white"}
                                                color={"black"}
                                                maxLength={250}
                                                type={"text"}
                                                readOnly
                                                disabled={mainApiData?.status === "Declined" ? false : true}
                                                inputTitle={"Reason for Declining Request"}
                                                required={true}
                                                placeHolder={"Reason"}
                                                inputValue={mainApiData?.declineRemarks}
                                                getUser={(value: any) => {
                                                    setIsFormDirty(true)
                                                    setMainApiData((prevState: any) => ({
                                                        ...prevState,
                                                        declineRemarks: value,
                                                    }));
                                                    dispatch(resetFormModified(true));
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                    <div>
                        <div className='footer-company'>
                            <ButtonComponent
                                title={"Cancel"}
                                height={"50px"}
                                width={"150px"}
                                margin={"0px 8px"}
                                backgroundColor={"#888888"}
                                color={"white"}
                                className={"button-component-hover cancel"}
                                handleClick={isFormModified ? () => { setShowChangesModal(true) } : returnGird}
                            />


                            {/* <ButtonComponent
                                title={isEditMode ? "Update" : 'Save'}
                                height={"50px"}
                                width={"150px"}
                                disabled={false}
                                backgroundColor={"#0055D4"}
                                color={"white"}
                                margin={"0px 8px"}
                                className={"button-component-hover common-btn"}
                                handleClick={() => {

                                }}
                            /> */}
                            <ButtonComponent
                                title={"Next"}
                                height={"50px"}
                                width={"150px"}
                                disabled={false}
                                backgroundColor={"#0055D4"}
                                color={"white"}
                                margin={"0px 8px"}
                                className={
                                    // (vendorDetails?.basicDetails?.emailId == "" || vendorDetails?.basicDetails?.contactNumber == "") ?
                                    // "button-component-hover disabled" :
                                    "button-component-hover common-btn"}
                                handleClick={() => {
                                    setShowPage('VendorOverview')
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div >
            {
                showAlertBox &&
                <div className='alert-warp'>
                    <AlertBox type={showType} message={showMessage} />
                </div>

            }
            {
                showChangesModal &&
                <Changesmodal
                    closeModal={() => setShowChangesModal(false)}
                    handleClose={() => {
                        setShowChangesModal(false);
                    }}
                    leavePage={() => returnGird()} />
            }
            {addVendorType &&
                <AddVendorType
                    vendorTypeData={
                        mainApiData
                    }

                    // setTransactionMappingData={setTransactionMappingData}getDepartment
                    clearValue={() => {
                        setAddVendorType(false)
                    }}
                    getVendorType={() => {
                        // fetchData(`/state/roc-cities/${vendorBankApprovalData.state}`, setCityValues, 'RocCity', "rocCityId", "name");
                        fetchData(`companyadmin/vendor/vendor-types`, setVendorTypeData, "vendorTypes");
                    }}
                    setVendorTypeValues={setVendorTypeData}
                    vendorTypeValues={vendorTypeData}
                    setVendorTypeData={setMainApiData}

                />
            }
        </>
    )
})

export default BasicDetails;
