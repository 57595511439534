import { Component } from 'react'
import Form from '../../components/product-admin/organisation/form'
import Grid from '../../components/product-admin/organisation/grid'

interface OrganisastionValues {
  organisationFun: string;
  editData: any;
  isEditMode: boolean;
  userIsActive: boolean;
  currentEdit: string;
}

class Organisation extends Component<{}, OrganisastionValues>{
  constructor(props: OrganisastionValues) {
    super(props);
    this.state = {
      organisationFun: "grid",
      editData: {},
      isEditMode: false,
      userIsActive: false,
      currentEdit: "Organisation Details",
    };
  }

  render() {
    return (
      <div>
        {
          this.state.organisationFun === "addForm" &&
          <Form
            editData={this.state.editData}
            isEditMode={this.state.isEditMode}
            userIsActive={this.state.userIsActive}
            currentEdit={this.state.currentEdit}
            organisationFun={(Val) => {
              this.setState({ organisationFun: Val })
            }}
            getEditMode={(value) => {
              this.setState({ isEditMode: value })
            }}
          />
        }
        {
          this.state.organisationFun === "grid" &&
          <Grid
            getEditMode={(val) =>
              this.setState({
                isEditMode: val
              })
            }
            getEditData={(name: string, data: any,) => {
              if (name === "addForm") {
                this.setState({
                  organisationFun: name,
                  editData: { ...data },
                  isEditMode: true,
                  userIsActive: true
                })
              } else {
                this.setState({
                  organisationFun: name,
                  isEditMode: true,
                })
              }
            }}
          />
        }
      </div>
    )
  }
}

export default Organisation;