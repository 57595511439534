
import { useEffect, useState, useRef } from 'react';
//Components
import MainPageCard from '../../../../components/common-component/cards/main-page-card';
import NavigateHeader from '../../../../components/common-component/navigate-header';
import IconButton from '../../../../components/common-component/form-elements/icon-button';
import DeleteModal from '../../../common-component/modals/delete-modal';
import GridFilter from '../../../common-component/grids-and-tables/grid-filter';
import AlertBox from '../../../common-component/alert-box';
import AgGrid, { AgGridRef } from '../../../../components/common-component/grids-and-tables/ag-grid';
// import { organisationColumnData, rowData2 } from '../../../config/product-admin/organisation/organisation-aggrid-column-config';
//API
import useAxiosPrivate from "../../../../services/hooks/useaxios-private";
import { v4 as uuidv4 } from 'uuid';
// import { rowData } from '../../../config/product-admin/organisation/organisation-aggrid-column-config';
//Style
import "../../../../styles/product-admin/organisation/grid.scss"
import { Filter } from '../../../../helpers/filtterFunction';

interface IFilterValue {
    [key: string]: {
        columnName: string;
        filterType: string;
        filterWord: string;
    };
}

interface Props {
    getEditData?: ((name: string, data: any) => void) | undefined;
    getDelete?: () => void;
    getEditMode: (value: boolean) => void;
}

function Grid({ getEditData, getDelete, getEditMode }: Props) {
    const [showType, setShowType] = useState("warning") // error message showType
    const [showMessage, setShowMessage] = useState<string>("") // error message showMessage
    const [showAlertBox, setShowAlertBox] = useState(false) // error message ShowAlertBox
    const [rowData, setRowData] = useState<any>([]);
    const [orginalRowData, setOrginalRowData] = useState<any>([]);
    const [filter, setFilter] = useState(false);
    const [curruntData, setCurruntData] = useState<any>([]);
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
    const [filterRestore, setFilterRestore] = useState<IFilterValue>({})
    const [filterMethod, setFilterMethod] = useState("")
    const gridOrgDownloadRef = useRef<AgGridRef>(null);
    const axiosPrivate = useAxiosPrivate();

    useEffect(() => {
        fetchData()
    }, []);
    const organisationColumnData = [
        {
            field: 'selection', headerName: '', suppressCsvExport: true, minWidth: 60, maxWidth: 60, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, headerCheckboxSelection: true, checkboxSelection: true, showDisabledCheckboxes: true, suppressRowTransform: true, cellStyle: { textAlign: 'center' },
        },
        {
            field: 'organisationName', headerName: 'Organisation Name', minWidth: 200, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text",
        },
        {
            field: 'organisationCategory', headerName: 'Organisation Category', minWidth: 200, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text",
        },
        {
            field: 'internalApprovalStatus', headerName: 'Internal Approval Status', minWidth: 200, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text",cellRenderer: (e: any) => {
                // Custom rendering logic
                let color = '';
                switch (e.data.internalApprovalStatus) {
                    case 'NYS':
                        color = '#0055D4'
                        break;
                    case 'Declined':
                        color = 'red'
                        break;
                    case 'Approved':
                        color = 'green'
                        break;
                    case 'Pending Approval':
                        color = '#D2A100'
                        break;
                    default:
                        break;
                }
                return (
                    <div style={{ color: color }}>
                        {e?.data?.internalApprovalStatus}
                    </div>
                );
            }
        },
        {
            field: 'isActive', headerName: 'Is Active', minWidth: 200, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text",
        },
    ]
    // clearAleart
    const clearAleart = () => {
        const timer = setTimeout(() => {
            setShowAlertBox(false);
            setShowMessage("");
            clearTimeout(timer);
        }, 5000);
    }

    const fetchData = async () => {

        try {
            const response = await axiosPrivate.get('/organisation');
            setRowData(response.data.organizations);
            setOrginalRowData(response.data.organizations)
           
        } catch (error) {
        }
    };

    const getCurruntData = (val: any) => {
        setCurruntData(val);
    }

    function getUserData(id: string) {
        axiosPrivate.get(`/organisation/${id}`)
            .then(response => {
                if (getEditData) {
                    getEditData("addForm", response.data.organization)
                }
            })
            .catch(error => {
            });
    }

    // Function for Filter values
    const handleFilter = (value: any, filterType: string) => {
        Filter(value, filterType, orginalRowData, setRowData)
    }

    // grid delete
    getDelete = async () => {
        if (curruntData.length === 1) {
            axiosPrivate.delete(`/organisation/delete/${curruntData[0].organisationId}`)
                .then(response => {
                    if (response.status === 200) {
                        setShowAlertBox(true)
                        setShowType("success")
                        setShowMessage(response.data.message)
                        clearAleart()
                        getEditMode(false)
                        setShowDeleteModal(false)
                        fetchData()
                        setCurruntData([]);
                    }
                })
                .catch(error => {
                    setShowAlertBox(true);
                    setShowType("danger")
                    setShowMessage(error.response.data.error)
                    setShowDeleteModal(false)
                    clearAleart()
                    // setCurruntData([]);
                });
        } else {
            let idList: any = [];
            curruntData.map((e: any, i: number) => {
                idList.push({
                    id: e.organisationId
                })
            })
            try {
                const res = await axiosPrivate.delete("/organisation/multiple/delete", {
                    data: {
                        data: idList
                    }
                })
                if (res.status === 200) {
                    fetchData()
                    setShowAlertBox(true)
                    setShowType("success")
                    setShowMessage(res.data.message)
                    clearAleart()
                    setCurruntData([])
                    getEditMode(false)
                    setShowDeleteModal(false)
                }
            } catch (error: any) {
                fetchData()
                setShowAlertBox(true)
                setShowType("danger")
                setShowMessage(error.response.data.error)
                clearAleart()
                setShowDeleteModal(false)
                setCurruntData([])
            }
        }
    };

    const handleDownloadExcel = () => {
        gridOrgDownloadRef.current!.onBtExport();
    }

    return (
        <>
            <MainPageCard>
                <div className='header-main'>
                    <div className='navigater'>
                        <NavigateHeader
                            style={{ cursor: "default" }}
                            firstValue={"Approval"}
                            secondValue={"Organisation"}
                        />
                    </div>
                    <div className='icon-buttons'>
                        <IconButton
                            iconName={"Filter"}
                            height={"40px"}
                            width={"40px"}
                            fontSize={"30px"}
                            margin={"0px 8px"}
                            color={"white"}
                            backgroundColor={"#0055D4"}
                            cursor={"pointer"}
                            hover={true}
                            handleClick={(event: React.MouseEvent) => {
                                event?.stopPropagation()
                                setFilter(!filter);
                            }}
                        />
                        <IconButton
                            iconName={"Delete"}
                            height={"40px"}
                            width={"40px"}
                            fontSize={"25px"}
                            margin={"0px 8px"}
                            color={"white"}
                            backgroundColor={"#D60000"}
                            hover={false}
                            opacity={ "0.5"}
                            cursor={ "default"}
                            handleClick={() => {
                                // if (curruntData.length > 0) {
                                //     setShowDeleteModal(!showDeleteModal)
                                // }
                            }}

                        />
                        <IconButton
                            iconName={"Add"}
                            height={"40px"}
                            width={"75px"}
                            fontSize={"25px"}
                            // disabled={true}
                            margin={"0px 8px"}
                            color={"white"}
                            opacity={'0.5'}
                            backgroundColor={"#0055D4"}
                            cursor={"default"}
                            hover={false}
                            disabled={true}
                            handleClick={() => {
                                // getEditData && getEditData("addForm", "")
                                // getEditMode(false)
                            }}
                        />
                        <IconButton
                            iconName={"Upload"}
                            height={"40px"}
                            width={"40px"}
                            fontSize={"25px"}
                            margin={"0px 0px 0px 8px"}
                            color={"white"}
                            backgroundColor={"#0055D4"}
                            hover={false}
                            opacity={"0.5"}
                            cursor={"not-allowed"}
                            handleClick={() => {
                                // setuploadedDataGridShow(true);
                            }}
                        />
                        <IconButton
                            iconName={"Download"}
                            height={"40px"}
                            width={"40px"}
                            fontSize={"25px"}
                            margin={"0px 8px"}
                            color={"white"}
                            cursor={"pointer"}
                            backgroundColor={"#0055D4"}
                            hover={true}
                            handleClick={() => { handleDownloadExcel() }}
                        />
                    </div>
                </div>
                <div>
                    <AgGrid
                        ref={gridOrgDownloadRef}
                        fileName={"OrganisationData"}
                        tabelRowData={rowData}
                        tableColumnData={organisationColumnData}
                        getCurruntData={getCurruntData}
                        doubleClickUpdate={() => { getEditData && getEditData("addForm", "") }}
                        getEdit={(data) => getUserData(data.organisationId)}
                    />
                </div>
                {
                    filter &&
                    <div className='filter' key={uuidv4()}>
                        <GridFilter
                            filterRestore={filterRestore}
                            filterMethod={filterMethod}
                            tabelRowData={rowData}
                            data={organisationColumnData.slice(1)}
                            handleClick={(Value: any, filterType: string) => {
                                if (filterType !== "") {
                                    setFilterRestore(Value)
                                    setFilter(!filter)
                                    setFilterMethod(filterType)
                                    handleFilter(Value, filterType)
                                    setCurruntData([]);
                                } else {
                                    setFilterMethod("")
                                    setFilterRestore({})
                                    setFilter(!filter)
                                    setRowData(orginalRowData)
                                    setCurruntData([]);
                                }
                            }}
                        />
                    </div>
                }
                {
                    showAlertBox &&
                    <div className='alert-warp'>
                        <AlertBox type={showType} message={showMessage} />
                    </div>
                }
            </MainPageCard>

            {
                showDeleteModal &&
                <DeleteModal
                    modelType={"grid-delete"}
                    getDelete={getDelete}
                    clearValue={(value) => {
                        setShowDeleteModal(value)
                    }}
                />
            }
        </>
    )
}

export default Grid;

