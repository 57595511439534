import React from 'react'
import { ReactComponent as Max } from '../../../assets/icons/max.svg'
import { ReactComponent as Filter } from '../../../assets/icons/filter.svg'
import { ReactComponent as Grid } from '../../../assets/icons/grid.svg'
import { ReactComponent as Download } from '../../../assets/icons/download.svg'
import { ReactComponent as Groupby } from '../../../assets/icons/groupby.svg'
import { ReactComponent as Minimise } from '../../../assets/icons/minimise.svg'
import { ReactComponent as SaveFilter } from '../../../assets/icons/save-filter.svg'
import { ReactComponent as GraphIcon } from '../../../assets/icons/graph-icon.svg'
import FilterToolTip from '../../dashboard/filter-tooltip'
import SideFilterTooltip from '../../dashboard/side-filter-tooltip'
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap.css';
import "../../../styles/dashboard/card.scss";

interface type {
  children: React.JSX.Element;
  width: string;
  height: string;
  currentChart?: any;
  groupByOptions?: any;
  setStoreGroupByOption?: any;
  storeGroupByOption?: any;
  openGroupBy?: any;
  openSideFilter?: boolean;
  isOpenEmpty?: boolean;
  setOpenRightSideFilter?: any;
  storeFilterOptions?: any;
  isOpenGrid?: any;
  chartHeading?: string;
  setOpenGroupBy?: () => void;
  setOpenSideFilter?: () => void;
  setOpenOneDashboard?: (value: string) => void;
  setIsOpenGrid?: () => void;
  setIsSaveFilterModal?: () => void;
  setIsDownloadModal?: () => void;
  setIsOpenEmpty?: () => void;
  handleDownloadExcel?: () => void;
}

function CardTwoChart({
  children,
  width,
  height,
  groupByOptions,
  setStoreGroupByOption,
  storeGroupByOption,
  setOpenGroupBy,
  openGroupBy,
  openSideFilter,
  setOpenSideFilter,
  setOpenRightSideFilter,
  setOpenOneDashboard,
  storeFilterOptions,
  setIsOpenGrid,
  setIsSaveFilterModal,
  setIsDownloadModal,
  currentChart,
  isOpenEmpty,
  setIsOpenEmpty,
  isOpenGrid,
  handleDownloadExcel,
  chartHeading
}: type) {

  return (
    <div
      data-testid="card-component"
      className='chart-card-component'
      style={{ width: width, height: height }}
    >
      <div className='expand-icon-main'>
        <span
          className='expand-icon-span'
          onClick={() => {
            setIsOpenEmpty && setIsOpenEmpty()
            setOpenOneDashboard && setOpenOneDashboard(currentChart)
          }}
        >
          {
            isOpenEmpty ? <Max className='expand-icon' /> : <Minimise className='expand-icon' />
          }
        </span>
      </div>
      <div className={'center-main'}>
        <div className='left-text'>
          {/* <p>{"Physical Card"}</p> */}
        </div>
        <div className='children-main'>
          {
            // isOpenGrid &&
            <p className='chart-heading'>{chartHeading}</p>
          }
          {children}
        </div>
        <div className='icon-list-main'>
          <ul className='icon-list'>
            <Tooltip
              placement="left"
              overlay={"Group by"}
            >
              <li
                className='list'
                style={{ opacity: "0.5", cursor: "default" }}
                onClick={() => { }}
              >
                <Groupby data-testid="download-icon" className='icon' />
              </li>
            </Tooltip>
            <Tooltip
              placement="left"
              overlay={"Filter"}
            >
              <li
                className='list'
                style={{ opacity: "0.5", cursor: "default" }}
                onClick={() => { }}
              >
                <Filter data-testid="filter-icon" className='icon' />
              </li>
            </Tooltip>
            <Tooltip
              placement="left"
              overlay={"Save Filter"}
            >
              <li
                className='list'
                style={{ opacity: "0.5", cursor: "default" }}
                onClick={() => { }}
              >
                <SaveFilter data-testid="filter-icon" className='icon' />
              </li>
            </Tooltip>
            <Tooltip
              placement="left"
              overlay={!isOpenGrid ? "Grid View" : "Chart View"}
            >
              <li
                className='list'
                onClick={() => { setIsOpenGrid && setIsOpenGrid() }}
              >
                {
                  isOpenGrid ?
                    <GraphIcon data-testid="grid-icon-1" className='icon' />
                    :
                    <Grid data-testid="grid-icon-1" className='icon' />
                }
              </li>
            </Tooltip>
            <Tooltip
              placement="left"
              overlay={"Download"}
            >
              <li
                className='list-no-border'
                style={{ opacity: isOpenGrid ? "1" : "0.5", cursor: isOpenGrid ? "pointer" : "default" }}
                onClick={() => {
                  // (isOpenGrid && setIsDownloadModal) && setIsDownloadModal()
                  (isOpenGrid && handleDownloadExcel) && handleDownloadExcel()
                }}
              >
                <Download data-testid="grid-icon-2" className='icon' />
              </li>
            </Tooltip>
          </ul>
          {
            openGroupBy &&
            <FilterToolTip
              groupByOptions={groupByOptions}
              storeGroupByOption={storeGroupByOption}
              setStoreGroupByOption={setStoreGroupByOption}
            />
          }
          {
            openSideFilter &&
            <SideFilterTooltip
              setOpenRightSideFilter={setOpenRightSideFilter}
              storeFilterOptions={storeFilterOptions}
            />
          }
        </div>
      </div>
    </div>
  )
}

export default CardTwoChart;