import CustomEllipsisRenderer from "../../product-admin/users/users-aggrid-column-config";

const AmountRenderer = ({ value }: any) => {
    const onlyNumber = value.toString().replace(/[^0-9]/g, "");
    const parsedValue = parseInt(onlyNumber);
    const formattedValue = isNaN(parsedValue) ? "" : parsedValue.toLocaleString('en-IN');
    return <span>{formattedValue}</span>;
};


// CustomEllipsisRenderer

export const cardColumnData = [
    {
        field: 'selection', headerName: '', minWidth: 60, maxWidth: 60, resizable: false, suppressMovable: true, filter: "agSetColumnFilter", suppressSizeToFit: false, headerCheckboxSelection: true, checkboxSelection: true, showDisabledCheckboxes: true, suppressRowTransform: true, cellStyle: { textAlign: 'center' }
    },
    {
        field: 'nameOnCard', headerName: 'Name on Card', minWidth: 200, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "number", cellRendererFramework: CustomEllipsisRenderer,
    },
    {
        field: 'cardNumber', headerName: 'Card Number', minWidth: 200, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "number"
    },
    {
        field: 'cardCategory', headerName: 'Card Category', minWidth: 200, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text"
    },
    {
        field: 'issuerName', headerName: 'Issuer', minWidth: 200, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text"
    },
    {
        field: 'validFrom', headerName: 'Valid From', minWidth: 200, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text"
    },
    // {
    //     field: 'address', headerName: 'Address', minWidth: 200, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype : "text"
    // },
    {
        field: 'validThru', headerName: 'Valid Thru', minWidth: 200, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text"
    },
    {
        field: 'status', headerName: 'Status', minWidth: 200, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellClassRules: {
            'success-cell': (params: any) => params.value === 'Approved',
            'registered-cell': (params: any) => params.value === 'Pending Approval',
            'denied-cell': (params: any) => params.value === 'Declined',
        },

    },
    {
        field: 'creditPeriod', headerName: 'Credit Period', minWidth: 200, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text"
    },
    {
        field: 'billingCycle', headerName: 'Billing Cycle', minWidth: 200, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text"
    },
    {
        field: 'cardMaxLimit', headerName: 'Limit', minWidth: 200, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: AmountRenderer
    },
    {
        field: 'isActive', headerName: 'Is Active', minWidth: 200, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text"
    },
    // {
    //     field: 'remarks', headerName: 'Remarks', minWidth: 200, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype : "text"
    // },
    {
        field: 'approvedBy', headerName: 'Approved / Declined by', minWidth: 200, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text"
    },
    // {
    //     field: 'remarks', headerName: 'Remarks', minWidth: 200, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype : "text"
    // },
    // {
    //     field: 'approvedBy', headerName: 'Approved by', minWidth: 200, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype : "text"
    // },


]


export const rowDatathis = [
    {
        nameOnCard: "Godrej",
        cardNumber: "2458 2145 2245 4571",
        cardCategory: "Corporate Cards",
        issuer: "ICICI",
        validFrom: "01/23",
        validThru: "05/28",
        creditPeriod: "52 Days",
        billingCycle: "20",
        limit: "20,0000",
        isActive: "Yes",
        status: "Approved",
        approvedBy: "Nagarajan",


    },
    {
        nameOnCard: "Godrej",
        cardNumber: "0000 0000...",
        cardCategory: "Corporate Cards",
        issuer: "ICICI",
        validFrom: "01/23",
        validThru: "05/28",
        creditPeriod: "52 Days",
        billingCycle: "20",
        limit: "20,0000",
        isActive: "Yes",
        status: "Approved",
        approvedBy: "Nagarajan",
    },
    {
        nameOnCard: "Godrej",
        cardNumber: "0000 0000...",
        cardCategory: "Corporate Cards",
        issuer: "ICICI",
        validFrom: "01/23",
        validThru: "05/28",
        creditPeriod: "52 Days",
        billingCycle: "20",
        limit: "20,0000",
        isActive: "Yes",
        status: "Approved",
        approvedBy: "Nagarajan",
    },
    {
        nameOnCard: "Godrej",
        cardNumber: "0000 0000...",
        cardCategory: "Corporate Cards",
        issuer: "ICICI",
        validFrom: "01/23",
        validThru: "05/28",
        creditPeriod: "52 Days",
        billingCycle: "20",
        limit: "20,0000",
        isActive: "Yes",
        status: "Approved",
        approvedBy: "Nagarajan",
    },

]