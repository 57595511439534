import React, { useEffect, useState } from 'react'
import AgGrid from '../../common-component/grids-and-tables/ag-grid';
import NavigateHeader from '../../common-component/navigate-header';
import '../../../styles/gst/gst-choose-card.scss'
import ButtonComponent from '../../common-component/form-elements/button-component';
import DropdownComponent from '../../common-component/form-elements/dropdown-component';
import { v4 as uuidv4 } from 'uuid'
import { axiosPrivate } from '../../../middleware/axois-api';
import { cardDetails } from '../../payable/vendor/vendor-form';
import CustomEllipsisRenderer from '../../../config/product-admin/users/users-aggrid-column-config';
import { DateConverterAgGrid } from "../../../helpers/capitalizetext";
import cellEyeIcon from "../../../components/common-component/grids-and-tables/cell-eye-icon";
import GstViewChallanModal from '../../common-component/modals/gst-view-challan-modal';
import GstGridChooseCard from '../../../config/gst/gst-grid-choose-card';
import CardPanel from '../../common-component/cards/card-panel';
import dummyCard from '../../../assets/images/dummy-card.png';
import InvoiceDeclinemodal from '../../common-component/modals/invoice-decline-modal';
import GstApprovalMakePayment from '../../common-component/modals/gst-approval-make-payment';
import Loading from '../../common-component/modals/loading-screen';
import AlertBox from '../../common-component/alert-box';

interface Props {
    gstView: (val: any) => void;
    transaction: any;
    makePaymentDatas: any;
}
interface GstDataType {
    GSTIN: string;
    CPIN: string;
    Total: string;
    CreatedDate: string;
    ExpiryDate: string;
    ChallanStatus: string;
}
function ChooseCard({ gstView, transaction, makePaymentDatas }: Props) {
    const [totalAmountData, setTotalAmountData] = useState('');
    const [cards, setCards] = useState<cardDetails[]>([]); //store company cards
    const [selectedGridChallan, setSelectedGridChallan] = useState<any>([]);
    const [disableMakePayment, setDisableMakePayment] = useState<any>('card');

    const [loadingScreen, setLoadingScreen] = useState(false);
    const [showType, setShowType] = useState("warning") // error message showType
    const [showMessage, setShowMessage] = useState<string>("") // error message showMessage
    const [showAlertBox, setShowAlertBox] = useState(false) // error message ShowAlertBox

    const [gstEyeShowModal, setGstEyeShowModal] = useState<boolean>(false);
    const [declinePopUp, setDeclinePopUp] = useState(false);
    const [makePaymentModel, setMakePaymentModel] = useState(false);
    const [declineRemarks, setDeclineRemarks] = useState<string>("");
    const [selectedPaymentMethod, SetSelectedPaymentMethod] = useState<any>({
        PaymentMethod: "Corporate Card",
        virtualcardId: "",
        cardId: "",
    });

    const PaymentMethodDD = [{ label: "Corporate Card", value: "Corporate Card", id: "1" }, { label: "Net Banking", value: "Net Banking", id: "2" }, { label: "Virtual Card", value: "Virtual Card", id: "3" },]
    const calculateTotal = () => {
        let sum = 0;
        selectedGridChallan.forEach((item: any) => {
            const total = parseFloat(item?.Total.replace(/,/g, ''));
            sum += total;
        });
        return sum.toFixed(2); // Round to two decimal places if needed
    };


    const formatAmountWithCommas = (amount: any) => {
        return parseFloat(amount).toLocaleString('en-US', {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
    };

    useEffect(() => {
        const total = calculateTotal();
        const formattedTotal = formatAmountWithCommas(total);
        setTotalAmountData(formattedTotal)
    }, [selectedGridChallan]);

    useEffect(() => {
        fetchCard()
        setSelectedGridChallan(transaction)
    }, []);

    const fetchCard = async () => {
        setLoadingScreen(true)
        try {
            const response = await axiosPrivate.get(`/vendor-payable/card-details/get`);
            setCards(response.data.decryptCardDetails)
            setLoadingScreen(false);
        } catch (error) {
            setLoadingScreen(false);;
        }
    };
    const clearAlert = () => {
        const timer = setTimeout(() => {
            gstView("grid")
            setLoadingScreen(false)
            clearTimeout(timer);
        }, 2000);
    }
    const GroupInvoiceDecline = async (remarks: string) => {
        const payableId = selectedGridChallan.map((val: any, index: number) => (val?.payableGstDetailsId))
        setLoadingScreen(true);
        try {
            const res = await axiosPrivate.post(`/gst-payable/declined-challans`,
                {
                    declinedChallanIds: payableId,
                    remarks: remarks,
                },
            );
            if (res.status === 200) {
                setDeclineRemarks('')
                setShowAlertBox(true);
                setShowType("success");
                setShowMessage('Your action has been successfully recorded.');
                clearAlert()
            }
        }
        catch (error: any) {
            // setLoadingScreen(false)
            setShowAlertBox(true)
            setShowType("warning")
            setShowMessage(error.response.data.message)
            clearAlert()
        }
    };
    async function payment() {
        setLoadingScreen(true)
        const payableId = selectedGridChallan.map((val: any, index: number) => (val?.payableGstDetailsId))
        try {
            const res = await axiosPrivate.post("/gst-payable/make-payment-challans", {
                makePaymentChallanIds: payableId,
            }).then((res) => {

                if (res.status === 200) {
                    setLoadingScreen(false)
                    setMakePaymentModel(true)
                }
            });
        } catch (error: any) {
            setLoadingScreen(false)
            // setapprovalModal(false)
            // setShowMessage(error?.response?.data?.error);
        }
    }

    function cancel() {
        gstView("grid")
    }
    const handleSelectedCard = (card: any, index: number) => {
        SetSelectedPaymentMethod((selectedPaymentMethod: any) => ({
            ...selectedPaymentMethod,
            cardId: card.cardId,
        }));
        setDisableMakePayment(index)

    }
    function Decline() {
        setDeclinePopUp(true);
    }
    return (
        <div className='company-form-gst'>
            <div className='navigation-header' style={{ height: "5vh", }}>
                <div className='left-header'>
                    <NavigateHeader firstValue={"Payable"}
                        style={{ cursor: "pointer" }}
                        secondValue={"GST"}
                        navigatePage={() => gstView("grid")}
                    />
                </div>
            </div>
            <div className='gst-card-chooser-area'>
                <div>
                    <div className={"select-payment-method"}>
                        <div style={{
                            display: "flex", justifyContent: "center", position: "relative", zIndex: 3
                        }}>
                            <div className={false ? 'setError' : 'dropdown-vendor-pay-plus'}
                                style={false ? { pointerEvents: "none", opacity: "0.5", cursor: "auto" } : {}}>
                                <DropdownComponent key={uuidv4()}
                                    options={PaymentMethodDD}
                                    title={"Select Payment Method"}
                                    isDisabled={true}
                                    required={true}
                                    width={'350px'}
                                    getData={(value: any) => {
                                        SetSelectedPaymentMethod((selectedPaymentMethod: any) => ({
                                            ...selectedPaymentMethod,
                                            PaymentMethod: value.value,
                                            cardId: "",
                                        }));
                                        //   dispatch(resetFormModified(true));
                                    }}
                                    defaultValue={[{ label: selectedPaymentMethod.PaymentMethod === "" ? "Select" : selectedPaymentMethod.PaymentMethod, value: selectedPaymentMethod.PaymentMethod }]}
                                />
                            </div>
                        </div>
                        {
                            selectedPaymentMethod.PaymentMethod === "Corporate Card" &&
                            <>
                                <div>
                                    <p className={"select-card"}>{"Select Card"}</p>
                                </div>
                                <div className={"Card-main-wrapper"} style={{ marginBottom: "0px" }}>
                                    <div className={"Card-sub-wrapper"}>
                                        {
                                            cards.length === 0 ?
                                                <div className='select-payment-dummy-card-container'>
                                                    <img src={dummyCard} className='select-payment-dummy-card-image' />
                                                    <div className='select-payment-dummy-card-content'>{"You have no cards added"}</div>
                                                </div>
                                                : cards.map((card: any, index: number) => (
                                                    <div onClick={() => handleSelectedCard(card, index)}
                                                        className={selectedPaymentMethod.cardId === card.cardId ? "activeCardId" : "inActiveCard"}
                                                    >
                                                        <CardPanel
                                                            key={index} // Make sure to provide a unique key for each element in the array
                                                            bankName={card.bankName}
                                                            percentage={card.percentage}
                                                            limit={card.limit}
                                                            insideCardBalance={card.insideCardBalance}
                                                            lastBilledDue={card.lastBilledDue}
                                                            cardNumber={card.cardNumber}
                                                            cardNetwork={card.cardNetwork}
                                                            dueIn={card.dueIn}
                                                            billingCycle={card.billingCycle}
                                                            processing={card.processing}
                                                            outsideCardBalance={card.outsideCardBalance}
                                                            backgroundColor={card.backgroundColor}
                                                        />
                                                    </div>
                                                ))}
                                    </div>
                                </div>
                            </>
                        }
                        {
                            selectedPaymentMethod.PaymentMethod === "Net Banking" &&
                            <>
                                <div style={{ height: "460px" }}>
                                    <div style={{
                                        display: "flex", justifyContent: "center", marginTop: "70px", position: "relative"
                                    }}>
                                        <div className={false ? 'setError' : 'dropdown-vendor-pay-plus'}
                                            style={false ? { pointerEvents: "none", opacity: "0.5", cursor: "auto" } : {}}>
                                            <DropdownComponent key={uuidv4()}
                                                options={PaymentMethodDD}
                                                title={"Select Payment Method"}
                                                // isDisabled={true}
                                                required={true}
                                                width={'350px'}
                                                getData={(value: any) => {
                                                    SetSelectedPaymentMethod((selectedPaymentMethod: any) => ({
                                                        ...selectedPaymentMethod,
                                                        PaymentMethod: value.value,
                                                        cardId: "",
                                                    }));
                                                    // dispatch(resetFormModified(true));
                                                }}
                                                defaultValue={[{ label: selectedPaymentMethod.PaymentMethod === "" ? "Select" : selectedPaymentMethod.PaymentMethod, value: selectedPaymentMethod.PaymentMethod }]}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                    </div >
                </div>
                <div>
                    <div className='gst-sync-main'>
                        <div className="right-details" >
                            <div className='align-amount-center'>
                                <p className='details-number'><b>{selectedGridChallan ? selectedGridChallan.length : 0}</b></p>
                                <p>
                                    Total Challans
                                </p>
                            </div>
                            <div className='align-amount-center'>
                                <p className='details-number'><b>₹{totalAmountData}</b></p>
                                <p>
                                    Total Amount
                                </p>
                            </div>
                            <div className='align-amount-center'>
                                <p className='details-number'><b>₹{totalAmountData}</b></p>
                                <p>
                                    Revised Amount
                                </p>
                            </div>
                        </div>
                        <div style={{ paddingRight: "15px" }}>
                            <ButtonComponent
                                title={"Sync Now"}
                                height={"50px"}
                                width={"150px"}
                                margin={"0px 8px"}
                                backgroundColor={"#0055D4"}
                                color={"white"}
                                className={"button-component-hover common-btn"}
                                handleClick={() => { }}
                            />
                        </div>
                    </div>
                </div>
                <div>
                    <div className='gst-grid-card'>
                        <GstGridChooseCard
                            makePaymentDatas={makePaymentDatas}
                            selectedGridChallan={selectedGridChallan}
                            selectedGridChallanFun={(val: any) => { setSelectedGridChallan(val) }}
                            gstEyeShowModal={gstEyeShowModal}
                            setGstEyeShowModal={setGstEyeShowModal}
                        />
                    </div>
                </div>
            </div>
            <div className='footer-gst'>
                <ButtonComponent
                    title={"Previous"}
                    height={"50px"}
                    width={"150px"}
                    margin={"0px 8px"}
                    backgroundColor={"#888888"}
                    color={"white"}
                    className={"button-component-hover cancel"}
                    handleClick={cancel}
                />
                <ButtonComponent
                    title={"Decline"}
                    height={"50px"}
                    width={"150px"}
                    backgroundColor={"#0055D4"}
                    color={"white"}
                    margin={"0px 8px"}
                    className={"button-component-hover common-btn"}
                    handleClick={Decline}
                />
                <div style={disableMakePayment === 'card' ? { cursor: "not-allowed" } : {}}>
                    <ButtonComponent
                        title={"Make Payment"}
                        height={"50px"}
                        width={"200px"}
                        backgroundColor={"#0055D4"}
                        color={"white"}
                        disabled={disableMakePayment === 'card' ? true : false}
                        margin={"0px 8px"}
                        className={disableMakePayment === 'card' ? "button-component-hover disabled" : "button-component-hover common-btn"}
                        handleClick={payment}
                    />
                </div>
            </div>
            {
                declinePopUp &&
                <InvoiceDeclinemodal
                    clearDeclinemodal={(val: boolean) => {
                        setDeclinePopUp(val)
                    }}
                    setDeclineRemarks={(val: string) => setDeclineRemarks(val)}
                    declineRemarks={declineRemarks}
                    Submit={() => {
                        GroupInvoiceDecline(declineRemarks);
                        setDeclinePopUp(false);
                    }}
                />
            }
            {
                makePaymentModel &&
                <GstApprovalMakePayment
                    clearValue={(val: boolean) => {
                        setMakePaymentModel(val)
                    }}
                    gstView={gstView}
                    setLoadingScreen={setLoadingScreen}
                    loadingScreen={loadingScreen}
                />
            }
            {
                loadingScreen &&
                <Loading />
            }
            {
                showAlertBox &&
                <div className='alert-warp' style={{ zIndex: "30" }}>
                    <AlertBox type={showType} message={showMessage} />
                </div>

            }
        </div>
    )
}

export default ChooseCard