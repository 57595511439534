import React, { useState, useEffect, useRef } from 'react'
import MainPageCard from '../../common-component/cards/main-page-card'
import NavigateHeader from '../../common-component/navigate-header'
import IconButton from '../../common-component/form-elements/icon-button'
import AgGrid, { AgGridRef } from '../../common-component/grids-and-tables/ag-grid'
import useAxiosPrivate from "../../../services/hooks/useaxios-private";
import { virutualCardApprovalColumnData, rowDatathis } from '../../../config/approval/bank-approval/virtualcard-approval/virtualcard';
import { axiosPrivate } from '../../../middleware/axois-api'
import FileUpload from '../../common-component/modals/file-upload-modal'
import DeleteModal from '../../common-component/modals/delete-modal'
import GridFilter from '../../common-component/grids-and-tables/grid-filter'
import { v4 as uuidv4 } from 'uuid';
import AlertBox from '../../common-component/alert-box'
import OtpVerifyModal from '../../common-component/modals/otp-verify-modal'
import useAuth from '../../../services/hooks/useauth'
import Loading from '../../common-component/modals/loading-screen'
import "../../../styles/approval/bank-approval/grid.scss"
import { Filter } from '../../../helpers/filtterFunction'

interface Props {
  virtualCard: (val: string) => void;
  getEditData?: ((name: string, data: any) => void) | undefined;
  getDelete?: () => void;
  modeFunction: (val: boolean) => void;
  getEditMode: (value: boolean) => void;

}
interface IFilterValue {
  [key: string]: {
    columnName: string;
    filterType: string;
    filterWord: string;
  };
}


function Grid({ virtualCard, getEditMode, modeFunction, getEditData, }: Props) {

  const [showType, setShowType] = useState("warning") // error message showType
  const [showMessage, setShowMessage] = useState<string>("") // error message showMessage
  const [showAlertBox, setShowAlertBox] = useState(false) // error message ShowAlertBox

  const gridDataDownloadRef = useRef<AgGridRef>(null);
  const [rowData, setRowData] = useState<any>([]);
  const [curruntData, setCurruntData] = useState<any>();
  const [fileUpload, setFileUpload] = useState<boolean>(false);
  const [filter, setFilter] = useState(false);
  const [filterRestore, setFilterRestore] = useState<IFilterValue>({})
  const [orginalRowData, setOrginalRowData] = useState<any>([]);
  const [filterMethod, setFilterMethod] = useState("")
  const [showDeleteModel, setShowDeleteModel] = useState<boolean>(false)
  const [uploadedDataStore, setuploadedDataStore] = useState<any>([]);
  const [otpVerification, setOtpVerification] = useState<boolean>(false)
  const [isLocked, setIsLocked] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { auth } = useAuth();


  const axiosPrivate = useAxiosPrivate();

  useEffect(() => {
    getTableData();
  }, []);


  // Timeout function for messages
  const clearAleart = () => {
    const timer = setTimeout(() => {
      setShowAlertBox(false);
      setShowMessage("");
      clearTimeout(timer);
    }, 5000);
  }

  // Get data for grid API
  const getTableData = async () => {
    try {
      const response = await axiosPrivate.get('/virtual-card/approval-grid/get');
      setRowData(response.data.decryptData);
      setOrginalRowData(response.data.decryptData)
    } catch (error) {
      setRowData([]);
      setOrginalRowData([])
    }
  };


  async function getUserData(data: any) {
    // if (data.status === "Processing") {
    //   return
    // }
    await axiosPrivate.get(`/virtual-card/form/get/${data.id}`)
      .then(response => {
        getEditData && getEditData("form", response.data.decryptData);
      })
      .catch(error => {
        console.error(error);
      });
  }

  //handle grid filter
  // Function for Filter values
  const handleFilter = (value: any, filterType: string) => {
    Filter(value, filterType, orginalRowData, setRowData)
  }

  // upload bank data 
  const getUploadedUserData = (val: any) => {
    setuploadedDataStore(val);

  }

  // handle download grid
  const handleDownloadExcel = () => {
    gridDataDownloadRef.current!.onBtExport();
  }


  const clearValue = (val: any) => {
    setFileUpload(val);
  }
  // get bank data for doubleclick
  // function getUserData(data: any) {
  //   modeFunction(true)
  //   getEditData && getEditData("form", data);


  // }
  // current data update
  const getCurruntData = (val: any) => {
    setCurruntData(val);
  }




  // Delete function for delete data in grid
  const getDelete = async () => {
    if (curruntData.length > 0) {
      let idList: any = []
      curruntData.map((ele: any) => {
        idList.push(
          ele.id
        )
      })
      axiosPrivate.post(`/virtual-card/approval-grid/delete`, idList)
        .then(response => {
          if (response.status === 200) {
            setShowAlertBox(true)
            setShowType("success")
            setShowMessage(response.data.message)
            clearAleart()
            setCurruntData([])
            getEditMode(false)
            setShowDeleteModel(false)
            setOtpVerification(false)
            getTableData()

          }
        })
        .catch(error => {
          setShowAlertBox(true);
          setShowType("warning")
          setShowMessage(error.response.data.error)
          setOtpVerification(false)
          setShowDeleteModel(false)
          clearAleart()
        });
    }
  };


  async function resendOtp() {
    try {
      const res = await axiosPrivate.post('companyadmin/bank/resend-otp-update/approval', {
        approvedBy: auth.token,

      });
      if (res.status === 200) {
        // updateData()
        setShowAlertBox(true)
        setShowType("success")
        setShowMessage(res.data.message)
        clearAleart()
      }
    } catch (error: any) {
      setShowAlertBox(true);
      setShowType("danger")
      setShowMessage(error.response.data.error)
      // clearAleart("")
    }
  }



  return (
    <MainPageCard>
      <div className='header-main'>
        <div className='navigater'>
          <NavigateHeader
            style={{
              cursor: "default"
            }}
            firstValue={"Approval"}
            secondValue={"Virtual Card"}
          />
        </div>

        <div className='icon-buttons'>
          <IconButton
            iconName={"Filter"}
            height={"40px"}
            width={"40px"}
            fontSize={"30px"}
            margin={"0px 8px"}
            color={"white"}
            backgroundColor={"#0055D4"}
            hover={true}
            handleClick={(event: React.MouseEvent) => {
              event?.stopPropagation()
              setFilter(!filter);
            }}
          />
          <IconButton
            iconName={"Delete"}
            height={"40px"}
            width={"40px"}
            fontSize={"25px"}
            margin={"0px 8px"}
            color={"white"}
            backgroundColor={"#D60000"}
            hover={curruntData?.length > 0}
            opacity={curruntData?.length > 0 ? "1" : "0.5"}
            cursor={curruntData?.length > 0 ? "pointer" : "default"}
            handleClick={() => {
              if (curruntData?.length > 0) {
                setShowDeleteModel(!showDeleteModel)
              }
            }}
          />
          <IconButton
            iconName={"Add"}
            height={"40px"}
            width={"75px"}
            fontSize={"25px"}
            margin={"0px 8px"}
            color={"white"}
            hover={false}
            opacity={"0.5"}
            cursor={"not-allowed"}
            backgroundColor={"#0055D4"}
            handleClick={() => {
              //   userNavigateFunction("form")

            }}
          />
          <IconButton
            iconName={"Upload"}
            height={"40px"}
            width={"40px"}
            fontSize={"25px"}
            margin={"0px 0px 0px 8px"}
            color={"white"}
            backgroundColor={"#0055D4"}
            hover={false}
            opacity={"0.5"}
            cursor={"not-allowed"}
            handleClick={() => {
              setFileUpload(false);
              // setuploadedDataGridShow(true);
            }}
          />
          <div>
            <IconButton
              iconName={"Download"}
              height={"40px"}
              width={"40px"}
              fontSize={"25px"}
              margin={"0px 8px"}
              color={"white"}
              backgroundColor={"#0055D4"}
              hover={true}
              handleClick={handleDownloadExcel}
            />
          </div>
        </div>
      </div>
      <div>
        <AgGrid
          ref={gridDataDownloadRef}
          fileName={"VirtualCard"}
          tabelRowData={rowData}
          tableColumnData={virutualCardApprovalColumnData}
          doubleClickUpdate={() => { getEditData && getEditData("form", "") }}
          getEdit={(data) => getUserData(data)}
          getCurruntData={getCurruntData}

        />
      </div>
      {
        filter &&
        <div className='filter' key={uuidv4()}>
          <GridFilter
            filterRestore={filterRestore}
            filterMethod={filterMethod}
            tabelRowData={rowData}
            data={virutualCardApprovalColumnData.slice(1)}
            handleClick={(Value: any, filterType: string) => {
              if (filterType !== "") {
                setFilterRestore(Value)
                setFilter(!filter)
                setFilterMethod(filterType)
                handleFilter(Value, filterType)
                setCurruntData([]);

              } else {
                setFilterMethod("")
                setFilterRestore({})
                setFilter(!filter)
                setRowData(orginalRowData)
                setCurruntData([]);
              }
            }}
          />
        </div>
      }
      {
        fileUpload &&
        <FileUpload clearValue={clearValue} headerData={virutualCardApprovalColumnData} templateName={"User"} getUploadedUserData={getUploadedUserData} />
      }


      {
        otpVerification &&
        <OtpVerifyModal
          otpVerify={resendOtp}
          leavePage={() => {
            setOtpVerification(false)
            virtualCard("grid")
          }}
          isLocked={false}
          setIsLocked={setIsLocked}
          getStatus={async (stringFromArray) => {
            let res;
            try {

              res = await axiosPrivate.post('companyadmin/bank/verify-otp/update', {
                otp: stringFromArray,
                approvedBy: auth.token,

              });
              if (res.status === 200) {
                getDelete()
              }
            } catch (error: any) {
              setShowAlertBox(true);
              setShowType("danger")
              setShowMessage(error.response.data.error)
              clearAleart()
            }

          }
          }
        />
      }
      {
        isLoading &&
        <Loading />
      }

      {
        showDeleteModel &&
        <div >
          <DeleteModal
            getDelete={async () => {
              setIsLoading(true)
              let currentDataValue = curruntData[0].companyBankId

              try {
                const res = await axiosPrivate.post("companyadmin/bank/otp-update/approval", {
                  approvedBy: auth.token,

                })
                if (res.status === 200) {
                  setOtpVerification(true)
                  setShowDeleteModel(false)
                  setIsLoading(false)
                  setShowAlertBox(true)
                  setShowType("success")
                  setShowMessage(res.data.message)
                  clearAleart()
                }

              } catch (error: any) {
                setIsLoading(false)
                setShowAlertBox(true);
                setShowType("danger")
                setShowMessage(error.response.data.error)
                clearAleart()
              }
            }}
            clearValue={(value) => { setShowDeleteModel(value) }}
            modelType={"grid-delete"}

          />
        </div>
      }
      {
        showAlertBox &&
        <div className='alert-warp'>
          <AlertBox type={showType} message={showMessage} />
        </div>
      }
    </MainPageCard>
  )
}

export default Grid;
