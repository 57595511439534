import React, { useEffect, useRef, useState } from 'react'
import MainPageCard from '../../../components/common-component/cards/main-page-card'
import AgGrid, { AgGridRef } from '../../../components/common-component/grids-and-tables/ag-grid';
import { userLogColumnData, securityLogColumnData } from '../../../config/log-reports/user-log/user-log-events-config';
import '../../../styles/product-admin/log-reports/user-log-events.scss'
import DropdownComponent from '../../../components/common-component/form-elements/dropdown-component';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import useAxiosPrivate from "../../../services/hooks/useaxios-private";

// import UserLogDetailsModal from '../modals/user-log-details';
import CommonShowLogDetails from '../../../components/common-component/modals/show-log-deatils';
import Loading from '../../../components/common-component/modals/loading-screen';
import ShowSecurityLogDetails from '../../../components/common-component/modals/show-security-log-details';

interface Props {
    getEditData: (nav: any, data: any) => void;

}

export default function SecurityLogs() {
    const gridDataDownloadRef = useRef<AgGridRef>(null);
    const [getMonths, setGetMonths] = useState<any[]>([])
    const [monthSelect, setMonthSelect] = useState<string>("")
    const [getUserLogValue, setGetUserLogValue] = useState<any>({})
    const [showuserDetailPopup, setShowUserDetailPopup] = useState<boolean>(false)
    const [rowData, setRowData] = useState<any>([]);
    const [loadingScreen, setLoadingScreen] = useState<boolean>(false);



    const axiosPrivate = useAxiosPrivate();

    useEffect(() => {
        getLogMonths()
    }, [])


    const getLogMonths = async () => {
        try {
            const response = await axiosPrivate.get("tables/list/request/responses");
            if (response.status === 200) {
                const dropdownValues = response.data.requestAndResponseLogsRecords.map((item: any) => ({
                    label: item,
                    value: item,
                    // id: item.bankId,
                }));
                initialLogApi(dropdownValues)
                setGetMonths(dropdownValues);
            }
        } catch (error) {
        }
    };
    async function initialLogApi(dropdownValues: any) {
        const dateString = dropdownValues[dropdownValues.length - 1].value;
        setMonthSelect(dateString)
        const data = dateString && dateString.split(' ');
        setLoadingScreen(true)
        try {
            const params = { "month": data[0], "year": data[1] };
            const response = await axiosPrivate.post("record/security", params);
            if (response.status === 200) {
                setLoadingScreen(false)
                setRowData(response.data.modifiedRecords)
            }
        } catch {
            setLoadingScreen(false)
        }
    }

    async function getSecurityLogDetails(logID: any) {
        console.log(logID)
        try {
            const response = await axiosPrivate.get(`record/security/get-by-id/${logID}`);
            if (response.status === 200) {
                setGetUserLogValue(response.data)
                setShowUserDetailPopup(true)
            }
        } catch {

        }
    }
    return (
        <>
            <MainPageCard>
                <div className='dropdown-user-log' key={uuidv4()}>
                    <DropdownComponent
                        options={getMonths}
                        title={"Months"}
                        required={false}
                        getData={async (val) => {
                            setMonthSelect(val.value)
                            const dateString = val.value;
                            const data = dateString && dateString.split(' ');
                            setLoadingScreen(true)
                            try {
                                const params = { "month": data[0], "year": data[1] };
                                const response = await axiosPrivate.post("record/security", params);
                                if (response.status === 200) {
                                    setLoadingScreen(false)
                                    setRowData(response.data.modifiedRecords)
                                    console.log(response)
                                    // getSecurityLogDetails(response.data.modifiedRecords[0].id)
                                }
                            } catch {
                                setLoadingScreen(false)
                            }

                        }}
                        defaultValue={[{ label: monthSelect ? monthSelect : "Select", value: monthSelect }]}
                    />
                </div>
                <div>
                    <AgGrid
                        ref={gridDataDownloadRef}
                        displaylength={15}
                        fileName={""}
                        tabelRowData={rowData}
                        singleSelect={true}
                        tableColumnData={securityLogColumnData}
                        // getEdit={(data) => {
                        //     getSecurityLogDetails(data.id)
                        //     console.log(data.id)
                        // }}
                        getSingleColumn={(data) => {
                            getSecurityLogDetails(data.id)
                        }}
                    // doubleClickUpdate={(data) => { getSecurityLogDetails(data.id) 
                    // }}



                    />
                </div>
            </MainPageCard>
            {/* {showuserDetailPopup &&
        <UserLogDetailsModal
          clearValue={() => { setShowUserDetailPopup(false) }}
          getUserLogDeatil={getUserLogValue}
        />
      } */}
            {showuserDetailPopup &&
                <div className='right-side-filter-component'>
                    <ShowSecurityLogDetails
                        setOpenRightSideFilter={() => {
                            setShowUserDetailPopup(false)
                        }}
                        getUserLogDeatil={getUserLogValue}
                    />
                </div>
            }
            {
                loadingScreen &&
                <Loading />
            }
        </>
    )
}
