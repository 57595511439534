import React, { useState, useEffect, useRef } from 'react'
import useAxiosPrivate from '../../services/hooks/useaxios-private';
import AlertBox from '../common-component/alert-box';
import MainPageCard from '../common-component/cards/main-page-card';
import ButtonComponent from '../common-component/form-elements/button-component';
import DropdownComponent from '../common-component/form-elements/dropdown-component';
import { v4 as uuidv4 } from "uuid"
import { FiCheckSquare } from 'react-icons/fi';
import { HiInformationCircle } from 'react-icons/hi';
import { ReactComponent as Information } from '../../assets/icons/Information.svg'
import { ReactComponent as ToDo } from '../../assets/icons/Remainder.svg'
import useAuth from '../../services/hooks/useauth';
import { useNavigate } from 'react-router-dom';
import '../../styles/notification/notification.scss';
import { DateConverter } from '../../helpers/capitalizetext';

interface Props {
    setActiveTitleMyaccount: (val: string) => void;
}

export default function Notification({ setActiveTitleMyaccount }: Props) {
    
    const navigate = useNavigate();

    interface iconsInterface {
        [key: string]: JSX.Element;
    }
    // Icons for notification 
    const icons: iconsInterface = {
        "ToDo": <ToDo />,
        "FYI": <Information />
    }

    const { auth } = useAuth();
    const axiosPrivate = useAxiosPrivate();

    // Dropdown values for notification filters
    const notificationFilterValues = [
        { label: "All", value: "All" },
        { label: "FYI", value: "FYI" },
        { label: "To Do", value: "ToDo" },
        { label: "Unread", value: "Unread" },
    ]
    const [notification, setNotification] = useState<any>([])
    const [notificationDropdownValue, setNotificationDropdownValue] = useState<any>("")

    useEffect(() => {
        getUserNotificationAll();
    }, [auth.token]);

    // Function for Dropdown change filter ALL
    const getUserNotificationAll = async () => {
        try {
            const res = await axiosPrivate.get(`/user/notifications/till-recieved`);

            if (res.status === 200) {
                setNotification(res.data.notifications)
            }

        } catch (error) {
            // Handle error here
        }
    };

    // Function for Dropdown change filter FYI & ToDo
    const getUserFilterNotification = async (val: string) => {
        try {
            const res = await axiosPrivate.post(`/user/notifications/filter`, {
                "messageType": val
            });

            if (res.status === 200) {
                setNotification(res.data.notifications)
            }

        } catch (error) {
            // Handle error here
        }
    };

    // Function for Dropdown change filter Unread
    const getUserNotificationUnread = async () => {
        try {
            const res = await axiosPrivate.get(`/user/all/notifications/unread`)

            if (res.status === 200) {
                setNotification(res.data.notifications)
            }

        } catch (error) {
        }
    };

    // Function for navigate page to Notification Perferences
    const handleNavigate = () => {
        setActiveTitleMyaccount("notificationPreference")
        navigate("/user-profile")
    }

    return (
        <>
            <MainPageCard>
                <div className='header-main'>
                    <div className='navigater'>
                        <div className='navigate-header-component'>
                            <ul className='bread-crumbs' >
                                <li><span>{"Notifications"}</span></li>
                            </ul>
                        </div>
                    </div>
                    <div className='icon-buttons'>
                        <div className=''>
                            <div className={'dropdown-user'} key={uuidv4()} style={{ width: "220px" }}>
                                <DropdownComponent
                                    options={notificationFilterValues}
                                    className={"kyc-dropdown"}
                                    width='350px'
                                    required={true}
                                    getData={(val) => {
                                        setNotificationDropdownValue(val.value);
                                        if (val.value === "All") {
                                            getUserNotificationAll();
                                        } else if (val.value === "ToDo" || val.value === "FYI") {
                                            getUserFilterNotification(val.value)
                                        } else if (val.value === "Unread") {
                                            getUserNotificationUnread()
                                        }
                                    }}
                                    defaultValue={[{
                                        label: notificationDropdownValue === "" ? "All" : notificationDropdownValue,
                                        value: notificationDropdownValue === "" ? "All" : notificationDropdownValue
                                    }]}
                                />
                            </div>
                        </div>
                        <ButtonComponent
                            title={"Notification Preference"}
                            height={"40px"}
                            width={"200px"}
                            backgroundColor={"#0055D4"}
                            color={"white"}
                            margin={"0px 8px"}
                            className={"button-component-hover common-btn"}
                            handleClick={handleNavigate}
                        />
                    </div>
                </div>

                <div className={"notification-content-area"}>
                    {notification?.length === 0 ?
                        <div className={"notification-noRecords"}>
                            <p>No Notification</p>
                        </div> : notification.map((data: any, i: any) => (
                            <>
                                <div className={"notification-main"} key={i}>
                                    <div className={"notification-sub"}>
                                        <div className={"notification-sub-main"} >
                                            <div className={"Icons"}>
                                                {icons[data.messageType]}   {/* {icons[data.type]} */}
                                            </div>
                                            <div className={"notification-main-content"}>
                                                {/* <b>{data.title}</b> */}
                                                <p>{data.message}</p>
                                            </div>
                                        </div>
                                        <div className={"notification-date"}>
                                            <p>{DateConverter(data.createdAt)}</p>
                                        </div>
                                    </div>
                                </div>
                            </>
                        ))}
                </div >
            </MainPageCard>
        </>
    )
}