import React, { useState, useRef, ChangeEvent, KeyboardEvent, useEffect } from 'react'
import { RxCross1 } from 'react-icons/rx';
import useAxiosPrivate from "../../../services/hooks/useaxios-private";
import useAuth from '../../../services/hooks/useauth';
import AlertBox from '../alert-box';
import { IoIosEye, IoIosEyeOff } from 'react-icons/io';
import ButtonComponent from '../form-elements/button-component';
import '../../../styles/modals/get-card-cvv-modal.scss';
import InputComponent from '../form-elements/input-component';


interface Styles {
    // isLocked?: boolean
    // setIsLocked: (val: any) => void;
    leavePage: (val: any) => void;
    // getStatus: (val: any) => void
    // otpVerify: () => void
    cardCvv: any,
    cardDetails: any,
    setCardCvv: (val: any) => void,
    ApproverPayMent: () => void,
}

export default function Cvv({
    // otpVerify,
    // getStatus,
    // isLocked,
    leavePage,
    ApproverPayMent,
    cardDetails,
    cardCvv,
    setCardCvv,

}: Styles) {

    const [showPassword, setShowPassword] = useState<boolean>()
    console.log(cardDetails)


    // Function for PIN field 


    return (

        <>
            <div className={"card-get-cvv-modal"}>
                <div className={"container-cvv"}>
                    {/* <div className={"close-icon"}>
                    <span className={"cross-icon"}>
                        <RxCross1 style={{ fontSize: "40px" }} onClick={()=>  leavePage(false)} />
                    </span>
                </div> */}
                    <div className={'card-details'}  >
                        <div className={'card-details-title'} >
                            Card Details
                        </div>

                        <div className='details-wrapper'>
                            <p>Card Holder</p>
                            <div className='display-card-value'>{cardDetails.nameOnCard}</div>
                        </div>

                        <div className='details-wrapper'>
                            <p>Card Number</p>
                            <div className='display-card-value'>{cardDetails.cardNumber}</div>
                        </div>

                        <div className='cvv-entry-field-wrapper'>
                            <div className='details-wrapper-small'>
                                <p>Expiry Date</p>
                                <div className='display-card-value'>{cardDetails.validThru}</div>
                            </div>
                            <div className='details-wrapper-small'>
                                <p>CVV</p>
                                <div>
                                    <InputComponent
                                        height={"40px"}
                                        width={"100%"}
                                        margin={"0px 0px 0px 0px"}
                                        padding={"10px"}
                                        border={"1px solid #A9C3DC"}
                                        borderRadius={"0px"}
                                        backgroundColor={"white"}
                                        color={"black"}
                                        type={"text"}
                                        autoFocus
                                        maxLength={4}
                                        // textAlign={"right"}
                                        // inputTitle={'Base Amount'}
                                        // disabled={partPaymetFieldsBlock}
                                        // required={true}
                                        placeHolder={'0'}
                                        className={"vendor-input-type"}
                                        inputValue={cardCvv}
                                        getUser={(value) => {
                                            let cvv = value.replace(/[^0-9]/g, "");
                                            setCardCvv(cvv)
                                        }}
                                    />

                                </div>
                            </div>
                        </div>


                        <div className={"approval-buttons"}  style={{marginTop:"10px"}}> 

                            <ButtonComponent
                                title={"Submit"}
                                height={"40px"}
                                width={"130px"}
                                backgroundColor={"#0055D4"}
                                color={"white"}
                                handleClick={async () => {
                                    await ApproverPayMent();
                                    setCardCvv('')
                                }}
                                disabled={cardCvv.length < 3}
                                className={cardCvv.length < 3 ? "button-component-hover disabled" : "button-component common-btn"}
                            />
                            {/* <ButtonComponent
                                title={"Cancel"}
                                height={"50px"}
                                width={"130px"}
                                margin={"0px 8px"}
                                backgroundColor={"#888888"}
                                color={"white"}
                                handleClick={() => {
                                    leavePage(false)
                                }}
                                className="button-component-hover cancel"
                            /> */}
                            <div className='cancel-cvv-enter' onClick={() => {
                                leavePage(false)
                                setCardCvv('')
                            }}>
                                <u>Cancel</u>
                            </div>
                        </div>
                    </div>
                </div>
            </div>





        </>

    )
}

