import React, { useEffect, useState, forwardRef, useImperativeHandle, } from 'react'
import InputComponent from '../../../common-component/form-elements/input-component'
import DropdownComponent from '../../../common-component/form-elements/dropdown-component'
import ButtonComponent from '../../../common-component/form-elements/button-component';
import BankRelationship from '../../../../config/onboarding/bank-relationship-grid';
import { v4 as uuidv4 } from 'uuid'
import useAxiosPrivate from "../../../../services/hooks/useaxios-private";
import AlertBox from '../../../common-component/alert-box';
import { useDispatch, useSelector } from "react-redux";
import { resetFormModified } from "../../../../redux/action";
import Changesmodal from '../../../common-component/modals/changes-modal';
import DeleteModal from '../../../common-component/modals/delete-modal';

interface DropdownItem {
  label: string;
  value: string;
}
export interface ChildRef {
  next: () => void;
}
interface props {
  onboardingViewfun: (val: any) => void;
  setBankRelationshipMain: (val: any) => void;
  bankRelationshipMain: any;
  iconStatus: any;
  editData: any;
  setShowPage: (val: any) => void;
  setIconStatus: (val: any) => void;
  isEditMode: boolean;
  sendDataApi: () => void;
}
const initData: any = {
  Name: "",
  Designation: "",
  UnitDetails: "",
  EmailId: "",
  ContactNumber: "",
  Region: "Select",
  State: "Select",
  City: "Select",
  RegionId: "",
  StateId: "",
  CityId: "",
  Branch: "",
  index: 0
}

const BankRelationshipManager = forwardRef<ChildRef, props>(({ onboardingViewfun, iconStatus, editData, isEditMode, setShowPage, sendDataApi, setIconStatus, setBankRelationshipMain, bankRelationshipMain }, ref) => {
  const [dropDownRegion, setDropDownRegion] = useState<DropdownItem[]>([])
  const [dropDownState, setDropDownState] = useState<DropdownItem[]>([])
  const [dropDownCities, setDropDownCities] = useState<DropdownItem[]>([])
  const [dropDownBranch, setDropDownBranch] = useState<DropdownItem[]>([])
  const [formDataEdit, setFormDataEdit] = useState(false)
  const [currentIndex, setCurrentIndex] = useState<number>()
  const [currentBankRelationship, setCurrentBankRelationship] = useState<number>()
  const axiosPrivate = useAxiosPrivate();
  //BankContact details
  const [bankContactDetails, setBankContactDetails] = useState<any>(initData)
  const [bankRelationError, setBankRelationError] = useState<Record<string, boolean>>({})
  const [showType, setShowType] = useState("warning") // error message showType
  const [showMessage, setShowMessage] = useState<string>("") // error message showMessage
  const [showAlertBox, setShowAlertBox] = useState(false) // error message ShowAlertBox
  const [isFormDirty, setIsFormDirty] = useState(false)
  const isFormModified = useSelector((state: any) => state.isFormModified);
  const [showChangesModal, setShowChangesModal] = useState<boolean>(false);
  const dispatch = useDispatch();
  const [currentDeletVal, setCurrentDeletVal] = useState<any>({});
  const [deleteIndex, setDeleteIndex] = useState<number>(0)
  const [showDeleteModel, setShowDeleteModel] = useState<boolean>(false)

  useEffect(() => {
    fetchData("region", setDropDownRegion, "region", "regionId", "name");
  }, [])


  function returnGird() {
    onboardingViewfun("grid")
    dispatch(resetFormModified(false));
  }

  async function duplicateCheck() {
    const finalData = {
      "EmailId": bankContactDetails?.EmailId,
      "ContactNumber": bankContactDetails?.ContactNumber
    }
    let statusMessage: boolean = false;
    try {
      const fieldsToCheck = ['EmailId', 'ContactNumber'];
      const todisplay = ['Email Id', 'Contact Number'];
      const failedFields: any[] = [];
      let isDuplicate = false
      if (!formDataEdit) {
        isDuplicate = bankRelationshipMain.some((item: any) => {
          const matches = fieldsToCheck.map((field, i) => {
            if (bankContactDetails[field] !== '') {
              if (item[field] === bankContactDetails[field]) {
                failedFields.push(todisplay[i]);
              }
              return item[field] === bankContactDetails[field];
            }
            return false;
          });
          return matches.some(match => match);
        });
      } else {
        isDuplicate = bankRelationshipMain.some((item: any, index: any) => {
          const matches = fieldsToCheck.map((field, i) => {
            if (bankContactDetails[field] && bankContactDetails[field] !== '' && currentIndex != index) {
              if (item[field] === bankContactDetails[field]) {
                failedFields.push(todisplay[i]);
              }
              return item[field] === bankContactDetails[field];
            }
            return false;
          });
          return matches.some(match => match);
        });
      }
      if (!isDuplicate) {
        const res = await axiosPrivate.post(`bank-admin/company/duplicate-checking/${currentBankRelationship}`, { 'accountManager': finalData })
        if (res.status === 200) {
          statusMessage = true;
        }
      } else {
        statusMessage = false;
        setShowAlertBox(true)
        setShowType("danger")
        setShowMessage(`${failedFields[0]} already exists.`)
        clearAlert("")
      }
    }
    catch (error: any) {
      statusMessage = false;
      setShowAlertBox(true);
      setShowType("danger")
      setShowMessage(error?.response?.data?.error)
      clearAlert('');
    }
    return statusMessage;
  }

  const userValidationRules: any = {
    companyData: {
      Name: {
        regex: /^[A-Za-z][A-Za-z\s]*$/,
        field: "mandatory",
        shouldNotBe: ""
      },
      Designation: {
        regex: /^[A-Za-z][A-Za-z\s]*$/,
        field: "mandatory",
        shouldNotBe: ""
      },
      UnitDetails: {
        regex: "",
        field: "mandatory",
        shouldNotBe: ""
      },
      EmailId: {
        regex: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        field: "mandatory",
        shouldNotBe: ""
      },
      ContactNumber: {
        regex: /^[0-9]{10}$/,
        field: "mandatory",
        shouldNotBe: ""
      },
    }
  }

  const updatedIsValueValidAll: Record<string, boolean> = {};
  const validateForm = (): boolean => {
    const updatedFieldValidity: Record<string, boolean> = {};
    Object.keys(userValidationRules.companyData).forEach((field) => {
      const rule = userValidationRules.companyData[field];
      const value = bankContactDetails[field];
      const isValueValid = (rule.field === "mandatory") ? (value ? (value !== rule.shouldNotBe) : false) : true;
      const isRegexValid = rule.regex !== "" ? new RegExp(rule.regex).test(value) : true;
      updatedFieldValidity[field] = !isValueValid || !isRegexValid;
      updatedIsValueValidAll[field] = !isValueValid;
    });
    setBankRelationError(updatedFieldValidity);
    const isFormValid = Object.keys(updatedFieldValidity).every((keys) => {
      return !updatedFieldValidity[keys]
    });
    return isFormValid;
  };


  function next() {
    if (isEditMode) {
      sendDataApi();
    } else {
      setShowPage("AdminDetails")
      setIconStatus({
        ...iconStatus,
        relationshipIcon: "completed",
        adminIcon: "selected"
      })
    }
  }
  useImperativeHandle(ref, () => {
    return {
      next: next
    }
  });


  const fetchData = async (url: string, setData: (data: any[]) => void, nestedProperty: string, id: any, representName: any) => {
    try {
      const response = await axiosPrivate.get(url);
      if (response.status === 200) {
        const dropdownValues = response.data[nestedProperty].map((item: any) => ({
          label: item[representName],
          value: item[representName],
          id: item[id],
        }));
        const sortedData = [...dropdownValues].sort((a, b) => a.label.localeCompare(b.label));
        setData([{ label: "Select", value: "Select", id: "" }, ...sortedData]);



      }
    } catch (error) {
    }
  };

  async function handelAdd() {
    if (!validateForm()) {
      setShowAlertBox(true);
      setShowType("danger");
      setShowMessage(Object.values(updatedIsValueValidAll).every((values) => !values) ?
        "Some of the field(s) are not in required format." : "Mandatory field(s) should not be left blank.")
      clearAlert('');
      return;
    }
    let Duplicate = await duplicateCheck();
    if (!Duplicate) return;
    if (formDataEdit) {
      let data: any = []
      bankRelationshipMain.length > 0 && bankRelationshipMain.map((ele: any, i: number) => {
        if (i === bankContactDetails.index) {
          data.push(bankContactDetails)
        } else {
          data.push(ele)
        }
      })
      setBankRelationshipMain(data)
      setFormDataEdit(false)
      setBankContactDetails(
        {
          Name: "",
          Designation: "",
          UnitDetails: "Select",
          EmailId: "",
          ContactNumber: "",
          Region: "Select",
          State: "Select",
          City: "Select",
          RegionId: "",
          StateId: "",
          CityId: "",
          Branch: "",
          index: 0,

        }
      )
      setIsFormDirty(false);
      setShowAlertBox(true);
      setShowType("success");
      setShowMessage("Account Manager updated successfully.")
      clearAlert('');
    }
    else {
      let newrecord = [...bankRelationshipMain]
      newrecord.push({ ...bankContactDetails })
      setBankRelationshipMain(newrecord)
      setBankContactDetails(
        {
          Name: "",
          Designation: "",
          UnitDetails: "Select",
          EmailId: "",
          ContactNumber: "",
          Region: "Select",
          State: "Select",
          City: "Select",
          RegionId: "",
          StateId: "",
          CityId: "",
          Branch: "",
          index: 0
        }
      )
    }
    setDropDownState([])
    setDropDownCities([])
    setDropDownBranch([])
    setIsFormDirty(false);
    setFormDataEdit(false);

  }
  function retriveData(value: any, index: number) {
    setCurrentIndex(index)
    setFormDataEdit(true)
    setIsFormDirty(true);
    setCurrentBankRelationship(value.bankRelationShipId)
    if (Object.values(value).length !== 3) {
      setBankContactDetails({
        ...value,
        index: index,
      })
    }

    const region: any = dropDownRegion.find((privilegeObj: any) => {
      return privilegeObj.value === bankContactDetails.Region;
    });

    const state: any = dropDownState.find((privilegeObj: any) => {
      return privilegeObj.value === bankContactDetails.State;
    });

    const city: any = dropDownCities.find((privilegeObj: any) => {
      return privilegeObj.value === bankContactDetails.City;
    });

    fetchData(`region/state/${value.RegionId}`, setDropDownState, 'states', "stateId", "name");
    fetchData(`state/cities/${value.StateId}`, setDropDownCities, 'city', "cityId", "name");
    fetchData(`get-branchBy-city/${value.CityId}`, setDropDownBranch, 'filteredBranch', "branchId", "branchName");
  }

  const clearAlert = (status: any) => {
    const timer = setTimeout(() => {
      setShowAlertBox(false);
      setShowMessage("");
      if (status === 200) {
        onboardingViewfun("form")
      }
      clearTimeout(timer);
    }, 5000);
  }
  const clearAleart = () => {
    const timer = setTimeout(() => {
      setShowAlertBox(false);
      setShowMessage("");
      clearTimeout(timer);
    }, 5000);
  }

  const DirectorInfoDelete = async () => {
    if (currentDeletVal.bankRelationShipId) {
      try {
        const response = await axiosPrivate.patch(`/company-approval/accountManager/${currentDeletVal.bankRelationShipId}`);
        if (response.status === 200) {
          setShowDeleteModel(false);
          const newArray = [...bankRelationshipMain];
          newArray.splice(deleteIndex, 1);
          setBankRelationshipMain(newArray);
          setShowAlertBox(true)
          setShowType("success")
          setShowMessage(response.data.message)
          setBankContactDetails(
            {
              Name: "",
              Designation: "",
              UnitDetails: "Select",
              EmailId: "",
              ContactNumber: "",
              Region: "Select",
              State: "Select",
              City: "Select",
              RegionId: "",
              StateId: "",
              CityId: "",
              Branch: "",
              index: 0
            }
          )
          setFormDataEdit(false)
          clearAleart()
        }
      } catch (error) {
      }
    } else if (currentDeletVal.index === 0) {
      const newArray = [...bankRelationshipMain];
      newArray.splice(deleteIndex, 1);
      setBankRelationshipMain(newArray)
      setShowDeleteModel(false)
      setShowAlertBox(true)
      setShowType("success")
      setShowMessage("The selected record(s) has been deleted")
      setBankContactDetails(
        {
          Name: "",
          Designation: "",
          UnitDetails: "Select",
          EmailId: "",
          ContactNumber: "",
          Region: "Select",
          State: "Select",
          City: "Select",
          RegionId: "",
          StateId: "",
          CityId: "",
          Branch: "",
          index: 0
        }
      )
      setFormDataEdit(false)
      clearAleart()
    }
  };


  return (
    <div className='form-wrapper form-wrapper-differ' >
      <div className='form-area' >
        <div className='company-heading'>
          Account's Manager
        </div>
        <div className='form-main-wrapper'>
          <div>
            <InputComponent
              height={"40px"}
              width={"300px"}
              margin={"0px 45px 30px 0px"}
              padding={"0px 0px 0px 10px"}
              border={bankRelationError.Name ? "1px solid red" : "1px solid #A9C3DC"}
              borderRadius={"0px"}
              backgroundColor={"white"}
              placeHolder={'Enter Name'}
              color={"black"}
              type={"text"}
              inputTitle={"Name"}
              maxLength={100}
              required={true}
              inputValue={bankContactDetails && bankContactDetails?.Name}
              getUser={(value: any) => {
                const val = value.replace(/^[^A-Za-z]+|[^\w\s\W]/g, "");
                setBankContactDetails({
                  ...bankContactDetails,
                  Name: val
                })
                setIconStatus({
                  ...iconStatus,
                  relationshipIcon: "selected"
                })
                setIsFormDirty(true)
                dispatch(resetFormModified(true));
              }}
            />
          </div>
          <div>
            <InputComponent
              height={"40px"}
              width={"300px"}
              margin={"0px 45px 30px 0px"}
              padding={"0px 0px 0px 10px"}
              border={bankRelationError.Designation ? "1px solid red" : "1px solid #A9C3DC"}
              borderRadius={"0px"}
              backgroundColor={"white"}
              placeHolder={'Enter Designation'}
              color={"black"}
              type={"text"}
              required={true}
              inputTitle={"Designation"}
              maxLength={100}
              inputValue={bankContactDetails && bankContactDetails?.Designation}
              getUser={(value: any) => {
                const val = value.replace(/^\s+/, "");
                setBankContactDetails({
                  ...bankContactDetails,
                  Designation: val
                })
                setIconStatus({
                  ...iconStatus,
                  relationshipIcon: "selected"
                })
                setIsFormDirty(true)
                dispatch(resetFormModified(true));
              }}
            />
          </div>
          <div key={uuidv4()} className={bankRelationError.UnitDetails ? 'setErrorcompany' : "dropdown-margin dropdown-width"}>
            <DropdownComponent
              width={"300px"}
              title={"Unit Details"}
              required={true}
              defaultValue={[{ label: bankContactDetails?.UnitDetails === (undefined || "") ? "Select" : bankContactDetails?.UnitDetails, value: bankContactDetails && bankContactDetails?.UnitDetails }]}
              options={[{ label: "Current Account", value: "Current Account" }, { label: "Commercial Card", value: "Commercial Card" }]}
              getData={(value) => {
                setBankContactDetails({
                  ...bankContactDetails,
                  UnitDetails: value.value
                })
                setIconStatus({
                  ...iconStatus,
                  relationshipIcon: "selected"
                })
                setIsFormDirty(true)
                dispatch(resetFormModified(true));
              }}
            />
          </div>
        </div>
        <div className='form-main-wrapper'>
          <div>
            <InputComponent
              height={"40px"}
              width={"300px"}
              margin={"0px 45px 30px 0px"}
              padding={"0px 40px 0px 10px"}
              borderRadius={"0px"}
              backgroundColor={"white"}
              required={true}
              border={bankRelationError.EmailId ? "1px solid red" : "1px solid #A9C3DC"}
              color={"black"}
              type={"text"}
              placeHolder={"Enter Email ID"}
              inputTitle={"Email ID"}
              maxLength={254}
              className={"address-input"}
              inputValue={bankContactDetails && bankContactDetails?.EmailId}
              inputField={"gmail"}
              getUser={(value: any) => {
                setBankContactDetails({
                  ...bankContactDetails,
                  EmailId: value
                })
                setIconStatus({
                  ...iconStatus,
                  relationshipIcon: "selected"
                })
                setIsFormDirty(true)
                dispatch(resetFormModified(true));
              }}
            />
          </div>
          <div>
            <InputComponent
              height={"40px"}
              width={"300px"}
              margin={"0px 45px 30px 0px"}
              padding={"0px 0px 0px 70px"}
              borderRadius={"0px"}
              backgroundColor={"white"}
              color={"black"}
              required={true}
              type={"mobile"}
              maxLength={10}
              inputTitle={"Contact Number"}
              className={"mobilenumber-input"}
              placeHolder={"Enter Contact Number"}
              inputValue={bankContactDetails && bankContactDetails?.ContactNumber}
              // disabled={admin_details[i].isActive !== "No"}
              border={bankRelationError.ContactNumber ? "1px solid red" : "1px solid #A9C3DC"}

              getUser={(value: any) => {
                const val = value.replace(/[^0-9]/g, "")
                setBankContactDetails({
                  ...bankContactDetails,
                  ContactNumber: val
                })
                setIconStatus({
                  ...iconStatus,
                  relationshipIcon: "selected"
                })
                setIsFormDirty(true)
                dispatch(resetFormModified(true));

              }}
            />
          </div>
          <div key={uuidv4()}>
            <DropdownComponent
              width={"300px"}
              title={"Region"}
              defaultValue={[{ label: (bankContactDetails?.Region === undefined || bankContactDetails?.Region === "") ? "Select" : bankContactDetails?.Region, value: bankContactDetails && bankContactDetails?.Region }]}
              options={dropDownRegion}
              getData={(value) => {
                setBankContactDetails({
                  ...bankContactDetails,
                  Region: value.value,
                  RegionId: value.id,
                  State: "",
                  City: "",
                  Branch: "",
                  StateId: "",
                  CityId: "",
                  BranchId: ""
                })
                setIconStatus({
                  ...iconStatus,
                  relationshipIcon: "selected"
                })

                if (value.id == "") {
                  setDropDownState([]);
                  setDropDownCities([]);
                  setDropDownBranch([]);
                } else {
                  fetchData(`region/state/${value.id}`, setDropDownState, 'states', "stateId", "name");
                  setDropDownCities([]);
                  setDropDownBranch([]);
                }
                setIsFormDirty(true)
                dispatch(resetFormModified(true));
              }}
            />
          </div>
        </div>
        <div className='form-main-wrapper'>
          <div key={uuidv4()} style={{ marginRight: "45px" }}>
            <DropdownComponent
              width={"300px"}
              title={"State"}
              defaultValue={[{ label: (bankContactDetails?.State === undefined || bankContactDetails?.State === "") ? "Select" : bankContactDetails?.State, value: bankContactDetails && bankContactDetails?.State }]}
              options={dropDownState}
              getData={(value) => {
                setBankContactDetails({
                  ...bankContactDetails,
                  State: value.value,
                  StateId: value.id,
                  City: "",
                  Branch: ""
                })
                setIconStatus({
                  ...iconStatus,
                  relationshipIcon: "selected"
                })
                if (value.id == "") {
                  setDropDownCities([]);
                  setDropDownBranch([]);
                } else {
                  fetchData(`state/cities/${value.id}`, setDropDownCities, 'city', "cityId", "name");
                  setDropDownBranch([]);
                }
                setIsFormDirty(true)
                dispatch(resetFormModified(true));

              }}
            />
          </div>
          <div key={uuidv4()} style={{ marginRight: "45px" }}>
            <DropdownComponent
              width={"300px"}
              title={"City"}
              defaultValue={[{ label: (bankContactDetails?.City === undefined || bankContactDetails?.City === "") ? "Select" : bankContactDetails?.City, value: bankContactDetails && bankContactDetails?.City }]}
              options={dropDownCities}
              getData={(value) => {
                setBankContactDetails({
                  ...bankContactDetails,
                  City: value.value,
                  CityId: value.id,
                  Branch: ""
                })
                if (value.id === "") {
                  setDropDownBranch([]);
                } else {
                  fetchData(`get-branchBy-city/${value.id}`, setDropDownBranch, 'filteredBranch', "branchId", "branchName");
                }
                setIconStatus({
                  ...iconStatus,
                  relationshipIcon: "selected"
                })
                setIsFormDirty(true)
                dispatch(resetFormModified(true));

              }}
            />
          </div>
          <div key={uuidv4()}>
            <DropdownComponent
              width={"300px"}
              title={"Branch"}
              defaultValue={[{ label: (bankContactDetails?.Branch === undefined || bankContactDetails?.Branch === "") ? "Select" : bankContactDetails?.Branch, value: bankContactDetails && bankContactDetails?.Branch }]}
              options={dropDownBranch}
              getData={(value) => {
                setBankContactDetails({
                  ...bankContactDetails,
                  Branch: value.value,
                  BranchId: value.id,
                })
                setIconStatus({
                  ...iconStatus,
                  relationshipIcon: "selected"
                })
                setIsFormDirty(true)
                dispatch(resetFormModified(true));
              }}
            />
          </div>
        </div>
        <div>
          <ButtonComponent
            title={formDataEdit ? "Update" : 'Add'}
            height={"50px"}
            width={"150px"}
            backgroundColor={"#0055D4"}
            color={"white"}
            margin={"35px 0px 0px 8px"}
            className={"button-component-hover common-btn"}
            handleClick={() => {
              handelAdd()
            }}
          />
        </div>
        <div className={"Company-DetailsGrid"}>
          <BankRelationship
            bankContactDetails={bankRelationshipMain}
            retriveData={retriveData}
            showmodal={(val: any, index: any) => {
              setShowDeleteModel(true);
              setDeleteIndex(index);
              setCurrentDeletVal(val);
            }}
          />
        </div>
        <div className='footer-company'>
          <div >
            {/* <ButtonComponent
              title={"Cancel"}
              height={"50px"}
              width={"150px"}
              margin={"0px 8px"}
              backgroundColor={"#888888"}
              color={"white"}
              className={"button-component-hover cancel"}
              handleClick={isFormModified ? () => { setShowChangesModal(true) } : returnGird}
            /> */}


            <ButtonComponent
              title={isEditMode ? "Update" : "Next"}
              height={"50px"}
              width={"150px"}
              backgroundColor={"#0055D4"}
              color={"white"}
              margin={"0px 8px"}
              handleClick={next}
              disabled={bankRelationshipMain && bankRelationshipMain?.length === 0 || isFormDirty}
              className={bankRelationshipMain && bankRelationshipMain?.length === 0 || isFormDirty ? "button-component disabled" : "button-component-hover common-btn"}
            />
          </div>
        </div>
      </div>
      {
        showAlertBox &&
        <div className='alert-warp'>
          <AlertBox type={showType} message={showMessage} />
        </div>

      }
      {
        showChangesModal &&
        <Changesmodal
          closeModal={() => setShowChangesModal(false)}
          handleClose={() => {
            setShowChangesModal(false);
          }}
          leavePage={() => returnGird()} />
      }
      {
        showDeleteModel &&
        <div >
          <DeleteModal
            getDelete={() => { DirectorInfoDelete() }}

            clearValue={(value) => {
              setShowDeleteModel(value);
              setCurrentDeletVal({})
            }}
            modelType={"grid-delete"}
          />
        </div>
      }
    </div>
  )
})
export default BankRelationshipManager
