import React, { useState } from 'react'
import MainPageCard from '../../../components/common-component/cards/main-page-card'
import NavigateHeader from '../../../components/common-component/navigate-header'
import "../../../styles/accounts/accounts-main.scss"
import moment from 'moment'
import { ReactComponent as Tally } from "../../../assets/icons/Tally.svg"
import ButtonComponent from '../../../components/common-component/form-elements/button-component'
import IconButton from '../../../components/common-component/form-elements/icon-button'

function TallyMain() {

    const [accountsSoftware, setAccountsSoftware] = useState<string>("Tally")
    const [previousReleaseData, setPreviousReleaseData] = useState<any>([])
    const [accountsData, setAccountsData] = useState<any>({
        syncdata: "",
        fromDate: "",
        toDate: ""
    })
    const todayDate = moment(new Date()).format('YYYY-MM-DD');
    const [mindate, setMindate] = useState<string>(todayDate);

    const handleFromDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        // setMindate(moment(e.target.value).format("YYYY-MM-DD"))

        const fromDate = e.target.value;

        const currentDate = moment().format('YYYY-MM-DD'); // Get the current date

        if (fromDate.length > 8) {
            const year = fromDate.substring(0, 4);
            if (parseInt(year, 10) > 2100) {
                return;
            }
            if (parseInt(year) > 999) {
                if (parseInt(year, 10) < 1900) {
                    return;
                }
            }
        }
        setAccountsData((prevsubscriptionData: any) => ({
            ...prevsubscriptionData,
            from: fromDate,
            to: ""
        }));
    };

    const handleToDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {

        const toDate = e.target.value;
        const currentDate = moment().format('YYYY-MM-DD'); // Get the current date

        if (toDate.length > 8) {
            const year = toDate.substring(0, 4);
            if (parseInt(year, 10) > 2100) {
                return;
            }
            if (parseInt(year) > 999) {
                if (parseInt(year, 10) < parseInt((accountsData.fromDate).substring(0, 4))) {
                    return;
                }
            }
        }
        setAccountsData((prevsubscriptionData: any) => ({
            ...prevsubscriptionData,
            to: toDate
        }));

    };

    const handleDownloadTallyFile = async () => {
        try {
            const fileUrl = '/assets/tally/sample.pdf'; // Change the path accordingly
            const response = await fetch(fileUrl);

            if (!response.ok) {
                throw new Error(`Failed to download file. Status: ${response.status}`);
            }

            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;
            link.download = 'sample.pdf';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    };

    return (
        <>
            <MainPageCard>
                <div className='navigater sub-navigator'>
                    <NavigateHeader
                        style={{ cursor: "default" }}
                        firstValue={"Organisation"}
                        secondValue={"Accounting Integration"}
                        navigatePage={() => { }}
                    />
                </div>

                <div className={"tally-Main"}>
                    <div className={"accounts-main-area"}>
                        <h5 className={"accounts-sub-area"}>{"Accounting Tool Integration"}</h5>
                        <p>{"Which accounting tool do you use?"}</p>
                    </div>
                    <div className={"accounts-software-list"}>
                        <div className={accountsSoftware === 'Tally' ? 'selected' : ''} onClick={() => { setAccountsSoftware("Tally") }}>{"Tally"}</div>
                        <div className={accountsSoftware === 'SAP' ? 'selected' : ''} onClick={() => { setAccountsSoftware("SAP") }} >{"SAP"}</div>
                    </div>
                    <div className={"accounts-status-filter-area"}>
                        <div className={"accounts-status"}>{"Account integration Status: "}<span>{"Incomplete"}</span></div>

                        <div className={"sync-settings-main"}>
                            <div className={"sync-settings"}>
                                <input type="checkbox" id="sync" name="sync" onChange={() => { }} />
                                <label htmlFor="sync">Sync</label>
                            </div>

                            <div>{"From Date"}<span>*</span></div>
                            <div>
                                <input
                                    type='date'
                                    className='date-picker'
                                    value={accountsData.fromDate}
                                    // min={todayDate}
                                    // max={moment(todayDate).add(1, 'years').format('YYYY-MM-DD')}
                                    onChange={handleFromDateChange}
                                />
                            </div>
                            <div>{"To Date"}<span>*</span></div>
                            <div>
                                <input
                                    type='date'
                                    className='date-picker'
                                    min={mindate}
                                    max={moment(mindate).add(1, 'years').format('YYYY-MM-DD')}
                                    value={accountsData.toDate}
                                    onChange={handleToDateChange}
                                />
                            </div>
                        </div>
                    </div>

                    {
                        accountsSoftware === "Tally" ?
                            <>
                                <div className={"tally-file-area"}>
                                    <div className={"tally-information"}>
                                        <Tally />
                                        <p>{"Sync invoices, vendor details, payment details between Fingertip and Tally."}</p>
                                        <p><b>Supported Versions :</b>Tally ERP 9 & Tally Prime</p>
                                        <b>Version 3.0</b>
                                    </div>

                                    <div>
                                        <ButtonComponent
                                            title={'Download Latest .tcp'}
                                            height={"40px"}
                                            width={"200px"}
                                            backgroundColor={"#0055D4"}
                                            color={"white"}
                                            margin={"0px 8px"}
                                            disabled={false}
                                            className={"button-component-hover common-btn"}
                                            handleClick={handleDownloadTallyFile}
                                        />
                                    </div>
                                </div>

                                {
                                    previousReleaseData.length !== 0 ? "" :

                                        <div className={"tally-release-version"}>
                                            <div className={"tally-previous-release"}>{"Previous Release"}
                                            </div>

                                            <div className='view-table-component' style={{ marginTop: "10px", marginBottom: "20px", height: "205px", overflowY: "auto" }} >

                                                <table className="myTable">
                                                    <thead style={{ zIndex: "1" }}>

                                                        <th>Versions</th>
                                                        <th>Information</th>
                                                        <th style={{ width: "150px" }}>Download .tcp</th>

                                                    </thead>
                                                    <tbody>
                                                        {
                                                            previousReleaseData?.map((data: any, index: number) => (
                                                                <tr className={"main-body-header"} style={{ marginTop: "0px" }} key={index}>
                                                                    <td>{data.versions}</td>
                                                                    <td style={{ width: "60%" }}>{data.information}</td>
                                                                    <td style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                                        <IconButton
                                                                            iconName={"Download"}
                                                                            height={"35px"}
                                                                            width={"35px"}
                                                                            fontSize={"22px"}
                                                                            margin={"0px 8px"}
                                                                            color={"white"}
                                                                            backgroundColor={"#0055D4"}
                                                                            hover={true}
                                                                            disabled={false}
                                                                            handleClick={() => { }}
                                                                        />
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                }
                            </>
                            : ""
                    }

                </div>
                <div className="save-button-tally-main">
                    <ButtonComponent
                        title={'Save'}
                        height={"40px"}
                        width={"150px"}
                        backgroundColor={"#0055D4"}
                        color={"white"}
                        margin={"0px 8px"}
                        disabled={false}
                        className={"button-component-hover common-btn"}
                        handleClick={() => { }}
                    />
                </div>
            </MainPageCard>
        </>
    )
}

export default TallyMain 