import React from 'react'
import "../../../styles/payable/vendor/vendor-grid.scss"
import { RxCross1 } from 'react-icons/rx';
import ButtonComponent from '../../common-component/form-elements/button-component';
import Paid from '../../../../src/assets/images/success-img.png'
import failed from '../../../../src/assets/images/failure-img.png'
import { format } from "date-fns/esm";
interface Styles {
    closeModal: () => void;
    leavePage?: () => void;
    handleOk: () => void;
    message?: string;
    type?: string;
    status: string;
    transactionsID: string;

}

export default function PaymentResultModal({ closeModal,
    leavePage,
    type,
    transactionsID,
    handleOk,
    message,
    status
}: Styles) {
    let datenow = new Date()
    function formatDate(dateString: any) {
        const originalDate = new Date(dateString)
        const formattedDateTime = format(originalDate, "dd-MMM-yyyy HH:mm:ss");
        return formattedDateTime;
    }
    return (
        <div className={"payment-result-modal"}>
            <div className={"container-payment-result"}>
                {/* <div className={"close-icon"}>
                    <span className={"cross-icon"}>
                        <RxCross1 style={{ fontSize: "40px" }} onClick={closeModal} />
                    </span>
                </div> */}
                <div className={`payment-result-image ${status === 'Paid' ? 'paid' : 'failed'}`}>
                </div>
                {/* <div className={'payment-result-modal-sub'}> */}
                {/* {
                        status === 'Paid' ?
                            <img src={Paid} className='result-image' alt='Paid' /> :
                            <img src={failed} className='result-image' alt='failed' />
                    } */}
                <div className={'changes-title'}>
                    <div className={"title"}> {type}</div>
                    <div className={"sub-title"}> {message}</div>

                    <div className={"tranction-details"}>
                        <p>Details of transction are included below:</p>
                        <p><b>Reference ID :</b> {transactionsID} </p>
                        <p><b>Date and Time :</b> {formatDate(datenow)}</p>
                    </div>

                    <div className={"buttons"} >
                        <ButtonComponent
                            title={"Ok"}
                            height={"40px"}
                            width={"80px"}
                            backgroundColor={"#0055D4"}
                            color={"white"}
                            handleClick={closeModal}
                            className={"button-component common-btn"}
                        />
                    </div>
                </div>
            </div>
        </div>

    )
}

