import React, { useEffect, useState } from 'react'
import PaymentCard from '../../../../src/assets/images/virtual_card.png';
import RadioOrCheckbox from '../../common-component/form-elements/radio-or-checkbox';
import useAxiosPrivate from '../../../services/hooks/useaxios-private';
import Changesmodal from '../../common-component/modals/changes-modal';
import AlertBox from '../../common-component/alert-box';
import Loading from '../../common-component/modals/loading-screen';
import { useDispatch } from "react-redux";
import { resetFormModified } from "../../../redux/action";
import { BsPlusLg } from 'react-icons/bs';
import { v4 as uuidv4 } from 'uuid';
import VirtualCardForm from '../../common-component/modals/virtualcard-form-model';
import { format } from "date-fns";
import MainPageCard from '../../common-component/cards/main-page-card';
import DropdownComponent from '../../common-component/form-elements/dropdown-component';
import InputComponent from '../../common-component/form-elements/input-component';
import ButtonComponent from '../../common-component/form-elements/button-component';
import OtpVerifyModal from '../../common-component/modals/otp-verify-modal';
import useAuth from '../../../services/hooks/useauth';
import VendorCardPaymentApproval from './vendor-paymentapproval';
import '../../../styles/approval/virtualcard-approval/form.scss'
import { isDisabled } from '@testing-library/user-event/dist/utils';
import moment from 'moment';


interface Props {
    virtualCard: (val: string) => void;
    isEditMode: boolean;
    // virtualCard: (val: string) => void;
    getEditData?: ((name: string, data: any) => void) | undefined;
    getDelete?: () => void;
    modeFunction: (val: boolean) => void;
    getEditMode: (value: boolean) => void;
    editData: any
}
export interface userValidationRulesInterface {
    [key: string]: {
        [key: string]: {
            regex: RegExp | string;
            field: string;
            shouldNotBe: string;
        };
    }
}


export default function Form({ virtualCard, isEditMode, modeFunction, editData, getEditMode }: Props) {
    const axiosPrivate = useAxiosPrivate();
    const todayDate = moment(new Date()).format('YYYY-MM-DD');
    const [mindate, setMindate] = useState<any>();
    const [makePayment, setMakePayment] = useState<boolean>(false)
    const [showSuccessfullyModal, setShowSuccessfullyModal] = useState<boolean>(false)
    const [userDataErrors, setUserDataErrors] = useState<Record<string, boolean>>({});
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [showType, setShowType] = useState("warning") // error message showType
    const [showMessage, setShowMessage] = useState<string>("") // error message showMessage
    const [showAlertBox, setShowAlertBox] = useState(false) // error message ShowAlertBox
    const [showChangesModal, setShowChangesModal] = useState(false);
    const [showChangesModalNavigate, setShowChangesModalNavigate] = useState(false);
    const [isFormDirty, setIsFormDirty] = useState(false);
    const [navigatePage, setNavigatePage] = useState("");
    const [navigateIsEdited, setNavigateIsEdited] = useState(false);
    const [componentNavigator, setComponentNavigator] = useState<boolean>(false);
    const [employeeNameDD, setEmployeeNameDD] = useState<any>([])
    const [vendorDetailsDD, setVendorDetailsDD] = useState<any>([])
    const [primaryCardDD, setprimaryCardDD] = useState<any>([])
    const [employeeDetailsAll, setEmployeeDetailsAll] = useState<any>([])
    const [vendarValueAll, setVendarValueAll] = useState<any>([])
    const [currrenSelectedVender, setcurrrenSelectedVender] = useState<any>({})
    const [currrenSelectedEmployee, setcurrrenSelectedEmployee] = useState<any>({})
    const [vendorCurrentDate, setVendorCurrentDate] = useState("");
    const [otpVerification, setOtpVerification] = useState<boolean>(false);
    const [isLocked, setIsLocked] = useState<boolean>(false);
    const [isDisabled, setIsDisabled] = useState<boolean>(false)
    const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);

    const [approvalStatus, setApprovalStatus] = useState<any>({
        status: "",
        declineRemark: ''
    });

    const [currentDate, setCurrentDate] = useState("");
    const [vendorForm, setVendorForm] = useState<any>({
        request: "Vendor's Payment",
        date: "",
        particulars: "",
        primaryAccountNo: "",
        primaryID: "",
        Transaction: "",
        amount: "",
        Validfrom: "",
        Validupto: "",
        remarks: "",
        token: "",
        id: ""
    })

    const [virtualForm, setVirtualForm] = useState<any>({
        request: "Employee",
        date: currentDate,
        vendorName: '',
        employeeName: '',
        unicEmployeeId: '',
        unicVendorId: '',
        particulars: '',
        primaryAccountNo: '',
        primaryID: '',
        Transaction: "",
        amount: '',
        Validfrom: "",
        Validupto: "",
        remarks: '',
        token: "",
        id: '',
        status: '',
    })

    const [empolyeeDetails, setEmpolyeeDetails] = useState({
        name: '',
        cardNumber: '',
        validTill: '',
        amount: '',
    });

    const [changeFormEmployee, setChangeFormEmployee] = useState<any>("Employee")
    const dispatch = useDispatch();
    const { auth } = useAuth();

    const userValidationRules: userValidationRulesInterface = {
        virtualCard_form: {
            declineRemark: {
                regex: (approvalStatus.status === "Declined" && isEditMode) ? /^[^\s].*/ : "",
                field: (approvalStatus.status === "Declined" && isEditMode) ? "mandatory" : "",
                shouldNotBe: ""
            },
        }
    }



    useEffect(() => {
        employeegetdata()
        primaryData()
    }, [])

    useEffect(() => {
        setNavigateIsEdited(true)
    }, [virtualForm])
    // date function
    useEffect(() => {
        if (!isEditMode) {
            const interval = setInterval(() => {
                const newDate = new Date();
                // Reset time to midnight 
                const onlydate = format(newDate, "dd-MM-yyyy")
                setCurrentDate(onlydate);
            }, 1000);  // Update every second

            return () => clearInterval(interval);
        }
    }, []);


    useEffect(() => {
        if (isEditMode && editData) {
            if (Object.keys(editData).length > 0) {
                setVirtualForm({
                    request: editData.isEmployeeRecord ? "Employee" : "Vendor's Payment",
                    date: editData.date,
                    vendorName: editData.vendorName,
                    unicVendorId: editData.unicVendorId,
                    employeeName: editData.employeeName,
                    unicEmployeeId: editData.unicEmployeeId,
                    particulars: editData.particulars,
                    primaryAccountNo: editData.primaryAccountNo,
                    primaryID: editData.primaryID,
                    amount: editData.amount,
                    Transaction: editData.Transaction,
                    Validfrom: editData.Validfrom,
                    Validupto: editData.Validupto,
                    remarks: editData.remarks || "",
                    status: editData.status,
                    id: editData.id,
                    token: editData.token,
                })
                setApprovalStatus({
                    status: editData.status === "Pending Approval" ? "" : editData.status,
                    declineRemark: editData?.reason_for_declining
                })
            }
            setChangeFormEmployee(editData.isEmployeeRecord ? "Employee" : "Vendor's Payment");
            if (editData.isEmployeeRecord === true) {
                employeegetdata()
                VendorDropdown(editData.unicVendorId);
            }
        } else {
            return;
        }
    }, [editData]);


    // cancel button function
    function returnGird() {
        if (isFormDirty) {
            setShowChangesModal(true)
        } else {
            setVirtualForm({
                request: "Employee",
                date: "",
                employeeName: '',
                vendorName: '',
                particulars: '',
                primaryAccountNo: '',
                amount: '',
                Validfrom: "",
                Transaction: "",
                Validupto: "",
                remarks: '',
                status: '',
            })
            modeFunction(false)
            virtualCard("grid")
            getEditMode(false)
        }
    }

    const updatedIsValueValidAll: Record<string, boolean> = {};
    const validateForm = (): boolean => {
        const updatedFieldValidity: Record<string, boolean> = {};
        Object.keys(userValidationRules.virtualCard_form).forEach((field) => {
            const rule = userValidationRules.virtualCard_form[field];
            const value = approvalStatus[field];
            // Check if the field value meets the conditions based on rule.field and rule.shouldNotBe
            const isValueValid = (rule.field === "mandatory") ? (value !== rule.shouldNotBe) : true;
            // If a regular expression is provided, test the field value against it
            const isRegexValid = rule.regex !== "" ? new RegExp(rule.regex).test(value) : true;
            updatedFieldValidity[field] = !isValueValid || !isRegexValid;

            updatedIsValueValidAll[field] = !isValueValid
        });
        setUserDataErrors(updatedFieldValidity);
        // Check if any field has validation errors
        const isFormValid = Object.keys(updatedFieldValidity).every((keys) => {
            return !updatedFieldValidity[keys]
        });
        return isFormValid;
    };

    const VendorDropdown = async (id: any) => {
        // "id": 1,
        // "vendorCode": "12345545",
        // "gstin": "07AAACB0195J1ZS",
        // "accountNumber": "59648100003096",
        // "ifscCode": "BARB0COIMBA"
        try {
            const response = await axiosPrivate.get(`/virtual-card/vendor-details/${id}`);
            setcurrrenSelectedVender(response.data.vendorDetails);
        } catch (error) {
        }
    };

    // warningMsg 
    const clearAleart = (status: any) => {
        const timer = setTimeout(() => {
            setShowAlertBox(false)
            setShowMessage("");
            if (status === 200) {
                virtualCard("grid")
                // getEditMode(false)
            }
            clearTimeout(timer);
        }, 5000);
    }

    const clearAleartemployeeName = (status: any) => {
        const timer = setTimeout(() => {
            setShowAlertBox(false)
            setShowMessage("");
            clearTimeout(timer);
        }, 5000);
    }

    const successfullymessage = () => {
        virtualCard("grid")
    }

    // save button and send  api 
    function virtualCardSave() {
        const isValid = validateForm();
        if (isValid) {
            setShowSuccessfullyModal(true)
        }
        else {
            setShowAlertBox(true);
            setShowType("danger")
            setShowMessage(Object.values(updatedIsValueValidAll).every((values) => !values) ?
                "Some of the field(s) are not in required format." : "Mandatory field(s) should not be left blank.")
            clearAleart('');
        }
    }

    // primary Number Card dropdown get API  method
    const primaryData = async () => {
        try {
            const response = await axiosPrivate.get('/virtual-card/card/dropdown/get');
            const primaryGroupDropDownValues = response.data.getCardDetails.map((item: any) => ({
                label: item,
                value: item,
                id: item,
                primaryID: item.id,
            }
            ));
            const sortedDataPrimary = [...primaryGroupDropDownValues].sort((a, b) => a.label.localeCompare(b.label));
            // const [primsryCardDD, setprimsryCardDD] = useState<any>([])
            setprimaryCardDD(sortedDataPrimary)
        } catch (error: any) {

        }
    }

    // employee  get method API 
    const employeegetdata = async () => {
        try {
            const response = await axiosPrivate.get('/virtual-card/employee-details/get');
            const groupdropdownValues = response.data.employeeDetails.map((item: any) => ({
                label: item.employeeName,
                value: item.employeeName,
                id: item.employeeId,
                unicEmployeeId: item.id,

            }
            ));
            const sortedData = [...groupdropdownValues].sort((a, b) => a.label.localeCompare(b.label));
            setEmployeeNameDD(sortedData);
            const groupdropdownValuesVendor = response.data.vendorDetails.map((item: any) => ({
                label: item.vendorName,
                value: item.vendorName,
                id: item.id,
                unicVendorId: item.id,
            }
            ));
            const sortedDataVendor = [...groupdropdownValuesVendor].sort((a, b) => a.label.localeCompare(b.label));
            setVendorDetailsDD(sortedDataVendor)
            setEmployeeDetailsAll(response.data.employeeDetails)
            setVendarValueAll(response.data.vendorDetails)
            if (isEditMode && editData.unicEmployeeId) {
                const foundedObj = response.data.employeeDetails.find((Obj: any) => Obj.id
                    === editData.unicEmployeeId);
                setcurrrenSelectedEmployee(foundedObj);

                // const foundedVendorObj = response.data.vendorDetails.find((Obj: any) => Obj.id
                //     === editData.unicVendorId);
                // setcurrrenSelectedVender(foundedVendorObj);
            }
            setVendarValueAll(response.data.vendorDetails)
        } catch (error) {
            setEmployeeDetailsAll([])
        }
    };


    // employee post method API 
    const employeeSave = async () => {
        try {
            const res = await axiosPrivate.post('/virtual-card/employee-details/add', employeeDetailsAll);
            if (res.status === 200) {
                setShowAlertBox(true)
                setShowType("success")
                setShowMessage(res.data.message)
                clearAleartemployeeName(res.status)
                setComponentNavigator(false)
                employeegetdata()
            }
        } catch (error: any) {

        }
    }
    const adminDataDelete = (id: any) => {
        if (id) {
            axiosPrivate.delete(`/virtual-card/employee-details/delete/${id}`)
                .then(response => {
                    if (response.status === 200) {
                        setShowAlertBox(true);
                        setShowType("success")
                        setShowMessage(response.data.message)
                        clearAleart(response.status)
                    }
                })
                .catch(error => {
                    setShowAlertBox(true);
                    setShowType("danger")
                    setShowMessage(error.response.data.error)
                    clearAleart("")
                });
        } else {
            setShowAlertBox(true);
            setShowType("danger")
            setShowMessage("The selected record(s) have been deleted.")
            clearAleart("")
        }
    }
    // submit tha form page api  patch 
    const updateData = async () => {
        let valid = validateForm();
        setIsLoading(true)
        if (valid) {
            try {
                const res = changeFormEmployee === "Employee" ? await axiosPrivate.patch(`/virtual-card/approval-form/update/${virtualForm.id}`, approvalStatus) : await axiosPrivate.patch(`/virtual-card/approval-form/update/${vendorForm.id}`, approvalStatus)
                if (res.status === 200) {
                    setIsLoading(false)
                    setShowAlertBox(true)
                    setShowType("success")
                    setShowMessage(res.data.message)
                    setIsFormDirty(false)
                    clearAleart(res.status)
                    dispatch(resetFormModified(false));
                    // setApprovalStatus({
                    //     status: "Approve",
                    //     declineRemark: ""
                    // });

                }
            } catch (error: any) {
                setIsLoading(false)
                if (error.status === 500) {
                    setShowAlertBox(true);
                    setShowType("danger")
                    setShowMessage(error.response.data.error)
                    clearAleart("")
                } else if (error.code === "ERR_NETWORK") {
                    setShowAlertBox(true);
                    setShowType("danger")
                    setShowMessage(error.message)
                    clearAleart("")
                } else {
                    setShowAlertBox(true);
                    setShowType("danger")
                    setShowMessage(error.response.data.error)
                    clearAleart("")
                }
            }
        } else {
            setIsLoading(false)
            setShowAlertBox(true);
            setShowType("danger")
            setShowMessage(Object.values(updatedIsValueValidAll).every((values) => !values) ?
                "Some of the field(s) are not in required format." : "Mandatory field(s) should not be left blank.")
            clearAleart('');
        }
    }


    async function resendOtp() {
        let valid = validateForm()
        if (valid) {
            try {
                const res = await axiosPrivate.post('companyadmin/bank/resend-otp-update/approval', {
                    approvedBy: auth.token,

                });
                if (res.status === 200) {
                    setShowAlertBox(true)
                    setShowType("success")
                    setShowMessage(res.data.message)
                    clearAleart("")
                    // updateData()
                }
            } catch (error: any) {
                setShowAlertBox(true);
                setShowType("danger")
                setShowMessage(error.response.data.error)
                clearAleart("")

            }
        }

    }

    const otpsend = async () => {
        setIsLoading(true)
        try {
            let res = await axiosPrivate.post('companyadmin/bank/otp-update/approval', {
                approvedBy: auth.token,
            });
            if (res.status === 200) {
                setIsLoading(false)
                setShowAlertBox(true)
                setShowType("success")
                setOtpVerification(true)
                setShowMessage(res.data.message)
                clearAleart("")

            }
        } catch (error: any) {
            setIsLoading(false)
            setShowAlertBox(true);
            setShowType("danger")
            setShowMessage(error.response.data.error)
            clearAleart("")

        }
    }

    const handleFromDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newFromDate = new Date(event.target.value);
        setVirtualForm((prevState: any) => ({
            ...prevState,
            Validfrom: newFromDate,
        }));

        if (virtualForm.Validupto && newFromDate > virtualForm.Validupto) {
            setVirtualForm((prevState: any) => ({
                ...prevState,
                Validupto: "",
            }));
        }
        setIsFormDirty(true)

        dispatch(resetFormModified(true));
    };

    const handleToDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newToDate = new Date(event.target.value);
        setVirtualForm((prevState: any) => ({
            ...prevState,
            Validupto: newToDate,
        }));
        setIsFormDirty(true)

        dispatch(resetFormModified(true));
    };


    return (
        <>

            <MainPageCard>

                <div className='header-main' style={{ marginBottom: "52px" }}>
                    <div className='navigater'>
                        <div className='navigate-header-component'>
                            <ul className='bread-crumbs'  >
                                <li style={{ cursor: "pointer" }}
                                    onClick={() => {
                                        returnGird()
                                    }}><span>Virtual Card</span>

                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className={virtualForm.request === "Employee" ? 'virtual-employeeName-main' : "virtual-approval-vendor-main"} >
                    <div className='virtualCard-approval-form' >
                        <div className='approval-title-virtual'>
                            <p>This new request required your approval.</p>
                        </div>
                        <div className='approval-radio-input'>
                            <div className='approval-radio-btn'>
                                <RadioOrCheckbox
                                    value={"Approved"}
                                    type={"radio"}
                                    name={"Approve"}
                                    label={"Approve"}
                                    disabled={false}
                                    margin={"0px 30px 0px 0px"}
                                    checkedValue={approvalStatus.status}
                                    getVal={(val) => {
                                        setIsFormDirty(true)
                                        setApprovalStatus((prevState: any) => ({
                                            ...prevState,
                                            status: val,
                                            declineRemark: ""
                                        }));
                                        // setIsButtonDisabled(false)
                                        setUserDataErrors({})
                                        dispatch(resetFormModified(true));
                                        setIsDisabled(true)
                                    }}
                                />
                                <div>
                                    <RadioOrCheckbox
                                        value={"Declined"}
                                        type={"radio"}
                                        name={"Decline"}
                                        label={"Decline"}
                                        disabled={false}
                                        checkedValue={approvalStatus.status}
                                        getVal={(val) => {
                                            setIsFormDirty(true)
                                            setApprovalStatus((prevState: any) => ({
                                                ...prevState,
                                                status: val,
                                            }));
                                            // setIsButtonDisabled(false)
                                            dispatch(resetFormModified(true));
                                            setIsDisabled(true)
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='decline-reason'>
                        <div className='reason-area'
                            style={
                                approvalStatus.status === "Declined" ?
                                    { pointerEvents: "auto", opacity: 1 } : { pointerEvents: "none", opacity: 0.5 }
                            }>
                            <InputComponent
                                height={"60px"}
                                width={"90%"}
                                margin={"10px 60px 30px 0px"}
                                padding={"0px 0px 0px 10px"}
                                border={userDataErrors.declineRemark ? "1px solid red" : "1px solid #A9C3DC"}
                                borderRadius={"0px"}
                                backgroundColor={"white"}
                                color={"black"}
                                maxLength={250}
                                type={"text"}
                                disabled={approvalStatus.status === "Declined" ? false : true}
                                inputTitle={"Reason for Declining Request"}
                                required={true}
                                placeHolder={"Reason"}
                                inputValue={approvalStatus.declineRemark}
                                getUser={(value: any) => {
                                    setIsFormDirty(true)
                                    setApprovalStatus((prevState: any) => ({
                                        ...prevState,
                                        declineRemark: value,
                                    }));
                                    dispatch(resetFormModified(true));
                                    setIsDisabled(true)

                                }}
                            />
                        </div>
                    </div>
                    <div className='Request-flex' style={isEditMode ?
                        { pointerEvents: "none", opacity: 0.5 } : { pointerEvents: "auto", opacity: 1 }}>
                        <div className='radio-input virtualcard-radio-input'>
                            <div className='main-Request-virtual' >
                                <div className='Request-name'>Request on behalf of</div>
                                <div className='radio-btn-flex-virtual'>
                                    <div style={isEditMode ?
                                        { pointerEvents: "none", opacity: 0.5, width: "130px" } : { pointerEvents: "auto", opacity: 1, width: "130px" }}>

                                        <RadioOrCheckbox
                                            value={"Employee"}
                                            type={"radio"}
                                            name={"Employee"}
                                            checkedValue={virtualForm.request}
                                            getVal={(val: any) => {
                                                setIsFormDirty(false)
                                                dispatch(resetFormModified(true));
                                                if (!isFormDirty) {
                                                    setChangeFormEmployee("Employee");
                                                    setVirtualForm((prevState: any) => ({
                                                        ...prevState,
                                                        request: "Employee",
                                                    }));
                                                } else {
                                                    setShowChangesModalNavigate(true)
                                                    setNavigatePage("Employee")
                                                }
                                            }}
                                        />
                                    </div>

                                    <div style={isEditMode && editData.isEmployeeRecord ?
                                        { pointerEvents: "none", opacity: 0.5 } : { pointerEvents: "auto", opacity: 1 }}>
                                        <RadioOrCheckbox
                                            value={"Vendor's Payment"}
                                            name={"Vendor's Payment"}
                                            type={"radio"}
                                            checkedValue={virtualForm.request}
                                            getVal={(val: any) => {
                                                setIsFormDirty(false)
                                                dispatch(resetFormModified(true));
                                                if (!isFormDirty) {
                                                    setChangeFormEmployee("Vendor's Payment");
                                                    setVirtualForm((prevState: any) => ({
                                                        ...prevState,
                                                        request: "Vendor's Payment",
                                                    }));
                                                } else {
                                                    setShowChangesModalNavigate(true)
                                                    setNavigatePage("Vendor's Payment")
                                                }
                                                // setChangeFormEmployee("Vendor's Payment");
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='date-regnumber' style={isEditMode ? { display: "flex" } : { display: "block" }}>
                                <div style={{ margin: "0px" }}>
                                    {
                                        isEditMode &&
                                        <p className='requestNumber'>
                                            Request Number : {editData.requestNumber}
                                        </p>
                                    }</div>
                                <div className='virtualcard-date'>
                                    <p> Date:  {
                                        isEditMode ?
                                            (changeFormEmployee == "Employee" ? virtualForm.date : vendorForm.date)
                                            :
                                            (changeFormEmployee == "Employee" ? currentDate.toLocaleString() : vendorCurrentDate)
                                    }</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        changeFormEmployee === "Employee" &&
                        <div style={{ height: "auto" }}>
                            <div className='main-form-card'>

                                <div className='form-main-details' style={isEditMode ?
                                    { pointerEvents: "none", opacity: 0.5 } : {}}  >
                                    <div className='form-main-employee'>
                                        <div className='employee-main-form'>
                                            <div className='form-title-inputbox'>
                                                <div className='form-title-vitrual'>
                                                    <label htmlFor="">Employee Name<span className='requrid-status'>*</span></label>
                                                </div>
                                                <div className='dropdown-plus-flex'>
                                                    <div key={uuidv4()} className={userDataErrors.employeeName ? 'setError-virtual-plus' : 'dropdown-user-with-plus'} >
                                                        <div className='dropdown' style={{ width: "246px" }}  >
                                                            <DropdownComponent
                                                                isDisabled={isEditMode ? true : false}

                                                                options={employeeNameDD}
                                                                width='300px'
                                                                getData={(val) => {
                                                                    setIsFormDirty(true)
                                                                    setVirtualForm((prevState: any) => ({
                                                                        ...prevState,
                                                                        employeeName: val.value,
                                                                        unicEmployeeId: val.unicEmployeeId,
                                                                    }));
                                                                    const foundedObj = employeeDetailsAll.find((Obj: any) => Obj.employeeId
                                                                        === val.id);
                                                                    setcurrrenSelectedEmployee(foundedObj);
                                                                    dispatch(resetFormModified(true));
                                                                }}
                                                                defaultValue={[
                                                                    {
                                                                        label: virtualForm.employeeName !== "" ? virtualForm.employeeName : "Select",
                                                                        value: virtualForm.employeeName !== "" ? virtualForm.employeeName : "Select",
                                                                    }
                                                                ]}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='with-plus' onClick={() => {
                                                        setComponentNavigator(true)

                                                    }} >
                                                        <BsPlusLg color='#004E9C' height={"14px"} />
                                                    </div>
                                                </div>

                                                <div className='empolyee-card' >
                                                    <div >
                                                        <div className='card-main' >
                                                            <div className='empolyee-details'>
                                                                <div>
                                                                    <p> Employee ID</p>
                                                                    <p> Department</p>
                                                                    <p> Designation</p>
                                                                    <p> Email ID</p>
                                                                    <p>  Contact Number</p>
                                                                </div>
                                                                <div>
                                                                    <p>: {currrenSelectedEmployee?.employeeId ? currrenSelectedEmployee.employeeId : ""} </p>
                                                                    <p>: {currrenSelectedEmployee?.department ? currrenSelectedEmployee.department : ""} </p>
                                                                    <p>: {currrenSelectedEmployee?.designation ? currrenSelectedEmployee.designation : ""} </p>
                                                                    <p>: {currrenSelectedEmployee?.emailId ? currrenSelectedEmployee.emailId : ""} </p>
                                                                    <p>: {currrenSelectedEmployee?.contactNumber ? currrenSelectedEmployee.contactNumber : ""} </p>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                            <div className='form-title-inputbox' >
                                                <div className='form-title-vitrual'>
                                                    <label htmlFor="">Vendor Name</label>
                                                </div>
                                                <div className={userDataErrors.vendorName ? 'setError-virtual' : 'dropdown-user'} key={uuidv4()}  >
                                                    <div className='dropdown'  >
                                                        <DropdownComponent
                                                            isDisabled={isEditMode ? true : false}

                                                            options={vendorDetailsDD}
                                                            width='300px'
                                                            getData={(val) => {
                                                                setIsFormDirty(true)
                                                                setVirtualForm((prevState: any) => ({
                                                                    ...prevState,
                                                                    vendorName: val.value,
                                                                    unicVendorId: val.unicVendorId,
                                                                }));
                                                                // const foundedObj = vendarValueAll.find((Obj: any) => Obj.id === val.id);
                                                                // setcurrrenSelectedVender(foundedObj);
                                                                dispatch(resetFormModified(true));
                                                            }}
                                                            defaultValue={[
                                                                {
                                                                    label: virtualForm.vendorName !== "" ? virtualForm.vendorName : "Select",
                                                                    value: virtualForm.vendorName !== "" ? virtualForm.vendorName : "Select",
                                                                }
                                                            ]}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='empolyee-card'>
                                                    <div className='vendor-dropdown'>
                                                        <div className='card-main'  >
                                                            <div className='vendor-details'>
                                                                <div>
                                                                    <p> Vendor ID</p>
                                                                    <p> GSTIN</p>
                                                                    <p> Account Number</p>
                                                                    <p> IFSC Code</p>
                                                                </div>
                                                                <div className=''>
                                                                    <p>:  {currrenSelectedVender && currrenSelectedVender.vendorCode
                                                                        ? currrenSelectedVender.vendorCode
                                                                        : ""} </p>
                                                                    <p>: {currrenSelectedVender && currrenSelectedVender.gstin
                                                                        ? currrenSelectedVender.gstin
                                                                        : ""}</p>
                                                                    <p>:  {currrenSelectedVender && currrenSelectedVender.accountNumber
                                                                        ? currrenSelectedVender.accountNumber
                                                                        : ""}</p>
                                                                    <p>:  {currrenSelectedVender && currrenSelectedVender.ifscCode
                                                                        ? currrenSelectedVender.ifscCode
                                                                        : ""}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div>
                                        </div>

                                    </div>
                                    <div className='form-main'>
                                        <div className='Primary-amount-flex'>

                                            <div className='form-title'>
                                                <div className='form-title-vitrual'>
                                                    <label htmlFor="">Primary Account No<span className='requrid-status'>*</span></label>
                                                </div>
                                                <div className={userDataErrors.primaryAccountNo ? 'setError-virtual' : 'dropdown-user'} key={uuidv4()}   >
                                                    <div className='dropdown'  >
                                                        <DropdownComponent
                                                            isDisabled={isEditMode ? true : false}
                                                            options={primaryCardDD}
                                                            width='300px'
                                                            getData={(val) => {
                                                                setIsFormDirty(true)
                                                                setVirtualForm((prevState: any) => ({
                                                                    ...prevState,
                                                                    primaryAccountNo: val.value,
                                                                    primaryID: val.primaryID,

                                                                }));
                                                                dispatch(resetFormModified(true));
                                                            }}
                                                            defaultValue={[
                                                                {
                                                                    label: virtualForm.primaryAccountNo !== "" ? virtualForm.primaryAccountNo : "Select",
                                                                    value: virtualForm.primaryAccountNo !== "" ? virtualForm.primaryAccountNo : "Select",
                                                                }
                                                            ]}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='form-title'   >
                                                <div className='form-title-vitrual'>
                                                    <label htmlFor="">Amount<span className='requrid-status'>*</span></label>
                                                </div>
                                                <InputComponent
                                                    disabled={isEditMode ? true : false}
                                                    height={"40px"}
                                                    width={"300px"}
                                                    padding={"10px 10px"}
                                                    border={userDataErrors.amount ?
                                                        "1px solid red" : "1px solid #A9C3DC"}
                                                    backgroundColor={"white"}
                                                    borderRadius={"0px"}
                                                    type={"mobile"}
                                                    color={"black"}
                                                    placeHolder={"0"}
                                                    inputValue={virtualForm.amount}
                                                    textAlign={"right"}
                                                    maxLength={14}
                                                    getUser={(val: any) => {
                                                        setIsFormDirty(true)
                                                        const onlyNumber = val.replace(/[^0-9]/g, "");
                                                        const parsedValue = parseInt(onlyNumber);
                                                        const formattedValue = isNaN(parsedValue) ? "" :
                                                            parsedValue.toLocaleString('en-IN');
                                                        setVirtualForm((prevState: any) => ({
                                                            ...prevState,
                                                            amount: formattedValue,
                                                        }));
                                                        dispatch(resetFormModified(true));

                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className='Primary-amount-flex'>

                                            <div className='form-title'>
                                                <div className='form-title-vitrual'>
                                                    <label htmlFor="">Particulars<span className='requrid-status'>*</span></label>
                                                </div>
                                                <div className={userDataErrors.primaryAccountNo ? 'setError-virtual' : 'dropdown-user'} key={uuidv4()}   >
                                                    <div className='Particulars-input' >

                                                        <InputComponent
                                                            height={"40px"}
                                                            // width={"850px"} 
                                                            width={"300px"}
                                                            padding={"10px 10px"}
                                                            border={userDataErrors.particulars ?
                                                                "1px solid red" : "1px solid #A9C3DC"}
                                                            backgroundColor={"white"}
                                                            disabled={isEditMode ? true : false}

                                                            borderRadius={"0px"}
                                                            type={"text"}
                                                            color={"black"}
                                                            maxLength={100}
                                                            placeHolder={"Enter Particulars of Invoice"}
                                                            inputValue={virtualForm.particulars}
                                                            getUser={(val: any) => {
                                                                setIsFormDirty(true)
                                                                setVirtualForm((prevState: any) => ({
                                                                    ...prevState,
                                                                    particulars: val,
                                                                }));
                                                                dispatch(resetFormModified(true));

                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='form-title'   >
                                                <div className='form-title-vitrual'>
                                                    <label htmlFor="">Number of Transaction<span className='requrid-status'>*</span></label>
                                                </div>
                                                <InputComponent
                                                    disabled={isEditMode ? true : false}
                                                    height={"40px"}
                                                    width={"300px"}
                                                    padding={"10px 10px"}
                                                    border={userDataErrors.Transaction ?
                                                        "1px solid red" : "1px solid #A9C3DC"}
                                                    backgroundColor={"white"}
                                                    borderRadius={"0px"}
                                                    type={"mobile"}
                                                    color={"black"}
                                                    placeHolder={"Enter Amount"}
                                                    inputValue={virtualForm.Transaction}
                                                    maxLength={14}
                                                    getUser={(val: any) => {
                                                        setIsFormDirty(true)
                                                        const onlyNumber = val.replace(/[^0-9]/g, "");

                                                        setVirtualForm((prevState: any) => ({
                                                            ...prevState,
                                                            Transaction: onlyNumber,
                                                        }));
                                                        dispatch(resetFormModified(true));

                                                    }}
                                                />
                                            </div>
                                        </div>





                                        {/* <div className='Particulars-inputbox' >
                                        <div className='heading'>
                                            <label htmlFor="">Particulars<span className='requrid-status'>*</span></label>
                                        </div>
                                        <div className='Particulars-input' >

                                            <InputComponent
                                                height={"40px"}
                                                // width={"850px"} 
                                                width={windowWidth > 1870 ? "850px" : windowWidth > 1500 ? "750px" : windowWidth > 1190 ? "500px" : "350px"}
                                                padding={"10px 10px"}
                                                border={userDataErrors.particulars ?
                                                    "1px solid red" : "1px solid #A9C3DC"}
                                                backgroundColor={"white"}
                                                disabled={isEditMode ? true : false}

                                                borderRadius={"0px"}
                                                type={"text"}
                                                color={"black"}
                                                maxLength={100}
                                                placeHolder={"Enter Particulars of Invoice"}
                                                inputValue={virtualForm.particulars}
                                                getUser={(val: any) => {
                                                    setIsFormDirty(true)
                                                    setVirtualForm((prevState: any) => ({
                                                        ...prevState,
                                                        particulars: val,
                                                    }));
                                                    dispatch(resetFormModified(true));

                                                }}
                                            />
                                        </div>
                                    </div> */}

                                        <div className='date-main' >
                                            <div className='data-input-flex'   >
                                                <div className='title'> Valid from<span className='requrid-status'>*</span></div>
                                                <div>
                                                    <div className='date-input' >
                                                        <input type='date' className='date-picker'
                                                            style={{ border: userDataErrors.Validfrom ? "1px solid red" : "" }}
                                                            value={virtualForm.Validfrom && moment(virtualForm.Validfrom).format('YYYY-MM-DD')}
                                                            disabled={isEditMode ? true : false}
                                                            min={todayDate}
                                                            max={moment(todayDate).add(1, 'years').format('YYYY-MM-DD')}
                                                            onChange={(e: any) => {
                                                                handleFromDateChange(e)
                                                            }} />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='data-input-flex'>
                                                <div className='title'> Valid Upto<span className='requrid-status'>*</span></div>
                                                <div>
                                                    <input type='date' className='date-picker'
                                                        style={{ border: userDataErrors.Validupto && virtualForm.Validfrom !== "" ? "1px solid red" : "" }}
                                                        value={virtualForm.Validupto && moment(virtualForm.Validupto).format('YYYY-MM-DD')}
                                                        // value={virtualForm.Validfrom === "" ? "" : virtualForm.Validupto}
                                                        min={mindate}
                                                        max={moment(mindate).add(1, 'years').format('YYYY-MM-DD')}
                                                        // max={moment(mindate).add(1, 'years').format('YYYY-MM-DD')}
                                                        disabled={isEditMode ? true : false}
                                                        onChange={(e: any) => {
                                                            handleToDateChange(e)

                                                        }} />
                                                    {/* <input
                                                type='date'
                                                className='date-picker'
                                                style={{ border: userDataErrors.Validupto ? '1px solid red' : '' }}
                                                min={mindate}
                                                max={moment(mindate).add(1, 'years').format('YYYY-MM-DD')}
                                                value={virtualForm.to}
                                                onChange={handleToDateChange}
                                            /> */}
                                                </div>
                                            </div>
                                        </div>

                                        <div className='Particulars-inputbox'>
                                            <div className='Remarks-heading'>
                                                <label htmlFor="">Remarks</label>
                                            </div>
                                            <div>

                                                <InputComponent
                                                    height={"40px"}
                                                    width={windowWidth > 1900 ? "816px" : windowWidth > 1500 ? "750px" : windowWidth > 1190 ? "500px" : "350px"}
                                                    padding={"10px 10px"}
                                                    border={userDataErrors.remarks ?
                                                        "1px solid red" : "1px solid #A9C3DC"}
                                                    backgroundColor={"white"}
                                                    borderRadius={"0px"}
                                                    disabled={isEditMode ? true : false}
                                                    type={"text"}
                                                    color={"black"}
                                                    maxLength={250}
                                                    placeHolder={"Reason"}
                                                    inputValue={virtualForm.remarks}
                                                    getUser={(val: any) => {
                                                        setIsFormDirty(true)
                                                        setVirtualForm((prevState: any) => ({
                                                            ...prevState,
                                                            remarks: val,
                                                        }));
                                                        dispatch(resetFormModified(true));
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='card-Payment-main'>
                                    <div className='card-contant'>
                                        <div className='card-main-data'>
                                            <img src={PaymentCard} alt="PaymentCard" className='payment-card' />
                                            {/* <p className='virtual-card-number'>
                                            **** **** **** 4511
                                            <p className='card-content' >
                                                MONTH/YEAR
                                            </p>
                                            <div className='card-name-main'>
                                                <p className='font-size'>
                                                    Arun
                                                </p>
                                                <div className='date-valid'>
                                                    <div>
                                                        <p className='font-size-valid'>
                                                            VALID
                                                        </p>
                                                        <p className='font-size-valid'> THRU</p>
                                                    </div>
                                                    <p className='date'>
                                                        01/2000
                                                    </p>
                                                </div>
                                            </div>
                                        </p> */}
                                        </div>
                                        {/* <p className='card-status' >
                                        Status : Pending Approval
                                    </p> */}
                                        <div className='card-details'>
                                            <div className='card-title'>
                                                <p> Status </p>
                                                <p> Name on Card</p>
                                                <p> Card Number</p>
                                                <p> Valid till</p>
                                                <p> Amount</p>
                                            </div>
                                            <div className='card-value'>
                                                <p>:{ } </p>
                                                <p>:{empolyeeDetails.name} </p>
                                                <p>:{empolyeeDetails.cardNumber} </p>
                                                <p>:{empolyeeDetails.validTill} </p>
                                                <p>:{empolyeeDetails.amount} </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    }

                    {
                        changeFormEmployee === "Vendor's Payment" &&
                        <VendorCardPaymentApproval
                            primaryCardDD={primaryCardDD}
                            vendorForm={vendorForm}
                            updateData={updateData}
                            virtualCard={virtualCard}
                            approvalStatus={approvalStatus}

                            vendorfunValue={(val: any) => {
                                setVendorForm(val);
                            }}
                            formDirtyFunction={(val: any) => {
                                setIsFormDirty(val);
                            }}
                            vendorPaymentPage={(val: any) => {
                                setApprovalStatus(val);
                            }}
                            currentdateFunction={(val: any) => {
                                setVendorCurrentDate(val);
                            }}
                            otpsend={otpsend}
                            validateForm={validateForm}
                            modeFunction={modeFunction}
                            getEditMode={getEditMode}
                            isEditMode={isEditMode}
                            editData={editData}
                            isDisabled={isDisabled}
                            // setIsDisabled={(val:any) => {
                            //     setIsDisabled(val)
                            // }}
                            setIsDisabled={setIsDisabled}
                            updatedIsValueValidAll={updatedIsValueValidAll}
                        />
                    }
                </div>


                <div className='footer-virtual-approval'>
                    <ButtonComponent
                        title={"Cancel"}
                        height={"50px"}
                        width={"150px"}
                        margin={"0px 8px"}
                        backgroundColor={"#888888"}
                        color={"white"}
                        className={"button-component-hover cancel"}
                        handleClick={() => { returnGird() }}
                    />
                    <ButtonComponent
                        title={"Save"}
                        height={"50px"}
                        width={"150px"}
                        backgroundColor={"#0055D4"}
                        disabled={!isDisabled || showType === "success"}
                        color={"white"}
                        margin={"0px 8px"}
                        className={!isDisabled || showType === "success" ? "button-component-hover disabled" : "button-component-hover common-btn"}
                        handleClick={async () => {
                            setShowMessage(Object.values(updatedIsValueValidAll).every((values) => !values) ? "Some of the field(s) are not in required format." : "Mandatory field(s) should not be left blank.")
                            if (approvalStatus.status === "Approved") {
                                otpsend()
                                setIsDisabled(true)
                            } else {
                                updateData()
                                setIsDisabled(true)
                            }
                        }
                        }
                    />
                </div>

                <div>

                </div>


                {
                    showAlertBox &&
                    <div className='alert-warp'>
                        <AlertBox type={showType} message={showMessage} />
                    </div>
                }
            </MainPageCard >

            {
                otpVerification &&
                <OtpVerifyModal
                    otpVerify={resendOtp}
                    leavePage={() => {
                        setOtpVerification(false)
                        virtualCard("form")
                    }}
                    isLocked={isLocked}
                    setIsLocked={setIsLocked}
                    getStatus={async (stringFromArray) => {
                        let res;
                        try {

                            res = await axiosPrivate.post('companyadmin/bank/verify-otp/update', {
                                otp: stringFromArray,
                                approvedBy: auth.token,

                            });
                            if (res.status === 200) {
                                setIsLocked(true)
                                updateData()
                            }
                        } catch (error: any) {
                            setShowAlertBox(true);
                            setShowType("danger")
                            setShowMessage(error.response.data.error)
                            clearAleart("")
                        }

                        // organisationBankDataSave()
                        // userNavigateFunction("grid")
                    }
                    }
                />
            }



            {
                isLoading &&
                <Loading />
            }
            {
                componentNavigator === true &&
                <div>
                    <VirtualCardForm
                        employeeDetailsAll={employeeDetailsAll}
                        setEmployeeDetailsAll={(val: any) => {
                            setEmployeeDetailsAll(val)
                        }}
                        virtualCard={virtualCard}
                        virtualForm={virtualForm}
                        clearValue={(value) => {
                            setComponentNavigator(value)
                        }}
                        employeedetails={(val: any) => {
                            setcurrrenSelectedEmployee(val);
                        }}
                        clearEmployeeData={(val: any) => {
                            setVirtualForm(val)
                        }}
                        // adminDataDelete={adminDataDelete}
                        postdata={employeegetdata}


                    />
                </div>

            }
            {
                showChangesModalNavigate &&
                <Changesmodal
                    modelType={"navigate-radiobtn"}
                    closeModal={() => setShowChangesModalNavigate(false)}
                    leavePage={() => {
                        setChangeFormEmployee(navigatePage);
                        setShowChangesModalNavigate(false)
                        setIsFormDirty(false)
                        setVirtualForm((prevState: any) => ({
                            ...prevState,
                            request: navigatePage,
                        }));
                        // getEditMode(false)
                    }}
                    handleClose={() => setShowChangesModalNavigate(false)} />
            }


            {
                showChangesModal &&
                <Changesmodal
                    closeModal={() => setShowChangesModal(false)}
                    leavePage={() => {
                        setShowChangesModal(false);
                        setIsFormDirty(false)
                        virtualCard("grid")
                        // getEditMode(false)
                    }}
                    handleClose={() => setShowChangesModal(false)} />
            }
        </>

    )


}

// style={(productAdminUserData.company === "") ?
//                                         { pointerEvents: "none", opacity: "0.5", cursor: "auto" } : {}} onClick={() => {
//                                             // if (productAdminUserData.company === "") return;
// }}


