import React, { Component } from 'react'
import VirtualFrom from '../../components/virtualcard/form'
import VirtualGrid from '../../components/virtualcard/grid'

interface vendorValues {
    virtualCardFun: string;
    isEditMode: boolean,
    editData: {}
}
export default class VirtualCard extends Component<{}, vendorValues> {
    constructor(props: vendorValues) {
        super(props);
        this.state = {
            virtualCardFun: "grid",
            isEditMode: false,
            editData: {}
        };
    }

    render() {
        return (
            <div>

                {
                    this.state.virtualCardFun === "form" &&
                    <VirtualFrom
                        getEditMode={(val: any) =>
                            this.setState({
                                isEditMode: val
                            })
                        }
                        virtualCard={(val: any) => {
                            this.setState({ virtualCardFun: val })
                        }}
                        editData={this.state.editData}
                        isEditMode={this.state.isEditMode}
                        // virtualCardFun={this.state.virtualCardFun}
                        modeFunction={(val: boolean) => {

                            this.setState({
                                isEditMode: val
                            })
                        }}
                    />
                }
                {
                    this.state.virtualCardFun == "grid" &&
                    <>
                        <VirtualGrid
                            getEditMode={(val: any) =>
                                this.setState({
                                    isEditMode: val
                                })
                            }
                            virtualCard={(val: any) => {
                                this.setState({ virtualCardFun: val })
                            }}
                            // modeFunction={(val: boolean) => {
                            //     this.setState({
                            //         isEditMode: val
                            //     })
                            // }}
                            getEditData={(name: string, data: any,) => {
                                this.setState({
                                    virtualCardFun: name,
                                    editData: { ...data },
                                    isEditMode: true,
                                })
                            }}
                        />
                    </>

                }
            </div>
        )
    }
}
