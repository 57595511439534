import React, { useEffect, useState } from 'react';
import { RxCross1 } from 'react-icons/rx';
import DropdownComponent from '../form-elements/dropdown-component';
import ButtonComponent from '../form-elements/button-component';
import TextArea from '../form-elements/text-area';
import '../../../styles/modals/document-remark-modal.scss';

interface props {
    clearDeclinemodal: (val: any) => void;
    declineRemarks: any
    Submit: () => void;
    setDeclineRemarks:(val: any)=> void;
}

export default function ExciseChallanDeclinemodal({
    clearDeclinemodal,
    setDeclineRemarks,
    declineRemarks,
    Submit
}: props) {

    return (
        <div className={"document-decline-modal"}>
            <div className={"container"}>
                <div className={"close-icon"}>
                    <span className={"cross-icon"}>
                        <RxCross1
                            onClick={() => clearDeclinemodal(false)}
                            style={{ fontSize: "40px" }} />
                    </span>
                </div>
                <div>
                    <div >
                        <TextArea
                            name={"Remarks"}
                            placeHolder={"Reason"}
                            width={"320px"}
                            height={"140px"}
                            margin={"10px 0px 0px 0px"}
                            padding={"10px"}
                            
                            maxLength={250}
                            inputValue={declineRemarks}
                            getUser={(val) => {
                                setDeclineRemarks(val)
                                // setDocumentDeclineStatus(val, "remarks");
                            }}
                        />
                    </div>
                </div>
                <div className={"buttons"} >
                    <ButtonComponent
                        title={"Submit"}
                        height={"50px"}
                        width={"130px"}
                        backgroundColor={"#0055D4"}
                        color={"white"}
                        disabled={declineRemarks === "" ? true : false}
                        className={declineRemarks === "" ? "button-component disabled" : "button-component common-btn"}
                        handleClick={() => {
                            // clearDoumentDeclinemodal(false)
                            Submit()
                        }}
                    />
                </div>
            </div>
        </div>
    )
}