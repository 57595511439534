import React, { useRef, useState } from 'react'
import IconButton from '../../common-component/form-elements/icon-button';
import ButtonComponent from '../../common-component/form-elements/button-component';

interface props {
    getFile: (val: File) => void;
    setInvoiceShowmodal: (val: any) => void;
    setShowDeleteModal: (val: boolean) => void;
    invoice: any
}
function UploadInvoice({ getFile, setInvoiceShowmodal, setShowDeleteModal, invoice }: props) {
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [excelfilename, setExcelFileName] = useState("")
    //AlertBox Type State

    const dragOver = (e: any) => {
        e.preventDefault();
    }

    const dragEnter = (e: any) => {
        e.preventDefault();
    }

    const dragLeave = (e: any) => {
        e.preventDefault();
    }

    const fileDrop = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        let fileObj;
        if (e.dataTransfer) {
            const { files } = e.dataTransfer;
            fileObj = files[0];
            setExcelFileName(fileObj);
        } else {
            fileObj = e.target.files[0];
            setExcelFileName(fileObj);
        }
        getFile(fileObj)
    }

    const handleClickExcel = () => {
        fileInputRef.current!.click();
    };

    const fileHandler = (e: any) => {
        e.preventDefault();
        const fileObj = e.target.files[0];
        getFile(fileObj)
    };



    return (
        <div className={"container-upload-vendor"}>
            <div className='sub-container'>
                <div className='upload'
                    style={{ margin: '0px 10px' }}
                    onDragOver={dragOver}
                    onDragEnter={dragEnter}
                    onDragLeave={dragLeave}
                    onDrop={fileDrop}
                    onClick={handleClickExcel}>

                    <input
                        type='file'
                        ref={fileInputRef}
                        onChange={fileHandler}
                        style={{ display: 'none' }}
                        accept='.pdf, .jpg, .jpeg, .png'
                    />

                    <IconButton
                        iconName={"Upload"}
                        height={"40px"}
                        width={"40px"}
                        fontSize={"48px"}
                        margin={"0px 0px 0px 8px"}
                        color={"#0055D4"}
                        backgroundColor={"#F1F1F1 "}
                        hover={false}
                        handleClick={() => {
                            // setFileUpload(true);
                        }}
                    />
                    <div style={{ margin: '10px 5px 20px 5px' }}>Upload Invoice</div>
                    {/* <div style={{ margin: '0px 0px 10px 5px' }}>or</div> */}
                    <div className={"buttons"}>
                        <ButtonComponent
                            title={"Browse"}
                            height={"40px"}
                            width={"130px"}
                            backgroundColor={"#0055D4"}
                            color={"white"}
                            margin={"0px 8px"}
                            className={"button-component common-btn"}
                            handleClick={() => { }}
                        />
                    </div>

                </div>
            </div>
            <div className='invoice-upload-icons'>
                <IconButton
                    iconName={false ? "Eye-white" : "ShowEye"}
                    height={"40px"}
                    width={"40px"}
                    fontSize={"20px"}
                    margin={"0px 8px"}
                    color={"white"}
                    className={"invoice-upload-eye"}
                    backgroundColor={"#004E9C"}
                    hover={true}
                    handleClick={() => { setInvoiceShowmodal(true) }}
                />
                <IconButton
                    iconName={"Delete"}
                    height={"40px"}
                    width={"40px"}
                    fontSize={"25px"}
                    margin={"0px 8px"}
                    color={"white"}
                    backgroundColor={"#D60000"}
                    hover={invoice !== undefined || invoice !== "" || invoice !== null ? false : true}
                    disabled={invoice !== undefined || invoice !== "" || invoice !== null ? false : true}
                    opacity={invoice === undefined || invoice === "" || invoice === null ? "0.5" : "1"}
                    cursor={invoice === undefined || invoice === "" || invoice === null ? "not-allowed" : "pointer"}
                    handleClick={() => {
                        if (invoice === undefined || invoice === "" || invoice === null) return
                        setShowDeleteModal(true)
                    }}

                />
            </div>
            <div className='instruction'>
                <p>Formats allowed: JPG or PNG or PDF.</p>
                <p>Maximum file size allowed: 2MB</p>

            </div>

        </div>
    )
}

export default UploadInvoice