import React, { useState, useEffect } from 'react';
import ButtonComponent from '../common-component/form-elements/button-component';
import RadioOrCheckbox from '../common-component/form-elements/radio-or-checkbox';
import { useDispatch } from 'react-redux';
import { resetFormModified } from '../../redux/action';
import { axiosPrivate } from '../../middleware/axois-api';
import AlertBox from '../common-component/alert-box';
import '../../styles/intial-setup/notification-preference.scss'


interface Props {
  setIsFormDirty: (val: any) => void;
}

export default function NotificationPreference({ setIsFormDirty }: Props) {

  const dispatch = useDispatch();

  const [showAlertBox, setShowAlertBox] = useState<boolean>(false)  //AlertBox State
  const [showMessage, setShowMessage] = useState<string>("")   //AlertBox Message State
  const [showType, setShowType] = useState<string>("warning")   //AlertBox Type State
  const [loadingScreen, setLoadingScreen] = useState(false)

  const [notificationPreferenceAnnnouncement, setNotificationPreferenceAnnnouncement] = useState({
    newCustomOnline: "Online",
    newCustomEmail: "",
    newCustomSms: "",
  });
  const [notificationPreferencePayment, setNotificationPreferencePayment] = useState({
    vendorOnline: "Online",
    vendorEmail: "",
    vendorSms: "",
  });
  const [notificationPreferenceGstPayment, setNotificationPreferenceGstPayment] = useState({
    gstOnline: "Online",
    gstEmail: "",
    gstSms: ""
  });

  const [isNotificationChanged, setIsNotificationChanged] = useState<boolean>(false)

  useEffect(() => {
    getNotificationPreferences()
  }, [])

  // Function for Factor Aunthetication
  async function handleAunthenticationSave() {
    setLoadingScreen(true)
    try {
      const res = await axiosPrivate.post("/store/notification/preferences", {
        "announcements": {
          "online": notificationPreferenceAnnnouncement.newCustomOnline === "Online" ? "Yes" : "No",
          "email": notificationPreferenceAnnnouncement.newCustomEmail === "Email" ? "Yes" : "No",
          "sms": notificationPreferenceAnnnouncement.newCustomSms === "SMS" ? "Yes" : "No",
        },
        "vendorPayment": {
          "online": notificationPreferencePayment.vendorOnline === "Online" ? "Yes" : "No",
          "email": notificationPreferencePayment.vendorEmail === "Email" ? "Yes" : "No",
          "sms": notificationPreferencePayment.vendorSms === "SMS" ? "Yes" : "No",
        },
        "gstPayment": {
          "online": notificationPreferenceGstPayment.gstOnline === "Online" ? "Yes" : "No",
          "email": notificationPreferenceGstPayment.gstEmail === "Email" ? "Yes" : "No",
          "sms": notificationPreferenceGstPayment.gstSms === "SMS" ? "Yes" : "No",
        }
      })
      setLoadingScreen(false)
      setShowAlertBox(true)
      setShowType("success")
      setShowMessage(res.data.message)

      setTimeout(() => {
        setShowAlertBox(false);
      }, 5000);
      setIsFormDirty(false);
      dispatch(resetFormModified(false));
      setIsNotificationChanged(false)

    }
    catch (error: any) {
      setShowAlertBox(true)
      setShowType("warning")
      setShowMessage(error.response.data.error)
      setLoadingScreen(false)

      setTimeout(() => {
        setShowAlertBox(false); 
      }, 5000);
    }

  }

  // Function for getting notification preferences
  async function getNotificationPreferences() {
    try {
      const res = await axiosPrivate.get("/store/notification/preferences")
      if (res.status === 200) {
        setNotificationPreferenceAnnnouncement({
          newCustomOnline: res.data.announcements.online === "Yes" ? "Online" : "Online",
          newCustomEmail: res.data.announcements.email === "Yes" ? "Email" : "",
          newCustomSms: res.data.announcements.sms === "Yes" ? "SMS" : "",
        })

        setNotificationPreferencePayment({
          vendorOnline: res.data.vendorPayment.online === "Yes" ? "Online" : "Online",
          vendorEmail: res.data.vendorPayment.email === "Yes" ? "Email" : "",
          vendorSms: res.data.vendorPayment.sms === "Yes" ? "SMS" : "",
        })

        setNotificationPreferenceGstPayment({
          gstOnline: res.data.gstPayment.online === "Yes" ? "Online" : "Online",
          gstEmail: res.data.gstPayment.email === "Yes" ? "Email" : "",
          gstSms: res.data.gstPayment.sms === "Yes" ? "SMS" : "",
        })
      }
    }
    catch (error: any) {
      setShowAlertBox(false)
      setShowType("warning")
      setShowMessage(error.response.data.error)

      setTimeout(() => {
        setShowAlertBox(false);
      }, 5000);
    }
  } 

  return (
    <div className='notification-preference-container'>
      <h5>{"Notification Preference"}</h5>
      <span style={{ marginBottom: "20px" }}>{"You will start receiving notifications when you enable."}</span>


      <div className='notification-wrapper'>
        <div className='announcements'>
          <h6>{"Announcements"}</h6>
          <hr />
          <div className='announcements-contents'>
            <div className='row-name'>{"New Custom Announcement"}</div>
            <div>
              <RadioOrCheckbox
                value={"Online"}
                type={"checkbox"}
                disabled={true}
                checkedValue={notificationPreferenceAnnnouncement.newCustomOnline}
                getVal={(val) => {
                  if (notificationPreferenceAnnnouncement.newCustomOnline === "") {
                    setNotificationPreferenceAnnnouncement((prevState) => ({
                      ...prevState,
                      newCustomOnline: val,
                    }));
                  } else {
                    setNotificationPreferenceAnnnouncement((prevState) => ({
                      ...prevState,
                      newCustomOnline: "",
                    }));
                  }
                }
                }
                name={"announcement Online"}
                id={"announcement Online"}
              />
            </div>
            <div>
              <RadioOrCheckbox
                value={"Email"}
                type={"checkbox"}
                checkedValue={notificationPreferenceAnnnouncement.newCustomEmail}
                getVal={(val) => {
                  if (notificationPreferenceAnnnouncement.newCustomEmail === "") {
                    setNotificationPreferenceAnnnouncement((prevState) => ({
                      ...prevState,
                      newCustomEmail: val,
                    }));
                  } else {
                    setNotificationPreferenceAnnnouncement((prevState) => ({
                      ...prevState,
                      newCustomEmail: "",
                    }));
                  }

                  setIsNotificationChanged(true)
                  setIsFormDirty(true);
                  dispatch(resetFormModified(true));
                }
                }
                name={"announcement Email"}
                id={"announcement Email"}
              />
            </div>
            <div>
              <RadioOrCheckbox
                value={"SMS"}
                type={"checkbox"}
                checkedValue={notificationPreferenceAnnnouncement.newCustomSms}
                getVal={(val) => {
                  if (notificationPreferenceAnnnouncement.newCustomSms === "") {
                    setNotificationPreferenceAnnnouncement((prevState) => ({
                      ...prevState,
                      newCustomSms: val,
                    }));
                  } else {
                    setNotificationPreferenceAnnnouncement((prevState) => ({
                      ...prevState,
                      newCustomSms: "",
                    }));
                  }

                  setIsNotificationChanged(true)
                  setIsFormDirty(true);
                  dispatch(resetFormModified(true));
                }
                }
                name={"announcement SMS"}
                id={"announcement SMS"}
              />
            </div>
          </div>
        </div>
        <div className='payment-reminders'>
          <h6>{"Payment Reminders"}</h6>
          <hr />
          <div className='announcements-contents'>
            <div className='row-name'>
              {"Vendor Payment"}
            </div>
            <div>
              <RadioOrCheckbox
                value={"Online"}
                type={"checkbox"}
                disabled={true}
                checkedValue={notificationPreferencePayment.vendorOnline}
                getVal={(val) => {
                  if (notificationPreferencePayment.vendorOnline === "") {
                    setNotificationPreferencePayment((prevState) => ({
                      ...prevState,
                      vendorOnline: val,
                    }));
                  } else {
                    setNotificationPreferencePayment((prevState) => ({
                      ...prevState,
                      vendorOnline: "",
                    }));
                  }
                }
                }
                name={"vendor Online"}
                id={"vendor Online"}
              />
            </div>
            <div>
              <RadioOrCheckbox
                value={"Email"}
                type={"checkbox"}
                checkedValue={notificationPreferencePayment.vendorEmail}
                getVal={(val) => {
                  if (notificationPreferencePayment.vendorEmail === "") {
                    setNotificationPreferencePayment((prevState) => ({
                      ...prevState,
                      vendorEmail: val,
                    }));
                  } else {
                    setNotificationPreferencePayment((prevState) => ({
                      ...prevState,
                      vendorEmail: "",
                    }));
                  }

                  setIsNotificationChanged(true)
                  setIsFormDirty(true);
                  dispatch(resetFormModified(true));
                }
                }
                name={"vendor Email"}
                id={"vendor Email"}
              />
            </div>
            <div>
              <RadioOrCheckbox
                value={"SMS"}
                type={"checkbox"}
                checkedValue={notificationPreferencePayment.vendorSms}
                getVal={(val) => {
                  if (notificationPreferencePayment.vendorSms === "") {
                    setNotificationPreferencePayment((prevState) => ({
                      ...prevState,
                      vendorSms: val,
                    }));
                  } else {
                    setNotificationPreferencePayment((prevState) => ({
                      ...prevState,
                      vendorSms: "",
                    }));
                  }

                  setIsNotificationChanged(true)
                  setIsFormDirty(true);
                  dispatch(resetFormModified(true));
                }
                }
                name={"vendor SMS"}
                id={"vendor SMS"}
              />
            </div>
          </div>
          <div className='announcements-contents'>
            <div className='row-name'>
              {"GST Payment"}
            </div>
            <div>
              <RadioOrCheckbox
                value={"Online"}
                type={"checkbox"}
                disabled={true}
                checkedValue={notificationPreferenceGstPayment.gstOnline}
                getVal={(val) => {
                  if (notificationPreferenceGstPayment.gstOnline === "") {
                    setNotificationPreferenceGstPayment((prevState) => ({
                      ...prevState,
                      gstOnline: val,
                    }));
                  } else {
                    setNotificationPreferenceGstPayment((prevState) => ({
                      ...prevState,
                      gstOnline: "",
                    }));
                  }
                }
                }
                name={"gst Online"}
                id={'gst Online'}
              />
            </div>
            <div>
              <RadioOrCheckbox
                value={"Email"}
                type={"checkbox"}
                checkedValue={notificationPreferenceGstPayment.gstEmail}
                getVal={(val) => {
                  if (notificationPreferenceGstPayment.gstEmail === "") {
                    setNotificationPreferenceGstPayment((prevState) => ({
                      ...prevState,
                      gstEmail: val,
                    }));
                  } else {
                    setNotificationPreferenceGstPayment((prevState) => ({
                      ...prevState,
                      gstEmail: "",
                    }));
                  }

                  setIsNotificationChanged(true)
                  setIsFormDirty(true);
                  dispatch(resetFormModified(true));
                }
                }
                name={"gst Email"}
                id={'gst Email'}
              />
            </div>
            <div>
              <RadioOrCheckbox
                value={"SMS"}
                type={"checkbox"}
                checkedValue={notificationPreferenceGstPayment.gstSms}
                getVal={(val) => {
                  if (notificationPreferenceGstPayment.gstSms === "") {
                    setNotificationPreferenceGstPayment((prevState) => ({
                      ...prevState,
                      gstSms: val,
                    }));
                  } else {
                    setNotificationPreferenceGstPayment((prevState) => ({
                      ...prevState,
                      gstSms: "",
                    }));
                  }

                  setIsNotificationChanged(true)
                  setIsFormDirty(true);
                  dispatch(resetFormModified(true));
                }
                }
                name={"gst SMS"}
                id={'gst SMS'}
              />
            </div>
          </div>
        </div>
      </div>

      <div className={"buttons save-Button"} >
        <ButtonComponent
          title={"Save"}
          height={"50px"}
          width={"130px"}
          backgroundColor={"#0055D4"}
          color={"white"}
          className={isNotificationChanged ? "button-component common-btn" : "button-component disabled"}
          handleClick={handleAunthenticationSave}
          disabled={!isNotificationChanged}
        />
      </div>

      {showAlertBox && <AlertBox type={showType} message={showMessage} />}

    </div>
  )
}
