import React from "react";

const CustomEllipsisRenderer: React.FC<any> = (params: any) => {
    const value = params.value || "";
    const maxLength = 30; // Maximum characters allowed before truncation

    let displayValue = value;
    if (value.length > maxLength) {
        displayValue = value.substring(0, maxLength) + "...";
    }

    const cellStyle: React.CSSProperties = {
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden",
        paddingRight: "3px",
    };

    return <div style={cellStyle}>{displayValue}</div>;
};

export default CustomEllipsisRenderer;
export const userLogColumnData = [
    // {
    //     field: 'selection', headerName: '', minWidth: 60, maxWidth: 60, resizable: false, suppressMovable: true, filter: "agSetColumnFilter", suppressSizeToFit: false, headerCheckboxSelection: true, checkboxSelection: true, showDisabledCheckboxes: true, suppressRowTransform: true, cellStyle: { textAlign: 'center' }
    // },
    {
        field: 'name', headerName: 'Name', minWidth: 300, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "number", cellRendererFramework: CustomEllipsisRenderer
    },
    {
        field: 'email', headerName: 'Email', minWidth: 300, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "number", cellRendererFramework: CustomEllipsisRenderer
    },
    {
        field: 'ipAddress', headerName: 'IP Address', minWidth: 300, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer
    },
    {
        field: 'lastLoggedIn', headerName: 'Last Login', minWidth: 300, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer
    },
    {
        field: 'RequestURL', headerName: 'Request URL', minWidth: 600, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer
    },
    {
        field: 'RequestMethod', headerName: 'Request Method', minWidth: 300, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer
    },
    {
        field: 'ResponseStatusCode', headerName: 'Response Status Code', minWidth: 300, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer,
        cellClassRules: {
            'success-cell': (params: any) => params.value === '200',
            'submitted-cell': (params: any) => params.value === '304',
            'denied-cell': (params: any) => params.value === '404' || params.value === '500' || params.value === '401' || params.value === '400',
        },
    },
    {
        field: 'lastApiHitted', headerName: 'Request Time', minWidth: 300, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer
    },

    // {
    //     field: 'remarks', headerName: 'Remarks', minWidth: 200, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype : "text"
    // },



]

export const securityLogColumnData = [
    {
        field: 'ipAddress', headerName: 'IP Address', minWidth: 300, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer
    },
    {
        field: 'name', headerName: 'Name', minWidth: 300, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer
    },
    {
        field: 'UserInformation', headerName: 'User Information', minWidth: 300, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "number", cellRendererFramework: CustomEllipsisRenderer
    },
    {
        field: 'status_code', headerName: 'Status Code', minWidth: 300, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "number", cellRendererFramework: CustomEllipsisRenderer,
        cellClassRules: {
            'success-cell': (params: any) => params.value === '200',
            'submitted-cell': (params: any) => params.value === '304',
            'denied-cell': (params: any) => params.value === '404' || params.value === '500' || params.value === '401' || params.value === '400',
        },
    },
    {
        field: 'timestamp', headerName: 'Time Stamp', minWidth: 300, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "number", cellRendererFramework: CustomEllipsisRenderer
    },
    {
        field: 'user_id', headerName: 'User Id', minWidth: 300, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "number", cellRendererFramework: CustomEllipsisRenderer
    },
]


export const integrationLogColumnData = [
    {
        field: 'ipAddress', headerName: 'IP Address', minWidth: 300, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer
    },
    {
        field: 'IntegratorName', headerName: 'Integrator Name', minWidth: 300, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer
    },
    {
        field: 'IntegrationEndpoint', headerName: 'Integration Endpoint', minWidth: 300, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "number", cellRendererFramework: CustomEllipsisRenderer
    },
     {
        field: 'EventType', headerName: 'Event Type', minWidth: 300, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "number", cellRendererFramework: CustomEllipsisRenderer
    },
     {
        field: 'RequestParameters', headerName: 'Request Parameters', minWidth: 300, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "number", cellRendererFramework: CustomEllipsisRenderer
    },
    {
        field: 'ResponseData', headerName: 'Response Data', minWidth: 300, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "number", cellRendererFramework: CustomEllipsisRenderer
    },
    {
        field: 'ResponseTime', headerName: 'Response Time', minWidth: 300, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "number", cellRendererFramework: CustomEllipsisRenderer
    },
    {
        field: 'timestamp', headerName: 'Time Stamp', minWidth: 300, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "number", cellRendererFramework: CustomEllipsisRenderer
    },
]
export const rowDatathis = [
    {
        ipAddress: "172.25.10.156",
        RequestMethod: "GET",
        RequestURL: "/v1/gst-payable/get-gst-data",
        ResponseStatusCode: "304",
        name: "Sharan P",
        email: "sharan.p@knowledgeq.com",
        lastApiHitted: "6/12/2023, 10:59:13 am",
        lastLoggedIn: "6/12/2023, 10:00:53 am"
    },
    {
        accountNumber: "6554342322121",
        bank: "Axis",
        ifscCode: "UTIB0045",
        branch: "Bhubaneswar",
        address: "Odisha",
        priority: "02",
        isActive: "Yes",
        status: "Approved",
        remarks: "",
        approvedBy: "Nagarajan",
    },
    {
        accountNumber: "54512124212143",
        bank: "SBI",
        ifscCode: "BNNK325",
        branch: "Bhubaneswar",
        address: "Odisha",
        priority: "03",
        isActive: "Yes",
        status: "Approved",
        remarks: "",
        approvedBy: "Nagarajan",
    },
    {
        accountNumber: "254242542214",
        bank: "HSBC",
        ifscCode: "UTICICI01",
        branch: "Bhubaneswar",
        address: "Odisha",
        priority: "04",
        isActive: "Yes",
        status: "Approved",
        remarks: "",
        approvedBy: "Nagarajan",
    },

]