import React, { useState } from 'react';
import '../../styles/login/create-new-password.scss'
import InputComponent from '../common-component/form-elements/input-component'
import ButtonComponent from '../common-component/form-elements/button-component';
import axios from 'axios';
import { IoIosEye, IoIosEyeOff } from 'react-icons/io';
import useAxiosPrivate from '../../services/hooks/useaxios-private';
import { encryptToken } from '../../services/utils/token-utils';



interface Props {
  passwordMail: string;
  handleclick: (val: any) => void;
}

export default function CreateNewPassword({ passwordMail, handleclick }: Props) {
  const [password, setPassword] = useState<string>('')
  const [confirmPassword, setConfirmPassword] = useState<string>('')
  const [showPassword, setShowPassword] = useState<boolean>()
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>()
  const [isPasswordValid, setIsPasswordValid] = useState<boolean>(false)
  const [isConfirmPasswordValid, setIsConfirmPasswordValid] = useState<boolean>(false)
  const [disabled, setDisabled] = useState<boolean>(true)
  const [errorMessage, setErrorMessage] = useState<string>('')

  const axiosPrivate = useAxiosPrivate();


  async function handleSetPassword() {
    const minLength = 8;
    const hasLowerCase = /[a-z]/.test(password);
    const hasUpperCase = /[A-Z]/.test(password);
    const hasSpecialChar = /^(?=.*[~!@#$%_-])[a-zA-Z0-9~!@#$%_-]+$/.test(password);
    const hasNumber = /\d/.test(password);
    if (
      password.length < minLength ||
      !hasLowerCase ||
      !hasUpperCase ||
      !hasSpecialChar ||
      !hasNumber
    ) return;
    try {
      const res = await axiosPrivate.post('forgot-password', {
        "email": encryptToken(passwordMail),
        "newPassword": encryptToken(password),
        "confirmPassword": encryptToken(confirmPassword)
      })
      if (res.status === 200) {
        handleclick("passwordChanged");
      }
    } catch (error: any) {
      setErrorMessage(error.response.data.error)
    }
  }
  function isValidPassword(pass: string, confirmPass: string) {
    const minLength = 8;
    const hasLowerCase = /[a-z]/.test(password);
    const hasUpperCase = /[A-Z]/.test(password);
    const hasSpecialChar = /^(?=.*[~!@#$%_-])[a-zA-Z0-9~!@#$%_-]+$/.test(password);
    const hasNumber = /\d/.test(password);
    if (
      password.length < minLength ||
      !hasLowerCase ||
      !hasUpperCase ||
      !hasSpecialChar ||
      !hasNumber || pass !== confirmPass
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }
  const toggleShowPassword = () => {

    setShowPassword(!showPassword);
  };
  const toggleShowConfirmPassword = () => {
    if (password !== "") {
        setShowConfirmPassword(!showConfirmPassword);

    }
  }
  const handleKeyPress =(e: any)=>{
    if(e.keyCode === 32) {
        e.preventDefault();
    }
    setErrorMessage("")
}
const handleKeyDown = (e: any) => {
    if(e.keyCode === 32) {
        e.preventDefault();
    }
    if (e.key === 'Enter') {
      handleSetPassword()
    }
    setErrorMessage("")
};
 
  return (
    <div className='create-new-password'>
      <div className='title'>{"Create New Password"}</div>
      <div className='password'>
        <InputComponent
          height={"40px"}
          width={"100%"}
          padding={"10px 10px"}
          border={"none"}
          maxLength={14}
          blockCopyPaste={true}
          backgroundColor={"#011D60"}
          borderRadius={"5px"}
          handleKeyDown={handleKeyPress}
          type={showPassword ? 'text' : 'password'}
          inputValue={password}
          autoFocus={true}
          color={"white"}
          placeHolder={"Enter Password"}
          getUser={(val: any) => { setPassword(val); isValidPassword(val, confirmPassword); }}
          points={[
            { name: "Atleast 8 characters", pattern: "8 character" },
            { name: "Atleast one lower case", pattern: "lowercase" },
            { name: "Atleast one upper case", pattern: "uppercase" },
            { name: "Atleast one number", pattern: "number" },
            { name: "Atleast one special character <br /> (Allowed: ~, !, @, #, $, %, _, -)", pattern: "specialCharacter" },
          ]}
          fieldStatus={(val: any) => { setIsPasswordValid(val) }}
        />
        {showPassword ? (
          <IoIosEyeOff className='show-icon' onClick={toggleShowPassword} />
        ) : (
          <IoIosEye className='show-icon' onClick={toggleShowPassword} />
        )}
      </div>
      <div className='password'>
        <InputComponent
          height={"40px"}
          width={"100%"}
          padding={"10px 10px"}
          border={"none"}
          backgroundColor={"#011D60"}
          borderRadius={"5px"}
          blockCopyPaste={true}
          handleKeyDown={handleKeyDown}
          disabled={(password === "" || isPasswordValid) ? true : false}
          type={showConfirmPassword ? 'text' : 'password'}
          color={"white"}
          showTooltipTitle={false}
          maxLength={14}
          inputValue={confirmPassword}
          placeHolder={"Enter Confirm Password"}
          getUser={(val: any) => { setConfirmPassword(val); isValidPassword(password, val); }}
          points={[
            { name: "Passwords do not match", pattern: "confirmpassword" },
          ]}
          password={password}
          fieldStatus={(val: any) => { setIsConfirmPasswordValid(val) }}

        />
         {showConfirmPassword ? (
                    <IoIosEyeOff className={(password === "" || isPasswordValid) ? `show-icon disabled-icon` : `show-icon`} onClick={(password === "" || isPasswordValid) ?  () => {} : toggleShowConfirmPassword} />
                ) : (
                    <IoIosEye className={(password === "" || isPasswordValid) ? `show-icon disabled-icon`: `show-icon`} onClick={(password === "" || isPasswordValid) ?  () => {} : toggleShowConfirmPassword} />
                )}
      </div>
      <div className='submit-btn'>
        <ButtonComponent
          title={"Submit"}
          height={"40px"}
          disabled={disabled}
          width={"100%"}
          boxShadow={"0px 10px 20px #0066FF80"}
          backgroundColor={"#FFFFFF"}
          color={"#0055D4"}
          handleClick={() => {
            handleSetPassword()
          }}
          className={disabled? "button-component-hover disabled" : "button-component-hover"}
        />
      </div>
      {errorMessage && <p className='error'>{errorMessage}</p>}
    </div>
  )
}
