export const userEngagementEventOptions = {
    maintainAspectRatio: false,
    type: 'line',
    responsive: true,
    layout: {},
    scales: {
        x: {
            title: {
                display: false,
                text: 'Organization',
                font: {
                    weight: 'bold',
                },
            },
            grid: {
                display: false,
                stacked: true,
                barPercentage: 1.5,
                categoryPercentage: 0.8,
                barThickness: 10,
            },
        },
        y: {
            title: {
                display: true,
                beginAtZero: true,
                font: {
                    weight: 'bold',
                },
                text: 'Time in hrs',
            },
            grid: {
                display: true,
                stacked: true,
                color: '#B3CAE1',
                barPercentage: 1.5,
                categoryPercentage: 0.8,
                barThickness: 4,
            },
        },
    },
    plugins: {
        tooltip: {
            enabled: false
        },
        legend: {
            display: false,
            position: 'bottom' as const,
        },
        title: {
            display: false,
            font: {
                weight: 'bold',
                size: 25,
            },
            padding: 10,
            text: 'User Engagement Events',
        },
    },
};