import React, { useEffect, useRef } from 'react'
import "../../styles/download-option/download-option.scss"
import { useReactToPrint } from 'react-to-print';
interface props {
    handleclose: (value: any) => void
    getMethodFun?: (value: any) => void
    conponentPDF?: any
}
export default function DownloadOption({ handleclose, getMethodFun }: props) {
    const downloadRef = useRef<HTMLDivElement | null>(null);

    let isOpend = false;
    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        isOpend = true;
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    const handleClickOutside = (event: MouseEvent) => {
        if (isOpend && downloadRef.current && !downloadRef.current.contains(event.target as Node) && (event.target as HTMLElement).className !== "download-option-main" && (event.target as HTMLElement).className !== " download-option" && (event.target as HTMLElement).className !== "border-line") {
            // isOpend = false;
            handleclose(false)
            console.log("closed")
        }
    };

    return (
        <div ref={downloadRef}>
            <div className='download-option-main'>
                <div className='download-option'>

                    <p onClick={() => {
                        if (getMethodFun) {
                            getMethodFun("CSV")
                        }
                    }} >
                        CSV
                    </p>
                    <div className='border-line'>
                    </div>
                    <p onClick={() => {
                        if (getMethodFun) {
                            getMethodFun("PDF")
                        }
                    }} >
                        PDF
                    </p>
                </div>
            </div>
        </div>
    )
}
