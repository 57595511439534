import React, { useState, useEffect } from 'react';
import '../../styles/intial-setup/intial-setup.scss';
import SigninOption from '../../components/initial-setup/sign-in-option';
import MyProfile from '../../components/initial-setup/my-profile';
import NotificationPreference from '../../components/initial-setup/notification-preference';
import Changesmodal from '../../components/common-component/modals/changes-modal';
import { useDispatch } from 'react-redux';
import { resetFormModified } from '../../redux/action';
import { axiosPrivate } from '../../middleware/axois-api';

interface props {
    activeTitled: string
}

export default function InitialSetup({ activeTitled }: props) {

    const dispatch = useDispatch();

    const [activeTitle, setActiveTitle] = useState<string>("signinOptions")
    const [selectedTitle, setSelectedTitle] = useState<string>("signinOptions")
    const [isFormDirty, setIsFormDirty] = useState(false);
    const [showChangesModal, setShowChangesModal] = useState(false);

    const [email, setEmail] = useState("");
    const [userGroup, setUserGroup] = useState("");

    // Function for open a save Changes model 
    const handleSetupPin = (title: string) => {
        if (isFormDirty) {
            setShowChangesModal(true)
            setSelectedTitle(title)
        } else {
            setActiveTitle(title)
            dispatch(resetFormModified(false));
        }
    }

    useEffect(() => {
        setActiveTitle(activeTitled)
    }, [])

    useEffect(() => {
        getUsertype()
    }, []);

    const getUsertype = async () => {
        try {
            const res = await axiosPrivate.get(`/user/get-privilege`)
            if (res.status === 200) {
                setEmail(res.data.email)
                setUserGroup(res.data.UserGroup)
            }
        }
        catch (error) {
        }
    }



    return (
        <div className='intial-setup'>
            <div className='main-container'>
                <div className='side-navbar'>
                    <div className={`${activeTitle === "signinOptions" ? "titles active" : "titles"}`} onClick={() => { handleSetupPin("signinOptions") }}>
                        {"Sign in Options"}
                    </div>
                    <div className={`${activeTitle === "myProfile" ? "titles active" : "titles"}`} onClick={() => { handleSetupPin("myProfile") }}>
                        {"My Profile"}
                    </div>
                    <div className={`${activeTitle === "notificationPreference" ? "titles active" : "titles"}`} onClick={() => { handleSetupPin("notificationPreference") }}>
                        {"Notification Preference"}
                    </div>
                </div>
                <div className='intial-setup-container'>
                    {
                        activeTitle === "signinOptions" && <SigninOption setIsFormDirty={setIsFormDirty} email={email} />
                    }
                    {
                        activeTitle === "myProfile" && <MyProfile setIsFormDirty={setIsFormDirty} email={email} userGroup={userGroup} />
                    }
                    {
                        activeTitle === "notificationPreference" && <NotificationPreference setIsFormDirty={setIsFormDirty} />
                    }
                </div>
            </div>
            {showChangesModal && <Changesmodal
                closeModal={() => setShowChangesModal(false)}
                leavePage={() => {
                    setActiveTitle(selectedTitle);
                    setShowChangesModal(false);
                    setIsFormDirty(false)
                    dispatch(resetFormModified(false));
                }}
                handleClose={() => setShowChangesModal(false)} />}
        </div>
    )
}
