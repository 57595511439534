import CompanyForm from '../../../components/approval/onboarding/company-form'
import CompanyGrid from '../../../components/approval/onboarding/company-grid'
import React, { Component } from 'react'

interface StateValues {
  onboardingView: string;
  editData: any;
  isEditMode: boolean;
}
export default class CompanyOnboarding extends Component<{}, StateValues> {

  constructor(props: StateValues) {
    super(props);
    this.state = {
      onboardingView: "grid",
      editData: {},
      isEditMode: false,
    };
    this.addMode = this.addMode.bind(this);
  }

  addMode = () => {
    this.setState({
      editData: {},
      isEditMode: false,
    })
  }

  render() {
    return (
      <div>
        {
          this.state.onboardingView === "form" &&
          <CompanyForm
            onboardingViewfun={(val) => {
              this.setState({ onboardingView: val })
            }}
            editData={this.state.editData}
            isEditMode={this.state.isEditMode}
            getEditData={(data: any) => this.setState({
              editData: { ...data },
            })}
          />
        }
        {
          this.state.onboardingView === "grid" &&
          <CompanyGrid
            onboardingViewfun={(val) => {
              this.setState({ onboardingView: val })
            }}
            addMode={this.addMode}
            getEditData={(nav: any, data: any) => this.setState({
              onboardingView: nav,
              editData: { ...data },
              isEditMode: true,
            })}

          />
        }


      </div>

    )
  }
}

