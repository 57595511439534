import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
interface Props {
    showlabels: boolean,
    reversed: boolean
    label?: any
    data?: any
}
const ApexChart = ({ showlabels, reversed, label, data }: Props) => {
    const [chartData, setChartData] = useState<any>({
        series: [{
            data: data,
        }],
        options: {
            chart: {
                type: 'bar',
                height: 350,
                toolbar: {
                    show: false, // Hide the toolbar
                },
            },
            annotations: {
                xaxis: [{
                    x: 500,
                    borderColor: '#00E396',
                    // label: {
                    //     borderColor: '#00E396',
                    //     style: {
                    //         colors: [],
                    //         fontSize: '5px',
                    //         fontFamily: 'Helvetica, Arial, sans-serif',
                    //         fontWeight: 400,
                    //         cssClass: 'apexcharts-yaxis-label',
                    //     },
                    //     // text: 'X annotation',
                    // },
                    labels: {
                        style: {
                          fontSize: '8px',
                          fontWeight: 500,
                        }}
                }],
                yaxis: [{
                    y: 'July',
                    y2: 'September',
                    labels: {
                        style: {
                          fontSize: '8px',
                          fontWeight: 500,
                        }}
                }],
            },
            tooltip: {
                enabled: false, // Disable tooltips on hover
            },
            plotOptions: {
                bar: {
                    // colors: {
                    //     ranges: [{
                    //         from: 0,
                    //         to: 400,
                    //         color: '#FF5733', // Change the color for bars in the specified range
                    //     }, {
                    //         from: 401,
                    //         to: 450,
                    //         color: '#00E396',
                    //     }, {
                    //         from: 451,
                    //         to: 500,
                    //         color: '#c8eb1c',
                    //     }, {
                    //         from: 501,
                    //         to: 1000,
                    //         color: '#f20ae3',
                    //     }],
                    // },
                    horizontal: true,
                },
            },
            dataLabels: {
                enabled: false,
            },
            xaxis: {
                categories: label,
            },
            grid: {
                xaxis: {
                    lines: {
                        show: true,
                    },
                },
            },
            yaxis: {
                show: false,
                reversed: reversed,
                axisTicks: {
                    show: true,
                },
            },
            // colors: [['#FF5733', '#00E396', '#F9C00E', '#AB47BC']],
        },
    });
    useEffect(() => {
        setChartData({
            series: [{
                data: data,
            }],
            options: {
                chart: {
                    type: 'bar',
                    height: 350,
                    toolbar: {
                        show: false, // Hide the toolbar
                    },
                },
                annotations: {
                    xaxis: [{
                        x: 500,
                        borderColor: '#00E396',
                        label: {
                            borderColor: '#00E396',
                            style: {
                                color: '#fff',
                                background: '#00E396',
                            },
                            // text: 'X annotation',
                        },
                    }],
                    yaxis: [{
                        y: 'July',
                        y2: 'September',
                        label: {
                            style: {
                                // colors: [],
                                // fontSize: '5px',
                                // fontFamily: 'Helvetica, Arial, sans-serif',
                                // fontWeight: 400,
                                // cssClass: 'apexcharts-yaxis-label',
                            },
                            // text: 'Y annotation',
                        },
                    }],
                },
                tooltip: {
                    enabled: false, // Disable tooltips on hover
                },
                plotOptions: {
                    bar: {
                        // colors: {
                        //     ranges: [{
                        //         from: 0,
                        //         to: 400,
                        //         color: '#FF5733', // Change the color for bars in the specified range
                        //     }, {
                        //         from: 401,
                        //         to: 450,
                        //         color: '#00E396',
                        //     }, {
                        //         from: 451,
                        //         to: 500,
                        //         color: '#c8eb1c',
                        //     }, {
                        //         from: 501,
                        //         to: 1000,
                        //         color: '#f20ae3',
                        //     }],
                        // },
                        horizontal: true,
                    },
                },
                dataLabels: {
                    enabled: false,
                },
                xaxis: {
                    categories: label,
                },
                grid: {
                    xaxis: {
                        lines: {
                            show: true,
                        },
                    },
                },
                yaxis: {
                    show: showlabels,
                    reversed: reversed,
                    axisTicks: {
                        show: true,
                    },
                },
                // colors: [['#FF5733', '#00E396', '#F9C00E', '#AB47BC']],
            },
        })
    }, [label, data, showlabels, reversed])

    return (
        <div id="chart" style={{ height: "100%" }}>
            <ReactApexChart options={chartData.options} series={chartData.series} type="bar" height='100%' />
        </div>
    );
};

export default ApexChart;