export const AmountRenderer = ({ value }: any) => {
    if (value) {
        const onlyNumber = value.toString().replace(/[^0-9.]/g, "");
        const parsedValue = parseFloat(onlyNumber);
        const formattedValue = isNaN(parsedValue) ? "" : parsedValue.toLocaleString('en-IN', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
        return <span>{formattedValue}</span>;
    }
    return <span>{""}</span>;
};
export const formatAmount = (value: any) => {
    if (value) {
        const onlyNumber = value.toString().replace(/[^0-9.]/g, "");
        const parsedValue = parseFloat(onlyNumber);
        const formattedValue = isNaN(parsedValue) ? "" : parsedValue.toLocaleString('en-IN', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
        return formattedValue;
    }
    return "";
};