import MasterVendorForm from '../../components/product-admin/vendor/vendor-form';
import MasterVendorGrid from '../../components/product-admin/vendor/vendor-grid';

import React, { Component } from 'react'
import PaymentForm from '../../components/organisation/vendor/vendor-payment-check';

interface StateValues {
  onboardingView: string;
  editData: any;
  isEditMode: boolean;
  editedShowPage: string;
}
export default class MasterVendorOnboarding extends Component<{}, StateValues> {

  constructor(props: StateValues) {
    super(props);
    this.state = {
      onboardingView: "grid",
      editData: {},
      isEditMode: false,
      editedShowPage: "",
    };
    this.addMode = this.addMode.bind(this);
  }

  addMode = () => {
    this.setState({
      // onboardingView: "form",
      editData: {},
      isEditMode: false,
      editedShowPage: "BasicDetails"
    })
  }

  render() {
    return (
      <div>
        {
          this.state.onboardingView === "form" &&
          <MasterVendorForm
            editedShowPage={this.state.editedShowPage}
            onboardingViewfun={(val) => {
              this.setState({ onboardingView: val })
            }}
            editData={this.state.editData}
            isEditMode={this.state.isEditMode}
            getEditData={(data: any) => this.setState({
              editData: { ...data },
              // isEditMode: false,
            })}
          />
        }
        {
          this.state.onboardingView === "grid" &&
          <MasterVendorGrid
            onboardingViewfun={(val:any) => {
              this.setState({ onboardingView: val })
            }}
            editedShowPageFun={(val: any) => {
              this.setState({
                editedShowPage: val
              })
            }}
            addMode={this.addMode}
            getEditData={(nav: any, data: any) => {
              this.setState({
                onboardingView: nav,
                editData: { ...data },
                isEditMode: true,
              })
            }
            }
            getDoubleClickData={(data:any) => {
              this.setState({
                editData: { ...data },
                isEditMode: true,
                editedShowPage: "BasicDetails"
              })
            }}

          />
        }
        {
          this.state.onboardingView === "payment" &&
          <PaymentForm />
        }
      </div >

    )
  }
}

