import React, { useEffect, useState } from 'react'
import MainPageCard from '../../common-component/cards/main-page-card';
import ButtonComponent from '../../common-component/form-elements/button-component';
import InputComponent from '../../common-component/form-elements/input-component';
import DropdownComponent from '../../common-component/form-elements/dropdown-component';
import PaymentCard from '../../../../src/assets/images/virtual_card.png';
// import Makepayment from '././makepayment';
// import VirtualCardayPmentModal from '../../../virtualcard-payment-modal';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import RadioOrCheckbox from '../../common-component/form-elements/radio-or-checkbox';
import useAxiosPrivate from '../../../services/hooks/useaxios-private';
import AlertBox from '../../common-component/alert-box';
import Changesmodal from '../../common-component/modals/changes-modal';
import { useDispatch } from "react-redux";
import { resetFormModified } from "../../../redux/action";
import { v4 as uuidv4 } from 'uuid';
import { format } from "date-fns";
import Loading from '../../common-component/modals/loading-screen';
import '../../../styles/virtualcard/vendor-payment.scss'
import moment from 'moment';






interface Props {
    virtualCard: (val: string) => void;
    formDirtyFunction: (val: boolean) => void;
    vendorfunValue: (val: any) => void;
    vendorPaymentPage: (val: any) => void;
    currentdateFunction: (val: any) => void;
    updateData: () => void;
    primaryCardDD: any;
    approvalStatus: any;
    vendorForm: any;
    isEditMode: boolean;
    updatedIsValueValidAll: Record<string, boolean>;
    modeFunction: (val: boolean) => void;
    getEditMode: (value: boolean) => void;
    editData: any
    otpsend: any
    validateForm: any
    isDisabled: boolean
    setIsDisabled: any
}
export interface userValidationRulesInterface {
    [key: string]: {
        [key: string]: {
            regex: RegExp | string;
            field: string;
            shouldNotBe: string;
        };
    }
}
export default function VendorCardPaymentApproval({ virtualCard, updateData, formDirtyFunction, isDisabled, setIsDisabled, primaryCardDD, updatedIsValueValidAll, otpsend, vendorPaymentPage, approvalStatus, validateForm, vendorfunValue, getEditMode, vendorForm, currentdateFunction, modeFunction, isEditMode, editData }: Props) {
    const axiosPrivate = useAxiosPrivate();
    const [userDataErrors, setUserDataErrors] = useState<Record<string, boolean>>({});
    const [showType, setShowType] = useState("warning") // error message showType
    const [showMessage, setShowMessage] = useState<string>("") // error message showMessage
    const [showAlertBox, setShowAlertBox] = useState(false) // error message ShowAlertBox
    const [showChangesModal, setShowChangesModal] = useState(false);
    const [isFormDirty, setIsFormDirty] = useState(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
    const todayDate = moment(new Date()).format('YYYY-MM-DD');
    const [mindate, setMindate] = useState<any>();




    const [currentDate, setCurrentDate] = useState("");

    const [empolyeeDetails, setEmpolyeeDetails] = useState({
        name: "",
        cardNumber: "",
        validTill: "",
        amount: "",
    });

    const dispatch = useDispatch();


    useEffect(() => {
        vendorfunValue((prevState: any) => ({
            ...prevState,
            date: currentDate
        }));
    }, [currentDate])

    useEffect(() => {
        formDirtyFunction(isFormDirty);
    }, [isFormDirty])

    // date function
    useEffect(() => {
        if (!isEditMode) {
            const interval = setInterval(() => {
                const newDate = new Date();
                // Reset time to midnight 
                const onlydate = format(newDate, "dd-MM-yyyy")
                currentdateFunction(onlydate);
                setCurrentDate(onlydate)
            }, 1000);  // Update every second

            return () => clearInterval(interval);
        }
    }, []);

    const [changeFormEmployee, setChangeFormEmployee] = useState<any>(true)
    // warningMsg 
    const clearAleart = (status: any) => {
        const timer = setTimeout(() => {
            setShowAlertBox(false)
            setShowMessage("");
            if (status === 200) {
                virtualCard("grid")
                getEditMode(false)
            }
            clearTimeout(timer);
        }, 5000);
    }
    // cancel funtion 
    const returnGird = () => {
        if (isFormDirty) {
            setShowChangesModal(true)
        } else {
            vendorfunValue({
                request: "Vendor's Payment",
                date: "",
                particulars: "",
                primaryAccountNo: "",
                primaryID: "",
                amount: "",
                Validfrom: "",
                Validupto: "",
                remarks: "",
                id: ""
            })
            modeFunction(false)
            virtualCard("grid")
            getEditMode(false)
        }
    }

    useEffect(() => {
        if (isEditMode) {
            if (Object.keys(editData).length > 0) {
                vendorfunValue({
                    request: editData.isEmployeeRecord ? "Vendor's Payment" : "Employee",
                    date: editData.date,
                    particulars: editData.particulars,
                    primaryAccountNo: editData.primaryAccountNo,
                    primaryID: editData.primaryID,
                    amount: editData.amount,
                    Validfrom: editData.Validfrom,
                    Validupto: editData.Validupto,
                    Transaction: editData.Transaction,
                    remarks: editData.remarks || "",
                    declineRemarks: editData.declineRemarks || "",
                    status: editData.status,
                    id: editData.id
                })
            }
            setChangeFormEmployee(editData.isEmployeeRecord ? "Vendor's Payment" : "Employee")
            if (editData.isEmployeeRecord) {

            }

        }
    }, [editData]);

    return (
        <>

            <div className='main-form-vendorpayment' style={isEditMode ? { pointerEvents: "auto", opacity: 0.5 } : {}}>
                <div className='main-form-card-vendor'  >
                    <div className='form-main-details'>
                        <div className='form-main-vendor'>
                            <div className='form-vendor-inputbox' >
                                <div className='form-main-details-vendor'>
                                    <div className='form-title-vitrual'>
                                        <label htmlFor="">Primary Account No<span className='requrid-status'>*</span></label>
                                    </div>
                                    <div className={userDataErrors.primaryAccountNo ? 'setError-virtual' : 'dropdown-user'} key={uuidv4()} >
                                        <div className='dropdown'>
                                            <DropdownComponent
                                                isDisabled={isEditMode ? true : false}
                                                options={primaryCardDD}
                                                width='300px'
                                                getData={(val) => {
                                                    setIsFormDirty(true)

                                                    vendorfunValue((prevState: any) => ({
                                                        ...prevState,
                                                        primaryAccountNo: val.value,
                                                        primaryID: val.primaryID,
                                                    }));
                                                    dispatch(resetFormModified(true));

                                                }}
                                                defaultValue={[
                                                    {
                                                        label: vendorForm.primaryAccountNo !== "" ? vendorForm.primaryAccountNo : "Select",
                                                        value: vendorForm.primaryAccountNo !== "" ? vendorForm.primaryAccountNo : "Select",
                                                    }
                                                ]}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='form-title-inputbox-vendor' >
                                <div className='form-main-details-vendor'>
                                    <div className='form-title-vitrual'>
                                        <label htmlFor="">Amount<span className='requrid-status'>*</span></label>
                                    </div>
                                    <InputComponent
                                        height={"40px"}
                                        width={"300px"}
                                        padding={"10px 10px"}
                                        border={userDataErrors.amount ?
                                            "1px solid red" : "1px solid #A9C3DC"}
                                        backgroundColor={"white"}
                                        borderRadius={"0px"}
                                        type={"mobile"}
                                        textAlign={"right"}
                                        color={"black"}
                                        disabled={isEditMode ? true : false}

                                        maxLength={14}
                                        placeHolder={"0"}
                                        inputValue={vendorForm.amount}
                                        getUser={(val: any) => {
                                            setIsFormDirty(true)
                                            const onlyNumber = val.replace(/[^0-9]/g, "");
                                            const parsedValue = parseInt(onlyNumber);
                                            const formattedValue = isNaN(parsedValue) ? "" :
                                                parsedValue.toLocaleString('en-IN');
                                            vendorfunValue((prevState: any) => ({
                                                ...prevState,
                                                amount: formattedValue,
                                            }));
                                            dispatch(resetFormModified(true));
                                        }}
                                    />
                                </div>
                            </div>

                        </div>
                        <div className='form-main-vendor'>
                            <div className='form-vendor-inputbox' >
                                <div className='form-main-details-vendor'>
                                    <div className='form-title-vitrual'>
                                        <label htmlFor="">particulars<span className='requrid-status'>*</span></label>
                                    </div>
                                    <InputComponent
                                        height={"40px"}
                                        width={"300px"}
                                        padding={"10px 10px"}
                                        border={userDataErrors.particulars ?
                                            "1px solid red" : "1px solid #A9C3DC"}
                                        backgroundColor={"white"}
                                        borderRadius={"0px"}
                                        type={"text"}
                                        color={"black"}
                                        placeHolder={"Enter Particulars of Invoice"}
                                        maxLength={100} inputValue={vendorForm.particulars}
                                        disabled={isEditMode ? true : false}

                                        getUser={(val: any) => {
                                            setIsFormDirty(true)
                                            vendorfunValue((prevState: any) => ({
                                                ...prevState,
                                                particulars: val,
                                            }));
                                            dispatch(resetFormModified(true));
                                        }}
                                    />
                                </div>
                            </div>
                            <div className='form-title-inputbox-vendor' >
                                <div className='form-main-details-vendor'>
                                    <div className='form-title-vitrual'>
                                        <label htmlFor="">Number of Transaction<span className='requrid-status'>*</span></label>
                                    </div>
                                    <InputComponent
                                        height={"40px"}
                                        width={"300px"}
                                        padding={"10px 10px"}
                                        border={userDataErrors.Transaction ?
                                            "1px solid red" : "1px solid #A9C3DC"}
                                        backgroundColor={"white"}
                                        borderRadius={"0px"}
                                        type={"mobile"}
                                        color={"black"}
                                        disabled={isEditMode ? true : false}

                                        maxLength={14}
                                        placeHolder={"Enter Amount"}
                                        inputValue={vendorForm.Transaction}
                                        getUser={(val: any) => {
                                            setIsFormDirty(true)
                                            const onlyNumber = val.replace(/[^0-9]/g, "");
                                            vendorfunValue((prevState: any) => ({
                                                ...prevState,
                                                Transaction: onlyNumber,
                                            }));
                                            dispatch(resetFormModified(true));
                                        }}
                                    />
                                </div>
                            </div>

                        </div>

                        {/* <div className='Particulars-inputbox'>
                            <div className='vendor-heading'>
                                <label htmlFor="">Particulars<span className='requrid-status'>*</span></label>
                            </div>
                            <InputComponent
                                height={"40px"}
                                width={windowWidth > 1870 ? "853px" : windowWidth > 1500 ? "750px" : windowWidth > 1190 ? "500px" : "350px"}
                                padding={"10px 10px"}
                                border={userDataErrors.particulars ?
                                    "1px solid red" : "1px solid #A9C3DC"}
                                backgroundColor={"white"}
                                borderRadius={"0px"}
                                type={"text"}
                                color={"black"}
                                placeHolder={"Enter Particulars of Invoice"}
                                maxLength={100} inputValue={vendorForm.particulars}
                                disabled={isEditMode ? true : false}

                                getUser={(val: any) => {
                                    setIsFormDirty(true)
                                    vendorfunValue((prevState: any) => ({
                                        ...prevState,
                                        particulars: val,
                                    }));
                                    dispatch(resetFormModified(true));
                                }}
                            />
                        </div> */}
                        <div className='date-main-vendor'>
                            <div className='data-input-vendor' >
                                <div className='vendor-date-title'> Valid from<span className='requrid-status'>*</span></div>
                                <div>
                                    <div className='date-input' style={isEditMode ?
                                        { pointerEvents: "none", opacity: 0.5 } : { pointerEvents: "auto", opacity: 1 }} >
                                        <input type='date' className='date-picker'
                                            style={{ border: userDataErrors.Validfrom ? "1px solid red" : "" }}
                                            // value={vendorForm.Validfrom}
                                            value={vendorForm.Validfrom && moment(vendorForm.Validfrom).format('YYYY-MM-DD')}
                                            // value={vendorForm.Validfrom}
                                            min={todayDate}
                                            max={moment(todayDate).add(1, 'years').format('YYYY-MM-DD')}
                                            disabled={isEditMode ? true : false}
                                            onChange={(value: any) => {
                                                // handleFromDateChange(value)

                                            }} />
                                    </div>
                                </div>
                            </div>

                            <div className='data-input-vendor' style={vendorForm.Validfrom !== "" ? { pointerEvents: "auto", opacity: 1 } : { pointerEvents: "none", opacity: 0.5 }}>
                                <div className='title'> Valid Upto<span className='requrid-status'>*</span></div>
                                <div >
                                    <input type='date' className='date-picker'
                                        style={{ border: userDataErrors.Validupto && vendorForm.Validfrom !== "" ? "1px solid red" : "" }}
                                        // value={vendorForm.Validfrom === "" ? "" : vendorForm.Validupto}
                                        value={vendorForm.Validupto && moment(vendorForm.Validupto).format('YYYY-MM-DD')}
                                        // value={vendorForm.Validfrom === "" ? "" : vendorForm.Validupto}
                                        min={mindate}
                                        max={moment(mindate).add(1, 'years').format('YYYY-MM-DD')}
                                        disabled={(isEditMode) || vendorForm.Validfrom == "" ? true : false}
                                        onChange={(val: any) => {
                                            // handleToDateChange(val)
                                        }} />
                                </div>
                            </div>
                        </div>

                        <div className='Particulars-inputbox'>
                            <div className='vendor-Remarks-heading'>
                                <label htmlFor="">Remarks</label>
                            </div>
                            <InputComponent
                                height={"40px"}
                                width={windowWidth > 1900 ? "853px" : windowWidth > 1500 ? "750px" : windowWidth > 1190 ? "500px" : "350px"}
                                padding={"10px 10px"}
                                border={"1px solid #A9C3DC"}
                                backgroundColor={"white"}
                                borderRadius={"0px"}
                                type={"text"}
                                maxLength={250}
                                disabled={isEditMode ? true : false}

                                color={"black"}
                                placeHolder={"Reason"}
                                inputValue={vendorForm.remarks}
                                getUser={(val: any) => {
                                    setIsFormDirty(true)
                                    vendorfunValue((prevState: any) => ({
                                        ...prevState,
                                        remarks: val,
                                    }));
                                    dispatch(resetFormModified(true));
                                }}
                            />
                        </div>
                    </div>
                    <div className='card-Payment-main'>
                        <div className='card-contant'>
                            <div className='card-main'>
                                <img src={PaymentCard} alt="PaymentCard" className='payment-card' />
                                {/* <p className='virtual-card-number'>
                                    **** **** **** 4511
                                    <p className='card-content' >
                                        MONTH/YEAR
                                    </p>
                                    <div className='card-name-main'>
                                        <p className='font-size'>
                                            Arun
                                        </p>
                                        <div className='date-valid'>
                                            <div>
                                                <p className='font-size-valid'>
                                                    VALID
                                                </p>
                                                <p className='font-size-valid'> THRU</p>
                                            </div>
                                            <p className='date'>
                                                01/2000
                                            </p>
                                        </div>
                                    </div>
                                </p> */}
                            </div>
                            {/* <p className='card-status' >
                                Status :
                            </p> */}
                            <div className='card-details'>
                                <div className='card-title'>
                                    <p> Status </p>
                                    <p> Name on Card</p>
                                    <p> Card Number</p>
                                    <p> Valid till</p>
                                    <p> Amount</p>
                                </div>
                                <div className='card-value'>
                                    <p>:{ } </p>
                                    <p>:{empolyeeDetails.name} </p>
                                    <p>:{empolyeeDetails.cardNumber} </p>
                                    <p>:{empolyeeDetails.validTill} </p>
                                    <p>:{empolyeeDetails.amount} </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className='footer-vendor-approval'>
                <ButtonComponent
                    title={"Cancel"}
                    height={"50px"}
                    width={"150px"}
                    margin={"0px 8px"}
                    backgroundColor={"#888888"}
                    color={"white"}
                    className={"button-component-hover cancel"}
                    handleClick={() => { returnGird() }}
                />
                <ButtonComponent
                    title={"Save"}
                    height={"50px"}
                    width={"150px"}
                    backgroundColor={"#0055D4"}
                    color={"white"}
                    disabled={!isDisabled || showType === "success"}
                    margin={"0px 8px"}
                    className={!isDisabled || showType === "success" ? "button-component-hover disabled" : "button-component-hover common-btn"}
                    handleClick={async () => {
                        // setShowMessage(Object.values(updatedIsValueValidAll).every((values) => !values) ? "Some of the field(s) are not in required format." : "Mandatory field(s) should not be left blank.")
                        if (approvalStatus.status === "Approved") {
                            otpsend()
                            setIsDisabled(true)
                        } else {
                            updateData()
                            setIsDisabled(true)

                        }
                        // virtualCardSave()
                    }}
                />
            </div> */}

            {
                showAlertBox &&
                <div className='alert-warp'>
                    <AlertBox type={showType} message={showMessage}
                    />
                </div>
            }

            {
                isLoading &&
                <Loading />
            }

        </>


    )
}

