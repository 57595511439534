import React, { useState } from 'react'
import "../../../styles/product-admin/kyc/transaction-Charges.scss"
import InputComponent from '../../common-component/form-elements/input-component';
import DropdownComponent from '../../common-component/form-elements/dropdown-component';
import IconButton from '../../common-component/form-elements/icon-button';
import KycTransactionModel from './kyc-transaction-model';
import ButtonComponent from '../../common-component/form-elements/button-component';
import { HiOutlineArrowSmDown } from "react-icons/hi";
import { HiOutlineArrowSmUp } from "react-icons/hi";
import { v4 as uuidv4 } from 'uuid'
import AlertBox from '../../common-component/alert-box';
import { axiosPrivate } from '../../../middleware/axois-api';
import Loading from '../../common-component/modals/loading-screen';
import DeleteModal from '../../common-component/modals/delete-modal';
import { useDispatch } from "react-redux";
import { resetFormModified } from "../../../redux/action";

interface transProps {
    transactionData: any,
    setTransactionData: any,
    kycTransactionData: any,
    setKycTransactionData: any,
    currentMode: any,
    setCurrentMode: any,
    kycCurrentMode: any,
    setKycCurrentMode: any,
    transactionTable: any;
    setTransactionTable: any
    setUserDataErrors: any
    userDataErrors: any
    companyDetails: any
    validateForm: any
    validateFormRateCard: any
    updatedIsValueValidAll: any
    tableData: any
    setTableData: any
    setIsFormDirty: any
    getOverallDetails: (val: any) => void;
}

export default function TransactionCharges({
    transactionData,
    kycCurrentMode,
    setKycCurrentMode,
    setTransactionData,
    setKycTransactionData,
    kycTransactionData,
    currentMode,
    setCurrentMode,
    transactionTable,
    setTransactionTable,
    userDataErrors,
    setUserDataErrors,
    companyDetails,
    validateForm,
    validateFormRateCard,
    updatedIsValueValidAll,
    tableData,
    setTableData,
    setIsFormDirty,
    getOverallDetails
}: transProps) {
    const [showRateCard, setShowRateCard] = useState(false);
    const [selectedOption, setSelectedOption] = useState("Fixed");
    const [editingIndex, setEditingIndex] = useState(null);
    const [showAlertBox, setShowAlertBox] = useState<boolean>(false)
    const [showMessage, setShowMessage] = useState<string>("")
    const [showType, setShowType] = useState<string>("warning")
    const [loadingScreen, setLoadingScreen] = useState<boolean>(false)
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
    const [deleteIndex, setDeleteIndex] = useState<number>()
    const [transactionId, setTransactionId] = useState<number>()

    const dispatch = useDispatch();

    const paymentGatewayDropdown = [
        { label: "Razorpay", value: "Razorpay" },
        // { label: "Fiserv", value: "Fiserv" },
        // { label: "Lyra", value: "Lyra" },
    ]
    const commercialcardDropdown = [
        { label: "Fixed", value: "Fixed" },
        { label: "Variable", value: "Variable" },
    ]
    const gstDropdown = [
        { label: "5%", value: "5%" },
        { label: "12%", value: "12%" },
        { label: "18%", value: "18%" },
        { label: "28%", value: "28%" },
    ];
    const platformGstDropdown = [
        { label: "5%", value: "5%" },
        { label: "12%", value: "12%" },
        { label: "18%", value: "18%" },
        { label: "28%", value: "28%" },
    ];

    // Function for Save------------------------->
    const handleAdd = async () => {

        // const invalidFields = ['pgChg', 'platformChg', 'netBanking', 'virtualCard', 'upi'];
        // const invalidValue = invalidFields.some(field => transactionData[field] === "0");
        // if (invalidValue) {
        //     setShowAlertBox(true);
        //     setShowType("warning");
        //     setShowMessage("Please do not enter 0 as value.");

        //     setTimeout(() => {
        //         setShowAlertBox(false);
        //     }, 5000);
        //     return;
        // }

        let updatedTable = transactionTable;
        if (transactionData.transactionChargesId) {
            updatedTable = transactionTable.map((item: any) =>
                item.transactionChargesId === transactionData.transactionChargesId ? transactionData : item
            );
        } else {
            updatedTable = [...transactionTable, transactionData];
        }
        // setTransactionTable(updatedTable);

        // Save the data
        setLoadingScreen(true);
        try {
            const response = await axiosPrivate.post(`customer-onboarding/transaction-rate-card/create`, {
                companyDetails: companyDetails,
                transactionTable: [transactionData],
                kycTransactionData: tableData
            });

            console.log(response, "RESPONSE")
            if (response.status === 200) {
                setLoadingScreen(false);
                setShowAlertBox(true);
                setShowType("success");
                setShowMessage(response.data.message);
                getOverallDetails(companyDetails.companyId)

                setTimeout(() => {
                    setShowAlertBox(false);
                }, 5000);

                setTransactionData({
                    paymentGateway: "",
                    commercialCard: "Fixed",
                    pgChg: "",
                    pgGst: "",
                    platformChg: "",
                    platformGst: "",
                    netBanking: "",
                    virtualCard: "",
                    upi: "",
                });

                // Set priority for the new first record if this is the first record
                // if (updatedTable.length === 1) {
                //     updatePriority(updatedTable[0].transactionChargesId);
                // }

            }

            if (response.status === 400) {
                setLoadingScreen(false);
                setShowAlertBox(true);
                setShowType("danger");
                setShowMessage(response.data.error);
                getOverallDetails(companyDetails.companyId)

                setTimeout(() => {
                    setShowAlertBox(false);
                }, 5000);
            }
        } catch (error: any) {
            setLoadingScreen(false);
            setShowAlertBox(true);
            setShowType("warning");
            setShowMessage(error.message);

            setTimeout(() => {
                setShowAlertBox(false);
            }, 5000);
        }
        setCurrentMode('addmode')
    };

    // Function to update priority in the backend  (PENDING NOT FINISHED) ------------------->
    const updatePriority = async (id: any, clearPrevious = false) => {
        try {
            await axiosPrivate.patch('/customer-onboarding/transaction/updatePriority', { id, clearPrevious });
            console.log(`Priority updated for record with id: ${id}`);
        } catch (error) {
            console.error('Error updating priority', error);
        }
    };

    // Function for move down the priority ----------------------->
    const handleMoveDown = (index: number) => {
        if (index < transactionTable.length - 1) {
            const newData = [...transactionTable];
            const temp = newData[index];
            newData[index] = newData[index + 1];
            newData[index + 1] = temp;
            setTransactionTable(newData);
        }
    };

    // Function for move up the priority ----------------------->
    const handleMoveUp = (index: number) => {
        if (index > 0) {
            const newData = [...transactionTable];
            const temp = newData[index];
            newData[index] = newData[index - 1];
            newData[index - 1] = temp;
            setTransactionTable(newData);

            // if (index - 1 === 0) {
            //     updatePriority(newData[0].transactionChargesId, true);
            // }
        }
    };

    // Function for Delete the Row Data ----------------------->
    const handleDelete = async (transactionChargesId: any, index: any) => {
        if (transactionChargesId) {
            try {
                const response = await axiosPrivate.patch(`/customer-onboarding/transaction-charges/delete/${transactionChargesId}`);
                if (response.status === 200) {
                    getOverallDetails(companyDetails.companyId)
                    setTransactionTable(transactionTable.filter((_: any, idx: any) => idx !== index));
                    setShowDeleteModal(false)
                    setShowAlertBox(true);
                    setShowType("success");
                    setShowMessage(response.data.message)
                    setTimeout(() => {
                        setShowAlertBox(false);
                    }, 5000);
                }
            } catch (error: any) {
                setShowDeleteModal(false)
                setShowAlertBox(true);
                setShowType("warning");
                setShowMessage(error.response.data.error)
                setTimeout(() => {
                    setShowAlertBox(false);
                }, 5000);
            }
        } else {
            setTransactionTable(transactionTable.filter((_: any, idx: any) => idx !== index));
            setShowDeleteModal(false)
        }
    };

    // Function for Edit the record ----------------------->
    const handleEdit = (data: any, index: any) => {
        if (data.transactionChargesId) {
            const rowData = transactionTable[index];
            setTransactionData({
                transactionChargesId: rowData.transactionChargesId,
                paymentGateway: rowData.paymentGateway,
                commercialCard: rowData.commercialCard,
                pgChg: rowData.pgChg,
                pgGst: rowData.pgGst,
                platformChg: rowData.platformChg,
                platformGst: rowData.platformGst,
                netBanking: rowData.netBanking,
                virtualCard: rowData.virtualCard,
                upi: rowData.upi,
            });
            setEditingIndex(index);
            setCurrentMode('editmode');
        } else {
            const rowData = transactionTable[index];
            setTransactionData({
                paymentGateway: rowData.paymentGateway,
                commercialCard: rowData.commercialCard,
                pgChg: rowData.pgChg,
                pgGst: rowData.pgGst,
                platformChg: rowData.platformChg,
                platformGst: rowData.platformGst,
                netBanking: rowData.netBanking,
                virtualCard: rowData.virtualCard,
                upi: rowData.upi,
            });
            setEditingIndex(index);
            setCurrentMode('editmode');
        }
    };

    // Function for get the Rate Card Data (Variable)----------------------->
    const getRateCardData = async (id: number) => {
        try {
            const response = await axiosPrivate.get(`customer-onboarding/rateCard/get/${id}`)
            let rateCardDetails: any[] = []
            if (response.data.rateCardDatas) {
                response.data.rateCardDatas.map((data: any) => {
                    rateCardDetails.push({
                        rateCardId: data.rateCardId,
                        from: data.from,
                        to: data.to,
                        mdr: data.mdr,
                        transactionChargesId: data.transactionChargesId,
                        customerOnboardingId: data.customerOnboardingId,
                        organisationId: data.organisationId,
                        company_id: data.company_id,
                    })
                })
                setTableData(rateCardDetails)
            } else {
                setTableData([])
            }
        } catch (error: any) {
            console.log(error, "Error")
        }
    }

    return (
        <div className='transaction-main'>
            <div className='transaction-header'>
                <span className='transaction-header-text'>Transaction Charges</span>
            </div>
            <div className='transaction-inputs'>
                <div className='transaction-input-one'>
                    <div key={uuidv4()} className={userDataErrors.paymentGateway ? 'setError' : 'transaction-ind-input'}>
                        <DropdownComponent
                            options={paymentGatewayDropdown}
                            title={"Payment Gateway"}
                            className={"kyc-dropdown"}
                            required={true}
                            isDisabled={false}
                            defaultValue={[{
                                value: transactionData.paymentGateway !== "" ? transactionData.paymentGateway : "Select",
                                label: transactionData.paymentGateway !== "" ? transactionData.paymentGateway : "Select",
                            }]}
                            getData={(value) => {
                                setIsFormDirty(true)
                                dispatch(resetFormModified(true));
                                setTransactionData({ ...transactionData, paymentGateway: value.value });
                            }}
                        />
                    </div>
                    <div className='transaction-commercial'>
                        <div className='transaction-commercial-1'>
                            <div>
                                <span className='commercial-title'>Commercial Card</span><span style={{ color: 'red' }}>*</span>
                            </div>
                            {transactionData.commercialCard === "Fixed" &&
                                <div>
                                    <span>PG Charges %</span><span style={{ color: 'red' }}>*</span>
                                </div>
                            }
                        </div>
                        <div className='transaction-commercial-2'>
                            <div key={uuidv4()} className={userDataErrors.commercialCard ? 'setError' : 'commercial-dropdown'}>
                                <DropdownComponent
                                    options={commercialcardDropdown}
                                    required={true}
                                    className={"kyc-dropdown"}
                                    isDisabled={true}
                                    defaultValue={[{
                                        value: transactionData.commercialCard !== "" ? transactionData.commercialCard : "Fixed",
                                        label: transactionData.commercialCard !== "" ? transactionData.commercialCard : "Fixed",
                                    }]}
                                    getData={(value) => {
                                        setIsFormDirty(true)
                                        dispatch(resetFormModified(true));
                                        setTransactionData({ ...transactionData, commercialCard: value.value })
                                    }}
                                />
                            </div>
                            {transactionData.commercialCard === "Fixed" ? (
                                <div className='commercial-input'>
                                    <div>
                                        <InputComponent
                                            height="40px"
                                            width="130px"
                                            margin="0px"
                                            padding={"0px 20px 0px 0px"}
                                            border={userDataErrors.pgChg ? "1px solid red" : "1px solid #A9C3DC"}
                                            borderRadius="0px"
                                            backgroundColor="white"
                                            color="black"
                                            required={true}
                                            type="text"
                                            disabled={false}
                                            maxLength={5}
                                            textAlign={"right"}
                                            inputValue={transactionData.pgChg}
                                            placeHolder={"0"}
                                            getUser={(value) => {
                                                setIsFormDirty(true)
                                                let cleanedValue = value.replace(/[^0-9.]/g, '');
                                                if (cleanedValue.startsWith('.')) {
                                                    cleanedValue = '0' + cleanedValue;
                                                }
                                                setTransactionData({ ...transactionData, pgChg: cleanedValue })
                                                dispatch(resetFormModified(true));
                                            }}
                                        />
                                    </div>
                                    {/* <InputComponent
                                        height="40px"
                                        width="50px"
                                        margin="0px"
                                        padding="0px 0px 0px 15px"
                                        border="1px solid #A9C3DC"
                                        borderRadius="0px"
                                        backgroundColor="white"
                                        color="black"
                                        required={true}
                                        disabled={true}
                                        type="text"
                                        maxLength={50}
                                        inputValue="%"
                                        placeHolder=""
                                        getUser={() => { }}
                                    /> */}
                                </div>
                            ) : transactionData.commercialCard === "Variable" ? (
                                <div>
                                    <button className='rate-card-button' onClick={() => setShowRateCard(true)}>
                                        Rate Card
                                    </button>
                                    {showRateCard && (
                                        <KycTransactionModel
                                            setShowRateCard={setShowRateCard}
                                            kycTransactionData={kycTransactionData}
                                            setKycTransactionData={setKycTransactionData}
                                            kycCurrentMode={kycCurrentMode}
                                            setKycCurrentMode={setKycCurrentMode}
                                            companyDetails={companyDetails}
                                            validateFormRateCard={validateFormRateCard}
                                            updatedIsValueValidAll={updatedIsValueValidAll}
                                            userDataErrors={userDataErrors}
                                            setUserDataErrors={setUserDataErrors}
                                            tableData={tableData}
                                            setTableData={setTableData}
                                        />
                                    )}
                                </div>
                            ) : null}
                        </div>
                    </div>
                    <div key={uuidv4()} className={userDataErrors.pgGst ? 'setError' : 'transaction-ind-input'}>
                        <DropdownComponent
                            options={gstDropdown}
                            title={"PG GST %"}
                            className={"kyc-dropdown"}
                            required={true}
                            isDisabled={false}
                            defaultValue={[{
                                value: transactionData.pgGst !== "" ? transactionData.pgGst : "Select",
                                label: transactionData.pgGst !== "" ? transactionData.pgGst : "Select",
                            }]}
                            getData={(value) => {
                                setIsFormDirty(true)
                                dispatch(resetFormModified(true));
                                setTransactionData({ ...transactionData, pgGst: value.value });
                            }}
                        />
                    </div>
                    <div className='transaction-ind-input-platform'>
                        <InputComponent
                            height={"40px"}
                            width={"165px"}
                            margin={"0px 0px 0px 0px"}
                            padding={"0px 20px 0px 0px"}
                            border={userDataErrors.platformChg ? "1px solid red" : "1px solid #A9C3DC"}
                            borderRadius={"0px"}
                            backgroundColor={"white"}
                            color={"black"}
                            type={"text"}
                            required={true}
                            textAlign={"right"}
                            maxLength={5}
                            inputTitle={"Platform Charges %"}
                            inputValue={transactionData.platformChg}
                            placeHolder={'0'}
                            getUser={(value) => {
                                setIsFormDirty(true);
                                let numericValue = value.replace(/[^0-9.]/g, '');
                                if (numericValue.startsWith('.')) {
                                    numericValue = '0' + numericValue;
                                }
                                setTransactionData({ ...transactionData, platformChg: numericValue });
                                dispatch(resetFormModified(true));
                            }}
                        />

                        {/* className = "setError" is default but this dropdown has setErrors so modify this className instead of this setError className */}
                        <div style={{ width: '145px', height: '40px' }} key={uuidv4()} className={userDataErrors.platformGst ? 'setErrors' : ''}>
                            <DropdownComponent
                                options={platformGstDropdown}
                                title={"Platform GST %"}
                                required={true}
                                className={"kyc-dropdown"}
                                isDisabled={false}
                                defaultValue={[{
                                    value: transactionData.platformGst !== "" ? transactionData.platformGst : "Select",
                                    label: transactionData.platformGst !== "" ? transactionData.platformGst : "Select",
                                }]}
                                getData={(value) => {
                                    setIsFormDirty(true)
                                    dispatch(resetFormModified(true));
                                    setTransactionData({ ...transactionData, platformGst: value.value });
                                }}
                            />
                        </div>
                    </div>
                    <div className='transaction-ind-input'>
                        <InputComponent
                            height={"40px"}
                            width={"300px"}
                            margin={"0px 0px 0px 0px"}
                            padding={"0px 20px 0px 0px"}
                            border={userDataErrors.netBanking ? "1px solid red" : "1px solid #A9C3DC"}
                            borderRadius={"0px"}
                            backgroundColor={"white"}
                            color={"black"}
                            type={"text"}
                            textAlign={"right"}
                            required={true}
                            maxLength={4}
                            inputTitle={"Net Banking %"}
                            inputValue={transactionData.netBanking}
                            placeHolder={'Enter Percentage'}
                            getUser={(value) => {
                                setIsFormDirty(true)
                                let numericValue = value.replace(/[^0-9.]/g, '');
                                if (numericValue.startsWith('.')) {
                                    numericValue = '0' + numericValue;
                                }
                                setTransactionData({ ...transactionData, netBanking: numericValue });
                                dispatch(resetFormModified(true));
                            }}
                        />
                    </div>


                    <div className='transaction-ind-input'>
                        <InputComponent
                            height={"40px"}
                            width={"300px"}
                            margin={"0px 0px 0px 0px"}
                            padding={"0px 20px 0px 0px"}
                            border={userDataErrors.virtualCard ? "1px solid red" : "1px solid #A9C3DC"}
                            borderRadius={"0px"}
                            backgroundColor={"white"}
                            color={"black"}
                            type={"text"}
                            required={true}
                            textAlign={"right"}
                            maxLength={3}
                            inputTitle={"Virtual Card ₹"}
                            inputValue={transactionData.virtualCard}
                            placeHolder={'Enter Amount'}
                            getUser={(value) => {
                                setIsFormDirty(true)
                                let numericValue = value.replace(/[^0-9.]/g, '');
                                if (numericValue.startsWith('.')) {
                                    numericValue = '0' + numericValue;
                                }
                                setTransactionData({ ...transactionData, virtualCard: numericValue });
                                dispatch(resetFormModified(true));
                            }}
                        />
                    </div>
                    <div className='transaction-ind-input'>
                        <InputComponent
                            height={"40px"}
                            width={"300px"}
                            margin={"0px 0px 0px 0px"}
                            padding={"0px 20px 0px 0px"}
                            border={userDataErrors.upi ? "1px solid red" : "1px solid #A9C3DC"}
                            borderRadius={"0px"}
                            backgroundColor={"white"}
                            color={"black"}
                            type={"text"}
                            required={true}
                            textAlign={"right"}
                            maxLength={4}
                            inputValue={transactionData.upi}
                            inputTitle={"UPI %"}
                            placeHolder={'Enter Percentage'}
                            getUser={(value) => {
                                setIsFormDirty(true)
                                let numericValue = value.replace(/[^0-9.]/g, '');
                                if (numericValue.startsWith('.')) {
                                    numericValue = '0' + numericValue;
                                }
                                setTransactionData({ ...transactionData, upi: numericValue })
                                dispatch(resetFormModified(true));
                            }}
                        />
                    </div>

                </div>
                <div className='transaction-ind-footer'>
                    <div style={{ marginTop: '35px' }}>
                        <ButtonComponent
                            title={currentMode === 'addmode' ? 'Add' : 'Update'}
                            height={"40px"}
                            width={"130px"}
                            backgroundColor={"#0055D4"}
                            color={"white"}
                            margin={"0px 0px"}
                            disabled={false}
                            className={"button-component-hover common-btn"}
                            handleClick={() => {
                                const valid = validateForm()
                                if (valid) {
                                    handleAdd()
                                } else {
                                    setShowAlertBox(true);
                                    setShowType("danger")
                                    setShowMessage(Object.values(updatedIsValueValidAll).every((values) => !values) ?
                                        "Some of the field(s) are not in required format." : "Mandatory field(s) should not be left blank.")
                                    setTimeout(() => {
                                        setShowAlertBox(false);
                                    }, 5000);
                                }
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className='kyc-transation-table' style={{ height: "275px" }} >
                <table>
                    <thead>
                        <tr>
                            <th className='action-row'>Action</th>
                            <th className='priority-row'>Priority</th>
                            <th className='payment-gateway-row'>Payment Gateway</th>
                            <th className='commercialCard-row'>Commercial Card</th>
                            <th className='pgchg-row'>PG Charges %</th>
                            <th className='gst-row'>PG GST %</th>
                            <th className='pt-chg-row'>Platform Charges %</th>
                            <th className='pt-gst-row'>Platform GST %</th>
                            <th className='net-banking-row'>Net Banking %</th>
                            <th className='virtulaCard-row'>Virtual Card &#8377;</th>
                            <th className='upi-row'>UPI %</th>
                        </tr>
                    </thead>

                    <tbody>
                        {transactionTable?.length === 0 ?
                            <div className={'main-body-header'}>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td>No records to show</td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            </div> :
                            transactionTable.map((data: any, i: any) => (
                                <div className={'main-body-header'} key={i} style={{ marginBottom: '2px' }}>
                                    <tr>
                                        <td className={'action-row'}>
                                            <IconButton
                                                iconName={"Edit"}
                                                height={"35px"}
                                                width={"40px"}
                                                fontSize={"30px"}
                                                className={"icon-button"}
                                                cursor={"pointer"}
                                                margin={""}
                                                color={"white"}
                                                backgroundColor={"#0055D4"}
                                                hover={false}
                                                handleClick={() => { handleEdit(data, i); getRateCardData(data.transactionChargesId) }}
                                            />
                                            <IconButton
                                                iconName={"Delete"}
                                                height={"35px"}
                                                width={"40px"}
                                                fontSize={"30px"}
                                                className={"icons"}
                                                cursor={"pointer"}
                                                margin={""}
                                                color={"white"}
                                                backgroundColor={"#D60000"}
                                                hover={true}
                                                handleClick={() => {
                                                    setDeleteIndex(i);
                                                    setTransactionId(data.transactionChargesId)
                                                    setShowDeleteModal(true)
                                                }}
                                            />
                                        </td>
                                        <td className={"priority-row"}>
                                            {/* {i === 0 ? 'Priority' : ''} */}
                                            <HiOutlineArrowSmUp className='arrows' onClick={() => handleMoveUp(i)} />
                                            <HiOutlineArrowSmDown className='arrows' onClick={() => handleMoveDown(i)} />
                                        </td>
                                        <td className={'payment-gateway-row'}>{data.paymentGateway} </td>
                                        <td className={'commercialCard-row'}>{data.commercialCard} </td>
                                        <td className={'pg-chg-row'}>{parseFloat(data.pgChg).toFixed(2)}</td>
                                        <td className={'gst-row'}>{parseFloat(data.pgGst).toFixed(2)}</td>
                                        <td className={"plt-gst-row"}>{parseFloat(data.platformChg).toFixed(2)}</td>
                                        <td className={"plt-chg-row"}>{parseFloat(data.platformGst).toFixed(2)}</td>
                                        <td className={"net-banking-row"}>{parseFloat(data.netBanking).toFixed(2)}</td>
                                        <td className={"virtulaCard-row"}>{parseFloat(data.virtualCard).toFixed(2)}</td>
                                        <td className={"upi-row"}>{parseFloat(data.upi).toFixed(2)}</td>
                                    </tr>
                                </div>
                            ))}

                    </tbody>

                </table>
            </div>

            {showAlertBox &&
                <AlertBox type={showType} message={showMessage}
                />
            }
            {
                showDeleteModal &&
                <div>
                    <DeleteModal
                        getDelete={() => { handleDelete(transactionId, deleteIndex) }}
                        clearValue={(value) => { setShowDeleteModal(value) }}
                        modelType={"grid-delete"}
                    />
                </div>
            }
            {
                loadingScreen && <Loading />
            }
        </div >
    )
}
