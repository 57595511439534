import React, { useState, useRef, ChangeEvent, KeyboardEvent, useEffect } from 'react';
import ButtonComponent from '../common-component/form-elements/button-component';
import axios from 'axios';
import { encryptToken } from '../../services/utils/token-utils';
import { setCookie } from '../../services/utils/cookie-utils';
import useAuth from '../../services/hooks/useauth';
import { useNavigate } from 'react-router-dom';
import '../../styles/login/otp-verification.scss';
import useAxiosPrivate from '../../services/hooks/useaxios-private';




interface Props {
    resetOtpAttemptsRemaining: number;
    updatedPassword: string
    isForgetPassword: boolean;
    passwordMail: string;
    handleclick: (val: any) => void;
    loginStage: string;
}

export default function PasswordExpireOtp({ handleclick, passwordMail, updatedPassword, resetOtpAttemptsRemaining, isForgetPassword }: Props) {
    const [forgotPassword, setForgotPassword] = useState<boolean>(isForgetPassword)
    const [userSessionId, setUserSessionId] = useState<number>()
    const [errorMessage, setErrorMessage] = useState<string>("")
    const [disabled, setDisabled] = useState<boolean>(false)
    const [successMessage, setSuccessMessage] = useState<string>("")
    const [hideMobileNumber, setHideMobileNumber] = useState<string>("")
    const [attemptsRemaining, setAttemptsRemaining] = useState(resetOtpAttemptsRemaining);


    const axiosPrivate = useAxiosPrivate();
    const { setAuth } = useAuth();
    const navigate = useNavigate();
    // OTP 

    const length = 6;
    const [otp, setOTP] = useState<string[]>(Array(length).fill(''));
    const [emailOtp, setEmailOtp] = useState<string[]>(Array(length).fill(''));


    const inputRefs = useRef<HTMLInputElement[]>([]);
    const inputEmailRefs = useRef<HTMLInputElement[]>([]);

    useEffect(() => {
        mobileNumber()
    }, [])
    useEffect(() => {
        if (inputRefs.current.length > 0 && inputRefs.current[0]) {
            inputRefs.current[0].focus();
        }
    }, []);

    const handleChange = (e: ChangeEvent<HTMLInputElement>, index: number, inputName: string) => {
        const value = e.target.value;
        if (!(/^\d*$/.test(value))) return;
        if (inputName === "emailOtp") {
            if (e.target.value.length > 1) return;
            const updatedOTP = [...emailOtp];
            updatedOTP[index] = e.target.value;
            setEmailOtp(updatedOTP);
            if (e.target.value !== '' && index < length - 1) {
                inputEmailRefs.current[index + 1].focus();
            }
        } else {
            if (e.target.value.length > 1) return;
            const updatedOTP = [...otp];
            updatedOTP[index] = e.target.value;
            setOTP(updatedOTP);


            if (e.target.value !== '' && index < length - 1) {
                inputRefs.current[index + 1].focus();
            }
            setErrorMessage("")
            setSuccessMessage("")
        }

    };

    const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>, index: number, inputName: string) => {

        if (e.key === 'Backspace' && index > 0 && otp[index] === '') {
            inputRefs.current[index - 1].focus();
        }
        if (e.key === 'Enter') {
            otpVerify()

        }

    };

    const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>, inputName: string) => {
        e.preventDefault();
        if (inputName === "emailOtp") {
            const pasteData = e.clipboardData.getData('text/plain');
            const newOTP = pasteData.slice(0, length).split('');

            const updatedOTP = newOTP.concat(Array(length - newOTP.length).fill(''));

            setEmailOtp(updatedOTP);
            inputEmailRefs.current[0].focus();
        } else {
            const pasteData = e.clipboardData.getData('text/plain');
            const numbersOnly = pasteData.replace(/\D/g, '');
            const newOTP = numbersOnly.slice(0, length).split('');

            const updatedOTP = newOTP.concat(Array(length - newOTP.length).fill(''));

            setOTP(updatedOTP);
            inputRefs.current[0].focus();
        }
    };


    async function otpVerify() {
        const stringFromArray = otp.join('');
        if (forgotPassword && stringFromArray !== "") {
            setSuccessMessage("")
            try {
                const res = await axiosPrivate.post('verify/otp/re-send', { "email": encryptToken(passwordMail), "password": encryptToken(updatedPassword), "otp": encryptToken(stringFromArray) })
                if (res.status === 200) {
                    {
                        setUserSessionId(res.data.newUserSession.userSessionId)
                        setAuth({
                            token: res.data.token,
                            userSessionId: res.data.newUserSession.userSessionId,
                            email: res.data.email,
                            UserType: res.data.UserType,
                            UserPrivilege: res.data.UserPrivilege,
                            userGroup: res.data.UserGroup,
                            CurrentPaymentActor: res.data.CurrentPaymentActor,
                            sessionRecordId: res.data.sessionRecordId
                        })
                        const encryptedToken = encryptToken(res.data.token);

                        // Store the encrypted token in cookies
                        setCookie('token', encryptedToken);
                        if (res.data.userIsAn === "existing User") {
                            navigate("/dashboard")
                        } else {
                            navigate("/user-profile")
                        }
                    }
                }
            } catch (error: any) {
                setErrorMessage(error.response.data.message)
                if (error.response.data.otpRemainingCount !== undefined) {
                    setAttemptsRemaining(error.response.data.otpRemainingCount);
                }
                if (error.response.data.showErrorPage) {
                    navigate("/accounterror", { state: { message: error.response.data.message } });
                }
            }
        }
    }

    async function resendOtpVerify() {
        if (forgotPassword) {

            const updatedOtp = Array(length).fill('');
            setOTP(updatedOtp);
            setErrorMessage("")
            setDisabled(true)
            try {
                const res = await axiosPrivate.post('forgot-password/resend/otp', { "email": encryptToken(passwordMail) })
                if (res.status === 200) {
                    setSuccessMessage(res.data.message)
                }


            } catch (error: any) {
                setErrorMessage(error.response.data.error)
                if (error.response.data.otpRemainingCount !== undefined) {
                    setAttemptsRemaining(error.response.data.otpRemainingCount);
                }
                if (error.response.data.showErrorPage) {
                    navigate("/accounterror", { state: { message: error.response.data.message } });
                }
            } finally {
                setDisabled(false)
            }
        } else if (!forgotPassword) {

            const updatedOtp = Array(length).fill('');
            setOTP(updatedOtp);
            setErrorMessage("")
            setDisabled(true)
            try {
                const res = await axiosPrivate.post('resend/otp', { "email": encryptToken(passwordMail) })
                if (res.status === 200) {
                    setSuccessMessage(res.data.message)
                }

            } catch (error: any) {
                setErrorMessage(error.response.data.error)
                if (error.response.data.otpRemainingCount !== undefined) {
                    setAttemptsRemaining(error.response.data.otpRemainingCount);
                }
                if (error.response.data.showErrorPage) {
                    navigate("/accounterror", { state: { message: error.response.data.message } });
                }
            } finally {
                setDisabled(false)
            }
        }
    }
    async function mobileNumber() {
        try {
            const res = await axiosPrivate.post('user/mobile_number', { "email": encryptToken(passwordMail) })
            if (res.data?.user.maskedVal) {
                setHideMobileNumber(res.data.user.maskedVal)
            }

        } catch (error: any) {
        }
    }

    return (
        <div className='otp-verification'>
            <div className='title'>{"OTP Verification"}</div>


            {forgotPassword && <div className='text'>{"Enter first 3 digits of the OTP sent to your Mobile and last 3 digits sent to your Email"}</div>}

            {!forgotPassword && <div className='text'>{"A 6-digit OTP has been sent to your registered " + hideMobileNumber + ". This OTP will expire in 3 mins."}</div>}

            <div className={errorMessage ? 'wrong-otp' : 'otp-number'}>

                {otp.map((digit, index) => (
                    <input
                        key={index}
                        type="text"
                        className={`${forgotPassword && digit === "" ? (index < 3 ? 'mobile-placeholder' : 'email-placeholder') : ''}`}
                        value={digit}
                        maxLength={1}
                        pattern='[0-9]*'
                        onChange={(e) => handleChange(e, index, "mobileOtp")}
                        onKeyDown={(e) => handleKeyPress(e, index, "mobileOtp")}
                        onPaste={(e) => handlePaste(e, "mobileOtp")}
                        ref={(ref) => (inputRefs.current[index] = ref as HTMLInputElement)}

                    />
                ))}

            </div>
            <div className='resend-text'>Didn't get a code?<button className='resend-button' disabled={disabled} onClick={() => resendOtpVerify()}>Click to resend.</button></div>
            <div className='verify-btn'>
                <ButtonComponent
                    title={"Verify"}
                    height={"40px"}
                    width={"100%"}
                    disabled={otp.filter((digit) => { return digit === "" }).length > 0}
                    boxShadow={"0px 10px 20px #0066FF80"}
                    backgroundColor={"#FFFFFF"}
                    color={"#0055D4"}
                    handleClick={() => {
                        otpVerify()
                    }}
                    className={otp.filter((digit) => { return digit === "" }).length > 0 ? "button-component-hover disabled" : "button-component-hover"}
                />
            </div>
            {errorMessage && <p className="error">{errorMessage}</p>}
            {successMessage && <p className="success">{successMessage}</p>}
            <div className='resend-timing'>{"*Attempts remaining: " + attemptsRemaining}</div>
        </div>
    )
}
