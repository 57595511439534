import React, { useEffect, useState, useRef, forwardRef, useImperativeHandle, } from 'react'
import InputComponent from '../../../common-component/form-elements/input-component'
import DropdownComponent from '../../../common-component/form-elements/dropdown-component'
import axios from 'axios'
import ButtonComponent from '../../../common-component/form-elements/button-component';
import BankRelationship from '../../../../config/onboarding/bank-relationship-grid';
import { v4 as uuidv4 } from 'uuid'
import useAxiosPrivate from "../../../../services/hooks/useaxios-private";
import AlertBox from '../../../common-component/alert-box';
import { useDispatch, useSelector } from "react-redux";
import { resetFormModified } from "../../../../redux/action";
import Changesmodal from '../../../common-component/modals/changes-modal';
import Loading from '../../../common-component/modals/loading-screen';

interface DropdownItem {
  label: string;
  value: string;
}
export interface ChildRef {
  next: () => void;
}
interface props {
  onboardingViewfun: (val: any) => void;
  setBankRelationshipMain: (val: any) => void;
  bankRelationshipMain: any;
  vendorBankApprovalDetails: any;
  mainApiData: any
  iconStatus: any;
  editData: any;
  setShowPage: (val: any) => void;
  setIconStatus: (val: any) => void;
  isEditMode: boolean;
  isDisabled: boolean;
 
  sendDataApi: () => void;
}

const BankDetails = forwardRef<ChildRef, props>(({ onboardingViewfun, mainApiData, isDisabled,vendorBankApprovalDetails, iconStatus, editData, isEditMode, setShowPage, sendDataApi, setIconStatus, setBankRelationshipMain, bankRelationshipMain }, ref) => {
  const [dropDownRegion, setDropDownRegion] = useState<DropdownItem[]>([])
  const [dropDownState, setDropDownState] = useState<DropdownItem[]>([])
  const [dropDownCities, setDropDownCities] = useState<DropdownItem[]>([])
  const [dropDownBranch, setDropDownBranch] = useState<DropdownItem[]>([])
  const [formDataEdit, setFormDataEdit] = useState(false)
  const [isDuplicate, setIsDuplicate] = useState(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [currentIndex, setCurrentIndex] = useState<number>()
  const [currentBankRelationship, setCurrentBankRelationship] = useState<number>(0)
  const axiosPrivate = useAxiosPrivate();
  //BankContact details
  const [bankRelationError, setBankRelationError] = useState<Record<string, boolean>>({})
  const [showType, setShowType] = useState("warning") // error message showType
  const [showMessage, setShowMessage] = useState<string>("") // error message showMessage
  const [showAlertBox, setShowAlertBox] = useState(false) // error message ShowAlertBox
  const [isFormDirty, setIsFormDirty] = useState(false)
  const isFormModified = useSelector((state: any) => state.isFormModified);
  const [showChangesModal, setShowChangesModal] = useState<boolean>(false);
  const dispatch = useDispatch();
  const [bankValues, setBankValues] = useState<any[]>([]);


  function returnGird() {
    onboardingViewfun("grid")
    dispatch(resetFormModified(false));
  }

  async function duplicateCheck() {
    let isduplicate = false;
    const finalData = {
      "bankDetails": {

        "accountNumber": bankRelationshipMain?.accountNumber,
        "ifscCode": bankRelationshipMain?.ifscCode,
        "upi": bankRelationshipMain?.upi ? bankRelationshipMain.upi : "",
        "customerOverviewId": editData?.customerOverviewId ? editData?.customerOverviewId : ""
      }
    }
    setIsLoading(true)
    try {
      const res = await axiosPrivate.post("companyadmin/organisation/customer/duplicate-checking", finalData)
      if (res.status === 200) {
        isduplicate = true
        setIsLoading(false)
      }
    }
    catch (error: any) {
      setIsLoading(false)
      setIsDuplicate(false)
      setShowAlertBox(true);
      setShowType("danger")
      setShowMessage(error.response.data.error)
      clearAlert('');
    }
    return isduplicate
  }

  const userValidationRules: any = {
    companyData: {
      accountNumber: {
        regex: /^\d+$/,
        field: "mandatory",
        shouldNotBe: ""
      },
      ifscCode: {
        regex: /^[A-Z]{4}0[A-Z0-9]{6}$/,
        field: "mandatory",
        shouldNotBe: ""
      },
      bank: {
        regex: "",
        field: "mandatory",
        shouldNotBe: ""
      },
      upi: {
        regex: bankRelationshipMain?.upi ? /^[a-zA-Z0-9.\-_]{2,256}@[a-zA-Z.]{2,64}$/ : "",
        field: "",
        shouldNotBe: ""
      },
      accountName: {
        regex: bankRelationshipMain?.accountName ? /^[a-zA-Z][A-Za-z ]+$/ : "",
        field: "",
        shouldNotBe: ""
      },
      branch: {
        regex: bankRelationshipMain?.branch ? /^[a-zA-Z][A-Za-z. ]+$/ : "",
        field: "",
        shouldNotBe: ""
      },

    }
  }

  const updatedIsValueValidAll: Record<string, boolean> = {};
  const validateForm = (): boolean => {
    const updatedFieldValidity: Record<string, boolean> = {};
    Object.keys(userValidationRules.companyData).forEach((field) => {
      const rule = userValidationRules.companyData[field];
      const value = bankRelationshipMain[field];
      const isValueValid = (rule.field === "mandatory") ? (value ? (value !== rule.shouldNotBe) : false) : true;
      const isRegexValid = rule.regex !== "" ? new RegExp(rule.regex).test(value) : true;
      updatedFieldValidity[field] = !isValueValid || !isRegexValid;
      updatedIsValueValidAll[field] = !isValueValid;
    });
    setBankRelationError(updatedFieldValidity);
    const isFormValid = Object.keys(updatedFieldValidity).every((keys) => {
      return !updatedFieldValidity[keys]
    });
    return isFormValid;
  };

  useEffect(() => {
    fetchBankValues()
  }, [])
  const fetchBankValues = async () => {
    try {
      const response = await axiosPrivate.get("bank");
      if (response.status === 200) {
        const dropdownValues = response.data.bank.map((item: any) => ({
          label: item.bankName,
          value: item.bankName,
          id: item.bankId,
        }));
        setBankValues(dropdownValues);
        dropdownValues.sort((a: { label: string; }, b: { label: any; }) => a.label.localeCompare(b.label));
      }
    } catch (error) {
    }
  };

  async function next() {
    const isFormValid = validateForm()
    if (isFormValid) {
      let isDuplicate = await duplicateCheck()
      if (isDuplicate) {

        if (isEditMode) {
          sendDataApi();
        } else {
          setShowPage("Address")
          setIconStatus({
            ...iconStatus,
            adminIcon: "completed",
            addressIcon: "selected"
          })
        }
      } else {

      }
    } else {
      setShowAlertBox(true);
      setShowType("danger");
      setShowMessage(Object.values(updatedIsValueValidAll).every((values) => !values) ?
        "Some of the field(s) are not in required format." : "Mandatory field(s) should not be left blank.")
      clearAlert('');
    }
  }
  useImperativeHandle(ref, () => {
    return {
      next: next
    }
  });


  const fetchData = async (url: string, setData: (data: any[]) => void, nestedProperty: string, id: any, representName: any) => {
    try {
      const response = await axiosPrivate.get(url);
      if (response.status === 200) {
        const dropdownValues = response.data[nestedProperty].map((item: any) => ({
          label: item[representName],
          value: item[representName],
          id: item[id],
        }));
        const sortedData = [...dropdownValues].sort((a, b) => a.label.localeCompare(b.label));
        setData([{ label: "Select", value: "Select", id: "" }, ...sortedData]);

      }
    } catch (error) {
    }
  };

  // const handleAdd = () => {
  //   let isValidAdd = validateForm()
  //   if (isValidAdd) {
  //     duplicateCheck();
  //     if (isDuplicate) {
  //       if (formDataEdit) {
  //         let data: any = []
  //         bankRelationshipMain.length > 0 && bankRelationshipMain.map((ele: any, i: number) => {
  //           if (i === currentIndex) {
  //             data.push(bankRelationshipMain)
  //           } else {
  //             data.push(ele)
  //           }
  //         })
  //         setBankRelationshipMain(data)
  //         setBankRelationshipMain(
  //           {
  //             accountNumber: "",
  //             ifscCode: "",
  //             bank: "",
  //             accountName: "",
  //             branch: "",
  //             Region: "",
  //             State: "",
  //             City: "",
  //             index: 0
  //           }
  //         )
  //         setIsFormDirty(false);
  //         setFormDataEdit(false)
  //       }
  //       else {
  //         let newrecord = bankRelationshipMain
  //         newrecord.push({ ...bankRelationshipMain })
  //         setBankRelationshipMain(newrecord)
  //         setBankRelationshipMain(
  //           {
  //             accountNumber: "",
  //             ifscCode: "",
  //             bank: "",
  //             accountName: "",
  //             branch: "",
  //             Region: "",
  //             State: "",
  //             City: "",
  //             index: 0
  //           }
  //         )
  //       }
  //       setFormDataEdit(false)
  //       setIsFormDirty(false);
  //     } else {

  //     }
  //   } else {
  //     setShowAlertBox(true);
  //     setShowType("danger")
  //     setShowMessage(Object.values(updatedIsValueValidAll).every((values) => !values) ?
  //       "Some of the field(s) are not in required format." : "Mandatory field(s) should not be left blank.")
  //     clearAlert('');
  //   }
  // }

  const clearAlert = (status: any) => {
    const timer = setTimeout(() => {
      setShowAlertBox(false);
      setShowMessage("");
      if (status === 200) {
        onboardingViewfun("form")
      }
      clearTimeout(timer);
    }, 5000);
  }

  function cancel() {
    setShowPage("CustomerOverview")
    setIconStatus({
      ...iconStatus,
      adminIcon: "selected",
    })
  }
  return (
    <div className='form-wrapper' >
      <div className='form-area' >
        <div>
          <div className='company-heading'>
            Bank Details
          </div>
          <div className='form-main-wrapper'>
            <div key={uuidv4()} style={{ marginRight: "45px" }} className={bankRelationError.bank ? 'setErrorcompany' : "dropdown-margin dropdown-width"}>
              <DropdownComponent
                width={"300px"}
                title={"Bank"}
                required={true}
                // isDisabled={isEditMode && mainApiData?.status === "Pending Approval" ? true : false}
                defaultValue={[{ label: bankRelationshipMain?.bank === undefined ? "Select" : bankRelationshipMain?.bank, value: bankRelationshipMain && bankRelationshipMain?.bank }]}
                options={bankValues}
                getData={(value) => {
                  setBankRelationshipMain({
                    ...bankRelationshipMain,
                    bank: value.value,
                    bankId: value.id
                  })
                  setIconStatus({
                    ...iconStatus,
                    adminIcon: "selected"
                  })
                  setIsFormDirty(true)
                  dispatch(resetFormModified(true));
                }}
              />
            </div>
            <div style={{ marginRight: "45px" }}>
              <InputComponent
                height={"40px"}
                width={"300px"}
                margin={"0px 0px 30px 0px"}
                padding={"0px 0px 0px 10px"}
                border={bankRelationError.accountNumber ? "1px solid red" : "1px solid #A9C3DC"}
                borderRadius={"0px"}
                backgroundColor={"white"}
                // disabled={isEditMode && mainApiData?.status === "Pending Approval" ? true : false}
                placeHolder={'Enter Account Number'}
                color={"black"}
                type={"text"}
                inputTitle={"Account Number"}
                maxLength={16}
                required={true}
                inputValue={bankRelationshipMain && bankRelationshipMain?.accountNumber}
                getUser={(value: any) => {
                  const val = value.replace(/[^0-9]/g, "")
                  setBankRelationshipMain({
                    ...bankRelationshipMain,
                    accountNumber: val
                  })
                  setIconStatus({
                    ...iconStatus,
                    adminIcon: "selected"
                  })
                  setIsFormDirty(true)
                  dispatch(resetFormModified(true));
                }}
              />
            </div>
            <div>
              <InputComponent
                height={"40px"}
                width={"300px"}
                margin={"0px 0px 30px 0px"}
                padding={"0px 0px 0px 10px"}
                border={bankRelationError.ifscCode ? "1px solid red" : "1px solid #A9C3DC"}
                borderRadius={"0px"}
                backgroundColor={"white"}
                placeHolder={'Enter IFSC Code '}
                // disabled={isEditMode && mainApiData?.status === "Pending Approval" ? true : false}

                color={"black"}
                type={"text"}
                required={true}
                inputTitle={"IFSC Code"}
                maxLength={11}
                inputValue={bankRelationshipMain && bankRelationshipMain?.ifscCode}
                getUser={(value: any) => {
                  const ifsc = value.toUpperCase().replace(/[^A-Z0-9]/g, "")
                  setBankRelationshipMain({
                    ...bankRelationshipMain,
                    ifscCode: ifsc
                  })
                  setIconStatus({
                    ...iconStatus,
                    adminIcon: "selected"
                  })
                  setIsFormDirty(true)
                  dispatch(resetFormModified(true));
                }}
              />
            </div>

          </div>
          <div className='form-main-wrapper'>

            <div style={{ marginRight: "45px" }}>
              <InputComponent
                height={"40px"}
                width={"300px"}
                margin={"0px 0px 30px 0px"}
                padding={"0px 0px 0px 10px"}
                borderRadius={"0px"}
                backgroundColor={"white"}
                // disabled={isEditMode && mainApiData?.status === "Pending Approval" ? true : false}

                color={"black"}
                required={false}
                type={"mobile"}
                maxLength={100}
                inputTitle={"Account Name"}
                placeHolder={"Enter Account Name"}
                inputValue={bankRelationshipMain && bankRelationshipMain?.accountName}
                // disabled={admin_details[i].isActive !== "No"}
                border={bankRelationError.accountName ? "1px solid red" : "1px solid #A9C3DC"}
                getUser={(value: any) => {
                  const onlyAlphabet = value.replace(/[^A-Za-z0-9 ]+/g, '')
                  setBankRelationshipMain({
                    ...bankRelationshipMain,
                    accountName: onlyAlphabet
                  })
                  setIconStatus({
                    ...iconStatus,
                    adminIcon: "selected"
                  })
                  setIsFormDirty(true)
                  dispatch(resetFormModified(true));

                }}
              />
            </div>
            <div style={{ marginRight: "45px" }}>
              <InputComponent
                height={"40px"}
                width={"300px"}
                margin={"0px 0px 30px 0px"}
                padding={"0px 0px 0px 10px"}
                borderRadius={"0px"}
                backgroundColor={"white"}
                color={"black"}
                required={false}
                // disabled={isEditMode && mainApiData?.status === "Pending Approval" ? true : false}

                type={"mobile"}
                maxLength={100}
                inputTitle={"Branch"}
                placeHolder={"Enter Branch"}
                inputValue={bankRelationshipMain && bankRelationshipMain?.branch}
                // disabled={admin_details[i].isActive !== "No"}
                border={bankRelationError.branch ? "1px solid red" : "1px solid #A9C3DC"}
                getUser={(value: any) => {
                  const onlyAlphabet = value.replace(/[^A-Za-z0-9. ]+/g, '')
                  setBankRelationshipMain({
                    ...bankRelationshipMain,
                    branch: onlyAlphabet
                  })
                  setIconStatus({
                    ...iconStatus,
                    adminIcon: "selected"
                  })
                  setIsFormDirty(true)
                  dispatch(resetFormModified(true));

                }}
              />
            </div>
            <div>
              <InputComponent
                height={"40px"}
                width={"300px"}
                margin={"0px 0px 30px 0px"}
                padding={"0px 0px 0px 10px"}
                borderRadius={"0px"}
                backgroundColor={"white"}
                color={"black"}
                required={false}
                // disabled={isEditMode && mainApiData?.status === "Pending Approval" ? true : false}
                type={"mobile"}
                maxLength={100}
                inputTitle={"UPI"}
                placeHolder={"Enter UPI"}
                inputValue={bankRelationshipMain && bankRelationshipMain?.upi}
                // disabled={admin_details[i].isActive !== "No"}
                border={bankRelationError.upi ? "1px solid red" : "1px solid #A9C3DC"}
                getUser={(value: any) => {
                  setBankRelationshipMain({
                    ...bankRelationshipMain,
                    upi: value
                  })
                  setIconStatus({
                    ...iconStatus,
                    adminIcon: "selected"
                  })
                  setIsFormDirty(true)
                  dispatch(resetFormModified(true));

                }}
              />
            </div>
          </div>
        </div>
        <div>
          <div className='footer-company' >
            {!isEditMode &&
              <ButtonComponent
                title={"Cancel"}
                height={"50px"}
                width={"150px"}
                margin={"0px 8px"}
                backgroundColor={"#888888"}
                color={"white"}
                className={"button-component-hover cancel"}
                handleClick={isFormModified ? () => { setShowChangesModal(true) } : returnGird}
              />
            }

            {
              isEditMode ?
                <ButtonComponent
                  title={"Cancel"}
                  height={"50px"}
                  width={"150px"}
                  margin={"0px 8px"}
                  backgroundColor={"#888888"}
                  color={"white"}
                  className={"button-component-hover cancel"}
                  handleClick={isFormModified ? () => { setShowChangesModal(true) } : returnGird}
                />
                :
                <ButtonComponent
                  title={"Previous"}
                  height={"50px"}
                  width={"150px"}
                  margin={"0px 8px"}
                  backgroundColor={"#888888"}
                  color={"white"}
                  className={"button-component-hover cancel"}
                  handleClick={cancel} />
            }


            <ButtonComponent
              title={isEditMode ? "Update" : "Next"}
              height={"50px"}
              width={"150px"}
              backgroundColor={"#0055D4"}
              color={"white"}
              margin={"0px 8px"}
              handleClick={next}
              disabled={showType === "success" ? true : false}
              className={
                isDisabled ?
                  "button-component-hover disabled" : "button-component-hover common-btn"}
            />
          </div>
        </div>
      </div>
      {
        isLoading &&
        <Loading />
      }
      {
        showAlertBox &&
        <div className='alert-warp'>
          <AlertBox type={showType} message={showMessage} />
        </div>

      }
      {
        showChangesModal &&
        <Changesmodal
          closeModal={() => setShowChangesModal(false)}
          handleClose={() => {
            setShowChangesModal(false);
          }}
          leavePage={() => returnGird()} />
      }
    </div>
  )
})
export default BankDetails
