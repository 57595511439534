import React, { useEffect, useState } from 'react'
import IconButton from '../../../common-component/form-elements/icon-button';
import { CiSearch } from 'react-icons/ci';
import ChartFilter from '../../../common-component/charts/chart-filter';
import { axiosPrivate } from '../../../../middleware/axois-api';
import '../../../../styles/reports/report-table.scss';
import { CustomerReportFilter } from '../../../../config/reports/master-reports/customer-reports-config';


const OrganisationfilterDatas: any = {
    "Vendor": [
        {
            cardTitle: "Vendor Code",
            cardType: "dropdown",
            selectedValue: "All",
            options: [
                { value: "All", label: "All" },
            ],
            isOpen: false
        },
        {
            cardTitle: "Name",
            cardType: "dropdown",
            selectedValue: "Name",
            options: [
                { value: "All", label: "All" },
            ],
            isOpen: false
        },

    ],
}
interface props {
    searchBarValue: string
    conponentPDFMaster: any
    filterOpen: any
    setFilterOpen: any

}
export default function CustomerGrid({ searchBarValue, filterOpen, setFilterOpen, conponentPDFMaster }: props) {
    const [searchValue, setSearchValue] = useState<string>('');
    const [filteredData, setFilteredData] = useState<any[]>([]);
    const [orginalFilteredData, setOrginalFilteredData] = useState<any[]>([]);
    const [openDropdownIndex, setOpenDropdownIndex] = useState<number | null>(null);
    const [storeFilterOptions, setStoreFilterOptions] = useState<any>();
    const [filterValue, setFilterValues] = useState<any>([])
    const [orginalRowData, setOrginalRowData] = useState<any[]>([]);

    const [selectedFilteredData, setSelectedFilteredData] = useState<any>('All');


    // all data filter
    // const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     const value = event.target.value;
    //     setSearchValue(value);

    //     const filtered = SampleDatasArun.filter((item) => {
    //         // Customize the condition based on your specific requirements
    //         return Object.values(item).some((val) => {
    //             if (val && typeof val === 'object') {
    //                 // If the value is an object and not null/undefined, check its values
    //                 return Object.values(val).some((nestedVal: any) =>
    //                     nestedVal && nestedVal.toString().toLowerCase().includes(value.toLowerCase())
    //                 );
    //             } else if (val) {
    //                 // If the value is not an object and not null/undefined, directly check it
    //                 return val.toString().toLowerCase().includes(value.toLowerCase());
    //             }
    //             return false; // Skip null/undefined values
    //         });
    //     });

    //     setFilteredData(filtered); 
    // };


    // const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     const value = event.target.value;
    //     setSearchValue(value);

    //     // Filter data based on the search value
    //     const filtered = SampleDatasArun.filter((item) => {
    //         // Customize the condition based on your specific requirements 
    //         return item.VendorDeatils.Name.toLowerCase().includes(value.toLowerCase());
    //     });

    //     setFilteredData(filtered);
    // };


    // two data filter




    useEffect(() => {
        handleApplyButtonForFilter(CustomerReportFilter["Customer"])
        setStoreFilterOptions(CustomerReportFilter["Customer"])
        setFilterValues(CustomerReportFilter["Customer"])
        fetchData()
    }, [])

    const handleOpenClose = (index: number) => {
        if (openDropdownIndex === index) {
            setOpenDropdownIndex(null); // Close the dropdown if it's already open
        } else {
            setOpenDropdownIndex(index); // Open the dropdown
        }
    };
    const updateFilterDatas = async (index: number, newData: any) => {
        setStoreFilterOptions((prevFilterDatas: any) => {
            const updatedFilterDatas = [...prevFilterDatas];
            updatedFilterDatas[index] = newData;
            return updatedFilterDatas;
        });

        setFilterValues((prevFilterDatas: any) => {
            const updatedFilterDatas = [...prevFilterDatas];
            updatedFilterDatas[index] = newData;
            return updatedFilterDatas;
        });
    };

    function addDataForDropDownFilter(res: any) {
        if (Object.keys(res).length > 0) {

            const updatedAccountNumber: any = [
                { value: "All", label: "All" },
                ...res['Account Number'].map((elem: any) => ({ value: elem, label: elem }))
            ];
            const updatedBank: any = [
                { value: "All", label: "All" },
                ...res.Bank.map((elem: any) => ({ value: elem, label: elem }))
            ];

            const updatedBranch: any = [
                { value: "All", label: "All" },
                ...res.Branch.map((elem: any) => ({ value: elem, label: elem }))
            ];
            const updatedBuisnessCategory: any = [
                { value: "All", label: "All" },
                ...res['Company Sub Category'].map((elem: any) => ({ value: elem, label: elem }))
            ];

            const updatedCompanyConstitution: any = [
                { value: "All", label: "All" },
                ...res['Company Constitution'].map((elem: any) => ({ value: elem, label: elem }))
            ];
            const updatedContactPersonName: any = [
                { value: "All", label: "All" },
                ...res['Contact Person Name'].map((elem: any) => ({ value: elem, label: elem }))
            ];
            const updatedEmail: any = [
                { value: "All", label: "All" },
                ...res.Email.map((elem: any) => ({ value: elem, label: elem }))
            ];

            const updatedIFSCCode: any = [
                { value: "All", label: "All" },
                ...res['IFSC Code'].map((elem: any) => ({ value: elem, label: elem }))
            ];

            const updatedIsActive: any = [
                { value: "All", label: "All" },
                ...res["Is Active"].map((elem: any) => ({ value: elem, label: elem }))
            ];

            const updatedName: any = [
                { value: "All", label: "All" },
                ...res.Name.map((elem: any) => ({ value: elem, label: elem }))
            ];

            const updatedPhoneNumber: any = [
                { value: "All", label: "All" },
                ...res['Phone Number'].map((elem: any) => ({ value: elem, label: elem }))
            ];

            const updatedUPI: any = [
                { value: "All", label: "All" },
                ...res.UPI.map((elem: any) => ({ value: elem, label: elem }))
            ];
            const updatedCustomerCode: any = [
                { value: "All", label: "All" },
                ...res['Customer Code'].map((elem: any) => ({ value: elem, label: elem }))
            ];

            setStoreFilterOptions((prevOptions: any) => {
                return prevOptions.map((option: any) => {
                    switch (option.cardTitle) {
                        case "Account Number":
                            return { ...option, options: updatedAccountNumber };
                        case "Bank":
                            return { ...option, options: updatedBank };
                        case "Branch":
                            return { ...option, options: updatedBranch };
                        case "Company Sub Category":
                            return { ...option, options: updatedBuisnessCategory };
                        case "Company Constitution":
                            return { ...option, options: updatedCompanyConstitution };
                        case "Contact Person Name":
                            return { ...option, options: updatedContactPersonName };
                        case "Email":
                            return { ...option, options: updatedEmail };

                        case "IFSC Code":
                            return { ...option, options: updatedIFSCCode };
                        case "Is Active":
                            return { ...option, options: updatedIsActive };

                        case "Phone Number":
                            return { ...option, options: updatedPhoneNumber };
                        case "Name":
                            return { ...option, options: updatedName };
                        case "UPI":
                            return { ...option, options: updatedUPI };
                        case "Customer Code":
                            return { ...option, options: updatedCustomerCode };
                        default:
                            return option;
                    }
                });
            });

            setFilterValues((prevOptions: any) => {
                return prevOptions.map((option: any) => {
                    switch (option.cardTitle) {
                        case "Account Number":
                            return { ...option, options: updatedAccountNumber };
                        case "Bank":
                            return { ...option, options: updatedBank };
                        case "Branch":
                            return { ...option, options: updatedBranch };
                        case "Company Sub Category":
                            return { ...option, options: updatedBuisnessCategory };
                        case "Company Constitution":
                            return { ...option, options: updatedCompanyConstitution };
                        case "Contact Person Name":
                            return { ...option, options: updatedContactPersonName };
                        case "Email":
                            return { ...option, options: updatedEmail };
                        case "IFSC Code":
                            return { ...option, options: updatedIFSCCode };
                        case "Is Active":
                            return { ...option, options: updatedIsActive };
                        case "Phone Number":
                            return { ...option, options: updatedPhoneNumber };
                        case "Name":
                            return { ...option, options: updatedName };
                        case "UPI":
                            return { ...option, options: updatedUPI };
                        case "Customer Code":
                            return { ...option, options: updatedCustomerCode };
                        default:
                            return option;
                    }
                });
            });

        }
    }

    async function handleApplyButtonForFilter(data: any) {
        const convertValue = convertToFilterValues(filterValue)
        if (
            convertValue['CustomerCode'] == "All" &&
            convertValue['Name'] == "All" &&
            convertValue['IsActive'] == "All" &&
            convertValue['CompanyConstitution'] == "All" &&
            convertValue['CompanySubCategory'] == "All" &&
            convertValue['Bank'] == "All" &&
            convertValue['Branch'] == "All" &&
            convertValue['AccountNumber'] == "All" &&
            convertValue['IFSCCode'] == "All" &&
            convertValue['UPI'] == "All" &&
            convertValue['ContactPersonName'] == "All" &&
            convertValue['PhoneNumber'] == "All" &&
            convertValue['Email'] == "All"
        ) {
            setFilteredData(orginalRowData)
        } else {
            const filteredData: any = orginalRowData && orginalRowData.filter((ele: any) => {
                const VendorCodeCondition = convertValue['CustomerCode'] === 'All' || ele.CustomerDetails['Customer Code'] === convertValue['CustomerCode'];

                const NameCondition = convertValue['Name'] === 'All' || ele.CustomerDetails.Name === convertValue['Name'];
                const IsActiveCondition = convertValue['IsActive'] === 'All' || ele.CustomerDetails.IsActive === convertValue['IsActive'];
                const CompanyConstitutionCondition = convertValue['CompanyConstitution'] === 'All' || ele.EntityInformation['Company Constitution'] === convertValue['CompanyConstitution'];
                const BuisnessCategoryCondition = convertValue['CompanySubCategory'] === 'All' || ele.EntityInformation['Company Sub Category'] === convertValue['CompanySubCategory'];

                const BankCondition = convertValue['Bank'] === 'All' || ele.AccountDetails.Bank === convertValue['Bank'];
                const BranchCondition = convertValue['Branch'] === 'All' || ele.AccountDetails.Branch === convertValue['Branch'];
                const AccountNumberCondition = convertValue['AccountNumber'] === 'All' || ele.AccountDetails['Account Number'] === convertValue['AccountNumber'];
                const IFSCCodeCondition = convertValue['IFSCCode'] === 'All' || ele.AccountDetails['IFSC Code'] === convertValue['IFSCCode'];
                const UPICondition = convertValue['UPI'] === 'All' || ele.AccountDetails.UPI === convertValue['UPI'];
                const ContactPersonNameCondition = convertValue['ContactPersonName'] === 'All' || ele.AddressDetails['Contact Person Name'] === convertValue['ContactPersonName'];
                const PhoneNumberCondition = convertValue['PhoneNumber'] === 'All' || ele.AddressDetails['Phone Number'] === convertValue['PhoneNumber'];
                const EmailCondition = convertValue['Email'] === 'All' || ele.AddressDetails.Email === convertValue['Email'];


                return VendorCodeCondition && NameCondition && IsActiveCondition && CompanyConstitutionCondition && BuisnessCategoryCondition && BankCondition && BranchCondition && AccountNumberCondition && IFSCCodeCondition && UPICondition && ContactPersonNameCondition && PhoneNumberCondition && EmailCondition;
            });
            setFilteredData(filteredData);
        }

        setFilterOpen(false)

    }

    const convertToFilterValues = (filterData: any) => {
        const filterValues: any = {};
        filterData.forEach((filter: any) => {
            const tittle = (filter.cardTitle).replace(/\s/g, '')
            filterValues[tittle] = filter.selectedValue;
        });
        return filterValues;
    };


    const fetchData = async () => {
        try {
            const response = await axiosPrivate.get('/companyadmin/master-reports/customer');
            setFilteredData(response.data.customerDetails)
            setOrginalRowData(response.data.customerDetails)
            addDataForDropDownFilter(response.data.dropDownDatas)
        } catch (error) {
        }
    };

    useEffect(() => {
        const filtered = orginalRowData.filter((item) => {
            const CustomerDetails = item.CustomerDetails;

            if (
                CustomerDetails &&
                (CustomerDetails["Customer Code"]?.toLowerCase().includes(searchBarValue.toLowerCase()) ||
                    CustomerDetails["Name"]?.toLowerCase().includes(searchBarValue.toLowerCase()))
            ) {
                // Return an object containing the matched field and its value
                return {
                    matchedField: CustomerDetails["Customer Code"]?.toLowerCase().includes(searchBarValue.toLowerCase())
                        ? "Customer Code"
                        : "Name",
                    value: CustomerDetails["Customer Code"] || CustomerDetails["Name"],
                };
            }

            return null;
        });

        setFilteredData(filtered);

    }, [searchBarValue]);


    return (
        <>

            <div className='report-table-component' style={{ marginTop: "20px", overflowX: "auto" }}>
                <table ref={conponentPDFMaster}>
                    <thead>
                        {/* <tr> */}
                        <th className='report-heading' style={{ paddingLeft: "10px", }}>Customer Details</th>
                        <th className='report-heading' style={{ paddingLeft: "10px" }}>Entity Information</th>
                        <th className='report-heading' style={{ paddingLeft: "10px" }}>Account Details</th>
                        <th className='report-heading' style={{ paddingLeft: "10px" }}>Address Details</th>
                        {/* </tr> */}
                    </thead>
                    <tbody>
                        {
                            filteredData.length === 0 ?
                                <tr className='style-grid-differ'>

                                    <td></td>
                                    <td style={{ textAlign: "center" }}>No records to show</td>
                                    <td></td>
                                    <td></td>
                                </tr> :
                                filteredData.map((data: any, index: any) => (
                                    <tr className='main-body-header' key={index} >
                                        <td className='report-details' >
                                            {Object.entries(data.CustomerDetails).map(([key, value]: any) => (
                                                <p key={key} style={{ whiteSpace: "normal", width: "350px" }}>
                                                    <i>{key}</i>  - {value}
                                                </p>
                                            ))}
                                        </td>
                                        <td className='report-details' >
                                            {Object.entries(data.EntityInformation).map(([key, value]: any) => (
                                                <p key={key} style={{ width: "350px", whiteSpace: "normal" }}>
                                                    <i>{key}</i> - {value}
                                                </p>
                                            ))}
                                        </td>

                                        <td className='report-details' >
                                            {Object.entries(data.AccountDetails).map(([key, value]: any) => (
                                                <p key={key}>
                                                    <i>{key}</i> - {value}
                                                </p>
                                            ))}
                                        </td>
                                        <td className='report-details' >
                                            {Object.entries(data.AddressDetails).map(([key, value]: any) => (
                                                <p key={key} style={{ width: "350px", whiteSpace: "normal" }}>

                                                    <i>{key}</i> - {value}
                                                </p>
                                            ))}
                                        </td>
                                    </tr>
                                ))
                        }
                    </tbody>


                </table>
            </div>

            {filterOpen &&
                <div className="right-side-filter-component" >
                    <ChartFilter
                        datas={storeFilterOptions}
                        updateFilterDatas={updateFilterDatas}
                        handleOpenClose={handleOpenClose}
                        openDropdownIndex={openDropdownIndex}
                        handleApplyButtonForFilter={() => { handleApplyButtonForFilter("") }}
                        setOpenRightSideFilter={() => { setFilterOpen(false) }}
                    />
                </div>
            }
        </>
    )
}



