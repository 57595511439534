import React, { useEffect, useState } from 'react';
import { ReactComponent as SingleUser } from '../../../../assets/icons/singleuser.svg'
import { ReactComponent as GroupUser } from '../../../../assets/icons/groupuser.svg'
import DropdownComponent from '../../../common-component/form-elements/dropdown-component';
import ButtonComponent from '../../../common-component/form-elements/button-component';
import ApprovalProcessGrid from '../../../../config/organisation/company/approval-process-grid';
import { useDispatch, useSelector } from "react-redux";
import Changesmodal from '../../../common-component/modals/changes-modal';
import { resetFormModified } from "../../../../redux/action";
import { v4 as uuidv4 } from 'uuid'
import AlertBox from '../../../common-component/alert-box';
import { axiosPrivate } from '../../../../middleware/axois-api';
import DeleteModal from '../../../common-component/modals/delete-modal';
import InputComponent from '../../../common-component/form-elements/input-component';
import rupeeImg from "../../../../assets/images/ruppees-img.png";

interface props {
    onboardingViewfun: (val: any) => void;
    setApprovalDataMain: (val: any) => void;
    approvalDataMain: any
    editData: any
    iconStatus: any
    setShowPage: (val: any) => void;
    setIconStatus: (val: any) => void;
    sendDataApi: () => void;
    isEditMode: boolean;
}
function ApprovalProcess({ setApprovalDataMain, approvalDataMain, onboardingViewfun, editData, iconStatus, setShowPage, setIconStatus, sendDataApi, isEditMode }: props) {

    const initData: any = {
        approvalType: "Single",
        userType: "Single",
        makerType: "Single",
        checkerType: "Single",
        approverType: "Single",
        recommenderType: "Single",
        singleUserId: "",
        singleUserValue: "",
        groupUserValue: "",
        groupUserId: "",
        department_id: "",
        departmentValue: "",
        min: "",
        max: "",
        isMakerChecked: "Yes",
        makerId: "",
        makerValue: "",
        makerGroupId: "",
        makerGroupValue: "",
        isCheckerChecked: "",
        checkerId: "",
        checkerGroupId: "",
        checkerValue: "",
        checkerGroupValue: "",
        isRecommenderChecked: "",
        recommenderId: "",
        recommenderGroupId: "",
        recommenderValue: "",
        recommenderGroupValue: "",
        isApproverChecked: "Yes",
        approverId: "",
        approverGroupId: "",
        approverValue: "",
        approverGroupValue: "",
        index: 0
    };
    const [showSingle, setShowSingle] = useState(true);
    const [showGroup, setShowGroup] = useState(false);
    const [showSingleIcon, setShowSingleIcon] = useState(true);
    const [showGroupIcon, setShowGroupIcon] = useState(false);
    const [showSingleMakerIcon, setShowSingleMakerIcon] = useState(true);
    const [showGroupMakerIcon, setShowGroupMakerIcon] = useState(false);
    const [showSingleCheckerIcon, setShowSingleCheckerIcon] = useState(true);
    const [showGroupCheckerIcon, setShowGroupCheckerIcon] = useState(false);
    const [showSingleRecommenderIcon, setShowSingleRecommenderIcon] = useState(true);
    const [showGroupRecommenderIcon, setShowGroupRecommenderIcon] = useState(false);
    const [showSingleApproverIcon, setShowSingleApproverIcon] = useState(true);
    const [showGroupApproverIcon, setShowGroupApproverIcon] = useState(false);
    const [approvalData, setApprovalData] = useState(initData);
    const [approvalDataErrors, setApprovalDataErrors] = useState(initData);
    const [showType, setShowType] = useState("warning") // error message showType
    const [showMessage, setShowMessage] = useState<string>("") // error message showMessage
    const [showAlertBox, setShowAlertBox] = useState(false) // error message ShowAlertBox
    const [isFormDirty, setIsFormDirty] = useState(false)
    const [formDataEdit, setFormDataEdit] = useState(false)
    const [currentIndex, setCurrentIndex] = useState<number>()
    const isFormModified = useSelector((state: any) => state.isFormModified);
    const dispatch = useDispatch();
    const [showChangesModal, setShowChangesModal] = useState<boolean>(false);
    const [showDeleteModel, setShowDeleteModel] = useState<boolean>(false)
    const [deleteIndex, setDeleteIndex] = useState<number>(0)
    const [currentDeletVal, setCurrentDeletVal] = useState<any>({});
    const [currentApprovalData, setCurrentApprovalData] = useState<number>(0)

    const [departmentDD, setDepartmentDD] = useState<any>([]);
    const [userDD, setUserDD] = useState<any>([]);
    const [userGroupDD, setUserGroupDD] = useState<any>([]);
    const [makerDD, setMakerDD] = useState<any>([]);
    const [checkerDD, setCheckerDD] = useState<any>([]);
    const [recommenderDD, setRecommenderDD] = useState<any>([]);
    const [approverDD, setApproverDD] = useState<any>([]);

    // group
    const [makerGroupDD, setMakerGroupDD] = useState<any>([]);
    const [checkerGroupDD, setCheckerGroupDD] = useState<any>([]);
    const [recommenderGroupDD, setRecommenderGroupDD] = useState<any>([]);
    const [approverGroupDD, setApproverGroupDD] = useState<any>([]);

    //dd max and min
    const [bankValues, setBankValues] = useState<any[]>([]);

    //Header toogle
    const handleSingleClick = (event: React.MouseEvent<HTMLDivElement>) => {
        setApprovalData({
            ...approvalData,
            approvalType: "Single"
        })
        setIsFormDirty(false)
        setShowSingle(true);
        setShowGroup(false);
    };
    const handleGroupClick = (event: React.MouseEvent<HTMLDivElement>) => {
        setApprovalData({
            ...approvalData,
            approvalType: "Group"
        })
        setShowSingle(false);
        setShowGroup(true);
    };

    const singleUserStyle = {
        color: approvalData?.approvalType === "Single" ? '#0055D4' : '#333333',
    };

    const groupUserStyle = {
        color: approvalData?.approvalType === "Group" ? '#0055D4' : '#333333',
    };

    //Single user Approval
    const handleSingleIconClick = (event: any) => {
        setApprovalData({
            ...approvalData,
            userType: "Single",
            groupUserValue: "",
            groupUserId: "",
        })
        singleUserUpdate("Single", approvalData.singleUserId, approvalData.singleUserValue, approvalData.groupUserValue, approvalData.groupUserId);
        setShowSingleIcon(true);
        setShowGroupIcon(false);
    };

    const handleGroupIconClick = (event: any) => {
        setApprovalData({
            ...approvalData,
            userType: "Group",
            singleUserValue: "",
            singleUserId: "",
        })
        singleUserUpdate("Group", approvalData.singleUserId, approvalData.singleUserValue, approvalData.groupUserValue, approvalData.groupUserId);
        setShowSingleIcon(false);
        setShowGroupIcon(true);
    };

    const singleUserIconStyle = {
        fill: approvalData?.userType === "Single" ? '#0055D4' : '#666666',
        width: "35px", height: "35px",
        cursor: approvalDataMain && approvalDataMain[0]?.approvalType == "Single" && approvalDataMain[0]?.noSwitchApprovalProcess ? "not-allowed" : "pointer"
    };

    const groupUserIconStyle = {
        fill: approvalData?.userType === "Group" ? '#0055D4' : '#666666',
        width: "35px", height: "35px",
        cursor: approvalDataMain && approvalDataMain[0]?.approvalType == "Single" && approvalDataMain[0]?.noSwitchApprovalProcess ? "not-allowed" : "pointer"
    };

    // Maker
    const handleSingleMakerIconClick = () => {
        setApprovalData({
            ...approvalData,
            makerType: "Single",
            makerGroupValue: "",
            makerGroupId: "",
        })
        setShowSingleMakerIcon(true);
        setShowGroupMakerIcon(false);
    };

    const handleGroupMakerIconClick = () => {
        setApprovalData({
            ...approvalData,
            makerType: "Group",
            makerValue: "",
            makerId: "",
        })
        setShowSingleMakerIcon(false);
        setShowGroupMakerIcon(true);
    };

    const singleMakerIconStyle = {
        fill: approvalData?.makerType === "Single" ? '#0055D4' : '#666666',
        width: "35px", height: "35px", cursor: "pointer"
    };

    const groupMakerIconStyle = {
        fill: approvalData?.makerType === "Group" ? '#0055D4' : '#666666',
        width: "35px", height: "35px", cursor: "pointer"
    };

    // Checker
    const handleSingleCheckerIconClick = () => {
        setApprovalData({
            ...approvalData,
            checkerType: "Single",
            checkerGroupValue: "",
            checkerGroupId: "",
        })
        setShowSingleCheckerIcon(true);
        setShowGroupCheckerIcon(false);
    };

    const handleGroupCheckerIconClick = () => {
        setApprovalData({
            ...approvalData,
            checkerType: "Group",
            checkerValue: "",
            checkerId: "",
        })
        setShowSingleCheckerIcon(false);
        setShowGroupCheckerIcon(true);
    };

    const singleCheckerIconStyle = {
        fill: approvalData?.checkerType === "Single" ? '#0055D4' : '#666666',
        width: "35px", height: "35px", cursor: "pointer"
    };

    const groupCheckerIconStyle = {
        fill: approvalData?.checkerType === "Group" ? '#0055D4' : '#666666',
        width: "35px", height: "35px", cursor: "pointer"
    };
    // Recommender
    const handleSingleRecommenderIconClick = () => {
        setApprovalData({
            ...approvalData,
            recommenderType: "Single",
            recommenderGroupValue: "",
            recommenderGroupId: "",
        })
        setShowSingleRecommenderIcon(true);
        setShowGroupRecommenderIcon(false);
    };

    const handleGroupRecommenderIconClick = () => {
        setApprovalData({
            ...approvalData,
            recommenderType: "Group",
            recommenderValue: "",
            recommenderId: "",
        })
        setShowSingleRecommenderIcon(false);
        setShowGroupRecommenderIcon(true);
    };

    const singleRecommenderIconStyle = {
        fill: approvalData?.recommenderType === "Single" ? '#0055D4' : '#666666',
        width: "35px", height: "35px", cursor: "pointer"
    };

    const groupRecommenderIconStyle = {
        fill: approvalData?.recommenderType === "Group" ? '#0055D4' : '#666666',
        width: "35px", height: "35px", cursor: "pointer"
    };
    // Approver
    const handleSingleApproverIconClick = () => {
        setApprovalData({
            ...approvalData,
            approverType: "Single",
            approverGroupValue: "",
            approverGroupId: "",
        })
        setShowSingleApproverIcon(true);
        setShowGroupApproverIcon(false);
    };

    const handleGroupApproverIconClick = () => {
        setApprovalData({
            ...approvalData,
            approverType: "Group",
            approverValue: "",
            approverId: "",
        })
        setShowSingleApproverIcon(false);
        setShowGroupApproverIcon(true);
    };

    const singleApproverIconStyle = {
        fill: approvalData?.approverType === "Single" ? '#0055D4' : '#666666',
        width: "35px", height: "35px", cursor: "pointer"
    };

    const groupApproverIconStyle = {
        fill: approvalData?.approverType === "Group" ? '#0055D4' : '#666666',
        width: "35px", height: "35px", cursor: "pointer"
    };

    useEffect(() => {
        if (approvalDataMain?.length == 1 && approvalDataMain[0]?.approvalType == "Single") {
            let data = {
                ...approvalData,
                approvalType: "Single",
                userType: approvalDataMain[0]?.userType,
                singleUserId: approvalDataMain[0]?.singleUserId,
                singleUserValue: approvalDataMain[0]?.singleUserValue,
                groupUserId: approvalDataMain[0]?.groupUserId,
                groupUserValue: approvalDataMain[0]?.groupUserValue,
            }
            setApprovalData(data)
        } else if (approvalDataMain?.length > 1 || (approvalDataMain && approvalDataMain[0]?.approvalType == "Group")) {
            let data = {
                ...approvalData,
                approvalType: "Group"
            }
            setApprovalData(data)
        }
        fetchBankValues();
    }, [])


    // function for bank dropdown
    const fetchBankValues = async () => {
        try {
            const response = await axiosPrivate.get("/card-issuer-bank");
            if (response.status === 200) {
                const dropdownValues = response.data.bank.map((item: any) => ({
                    label: item.bankName,
                    value: item.bankName,
                    id: item.bankId,
                }));

                const sortedData = [...dropdownValues].sort((a, b) => a.label.localeCompare(b.label));
                setBankValues(sortedData);
            }
        } catch (error) {
        }
    };

    const userValidationRules: any = {
        departmentValue: {
            regex: "",
            field: "",
            shouldNotBe: ""
        },
        makerValue: {
            regex: "",
            field: approvalData?.makerType === "Single" ? "mandatory" : "",
            shouldNotBe: ""
        },
        makerGroupValue: {
            regex: "",
            field: (approvalData?.isMakerChecked == "Yes" && approvalData?.makerType === "Group" && !makerGroupDD[0]?.id) ? "mandatory" : "",
            shouldNotBe: ""
        },
        checkerValue: {
            regex: "",
            field: (approvalData?.isCheckerChecked == "Yes" && approvalData?.checkerType === "Single") ? "mandatory" : "",
            shouldNotBe: ""
        },
        checkerGroupValue: {
            regex: "",
            field: (approvalData?.isCheckerChecked == "Yes" && approvalData?.checkerType === "Group" && !checkerGroupDD[0]?.id) ? "mandatory" : "",
            shouldNotBe: ""
        },
        recommenderValue: {
            regex: "",
            field: (approvalData?.isRecommenderChecked == "Yes" && approvalData?.recommenderType === "Single") ? "mandatory" : "",
            shouldNotBe: ""
        },
        recommenderGroupValue: {
            regex: "",
            field: (approvalData?.isRecommenderChecked == "Yes" && approvalData?.recommenderType === "Group" && !recommenderGroupDD[0]?.id) ? "mandatory" : "",
            shouldNotBe: ""
        },
        approverValue: {
            regex: "",
            field: approvalData?.approverType === "Single" ? "mandatory" : "",
            shouldNotBe: ""
        },
        approverGroupValue: {
            regex: "",
            field: (approvalData?.isApproverChecked == "Yes" && approvalData?.approverType === "Group" && !approverGroupDD[0]?.id) ? "mandatory" : "",
            shouldNotBe: ""
        },
        min: {
            regex: "",
            field: "",
            shouldNotBe: "0",
        },
        max: {
            regex: "",
            field: "",
            shouldNotBe: "0",
        }
    }
    const updatedIsValueValidAll: Record<string, boolean> = {};
    const validateForm = (): boolean => {
        const updatedFieldValidity: Record<string, boolean> = {};
        const isMandatoryfalse = true;
        const isRegexfalse = true;
        Object.keys(userValidationRules).forEach((field) => {
            const rule = userValidationRules[field];
            const value = approvalData && approvalData[field];
            const isValueValid = (rule.field === "mandatory") ? (value ? (value !== rule.shouldNotBe) : false) : true;
            const isRegexValid = rule.regex !== "" ? new RegExp(rule.regex).test(value) : true;
            updatedFieldValidity[field] = !isValueValid || !isRegexValid;
            updatedIsValueValidAll[field] = !isValueValid;
        });
        setApprovalDataErrors(updatedFieldValidity);
        const isFormValid = Object.keys(updatedFieldValidity).every((keys) => {
            return !updatedFieldValidity[keys]
        });
        return isFormValid;
    };
    const clearAlert = (status: any) => {
        const timer = setTimeout(() => {
            setShowAlertBox(false);
            setShowMessage("");
            if (status === 400) {

            }
            clearTimeout(timer);
        }, 5000);
    }

    function duplicateCheck() {
        let isDuplicate = false;

        const newMin = parseInt(approvalData.min);
        const newMax = parseInt(approvalData.max);

        if (!isNaN(newMin) && !isNaN(newMax)) {
            if (!formDataEdit) {
                isDuplicate = approvalDataMain?.some((item: any) =>
                    item.departmentValue === approvalData.departmentValue &&
                    (
                        (newMin >= parseInt(item.min) && newMin <= parseInt(item.max)) ||
                        (newMax >= parseInt(item.min) && newMax <= parseInt(item.max)) ||
                        (parseInt(item.min) >= newMin && parseInt(item.max) <= newMax)
                    )
                );
            } else {
                isDuplicate = approvalDataMain?.some((item: any, index: number) => {
                    if (currentIndex !== index) {
                        return (
                            item.departmentValue === approvalData.departmentValue &&
                            (
                                (newMin >= parseInt(item.min) && newMin <= parseInt(item.max)) ||
                                (newMax >= parseInt(item.min) && newMax <= parseInt(item.max)) ||
                                (parseInt(item.min) >= newMin && parseInt(item.max) <= newMax)
                            )
                        );
                    }
                    return false;
                });
            }
        }

        if (isDuplicate) {
            setShowAlertBox(true);
            setApprovalDataErrors({
                departmentValue: true,
                max: true,
                min: true
            });
            setShowType("danger");
            setShowMessage("Department, Min, or Max Limit already exists.");
            clearAleart();
        }

        return isDuplicate;
    }
    function UsualDuplicateCheck() {
        let isDupliAll = false;

        if (!formDataEdit) {
            isDupliAll = approvalDataMain?.some((item: any) => {
                const isDepartmentMatch = item.departmentId == approvalData.departmentId;
                const isMinMatch = item.min == approvalData.min;
                const isMaxMatch = item.max == approvalData.max;
                const isMakerIdMatch = item.makerId == approvalData.makerId;
                const isMakerGroupIdMatch = item.makerGroupId == approvalData.makerGroupId;
                const isCheckerIdMatch = item.checkerId == approvalData.checkerId;
                const isCheckerGroupIdMatch = item.checkerGroupId == approvalData.checkerGroupId;
                const isRecommenderGroupIdMatch = item.recommenderGroupId == approvalData.recommenderGroupId;
                const isRecommenderIdMatch = item.recommenderId == approvalData.recommenderId;
                const isApproverGroupIdMatch = item.approverGroupId == approvalData.approverGroupId;
                const isApproverIdMatch = item.approverId == approvalData.approverId;
                return isDepartmentMatch && isMinMatch && isMaxMatch && isMakerIdMatch && isMakerGroupIdMatch && isCheckerIdMatch && isCheckerGroupIdMatch && isRecommenderGroupIdMatch && isRecommenderIdMatch &&
                    isApproverGroupIdMatch && isApproverIdMatch
            });
        } else {
            isDupliAll = approvalDataMain?.some((item: any, index: number) => {
                if (currentIndex !== index) {
                    const isDepartmentMatch = item.departmentId == approvalData.departmentId;
                    const isMaxMatch = item.max == approvalData.max;
                    const isMinMatch = item.min == approvalData.min;
                    const isMakerIdMatch = item.makerId == approvalData.makerId;
                    const isMakerGroupIdMatch = item.makerGroupId == approvalData.makerGroupId;
                    const isCheckerIdMatch = item.checkerId == approvalData.checkerId;
                    const isCheckerGroupIdMatch = item.checkerGroupId == approvalData.checkerGroupId;
                    const isRecommenderGroupIdMatch = item.recommenderGroupId == approvalData.recommenderGroupId;
                    const isRecommenderIdMatch = item.recommenderId == approvalData.recommenderId;
                    const isApproverGroupIdMatch = item.approverGroupId == approvalData.approverGroupId;
                    const isApproverIdMatch = item.approverId == approvalData.approverId;
                    return isDepartmentMatch && isMinMatch && isMaxMatch && isMakerIdMatch && isMakerGroupIdMatch && isCheckerIdMatch && isCheckerGroupIdMatch && isRecommenderGroupIdMatch && isRecommenderIdMatch &&
                        isApproverGroupIdMatch && isApproverIdMatch
                }
                return false;
            });
        }
        // }

        if (isDupliAll) {
            setShowAlertBox(true);
            setApprovalDataErrors({});
            setShowType("danger");
            setShowMessage("Entered Details already exists.");
            clearAleart();
        }

        return isDupliAll;
    }

    // function UsualDuplicateCheck() {
    //     let isDuplicate = false;
    //     let duplicateFields = {};

    //     const newMin = parseInt(approvalData.min);
    //     const newMax = parseInt(approvalData.max);

    //     if (!isNaN(newMin) && !isNaN(newMax)) {
    //         if (!formDataEdit) {
    //             isDuplicate = approvalDataMain?.some((item: any) => {
    //                 const duplicateFieldsItem = checkDuplicateFields(item);
    //                 if (Object.keys(duplicateFieldsItem).length > 0) {
    //                     duplicateFields = duplicateFieldsItem;
    //                     return true;
    //                 }
    //                 return false;
    //             });
    //         } else {
    //             isDuplicate = approvalDataMain?.some((item: any, index: number) => {
    //                 if (currentIndex !== index) {
    //                     const duplicateFieldsItem = checkDuplicateFields(item);
    //                     if (Object.keys(duplicateFieldsItem).length > 0) {
    //                         duplicateFields = duplicateFieldsItem;
    //                         return true;
    //                     }
    //                 }
    //                 return false;
    //             });
    //         }
    //     }

    //     if (isDuplicate) {
    //         setShowAlertBox(true);
    //         setApprovalDataErrors({
    //             ...duplicateFields,
    //         });
    //         setShowType("danger");
    //         setShowMessage("Duplicate entry found. Please check the highlighted fields.");
    //         clearAleart();
    //     }

    //     return isDuplicate;
    // }

    // function checkDuplicateFields(item: any) {
    //     const duplicateFields: any = {};

    //     const checkFields = [
    //         "departmentValue",
    //         "min",
    //         "max",
    //         "makerId",
    //         "makerGroupId",
    //         "checkerId",
    //         "checkerGroupId",
    //         "recommenderGroupId",
    //         "recommenderId",
    //         "approverGroupId",
    //         "approverId",
    //     ];

    //     checkFields.forEach((field: any) => {
    //         if (approvalData[field] !== item[field]) {
    //             duplicateFields[field] = true;
    //         }
    //     });

    //     return duplicateFields;
    // }



    function duplicateDropDownValues() {
        if ((approvalData?.makerId && approvalData?.isCheckerChecked == "Yes" && approvalData?.checkerId && approvalData?.makerId == approvalData?.checkerId) ||
            (approvalData?.makerId && approvalData?.isRecommenderChecked === "Yes" && approvalData?.recommenderId && approvalData?.makerId == approvalData?.recommenderId) ||
            (approvalData?.makerId && approvalData?.approverId && approvalData?.makerId == approvalData?.approverId) ||
            (approvalData?.isCheckerChecked == "Yes" && approvalData?.checkerId && approvalData?.isRecommenderChecked === "Yes" && approvalData.recommenderId && approvalData?.isCheckerChecked == "Yes" && approvalData?.checkerId == approvalData?.recommenderId) ||
            (approvalData?.isCheckerChecked == "Yes" && approvalData?.checkerId && approvalData?.approverId && approvalData?.isCheckerChecked == "Yes" && approvalData?.checkerId == approvalData?.approverId) ||
            (approvalData?.isRecommenderChecked === "Yes" && approvalData?.recommenderId && approvalData?.approverId && approvalData?.isRecommenderChecked === "Yes" && approvalData?.recommenderId == approvalData?.approverId)) {
            setShowAlertBox(true);
            setShowType("danger");
            setShowMessage("Admin cannot be selected for more than one Group.");
            clearAleart();
            return true;
        } else if ((approvalData?.makerGroupId && approvalData?.isCheckerChecked == "Yes" && approvalData?.checkerGroupId && approvalData?.makerGroupId == approvalData?.checkerGroupId) ||
            (approvalData?.makerGroupId && approvalData?.isRecommenderChecked === "Yes" && approvalData?.recommenderGroupId && approvalData.makerGroupId == approvalData?.recommenderGroupId) ||
            (approvalData?.makerGroupId && approvalData?.approverGroupId && approvalData?.makerGroupId == approvalData?.approverGroupId) ||
            (approvalData?.isCheckerChecked == "Yes" && approvalData?.checkerGroupId && approvalData?.isRecommenderChecked === "Yes" && approvalData?.recommenderGroupId && approvalData?.checkerGroupId == approvalData?.recommenderGroupId) ||
            (approvalData?.isCheckerChecked == "Yes" && approvalData?.checkerGroupId && approvalData?.approverGroupId && approvalData?.checkerGroupId == approvalData?.approverGroupId) ||
            (approvalData?.isRecommenderChecked === "Yes" && approvalData?.recommenderGroupId && approvalData?.approverGroupId && approvalData?.recommenderGroupId == approvalData?.approverGroupId)) {
            setShowAlertBox(true);
            setShowType("danger");
            setShowMessage("Admin cannot be selected for more than one Group.");
            clearAleart();
            return true;
        } else if (
            (approvalData?.makercheckid && approvalData?.checkerGroupId && approvalData?.makercheckid == approvalData?.checkerGroupId) ||
            (approvalData?.makercheckid && approvalData?.isRecommenderChecked == "Yes" && approvalData.recommenderGroupId && approvalData?.makercheckid == approvalData?.recommenderGroupId) ||
            (approvalData?.makercheckid && approvalData?.approverGroupId && approvalData?.makercheckid == approvalData?.approverGroupId) ||
            (approvalData?.isCheckerChecked == "Yes" && approvalData?.checkercheckid && approvalData?.makerGroupId && approvalData?.checkercheckid == approvalData?.makerGroupId) ||
            (approvalData?.isCheckerChecked == "Yes" && approvalData?.checkercheckid && approvalData?.isRecommenderChecked == "Yes" && approvalData?.recommenderGroupId && approvalData?.checkercheckid == approvalData?.recommenderGroupId) ||
            (approvalData?.isCheckerChecked == "Yes" && approvalData?.checkercheckid && approvalData?.approverGroupId && approvalData?.checkercheckid == approvalData?.approverGroupId)
        ) {
            setShowAlertBox(true);
            setShowType("danger");
            setShowMessage("Admin cannot be selected for more than one Group.");
            clearAleart();
            return true;
        } else {
            return false;
        }
    }

    // function dataChecking() {
    //     if (approvalDataMain.length < 1)
    //         return;
    //     const approvalDataHasValues = ( 
    //         approvalData &&
    //         approvalData?.departmentValue !== '' &&
    //         approvalData?.min !== '' &&
    //         approvalData?.max !== ''
    //     );

    //     const approvalDataMainHasEmptyValues = approvalDataMain?.some((data: any) => {
    //         return data?.departmentValue === '' || data?.min === '' || data?.max === '';
    //     });

    //     if (!approvalDataHasValues || approvalDataMainHasEmptyValues) {
    //         setShowAlertBox(true);
    //         setShowType("danger");
    //         setShowMessage("ghjghjghj");
    //         clearAleart();
    //     }
    //     return !approvalDataHasValues || approvalDataMainHasEmptyValues;
    // }

    async function handelAddButton() {
        let CheckingVal = UsualDuplicateCheck()
        if (CheckingVal) return;
        // let CheckingVal = dataChecking()
        // if (CheckingVal) return;
        let DropdownDupli = duplicateDropDownValues()
        if (DropdownDupli) return;
        let Duplicate = duplicateCheck();
        if (Duplicate) return;
        if (!validateForm()) {
            setShowAlertBox(true);
            setShowType("danger");
            setShowMessage(Object.values(updatedIsValueValidAll).every((values) => !values) ?
                "Some of the field(s) are not in required format." : "Mandatory field(s) should not be left blank.")
            if (approvalData.min == "0") {
                setShowMessage("Some of the field(s) are not in required format.")
            }
            clearAlert('');
            return;
        }

        if (formDataEdit) {
            let data: any = [];
            approvalDataMain.length > 0 && approvalDataMain.map((ele: any, i: number) => {
                if (i === currentIndex) {
                    data.push({
                        approvalType: "Group",
                        userType: approvalData?.userType,
                        makerType: approvalData?.makerType,
                        checkerType: approvalData?.checkerType,
                        approverType: approvalData?.approverType,
                        recommenderType: approvalData?.recommenderType,
                        singleUserValue: approvalData?.singleUserValue,
                        groupUserValue: approvalData?.groupUserValue,
                        singleUserId: approvalData?.singleUserId,
                        groupUserId: approvalData?.groupUserId,
                        department_id: approvalData?.department_id,
                        departmentValue: approvalData?.departmentValue,
                        min: approvalData?.min,
                        max: approvalData?.max,
                        isMakerChecked: approvalData?.isMakerChecked,
                        makerId: approvalData?.isMakerChecked === "Yes" && approvalData?.makerType === "Single" ? approvalData?.makerId : "",
                        makerValue: approvalData?.isMakerChecked === "Yes" && approvalData?.makerType === "Single" ? approvalData?.makerValue : "",
                        makerGroupId: approvalData?.isMakerChecked === "Yes" && approvalData?.makerType === "Group" ? approvalData?.makerGroupId : "",
                        makerGroupValue: approvalData?.isMakerChecked === "Yes" && approvalData?.makerType === "Group" ? approvalData?.makerGroupValue : "",
                        isCheckerChecked: approvalData?.isCheckerChecked,
                        checkerId: approvalData?.isCheckerChecked === "Yes" ? approvalData?.checkerId : "",
                        checkerValue: approvalData?.isCheckerChecked === "Yes" ? approvalData?.checkerValue : "",
                        checkerGroupId: approvalData?.isCheckerChecked === "Yes" && approvalData?.checkerType === "Group" ? approvalData?.checkerGroupId : "",
                        checkerGroupValue: approvalData?.isCheckerChecked === "Yes" && approvalData?.checkerType === "Group" ? approvalData?.checkerGroupValue : "",
                        isRecommenderChecked: approvalData?.isRecommenderChecked,
                        recommenderId: approvalData?.isRecommenderChecked === "Yes" ? approvalData?.recommenderId : "",
                        recommenderValue: approvalData?.isRecommenderChecked === "Yes" ? approvalData?.recommenderValue : "",
                        recommenderGroupId: approvalData?.isRecommenderChecked === "Yes" && approvalData?.recommenderType === "Group" ? approvalData?.recommenderGroupId : "",
                        recommenderGroupValue: approvalData?.isRecommenderChecked === "Yes" && approvalData?.recommenderType === "Group" ? approvalData?.recommenderGroupValue : "",
                        isApproverChecked: approvalData?.isApproverChecked,
                        approverId: approvalData?.isApproverChecked === "Yes" && approvalData?.approverType === "Single" ? approvalData?.approverId : "",
                        approverValue: approvalData?.isApproverChecked === "Yes" && approvalData?.approverType === "Single" ? approvalData?.approverValue : "",
                        approverGroupId: approvalData?.isApproverChecked === "Yes" && approvalData?.approverType === "Group" ? approvalData?.approverGroupId : "",
                        approverGroupValue: approvalData?.isApproverChecked === "Yes" && approvalData?.approverType === "Group" ? approvalData?.approverGroupValue : "",
                        index: 0
                    })

                } else if (ele.approvalType == "Group") {
                    console.log(ele)
                    data.push({
                        approvalType: ele.approvalType,
                        userType: ele.userType,
                        makerType: ele.makerType,
                        checkerType: ele.checkerType,
                        approverType: ele.approverType,
                        recommenderType: ele.recommenderType,
                        singleUserValue: ele.singleUserValue,
                        groupUserValue: ele.groupUserValue,
                        department_id: ele.department_id,
                        departmentValue: ele.departmentValue,
                        min: ele.min,
                        max: ele.max,
                        isMakerChecked: ele.isMakerChecked,
                        makerId: ele.isMakerChecked === "Yes" && ele.makerType === "Single" ? ele.makerId : "",
                        makerValue: ele.isMakerChecked === "Yes" && ele.makerType === "Single" ? ele.makerValue : "",
                        makerGroupId: ele.isMakerChecked === "Yes" && ele.makerType === "Group" ? ele.makerGroupId : "",
                        makerGroupValue: ele.isMakerChecked === "Yes" && ele.makerType === "Group" ? ele.makerGroupValue : "",
                        isCheckerChecked: ele.isCheckerChecked,
                        checkerId: ele.isCheckerChecked === "Yes" && ele.checkerType === "Single" ? ele.checkerId : "",
                        checkerValue: ele.isCheckerChecked === "Yes" && ele.checkerType === "Single" ? ele.checkerValue : "",
                        checkerGroupId: ele.isCheckerChecked === "Yes" && ele.checkerType === "Group" ? ele.checkerGroupId : "",
                        checkerGroupValue: ele.isCheckerChecked === "Yes" && ele.checkerType === "Group" ? ele.checkerGroupValue : "",
                        isRecommenderChecked: ele.isRecommenderChecked,
                        recommenderId: ele.isRecommenderChecked === "Yes" && ele.recommenderType === "Single" ? ele.recommenderId : "",
                        recommenderValue: ele.isRecommenderChecked === "Yes" && ele.recommenderType === "Single" ? ele.recommenderValue : "",
                        recommenderGroupId: ele.isRecommenderChecked === "Yes" && ele.recommenderType === "Group" ? ele.recommenderGroupId : "",
                        recommenderGroupValue: ele.isRecommenderChecked === "Yes" && ele.recommenderType === "Group" ? ele.recommenderGroupValue : "",
                        isApproverChecked: ele.isApproverChecked,
                        approverId: ele.isApproverChecked === "Yes" && ele.approverType === "Single" ? ele.approverId : "",
                        approverValue: ele.isApproverChecked === "Yes" && ele.approverType === "Single" ? ele.approverValue : "",
                        approverGroupId: ele.isApproverChecked === "Yes" && ele.approverType === "Group" ? ele.approverGroupId : "",
                        approverGroupValue: ele.isApproverChecked === "Yes" && ele.approverType === "Group" ? ele.approverGroupValue : "",
                        approvalId: ele.approvalId,
                        index: ele.index
                    })
                }
            })
            setFormDataEdit(false)
            setApprovalDataMain(data)
            setApprovalData(
                {
                    approvalType: approvalData.approvalType,
                    userType: "Single",
                    makerType: "Single",
                    checkerType: "Single",
                    approverType: "Single",
                    recommenderType: "Single",
                    singleUserId: "",
                    singleUserValue: "",
                    groupUserValue: "",
                    groupUserId: "",
                    department_id: "",
                    departmentValue: "",
                    min: "",
                    max: "",
                    isMakerChecked: "Yes",
                    makerId: "",
                    makerValue: "",
                    makerGroupId: "",
                    makerGroupValue: "",
                    isCheckerChecked: "",
                    checkerId: "",
                    checkerGroupId: "",
                    checkerValue: "",
                    checkerGroupValue: "",
                    isRecommenderChecked: "",
                    recommenderId: "",
                    recommenderGroupId: "",
                    recommenderValue: "",
                    recommenderGroupValue: "",
                    isApproverChecked: "Yes",
                    approverId: "",
                    approverGroupId: "",
                    approverValue: "",
                    approverGroupValue: "",
                    index: 0
                }
            )
            setIsFormDirty(false);
            setShowAlertBox(true);
            setShowType("success");
            setShowMessage("Approval Process updated successfully.")
            clearAlert('');
        }
        else {
            let newrecord = approvalDataMain?.filter((ele: any) => ele.approvalType == "Group")
            newrecord.push({
                approvalType: approvalData?.approvalType,
                userType: approvalData?.userType,
                makerType: approvalData?.makerType,
                checkerType: approvalData?.checkerType,
                approverType: approvalData?.approverType,
                recommenderType: approvalData?.recommenderType,
                singleUserValue: approvalData?.singleUserValue,
                groupUserValue: approvalData?.groupUserValue,
                groupUserId: approvalData?.groupUserId,
                singleUserId: approvalData?.singleUserId,
                department_id: approvalData?.department_id,
                departmentValue: approvalData?.departmentValue,
                min: approvalData?.min,
                max: approvalData?.max,
                isMakerChecked: approvalData?.isMakerChecked,
                makerId: approvalData.isMakerChecked === "Yes" && approvalData.makerType === "Single" ? approvalData.makerId : "",
                makerValue: approvalData?.isMakerChecked === "Yes" && approvalData?.makerType === "Single" ? approvalData?.makerValue : "",
                makerGroupId: approvalData?.isMakerChecked === "Yes" && approvalData?.makerType === "Group" ? approvalData?.makerGroupId : "",
                makerGroupValue: approvalData?.isMakerChecked === "Yes" && approvalData?.makerType === "Group" ? approvalData?.makerGroupValue : "",
                isCheckerChecked: approvalData?.isCheckerChecked,
                checkerId: approvalData?.isCheckerChecked === "Yes" ? approvalData?.checkerId : "",
                checkerValue: approvalData?.isCheckerChecked === "Yes" ? approvalData?.checkerValue : "",
                checkerGroupId: approvalData?.isCheckerChecked === "Yes" && approvalData?.checkerType === "Group" ? approvalData?.checkerGroupId : "",
                checkerGroupValue: approvalData?.isCheckerChecked === "Yes" && approvalData?.checkerType === "Group" ? approvalData?.checkerGroupValue : "",
                isRecommenderChecked: approvalData?.isRecommenderChecked,
                recommenderId: approvalData?.isRecommenderChecked === "Yes" ? approvalData?.recommenderId : "",
                recommenderValue: approvalData?.isRecommenderChecked === "Yes" ? approvalData?.recommenderValue : "",
                recommenderGroupId: approvalData?.isRecommenderChecked === "Yes" && approvalData?.recommenderType === "Group" ? approvalData?.recommenderGroupId : "",
                recommenderGroupValue: approvalData?.isRecommenderChecked === "Yes" && approvalData?.recommenderType === "Group" ? approvalData?.recommenderGroupValue : "",
                isApproverChecked: approvalData?.isApproverChecked,
                approverId: approvalData?.isApproverChecked === "Yes" && approvalData?.approverType === "Single" ? approvalData?.approverId : "",
                approverValue: approvalData?.isApproverChecked === "Yes" && approvalData?.approverType === "Single" ? approvalData?.approverValue : "",
                approverGroupId: approvalData?.isApproverChecked === "Yes" && approvalData?.approverType === "Group" ? approvalData?.approverGroupId : "",
                approverGroupValue: approvalData?.isApproverChecked === "Yes" && approvalData?.approverType === "Group" ? approvalData?.approverGroupValue : "",
                index: approvalData.index
            })
            setApprovalDataMain(newrecord)
            setApprovalData(
                {
                    approvalType: approvalData.approvalType,
                    userType: "Single",
                    makerType: "Single",
                    checkerType: "Single",
                    approverType: "Single",
                    recommenderType: "Single",
                    singleUserId: "",
                    singleUserValue: "",
                    groupUserValue: "",
                    groupUserId: "",
                    department_id: "",
                    departmentValue: "",
                    min: "",
                    max: "",
                    isMakerChecked: "Yes",
                    makerId: "",
                    makerValue: "",
                    makerGroupId: "",
                    makerGroupValue: "",
                    isCheckerChecked: "",
                    checkerId: "",
                    checkerGroupId: "",
                    checkerValue: "",
                    checkerGroupValue: "",
                    isRecommenderChecked: "",
                    recommenderId: "",
                    recommenderGroupId: "",
                    recommenderValue: "",
                    recommenderGroupValue: "",
                    isApproverChecked: "Yes",
                    approverId: "",
                    approverGroupId: "",
                    approverValue: "",
                    approverGroupValue: "",
                    index: 0
                }
            )
            setIsFormDirty(false);
            setFormDataEdit(false)
        }
    }

    function singleUserUpdate(userType: any, singleUserId: any, singleUserValue: any, groupUserValue: any, groupUserId: any) {
        let data: any = [];
        data.push({
            approvalType: "Single",
            userType: userType,
            makerType: "",
            checkerType: "",
            approverType: "",
            recommenderType: "",
            singleUserId: singleUserId,
            singleUserValue: singleUserValue,
            groupUserValue: groupUserValue,
            groupUserId: groupUserId,
            department_id: null,
            departmentValue: "",
            min: null,
            max: null,
            isMakerChecked: "Yes",
            makerId: "",
            makerValue: "",
            makerGroupId: "",
            makerGroupValue: "",
            isCheckerChecked: "",
            checkerId: "",
            checkerValue: "",
            checkerGroupId: "",
            checkerGroupValue: "",
            isRecommenderChecked: "",
            recommenderId: "",
            recommenderValue: "",
            recommenderGroupId: "",
            recommenderGroupValue: "",
            isApproverChecked: "Yes",
            approverId: "",
            approverValue: "",
            approverGroupId: "",
            approverGroupValue: "",
            approvalId: "",
        });
        setApprovalDataMain(data)
    }
    function retriveData(value: any, index: number) {
        setApprovalDataErrors({})
        setCurrentIndex(index)
        fetchDataMakerDD(value.department_id)
        setCurrentApprovalData(value.id)
        setFormDataEdit(true)
        setIsFormDirty(true);
        if (Object.values(value).length !== 3) {
            setApprovalData({
                ...approvalData,
                ...value,
                // singleUserValue: "",
                // singleUserId: "",
                // groupUserValue: "",
                // groupUserId: "",
                // userType: "Single"
            })

        }

    }

    useEffect(() => {
        fetchData();
        fetchSingleData();
        fetchGroupData();
        fetchDataMakerDD('null')
    }, []);




    const fetchSingleData = async () => {
        try {
            const response = await axiosPrivate.get("company-approval/all-UserByPrivilege");
            if (response.status === 200) {
                const dropdownValues = response.data.decryptedUsers.map((item: any) => ({
                    label: item.name,
                    value: item.name,
                    id: item.userId,
                }));
                const sortedData = [...dropdownValues].sort((a, b) => a.label.localeCompare(b.label));
                setUserDD(sortedData);
            }
        } catch (error) {
        }
    };
    const fetchGroupData = async () => {
        try {
            const response = await axiosPrivate.get("company-approval/group-Users/dropdown");
            if (response.status === 200) {
                const dropdownValues = response.data.decryptedGroups.map((item: any) => ({
                    label: item.groupName,
                    value: item.groupName,
                    id: item.groupId,
                }));
                const sortedData = [...dropdownValues].sort((a, b) => a.label.localeCompare(b.label));
                setUserGroupDD(sortedData);
            }
        } catch (error) {
        }
    };
    const fetchData = async () => {
        try {
            const response = await axiosPrivate.get("/company-approval/department");
            if (response.status === 200) {
                const dropdownValues = response.data.departmentDetails.map((item: any) => ({
                    label: item.departmentName,
                    value: item.departmentName,
                    id: item.departmentId,
                }));
                const sortedData = [...dropdownValues].sort((a, b) => a.label.localeCompare(b.label));
                setDepartmentDD(sortedData);
            }
        } catch (error) {
        }
    };

    const fetchDataMakerDD = async (id: any) => {
        try {
            const response = await axiosPrivate.get(`/company-approval/userByPrivilege/${id}`);
            if (response.status === 200) {
                const makerValues = response.data.makers.map((item: any) => ({
                    label: item.name,
                    value: item.name,
                    id: item.userId,
                    checkid: item.groupId,
                }));
                const sortedDataMaker = [...makerValues].sort((a, b) => a.label.localeCompare(b.label));
                setMakerDD(sortedDataMaker);

                const makerGroupValues = response.data.makersGroup.map((item: any) => ({
                    label: item.name,
                    value: item.name,
                    id: item.groupId,
                }));

                if (makerGroupValues.length > 0) {
                    setMakerGroupDD(makerGroupValues)
                } else {
                    setMakerGroupDD([{ label: "Select an Option", value: "", id: "" }])
                }

                const checkerValues = response.data.checkers.map((item: any) => ({
                    label: item.name,
                    value: item.name,
                    id: item.userId,
                    checkid: item.groupId,
                }));
                const sortedDataChecker = [...checkerValues].sort((a, b) => a.label.localeCompare(b.label));
                setCheckerDD(sortedDataChecker);


                const checkerGroupValues = response.data.checkersGroup.map((item: any) => ({
                    label: item.name,
                    value: item.name,
                    id: item.groupId,
                }));

                if (checkerGroupValues.length > 0) {
                    setCheckerGroupDD(checkerGroupValues)
                } else {
                    setCheckerGroupDD([{ label: "Select an Option", value: "", id: "" }])
                }

                const makerValuesRecommender = response.data.recommenders.map((item: any) => ({
                    label: item.name,
                    value: item.name,
                    id: item.userId,
                    checkid: item.groupId,
                }));
                const sortedDataRecommender = [...makerValuesRecommender].sort((a, b) => a.label.localeCompare(b.label));
                setRecommenderDD(sortedDataRecommender);

                const recommenderGroupValues = response.data.recommendersGroup.map((item: any) => ({
                    label: item.name,
                    value: item.name,
                    id: item.groupId,
                }));

                if (recommenderGroupValues.length > 0) {
                    setRecommenderGroupDD(recommenderGroupValues)
                } else {
                    setRecommenderGroupDD([{ label: "Select an Option", value: "", id: "" }])
                }

                const makerValuesApprover = response.data.approvers.map((item: any) => ({
                    label: item.name,
                    value: item.name,
                    id: item.userId,
                    checkid: item.groupId,
                }));
                const sortedDataApprover = [...makerValuesApprover].sort((a, b) => a.label.localeCompare(b.label));
                setApproverDD(sortedDataApprover);

                const approverGroupValues = response.data.approversGroup.map((item: any) => ({
                    label: item.name,
                    value: item.name,
                    id: item.groupId,
                }));

                if (approverGroupValues.length > 0) {
                    setApproverGroupDD(approverGroupValues)
                } else {
                    setApproverGroupDD([{ label: "Select an Option", value: "", id: "" }])
                }
            }
        } catch (error) {
            console.log(error, "No records Found")
        }
    };
    const clearAleart = () => {
        const timer = setTimeout(() => {
            setShowAlertBox(false);
            setShowMessage("");
            clearTimeout(timer);
        }, 5000);
    }
    const DirectorInfoDelete = async () => {
        if (currentDeletVal.approvalId) {
            try {
                const response = await axiosPrivate.patch(`/company-approval/approval-process/${currentDeletVal.approvalId}`);
                if (response.status === 200) {
                    setShowDeleteModel(false);
                    const newArray = [...approvalDataMain];
                    newArray.splice(deleteIndex, 1);
                    setApprovalDataMain(newArray);
                    setApprovalData(
                        {
                            approvalType: approvalData.approvalType,
                            userType: "Single",
                            makerType: "Single",
                            checkerType: "Single",
                            approverType: "Single",
                            recommenderType: "Single",
                            singleUserId: "",
                            singleUserValue: "",
                            groupUserValue: "",
                            groupUserId: "",
                            department_id: "",
                            departmentValue: "",
                            min: "",
                            max: "",
                            isMakerChecked: "Yes",
                            makerId: "",
                            makerValue: "",
                            makerGroupId: "",
                            makerGroupValue: "",
                            isCheckerChecked: "",
                            checkerId: "",
                            checkerGroupId: "",
                            checkerValue: "",
                            checkerGroupValue: "",
                            isRecommenderChecked: "",
                            recommenderId: "",
                            recommenderGroupId: "",
                            recommenderValue: "",
                            recommenderGroupValue: "",
                            isApproverChecked: "Yes",
                            approverId: "",
                            approverGroupId: "",
                            approverValue: "",
                            approverGroupValue: "",
                            index: 0
                        }
                    )
                    setShowAlertBox(true)
                    setShowType("success")
                    setShowMessage(response.data.message)
                    clearAleart()
                }
            } catch (error) {
            }

        }
        else if (currentDeletVal.index === 0) {
            const newArray = [...approvalDataMain];
            newArray.splice(deleteIndex, 1);
            setApprovalDataMain(newArray)
            setApprovalData(
                {
                    approvalType: approvalData.approvalType,
                    userType: "Single",
                    makerType: "Single",
                    checkerType: "Single",
                    approverType: "Single",
                    recommenderType: "Single",
                    singleUserId: "",
                    singleUserValue: "",
                    groupUserValue: "",
                    groupUserId: "",
                    department_id: "",
                    departmentValue: "",
                    min: "",
                    max: "",
                    isMakerChecked: "Yes",
                    makerId: "",
                    makerValue: "",
                    makerGroupId: "",
                    makerGroupValue: "",
                    isCheckerChecked: "",
                    checkerId: "",
                    checkerGroupId: "",
                    checkerValue: "",
                    checkerGroupValue: "",
                    isRecommenderChecked: "",
                    recommenderId: "",
                    recommenderGroupId: "",
                    recommenderValue: "",
                    recommenderGroupValue: "",
                    isApproverChecked: "Yes",
                    approverId: "",
                    approverGroupId: "",
                    approverValue: "",
                    approverGroupValue: "",
                    index: 0
                }
            )
            setShowDeleteModel(false)
            setShowAlertBox(true)
            setShowType("success")
            setShowMessage("The selected record(s) has been deleted")
            clearAleart()
        }
    };

    function next(next: any = "") {
        if (isEditMode && approvalDataMain.length > 0) {
            sendDataApi();
            setIconStatus({
                ...iconStatus,
                subscriptionIcon: "completed",

            })
        } else {
            if (approvalDataMain.length > 0) {
                sendDataApi();
                setIconStatus({
                    ...iconStatus,
                    subscriptionIcon: "completed",
                    approvalIcon: "selected"
                })
            }
        }
    }
    const formatAmount = (amount: any) => {
        const onlyNumber = amount?.toString().replace(/[^0-9.]/g, "");
        if (!onlyNumber) { return ""; }
        // Split input into integer and decimal parts     
        // const parts = onlyNumber?.split(".");
        const integerPart = onlyNumber; const decimalPart = "";
        // Format the integer part with commas      
        const formattedInteger = parseFloat(integerPart).toLocaleString('en-IN');
        // Handle complete decimal input (e.g., "5000.50") 
        if (decimalPart !== "") { return `${formattedInteger}.${decimalPart}`; }
        // Handle incomplete decimal input (e.g., "5000.")        
        if (amount?.toString().endsWith(".")) { return `${formattedInteger}.`; }
        // Return formatted integer 
        return formattedInteger;
    };

    return (
        <div className='form-wrapper'>
            <div className='form-area-address'>
                <div className='company-heading'>
                    Approval Process
                </div>
                <div className='approval-flex'>
                    <div className={`${approvalDataMain && approvalDataMain[0]?.approvalType == "Group" && approvalDataMain[0]?.noSwitchApprovalProcess ? "disable-noswitch" : "single-user"} `}
                        onClick={
                            (event: any) => {
                                if (approvalDataMain && approvalDataMain[0]?.approvalType == "Group" && approvalDataMain[0].noSwitchApprovalProcess) return;
                                handleSingleClick(event)
                            }
                        } style={singleUserStyle}>
                        Single User Approval
                    </div>
                    <div className='single-divider'></div>
                    {/* <div className={"group-user"} */}
                    <div className={`${approvalDataMain && approvalDataMain[0]?.approvalType == "Single" && approvalDataMain[0]?.noSwitchApprovalProcess ? "disable-noswitch" : "group-user"} `}
                        onClick={
                            (event: any) => {
                                if (approvalDataMain && approvalDataMain[0]?.approvalType == "Single" && approvalDataMain[0].noSwitchApprovalProcess) return;
                                handleGroupClick(event)
                            }
                        }
                        style={groupUserStyle}>
                        Group User Approval
                    </div>
                </div>
                {approvalData?.approvalType === "Single" &&
                    <div>
                        <div className='icon-flex'>
                            <div onClick={
                                (event: any) => {
                                    if (approvalDataMain && approvalDataMain[0]?.approvalType == "Single" && approvalDataMain[0]?.noSwitchApprovalProcess) return;

                                    handleSingleIconClick(event)
                                }
                            }>
                                <SingleUser style={singleUserIconStyle} />
                            </div>
                            <div className='icon-divider'></div>
                            <div onClick={
                                (event: any) => {
                                    if (approvalDataMain && approvalDataMain[0]?.approvalType == "Single" && approvalDataMain[0]?.noSwitchApprovalProcess) return;

                                    handleGroupIconClick(event)
                                }
                            } >
                                <GroupUser style={groupUserIconStyle} />
                            </div>
                        </div>
                        <div className='border-dropdown'>
                            {approvalData?.userType === "Single" &&
                                <div key={uuidv4()} className='single-dropdown-unique' >

                                    <DropdownComponent
                                        width={"200px"}
                                        required={true}
                                        title={"User"}
                                        defaultValue={[{ label: approvalData && (!approvalData?.singleUserValue) ? "Select User" : approvalData?.singleUserValue, value: approvalData && (approvalData?.singleUserValue === (undefined || "")) ? "Select User" : approvalData?.singleUserValue }]}
                                        options={userDD}
                                        isDisabled={approvalDataMain && approvalDataMain[0]?.approvalType == "Single" && approvalDataMain[0]?.noSwitchApprovalProcess}
                                        getData={(value: any) => {
                                            setApprovalData({
                                                ...approvalData,
                                                singleUserValue: value.value,
                                                singleUserId: value.id,
                                                groupUserValue: "",
                                                groupUserId: "",
                                            })
                                            singleUserUpdate(approvalData.userType, value.id, value.value, "", "");
                                        }}
                                    />
                                </div>
                            }
                            {approvalData?.userType === "Group" &&
                                <div key={uuidv4()} className='single-dropdown-unique' >
                                    <DropdownComponent
                                        width={"200px"}
                                        required={true}
                                        title={"Group"}
                                        isDisabled={approvalDataMain && approvalDataMain[0]?.approvalType == "Single" && approvalDataMain[0]?.noSwitchApprovalProcess}
                                        defaultValue={[{ label: approvalData && (!approvalData?.groupUserValue) ? "Select Group" : approvalData?.groupUserValue, value: approvalData && (approvalData?.groupUserValue === (undefined || "")) ? "Select Group" : approvalData?.groupUserValue }]}
                                        options={userGroupDD}
                                        getData={(value: any) => {
                                            setApprovalData({
                                                ...approvalData,
                                                groupUserValue: value.value,
                                                groupUserId: value.id,
                                                singleUserValue: "",
                                                singleUserId: "",
                                            })
                                            singleUserUpdate(approvalData.userType, "", "", value.value, value.id);
                                        }}
                                    />
                                </div>
                            }

                        </div>
                    </div>
                }
                {approvalData?.approvalType === "Group" &&
                    <div>
                        <div className='department-flex'>
                            <div key={uuidv4()} className={approvalDataErrors.departmentValue ? 'setErrorcompany' : ""} style={{ marginTop: "30px", marginRight: "45px", marginBottom: "30px", width: "300px" }} >
                                <DropdownComponent
                                    width={"300px"}
                                    // required={true}
                                    title={"Department"}
                                    defaultValue={[{ label: approvalData && (approvalData?.departmentValue === (undefined || "")) ? "Select" : approvalData?.departmentValue, value: approvalData && (approvalData?.departmentValue === (undefined || "")) ? "Select" : approvalData?.departmentValue }]}
                                    options={departmentDD}
                                    getData={(value: any) => {
                                        setApprovalData({
                                            ...approvalData,
                                            departmentValue: value.value,
                                            department_id: value.id,
                                            makerValue: "",
                                            checkerValue: "",
                                            recommenderValue: "",
                                            approverValue: "",
                                        })
                                        setIsFormDirty(true)
                                        dispatch(resetFormModified(true));
                                        fetchDataMakerDD(value.id)

                                    }}
                                />
                            </div>
                        </div>
                        <div style={{ display: "flex", flex: "wrap" }}>
                            <div className='dropdown-input'>
                                <InputComponent
                                    height={"41px"}
                                    width={"200px"}
                                    margin={"0px 60px 30px 0px"}
                                    padding={"0px 5px 0px 30px"}
                                    border={approvalDataErrors.min ? "1px solid red" : "1px solid #A9C3DC"}
                                    borderRadius={"0px"}
                                    backgroundColor={"white"}
                                    color={"black"}
                                    type={"mobile"}
                                    textAlign={"right"}
                                    maxLength={10}
                                    inputTitle={"Min Amount"}
                                    placeHolder={"Enter Min Amount"}
                                    // required={true}
                                    inputValue={formatAmount(approvalData.min)}
                                    getUser={(value: any) => {
                                        const baseAmount = value.replace(/[^0-9]/g, "").replace(/(\..*)\./g, '$1').replace(/^(\d*\.\d{0,2}).*/, '$1');
                                        setApprovalData({
                                            ...approvalData,
                                            min: baseAmount
                                        });
                                        setIsFormDirty(true)
                                        dispatch(resetFormModified(true));
                                    }}
                                />

                                <div className='dropdown-position card-min'>
                                    <div className={!approvalDataErrors.min ? 'setError-company' : 'dropdown-card'}>
                                        <DropdownComponent
                                            options={bankValues}
                                            title={""}
                                            required={false}
                                            isDisabled={true}
                                            // border={approvalDataErrors.max ? "1px solid red" : "1px solid #A9C3DC"}
                                            getData={(val) => {
                                            }}
                                            defaultValue={[{ label: "INR", value: "INR" }]}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <img className='rupee-symbol-position-company' src={rupeeImg} alt="" />
                                </div>
                            </div>

                            <div className='dropdown-input'>
                                <InputComponent
                                    height={"41px"}
                                    width={"200px"}
                                    margin={"0px 60px 30px 0px"}
                                    padding={"0px 5px 0px 30px"}
                                    border={approvalDataErrors.max ? "1px solid red" : "1px solid #A9C3DC"}
                                    borderRadius={"0px"}
                                    backgroundColor={"white"}
                                    color={"black"}
                                    type={"mobile"}
                                    textAlign={"right"}
                                    maxLength={10}
                                    inputTitle={"Max Amount"}
                                    placeHolder={"Enter Max Amount"}
                                    // required={true}
                                    inputValue={formatAmount(approvalData.max)}
                                    getUser={(value: any) => {
                                        const maxAmount = value.replace(/[^0-9]/g, "").replace(/(\..*)\./g, '$1').replace(/^(\d*\.\d{0,2}).*/, '$1');
                                        setApprovalData({
                                            ...approvalData,
                                            max: maxAmount
                                        });
                                        setIsFormDirty(true)
                                        dispatch(resetFormModified(true));
                                    }}
                                />

                                <div className='dropdown-position card-min'>
                                    <div className={approvalDataErrors.max ? 'setError-company' : 'dropdown-card'}>
                                        <DropdownComponent
                                            options={bankValues}
                                            title={""}
                                            required={false}
                                            isDisabled={true}
                                            // border={approvalDataErrors.max ? "1px solid red" : "1px solid #A9C3DC"}
                                            getData={(val) => {
                                            }}
                                            defaultValue={[{ label: "INR", value: "INR" }]}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <img className='rupee-symbol-position-company' src={rupeeImg} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className='group-flex'>
                            {/* style={(approvalData?.departmentValue !== "" || undefined) ? {} : { opacity: 0.4, pointerEvents: "none" }} */}
                            <div style={{ marginRight: "35px" }}>
                                <div className='icon-flex'>
                                    <div onClick={handleSingleMakerIconClick}>
                                        <SingleUser style={singleMakerIconStyle} />
                                    </div>
                                    <div className='icon-divider'></div>
                                    <div onClick={handleGroupMakerIconClick} >
                                        <GroupUser style={groupMakerIconStyle} />
                                    </div>
                                </div>
                                <div className='border-dropdown'>
                                    {approvalData?.makerType === "Single" &&
                                        <div style={{ marginRight: "45px", marginBottom: "30px", width: "200px" }} >
                                            <div className='checkbox-flex' style={{ marginRight: "45px", marginBottom: "10px" }}>
                                                <label className='label-flex'>
                                                    <input type="checkbox" checked={true}
                                                        onClick={(e: any) => {
                                                            // const value = e.target.checked ? 'Yes' : 'No';
                                                            // setApprovalData({ ...approvalData, isMakerChecked: value })

                                                        }} />
                                                    <span className='signatory-checkbox'>Maker</span><span className='required'>*</span></label>
                                            </div>
                                            <div key={uuidv4()} className={approvalDataErrors.makerValue ? 'setErrorapproval' : ""}>
                                                <DropdownComponent
                                                    width={"200px"}
                                                    required={true}
                                                    title={""}
                                                    // isDisabled={(approvalData?.departmentValue !== "" || undefined) ? false : true}
                                                    defaultValue={[{ label: approvalData && (!approvalData?.makerValue) ? "Select User" : approvalData?.makerValue, value: approvalData && (approvalData?.makerValue === (undefined || "")) ? "Select User" : approvalData?.makerValue }]}
                                                    options={makerDD}
                                                    getData={(value: any) => {
                                                        setApprovalData({
                                                            ...approvalData,
                                                            makerValue: value.value,
                                                            makerId: value.id,
                                                            makercheckid: value.checkid,
                                                            makerGroupValue: "",
                                                            makerGroupId: "",
                                                        });
                                                        setIsFormDirty(true)
                                                        dispatch(resetFormModified(true));
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    }
                                    {approvalData?.makerType === "Group" &&
                                        <div style={{ marginRight: "45px", marginBottom: "30px", width: "200px" }} >
                                            <div className='checkbox-flex' style={{ marginRight: "45px", marginBottom: "10px" }}>
                                                <label className='label-flex'>
                                                    <input type="checkbox" checked={true}
                                                        onClick={(e: any) => {
                                                            // const value = e.target.checked ? 'Yes' : 'No';
                                                            // setApprovalData({ ...approvalData, isMakerChecked: value })
                                                        }} />
                                                    <span className='signatory-checkbox'>Maker</span><span className='required'>*</span></label>
                                            </div>
                                            <div key={uuidv4()} className={approvalDataErrors.makerGroupValue ? 'setErrorapproval' : ""}>
                                                <DropdownComponent
                                                    width={"200px"}
                                                    required={true}
                                                    title={""}
                                                    defaultValue={[{ label: approvalData && (!approvalData?.makerGroupValue) ? "Select Group" : approvalData?.makerGroupValue, value: approvalData && (!approvalData?.makerGroupValue) ? "Select Group" : approvalData?.makerGroupValue }]}
                                                    options={makerGroupDD}
                                                    getData={(value: any) => {
                                                        setApprovalData({
                                                            ...approvalData,
                                                            makerGroupValue: value.value,
                                                            makerGroupId: value.id,
                                                            makerValue: "",
                                                            makerId: "",
                                                        });
                                                        setIsFormDirty(true)
                                                        dispatch(resetFormModified(true));
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    }

                                </div>
                            </div>
                            <div style={{ marginRight: "35px" }}>
                                <div className='icon-flex'>
                                    <div onClick={handleSingleCheckerIconClick}>
                                        <SingleUser style={singleCheckerIconStyle} />
                                    </div>
                                    <div className='icon-divider'></div>
                                    <div onClick={handleGroupCheckerIconClick} >
                                        <GroupUser style={groupCheckerIconStyle} />
                                    </div>
                                </div>
                                <div className='border-dropdown'>
                                    {approvalData?.checkerType === "Single" &&
                                        <div style={{ marginRight: "45px", marginBottom: "30px", width: "200px" }} >
                                            <div className='checkbox-flex' style={{ marginRight: "45px", marginBottom: "10px" }}>
                                                <label className='label-flex'>
                                                    <input type="checkbox" checked={approvalData.isCheckerChecked === 'Yes'}
                                                        // disabled={(approvalData?.departmentValue !== "" || undefined) ? false : true}
                                                        onClick={(e: any) => {
                                                            const value = e.target.checked ? 'Yes' : 'No';
                                                            setApprovalData({ ...approvalData, isCheckerChecked: value })

                                                        }} />
                                                    <span className='signatory-checkbox'>Checker</span></label>
                                            </div>
                                            <div key={uuidv4()} className={approvalDataErrors.checkerValue ? 'setErrorapproval' : ""} >
                                                <DropdownComponent
                                                    width={"200px"}
                                                    required={true}
                                                    title={""}
                                                    // isDisabled={(approvalData?.departmentValue !== "" || undefined) ? false : true}
                                                    defaultValue={[{ label: approvalData && (!approvalData?.checkerValue) ? "Select User" : approvalData?.checkerValue, value: approvalData && (approvalData?.checkerValue === (undefined || "")) ? "Select User" : approvalData?.checkerValue }]}
                                                    options={checkerDD}
                                                    getData={(value: any) => {
                                                        setApprovalData({
                                                            ...approvalData,
                                                            checkerValue: value.value,
                                                            checkerId: value.id,
                                                            checkercheckid: value.checkid,
                                                            checkerGroupValue: "",
                                                            checkerGroupId: "",
                                                        });
                                                        setIsFormDirty(true)
                                                        dispatch(resetFormModified(true));
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    }
                                    {approvalData?.checkerType === "Group" &&
                                        <div style={{ marginRight: "45px", marginBottom: "30px", width: "200px" }} >
                                            <div className='checkbox-flex' style={{ marginRight: "45px", marginBottom: "10px" }}>
                                                <label className='label-flex'>
                                                    <input type="checkbox" checked={approvalData.isCheckerChecked === 'Yes'}
                                                        // disabled={(approvalData?.departmentValue !== "" || undefined) ? false : true}
                                                        onClick={(e: any) => {
                                                            const value = e.target.checked ? 'Yes' : 'No';
                                                            setApprovalData({ ...approvalData, isCheckerChecked: value })

                                                        }} />
                                                    <span className='signatory-checkbox'>Checker</span></label>
                                            </div>
                                            <div className={approvalDataErrors.checkerGroupValue ? 'setErrorapproval' : ""} key={uuidv4()}>
                                                <DropdownComponent
                                                    width={"200px"}
                                                    required={true}
                                                    title={""}
                                                    defaultValue={[{ label: approvalData && (!approvalData?.checkerGroupValue) ? "Select Group" : approvalData?.checkerGroupValue, value: approvalData && (!approvalData?.checkerGroupValue) ? "Select Group" : approvalData?.checkerGroupValue }]}
                                                    options={checkerGroupDD}
                                                    getData={(value: any) => {
                                                        setApprovalData({
                                                            ...approvalData,
                                                            checkerGroupValue: value.value,
                                                            checkerGroupId: value.id,
                                                            checkerValue: "",
                                                            checkerId: "",
                                                        });
                                                        setIsFormDirty(true)
                                                        dispatch(resetFormModified(true));
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    }

                                </div>
                            </div>
                            <div style={{ marginRight: "35px" }} >
                                <div className='icon-flex'>
                                    <div onClick={handleSingleRecommenderIconClick}>
                                        <SingleUser style={singleRecommenderIconStyle} />
                                    </div>
                                    <div className='icon-divider'></div>
                                    <div onClick={handleGroupRecommenderIconClick} >
                                        <GroupUser style={groupRecommenderIconStyle} />
                                    </div>
                                </div>
                                <div className='border-dropdown'>
                                    {approvalData?.recommenderType === "Single" &&
                                        <div style={{ marginRight: "45px", marginBottom: "30px", width: "200px" }} >
                                            <div className='checkbox-flex' style={{ marginRight: "45px", marginBottom: "10px" }}>
                                                <label className='label-flex'>
                                                    <input type="checkbox" checked={approvalData.isRecommenderChecked === 'Yes'}
                                                        // disabled={(approvalData?.departmentValue !== "" || undefined) ? false : true}
                                                        onClick={(e: any) => {
                                                            const value = e.target.checked ? 'Yes' : 'No';
                                                            setApprovalData({ ...approvalData, isRecommenderChecked: value })

                                                        }} />
                                                    <span className='signatory-checkbox'>Recommender</span></label>
                                            </div>
                                            <div key={uuidv4()} className={approvalDataErrors.recommenderValue ? 'setErrorapproval' : ""}>
                                                <DropdownComponent
                                                    width={"200px"}
                                                    required={true}
                                                    title={""}
                                                    // isDisabled={(approvalData?.departmentValue !== "" || undefined) ? false : true}
                                                    defaultValue={[{ label: approvalData && (!approvalData?.recommenderValue) ? "Select User" : approvalData?.recommenderValue, value: approvalData && (approvalData?.recommenderValue === (undefined || "")) ? "Select User" : approvalData?.recommenderValue }]}
                                                    options={recommenderDD}
                                                    getData={(value: any) => {
                                                        setApprovalData({
                                                            ...approvalData,
                                                            recommenderValue: value.value,
                                                            recommenderId: value.id,
                                                            recommendercheckid: value.checkid,
                                                            recommenderGroupValue: "",
                                                            recommenderGroupId: "",
                                                        });
                                                        setIsFormDirty(true)
                                                        dispatch(resetFormModified(true));
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    }
                                    {approvalData?.recommenderType === "Group" &&
                                        <div style={{ marginRight: "45px", marginBottom: "30px", width: "200px" }} >
                                            <div className='checkbox-flex' style={{ marginRight: "45px", marginBottom: "10px" }}>
                                                <label className='label-flex'>
                                                    <input type="checkbox" checked={approvalData?.isRecommenderChecked === 'Yes'}
                                                        // disabled={(approvalData?.departmentValue !== "" || undefined) ? false : true}
                                                        onClick={(e: any) => {
                                                            const value = e.target.checked ? 'Yes' : 'No';
                                                            setApprovalData({ ...approvalData, isRecommenderChecked: value })

                                                        }} />
                                                    <span className='signatory-checkbox'>Recommender</span></label>
                                            </div>
                                            <div className={approvalDataErrors.recommenderGroupValue ? 'setErrorapproval' : ""} key={uuidv4()}>
                                                <DropdownComponent
                                                    width={"200px"}
                                                    required={true}
                                                    title={""}
                                                    defaultValue={[{ label: approvalData && (!approvalData?.recommenderGroupValue) ? "Select Group" : approvalData?.recommenderGroupValue, value: approvalData && (!approvalData?.recommenderGroupValue) ? "Select Group" : approvalData?.recommenderGroupValue }]}
                                                    options={recommenderGroupDD}
                                                    getData={(value: any) => {
                                                        setApprovalData({
                                                            ...approvalData,
                                                            recommenderGroupValue: value.value,
                                                            recommenderGroupId: value.id,
                                                            recommenderValue: "",
                                                            recommenderId: "",
                                                        });
                                                        setIsFormDirty(true)
                                                        dispatch(resetFormModified(true));
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    }

                                </div>
                            </div>
                            <div>
                                <div className='icon-flex'>
                                    <div onClick={handleSingleApproverIconClick}>
                                        <SingleUser style={singleApproverIconStyle} />
                                    </div>
                                    <div className='icon-divider'></div>
                                    <div onClick={handleGroupApproverIconClick} >
                                        <GroupUser style={groupApproverIconStyle} />
                                    </div>
                                </div>
                                <div className='border-dropdown'>
                                    {approvalData?.approverType === "Single" &&
                                        <div style={{ marginRight: "45px", marginBottom: "30px", width: "200px" }} >
                                            <div className='checkbox-flex' style={{ marginRight: "45px", marginBottom: "10px" }}>
                                                <label className='label-flex'>
                                                    <input type="checkbox" checked={true}
                                                        onClick={(e: any) => {
                                                            // const value = e.target.checked ? 'Yes' : 'No';
                                                            // setApprovalData({ ...approvalData, isApproverChecked: value })

                                                        }} />
                                                    <span className='signatory-checkbox'>Approver</span><span className='required'>*</span></label>
                                            </div>
                                            <div key={uuidv4()} className={approvalDataErrors.approverValue ? 'setErrorapproval' : ""}>
                                                <DropdownComponent
                                                    width={"200px"}
                                                    required={true}
                                                    title={""}
                                                    // isDisabled={(approvalData?.departmentValue !== "" || undefined) ? false : true}
                                                    defaultValue={[{ label: approvalData && (!approvalData?.approverValue) ? "Select User" : approvalData?.approverValue, value: approvalData && (approvalData?.approverValue === (undefined || "")) ? "Select User" : approvalData?.approverValue }]}
                                                    options={approverDD}
                                                    getData={(value: any) => {
                                                        setApprovalData({
                                                            ...approvalData,
                                                            approverValue: value.value,
                                                            approverId: value.id,
                                                            approvercheckid: value.checkid,
                                                            approverGroupValue: "",
                                                            approverGroupId: "",
                                                        });
                                                        setIsFormDirty(true)
                                                        dispatch(resetFormModified(true));
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    }
                                    {approvalData?.approverType === "Group" &&
                                        <div style={{ marginRight: "45px", marginBottom: "30px", width: "200px" }} >
                                            <div className='checkbox-flex' style={{ marginRight: "45px", marginBottom: "10px" }}>
                                                <label className='label-flex'>
                                                    <input type="checkbox" checked={true}
                                                        onClick={(e: any) => {
                                                            // const value = e.target.checked ? 'Yes' : 'No';
                                                            // setApprovalData({ ...approvalData, isApproverChecked: value })

                                                        }} />
                                                    <span className='signatory-checkbox'>Approver</span><span className='required'>*</span></label>
                                            </div>
                                            <div key={uuidv4()} className={approvalDataErrors.approverGroupValue ? 'setErrorapproval' : ""}>
                                                <DropdownComponent
                                                    width={"200px"}
                                                    required={true}
                                                    title={""}
                                                    defaultValue={[{ label: approvalData && (!approvalData?.approverGroupValue) ? "Select Group" : approvalData?.approverGroupValue, value: approvalData && (!approvalData?.approverGroupValue) ? "Select Group" : approvalData?.approverGroupValue }]}
                                                    options={approverGroupDD}
                                                    getData={(value: any) => {
                                                        setApprovalData({
                                                            ...approvalData,
                                                            approverGroupValue: value.value,
                                                            approverGroupId: value.id,
                                                            approverValue: "",
                                                            approverId: "",
                                                        });
                                                        setIsFormDirty(true)
                                                        dispatch(resetFormModified(true));
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div>
                            <ButtonComponent
                                title={formDataEdit ? "Update" : 'Add'}
                                height={"50px"}
                                width={"150px"}
                                backgroundColor={"#0055D4"}
                                color={"white"}
                                margin={"40px 0px"}
                                className={"button-component-hover common-btn"}
                                handleClick={() => {
                                    handelAddButton()
                                }}
                            />
                        </div>
                        <div className={"Company-DetailsGrid"}>
                            <ApprovalProcessGrid
                                approvalData={approvalDataMain}
                                retriveData={retriveData}
                                showmodal={(val: any, index: any) => {
                                    setShowDeleteModel(true);
                                    setDeleteIndex(index);
                                    setCurrentDeletVal(val);
                                }}
                            />
                        </div>
                    </div>
                }
            </div>

            <div className='footer-company'>
                <div>
                    {/* <ButtonComponent
                        title={"Cancel"}
                        height={"50px"}
                        width={"150px"}
                        margin={"0px 8px"}
                        backgroundColor={"#888888"}
                        color={"white"}
                        className={"button-component-hover cancel"}
                        handleClick={isFormModified ? () => { setShowChangesModal(true) } : returnGird}
                    /> */}
                    <ButtonComponent
                        title={isEditMode ? "Update" : "Next"}
                        height={"50px"}
                        width={"150px"}
                        backgroundColor={"#0055D4"}
                        color={"white"}
                        margin={"0px 8px"}
                        handleClick={next}
                        key={approvalDataMain?.length}
                        disabled={isFormDirty || (approvalData?.approvalType == "Group" && (approvalDataMain?.length == 0 || (approvalDataMain && approvalDataMain[0]?.approvalType == "Single"))) || (approvalData?.approvalType == "Single" && !approvalData?.singleUserId && !approvalData?.groupUserId)}
                        className={isFormDirty || (approvalData?.approvalType == "Group" && (approvalDataMain?.length == 0 || (approvalDataMain && approvalDataMain[0]?.approvalType == "Single"))) || (approvalData?.approvalType == "Single" && !approvalData?.singleUserId && !approvalData?.groupUserId) ? "button-component-hover disabled" : "button-component-hover common-btn"}
                    />
                </div>
            </div>
            {
                showAlertBox &&
                <div className='alert-warp'>
                    <AlertBox type={showType} message={showMessage} />
                </div>

            }
            {
                showDeleteModel &&
                <div>
                    <DeleteModal
                        getDelete={() => { DirectorInfoDelete() }}

                        clearValue={(value) => {
                            setShowDeleteModel(value);
                            setCurrentDeletVal({})
                        }}
                        modelType={"grid-delete"}

                    />
                </div>
            }
        </div >
    );
}

export default ApprovalProcess;
