import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);




interface type {
    width: string
    height: string
    options: any;
    data: any;
}

export default function LineChart({ width, height, options, data }: type) {

    return (
        <div style={{ width: width, height: height, display: "flex", justifyContent: "center" }}>
            <Line options={options} data={data} />
        </div>
    );
}