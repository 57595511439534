import React, { useState } from 'react'
import { RxCross1 } from 'react-icons/rx'
import '../../../styles/gst/gst-view-challan-modal.scss'
import ButtonComponent from '../form-elements/button-component';
import Loading from './loading-screen';
interface Props {
    clearValue: (value: any) => void;
    gstView: (value: any) => void;
    setLoadingScreen: (value: any) => void;
    loadingScreen: any;

}

function DirectTaxApprovalModel({ clearValue, gstView, setLoadingScreen, loadingScreen }: Props) {

    const clearAlert = () => {
        const timer = setTimeout(() => {
            gstView("grid")
            setLoadingScreen(false)
            clearTimeout(timer);
        }, 2000);
    }

    function handelOk() {
        clearValue(false)
        setLoadingScreen(true)
        clearAlert()
    }

    return (
        <div>
            <div className={"department-add-modal-gst"}>
                <div className={"container-gst-approval"}>
                    <div className={"close-icon"}>
                        <span className={"cross-icon"}>
                            <RxCross1 onClick={() => {
                                clearValue(false)
                                // setLoadingScreen(true)
                                // clearAlert()
                            }
                            } style={{ fontSize: "40px" }} />
                        </span>
                    </div>
                    <div className='main-wrapper-gst-approval'>
                        <div className='gst-content'>
                            <div style={{ fontWeight: "bold" }}>
                            Direct Tax Payment request has been submitted and notification has been sent</div>
                            <div>You can view the summary and status of your request in the Direct Tax Payment page</div>
                        </div>
                        <div>
                            <ButtonComponent
                                title={"Ok"}
                                height={"50px"}
                                width={"120px"}
                                margin={"0px 8px"}
                                backgroundColor={"#0055D4"}
                                color={"white"}
                                className={"button-component-hover cancel"}
                                handleClick={handelOk}
                            />
                        </div>
                    </div>

                </div>

            </div>

        </div>
    )
}

export default DirectTaxApprovalModel;