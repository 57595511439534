import MainPageCard from '../../common-component/cards/main-page-card'
import NavigateHeader from '../../common-component/navigate-header'
import IconButton from '../../common-component/form-elements/icon-button'
import "../../../styles/onboarding/company/company-grid.scss"
import AgGrid, { AgGridRef } from '../../common-component/grids-and-tables/ag-grid'
import { vendorColumnData } from '../../../config/organisation/vendor/vendor-aggrid-column-config';
import React, { useEffect, useState, useRef } from 'react'
import FileUpload from '../../common-component/modals/file-upload-modal'
import GridFilter from '../../common-component/grids-and-tables/grid-filter'
import { v4 as uuidv4 } from 'uuid'
import { axiosPrivate } from '../../../middleware/axois-api'
import DeleteModal from '../../common-component/modals/delete-modal'
import AlertBox from '../../common-component/alert-box'
import { useDispatch, useSelector } from "react-redux";
import Loading from '../../common-component/modals/loading-screen'
import { Filter } from '../../../helpers/filtterFunction'
import CustomEllipsisRenderer from '../../../config/dashboard/customers-vs-transaction-value-config'

import { IoEye } from "react-icons/io5";
import VendorPendingInfo from '../../common-component/modals/vendor-pending-info'

interface Props {
  onboardingViewfun: (val: any) => void;
  getDoubleClickData: (val: any) => void;
  editedShowPageFun: (val: any) => void;
  getEditData: (nav: any, data: any) => void;
  addMode: () => void;

}
interface IFilterValue {
  [key: string]: {
    columnName: string;
    filterType: string;
    filterWord: string;
  };
}
export default function MasterVendorGrid({ onboardingViewfun, getEditData, addMode, getDoubleClickData, editedShowPageFun }: Props) {
  const [filter, setFilter] = useState(false);
  const [fileUpload, setFileUpload] = useState(false);
  const [filterRestore, setFilterRestore] = useState<IFilterValue>({})
  const [filterMethod, setFilterMethod] = useState("")
  const [curruntData, setCurruntData] = useState<any>([]);
  const [rowData, setRowData] = useState<any>([]);
  const [orginalRowData, setOrginalRowData] = useState<any>([]);
  const companyDownloadRef = useRef<AgGridRef>(null);
  const [showType, setShowType] = useState("warning") // error message showType
  const [showMessage, setShowMessage] = useState<string>("") // error message showMessage
  const [showAlertBox, setShowAlertBox] = useState(false) // error message ShowAlertBox
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
  const isFormModified = useSelector((state: any) => state.isFormModified);
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [pendingInfoModal, setPendingInfoModal] = useState<boolean>(false)
  const [pendingVendorInfoDatas, setPendingVendorInfoDatas] = useState<any>({
    percentage: "",
    pendingList: [
      {
        title: "Stakeholder Info",
        pendingField: ["Name", "Din", "Designation", "Name"]
      },
      {
        title: "Statutory Details",
        pendingField: ["Company Pan"]
      },
    ]

  })

  const templateLink = "bulkupload_templates/Vendor-bulkUpload.xlsx";

  const masterVendorColumnData = [

    {
      field: 'selection', headerName: '', minWidth: 60, maxWidth: 60, resizable: false, suppressMovable: true, filter: "agSetColumnFilter", suppressSizeToFit: false, headerCheckboxSelection: true, checkboxSelection: true, showDisabledCheckboxes: true, suppressRowTransform: true, cellStyle: { textAlign: 'left' },

    },
    {
      field: 'action', headerName: 'Action', minWidth: 100, maxWidth: 100, resizable: false, filter: false, suppressMovable: true, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer, cellRenderer: (e: any) => {
        // Custom rendering logic
        return (
          <IconButton
            iconName={"ShowEye"}
            height={"40px"}
            width={"40px"}
            fontSize={"30px"}
            margin={"10px 8px"}
            color={"white"}
            backgroundColor={"#0055D4"}
            hover={true}
            handleClick={(event: React.MouseEvent) => {
              console.log("JSON.parse(e.data.pendingList)", JSON.parse(e.data.pendingList))
              event?.stopPropagation()
              setPendingInfoModal(true);
              setPendingVendorInfoDatas({
                ...pendingVendorInfoDatas,
                percentage: e.data.overAllPercentage,
                pendingList: JSON.parse(e.data.pendingList),
                vendorDetails: e.data
              })
            }}
          />
          // <div style={{ height: "40px", width: "50px",background:"#0055D4",textAlign:"center" }}>
          //   <IoEye style={{color:"white",fontSize:"25px"}}/>
          //   {/* Custom content based on params */}
          // </div>
        );
      }
    },
    {
      field: 'submission', headerName: 'Submission %', minWidth: 240, maxWidth: 240, resizable: false, filter: false, suppressMovable: true, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer, cellRenderer: (e: any) => {
        // Custom rendering logic
        return (
          <div style={{ height: "25px", width: "120px", border: "1px solid #B3CAE1", position: "relative", marginTop: "10px" }}>
            <div style={{ position: "absolute", left: "50%", top: "50%", transform: "translate(-50%, -50%)", fontSize: "15px", color: e.data.overAllPercentage == "100" ? "white" : "black" }}><b>{e.data.overAllPercentage}</b>%</div>
            <div style={{ height: "100%", width: `${e.data.overAllPercentage}%`, background: e.data.overAllPercentage == "100" ? "green" : "#D2A100" }}></div>
            {/* Custom content based on params */}
          </div>
        );
      }
    },
    {
      field: 'MID Status', headerName: 'MID Status', minWidth: 240, maxWidth: 240, resizable: false, filter: false, suppressMovable: true, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer, cellRenderer: (e: any) => {
        // Custom rendering logic
        let fontColor = ""
        switch (e.data.midStatus) {
          case "NYS":
            fontColor = "#0055D4"
            break;
          case "Activated":
            fontColor = "green"
            break;
          case "Under Review":
            fontColor = "#FFC400"
            break;
          case "Not Submitted":
            fontColor = "#200160"
            break;
          case "Need Clarification":
            fontColor = "red"
            break;
          default:
            break;
        }
        return (
          <div style={{ color: fontColor }}>
            {e.data.basicDetails.midStatus}
            {/* Custom content based on params */}
          </div>
        );
      }
    },
    {
      field: 'Merchant ID', headerName: 'Merchant ID', minWidth: 240, maxWidth: 240, resizable: false, filter: false, suppressMovable: true, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer, cellRenderer: (e: any) => {
        // Custom rendering logic
        return (
          <div>
            {e.data.basicDetails.merchantId}
            {/* Custom content based on params */}
          </div>
        );
      }
    },
    {
      field: 'legal Business Name', headerName: 'Legal Business Name', minWidth: 240, maxWidth: 240, resizable: false, filter: false, suppressMovable: true, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer, cellRenderer: (e: any) => {
        // Custom rendering logic
        return (
          <div style={{ display: 'inline-block', maxWidth: '100%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>

            {e.data.vendorOverView.legalBusinessName}
            {/* Custom content based on params */}
          </div>
        );
      }
    },
    {
      field: 'Vendor Contacts', headerName: 'Vendor Contacts', minWidth: 360, maxWidth: 360, resizable: false, filter: false, suppressMovable: true, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer, cellRenderer: (e: any) => {
        return (
          <div style={{ height: "50px", lineHeight: "25px" }}>
            <div style={{ display: 'inline-block', maxWidth: '100%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}> Email ID : {e.data.basicDetails.emailId}</div>
            <div>Contact No : {e.data.basicDetails.contactNumber}</div>
            {/* Custom content based on params */}
          </div>
        );
      }
    },
    {
      field: 'Vendor Code', headerName: 'Vendor Code', minWidth: 200, maxWidth: 200, resizable: false, filter: false, suppressMovable: true, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer, cellRenderer: (e: any) => {
        // Custom rendering logic
        return (
          <div>
            {e.data.vendorOverView.vendorCode}
            {/* Custom content based on params */}
          </div>
        );
      }
    },
    {
      field: 'Company Internal Approval', headerName: 'Company Internal Approval', minWidth: 270, maxWidth: 270, resizable: false, filter: false, suppressMovable: true, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer, cellRenderer: (e: any) => {
        // Custom rendering logic
        let color = '';
        switch (e.data.basicDetails.companyInternalApproval) {
          case 'NYS':
            color = '#0055D4'
            break;
          case 'Declined':
            color = 'red'
            break;
          case 'Approved':
            color = 'green'
            break;
          case 'Pending Approval':
            color = '#D2A100'
            break;
          default:
            break;
        }
        return (
          <div style={{ color: color }}>
            {e?.data?.basicDetails?.companyInternalApproval}
            {/* Custom content based on params */}
          </div>
        );
      }
    },

    // {
    //   field: 'Bank Approval', headerName: 'Bank Approval', minWidth: 240, maxWidth: 240, resizable: false, filter: false, suppressMovable: true, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer, cellRenderer: (e: any) => {
    //     // Custom rendering logic
    //     return (
    //       <div style={{ color: e.data.companyInternalApproval == "NYS" ? "#0055D4" : "green" }}>
    //         {e.data.bankApproval}
    //         {/* Custom content based on params */}
    //       </div>
    //     );
    //   }
    // },
    {
      field: 'Business Category', headerName: 'Business Category', minWidth: 240, maxWidth: 240, resizable: false, filter: false, suppressMovable: true, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer, cellRenderer: (e: any) => {
        // Custom rendering logic
        return (
          <div>
            {e.data.vendorOverView.businessCategory.label}
            {/* Custom content based on params */}
          </div>
        );
      }
    },
    {
      field: 'Is Active', headerName: 'Is Active', minWidth: 240, maxWidth: 240, resizable: false, filter: false, suppressMovable: true, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer, cellRenderer: (e: any) => {
        // Custom rendering logic
        return (
          <div>
            {e.data.vendorOverView.isActive}
            {/* Custom content based on params */}
          </div>
        );
      }
    },
    {
      field: 'Approved by (Internal)', headerName: 'Approved by (Internal)', minWidth: 240, maxWidth: 240, resizable: false, filter: false, suppressMovable: true, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer, cellRenderer: (e: any) => {
        // Custom rendering logic
        return (
          <div>
            {e?.data?.basicDetails?.approvedByInternal}
            {/* Custom content based on params */}
          </div>
        );
      }
    },
  ]
  const dummyRowData = [
    {
      "submission": "90",
      "legalBusinessName": "ABT Industries",
      "vendorCode": "VESS24001",
      "vendorContacts": {
        "emailId": "",
        "contactNumber": "",
      },
      "companyInternalApproval": "NYS",
      "merchantId": "ADAD324",
      "midStatus": "NYS",
      "bankApproval": "NYS",
      "businessCategory": "Food",
      "isActive": "Manufacturing",
      "approvedByInternal": "Yes",
    },
    {
      "submission": "100",
      "legalBusinessName": "ABT Industries",
      "vendorCode": "VESS24001",
      "vendorContacts": {
        "emailId": "",
        "contactNumber": "",
      },
      "companyInternalApproval": "Approved",
      "merchantId": "ADAD324",
      "midStatus": "Activated",
      "bankApproval": "Approved",
      "businessCategory": "Food",
      "isActive": "Manufacturing",
      "approvedByInternal": "Yes",
    },
    {
      "submission": "80",
      "legalBusinessName": "ABT Industries",
      "vendorCode": "VESS24001",
      "vendorContacts": {
        "emailId": "",
        "contactNumber": "",
      },
      "companyInternalApproval": "Approved",
      "merchantId": "ADAD324",
      "midStatus": "Under Review",
      "bankApproval": "Approved",
      "businessCategory": "Food",
      "isActive": "Manufacturing",
      "approvedByInternal": "Yes",
    },
    {
      "submission": "100",
      "legalBusinessName": "ABT Industries",
      "vendorCode": "VESS24001",
      "vendorContacts": {
        "emailId": "",
        "contactNumber": "",
      },
      "companyInternalApproval": "Approved",
      "merchantId": "ADAD324",
      "midStatus": "Not Submitted",
      "bankApproval": "Approved",
      "businessCategory": "Food",
      "isActive": "Manufacturing",
      "approvedByInternal": "Yes",
    },
    {
      "submission": "90",
      "legalBusinessName": "ABT Industries",
      "vendorCode": "VESS24001",
      "vendorContacts": {
        "emailId": "",
        "contactNumber": "",
      },
      "companyInternalApproval": "Approved",
      "merchantId": "ADAD324",
      "midStatus": "Need Clarification",
      "bankApproval": "Approved",
      "businessCategory": "Food",
      "isActive": "Manufacturing",
      "approvedByInternal": "Yes",
    },

  ]

  useEffect(() => {
    // fetchData()
    // setRowData([

    // ])
  }, []);

  async function getUserData(vendorOverviewId: string) {
    await axiosPrivate.get(`/companyadmin/organisation/get-detailsby-vendorOverviewid/${vendorOverviewId}`)
      .then(response => {
        if (getEditData) {
          getEditData("form", response.data.vendorDetails)
        }
      })
      .catch(error => {
        console.error(error);
      });
  }
  const UploadMessage = (message: any, type: any) => {
    if (type === "success") {
      setShowMessage(message)
      setShowAlertBox(true);
      setShowType("success");
      clearAleart()
    } else if (type === "warning") {
      setShowMessage(message)
      setShowAlertBox(true);
      setShowType("warning");
      clearAleart()
    }

  }
  const clearValue = (val: any) => {
    setFileUpload(val);
  }
  // clearAleart
  const clearAleart = () => {
    const timer = setTimeout(() => {
      setShowAlertBox(false);
      setShowMessage("");
      clearTimeout(timer);
    }, 5000);
  }
  const getUploadedUserData = (val: any) => {
  }

  const getCurruntData = (val: any) => {
    setCurruntData(val);
  }

  // Function for Filter values
  const handleFilter = (value: any, filterType: string) => {
    Filter(value, filterType, orginalRowData, setRowData)
  }

  const fetchData = async () => {
    try {
      const response = await axiosPrivate.get('companyadmin/organisation/get-vendor');
      setRowData(response.data.vendorDetails);
      setOrginalRowData(response.data.vendorDetails)
    } catch (error) {
      console.log('Error:', error);
    }
  };
  const handleDownloadExcel = () => {
    companyDownloadRef.current!.onBtExport();
  }

  const getDelete = async () => {

    let deletedIdsList: any = []
    for (const data of curruntData) {
      deletedIdsList.push(data.primaryKeyOfCurrentDatas)
    }
    if (curruntData.length > 0) {
      setIsLoading(true)
      axiosPrivate.post(`razorpay/vendorDatas/delete`, {
        deletedIds: deletedIdsList
      })
        .then(response => {
          if (response.status === 200) {
            setIsLoading(false)
            setShowAlertBox(true)
            setShowType("success")
            setShowMessage(response.data.message)
            clearAleart()
            setShowDeleteModal(false)
            getRazorpayVendorDetails()
            // setCurruntData([]);
          }
        })
        .catch(error => {
          setIsLoading(false)
          setShowAlertBox(true);
          setShowType("warning")
          setShowMessage(error.response.data.error)
          setShowDeleteModal(false)
          clearAleart()
        });
    }
    // else {
    //   let idList: any = [];
    //   curruntData.map((e: any, i: number) => {
    //     idList.push({
    //       id: e.vendorOverviewId
    //     });
    //   })
    //   try {
    //     const res = await axiosPrivate.post("/companyadmin/organisation/delete-multiple-vendor", {

    //       data: idList

    //     })
    //     if (res.status === 200) {
    //       setShowAlertBox(true)
    //       setShowType("success")
    //       setShowMessage(res.data.message);
    //       clearAleart();
    //       setShowDeleteModal(false)
    //       fetchData()
    //       setCurruntData([]);
    //     }
    //   } catch (error: any) {
    //     setShowAlertBox(true)
    //     setShowType("danger")
    //     setShowMessage(error.response.data.error)
    //     clearAleart()
    //     setShowDeleteModal(false)
    //   }
    // }
  };

  useEffect(() => {
    getRazorpayVendorDetails()
  }, [])

  async function getRazorpayVendorDetails() {
    try {
      setIsLoading(true)
      const res = await axiosPrivate.get('razorpay/vendorDatas')
      if (res.status === 200) {
        setIsLoading(false)
        setRowData(res.data.responseVendorDatas)
        setOrginalRowData(res.data.responseVendorDatas)
      } else {
        setIsLoading(false)
        console.log("Error in fetching vendor details", res.data)
      }

    } catch (error) {
      setIsLoading(false)
      console.log("Error in fetching vendor details", error)
    }
  }

  return (
    <MainPageCard >
      <div className='header-grid'>
        <div className='left-header'>
          <NavigateHeader firstValue={"Organisation"}
            secondValue={"Vendor Details"}
          />
        </div>
        <div className='icon-buttons'>
          <IconButton
            iconName={"Filter"}
            height={"40px"}
            width={"40px"}
            fontSize={"30px"}
            margin={"0px 8px"}
            color={"white"}
            backgroundColor={"#0055D4"}
            hover={true}
            handleClick={(event: React.MouseEvent) => {
              event?.stopPropagation()
              setFilter(!filter);
            }}
          />
          <IconButton
            iconName={"Delete"}
            height={"40px"}
            width={"40px"}
            fontSize={"25px"}
            margin={"0px 8px"}
            color={"white"}
            backgroundColor={"#D60000"}
            hover={curruntData.length > 0}
            opacity={curruntData.length > 0 ? "1" : "0.5"}
            cursor={curruntData.length > 0 ? "pointer" : "not-allowed"}
            handleClick={() => {
              if (curruntData.length > 0) {
                setShowDeleteModal(!showDeleteModal)
              }
            }}
          />

          <IconButton
            iconName={"Add"}
            height={"40px"}
            width={"75px"}
            fontSize={"25px"}
            margin={"0px 8px"}
            color={"white"}
            backgroundColor={"#0055D4"}
            hover={true}
            handleClick={() => {
              onboardingViewfun("form");
              addMode();
            }}
          />
          {/* <IconButton
            iconName={"Upload"}
            height={"40px"}
            width={"40px"}
            fontSize={"25px"}
            margin={"0px 0px 0px 8px"}
            color={"white"}
            backgroundColor={"#0055D4"}
            hover={true}
            // opacity={"0.5"}
            // cursor={"not-allowed"}
            handleClick={() => {
              setFileUpload(true)
            }}
          /> */}
          <IconButton
            iconName={"Download"}
            height={"40px"}
            width={"40px"}
            fontSize={"25px"}
            margin={"0px 8px"}
            color={"white"}
            backgroundColor={"#0055D4"}
            hover={true}
            handleClick={() => { handleDownloadExcel() }}
          />
        </div>
      </div>
      <div>
        <AgGrid
          rowHeight={80}
          displaylength={13}
          tabelRowData={rowData}
          tableColumnData={masterVendorColumnData}
          ref={companyDownloadRef}
          fileName={"CompanyData"}
          getCurruntData={getCurruntData}
          doubleClickUpdate={(data) => {
            getEditData && getEditData("form", "")
          }}
          getEdit={(data) => {
            getDoubleClickData(data)
            // getUserData(data?.vendorOverviewId)
          }

          }
        />
      </div>
      {
        fileUpload &&
        <FileUpload
          clearValue={clearValue}
          headerData={masterVendorColumnData}
          templateName={"User"}
          template={templateLink}
          getUploadedUserData={getUploadedUserData}
          apiUrl={"bulk-upload/vendor"}
          gridUpdate={() => { fetchData() }}
          setshowUploadMessage={UploadMessage}
          loadingScreenFunction={(val: boolean) => { setIsLoading(val) }}
          excelFileName={"Vendor-BulkUpload-Status"}
        />
      }
      {
        filter &&
        <div className='filter' key={uuidv4()}>
          <GridFilter
            filterRestore={filterRestore}
            filterMethod={filterMethod}
            tabelRowData={rowData}
            data={masterVendorColumnData.slice(1)}
            handleClick={(Value: any, filterType: string) => {
              if (filterType !== "") {
                setFilterRestore(Value)
                setFilter(!filter)
                setFilterMethod(filterType)
                handleFilter(Value, filterType)
                setCurruntData([]);

              } else {
                setFilterMethod("")
                setFilterRestore({})
                setFilter(!filter)
                setRowData(orginalRowData)
                setCurruntData([]);
              }
            }}
          />
        </div>
      }
      {
        showDeleteModal &&
        <DeleteModal
          modelType={"grid-delete"}
          getDelete={getDelete}
          clearValue={(value) => {
            setShowDeleteModal(value)
          }}
        />
      }
      {
        isLoading &&
        <Loading />
      }
      {
        pendingInfoModal &&
        <VendorPendingInfo
          closeModal={() => { setPendingInfoModal(false) }}
          pendingInfoEditClick={(val: any) => {
            getEditData('form', pendingVendorInfoDatas.vendorDetails);
            editedShowPageFun(val)
          }}
          overAllPercentage={pendingVendorInfoDatas.percentage}
          pendingList={pendingVendorInfoDatas.pendingList}

        />
      }

      {
        showAlertBox &&
        <div className='alert-warp'>
          <AlertBox type={showType} message={showMessage} />
        </div>
      }
    </MainPageCard>

  )
}





