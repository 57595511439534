import React, { useState, useEffect } from 'react';
import '../../styles/login-error/login-error.scss'
import axios from 'axios';
import LoginErrorImage from '../../assets/images/login-error.jpg';
import ButtonComponent from '../common-component/form-elements/button-component';
import useAxiosPrivate from '../../services/hooks/useaxios-private';
import { encryptToken } from '../../services/utils/token-utils';





export default function LoginError() {
    const [userGmail, setUserGmail] = useState<string | null>('')
    const [decryptedEmail, setDecryptedEmail] = useState<string>('')
    const [message, setMessage] = useState<string>('')
    const [showResendMail, setShowResendMail] = useState<boolean>(false)
    const [showCancel, setShowCancel] = useState<boolean>(false)
    const [isError, setIsError] = useState<boolean>(true)

    const axiosPrivate = useAxiosPrivate();


    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const userName = params.get('token');
        setUserGmail(userName)
    }, []);

    useEffect(() => {
        if (userGmail !== "") {
            mailLink()
        };
    }, [userGmail])

    async function mailLink() {
        try {
            const res = await axiosPrivate.post('email/token', { "token": userGmail })

            setDecryptedEmail(res.data.email)
            showError(res.data.email)
        } catch (error) {
        }
    }
    async function expireToken() {
        try {
            const res = await axiosPrivate.post('resend/email-link', { "email": encryptToken(decryptedEmail) })
            if (res.status === 200) {
                setIsError(false)
                setMessage(res.data.message);
                setShowResendMail(false);
                setShowCancel(false);
            }
        } catch (error) {
        }
    }
    async function showError(email: any) {
        try {
            const response = await axiosPrivate.get(`user/error-code/${userGmail}`)
            if (response?.data.message?.errorCode) {
                setIsError(true)
                switch (response?.data.message?.errorCode) {
                    case 1:
                        setMessage(response?.data.message.errorMsg);
                        setShowResendMail(true);
                        setShowCancel(true);
                        break;
                    case 2:
                        setMessage(response?.data.message.errorMsg);
                        setShowResendMail(false);
                        setShowCancel(false);
                        break;
                    case 3:
                        setMessage(response?.data.message.errorMsg);
                        setShowResendMail(true);
                        setShowCancel(true);
                        break;
                    case 4:
                        setMessage(response?.data.message.errorMsg);
                        setShowResendMail(true);
                        setShowCancel(true);
                        break;
                    case 5:
                        setMessage(response?.data.message.errorMsg);
                        setShowResendMail(false);
                        setShowCancel(false)
                        break;
                    case 11:
                        setMessage(response?.data.message.errorMsg);
                        break;
                    case 12:
                        setMessage(response?.data.message.errorMsg);
                        setShowResendMail(false);
                        setShowCancel(false)
                        break;
                    case 13:
                        setMessage(response?.data.message.errorMsg);
                        break;
                    case 14:
                        setMessage(response?.data.message.errorMsg);
                        break;
                    case 15:
                        setMessage(response?.data.message.errorMsg);
                        break;
                    default:
                        break;
                }
            }
        } catch (error: any) {

        }
    }
    async function handleCancel() {
        try {
            const res = await axiosPrivate.patch('update/error-code', { "email": encryptToken(decryptedEmail) })
            if (res.status === 200) {
                showError(res.data.email)
            }
        } catch (error) {
        }
    }
    return (
        <div className='MailExpire-container'>
            <div className='login-logo'>
                <div className='fingertip-logo'></div>
            </div>
            <div>
                <img src={LoginErrorImage} alt="" className='mail-expire-image' />
            </div>
            <div className={isError ? 'error-content' : 'success-content'} dangerouslySetInnerHTML={{ __html: message }} />
            {showResendMail &&
                <div className='resend-email' onClick={() => expireToken()}>
                    Resend New Registration Link
                </div>
            }
            {showCancel &&
                <div className='cancel-btn'>
                    <ButtonComponent
                        title={"Cancel"}
                        height={"50px"}
                        width={"130px"}
                        // boxShadow={"0px 10px 20px #0066FF80"}
                        backgroundColor={"#888888"}
                        color={"#ffffff"}
                        handleClick={() => {
                            handleCancel();
                        }}
                        className={"button-component-hover cancel"}
                    />
                </div>
            }
        </div >
    )
}
