import React, { useEffect, useState } from 'react'
import { BiPlus, BiMinus } from "react-icons/bi";
import "../../../styles/view-table.scss";
import IconButton from '../../../components/common-component/form-elements/icon-button';
import DeleteModal from '../../../components/common-component/modals/delete-modal';
import "../../../styles/onboarding/company/grid-icon.scss"
import { axiosPrivate } from '../../../middleware/axois-api';
import GstApprovalChallanModal from '../../../components/common-component/modals/gst-approval-challan-modal';
// import { DateConverter } from '../../helpers/capitalizetext';
import Loading from '../../../components/common-component/modals/loading-screen';
import AlertBox from '../../../components/common-component/alert-box';
import DocumentModal from '../../../components/common-component/modals/document-modal';




interface Props {

    selectedGridChallan: any;
    setGstEyeShowModal: (val: any) => void;
    selectedGridChallanFun: (val: any) => void;
    gstEyeShowModal: any;
    makePaymentDatas: any;
}

function ExciseChallanGrid({ selectedGridChallan, setGstEyeShowModal, gstEyeShowModal, makePaymentDatas, selectedGridChallanFun }: Props) {

    const [showDeleteModel, setShowDeleteModel] = useState<boolean>(false)
    const [approvalChallanDetails, setApprovalChallanDetails] = useState<any>([]);
    const [approvalStatus, setApprovalStatus] = useState<any>([]);
    const [deleteId, setDeleteId] = useState<any>('');
    const [currectFile, setcurrectFile] = useState<any>('');
    const [showDocumentModal, setShowDocumentModal] = useState<boolean>(false);
    const [loadingScreen, setLoadingScreen] = useState<boolean>(false);
    const [showType, setShowType] = useState("warning") // error message showType
    const [showMessage, setShowMessage] = useState<string>("") // error message showMessage
    const [showAlertBox, setShowAlertBox] = useState(false) // error message ShowAlertBox
    const clearAlert = (status: any) => {
        const timer = setTimeout(() => {
            setShowAlertBox(false);
            setShowMessage("");
            clearTimeout(timer);
        }, 3000);
    }

    async function gstChallanDelete() {
        setLoadingScreen(true)
        await axiosPrivate.delete(`/gst-payable/delete-challans/${deleteId}`)
            .then(async (response: any) => {
                setShowDeleteModel(false)
                setLoadingScreen(false)
                setShowAlertBox(true)
                setShowType('success')
                setShowMessage("The record have been deleted.")

                //deleted challan details and update
                await axiosPrivate.post(`gst-payable/get-makepayment-challan-datas`, makePaymentDatas)
                    .then((response: any) => {
                        selectedGridChallanFun(response.data.MakePaymentDetails)
                        clearAlert('')
                    })
                    .catch(error => {
                        console.error(error);
                    });
            })
            .catch(error => {
                console.error(error);
                setShowDeleteModel(false)
                setLoadingScreen(false)
                setShowAlertBox(true)
                setShowType('error')
                setShowMessage(error.data.message)
            });

    }
    console.log(selectedGridChallan)
    return (
        <>
            <div className='view-table-component ' style={{ marginTop: "20px", height: "205px", overflowY: "auto" }}>
                <table>
                    <thead>
                        <th className='align-differ' style={{ width: "25%" }}>Central Excise Registration No.</th>
                        <th className='' style={{ width: "20%", paddingLeft: "10px" }}>Document Type</th>
                        <th className='' style={{ width: "20%", paddingRight: "40px" }}>Duty Type</th>
                        <th className='' style={{ width: "20%", paddingLeft: "10px" }}>Account Name</th>
                        <th className='' style={{ width: "10%", textAlign: "right", paddingRight: "45px" }}>Amount(₹)</th>
                        <th className='' style={{ textAlign: "center", paddingLeft: "2%", width: "15%" }}>Action</th>
                    </thead>
                    <tbody >
                        {selectedGridChallan?.length === 0 ?
                            <tr className='style-grid-differ'>
                                <td></td>
                                <td>No records to show</td>
                                <td></td>
                                <td></td>
                            </tr> :
                            selectedGridChallan?.map((data: any, index: number) => (
                                <tr className='main-body-header' style={{ marginTop: "0px" }} key={index}>
                                    <td className='align-differ' style={{ width: "6%" }}>{data?.centralExciseRegistrationNo}</td>
                                    <td className='align-left' style={{ width: "20%" }}>{data?.documentType}</td>
                                    <td className='align-left' style={{ width: "20%" }}>{data?.dutyType}</td>
                                    <td className='align-left' style={{ width: "20%" }}>{data?.accountName}</td>
                                    <td className='align-left' style={{ textAlign: "right", width: "20%", paddingRight: "45px" }}>{data?.amount}</td>
                                    <td className='last-icon flexcls'>
                                        <IconButton
                                            iconName={"ShowEye"}
                                            height={"40px"}
                                            width={"40px"}
                                            fontSize={"30px"}
                                            margin={"0px 8px"}
                                            color={"white"}
                                            backgroundColor={""}
                                            hover={false}
                                            handleClick={async () => {
                                                setShowDocumentModal(true);
                                                setcurrectFile(data.fileLocation)
                                            }}
                                        />
                                        {/* selectedGridChallan?.length === 1 ? : {} */}
                                        <div style={{ opacity: "0.5", cursor: "not-allowed" }}>
                                            <IconButton
                                                iconName={"Delete"}
                                                height={"40px"}
                                                width={"40px"}
                                                fontSize={"25px"}
                                                margin={"0px 8px"}
                                                color={"white"}
                                                cursor={"not-allowed"}
                                                // selectedGridChallan?.length === 1 ?  : "pointer"
                                                backgroundColor={"#D60000"}
                                                hover={false}
                                                // selectedGridChallan?.length === 1 ?  : true
                                                disabled={true}
                                                handleClick={() => {
                                                    // if (selectedGridChallan?.length === 1) {
                                                    // } else {
                                                    //     setShowDeleteModel(true)
                                                    //     setDeleteId(data?.payableGstDetailsId)
                                                    // }
                                                }}
                                            />
                                        </div>


                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>
            </div>
            {
                showDeleteModel &&
                <div >
                    <DeleteModal
                        getDelete={() => { gstChallanDelete() }}
                        clearValue={(value) => { setShowDeleteModel(value) }}
                        modelType={"grid-delete"}
                    />
                </div>
            }

            {showDocumentModal &&
                <DocumentModal                     //Eye button Component
                    closeDocument={(val: boolean) => { setShowDocumentModal(val); }}
                    document={currectFile}
                    showDocumentModal={showDocumentModal}
                // exciseId={editData.payableExciseId}
                />}
            {
                loadingScreen &&
                <Loading />
            }
            {
                showAlertBox &&
                <div className='alert-warp'>
                    <AlertBox type={showType} message={showMessage} />
                </div>

            }
        </>
    )
}

export default ExciseChallanGrid;
