
import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react'
import DropdownComponent from '../../../common-component/form-elements/dropdown-component';
import { v4 as uuidv4 } from 'uuid'
import { resetFormModified } from '../../../../redux/action';
import { useDispatch, useSelector } from 'react-redux';
import ButtonComponent from '../../../common-component/form-elements/button-component';
import IconButton from '../../../common-component/form-elements/icon-button';
import InputComponent from '../../../common-component/form-elements/input-component';
import { axiosPrivate } from '../../../../middleware/axois-api';
import moment from 'moment';
import RadioOrCheckbox from '../../../common-component/form-elements/radio-or-checkbox';
import AlertBox from '../../../common-component/alert-box';
import Changesmodal from '../../../common-component/modals/changes-modal';





interface DropdownItem {
  label: string;
  value: string;
  id?: string;
  billingCycleId?: number;
  companyConstitutionId?: number;
  businessCategoryId?: number;
}

export interface ChildRef {
  next: (val: any) => void;
}

interface props {
  fetchDatas: (value: any, chooseType: any) => void;
  companyOverviewPan: string;
  setCompanyOverviewPan: (value: any) => void;
  onboardingViewfun: (val: any) => void;
  mainApiData: any;
  setMainApiData: (val: any) => void;
  setIsFormDirty: (val: any) => void;
  setChooseType: (val: any) => void;
  sendDataApi: () => void;
  iconStatus: any
  setShowPage: (val: any) => void;
  setIconStatus: (val: any) => void;
  chooseType: any;
  showPage: any;
  isEditMode: boolean;
  editData: any;


}


const CustomerOverview = forwardRef<ChildRef, props>(({ mainApiData, setIsFormDirty, showPage, setShowPage, setIconStatus, sendDataApi, editData, iconStatus, isEditMode, onboardingViewfun, setMainApiData, fetchDatas, companyOverviewPan, setCompanyOverviewPan, setChooseType, chooseType }, ref) => {

  const [errorChoosetype, setErrorChoosetype] = useState<boolean>(false);
  const [showType, setShowType] = useState("warning") // error message showType
  const [showMessage, setShowMessage] = useState<string>("") // error message showMessage
  const [showAlertBox, setShowAlertBox] = useState(false) // error message ShowAlertBox
  const [companyOverviewErrors, setCompanyOverviewErrors] = useState<Record<string, boolean>>({})
  const [constitutionData, setConstitutionData] = useState<DropdownItem[]>([])
  const [businessData, setBusinessData] = useState<DropdownItem[]>([])
  const [billingCycle, setBillingCycle] = useState<DropdownItem[]>([])
  const [subCategoryData, setSubCategoryData] = useState<DropdownItem[]>([])
  const isFormModified = useSelector((state: any) => state.isFormModified);
  const [showChangesModal, setShowChangesModal] = useState<boolean>(false);








  useEffect(() => {
    getConstitutionData()
    getBusinessData()
    getBillingCycle()
    getSubCategoryData()
  }, [])

  useEffect(() => {
    if (isEditMode) return;
    setMainApiData({
      ...mainApiData,
      ...mainApiData?.customerOverView,
      isActive: "Yes",
    });
  }, [])


  const dispatch = useDispatch();

  const userValidationRules: any = {
    customerOverView: {
      customerName: {
        regex: "",
        field: "mandatory",
        shouldNotBe: ""
      },
      customerCode: {
        regex: /^[A-Z0-9-]+$/,
        field: "mandatory",
        shouldNotBe: ""
      },
      companyConstitution: {
        regex: "",
        field: "mandatory",
        shouldNotBe: ""
      },
      businessCategory: {
        regex: "",
        field: "mandatory",
        shouldNotBe: ""
      },
      noOfdays: {
        regex: mainApiData?.typesOfBillingCycle === "Days" ? /^[0-9]+$/ : "",
        field: mainApiData?.typesOfBillingCycle === "Days" ? "mandatory" : "",
        shouldNotBe: ""
      },

    }
  }

  const updatedIsValueValidAll: Record<string, boolean> = {};
  const validateForm = (): boolean => {
    const updatedFieldValidity: Record<string, boolean> = {};
    const isMandatoryfalse = true;
    const isRegexfalse = true;
    Object.keys(userValidationRules.customerOverView).forEach((field) => {
      const rule = userValidationRules.customerOverView[field];
      const value = mainApiData[field];
      const isValueValid = (rule.field === "mandatory") ? (value ? (value !== rule.shouldNotBe) : false) : true;
      const isRegexValid = rule.regex !== "" ? new RegExp(rule.regex).test(value) : true;
      updatedFieldValidity[field] = !isValueValid || !isRegexValid;
      updatedIsValueValidAll[field] = !isValueValid;
    });
    setCompanyOverviewErrors(updatedFieldValidity);
    const isFormValid = Object.keys(updatedFieldValidity).every((keys) => {
      return !updatedFieldValidity[keys]
    });
    return isFormValid;
  };



  function handelFectch() {
    let regexPattern;
    if (chooseType === "CIN") {
      regexPattern = /\b[A-Z]\d{5}[A-Z]{2}\d{4}[A-Z]{3}\d{6}\b/;
    } else if (chooseType === "PAN") {
      regexPattern = /[A-Z]{5}\d{4}[A-Z]{1}/;
    } else if (chooseType === "LLPIN") {
      regexPattern = /\b[A-Z]{3}-\d{4}\b/;
    } else {
      return;
    }
    const isMatch = regexPattern.test(companyOverviewPan);
    if (isMatch) {
      fetchDatas(companyOverviewPan, chooseType);
      setErrorChoosetype(false)
    } else {
      setShowAlertBox(true);
      setShowType("danger");
      (companyOverviewPan == "" || companyOverviewPan == undefined) ?
        setShowMessage(chooseType + " should not be empty.") :
        setShowMessage(chooseType + " is not in required format.");
      clearAlert('');
      setErrorChoosetype(true)
    }
  }


  async function getBusinessData() {
    try {
      const response = await axiosPrivate.get("/bank-admin/company/dropdown/buisnessCategory")
      const ddRegionVal = response.data.buisnessCategory.map((e: any, i: number) => {
        const dropdownItem: DropdownItem = {
          label: e.buisnessCategory,
          value: e.buisnessCategory,
          id: e.id,
          businessCategoryId: e.id,
        };
        return dropdownItem;
      })
      const sortedData = [...ddRegionVal].sort((a, b) => a.label.localeCompare(b.label));
      setBusinessData(sortedData)
    }
    catch (error) {
    }
  }

  async function getBillingCycle() {
    try {
      const response = await axiosPrivate.get("/companyadmin/organisation/billing-cycle")
      const ddRegionVal = response.data.billingCycle.map((e: any, i: number) => {
        const dropdownItem: DropdownItem = {
          label: e.billingCycleName,
          value: e.billingCycleName,
          billingCycleId: e.billingCycleId
        };
        return dropdownItem;
      })
      const sortedData = [...ddRegionVal].sort((a, b) => a.label.localeCompare(b.label));

      setBillingCycle([{ label: "Select", value: "", id: "" }, ...ddRegionVal])
    }
    catch (error) {
    }
  }


  async function getConstitutionData() {
    try {
      const response = await axiosPrivate.get("/bank-admin/company/dropdown/constituion")
      const groupdropdownValues = response.data.companyConstitutions.map((item: any) => ({
        label: item.constitution,
        value: item.constitution,
        companyConstitutionId: item.id,
      }));
      const sortedData = [...groupdropdownValues].sort((a, b) => a.label.localeCompare(b.label));
      setConstitutionData(sortedData);
    }
    catch (error) {
    }
  }


  async function getSubCategoryData() {
    try {
      const response = await axiosPrivate.get("/bank-admin/company/dropdown/sub-category")
      const ddRegionVal = response.data.subCategory.map((e: any, i: number) => {
        const dropdownItem: DropdownItem = {
          label: e.subCategory,
          value: e.subCategory,
          id: e.id,
        };
        return dropdownItem;
      })
      const sortedData = [...ddRegionVal].sort((a, b) => a.label.localeCompare(b.label));
      setSubCategoryData(sortedData)
    }
    catch (error) {
    }
  }


  const clearAlert = (status: any) => {
    const timer = setTimeout(() => {
      setShowAlertBox(false);
      setShowMessage("");
      clearTimeout(timer);
    }, 5000);
  }

  async function next(next: any = "") {
    const isFormValid = validateForm();
    if (isFormValid) {
      const Duplicate = await duplicateCheck();
      if (Duplicate) {
        if (isEditMode) {
          sendDataApi();
          setIconStatus({
            ...iconStatus,
            CustomerIcon: "completed",
          })
          dispatch(resetFormModified(false));
        } else {
          setShowPage(next != "" ? next : "BankDetails")
          if (next != "") {
            switch (next) {
              case "CompanyOverview":
                setIconStatus({
                  ...iconStatus,
                  CustomerIcon: "selected",
                })
                break;
              case "BankDetails":
                setIconStatus({
                  ...iconStatus,
                  CustomerIcon: "completed",
                  adminIcon: "selected",
                })
                break;
              case "Address":
                setIconStatus({
                  ...iconStatus,
                  adminIcon: "completed",
                  addressIcon: "selected",
                })
                break;
              case "pointofContact":
                setIconStatus({
                  ...iconStatus,
                  addressIcon: "completed",
                  pointofContact: "completed",

                })
                break;

              default:
                break;
            }
          } else {
            setIconStatus({
              ...iconStatus,
              CustomerIcon: "completed",
              adminIcon: "selected"
            })
          }
        }
      } else {

      }

      setIsFormDirty(false);
    } else {
      setShowAlertBox(true);
      setShowType("danger");
      setShowMessage(Object.values(updatedIsValueValidAll).every((values) => !values) ?
        "Some of the field(s) are not in required format." : "Mandatory field(s) should not be left blank.")
      clearAlert('');
    }
  }


  async function duplicateCheck() {
    const finalData = {
      "customerOverView": {
        "customerName": mainApiData?.customerName,
        "customerCode": mainApiData?.customerCode,
        "customerOverviewId": editData?.customerOverviewId ? editData?.customerOverviewId : ""
      }
    }
    let statusMessage: boolean = false;
    try {
      const res = await axiosPrivate.post("companyadmin/organisation/customer/duplicate-checking", finalData)
      if (res.status === 200) {
        statusMessage = true;

      }
    }
    catch (error: any) {
      setShowAlertBox(true)
      setShowType("danger")
      setShowMessage(error.response.data.error)
      clearAlert("")
      statusMessage = false;

    }
    return statusMessage;
  }
  function returnGird() {
    onboardingViewfun("grid")
    dispatch(resetFormModified(false));
  }





  let type: any = [{ label: "PAN", value: "PAN" }, { label: "CIN", value: "CIN" }, { label: "LLPIN", value: "LLPIN" }]
  return (
    <>
      <div className='form-wrapper'>
        <div className='form-area' >
          <div className='form-main-end'>
            <div className='dropdown-width' key={uuidv4()} style={isEditMode ? { pointerEvents: "none", opacity: 0.5, marginRight: "45px", marginBottom: "30px" } : { marginRight: "45px", marginBottom: "30px" }}>
              <DropdownComponent
                width={"300px"}
                required={true}
                title={"Choose PAN/LLPIN/CIN"}
                defaultValue={[{ label: mainApiData && (mainApiData?.chooseType === (undefined)) ? "PAN" : mainApiData?.chooseType, value: mainApiData && (mainApiData?.chooseType === (undefined)) ? "PAN" : mainApiData?.chooseType }]}
                options={type}
                isDisabled={isEditMode}
                getData={(value: any) => {
                  setIsFormDirty(true)
                  dispatch(resetFormModified(true));
                  setMainApiData({
                    ...mainApiData,
                    chooseType: value.value,
                    chooseTypeValue: "",
                  })
                  setCompanyOverviewPan("")
                  setChooseType(value.value)
                  setIconStatus({
                    ...iconStatus,
                    CustomerIcon: "selected",
                  })
                }}

              />
            </div>
            <div className='input-fetch-flex' >
              <div style={isEditMode ? { pointerEvents: "none", opacity: 0.5 } : {}}>
                <InputComponent
                  height={"40px"}
                  width={"250px"}
                  padding={"0px 0px 0px 10px"}
                  margin={"0px 0px 30px 0px"}
                  border={errorChoosetype ? "1px solid red" : "1px solid #A9C3DC"}
                  borderRadius={"0px"}
                  backgroundColor={"white"}
                  disabled={isEditMode}
                  color={"black"}
                  type={"text"}
                  maxLength={
                    chooseType === "CIN" ? 21 :
                      chooseType === "PAN" ? 10 :
                        chooseType === "LLPIN" ? 8 : undefined
                  }
                  required={true}
                  placeHolder={`Enter Company ${((mainApiData?.chooseType !== (undefined)) && (mainApiData?.chooseType !== "")) ? mainApiData?.chooseType : "PAN"}`}
                  inputTitle={`Company ${((mainApiData?.chooseType !== (undefined)) && (mainApiData?.chooseType !== "")) ? mainApiData?.chooseType : "PAN"}`}
                  inputValue={mainApiData?.chooseTypeValue}
                  getUser={(value) => {
                    const userRes = value.toUpperCase()
                    setIsFormDirty(true)
                    dispatch(resetFormModified(true));
                    setMainApiData({
                      ...mainApiData,
                      chooseTypeValue: userRes
                    })

                    setCompanyOverviewPan(userRes)
                    setIconStatus({
                      ...iconStatus,
                      CustomerIcon: "selected",
                    })
                  }}
                />
              </div>
              <div style={isEditMode ? { pointerEvents: "none", opacity: 0.5 } : {}}>
                <IconButton
                  iconName={"Fetch"}
                  height={"40px"}
                  width={"100px"}
                  fontSize={""}
                  color={""}
                  border={""}
                  borderRadius={"0px"}
                  disabled={isEditMode}
                  backgroundColor={"#0055D4"}
                  hover={mainApiData?.chooseType !== (undefined || null) ? true : false}
                  margin={"4px 0px 0px 0px"}
                  handleClick={() => {
                    if (isEditMode) return;
                    handelFectch()
                  }}
                />
              </div>
            </div>
          </div>
          <div className='company-heading'>
            Customer Overview
          </div>
          <div className='form-main-wrapper'>
            <div>
              <InputComponent
                height={"40px"}
                width={"300px"}
                margin={"0px 45px 30px 0px"}
                padding={"0px 0px 0px 10px"}
                border={companyOverviewErrors.customerName ?
                  "1px solid red" : "1px solid #A9C3DC"
                }
                borderRadius={"0px"}
                backgroundColor={"white"}
                autoFocus
                color={"black"}
                type={"text"}
                // disabled={isEditMode && mainApiData?.status === "Pending Approval" ? true : false}
                placeHolder={"Enter Customer name"}
                inputTitle={"Customer Name"}
                required={true}
                maxLength={100}
                inputValue={mainApiData && mainApiData?.customerName}
                getUser={(value: any) => {
                  setIsFormDirty(true)
                  dispatch(resetFormModified(true));

                  const val = value.replace(/^[^A-Za-z0-9]/g, "");
                  setMainApiData({
                    ...mainApiData,
                    // ...mainApiData?.customerOverView,
                    customerName: val,
                  })
                  setIconStatus({
                    ...iconStatus,
                    CustomerIcon: "selected",
                  })
                  // setIsFormDirty(true)
                }}

              />
            </div>
            <div>
              <InputComponent
                height={"40px"}
                width={"300px"}
                margin={"0px 45px 30px 0px"}
                padding={"0px 0px 0px 10px"}
                border={companyOverviewErrors.customerCode ?
                  "1px solid red" : "1px solid #A9C3DC"
                }
                borderRadius={"0px"}
                backgroundColor={"white"}
                color={"black"}
                type={"text"}
                placeHolder={"Enter Customer Code"}
                inputTitle={"Customer Code"}
                required={true}
                maxLength={8}
                // disabled={isEditMode && mainApiData?.status === "Pending Approval" ? true : false}
                inputValue={mainApiData && mainApiData?.customerCode}
                getUser={(value: any) => {
                  setIsFormDirty(true)
                  dispatch(resetFormModified(true));
                  const val = value.toUpperCase().replace(/[^A-Z0-9-]/g, "")
                  setMainApiData({
                    ...mainApiData,
                    customerCode: val,

                  })
                  setIconStatus({
                    ...iconStatus,
                    CustomerIcon: "selected",
                  })

                }}

              />
            </div>

            <div key={uuidv4()} style={{ marginRight: "45px", marginBottom: "30px" }} className={companyOverviewErrors.companyConstitution ? 'setErrorcompany' : "dropdown-width"}>
              <DropdownComponent
                width={"300px"}
                required={true}
                // isDisabled={isEditMode && mainApiData?.status === "Pending Approval" ? true : false}

                title={"Company Constitution"}
                defaultValue={[{ label: (mainApiData?.companyConstitution === undefined || mainApiData?.companyConstitution === "") ? "Select" : mainApiData?.companyConstitution, value: mainApiData?.companyConstitution === undefined ? "" : mainApiData?.companyConstitution }]}
                // defaultValue={[{ label: mainApiData?.companyConstitution === undefined ? "Select" : mainApiData?.companyConstitution, value: mainApiData?.companyConstitution === undefined ? "" : mainApiData?.companyConstitution }]}
                options={constitutionData}
                getData={(value: any) => {
                  setIsFormDirty(true)
                  dispatch(resetFormModified(true));
                  setMainApiData({
                    ...mainApiData,
                    // ...mainApiData?.customerOverView,
                    companyConstitution: value.value,
                    companyConstitutionId: value.companyConstitutionId,

                  })
                  setIconStatus({
                    ...iconStatus,
                    CustomerIcon: "selected",
                  })
                }}
              />
            </div>


          </div>
          <div className='form-main-wrapper'>
            <div key={uuidv4()} style={{ marginRight: "45px", marginBottom: "30px" }} className={companyOverviewErrors.businessCategory ? 'setErrorcompany' : "dropdown-width"}>
              <DropdownComponent
                width={"300px"}
                // isDisabled={isEditMode && mainApiData?.status === "Pending Approval" ? true : false}
                title={"Company Sub Category"}
                required={true}
                defaultValue={[{ label: (mainApiData?.businessCategory === undefined || mainApiData?.businessCategory === "") ? "Select" : mainApiData?.businessCategory, value: mainApiData?.businessCategory === undefined ? "Select" : mainApiData?.businessCategory }]}
                options={subCategoryData}
                getData={(value: any) => {
                  setIsFormDirty(true)
                  dispatch(resetFormModified(true));
                  setMainApiData({
                    ...mainApiData,
                    businessCategory: value.value,
                    businessCategoryId: value.id

                  })
                  setIconStatus({
                    ...iconStatus,
                    CustomerIcon: "selected",
                  })
                }}
              />
            </div>
            <div key={uuidv4()} style={{ marginRight: "45px", marginBottom: "30px" }} className={companyOverviewErrors.typesOfBillingCycle ? 'setErrorcompany' : "dropdown-width"}>
              <DropdownComponent
                width={"300px"}
                // isDisabled={isEditMode && mainApiData?.status === "Pending Approval" ? true : false}
                title={"Types of Billing Cycle"}
                // required={true}
                defaultValue={[{
                  label: (mainApiData?.typesOfBillingCycle === undefined || mainApiData?.typesOfBillingCycle === "") ? "Select" : mainApiData?.typesOfBillingCycle,
                  value: mainApiData?.typesOfBillingCycle === undefined ? "Select" : mainApiData?.typesOfBillingCycle
                }]}
                options={billingCycle}

                getData={(value: any) => {
                  setIsFormDirty(true)
                  dispatch(resetFormModified(true));
                  setMainApiData({
                    ...mainApiData,
                    // ...mainApiData?.customerOverView,
                    typesOfBillingCycle: value.value,
                    typesOfBillingCycleId: value.billingCycleId,
                    noOfdays: ""
                  })
                  // setCompanyOverviewErrors({
                  //   noOfdays: false 

                  // })

                  setIconStatus({
                    ...iconStatus,
                    CustomerIcon: "selected",
                  })
                }}
              />
            </div>

            <div style={mainApiData?.typesOfBillingCycle === "Days" ? { pointerEvents: "auto", opacity: 1 } : { pointerEvents: "none", opacity: 0.5 }}>
              <InputComponent
                height={"40px"}
                width={"300px"}
                margin={"0px 45px 30px 0px"}

                padding={"0px 0px 0px 10px"}
                border={companyOverviewErrors.noOfdays && mainApiData?.typesOfBillingCycle === "Days" ? "1px solid red" : ""
                }
                borderRadius={"0px"}
                backgroundColor={"white"}
                color={"black"}
                type={"text"}
                required={true}
                inputTitle={"No of days"}
                placeHolder={"Enter Days"}
                disabled={mainApiData?.typesOfBillingCycle === "Days" ? false : true}

                // disabled={(isEditMode || mainApiData?.typesOfBillingCycle === "Days") && (mainApiData?.typesOfBillingCycle === "Days" && mainApiData?.isActive !== "Declined") ? false : true}

                maxLength={3}
                inputValue={mainApiData && mainApiData?.noOfdays}
                getUser={(val: any) => {
                  setIsFormDirty(true)
                  dispatch(resetFormModified(true));
                  let value = val.replace(/[^0-9]/g, "")
                  // const val = value.replace(/[^0-9]/g, "")

                  setMainApiData({
                    ...mainApiData,
                    // ...mainApiData?.customerOverView,
                    noOfdays: value,

                  })
                  setIconStatus({
                    ...iconStatus,
                    CustomerIcon: "selected",
                  })
                }}
              />
            </div>
          </div>

          <div className='form-main-end' style={isEditMode ?
            { pointerEvents: "none", opacity: 0.5 } : { pointerEvents: "auto", opacity: 1 }}>
            <div className='radio-input vendor-form-radio-input' style={{ width: "344px" }}>
              <div className=''>Is Active<span className='required'>*</span></div>
              <div className='radio-btn-flex' >
                <RadioOrCheckbox
                  value={"Yes"}
                  type={"radio"}
                  name={"Yes"}
                  margin={"0px 30px 0px 0px"}
                  disabled={true}
                  checkedValue={mainApiData && mainApiData?.isActive}
                  getVal={(value: any) => {
                    setCompanyOverviewErrors({})
                    setMainApiData({
                      ...mainApiData,
                      // ...mainApiData?.customerOverView,
                      isActive: value,
                      remarks: ""

                    })
                    setIconStatus({
                      ...iconStatus,
                      CustomerIcon: "selected",
                    })
                    setIsFormDirty(true)
                    dispatch(resetFormModified(true));
                    // setRadioActive(value)
                  }}
                />
                {
                  <div style={mainApiData?.isActive === "No" ? { pointerEvents: "auto", opacity: 1 } : { pointerEvents: "none", opacity: 0.5 }} >
                    <RadioOrCheckbox
                      value={"No"}
                      type={"radio"}
                      disabled={true}
                      name={"No"}
                      checkedValue={mainApiData && mainApiData?.isActive}
                      getVal={(value: any) => {
                        setCompanyOverviewErrors({})
                        setMainApiData({
                          ...mainApiData,
                          // ...mainApiData?.customerOverView,
                          isActive: value,

                        })
                        setIconStatus({
                          ...iconStatus,
                          CustomerIcon: "selected",
                        })
                        setIsFormDirty(true)
                        dispatch(resetFormModified(true));
                        // setRadioActive(value)
                      }}
                    />
                  </div>
                }
              </div>
            </div>
            { mainApiData?.isActive === "No" &&
            <div style={mainApiData?.isActive === "No" ? { width: "100%" } : { pointerEvents: "none", opacity: "0.5", width: "100%" }}>
              <InputComponent
                height={"40px"}
                width={"65%"}
                margin={"0px 0px 45px 0px"}
                padding={"0px 0px 0px 10px"}
                borderRadius={"0px"}
                backgroundColor={"white"}
                color={"black"}
                disabled={true}
                type={"text"}
                placeHolder={"Reason"}
                inputTitle={"Remarks"}
                required={true}
                maxLength={250}
                border={companyOverviewErrors.remarks ? "1px solid red" : "1px solid #A9C3DC"}
                inputValue={mainApiData && mainApiData?.remarks}
                getUser={(value: any) => {
                  const val = value.replace(/[^A-Za-z] /g, "")
                  setMainApiData({
                    ...mainApiData,
                    // ...mainApiData?.customerOverView,
                    remarks: val,

                  })
                  setIconStatus({
                    ...iconStatus,
                    CustomerIcon: "selected",
                  })
                  setIsFormDirty(true)
                  dispatch(resetFormModified(true));
                }}
              />
            </div>
}
          </div >
          {/* (approvalStatus.status === "Approved" || approvalStatus.status === "Declined") */}
          {
            isEditMode && (mainApiData?.status === "Approved" || mainApiData?.status === "Declined") &&
            <div className='virtualCard-approval-form' style={{ pointerEvents: "auto", opacity: 0.5, width: "97%" }} >
              <div className='approval-title-virtual' >
                {mainApiData && mainApiData?.status === "Declined" ? (<p>Your request to add a Customer master record has been declined.</p>) : (<p>Your request to add a Customer master record has been approved.</p>)}
              </div>
              <div className='approval-radio-input'>
                <div className='approval-radio-btn'>
                  <RadioOrCheckbox
                    value={"Approved"}
                    type={"radio"}
                    name={"Approve"}
                    disabled={true}
                    margin={"0px 30px 0px 0px"}
                    checkedValue={mainApiData && mainApiData?.status}
                    getVal={(val) => {
                      setIsFormDirty(true)

                      setMainApiData({
                        ...mainApiData,
                        // ...mainApiData?.customerOverView,
                        status: val,
                      })
                      // setIsButtonDisabled(false)
                      // setUserDataErrors({})
                      dispatch(resetFormModified(true));
                      // setIsDisabled(false)
                    }}
                  />
                  <div>
                    <RadioOrCheckbox
                      value={"Declined"}
                      type={"radio"}
                      name={"Decline"}
                      disabled={true}
                      checkedValue={mainApiData && mainApiData?.status}
                      getVal={(val) => {
                        setIsFormDirty(true)

                        setMainApiData({
                          ...mainApiData,
                          // ...mainApiData?.customerOverView,
                          status: val,
                        })
                        // setIsButtonDisabled(false)
                        dispatch(resetFormModified(true));
                        // setIsDisabled(false)
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          }
          {
            isEditMode && (mainApiData?.status === "Approved" || mainApiData?.status === "Declined") &&
            <div className='decline-reason' style={
              { pointerEvents: "auto", opacity: 0.5, width: "97%" }
            } >
              <div className='reason-area'
                style={
                  mainApiData && mainApiData?.status === "Declined" ?
                    { pointerEvents: "auto", opacity: 1 } : { pointerEvents: "none", opacity: 0.5 }
                }>
                <InputComponent
                  height={"60px"}
                  width={"90%"}
                  margin={"10px 60px 30px 0px"}
                  padding={"0px 0px 0px 10px"}
                  border={"1px solid #A9C3DC"}
                  borderRadius={"0px"}
                  backgroundColor={"white"}
                  color={"black"}
                  maxLength={250}
                  type={"text"}
                  disabled={isEditMode && mainApiData && mainApiData?.status === "Declined" ? true : false}
                  inputTitle={"Reason for Declining Request"}
                  required={true}
                  placeHolder={"Reason"}
                  inputValue={mainApiData && mainApiData?.declineRemarks}
                  getUser={(value: any) => {
                    setIsFormDirty(true)
                    setMainApiData({
                      ...mainApiData,
                      // ...mainApiData?.customerOverView,
                      declineRemarks: value,
                    })


                    dispatch(resetFormModified(true));
                  }}
                />
              </div>
            </div>
          }

          <div style={{ height: "10vh", }}>
            <div className='footer-company'>
              <ButtonComponent
                title={"Cancel"}
                height={"50px"}
                width={"150px"}
                margin={"0px 8px"}
                backgroundColor={"#888888"}
                color={"white"}
                className={"button-component-hover cancel"}
                handleClick={isFormModified

                  ?
                  () => { setShowChangesModal(true) }
                  : returnGird}
              />
              <div>


                <ButtonComponent
                  title={isEditMode ? "Update" : "Next"}
                  height={"50px"}
                  width={"150px"}
                  backgroundColor={"#0055D4"}
                  color={"white"}
                  margin={"0px 8px"}
                  // disabled={isEditMode && mainApiData?.status === "Pending Approval" ? true : false}
                  className={"button-component-hover common-btn"}
                  handleClick={next}
                />
              </div>
            </div>
          </div>
        </div >
      </div >
      {
        showAlertBox &&
        <div className='alert-warp'>
          <AlertBox type={showType} message={showMessage} />
        </div>

      }
      {
        showChangesModal &&
        <Changesmodal
          closeModal={() => setShowChangesModal(false)}
          handleClose={() => {
            setShowChangesModal(false);
          }}
          leavePage={() => returnGird()} />
      }


    </>
  )
})

export default CustomerOverview;


// style={isEditMode && mainApiData?.status !== "Declined" ?
//             { pointerEvents: "none", opacity: 0.5 } : { pointerEvents: "auto", opacity: 1 }}
//isEditMode && mainApiData?.status !== "Declined" ? true :
//isEditMode && mainApiData?.status !== "Declined" ? "button-component-hover disabled" : 
