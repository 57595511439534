import React, { useState, useEffect, useRef } from 'react'
import MainPageCard from '../../common-component/cards/main-page-card'
import NavigateHeader from '../../common-component/navigate-header'
import IconButton from '../../common-component/form-elements/icon-button'
import AgGrid, { AgGridRef } from '../../common-component/grids-and-tables/ag-grid'
import useAxiosPrivate from "../../../services/hooks/useaxios-private";
import { bankApprovalColumnData } from '../../../config/approval/bank-approval/bank-approval-grid-config';
import FileUpload from '../../common-component/modals/file-upload-modal'
import DeleteModal from '../../common-component/modals/delete-modal'
import GridFilter from '../../common-component/grids-and-tables/grid-filter'
import useAuth from "../../../services/hooks/useauth";
import { v4 as uuidv4 } from 'uuid';
import AlertBox from '../../common-component/alert-box'
import OtpVerifyModal from '../../common-component/modals/otp-verify-modal'
import "../../../styles/approval/bank-approval/grid.scss"
import { Filter } from '../../../helpers/filtterFunction'

interface Props {
  userNavigateFunction: (val: string) => void;
  getEditData?: ((name: string, data: any) => void) | undefined;
  modeFunction: (val: boolean) => void;
  getEditMode: (value: boolean) => void;

}
interface IFilterValue {
  [key: string]: {
    columnName: string;
    filterType: string;
    filterWord: string;
  };
}


function Grid({ userNavigateFunction, getEditMode, modeFunction, getEditData }: Props) {

  const [showType, setShowType] = useState("warning") // error message showType
  const [showMessage, setShowMessage] = useState<string>("") // error message showMessage
  const [showAlertBox, setShowAlertBox] = useState(false) // error message ShowAlertBox
  const gridDataDownloadRef = useRef<AgGridRef>(null);
  const [rowData, setRowData] = useState<any>([]);
  const [curruntData, setCurruntData] = useState<any>();
  const [fileUpload, setFileUpload] = useState<boolean>(false);
  const [filter, setFilter] = useState(false);
  const [filterRestore, setFilterRestore] = useState<IFilterValue>({})
  const [orginalRowData, setOrginalRowData] = useState<any>([]);
  const [filterMethod, setFilterMethod] = useState("")
  const [companyId, setCompanyId] = useState("")
  const [showDeleteModel, setShowDeleteModel] = useState<boolean>(false)
  const [otpVerification, setOtpVerification] = useState<boolean>(false)
  const [isLocked, setIsLocked] = useState<boolean>(false);
  const [uploadedDataStore, setuploadedDataStore] = useState<any>([]);

  const axiosPrivate = useAxiosPrivate();
  const { auth } = useAuth();


  useEffect(() => {
    fetchData();
  }, []);

  // Get data for grid API
  const fetchData = async () => {
    try {
      const response = await axiosPrivate.get('companyadmin/bank');
      setRowData(response.data.bankDetails);

      setOrginalRowData(response.data.bankDetails)
      setCompanyId(response.data.bankDetails.companyBankId)

    } catch (error) {
    }
  };

  // Function for Filter values
  const handleFilter = (value: any, filterType: string) => {
    Filter(value, filterType, orginalRowData, setRowData)
  }

  // upload bank data 
  const getUploadedUserData = (val: any) => {
    setuploadedDataStore(val);

  }

  // handle download grid
  const handleDownloadExcel = () => {
    gridDataDownloadRef.current!.onBtExport();
  }

  // this function for BulkUpload
  const clearValue = (val: any) => {
    setFileUpload(val);
  }
  // get bank data for doubleclick
  async function getUserData(data: any) {
    modeFunction(true)
    try {
      const response = await axiosPrivate.get(`companyadmin/bank/getAccountNumberById/${data.companyBankId}`);
      data.accountNumber = response.data.accountNumber
      getEditData && getEditData("form", data);
    } catch (error) {
    }
  }
  // current data update
  const getCurruntData = (val: any) => {
    setCurruntData(val);
  }

  // Timeout function for messages
  const clearAleart = () => {
    const timer = setTimeout(() => {
      setShowAlertBox(false);
      setShowMessage("");
      clearTimeout(timer);
    }, 5000);
  }

  // Delete function for delete data in grid
  async function getDelete() {
    if (curruntData.length === 1) {
      axiosPrivate.delete(`companyadmin/bank/delete/${curruntData[0].companyBankId}`)
        .then(response => {
          if (response.status === 200) {
            setOtpVerification(false)
            setShowAlertBox(true)
            setShowType("success")
            setShowMessage(response.data.message)
            clearAleart()
            setCurruntData([])
            getEditMode(false)
            setShowDeleteModel(false)
            fetchData()

          }
        })
        .catch(error => {
          setShowAlertBox(true);
          setShowType("warning")
          setShowMessage(error.response.data.error)
          setShowDeleteModel(false)
          clearAleart()
        });


    } else {


      let idList: any = [];
      curruntData.map((e: any, i: number) => {
        idList.push({
          id: e.companyBankId
        })
      })
      try {
        const res = await axiosPrivate.delete("companyadmin/bank/multiple-delete", {
          data: {
            data: idList
          }
        })

        if (res.status === 200) {
          setOtpVerification(false)
          setShowAlertBox(true)
          setShowType("success")
          setShowMessage(res.data.message)
          clearAleart()
          getEditMode(false)
          setCurruntData([])
          setShowDeleteModel(false)
          fetchData()

        }
      } catch (error: any) {
        setShowAlertBox(true)
        setShowType("danger")
        setShowMessage(error.response.data.error)
        clearAleart()
        setShowDeleteModel(false)
      }

    }
  };


  // this function call when click resend button in OTP screen   
  async function resendOtp() {
    let res;
    let currentDataValue = curruntData[0].companyBankId
    try {

      res = await axiosPrivate.post('companyadmin/bank/resend-otp-update/approval', {
        approvedBy: auth.token, pageName: "bankApproval", processName: "delete", currentDataId: curruntData.companyBankId

      });
      if (res.status === 200) {
        setShowAlertBox(true)
        setShowType("success")
        setShowMessage(res.data.message)
        clearAleart()
      }

    } catch (error: any) {
      setShowAlertBox(true);
      setShowType("danger")
      setShowMessage(error.response.data.error)
      clearAleart()
    }
  }

  return (
    <MainPageCard>
      <div className='header-main'>
        <div className='navigater'>
          <NavigateHeader
            style={{
              cursor: "default"
            }}
            firstValue={"Approval"}
            secondValue={"Bank"}
          />
        </div>

        <div className='icon-buttons'>
          <IconButton
            iconName={"Filter"}
            height={"40px"}
            width={"40px"}
            fontSize={"30px"}
            margin={"0px 8px"}
            color={"white"}
            backgroundColor={"#0055D4"}
            hover={true}
            handleClick={(event: React.MouseEvent) => {
              event?.stopPropagation()
              setFilter(!filter);
            }}
          />
          <IconButton
            iconName={"Delete"}
            height={"40px"}
            width={"40px"}
            fontSize={"25px"}
            margin={"0px 8px"}
            color={"white"}
            backgroundColor={"#D60000"}
            hover={curruntData?.length > 0}
            opacity={curruntData?.length > 0 ? "1" : "0.5"}
            cursor={curruntData?.length > 0 ? "pointer" : "default"}
            handleClick={() => {

              if (curruntData?.length > 0) {
                setShowDeleteModel(!showDeleteModel)

              }
            }}
          />
          <IconButton
            iconName={"Add"}
            height={"40px"}
            width={"75px"}
            fontSize={"25px"}
            margin={"0px 8px"}
            color={"white"}
            hover={false}
            opacity={"0.5"}
            cursor={"not-allowed"}
            backgroundColor={"#0055D4"}
            handleClick={() => {
              //   userNavigateFunction("form")

            }}
          />
          <IconButton
            iconName={"Upload"}
            height={"40px"}
            width={"40px"}
            fontSize={"25px"}
            margin={"0px 0px 0px 8px"}
            color={"white"}
            backgroundColor={"#0055D4"}
            hover={false}
            opacity={"0.5"}
            cursor={"not-allowed"}
            handleClick={() => {
              setFileUpload(false);
              // setuploadedDataGridShow(true);
            }}
          />
          <div>
            <IconButton
              iconName={"Download"}
              height={"40px"}
              width={"40px"}
              fontSize={"25px"}
              margin={"0px 8px"}
              color={"white"}
              backgroundColor={"#0055D4"}
              hover={true}
              handleClick={handleDownloadExcel}
            />
          </div>
        </div>
      </div>
      <div>
        <AgGrid
          ref={gridDataDownloadRef}
          fileName={"Bank"}
          tabelRowData={rowData}
          displaylength={10}
          tableColumnData={bankApprovalColumnData}
          doubleClickUpdate={() => {
            modeFunction(false);
          }}
          getEdit={(data) => getUserData(data)}
          getCurruntData={getCurruntData}

        />
      </div>
      {
        filter &&
        <div className='filter' key={uuidv4()}>
          <GridFilter
            filterRestore={filterRestore}
            filterMethod={filterMethod}
            tabelRowData={rowData}
            data={bankApprovalColumnData.slice(1)}
            handleClick={(Value: any, filterType: string) => {
              if (filterType !== "") {
                setFilterRestore(Value)
                setFilter(!filter)
                setFilterMethod(filterType)
                handleFilter(Value, filterType)
                setCurruntData([]);

              } else {
                setFilterMethod("")
                setFilterRestore({})
                setFilter(!filter)
                setRowData(orginalRowData)
                setCurruntData([]);
              }
            }}
          />
        </div>
      }
      {
        fileUpload &&
        <FileUpload
          clearValue={clearValue}
          headerData={bankApprovalColumnData}
          templateName={"User"}
          getUploadedUserData={getUploadedUserData}
          excelFileName={"BankApprovalData"}
        />
      }

      {
        showDeleteModel &&
        <div >
          <DeleteModal
            getDelete={async () => {
              let currentDataValue = curruntData[0].companyBankId
              try {
                const res = await axiosPrivate.post("companyadmin/bank/otp-update/approval", {
                  approvedBy: auth.token, pageName: "bankApproval", processName: "delete", currentDataId: curruntData[0].companyBankId


                })
                if (res.status === 200) {
                  setOtpVerification(true)
                  setShowDeleteModel(false)
                  setShowAlertBox(true)
                  setShowType("success")
                  setShowMessage(res.data.message)
                  clearAleart()
                }

              } catch (error: any) {
                setShowAlertBox(true);
                setShowType("danger")
                setShowMessage(error.response.data.error)
                clearAleart()
              }
            }}
            clearValue={(value) => {
              setShowDeleteModel(value)
            }}
            modelType={"grid-delete"}

          />
        </div>
      }
      {
        showAlertBox &&
        <div className='alert-warp'>
          <AlertBox type={showType} message={showMessage} />
        </div>
      }
      {
        otpVerification &&
        <div className='otp-model'>
          <OtpVerifyModal
            otpVerify={resendOtp}
            leavePage={() => {
              setOtpVerification(false)
              userNavigateFunction("grid")
            }
            }
            isLocked={false}
            setIsLocked={setIsLocked}
            getStatus={async (stringFromArray) => {
              let res;
              let currentDataValue = curruntData[0].companyBankId
              try {

                res = await axiosPrivate.post('companyadmin/bank/verify-otp/update', {
                  otp: stringFromArray,
                  approvedBy: auth.token,

                });
                if (res.data.status === "true") {
                  getDelete(
                    // userNavigateFunction("grid")
                  )

                }
              } catch (error: any) {
                setShowAlertBox(true);
                setShowType("danger")
                setShowMessage(error.response.data.error)
                clearAleart()
              }
            }}
          />
        </div>
      }
    </MainPageCard>
  )
}

export default Grid;
