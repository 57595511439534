import React, { useState, useEffect, useRef } from 'react'
import MainPageCard from '../../common-component/cards/main-page-card'
import NavigateHeader from '../../common-component/navigate-header'
import IconButton from '../../common-component/form-elements/icon-button'
import AgGrid, { AgGridRef } from '../../common-component/grids-and-tables/ag-grid'
import { v4 as uuidv4 } from 'uuid';
import { customerOnboardingColumnData, customerOnboardingRowData } from '../../../config/product-admin/customer-onboarding/customer-onboarding-config'
import GridFilter from '../../common-component/grids-and-tables/grid-filter'
import { axiosPrivate } from '../../../middleware/axois-api'
import "../../../styles/product-admin/users/grid.scss"
import ButtonComponent from '../../common-component/form-elements/button-component'
import { useNavigate } from 'react-router-dom'
import { Filter } from '../../../helpers/filtterFunction'
import CustomEllipsisRenderer from "../../../config/product-admin/users/users-aggrid-column-config"
import CellEyeIcon from '../../common-component/grids-and-tables/cell-eye-icon'
import OverAllHyperVergeDetailsModel from '../../common-component/modals/overall-hyperverge-details'
import Loading from '../../common-component/modals/loading-screen'


interface Props {
  getEditData?: ((name: string, data: any) => void) | undefined;
  userNavigateFunction: (val: any) => void;
  getEditMode: (value: boolean) => void;
}

interface IFilterValues {
  [key: string]: {
    columnName: string;
    filterType: string;
    filterWord: string;
  };
}

function Grid({ userNavigateFunction, getEditData, getEditMode }: Props) {

  const gridDownloadRef = useRef<AgGridRef>(null);
  const [rowData, setRowData] = useState<any>([]);
  const [filter, setFilter] = useState(false);
  const [filterRestore, setFilterRestore] = useState<IFilterValues>({});
  const [filterMethod, setFilterMethod] = useState("");
  const [curruntData, setCurruntData] = useState<any>();
  const [orginalRowData, setOrginalRowData] = useState<any>([]);
  const [userPrivilege, setUserPrivilege] = useState<any>("");
  const navigate = useNavigate();
  const [showDocumentModal, setShowDocumentModal] = useState(false)
  const [companyId, setCompanyId] = useState<any>()
  const [userEditedData, setUserEditedData] = useState<any>([])
  const [comparisonData, setComparisonData] = useState<any>([])
  const [loadingScreen, setLoadingScreen] = useState<boolean>(false)

  // Function for getting table grid values via API 
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosPrivate.get('/customer-onboarding/table/get');
        setRowData(response.data.companyDetails);
        setOrginalRowData(response.data.companyDetails)
      } catch (error) {
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchPrivillageData = async () => {
      try {
        const response = await axiosPrivate.get('/user/get-privilege');
        setUserPrivilege(response.data.UserPrivilege)

      } catch (error) {
      }
    };
    fetchPrivillageData();
  }, []);

  // Function for getting the Company values via API  
  function getUserData(id: string) {
    axiosPrivate.get(`/customer-onboarding/form/get/${id}`)
      .then(response => {
        if (response.status === 200) {
          if (getEditData) {
            getEditData("form", response.data.companyAndKycDetails);
          }
        }
      })
      .catch(error => {
      });
  }

  // Download Excel Files 
  const handleDownloadExcel = () => {
    gridDownloadRef.current!.onBtExport();
  }

  // current data update
  const getCurruntData = (val: any) => {
    setCurruntData(val);
  }

  // Function for Filter values
  // Function for Filter values
  const handleFilter = (value: any, filterType: string) => {
    Filter(value, filterType, orginalRowData, setRowData)
  }


  // customerOnboardingColumnData config file
  const customerOnboardingColumnData = [
    {
      field: 'selection', headerName: '', minWidth: 60, maxWidth: 60, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, headerCheckboxSelection: true, checkboxSelection: true, showDisabledCheckboxes: true, suppressRowTransform: true, cellStyle: { textAlign: 'left' }, cellRendererFramework: CustomEllipsisRenderer,
    },
    {
      field: 'companyName', headerName: 'Company Name', minWidth: 280, maxWidth: 360, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, filtertype: "text", cellStyle: { textAlign: 'left' }, cellRendererFramework: CustomEllipsisRenderer,
      cellRenderer: (e: any) => {
        return (
          <div style={{
            display: "flex",
            justifyContent: "center",
            whiteSpace: "normal",
            lineHeight: "25px",
            flexDirection: "column",
            margin: "5px"
          }}>
            <p style={{ margin: "0px" }}>{e.data.companyName}</p>
          </div>
        )
      },
    },
    {
      field: 'companyConstitution', headerName: 'Company Constitution', minWidth: 280, maxWidth: 360, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, filtertype: "text", cellStyle: { textAlign: 'left' }, cellRendererFramework: CustomEllipsisRenderer,
      cellRenderer: (e: any) => {
        return (
          <div style={{
            display: "flex",
            justifyContent: "center",
            whiteSpace: "normal",
            lineHeight: "25px",
            flexDirection: "column",
            margin: "5px"
          }}>
            <p style={{ margin: "0px" }}>{e.data.companyConstitution}</p>
          </div>
        )
      },
    },
    {
      field: 'proof', headerName: 'Proof Submitted', minWidth: 280, maxWidth: 580, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, filtertype: "text", cellStyle: { textAlign: 'left' }, cellRendererFramework: CustomEllipsisRenderer,
      cellRenderer: (e: any) => {

        const proofs = e.data.proof.split(', ').map((proof: string, index: number) => (
          <li key={index} style={{ margin: "0px", paddingLeft: "20px" }}>{proof}</li>
        ));

        return (
          <div style={{
            display: "flex",
            justifyContent: "center",
            whiteSpace: "normal",
            lineHeight: "25px",
            flexDirection: "column",
            margin: "5px"
          }}>
            <ul style={{ margin: "0px", paddingLeft: "20px" }}>
              {proofs}
            </ul>
          </div>
        )
      },
    },
    {
      field: 'cus_onb_status', headerName: 'Customer Onboarding Status', minWidth: 280, maxWidth: 360, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, filtertype: "text", cellStyle: { textAlign: 'left' }, cellClassRules: {
        'success-cell': (params: any) => params.value === 'Approved',
        'submitted-cell': (params: any) => params.value === 'Submitted',
        'denied-cell': (params: any) => params.value === 'Declined',
        'registered-cell': (params: any) => params.value === 'Pending Approval',
      }, cellRendererFramework: CustomEllipsisRenderer,
      cellRenderer: (e: any) => {
        return (
          <div style={{
            display: "flex",
            justifyContent: "center",
            whiteSpace: "normal",
            lineHeight: "25px",
            flexDirection: "column",
            margin: "5px"
          }}>
            <p style={{ margin: "0px" }}>{e.data.cus_onb_status}</p>
          </div>
        )
      },
    },
    // {
    //   field: 'transactionHistory',
    //   headerName: 'User Edited',
    //   minWidth: 150,
    //   resizable: false,
    //   suppressMovable: true,
    //   filter: false,
    //   suppressSizeToFit: false,
    //   cellStyle: { textAlign: 'left', color: "#0055d5" },
    //   filtertype: "text",
    //   cellRendererFramework: CellEyeIcon,
    //   cellRendererParams: (params: any) => {
    //     const handleIconClick = async () => {
    //       setLoadingScreen(true)
    //       try {
    //         const response = await axiosPrivate.post(`customer-onboarding/get-overall-details/${params.data.companyId}`);
    //         setShowDocumentModal(true)
    //         setLoadingScreen(false)
    //         let data = []
    //         data.push(...response.data.finalData)
    //         data.push(...response.data.DirectorName)
    //         data.push(...response.data.DirectorDin)
    //         data.push(...response.data.DirectorDesignation)
    //         data.push(...response.data.gstinNumber)
    //         data.push(...response.data.gstinStatus)
    //         data.push(...response.data.gstinState)
    //         data.push(...response.data.gstinStateCode)
    //         console.log(data)
    //         setComparisonData(data)

    //         // SetEditData && SetEditData(response.data, false);
    //         // setVendorEyeShowModal(!vendorEyeShowmodal);
    //       } catch (error) {
    //         setLoadingScreen(false)
    //       }
    //     };

    //     return {
    //       handleIconClick: handleIconClick,
    //       showIcon: false
    //     };
    //   }
    // }
  ]


  return (
    <>
      <MainPageCard>
        <div className='header-main'>
          <div className='navigater'>
            <NavigateHeader
              firstValue={userPrivilege === "FingertipAdmin" ? "Fingertip Admin" : "Customer Authorizer"}
              secondValue={userPrivilege === "FingertipAdmin" ? "KYC Onboarding" : "Customer Onboarding"}
            />
          </div>
          <div className='icon-buttons'>

            <IconButton
              iconName={"Filter"}
              height={"40px"}
              width={"40px"}
              fontSize={"30px"}
              margin={"0px 8px"}
              color={"white"}
              backgroundColor={"#0055D4"}
              hover={true}
              handleClick={(event: React.MouseEvent) => {
                event?.stopPropagation()
                setFilter(!filter);
              }}
            />
            <IconButton
              iconName={"Delete"}
              height={"40px"}
              width={"40px"}
              fontSize={"25px"}
              margin={"0px 8px"}
              color={"white"}
              backgroundColor={"#D60000"}
              hover={false}
              opacity={"0.5"}
              cursor={"not-allowed"}
              handleClick={() => { }}
            />
            <IconButton
              iconName={"Download"}
              height={"40px"}
              width={"40px"}
              fontSize={"25px"}
              margin={"0px 8px"}
              color={"white"}
              backgroundColor={"#0055D4"}
              hover={true}
              handleClick={handleDownloadExcel}
            />
            {
              userPrivilege === "FingertipAdmin" &&
              <ButtonComponent
                title={"Upload KYC"}
                height={"40px"}
                width={"120px"}
                backgroundColor={"#0055D4"}
                color={"white"}
                margin={"0px 8px"}
                // disabled={buttonDisabled ? false : true}
                className={"button-component-hover common-btn"}
                handleClick={() => { navigate("/organisation/kycUpload"); }}
              />
            }
            <ButtonComponent
              title={"Verify KYC"}
              height={"40px"}
              width={"120px"}
              backgroundColor={"#0055D4"}
              color={"white"}
              margin={"0px 8px"}
              // disabled={buttonDisabled ? false : true}
              className={"button-component-hover common-btn"}
              handleClick={() => {
                // userNavigateFunction("form");
                getEditData && getEditData("form", {})
                getEditMode && getEditMode(false)

              }}
            />
          </div>
        </div>
        <div>
          <AgGrid
            ref={gridDownloadRef}
            fileName={"Customer Onboarding"}
            tabelRowData={rowData}
            tableColumnData={customerOnboardingColumnData}
            doubleClickUpdate={() => {
              getEditData && getEditData("form", "")
            }}
            getEdit={(data) => getUserData(data.companyId)}
            getCurruntData={getCurruntData}
            rowHeight={120}
          />
        </div>
        {
          showDocumentModal &&
          <OverAllHyperVergeDetailsModel
            closeDocument={(val: boolean) => { setShowDocumentModal(val); }}
            companyId={companyId}
            comparisonData={comparisonData}

          />
        }
        {
          filter &&
          <div className='customerOnboarding-filter' key={uuidv4()} >
            <GridFilter
              filterRestore={filterRestore}
              filterMethod={filterMethod}
              tabelRowData={rowData}
              data={customerOnboardingColumnData.slice(1)}
              handleClick={(Value: any, filterType: string) => {
                if (filterType !== "") {
                  setFilterRestore(Value)
                  setFilter(!filter)
                  setFilterMethod(filterType)
                  handleFilter(Value, filterType)
                  setCurruntData([]);
                } else {
                  setFilterMethod("")
                  setFilterRestore({})
                  setFilter(!filter)
                  setRowData(orginalRowData)
                  setCurruntData([]);
                }
              }}
            />
          </div>
        }
        {
          loadingScreen && <Loading />
        }

      </MainPageCard>
    </>
  )
}

export default Grid;
