import React, { Component } from 'react'
import ExcsieApprovalGrid from '../../components/approval/excise-approval/excise-approval-grid';
import ExcsieApprovalForm from '../../components/approval/excise-approval/excise-approval-card-form';
import Form from '../../components/approval/excise-approval/excise-approval-form'


interface StateValues {
    excisePaymentView: string;
    isEditMode: boolean;
    transaction: string;
    makePaymentData: any;
    editData: {};
}
export default class ExcsiePaymentApproval extends Component<{}, StateValues> {
    constructor(props: StateValues) {
        super(props);
        this.state = {
            excisePaymentView: "grid",
            isEditMode: false,
            transaction: "",
            makePaymentData: [],
            editData: {}
        };
    }
    addMode = () => {
        this.setState({
            isEditMode: false,
        })
    }
    render() {
        return (
            <div>
                {
                    this.state.excisePaymentView === "form" &&
                    <ExcsieApprovalForm
                        excisePaymentView={(val) => {
                            this.setState({ excisePaymentView: val })
                        }}
                        transaction={this.state.transaction}
                        makePaymentDatas={this.state.makePaymentData}
                    />
                }
                {
                    this.state.excisePaymentView === "grid" &&
                    <ExcsieApprovalGrid
                        getEditMode={(val) =>
                            this.setState({
                                isEditMode: val
                            })
                        }
                        excisePaymentView={(val) => {
                            this.setState({ excisePaymentView: val })
                        }}
                        transaction={(val: any) => {
                            this.setState({ transaction: val })
                        }}
                        makePaymentDataFun={(val: any) => {
                            this.setState({ makePaymentData: val })
                        }}
                        modeFunction={(val: boolean) => {
                            this.setState({
                                isEditMode: val
                            })
                        }}
                        addMode={this.addMode}
                        getEditData={(nav: any, data: any) => this.setState({
                            excisePaymentView: nav,
                            editData: { ...data },
                            isEditMode: true,
                        })}
                    />
                }
                {
                    this.state.excisePaymentView === "excise-form" &&
                    <Form
                        excisePaymentView={(val) => {
                            this.setState({ excisePaymentView: val });
                        }}
                        isEditMode={this.state.isEditMode}
                        getEditMode={(val) =>
                            this.setState({
                                isEditMode: val
                            })
                        }
                        modeFunction={(val: boolean) => {

                            this.setState({
                                isEditMode: val
                            })
                        }}
                        editData={this.state.editData}

                    />
                }
            </div>

        )
    }
}

