import React, { Component } from 'react'
import DirectTaxGrid from '../../components/payable/direct-tax/direct-tax-grid';
import DirectTaxForm from '../../components/payable/direct-tax/direct-tax-form';
import DirectTaxApproval from '../approval/direct-tax-approval';

interface StateValues {
    directTaxView: string;
    formDataById: any;
    directTaxId: any;

}

export default class PayableDirectTax extends Component<{}, StateValues> {
    constructor(props: StateValues) {
        super(props);
        this.state = {
            directTaxView: "grid",
            formDataById: [],
            directTaxId: "",
        };
    }
    render() {
        return (
            <div>
                {
                    this.state.directTaxView == "grid" &&
                    <>
                        <DirectTaxGrid
                            directTaxViewfun={(val: any) => { this.setState({ directTaxView: val }) }}
                            formDataByIdFun={(val: any) => {
                                this.setState({
                                    formDataById: val,
                                    directTaxView: "form"
                                })
                            }}
                        />
                    </>
                }
                {
                    this.state.directTaxView == "form" &&
                    <>
                        <DirectTaxForm
                            directTaxViewfun={(val: any) => { this.setState({ directTaxView: val }) }}
                            formDataById={this.state.formDataById}
                        />
                    </>
                }
            </div>
        )
    }
}

