import React, { useState } from 'react'
import { RxCross1 } from 'react-icons/rx'
import '../../../styles/gst/gst-view-challan-modal.scss'
import ButtonComponent from '../form-elements/button-component';
import Loading from './loading-screen';
import AlertBox from '../../common-component/alert-box';
interface Props {
    clearValue: (value: any) => void;
    gstView: (value: any) => void;
    setLoadingScreen: (value: any) => void;
    loadingScreen: any;

}

function GstApprovalMakePayment({ clearValue, gstView, setLoadingScreen, loadingScreen }: Props) {
    const [showType, setShowType] = useState("warning") // error message showType
    const [showMessage, setShowMessage] = useState<string>("") // error message showMessage
    const [showAlertBox, setShowAlertBox] = useState(false) // error message ShowAlertBox


    const clearAlert = () => {
        const timer = setTimeout(() => {
            clearValue(false)
            gstView("grid")
            setLoadingScreen(false)
            setShowAlertBox(false)
            clearTimeout(timer);
        }, 2000);
        
    }

    function handelOk() {
        setShowAlertBox(true)
        setShowType("success")
        setShowMessage("Payment Successfully Done.")
        setLoadingScreen(true)
        clearAlert()
    }

    return (
        <div>
            <div className={"department-add-modal-gst"}>
                <div className={"container-gst-approval"}>
                    <div className={"close-icon"}>
                        <span className={"cross-icon"}>
                            <RxCross1 onClick={() => {
                                clearValue(false)
                            }
                            } style={{ fontSize: "40px" }} />
                        </span>
                    </div>
                    <div className='main-wrapper-gst-approval'>
                        <div className='gst-content'>
                            <div style={{ fontWeight: "bold" }}>GST Payment request has been approved and notification has been sent</div>
                            <div>You can view the summary and status of your request in the payment page</div>
                        </div>
                        <div>
                            <ButtonComponent
                                title={"Ok"}
                                height={"50px"}
                                width={"120px"}
                                margin={"0px 8px"}
                                backgroundColor={"#0055D4"}
                                color={"white"}
                                className={"button-component-hover cancel"}
                                handleClick={handelOk}
                            />
                        </div>
                    </div>

                </div>
                {
                    loadingScreen &&
                    <Loading />
                }
                {
                    showAlertBox &&
                    <div className='alert-warp' style={{ zIndex: "20" }}>
                        <AlertBox type={showType} message={showMessage} />
                    </div>

                }

            </div>

        </div>
    )
}

export default GstApprovalMakePayment