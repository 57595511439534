import React, { useEffect, useRef, useState } from 'react'
import ButtonComponent from '../../../common-component/form-elements/button-component';
import LinkAccount from './link-account';
import IconButton from '../../../common-component/form-elements/icon-button';
import AgGrid from '../../../common-component/grids-and-tables/ag-grid';
import CustomEllipsisRenderer from "../../../../config/product-admin/users/users-aggrid-column-config"
import { axiosPrivate } from '../../../../middleware/axois-api';
import AlertBox from '../../../common-component/alert-box';
import ElectricityHistory from './electricity-details';
import DeleteModal from '../../../common-component/modals/delete-modal';
import { AgGridRef } from '../../vendor/bulk-payment-ag-grid';
import { mainElectricityConfig } from '../../../../config/electricity/electricity-board-config';
import { BsXLg } from 'react-icons/bs';

interface ElectricityProps { }

const Electricity: React.FC<ElectricityProps> = () => {

    const electricityRef = useRef<AgGridRef>(null);
    const [showType, setShowType] = useState("warning"); // error message showType
    const [showMessage, setShowMessage] = useState<string>(""); // error message showMessage
    const [showAlertBox, setShowAlertBox] = useState(false); // error message ShowAlertBox
    const [showDeleteModel, setShowDeleteModel] = useState<boolean>(false) //Delete Modal
    const [linkAccount, setLinkAccount] = useState<boolean>(false);
    const [editIndex, setEditIndex] = useState<number>();
    const [isAccountEditMode, setIsAccountEditMode] = useState<boolean>(false)
    const [deleteId, setDeleteId] = useState<number | any>();
    const [curruntData, setCurruntData] = useState<any>([]);
    const [electricityHistory, setElectricityHistory] = useState<boolean>(false);
    const [rowData, setRowData] = useState<any>([]);
    const [accountSummary, setAccountSummary] = useState<any>([]);
    const [electricityAccount, setElectricityAccount] = useState<any>({
        accountName: "",
        state: "",
        stateId: "",
        electricityBoard: "",
        electricityBoardId: "",
        isActive: "Yes",
        remarks: ""
    });

    const [boardinput, setBoardinput] = useState<any>([]);

    const EletrictiyColumnData = [
        {
            field: '', headerName: '', minWidth: 60, maxWidth: 60, resizable: false, suppressMovable: true, filter: "agSetColumnFilter", suppressSizeToFit: false, headerCheckboxSelection: true, checkboxSelection: true, showDisabledCheckboxes: true, suppressRowTransform: true, cellStyle: { textAlign: 'center' }
        },
        {
            field: 'serviceNumber', headerName: 'Service Number', minWidth: 200, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "number", cellRendererFramework: CustomEllipsisRenderer,
        },
        {
            field: 'consumerName', headerName: 'Consumer Name', minWidth: 200, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "number", cellRendererFramework: CustomEllipsisRenderer,
        },
        {
            field: 'billDate', headerName: 'Bill Date', minWidth: 200, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer,
        },
        {
            field: 'dueDate', headerName: 'Due Date', minWidth: 200, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer,
        },
        {
            field: 'requestedDate', headerName: 'Requested Date', minWidth: 200, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer,
        },
        {
            field: 'amount', headerName: 'Amount', minWidth: 200, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "number", cellRendererFramework: CustomEllipsisRenderer,
        },
        {
            field: 'approvalStatus', headerName: 'Approval Status', minWidth: 200, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellClassRules: {
                'success-cell': (params: any) => params.value === 'Approved',
                'registered-cell': (params: any) => params.value === 'Pending Approval',
                'denied-cell': (params: any) => params.value === 'Declined',
            }, cellRendererFramework: CustomEllipsisRenderer,
        },

        {
            field: 'boardProvider', headerName: 'Board/Provider', minWidth: 200, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer,
        },

    ]
    const rowDatathis = [
        {
            serviceNumber: "5465656654",
            consumerName: "FTKQ0001",
            billDate: "12-09-2023",
            dueDate: "10-10-2023",
            requestedDate: "8-10-2023",
            amount: "1000",
            approvalStatus: "Approved",
            boardProvider: "",

        },
        {
            serviceNumber: "5465656654",
            consumerName: "FTKQ0001",
            billDate: "12-09-2023",
            dueDate: "10-10-2023",
            requestedDate: "8-10-2023",
            amount: "1000",
            approvalStatus: "Approved",
            boardProvider: "",

        },
        {
            serviceNumber: "5465656654",
            consumerName: "FTKQ0001",
            billDate: "12-09-2023",
            dueDate: "10-10-2023",
            requestedDate: "8-10-2023",
            amount: "1000",
            approvalStatus: "Approved",
            boardProvider: "",

        },
        {
            serviceNumber: "5465656654",
            consumerName: "FTKQ0001",
            billDate: "12-09-2023",
            dueDate: "10-10-2023",
            requestedDate: "8-10-2023",
            amount: "1000",
            approvalStatus: "Approved",
            boardProvider: "",

        },

    ]

    // current data update
    const getCurruntData = (val: any) => {
        setCurruntData(val);
    }

    const clearAleart = () => {
        const timer = setTimeout(() => {
            setShowAlertBox(false);
            setShowMessage("");
            clearTimeout(timer);
        }, 5000);
    }

    useEffect(() => {
        getAccountGrid();
    }, []);

    // Account Details Get API
    const getAccountGrid = async () => {
        try {
            const response = await axiosPrivate.get('utility/electricity/get/account');
            console.log(response, "=====>")
            setAccountSummary(response.data.electricityAccounts);
        } catch (error) {
        }
    };

    //editAccount 
    const editAccount = async (value: any, index: number) => {
        let findFields = mainElectricityConfig.find((obj: any) => obj.providerBiller === value.electricityBoard);
        if (Array.isArray(findFields?.Inputs)) {
            setBoardinput(findFields?.Inputs);
        } else {
            setBoardinput([]);
        }
        setEditIndex(index);
        setElectricityAccount(value);
        // setBoardinput(value);
        setIsAccountEditMode(true);
        setLinkAccount(true);
    };

    // Delete Account API
    const deleteAccount = async () => {
        try {
            const response = await axiosPrivate.patch(`utility/electricity/delete/account/${deleteId}`);
            if (response.status === 200) {
                setShowDeleteModel(false)
                setShowAlertBox(true);
                setShowType("success");
                setShowMessage(response.data.message)
                getAccountGrid();
                clearAleart();
            }
        } catch (error: any) {
            setShowAlertBox(true);
            setShowType("danger");
            setShowMessage(error.response.data.error)
            clearAleart();
            setShowDeleteModel(false);
        }
    }

    return (
        <>
            <div className='utility-Electricity-main'>
                <div>
                    <div className='title-buttons'>
                        <div className='utility-grid-Title'>
                            <p>Account Summary</p>
                        </div>
                        <ButtonComponent
                            title={"Link Account"}
                            height={"45px"}
                            width={"150px"}
                            margin={"0px 8px"}
                            backgroundColor={"#0055D4"}
                            color={"white"}
                            className={"button-component common-btn"}
                            handleClick={() => {
                                setElectricityAccount({
                                    accountName: "",
                                    state: "",
                                    stateId: "",
                                    electricityBoard: "",
                                    electricityBoardId: "",
                                    consumerNumber: "",
                                    isActive: "Yes",
                                    remarks: ""
                                });
                                setBoardinput([]);
                                setLinkAccount(true)
                                setIsAccountEditMode(false)


                            }}
                        />
                    </div>
                    <div>
                        <div className='view-table-component admin-details-table' style={{ marginTop: "20px", overflowX: "auto", height: "275px" }}>
                            <table>
                                <thead>
                                    <th className='access left-align'>Account Name</th>
                                    <th className='access left-align'>State</th>
                                    <th className='access left-align' >Electricity Board</th>
                                    <th className='access left-align' >Service Number</th>
                                    <th className='access left-align' >Is Active</th>
                                    <th className='access left-align' >Remarks</th>
                                    <th className='access center-align' >Transaction History</th>
                                    <th className='access center-align' >Action</th>
                                </thead>
                                <tbody>
                                    {accountSummary?.length === 0 ?
                                        <tr className='main-body-header'>
                                            <td></td>
                                            <td></td>
                                            <td colSpan={2} style={{ textAlign: "center" }}>No records to show</td>
                                            <td></td>
                                            <td></td>
                                        </tr> :
                                        accountSummary[0]?.name !== "" &&
                                        accountSummary.map((data: any, index: number) => (
                                            <tr className='main-body-header' key={index} onClick={(e) => {
                                                const ele = e.target as HTMLElement;
                                                if (ele.tagName.toLocaleLowerCase() !== "svg" && ele.tagName.toLocaleLowerCase() != "g" && ele.className !== "icon-button") {

                                                }
                                            }}>
                                                <td className='first-name left-align'>{data.accountName}</td>
                                                <td className='left-align'>{data.state}</td>
                                                <td className='left-align'>{data.electricityBoard}</td>
                                                <td className='left-align'>{data.service_Number}</td>
                                                <td className='left-align'>{data.isActive}</td>
                                                <td className='left-align'>{data.remarks}</td>
                                                <td className='center-align' style={{ display: "flex", justifyContent: "center" }}>
                                                    <IconButton
                                                        iconName={"ShowEye"}
                                                        height={"35px"}
                                                        width={"30px"}
                                                        fontSize={""}
                                                        className={"icons"}
                                                        cursor={"pointer"}
                                                        color={"white"}
                                                        backgroundColor={""}
                                                        hover={false}
                                                        handleClick={() => {
                                                            setElectricityHistory(true)
                                                        }}
                                                    /></td>
                                                <td >
                                                    <div className='last-icon' style={{ justifyContent: "center" }}>
                                                        <IconButton
                                                            iconName={"Edit"}
                                                            height={"30px"}
                                                            width={"30px"}
                                                            fontSize={"25px"}
                                                            margin={"0px 8px"}
                                                            color={"white"}
                                                            backgroundColor={"#0055D4"}
                                                            hover={true}
                                                            handleClick={() => {
                                                                setLinkAccount(true)
                                                                setIsAccountEditMode(true)
                                                                editAccount(data, data.electricityId);
                                                            }}
                                                        />
                                                        <IconButton
                                                            iconName={"Delete"}
                                                            height={"30px"}
                                                            width={"30px"}
                                                            fontSize={"25px"}
                                                            margin={"0px 8px"}
                                                            color={"white"}
                                                            backgroundColor={"#D60000"}
                                                            hover={true}
                                                            handleClick={() => {
                                                                setShowDeleteModel(true)
                                                                setDeleteId(data.electricityId)
                                                            }}
                                                        />

                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div style={{ marginTop: "20px" }}>
                        <div className='utility-grid-Title'>
                            <p>Bill Details</p>
                        </div>
                        <div key={rowData} className='utility-eletricity-grid'>
                            <AgGrid

                                ref={electricityRef}
                                displaylength={10}
                                tableColumnData={EletrictiyColumnData}
                                tabelRowData={rowDatathis}
                                getCurruntData={(data: any) => {
                                    if (Array.isArray(data)) {
                                        getCurruntData(data)
                                    }
                                }}
                            />
                        </div>
                    </div>


                </div>
            </div>
            <div className='Eletrictiy-footer'>
                <ButtonComponent
                    title={"Submit for Approval"}
                    height={"50px"}
                    width={"200px"}
                    backgroundColor={"#0055D4"}
                    color={"white"}
                    margin={"0px 8px"}
                    className={curruntData.length === 0 ? "button-component-hover disabled" : "button-component-hover common-btn"}
                    handleClick={() => {
                        // clearValue(false)
                        // addRecords()

                    }}
                />
            </div>
            {
                linkAccount &&
                <LinkAccount
                    getAccountGrid={getAccountGrid}
                    isAccountEditMode={isAccountEditMode}
                    editIndex={editIndex}
                    setIsAccountEditMode={(val: boolean) => {
                        setIsAccountEditMode(val)
                    }}

                    electricityAccount={electricityAccount}
                    setElectricityAccount={(val: any) => {
                        setElectricityAccount(val)
                    }}

                    boardinput={boardinput}
                    setBoardinput={(val: any) => {
                        setBoardinput(val)
                    }}
                    clearValue={(value: any) => {
                        setLinkAccount(value)
                    }} />
            }
            {
                electricityHistory &&
                <ElectricityHistory
                    paymentHistory={[]}
                    clearValue={(value: any) => {
                        setElectricityHistory(value)
                    }}
                />
            }
            {
                showDeleteModel &&
                <div >
                    <DeleteModal
                        getDelete={() => {
                            setShowDeleteModel(false)
                            deleteAccount()
                        }}
                        clearValue={(value) => {
                            setShowDeleteModel(value)
                            setDeleteId(null)
                        }}
                        modelType={"grid-delete"}
                    />
                </div>
            }
            {
                showAlertBox &&
                <div className='alert-warp'>
                    <AlertBox type={showType} message={showMessage} />
                </div>
            }


        </>
    );
};

export default Electricity;