import { createContext, useState } from "react";
import { getCookie } from "../utils/cookie-utils";

interface AuthContextType {
  auth: any; // Update the 'any' type with the appropriate type for your 'auth' object
  setAuth: React.Dispatch<React.SetStateAction<any>>;
}

const AuthContext = createContext<AuthContextType>({
  auth: {},
  setAuth: () => { },
});

interface Props {
  children?: React.ReactNode;
}

export const AuthProvider = ({ children }: Props) => {
  const token = { token: getCookie("token") || "" };
  const [auth, setAuth] = useState<any>(token);

  return (
    <AuthContext.Provider value={{ auth, setAuth }}>
      {children}
    </AuthContext.Provider>
  )
}

export default AuthContext;