import React, { Component } from 'react'
import GstApprovalGrid from '../../components/approval/gst-approval/gst-approval-grid';
import GstApprovalForm from '../../components/approval/gst-approval/gst-approval-form';
import DirectTaxApprovalGrid from '../../components/approval/direct-tax-approval/direct-tax-approval-grid';
import DirectTaxForm from '../../components/payable/direct-tax/direct-tax-form';
import DirectTaxChooseCard from '../../components/approval/direct-tax-approval/direct-tax-choose-card';
import DirectTaxApprovalForm from '../../components/approval/direct-tax-approval/direct-tax-approval-form';


interface StateValues {
    gstView: string;
    isEditMode: boolean;
    transaction: string;
    makePaymentData: any;
    directTaxFormDatas: any;
    formDataById: any;
}
export default class DirectTaxApproval extends Component<{}, StateValues> {
    constructor(props: StateValues) {
        super(props);
        this.state = {
            gstView: "grid",
            isEditMode: false,
            transaction: "",
            makePaymentData: [],
            directTaxFormDatas: {},
            formDataById: [],
        };
    }
    addMode = () => {
        this.setState({
            isEditMode: false,
        })
    }
    render() {
        return (
            <div>
                {
                    this.state.gstView === "form" &&
                    <DirectTaxChooseCard
                        gstView={(val) => {
                            this.setState({ gstView: val })
                        }}
                        transaction={this.state.transaction}
                        makePaymentDatas={this.state.makePaymentData}
                    />
                    // <DirectTaxApprovalForm
                    //     gstView={(val) => {
                    //         this.setState({ gstView: val })
                    //     }}
                    //     transaction={this.state.transaction}
                    //     makePaymentDatas={this.state.makePaymentData}
                    // />
                }
                {
                    this.state.gstView === "grid" &&
                    <DirectTaxApprovalGrid
                        gstView={(val) => {
                            this.setState({ gstView: val })
                        }}
                        directTaxFormDatasFun={(val) => {
                            this.setState({
                                directTaxFormDatas: val,
                                gstView: "approval-form"
                            })
                        }}
                        formDataByIdFun={(val: any) => {
                            this.setState({
                                formDataById: val,
                                gstView: 'approval-form'
                            })
                        }}
                        makePaymentDataFun={(val) => {
                            this.setState({ makePaymentData: val })
                        }}
                    />
                }
                {
                    this.state.gstView === "approval-form" &&
                    <DirectTaxApprovalForm
                        title={"Approval"}
                        buttonNameValue={"Next"}
                        directTaxViewfun={(val: any) => { this.setState({ gstView: val }) }}
                        formDataById={this.state.formDataById}
                        makePaymentDataFun={(val:any) => {
                            this.setState({ 
                                makePaymentData: val,
                                gstView: 'form'
                             })
                        }}

                    />
                    // <DirectTaxForm
                    //     title={"Approval"}
                    //     buttonNameValue={"Next"}
                    //     directTaxViewfun={(val: any) => { this.setState({ gstView: val }) }}
                    //     formDataById={this.state.formDataById}

                    // />
                }
            </div>

        )
    }
}

