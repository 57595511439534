import React from 'react'

const BankDetails = () => {
  return (
    <div>
      BankDetails
      BankDetails
    </div>
  )
}

export default BankDetails
