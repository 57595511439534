import { useRef, useState } from 'react';
import '../../../styles/modals/file-upload-modal.scss';
import { RxCross1 } from 'react-icons/rx';
import ButtonComponent from '../form-elements/button-component';
import IconButton from '../form-elements/icon-button';
import { axiosPrivate } from '../../../middleware/axois-api';

interface Styles {
    headerData: any
    templateName: string
    apiUrl?: string
    setshowUploadMessage?: any
    template?: string
    excelFileName?: string
    clearValue: (val: any) => void;
    getUploadedUserData: (val: any) => void;
    getDelete?: () => void;
    afterFileDownload?: (val: any) => void;
    loadingScreenFunction?: (value: boolean) => void;
    gridUpdate?: (val: any) => void;
}

export default function FileUpload({
    setshowUploadMessage,
    loadingScreenFunction,
    clearValue,
    apiUrl,
    gridUpdate,
    getDelete,
    headerData,
    templateName,
    getUploadedUserData,
    afterFileDownload,
    template,
    excelFileName
}: Styles) {
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [excelfilename, setExcelFileName] = useState("");
    const XLSX = require('sheetjs-style');

    const downloadExcelTemplate = async () => {
        clearValue(false);
        try {
            const response = await axiosPrivate.post(`/bulk-upload/template/get`, { document: template }, {
                responseType: "blob", // Use responseType "blob" to receive binary data
            });

            const contentType = response.headers['content-type'];
            const blob = new Blob([response.data], { type: contentType });

            const [folderName, fileName]: any = template?.split("/")

            // Create an anchor element and initiate the download
            const downloadLink = document.createElement('a');

            downloadLink.href = URL.createObjectURL(blob);
            downloadLink.download = fileName; // Set the filename dynamically
            downloadLink.style.display = 'none'; // Hide the link
            document.body.appendChild(downloadLink);
            downloadLink.click();

            // Clean up the URL.createObjectURL to release resources
            URL.revokeObjectURL(downloadLink.href);

            // Remove the anchor element from the document
            document.body.removeChild(downloadLink);
        } catch (error) {
            console.error('Error while downloading the file:', error);
        }
    };

    const dragOver = (e: any) => {
        e.preventDefault();
    }

    const dragEnter = (e: any) => {
        e.preventDefault();
    }

    const dragLeave = (e: any) => {
        e.preventDefault();
    }

    const fileDrop = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        let fileObj;
        if (e.dataTransfer) {
            const { files } = e.dataTransfer;
            fileObj = files[0];
            setExcelFileName(fileObj);
            fileHandler(fileObj, true)
        } else {
            fileObj = e.target.files[0];
            setExcelFileName(fileObj);
        }
    }

    const handleClickExcel = () => {
        fileInputRef.current!.click();
    };

    const fileHandler = async (e: any, drag: any) => {
        let fileObj: any = "";
        if (drag) {
            fileObj = e;
            setExcelFileName(fileObj);
        } else {
            e.preventDefault();
            fileObj = e.target.files[0];
            setExcelFileName(fileObj);
        }
        const allowedFormats = [
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // XLSX
            "application/vnd.ms-excel", // XLS
            // "application/.xlsx", // XLSX
            // "application/.xls", // XLS
            "text/csv" // CSV
        ];
        const validFormat = allowedFormats.includes(fileObj?.type);
        if (!validFormat) {
            setshowUploadMessage("Unsupported file format. Upload XLSX or XLS or CSV file format.", "warning")
            return;
        }
        if (fileObj.size > 2000000) {
            fileInputRef.current!.value = '';
            setshowUploadMessage("File size exceeds the maximum 2MB. Compress the file and upload again.", "warning")
            return;
        }

        const formData = new FormData();
        formData.append("xlsxFile", fileObj)
        // setLoadingScreen(true) 
        clearValue(false);
        if (apiUrl) {
            loadingScreenFunction && loadingScreenFunction(true)
            try {
                const response = await axiosPrivate.post(apiUrl, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                })
                if (response.status === 200) {
                    loadingScreenFunction && loadingScreenFunction(false)
                    if (apiUrl === "/bulk-upload/vendor-payment") {
                        afterFileDownload && afterFileDownload(response.data)
                        generateExcel(response.data.data);
                    } else {
                        gridUpdate && gridUpdate(true)
                        setshowUploadMessage(response.data.message, response.data.type)
                        generateExcel(response.data.data);
                    }
                }
            } catch (error) {
                console.log('Error:', error);
                loadingScreenFunction && loadingScreenFunction(false)
            }
        };
    }

    const readFileAsText = (file: any) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
                resolve(reader.result);
            };
            reader.onerror = () => {
                reject(reader.error);
            };
            reader.readAsText(file);
        });
    };

    const generateExcel = (data: any, fileName = `${excelFileName}.xlsx`) => {
        const headings = Object.keys(data[0]);

        // Create a new worksheet
        const worksheet = XLSX.utils.json_to_sheet(data);

        // Set custom column widths
        const columnWidths: any = [];
        let statusWidth = { wch: 60 };
        let emailWidth = { wch: 30 };
        let defaultWidth = { wch: 20 };

        headings.forEach((heading) => {
            if (heading === 'Status') {
                statusWidth = { wch: 60 }; // Update custom width for 'Status'
            } else if (heading === 'Email') {
                emailWidth = { wch: 30 }; // Update default width for 'Email'
            } else {
                columnWidths.push(defaultWidth); // Push default width for other columns
            }
        });

        // Push 'Status' and 'Email' widths after processing other columns
        columnWidths.push(emailWidth);
        columnWidths.push(statusWidth);

        const ThinBorder = {
            top: { style: "thin" },
            bottom: { style: "thin" },
            left: { style: "thin" },
            right: { style: "thin" }
        };

        // Apply styles for header row (background color)
        worksheet['!cols'] = columnWidths;
        worksheet['!rows'] = [{ hpx: 20 }]; // Row height
        headings.forEach((heading, index) => {
            const cellAddress = XLSX.utils.encode_cell({ r: 0, c: index }); // Header cell address
            const cell = worksheet[cellAddress];
            cell.s = {
                font: { bold: true },
                border: ThinBorder,
                alignment: { horizontal: 'center' },
                fill: {
                    type: 'pattern',
                    patternType: 'solid',
                    fgColor: { rgb: "e8f0f8" }
                }
            }; // Header style
        });


        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

        // Create a blob from the workbook
        const blob = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });

        // Create a download link
        const blobData = new Blob([s2ab(blob)], { type: 'application/octet-stream' });
        const url = window.URL.createObjectURL(blobData);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();

        // Clean up
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
    };

    function s2ab(s: any) {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i < s.length; i++) {
            view[i] = s.charCodeAt(i) & 0xFF;
        }
        return buf;
    }

    return (
        <div className={"file-upload-modal"}>
            <div className={"container"}>
                <div className={"close-icon"}>
                    <span className={"cross-icon"}>
                        <RxCross1 onClick={() => clearValue(false)
                        } style={{ fontSize: "40px" }} />
                    </span>
                </div>
                {
                    excelFileName !== "User-BulkUpload-Status" &&
                    < div style={{ fontWeight: "bold" }}>
                        {"Maximum 10 records only uploaded at a time"}
                    </div>
                }
                <div className='sub-container'>

                    <div className='upload'
                        style={{ margin: '0px 10px' }}
                        onDragOver={dragOver}
                        onDragEnter={dragEnter}
                        onDragLeave={dragLeave}
                        onDrop={fileDrop}
                        onClick={handleClickExcel}>
                        <input
                            type='file'
                            ref={fileInputRef}
                            onChange={(e: any) => { fileHandler(e, false) }}
                            style={{ display: 'none' }}
                            accept='.xls,.xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel'
                        />
                        <IconButton
                            iconName={"Upload"}
                            height={"40px"}
                            width={"40px"}
                            fontSize={"48px"}
                            margin={"0px 0px 0px 8px"}
                            color={"#0055D4"}
                            backgroundColor={"white"}
                            hover={false}
                            handleClick={() => {
                                // setFileUpload(true);
                            }}
                        />
                        <div style={{ margin: '10px 5px 0px 5px' }}>Drag and Drop file</div>
                        <div style={{ margin: '0px 0px 10px 5px' }}>or</div>
                        <div className={"buttons"}>
                            <ButtonComponent
                                title={"Browse"}
                                height={"40px"}
                                width={"130px"}
                                backgroundColor={"#0055D4"}
                                color={"white"}
                                margin={"0px 8px"}
                                className={"button-component common-btn"}
                                handleClick={() => {
                                }}
                            />
                        </div>
                    </div>
                    <div className='template' onClick={downloadExcelTemplate}>
                        <IconButton
                            iconName={"Download"}
                            height={"40px"}
                            width={"40px"}
                            fontSize={"48px"}
                            margin={"0px 8px"}
                            color={"#0055D4"}
                            backgroundColor={"white"}
                            hover={false}
                            handleClick={() => { }}
                        />
                        <div style={{ margin: '10px 5px 0px 5px' }}>Download Template</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

// Add sample data rows (modify this part to add your actual data)
// const data = [
//   ['12345', 'ABC Inc.', 'Category A'],
//   ['67890', 'XYZ Corp.', 'Category B'],
//   // Add more data rows as needed
// ];

// data.forEach((rowData) => {
//   const row = document.createElement('tr');
//   rowData.forEach((cellData) => {
//     const cell = document.createElement('td');
//     cell.textContent = cellData;
//     cell.style.border = '1px solid #000';
//     row.appendChild(cell);
//   });
//   table.appendChild(row);
// });

// const generateExcel = (data: any, fileName = 'data.xlsx') => {
//     // Extract column headings from the first data item
//     const headings = Object.keys(data[0]);

//     // Create an HTML table
//     const table = document.createElement('table');
//     table.style.borderCollapse = 'collapse';

//     // Create a table row for the headings
//     const headerRow = document.createElement('tr');
//     headings.forEach((heading) => {
//         const th = document.createElement('th');
//         th.textContent = heading;
//         th.style.backgroundColor = 'gray'; // Set the background color to gray
//         th.style.width = heading === 'Status' ? '500px' : '200px'; // Set custom widths
//         headerRow.appendChild(th);
//         headerRow.appendChild(th);
//     });
//     table.appendChild(headerRow);

//     data.forEach((rowData: any) => {
//         const row = document.createElement('tr');
//         headings.forEach((heading) => {
//             const cell = document.createElement('td');
//             cell.textContent = rowData[heading];
//             cell.style.border = '1px solid red';
//             row.appendChild(cell);
//         });
//         table.appendChild(row);
//     });

//     // Create a blob from the HTML table
//     const blob = new Blob([table.outerHTML], {
//         type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
//     });

//     // Create a download link
//     const url = window.URL.createObjectURL(blob);
//     const a = document.createElement('a');
//     a.href = url;
//     a.download = fileName;
//     document.body.appendChild(a);
//     a.click();

//     // Clean up
//     window.URL.revokeObjectURL(url);
//     document.body.removeChild(a);
// };

