

export let providerConfig = [
    {
        "providerBiller": "Adani Electricity Mumbai Limited",
        "ProviderCode": 1,
        "State": "Maharashtra",
        "Inputs ": [{
            title: "Account Number",
            fieldName: "account_Number",
            fieldValidation: "Please enter your 9 digit Account Number",
            maxlength: 9,
            placeHolder: "",
            required: true
        }],
        "errorResponse": "Incorrect Consumer ID entered"
    },
    {
        "providerBiller": "Ajmer Vidyut Vitran Nigam Limited (AVVNL)",
        "ProviderCode": 2,
        "State": "Rajasthan",
        "Inputs ": "1.K number",
        "fieldValidation": "Please enter your 12 digit K Number.",
        "errorResponse": "Please enter a valid K Number"
    },
    {
        "providerBiller": "APSPDCL-Eastern Power Distribution CO AP Ltd",
        "ProviderCode": 3,
        "State": "Andhra Pradesh",
        "Inputs ": "1.Service Number",
        "fieldValidation": "Please enter your 16 digit Service Number",
        "errorResponse": "Consumer Does not Exists"
    },
    {
        "providerBiller": "APSPDCL-Southern Power Distribution CO AP Ltd",
        "ProviderCode": 4,
        "State": "Andhra Pradesh",
        "Inputs ": "1.Service Number",
        "fieldValidation": "Please enter your 13 digit Service Number"
    },
    {
        "providerBiller": "Assam Power Distribution Company Ltd (NON-RAPDR)",
        "ProviderCode": 5,
        "State": "Assam",
        "Inputs ": " 2. Consumer Number",
        "fieldValidation": "Please enter your 12 digit Consumer Number",
        "errorResponse": "It seems the details that you entered are wrong. Please enter correct details and try again."
    },
    {
        "providerBiller": "B.E.S.T Mumbai",
        "ProviderCode": 6,
        "State": "Maharashtra",
        "Inputs ": "1.Consumer Number",
        "fieldValidation": "Please enter your valid Consumer Number",
        "errorResponse": "We are not able to get your bill details from Brihanmumbai Electricity Supply and Transport Undertaking (BEST Undertaking). Please try again in a while"
    },
    {
        "providerBiller": "Bangalore Electricity Supply Co. Ltd (BESCOM)",
        "ProviderCode": 7,
        "State": "Karnataka",
        "Inputs ": "1. Customer Id Account Id",
        "fieldValidation": "Please enter your Customer Id Account Id",
        "errorResponse": "Please enter a valid Customer Id Account Id."
    },
    {
        "providerBiller": "Bharatpur Electricity Services Ltd. (BESL)",
        "ProviderCode": 8,
        "State": "Rajasthan",
        "Inputs ": "1.K number",
        "fieldValidation": "Please enter your 12 digit K Number.",
        "errorResponse": "Please enter a valid K Number"
    },
    {
        "providerBiller": "Bikaner Electricity Supply Limited (BkESL)",
        "ProviderCode": 9,
        "State": "Rajasthan",
        "Inputs ": "1.K number",
        "fieldValidation": "Please enter your 12 digit K Number.",
        "errorResponse": "It seems the details that you entered are wrong. Please enter correct details and try again."
    },
    {
        "providerBiller": "BSES Rajdhani Power Limited",
        "ProviderCode": 10,
        "State": "Delhi",
        "Inputs ": "1. CA Number",
        "fieldValidation": "Please enter your 9 digit CA Number "
    },
    {
        "providerBiller": "BSES Rajdhani Prepaid Meter Recharge",
        "ProviderCode": 11,
        "State": "Delhi",
        "Inputs ": " 1. CA Number 2. Amount",
        "fieldValidation": "Please enter your 9 digit CA Number "
    },
    {
        "providerBiller": "BSES Yamuna Power Limited",
        "ProviderCode": 12,
        "State": "Delhi",
        "Inputs ": " 1. CA Number",
        "fieldValidation": "Please enter your 9 digit CA Number"
    },
    {
        "providerBiller": "BSES Yamuna Prepaid Meter Recharge",
        "ProviderCode": 13,
        "State": "Delhi",
        "Inputs ": " 1. CA Number 2. Amount",
        "fieldValidation": "Please enter your 9 digit CA Number"
    },
    {
        "providerBiller": "CESC Limited",
        "ProviderCode": 14,
        "State": "West Bengal",
        "Inputs ": "1. Customer Id",
        "fieldValidation": "Please enter your 11 digit Customer Id"
    },
    {
        "providerBiller": "Chamundeshwari Electricity Supply Corp Ltd (CESCOM)",
        "ProviderCode": 15,
        "State": "Karnataka",
        "Inputs ": "1. Account ID Connection IDConsumer number",
        "fieldValidation": "Enter 10 digit Account IDConsumer NumberConnection ID",
        "errorResponse": "It seems the details that you entered are wrong. Please enter correct details and try again"
    },
    {
        "providerBiller": "Chhattisgarh State Power Distribution Co. Ltd",
        "ProviderCode": 16,
        "State": "Chhattisgarh",
        "Inputs ": "1. Business Partner Number",
        "fieldValidation": "Please enter your 10 digit BP Number ",
        "errorResponse": "Please enter a valid BP Number."
    },
    {
        "providerBiller": "Dadra and Nagar Haveli and Daman and Diu Power Distribution Corporation Limited",
        "ProviderCode": 17,
        "State": "National",
        "Inputs ": "1. CA Number",
        "fieldValidation": "Please enter your CANumber"
    },
    {
        "providerBiller": "Dakshin Gujarat Vij Company Limited (DGVCL)",
        "ProviderCode": 18,
        "State": "Gujarat",
        "Inputs ": "1. Consumer Number.",
        "fieldValidation": "Please enter your 5 or 11 digit Consumer Number.",
        "errorResponse": "It seems the details that you entered are wrong. Please enter correct details and try again"
    },
    {
        "providerBiller": "Dakshin Gujarat Vij Company Limited (DGVCL)-Fetch and pay",
        "ProviderCode": 19,
        "State": "Gujarat",
        "fieldValidation": "NO 19 & 20 ARE SIMILAR"
    },
    {
        "providerBiller": "Dakshin Haryana Bijli Vitran Nigam (DHBVN)",
        "ProviderCode": 20,
        "State": "Haryana",
        "Inputs ": "1. Account Number 2. Mobile Number",
        "fieldValidation": "Enter your 9 to 12 character long Account Number;  Please enter your 10 digit Mobile number"
    },
    {
        "providerBiller": "Dakshinanchal Vidyut Vitran Nigam Limited (DVVNL)(Postpaid and Smart Prepaid Meter Recharge)",
        "ProviderCode": 21,
        "State": "Uttar Pradesh",
        "Inputs ": "1. Consumer Number",
        "fieldValidation": "Please enter your Consumer Number",
        "errorResponse": "Please enter a valid Consumer Number."
    },
    {
        "providerBiller": "Daman and Diu Electricity",
        "ProviderCode": 22,
        "State": "Daman and DIU",
        "Inputs ": "1. CA Number",
        "fieldValidation": "Please enter your CANumber"
    },
    {
        "providerBiller": "Department of Power, Government of Arunachal Pradesh",
        "ProviderCode": 23,
        "State": "Arunachal Pradesh",
        "Inputs ": " 1. Consumer Number",
        "fieldValidation": "Please enter your 11 digit Consumer Number",
        "errorResponse": "It seems the details that you entered are wrong. Please enter correct details and try again."
    },
    {
        "providerBiller": "Department of Power, Government of Arunachal Pradesh - Prepaid",
        "ProviderCode": 24,
        "State": "Arunachal Pradesh",
        "Inputs ": " 1. Consumer Number",
        "fieldValidation": "Please enter your 11 digit Consumer Number",
        "errorResponse": "It seems the details that you entered are wrong. Please enter correct details and try again."
    },
    {
        "providerBiller": "Department of Power, Nagaland",
        "ProviderCode": 25,
        "State": "Nagaland",
        "Inputs ": "1. Consumer Number",
        "fieldValidation": "Please enter your 11 digit Consumer number"
    },
    {
        "providerBiller": "DNH Power Distribution Company Limited",
        "ProviderCode": 26,
        "State": "Dadra and Nagar Haveli",
        "Inputs ": "1. CA Number",
        "fieldValidation": "Please enter your CANumber",
        "errorResponse": "Please enter a valid CANumber"
    },
    {
        "providerBiller": "Electricity Department Chandigarh",
        "ProviderCode": 27,
        "State": "Chandigarh",
        "Inputs ": "1.Account Number without ()",
        "fieldValidation": "Account No : AAABBBCCCCCCC should be entered as BBBCCCCCCC",
        "errorResponse": "It seems the details that you entered are wrong. Please enter correct details and try again."
    },
    {
        "providerBiller": "Gift Power Company Limited",
        "ProviderCode": 28,
        "State": "Gujarat",
        "Inputs ": "1. Consumer Number",
        "fieldValidation": "Please enter your valid Consumer Number",
        "errorResponse": "We are not able to get your bill details from Gift Power Company Limited. Please try again in a while."
    },
    {
        "providerBiller": "Goa Electricity Department",
        "ProviderCode": 29,
        "State": "Goa",
        "Inputs ": "1. Contract Account No.",
        "fieldValidation": "Please enter your 11 digit Contract Account Numbe",
        "errorResponse": "It seems the details that you entered are wrong. Please enter correct details and try again."
    },
    {
        "providerBiller": "Government of Puducherry Electricity Department",
        "ProviderCode": 30,
        "State": "Puducherry",
        "Inputs ": " 1. Consumer Number 2.Consumer type dd(LT,HT)",
        "fieldValidation": "Please enter your 9-12 character Consumer Number"
    },
    {
        "providerBiller": "Gulbarga Electricity Supply Company Limited",
        "ProviderCode": 31,
        "State": "Karnataka",
        "Inputs ": "1.Consumer No.",
        "fieldValidation": "1.Account ID (in paytm) also"
    },
    {
        "providerBiller": "Himachal Pradesh State Electricity Board",
        "ProviderCode": 32,
        "State": "Himachal Pradesh",
        "Inputs ": "1. Consumer Number",
        "fieldValidation": "Please enter your 12 digit Consumer Id.",
        "errorResponse": "It seems the details that you entered are wrong. Please enter correct details and try again."
    },
    {
        "providerBiller": "Hubli Electricity Supply Company Ltd (HESCOM)",
        "ProviderCode": 33,
        "State": "Karnataka",
        "Inputs ": "Account IDConnection ID Consumer Number",
        "fieldValidation": "Enter 5-10 digit Account IDConsumer NumberConnection ID"
    },
    {
        "providerBiller": "Hukkeri Rural Electric CoOperative Society Ltd",
        "ProviderCode": 34,
        "State": "Karnataka",
        "Inputs ": "1.Connection Number",
        "fieldValidation": "Please enter your Connection Number.",
        "errorResponse": "It seems the details that you entered are wrong. Please enter correct details and try again."
    },
    {
        "providerBiller": "India Power Corporation Limited (IPCL)",
        "ProviderCode": 35,
        "State": "West Bengal",
        "Inputs ": "1.Consumer Number  Meter Number",
        "fieldValidation": "Please enter your 7 digit Consumer Number or 11 digit Meter Number"
    },
    {
        "providerBiller": "Jaipur Vidyut Vitran Nigam (JVVNL)",
        "ProviderCode": 36,
        "State": "Rajasthan",
        "Inputs ": "1.  K Number",
        "fieldValidation": "Please enter your 12 digit K Number.",
        "errorResponse": "It seems the details that you entered are wrong. Please enter correct details and try again."
    },
    {
        "providerBiller": "Jammu and Kashmir Power Development Department",
        "ProviderCode": 37,
        "State": "Jammu And Kashmir",
        "Inputs ": "1. Consumer Code",
        "fieldValidation": "Please enter your valid Consumer Code",
        "errorResponse": "Please enter a valid Consumer Code"
    },
    {
        "providerBiller": "Jamshedpur Utilities",
        "ProviderCode": 38,
        "State": "Jharkhand",
        "Inputs ": "1. Business Partner Number",
        "fieldValidation": "Please enter your 6 to 10 digit Business Partner Number",
        "errorResponse": "It seems the details that you entered are wrong. Please enter correct details and try again."
    },
    {
        "providerBiller": "Jharkhand Bijli Vitran Nigam Limited (JBVNL)",
        "ProviderCode": 39,
        "State": "Jharkhand",
        "Inputs ": "1. Consumer Number  2.subdivision (dropdown)",
        "fieldValidation": "Please enter your Consumer Number without special characters and spaces. ",
        "errorResponse": "There seems to be some issue at Jharkhand Bijli Vitran Nigam Limited (JBVNL)'s end. Please try after some time."
    },
    {
        "providerBiller": "Jharkhand Bijli Vitran Nigam Limited - Prepaid Meter Recharge",
        "ProviderCode": 40,
        "State": "National",
        "Inputs ": "1. Meter number  Account number",
        "fieldValidation": "Please enter your Consumer Number without special characters and spaces."
    },
    {
        "providerBiller": "Jodhpur Vidyut Vitran Nigam Limited (JDVVNL)",
        "ProviderCode": 41,
        "State": "Rajasthan",
        "Inputs ": "1. K Number",
        "fieldValidation": "Please enter your 12 digit K Number.",
        "errorResponse": "Please enter a valid K Number"
    },
    {
        "providerBiller": "Kanan Devan Hills Plantations Company Private Limited",
        "ProviderCode": 42,
        "State": "Kerala",
        "Inputs ": "1. Consumer Number",
        "fieldValidation": "Please enter your 1 to 8 digit Consumer Number",
        "errorResponse": "There seems to be some issue at Kannan Devan Hills Plantations Company Private Limited's end. Please try after some time."
    },
    {
        "providerBiller": "Kanpur Electricity Supply Company",
        "ProviderCode": 43,
        "State": "Uttar Pradesh",
        "Inputs ": "1. Account Number",
        "fieldValidation": "Please enter your 5 to 10 digit Account Number",
        "errorResponse": "It seems the details that you entered are wrong. Please enter correct details and try again."
    },
    {
        "providerBiller": "Kerala State Electricity Board Ltd. (KSEBL)",
        "ProviderCode": 44,
        "State": "Kerala",
        "Inputs ": "1. Consumer Number",
        "fieldValidation": "Please enter your 13 digit Consumer Number ",
        "errorResponse": "It seems the details that you entered are wrong. Please enter correct details and try agai"
    },
    {
        "providerBiller": "Kota Electricity Distribution Limited (KEDL)",
        "ProviderCode": 45,
        "State": "Rajasthan",
        "Inputs ": " 1. K Number",
        "fieldValidation": "Please enter your 12 digit K Number.",
        "errorResponse": "Please enter a valid K Number"
    },
    {
        "providerBiller": "Lakshadweep Electricity Department",
        "ProviderCode": 46,
        "State": "Lakshadweep",
        "Inputs ": "1.Consumer Number",
        "fieldValidation": "Please enter your Consumer Number",
        "errorResponse": "Please enter a valid Consumer Number."
    },
    {
        "providerBiller": "M.P. Madhya Kshetra Vidyut Vitaran - RURAL",
        "ProviderCode": 47,
        "State": "Madhya Pradesh",
        "Inputs ": "1. IVRS IDConsumer Number",
        "fieldValidation": "Please enter your 7 to 15 digit IVRS Number",
        "errorResponse": "It seems the details that you entered are wrong. Please enter correct details and try again."
    },
    {
        "providerBiller": "M.P. Madhya Kshetra Vidyut Vitaran - URBAN",
        "ProviderCode": 48,
        "State": "Madhya Pradesh",
        "Inputs ": " 1. IVRS Number",
        "fieldValidation": "Please enter your 7 to 16 digit IVRS Number",
        "errorResponse": "It seems the details that you entered are wrong. Please enter correct details and try again."
    },
    {
        "providerBiller": "M.P. Paschim Kshetra Vidyut Vitaran",
        "ProviderCode": 49,
        "State": "Madhya Pradesh",
        "Inputs ": "1. IVRS Number",
        "fieldValidation": "Please enter your 11 digit IVRS Number starting with 'N' ",
        "errorResponse": "It seems the details that you entered are wrong. Please enter correct details and try again."
    },
    {
        "providerBiller": "M.P. Poorv Kshetra Vidyut Vitaran - RURAL",
        "ProviderCode": 50,
        "State": "Madhya Pradesh",
        "Inputs ": "1. IVRS IDConsumer Number",
        "fieldValidation": "Please enter your Consumer NumberIVRS Number",
        "errorResponse": "Please enter a valid Consumer NumberIVRS"
    },
    {
        "providerBiller": "Madhya Gujarat Vij Company Limited (MGVCL)",
        "ProviderCode": 51,
        "State": "Gujarat",
        "Inputs ": "1.Consumer Number",
        "fieldValidation": "Please enter your 5 or 11 digit Consumer Number.",
        "errorResponse": "It seems the details that you entered are wrong. Please enter correct details and try again."
    },
    {
        "providerBiller": "Madhya Gujarat Vij Company Limited (MGVCL)-Fetch and pay",
        "ProviderCode": 52,
        "State": "Gujarat"
    },
    {
        "providerBiller": "Madhyanchal Vidyut Vitran Nigam Limited (MVVNL)(Postpaid and Smart Prepaid Meter Recharge)",
        "ProviderCode": 53,
        "State": "Uttar Pradesh",
        "Inputs ": "1. Consumer Number",
        "fieldValidation": "Please enter your Consumer Number",
        "errorResponse": "Please enter a valid Consumer Number."
    },
    {
        "providerBiller": "Maharashtra State Electricity Distbn Co Ltd",
        "ProviderCode": 54,
        "State": "Maharashtra",
        "Inputs ": "1.Consumer Number 2.Sub Divison code",
        "fieldValidation": "Please enter your 12 digit Consumer Number",
        "errorResponse": "We are not able to get your bill details from MSEDCL. Please try again in a while."
    },
    {
        "providerBiller": "Mangalore Electricity Supply Co. Ltd (MESCOM) - RAPDR",
        "ProviderCode": 55,
        "State": "Karnataka",
        "Inputs ": " 1. Account ID",
        "fieldValidation": "Please enter your valid Account ID",
        "errorResponse": "Please enter your valid Account ID"
    },
    {
        "providerBiller": "Mangalore Electricity Supply Company LTD (Non RAPDR)",
        "ProviderCode": 56,
        "State": "Karnataka",
        "Inputs ": "1. RR Number",
        "fieldValidation": "Please enter your 5 to 25 character long RR Number",
        "errorResponse": "It seems the details that you entered are wrong. Please enter correct details and try again."
    },
    {
        "providerBiller": "Meghalaya Power Dist Corp Ltd",
        "ProviderCode": 57,
        "State": "Meghalaya",
        "Inputs ": "1. Consumer ID",
        "fieldValidation": "Please enter your Consumer ID",
        "errorResponse": "Please enter a valid Consumer ID"
    },
    {
        "providerBiller": "MePDCL Smart Prepaid Meter Recharge",
        "ProviderCode": 58,
        "State": "National (Meghalaya)",
        "Inputs ": "1.Account Number",
        "fieldValidation": "Please enter your ACCOUNT_NO"
    },
    {
        "providerBiller": "New Delhi Municipal Council (NDMC) - Electricity",
        "ProviderCode": 59,
        "State": "Delhi",
        "Inputs ": "1. Consumer Number",
        "fieldValidation": "Please enter 8 digit numeric Consumer No. as mentioned in your physical bill."
    },
    {
        "providerBiller": "Noida Power",
        "ProviderCode": 60,
        "State": "Uttar Pradesh",
        "Inputs ": "1. Consumer Number",
        "fieldValidation": "Please enter your 10 digit Consumer Number",
        "errorResponse": "The details entered seem to be invalid. Please re-enter the correct details and try again."
    },
    {
        "providerBiller": "North Bihar Power Distribution Company Ltd.",
        "ProviderCode": 61,
        "State": "Bihar",
        "Inputs ": "1. CA Number",
        "fieldValidation": "Please enter your 9 to 12 digit CA Number",
        "errorResponse": "Please enter a valid CA Number. "
    },
    {
        "providerBiller": "Paschim Gujarat Vij Company Limited (PGVCL)",
        "ProviderCode": 62,
        "State": "Gujarat",
        "Inputs ": "1. Consumer Number",
        "fieldValidation": "Please enter your 5 or 11 digit Consumer Number.",
        "errorResponse": "It seems the details that you entered are wrong. Please enter correct details and try again."
    },
    {
        "providerBiller": "Paschim Gujarat Vij Company Limited (PGVCL)-Fetch and pay",
        "ProviderCode": 63,
        "State": "Gujarat"
    },
    {
        "providerBiller": "Paschimanchal Vidyut Vitran Nigam Limited (PVVNL)(Postpaid and Smart Prepaid Meter Recharge)",
        "ProviderCode": 64,
        "State": "Uttar Pradesh",
        "Inputs ": "1. Consumer Number",
        "fieldValidation": "Please enter your Consumer Number",
        "errorResponse": "It seems the details that you entered are wrong. Please enter correct details and try again."
    },
    {
        "providerBiller": "Power and Electricity Department - Mizoram",
        "ProviderCode": 65,
        "State": "Mizoram",
        "Inputs ": "1. Business Partner",
        "fieldValidation": "Please enter your 8 digit Business Partner"
    },
    {
        "providerBiller": "Punjab State Power Corporation Ltd (PSPCL)",
        "ProviderCode": 66,
        "State": "Punjab",
        "Inputs ": "1. Account Number",
        "fieldValidation": "Please enter your 10-12 character Account Number"
    },
    {
        "providerBiller": "Purvanchal Vidyut Vitran Nigam Limited(PUVVNL)(Postpaid and Smart Prepaid Meter Recharge)",
        "ProviderCode": 67,
        "State": "Uttar Pradesh",
        "Inputs ": "1. Consumer Number",
        "fieldValidation": "Please enter your Consumer Number"
    },
    {
        "providerBiller": "Sikkim Power - RURAL",
        "ProviderCode": 68,
        "State": "Sikkim",
        "Inputs ": " 1. Contract Account Number",
        "fieldValidation": "Please enter your Contract Account Number",
        "errorResponse": "Please enter a valid Contract Account Number."
    },
    {
        "providerBiller": "Sikkim Power - URBAN",
        "ProviderCode": 69,
        "State": "Sikkim",
        "Inputs ": " 1. Contract Account Number",
        "fieldValidation": "Please enter your Contract Account Number",
        "errorResponse": "Please enter a valid Contract Account Number."
    },
    {
        "providerBiller": "South Bihar Power Distribution Company Ltd.",
        "ProviderCode": 70,
        "State": "Bihar",
        "Inputs ": "1. CA Number",
        "fieldValidation": "Please enter your 9 to 12 digit CA Number",
        "errorResponse": "Please enter a valid CA Number. "
    },
    {
        "providerBiller": "Spurt Electric Limited",
        "ProviderCode": 71,
        "State": "Gujarat"
    },
    {
        "providerBiller": "Tamil Nadu Electricity Board (TNEB)",
        "ProviderCode": 72,
        "State": "Tamil Nadu",
        "Inputs ": "1. Consumer Number",
        "fieldValidation": "Please enter your Consumer Number starting with regional code followed by section, distribution and service number. Note - Please read disclaimer",
        "errorResponse": "Please enter a valid Consumer Number."
    },
    {
        "providerBiller": "Tata Power - DDL",
        "State": "Delhi",
        "Inputs ": "1. Contract Account Number",
        "fieldValidation": "Please enter your 11-12 digit CA Number"
    },
    {
        "providerBiller": "Tata Power - Mumbai",
        "ProviderCode": 73,
        "State": "Maharashtra",
        "Inputs ": "1.Consumer Number",
        "fieldValidation": "Please enter your 12 digit Consumer Number",
        "errorResponse": "It seems the details that you entered are wrong. Please enter correct details and try again."
    },
    {
        "providerBiller": "Thrissur Corporation Electricity Department",
        "ProviderCode": 74,
        "State": "Kerala",
        "Inputs ": "1. Consumer Number 2. Bill Number 3.Bill type (Monthly  bimonthly ) dropdown ",
        "fieldValidation": "Please enter your valid Consumer Number ",
        "errorResponse": "It seems the details that you entered are wrong. Please enter correct details and try agai"
    },
    {
        "providerBiller": "Torrent Power",
        "ProviderCode": 75,
        "State": "National (Uttar Pradesh)",
        "Inputs ": " 1. Service Number",
        "fieldValidation": "Please enter your Service Number"
    },
    {
        "providerBiller": "TP Ajmer Distribution Ltd (TPADL)",
        "ProviderCode": 76,
        "State": "Rajasthan",
        "Inputs ": "1. K Number",
        "fieldValidation": "Please enter your 12 digit K Number.",
        "errorResponse": "It seems the details that you entered are wrong. Please enter correct details and try again."
    },
    {
        "providerBiller": "TP Central Odisha Distribution Ltd.",
        "ProviderCode": 77,
        "State": "Odisha",
        "Inputs ": "1.Consumer ID",
        "fieldValidation": "Please enter your 11 or 12 character Consumer ID;  Please enter your 10 digit Mobile Numbe ron which you want payment confirmation ",
        "errorResponse": "Please enter a valid Consumer ID. Please enter a valid Mobile Number"
    },
    {
        "providerBiller": "TP Northern Odisha Distribution Limited",
        "ProviderCode": 78,
        "State": "Odisha",
        "Inputs ": "1.Consumer Number",
        "fieldValidation": "Please enter your Consumer Number",
        "errorResponse": "Please enter a valid Consumer Number."
    },
    {
        "providerBiller": "TP Renewables Microgrid Ltd.",
        "ProviderCode": 79,
        "State": "Uttar Pradesh",
        "Inputs ": "1. Registered Mob No",
        "fieldValidation": "Please enter your Registered Mobile Number or Customer Account Number"
    },
    {
        "providerBiller": "TP Southen Odisha Distribution Ltd-Smart Prepaid Meter Recharge",
        "ProviderCode": 80,
        "State": "Odisha",
        "Inputs ": "1.Consumer Number",
        "fieldValidation": "Please enter your Consumer Number",
        "errorResponse": "Please enter a valid Consumer Number."
    },
    {
        "providerBiller": "TP Southern Odisha Distribution Limited",
        "ProviderCode": 81,
        "State": "Odisha",
        "Inputs ": "1.  Consumer Number",
        "fieldValidation": "Please enter your 12 character Consumer Number ",
        "errorResponse": "Please enter a valid Consumer Number."
    },
    {
        "providerBiller": "TP Western Odisha Distribution Limited",
        "ProviderCode": 82,
        "State": "Odisha",
        "Inputs ": "1.  Consumer Number",
        "fieldValidation": "Please enter your 12 character Consumer Number ",
        "errorResponse": "Please enter a valid Consumer Number."
    },
    {
        "providerBiller": "TP Western Odisha Distribution Limited",
        "ProviderCode": 83,
        "State": "Odisha",
        "Inputs ": "1.  Consumer Number",
        "fieldValidation": "smart prepaid meter recharage "
    },
    {
        "providerBiller": "Tripura Electricity Corp Ltd",
        "ProviderCode": 84,
        "State": "Tripura",
        "Inputs ": "1. Consumer Account Number",
        "fieldValidation": "Please enter your 12 digit Consumer ID starting with \"1\"",
        "errorResponse": "It seems the details that you entered are wrong. Please enter correct details and try again."
    },
    {
        "providerBiller": "Uttar Gujarat Vij Company Limited (UGVCL)",
        "ProviderCode": 85,
        "State": "Gujarat",
        "Inputs ": "1.  Consumer Number",
        "fieldValidation": "Please enter your 5 or 11 digit Consumer Number.",
        "errorResponse": "It seems the details that you entered are wrong. Please enter correct details and try again."
    },
    {
        "providerBiller": "Uttar Gujarat Vij Company Limited (UGVCL)-Fetch and pay",
        "ProviderCode": 86,
        "State": "Gujarat"
    },
    {
        "providerBiller": "Uttar Haryana Bijli Vitran Nigam (UHBVN)",
        "ProviderCode": 87,
        "State": "Haryana",
        "Inputs ": "1. Account Number 2. Mobile Number",
        "fieldValidation": "Enter your 10 to 12 character long Account Number;  Enter 10 digit Mobile number on which you want payment confirmation "
    },
    {
        "providerBiller": "Uttar Pradesh Power Corp Ltd (UPPCL) - RURAL",
        "ProviderCode": 88,
        "State": "Uttar Pradesh",
        "Inputs ": "1. District  Type (\"Rural\" Autofill) 2. Account Number",
        "fieldValidation": "Please enter your 12 digit Account Number "
    },
    {
        "providerBiller": "Uttarakhand Power Corporation Limited",
        "ProviderCode": 89,
        "State": "Uttarakhand",
        "Inputs ": "1. Account Number",
        "fieldValidation": "Please enter your valid Account Number",
        "errorResponse": "It seems the details that you entered are wrong. Please enter correct details and try again."
    },
    {
        "providerBiller": "Vaghani Energy Limited",
        "ProviderCode": 90,
        "State": "Gujarat",
        "Inputs ": "1.  Consumer Number",
        "fieldValidation": "Please enter your Consumer No",
        "errorResponse": "It seems the details that you entered are wrong. Please enter correct details and try again."
    },
    {
        "providerBiller": "West Bengal Electricity",
        "ProviderCode": 91,
        "State": "West Bengal",
        "Inputs ": "1. Consumer ID 2. Mobile Number",
        "fieldValidation": "Please enter your 9 digit Consumer ID; Payment Confirmation will be sent on this Mobile Number"
    },
]



export let mainElectricityConfig = [
    {
        "providerBiller": "Adani Electricity Mumbai Limited",
        "ProviderCode": 1,
        "State": "Maharashtra",
        "Inputs": [{
            title: "Account Number",
            fieldName: "account_Number",
            fieldValidation: "Please enter your 9 digit Account Number",
            maxlength: 9,
            placeHolder: "",
            required: true
        }],
        "errorResponse": "Incorrect Consumer ID entered"
    },
    {
        "providerBiller": "Ajmer Vidyut Vitran Nigam Limited (AVVNL)",
        "ProviderCode": 2,
        "State": "Rajasthan",
        "Inputs": [{
            title: "K number",
            fieldName: "k_Number",
            fieldValidation: "Please enter your 12 digit K Number.",
            placeHolder: "",
            maxlength: 12,
            required: true
        }],
        "errorResponse": "Please enter a valid K Number"
    },
    {
        "providerBiller": "APSPDCL-Eastern Power Distribution CO AP Ltd",
        "ProviderCode": 3,
        "State": "Andhra Pradesh",
        "Inputs": [{
            title: "Service Number",
            fieldName: "service_Number",
            fieldValidation: "Please enter your 16 digit Service Number",
            placeHolder: "",
            maxlength: 16,
            required: true
        }],
        "errorResponse": "Consumer Does not Exists"
    },
    {
        "providerBiller": "APSPDCL-Southern Power Distribution CO AP Ltd",
        "ProviderCode": 4,
        "State": "Andhra Pradesh",
        "Inputs": [{
            title: "Service Number",
            fieldName: "service_Number",
            fieldValidation: "Please enter your 13 digit Service Number",
            placeHolder: "",
            maxlength: 13,
            required: true
        }]
    },
    {
        "providerBiller": "Assam Power Distribution Company Ltd (NON-RAPDR)",
        "ProviderCode": 5,
        "State": "Assam",
        "Inputs": [{
            title: "Consumer Number",
            fieldName: "consumer_Number",
            fieldValidation: "Please enter your 12 digit Consumer Number",
            placeHolder: "",
            maxlength: 12,
            required: true
        }],
        "errorResponse": "It seems the details that you entered are wrong. Please enter correct details and try again."
    },
    {
        "providerBiller": "B.E.S.T Mumbai",
        "ProviderCode": 6,
        "State": "Maharashtra",
        "Inputs": [{
            title: "Consumer Number",
            fieldName: "consumer_Number",
            fieldValidation: "Please enter your valid Consumer Number",
            placeHolder: "",
            maxlength: 9,
            required: true
        }],
        "errorResponse": "We are not able to get your bill details from Brihanmumbai Electricity Supply and Transport Undertaking (BEST Undertaking). Please try again in a while"
    },
    {
        "providerBiller": "Bangalore Electricity Supply Co. Ltd (BESCOM)",
        "ProviderCode": 7,
        "State": "Karnataka",
        "Inputs": [{
            title: "Customer Id / Account Id",
            fieldName: "account_Id",
            fieldValidation: "Please enter your Customer Id / Account Id",
            placeHolder: "",
            maxlength: 10,
            required: true
        }],
        "errorResponse": "Please enter a valid Customer Id / Account Id."
    },
    {
        "providerBiller": "Bharatpur Electricity Services Ltd. (BESL)",
        "ProviderCode": 8,
        "State": "Rajasthan",
        "Inputs": [{
            title: "K number",
            fieldName: "k_Number",
            fieldValidation: "Please enter your 12 digit K Number.",
            placeHolder: "",
            maxlength: 12,
            required: true
        }],
        "errorResponse": "Please enter a valid K Number"
    },
    {
        "providerBiller": "Bikaner Electricity Supply Limited (BkESL)",
        "ProviderCode": 9,
        "State": "Rajasthan",
        "Inputs": [{
            title: "K number",
            fieldName: "k_Number",
            fieldValidation: "Please enter your 12 digit K Number.",
            placeHolder: "",
            maxlength: 12,
            required: true

        }],
        "errorResponse": "It seems the details that you entered are wrong. Please enter correct details and try again."
    },
    {
        "providerBiller": "BSES Rajdhani Power Limited",
        "ProviderCode": 10,
        "State": "Delhi",
        "Inputs": [{
            title: "CA Number",
            fieldName: "ca_Number",
            fieldValidation: "Please enter your 9 digit CA Number",
            placeHolder: "",
            required: true
        }]
    },
    {
        "providerBiller": "BSES Rajdhani Prepaid Meter Recharge",
        "ProviderCode": 11,
        "State": "Delhi",
        "Inputs": [{
            title: "CA Number",
            fieldName: "ca_Number",
            fieldValidation: "Please enter your 9 digit CA Number",
            placeHolder: "",
            required: true
        },
        ]
    }
    ,
    {
        "providerBiller": "BSES Yamuna Power Limited",
        "ProviderCode": 12,
        "State": "Delhi",
        "Inputs": [{
            title: "CA Number",
            fieldName: "ca_Number",
            fieldValidation: "Please enter your 9 digit CA Number",
            placeHolder: "",
            required: true
        }]
    },
    {
        "providerBiller": "BSES Yamuna Prepaid Meter Recharge",
        "ProviderCode": 13,
        "State": "Delhi",
        "Inputs": [{
            title: "CA Number",
            fieldName: "ca_Number",
            fieldValidation: "Please enter your 9 digit CA Number",
            placeHolder: "",
            required: true
        },
            // {
            //     title: "Amount",
            //     fieldName: "",
            //     fieldValidation: "",
            //     placeHolder: "",
            //     required: true
            // }
        ]
    },
    {
        "providerBiller": "CESC Limited",
        "ProviderCode": 14,
        "State": "West Bengal",
        "Inputs": [{
            title: "Customer Id",
            fieldName: "consumer_Number",
            fieldValidation: "Please enter your 11 digit Customer Id",
            placeHolder: "",
            required: true
        }]
    },
    {
        "providerBiller": "Chamundeshwari Electricity Supply Corp Ltd (CESCOM)",
        "ProviderCode": 15,
        "State": "Karnataka",
        "Inputs": [{
            title: "Account ID/Connection ID/Consumer number",
            fieldName: "account_Id",
            fieldValidation: "Enter 10 digit Account IDConsumer NumberConnection ID",
            placeHolder: "",
            maxlength: 10,
            required: true
        }],
        "errorResponse": "It seems the details that you entered are wrong. Please enter correct details and try again"
    },
    {
        "providerBiller": "Chhattisgarh State Power Distribution Co. Ltd",
        "ProviderCode": 16,
        "State": "Chhattisgarh",
        "Inputs": [{
            title: "Business Partner Number",
            fieldName: "business_Partner_Number",
            fieldValidation: "Please enter your 10 digit BP Number",
            placeHolder: "",
            required: true
        }],
        "errorResponse": "Please enter a valid BP Number."
    },
    {
        "providerBiller": "Dadra and Nagar Haveli and Daman and Diu Power Distribution Corporation Limited",
        "ProviderCode": 17,
        "State": "National",
        "Inputs": [{
            title: "CA Number",
            fieldName: "ca_Number",
            fieldValidation: "Please enter your CANumber",
            placeHolder: "",
            required: true
        }]
    },
    {
        "providerBiller": "Dakshin Gujarat Vij Company Limited (DGVCL)",
        "ProviderCode": 18,
        "State": "Gujarat",
        "Inputs": [{
            title: "Consumer Number",
            fieldName: "consumer_Number",
            fieldValidation: "Please enter your 5 or 11 digit Consumer Number",
            placeHolder: "",
            required: true
        }],
        "errorResponse": "It seems the details that you entered are wrong. Please enter correct details and try again"
    },
    {
        "providerBiller": "Dakshin Gujarat Vij Company Limited (DGVCL)-Fetch and pay",
        "ProviderCode": 19,
        "State": "Gujarat",
        "Inputs": [{
            title: "Consumer Number",
            fieldName: "consumer_Number",
            fieldValidation: "Please enter your valid Consumer Number",
            placeHolder: "",
            required: true
        }],
        "fieldValidation": "NO 19 & 20 ARE SIMILAR"
    },
    {
        "providerBiller": "Dakshin Haryana Bijli Vitran Nigam (DHBVN)",
        "ProviderCode": 20,
        "State": "Haryana",
        "Inputs": [{
            title: "Account Number",
            fieldName: "account_Number",
            fieldValidation: "Enter your 9 to 12 character long Account Number",
            placeHolder: "",
            required: true
        },
        {
            title: "Mobile Number",
            fieldName: "mobile_Number",
            fieldValidation: "Please enter your 10 digit Mobile number",
            placeHolder: "",
            required: true
        }]
    },
    {
        "providerBiller": "Dakshinanchal Vidyut Vitran Nigam Limited (DVVNL)(Postpaid and Smart Prepaid Meter Recharge)",
        "ProviderCode": 21,
        "State": "Uttar Pradesh",
        "Inputs": [{
            title: "Consumer Number",
            fieldName: "consumer_Number",
            fieldValidation: "Please enter your Consumer Number",
            placeHolder: "",
            required: true
        }],
        "errorResponse": "Please enter a valid Consumer Number."
    },
    {
        "providerBiller": "Daman and Diu Electricity",
        "ProviderCode": 22,
        "State": "Daman and DIU",
        "Inputs": [{
            title: "CA Number",
            fieldName: "ca_Number",
            fieldValidation: "Please enter your CANumber",
            placeHolder: "",
            required: true
        }]
    },
    {
        "providerBiller": "Department of Power, Government of Arunachal Pradesh",
        "ProviderCode": 23,
        "State": "Arunachal Pradesh",
        "Inputs": [{
            title: "Consumer Number",
            fieldName: "consumer_Number",
            fieldValidation: "Please enter your 11 digit Consumer Number",
            placeHolder: "",
            required: true
        }],
        "errorResponse": "It seems the details that you entered are wrong. Please enter correct details and try again."
    },
    {
        "providerBiller": "Department of Power, Government of Arunachal Pradesh - Prepaid",
        "ProviderCode": 24,
        "State": "Arunachal Pradesh",
        "Inputs": [{
            title: "Consumer Number",
            fieldName: "consumer_Number",
            fieldValidation: "Please enter your 11 digit Consumer Number",
            placeHolder: "",
            required: true
        }],
        "errorResponse": "It seems the details that you entered are wrong. Please enter correct details and try again."
    },
    {
        "providerBiller": "Department of Power, Nagaland",
        "ProviderCode": 25,
        "State": "Nagaland",
        "Inputs": [{
            title: "Consumer Number",
            fieldName: "consumer_Number",
            fieldValidation: "Please enter your 11 digit Consumer number",
            placeHolder: "",
            required: true
        }]
    },
    {
        "providerBiller": "DNH Power Distribution Company Limited",
        "ProviderCode": 26,
        "State": "Dadra and Nagar Haveli",
        "Inputs": [{
            title: "CA Number",
            fieldName: "ca_Number",
            fieldValidation: "Please enter your CANumber",
            placeHolder: "",
            required: true
        }],
        "errorResponse": "Please enter a valid CANumber"
    },
    {
        "providerBiller": "Electricity Department Chandigarh",
        "ProviderCode": 27,
        "State": "Chandigarh",
        "Inputs": [{
            title: "Account Number without (/)",
            fieldName: "account_Number",
            fieldValidation: "Account No : AAABBBCCCCCCC should be entered as BBBCCCCCCC",
            placeHolder: "",
            required: true
        }],
        "errorResponse": "It seems the details that you entered are wrong. Please enter correct details and try again."
    },
    {
        "providerBiller": "Gift Power Company Limited",
        "ProviderCode": 28,
        "State": "Gujarat",
        "Inputs": [{
            title: "Consumer Number",
            fieldName: "consumer_Number",
            fieldValidation: "Please enter your valid Consumer Number",
            placeHolder: "",
            required: true
        }],
        "errorResponse": "We are not able to get your bill details from Gift Power Company Limited. Please try again in a while."
    },
    {
        "providerBiller": "Goa Electricity Department",
        "ProviderCode": 29,
        "State": "Goa",
        "Inputs": [{
            title: "Contract Account No.",
            fieldName: "contract_Account_Number",
            fieldValidation: "Please enter your 11 digit Contract Account Numbe",
            placeHolder: "",
            required: true
        }],
        "errorResponse": "It seems the details that you entered are wrong. Please enter correct details and try again."
    },
    {
        "providerBiller": "Government of Puducherry Electricity Department",
        "ProviderCode": 30,
        "State": "Puducherry",
        "Inputs": [{
            title: "Consumer Number",
            fieldName: "consumer_Number",
            fieldValidation: "Please enter your 9-12 character Consumer Number",
            placeHolder: "",
            required: true
        },
        {
            title: "Consumer type dd(LT,HT)",
            fieldName: "",
            fieldValidation: "",
            placeHolder: "",
            required: false
        }]
    },
    {
        "providerBiller": "Gulbarga Electricity Supply Company Limited",
        "ProviderCode": 31,
        "State": "Karnataka",
        "Inputs": [{
            title: "Consumer No.",
            fieldName: "consumer_Number",
            fieldValidation: "Account ID (in paytm) also",
            placeHolder: "",
            maxlength: 10,
            required: true
        }]
    },
    {
        "providerBiller": "Himachal Pradesh State Electricity Board",
        "ProviderCode": 32,
        "State": "Himachal Pradesh",
        "Inputs": [{
            title: "Consumer Number",
            fieldName: "consumer_Number",
            fieldValidation: "Please enter your 12 digit Consumer Id.",
            placeHolder: "",
            required: true
        }],
        "errorResponse": "It seems the details that you entered are wrong. Please enter correct details and try again."
    },
    {
        "providerBiller": "Hubli Electricity Supply Company Ltd (HESCOM)",
        "ProviderCode": 33,
        "State": "Karnataka",
        "Inputs": [{
            title: "Account ID / Connection ID / Consumer Number",
            fieldName: "account_Id",
            fieldValidation: "Enter 5-10 digit Account IDConsumer Number Connection ID",
            placeHolder: "",
            maxlength: 10,
            required: true
        }]
    },
    {
        "providerBiller": "Hukkeri Rural Electric CoOperative Society Ltd",
        "ProviderCode": 34,
        "State": "Karnataka",
        "Inputs": [{
            title: "Connection Number",
            fieldName: "connection_Id",
            fieldValidation: "Please enter your Connection Number.",
            placeHolder: "",
            required: true
        }],
        "errorResponse": "It seems the details that you entered are wrong. Please enter correct details and try again."
    },
    {
        "providerBiller": "India Power Corporation Limited (IPCL)",
        "ProviderCode": 35,
        "State": "West Bengal",
        "Inputs": [{
            title: "Consumer Number / Meter Number",
            fieldName: "meter_Number",
            fieldValidation: "Please enter your 7 digit Consumer Number or 11 digit Meter Number",
            placeHolder: "",
            required: true
        }]
    },
    {
        "providerBiller": "Jaipur Vidyut Vitran Nigam (JVVNL)",
        "ProviderCode": 36,
        "State": "Rajasthan",
        "Inputs": [{
            title: "K Number",
            fieldName: "k_Number",
            fieldValidation: "Please enter your 12 digit K Number.",
            placeHolder: "",
            maxlength: 12,
            required: true
        }],
        "errorResponse": "It seems the details that you entered are wrong. Please enter correct details and try again."
    },
    {
        "providerBiller": "Jammu and Kashmir Power Development Department",
        "ProviderCode": 37,
        "State": "Jammu And Kashmir",
        "Inputs": [{
            title: "Consumer Code",
            fieldName: "consumer_Code",
            fieldValidation: "Please enter your valid Consumer Code",
            placeHolder: "",
            required: true
        }],
        "errorResponse": "Please enter a valid Consumer Code"
    },
    {
        "providerBiller": "Jamshedpur Utilities",
        "ProviderCode": 38,
        "State": "Jharkhand",
        "Inputs": [{
            title: "Business Partner Number",
            fieldName: "business_Partner_Number",
            fieldValidation: "Please enter your 6 to 10 digit Business Partner Number",
            placeHolder: "",
            required: true
        }],
        "errorResponse": "It seems the details that you entered are wrong. Please enter correct details and try again."
    },
    {
        "providerBiller": "Jharkhand Bijli Vitran Nigam Limited (JBVNL)",
        "ProviderCode": 39,
        "State": "Jharkhand",
        "Inputs": [{
            title: "Consumer Number",
            fieldName: "consumer_Number",
            fieldValidation: "Please enter your Consumer Number without special characters and spaces.",
            placeHolder: "",
            required: true
        }],
        "errorResponse": "There seems to be some issue at Jharkhand Bijli Vitran Nigam Limited (JBVNL)'s end. Please try after some time."
    },
    {
        "providerBiller": "Jharkhand Bijli Vitran Nigam Limited - Prepaid Meter Recharge",
        "ProviderCode": 40,
        "State": "National",
        "Inputs": [{
            title: "Meter number / Account number",
            fieldName: "meter_Number",
            fieldValidation: "Please enter your Consumer Number without special characters and spaces.",
            placeHolder: "",
            required: true
        }]
    },
    {
        "providerBiller": "Jodhpur Vidyut Vitran Nigam Limited (JDVVNL)",
        "ProviderCode": 41,
        "State": "Rajasthan",
        "Inputs": [{
            title: "K Number",
            fieldName: "k_Number",
            fieldValidation: "Please enter your 12 digit K Number.",
            placeHolder: "",
            maxlength: 12,
            required: true
        }],
        "errorResponse": "Please enter a valid K Number"
    },
    {
        "providerBiller": "Kanan Devan Hills Plantations Company Private Limited",
        "ProviderCode": 42,
        "State": "Kerala",
        "Inputs": [{
            title: "Consumer Number",
            fieldName: "consumer_Number",
            fieldValidation: "Please enter your 1 to 8 digit Consumer Number",
            placeHolder: "",
            required: true
        }],
        "errorResponse": "There seems to be some issue at Kannan Devan Hills Plantations Company Private Limited's end. Please try after some time."
    },
    {
        "providerBiller": "Kanpur Electricity Supply Company",
        "ProviderCode": 43,
        "State": "Uttar Pradesh",
        "Inputs": [{
            title: "Account Number",
            fieldName: "account_Number",
            fieldValidation: "Please enter your 5 to 10 digit Account Number",
            placeHolder: "",
            required: true
        }],
        "errorResponse": "It seems the details that you entered are wrong. Please enter correct details and try again."
    },
    {
        "providerBiller": "Kerala State Electricity Board Ltd. (KSEBL)",
        "ProviderCode": 44,
        "State": "Kerala",
        "Inputs": [{
            title: "Consumer Number",
            fieldName: "consumer_Number",
            fieldValidation: "Please enter your 13 digit Consumer Number",
            placeHolder: "",
            required: true
        }],
        "errorResponse": "It seems the details that you entered are wrong. Please enter correct details and try again."
    },
    {
        "providerBiller": "Kota Electricity Distribution Limited (KEDL)",
        "ProviderCode": 45,
        "State": "Rajasthan",
        "Inputs": [{
            title: "K Number",
            fieldName: "k_Number",
            fieldValidation: "Please enter your 12 digit K Number",
            placeHolder: "",
            maxlength: 12,
            required: true
        }],
        "errorResponse": "Please enter a valid K Number"
    },
    {
        "providerBiller": "Lakshadweep Electricity Department",
        "ProviderCode": 46,
        "State": "Lakshadweep",
        "Inputs": [{
            title: "Consumer Number",
            fieldName: "consumer_Number",
            fieldValidation: "Please enter your Consumer Number",
            placeHolder: "",
            required: true
        }],
        "errorResponse": "Please enter a valid Consumer Number."
    },
    {
        "providerBiller": "M.P. Madhya Kshetra Vidyut Vitaran - RURAL",
        "ProviderCode": 47,
        "State": "Madhya Pradesh",
        "Inputs": [{
            title: "IVRS ID/Consumer Number",
            fieldName: "ivrs_Number",
            fieldValidation: "Please enter your 7 to 15 digit IVRS Number",
            placeHolder: "",
            required: true

        }],
        "errorResponse": "It seems the details that you entered are wrong. Please enter correct details and try again."
    },
    {
        "providerBiller": "M.P. Madhya Kshetra Vidyut Vitaran - URBAN",
        "ProviderCode": 48,
        "State": "Madhya Pradesh",
        "Inputs": [{
            title: "IVRS Number",
            fieldName: "ivrs_Number",
            fieldValidation: "Please enter your 7 to 16 digit IVRS Number",
            placeHolder: "",
            required: true
        }],
        "errorResponse": "It seems the details that you entered are wrong. Please enter correct details and try again."
    },
    {
        "providerBiller": "M.P. Paschim Kshetra Vidyut Vitaran",
        "ProviderCode": 49,
        "State": "Madhya Pradesh",
        "Inputs": [{
            title: "IVRS Number",
            fieldName: "ivrs_Number",
            fieldValidation: "Please enter your 11 digit IVRS Number starting with 'N'",
            placeHolder: "",
            required: true
        }],
        "errorResponse": "It seems the details that you entered are wrong. Please enter correct details and try again."
    },
    {
        "providerBiller": "M.P. Poorv Kshetra Vidyut Vitaran - RURAL",
        "ProviderCode": 50,
        "State": "Madhya Pradesh",
        "Inputs": [{
            title: "IVRS ID / Consumer Number",
            fieldName: "ivrs_Number",
            fieldValidation: "Please enter your Consumer NumberIVRS Number",
            placeHolder: "",
            required: true
        }],
        "errorResponse": "Please enter a valid Consumer NumberIVRS"
    },
    {
        "providerBiller": "Madhya Gujarat Vij Company Limited (MGVCL)",
        "ProviderCode": 51,
        "State": "Gujarat",
        "Inputs": [{
            title: "Consumer Number",
            fieldName: "consumer_Number",
            fieldValidation: "Please enter your 5 or 11 digit Consumer Number",
            placeHolder: "",
            required: true
        }],
        "errorResponse": "It seems the details that you entered are wrong. Please enter correct details and try again."
    },
    {
        "providerBiller": "Madhya Gujarat Vij Company Limited (MGVCL)-Fetch and pay",
        "ProviderCode": 52,
        "State": "Gujarat",
        "Inputs": [{
            title: "Consumer Number",
            fieldName: "consumer_Number",
            fieldValidation: "Please enter your 5 or 11 digit Consumer Number",
            placeHolder: "",
            required: true
        }],
        "errorResponse": "It seems the details that you entered are wrong. Please enter correct details and try again."
    },
    {
        "providerBiller": "Madhyanchal Vidyut Vitran Nigam Limited (MVVNL)(Postpaid and Smart Prepaid Meter Recharge)",
        "ProviderCode": 53,
        "State": "Uttar Pradesh",
        "Inputs": [{
            title: "Consumer Number",
            fieldName: "consumer_Number",
            fieldValidation: "Please enter your Consumer Number",
            placeHolder: "",
            required: true
        }],
        "errorResponse": "Please enter a valid Consumer Number."
    },
    {
        "providerBiller": "Maharashtra State Electricity Distbn Co Ltd",
        "ProviderCode": 54,
        "State": "Maharashtra",
        "Inputs": [{
            title: "Consumer Number",
            fieldName: "consumer_Number",
            fieldValidation: "Please enter your 12 digit Consumer Number",
            placeHolder: "",
            maxlength: 12,
            required: true
        },
            // {
            //     title: "Sub Divison code",
            //     fieldName: "",
            //     fieldValidation: "Please enter your Sub Divison code",
            //     placeHolder: "",
            //     required: true
            // }
        ],
        "errorResponse": "We are not able to get your bill details from MSEDCL. Please try again in a while."
    },
    {
        "providerBiller": "Mangalore Electricity Supply Co. Ltd (MESCOM) - RAPDR",
        "ProviderCode": 55,
        "State": "Karnataka",
        "Inputs": [{
            title: "Account ID",
            fieldName: "account_Id",
            fieldValidation: "Please enter your valid Account ID",
            placeHolder: "",
            required: true
        }],
        "errorResponse": "Please enter your valid Account ID"
    },
    {
        "providerBiller": "Mangalore Electricity Supply Company LTD (Non RAPDR)",
        "ProviderCode": 56,
        "State": "Karnataka",
        "Inputs": [{
            title: "RR Number",
            fieldName: "rr_Number",
            fieldValidation: "Please enter your 5 to 25 character long RR Number",
            placeHolder: "",
            required: true
        }],
        "errorResponse": "It seems the details that you entered are wrong. Please enter correct details and try again."
    },
    {
        "providerBiller": "Meghalaya Power Dist Corp Ltd",
        "ProviderCode": 57,
        "State": "Meghalaya",
        "Inputs": [{
            title: "Consumer ID",
            fieldName: "consumer_Number",
            fieldValidation: "Please enter your Consumer ID",
            placeHolder: "",
            required: true
        }],
        "errorResponse": "Please enter a valid Consumer ID"
    },
    {
        "providerBiller": "MePDCL Smart Prepaid Meter Recharge",
        "ProviderCode": 58,
        "State": "National (Meghalaya)",
        "Inputs": [{
            title: "Account Number",
            fieldName: "account_Number",
            fieldValidation: "Please enter your ACCOUNT_NO",
            placeHolder: "",
            required: true
        }]
    },
    {
        "providerBiller": "New Delhi Municipal Council (NDMC) - Electricity",
        "ProviderCode": 59,
        "State": "Delhi",
        "Inputs": [{
            title: "Consumer Number",
            fieldName: "consumer_Number",
            fieldValidation: "Please enter 8 digit numeric Consumer No. as mentioned in your physical bill.",
            placeHolder: "",
            required: true
        }]
    },
    {
        "providerBiller": "Noida Power",
        "ProviderCode": 60,
        "State": "Uttar Pradesh",
        "Inputs": [{
            title: "Consumer Number",
            fieldName: "consumer_Number",
            fieldValidation: "Please enter your 10 digit Consumer Number",
            placeHolder: "",
            required: true
        }],
        "errorResponse": "The details entered seem to be invalid. Please re-enter the correct details and try again."
    },
    {
        "providerBiller": "North Bihar Power Distribution Company Ltd.",
        "ProviderCode": 61,
        "State": "Bihar",
        "Inputs": [{
            title: "CA Number",
            fieldName: "ca_Number",
            fieldValidation: "Please enter your 9 to 12 digit CA Number",
            placeHolder: "",
            required: true
        }],
        "errorResponse": "Please enter a valid CA Number."
    },
    {
        "providerBiller": "Paschim Gujarat Vij Company Limited (PGVCL)",
        "ProviderCode": 62,
        "State": "Gujarat",
        "Inputs": [{
            title: "Consumer Number",
            fieldName: "consumer_Number",
            fieldValidation: "Please enter your 5 or 11 digit Consumer Number.",
            placeHolder: "",
            required: true
        }],
        "errorResponse": "It seems the details that you entered are wrong. Please enter correct details and try again."
    },
    {
        "providerBiller": "Paschimanchal Vidyut Vitran Nigam Limited (PVVNL)(Postpaid and Smart Prepaid Meter Recharge)",
        "ProviderCode": 64,
        "State": "Uttar Pradesh",
        "Inputs": [{
            title: "Consumer Number",
            fieldName: "consumer_Number",
            fieldValidation: "Please enter your Consumer Number",
            placeHolder: "",
            required: true
        }],
        "errorResponse": "It seems the details that you entered are wrong. Please enter correct details and try again."
    },
    {
        "providerBiller": "Power and Electricity Department - Mizoram",
        "ProviderCode": 65,
        "State": "Mizoram",
        "Inputs": [{
            title: "Business Partner",
            fieldName: "business_Partner_Number",
            fieldValidation: "Please enter your 8 digit Business Partner",
            placeHolder: "",
            required: true
        }]
    },
    {
        "providerBiller": "Punjab State Power Corporation Ltd (PSPCL)",
        "ProviderCode": 66,
        "State": "Punjab",
        "Inputs": [{
            title: "Account Number",
            fieldName: "account_Number",
            fieldValidation: "Please enter your 10-12 character Account Number",
            placeHolder: "",
            required: true
        }]
    },
    {
        "providerBiller": "Purvanchal Vidyut Vitran Nigam Limited(PUVVNL)(Postpaid and Smart Prepaid Meter Recharge)",
        "ProviderCode": 67,
        "State": "Uttar Pradesh",
        "Inputs": [{
            title: "Consumer Number",
            fieldName: "consumer_Number",
            fieldValidation: "Please enter your Consumer Number",
            placeHolder: "",
            required: true
        }]
    },
    {
        "providerBiller": "Sikkim Power - RURAL",
        "ProviderCode": 68,
        "State": "Sikkim",
        "Inputs": [{
            title: "Contract Account Number",
            fieldName: "ca_Number",
            fieldValidation: "Please enter your Contract Account Number",
            placeHolder: "",
            required: true
        }],
        "errorResponse": "Please enter a valid Contract Account Number."
    },
    {
        "providerBiller": "Sikkim Power - URBAN",
        "ProviderCode": 69,
        "State": "Sikkim",
        "Inputs": [{
            title: "Contract Account Number",
            fieldName: "ca_Number",
            fieldValidation: "Please enter your Contract Account Number",
            placeHolder: "",
            required: true
        }],
        "errorResponse": "Please enter a valid Contract Account Number."
    },
    {
        "providerBiller": "South Bihar Power Distribution Company Ltd.",
        "ProviderCode": 70,
        "State": "Bihar",
        "Inputs": [{
            title: "CA Number",
            fieldName: "ca_Number",
            fieldValidation: "Please enter your 9 to 12 digit CA Number",
            placeHolder: "",
            required: true
        }],
        "errorResponse": "Please enter a valid CA Number."
    },
    {
        "providerBiller": "Spurt Electric Limited",
        "ProviderCode": 71,
        "State": "Gujarat",
        "Inputs": [
            {
                title: "Service Number",
                fieldName: "service_Number",
                fieldValidation: "Please enter your Service Number",
                placeHolder: "",
                required: true
            }
        ]
    },
    {
        "providerBiller": "Tamil Nadu Electricity Board (TNEB)",
        "ProviderCode": 72,
        "State": "Tamil Nadu",
        "Inputs": [{
            title: "Consumer Number",
            fieldName: "consumer_Number",
            fieldValidation: "Please enter your Consumer Number starting with regional code followed by section, distribution and service number. Note - Please read disclaimer",
            placeHolder: "",
            required: true
        }],
        "errorResponse": "Please enter a valid Consumer Number."
    },
    {
        "providerBiller": "Tata Power - DDL",
        "State": "Delhi",
        "Inputs": [{
            title: "Contract Account Number",
            fieldName: "ca_Number",
            fieldValidation: "Please enter your 11-12 digit CA Number",
            placeHolder: "",
            required: true
        }]
    },
    {
        "providerBiller": "Tata Power - Mumbai",
        "ProviderCode": 73,
        "State": "Maharashtra",
        "Inputs": [{
            title: "Consumer Number",
            fieldName: "consumer_Number",
            fieldValidation: "Please enter your 12 digit Consumer Number",
            placeHolder: "",
            maxlength: 12,
            required: true
        }],
        "errorResponse": "It seems the details that you entered are wrong. Please enter correct details and try again."
    },
    {
        "providerBiller": "Thrissur Corporation Electricity Department",
        "ProviderCode": 74,
        "State": "Kerala",
        "Inputs": [
            {
                title: "Consumer Number",
                fieldName: "consumer_Number",
                fieldValidation: "Please enter your valid Consumer Number",
                placeHolder: "",
                required: true
            },
            // {
            //     title: "Bill Number",
            //     fieldName: "",
            //     fieldValidation: "",
            //     placeHolder: "",
            //     required: false
            // },
            // {
            //     title: "Bill type",
            //     fieldName: "",
            //     fieldValidation: "",
            //     placeHolder: "Monthly or Bimonthly",
            //     required: false
            // }
        ],
        "errorResponse": "It seems the details that you entered are wrong. Please enter correct details and try again."
    },
    {
        "providerBiller": "Torrent Power",
        "ProviderCode": 75,
        "State": "National (Uttar Pradesh)",
        "Inputs": [
            {
                title: "Service Number",
                fieldName: "service_Number",
                fieldValidation: "Please enter your Service Number",
                placeHolder: "",
                required: true
            }
        ]
    },
    {
        "providerBiller": "TP Ajmer Distribution Ltd (TPADL)",
        "ProviderCode": 76,
        "State": "Rajasthan",
        "Inputs": [
            {
                title: "K Number",
                fieldName: "k_Number",
                fieldValidation: "Please enter your 12 digit K Number",
                placeHolder: "",
                maxlength: 12,
                required: true
            }
        ],
        "errorResponse": "It seems the details that you entered are wrong. Please enter correct details and try again."
    },
    {
        "providerBiller": "TP Central Odisha Distribution Ltd.",
        "ProviderCode": 77,
        "State": "Odisha",
        "Inputs": [
            {
                title: "Consumer ID",
                fieldName: "consumer_Number",
                fieldValidation: "Please enter your 11 or 12 character Consumer ID",
                placeHolder: "",
                required: true
            },
            {
                title: "Mobile Number",
                fieldName: "mobile_Number",
                fieldValidation: "Please enter your 10 digit Mobile Number on which you want payment confirmation",
                placeHolder: "",
                required: true
            }
        ],
        "errorResponse": "Please enter a valid Consumer ID. Please enter a valid Mobile Number"
    },
    {
        "providerBiller": "TP Northern Odisha Distribution Limited",
        "ProviderCode": 78,
        "State": "Odisha",
        "Inputs": [
            {
                title: "Consumer Number",
                fieldName: "consumer_Number",
                fieldValidation: "Please enter your Consumer Number",
                placeHolder: "",
                required: true
            }
        ],
        "errorResponse": "Please enter a valid Consumer Number."
    },
    {
        "providerBiller": "TP Renewables Microgrid Ltd.",
        "ProviderCode": 79,
        "State": "Uttar Pradesh",
        "Inputs": [
            {
                title: "Registered Mobile Number",
                fieldName: "mobile_Number",
                fieldValidation: "Please enter your Registered Mobile Number or Customer Account Number",
                placeHolder: "",
                required: true
            }
        ]
    },
    {
        "providerBiller": "TP Southern Odisha Distribution Ltd-Smart Prepaid Meter Recharge",
        "ProviderCode": 80,
        "State": "Odisha",
        "Inputs": [
            {
                title: "Consumer Number",
                fieldName: "consumer_Number",
                fieldValidation: "Please enter your Consumer Number",
                placeHolder: "",
                required: true
            }
        ],
        "errorResponse": "Please enter a valid Consumer Number."
    },
    {
        "providerBiller": "TP Southern Odisha Distribution Limited",
        "ProviderCode": 81,
        "State": "Odisha",
        "Inputs": [
            {
                title: "Consumer Number",
                fieldName: "consumer_Number",
                fieldValidation: "Please enter your 12 character Consumer Number",
                placeHolder: "",
                required: true
            }
        ],
        "errorResponse": "Please enter a valid Consumer Number."
    },
    {
        "providerBiller": "TP Western Odisha Distribution Limited",
        "ProviderCode": 82,
        "State": "Odisha",
        "Inputs": [
            {
                title: "Consumer Number",
                fieldName: "consumer_Number",
                fieldValidation: "Please enter your 12 character Consumer Number",
                placeHolder: "",
                required: true
            }
        ],
        "errorResponse": "Please enter a valid Consumer Number."
    },
    {
        "providerBiller": "TP Western Odisha Distribution Limited",
        "ProviderCode": 83,
        "State": "Odisha",
        "Inputs": [
            {
                title: "Consumer Number",
                fieldName: "consumer_Number",
                fieldValidation: "Smart prepaid meter recharge",
                placeHolder: "",
                required: true
            }
        ]
    },
    {
        "providerBiller": "Tripura Electricity Corp Ltd",
        "ProviderCode": 84,
        "State": "Tripura",
        "Inputs": [
            {
                title: "Consumer Account Number",
                fieldName: "ca_Number",
                fieldValidation: "Please enter your 12 digit Consumer ID starting with \"1\"",
                placeHolder: "",
                required: true
            }
        ],
        "errorResponse": "It seems the details that you entered are wrong. Please enter correct details and try again."
    },
    {
        "providerBiller": "Uttar Gujarat Vij Company Limited (UGVCL)",
        "ProviderCode": 85,
        "State": "Gujarat",
        "Inputs": [
            {
                title: "Consumer Number",
                fieldName: "consumer_Number",
                fieldValidation: "Please enter your 5 or 11 digit Consumer Number.",
                placeHolder: "",
                required: true
            }
        ],
        "errorResponse": "It seems the details that you entered are wrong. Please enter correct details and try again."
    },
    {
        "providerBiller": "Uttar Gujarat Vij Company Limited (UGVCL)-Fetch and pay",
        "ProviderCode": 86,
        "State": "Gujarat",
        "Inputs": [
            {
                title: "Consumer Number",
                fieldName: "consumer_Number",
                fieldValidation: "Please enter your 5 or 11 digit Consumer Number.",
                placeHolder: "",
                required: true
            }
        ],
    },
    {
        "providerBiller": "Uttar Haryana Bijli Vitran Nigam (UHBVN)",
        "ProviderCode": 87,
        "State": "Haryana",
        "Inputs": [
            {
                title: "Account Number",
                fieldName: "account_Number",
                fieldValidation: "Enter your 10 to 12 character long Account Number",
                placeHolder: "",
                required: true
            },
            {
                title: "Mobile Number",
                fieldName: "mobile_Number",
                fieldValidation: "Enter 10 digit Mobile number on which you want payment confirmation",
                placeHolder: "",
                required: true
            }
        ]
    },
    {
        "providerBiller": "Uttar Pradesh Power Corp Ltd (UPPCL) - RURAL",
        "ProviderCode": 88,
        "State": "Uttar Pradesh",
        "Inputs": [
            {
                title: "District Type",
                fieldName: "",
                fieldValidation: "\"Rural\" Autofill",
                placeHolder: "",
                required: false
            },
            {
                title: "Account Number",
                fieldName: "account_Number",
                fieldValidation: "Please enter your 12 digit Account Number",
                placeHolder: "",
                required: true
            }
        ]
    },
    {
        "providerBiller": "Uttarakhand Power Corporation Limited",
        "ProviderCode": 89,
        "State": "Uttarakhand",
        "Inputs": [
            {
                title: "Account Number",
                fieldName: "account_Number",
                fieldValidation: "Please enter your valid Account Number",
                placeHolder: "",
                required: true
            }
        ],
        "errorResponse": "It seems the details that you entered are wrong. Please enter correct details and try again."
    },
    {
        "providerBiller": "Vaghani Energy Limited",
        "ProviderCode": 90,
        "State": "Gujarat",
        "Inputs": [
            {
                title: "Consumer Number",
                fieldName: "consumer_Number",
                fieldValidation: "Please enter your Consumer No",
                placeHolder: "",
                required: true
            }
        ],
        "errorResponse": "It seems the details that you entered are wrong. Please enter correct details and try again."
    },
    {
        "providerBiller": "West Bengal Electricity",
        "ProviderCode": 91,
        "State": "West Bengal",
        "Inputs": [
            {
                title: "Consumer ID",
                fieldName: "consumer_Number",
                fieldValidation: "Please enter your 9 digit Consumer ID",
                placeHolder: "",
                required: true
            },
            {
                title: "Mobile Number",
                fieldName: "mobile_Number",
                fieldValidation: "Payment Confirmation will be sent on this Mobile Number",
                placeHolder: "",
                required: false
            }
        ]
    },
]

