import React, { useEffect, useState } from 'react'
import YesNoButton from './yes-no-button';
import { BiPlus, BiMinus } from "react-icons/bi";
import IconButton from '../form-elements/icon-button';
import radioDeSelect from '../../../assets/images/radio_deselect.png';
import radioNormal from '../../../assets/images/radio_normal.png';
import radioSelect from '../../../assets/images/radio_select.png';
import "../../../styles/view-table.scss"

// sending datas
interface DataItem {
    name: string;
    subName: string[];
    jsonKey: string[];
    isSubName: boolean;
}


interface ProductData {
    [key: string]: any; // You can replace 'any' with a more specific type if possible
}

interface TableValues {
    name?: string;
    subName?: string[];
    jsonKey?: string[];
    isSubName?: boolean;
}
interface Props {
    tableDatas: any;
    rowDisabled?: boolean;
    axisToChoose?: boolean;
    productData?: any;
    setProductData?: (value: any) => void;
}

function ViewTable({ rowDisabled, setProductData, productData, axisToChoose }: Props) {
    const [openClose, setOpenClose] = useState("")
    const radioIcons = [radioDeSelect, radioNormal, radioSelect];

    const handleChange = (category: string, updateto: number, value: string) => {
        setProductData && setProductData((prevData: any) => ({
            ...prevData,
            [category]: {
                ...prevData[category],
                module: {
                    ...prevData[category].module,
                    accessCategory: value,
                },
                features: prevData[category].features.map((feature: any, i: number) => ({
                    ...feature,
                    accessCategory: i === updateto ? value : feature.accessCategory,
                })),
            },
        }));
    };

    const handleChangeall = (category: string, value: string) => {
        setProductData && setProductData((prevData: any) => ({
            ...prevData,
            [category]: {
                ...prevData[category],
                module: {
                    ...prevData[category].module,
                    accessCategory: value,
                },
                features: prevData[category].features.map((feature: any) => ({
                    ...feature,
                    accessCategory: value,
                })),
            },
        }));
    };


    return (
        <div className='view-table-component'>
            <table>
                <thead style={{ position: "relative" }}>
                    <tr>
                        <th className='privilege-head'>Privilege</th>
                        <th className='access-full'>Full Access</th>
                        <th className='access-full'>View Only</th>
                        <th className='access-full'>No Access</th>
                        <th className='empty'></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        Object.keys(productData).map((elem: any, i) => {
                            return (
                                <React.Fragment key={i}>
                                    <div
                                        className='main-body-header'
                                        style={rowDisabled ? { opacity: "0.5", pointerEvents: "none" } : {}}
                                        onClick={(e) => {
                                            const targetElement = e.target as HTMLElement;
                                            if (targetElement.tagName.toLowerCase() !== "input" && targetElement.tagName.toLowerCase() !== "img") {
                                                elem === openClose ? setOpenClose("") : setOpenClose(elem)
                                            }
                                        }}>
                                        <tr key={`${productData[elem][elem]} ${i}`} style={{ backgroundColor: elem.isSubName ? "#F1F1F1" : "#ffff" }}>
                                            <td className='first-name-head'>{elem}</td>
                                            {/* <td className='middle-icons'><YesNoButton /></td> */}
                                            <td className='middle-icons-body'>
                                                <label>
                                                    <input
                                                        type='radio'
                                                        value={"Full Access"}
                                                        disabled={axisToChoose}
                                                        name={elem + "Full Access"}
                                                        checked={productData[elem].features.filter((e: any) => e.accessCategory != "Full Access").length == 0}
                                                        onChange={(e: any) => {
                                                            handleChangeall(elem, e.target.value)
                                                        }}
                                                    />
                                                    <img src={radioIcons[productData[elem].features.filter((e: any) => e.accessCategory != "Full Access").length == 0 ? 2 : (productData[elem].features.filter((e: any) => e.accessCategory == "Full Access").length == 0 ? 1 : 0)]} alt={radioIcons[0]} className='img' />
                                                </label>
                                            </td>
                                            <td className='middle-icons-body'>
                                                <label>
                                                    <input
                                                        type="radio"
                                                        value={"View Only"}
                                                        disabled={axisToChoose}
                                                        name={elem + "View Only"}
                                                        checked={productData[elem].features.filter((e: any) => e.accessCategory != "View Only").length == 0}
                                                        onChange={(e: any) => handleChangeall(elem, e.target.value)}
                                                    />
                                                    <img src={radioIcons[productData[elem].features.filter((e: any) => e.accessCategory != "View Only").length == 0 ? 2 : (productData[elem].features.filter((e: any) => e.accessCategory == "View Only").length == 0 ? 1 : 0)]} alt={radioIcons[1]} className='img' />
                                                </label>
                                            </td>
                                            <td className='middle-icons-body'>
                                                <label>
                                                    <input
                                                        type='radio'
                                                        name={elem + "No Access"}
                                                        disabled={axisToChoose}
                                                        checked={productData[elem].features.filter((e: any) => e.accessCategory != "No Access").length == 0} //"No Access"
                                                        onChange={(e: any) => handleChangeall(elem, e.target.value)}
                                                        value={"No Access"}
                                                    />
                                                    <img src={radioIcons[productData[elem].features.filter((e: any) => e.accessCategory != "No Access").length == 0 ? 2 : (productData[elem].features.filter((e: any) => e.accessCategory == "No Access").length == 0 ? 1 : 0)]} alt={radioIcons[2]} className='img' />
                                                </label>
                                            </td>
                                            <td className='last-icon-body'>
                                                <p data-testid={`table-row-organisation-${i}`}
                                                    onClick={() => {
                                                        //  handleTableOpen(elem) 
                                                    }}>
                                                    {
                                                        openClose === elem ? (
                                                            <IconButton
                                                                iconName={"DownArrow"}
                                                                height={"45px"}
                                                                width={"45px"}
                                                                fontSize={"20px"}
                                                                color={"#A9C3DC"}
                                                                border={""}
                                                                borderRadius={""}
                                                                backgroundColor={""}
                                                                transform={"rotate(-180deg)"}
                                                                hover={false}
                                                                margin={"5px 0px 0px 0px"}
                                                                handleClick={() => {
                                                                    // handleTableOpen(elem)
                                                                }}
                                                            />
                                                        ) : (
                                                            <IconButton
                                                                iconName={"DownArrow"}
                                                                height={"45px"}
                                                                width={"45px"}
                                                                fontSize={"20px"}
                                                                color={"#A9C3DC"}
                                                                border={""}
                                                                borderRadius={""}
                                                                backgroundColor={"white"}
                                                                hover={false}
                                                                margin={"5px 0px 0px 0px"}
                                                                handleClick={() => {
                                                                    // handleTableOpen(elem)
                                                                }}
                                                            />
                                                        )
                                                    }
                                                </p>
                                            </td>
                                        </tr>
                                    </div>
                                    {
                                        openClose && openClose === elem &&
                                        <div className='main-sub-header'>
                                            {
                                                Object.keys(productData[elem].features).map((ele: any, i: number) => {
                                                    return (
                                                        <tr style={{ backgroundColor: openClose === elem ? "#F1F1F1" : "#ffff" }}>
                                                            <td className='sub-first-name-body'>{productData[elem].features[ele].features}</td>
                                                            {/* <td className='sub-middle-icons'><YesNoButton /></td> */}
                                                            <td className='sub-middle-icons-body'>
                                                                <input
                                                                    type='radio'
                                                                    disabled={axisToChoose}
                                                                    value={"Full Access"}
                                                                    checked={productData[elem].features[i].accessCategory
                                                                        === "Full Access"}
                                                                    name={ele}
                                                                    onChange={(e: any) => handleChange(elem, i, e.target.value)}
                                                                />
                                                            </td>
                                                            <td className='sub-middle-icons-body'>
                                                                <input
                                                                    type='radio'
                                                                    value={"View Only"}
                                                                    disabled={axisToChoose}
                                                                    checked={productData[elem].features[i].accessCategory === "View Only"}
                                                                    name={ele}
                                                                    onChange={(e: any) => handleChange(elem, i, e.target.value)}
                                                                />
                                                            </td>
                                                            <td className='sub-middle-icons-body'>
                                                                <input
                                                                    type='radio'
                                                                    value={"No Access"}
                                                                    disabled={axisToChoose}
                                                                    checked={productData[elem].features[i].accessCategory === "No Access"}
                                                                    name={ele}
                                                                    onChange={(e: any) => handleChange(elem, i, e.target.value)}
                                                                />
                                                            </td>
                                                            <td className='sub-last-icon-body'></td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </div>
                                    }
                                </React.Fragment>
                            )
                        })
                    }
                </tbody>
            </table>
        </div>
    )
}

export default ViewTable;
