import React, { useState, useRef, ChangeEvent, KeyboardEvent, useEffect } from 'react';
import ButtonComponent from '../common-component/form-elements/button-component';
import axios from 'axios';
import useAuth from '../../services/hooks/useauth';
import { useNavigate } from 'react-router-dom';
import '../../styles/login/otp-verification.scss';
import useAxiosPrivate from '../../services/hooks/useaxios-private';
import { encryptToken } from '../../services/utils/token-utils';




interface Props {
    handleclick: (val: any) => void;
    setIsPasswordSetup: (val: any) => void;
    userMail: string;
    userPassword: string;
    otpAttemptsRemaining: number;

}

export default function LoginOtpVerification({ handleclick, setIsPasswordSetup, userMail, userPassword, otpAttemptsRemaining }: Props) {
    const [errorMessage, setErrorMessage] = useState<string>("")
    const [successMessage, setSuccessMessage] = useState<string>("")

    const [isButtonDisabled, setButtonDisabled] = useState<boolean>(false);
    const [attemptsRemaining, setAttemptsRemaining] = useState(otpAttemptsRemaining);
    const [userGmail, setUserGmail] = useState<string | null>('')
    const [hideMobileNumber, setHideMobileNumber] = useState<string>("")

    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate()
    // OTP 

    const length = 6;
    const [otp, setOTP] = useState<string[]>(Array(length).fill(''));



    const inputRefs = useRef<HTMLInputElement[]>([]);

    const handleChange = (e: ChangeEvent<HTMLInputElement>, index: number, inputName: string) => {
        const value = e.target.value;
        if (!(/^\d*$/.test(value))) return;
        if (e.target.value.length > 1) return;
        const updatedOTP = [...otp];
        updatedOTP[index] = e.target.value;
        setOTP(updatedOTP);

        if (e.target.value !== '' && index < length - 1) {
            inputRefs.current[index + 1].focus();
        }
        setErrorMessage("")
        setSuccessMessage("")
    };

    const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>, index: number, inputName: string) => {
        if (e.key === 'Backspace' && index > 0 && otp[index] === '') {
            inputRefs.current[index - 1].focus();
        }
        if (e.key === 'Enter') {
            otpVerify()
            setIsPasswordSetup(true)
        }
    };


    const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>, inputName: string) => {
        e.preventDefault();
        const pasteData = e.clipboardData.getData('text/plain');
        const numbersOnly = pasteData.replace(/\D/g, '');
        const newOTP = numbersOnly.slice(0, length).split('');

        const updatedOTP = newOTP.concat(Array(length - newOTP.length).fill(''));

        setOTP(updatedOTP);
        inputRefs.current[0].focus();

    };

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const userName = params.get('token');
        setUserGmail(userName)
        mobileNumber()
    }, []);
    useEffect(() => {
        if (inputRefs.current.length > 0 && inputRefs.current[0]) {
            inputRefs.current[0].focus();
        }
    }, []);



    async function otpVerify() {
        const stringFromArray = otp.join('');
        if (stringFromArray !== "") {
            setSuccessMessage("")
            try {
                const res = await axiosPrivate.post('verify/otp/set-password', { "email": encryptToken(userMail), "email_otp": encryptToken(stringFromArray), "password": encryptToken(userPassword) })


                if (res.status === 200) {
                    handleclick("passwordChanged")

                }
            } catch (error: any) {
                if (error.response.data.showErrorPage) {
                    navigate("/loginerror?token=" + userGmail);
                } else {
                    setErrorMessage(error.response.data.message)
                    if (error.response.data.otpRemainingCount !== undefined) {
                        setAttemptsRemaining(error.response.data.otpRemainingCount);
                    }
                }
            }
        }
    }
    async function resendOtpVerify() {
        const updatedOtp = Array(length).fill('');
        setOTP(updatedOtp);
        setErrorMessage("")
        setButtonDisabled(true)

        try {
            const res = await axiosPrivate.post('resend/otp/setuppassword', { "email": encryptToken(userMail) })
            if (res.status === 200) {
                setSuccessMessage(res.data.message)
            }

        } catch (error: any) {
            if (error.response.data.showErrorPage) {
                navigate("/loginerror?token=" + userGmail);
            } else {
                setErrorMessage(error.response.data.message)
                if (error.response.data.otpRemainingCount !== undefined) {
                    setAttemptsRemaining(error.response.data.otpRemainingCount);
                }
            }
        } finally {
            setButtonDisabled(false)
        }
    }
    async function mobileNumber() {
        try {
            const res = await axiosPrivate.post('user/mobile_number', { "email": encryptToken(userMail) })

            if (res.data?.user.maskedVal) {
                setHideMobileNumber(res.data.user.maskedVal)
            }

        } catch (error) {
        }
    }

    return (
        <div className='otp-verification'>
            <div className='title'>{"OTP Verification"}</div>

            <div className='text'>{"A 6-digit OTP has been sent to your registered " + hideMobileNumber + ". This OTP will expire in 3 mins."}</div>

            <div className={errorMessage ? 'wrong-otp' : 'otp-number'}>
                {otp.map((digit, index) => (
                    <input
                        key={index}
                        type="text"
                        value={digit}
                        maxLength={1}
                        onChange={(e) => handleChange(e, index, "mobileOtp")}
                        onKeyDown={(e) => handleKeyPress(e, index, "mobileOtp")}
                        onPaste={(e) => handlePaste(e, "mobileOtp")}
                        ref={(ref) => (inputRefs.current[index] = ref as HTMLInputElement)}
                    />
                ))}
            </div>
            <div className='resend-text'>Didn't get a OTP?<button className='resend-button' onClick={() => resendOtpVerify()} disabled={isButtonDisabled}>Click to resend.</button></div>
            <div className='verify-btn'>
                <ButtonComponent
                    title={"Verify"}
                    height={"40px"}
                    width={"100%"}
                    disabled={otp.filter((digit) => { return digit === "" }).length > 0}
                    boxShadow={"0px 10px 20px #0066FF80"}
                    backgroundColor={"#FFFFFF"}
                    color={"#0055D4"}
                    handleClick={() => {
                        otpVerify()
                        setIsPasswordSetup(true)
                    }}
                    className={otp.filter((digit) => { return digit === "" }).length > 0 ? "button-component-hover disabled" : "button-component-hover"}
                />
            </div>
            {errorMessage && <p className="error">{errorMessage}</p>}
            {successMessage && <p className="success">{successMessage}</p>}


            <div className='resend-timing'>{"*Attempts remaining: " + attemptsRemaining}</div>
        </div>
    )
}
