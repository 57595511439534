import React, { useEffect, useState, forwardRef, useImperativeHandle, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { resetFormModified } from '../../../../redux/action';
import { axiosPrivate } from '../../../../middleware/axois-api';
import useAxiosPrivate from '../../../../services/hooks/useaxios-private';
import InputComponent from '../../../common-component/form-elements/input-component';
import ButtonComponent from '../../../common-component/form-elements/button-component';
import Changesmodal from '../../../common-component/modals/changes-modal';
import AlertBox from '../../../common-component/alert-box';
import RadioOrCheckbox from '../../../common-component/form-elements/radio-or-checkbox';
import OtpVerifyModal from '../../../common-component/modals/otp-verify-modal';
import useAuth from '../../../../services/hooks/useauth';
import Loading from '../../../common-component/modals/loading-screen';



export interface ChildRef {
  next: (val: any) => void;
}

interface DropdownItem {
  label: string;
  value: string;
}


interface props {
  mainApiData: any
  setMainApiData: (val: any) => void;
  onboardingViewfun: (val: any) => void;
  isEditMode: boolean;
  iconStatus: any;
  editData: any;
  setShowPage: (val: any) => void;
  sendDataApi: () => void;
  setIconStatus: (val: any) => void;
}

const Approval = forwardRef<ChildRef, props>(({ mainApiData, isEditMode, onboardingViewfun, setMainApiData, sendDataApi, iconStatus, editData, setShowPage, setIconStatus }, ref) => {
  const [companyOverviewErrors, setCompanyOverviewErrors] = useState<Record<string, boolean>>({})
  const [isFormDirty, setIsFormDirty] = useState(false)
  const [showType, setShowType] = useState("warning") // error message showType
  const [showMessage, setShowMessage] = useState<string>("") // error message showMessage
  const [showAlertBox, setShowAlertBox] = useState(false) // error message ShowAlertBox
  const isFormModified = useSelector((state: any) => state.isFormModified);
  const dispatch = useDispatch();
  const [showChangesModal, setShowChangesModal] = useState<boolean>(false);
  const [dropDownState, setDropDownState] = useState<DropdownItem[]>([])
  const [otpVerification, setOtpVerification] = useState<boolean>(false);
  const [isLocked, setIsLocked] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(true)

  const { auth } = useAuth();

  const userValidationRules: any = {
    companyData: {
      remarks: {
        regex: (mainApiData && mainApiData?.isActive === "No" && isEditMode) ? /^[^\s].*/ : "",
        field: (mainApiData && mainApiData?.isActive === "No" && isEditMode) ? "mandatory" : "",
        shouldNotBe: ""
      },

      declineRemarks: {
        regex: (mainApiData && mainApiData?.status === "Declined" && isEditMode) ? /^[^\s].*/ : "",
        field: (mainApiData && mainApiData?.status === "Declined" && isEditMode) ? "mandatory" : "",
        shouldNotBe: ""
      },
    }
  }

  const updatedIsValueValidAll: Record<string, boolean> = {};
  const validateForm = (): boolean => {
    const updatedFieldValidity: Record<string, boolean> = {};
    Object.keys(userValidationRules.companyData).forEach((field) => {
      const rule = userValidationRules.companyData[field];
      const value = mainApiData[field];
      const isValueValid = (rule.field === "mandatory") ? (value ? (value !== rule.shouldNotBe) : false) : true;
      const isRegexValid = rule.regex !== "" ? new RegExp(rule.regex).test(value) : true;
      updatedFieldValidity[field] = !isValueValid || !isRegexValid;
      updatedIsValueValidAll[field] = !isValueValid;
    });
    setCompanyOverviewErrors(updatedFieldValidity);
    const isFormValid = Object.keys(updatedFieldValidity).every((keys) => {
      return !updatedFieldValidity[keys]
    });
    return isFormValid;
  };

  function returnGird() {
    onboardingViewfun("grid")
    dispatch(resetFormModified(false));
  }

  useEffect(() => {
    fetchData("state", setDropDownState, "states", "stateId", "name");
  }, [])
  const axiosPrivate = useAxiosPrivate();

  const fetchData = async (url: string, setData: (data: any[]) => void, nestedProperty: string, id: any, representName: any) => {
    try {
      const response = await axiosPrivate.get(url);
      if (response.status === 200) {
        const dropdownValues = response.data[nestedProperty].map((item: any) => ({
          label: item[representName],
          value: item[representName],
          id: item[id],
        }));
        const sortedData = [...dropdownValues].sort((a, b) => a.label.localeCompare(b.label));
        setData([{ label: "Select", value: "Select", id: "" }, ...sortedData]);

      }
    } catch (error) {
    }
  };


  const clearAlert = (status: any) => {
    const timer = setTimeout(() => {
      setShowAlertBox(false);
      setShowMessage("");
      if (status === 400) {

      }
      clearTimeout(timer);
    }, 5000);
  }

  async function duplicateCheck() {
    const finalData = {
      "CompanyEmail": mainApiData.CompanyEmail,
      "CompanyContactNumber": mainApiData.companyData?.ContactNumber
    }

    let statusMessage: boolean = false;
    try {
      const res = isEditMode ? await axiosPrivate.patch(`bank-admin/company/duplicate-check/addressData/${editData?.CompanyData?.companyData?.id}`, finalData) : await axiosPrivate.post("bank-admin/company/duplicate-check/addressData", finalData)
      if (res.status === 200) {
        statusMessage = true;
      }
    }
    catch (error: any) {
      setShowAlertBox(true)
      setShowType("danger")
      setShowMessage(error.response.data.error)
      clearAlert("")
      statusMessage = false;
    }
    return statusMessage;
  }


  async function next() {
    var validateResult = validateForm();
    if (validateResult) {
      if (isEditMode) {
        sendDataApi();
      }
    }
    else {
      setShowAlertBox(true);
      setShowType("danger");
      setShowMessage(Object.values(updatedIsValueValidAll).every((values) => !values) ?
        "Some of the field(s) are not in required format." : "Mandatory field(s) should not be left blank.")
      clearAlert('');
      setIsDisabled(false)
    }
  }



  // warningMsg 
  const clearAleart = (status: any) => {
    const timer = setTimeout(() => {
      setShowAlertBox(false)
      setShowMessage("");
      if (status === 200) {
        // onboardingViewfun("grid")

        // getEditMode(false)
      }
      clearTimeout(timer);
    }, 5000);
  }

  // resendotp function 
  async function resendOtp() {
    let valid = validateForm()
    if (valid) {
      try {
        const res = await axiosPrivate.post('companyadmin/bank/resend-otp-update/approval', {
          approvedBy: auth.token,

        });
        if (res.status === 200) {
          setShowAlertBox(true)
          setShowType("success")
          setShowMessage(res.data.message)
          clearAleart("")
          // updateData()
        }
      } catch (error: any) {
        setShowAlertBox(true);
        setShowType("danger")
        setShowMessage(error.response.data.error)
        clearAleart("")

      }
    }

  }



  // otp function 
  const otpsend = async () => {
    setIsLoading(true)
    try {
      let res = await axiosPrivate.post('companyadmin/bank/otp-update/approval', {
        approvedBy: auth.token,
      });
      if (res.status === 200) {
        setIsLoading(false)
        setShowAlertBox(true)
        setShowType("success")
        setOtpVerification(true)
        setShowMessage(res.data.message)
        clearAleart("")

      }
    } catch (error: any) {
      setIsLoading(false)
      setShowAlertBox(true);
      setShowType("danger")
      setShowMessage(error.response.data.error)
      clearAleart("")

    }
  }

  return (
    <div className='form-wrapper' >
      <div className='form-area-address'>
        <div className='company-heading' >
          Customer Status
        </div>
        <div className='form-main-wrapper' >

          <div className='form-main-end' style={{ width: "100%" }}>
            <div className='radio-input vendor-form-radio-input' style={{ width: "100%" }}>
              <div className=''>Is Active<span className='required'>*</span></div>
              <div className='radio-btn-flex' >
                <RadioOrCheckbox
                  value={"Yes"}
                  type={"radio"}
                  name={"Yes"}
                  margin={"0px 30px 0px 0px"}
                  checkedValue={mainApiData && mainApiData?.isActive}
                  getVal={(value: any) => {
                    setCompanyOverviewErrors({})
                    setMainApiData({
                      ...mainApiData,
                      // ...mainApiData?.customerOverView,
                      isActive: value,
                      remarks: ""

                    })
                    setIconStatus({
                      ...iconStatus,
                      Approval: "selected",
                    })
                    setIsFormDirty(true)
                    dispatch(resetFormModified(true));
                    setIsDisabled(false)

                  }}
                />
                {
                  <div>
                    <RadioOrCheckbox
                      value={"No"}
                      type={"radio"}
                      name={"No"}
                      checkedValue={mainApiData && mainApiData?.isActive}
                      getVal={(value: any) => {
                        setCompanyOverviewErrors({})
                        setMainApiData({
                          ...mainApiData,
                          // ...mainApiData?.customerOverView,
                          isActive: value,

                        })
                        setIconStatus({
                          ...iconStatus,
                          Approval: "selected",
                        })
                        setIsDisabled(false)

                        setIsFormDirty(true)
                        dispatch(resetFormModified(true));

                      }}
                    />
                  </div>
                }
              </div>
            </div>
            {mainApiData?.isActive === "No" &&
              <div style={mainApiData?.isActive === "No" ? { width: "100%" } : { pointerEvents: "none", opacity: "0.5", width: "100%" }}>
                <InputComponent
                  height={"40px"}
                  width={"65%"}
                  margin={"0px 0px 45px 0px"}
                  padding={"0px 0px 0px 10px"}
                  borderRadius={"0px"}
                  backgroundColor={"white"}
                  color={"black"}
                  disabled={(mainApiData && mainApiData?.isActive === "No") ? false : true}
                  type={"text"}
                  placeHolder={"Reason"}

                  inputTitle={"Remarks"}
                  required={true}
                  maxLength={250}
                  border={companyOverviewErrors.remarks ? "1px solid red" : "1px solid #A9C3DC"}
                  inputValue={mainApiData && mainApiData?.remarks}
                  getUser={(value: any) => {
                    const val = value.replace(/[^A-Za-z] /g, "")
                    setMainApiData({
                      ...mainApiData,
                      // ...mainApiData?.customerOverView,
                      remarks: val,

                    })
                    setIconStatus({
                      ...iconStatus,
                      Approval: "selected",
                    })

                    setIsFormDirty(true)
                    dispatch(resetFormModified(true));
                  }}
                />
              </div>
            }
          </div >

        </div>
        <div className='company-heading'>
          Approval
        </div>
        <div>
          <div className='approval-request'>{"This new request required your approval."}</div>
          <div className='radio-btn-transction-limit'>
            <div className='radio-input user-form-radio-input'  >
              <div className='radio-btn-transction-limit' style={{ display: "flex", marginTop: "15px" }}>
                <RadioOrCheckbox
                  value={"Approved"}
                  type={"radio"}
                  label={"Approve"}
                  name={"customLimits"}
                  margin={"0px 30px 0px 0px"}
                  checkedValue={mainApiData && mainApiData?.status}
                  getVal={(val) => {
                    setIsFormDirty(true)
                    setMainApiData({
                      ...mainApiData,
                      status: val,
                      declineRemarks: ""

                    })
                    setIconStatus({
                      ...iconStatus,
                      Approval: "selected",
                    })

                    setCompanyOverviewErrors({ declineRemarks: false })
                    dispatch(resetFormModified(true));
                    setIsDisabled(false)

                  }}
                />
                <div>
                  <RadioOrCheckbox
                    value={"Declined"}
                    name={"customlimits"}
                    type={"radio"}
                    label={"Decline"}
                    checkedValue={mainApiData && mainApiData?.status}
                    getVal={(val) => {
                      setIsFormDirty(true)
                      setMainApiData({
                        ...mainApiData,

                        // ...mainApiData?.companyData,
                        status: val,

                      })
                      setIconStatus({
                        ...iconStatus,
                        Approval: "selected",
                      })

                      dispatch(resetFormModified(true));
                      setIsDisabled(false)

                    }}

                  />
                </div>
              </div>
            </div>
          </div>
          <div style={
            isEditMode && mainApiData && mainApiData?.status === "Declined" ?
              { pointerEvents: "auto", opacity: 1, width: "100%" } : { pointerEvents: "none", opacity: 0.5, width: "100%" }
          }>
            <InputComponent
              height={"50px"}
              width={"65%"}
              margin={"0px 60px 30px 0px"}
              padding={"0px 0px 0px 10px"}
              border={companyOverviewErrors.declineRemarks ? "1px solid red" : "1px solid #A9C3DC"}
              borderRadius={"0px"}
              backgroundColor={"white"}
              color={"black"}
              maxLength={250}
              type={"text"}
              disabled={mainApiData && mainApiData?.status === "Declined" ? false : true}
              inputTitle={"Reason for Declining Request"}
              required={true}
              placeHolder={"Reason"}
              inputValue={mainApiData && mainApiData?.declineRemarks}
              getUser={(value: any) => {
                setIsFormDirty(true)
                setMainApiData({
                  ...mainApiData,
                  declineRemarks: value,
                })
                setIconStatus({
                  ...iconStatus,
                  Approval: "selected",
                })

                dispatch(resetFormModified(true));
              }}
            />
          </div>
        </div>




        <div>
          <div className='footer-company'>
            <ButtonComponent
              title={"Cancel"}
              height={"50px"}
              width={"150px"}
              margin={"0px 8px"}
              backgroundColor={"#888888"}
              color={"white"}
              className={"button-component-hover cancel"}
              handleClick={isFormModified ? () => { setShowChangesModal(true) } : returnGird}
            />


            <ButtonComponent
              title={"Save"}
              height={"50px"}
              width={"150px"}
              backgroundColor={"#0055D4"}
              color={"white"}
              disabled={(showAlertBox && showType === "success" || isDisabled) ? true : false}
              margin={"0px 8px"}
              className={(showAlertBox && showType === "success" || isDisabled) ? "button-component-hover disabled" : "button-component-hover common-btn"}
              handleClick={async () => {
                const isValid = validateForm();
                if (isValid) {
                  if ((isEditMode && mainApiData && mainApiData?.status === "Approved") || (editData.customerDetails?.customerOverView?.isActive !== mainApiData.isActive) && (isFormDirty === true)) {
                    otpsend()
                    setIsDisabled(true)
                  } else {
                    next()
                    setIsDisabled(true)
                  }
                } else {
                  setShowAlertBox(true);
                  setShowType("danger");
                  setShowMessage(Object.values(updatedIsValueValidAll).every((values) => !values) ?
                    "Some of the field(s) are not in required format." : "Mandatory field(s) should not be left blank.")
                  clearAlert('');
                  // next()
                }
              }}
            />
          </div>
        </div>

      </div>


      {
        otpVerification &&
        <OtpVerifyModal
          otpVerify={resendOtp}
          leavePage={() => {
            setOtpVerification(false)
            // virtualCard("form")
            onboardingViewfun("form")

          }}
          isLocked={isLocked}
          setIsLocked={setIsLocked}

          getStatus={async (stringFromArray) => {
            let res;
            try {

              res = await axiosPrivate.post('companyadmin/bank/verify-otp/update', {
                otp: stringFromArray,
                approvedBy: auth.token,

              });
              if (res.status === 200) {
                // updateData()
                setIsLocked(true)
                next()

              }
            } catch (error: any) {
              setShowAlertBox(true);
              setShowType("danger")
              setShowMessage(error.response.data.error)
              clearAleart("")
            }
          }
          }
        />
      }
      {
        showAlertBox &&
        <div className='alert-warp'>
          <AlertBox type={showType} message={showMessage} />
        </div>
      }
      {
        isLoading &&
        <Loading />
      }
      {
        showChangesModal &&
        <Changesmodal
          closeModal={() => setShowChangesModal(false)}
          handleClose={() => {
            setShowChangesModal(false);
          }}
          leavePage={() => returnGird()} />
      }
    </div>

  )
})
export default Approval