import MainPageCard from '../../common-component/cards/main-page-card'
import NavigateHeader from '../../common-component/navigate-header'
import IconButton from '../../common-component/form-elements/icon-button'
import "../../../styles/onboarding/company/company-grid.scss"
import AgGrid, { AgGridRef } from '../../common-component/grids-and-tables/ag-grid'
import { companyColumnData } from '../../../config/onboarding/company-grid';
import React, { useEffect, useState, useRef } from 'react'
import FileUpload from '../../common-component/modals/file-upload-modal'
import GridFilter from '../../common-component/grids-and-tables/grid-filter'
import { v4 as uuidv4 } from 'uuid'
import { axiosPrivate } from '../../../middleware/axois-api'
import DeleteModal from '../../common-component/modals/delete-modal'
import AlertBox from '../../common-component/alert-box'
import { useDispatch, useSelector } from "react-redux";
import Loading from '../../common-component/modals/loading-screen'
import { Filter } from '../../../helpers/filtterFunction'

const templateLink = "bulkupload_templates/Company-bulkUpload.xlsx";
interface Props {
  onboardingViewfun: (val: any) => void;
  getEditData: (nav: any, data: any) => void;
  addMode: () => void;

}
interface IFilterValue {
  [key: string]: {
    columnName: string;
    filterType: string;
    filterWord: string;
  };
}
export default function CompanyGrid({ onboardingViewfun, getEditData, addMode }: Props) {
  const [filter, setFilter] = useState(false);
  const [fileUpload, setFileUpload] = useState(false);
  const [filterRestore, setFilterRestore] = useState<IFilterValue>({})
  const [filterMethod, setFilterMethod] = useState("")
  const [curruntData, setCurruntData] = useState<any>([]);
  const [rowData, setRowData] = useState<any>([]);
  const [orginalRowData, setOrginalRowData] = useState<any>([]);
  const companyDownloadRef = useRef<AgGridRef>(null);
  const [showType, setShowType] = useState("warning") // error message showType
  const [showMessage, setShowMessage] = useState<string>("") // error message showMessage
  const [showAlertBox, setShowAlertBox] = useState(false) // error message ShowAlertBox
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
  const isFormModified = useSelector((state: any) => state.isFormModified);
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [userPrivilege, setUserPrivilege] = useState<any>("");

  useEffect(() => {
    fetchData()
  }, []);

  async function getUserData(id: string) {
    await axiosPrivate.get(`bank-admin/company/${id}`)
      .then(response => {
        if (getEditData) {
          getEditData("form", response.data.results)
        }
      })
      .catch(error => {
        console.error(error);
      });
  }

  const clearValue = (val: any) => {
    setFileUpload(val);
  }
  // clearAleart
  const clearAleart = () => {
    const timer = setTimeout(() => {
      setShowAlertBox(false);
      setShowMessage("");
      clearTimeout(timer);
    }, 5000);
  }

  const getUploadedUserData = (val: any) => {
  }

  const UploadMessage = (message: any, type: any) => {

    if (type === "success") {

      setShowMessage(message)

      setShowAlertBox(true);

      setShowType("success");

      clearAleart()

    } else if (type === "warning") {

      setShowMessage(message)

      setShowAlertBox(true);

      setShowType("warning");

      clearAleart()

    }



  }


  const getCurruntData = (val: any) => {
    setCurruntData(val);
  }



  // Function for Filter values
  const handleFilter = (value: any, filterType: string) => {
    Filter(value, filterType, orginalRowData, setRowData)
  }

  const fetchData = async () => {
    try {
      const response = await axiosPrivate.get('bank-admin/company/grid/values');
      setRowData(response.data);
      setOrginalRowData(response.data)
    } catch (error) {
    }
  };
  const handleDownloadExcel = () => {
    companyDownloadRef.current!.onBtExport();
  }


  const getDelete = async () => {
    if (curruntData.length === 1) {
      axiosPrivate.patch(`bank-admin/company/delete/${curruntData[0].id}`)
        .then(response => {
          if (response.status === 200) {
            setShowAlertBox(true)
            setShowType("success")
            setShowMessage(response.data.message)
            clearAleart()
            setShowDeleteModal(false)
            fetchData()
            setCurruntData([]);
          }
        })
        .catch(error => {
          setShowAlertBox(true);
          setShowType("warning")
          setShowMessage(error.response.data.error)
          setShowDeleteModal(false)
          clearAleart()
        });
    } else {
      let idList: any = [];
      curruntData.map((e: any, i: number) => {
        idList.push(e.id);
      })
      try {
        const res = await axiosPrivate.patch("bank-admin/company/multipledelete/delete", { companyIds: idList })
        if (res.status === 200) {
          setShowAlertBox(true)
          setShowType("success")
          setShowMessage(res.data.message);
          clearAleart();
          setShowDeleteModal(false)
          fetchData()
          setCurruntData([]);
        }
      } catch (error: any) {
        setShowAlertBox(true)
        setShowType("danger")
        setShowMessage(error.response.data.error)
        clearAleart()
        setShowDeleteModal(false)
      }
    }
  };


  useEffect(() => {
    const fetchPrivillageData = async () => {
      try {
        const response = await axiosPrivate.get('/user/get-privilege');
        setUserPrivilege(response.data.UserPrivilege)

      } catch (error) {
      }
    };
    fetchPrivillageData();
  }, []);


  return (
    <MainPageCard >
      <div className='header-grid'>
        <div className='left-header'>
          <NavigateHeader firstValue={userPrivilege === "FingertipAdmin" ? "Fingertip Admin" : "Approval"}
            secondValue={"Company Onboarding"}
          />
        </div>
        <div className='icon-buttons'>
          <IconButton
            iconName={"Filter"}
            height={"40px"}
            width={"40px"}
            fontSize={"30px"}
            margin={"0px 8px"}
            color={"white"}
            backgroundColor={"#0055D4"}
            hover={true}
            handleClick={(event: React.MouseEvent) => {
              event?.stopPropagation()
              setFilter(!filter);
            }}
          />
          <IconButton
            iconName={"Delete"}
            height={"40px"}
            width={"40px"}
            fontSize={"25px"}
            margin={"0px 8px"}
            color={"white"}
            backgroundColor={"#D60000"}
            hover={curruntData.length > 0}
            opacity={curruntData.length > 0 ? "1" : "0.5"}
            cursor={curruntData.length > 0 ? "pointer" : "default"}
            handleClick={() => {
              if (curruntData.length > 0) {
                setShowDeleteModal(!showDeleteModal)
              }
            }}
          />

          <IconButton
            iconName={"Add"}
            height={"40px"}
            width={"75px"}
            fontSize={"25px"}
            margin={"0px 8px"}
            color={"white"}
            opacity={"0.5"}
            disabled={true}
            cursor={"not-allowed"}
            backgroundColor={"#0055D4"}
            hover={true}
            handleClick={() => {
              // onboardingViewfun("form");
              // addMode();
            }}
          />
          <IconButton
            iconName={"Upload"}
            height={"40px"}
            width={"40px"}
            fontSize={"25px"}
            margin={"0px 0px 0px 8px"}
            color={"white"}
            backgroundColor={"#0055D4"}
            hover={true}
            // opacity={"0.5"}
            // cursor={"not-allowed"}
            handleClick={() => {
              setFileUpload(true)
            }}
          />
          <IconButton
            iconName={"Download"}
            height={"40px"}
            width={"40px"}
            fontSize={"25px"}
            margin={"0px 8px"}
            color={"white"}
            backgroundColor={"#0055D4"}
            hover={true}
            handleClick={() => { handleDownloadExcel() }}
          />
        </div>
      </div>
      <div>
        <AgGrid
          tabelRowData={rowData}
          tableColumnData={companyColumnData}
          ref={companyDownloadRef}
          fileName={"CompanyData"}
          getCurruntData={getCurruntData}
          doubleClickUpdate={() => { getEditData && getEditData("form", "") }}
          getEdit={(data) => getUserData(data.id)}
        />
      </div>
      {
        fileUpload &&
        <FileUpload
          template={templateLink}
          loadingScreenFunction={(val: boolean) => { setIsLoading(val) }}
          gridUpdate={() => { fetchData() }}
          setshowUploadMessage={UploadMessage}
          clearValue={clearValue}
          headerData={companyColumnData}
          templateName={"User"}
          getUploadedUserData={getUploadedUserData}
          apiUrl={'bulk-upload/company'}
          excelFileName={"Company-BulkUpload-Status"}
        />
      }
      {
        filter &&
        <div className='filter' key={uuidv4()}>
          <GridFilter
            filterRestore={filterRestore}
            filterMethod={filterMethod}
            tabelRowData={rowData}
            data={companyColumnData.slice(1)}
            handleClick={(Value: any, filterType: string) => {
              if (filterType !== "") {
                setFilterRestore(Value)
                setFilter(!filter)
                setFilterMethod(filterType)
                handleFilter(Value, filterType)
                setCurruntData([]);

              } else {
                setFilterMethod("")
                setFilterRestore({})
                setFilter(!filter)
                setRowData(orginalRowData)
                setCurruntData([]);
              }
            }}
          />
        </div>
      }
      {
        showDeleteModal &&
        <DeleteModal
          modelType={"grid-delete"}
          getDelete={getDelete}
          clearValue={(value) => {
            setShowDeleteModal(value)
          }}
        />
      }
      {
        showAlertBox &&
        <div className='alert-warp'>
          <AlertBox type={showType} message={showMessage} />
        </div>
      }
      {
        isLoading &&
        <Loading />
      }
    </MainPageCard>

  )
}




