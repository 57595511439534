import React, { useEffect, useState } from 'react'
import MainPageCard from '../../common-component/cards/main-page-card'
import NavigateHeader from '../../common-component/navigate-header'
import ButtonComponent from '../../common-component/form-elements/button-component';
import '../../../styles/approval/utility-approval/form.scss'
import IconButton from '../../common-component/form-elements/icon-button';
import { RxCross1 } from 'react-icons/rx';
import DeleteModal from '../../common-component/modals/delete-modal';
import AlertBox from '../../common-component/alert-box';
import DropdownComponent from '../../common-component/form-elements/dropdown-component';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch } from 'react-redux';
import { resetFormModified } from '../../../redux/action';
import CardPanel from '../../common-component/cards/card-panel';
import { cardDetails } from '../../payable/vendor/vendor-form';
import dummyCard from '../../../assets/images/dummy-card.png';

interface ApprovalFormValues {
    // SetSelectedPaymentMethod: (val: any) => void

    utilityFun: (val: string) => void;
    // cards: cardDetails[];

}
export default function UtilityForm({ utilityFun }: ApprovalFormValues) {
    const [showDeleteModel, setShowDeleteModel] = useState<boolean>(false) //Delete Modal
    const [showType, setShowType] = useState("warning") // error message showType
    const [showMessage, setShowMessage] = useState<string>("") // error message showMessage
    const [showAlertBox, setShowAlertBox] = useState(false) // error message ShowAlertBox
    const [accountSummary, setAccountSummary] = useState<any>([{
        AccountName: "4561237890",
        consumerName: "KQ",
        billDate: "3/3/2023",
        dueDate: "3/7/2023",
        amount: "2,000.00",
        approvalStatus: "Pending Approval",
        paymentStatus: "Unpaid",
        boardProvider: "Tamil Nadu Electricity Board (TNEB)",


    },
    {
        AccountName: "4561237890",
        consumerName: "KQ",
        billDate: "3/3/2023",
        dueDate: "3/7/2023",
        amount: "2,000.00",
        approvalStatus: "Pending Approval",
        paymentStatus: "Unpaid",
        boardProvider: "Tamil Nadu Electricity Board (TNEB)",


    }])
    const returnGird = () => {
        utilityFun("UtilityGrid")
    }


    const PaymentMethodDD = [{ label: "Corporate Card", value: "Corporate Card", id: "1" }, { label: "Net Banking", value: "Net Banking", id: "2" }, { label: "Virtual Card", value: "Virtual Card", id: "3" },]
    const dispatch = useDispatch();
    const handleSelectedCard = (card: any, index: number) => {
        // SetSelectedPaymentMethod((selectedPaymentMethod: any) => ({
        //     ...selectedPaymentMethod,
        //     cardId: card.cardId,
        // }));
    }
    return (
        <>
            <MainPageCard>
                <div>
                    <div className='header-main'>
                        <div className='navigater'>
                            <div className='navigate-header-component'>
                                <NavigateHeader
                                    style={{ cursor: "pointer" }}
                                    firstValue={"Approval"}
                                    secondValue={"Utility Payment"}
                                    navigatePage={() => { returnGird() }}
                                />
                            </div>
                        </div>
                    </div>
                    {/* <div className={"select-payment-method"}>
                        <div style={{
                            display: "flex", justifyContent: "center", marginTop: "70px", position: "relative", zIndex: 3
                        }}>
                            <div className={false ? 'setError' : 'dropdown-vendor-pay-plus'}
                                style={false ? { pointerEvents: "none", opacity: "0.5", cursor: "auto" } : {}}>
                                <DropdownComponent key={uuidv4()}
                                    options={PaymentMethodDD}
                                    title={"Select Payment Method"}
                                    isDisabled={true}
                                    required={true}
                                    width={'350px'}
                                    getData={(value: any) => {
                                        // SetSelectedPaymentMethod((selectedPaymentMethod: any) => ({
                                        //     ...selectedPaymentMethod,
                                        //     PaymentMethod: value.value,
                                        //     cardId: "",
                                        // })); 
                                        dispatch(resetFormModified(true));
                                    }}
                                    defaultValue={[{ label: "Select", value: "" }]}
                                />
                            </div>
                            {
                                // selectedPaymentMethod.PaymentMethod === "Corporate Card" &&
                                <>
                                    <div>
                                        <p className={"select-card"}>{"Select Card"}</p>
                                    </div>
                                    <div className={"Card-main-wrapper"}>
                                        <div className={"Card-sub-wrapper"}>
                                            {
                                                cards.length === 0 ?
                                                    <div className='select-payment-dummy-card-container'>
                                                        <img src={dummyCard} className='select-payment-dummy-card-image' />
                                                        <div className='select-payment-dummy-card-content'>{"You have no cards added"}</div>
                                                    </div>
                                                    : cards.map((card: any, index: number) => (
                                                        <div onClick={() => handleSelectedCard(card, index)}
                                                            className={"inActiveCard"}
                                                        // selectedPaymentMethod.cardId === card.cardId ? "activeCardId" : 
                                                        >
                                                            <CardPanel
                                                                key={index} // Make sure to provide a unique key for each element in the array
                                                                bankName={card.bankName}
                                                                percentage={card.percentage}
                                                                limit={card.limit}
                                                                insideCardBalance={card.insideCardBalance}
                                                                lastBilledDue={card.lastBilledDue}
                                                                cardNumber={card.cardNumber}
                                                                cardNetwork={card.cardNetwork}
                                                                dueIn={card.dueIn}
                                                                billingCycle={card.billingCycle}
                                                                processing={card.processing}
                                                                outsideCardBalance={card.outsideCardBalance}
                                                                backgroundColor={card.backgroundColor}
                                                            />
                                                        </div>
                                                    ))}
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                    </div> */}
                    <div>
                        <div className='utility-sync-main'>
                            <div className="right-details" >
                                <div style={{ textAlign: "center" }}>
                                    <p className='details-number' ><b> 2</b></p>
                                    <p>
                                        Total Bills
                                    </p>
                                </div>
                                <div style={{ textAlign: "center" }}>
                                    <p className='details-number'> <b>
                                        ₹ 5,0000.00 </b></p>
                                    <p>
                                        Total Amount
                                    </p>
                                </div>
                                <div style={{ textAlign: "center" }}>
                                    <p className='details-number'>
                                        <b>₹ 10,000.00</b>
                                    </p>
                                    <p>
                                        Revised Amount
                                    </p>
                                </div>
                            </div>
                            <div style={{ paddingRight: "15px" }}>
                                <ButtonComponent
                                    title={"Sync Now"}
                                    height={"50px"}
                                    width={"150px"}
                                    margin={"0px 8px"}
                                    backgroundColor={"#0055D4"}
                                    color={"white"}
                                    className={"button-component-hover common-btn"}
                                    handleClick={() => { returnGird() }}
                                />
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className='view-table-component admin-details-table' style={{ marginTop: "20px", overflowX: "auto", minHeight: "70px", maxHeight: "166px" }}>
                            <table>
                                <thead>
                                    <th className='access left-align'>Service Number</th>
                                    <th className='access left-align'>Consumer Name</th>
                                    <th className='access left-align' >Bill Date</th>
                                    <th className='access left-align' >Due Date</th>
                                    <th className='access left-align' >Amount(₹)</th>
                                    <th className='access left-align' >Approval Status</th>
                                    <th className='access left-align' >Payment Status</th>
                                    <th className='access left-align' >Board/Provider</th>
                                    <th className='access center-align' >Acton</th>
                                </thead>
                                <tbody>
                                    {accountSummary?.length === 0 ?
                                        <tr className='main-body-header'>
                                            <td></td>
                                            <td></td>
                                            <td colSpan={2} style={{ textAlign: "center" }}>No records to show</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr> :
                                        accountSummary[0]?.name !== "" &&
                                        accountSummary.map((data: any, index: number) => (
                                            <tr className='main-body-header' key={index} onClick={(e) => {
                                                const ele = e.target as HTMLElement;
                                                if (ele.tagName.toLocaleLowerCase() !== "svg" && ele.tagName.toLocaleLowerCase() != "g" && ele.className !== "icon-button") {

                                                }
                                            }}>
                                                <td className='first-name left-align'>{data.serviceNumber}</td>
                                                <td className='left-align'>{data.consumerName}</td>
                                                <td className='left-align'>{data.billDate}</td>
                                                <td className='left-align'>{data.dueDate}</td>
                                                <td className='left-align'>{data.amount}</td>
                                                <td className='left-align'>{data.approvalStatus}</td>
                                                <td className='left-align'>{data.paymentStatus}</td>
                                                <td className='left-align'>{data.boardProvider}</td>

                                                <td >
                                                    <div className='last-icon' style={{ justifyContent: "center" }}>
                                                        <IconButton
                                                            iconName={"ShowEye"}
                                                            height={"35px"}
                                                            width={"30px"}
                                                            fontSize={""}
                                                            className={"icons"}
                                                            cursor={"pointer"}
                                                            color={"white"}
                                                            backgroundColor={""}
                                                            hover={false}
                                                            handleClick={() => {
                                                                // setElectricityHistory(true)
                                                                // retriveData(data, index)

                                                            }}
                                                        />
                                                        <IconButton
                                                            iconName={"Delete"}
                                                            height={"30px"}
                                                            width={"30px"}
                                                            fontSize={"25px"}
                                                            margin={"0px 8px"}
                                                            color={"white"}
                                                            backgroundColor={"#D60000"}
                                                            hover={true}
                                                            handleClick={() => {
                                                                setShowDeleteModel(true)
                                                                // setIdData(data.userId)
                                                            }}
                                                        />

                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div className='utility-approval-footer'>
                            <ButtonComponent
                                title={"Previous"}
                                height={"50px"}
                                width={"150px"}
                                backgroundColor={"#0055D4"}
                                disabled={false}
                                color={"white"}
                                margin={"0px 8px"}
                                className={"button-component-hover common-btn"}
                                handleClick={() => {
                                    returnGird()
                                }
                                }
                            />
                            <ButtonComponent
                                title={
                                    <>
                                        <RxCross1 color={true ? '#0055D4' : "#ffff"}
                                            style={{ verticalAlign: "middle", marginRight: "5px" }}
                                            className='add-icon' />   Decline
                                    </>}
                                height={"50px"}
                                width={"150px"}
                                backgroundColor={"white"}
                                border={!true ? "" : "1px solid #0055D4"}
                                color={true ? '#0055D4' : "#ffff"}
                                margin={"0px 8px"}
                                className={!true ? "button-component-hover disabled" : "button-component common-btn"}
                                handleClick={() => {
                                    // setDeclinePopUp(true);
                                }}
                            />
                            <div>
                                <ButtonComponent
                                    title={"Make Payment"}
                                    height={"50px"}
                                    width={"150px"}
                                    backgroundColor={"#0055D4"}
                                    disabled={false}
                                    color={"white"}
                                    margin={"0px 8px"}
                                    className={"button-component-hover common-btn"}
                                    handleClick={() => {

                                    }
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </MainPageCard>
            {
                showAlertBox &&
                <div className='alert-warp'>
                    <AlertBox type={showType} message={showMessage} />
                </div>
            }
            {
                showDeleteModel &&
                <div >
                    <DeleteModal
                        getDelete={() => {
                            setShowDeleteModel(false)
                            // checkDelete(idData, deleteIndex)
                            // clearValue(false)
                        }}
                        clearValue={(value) => { setShowDeleteModel(value) }}
                        modelType={"grid-delete"}
                    />
                </div>
            }
        </>
    )
}
