import React, { useEffect } from 'react';
import '../../../styles/modals/transaction-show-modal.scss';
// import '../../../styles/modals/transaction-show-modal.scss';
import { RxCross1 } from 'react-icons/rx';
import ButtonComponent from '../form-elements/button-component';

interface Styles {
    clearValue: (val: any) => void;
    handleTransactionData: any
    transactionDetails: any
    rateCardDetails: any
}

export default function TransactionShowModal({
    clearValue,
    handleTransactionData,
    transactionDetails,
    rateCardDetails 
}: Styles) {

    // Function for Getting Transaction Data Modal ------------------>
    useEffect(() => {
        handleTransactionData()
    }, [])



    return (
        <div className={"transaction-show-modal"}>
            <div className={"transaction-container-modal"}>
                <div className={"close-icon"}>
                    <span className={"cross-icon"}>
                        <RxCross1 onClick={() => clearValue(false)
                        } style={{ fontSize: "40px" }} />
                    </span>
                </div>

                <div className="sub-container">
                    <div className='main-heading'>{"Transaction Charges"}</div>

                    <div className={"main-content"}>

                        {/* <div className={"main-area"}>
                            <div className={"sub-area"}>
                                <div className='sub-content'>
                                    <div className={"transaction-details"}>{transactionDetails.paymentGateway}</div>
                                    <div>{"Payment Gateway"}</div>
                                </div>
                                <div className='sub-content'>
                                    <div className={"transaction-details"}>{transactionDetails.commercialCard}</div>
                                    <div>{"Commercial Card%"}</div>
                                </div>
                                <div className='sub-content' style={{ width: "35%" }}>
                                    <div className={"transaction-details transaction-number"}>{transactionDetails.pgCharge}</div>
                                    <div>{"PG Chg%"}</div>
                                </div>
                                <div className='sub-content' style={{ width: "35%" }}>
                                    <div className={"transaction-details transaction-number"}>{transactionDetails.pgGst}</div>
                                    <div>{"PG GST%"}</div>
                                </div>
                                <div className='sub-content' style={{ width: "35%" }}>
                                    <div className={"transaction-details transaction-number"}>{transactionDetails.netBanking}</div>
                                    <div>{"Net Banking%"}</div>
                                </div>
                                <div className='sub-content' style={{ width: "35%" }}>
                                    <div className={"transaction-details transaction-number"}>{transactionDetails.platformCharge}</div>
                                    <div>{"Platform chg%"}</div>
                                </div>
                                <div className='sub-content' style={{ width: "35%" }}>
                                    <div className={"transaction-details transaction-number"}>{transactionDetails.platformGst}</div>
                                    <div>{"Platform GST%"}</div>
                                </div>
                                <div className='sub-content' style={{ width: "35%" }}>
                                    <div className={"transaction-details transaction-number"}>{transactionDetails.virtualCard}</div>
                                    <div>{"Virtual Card₹"}</div>
                                </div>
                                <div className='sub-content' style={{ width: "20%" }}>
                                    <div className={"transaction-details transaction-number"}>{transactionDetails.upi}</div>
                                    <div>{"UPI%"}</div>
                                </div>
                            </div>
                        </div> */}
                        {
                            transactionDetails && Object.keys(transactionDetails).length > 0 ? (
                                <div className="main-area">
                                    <div className="sub-area">
                                        <div className="sub-content">
                                            <div className="transaction-details">{transactionDetails?.paymentGateway || 'N/A'}</div>
                                            <div>{"Payment Gateway"}</div>
                                        </div>
                                        <div className="sub-content">
                                            <div className="transaction-details">{transactionDetails?.commercialCard || 'N/A'}</div>
                                            <div>{"Commercial Card"}</div>
                                        </div>
                                        <div className="sub-content" style={{ width: "35%" }}>
                                            <div className="transaction-details transaction-number">{parseFloat(transactionDetails?.pgCharge).toFixed(2) || '-'}</div>
                                            <div>{"PG Charges %"}</div>
                                        </div>
                                        <div className="sub-content" style={{ width: "35%" }}>
                                            <div className="transaction-details transaction-number">{parseFloat(transactionDetails?.pgGst).toFixed(2) || '-'}</div>
                                            <div>{"PG GST %"}</div>
                                        </div>
                                        <div className="sub-content" style={{ width: "45%" }}>
                                            <div className="transaction-details transaction-number">{parseFloat(transactionDetails?.platformCharge).toFixed(2) || '-'}</div>
                                            <div>{"Platform charges %"}</div>
                                        </div>
                                        <div className="sub-content" style={{ width: "35%" }}>
                                            <div className="transaction-details transaction-number">{parseFloat(transactionDetails?.platformGst).toFixed(2) || '-'}</div>
                                            <div>{"Platform GST %"}</div>
                                        </div>
                                        <div className="sub-content" style={{ width: "35%" }}>
                                            <div className="transaction-details transaction-number">{parseFloat(transactionDetails?.netBanking).toFixed(2) || '-'}</div>
                                            <div>{"Net Banking %"}</div>
                                        </div>
                                        <div className="sub-content" style={{ width: "35%" }}>
                                            <div className="transaction-details transaction-number">{parseFloat(transactionDetails?.virtualCard).toFixed(2) || '-'}</div>
                                            <div>{"Virtual Card ₹"}</div>
                                        </div>
                                        <div className="sub-content" style={{ width: "20%" }}>
                                            <div className="transaction-details transaction-number">{parseFloat(transactionDetails?.upi).toFixed(2) || '-'}</div>
                                            <div>{"UPI %"}</div>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div style={{ display: "flex", justifyContent: "center", alignItems: 'center', height: "330px" }}>
                                    No records found
                                </div>
                            )
                        }


                        {
                            rateCardDetails.length > 0 &&
                            <>
                                <div style={{ fontWeight: "bold", fontSize: "24px", marginTop: "10px", marginBottom: "10px" }}>{"Variable"}</div>
                                <div className='kyc-transaction-table'>
                                    <div className='document-table-component' style={{ height: "181px" }} >
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th className='from-row' style={{ position: "relative", left: "30px" }}>From ₹</th>
                                                    <th className='to-row'>To ₹</th>
                                                    <th className='mdr-row' style={{ position: "relative", right: "30px" }}>MDR %</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {rateCardDetails.map((data: any, i: any) => (
                                                    <div className={'main-body-header'} key={i}>
                                                        <tr>
                                                            <td className={'from-row'} style={{ textAlign: "right", minWidth: "5%" }}>{data.from} </td>
                                                            <td className={'to-row'} style={{ textAlign: "right", minWidth: "5%", position: "relative", right: "50px" }}>{data.to} </td>
                                                            <td className={"mdr-row"} style={{ textAlign: "right", position: "relative", right: "20px" }}>{data.mdr}</td>
                                                        </tr>
                                                    </div>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </>
                        }

                    </div>

                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "30px" }}>
                        <ButtonComponent
                            title={"Ok"}
                            height={"40px"}
                            width={"130px"}
                            backgroundColor={"#0055D4"}
                            color={"white"}
                            margin={"0px 8px"}
                            disabled={false}
                            className={"button-component common-btn"}
                            handleClick={() => {
                                clearValue(false)
                            }}
                        />
                    </div>
                </div>


            </div>
        </div>
    )
}



