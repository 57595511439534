import CustomEllipsisRenderer from "../product-admin/users/users-aggrid-column-config"

export const virtualCardColumnData = [
    {
        field: 'selection', headerName: '', minWidth: 60, maxWidth: 60, resizable: false, suppressMovable: true, filter: "agSetColumnFilter", suppressSizeToFit: false, headerCheckboxSelection: true, checkboxSelection: true, showDisabledCheckboxes: true, suppressRowTransform: true, cellStyle: { textAlign: 'center' }
    },
    {
        field: 'requestNumber', headerName: 'Request Number', minWidth: 200, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "number", cellRendererFramework: CustomEllipsisRenderer,
    },
    {
        field: 'employeeName', headerName: 'Employee Name', minWidth: 200, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer,
    },
    {
        field: 'vendorName', headerName: 'Vendor Name', minWidth: 200, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer,
    },
    {
        field: 'createDate', headerName: 'Requested Date', minWidth: 150, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer,
    },
    {
        field: 'primaryAccountNo', headerName: 'Primary Account No', minWidth: 200, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "number", cellRendererFramework: CustomEllipsisRenderer,
    },
    {
        field: 'status', headerName: 'Status', minWidth: 200, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellClassRules: {
            'success-cell': (params: any) => params.value === 'Approved',
            'registered-cell': (params: any) => params.value === 'Pending Approval',
            'denied-cell': (params: any) => params.value === 'Declined',
        }, cellRendererFramework: CustomEllipsisRenderer,
    },
    
    {
        field: 'approvedBy', headerName: 'Approved / Declined by', minWidth: 200, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer,
    },
    {
        field: 'validFromDate', headerName: 'Valid From', minWidth: 200, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer,
    },
    {
        field: 'validUpToDate', headerName: 'Valid Upto', minWidth: 200, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer,
    },
    {
        field: 'amount', headerName: 'Amount', minWidth: 200, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "number", cellRendererFramework: CustomEllipsisRenderer,
    },
    {
        field: 'particulars', headerName: 'Particulars', minWidth: 200, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer,
    },
    



]


export const rowDatathis = [
    {
        employeeName: "Arun",
        vendorName: "KQ",
        Date: "03/05/2023",
        primaryAccountNo: "78945612301",
        particulars: "Travel",
        validFrom: "03/05/2023",
        validUpto: "03/05/2023",
        amount: "70000.00",
        Status: "Processing",


    }, {
        employeeName: "Arun",
        vendorName: "KQ",
        Date: "03/05/2023",
        primaryAccountNo: "78945612301",
        particulars: "Travel",
        validFrom: "03/05/2023",
        validUpto: "03/05/2023",
        amount: "70000.00",
        Status: "Processing",

    }, {
        employeeName: "Arun",
        vendorName: "KQ",
        Date: "03/05/2023",
        primaryAccountNo: "78945612301",
        particulars: "Travel",
        validFrom: "03/05/2023",
        validUpto: "03/05/2023",
        amount: "70000.00",
        Status: "Processing",

    }
    , {
        employeeName: "Arun",
        vendorName: "KQ",
        Date: "03/05/2023",
        primaryAccountNo: "78945612301",
        particulars: "Travel",
        validFrom: "03/05/2023",
        validUpto: "03/05/2023",
        amount: "70000.00",
        Status: "Processing",

    }


]