import { useEffect, useRef, useState } from 'react'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie, getElementAtEvent } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

interface type {
  width:string;
  height:string;
  options:any;
  data:any;
  orginalBarchartData?: any;
  clickedEvent?: any;
  HandleBarChartClcik?: (barValue:string,label:string)=>void;
}

const PieChart = ({width, height, options, data,HandleBarChartClcik,clickedEvent, orginalBarchartData}:type) => {  
  const chartRef:any = useRef();
 
  const onClick = (event: any) => {
    if (chartRef.current && getElementAtEvent(chartRef.current, event)[0] && clickedEvent) {
      const selectedIndex:any =  getElementAtEvent(chartRef.current, event)[0].index;
      const selectedDatasetIndex:any = getElementAtEvent(chartRef.current, event)[0].datasetIndex;     
      const selectBarValue = orginalBarchartData.datasets[selectedDatasetIndex].label
      const selectLabel = orginalBarchartData.labels[selectedIndex]

      HandleBarChartClcik && HandleBarChartClcik(selectBarValue,selectLabel)
    }
  };

      return (
        <div className='pie-chart-container' style={{width:width, height:height,display:"flex",justifyContent:"center"}}>
          <Pie data={data} options={options} ref={chartRef} onClick={onClick}/>
        </div>
      );
    };

export default PieChart;

// import React from 'react';
// import {Chart, ArcElement} from 'chart.js'
// import { Pie } from 'react-chartjs-2';
// // import plugin from '@energiency/chartjs-plugin-piechart-outlabels';
// import  "chartjs-plugin-datalabels";
// // Chart.register(ChartDataLabels);
// import "chartjs-plugin-piechart-outlabels";
// Chart.register(ArcElement );

// interface PieChartProps {
//   data: any;
//   options: any;
// }

// const chartData = {
//     labels: ['Red', 'Blue', 'Yellow'],
//     datasets: [
//       {
//         data: [10, 20, 30],
//         backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
//         hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
//       },
//     ],
//   };

//   const chartOptions = {
//     responsive: true,
//     maintainAspectRatio: false,
//     legend: {
//       display: false,
//       position: 'bottom',
//     },
//   };


// const PieChart: React.FC = () => {
  
//   const plugins = [      
//     {       
//         datalabels: {
//           display: true,
//           color: '#fff',
//           formatter: (value: number, ctx: any) => {
//             let sum = 0;
//             let dataArr = ctx.chart.data.datasets[0].data;
//             dataArr.map((data: number) => {
//               sum += data;
//             });
//             let percentage = ((value * 100) / sum).toFixed(2) + '%';
//             return `${value} (${percentage})`;
//           },
//         },
//         id: 'outlabels',
//         outlabels: {
//           text: '%l',
//           color: 'black',
//           stretch: 30,
//           font: {
//             resizable: true,
//             minSize: 12,
//             maxSize: 18,
//           },
//         },
//       },
//   ];

//   return <Pie data={chartData}  options={chartOptions} plugins={plugins} />;
// };

// export default PieChart;


