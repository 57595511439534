import CustomEllipsisRenderer from "../product-admin/users/users-aggrid-column-config";


export const gstColumnData = [

    {
        field: 'selection', headerName: '', minWidth: 60, maxWidth: 60, resizable: false, suppressMovable: true, filter: "agSetColumnFilter", suppressSizeToFit: false, headerCheckboxSelection: true, checkboxSelection: true, showDisabledCheckboxes: true, suppressRowTransform: true, cellStyle: { textAlign: 'left' }
    },
    {
        field: 'TransactionNo', headerName: 'Transaction No', minWidth: 220, maxWidth: 220, resizable: false, filter: false, suppressMovable: true, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "number", cellRendererFramework: CustomEllipsisRenderer,
    },
    {
        field: 'GSTIN', headerName: 'GSTIN', minWidth: 220, maxWidth: 220, resizable: false, filter: false, suppressMovable: true, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "number", cellRendererFramework: CustomEllipsisRenderer,
    },
    {
        field: 'NoofChallan', headerName: 'No of Challan/CPIN',headerClass: 'header-center-align', minWidth: 220, maxWidth: 220, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'center' }, filtertype: "number", cellRendererFramework: CustomEllipsisRenderer,
    },
    {
        field: 'TotalAmount', headerName: 'Total Amount(₹)', minWidth: 220, maxWidth: 220, resizable: false,headerClass: 'header-right-align', suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'right' }, filtertype: "number", cellRendererFramework: CustomEllipsisRenderer,
    },
    {
        field: 'ApprovalStatus', headerName: 'Approval Status', minWidth: 220, maxWidth: 220, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, filtertype: "text", cellStyle: { textAlign: 'left' }, cellClassRules: {
            'paid-cell': (params: any) => params.value === 'Approved',
            'expired-cell': (params: any) => params.value === 'Declined',
            'pending-approval-cell': (params: any) => params.value === 'Pending Approval',
            // 'unpaid-cell': (params: any) => params.value === 'Unpaid',
        }, cellRendererFramework: CustomEllipsisRenderer,
    },
    {
        field: 'PaymentStatus', headerName: 'Payment Status', minWidth: 220, maxWidth: 220, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, filtertype: "text", cellStyle: { textAlign: 'left' }, cellClassRules: {
            'paid-cell': (params: any) => params.value === 'Paid',
            'expired-cell': (params: any) => params.value === 'Unpaid',
            'pending-approval-cell': (params: any) => params.value === 'Pending',
        }, cellRendererFramework: CustomEllipsisRenderer,
    },
    {
        field: 'Remarks', headerName: 'Remarks', minWidth: 220, maxWidth: 220, resizable: false, filter: false, suppressMovable: true, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer,
    },
]

export const rowData = [
    {
        TransactionNo: "10001",
        GSTIN: "33AAACH7409R1Z8",
        NoofChallan: "5",
        TotalAmount: "1,500.56",
        ApprovalStatus: "Approved",
        PaymentStatus: "Paid"

    },
    {
        TransactionNo: "10002",
        GSTIN: "33AAACH7409R1Z8",
        NoofChallan: "2",
        TotalAmount: "10.50",
        ApprovalStatus: "Pending Approval",
        PaymentStatus: "Pending"

    },
    {
        TransactionNo: "10003",
        GSTIN: "33AAACH7409R1Z8",
        NoofChallan: "2548555545545555",
        TotalAmount: "36,250.00",
        ApprovalStatus: "Declined",
        PaymentStatus: "Unpaid"

    },
    {
        TransactionNo: "10004",
        GSTIN: "33AAACH7409R1Z8",
        NoofChallan: "1",
        TotalAmount: "45,900.50",
        ApprovalStatus: "Approved",
        PaymentStatus: "Paid"

    }
]