import React, { useEffect, useState } from 'react'
import Logo from '../../../assets/images/logo.png'
import CompanyLeft from '../../../assets/images/company-left.png'
import CompanyRight from '../../../assets/images/company-right.png'
import BankLeft from '../../../assets/images/bank-left.png'
import BankRight from '../../../assets/images/bank-right.png'
import OrganizationLeft from '../../../assets/images/organization-left.png'
import OrganizationRight from '../../../assets/images/organization-right.png'
import '../../../styles/launching-screen/launching-screen.scss'
import { HiArrowNarrowRight } from 'react-icons/hi';
import { axiosPrivate } from '../../../middleware/axois-api'
import { useNavigate } from 'react-router-dom';


function LaunchingScreen() {
    const [navbarTitle, setNavbarTitle] = useState<any>("")
    const [leftImage, setLeftImage] = useState<any>(CompanyLeft)
    const [rightImage, setRightImage] = useState<any>(CompanyRight)

    const navigate = useNavigate();

    useEffect(() => {
        fetchData();
    }, [])


    const fetchData = async () => {
        try {
            const response = await axiosPrivate.get('/user/get-privilege');
            setNavbarTitle(response.data.Name)
            if (response.data?.UserPrivilege === "BankAdmin") {
                setLeftImage(BankLeft)
                setRightImage(BankRight)
            } else if (response.data?.UserPrivilege === "FingertipAdmin") {
                setLeftImage(OrganizationLeft)
                setRightImage(OrganizationRight)
            } else {
                setLeftImage(CompanyLeft)
                setRightImage(CompanyRight)
            }
        } catch (error) {

        }

    };



    return (
        <div className='launching-container'>
            <div className='main-cointainer'>
                <div className='image-container'><img className='launch-image' src={leftImage} alt="" /></div>
                <div className='welcome-text'>
                    <div className='name-displayer'>{navbarTitle}</div>
                    <div className='glad-text'> {"We're Glad You're Here"}</div>
                    <div className='button-overlay'><button onClick={() => { navigate("/user-profile") }} className='get-in'><p>Get in</p><HiArrowNarrowRight className='icon-arrow-ri' /></button></div>
                    <img draggable="false" className='finger-print-icon' src={Logo} alt="" />
                </div>
                <div className='image-container'><img className='launch-image' src={rightImage} alt="" /></div>
            </div>
            <div className='footer-launch'>
                <div className='commerce-text'>{"Commerce, Cash Flows & Cards Eco-System"}</div>
                <div className='cost-text'>{"Cost, Control & Convenience Advantage @ your Fingertip"}</div>
                <div className='platform-text'>{"A simplified platform for account payable & receivable of the corporate value chain."}</div>
            </div>
            <div className={'error-content'} />
        </div >
    )
}

export default LaunchingScreen