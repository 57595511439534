import React, { useEffect, useState, forwardRef, useImperativeHandle, useRef, } from 'react'
import InputComponent from '../../../common-component/form-elements/input-component'
import DropdownComponent from '../../../common-component/form-elements/dropdown-component'
import ButtonComponent from '../../../common-component/form-elements/button-component'
import StatutoryDetailsGrid from '../../../../config/organisation/vendor/statutory-details-grid'
import { v4 as uuidv4 } from 'uuid'
import Loading from '../../../common-component/modals/loading-screen'
import useAxiosPrivate from "../../../../services/hooks/useaxios-private";
import AlertBox from '../../../common-component/alert-box'
import { useDispatch, useSelector } from "react-redux";
import { resetFormModified } from "../../../../redux/action";
import Changesmodal from '../../../common-component/modals/changes-modal';
import DeleteModal from '../../../common-component/modals/delete-modal'
import Chart from 'chart.js/auto';
import StatutoryDetailsUpdatedGrid from '../../../../config/organisation/vendor/statutory-details-v2-grid'


// statutoryDetails
interface Props {
  mainApiData: any;
  vendorDetails: any;
  setVendorDetails: any;
  statutoryDetailsMain: any;
  onboardingViewfun: (val: any) => void;
  sendDataApi: () => void;
  iconStatus: any
  editData: any
  setShowPage: (val: any) => void;
  setIconStatus: (val: any) => void;
  setStatutoryDetailsMain: (value: any) => void;
  isEditMode: boolean;
  stepperStatus: any;
  setStepperStatus: any;
  saveVendorDatas: any
}
export interface ChildRef {
  next: (val: any) => void;
}
const StatutoryDetails = forwardRef<ChildRef, Props>(({ statutoryDetailsMain, mainApiData, editData, isEditMode, iconStatus, setShowPage, sendDataApi, setIconStatus, onboardingViewfun, setStatutoryDetailsMain, vendorDetails, setVendorDetails, stepperStatus,
  setStepperStatus, saveVendorDatas }, ref) => {
  const initData: any = {
    gstin: "",
    tan: "",
    status: "",
    companyRegState: "",
    stateCode: "",
    index: 0

  }
  const [formDataEdit, setFormDataEdit] = useState(false)
  const [currentSatutory, setCurrentSatutory] = useState<number>(0)
  const [formData, setFormData] = useState(initData);
  const [statutoryDetailsErrors, setStatutoryDetailsErrors] = useState<Record<string, boolean>>({})
  const [StatutoryState, setStatutoryState] = useState<any[]>([]);
  const [noDuplicate, setNoDuplicate] = useState(false)
  const [showType, setShowType] = useState("warning") // error message showType
  const [showMessage, setShowMessage] = useState<string>("") // error message showMessage
  const [showAlertBox, setShowAlertBox] = useState(false) // error message ShowAlertBox
  const [isFormDirty, setIsFormDirty] = useState(false)
  const isFormModified = useSelector((companyRegState: any) => companyRegState.isFormModified);
  const [showChangesModal, setShowChangesModal] = useState<boolean>(false);
  const dispatch = useDispatch();
  const [currentDeletVal, setCurrentDeletVal] = useState<any>({});
  const [deleteIndex, setDeleteIndex] = useState<number>(0)
  const [showDeleteModel, setShowDeleteModel] = useState<boolean>(false)
  const [currIndex, setCurrIndex] = useState<number>()
  const [statutoryDetailsPercentage, setStatutoryDetailsPercentage] = useState<any>(0)
  const [saveBtnDisabledStatus, setSaveBtnDisabledStatus] = useState<any>(true)
  const [statutoryGridEdit, setStatutoryGridEdit] = useState<any>({
    currentMode: false,
    currentEditId: ""
  })
  const userValidationRules: any = {
    companyGSTIN: {
      regex: vendorDetails?.statutoryDetails?.companyGSTIN !== "" ? /^[0-9]{2}[A-Z]{5}[0-9]{4}[0-9A-Z]{1}[0-9A-Z]{1}[0-9A-Z]{1}[0-9A-Z]{1}$/ : "",
      field: "mandatory",
      shouldNotBe: ""
    },
    status: {
      regex: '',
      field: "mandatory",
      shouldNotBe: ""
    },
    companyRegState: {
      regex: '',
      field: "mandatory",
      shouldNotBe: ""
    },
    stateCode: {
      regex: '',
      field: "mandatory",
      shouldNotBe: ""
    },
  }

  const [razorPayStatutoryDetails, setRazorPayStatutoryDetails] = useState<any>([])
  const updatedIsValueValidAll: Record<string, boolean> = {};
  const validateForm = (): boolean => {
    const updatedFieldValidity: Record<string, boolean> = {};
    const isMandatoryfalse = true;
    const isRegexfalse = true;
    Object.keys(userValidationRules).forEach((field) => {
      const rule = userValidationRules[field];
      const value = vendorDetails?.statutoryDetails[field];
      const isValueValid = (rule.field === "mandatory") ? (value ? (value !== rule.shouldNotBe) : false) : true;
      const isRegexValid = rule.regex !== "" ? new RegExp(rule.regex).test(value) : true;
      updatedFieldValidity[field] = !isValueValid || !isRegexValid;
      updatedIsValueValidAll[field] = !isValueValid;
    });
    setStatutoryDetailsErrors(updatedFieldValidity);
    const isFormValid = Object.keys(updatedFieldValidity).every((keys) => {
      return !updatedFieldValidity[keys]
    });
    return isFormValid;
  };

  const axiosPrivate = useAxiosPrivate();

  useEffect(() => {
    fetchStatutoryState()
  }, [])


  const fetchStatutoryState = async () => {
    try {
      const response = await axiosPrivate.get("state");
      if (response.status === 200) {
        const dropdownValues = response.data.states.map((item: any) => ({
          label: item.name,
          value: item.name,
          id: item.stateId,
        }));
        const sortedData = [...dropdownValues].sort((a, b) => a.label.localeCompare(b.label));
        setStatutoryState(sortedData);
      }
    } catch (error) {
    }
  };

  async function duplicateCheck() {
    const finalData = {
      "statutoryDetails": {
        "companyGSTIN": vendorDetails?.statutoryDetails?.companyGSTIN,
        "companyTAN": vendorDetails?.statutoryDetails?.companyTAN,
        'razorpayAccountId': vendorDetails?.primaryKeyOfCurrentDatas
      }
    }
    let statusMessage: boolean = false;
    try {
      const res = await axiosPrivate.post("razorpay/vendor/user-creation/duplicateChecking", finalData)

      if (res.status === 200) {
        statusMessage = true;
        return true;
      }
    }
    catch (error: any) {
      setShowAlertBox(true)
      setShowType("danger")
      setShowMessage(error.response.data.error)
      clearAlert("")
      statusMessage = false;
      return false;
    }
  }
  const clearAlert = (status: any) => {
    const timer = setTimeout(() => {
      setShowAlertBox(false);
      setShowMessage("");
      if (status === 400) {

      }
      clearTimeout(timer);
    }, 5000);
  }


  async function handelAdd() {
    if (!validateForm()) {
      setShowAlertBox(true);
      setShowType("danger");
      setShowMessage(Object.values(updatedIsValueValidAll).every((values) => !values) ?
        "Some of the field(s) are not in required format." : "Mandatory field(s) should not be left blank.")
      clearAlert('');
      return;
    }
    // let Duplicate = await duplicateCheck();
    // if (!Duplicate) return;
    // if (formDataEdit) {
    //   let data: any = []
    //   statutoryDetailsMain.length > 0 && statutoryDetailsMain.map((ele: any, i: number) => {
    //     if (i === formData.index) {
    //       data.push(formData)
    //     } else {
    //       data.push(ele)
    //     }
    //   })
    //   setStatutoryDetailsMain(data)
    //   setFormDataEdit(false)
    //   setFormData(
    //     {
    //       gstin: "",
    //       tan: "",
    //       status: "",
    //       companyRegState: "",
    //       stateCode: "",
    //       index: 0
    //     }
    //   )
    //   setIsFormDirty(false);
    //   setShowAlertBox(true);
    //   setShowType("success");
    //   setShowMessage("Statutory Details updated successfully.")
    //   clearAlert('');

    // }
    // else {
    //   let newrecord = [...statutoryDetailsMain]
    //   newrecord.push({ ...formData })
    //   setStatutoryDetailsMain(newrecord)
    //   setFormData(
    //     {
    //       gstin: "",
    //       tan: "",
    //       status: "",
    //       companyRegState: "",
    //       stateCode: "",
    //       index: 0
    //     }
    //   )
    // }
    // setIsFormDirty(false);
    // setFormDataEdit(false);
    if (await duplicateCheck()) {
      createStatutory()
      // setVendorDetails({
      //   ...vendorDetails,
      //   statutoryDetails: {

      //     companyTAN: '',
      //   }
      // })
    }
  }

  function next(next: any = "") {
    setShowPage('PointOfContact')
    // if (isEditMode && statutoryDetailsMain.length > 0) {
    //   sendDataApi();
    // } else {
    //   //statutoryDetailsMain.length > 0
    //   if (statutoryDetailsMain.length > 0) {
    //     setShowPage(next != "" ? next : "PointOfContact")
    //     if (next != "") {
    //       switch (next) {
    //         case "CompanyOverview":
    //           setIconStatus({
    //             ...iconStatus,
    //             companyIcon: "selected",
    //           })
    //           break;
    //         case "Address":
    //           setIconStatus({
    //             ...iconStatus,
    //             companyIcon: "completed",
    //             addressIcon: "selected",
    //           })
    //           break;
    //         case "DirectorsInfo":
    //           setIconStatus({
    //             ...iconStatus,
    //             directorIcon: "selected",
    //             addressIcon: "completed"
    //           })
    //           break;
    //         case "StatutoryDetails":
    //           setIconStatus({
    //             ...iconStatus,
    //             statutoryIcon: "selected",
    //             directorIcon: "completed"
    //           })
    //           break;
    //         case "PointOfContact":
    //           setIconStatus({
    //             ...iconStatus,
    //             PointOfContactIcon: "selected",
    //             statutoryIcon: "completed"
    //           })
    //           break;

    //         default:
    //           break;
    //       }
    //     } else {
    //       setIconStatus({
    //         ...iconStatus,
    //         statutoryIcon: "completed",
    //         PointOfContactIcon: "selected"
    //       })
    //     }
    //   }
    // }
  }


  useImperativeHandle(ref, () => {
    return {
      next: next
    }
  });

  function returnGird() {
    onboardingViewfun("grid")
    dispatch(resetFormModified(false));
  }

  function retriveData(value: any, index: number) {
    setCurrIndex(index)
    setFormDataEdit(true)
    setCurrentSatutory(value.satutoryid)
    setIsFormDirty(true);
    setStatutoryDetailsErrors({})
    if (Object.values(value).length !== 3) {
      setFormData(
        {
          ...value,
          index: index
        }
      )
    }

  }

  const clearAleart = () => {

    const timer = setTimeout(() => {
      setShowAlertBox(false);
      setShowMessage("");
      clearTimeout(timer);
    }, 5000);
  }


  const DirectorInfoDelete = async () => {
    try {
      const res = await axiosPrivate.post('razorpay/statutoryDetails/delete', { razorpayVendorStatutoryId: deleteIndex })
      if (res.status === 200) {
        setShowDeleteModel(false);
        setShowAlertBox(true)
        setShowType("success")
        setShowMessage(res.data.message)
        clearAleart()
        getStatutoryGridDetails()
      }
    } catch (error: any) {
      setShowDeleteModel(false)
      setShowAlertBox(true)
      setShowType("danger")
      setShowMessage(error.response.data.error)
      clearAleart()
      console.log(error)
    }
    // if (currentDeletVal.statutoryDetailsId) {
    //   try {
    //     const response = await axiosPrivate.delete(`/companyadmin/organisation/delete-statutory/${currentDeletVal.statutoryDetailsId}`);
    //     if (response.status === 200) {
    //       setShowDeleteModel(false);
    //       const newArray = [...statutoryDetailsMain];
    //       newArray.splice(deleteIndex, 1);
    //       setStatutoryDetailsMain(newArray);
    //       setShowAlertBox(true)
    //       setShowType("success")
    //       setShowMessage(response.data.message)
    //       clearAleart()
    //       setFormData(initData);
    //       setFormDataEdit(false)
    //     }
    //   } catch (error: any) {
    //     setShowDeleteModel(false)
    //     setShowAlertBox(true)
    //     setShowType("danger")
    //     setShowMessage(error.response.data.error)
    //     clearAleart()
    //   }
    // } else if (currentDeletVal.index === 0) {
    //   const newArray = [...statutoryDetailsMain];
    //   newArray.splice(deleteIndex, 1);
    //   setStatutoryDetailsMain(newArray)
    //   setShowDeleteModel(false)
    //   setShowAlertBox(true)
    //   setShowType("success")
    //   setShowMessage("The selected record(s) has been deleted.")
    //   clearAleart()
    //   setFormData(initData);
    //   setFormDataEdit(false)
    // } else if (currentDeletVal) {
    //   const newArray = [...statutoryDetailsMain];
    //   newArray.splice(deleteIndex, 1);
    //   setStatutoryDetailsMain(newArray)
    //   setShowDeleteModel(false)
    //   setShowAlertBox(true)
    //   setShowType("success")
    //   setShowMessage("The selected record(s) has been deleted.")
    //   clearAleart()
    //   setFormData(initData);
    //   setFormDataEdit(false)
    // }
  };
  function cancel() {
    setShowPage("DirectorsInfo")
    setIconStatus({ ...iconStatus, directorIcon: "selected", })
  }

  const postgstn = async () => {
    try {
      const res = await axiosPrivate.get(`getstate/bycompany-gstin/${(formData.gstin).substring(0, 2)}`)
      if (res.status === 200) {
        setFormData({
          ...formData,
          companyRegState: res.data.stateValue.state,
          companyRegStateId: res.data.stateValue.stateId
        })
      } else {
        setFormData({
          ...formData,
          companyRegState: "",
          companyRegStateId: ""
        })
      }
    }
    catch (error: any) {
    }
  }
  // percentage chart
  const chartRef: any = useRef(null);
  useEffect(() => {
    const data = {
      value: statutoryDetailsPercentage,
      max: 100,
      label: "Progress"
    };

    const config: any = {
      type: 'doughnut',
      data: {
        datasets: [{
          data: [data.value, data.max - data.value],
          backgroundColor: [statutoryDetailsPercentage == 100 ? "green" : '#D2A100', '#0055D4'],
          borderWidth: 0
        }]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        cutoutPercentage: 85,
        rotation: -90,
        circumference: 180,
        tooltips: {
          enabled: false
        },
        legend: {
          display: false
        },
        animation: {
          animateRotate: true,
          animateScale: false
        },
        title: {
          display: true,
          text: data.label,
          fontSize: 16
        }
      }
    };

    if (chartRef.current) {
      const chartCtx = chartRef.current.getContext('2d');
      const gaugeChart = new Chart(chartCtx, config);

      // Cleanup on unmount
      return () => {
        gaugeChart.destroy();
      };
    }
  }, [statutoryDetailsPercentage]);
  // percentage chart

  useEffect(() => {
    // let count = 0;
    // let mandatoryList = ['companyGSTIN', 'status', 'companyRegState', 'stateCode']
    // Object.keys(vendorDetails?.statutoryDetails)?.forEach((item: any) => {
    //   let dropdownValueStatus = typeof (vendorDetails?.statutoryDetails[item]) == 'object' ? vendorDetails?.statutoryDetails[item].label !== "Select" : true;
    //   if (vendorDetails?.statutoryDetails[item] !== "" && mandatoryList.includes(item) && dropdownValueStatus) {
    //     setStepperStatus({ ...stepperStatus, statutoryDetails: "working" })
    //     count++;
    //   }
    // });
    // let percentage: any = (count / 4) * 100
    // if (percentage.toFixed(0) == 100) {
    //   setStepperStatus({ ...stepperStatus, statutoryDetails: "completed" })
    // }
    if (razorPayStatutoryDetails.length > 0) {
      setStatutoryDetailsPercentage(100)
      setStepperStatus({ ...stepperStatus, statutoryDetails: "completed" })
    }
  }, [razorPayStatutoryDetails])
  // useEffect(() => {
  //   let count = 0;
  //   let mandatoryList = ['companyGSTIN', 'status', 'companyRegState', 'stateCode']
  //   Object.keys(vendorDetails?.statutoryDetails)?.forEach((item: any) => {
  //     let dropdownValueStatus = typeof (vendorDetails?.statutoryDetails[item]) == 'object' ? vendorDetails?.statutoryDetails[item].label !== "Select" : true;
  //     if (vendorDetails?.statutoryDetails[item] !== "" && mandatoryList.includes(item) && dropdownValueStatus) {
  //       setStepperStatus({ ...stepperStatus, statutoryDetails: "working" })
  //       count++;
  //     }
  //   });
  //   let percentage: any = (count / 4) * 100
  //   if (percentage.toFixed(0) == 100) {
  //     setStepperStatus({ ...stepperStatus, statutoryDetails: "completed" })
  //   }
  //   setStatutoryDetailsPercentage(percentage.toFixed(0))
  // }, [vendorDetails?.statutoryDetails])

  async function getStatutoryGridDetails() {
    try {
      const res = await axiosPrivate.post('razorpay/statutoryDetails/get', { "razorpayAccountId": vendorDetails?.primaryKeyOfCurrentDatas })
      setVendorDetails({
        ...vendorDetails,
        statutoryGridDetails: res.data.responseStatutoryDetails,
        statutoryDetails: {
          companyGSTIN: "",
          companyTAN: "",
          status: "",
          companyRegState: "",
          stateCode: "",
        },
      })
      setRazorPayStatutoryDetails(res.data.responseStatutoryDetails)
    } catch (error) {
      console.log(error)
    }

  }
  useEffect(() => {
    getStatutoryGridDetails()
    // setVendorDetails({
    //   ...vendorDetails,
    //   statutoryDetails: {
    //     companyGSTIN: "",
    //     companyTAN: "",
    //     status: "",
    //     companyRegState: "",
    //     stateCode: "",
    //   },
    // })
  }, [])

  async function createStatutory() {
    try {
      const res = await axiosPrivate.post('razorpay/statutoryDetails/create', { statutoryDetails: vendorDetails.statutoryDetails, "razorpayAccountId": vendorDetails?.primaryKeyOfCurrentDatas })
      if (res.status === 200) {

        getStatutoryGridDetails()
        setVendorDetails({
          ...vendorDetails,
          statutoryDetails: {
            companyGSTIN: "",
            companyTAN: "",
            status: "",
            companyRegState: "",
            stateCode: "",
          },
        })
      }
    } catch (error) {
      console.log("Error in createStatutory", error)
    }

  }
  async function updateStatutory() {
    try {
      if (!validateForm()) {
        setShowAlertBox(true);
        setShowType("danger");
        setShowMessage(Object.values(updatedIsValueValidAll).every((values) => !values) ?
          "Some of the field(s) are not in required format." : "Mandatory field(s) should not be left blank.")
        clearAlert('');
        return;
      }
      const res = await axiosPrivate.post('razorpay/statutoryDetails/update', { statutoryDetails: vendorDetails.statutoryDetails, razorpayVendorStatutoryId: statutoryGridEdit.currentEditId })
      if (res.status === 200) {


        setStatutoryGridEdit({
          currentMode: false,
          currentEditId: ""
        })
        setVendorDetails({
          ...vendorDetails,
          statutoryDetails: {
            companyGSTIN: "",
            companyTAN: "",
            status: "",
            companyRegState: "",
            stateCode: "",
          },
        })
      }
      getStatutoryGridDetails()
    } catch (error) {
      console.log(error)
    }

  }
  return (
    <>
      <div style={{ background: "#F8F8F8", height: "110px", width: "190px", position: "absolute", right: "0px", top: "0px", display: "flex", borderRadius: "0px 10px" }}>
        <div style={{ position: 'relative', height: '95px', width: '135px', left: "30px", top: "0px" }}>
          <canvas ref={chartRef}></canvas>
          <div style={{ width: "40px", position: "absolute", left: "50px", top: "58px", textAlign: "center" }}><strong style={{ fontSize: "16px" }}>{statutoryDetailsPercentage}%</strong></div>
          <div style={{ position: "absolute", left: "10px", fontSize: "16px", bottom: "-12px" }}>0%</div>
          <div style={{ position: "absolute", right: "-4px", fontSize: "16px", bottom: "-11px" }}>100%</div>
        </div>
      </div>
      <div className='form-wrapper'>
        <div className='form-area' >
          <div style={vendorDetails?.basicDetails?.merchantId == "" ? {} : { opacity: "0.5", pointerEvents: "none" }}>
            <div className='company-heading'>
              Statutory Details
            </div>
            <div className='form-main-end'>
              <div style={{ marginRight: "45px" }}>
                <InputComponent
                  height={"40px"}
                  width={"300px"}
                  margin={"0px 0px 30px 0px"}
                  padding={"0px 0px 0px 10px"}
                  border={statutoryDetailsErrors?.companyGSTIN ? "1px solid red" : "1px solid #A9C3DC"}
                  borderRadius={"0px"}
                  backgroundColor={"white"}
                  autoFocus={vendorDetails?.basicDetails?.merchantId !== "" ? false : true}
                  // disabled={isEditMode && (mainApiData?.status === "Pending Approval") ? true : false}
                  color={"black"}
                  type={"text"}
                  maxLength={15}
                  placeHolder={"Enter Company GSTIN"}
                  inputTitle={"Company GSTIN"}
                  required={true}
                  inputValue={vendorDetails?.statutoryDetails?.companyGSTIN}
                  onFocusOut={postgstn}
                  getUser={(value) => {
                    setSaveBtnDisabledStatus(false)
                    const removeInitialSpace = value.replace(/^\s+/, "")
                    setVendorDetails({
                      ...vendorDetails,
                      statutoryDetails: {
                        ...vendorDetails.statutoryDetails,
                        companyGSTIN: removeInitialSpace.toUpperCase(),
                        stateCode: value.substring(0, 2)
                      }
                    })
                    // setIconStatus({
                    //   ...iconStatus,
                    //   statutoryIcon: "selected"
                    // })
                    setIsFormDirty(true)
                    dispatch(resetFormModified(true));
                  }}
                />
              </div>
              <div style={{ marginRight: "45px" }}>
                <InputComponent
                  height={"40px"}
                  width={"300px"}
                  margin={"0px 0px 30px 0px"}
                  padding={"0px 0px 0px 10px"}
                  border={"1px solid #A9C3DC"}
                  borderRadius={"0px"}
                  backgroundColor={"white"}
                  // disabled={isEditMode && (mainApiData?.status === "Pending Approval") ? true : false}
                  color={"black"}
                  type={"text"}
                  maxLength={15}
                  placeHolder={"Enter Company TAN"}
                  inputTitle={"Company TAN"}
                  required={false}
                  inputValue={vendorDetails?.statutoryDetails?.companyTAN}
                  onFocusOut={postgstn}
                  getUser={(value) => {
                    setSaveBtnDisabledStatus(false)
                    const removeInitialSpace = value.replace(/^\s+/, "")
                    setVendorDetails({
                      ...vendorDetails,
                      statutoryDetails: {
                        ...vendorDetails.statutoryDetails,
                        companyTAN: removeInitialSpace.toUpperCase(),
                      }
                    })
                    // setIconStatus({
                    //   ...iconStatus,
                    //   statutoryIcon: "selected"
                    // })
                    setIsFormDirty(true)
                    dispatch(resetFormModified(true));
                  }}
                />
              </div>
              <div style={{ marginRight: "45px" }} key={uuidv4()} className={statutoryDetailsErrors?.status ? 'setErrorcompany' : ""}>
                <DropdownComponent
                  width={"300px"}
                  required={true}
                  title={"Status"}
                  // isDisabled={isEditMode && (mainApiData?.status === "Pending Approval") ? true : false}
                  defaultValue={vendorDetails?.statutoryDetails?.status ? [vendorDetails?.statutoryDetails?.status] : [{ label: "Select", value: "Select" }]}
                  options={[{ label: "Active", value: "Active" }, { label: "InActive", value: "InActive" }]}
                  getData={(value) => {
                    setSaveBtnDisabledStatus(false)
                    setVendorDetails({
                      ...vendorDetails,
                      statutoryDetails: {
                        ...vendorDetails.statutoryDetails,
                        status: value,
                      }
                    })
                    // setFormData({
                    //   ...formData,
                    //   status: value.value
                    // })
                    // setIconStatus({
                    //   ...iconStatus,
                    //   statutoryIcon: "selected"
                    // })
                    setIsFormDirty(true)
                    dispatch(resetFormModified(true));
                  }}
                />
              </div>
            </div>
            <div className='form-main-wrapper'>
              <div style={{ marginRight: "45px" }} key={uuidv4()} className={statutoryDetailsErrors?.companyRegState ? 'setErrorcompany' : ""}>
                <DropdownComponent
                  width={"300px"}
                  title={"Company Reg State"}
                  required={true}
                  // isDisabled={isEditMode && (mainApiData?.status === "Pending Approval") ? true : false}
                  defaultValue={vendorDetails?.statutoryDetails?.companyRegState ? [vendorDetails?.statutoryDetails?.companyRegState] : [{ label: "Select", value: "Select" }]}
                  options={StatutoryState}
                  getData={(value) => {
                    setSaveBtnDisabledStatus(false)
                    setVendorDetails({
                      ...vendorDetails,
                      statutoryDetails: {
                        ...vendorDetails.statutoryDetails,
                        companyRegState: value,
                      }
                    })
                    // setFormData({
                    //   ...formData,
                    //   companyRegState: value.value,
                    //   companyRegStateId: value.id
                    // })
                    // setIconStatus({
                    //   ...iconStatus,
                    //   statutoryIcon: "selected"
                    // })
                    setIsFormDirty(true)
                    dispatch(resetFormModified(true));
                  }}
                />
              </div>
              <div style={{ marginRight: "45px", opacity: "0.5" }}>
                <InputComponent
                  height={"40px"}
                  width={"300px"}
                  margin={"0px 0px 30px 0px"}
                  // disabled={isEditMode && (mainApiData?.status === "Pending Approval") ? true : false}
                  padding={"0px 0px 0px 10px"}
                  border={statutoryDetailsErrors && statutoryDetailsErrors.stateCode ? "1px solid red" : "1px solid #A9C3DC"}
                  borderRadius={"0px"}
                  backgroundColor={"white"}
                  color={"black"}
                  type={"text"}
                  inputTitle={"State Code"}
                  placeHolder={"Enter State Code"}
                  maxLength={2}
                  readOnly
                  required={true}
                  inputValue={vendorDetails?.statutoryDetails?.stateCode}
                  getUser={(value) => {
                    setSaveBtnDisabledStatus(false)
                    // setIconStatus({
                    //   ...iconStatus,
                    //   statutoryIcon: "selected"
                    // })
                    setIsFormDirty(true)
                    dispatch(resetFormModified(true));
                  }}
                />
              </div>
            </div>
            <div>
              <ButtonComponent
                title={statutoryGridEdit.currentMode ? "Update" : 'Add'}
                height={"50px"}
                width={"150px"}
                // disabled={isEditMode && (mainApiData?.status === "Pending Approval") ? true : false}
                backgroundColor={"#0055D4"}
                color={"white"}
                margin={"0px 8px"}
                className={(razorPayStatutoryDetails.length == 0 || statutoryGridEdit.currentMode) ? "button-component-hover common-btn" : "button-component disabled"}
                handleClick={() => {
                  console.log(razorPayStatutoryDetails)
                  if (razorPayStatutoryDetails.length == 0 || statutoryGridEdit.currentMode) {
                    if (statutoryGridEdit.currentMode) {
                      updateStatutory()
                    } else {
                      handelAdd()
                    }
                  }
                }}
              />
            </div>
            <div className={"Company-DetailsGrid"}>
              <StatutoryDetailsUpdatedGrid
                statutoryDetails={razorPayStatutoryDetails || []}
                retriveData={retriveData}
                showmodal={(val: any, index: any) => {
                  setShowDeleteModel(true);
                  setDeleteIndex(val.razorpayVendorStatutoryId);
                  setCurrentDeletVal(val);
                }}
                updateDatas={(val: any) => {
                  setStatutoryGridEdit({
                    currentMode: true,
                    currentEditId: val.razorpayVendorStatutoryId
                  })
                  setVendorDetails({
                    ...vendorDetails,
                    statutoryDetails: {
                      companyGSTIN: val.companyGSTIN,
                      companyTAN: val.companyTAN,
                      status: { label: val.status || "select", value: val.status || "select" },
                      companyRegState: { label: val.companyRegState || "select", value: val.companyRegState || "select" },
                      stateCode: val.stateCode,
                    },
                  })
                }}
              />
            </div>
          </div>
          {/* <div>
            <div className='footer-company'>
              {!isEditMode &&
                <ButtonComponent
                  title={"Cancel"}
                  height={"50px"}
                  width={"150px"}
                  margin={"0px 8px"}
                  backgroundColor={"#888888"}
                  color={"white"}
                  className={"button-component-hover cancel"}
                  handleClick={isFormModified ? () => { setShowChangesModal(true) } : returnGird}
                />
              }
              {
                isEditMode ?
                  <ButtonComponent
                    title={"Cancel"}
                    height={"50px"}
                    width={"150px"}
                    margin={"0px 8px"}
                    backgroundColor={"#888888"}
                    color={"white"}
                    className={"button-component-hover cancel"}
                    handleClick={isFormModified ? () => { setShowChangesModal(true) } : returnGird}
                  />
                  :
                  <ButtonComponent
                    title={"Previous"}
                    height={"50px"}
                    width={"150px"}
                    margin={"0px 8px"}
                    backgroundColor={"#888888"}
                    color={"white"}
                    className={"button-component-hover cancel"}
                    handleClick={cancel} />
              }


              <ButtonComponent
                title={isEditMode ? "Update" : "Next"}
                height={"50px"}
                width={"150px"}
                backgroundColor={"#0055D4"}
                color={"white"}
                margin={"0px 8px"}
                handleClick={next}
                disabled={(statutoryDetailsMain.length == 0 || isFormDirty)}
                className={
                  (statutoryDetailsMain.length == 0 || isFormDirty) ?
                    "button-component-hover disabled" : "button-component-hover common-btn"}
              />
            </div>
          </div> */}
          <div>
            <div className='footer-company'>
              <ButtonComponent
                title={"Cancel"}
                height={"50px"}
                width={"150px"}
                margin={"0px 8px"}
                backgroundColor={"#888888"}
                color={"white"}
                className={"button-component-hover cancel"}
                handleClick={isFormModified ? () => { setShowChangesModal(true) } : returnGird}
              />
              <ButtonComponent
                title={"Previous"}
                height={"50px"}
                width={"150px"}
                disabled={false}
                backgroundColor={"#0055D4"}
                color={"white"}
                margin={"0px 8px"}
                className={"button-component-hover common-btn"}
                handleClick={() => { setShowPage("VendorOverview") }}
              />
              <ButtonComponent
                // title={isEditMode ? "Update" : "Save"}
                title={"Next"}
                height={"50px"}
                width={"150px"}
                // disabled={saveBtnDisabledStatus}
                backgroundColor={"#0055D4"}
                color={"white"}
                margin={"0px 8px"}
                className={razorPayStatutoryDetails?.length > 0 ? "button-component-hover common-btn" : "button-component disabled"}
                // className={"button-component-hover common-btn"}
                handleClick={() => {
                  if (vendorDetails?.basicDetails?.merchantId == "") {
                    saveVendorDatas()
                    setShowPage('PointOfContact')
                    setSaveBtnDisabledStatus(true)
                  } else {
                    setShowPage('PointOfContact')
                  }

                }}
              />
              {/* <ButtonComponent
                title={"Next"}
                height={"50px"}
                width={"150px"}
                disabled={showMessage && showType === "success" ? true : false}
                backgroundColor={"#0055D4"}
                color={"white"}
                margin={"0px 8px"}
                className={
                  showMessage && showType === "success" ?
                    "button-component-hover disabled" : "button-component-hover common-btn"}
                handleClick={next}
              /> */}
            </div>
          </div>
        </div>
      </div>
      {
        showAlertBox &&
        <div className='alert-warp'>
          <AlertBox type={showType} message={showMessage} />
        </div>
      }
      {
        showChangesModal &&
        <Changesmodal
          closeModal={() => setShowChangesModal(false)}
          handleClose={() => {
            setShowChangesModal(false);
          }}
          leavePage={() => returnGird()} />
      }
      {
        showDeleteModel &&
        <div >
          <DeleteModal
            getDelete={() => { DirectorInfoDelete() }}
            clearValue={(value) => {
              setShowDeleteModel(value);
              setCurrentDeletVal({})
            }}
            modelType={"grid-delete"}

          />
        </div>
      }
    </>
  )
})
export default StatutoryDetails

