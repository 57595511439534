import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react'
import InputComponent from '../../../common-component/form-elements/input-component'
import IconButton from '../../../common-component/form-elements/icon-button'
import DropdownComponent from '../../../common-component/form-elements/dropdown-component'
import ButtonComponent from '../../../common-component/form-elements/button-component';
import { v4 as uuidv4 } from 'uuid'
import useAxiosPrivate from "../../../../services/hooks/useaxios-private";
import AlertBox from '../../../common-component/alert-box';
import { useDispatch, useSelector } from "react-redux";
import { resetFormModified } from "../../../../redux/action";
import Changesmodal from '../../../common-component/modals/changes-modal';
import moment from 'moment';
// import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';

interface DropdownItem {
  label: string;
  value: string;
}
export interface ChildRef {
  next: (val: any) => void;
}
interface props {
  fetchDatas: (value: any, chooseType: any) => void;
  companyOverviewPan: string;
  setCompanyOverviewPan: (value: any) => void;
  onboardingViewfun: (val: any) => void;
  mainApiData: any;
  setMainApiData: (val: any) => void;
  setIsFormDirty: (val: any) => void;
  setChooseType: (val: any) => void;
  sendDataApi: () => void;
  iconStatus: any
  setShowPage: (val: any) => void;
  setIconStatus: (val: any) => void;
  chooseType: any;
  showPage: any;
  isEditMode: boolean;
  editData: any;


}
let pancardno = "AAGCV3794D";
let directorpan = 'DUQPA2883C';

const CompanyOverview = forwardRef<ChildRef, props>(({ mainApiData, setIsFormDirty, showPage, setShowPage, setIconStatus, sendDataApi, editData, iconStatus, isEditMode, onboardingViewfun, setMainApiData, fetchDatas, companyOverviewPan, setCompanyOverviewPan, setChooseType, chooseType }, ref) => {
  const [constitutionData, setConstitutionData] = useState<DropdownItem[]>([])
  const [businessData, setBusinessData] = useState<DropdownItem[]>([])
  const [subCategoryData, setSubCategoryData] = useState<DropdownItem[]>([])
  const [companyOverviewErrors, setCompanyOverviewErrors] = useState<Record<string, boolean>>({})
  const [showType, setShowType] = useState("warning") // error message showType
  const [showMessage, setShowMessage] = useState<string>("") // error message showMessage
  const [showAlertBox, setShowAlertBox] = useState(false) // error message ShowAlertBox
  const isFormModified = useSelector((state: any) => state.isFormModified);
  const dispatch = useDispatch();
  const [showChangesModal, setShowChangesModal] = useState<boolean>(false);
  const [errorChoosetype, setErrorChoosetype] = useState<boolean>(false);
  const todayDate = moment(new Date()).format('YYYY-MM-DD');
  useEffect(() => {
    getConstitutionData()
    getBusinessData()
    getSubCategoryData()

  }, [])


  const axiosPrivate = useAxiosPrivate();
  useImperativeHandle(ref, () => {
    return {
      next: next
    }
  });
  async function getConstitutionData() {
    try {
      const response = await axiosPrivate.get("/bank-admin/company/dropdown/constituion")
      const ddRegionVal = response.data.companyConstitutions.map((e: any, i: number) => {
        const dropdownItem: DropdownItem = {
          label: e.constitution,
          value: e.constitution,
        };
        return dropdownItem;
      })
      const sortedData = [...ddRegionVal].sort((a, b) => a.label.localeCompare(b.label));
      setConstitutionData(sortedData)
    }
    catch (error) {
    }
  }
  async function getBusinessData() {
    try {
      const response = await axiosPrivate.get("/bank-admin/company/dropdown/buisnessCategory")
      const ddRegionVal = response.data.buisnessCategory.map((e: any, i: number) => {
        const dropdownItem: DropdownItem = {
          label: e.buisnessCategory,
          value: e.buisnessCategory,
        };
        return dropdownItem;
      })
      const sortedData = [...ddRegionVal].sort((a, b) => a.label.localeCompare(b.label));
      setBusinessData(sortedData)
    }
    catch (error) {
    }
  }
  async function getSubCategoryData() {
    try {
      const response = await axiosPrivate.get("/bank-admin/company/dropdown/sub-category")
      const ddRegionVal = response.data.subCategory.map((e: any, i: number) => {
        const dropdownItem: DropdownItem = {
          label: e.subCategory,
          value: e.subCategory,
        };
        return dropdownItem;
      })
      const sortedData = [...ddRegionVal].sort((a, b) => a.label.localeCompare(b.label));
      setSubCategoryData(sortedData)
    }
    catch (error) {
    }
  }

  const userValidationRules: any = {
    companyData: {
      CompanyName: {
        regex: "",
        field: "mandatory",
        shouldNotBe: ""
      },
      CompanyClass: {
        regex: "",
        field: "mandatory",
        shouldNotBe: ""
      },
      CompanyCategory: {
        regex: "",
        field: "mandatory",
        shouldNotBe: ""
      },
      CompanySubCategory: {
        regex: "",
        field: "mandatory",
        shouldNotBe: ""
      },
      CompanyRegNumber: {
        regex: /^[0-9]{2,}$/,
        field: "mandatory",
        shouldNotBe: ""
      },
      pan: {
        regex: /[A-Z]{5}\d{4}[A-Z]{1}/,
        field: "mandatory",
        shouldNotBe: ""
      },
      CompanyCin: {
        regex: (mainApiData && mainApiData?.companyData?.CompanyCin) ? /\b[A-Z]\d{5}[A-Z]{2}\d{4}[A-Z]{3}\d{6}\b/ : "",
        field: (mainApiData && !mainApiData?.companyData?.CompanyCin && !mainApiData?.companyData?.CompanyLLPIN) ? "mandatory" : "",
        shouldNotBe: ""
      },
      CompanyLLPIN: {
        regex: (mainApiData && mainApiData?.companyData?.CompanyLLPIN) ? /\b[A-Z]{3}-\d{4}\b/ : "",
        field: (mainApiData && !mainApiData?.companyData?.CompanyCin && !mainApiData?.companyData?.CompanyLLPIN) ? "mandatory" : "",
        shouldNotBe: ""
      },
      CompanyMcaIndustry: {
        regex: "",
        field: "",
        shouldNotBe: ""
      },
      CompanyMcaIndustryDivision: {
        regex: "",
        field: "",
        shouldNotBe: ""
      },
      CompanyMcaIndustryGroup: {
        regex: "",
        field: "",
        shouldNotBe: ""
      },
      CompanyDateOfInc: {
        regex: "",
        field: "mandatory",
        shouldNotBe: ""
      },
      CompanyTan: {
        regex: "",
        field: "",
        shouldNotBe: ""
      },
    }
  }

  const updatedIsValueValidAll: Record<string, boolean> = {};
  const validateForm = (): boolean => {
    const updatedFieldValidity: Record<string, boolean> = {};
    Object.keys(userValidationRules.companyData).forEach((field) => {
      const rule = userValidationRules.companyData[field];
      const value = mainApiData?.companyData[field];
      const isValueValid = (rule.field === "mandatory") ? (value ? (value !== rule.shouldNotBe) : false) : true;
      const isRegexValid = rule.regex !== "" ? new RegExp(rule.regex).test(value) : true;
      updatedFieldValidity[field] = !isValueValid || !isRegexValid;
      updatedIsValueValidAll[field] = !isValueValid;
    });
    setCompanyOverviewErrors(updatedFieldValidity);
    const isFormValid = Object.keys(updatedFieldValidity).every((keys) => {
      return !updatedFieldValidity[keys]
    });
    return isFormValid;
  };

  function handelFectch() {
    let regexPattern;
    if (chooseType === "CIN") {
      regexPattern = /\b[A-Z]\d{5}[A-Z]{2}\d{4}[A-Z]{3}\d{6}\b/;
    } else if (chooseType === "PAN") {
      regexPattern = /[A-Z]{5}\d{4}[A-Z]{1}/;
    } else if (chooseType === "LLPIN") {
      regexPattern = /\b[A-Z]{3}-\d{4}\b/;
    } else {
      return;
    }
    const isMatch = regexPattern.test(companyOverviewPan);
    if (isMatch) {
      fetchDatas(companyOverviewPan, chooseType);
      setErrorChoosetype(false)
    } else {
      setShowAlertBox(true);
      setShowType("danger");
      (companyOverviewPan == "" || companyOverviewPan == undefined) ?
        setShowMessage(chooseType + " should not be empty.") :
        setShowMessage(chooseType + " is not in required format.");
      clearAlert('');
      setErrorChoosetype(true)
    }

  }
  async function duplicateCheck() {
    const finalData: any = {
      "CompanyValue": companyOverviewPan,
      "CompanyLLPIN": mainApiData?.companyData?.CompanyLLPIN,
      "CompanyCin": mainApiData?.companyData?.CompanyCin,
      "CompanyRegNumber": mainApiData?.companyData?.CompanyRegNumber,
      "pan": mainApiData?.companyData?.pan,
      "CompanyName": mainApiData?.companyData?.CompanyName,
      "CompanyTan": mainApiData?.companyData?.CompanyTan,
    }
    let statusMessage: boolean = false;
    try {
      // const res = isEditMode ? await axiosPrivate.patch(`bank-admin/company/duplicate-checking/${editData?.CompanyData?.companyData?.id}`, finalData) : await axiosPrivate.post("bank-admin/company/duplicate-checking", finalData)
      const res = await axiosPrivate.post(`bank-admin/company/duplicate-checking/${editData?.CompanyData?.companyData?.id}`, { "companyOverView": finalData })

      if (res.status === 200) {
        statusMessage = true;
      }
    }
    catch (error: any) {
      setShowAlertBox(true)
      setShowType("danger")
      setShowMessage(error.response.data.error)
      clearAlert("")
      statusMessage = false;

    }
    return statusMessage;
  }

  const clearAlert = (status: any) => {
    const timer = setTimeout(() => {
      setShowAlertBox(false);
      setShowMessage("");
      if (status === 400) {

      }
      clearTimeout(timer);
    }, 5000);
  }

  const isDateValid = () => {
    const fromDate = new Date(mainApiData?.companyData.CompanyDateOfInc);
    let isOk = false

    if (fromDate < new Date() && fromDate > new Date("1850-01-01")) {
      isOk = true
      setCompanyOverviewErrors({ CompanyDateOfInc: false })
    }
    else {
      isOk = false
      setCompanyOverviewErrors({ CompanyDateOfInc: true })
    }

    return isOk;
  }

  async function next(next: any = "") {
    const isFormValid = validateForm();
    if (isFormValid) {
      let isvalidDOB = isDateValid();
      if (!isvalidDOB) {
        setShowAlertBox(true);
        setShowType("danger");
        setShowMessage("Some of the field(s) are not in required format.")
        clearAlert('');
        return;
      }
      const Duplicate = await duplicateCheck();
      if (Duplicate) {
        if (isEditMode) {
          sendDataApi();
          setIconStatus({
            ...iconStatus,
            companyIcon: "completed",
          })
          dispatch(resetFormModified(false));
        } else {
          setShowPage(next != "" ? next : "Address")
          if (next != "") {
            switch (next) {
              case "CompanyOverview":
                setIconStatus({
                  ...iconStatus,
                  companyIcon: "selected",
                })
                break;
              case "Address":
                setIconStatus({
                  ...iconStatus,
                  companyIcon: "completed",
                  addressIcon: "selected",
                })
                break;
              case "DirectorsInfo":
                setIconStatus({
                  ...iconStatus,
                  directorIcon: "selected",
                  addressIcon: "completed"
                })
                break;
              case "StatutoryDetails":
                setIconStatus({
                  ...iconStatus,
                  statutoryIcon: "selected",
                  directorIcon: "completed"
                })
                break;
              case "AdminDetails":
                setIconStatus({
                  ...iconStatus,
                  adminIcon: "selected",
                  statutoryIcon: "completed"
                })
                break;

              default:
                break;
            }
          } else {
            dispatch(resetFormModified(false));
            setIconStatus({
              ...iconStatus,
              companyIcon: "completed",
              addressIcon: "selected"
            })
          }
        }
      } else {
      }

      setIsFormDirty(false);
    } else {
      setShowAlertBox(true);
      setShowType("danger");
      setShowMessage(Object.values(updatedIsValueValidAll).every((values) => !values) ?
        "Some of the field(s) are not in required format." : "Mandatory field(s) should not be left blank.")
      clearAlert('');
    }
  }

  function returnGird() {
    onboardingViewfun("grid")
    dispatch(resetFormModified(false));
  }
  let type: any = [{ label: "PAN", value: "PAN" }, { label: "CIN", value: "CIN" }, { label: "LLPIN", value: "LLPIN" }]
  return (
    <>
      <div className='form-wrapper'>
        <div className='form-area' style={mainApiData?.kycApprovalStatus === "Approved" ? { overflowY: "scroll" } : { overflowY: "scroll" }}>
          <div className='form-area' style={mainApiData?.kycApprovalStatus === "Approved" ? { opacity: 0.5, pointerEvents: "none" } : {}}>
            <div className='form-main-end'>
              <div className='dropdown-width' key={uuidv4()} style={isEditMode ? { pointerEvents: "none", opacity: 0.5, marginRight: "45px", marginBottom: "30px" } : { marginRight: "45px", marginBottom: "30px" }}>
                <DropdownComponent
                  width={"300px"}
                  required={true}
                  title={"Choose PAN/LLPIN/CIN"}
                  defaultValue={[{ label: mainApiData && (mainApiData?.ChooseType === (undefined)) ? "PAN" : mainApiData?.ChooseType, value: mainApiData && (mainApiData?.ChooseType === (undefined)) ? "PAN" : mainApiData?.ChooseType }]}
                  options={type}
                  isDisabled={isEditMode || mainApiData?.kycApprovalStatus === "Approved"}
                  getData={(value: any) => {
                    setIsFormDirty(true)
                    dispatch(resetFormModified(true));
                    setMainApiData({
                      ...mainApiData,
                      ChooseType: value.value,
                      CompanyValue: "",
                    })
                    setCompanyOverviewPan("")
                    setChooseType(value.value)
                    setIconStatus({
                      ...iconStatus,
                      companyIcon: "selected",
                    })
                  }}

                />
              </div>
              <div className='input-fetch-flex'>
                <div style={isEditMode ? { pointerEvents: "none", opacity: 0.5 } : {}}>
                  <InputComponent
                    height={"40px"}
                    width={"250px"}
                    padding={"0px 0px 0px 10px"}
                    margin={"0px 0px 35px 0px"}
                    border={errorChoosetype ? "1px solid red" : "1px solid #A9C3DC"}
                    borderRadius={"0px"}
                    backgroundColor={"white"}
                    disabled={isEditMode || mainApiData?.kycApprovalStatus === "Approved"}
                    color={"black"}
                    type={"text"}
                    maxLength={
                      chooseType === "CIN" ? 21 :
                        chooseType === "PAN" ? 10 :
                          chooseType === "LLPIN" ? 8 : undefined
                    }
                    required={true}
                    placeHolder={`Enter Company ${((mainApiData?.ChooseType !== (undefined)) && (mainApiData?.ChooseType !== "")) ? mainApiData?.ChooseType : "PAN"}`}
                    inputTitle={`Company ${((mainApiData?.ChooseType !== (undefined)) && (mainApiData?.ChooseType !== "")) ? mainApiData?.ChooseType : "PAN"}`}
                    inputValue={mainApiData?.CompanyValue}
                    getUser={(value) => {
                      const userRes = value.toUpperCase()
                      setIsFormDirty(true)
                      dispatch(resetFormModified(true));
                      setMainApiData({
                        ...mainApiData,
                        CompanyValue: userRes
                      })
                      setCompanyOverviewPan(userRes)
                      setIconStatus({
                        ...iconStatus,
                        companyIcon: "selected",
                      })
                    }}
                  />
                </div>
                <div  className='fetch-button' style={isEditMode ? { pointerEvents: "none", opacity: 0.5 } : {}}>
                  <IconButton
                    iconName={"Fetch"}
                    height={"40px"}
                    width={"100px"}
                    fontSize={""}
                    color={""}
                    border={""}
                    borderRadius={"0px"}
                    disabled={isEditMode || mainApiData?.kycApprovalStatus === "Approved"}
                    backgroundColor={"#0055D4"}
                    hover={mainApiData?.ChooseType !== (undefined || null) ? true : false}
                    margin={"0px 0px 0px 0px"}
                    handleClick={() => {
                      if (isEditMode) return;
                      handelFectch()
                    }}
                  />
                </div>
              </div>
            </div>
            <div className='company-heading'>
              Company Overview
            </div>
            <div className='form-main-wrapper'>
              <div>
                <InputComponent
                  height={"40px"}
                  width={"300px"}
                  margin={"0px 45px 30px 0px"}
                  padding={"0px 0px 0px 10px"}
                  border={companyOverviewErrors.CompanyName ?
                    "1px solid red" : "1px solid #A9C3DC"
                  }
                  borderRadius={"0px"}
                  backgroundColor={"white"}
                  color={"black"}
                  type={"text"}
                  disabled={mainApiData?.kycApprovalStatus === "Approved"}
                  autoFocus
                  placeHolder={"Enter Company Name"}
                  inputTitle={"Company Name"}
                  required={true}
                  maxLength={100}
                  inputValue={mainApiData && mainApiData?.companyData?.CompanyName}

                  getUser={(value: any) => {
                    setIsFormDirty(true)
                    dispatch(resetFormModified(true));
                    const val = value.replace(/^[^A-Za-z0-9]+|[^\w\s\W]/g, "");
                    setMainApiData({
                      ...mainApiData,
                      companyData: {
                        ...mainApiData?.companyData,
                        CompanyName: val,
                      }
                    })
                    setIconStatus({
                      ...iconStatus,
                      companyIcon: "selected",
                    })

                  }}

                />
              </div>

              <div style={{ marginRight: "45px", marginBottom: "30px" }} key={uuidv4()} className={companyOverviewErrors.CompanyClass ? 'setErrorcompany' : "dropdown-width"}>
                <DropdownComponent
                  width={"300px"}
                  required={true}
                  title={"Company Constitution"}
                  defaultValue={[{ label: mainApiData?.companyData?.CompanyClass === undefined ? "Select" : mainApiData?.companyData?.CompanyClass, value: mainApiData?.companyData?.CompanyClass === undefined ? "Select" : mainApiData?.companyData?.CompanyClass }]}
                  options={constitutionData}
                  isDisabled={mainApiData?.kycApprovalStatus === "Approved"}
                  getData={(value: any) => {
                    setIsFormDirty(true)
                    dispatch(resetFormModified(true));
                    setMainApiData({
                      ...mainApiData,
                      companyData: {
                        ...mainApiData?.companyData,
                        CompanyClass: value.value,
                      }
                    })
                    setIconStatus({
                      ...iconStatus,
                      companyIcon: "selected",
                    })
                  }}
                />
              </div>
              <div style={{ marginRight: "45px", marginBottom: "30px" }} key={uuidv4()} className={companyOverviewErrors.CompanyCategory ? 'setErrorcompany' : "dropdown-width"}>
                <DropdownComponent
                  width={"300px"}
                  title={"Business Category"}
                  required={true}
                  defaultValue={[{ label: mainApiData?.companyData?.CompanyCategory === undefined ? "Select" : mainApiData?.companyData?.CompanyCategory, value: mainApiData?.companyData?.CompanyCategory === undefined ? "Select" : mainApiData?.companyData?.CompanyCategory }]}
                  options={businessData}
                  isDisabled={mainApiData?.kycApprovalStatus === "Approved"}
                  getData={(value: any) => {
                    setIsFormDirty(true)
                    dispatch(resetFormModified(true));
                    setMainApiData({
                      ...mainApiData,
                      companyData: {
                        ...mainApiData?.companyData,
                        CompanyCategory: value.value,
                      }
                    })
                    setIconStatus({
                      ...iconStatus,
                      companyIcon: "selected",
                    })
                  }}
                />
              </div>
            </div>
            <div className='form-main-wrapper'>
              <div style={{ marginRight: "45px", marginBottom: "30px" }} key={uuidv4()} className={companyOverviewErrors.CompanySubCategory ? 'setErrorcompany' : "dropdown-width"}>
                <DropdownComponent
                  width={"300px"}
                  title={"Company Sub Category"}
                  required={true}
                  isDisabled={mainApiData?.kycApprovalStatus === "Approved"}
                  defaultValue={[{ label: mainApiData?.companyData?.CompanySubCategory === undefined ? "Select" : mainApiData?.companyData?.CompanySubCategory, value: mainApiData?.companyData?.CompanySubCategory === undefined ? "Select" : mainApiData?.companyData?.CompanySubCategory }]}
                  options={subCategoryData}
                  getData={(value: any) => {
                    setIsFormDirty(true)
                    dispatch(resetFormModified(true));
                    setMainApiData({
                      ...mainApiData,
                      companyData: {
                        ...mainApiData?.companyData,
                        CompanySubCategory: value.value,
                      }
                    })
                    setIconStatus({
                      ...iconStatus,
                      companyIcon: "selected",
                    })
                  }}
                />
              </div>
              <div>
                <InputComponent
                  height={"40px"}
                  width={"300px"}
                  padding={"0px 0px 0px 10px"}
                  margin={"0px 45px 30px 0px"}
                  border={companyOverviewErrors.CompanyRegNumber ?
                    "1px solid red" : "1px solid #A9C3DC"
                  }
                  borderRadius={"0px"}
                  backgroundColor={"white"}
                  color={"black"}
                  type={"text"}
                  inputTitle={"Company Registration Number"}
                  placeHolder={"Enter Registration Number"}
                  required={true}
                  disabled={mainApiData?.kycApprovalStatus === "Approved"}
                  maxLength={6}
                  inputValue={mainApiData && mainApiData?.companyData?.CompanyRegNumber}
                  getUser={(value: any) => {
                    setIsFormDirty(true)
                    dispatch(resetFormModified(true));
                    const val = value.replace(/[^0-9]/g, "")
                    setMainApiData({
                      ...mainApiData,
                      companyData: {
                        ...mainApiData?.companyData,
                        CompanyRegNumber: val,
                      }
                    });
                    setIconStatus({
                      ...iconStatus,
                      companyIcon: "selected",
                    })
                  }}
                />
              </div>
              <div>
                <InputComponent
                  height={"40px"}
                  width={"300px"}
                  margin={"0px 0px 30px 0px"}
                  padding={"0px 0px 0px 10px"}
                  border={companyOverviewErrors.pan ?
                    "1px solid red" : "1px solid #A9C3DC"
                  }
                  disabled={mainApiData?.kycApprovalStatus === "Approved"}
                  borderRadius={"0px"}
                  backgroundColor={"white"}
                  color={"black"}
                  type={"text"}
                  inputTitle={"Company PAN No"}
                  placeHolder={"Enter PAN No"}
                  maxLength={10}
                  required={true}
                  inputValue={mainApiData && !mainApiData?.companyData?.pan ? "" : mainApiData?.companyData?.pan}
                  getUser={(val: any) => {
                    setIsFormDirty(true)
                    dispatch(resetFormModified(true));
                    let value = val.toUpperCase()
                    setMainApiData({
                      ...mainApiData,
                      companyData: {
                        ...mainApiData?.companyData,
                        pan: value,
                      }
                    })
                    setIconStatus({
                      ...iconStatus,
                      companyIcon: "selected",
                    })
                  }}
                />
              </div>
            </div>
            <div className='form-main-wrapper'>
              <div>
                <InputComponent
                  height={"40px"}
                  width={"300px"}
                  margin={"0px 45px 30px 0px"}
                  padding={"0px 0px 0px 10px"}
                  border={companyOverviewErrors && companyOverviewErrors.CompanyTan ? "1px solid red" : "1px solid #A9C3DC"}
                  borderRadius={"0px"}
                  backgroundColor={"white"}
                  color={"black"}
                  disabled={mainApiData?.kycApprovalStatus === "Approved"}
                  type={"text"}
                  maxLength={10}
                  required={true}
                  inputValue={mainApiData && mainApiData?.companyData?.CompanyTan}
                  placeHolder={"Enter Company TAN"}
                  inputTitle={"Company TAN"}
                  getUser={(val) => {
                    let value = val.toUpperCase()
                    setMainApiData({
                      ...mainApiData,
                      companyData: {
                        ...mainApiData?.companyData,
                        CompanyTan: value,
                      }
                    })
                    setIconStatus({
                      ...iconStatus,
                      companyIcon: "selected",
                    })
                  }}
                />
              </div>
              <div>
                <InputComponent
                  height={"40px"}
                  width={"300px"}
                  margin={"0px 45px 30px 0px"}
                  padding={"0px 0px 0px 10px"}
                  border={companyOverviewErrors.CompanyCin ?
                    "1px solid red" : "1px solid #A9C3DC"
                  }
                  disabled={mainApiData?.kycApprovalStatus === "Approved"}
                  borderRadius={"0px"}
                  backgroundColor={"white"}
                  color={"black"}
                  type={"text"}
                  inputTitle={"Company CIN No"}
                  placeHolder={"Enter CIN No"}
                  maxLength={21}
                  inputValue={mainApiData && mainApiData?.companyData?.CompanyCin}
                  getUser={(val: any) => {
                    setIsFormDirty(true)
                    dispatch(resetFormModified(true));
                    let value = val.toUpperCase()
                    setMainApiData({
                      ...mainApiData,
                      companyData: {
                        ...mainApiData?.companyData,
                        CompanyCin: value,
                      }
                    })
                    setIconStatus({
                      ...iconStatus,
                      companyIcon: "selected",
                    })
                  }}
                />
              </div>
              <div>
                <InputComponent
                  height={"40px"}
                  width={"300px"}
                  margin={"0px 45px 30px 0px"}
                  padding={"0px 0px 0px 10px"}
                  border={companyOverviewErrors.CompanyLLPIN ?
                    "1px solid red" : "1px solid #A9C3DC"
                  }
                  disabled={mainApiData?.kycApprovalStatus === "Approved"}
                  borderRadius={"0px"}
                  backgroundColor={"white"}
                  color={"black"}
                  type={"text"}
                  maxLength={8}
                  placeHolder={"Enter LLPIN"}
                  inputTitle={"Company LLPIN"}
                  inputValue={mainApiData && mainApiData?.companyData?.CompanyLLPIN}
                  getUser={(val: any) => {
                    setIsFormDirty(true)
                    dispatch(resetFormModified(true));
                    let value = val.toUpperCase();
                    setMainApiData({
                      ...mainApiData,
                      companyData: {
                        ...mainApiData?.companyData,
                        CompanyLLPIN: value,
                      }
                    })
                    setIconStatus({
                      ...iconStatus,
                      companyIcon: "selected",
                    })
                  }}
                />
              </div>
            </div>
            <div className='form-main-end'>
              <div>
                <InputComponent
                  height={"40px"}
                  width={"300px"}
                  disabled={mainApiData?.kycApprovalStatus === "Approved"}
                  padding={"0px 0px 0px 10px"}
                  margin={"0px 45px 30px 0px"}
                  border={companyOverviewErrors.CompanyMcaIndustry ?
                    "1px solid red" : "1px solid #A9C3DC"
                  }
                  borderRadius={"0px"}
                  backgroundColor={"white"}
                  color={"black"}
                  type={"text"}
                  maxLength={100}
                  inputTitle={"Company MCA Industry"}
                  placeHolder={"Enter MCA Industry"}
                  inputValue={mainApiData && mainApiData?.companyData?.CompanyMcaIndustry}
                  getUser={(value: any) => {
                    const val = value.replace(/^[^A-Za-z0-9]+|[^\w\s\W]/g, "");
                    setIsFormDirty(true)
                    dispatch(resetFormModified(true));
                    setMainApiData({
                      ...mainApiData,
                      companyData: {
                        ...mainApiData?.companyData,
                        CompanyMcaIndustry: val,
                      }
                    })
                    setIconStatus({
                      ...iconStatus,
                      companyIcon: "selected",
                    })
                  }}
                />
              </div>
              <div>
                <InputComponent
                  height={"40px"}
                  width={"300px"}
                  disabled={mainApiData?.kycApprovalStatus === "Approved"}
                  padding={"0px 0px 0px 10px"}
                  margin={"0px 45px 30px 0px"}
                  border={companyOverviewErrors.CompanyMcaIndustryDivision ?
                    "1px solid red" : "1px solid #A9C3DC"
                  }
                  borderRadius={"0px"}
                  backgroundColor={"white"}
                  color={"black"}
                  type={"text"}
                  maxLength={100}
                  inputTitle={"Company MCA Industry Division"}
                  placeHolder={"Enter MCA Industry Division"}
                  inputValue={mainApiData && mainApiData?.companyData?.CompanyMcaIndustryDivision}
                  getUser={(value: any) => {
                    const val = value.replace(/^[^A-Za-z0-9]+|[^\w\s\W]/g, "");
                    setIsFormDirty(true)
                    dispatch(resetFormModified(true));
                    setMainApiData({
                      ...mainApiData,
                      companyData: {
                        ...mainApiData?.companyData,
                        CompanyMcaIndustryDivision: val,
                      }
                    })
                    setIconStatus({
                      ...iconStatus,
                      companyIcon: "selected",
                    })
                  }}
                />
              </div>
              <div>
                <InputComponent
                  height={"40px"}
                  width={"300px"}
                  disabled={mainApiData?.kycApprovalStatus === "Approved"}
                  margin={"0px 45px 30px 0px"}
                  padding={"0px 0px 0px 10px"}
                  maxLength={100}
                  border={companyOverviewErrors.CompanyMcaIndustryGroup ?
                    "1px solid red" : "1px solid #A9C3DC"
                  }
                  borderRadius={"0px"}
                  backgroundColor={"white"}
                  color={"black"}
                  type={"text"}
                  inputValue={mainApiData && mainApiData?.companyData?.CompanyMcaIndustryGroup}
                  inputTitle={"Company MCA Industry Group"}
                  placeHolder={"Enter MCA Industry Group"}
                  getUser={(value: any) => {
                    const val = value.replace(/^[^A-Za-z0-9]+|[^\w\s\W]/g, "");
                    setIsFormDirty(true)
                    dispatch(resetFormModified(true));
                    setMainApiData({
                      ...mainApiData,
                      companyData: {
                        ...mainApiData?.companyData,
                        CompanyMcaIndustryGroup: val,
                      }
                    })
                    setIconStatus({
                      ...iconStatus,
                      companyIcon: "selected",
                    })
                  }}
                />
              </div>

              <div style={{ marginRight: "45px" }}>
                <div className='title'>Company Date Of Inc<span className='required'>*</span></div>
                <input
                  type="date"
                  id="date-picker"
                  className="date-picker"
                  disabled={mainApiData?.kycApprovalStatus === "Approved"}
                  style={{
                    border: companyOverviewErrors.CompanyDateOfInc ? "1px solid red" : "",
                  }}
                  max={todayDate}
                  value={
                    mainApiData?.companyData?.CompanyDateOfInc &&
                    moment(mainApiData?.companyData.CompanyDateOfInc).format("YYYY-MM-DD")
                  }

                  onChange={(e: any) => {
                    if (e.target.value.length > 8) {
                      const year = e.target.value.substring(0, 4);
                      if (parseInt(year, 10) > 2023) {
                        return;
                      }
                      if (year > 999) {
                        if (parseInt(year, 10) < 1900) {
                          return;
                        }
                      }
                    }
                    setMainApiData({
                      ...mainApiData,
                      companyData: {
                        ...mainApiData?.companyData,
                        CompanyDateOfInc: e.target.value,
                      }
                    });
                    setIconStatus({
                      ...iconStatus,
                      companyIcon: "selected",
                    });
                    setIsFormDirty(true)
                    dispatch(resetFormModified(true));
                  }}
                />
              </div>
              <div>
              <InputComponent
                height={"40px"}
                width={"300px"}
                margin={"0px 45px 30px 0px"}
                padding={"0px 0px 0px 10px"}
                maxLength={100}
                border={companyOverviewErrors.Description ?
                  "1px solid red" : "1px solid #A9C3DC"
                }
                borderRadius={"0px"}
                backgroundColor={"white"}
                color={"black"}
                type={"text"}
                inputValue={mainApiData && mainApiData?.companyData?.Description}
                inputTitle={"Description"}
                placeHolder={"Enter Description"}
                required={true}
                getUser={(value: any) => {
                  const val = value.replace(/^[^A-Za-z0-9]+|[^\w\s\W]/g, "");
                  setIsFormDirty(true)
                  dispatch(resetFormModified(true));
                  setMainApiData({
                    ...mainApiData,
                    companyData: {
                      ...mainApiData?.companyData,
                      Description: val,
                    }
                  })
                  setIconStatus({
                    ...iconStatus,
                    companyIcon: "selected",
                  })
                }}
              />
            </div>
            </div>
            <div className='footer-company'>
              <div>
                {/* <ButtonComponent
                title={"Cancel"}
                height={"50px"}
                width={"150px"}
                margin={"0px 8px"}
                backgroundColor={"#888888"}
                color={"white"}
                className={"button-component-hover cancel"}
                handleClick={isFormModified ? () => { setShowChangesModal(true) } : returnGird}
              /> */}


                <ButtonComponent
                  title={isEditMode ? "Update" : "Next"}
                  height={"50px"}
                  width={"150px"}
                  backgroundColor={"#0055D4"}
                  disabled={mainApiData?.kycApprovalStatus === "Approved"}
                  color={"white"}
                  margin={"0px 8px"}
                  className={mainApiData?.kycApprovalStatus === "Approved" ? "button-component disabled" : "button-component-hover common-btn"}
                  handleClick={next}
                />
              </div>
            </div>
          </div>
        </div>

      </div >
      {
        showAlertBox &&
        <div className='alert-warp'>
          <AlertBox type={showType} message={showMessage} />
        </div>
      }
      {
        showChangesModal &&
        <Changesmodal
          closeModal={() => setShowChangesModal(false)}
          handleClose={() => {
            setShowChangesModal(false);
          }}
          leavePage={() => returnGird()} />
      }
    </>
  )
})

export default CompanyOverview;
