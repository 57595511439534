import React, { useEffect } from 'react';
import '../../../styles/modals/document-remark-modal.scss';
import { RxCross1 } from 'react-icons/rx';
import InputComponent from '../form-elements/input-component';


interface props {
    clearDoumentRemarksmodal: (val: any) => void;
    remarks?:string;
}



export default function DoumentRemarksmodal({ clearDoumentRemarksmodal, remarks }: props) {
    
    const handleTextAreaMouseDown = (e:any) => {
        e.preventDefault();
    };
    return (
        <div className={"document-remark-modal"}>

            <div className={"container"}>
                <div className={"close-icon"}>
                    <span className={"cross-icon"}>
                        <RxCross1
                            onClick={() => clearDoumentRemarksmodal(false)}
                            style={{ fontSize: "40px" }} />
                    </span>
                </div>

                <div>
                    <div>
                        <p>Remarks</p>
                    </div>
                    <div>
                        <textarea
                            readOnly
                            onMouseDown={handleTextAreaMouseDown}
                            className={"textarea-modal"}
                            value={remarks}></textarea>
                    </div>
                </div>
            </div>

        </div>
    )
}



