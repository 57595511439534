import React from "react";

const CustomEllipsisRenderer: React.FC<any> = (params: any) => {
    const value = params.value || "";
    const maxLength = 30; // Maximum characters allowed before truncation

    let displayValue = value;
    if (value.length > maxLength) {
        displayValue = value.substring(0, maxLength) + "...";
    }

    const cellStyle: React.CSSProperties = {
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden",
        paddingRight: "3px",
    };

    return <div style={cellStyle}>{displayValue}</div>;
};

export default CustomEllipsisRenderer;
export const vendorBankApprovalData = [
    {
        field: 'selection', headerName: '', minWidth: 60, maxWidth: 60, resizable: false, suppressMovable: true, filter: "agSetColumnFilter", suppressSizeToFit: false, headerCheckboxSelection: true, checkboxSelection: true, showDisabledCheckboxes: true, suppressRowTransform: true, cellStyle: { textAlign: 'center' }
    },
    {
        field: 'bank', headerName: 'Bank Name', minWidth: 200, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer
    },
    {
        field: 'state', headerName: 'State', minWidth: 200, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer
    },
    {
        field: 'city', headerName: 'ROC City', minWidth: 200, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer
    },
    {
        field: 'name', headerName: 'Conatact Name', minWidth: 200, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer
    },
    {
        field: 'emailId', headerName: 'Conatact Email ID', minWidth: 300, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer
    },
    {
        field: 'isRequired', headerName: 'Vendor Approval Required', minWidth: 300, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer
    },
    {
        field: 'internalApprovalStatus', headerName: 'Internal Approval Status', minWidth: 300, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer,
        cellRenderer: (e: any) => {
            // Custom rendering logic
            let color = '';
            switch (e.data.internalApprovalStatus) {
                case 'NYS':
                    color = '#0055D4'
                    break;
                case 'Declined':
                    color = 'red'
                    break;
                case 'Approved':
                    color = 'green'
                    break;
                case 'Pending Approval':
                    color = '#D2A100'
                    break;
                default:
                    break;
            }
            return (
                <div style={{ color: color }}>
                    {e?.data?.internalApprovalStatus}
                </div>
            );
        }
    },
    {
        field: 'branch', headerName: 'Branch', minWidth: 200, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer
    },
    {
        field: 'ifscCode', headerName: 'IFSC Code', minWidth: 200, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer
    },

    {
        field: 'isActive', headerName: 'Is Active', minWidth: 200, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer
    },
]


export const rowDatathis = [
    {
        accountNumber: "4561237890",
        bank: "ICICI",
        ifscCode: "UTICICI01",
        branch: "Bhubaneswar",
        address: "Odisha",
        priority: "01",
        isActive: "Yes",
        status: "Approved",
        remarks: "",
        approvedBy: "Nagarajan",

    },
    {
        accountNumber: "6554342322121",
        bank: "Axis",
        ifscCode: "UTIB0045",
        branch: "Bhubaneswar",
        address: "Odisha",
        priority: "02",
        isActive: "Yes",
        status: "Approved",
        remarks: "",
        approvedBy: "Nagarajan",
    },
    {
        accountNumber: "54512124212143",
        bank: "SBI",
        ifscCode: "BNNK325",
        branch: "Bhubaneswar",
        address: "Odisha",
        priority: "03",
        isActive: "Yes",
        status: "Approved",
        remarks: "",
        approvedBy: "Nagarajan",
    },
    {
        accountNumber: "254242542214",
        bank: "HSBC",
        ifscCode: "UTICICI01",
        branch: "Bhubaneswar",
        address: "Odisha",
        priority: "04",
        isActive: "Yes",
        status: "Approved",
        remarks: "",
        approvedBy: "Nagarajan",
    },

]