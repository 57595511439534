import "../../../styles/common-component/text-area.scss"

interface propsType {
    name: string;
    placeHolder: string;
    width: string;
    required?: boolean;
    height: string;
    margin?: string;
    disabled?: boolean
    padding?: string;
    maxLength?: number;
    border?: string
    getUser: (value: string) => void;
    inputValue: string
}

export default function TextArea({ name, placeHolder, width, height, padding, margin, maxLength, border, inputValue, getUser, disabled, required }: propsType) {
    return (
        <div className='text-area' style={{ margin, }}>
            <div >
                {name}
                {required && <span style={{ color: "red" }}>*</span>}
            </div>
            <textarea
                placeholder={placeHolder}
                style={{ width, height, padding, border }}
                value={inputValue}
                maxLength={maxLength}
                disabled={disabled}
                onChange={(e) => { getUser(e.target.value) }}
            />
        </div>
    )
}
