import React, { useState, useEffect, forwardRef, useImperativeHandle, } from 'react'
import RadioOrCheckbox from '../../../common-component/form-elements/radio-or-checkbox'
import InputComponent from '../../../common-component/form-elements/input-component'
import ButtonComponent from '../../../common-component/form-elements/button-component'
import AdminDetailsGrid from '../../../../config/organisation/company/admin-details-grid'
import useAxiosPrivate from "../../../../services/hooks/useaxios-private";
import AlertBox from '../../../common-component/alert-box'
import { useDispatch, useSelector } from "react-redux";
import { resetFormModified } from "../../../../redux/action";
import Changesmodal from '../../../common-component/modals/changes-modal';
import DeleteModal from '../../../common-component/modals/delete-modal'


interface props {
  onboardingViewfun: (val: any) => void;
  setAdminDetailsMain: (val: any) => void;
  adminDetailsMain: any
  editData: any

  iconStatus: any
  setShowPage: (val: any) => void;
  setIconStatus: (val: any) => void;
  sendDataApi: () => void;
  isEditMode: boolean;
}
export interface ChildRef {
  next: (val: any) => void;
}
const AdminDetails = forwardRef<ChildRef, props>(({ onboardingViewfun, iconStatus, editData, isEditMode, setShowPage, sendDataApi, setIconStatus, setAdminDetailsMain, adminDetailsMain }, ref) => {
  const initData: any = {
    name: "",
    designation: "",
    mobileNumber: "",
    email: "",
    userIsActive: "Yes",
    userIsActiveRemarks: "",
    index: 0
  };

  const [radioActive, setRadioActive] = useState("Yes")
  const [formDataEdit, setFormDataEdit] = useState(false)
  const [currentIndex, setCurrentIndex] = useState<number>()
  const [adminDetails, setAdminDetails] = useState(initData)
  const [companyOverviewErrors, setCompanyOverviewErrors] = useState<Record<string, boolean>>({})
  const [currentAdmin, setCurrentAdmin] = useState<number>(0)
  const [showType, setShowType] = useState("warning") // error message showType
  const [showMessage, setShowMessage] = useState<string>("") // error message showMessage
  const [showAlertBox, setShowAlertBox] = useState(false) // error message ShowAlertBox
  const [disableState, setDisableState] = useState(true)
  const [isFormDirty, setIsFormDirty] = useState(false)
  const [isFirstRender, setIsFirstRender] = useState(false)
  const isFormModified = useSelector((state: any) => state.isFormModified);
  const dispatch = useDispatch();
  const [showChangesModal, setShowChangesModal] = useState<boolean>(false);
  const [showDeleteModel, setShowDeleteModel] = useState<boolean>(false)
  const [deleteIndex, setDeleteIndex] = useState<number>(0)
  const [currentDeletVal, setCurrentDeletVal] = useState<any>({});


  function returnGird() {
    onboardingViewfun("grid")
    dispatch(resetFormModified(false));
  }

  useEffect(() => {
    setAdminDetails({
      ...adminDetails,
      userIsActive: "Yes"
    })
  }, [])



  useEffect(() => {
    if (isFirstRender) {
      next();
      setIsFirstRender(false);
    }
  }, [adminDetailsMain, adminDetailsMain?.length])

  useEffect(() => {
    if (!isEditMode) return;
    adminDetailsMain?.map((user: any, index: any) => {
      if (user.userIsActive == "Yes") {
        setAdminDetails(
          {
            name: user.name,
            designation: user.designation,
            mobileNumber: user.mobileNumber,
            email: user.email,
            userIsActive: user.userIsActive,
            userIsActiveRemarks: user.userIsActiveRemarks,
            index: index,
            userId: user.userId
          }
        )
        setFormDataEdit(true);
        setCurrentIndex(index)
      }
    });

  }, [])


  const clearAleart = () => {
    const timer = setTimeout(() => {
      setShowAlertBox(false);
      setShowMessage("");
      clearTimeout(timer);
    }, 5000);
  }

  const DirectorInfoDelete = async () => {
    if (currentDeletVal.userId) {
      try {
        const response = await axiosPrivate.patch(`/bank-admin/company/delete-admin/${currentDeletVal.userId}`);
        if (response.status === 200) {
          setShowDeleteModel(false);
          const newArray = [...adminDetailsMain];
          newArray.splice(deleteIndex, 1);
          setAdminDetailsMain(newArray);
          setShowAlertBox(true)
          setShowType("success")
          setShowMessage("The selected record(s) has been deleted")
          clearAleart()
          setAdminDetails(initData)
          setFormDataEdit(false);
        }
      } catch (error: any) {
        setShowDeleteModel(false);
        setShowAlertBox(true)
        setShowType("danger")
        setShowMessage(error.response.data.error)
        clearAleart()
      }
    } else if (currentDeletVal.index === 0) {
      const newArray = [...adminDetailsMain];
      newArray.splice(deleteIndex, 1);
      setAdminDetailsMain(newArray)
      setShowDeleteModel(false)
      setShowAlertBox(true)
      setShowType("success")
      setShowMessage("The selected record(s) has been deleted")
      clearAleart()
    }
  };

  const userValidationRules: any = {
    name: {
      regex: /^[a-zA-Z][a-zA-Z /]*$/,
      field: "mandatory",
      shouldNotBe: ""
    },
    designation: {
      regex: /[A-Za-z]$/,
      field: "mandatory",
      shouldNotBe: ""
    },
    mobileNumber: {
      regex: /^[0-9]{10}$/,
      field: "mandatory",
      shouldNotBe: ""
    },
    email: {
      regex: /^[a-z0-9._-]+@[a-z0-9-]+\.[a-z]{2,}$/,
      field: "mandatory",
      shouldNotBe: ""
    },
    userIsActiveRemarks: {
      regex: isEditMode && adminDetails.userIsActive === "No" ? /^[^\s].*/ : "",
      field: isEditMode && adminDetails.userIsActive === "No" ? "mandatory" : "",
      shouldNotBe: ""
    },
  }
  const updatedIsValueValidAll: Record<string, boolean> = {};
  const validateForm = (): boolean => {
    const updatedFieldValidity: Record<string, boolean> = {};
    const isMandatoryfalse = true;
    const isRegexfalse = true;
    Object.keys(userValidationRules).forEach((field) => {
      const rule = userValidationRules[field];
      const value = adminDetails && adminDetails[field];
      const isValueValid = (rule.field === "mandatory") ? (value ? (value !== rule.shouldNotBe) : false) : true;
      const isRegexValid = rule.regex !== "" ? new RegExp(rule.regex).test(value) : true;
      updatedFieldValidity[field] = !isValueValid || !isRegexValid;
      updatedIsValueValidAll[field] = !isValueValid;
    });
    setCompanyOverviewErrors(updatedFieldValidity);
    const isFormValid = Object.keys(updatedFieldValidity).every((keys) => {
      return !updatedFieldValidity[keys]
    });
    return isFormValid;
  };

  function retriveData(value: any, index: number) {
    setCompanyOverviewErrors({})
    setCurrentIndex(index)
    setCurrentAdmin(value.id)
    setFormDataEdit(true)
    if (Object.values(value).length !== 3) {
      setAdminDetails({
        ...adminDetails,
        ...value,
      })
    }

  }
  const axiosPrivate = useAxiosPrivate();
  async function duplicateCheck() {
    const finalData = {
      "email": adminDetails.email,
      "mobileNumber": adminDetails.mobileNumber
    }
    let statusMessage: boolean = false;
    try {
      const fieldsToCheck = ['email', 'mobileNumber'];
      const todisplay = ['Email', 'Contact Number'];
      const failedFields: any[] = [];
      let isDuplicate = false;

      if (!formDataEdit) {
        isDuplicate = adminDetailsMain.some((item: any) => {
          const matches = fieldsToCheck.map((field, i) => {
            if (adminDetails[field] && adminDetails[field] !== '') {
              if (item[field] === adminDetails[field]) {
                failedFields.push(todisplay[i]);
              }
              return item[field] === adminDetails[field];
            }
            return false;
          });
          return matches.some(match => match);
        });
      } else {
        isDuplicate = adminDetailsMain.some((item: any, index: any) => {
          const matches = fieldsToCheck.map((field, i) => {
            if (adminDetails[field] && adminDetails[field] !== '' && currentIndex != index) {
              if (item[field] === adminDetails[field]) {
                failedFields.push(todisplay[i]);
              }
              return item[field] === adminDetails[field];
            }
            return false;
          });
          return matches.some(match => match);
        });
      }
      if (!isDuplicate) {
        const res = isEditMode ? await axiosPrivate.patch(`bank-admin/company/duplicate-check/adminData/${currentAdmin}`, finalData) : await axiosPrivate.post("bank-admin/company/duplicate-check/adminData", finalData)
        if (res.status === 200) {
          statusMessage = true;
        }
      } else {
        statusMessage = false
        setShowAlertBox(true)
        setShowType("danger")
        setShowMessage(`${failedFields[0]} already exists`)
        clearAlert("")
      }
    }
    catch (error: any) {
      statusMessage = false;
      setShowAlertBox(true)
      setShowType("danger")
      setShowMessage(error.response.data.error)
      clearAlert("")
    }
    return statusMessage;
  }
  const clearAlert = (status: any) => {
    const timer = setTimeout(() => {
      setShowAlertBox(false);
      setShowMessage("");
      if (status === 400) {

      }
      clearTimeout(timer);
    }, 5000);
  }


  // async function handelAddButton() {
  //   if (!validateForm()) {
  //     setShowAlertBox(true);
  //     setShowType("danger");
  //     setShowMessage(Object.values(updatedIsValueValidAll).every((values) => !values) ?
  //       "Some of the field(s) are not in required format." : "Mandatory field(s) should not be left blank.")
  //     clearAlert('');
  //     return;
  //   }
  //   let Duplicate = await duplicateCheck();
  //   if (!Duplicate) return;
  //   setIsFirstRender(true);
  //   if (formDataEdit) {
  //     let data: any = []
  //     adminDetailsMain.length > 0 && adminDetailsMain.map((ele: any, i: number) => {
  //       if (i === currentIndex) {
  //         data.push(adminDetails)
  //       } else {
  //         data.push(ele)
  //       }
  //     })
  //     setFormDataEdit(false)
  //     setAdminDetailsMain(data)
  //     setAdminDetails(
  //       {
  //         name: "",
  //         designation: "",
  //         mobileNumber: "",
  //         email: "",
  //         userIsActive: "Yes",
  //         userIsActiveRemarks: "",
  //         index: 0
  //       }
  //     )
  //     setIsFormDirty(false);
  //   }
  //   else {
  //     let newrecord = adminDetailsMain
  //     newrecord.push({ ...adminDetails })
  //     setAdminDetailsMain(newrecord)
  //     setAdminDetails(
  //       {
  //         name: "",
  //         designation: "",
  //         mobileNumber: "",
  //         email: "",
  //         userIsActive: "Yes",
  //         userIsActiveRemarks: "",
  //         index: 0
  //       }
  //     )
  //     setIsFormDirty(false);
  //     setFormDataEdit(false)
  //   }
  // }


  function next() {
    setIconStatus({
      ...iconStatus,
      adminIcon: "completed",
      subscriptionIcon: "selected"
    })
  }

  useImperativeHandle(ref, () => {
    return {
      next: next
    }
  });

  return (
    <div className='form-wrapper'>
      <div className='form-area-address'>
        <div className='company-heading'>
          Admin Details
        </div>
        <div className='form-main-admin' style={{ opacity: 0.5, pointerEvents: "none" }}>
          <div>
            <InputComponent
              height={"40px"}
              width={"350px"}
              margin={"0px 45px 30px 0px"}
              padding={"0px 0px 0px 10px"}
              border={companyOverviewErrors.name ? "1px solid red" : "1px solid #A9C3DC"}
              borderRadius={"0px"}
              backgroundColor={"white"}
              color={"black"}
              type={"text"}
              inputTitle={"Name"}
              placeHolder={"Enter Name"}
              maxLength={100}
              disabled={true}
              required={true}
              inputValue={adminDetails && adminDetails.name}
              getUser={(value: any) => {
                setAdminDetails({
                  ...adminDetails,
                  name: value
                })
                setIconStatus({
                  ...iconStatus,
                  adminIcon: "selected",
                })
                setIsFormDirty(true)
                dispatch(resetFormModified(true));
              }}
            />
          </div>
          <div>
            <InputComponent
              height={"40px"}
              width={"350px"}
              margin={"0px 45px 30px 0px"}
              padding={"0px 0px 0px 10px"}
              border={companyOverviewErrors.designation ? "1px solid red" : "1px solid #A9C3DC"}
              borderRadius={"0px"}
              backgroundColor={"white"}
              color={"black"}
              type={"text"}
              disabled={true}
              inputTitle={"Designation"}
              placeHolder={"Enter Designation"}
              maxLength={100}
              required={true}
              inputValue={adminDetails && adminDetails.designation}
              getUser={(value: any) => {
                setAdminDetails({
                  ...adminDetails,
                  designation: value
                })
                setIconStatus({
                  ...iconStatus,
                  adminIcon: "selected",
                })
                setIsFormDirty(true)
                dispatch(resetFormModified(true));
              }}
            />
          </div>
        </div>
        <div className='form-main-admin' style={formDataEdit ?
          { pointerEvents: "none", opacity: 0.5 } : { pointerEvents: "auto", opacity: 1 }
        }>
          <div style={{ opacity: 0.5, pointerEvents: "none" }}>
            <InputComponent
              height={"40px"}
              width={"350px"}
              margin={"0px 45px 30px 0px"}
              padding={"0px 0px 0px 70px"}
              borderRadius={"0px"}
              backgroundColor={"white"}
              placeHolder={"Enter Contact Number"}
              color={"black"}
              type={"mobile"}
              disabled={formDataEdit}
              maxLength={10}
              inputTitle={"Contact Number"}
              required={true}
              className={"mobilenumber-input"}
              border={companyOverviewErrors.mobileNumber ? "1px solid red" : "1px solid #A9C3DC"}
              inputValue={adminDetails && adminDetails.mobileNumber}
              getUser={(value: any) => {
                const val = value.replace(/[^0-9]/g, "")
                setAdminDetails({
                  ...adminDetails,
                  mobileNumber: val
                })
                setIconStatus({
                  ...iconStatus,
                  adminIcon: "selected",
                })
                setIsFormDirty(true)
                dispatch(resetFormModified(true));
              }}
            />
          </div>
          <div style={{ opacity: 0.5, pointerEvents: "none" }}>
            <InputComponent
              height={"40px"}
              width={"350px"}
              margin={"0px 45px 30px 0px"}
              padding={"0px 40px 0px 10px"}
              borderRadius={"0px"}
              backgroundColor={"white"}
              color={"black"}
              type={"text"}
              placeHolder={"Enter Email ID"}
              inputTitle={"Email ID"}
              required={true}
              maxLength={254}
              className={"gmail-input"}
              disabled={formDataEdit}
              inputField={"gmail"}
              border={companyOverviewErrors.email ? "1px solid red" : "1px solid #A9C3DC"}
              inputValue={adminDetails && adminDetails.email}
              getUser={(value: any) => {
                setAdminDetails({
                  ...adminDetails,
                  email: value
                })
                setIconStatus({
                  ...iconStatus,
                  adminIcon: "selected",
                })
                setIsFormDirty(true)
                dispatch(resetFormModified(true));
              }}
            />
          </div>
        </div>
        <div className='form-main-radio' style={{ opacity: 0.5, pointerEvents: "none" }}>
          <div className='radio-input organisation-radio-input margin-isactive'>
            <div className=''>Is Active<span className='required'>*</span></div>
            <div className='radio-btn-flex' >
              <RadioOrCheckbox
                value={"Yes"}
                type={"radio"}
                name={"Yes"}
                disabled={true}
                margin={"0px 30px 0px 0px"}
                checkedValue={adminDetails && adminDetails.userIsActive}
                getVal={(value: any) => {
                  setCompanyOverviewErrors({})
                  setAdminDetails({
                    ...adminDetails,
                    userIsActive: value,
                    userIsActiveRemarks: ""
                  })
                  setIconStatus({
                    ...iconStatus,
                    adminIcon: "selected",
                  })
                  setIsFormDirty(true)
                  dispatch(resetFormModified(true));
                  setRadioActive(value)
                }}
              />
              {
                <div style={adminDetails.userIsActive === "No" ? { pointerEvents: "auto", opacity: 1 } : (formDataEdit ? { pointerEvents: "auto", opacity: 1 } : { pointerEvents: "none", opacity: 0.5 })}>
                  <RadioOrCheckbox
                    value={"No"}
                    type={"radio"}
                    name={"No"}
                    disabled={true}
                    checkedValue={adminDetails && adminDetails.userIsActive}
                    getVal={(value: any) => {
                      setCompanyOverviewErrors({})
                      setAdminDetails({
                        ...adminDetails,
                        userIsActive: value
                      })
                      setIconStatus({
                        ...iconStatus,
                        adminIcon: "selected",
                      })
                      setIsFormDirty(true)
                      dispatch(resetFormModified(true));
                      setRadioActive(value)
                    }}
                  />
                </div>
              }
            </div>
          </div>
          {isEditMode && adminDetails.userIsActive === "No" &&
            <div style={(isEditMode && adminDetails.userIsActive === "No") ? {} : { pointerEvents: "none", opacity: "0.8" }}>
              <InputComponent
                height={"40px"}
                width={"650px"}
                margin={"0px 45px 30px 0px"}
                padding={"0px 0px 0px 10px"}
                borderRadius={"0px"}
                backgroundColor={"white"}
                color={"black"}
                disabled={(isEditMode && adminDetails.userIsActive === "No") ? false : true}
                type={"text"}
                placeHolder={"Reason"}
                inputTitle={"Remarks"}
                required={true}
                maxLength={250}
                border={companyOverviewErrors.userIsActiveRemarks ? "1px solid red" : "1px solid #A9C3DC"}
                inputValue={adminDetails && adminDetails.userIsActiveRemarks}
                getUser={(value: any) => {
                  setAdminDetails({
                    ...adminDetails,
                    userIsActiveRemarks: value
                  })
                  setIconStatus({
                    ...iconStatus,
                    adminIcon: "selected",
                  })
                  setIsFormDirty(true)
                  dispatch(resetFormModified(true));
                }}
              />
            </div>
          }
        </div>
        <div className={"Company-DetailsGrid"} style={{ pointerEvents: "none", opacity: 0.5 }}>
          <AdminDetailsGrid
            adminDetails={adminDetailsMain}
            retriveData={retriveData}
            showmodal={(val: any, index: any) => {
              setShowDeleteModel(true);
              setDeleteIndex(index);
              setCurrentDeletVal(val);
            }}
          />
        </div>
        <div className='footer-company'>
          <div>
            {/* <ButtonComponent
              title={"Cancel"}
              height={"50px"}
              width={"150px"}
              margin={"0px 8px"}
              backgroundColor={"#888888"}
              color={"white"}
              className={"button-component-hover cancel"}
              handleClick={isFormModified ? () => { setShowChangesModal(true) } : returnGird}
            /> */}


            <ButtonComponent
              title={isEditMode ? "Update" : "Add"}
              height={"50px"}
              width={"150px"}
              backgroundColor={"#0055D4"}
              color={"white"}
              margin={"0px 8px"}
              disabled={isEditMode ? true : false}
              className={isEditMode ? "button-component-hover disabled" : "button-component-hover common-btn"}
              handleClick={next}
            />
          </div>
        </div>
      </div>
      {
        showAlertBox &&
        <div className='alert-warp'>
          <AlertBox type={showType} message={showMessage} />
        </div>
      }
      {
        showChangesModal &&
        <Changesmodal
          closeModal={() => setShowChangesModal(false)}
          handleClose={() => {
            setShowChangesModal(false);
          }}
          leavePage={() => returnGird()} />
      }
      {
        showDeleteModel &&
        <div >
          <DeleteModal
            getDelete={() => { DirectorInfoDelete() }}

            clearValue={(value) => {
              setShowDeleteModel(value);
              setCurrentDeletVal({})
            }}
            modelType={"grid-delete"}

          />
        </div>
      }
    </div>
  )
})
export default AdminDetails