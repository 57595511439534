import React, { useEffect, useState, forwardRef, useImperativeHandle, } from 'react'
import InputComponent from '../../../common-component/form-elements/input-component'
import ButtonComponent from '../../../common-component/form-elements/button-component'
import useAxiosPrivate from "../../../../services/hooks/useaxios-private";
import AlertBox from '../../../common-component/alert-box'
import { useDispatch, useSelector } from "react-redux";
import { resetFormModified } from "../../../../redux/action";
import Changesmodal from '../../../common-component/modals/changes-modal'

export interface ChildRef {
  next: () => void;
}
interface props {
  onboardingViewfun: (val: any) => void;
  iconStatus: any
  editData: any
  businessContactMain: any
  setShowPage: (val: any) => void;
  setIconStatus: (val: any) => void;
  sendDataApi: () => void;
  mainApiData: any;
  setBusinessContactMain: (val: any) => void;
  isEditMode: boolean;
}
const PointOfContact = forwardRef<ChildRef, props>(({ onboardingViewfun, iconStatus, editData, mainApiData, isEditMode, sendDataApi, setShowPage, setIconStatus, businessContactMain, setBusinessContactMain }, ref) => {


  const [companyOverviewErrors, setCompanyOverviewErrors] = useState<Record<string, boolean>>({})
  const [showType, setShowType] = useState("warning") // error message showType
  const [showMessage, setShowMessage] = useState<string>("") // error message showMessage
  const [showAlertBox, setShowAlertBox] = useState(false) // error message ShowAlertBox
  const [isFormDirty, setIsFormDirty] = useState(false)
  const isFormModified = useSelector((state: any) => state.isFormModified);
  const [showChangesModal, setShowChangesModal] = useState<boolean>(false);
  const dispatch = useDispatch();

  const userValidationRules: any = {
    contactPersonName: {
      regex: /[A-Za-z]$/,
      field: "mandatory",
      shouldNotBe: ""
    },

    phoneNumber: {
      regex: /^[0-9]{10}$/,
      field: "mandatory",
      shouldNotBe: ""
    },
    email: {
      regex: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      field: "mandatory",
      shouldNotBe: ""
    },
  }

  const updatedIsValueValidAll: Record<string, boolean> = {};
  const validateForm = (): boolean => {
    const updatedFieldValidity: Record<string, boolean> = {};
    const isMandatoryfalse = true;
    const isRegexfalse = true;
    Object.keys(userValidationRules).forEach((field) => {
      const rule = userValidationRules[field];
      const value = businessContactMain && businessContactMain[field];
      const isValueValid = (rule.field === "mandatory") ? (value ? (value !== rule.shouldNotBe) : false) : true;
      const isRegexValid = rule.regex !== "" ? new RegExp(rule.regex).test(value) : true;
      updatedFieldValidity[field] = !isValueValid || !isRegexValid;
      updatedIsValueValidAll[field] = !isValueValid;
    });
    setCompanyOverviewErrors(updatedFieldValidity);
    const isFormValid = Object.keys(updatedFieldValidity).every((keys) => {
      return !updatedFieldValidity[keys]
    });
    return isFormValid;
  };

  function returnGird() {
    onboardingViewfun("grid")
    dispatch(resetFormModified(false));
  }

  const axiosPrivate = useAxiosPrivate();


  const clearAlert = (status: any) => {
    const timer = setTimeout(() => {
      setShowAlertBox(false);
      setShowMessage("");
      if (status === 400) {

      }
      clearTimeout(timer);
    }, 5000);
  }

  async function next(next: any = "") {
    setShowPage(next != "" ? next : "Approval")
    if (next != "") {
      switch (next) {
        case "CompanyOverview":
          setIconStatus({
            ...iconStatus,
            CustomerIcon: "selected",
          })
          break;
        case "Address":
          setIconStatus({
            ...iconStatus,
            CustomerIcon: "completed",
            addressIcon: "selected",
          })
          break;
        case "pointofContact":
          setIconStatus({
            ...iconStatus,
            pointofContact: "completed",
            Approval: "selected",

          })
          break;
        case "Approval":
          setIconStatus({
            ...iconStatus,
            Approval: "completed",

          })
          break;

        default:
          break;
      }
    }
  }


  return (
    <div className='form-wrapper'>
      <div className='form-area-address'>
        <div className='company-heading' style={isEditMode ?
          { pointerEvents: "none", opacity: 0.5 } : { pointerEvents: "auto", opacity: 1 }}>
          Point of Contact
        </div>
        <div className='form-main-admin' style={isEditMode ?
          { pointerEvents: "none", opacity: 0.5 } : { pointerEvents: "auto", opacity: 1 }}>
          <div>
            <InputComponent
              height={"40px"}
              width={"300px"}
              margin={"0px 45px 30px 0px"}
              padding={"0px 0px 0px 10px"}
              border={companyOverviewErrors.Name ? "1px solid red" : "1px solid #A9C3DC"}
              borderRadius={"0px"}
              backgroundColor={"white"}
              placeHolder={"Enter Name"}
              color={"black"}
              type={"text"}
              inputTitle={"Contact Person Name"}
              disabled={isEditMode ? true : false}

              maxLength={100}
              required={true}
              inputValue={businessContactMain && businessContactMain.contactPersonName}
              getUser={(value: any) => {
                setBusinessContactMain({
                  ...businessContactMain,
                  contactPersonName: value
                })
                setIconStatus({
                  ...iconStatus,
                  pointofContact: "selected",
                })
                setIsFormDirty(true)
                dispatch(resetFormModified(true));
              }}
            />
          </div>
          <div>
            <InputComponent
              height={"40px"}
              width={"300px"}
              margin={"0px 45px 30px 0px"}
              padding={"0px 0px 0px 70px"}
              borderRadius={"0px"}
              backgroundColor={"white"}
              color={"black"}
              type={"mobile"}
              placeHolder={"Enter Phone Numbe"}
              maxLength={10}
              inputTitle={"Phone Number"}
              required={true}
              className={"mobilenumber-input"}
              disabled={isEditMode ? true : false}

              border={companyOverviewErrors.phoneNumber ? "1px solid red" : "1px solid #A9C3DC"}
              inputValue={businessContactMain && businessContactMain.phoneNumber}
              getUser={(value: any) => {
                const val = value.replace(/[^0-9]/g, "")
                setBusinessContactMain({
                  ...businessContactMain,
                  phoneNumber: val
                })
                setIconStatus({
                  ...iconStatus,
                  pointofContact: "selected",
                })
                setIsFormDirty(true)
                dispatch(resetFormModified(true));
              }}
            />
          </div>
          <div className='mail-icon-vendor-input'>
            <InputComponent
              height={"40px"}
              width={"300px"}
              margin={"0px 0px 45px 0px"}
              padding={"0px 65px 0px 10px"}
              borderRadius={"0px"}
              backgroundColor={"white"}
              color={"black"}
              type={"text"}
              placeHolder={"Enter Email ID"}
              inputTitle={"Email ID"}
              required={true}
              maxLength={254}
              className={"gmail-input"}
              inputField={"gmail"}
              disabled={isEditMode ? true : false}

              border={companyOverviewErrors.email ? "1px solid red" : "1px solid #A9C3DC"}
              inputValue={businessContactMain && businessContactMain.email}
              getUser={(value: any) => {
                setBusinessContactMain({
                  ...businessContactMain,
                  email: value
                })
                setIconStatus({
                  ...iconStatus,
                  pointofContact: "selected",
                })
                setIsFormDirty(true)
                dispatch(resetFormModified(true));
              }}
            />
          </div>

        </div>
        <div className='form-main-admin' style={isEditMode ?
          { pointerEvents: "none", opacity: 0.5 } : { pointerEvents: "auto", opacity: 1 }}>
          <div>
            <InputComponent
              height={"40px"}
              width={"300px"}
              margin={"0px 0px 30px 0px"}
              padding={"0px 0px 0px 10px"}
              border={companyOverviewErrors.designation ? "1px solid red" : "1px solid #A9C3DC"}
              borderRadius={"0px"}
              backgroundColor={"white"}
              placeHolder={"Enter Designation"}
              color={"black"}
              type={"text"}
              inputTitle={"Designation"}
              disabled={isEditMode ? true : false}

              maxLength={100}
              required={false}
              inputValue={businessContactMain && businessContactMain.designation}
              getUser={(value: any) => {
                const val = value.replace(/^[a-zA-Z][a-zA-Z /]*$/, "");
                setBusinessContactMain({
                  ...businessContactMain,
                  designation: val
                })
                setIconStatus({
                  ...iconStatus,
                  pointofContact: "selected",
                })
                setIsFormDirty(true)
                dispatch(resetFormModified(true));
              }}
            />
          </div>
        </div>


        <div>
          <div className='footer-company'>
            <ButtonComponent
              title={"Cancel"}
              height={"50px"}
              width={"150px"}
              margin={"0px 8px"}
              backgroundColor={"#888888"}
              color={"white"}
              className={"button-component-hover cancel"}
              handleClick={isFormModified ? () => { setShowChangesModal(true) } : returnGird}
            />
            <div>

              <ButtonComponent
                title={"Next"}
                height={"50px"}
                width={"150px"}
                backgroundColor={"#0055D4"}
                color={"white"}
                disabled={false}
                margin={"0px 8px"}
                className={"button-component-hover common-btn"}
                handleClick={next}
              />
            </div>
          </div>
        </div>
      </div>
      {
        showAlertBox &&
        <div className='alert-warp'>
          <AlertBox type={showType} message={showMessage} />
        </div>
      }
      {
        showChangesModal &&
        <Changesmodal
          closeModal={() => setShowChangesModal(false)}
          handleClose={() => {
            setShowChangesModal(false);
          }}
          leavePage={() => returnGird()} />
      }
    </div>
  )
})
export default PointOfContact
