import React, { useState } from 'react'
import { RxCross1 } from 'react-icons/rx'
import '../../../styles/gst/gst-view-challan-modal.scss'
import GstEyeIconGrid from '../../../config/gst/gst-eye-icon-grid';
import GstPaymentHistory from '../../../config/gst/gst-payment-history';
import GstApprovalStatus from '../../../config/gst/gst-approval-status';
import GstMainEyeIconGrid from '../../../config/gst/gst-main-eye-icon-grid';
interface Props {
    clearValue: (value: any) => void;
    approvalChallanDetails?: any;
    approvalStatus?: any;
}

function GstApprovalChallanModal({ clearValue, approvalChallanDetails, approvalStatus }: Props) {
    return (
        <div>
            <div className={"department-add-modal-gst"}>
                <div className={"container-gst"}>
                    <div className={"close-icon"}>
                        <span className={"cross-icon"}>
                            <RxCross1 onClick={() => { clearValue(false) }
                            } style={{ fontSize: "40px" }} />
                        </span>
                    </div>
                    <div className='main-wrapper-gst-challan'>
                        <div className='challan-details-text'>Challan Details</div>
                        <div>
                            <GstMainEyeIconGrid
                                mainChallanDetails={approvalChallanDetails}
                            />
                        </div>
                        <div>
                            <GstPaymentHistory

                            />
                        </div>
                        <div>
                            <GstApprovalStatus
                                approvalStatus={approvalStatus}
                            />
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default GstApprovalChallanModal