import React, { useEffect, useRef, useState } from 'react'
import IconButton from '../../../common-component/form-elements/icon-button';
import { CiSearch } from 'react-icons/ci';
import ChartFilter from '../../../common-component/charts/chart-filter';
import { axiosPrivate } from '../../../../middleware/axois-api';
import '../../../../styles/reports/report-table.scss';
import { handlePngPDFDownload } from '../../../common-component/export-pdf/Export-to-pdf';
import DownloadOption from '../../download-option';
import AlertBox from '../../../common-component/alert-box';
import { VendorReportFilter } from '../../../../config/reports/master-reports/vendor-report-config';



interface props {
    searchBarValue: string
    filterOpen: any
    setFilterOpen: any
    conponentPDFMaster: any
}
export default function VendorGrid({ searchBarValue, filterOpen, setFilterOpen, conponentPDFMaster }: props) {
    const divRef = useRef<HTMLDivElement>(null);
    const [filteredData, setFilteredData] = useState<any[]>([]);
    const [orginalRowData, setOrginalRowData] = useState<any[]>([]);
    // const [filterOpen, setFilterOpen] = useState<boolean>(false);
    const [openDropdownIndex, setOpenDropdownIndex] = useState<number | null>(null);
    const [storeFilterOptions, setStoreFilterOptions] = useState<any>([]);
    const gridFilterRef = useRef<HTMLDivElement | null>(null);
    const [showAlertBox, setShowAlertBox] = useState<boolean>(false)
    const [showMessage, setShowMessage] = useState<string>("")
    const [showType, setShowType] = useState<string>("warning")

    //filter 
    const [filterValue, setFilterValues] = useState<any>([])


    // all data filter
    // const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     const value = event.target.value;
    //     setSearchValue(value);

    //     const filtered = SampleDatasArun.filter((item) => {
    //         // Customize the condition based on your specific requirements
    //         return Object.values(item).some((val) => {
    //             if (val && typeof val === 'object') {
    //                 // If the value is an object and not null/undefined, check its values
    //                 return Object.values(val).some((nestedVal: any) =>
    //                     nestedVal && nestedVal.toString().toLowerCase().includes(value.toLowerCase())
    //                 );
    //             } else if (val) {
    //                 // If the value is not an object and not null/undefined, directly check it
    //                 return val.toString().toLowerCase().includes(value.toLowerCase());
    //             }
    //             return false; // Skip null/undefined values
    //         });
    //     });

    //     setFilteredData(filtered); 
    // };





    useEffect(() => {
        const filtered = orginalRowData.filter((item) => {
            const vendorDetails = item.VendorDetails;
            if (
                vendorDetails &&
                (vendorDetails["Vendor Code"]?.toLowerCase().includes(searchBarValue.toLowerCase()) ||
                    vendorDetails["Name"]?.toLowerCase().includes(searchBarValue.toLowerCase()))
            ) {
                // Return an object containing the matched field and its value
                return {
                    matchedField: vendorDetails["Vendor Code"]?.toLowerCase().includes(searchBarValue.toLowerCase())
                        ? "Vendor Code"
                        : "Name",
                    value: vendorDetails["Vendor Code"] || vendorDetails["Name"],
                };
            }

            return null;
        });
        setFilteredData(filtered);
    }, [searchBarValue])


    async function getDropDownData() {
        try {
            const res = await axiosPrivate.get(`companyadmin/master-reports/vendor-fillter`);
            if (res.status === 200) {
                addDataForDropDownFilter(res)
            }
        } catch (error) {
            console.log(`Error:${error}`)
        }
    }



    const fetchData = async () => {
        try {
            const response = await axiosPrivate.get('/companyadmin/master-reports/vendor');
            setOrginalRowData(response.data.vendorDetailsReports)
            setFilteredData(response.data.vendorDetailsReports)
            addDataForDropDownFilter(response.data.dropDownData)
        } catch (error) {
            console.log("first", error)
        }
    };

    // filter functions

    useEffect(() => {
        handleApplyButtonForFilter(VendorReportFilter["Vendor"])
        setStoreFilterOptions(VendorReportFilter["Vendor"])
        setFilterValues(VendorReportFilter["Vendor"])
        fetchData()
    }, [])

    const handleOpenClose = (index: number) => {
        if (openDropdownIndex === index) {
            setOpenDropdownIndex(null); // Close the dropdown if it's already open
        } else {
            setOpenDropdownIndex(index); // Open the dropdown
        }
    };

    const updateFilterDatas = async (index: number, newData: any) => {
        setStoreFilterOptions((prevFilterDatas: any) => {
            const updatedFilterDatas = [...prevFilterDatas];
            updatedFilterDatas[index] = newData;
            return updatedFilterDatas;
        });

        setFilterValues((prevFilterDatas: any) => {
            const updatedFilterDatas = [...prevFilterDatas];
            updatedFilterDatas[index] = newData;
            return updatedFilterDatas;
        });
    };

    function addDataForDropDownFilter(res: any) {
        if (Object.keys(res).length > 0) {

            const updatedAccountNumber: any = [
                { value: "All", label: "All" },
                ...res['Account Number'].map((elem: any) => ({ value: elem, label: elem }))
            ];
            const updatedBank: any = [
                { value: "All", label: "All" },
                ...res.Bank.map((elem: any) => ({ value: elem, label: elem }))
            ];
            const updatedBankApprovalStatus: any = [
                { value: "All", label: "All" },
                ...res['Bank Approval Status'].map((elem: any) => ({ value: elem, label: elem }))
            ];
            const updatedBranch: any = [
                { value: "All", label: "All" },
                ...res.Branch.map((elem: any) => ({ value: elem, label: elem }))
            ];
            const updatedBuisnessCategory: any = [
                { value: "All", label: "All" },
                ...res['Company Sub Category'].map((elem: any) => ({ value: elem, label: elem }))
            ];

            const updatedCompanyConstitution: any = [
                { value: "All", label: "All" },
                ...res['Company Constitution'].map((elem: any) => ({ value: elem, label: elem }))
            ];
            const updatedContactPersonName: any = [
                { value: "All", label: "All" },
                ...res['Contact Person Name'].map((elem: any) => ({ value: elem, label: elem }))
            ];
            const updatedEmail: any = [
                { value: "All", label: "All" },
                ...res.Email.map((elem: any) => ({ value: elem, label: elem }))
            ];
            const updatedGSTIN: any = [
                { value: "All", label: "All" },
                ...res.GSTIN.map((elem: any) => ({ value: elem, label: elem }))
            ];
            const updatedIFSCCode: any = [
                { value: "All", label: "All" },
                ...res['IFSC Code'].map((elem: any) => ({ value: elem, label: elem }))
            ];
            const updatedInternalApprovalStatus: any = [
                { value: "All", label: "All" },
                ...res['Is Approved'].map((elem: any) => ({ value: elem, label: elem }))
            ];
            const updatedIsActive: any = [
                { value: "All", label: "All" },
                ...res["Is Active"].map((elem: any) => ({ value: elem, label: elem }))
            ];

            const updatedName: any = [
                { value: "All", label: "All" },
                ...res.Name.map((elem: any) => ({ value: elem, label: elem }))
            ];

            const updatedPhoneNumber: any = [
                { value: "All", label: "All" },
                ...res['Phone Number'].map((elem: any) => ({ value: elem, label: elem }))
            ];

            const updatedUPI: any = [
                { value: "All", label: "All" },
                ...res.UPI.map((elem: any) => ({ value: elem, label: elem }))
            ];
            const updatedVendorCode: any = [
                { value: "All", label: "All" },
                ...res['Vendor Code'].map((elem: any) => ({ value: elem, label: elem }))
            ];

            setStoreFilterOptions((prevOptions: any) => {
                return prevOptions.map((option: any) => {
                    switch (option.cardTitle) {
                        case "Account Number":
                            return { ...option, options: updatedAccountNumber };
                        case "Bank":
                            return { ...option, options: updatedBank };
                        case "Bank Approval Status":
                            return { ...option, options: updatedBankApprovalStatus };
                        case "Branch":
                            return { ...option, options: updatedBranch };
                        case "Buisness Category":
                            return { ...option, options: updatedBuisnessCategory };

                        case "Company Constitution":
                            return { ...option, options: updatedCompanyConstitution };
                        case "Contact Person Name":
                            return { ...option, options: updatedContactPersonName };
                        case "Email":
                            return { ...option, options: updatedEmail };
                        case "GSTIN":
                            return { ...option, options: updatedGSTIN };
                        case "IFSC Code":
                            return { ...option, options: updatedIFSCCode };
                        case "Is Approved":
                            return { ...option, options: updatedInternalApprovalStatus };
                        case "IsActive":
                            return { ...option, options: updatedIsActive };

                        case "Phone Number":
                            return { ...option, options: updatedPhoneNumber };

                        case "UPI":
                            return { ...option, options: updatedUPI };

                        case "Name":
                            return { ...option, options: updatedName };
                        case "Vendor Code":
                            return { ...option, options: updatedVendorCode };
                        default:
                            return option;
                    }
                });
            });

            setFilterValues((prevOptions: any) => {
                return prevOptions.map((option: any) => {
                    switch (option.cardTitle) {
                        case "Account Number":
                            return { ...option, options: updatedAccountNumber };
                        case "Bank":
                            return { ...option, options: updatedBank };
                        case "Bank Approval Status":
                            return { ...option, options: updatedBankApprovalStatus };
                        case "Branch":
                            return { ...option, options: updatedBranch };
                        case "Buisness Category":
                            return { ...option, options: updatedBuisnessCategory };
                        case "Company Constitution":
                            return { ...option, options: updatedCompanyConstitution };
                        case "Contact Person Name":
                            return { ...option, options: updatedContactPersonName };
                        case "Email":
                            return { ...option, options: updatedEmail };
                        case "GSTIN":
                            return { ...option, options: updatedGSTIN };
                        case "IFSC Code":
                            return { ...option, options: updatedIFSCCode };
                        case "Is Approved":
                            return { ...option, options: updatedInternalApprovalStatus };
                        case "IsActive":
                            return { ...option, options: updatedIsActive };
                        case "Phone Number":
                            return { ...option, options: updatedPhoneNumber };
                        case "UPI":
                            return { ...option, options: updatedUPI };
                        case "Name":
                            return { ...option, options: updatedName };
                        case "Vendor Code":
                            return { ...option, options: updatedVendorCode };
                        default:
                            return option;
                    }
                });
            });

        }
    }

    const convertToFilterValues = (filterData: any) => {
        const filterValues: any = {};
        filterData.forEach((filter: any) => {
            const tittle = (filter.cardTitle).replace(/\s/g, '')
            filterValues[tittle] = filter.selectedValue;
        });
        return filterValues;
    };

    async function handleApplyButtonForFilter(data: any) {
        const convertValue = convertToFilterValues(filterValue)
        if (
            convertValue['VendorCode'] == "All" &&
            convertValue['Name'] == "All" &&
            convertValue['IsActive'] == "All" &&
            convertValue['IsApproved'] == "All" &&
            convertValue['BankApprovalStatus'] == "All" &&
            convertValue['CompanyConstitution'] == "All" &&
            convertValue['BuisnessCategory'] == "All" &&
            convertValue['GSTIN'] == "All" &&
            convertValue['Bank'] == "All" &&
            convertValue['Branch'] == "All" &&
            convertValue['AccountNumber'] == "All" &&
            convertValue['IFSCCode'] == "All" &&
            convertValue['UPI'] == "All" &&
            convertValue['ContactPersonName'] == "All" && 
            convertValue['PhoneNumber'] == "All" &&
            convertValue['Email'] == "All"
        ) {
            setFilteredData(orginalRowData)
        } else {
            const filteredData: any = orginalRowData && orginalRowData.filter((ele: any) => {
                const VendorCodeCondition = convertValue['VendorCode'] === 'All' || ele.VendorDetails['Vendor Code'] === convertValue['VendorCode'];

                const NameCondition = convertValue['Name'] === 'All' || ele.VendorDetails.Name === convertValue['Name'];
                const IsActiveCondition = convertValue['IsActive'] === 'All' || ele.VendorDetails["Is Active"] === convertValue['IsActive'];
                const InternalApprovalStatusCondition = convertValue['IsApproved'] === 'All' || ele.VendorDetails['Is Approved'] === convertValue['IsApproved'];
                const BankApprovalStatusCondition = convertValue['BankApprovalStatus'] === 'All' || ele.VendorDetails['Bank Approval Status'] === convertValue['BankApprovalStatus'];

                const CompanyConstitutionCondition = convertValue['CompanyConstitution'] === 'All' || ele.EntityInformation['Company Constitution'] === convertValue['CompanyConstitution'];
                const BuisnessCategoryCondition = convertValue['BuisnessCategory'] === 'All' || ele.EntityInformation['Buisness Category'] === convertValue['BuisnessCategory'];
                const GSTINCondition = convertValue['GSTIN'] === 'All' || ele.GSTIN.includes(convertValue['GSTIN']);
                const BankCondition = convertValue['Bank'] === 'All' || ele.AccountDetails.Bank === convertValue['Bank'];
                const BranchCondition = convertValue['Branch'] === 'All' || ele.AccountDetails.Branch === convertValue['Branch'];
                const AccountNumberCondition = convertValue['AccountNumber'] === 'All' || ele.AccountDetails['Account Number'] === convertValue['AccountNumber'];
                const IFSCCodeCondition = convertValue['IFSCCode'] === 'All' || ele.AccountDetails['IFSC Code'] === convertValue['IFSCCode'];
                const UPICondition = convertValue['UPI'] === 'All' || ele.AccountDetails.UPI === convertValue['UPI'];
                const ContactPersonNameCondition = convertValue['ContactPersonName'] === 'All' || ele.AddressDetails['Contact Person Name'] === convertValue['ContactPersonName'];
                const PhoneNumberCondition = convertValue['PhoneNumber'] === 'All' || ele.AddressDetails['Phone Number'] === convertValue['PhoneNumber'];
                const EmailCondition = convertValue['Email'] === 'All' || ele.AddressDetails.Email === convertValue['Email'];


                return VendorCodeCondition && NameCondition && IsActiveCondition && InternalApprovalStatusCondition && BankApprovalStatusCondition && CompanyConstitutionCondition && BuisnessCategoryCondition
                    && GSTINCondition && BankCondition && BranchCondition && AccountNumberCondition && IFSCCodeCondition && UPICondition && ContactPersonNameCondition && PhoneNumberCondition && EmailCondition;
            });
            setFilteredData(filteredData);
        }

        setFilterOpen(false)

    }

    return (
        <>
            <div className='report-table-component' style={{ marginTop: "20px", overflowX: "auto" }}  >
                <table ref={conponentPDFMaster}>
                    <thead>
                        {/* <tr> */}
                        <th className='report-heading' style={{ paddingLeft: "10px", }}>Vendor Details</th>
                        <th className='report-heading' style={{ paddingLeft: "10px" }}>Entity Information</th>
                        <th className='report-heading' style={{ paddingLeft: "10px" }}>GSTIN</th>
                        <th className='report-heading' style={{ paddingLeft: "10px" }}>Account Details</th>
                        <th className='report-heading' style={{ paddingLeft: "10px" }}>Address Details</th>
                        {/* </tr> */}
                    </thead>
                    <tbody>
                        {
                            filteredData.length === 0 ?
                                <tr className='style-grid-differ'>

                                    <td></td>
                                    <td></td>
                                    <td style={{ textAlign: "center" }}>No records to show</td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                :
                                filteredData.map((data: any, index: any) => (
                                    <tr className='main-body-header' key={index} >
                                        <td className='report-details' >
                                            {Object.entries(data.VendorDetails).map(([key, value]: any) => (
                                                <p key={key} style={{ whiteSpace: "normal", width: "350px" }}>
                                                    <i>{key}</i>  - {value}
                                                </p>
                                            ))}
                                        </td>
                                        <td className='report-details' >
                                            {Object.entries(data.EntityInformation).map(([key, value]: any) => (
                                                <p key={key} style={{ width: "350px", whiteSpace: "normal" }}>
                                                    <i>{key}</i> - {value}
                                                </p>
                                            ))}
                                        </td>
                                        <td className='report-details'>
                                            {data.GSTIN?.slice(0, 7).map((gstin: any, index: number) => (
                                                <p key={index} style={{ textOverflow: "ellipsis" }}>
                                                    {gstin}  {index < data.GSTIN.length - 1 && ","}
                                                </p>
                                            ))}
                                        </td>
                                        <td className='report-details' >
                                            {Object.entries(data.AccountDetails).map(([key, value]: any) => (
                                                <p key={key}>
                                                    <i>{key}</i> - {value}
                                                </p>
                                            ))}
                                        </td>
                                        <td className='report-details' >
                                            {Object.entries(data.AddressDetails).map(([key, value]: any) => (
                                                <p key={key} style={{ width: "350px", whiteSpace: "normal" }}>
                                                    {/* <> */}
                                                    <i>{key}</i> - {value}
                                                    {/* </> */}
                                                </p>
                                            ))}
                                        </td>
                                    </tr>
                                ))}
                    </tbody>

                </table>
            </div>

            {filterOpen &&
                <div className="right-side-filter-component" >
                    <ChartFilter
                        datas={storeFilterOptions}
                        updateFilterDatas={updateFilterDatas}
                        handleOpenClose={handleOpenClose}
                        openDropdownIndex={openDropdownIndex}
                        handleApplyButtonForFilter={() => { handleApplyButtonForFilter("") }}
                        setOpenRightSideFilter={() => { setFilterOpen(false) }}
                    />
                </div>
            }

            {showAlertBox && <AlertBox type={showType} message={showMessage} />
            }
        </>
    )
}

let AllVendorDetailes = {
    "Vendor Deatils": {
        "vendor Code": "VE-001",
        "Name": "FARMER PRODUCER COMPANY LIMITED",
        "Is Active": " Yes",
        "Internal Approval Status": "Approved",
        "Bank Approval Status": "Approved",

    },
    "Entity Information": {
        "PAN": "EQIPA1111A",
        "CIN": "U78300TZ2023PTC028800",
        "LLPIN": "NA",
        "TAN": "TGRT52312M",
        "Company Constitution ": "Public Limited",
        "Buisness Category": "Company limited by shares",

    },
    "GSTIN": [
        "33AAACH7413R1Z2",
        "33AAACH7414R1Z3"
    ],
    "Account Details": {
        "Bank": "Axis",
        "Branch": "Rs puram",
        "Account Number": "30000110007457",
        "IFSC Code": "UCBA0003000",
        "UPI": "Upi@123",

    },
    "Address Details": {
        "Contact Person Name": "DURAISAMY R",
        "Phone Number": "9876543210",
        "Email": "duraisamy@gmail.com",
        "Website": "farmerproducerfpcl@farmerp.com",
        "Address": " 2 / 796; KAZURA GARDENS; FIRST MAIN ROAD; SECOND FLOOR; SAKSHI TOWERS; NEELANKARAI; Coimbatore; Tamil Nadu; 641109; India"
    }


}


export const SampleDatasArun = [
    {
        "VendorDetails": {
            "Vendor Code": "1",
            "Name": "FARMER PRODUCER COMPANY LIMITED FARMER PRODUCER COMPANY LIMITED",
            "IsActive": " Yes",
            "Internal Approval Status": "Approved",
            "Bank Approval Status": "Approved",

        },
        "EntityInformation": {
            "PAN": "EQIPA1111A",
            "CIN": "U78300TZ2023PTC028800",
            "LLPIN": "NA",
            "TAN": "TGRT52312M",
            "CompanyConstitution ": "Public Limited",
            "BuisnessCategory": "Company limited by shares",

        },
        "GSTIN": [
            "33AAACH7413R1Z2",
            "33AAACH7414R1Z3"
        ],
        "AccountDetails": {
            "Bank": "Axis",
            "Branch": "Rs puram",
            "Account Number": "30000110007457",
            "IFSC Code": "UCBA0003000",
            "UPI": "Upi@123",

        },
        "AddressDetails": {
            "Contact Person Name": "DURAISAMY R",
            "Phone Number": "9688226687",
            "Email": "duraisamy@gmail.com",
            "Website": "farmerproducerfpcl@farmerp.com",
            "Address": " 2 / 796; KAZURA GARDENS; FIRST MAIN ROAD; SECOND FLOOR; SAKSHI TOWERS; NEELANKARAI; Coimbatore; Tamil Nadu; 641109; India"
        }
    },
    {
        "VendorDeatils": {
            "Vendor Code": "3",
            "Name": "arun",
            "IsActive": " Yes",
            "Internal Approval Status": "Approved",
            "Bank Approval Status": "Approved",

        },
        "EntityInformation": {
            "PAN": "EQIPA1111A",
            "CIN": "U78300TZ2023PTC028800",
            "LLPIN": "NA",
            "TAN": "TGRT52312M",
            "CompanyConstitution ": "Public Limited",
            "BuisnessCategory": "Company limited by shares",

        },
        "GSTIN": [
            "33AAACH7413R1Z2",
            "33AAACH7414R1Z3"
        ],
        "AccountDetails": {
            "Bank": "Axis",
            "Branch": "Rs puram",
            "Account Number": "30000110007457",
            "IFSC Code": "UCBA0003000",
            "UPI": "Upi@123",
        },
        "AddressDetails": {
            "ContactPersonName": "DURAISAMY R",
            "PhoneNumber": "6369548848",
            "Email": "duraisamy@gmail.com",
            "Website": "farmerproducerfpcl@farmerp.com",
            "Address": " 2 / 796; KAZURA GARDENS; FIRST MAIN ROAD; SECOND FLOOR; SAKSHI TOWERS; NEELANKARAI; Coimbatore; Tamil Nadu; 641109; India"
        }
    },
    {
        "VendorDeatils": {
            "Vendor Code": "2",
            "Name": "sharan",
            "IsActive": " Yes",
            "Internal Approval Status": "Approved",
            "Bank Approval Status": "Approved",

        },
        "EntityInformation": {
            "PAN": "EQIPA1111A",
            "CIN": "U78300TZ2023PTC028800",
            "LLPIN": "NA",
            "TAN": "TGRT52312M",
            "CompanyConstitution ": "Public Limited",
            "BuisnessCategory": "Company limited by shares",

        },
        "GSTIN": [
            "33AAACH7413R1Z2",
            "33AAACH7414R1Z3"
        ],
        "AccountDetails": {
            "Bank": "Axis",
            "Branch": "Rs puram",
            "Account Number": "30000110007457",
            "IFSC Code": "UCBA0003000",
            "UPI": "Upi@123",
        },
        "AddressDetails": {
            "ContactPersonName": "DURAISAMY R",
            "PhoneNumber": "9876543210",
            "Email": "duraisamy@gmail.com",
            "Website": "farmerproducerfpcl@farmerp.com",
            "Address": " 2 / 796; KAZURA GARDENS; FIRST MAIN ROAD; SECOND FLOOR; SAKSHI TOWERS; NEELANKARAI; Coimbatore; Tamil Nadu; 641109; India"
        }
    },

]