import React from 'react';
import '../../../styles/radio-buttons.scss'
interface Props {
    name?: string;
    value: string;
    type: string;
    label?: string;
    margin?: string;
    checkedValue?: string;
    id?: string;
    disabled?: boolean;
    getVal: (val: any) => void;
}

export default function RadioOrCheckbox({ getVal, value,label, type, margin, name, disabled, checkedValue, id }: Props) {
    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        getVal(event.target.value)
    };
    return (
        <div style={{ margin: margin }}>
            <span className='radio-button'>
                <input disabled={disabled} type={type} name={name} value={value} onChange={handleOnChange} checked={value === checkedValue} id={name} />
                <label className='label-cursor' htmlFor={name}>{label ? label : value}</label>
            </span>
        </div>
    )
}
