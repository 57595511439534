import React, { useState, useRef, ChangeEvent, KeyboardEvent, useEffect } from 'react';
import '../../styles/login/login.scss';
import { MdOutlineDialpad } from 'react-icons/md';
import { FaKey } from 'react-icons/fa';
import { IoIosEye, IoIosEyeOff } from 'react-icons/io';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import InputComponent from '../common-component/form-elements/input-component';
import ButtonComponent from '../common-component/form-elements/button-component';
import useAxiosPrivate from '../../services/hooks/useaxios-private';
import { encryptToken } from '../../services/utils/token-utils';





interface Props {
    setIsForgetPassword: (val: boolean) => void
    setLoginOtpAttemptsRemaining: (val: any) => void
    setIsPasswordSetup: (val: boolean) => void
    currentLoginstage: string;
    handleclick: (val: any) => void;
    PasswordFunction: (val: any) => void;
    userMail: string

}

export default function Login({ currentLoginstage, handleclick, userMail, setIsPasswordSetup, setLoginOtpAttemptsRemaining, PasswordFunction, setIsForgetPassword }: Props) {

    const [toggleBtn, setToggleBtn] = useState<boolean>(true)
    const [loginVerify, setLoginVerify] = useState<boolean>(true)
    const [userverify, setUserverify] = useState<string>(userMail)
    const [password, setPassword] = useState<string>('')
    const [errorMessage, setErrorMessage] = useState<string>('')
    const [showPassword, setShowPassword] = useState<boolean>()
    const [isPinSetup, setIsPinSetup] = useState<boolean>(false)
    const [isLoginDisabled, setIsLoginDisabled] = useState<boolean>(true)

    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const length = 6;
    const [otp, setOTP] = useState<string[]>(Array(length).fill(''));
    const inputRefs = useRef<HTMLInputElement[]>([]);

    useEffect(() => {
        if (userverify !== "" && (password !== "" || otp.every((value) => value !== ''))) {
            setIsLoginDisabled(false)
            setErrorMessage("")
        } else {
            setIsLoginDisabled(true)
        }
    }, [userverify, password, otp])


    const handleChange = (e: ChangeEvent<HTMLInputElement>, index: number) => {
        const value = e.target.value;
        if (!(/^\d*$/.test(value))) return;
        if (e.target.value.length > 1) return;
        const updatedOTP = [...otp];
        updatedOTP[index] = e.target.value;
        setOTP(updatedOTP);

        if (e.target.value !== '' && index < length - 1) {
            inputRefs.current[index + 1].focus();
        }
    };

    const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>, index: number) => {
        if (e.key === 'Backspace' && index > 0 && otp[index] === '') {
            inputRefs.current[index - 1].focus();
        }
        if (e.key === 'Enter') {
            loginData()
        }

    };

    const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
        e.preventDefault();
        const pasteData = e.clipboardData.getData('text/plain');
        const numbersOnly = pasteData.replace(/\D/g, '');
        const newOTP = numbersOnly.slice(0, length).split('');

        const updatedOTP = newOTP.concat(Array(length - newOTP.length).fill(''));
        setOTP(updatedOTP);
        inputRefs.current[0].focus();
    };

    const handleFocusOut = async (e: React.ChangeEvent<HTMLInputElement>) => {
        let username = e.target?.value;
        if (username !== "") {

            try {
                const res = await axiosPrivate.post('user/first-factor', { "email": encryptToken(username) })
                if (res.status === 200) {

                    if ((res.data.userDetails.userPinStatus === true)) {
                        setIsPinSetup(true);
                    }
                    if (res.data.userDetails.firstFactor.toLowerCase() === "pin") {
                        setToggleBtn(false)
                    }
                    else {
                        setToggleBtn(true)
                    }
                }
            } catch (error) {

            }
        }

    }



    async function loginData() {
        const stringFromArray = otp.join('');

        if (!toggleBtn && stringFromArray !== "" && userverify !== "") {

            try {
                const res = await axiosPrivate.post('user/login', { "email": encryptToken(userverify), "pin": encryptToken(stringFromArray) })
                if (res.status === 200) {
                    handleclick("otpVerification")
                    if (PasswordFunction) {
                        PasswordFunction(userverify)
                    }
                    if (setLoginOtpAttemptsRemaining) {

                        setLoginOtpAttemptsRemaining(res.data.otpRemainingCount);
                    }
                }
            } catch (error: any) {

                setErrorMessage(error.response.data.message)

                if (error.response.data.showErrorPage) {
                    navigate("/accounterror", { state: { message: error.response.data.message } });
                }
            }
        }
        else if (toggleBtn && password !== "") {

            try {
                const res = await axiosPrivate.post('user/login', { "email": encryptToken(userverify), "password": encryptToken(password) })
                if (res.status === 200) {
                    handleclick("otpVerification")
                    if (PasswordFunction) {
                        PasswordFunction(userverify)
                    }
                    if (setLoginOtpAttemptsRemaining) {

                        setLoginOtpAttemptsRemaining(res.data.otpRemainingCount);
                    }
                }
            } catch (error: any) {
                setErrorMessage(error.response.data.message)
                if (error.response.data.error) {
                    handleclick("passwordExpire")
                    if (PasswordFunction) {
                        PasswordFunction(userverify)
                    }
                }
                if (error.response.data.showErrorPage) {
                    navigate("/accounterror", { state: { message: error.response.data.message } });
                }
            }
        } else {

            setErrorMessage("Invalid Password or Pin")
        }
    }


    async function forgetPasswordData() {

        if (userverify !== "") {
            try {
                const res = await axiosPrivate.post('forgot-password/otp', { "email": encryptToken(userverify) })
                if (res.status === 200) {
                    handleclick("otpVerification")

                }
                if (PasswordFunction) {
                    PasswordFunction(userverify)
                }
                if (setLoginOtpAttemptsRemaining) {
                    setLoginOtpAttemptsRemaining(res.data.otpRemainingCount);
                }
            } catch (error: any) {
                setErrorMessage(error.response.data.message)
                if (error.response.data.showErrorPage) {
                    navigate("/accounterror", { state: { message: error.response.data.message } });
                }
            }
        } else {
            setErrorMessage("Enter your username")
        }
    }
    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };
    const handleKeyDown = (e: any) => {
        if (e.key === 'Enter') {
            loginData()
        }
    };




    return (
        <div className='login-container'>
            {
                loginVerify &&
                <div className='login-verification'>
                    <div className='text'>{"Login"}</div>
                    <div className='usermail'>
                        <InputComponent
                            height={"40px"}
                            width={"100%"}
                            padding={"10px 10px"}
                            border={"none"}
                            backgroundColor={"#011D60"}
                            borderRadius={"5px"}
                            type={"text"}
                            color={"white"}
                            autoFocus={true}
                            maxLength={254}
                            placeHolder={"Enter Username"}
                            inputValue={userverify}
                            onFocusOut={handleFocusOut}
                            getUser={(val: any) => { setUserverify(val) }}

                        />
                    </div>
                    <div className='userpassword'>
                        {
                            toggleBtn ?
                                <InputComponent
                                    height={"40px"}
                                    width={"100%"}
                                    padding={"10px 10px"}
                                    blockCopyPaste={true}
                                    border={"none"}
                                    backgroundColor={"#011D60"}
                                    borderRadius={"5px"}
                                    type={showPassword ? 'text' : 'password'}
                                    handleKeyDown={handleKeyDown}
                                    maxLength={16}

                                    color={"white"}
                                    placeHolder={"Enter Password"}

                                    getUser={(val: any) => { setPassword(val) }}

                                />
                                :
                                <div className='otp-number'>
                                    {otp.map((digit, index) => (
                                        <input
                                            key={index}
                                            type="password"
                                            value={digit}
                                            maxLength={1}
                                            onChange={(e) => handleChange(e, index)}
                                            onKeyDown={(e) => handleKeyPress(e, index)}
                                            onPaste={(e) => handlePaste(e)}
                                            ref={(ref) => (inputRefs.current[index] = ref as HTMLInputElement)}
                                        />
                                    ))}
                                </div>

                        }

                        <div className={`toggle-icon ${isPinSetup || !toggleBtn ? '' : 'disabled-icon'}`} onClick={() => {
                            if (isPinSetup || !toggleBtn) {
                                setToggleBtn(!toggleBtn)
                                const updatedOtp = Array(length).fill('');
                                setOTP(updatedOtp);
                                setPassword("")

                            }
                        }}>
                            {
                                toggleBtn ?
                                    <MdOutlineDialpad className='dialpad-icon' />
                                    :
                                    <FaKey className='key-icon' />
                            }
                        </div>
                        {toggleBtn && showPassword ? (
                            <IoIosEyeOff className='show-icon' onClick={toggleShowPassword} />
                        ) : (
                            toggleBtn && <IoIosEye className='show-icon' onClick={toggleShowPassword} />
                        )}
                    </div>



                    <div className='forgot-password' onClick={() => {
                        setIsForgetPassword(true);
                        forgetPasswordData()
                        setIsPasswordSetup(false)



                    }}>
                        {"Forgot Password?"}
                    </div>
                    <div className='login-btn'>
                        <ButtonComponent
                            title={"Login"}
                            height={"40px"}
                            disabled={isLoginDisabled}
                            width={"100%"}
                            boxShadow={"0px 10px 20px #0066FF80"}
                            backgroundColor={"#FFFFFF"}
                            color={"#0055D4"}
                            handleClick={() => {
                                loginData()
                            }}
                            className={isLoginDisabled ? "button-component-hover disabled" : "button-component-hover"}
                        />
                    </div>

                    {errorMessage && <p className="error">{errorMessage}</p>}

                </div>

            }
            <div>
                <div className='version'>{"v 1.5"}</div>
            </div>

        </div >
    )
}
