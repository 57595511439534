import { useState } from 'react';
import Select from 'react-select';
import "../../../styles/common-component/dropdown-component.scss"

interface Option {
    value: string | number;
    label: string | number;
    id?: string | number;
}
interface Props {
    options: Option[],
    title?: string,
    required?: boolean,
    getData: (val: any) => void,
    defaultValue?: any,
    width?: string,
    className?: string
    isDisabled?: boolean

}

export default function DropdownComponent({ options, getData, defaultValue, title, required, width, className,isDisabled }: Props) {

    const DefaultValues = defaultValue ? defaultValue : { value: 'Select', label: 'Select' };
    const [selectedOption, setSelectedOption] = useState(DefaultValues)

    function handleChange(value: any) {
        setSelectedOption(value)
        getData(value)
    };

    return (
        <>
            {
                title &&
                <div style={{ marginBottom: "8px", width: width }}>
                    {title}{required && <span style={{ color: "red" }}>*</span>}
                </div>
            }
            <Select
                value={selectedOption}
                onChange={handleChange}
                isDisabled={isDisabled}
                options={options}
                placeholder={"Select an Option"}
                className={className !== "" && className !== undefined ? className : 'input-select'}

            />
        </>
    )
}