import React, { useState } from 'react'
import FetchChallan from './gst-form-list/fetch-challan';
interface Props {
    gstView: (val: any) => void;
    transaction: any;
}



function GstForm({ gstView, transaction }: Props) {
    const [selectedGridChallan, setSelectedGridChallan] = useState()
    return (
        <div>


            <div>
                <FetchChallan
                    gstView={gstView}
                    transaction={transaction}
                />
            </div>
        </div>
    )
}

export default GstForm