import React, { useState, useEffect, useRef } from 'react'
import '../../../src/styles/onboarding/company/company-form.scss'
// import '../../../../styles/onboarding/company/company-form.scss'
import { MdPersonSearch } from 'react-icons/md';
import { RiBankFill } from 'react-icons/ri';
import { BsFillPersonLinesFill } from 'react-icons/bs';
import { BsFileEarmarkCheckFill } from 'react-icons/bs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapLocationDot } from '@fortawesome/free-solid-svg-icons';
import { BsFillPersonFill } from 'react-icons/bs';

//svg
import { ReactComponent as Progress } from '../../../src/assets/icons/in-progerss.svg'
import { ReactComponent as Correct } from '../../../src/assets/icons/correct.svg'
import { ReactComponent as BasicDetail } from '../../../src/assets/icons/basic_details.svg'
import { ReactComponent as VendorOverviewIcon } from '../../../src/assets/icons/vendor-overview.svg'
import { ReactComponent as Stakeholder_Info } from '../../../src/assets/icons/stakeholder_Info.svg'
import { ReactComponent as Address_Icon } from '../../../src/assets/icons/vendor_address.svg'
import { ReactComponent as Vendor_Kyc } from '../../../src/assets/icons/vendor_kyc.svg'
import { ReactComponent as Point_Of_Contact } from '../../../src/assets/icons/vendor_pointofcontact.svg'
import { ReactComponent as Notworking } from '../../../src/assets/icons/not-work.svg'

import { useDispatch, useSelector } from 'react-redux';
import useAxiosPrivate from '../../services/hooks/useaxios-private';

import { resetFormModified } from '../../redux/action';
import NavigateHeader from '../../components/common-component/navigate-header';

import Loading from '../../components/common-component/modals/loading-screen';
import AlertBox from '../../components/common-component/alert-box';
import Changesmodal from '../../components/common-component/modals/changes-modal';
import useAuth from '../../services/hooks/useauth';
import Address, { ChildRef } from './vendor-login-form-list/address';
import BasicDetails from './vendor-login-form-list/basic-details';
import VendorOverview from './vendor-login-form-list/vendor-overview';
import PointOfContact from './vendor-login-form-list/point-of-contact';
import StatutoryDetails from './vendor-login-form-list/statutory-details';
import StakeholderInfo from './vendor-login-form-list/stakeholder-Info';
import KycDocuments from './vendor-login-form-list/kyc-documents';
import BankDetails from './vendor-login-form-list/bank-details';
// import BasicDetails from './vendor-login-form-list/basic-details';
// import VendorOverview from './vendor-login-form-list/vendor-overview';
// import StatutoryDetails from './vendor-login-form-list/statuatory-details';
// import StakeholderInfo from './vendor-login-form-list/stakeholder-info';
// import Address, { ChildRef } from './vendor-login-form-list/address';
// import BankDetails from './vendor-login-form-list/bank-details';
// import PointOfContact from './vendor-login-form-list/point-of-contact';
// import KycDocuments from './vendor-login-form-list/kyc-documents';


interface Props {
    onboardingViewfun: (val: any) => void;
    getEditData: (data: any) => void;
    editData: any;
    isEditMode: boolean;
    // editedShowPage: string;
}

export default function VendorLoginForm({ onboardingViewfun, getEditData, editData, isEditMode, }: Props) {
    const [showChangesModalcancel, setShowChangesModalcancel] = useState(false);
    const [showChangesModalEdit, setShowChangesModalEdit] = useState(false);
    const [isFormDirty, setIsFormDirty] = useState(false);

    const [showPage, setShowPage] = useState("BasicDetails");
    const [setNextNav, setSetNextNav] = useState("");

    const [mainApiData, setMainApiData] = useState<any>({ isActive: "Yes", chooseType: "PAN" });
    const [showType, setShowType] = useState("warning")
    const [showMessage, setShowMessage] = useState<string>("")
    const [showAlertBox, setShowAlertBox] = useState(false)
    const [panDataByFetching, setPanDataByFetching] = useState<any>()
    const [directorApiData, setDirectorApiData] = useState<any>([]);

    const [statutoryDetailsMain, setStatutoryDetailsMain] = useState<any>([]);
    const [bankRelationshipMain, setBankRelationshipMain] = useState<any>([]);
    const [adminDetailsMain, setAdminDetailsMain] = useState<any>([]);
    const [businessContactMain, setBusinessContactMain] = useState<any>([]);
    const [subscriptionMain, setSubscriptionMain] = useState<any>([]);
    const [vendorBankApprovalDetails, setVendorBankApprovalDetails] = useState<any[]>([]);
    const [companyOverviewPan, setCompanyOverviewPan] = useState<any>();
    const [chooseType, setChooseType] = useState<any>("PAN");
    const [directorInfoPan, setDirectorInfoPan] = useState<any>();
    const [isDisabled, setIsDisabled] = useState<boolean>(false)
    const [singleDirectorInfoPan, setSingleDirectorInfoPan] = useState<any>({});
    const [singleDirectorAddressInfoPan, setSingleDirectorAddressInfoPan] = useState<any>({});
    const [loadingScreen, setLoadingScreen] = useState<boolean>(false);
    const sideStatusRef = useRef<ChildRef | any>(null);
    const isFormModified = useSelector((state: any) => state.isFormModified);
    const axiosPrivate = useAxiosPrivate();
    const dispatch = useDispatch();
    const [basicDetailsFetchBtn, setBasicDetailsFetchBtn] = useState<any>(false);
    const [getEmail, setGetEmail] = useState("")


    const [currentApprovedStatus, setCurrentApprovedStatus] = useState<any>({});
    const [iconStatus, setIconStatus] = useState<any>({
        companyIcon: "selected",
        addressIcon: "notworking",
        directorIcon: "notworking",
        statutoryIcon: "notworking",
        PointOfContactIcon: "notworking",
        adminIcon: "notworking",
    });
    const [stepperStatus, setStepperStatus] = useState<any>({
        basicDetails: "notworking",
        vendorOverview: "notworking",
        statutoryDetails: "notworking",
        stakeholderInfo: "notworking",
        address: "notworking",
        bankDetails: "notworking",
        pointofContact: "notworking",
        KYCDocuments: "notworking",
    });
    const [kycUploadedStatus, setKycUploadedStatus] = useState(false)
    async function getVendorCode() {
        try {
            const response = await axiosPrivate.get(`companyadminvendor/vendorCodes`);
            if (response.status === 200) {
                // console.log(response.data.vendorCode)
                setMainApiData({
                    ...mainApiData,
                    vendorCode: response.data.vendorCode,
                })
                // setVendorCode(response.data.vendorCode)
            }
        } catch (error) {
        }
    }
    useEffect(() => {
        // if (!isEditMode) {
        //     getVendorCode()
        // }
        // if (!isEditMode) return;
        // setStatutoryDetailsMain(editData.statutoryDetails)
        // setBusinessContactMain(editData.pointOfContact)
        // setBankRelationshipMain(editData.bankDetails)
        // setDirectorApiData(editData.directorsInfo)
        // setMainApiData(editData.vendorOverView)
        // setChooseType(editData.chooseType)
        // setIconStatus({
        //     companyIcon: "completed",
        //     addressIcon: "completed",
        //     directorIcon: "completed",
        //     statutoryIcon: "completed",
        //     PointOfContactIcon: "completed",
        //     relationshipIcon: "completed",
        //     adminIcon: "completed",
        //     businessIcon: "completed",
        //     subscriptionIcon: "completed",
        // });
        // getVendorBankApprovalDetails()
        if (Object.keys(editData).length > 0) {
            setVendorDetails(editData)
            let chekcing: any = editData.kycDocuments
            for (const data of Object.values(chekcing)) {
                if (typeof data === 'object' && data !== null && 'fileLocation' in data) {
                    if ((data as { fileLocation: string }).fileLocation !== "") {
                        setKycUploadedStatus(true)
                    }
                } else {
                    console.error("Data is not of expected type", data);
                }
                // if(data?.fileLocation)
                // if (data?.fileLocation){

                // } 
            }
        }

    }, [editData]);
    async function fetchDataFunction(companyPan: any, chooseType: any) {
        setLoadingScreen(true)
        try {
            const res = await axiosPrivate.post("companyadmin/organisation/vendor/get-vendor-details", { chooseTypeValue: vendorDetails?.basicDetails?.chooseTypeValue, ChooseType: vendorDetails?.basicDetails?.chooseType.label, PBName: "vendor" }).then(async (res) => {
                if (res.status === 200) {
                    setLoadingScreen(false)
                    // const id = await saveVendorDatas('fetch', '')
                    // await createStatutoryByFetching(res.data.vendorGSTINDetails, id)
                    // await createStakeHolderInfoByFetching(res.data.vendorDirectorDetails, id)
                    let city = res.data.vendorMainDetails.city || ""
                    let state = res.data.vendorMainDetails.state || ""
                    let gstDetails = res.data.vendorGSTINDetails;

                    let gstinSingleData = {};

                    if (gstDetails?.length > 0) {

                        gstinSingleData = gstDetails.find((e: any) => e.status == "Active")

                    }

                    setPanDataByFetching({
                        statutoryByFetching: gstinSingleData,
                        stakeHolderInfoByFetching: res.data.vendorDirectorDetails,

                        // address: {

                        //     contact_city: { label: city, value: city },

                        //     contact_state: { label: state, value: state },

                        //     contact_postalCode: res.data.vendorMainDetails.pinCode,

                        //     registered_city: { label: city, value: city },

                        //     registered_state: { label: state, value: state },

                        //     registered_postalCode: "",

                        // }

                    })
                    setVendorDetails({
                        ...vendorDetails,
                        vendorOverView: {
                            ...vendorDetails.vendorOverView,
                            legalBusinessName: res.data.vendorMainDetails.vendorName
                        },
                        // address: {
                        //     contact_city: { label: city, value: city },
                        //     contact_state: { label: state, value: state },
                        //     contact_postalCode: res.data.vendorMainDetails.pinCode,
                        //     registered_city: { label: city, value: city },
                        //     registered_state: { label: state, value: state },
                        //     registered_postalCode: "",
                        // },
                        // primaryKeyOfCurrentDatas: id
                    })
                    setBasicDetailsFetchBtn(true)
                    setShowAlertBox(true);
                    setShowType("success");
                    setShowMessage(res?.data?.message);
                    clearAlert('');
                    // setIsFormDirty(false)
                    // dispatch(resetFormModified(false));
                }
            });
        } catch (error: any) {
            setLoadingScreen(false)
            setShowAlertBox(true);
            setShowType("danger");
            setShowMessage(error?.response?.data?.error);
            clearAlert('');
        }
    }
    async function createStatutoryByFetching(data: any, id: any) {
        try {
            const res = await axiosPrivate.post('razorpay/statutoryDetails/create', { statutoryDetails: data, "razorpayAccountId": id })
        } catch (error) {
            console.log("Error in createStatutoryByFetching", error)
        }

    }
    async function createStakeHolderInfoByFetching(data: any, id: any) {
        try {
            const formData = new FormData();
            formData.append(`uploadAadhar`, '')
            formData.append(`stakeholderInfoDetailsByFetching`, JSON.stringify(data))
            formData.append(`razorpayAccountId`, id)
            const res = await axiosPrivate.post('razorpay/stakeHolderInfo/create', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data', // Important: Set the content type to multipart/form-data
                },
            })
        } catch (error) {
            console.log("Error in createStakeHolderInfoByFetching", error)
        }

    }
    async function getVendorBankApprovalDetails() {
        try {
            const response = await axiosPrivate.get(`companyadminvendor/grid/${editData?.vendorOverView?.vendorOverviewId}`);
            setVendorBankApprovalDetails(response.data.results)
        } catch (error) {
        }
    }
    async function fetchDirectorDataFunction(directorPan: any) {
        setLoadingScreen(true)
        try {

            const res = await axiosPrivate.post("pan-card/details", { "pan": directorPan }).then((res) => {

                if (res.status === 200) {
                    setLoadingScreen(false)
                    // setSingleDirectorInfoPan(res.data)
                    setSingleDirectorInfoPan({
                        "panData": {
                            name: res.data.panData.name,
                            email: res.data.panData.email.toLowerCase().replace(/(\d{4})(?=\d)/g, "$1 ").trim(),
                            contactNumber: res.data.panData.phone,
                            gender: res.data.panData.gender,
                            pan: res.data.panData.pan,
                            firstName: res.data.panData.firstName,
                            lastName: res.data.panData.lastName,
                            dateOfBirth: res.data.panData.dateOfBirth,
                            aadhaarNumber: res.data.panData.maskedAadhaarNumber,
                            aadhaarLinked: res.data.panData.aadhaarLinked,
                            city: res.data.panData.city,
                            state: res.data.panData.state,
                            pincode: res.data.panData.pincode
                        },
                        "address": {
                            city: res.data.address.city,
                            state: res.data.address.state,
                            pincode: res.data.address.pincode
                        }
                    })
                    setShowAlertBox(true);
                    setShowType("success");
                    setShowMessage("PAN Data has been fetched and filled successfully.");
                    clearAlert('');
                }

            })
        } catch (error) {
            setLoadingScreen(false)
            setShowAlertBox(true);
            setShowType("danger");
            setShowMessage("Could not fetch PAN data. Enter values manually to complete this operation.");
            clearAlert('');
        }
    }
    async function getUserData() {
        await axiosPrivate.get(`/companyadmin/organisation/get-detailsby-vendorOverviewid/${editData?.vendorOverView?.vendorOverviewId}`)
            .then(response => {
                getEditData(response.data.vendorDetails);
            })
            .catch(error => {
                console.error(error);
            });
    }

    //arun
    async function sendEmailVendor() {
        try {
            const res = isEditMode ? await axiosPrivate.patch(`/companyadmin/organisation/update-vendor/${editData?.vendorOverView?.vendorOverviewId}`,) : await axiosPrivate.post("companyadmin/organisati/email-vendor",)
        } catch (error) {

        }
    }
    useEffect(() => {
        // fetchPrivillageData()
        getVendorEmailData()
    }, [])

    const fetchPrivillageData = async () => {
        try {
            const response = await axiosPrivate.get('/user/get-privilege');
            if (response.status === 200) {
                setGetEmail(response?.data.email);
                // getVendorEmailData(response?.data.email)
            }


        } catch (error) {
        }
    };

    //arun
    async function getVendorEmailData() {
        try {
            const res = await axiosPrivate.get("get/razorpay/email/vendor")
            console.log(res?.data?.responseVendorDatas)
            if (res.status == 200) {
                setVendorDetails(res?.data?.responseVendorDatas[0])
            }
        } catch (error) {
            console.log(error, "error")
        }
    }

    async function sendDataApi() {
        setMainApiData({
            ...mainApiData,
            country: "India",

        })
        const address = {

            "pinCode": mainApiData.pinCode,
            "country": mainApiData.country,
            "state": mainApiData.state,
            "stateId": mainApiData.stateId,
            "city": mainApiData.city,
            "cityId": mainApiData.cityId,
            "website": mainApiData.website,
            "address": mainApiData.address,
        }
        const finalData = {
            "messageType": showPage === "PointOfContact" ? "Approval" : "Action",
            "chooseType": mainApiData?.chooseType === (undefined) ? "PAN" : mainApiData?.chooseType,
            "chooseTypeValue": mainApiData.chooseTypeValue,
            "PBName": "vendor",
            "vendorOverView": {
                vendorName: mainApiData.vendorName,
                vendorCode: mainApiData.vendorCode,
                companyConstitution: mainApiData.companyConstitution,
                companyConstitutionId: mainApiData.companyConstitutionId,
                businessCategory: mainApiData.businessCategory,
                businessCategoryId: mainApiData.businessCategoryId,
                typesOfBillingCycle: mainApiData.typesOfBillingCycle,
                typesOfBillingCycleId: mainApiData.typesOfBillingCycleId,
                vendorTypeId: mainApiData.vendorTypeId,
                vendorType: mainApiData.vendorType,
                noOfdays: mainApiData.noOfdays,
                tan: mainApiData.tan,
                isActive: mainApiData.isActive,
                remarks: mainApiData.remarks,
                declineRemarks: mainApiData.declineRemarks,
                status: "Pending Approval",
                approvedBy: mainApiData.approvedBy,
                vendorOverviewId: mainApiData.vendorOverviewId,
                address: mainApiData.address,
                country: mainApiData.country,
                state: mainApiData.state,
                stateId: mainApiData.stateId,
                city: mainApiData.city,
                cityId: mainApiData.cityId,
                pinCode: mainApiData.pinCode,
                website: mainApiData.website
            },
            "address": { ...address },
            "directorsInfo": [...directorApiData],
            "statutoryDetails": [...statutoryDetailsMain],
            "pointOfContact": { ...businessContactMain },
            "bankDetails": { ...bankRelationshipMain }
        }
        setLoadingScreen(true)
        try {

            const res = isEditMode ? await axiosPrivate.patch(`/companyadmin/organisation/update-vendor/${editData?.vendorOverView?.vendorOverviewId}`, finalData) : await axiosPrivate.post("companyadmin/organisation/create-vendor", finalData)

            if (res.status === 200) {
                !isEditMode && setIsDisabled(true)
                setLoadingScreen(false)
                setShowAlertBox(true);
                setShowType("success");
                setShowMessage(res.data.message);
                clearAlert(res.status);
                dispatch(resetFormModified(false));
                getUserData();
                setIconStatus({
                    companyIcon: "completed",
                    addressIcon: "completed",
                    directorIcon: "completed",
                    statutoryIcon: "completed",
                    PointOfContactIcon: "completed",
                    relationshipIcon: "completed",
                    adminIcon: "completed",
                    businessIcon: "completed",
                    subscriptionIcon: "completed",
                });
            }
        }
        catch (error: any) {
            setIsDisabled(false)
            setLoadingScreen(false)
            setShowAlertBox(true);
            setShowType("danger");
            setShowMessage(error.response.data.message);
            clearAlert('');
        }
    }

    const clearAlert = (status: any) => {
        const timer = setTimeout(() => {
            setShowAlertBox(false);
            setShowMessage("");
            if (status === 200) {
                (!isEditMode || showPage === "PointOfContact") && onboardingViewfun("grid");

            }
            clearTimeout(timer);
        }, 5000);
    }

    const handleSideBar = (next: any) => {
        sideStatusRef.current!.next(next);
    }
    const handleStepClick = (step: any) => () => {
        setShowPage(step.page);
        // if (iconStatus[step.icon] === "notworking") return;
        // if (!isEditMode && iconStatus[step.icon] !== "completed") {
        //     if (isFormModified && showPage !== step.page) {
        //         if (!isEditMode) {
        //             setShowChangesModalEdit(true);
        //             setSetNextNav(step.page);
        //         }
        //     } else {
        //         // handleSideBar(step.page);
        //         setShowPage(step.page);
        //     }
        // } else {
        // if (isFormModified && showPage !== step.page) {
        //     console.log("entry 12")
        //     setShowChangesModalEdit(true);
        //     setSetNextNav(step.page);
        // } else {
        //     console.log("entry 13")
        // }
        // }
    };
    const navPageDestination = () => {
        setShowPage(setNextNav);
        setShowChangesModalEdit(false);
        dispatch(resetFormModified(false));
        setSetNextNav('');
        getUserData()

    };

    const steps = [
        { image: <BasicDetail style={{ color: "#0055d4", fontSize: "20px", margin: "4px 0px 0px 0px" }} />, heading: "Basic Details", page: "BasicDetails", icon: "basicDetails", isManditary: true },
        { image: <VendorOverviewIcon style={{ color: "#0055d4", fontSize: "25px", marginTop: "0px" }} />, heading: "Business Overview", page: "VendorOverview", icon: "vendorOverview", isManditary: true },
        { image: <BsFileEarmarkCheckFill style={{ color: "#0055d4", fontSize: "25px", margin: "0px 7px 0px 3px" }} />, heading: "Statutory Details", page: "StatutoryDetails", icon: "statutoryDetails", isManditary: true },
        { image: <Point_Of_Contact style={{ color: "#0055d4", fontSize: "25px", marginTop: "3px" }} />, heading: "Point of Contact", page: "PointOfContact", icon: "pointofContact", isManditary: true },
        { image: <Address_Icon style={{ color: "#0055d4", fontSize: "25px" }} />, heading: "Address", page: "Address", icon: "address", isManditary: true },
        // { image: <BsFillPersonLinesFill style={{ color: "#0055d4", fontSize: "25px", marginTop: "3px" }} />, heading: "Directors Info", page: "DirectorsInfo", icon: "directorIcon", isManditary: true },
        { image: <Vendor_Kyc style={{ color: "#0055d4", fontSize: "25px", marginTop: "3px" }} />, heading: "KYC Documents", page: "KYCDocuments", icon: "KYCDocuments", isManditary: true },
        { image: <Stakeholder_Info style={{ color: "#0055d4", fontSize: "25px", marginTop: "3px" }} />, heading: "Stakeholder Info", page: "StakeholderInfo", icon: "stakeholderInfo", isManditary: true },
        { image: <RiBankFill style={{ color: "#0055d4", fontSize: "27px", margin: "3px 5px 0px 5px" }} />, heading: "Bank Details", page: "BankDetails", icon: "bankDetails", isManditary: true },

    ];

    // new update vendor states
    const [vendorDetails, setVendorDetails] = useState<any>({
        basicDetails: {
            emailId: "",
            contactNumber: "",
            generateURL: "",
            merchantId: "",
            chooseType: { label: "PAN", value: "PAN" },
            chooseTypeValue: "",
            updatedBy: "Company",
            sendVendorIsChecked: "",
            companyInternalApproval: "NYS",
            approvalRemarks: '',
            razorpayAccountCreationStatus: false,
            razorpayBusinessDocStatus: false,
            razorpayStakeholderDocStatus: false,
            razorpayStakeholderStatus: false,
            razorpayBankDetailsStatus: false,
            referenceId: '',
        },
        vendorOverView: {
            companyCIN: "",
            companyLLPIN: "",
            companyPAN: "",
            legalBusinessName: "",
            vendorCode: "",
            vendorType: "",
            businessType: "",
            businessCategory: "",
            businessSubCategory: "",
            businessModel: "",
            billingCycle: "",
            noOfDays: "",
            website: "",
            description: "",
            isActive: "Yes",
            remarks: "",
        },
        statutoryDetails: {
            companyGSTIN: "",
            companyTAN: "",
            status: "",
            companyRegState: "",
            stateCode: "",
        },
        statutoryGridDetails: [],
        stakeholderInfoGridDetails: [],
        stakeholderInfo: {
            directorsPAN: "",
            name: "",
            relation: "",
            din: "",
            designation: "",
            ownership: "",
            uploadAadharFile: "",
            aadharFileLocalPath: '',
            dateOfBirth: "",
            gender: "",
            email: "",
            primaryContactNumber: "",
            secondaryContactNumber: "",
            aadhaarNumber: "",
            street: "",
            city: "",
            state: "",
            postalCode: "",
            country: "India",
            isAuthSignatory: "",
        },
        address: {
            contact_street_1: "",
            contact_street_2: "",
            contact_city: "",
            contact_state: "",
            contact_postalCode: "",
            contact_country: "India",
            sameAsContactAddress: "No",
            registered_street_1: "",
            registered_street_2: "",
            registered_city: "",
            registered_state: "",
            registered_postalCode: "",
            registered_country: "India",
        },
        bankDetails: {
            bank: "",
            accountNumber: "",
            ifscCode: "",
            accountName: "",
            branch: "",
            upi: ""
        },
        pointOfContact: {
            contactPersonName: "",
            contactNumber: "",
            email: "",
            designation: "",
        },
        stackHolderDocuments: [
            { documentType: "Personal PAN", fileName: "" },
            { documentType: "Aadhar Front", fileName: "" },
            { documentType: "Aadhar Back", fileName: "" }
        ],
        kycDocuments: [],
        primaryKeyOfCurrentDatas: null,
        overAllPercentage: 0,
        sendToRazorpay: false,
        pendingList: {},
    });

    async function saveVendorDatas(type: any, approvedStatus: any) {
        try {
            let mandatoryListsAccountCreation = ['basicDetails', 'vendorOverView', 'pointOfContact', 'address']
            let mandatoryListsDocs = ['razorpayBankDetailsStatus', 'razorpayBusinessDocStatus', 'razorpayStakeholderDocStatus', 'razorpayStakeholderStatus']
            let mandatoryField: any = {

                basicDetails: 'emailId',

                vendorOverView: 'companyPAN',

                address: 'contact_street_1',

                pointOfContact: 'contactPersonName',

            }

            let final_percentage = 0;

            let final_list = [];

            for (const data of Object.keys(vendorDetails)) {

                if (mandatoryListsAccountCreation.includes(data)) {

                    if (vendorDetails[data][mandatoryField[data]]) {

                        final_percentage += 11.11

                    } else {

                        final_list.push(data)

                    }

                }

            }

            if (vendorDetails?.statutoryGridDetails.length > 0) {

                final_percentage += 11.11

            } else {

                final_list.push('statutoryDetails')

            }

            for (const data of Object.keys(vendorDetails.basicDetails)) {

                if (mandatoryListsDocs.includes(data)) {

                    if (vendorDetails.basicDetails[data]) {

                        final_percentage += 11.11

                    } else {

                        final_list.push(data)

                    }

                }

            }

            const formData = new FormData();

            // get overAll datas

            // let count = 0;

            // let mandatoryList: any = {

            //     basicDetails: ['emailId'],

            //     vendorOverView: ['companyPAN'],

            //     statutoryDetails: [],

            //     statutoryGridDetails: [],

            //     stakeholderInfo: [],

            //     address: ['contact_street_1'],

            //     bankDetails: ['accountNumber'],

            //     pointOfContact: ['contactPersonName'],

            // }

            // let list: any = {}

            // Object.keys(vendorDetails).forEach((mainKey) => {

            //     let skipDatas = ['statutoryGridDetails', 'kycDocuments', 'primaryKeyOfCurrentDatas', 'pendingList', 'overAllPercentage', "bankDetails", "stakeholderInfo", "stakeholderInfoGridDetails"]

            //     if (!skipDatas.includes(mainKey)) {

            //         Object.keys(vendorDetails[mainKey])?.forEach((item: any) => {

            //             let dropdownValueStatus = typeof (vendorDetails[mainKey][item]) == 'object' ? vendorDetails[mainKey][item]?.label !== "Select" : true;

            //             if (vendorDetails[mainKey][item] == "" && mandatoryList[mainKey]?.includes(item)) {

            //                 if (!list[mainKey]) {

            //                     list[mainKey] = [item]

            //                 } else {

            //                     list[mainKey].push(item)

            //                 }

            //             }

            //             if (vendorDetails[mainKey][item] !== "" && mandatoryList[mainKey]?.includes(item) && dropdownValueStatus) {

            //                 count++;

            //             }

            //         });

            //     }

            // })


            let allVendorDetails = {

                ...vendorDetails,

                basicDetails: {

                    ...vendorDetails.basicDetails,

                    companyInternalApproval: approvedStatus === "Submit" ? "Pending Approval" : isEditMode ? "Pending Approval" : "NYS"

                },

                overAllPercentage: final_percentage.toFixed(0),

                pendingList: final_list,

            }

            formData.append(`vendorDetails`, JSON.stringify(allVendorDetails))

            setLoadingScreen(true)

            const response = await axiosPrivate.post(`razorpay/vendor/user-creation`, formData, {

                headers: {

                    'Content-Type': 'multipart/form-data', // Important: Set the content type to multipart/form-data

                },

            });

            if (response.status == 200) {

                if (approvedStatus == "Submit") {

                    onboardingViewfun("grid")

                }

                if (showPage === "Address") {

                    // await axiosPrivate.post('razorpay/vendor-details/mid-creation', formData)

                    // await axiosPrivate.patch('razorpay/vendor-details/mid-creation/update', formData) //update

                }

                setLoadingScreen(false)

                if (panDataByFetching) {

                    // await createStatutoryByFetching(panDataByFetching.statutoryByFetching, response?.data?.razorpayAccountId)

                }

                if (response?.data?.razorpayAccountId) {

                    let data = {

                        ...allVendorDetails,

                        primaryKeyOfCurrentDatas: response.data.razorpayAccountId,

                    }

                    setVendorDetails(data)

                }

                if (showPage === "BasicDetails") {

                    setShowPage('VendorOverview')

                }

                // if (approvedStatus === "Submit") {

                //     try {

                //         const res = await axiosPrivate.post(`razorpay/vendor-bank-approval-datas/create/${response?.data?.razorpayAccountId}`)

                //     } catch (error) {

                //         console.log("error in create vendor bank approval datas", error)

                //     }

                //     onboardingViewfun("grid")

                // }



                setShowAlertBox(true);

                setShowType("success");

                setShowMessage(`Record ${isEditMode ? 'updated' : 'saved'} successfully.`)

                clearAlert('');

            }

        } catch (error: any) {

            setLoadingScreen(false)

            setShowAlertBox(true);

            setShowType("danger");

            setShowMessage(error?.response?.data?.error);

            clearAlert('');

        }
    }
    async function saveVendoremail(type: any) {
        try {
            const formData = new FormData();
            // get overAll datas
            let count = 0;
            let mandatoryList: any = {
                basicDetails: ['emailId', 'contactNumber'],
                vendorOverView: ['companyCIN', vendorDetails?.vendorOverView?.companyCIN == "" ? 'companyLLPIN' : "", 'companyPAN', 'legalBusinessName', 'businessType', 'businessCategory', 'businessSubCategory', 'businessModel', 'website', 'description'],
                statutoryDetails: [],
                statutoryGridDetails: [],
                stakeholderInfo: [],
                address: ['contact_street_1', 'contact_street_2', 'contact_city', 'contact_state', 'contact_postalCode', 'contact_country', 'registered_street_1', 'registered_street_2', 'registered_city', 'registered_state', 'registered_postalCode', 'registered_country',],
                bankDetails: ['accountNumber', 'ifscCode', 'accountName'],
                pointOfContact: ['contactPersonName', 'contactNumber', 'email'],

            }
            let list: any = {}
            Object.keys(vendorDetails).forEach((mainKey) => {
                let skipDatas = ['statutoryGridDetails', 'kycDocuments', 'primaryKeyOfCurrentDatas', 'pendingList', 'overAllPercentage']
                if (!skipDatas.includes(mainKey)) {
                    Object.keys(vendorDetails[mainKey])?.forEach((item: any) => {
                        console.log("vendorDetails[mainKey][item]", vendorDetails[mainKey][item])
                        let dropdownValueStatus = typeof (vendorDetails[mainKey][item]) == 'object' ? vendorDetails[mainKey][item].label !== "Select" : true;
                        if (vendorDetails[mainKey][item] == "" && mandatoryList[mainKey]?.includes(item)) {
                            if (!list[mainKey]) {
                                list[mainKey] = [item]
                            } else {
                                list[mainKey].push(item)
                            }
                        }
                        // console.log( mandatoryList[mainKey] ? mandatoryList[mainKey]?.includes(item) : true)
                        if (vendorDetails[mainKey][item] !== "" && mandatoryList[mainKey]?.includes(item) && dropdownValueStatus) {
                            count++;
                        }
                    });
                }
            })

            let percentage: any = (count / 27) * 100
            let allVendorDetails = {
                ...vendorDetails,
                overAllPercentage: percentage.toFixed(0),
                pendingList: list,
            }
            // get overAll datas


            if (allVendorDetails?.kycDocuments.length > 0) {
                allVendorDetails?.kycDocuments.map((doc: any, index: any) => {
                    formData.append(`kycDoc`, doc.file[0]);
                });
            }
            formData.append(`vendorDetails`, JSON.stringify(allVendorDetails))
            formData.append(`uploadAadhar`, allVendorDetails?.stakeholderInfo?.uploadAadharFile ? allVendorDetails?.stakeholderInfo?.uploadAadharFile[0] : "")
            setLoadingScreen(true)
            const response = await axiosPrivate.post(`razorpay/vendor/user-creation/sendemail`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data', // Important: Set the content type to multipart/form-data
                },
            });

            if (response.status == 200) {
                setLoadingScreen(false)
                if (type !== "fetch" && type !== "next button") {
                    setShowAlertBox(true);
                    setShowType("success");
                    setShowMessage(`Login link has been sent to vendor's mail Id successfully.`)
                    clearAlert('');
                }
                if (showPage == "BasicDetails" && type !== "fetch") {
                    if (type == "next button") {
                        setShowPage('VendorOverview')
                    }
                    if (response.data.razorpayAccountId) {
                        setVendorDetails({
                            ...allVendorDetails,
                            primaryKeyOfCurrentDatas: response.data.razorpayAccountId
                        })
                    }
                }
                return response.data.razorpayAccountId
            }
        } catch (error: any) {
            setLoadingScreen(false)
            setShowAlertBox(true);
            setShowType("danger");
            setShowMessage(error.response.data.error);
            clearAlert('');
            console.log(error)
        }
    }
    async function duplicateCheckBasicDeatilsVendor() {
        try {
            const response = await axiosPrivate.post(`razorpay/vendor/user-creation/duplicateChecking`, { basicDetails: vendorDetails.basicDetails });
            if (response.status == 200) {
                // setLoadingScreen(false)
                // setShowAlertBox(true);
                // setShowType("danger");
                // clearAlert('');
            }
        }
        catch (error: any) {
            setLoadingScreen(false)
            setShowAlertBox(true);
            setShowType("danger");
            setShowMessage(error.response.data.error);
            clearAlert('');
        }
    }

    return (
        <div className='company-form' style={{ overflow: "hidden" }}>
            <div className='navigation-header' style={{ height: "10vh", }}>
                <div className='left-header'>
                    <NavigateHeader firstValue={"Fingertip"}
                        style={{ cursor: "pointer" }}

                        secondValue={"Vendor"} navigatePage={
                            isFormModified
                                ? () => {
                                    setShowChangesModalcancel(true);
                                } : () => {
                                    // dispatch(resetFormModified(false));
                                    onboardingViewfun("grid");
                                }
                        }
                    />
                </div>

            </div>

            <div className='form-contents' style={{ height: "63vh", width: '100%' }}>

                <div style={{ width: '300px', height: '63vh' }}>
                    <div className='side-navigation-main-company'>

                        <div className='position-stepper-list'>
                            {steps.map((step, index) => (
                                // <div className='stepper-main' key={index} style={isEditMode ? {} : { pointerEvents: "none" }}>
                                <div className='stepper-main' key={index} style={{}}>
                                    {/* <div className='flex-image-text' onClick={isEditMode ? handleStepClick(step) : undefined}> */}
                                    <div className='flex-image-text' style={{ cursor: "default" }} onClick={() => {
                                        setShowPage(step.page)
                                        if (isEditMode) {

                                        }

                                        // handleStepClick(step)

                                        // if (vendorDetails?.basicDetails.companyInternalApproval == "Approved") {

                                        //     setShowPage(step.page)

                                        // }
                                    }
                                        // handleStepClick(step)

                                    } >
                                        <div>{step.image}</div>
                                        <div className='heading-icon'>
                                            <div className={`stepper-heading ${showPage === step.page ? "active" : ""}`}
                                                style={
                                                    (
                                                        ['Stakeholder Info', 'KYC Documents', 'Bank Details'].includes(step.heading)
                                                    )
                                                        ? vendorDetails?.basicDetails?.merchantId !== "" ? {} : { pointerEvents: "none", opacity: "0.5" } : {}}
                                            >
                                                {step.heading}
                                                {step.isManditary &&
                                                    <span style={{ color: "red" }}>*</span>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className='stepper-icon'>
                                        {stepperStatus[step.icon] === "notworking" && <Notworking className='icon-tick' />}
                                        {stepperStatus[step.icon] === "working" && <Progress className='icon-tick' />}
                                        {stepperStatus[step.icon] === "completed" && <Correct className='icon-tick' />}
                                    </div>
                                    {index !== steps.length - 1 &&
                                        <div className='stepper-line'></div>
                                    }
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                {
                    showPage === "BasicDetails" &&
                    <BasicDetails
                        duplicateCheckBasicDeatilsVendor={duplicateCheckBasicDeatilsVendor}
                        saveVendoremail={saveVendoremail}
                        setIsFormDirty={setIsFormDirty}
                        ref={sideStatusRef}
                        mainApiData={mainApiData}
                        setMainApiData={setMainApiData}
                        setShowPage={setShowPage}
                        showPage={showPage}
                        iconStatus={iconStatus}
                        setIconStatus={setIconStatus}
                        fetchDatas={(value) => {
                            fetchDataFunction(companyOverviewPan, chooseType)
                        }}
                        sendDataApi={sendDataApi}
                        onboardingViewfun={onboardingViewfun}
                        companyOverviewPan={companyOverviewPan}
                        setCompanyOverviewPan={setCompanyOverviewPan}
                        setChooseType={setChooseType}
                        chooseType={chooseType}
                        isEditMode={isEditMode}
                        editData={editData}

                        //vendorDetails
                        vendorDetails={vendorDetails}
                        setVendorDetails={setVendorDetails}
                        stepperStatus={stepperStatus}
                        setStepperStatus={setStepperStatus}
                        saveVendorDatas={(val: any) => {
                            saveVendorDatas(val, '')
                        }
                        }
                        basicDetailsFetchBtn={basicDetailsFetchBtn}
                        setBasicDetailsFetchBtn={setBasicDetailsFetchBtn}
                    />
                }
                {
                    showPage === "VendorOverview" &&
                    <VendorOverview
                        setIsFormDirty={setIsFormDirty}
                        ref={sideStatusRef}
                        mainApiData={mainApiData}
                        setMainApiData={setMainApiData}
                        setShowPage={setShowPage}
                        showPage={showPage}
                        iconStatus={iconStatus}
                        setIconStatus={setIconStatus}
                        fetchDatas={(value) => {
                            fetchDataFunction(companyOverviewPan, chooseType)
                        }}
                        sendDataApi={sendDataApi}
                        onboardingViewfun={onboardingViewfun}
                        companyOverviewPan={companyOverviewPan}
                        setCompanyOverviewPan={setCompanyOverviewPan}
                        setChooseType={setChooseType}
                        chooseType={chooseType}
                        isEditMode={isEditMode}
                        editData={editData}

                        //vendorDetails
                        vendorDetails={vendorDetails}
                        setVendorDetails={setVendorDetails}
                        stepperStatus={stepperStatus}
                        setStepperStatus={setStepperStatus}
                        saveVendorDatas={() => {
                            saveVendorDatas('', '')
                        }
                        }
                        kycUploadedStatus={kycUploadedStatus}
                    />
                }


                {
                    showPage === "PointOfContact" &&
                    <PointOfContact
                        ref={sideStatusRef}
                        setMainApiData={setMainApiData}
                        isDisabled={isDisabled}
                        mainApiData={mainApiData}
                        editData={editData}
                        setShowPage={setShowPage}
                        iconStatus={iconStatus}
                        setIconStatus={setIconStatus}
                        onboardingViewfun={onboardingViewfun}
                        sendDataApi={sendDataApi}
                        setBusinessContactMain={(val: any) => {

                            setBusinessContactMain(val)
                        }
                        }
                        businessContactMain={businessContactMain}
                        isEditMode={isEditMode}

                        //vendorDetails
                        vendorDetails={vendorDetails}
                        setVendorDetails={setVendorDetails}
                        stepperStatus={stepperStatus}
                        setStepperStatus={setStepperStatus}
                        saveVendorDatas={() => {
                            saveVendorDatas('', '')
                        }
                        }
                    />
                }

                {
                    showPage === "StatutoryDetails" &&
                    <StatutoryDetails
                        ref={sideStatusRef}
                        sendDataApi={sendDataApi}
                        mainApiData={mainApiData}
                        editData={editData}
                        setShowPage={setShowPage}
                        iconStatus={iconStatus}
                        setIconStatus={setIconStatus}
                        onboardingViewfun={onboardingViewfun}
                        statutoryDetailsMain={statutoryDetailsMain}
                        setStatutoryDetailsMain={setStatutoryDetailsMain}
                        isEditMode={isEditMode}
                        //vendorDetails
                        vendorDetails={vendorDetails}
                        setVendorDetails={setVendorDetails}
                        stepperStatus={stepperStatus}
                        setStepperStatus={setStepperStatus}
                        saveVendorDatas={() => {
                            saveVendorDatas('', '')
                        }
                        }
                    />
                }
                {
                    showPage === "StakeholderInfo" &&
                    <StakeholderInfo
                        ref={sideStatusRef}
                        vendorBankApprovalDetails={vendorBankApprovalDetails}
                        mainApiData={mainApiData}
                        setShowPage={setShowPage}
                        iconStatus={iconStatus}
                        setIconStatus={setIconStatus}
                        onboardingViewfun={onboardingViewfun}
                        directorApiData={directorApiData}
                        editData={editData}
                        fetchDirectorPan={(value) => {
                            fetchDirectorDataFunction(value)
                        }}
                        setDirectorApiData={(val: any) => {
                            setDirectorApiData(val)
                        }}
                        sendDataApi={sendDataApi}
                        setDirectorInfoPan={(val: string) => {
                            setDirectorInfoPan(val)
                        }}
                        directorInfoPan={directorInfoPan}
                        singleDirectorInfoPan={singleDirectorInfoPan}
                        setSingleDirectorInfoPan={setSingleDirectorInfoPan}
                        singleDirectorAddressInfoPan={singleDirectorAddressInfoPan}
                        isEditMode={isEditMode}

                        //vendorDetails
                        vendorDetails={vendorDetails}
                        setVendorDetails={setVendorDetails}
                        stepperStatus={stepperStatus}
                        setStepperStatus={setStepperStatus}
                        saveVendorDatas={() => {
                            saveVendorDatas('', '')
                        }
                        }

                    />
                }
                {/* {
                    showPage === "DirectorsInfo" &&
                    <DirectorsInfo
                        ref={sideStatusRef}
                        vendorBankApprovalDetails={vendorBankApprovalDetails}
                        mainApiData={mainApiData}
                        setShowPage={setShowPage}
                        iconStatus={iconStatus}
                        setIconStatus={setIconStatus}
                        onboardingViewfun={onboardingViewfun}
                        directorApiData={directorApiData}
                        editData={editData}
                        fetchDirectorPan={(value) => {
                            fetchDirectorDataFunction(value)
                        }}
                        setDirectorApiData={(val: any) => {
                            setDirectorApiData(val)
                        }}
                        sendDataApi={sendDataApi}
                        setDirectorInfoPan={(val: string) => {
                            setDirectorInfoPan(val)
                        }}
                        directorInfoPan={directorInfoPan}
                        singleDirectorInfoPan={singleDirectorInfoPan}
                        setSingleDirectorInfoPan={setSingleDirectorInfoPan}
                        singleDirectorAddressInfoPan={singleDirectorAddressInfoPan}
                        isEditMode={isEditMode}
                    />
                } */}
                {
                    showPage === "Address" &&
                    <Address
                        ref={sideStatusRef}
                        mainApiData={mainApiData}
                        setMainApiData={setMainApiData}
                        onboardingViewfun={onboardingViewfun}
                        sendDataApi={sendDataApi}
                        setShowPage={setShowPage}
                        iconStatus={iconStatus}
                        editData={editData}
                        setIconStatus={setIconStatus}
                        isEditMode={isEditMode}

                        //vendorDetails
                        vendorDetails={vendorDetails}
                        setVendorDetails={setVendorDetails}
                        stepperStatus={stepperStatus}
                        setStepperStatus={setStepperStatus}
                        saveVendorDatas={() => {
                            saveVendorDatas('', 'Submit')
                        }
                        }
                    />
                }
                {
                    showPage === "BankDetails" &&
                    <BankDetails
                        ref={sideStatusRef}
                        vendorBankApprovalDetails={vendorBankApprovalDetails}
                        mainApiData={mainApiData}
                        sendDataApi={sendDataApi}
                        editData={editData}
                        bankRelationshipMain={bankRelationshipMain}
                        setBankRelationshipMain={setBankRelationshipMain}
                        setShowPage={setShowPage}
                        iconStatus={iconStatus}
                        setIconStatus={setIconStatus}
                        onboardingViewfun={onboardingViewfun}
                        isEditMode={isEditMode}
                        //vendorDetails
                        vendorDetails={vendorDetails}
                        setVendorDetails={setVendorDetails}
                        stepperStatus={stepperStatus}
                        setStepperStatus={setStepperStatus}
                        saveVendorDatas={() => {
                            saveVendorDatas('', '')
                        }
                        }
                    />
                }



                {
                    showPage === "KYCDocuments" &&
                    <KycDocuments
                        setShowPage={setShowPage}
                        //vendorDetails
                        vendorDetails={vendorDetails}
                        setVendorDetails={setVendorDetails}
                        stepperStatus={stepperStatus}
                        setStepperStatus={setStepperStatus}
                        saveVendorDatas={(val: any) => {
                            saveVendorDatas('', val)
                        }}
                        isEditMode={isEditMode}
                        onboardingViewfun={onboardingViewfun}
                    />
                }


                {/* {
          showPage === "AdminDetails" &&
          <AdminDetails
            ref={sideStatusRef}
            sendDataApi={sendDataApi}
            editData={editData}
            setShowPage={setShowPage}
            iconStatus={iconStatus}
            setIconStatus={setIconStatus}
            onboardingViewfun={onboardingViewfun}
            setAdminDetailsMain={setAdminDetailsMain}
            adminDetailsMain={adminDetailsMain}
            isEditMode={isEditMode}
          />
        } */}

                {/* {
          showPage === "SubscriptionValidity" &&
          <SubscriptionValidity
            editData={editData}
            setShowPage={setShowPage}
            getEditData={getEditData}
            setSubscriptionMain={setSubscriptionMain}
            subscriptionMain={subscriptionMain}
            onboardingViewfun={onboardingViewfun}
            sendDataApi={sendDataApi}
            isEditMode={isEditMode}
            iconStatus={iconStatus}
            setIconStatus={setIconStatus}
          />
        } */}
                {/* {
                    showPage === "Privilege" &&
                    <Privilege
                    />
                } */}
            </div>
            {
                loadingScreen &&
                <Loading />
            }
            {
                showAlertBox &&
                <div className='alert-warp'>
                    <AlertBox type={showType} message={showMessage} />
                </div>

            }
            {
                showChangesModalcancel &&
                <Changesmodal
                    closeModal={() => setShowChangesModalcancel(false)}
                    leavePage={() => {
                        setShowChangesModalcancel(false);
                        dispatch(resetFormModified(false));
                        setIsFormDirty(false)
                        onboardingViewfun("grid")
                        // getEditMode(false)
                    }}
                    handleClose={() => setShowChangesModalcancel(false)} />
            }
            {
                showChangesModalEdit &&
                <Changesmodal
                    closeModal={() => setShowChangesModalEdit(false)}
                    leavePage={navPageDestination}
                    handleClose={() => setShowChangesModalEdit(false)} />
            }
        </div >
    )
}