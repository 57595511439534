import React, { useEffect, useState } from 'react'
import { IoMdArrowDropdown, IoMdArrowDropright } from 'react-icons/io';
import { IoIosArrowForward, IoIosArrowDown } from 'react-icons/io';
import { useLocation } from 'react-router-dom';
import { Navigate, useNavigate } from 'react-router-dom';
import { ReactComponent as Lessthan } from '../../assets/icons/lessarrow.svg'
import { ReactComponent as GetFamiliar } from '../../assets/icons/getfamiliar.svg'
import { ReactComponent as Support } from '../../assets/icons/contactus.svg'
import { ReactComponent as Faq } from '../../assets/icons/faq.svg'
import { axiosPrivate } from '../../middleware/axois-api'
import Loading from '../../components/common-component/modals/loading-screen'

interface Props {
    setDocumentationNav: (val: string) => void;
}

function DocumentationVideos({ setDocumentationNav }: Props) {

    const location = useLocation();

    const [mainSection, setMainSection] = useState(null);
    const [openSection, setOpenSection] = useState(null);
    const [dropdownSelection, setDropdownSelection] = useState(null);
    const [apiVideoNamePost, setApiVideoNamePost] = useState<string>("");
    const [selectedName, setSelectedName] = useState(null)
    const [videoUrl, setVideoUrl] = useState("")
    const [vttUrl, setVttUrl] = useState("")
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const mainToggleSection = (mainsectionName: any) => {
        if (mainSection === mainsectionName) {
            setMainSection(null);
        } else {
            setMainSection(mainsectionName);
        }
    };

    const toggleSection = (sectionName: any) => {
        if (openSection === sectionName) {
            setOpenSection(null);
        } else {
            setOpenSection(sectionName);
        }
    };

    const dropdownSection = (dropdownsectionName: any) => {
        setTimeout(() => {
            const clickedText: any = document.querySelector(".nav-sublist.clicked");
            const selectedText = clickedText?.innerText;
            setSelectedName(selectedText)
        }, 100);
        if (dropdownSelection === dropdownsectionName) {
            return;
        } else {
            setDropdownSelection(dropdownsectionName);
        }

    };

    useEffect(() => {
        const trimmedTitle = location.state?.title.replace(/\s+/g, '');
        const title = location.state?.title;
        const apiVideoName = trimmedTitle?.replace('/', '');
        let aa: any = "UserGuide";
        let section: any = location.state?.main;
        setMainSection(aa);
        setOpenSection(section);
        setDropdownSelection(trimmedTitle);
        setSelectedName(title);
        setApiVideoNamePost(apiVideoName);

    }, [location])

    useEffect(() => {
        fetchVideos(dropdownSelection)
    }, [dropdownSelection])

    const navigate = useNavigate();
    function dashboard() {
        navigate("/dashboard")
        setDocumentationNav("")
    }

    function documentation() {
        navigate("/documentation")
    }
    console.log(selectedName, "selectedName")
    const fetchVideos = async (dropdownSelection: any) => {
        const apiVideoName = dropdownSelection?.replace('/', '');
        if (apiVideoName && apiVideoName != "undefined") {
            setIsLoading(true)
            try {
                const response = await axiosPrivate.post("/documentation/video/get", { "fileName": `documentation_videos/${openSection}/${apiVideoName}_v1.mp4` }, {
                    responseType: "arraybuffer"
                })
                if (response.status === 200) {
                    setIsLoading(false)
                    const contentType = response.headers['content-type'];
                    const blob = new Blob([response.data], { type: contentType });
                    const objectURL = URL.createObjectURL(blob);
                    setVideoUrl(objectURL)
                }

            } catch (error) {
                setIsLoading(false)
                setVideoUrl("")
                console.log(error)
            }
            try {
                const response = await axiosPrivate.post("/documentation/video/get", { "fileName": `documentation_vtt/${openSection}/${apiVideoName}_v1.vtt` }, {
                    responseType: "arraybuffer"
                })
                if (response.status === 200) {
                    setIsLoading(false)
                    const contentType = response.headers['content-type'];
                    const blob = new Blob([response.data], { type: contentType });
                    const objectURL = URL.createObjectURL(blob);
                    setVttUrl(objectURL)
                }

            } catch (error) {
                setIsLoading(false)
                setVttUrl("")
                console.log(error)
            }
        }
    };

    return (
        <div>
            <div>
                <div className='navigator-icon-wrapper'>
                    <div className='navigator-main'>
                        <div onClick={dashboard} className='navigator-main-title'>FingerTip</div><Lessthan style={{ marginTop: "2px" }} />
                        <div className='navigator-title' onClick={documentation}><a href='#0' style={{ textDecoration: "none", color: "white" }}>Documentation</a></div>
                        <Lessthan style={{ marginTop: "2px" }} />
                        {
                            selectedName !== "" ?
                                <div className='navigator-title'>{selectedName}</div>
                                :
                                ""
                        }


                    </div>
                    <div className='right-icons-doc'>
                        <div onClick={() => navigate('/documentation/#18')} ><GetFamiliar />{"Get Familiar"}</div>
                        <div onClick={() => navigate('/documentation/#20')}><Faq />{"FAQ"}</div>
                        <div onClick={() => navigate('/documentation/#21')}><Support />{"Support"}</div>
                    </div>
                </div>
            </div>
            <div className='video-wrapper'>
                <div className='video-container'>
                    <div className='video-stepper-main'>
                        <div className='nav-list'>
                            {mainSection === 'UserGuide' ? (
                                <IoMdArrowDropdown className={mainSection === 'UserGuide' ? 'arrow-clicked' : 'arrow-doc'} />
                            ) : (
                                <IoMdArrowDropright className={mainSection === 'UserGuide' ? 'arrow-clicked' : 'arrow-doc'} />
                            )}
                            <p
                                className={mainSection === 'UserGuide' ? 'clicked' : ''}
                                onClick={() => mainToggleSection('UserGuide')}
                            >
                                {"User Guide"}
                            </p>
                        </div>
                        {mainSection === 'UserGuide'
                            &&
                            <div className='sub-nav' onClick={() => toggleSection('Signup')}>
                                {openSection === 'Signup' ? (
                                    <IoIosArrowDown className={openSection === 'Signup' ? 'arrow-clicked-differ' : 'arrow-doc-differ'} />
                                ) : (
                                    <IoIosArrowForward className={openSection === 'Signup' ? 'arrow-clicked-differ' : 'arrow-doc-differ'} />
                                )}
                                <p
                                    className={openSection === 'Signup' ? 'clicked' : ''}
                                >
                                    {"Signup"}
                                </p>
                            </div>
                        }
                        {mainSection === "UserGuide" && openSection === 'Signup' && (
                            <div>
                                <p className={dropdownSelection === 'Setup' ? 'nav-sublist clicked' : 'nav-sublist'} onClick={() => dropdownSection('Setup')}>Setup</p>
                                <p className={dropdownSelection === 'Login' ? 'nav-sublist clicked' : 'nav-sublist'} onClick={() => dropdownSection('Login')}>Login</p>
                                <p className={dropdownSelection === 'ForgotPassword' ? 'nav-sublist clicked' : 'nav-sublist'} onClick={() => dropdownSection('ForgotPassword')}>Forgot Password</p>
                                <p className={dropdownSelection === 'SetPIN' ? 'nav-sublist clicked' : 'nav-sublist'} onClick={() => dropdownSection('SetPIN')}>Set PIN</p>
                                <p className={dropdownSelection === 'TwoFactorAuthentication' ? 'nav-sublist clicked' : 'nav-sublist'} onClick={() => dropdownSection('TwoFactorAuthentication')}>Two Factor Authentication</p>
                                <p className={dropdownSelection === 'ViewcurrentPassword/PIN' ? 'nav-sublist clicked' : 'nav-sublist'} onClick={() => dropdownSection('ViewcurrentPassword/PIN')}>View current Password/PIN</p>
                                <p className={dropdownSelection === 'ResetcurrentPassword/PIN' ? 'nav-sublist clicked' : 'nav-sublist'} onClick={() => dropdownSection('ResetcurrentPassword/PIN')}>Reset current Password/PIN</p>
                                <p className={dropdownSelection === 'SetProfilePhoto' ? 'nav-sublist clicked' : 'nav-sublist'} onClick={() => dropdownSection('SetProfilePhoto')}>Set Profile Photo</p>
                            </div>
                        )}
                        {mainSection === 'UserGuide'
                            &&
                            <div className='sub-nav' onClick={() => toggleSection('Organisation')}>
                                {openSection === 'Organisation' ? (
                                    <IoIosArrowDown className={openSection === 'Organisation' ? 'arrow-clicked-differ' : 'arrow-doc-differ'} />
                                ) : (
                                    <IoIosArrowForward className={openSection === 'Organisation' ? 'arrow-clicked-differ' : 'arrow-doc-differ'} />
                                )}
                                <p
                                    className={openSection === 'Organisation' ? 'clicked' : ''}>
                                    {"Organisation"}
                                </p>
                            </div>
                        }
                        {mainSection === "UserGuide" && openSection === 'Organisation' && (
                            <div>
                                <p className={dropdownSelection === 'AddOrganisation' ? 'nav-sublist clicked' : 'nav-sublist'} onClick={() => dropdownSection('AddOrganisation')}>Add Organisation</p>
                                <p className={dropdownSelection === 'EditOrganisation' ? 'nav-sublist clicked' : 'nav-sublist'} onClick={() => dropdownSection('EditOrganisation')}>Edit Organisation</p>
                                <p className={dropdownSelection === 'DeleteOrganisation' ? 'nav-sublist clicked' : 'nav-sublist'} onClick={() => dropdownSection('DeleteOrganisation')}>Delete Organisation</p>
                            </div>
                        )}
                        {mainSection === 'UserGuide'
                            &&
                            <div className='sub-nav' onClick={() => toggleSection('Company')}>
                                {openSection === 'Company' ? (
                                    <IoIosArrowDown className={openSection === 'Company' ? 'arrow-clicked-differ' : 'arrow-doc-differ'} />
                                ) : (
                                    <IoIosArrowForward className={openSection === 'Company' ? 'arrow-clicked-differ' : 'arrow-doc-differ'} />
                                )}
                                <p
                                    className={openSection === 'Company' ? 'clicked' : ''}>
                                    {"Company"}
                                </p>
                            </div>
                        }
                        {mainSection === "UserGuide" && openSection === 'Company' && (
                            <div>
                                <p className={dropdownSelection === 'AddCompany' ? 'nav-sublist clicked' : 'nav-sublist'}
                                    onClick={() => dropdownSection('AddCompany')}>Add Company</p>
                                <p className={dropdownSelection === 'EditCompany' ? 'nav-sublist clicked' : 'nav-sublist'}
                                    onClick={() => dropdownSection('EditCompany')}>Edit Company</p>
                                <p className={dropdownSelection === 'DeleteCompany' ? 'nav-sublist clicked' : 'nav-sublist'}
                                    onClick={() => dropdownSection('DeleteCompany')}>Delete Company</p>
                                <p className={dropdownSelection === 'AddDepartment' ? 'nav-sublist clicked' : 'nav-sublist'}
                                    onClick={() => dropdownSection('AddDepartment')}>Add Department</p>
                                <p className={dropdownSelection === 'AddAccountManager' ? 'nav-sublist clicked' : 'nav-sublist'}
                                    onClick={() => dropdownSection('AddAccountManager')}>Add Account Manager</p>
                                <p className={dropdownSelection === 'SetSingleUserApprovalProcess' ? 'nav-sublist clicked' : 'nav-sublist'}
                                    onClick={() => dropdownSection('SetSingleUserApprovalProcess')}>Set Single User Approval Process</p>
                                <p className={dropdownSelection === 'SetGroupUserApprovalProcess' ? 'nav-sublist clicked' : 'nav-sublist'}
                                    onClick={() => dropdownSection('SetGroupUserApprovalProcess')}>Set Group User Approval Process</p>
                                <p className={dropdownSelection === 'SetSubscriptionValidity' ? 'nav-sublist clicked' : 'nav-sublist'}
                                    onClick={() => dropdownSection('SetSubscriptionValidity')}>Set Subscription Validity</p>
                            </div>
                        )}

                        {mainSection === 'UserGuide'
                            &&
                            <div className='sub-nav' onClick={() => toggleSection('KYC')}>
                                {openSection === 'KYC' ? (
                                    <IoIosArrowDown className={openSection === 'KYC' ? 'arrow-clicked-differ' : 'arrow-doc-differ'} />
                                ) : (
                                    <IoIosArrowForward className={openSection === 'KYC' ? 'arrow-clicked-differ' : 'arrow-doc-differ'} />
                                )}
                                <p
                                    className={openSection === 'KYC' ? 'clicked' : ''}>
                                    {"KYC"}
                                </p>
                            </div>
                        }
                        {mainSection === "UserGuide" && openSection === 'KYC' && (
                            <div>
                                <p className={dropdownSelection === 'UploadKYC' ? 'nav-sublist clicked' : 'nav-sublist'}
                                    onClick={() => dropdownSection('UploadKYC')}>Upload KYC document</p>
                                <p className={dropdownSelection === 'ResubmitthedeclinedKYCdocument' ? 'nav-sublist clicked' : 'nav-sublist'}
                                    onClick={() => dropdownSection('ResubmitthedeclinedKYCdocument')}>Resubmit the declined KYC document</p>
                            </div>
                        )}
                        {mainSection === 'UserGuide'
                            &&
                            <div className='sub-nav' onClick={() => toggleSection('CustomerOnboarding')}>
                                {openSection === 'CustomerOnboarding' ? (
                                    <IoIosArrowDown className={openSection === 'CustomerOnboarding' ? 'arrow-clicked-differ' : 'arrow-doc-differ'} />
                                ) : (
                                    <IoIosArrowForward className={openSection === 'CustomerOnboarding' ? 'arrow-clicked-differ' : 'arrow-doc-differ'} />
                                )}
                                <p
                                    className={openSection === 'CustomerOnboarding' ? 'clicked' : ''}>
                                    {"Customer Onboarding"}
                                </p>
                            </div>
                        }
                        {mainSection === "UserGuide" && openSection === 'CustomerOnboarding' && (
                            <div>
                                <p className={dropdownSelection === 'Approveindividualdocument' ? 'nav-sublist clicked' : 'nav-sublist'}
                                    onClick={() => dropdownSection('Approveindividualdocument')}>Approve individual document</p>
                                <p className={dropdownSelection === 'Declineindividualdocument' ? 'nav-sublist clicked' : 'nav-sublist'}
                                    onClick={() => dropdownSection('Declineindividualdocument')}>Decline individual document</p>
                                <p className={dropdownSelection === 'ApproveKYC' ? 'nav-sublist clicked' : 'nav-sublist'}
                                    onClick={() => dropdownSection('ApproveKYC')}>Approve KYC</p>
                                <p className={dropdownSelection === 'DeclineKYC' ? 'nav-sublist clicked' : 'nav-sublist'}
                                    onClick={() => dropdownSection('DeclineKYC')}>Decline KYC</p>
                                <p className={dropdownSelection === 'GenerateLicenseKey' ? 'nav-sublist clicked' : 'nav-sublist'}
                                    onClick={() => dropdownSection('GenerateLicenseKey')}>Generate LicenseKey</p>
                            </div>
                        )}
                        {mainSection === 'UserGuide'
                            &&
                            <div className='sub-nav' onClick={() => toggleSection('UserManagement')}>
                                {openSection === 'UserManagement' ? (
                                    <IoIosArrowDown className={openSection === 'UserManagement' ? 'arrow-clicked-differ' : 'arrow-doc-differ'} />
                                ) : (
                                    <IoIosArrowForward className={openSection === 'UserManagement' ? 'arrow-clicked-differ' : 'arrow-doc-differ'} />
                                )}
                                <p
                                    className={openSection === 'UserManagement' ? 'clicked' : ''}>
                                    {"User Management"}
                                </p>
                            </div>
                        }
                        {mainSection === "UserGuide" && openSection === 'UserManagement' && (
                            <div>
                                <p className={dropdownSelection === 'AddFingertipUser' ? 'nav-sublist clicked' : 'nav-sublist'}
                                    onClick={() => dropdownSection('AddFingertipUser')}>Add Fingertip User</p>
                                <p className={dropdownSelection === 'AddOrganisationUser' ? 'nav-sublist clicked' : 'nav-sublist'}
                                    onClick={() => dropdownSection('AddOrganisationUser')}>Add Organisation User</p>
                                <p className={dropdownSelection === 'AddCompanyUser' ? 'nav-sublist clicked' : 'nav-sublist'}
                                    onClick={() => dropdownSection('AddCompanyUser')}>Add Company User</p>
                            </div>
                        )}
                        {mainSection === 'UserGuide'
                            &&
                            <div className='sub-nav' onClick={() => toggleSection('Bank')}>
                                {openSection === 'Bank' ? (
                                    <IoIosArrowDown className={openSection === 'Bank' ? 'arrow-clicked-differ' : 'arrow-doc-differ'} />
                                ) : (
                                    <IoIosArrowForward className={openSection === 'Bank' ? 'arrow-clicked-differ' : 'arrow-doc-differ'} />
                                )}
                                <p
                                    className={openSection === 'Bank' ? 'clicked' : ''}>
                                    {"Bank"}
                                </p>
                            </div>
                        }
                        {mainSection === "UserGuide" && openSection === 'Bank' && (
                            <div>
                                <p className={dropdownSelection === 'AddBank' ? 'nav-sublist clicked' : 'nav-sublist'}
                                    onClick={() => dropdownSection('AddBank')}>Add Bank</p>
                                <p className={dropdownSelection === 'DeclineBank' ? 'nav-sublist clicked' : 'nav-sublist'}
                                    onClick={() => dropdownSection('DeclineBank')}>Decline Bank</p>
                                <p className={dropdownSelection === 'ApproveBank' ? 'nav-sublist clicked' : 'nav-sublist'}
                                    onClick={() => dropdownSection('ApproveBank')}>Approve Bank</p>
                            </div>
                        )}
                        {mainSection === 'UserGuide'
                            &&
                            <div className='sub-nav' onClick={() => toggleSection('Card')}>
                                {openSection === 'Card' ? (
                                    <IoIosArrowDown className={openSection === 'Card' ? 'arrow-clicked-differ' : 'arrow-doc-differ'} />
                                ) : (
                                    <IoIosArrowForward className={openSection === 'Card' ? 'arrow-clicked-differ' : 'arrow-doc-differ'} />
                                )}
                                <p
                                    className={openSection === 'Card' ? 'clicked' : ''}>
                                    {"Card"}
                                </p>
                            </div>
                        }
                        {mainSection === "UserGuide" && openSection === 'Card' && (
                            <div>
                                <p className={dropdownSelection === 'AddCard' ? 'nav-sublist clicked' : 'nav-sublist'}
                                    onClick={() => dropdownSection('AddCard')}>Add Card</p>
                                <p className={dropdownSelection === 'DeclineCard' ? 'nav-sublist clicked' : 'nav-sublist'}
                                    onClick={() => dropdownSection('DeclineCard')}>Decline Card</p>
                                <p className={dropdownSelection === 'ApproveCard' ? 'nav-sublist clicked' : 'nav-sublist'}
                                    onClick={() => dropdownSection('ApproveCard')}>Approve Card</p>
                            </div>
                        )}
                        {mainSection === 'UserGuide'
                            &&
                            <div className='sub-nav' onClick={() => toggleSection('VirtualCard')}>
                                {openSection === 'VirtualCard' ? (
                                    <IoIosArrowDown className={openSection === 'VirtualCard' ? 'arrow-clicked-differ' : 'arrow-doc-differ'} />
                                ) : (
                                    <IoIosArrowForward className={openSection === 'VirtualCard' ? 'arrow-clicked-differ' : 'arrow-doc-differ'} />
                                )}
                                <p
                                    className={openSection === 'VirtualCard' ? 'clicked' : ''}>
                                    {"Virtual Card"}
                                </p>
                            </div>
                        }
                        {mainSection === "UserGuide" && openSection === 'VirtualCard' && (
                            <div>
                                <p className={dropdownSelection === 'AddVirtualCard' ? 'nav-sublist clicked' : 'nav-sublist'}
                                    onClick={() => dropdownSection('AddVirtualCard')}>Add Virtual Card</p>
                                <p className={dropdownSelection === 'DeclineVirtualCard' ? 'nav-sublist clicked' : 'nav-sublist'}
                                    onClick={() => dropdownSection('DeclineVirtualCard')}>Decline Virtual Card</p>
                                <p className={dropdownSelection === 'ApproveVirtualCard' ? 'nav-sublist clicked' : 'nav-sublist'}
                                    onClick={() => dropdownSection('ApproveVirtualCard')}>Approve Virtual Card</p>
                            </div>
                        )}
                        {mainSection === 'UserGuide'
                            &&
                            <div className='sub-nav' onClick={() => toggleSection('Vendor')}>
                                {openSection === 'Vendor' ? (
                                    <IoIosArrowDown className={openSection === 'Vendor' ? 'arrow-clicked-differ' : 'arrow-doc-differ'} />
                                ) : (
                                    <IoIosArrowForward className={openSection === 'Vendor' ? 'arrow-clicked-differ' : 'arrow-doc-differ'} />
                                )}
                                <p
                                    className={openSection === 'Vendor' ? 'clicked' : ''}>
                                    {"Vendor"}
                                </p>
                            </div>
                        }
                        {mainSection === "UserGuide" && openSection === 'Vendor' && (
                            <div>
                                <p className={dropdownSelection === 'AddVendor' ? 'nav-sublist clicked' : 'nav-sublist'}
                                    onClick={() => dropdownSection('AddVendor')}>Add Vendor</p>
                                <p className={dropdownSelection === 'DeclineVendor' ? 'nav-sublist clicked' : 'nav-sublist'}
                                    onClick={() => dropdownSection('DeclineVendor')}>Decline Vendor</p>
                                <p className={dropdownSelection === 'ApproveVendor' ? 'nav-sublist clicked' : 'nav-sublist'}
                                    onClick={() => dropdownSection('ApproveVendor')}>Approve Vendor</p>
                            </div>
                        )}
                        {mainSection === 'UserGuide'
                            &&
                            <div className='sub-nav' onClick={() => toggleSection('Customer')}>
                                {openSection === 'Customer' ? (
                                    <IoIosArrowDown className={openSection === 'Customer' ? 'arrow-clicked-differ' : 'arrow-doc-differ'} />
                                ) : (
                                    <IoIosArrowForward className={openSection === 'Customer' ? 'arrow-clicked-differ' : 'arrow-doc-differ'} />
                                )}
                                <p
                                    className={openSection === 'Customer' ? 'clicked' : ''}>
                                    {"Customer"}
                                </p>
                            </div>
                        }
                        {mainSection === "UserGuide" && openSection === 'Customer' && (
                            <div>
                                <p className={dropdownSelection === 'AddCustomer' ? 'nav-sublist clicked' : 'nav-sublist'}
                                    onClick={() => dropdownSection('AddCustomer')}>Add Customer</p>
                                <p className={dropdownSelection === 'DeclineCustomer' ? 'nav-sublist clicked' : 'nav-sublist'}
                                    onClick={() => dropdownSection('DeclineCustomer')}>Decline Customer</p>
                                <p className={dropdownSelection === 'ApproveCustomer' ? 'nav-sublist clicked' : 'nav-sublist'}
                                    onClick={() => dropdownSection('ApproveCustomer')}>Approve Customer</p>
                            </div>
                        )}
                        {mainSection === 'UserGuide'
                            &&
                            <div className='sub-nav' onClick={() => toggleSection('Payable')}>
                                {openSection === 'Payable' ? (
                                    <IoIosArrowDown className={openSection === 'Payable' ? 'arrow-clicked-differ' : 'arrow-doc-differ'} />
                                ) : (
                                    <IoIosArrowForward className={openSection === 'Payable' ? 'arrow-clicked-differ' : 'arrow-doc-differ'} />
                                )}
                                <p
                                    className={openSection === 'Payable' ? 'clicked' : ''}>
                                    {"Payable"}
                                </p>
                            </div>
                        }
                        {mainSection === "UserGuide" && openSection === 'Payable' && (
                            <div>
                                <p className={dropdownSelection === 'AddInvoice' ? 'nav-sublist clicked' : 'nav-sublist'}
                                    onClick={() => dropdownSection('AddInvoice')}>Add Invoice</p>
                                <p className={dropdownSelection === 'DeclineInvoice' ? 'nav-sublist clicked' : 'nav-sublist'}
                                    onClick={() => dropdownSection('DeclineInvoice')}>Decline Invoice</p>
                                <p className={dropdownSelection === 'ApproveInvoice' ? 'nav-sublist clicked' : 'nav-sublist'}
                                    onClick={() => dropdownSection('ApproveInvoice')}>Approve Invoice</p>
                            </div>
                        )}
                        {mainSection === 'UserGuide'
                            &&
                            <div className='sub-nav' onClick={() => toggleSection('BulkUploads')}>
                                {openSection === 'BulkUploads' ? (
                                    <IoIosArrowDown className={openSection === 'BulkUploads' ? 'arrow-clicked-differ' : 'arrow-doc-differ'} />
                                ) : (
                                    <IoIosArrowForward className={openSection === 'BulkUploads' ? 'arrow-clicked-differ' : 'arrow-doc-differ'} />
                                )}
                                <p
                                    className={openSection === 'BulkUploads' ? 'clicked' : ''}>
                                    {"Bulk Uploads"}
                                </p>
                            </div>
                        }
                        {mainSection === "UserGuide" && openSection === 'BulkUploads' && (
                            <div>
                                <p className={dropdownSelection === 'BulkUploadFingertipUser' ? 'nav-sublist clicked' : 'nav-sublist'}
                                    onClick={() => dropdownSection('BulkUploadFingertipUser')}>Bulk Upload Fingertip User</p>
                                <p className={dropdownSelection === 'BulkUploadOrganisationUser' ? 'nav-sublist clicked' : 'nav-sublist'}
                                    onClick={() => dropdownSection('BulkUploadOrganisationUser')}>Bulk Upload Organisation User</p>
                                <p className={dropdownSelection === 'BulkUploadCompanyUser' ? 'nav-sublist clicked' : 'nav-sublist'}
                                    onClick={() => dropdownSection('BulkUploadCompanyUser')}>Bulk Upload Company User</p>
                                <p className={dropdownSelection === 'BulkUploadCompany' ? 'nav-sublist clicked' : 'nav-sublist'}
                                    onClick={() => dropdownSection('BulkUploadCompany')}>Bulk Upload Company</p>
                                <p className={dropdownSelection === 'BulkUploadCustomer' ? 'nav-sublist clicked' : 'nav-sublist'}
                                    onClick={() => dropdownSection('BulkUploadCustomer')}>Bulk Upload Customer</p>
                                <p className={dropdownSelection === 'BulkUploadVendor' ? 'nav-sublist clicked' : 'nav-sublist'}
                                    onClick={() => dropdownSection('BulkUploadVendor')}>Bulk Upload Vendor</p>
                                <p className={dropdownSelection === 'BulkUploadInvoice' ? 'nav-sublist clicked' : 'nav-sublist'}
                                    onClick={() => dropdownSection('BulkUploadInvoice')}>Bulk Upload Invoice</p>
                            </div>
                        )}
                    </div>
                    <div className='instruction-main'>
                        {dropdownSelection === "Setup" && (<div>
                            <div className='heading-doc'>1. Signup</div>
                            <div className='sub-heading-doc'>1.1 Setup Password</div>
                            <div className='description-doc'>To setup your password on the Fingertip platform:</div>
                            <ol>
                                <li className='doc-points'>Click the registration link.</li>
                                <li>Enter the new password.</li>
                                <li>Reenter the same password.</li>
                                <li>Click the <b>Next</b> button.</li>
                                <li>Enter the OTP that you have received.</li>
                                <li>Click the <b>Verify</b> button.</li>
                                <li>Click the <b>Login</b> button.</li>
                            </ol>
                        </div>
                        )}
                        {dropdownSelection === "Login" && (<div>
                            <div className='heading-doc'>1. Signup</div>
                            <div className='sub-heading-doc'>1.2 How to Login</div>
                            <div className='description-doc'>To login into the Fingertip platform:</div>
                            <ol>
                                <li>Complete the password setup.</li>
                                <li>Enter your registered email ID. </li>
                                <li>Enter your password. </li>
                                <li>Click the <b>Login</b> button. </li>
                                <li>Enter the OTP that you have received. </li>
                                <li>Click the <b>Verify</b> button.</li>
                                <li>Click <b>Get</b> button.</li>
                            </ol>
                        </div>
                        )}
                        {dropdownSelection === "ForgotPassword" && (<div>
                            <div className='heading-doc'>1. Signup</div>
                            <div className='sub-heading-doc'>1.3 Forgot Password</div>
                            <div className='description-doc'>To login into the Fingertip platform if you forgot your password:</div>
                            <ol>
                                <li className='doc-points'>Click the registration link.</li>
                                <li>Click the <b>Forgot password</b> button.</li>
                                <li>Enter OTP by following the instructions shown.</li>
                                <li>Click the <b>Verify</b> button.</li>
                                <li>Enter the new password.</li>
                                <li>Reenter the same password.</li>
                                <li>Click the <b>Submit</b> button.</li>
                                <li>Click the <b>Login</b> button.</li>
                            </ol>
                        </div>
                        )}
                        {dropdownSelection === "SetPIN" && (<div>
                            <div className='heading-doc'>1. Signup</div>
                            <div className='sub-heading-doc'>1.4 Setup PIN </div>
                            <div className='description-doc'>To setup your PIN on the Fingertip platform: </div>
                            <ol>
                                <li className='doc-points'>Login into the Fingertip platform. </li>
                                <li>Click the <b>profile icon.</b></li>
                                <li>Click <b>My Account.</b></li>
                                <li>Click the <b>Set up</b> button.</li>
                                <li>Enter OTP by following the instructions shown.</li>
                                <li>Click the <b>Submit</b> button.</li>
                                <li>Enter 6-digit PIN.</li>
                                <li>Click the <b>Save</b> button.</li>
                            </ol>
                        </div>
                        )}
                        {dropdownSelection === "TwoFactorAuthentication" && (<div>
                            <div className='heading-doc'>1. Signup</div>
                            <div className='sub-heading-doc'>1.5 Set preference for two-factor authentication </div>
                            <div className='description-doc'>To set two-factor authentication preference for login into the Fingertip platform: </div>
                            <ol>
                                <li className='doc-points'>Login into the Fingertip platform. </li>
                                <li>Click the <b>profile icon.</b></li>
                                <li>Click <b>My Account.</b></li>
                                <li>Click Password/PIN. </li>
                                <li>Click Mobile OTP/Email OTP.</li>
                                <li>Click the <b>Save</b> button to save the changes made. </li>
                            </ol>
                        </div>
                        )}
                        {dropdownSelection === "ViewcurrentPassword/PIN" && (<div>
                            <div className='heading-doc'>1. Signup</div>
                            <div className='sub-heading-doc'>1.6 View current Password or PIN</div>
                            <div className='description-doc'>To view the current password or pin: </div>
                            <ol>
                                <li className='doc-points'>Login into the Fingertip platform. </li>
                                <li>Click the <b>profile icon.</b></li>
                                <li>Click <b>My Account.</b></li>
                                <li>Click the <b>Manage</b> button.</li>
                                <li>Click the <b>eye icon.</b></li>
                                <li>Enter OTP by following the instructions shown. </li>
                                <li>Click the <b>Submit</b> button.</li>
                            </ol>
                        </div>
                        )}
                        {dropdownSelection === "ResetcurrentPassword/PIN" && (<div>
                            <div className='heading-doc'>1. Signup</div>
                            <div className='sub-heading-doc'>1.7 Reset current Password or PIN </div>
                            <div className='description-doc'>To reset the current password or pin: </div>
                            <ol>
                                <li className='doc-points'>Login into the Fingertip platform. </li>
                                <li>Click the <b>profile icon.</b></li>
                                <li>Click <b>My Account.</b></li>
                                <li>Click the <b>Manage</b> button.</li>
                                <li>Click the <b>Reset PIN</b> button.</li>
                                <li>Enter OTP by following the instructions shown.</li>
                                <li>Click the <b>Submit</b> button.</li>
                                <li>Enter the new PIN.</li>
                                <li>Click the <b>Save</b> button.</li>
                            </ol>
                        </div>
                        )}
                        {dropdownSelection === "SetProfilePhoto" && (<div>
                            <div className='heading-doc'>1. Signup</div>
                            <div className='sub-heading-doc'>1.8 Set profile photo </div>
                            <div className='description-doc'>To set profile photo: </div>
                            <ol>
                                <li className='doc-points'>Login into the Fingertip platform. </li>
                                <li>Click the <b>profile icon.</b></li>
                                <li>Click <b>My Account.</b></li>
                                <li>Click <b>My Profile.</b></li>
                                <li>Click <b>the Open Camera</b> button to take a photo. (or) </li>
                                <li>Click the <b>Browse Files</b> button to select an already existing photo. </li>
                                <li>Click the <b>Save</b> button.</li>
                            </ol>
                        </div>
                        )}
                        {dropdownSelection === "AddOrganisation" && (<div>
                            <div className='heading-doc'>2. Manage Organisation </div>
                            <div className='sub-heading-doc'>2.1 Add Organisation </div>
                            <div className='description-doc'>To create an organisation: </div>
                            <ol>
                                <li className='doc-points'>Login into the Fingertip platform. </li>
                                <li>Click <b>Product Admin.</b></li>
                                <li>Click <b>Organisation.</b></li>
                                <li>Click the <b>+Add</b> button.</li>
                                <li>Fill up Organisation details.</li>
                                <li>Click <b>Next</b> button.</li>
                                <li>Fill the Admin Details.</li>
                                <li>Click the <b>Save</b> button.</li>
                            </ol>
                        </div>
                        )}
                        {dropdownSelection === "EditOrganisation" && (<div>
                            <div className='heading-doc'>2. Manage Organisation </div>
                            <div className='sub-heading-doc'>2.2 Edit Organisation </div>
                            <div className='description-doc'>To edit an organisation:</div>
                            <ol>
                                <li className='doc-points'>Login into the Fingertip platform. </li>
                                <li>Click <b>Organisation.</b></li>
                                <li>Double click on the record.</li>
                                <li>Edit the details.</li>
                                <li>Click <b>Update</b> button.</li>
                                <li>Click <b>Admin Details.</b></li>
                                <li>Edit the admin Details.</li>
                                <li>Click <b>Update</b> button.</li>
                            </ol>
                        </div>
                        )}
                        {dropdownSelection === "DeleteOrganisation" && (<div>
                            <div className='heading-doc'>2. Manage Organisation </div>
                            <div className='sub-heading-doc'>2.3 Delete Organisation</div>
                            <div className='description-doc'>To delete an organisation:</div>
                            <ol>
                                <li className='doc-points'>Login into the Fingertip platform.</li>
                                <li>Click <b>Organisation.</b></li>
                                <li>Select the Organisation you want to delete.</li>
                                <li>Click <b>Delete</b> icon.</li>
                                <li>Click <b>Delete</b> button on the confirmation popup.</li>
                            </ol>
                        </div>
                        )}
                        {dropdownSelection === "AddCompany" && (<div>
                            <div className='heading-doc'>3. Manage Company </div>
                            <div className='sub-heading-doc'>3.1 Add Company </div>
                            <div className='description-doc'>To create a company: </div>
                            <ol>
                                <li className='doc-points'>Login into the Fingertip platform. </li>
                                <li>Click <b>Onboarding.</b></li>
                                <li>Click <b>Company.</b></li>
                                <li>Click the <b>Add</b> button.</li>
                                <li>Fill the Company overview details by using fetch or manually. </li>
                                <li>Click the <b>Next</b> button.</li>
                                <li>Fill in the Address details.</li>
                                <li>Click the <b>Next</b> button.</li>
                                <li>Fill in the Director Info details by using fetch or manually.</li>
                                <li>Click the <b>Next</b> button.</li>
                                <li>Fill in the Statutory details.</li>
                                <li>Click the <b>Next</b> button.</li>
                                <li>Fill in the Admin details.</li>
                                <li>Click the <b>Save</b> button.</li>
                            </ol>
                        </div>
                        )}
                        {dropdownSelection === "EditCompany" && (<div>
                            <div className='heading-doc'>3. Manage Company </div>
                            <div className='sub-heading-doc'>3.2 Edit Company </div>
                            <div className='description-doc'>To edit a company: </div>
                            <ol>
                                <li className='doc-points'>Login into the Fingertip platform. </li>
                                <li>Click <b>Onboarding.</b></li>
                                <li>Click <b>Company.</b></li>
                                <li>Double click the record.</li>
                                <li>Edit the details.</li>
                                <li>Click the <b>Update</b> button.</li>
                            </ol>
                        </div>
                        )}
                        {dropdownSelection === "DeleteCompany" && (<div>
                            <div className='heading-doc'>3. Manage Company </div>
                            <div className='sub-heading-doc'>3.3 Delete Company </div>
                            <div className='description-doc'>To delete a company: </div>
                            <ol>
                                <li className='doc-points'>Login into the Fingertip platform. </li>
                                <li>Click <b>Onboarding.</b></li>
                                <li>Click <b>Company.</b></li>
                                <li>Select the Company.</li>
                                <li>Click the <b>Delete</b> icon.</li>
                                <li>Click the <b>Delete</b> button.</li>
                            </ol>
                        </div>
                        )}
                        {dropdownSelection === "AddDepartment" && (
                            <div>
                                <div className='heading-doc'>3. Manage Company </div>
                                <div className='sub-heading-doc'>3.4 Add Department</div>
                                <div className='description-doc'>To add Department of the company: </div>
                                <ol>
                                    <li className='doc-points'>Login into the Fingertip platform. </li>
                                    <li>Click <b>Organisation.</b></li>
                                    <li>Click <b>Users.</b></li>
                                    <li>Click the <b>Add</b> button.</li>
                                    <li>Click the <b>plus</b> icon.</li>
                                    <li>Enter department name.</li>
                                    <li>Click the <b>Save</b> button.</li>
                                    <li>Click <b>Close</b> icon.</li>
                                    <li>Click <b>Department</b> field to show the created department.</li>
                                </ol>
                            </div>
                        )}
                        {dropdownSelection === "AddAccountManager" && (
                            <div>
                                <div className='heading-doc'>3. Manage Company </div>
                                <div className='sub-heading-doc'>3.5 Add Account Manager</div>
                                <div className='description-doc'>To set group user approval process:</div>
                                <ol>
                                    <li className='doc-points'>Login to the Fingertip platform.</li>
                                    <li>Click <b>Organisation.</b></li>
                                    <li>Click <b>Company.</b></li>
                                    <li>Click the <b>Account's Manager.</b></li>
                                    <li>Fill Account's Manager details.</li>
                                    <li>Select click <b>Add</b> button.</li>
                                    <li>Click the <b>Update</b> button.</li>
                                </ol>
                            </div>
                        )}
                        {dropdownSelection === "SetSingleUserApprovalProcess" && (
                            <div>
                                <div className='heading-doc'>3. Manage Company </div>
                                <div className='sub-heading-doc'>3.6 Set single user approval process</div>
                                <div className='description-doc'>To set single user approval process:</div>
                                <ol>
                                    <li className='doc-points'>Login to the Fingertip platform.</li>
                                    <li>Click <b>Organisation.</b></li>
                                    <li>Click <b>Company.</b></li>
                                    <li>Click the <b>Approval process.</b></li>
                                    <li>Click <b>Single User Approval button.</b></li>
                                    <li>Select User.</li>
                                    <li>Click the <b>Update</b> button.</li>
                                </ol>
                            </div>
                        )}
                        {dropdownSelection === "SetGroupUserApprovalProcess" && (
                            <div>
                                <div className='heading-doc'>3. Manage Company </div>
                                <div className='sub-heading-doc'>3.7 Set Group user approval process</div>
                                <div className='description-doc'>To set group user approval process:</div>
                                <ol>
                                    <li className='doc-points'>Login to the Fingertip platform.</li>
                                    <li>Click <b>Organisation.</b></li>
                                    <li>Click <b>Company.</b></li>
                                    <li>Click the <b>Approval process.</b></li>
                                    <li>Click <b>Group user approval button.</b></li>
                                    <li>Select <b>Department.</b></li>
                                    <li>Enter <b>Min</b> and <b>Max</b> value.</li>
                                    <li>Click <b>Group icon</b> in Maker.</li>
                                    <li>Click <b>Group icon</b> in Checker then click check box.</li>
                                    <li>Click <b>Group icon</b> in Recommender then click check box.</li>
                                    <li>Click <b>Group icon</b> in Approver.</li>
                                    <li>Click the <b>Add</b> button.</li>
                                    <li>Click the <b>Update</b> button.</li>
                                </ol>
                            </div>
                        )}
                        {dropdownSelection === "SetSubscriptionValidity" && (
                            <div>
                                <div className='heading-doc'>3. Manage Company </div>
                                <div className='sub-heading-doc'>3.8 Set Subscription validity</div>
                                <div className='description-doc'>To set Subscription validity:</div>
                                <ol>
                                    <li className='doc-points'>Login to the Fingertip platform.</li>
                                    <li>Click <b>Organisation.</b></li>
                                    <li>Click <b>Company.</b></li>
                                    <li>Click the <b>Subscription Validity.</b></li>
                                    <li>Enter License Key.</li>
                                    <li>Click the <b>Add</b> button.</li>
                                    <li>Click the <b>Update</b> button.</li>
                                </ol>
                            </div>
                        )}
                        {dropdownSelection === "UploadKYC" && (
                            <div>
                                <div className='heading-doc'>4. Manage KYC</div>
                                <div className='sub-heading-doc'>4.1 Upload KYC document</div>
                                <div className='description-doc'>To upload a KYC document:</div>
                                <ol>
                                    <li className='doc-points'>Login to the Fingertip platform.</li>
                                    <li>Click <b>KYC.</b></li>
                                    <li>Click the Drop items here or Browse Files button to upload the Company GST.</li>
                                    <li>Click the Drop items here or Browse Files button to upload the Company PAN.</li>
                                    <li>Click Company to upload the company document.</li>
                                    <li>Select Document Type.</li>
                                    <li>Click the Drop items here or Browse Files button to upload the company document.</li>
                                    <li>Click Director to upload the director document.</li>
                                    <li>Select Document Type.</li>
                                    <li>Select the Director’s Name.</li>
                                    <li>Click the <b>Drop items here or Browse Files</b> button to upload the Director document.</li>
                                    <li>Click <b>the Save</b> button.</li>
                                </ol>
                            </div>
                        )}
                        {dropdownSelection === "ResubmitthedeclinedKYCdocument" && (
                            <div>
                                <div className='heading-doc'>4. Manage KYC</div>
                                <div className='sub-heading-doc'>4.2 Resubmit the declined KYC document</div>
                                <div className='description-doc'>To edit an organisation from the Fingertip application:</div>
                                <ol>
                                    <li className='doc-points'>Login to the Fingertip platform.</li>
                                    <li>Click <b>Organisation.</b></li>
                                    <li>Click <b>KYC.</b></li>
                                    <li>Click the <b>Drop items here or Browse Files</b> button to select or drag the file into this button.</li>
                                    <li>Click <b>the Save</b> button.</li>
                                </ol>
                            </div>
                        )}
                        {dropdownSelection === "Approveindividualdocument" && (
                            <div>
                                <div className='heading-doc'>5. Manage Customer Onboarding</div>
                                <div className='sub-heading-doc'>5.1 Approve individual document</div>
                                <div className='description-doc'>To approve individual KYC documents:</div>
                                <ol>
                                    <li className='doc-points'>Login to the Fingertip platform.</li>
                                    <li>Click <b>Product Admin.</b></li>
                                    <li>Click <b>Customer Onboarding</b> </li>
                                    <li>Click the <b>Verify KYC</b> button.</li>
                                    <li>Select any company name.</li>
                                    <li>Click the <b>Eye icon</b> in the grid.</li>
                                    <li>Verify the details shown in the popup.</li>
                                    <li>Click the <b>Approve</b> button.</li>
                                </ol>
                            </div>
                        )}
                        {dropdownSelection === "Declineindividualdocument" && (
                            <div>
                                <div className='heading-doc'>5. Manage Customer Onboarding</div>
                                <div className='sub-heading-doc'>5.2 Decline individual document</div>
                                <div className='description-doc'>To decline individual KYC documents:</div>
                                <ol>
                                    <li className='doc-points'>Login to the Fingertip platform.</li>
                                    <li>Click <b>Product Admin.</b></li>
                                    <li>Click <b>Customer Onboarding.</b> </li>
                                    <li>Click the <b>Verify KYC</b> button.</li>
                                    <li>Select any company name.</li>
                                    <li>Click the <b>Eye icon</b> in the grid.</li>
                                    <li>Verify the details shown in the popup.</li>
                                    <li>Click the <b>Decline</b> button.</li>
                                    <li>Enter reason for decline.</li>
                                    <li>Click the <b>Submit</b> button.</li>
                                </ol>
                            </div>
                        )}
                        {dropdownSelection === "ApproveKYC" && (
                            <div>
                                <div className='heading-doc'>5. Manage Customer Onboarding</div>
                                <div className='sub-heading-doc'>5.3 Approve KYC</div>
                                <div className='description-doc'>To approve the KYC:</div>
                                <ol>
                                    <li className='doc-points'>Login to the Fingertip platform.</li>
                                    <li>Click <b>Product Admin</b>.</li>
                                    <li>Click <b>Customer Onboarding</b> </li>
                                    <li>Click the <b>Verify KYC</b> button.</li>
                                    <li>Select any company name.</li>
                                    <li>Approve the individual documents by clicking Eye icon in the grid.</li>
                                    <li>Select <b>Approved</b> option from Customer Onboarding Status.</li>
                                    <li>Fill the transaction Charges details.</li>
                                    <li>Click the <b>Save</b> button then enter OTP.</li>
                                    <li>Click <b>Verify</b> button.</li>
                                </ol>
                            </div>
                        )}
                        {dropdownSelection === "DeclineKYC" && (
                            <div>
                                <div className='heading-doc'>5. Manage Customer Onboarding</div>
                                <div className='sub-heading-doc'>5.4 Decline KYC</div>
                                <div className='description-doc'>To decline the KYC:</div>
                                <ol>
                                    <li className='doc-points'>Login to the Fingertip platform.</li>
                                    <li>Click <b>Product Admin.</b></li>
                                    <li>Click <b>Customer Onboarding</b> </li>
                                    <li>Select any company name.</li>
                                    <li>Select <b>Decline</b> option from Customer Onboarding Status.</li>
                                    <li>Fill in the transaction Charges details.</li>
                                    <li>Click the <b>Save</b> button.</li>
                                </ol>
                            </div>
                        )}
                        {dropdownSelection === "GenerateLicenseKey" && (
                            <div>
                                <div className='heading-doc'>5. Manage Customer Onboarding</div>
                                <div className='sub-heading-doc'>5.5 Generate License key</div>
                                <div className='description-doc'>To generate license key:</div>
                                <ol>
                                    <li className='doc-points'>Login to the Fingertip platform.</li>
                                    <li>Click <b>Product Admin</b>.</li>
                                    <li>Click <b>Customer Onboarding</b></li>
                                    <li>Select any company name.</li>
                                    <li>Click the <b>Plus</b> button below the License key label.</li>
                                    <li>Select any Plan.</li>
                                    <li>Click the <b>Generate</b> button.</li>
                                    <li>Click the <b>Save</b> button.</li>
                                    <li>Click the Close icon.</li>
                                    <li>Click the Save button and enter OTP.</li>
                                    <li>Click Verify Button.</li>
                                </ol>
                            </div>
                        )}
                        {dropdownSelection === "AddFingertipUser" && (
                            <div>
                                <div className='heading-doc'>6. Users Managements</div>
                                <div className='sub-heading-doc'>6.1 Add Fingertip Users</div>
                                <div className='description-doc'>To add fingertip users:</div>
                                <ol>
                                    <li className='doc-points'>Login to the Fingertip platform.</li>
                                    <li>Click <b>Product Admin.</b> </li>
                                    <li>Click <b>Users.</b> </li>
                                    <li>Click the <b> Add</b> button.</li>
                                    <li>Select Organisation as Fingertip.</li>
                                    <li>Select either Admin or BI Reports in the group.</li>
                                    <li>Fill in user details.</li>
                                    <li>Click the <b>Save</b> button.</li>
                                </ol>
                            </div>
                        )}
                        {dropdownSelection === "AddOrganisationUser" && (
                            <div>
                                <div className='heading-doc'>6. Users Managements</div>
                                <div className='sub-heading-doc'>6.2 Add Organisation Users</div>
                                <div className='description-doc'>To add organisation  users: </div>
                                <ol>
                                    <li className='doc-points'>Login to the Fingertip platform.</li>
                                    <li>Click <b>Onboarding</b> </li>
                                    <li>Click <b>Users.</b> </li>
                                    <li>Click the <b> Add</b> button.</li>
                                    <li>Select either Admin or BI Reports in the group.</li>
                                    <li>Fill the user details.</li>
                                    <li>Click the <b>Save</b> button.</li>
                                </ol>
                            </div>
                        )}
                        {dropdownSelection === "AddCompanyUser" && (
                            <div>
                                <div className='heading-doc'>6. Users Managements</div>
                                <div className='sub-heading-doc'>6.3Add Company Users</div>
                                <div className='description-doc'>To add company users:</div>
                                <ol>
                                    <li className='doc-points'>Login to the Fingertip platform.</li>
                                    <li>2.Click <b>Organisation</b> </li>
                                    <li>Click <b>Users.</b></li>
                                    <li>Click the <b> Add</b> button. </li>
                                    <li>Select user department.</li>
                                    <li>Select any one option in the group.</li>
                                    <li>Fill the user details.</li>
                                    <li>Click the <b>Save</b> button.</li>
                                </ol>
                            </div>
                        )}
                        {dropdownSelection === "AddBank" && (
                            <div>
                                <div className='heading-doc'>7. Manage Bank</div>
                                <div className='sub-heading-doc'>7.1 Add Bank</div>
                                <div className='description-doc'>To add Bank:</div>
                                <ol>
                                    <li className='doc-points'>Login to the Fingertip platform.</li>
                                    <li>Click <b>Organisation</b> </li>
                                    <li>Click <b>Bank.</b> </li>
                                    <li>Click the <b> Add</b> button. </li>
                                    <li>Fill in all the details.</li>
                                    <li>Click the <b>Submit for Approval</b> button.</li>
                                </ol>
                            </div>
                        )}
                        {dropdownSelection === "DeclineBank" && (
                            <div>
                                <div className='heading-doc'>7. Manage Bank</div>
                                <div className='sub-heading-doc'>7.2 Decline Bank</div>
                                <div className='description-doc'>To decline Bank:</div>
                                <ol>
                                    <li className='doc-points'>Login to the Fingertip platform.</li>
                                    <li>Click <b>Approval.</b> </li>
                                    <li>Click <b>Bank.</b> </li>
                                    <li>Double Click the <b>record.</b> </li>
                                    <li>Click <b>Decline.</b></li>
                                    <li>Enter Reason.</li>
                                    <li>Click the <b>Save</b> button.</li>
                                </ol>
                            </div>
                        )}
                        {dropdownSelection === "ApproveBank" && (
                            <div>
                                <div className='heading-doc'>7. Manage Bank</div>
                                <div className='sub-heading-doc'>7.3 Approve Bank</div>
                                <div className='description-doc'>To approve Bank:</div>
                                <ol>
                                    <li className='doc-points'>Login to the Fingertip platform.</li>
                                    <li>Click <b>Approval.</b> </li>
                                    <li>Click <b>Bank.</b> </li>
                                    <li>Double Click the <b>record.</b> </li>
                                    <li>Click <b>Approve</b>.</li>
                                    <li>Click the <b>Save</b> button.</li>
                                    <li>Enter the OTP that you have received.</li>
                                    <li>Click the <b>Verify</b> button.</li>
                                </ol>
                            </div>
                        )}
                        {dropdownSelection === "AddCard" && (
                            <div>
                                <div className='heading-doc'>8. Manage Card</div>
                                <div className='sub-heading-doc'>8.1 Add Card</div>
                                <div className='description-doc'>To add Card:</div>
                                <ol>
                                    <li className='doc-points'>Login to the Fingertip platform.</li>
                                    <li>Click <b>Organisation</b> </li>
                                    <li>Click <b>Card.</b> </li>
                                    <li>Click the <b> Add</b> button. </li>
                                    <li>Fill in all the details.</li>
                                    <li>Click the <b>Next</b> button.</li>
                                    <li>Fill in all the details.</li>
                                    <li>Click the <b>Add</b> button.</li>
                                    <li>Click the <b>Submit for Approval</b> button.</li>
                                </ol>
                            </div>
                        )}
                        {dropdownSelection === "DeclineCard" && (
                            <div>
                                <div className='heading-doc'>8. Manage Card</div>
                                <div className='sub-heading-doc'>8.2 Decline Card</div>
                                <div className='description-doc'>To decline Card:</div>
                                <ol>
                                    <li className='doc-points'>Login to the Fingertip platform.</li>
                                    <li>Click <b>Approval.</b> </li>
                                    <li>Click <b>Card.</b></li>
                                    <li>Double Click the <b>record.</b> </li>
                                    <li>Verify the data.</li>
                                    <li>Click <b>Next</b>.</li>
                                    <li>Verify the data.</li>
                                    <li>Click <b>Next</b>.</li>
                                    <li>Click <b>Decline</b>.</li>
                                    <li>Enter Reason.</li>
                                    <li>Click the <b>Save</b> button.</li>
                                </ol>
                            </div>
                        )}
                        {dropdownSelection === "ApproveCard" && (
                            <div>
                                <div className='heading-doc'>8. Manage Card</div>
                                <div className='sub-heading-doc'>8.3 Approve Card</div>
                                <div className='description-doc'>To approve Card:</div>
                                <ol>
                                    <li className='doc-points'>Login to the Fingertip platform.</li>
                                    <li>Click <b>Approval.</b> </li>
                                    <li>Click <b>Card.</b> </li>
                                    <li>Double Click the <b> record.</b> </li>
                                    <li>Verify the data.</li>
                                    <li>Click <b>Next</b>.</li>
                                    <li>Verify the data.</li>
                                    <li>Click <b>Next</b>.</li>
                                    <li>Click Approve.</li>
                                    <li>Click the <b>Save</b> button.</li>
                                </ol>
                            </div>
                        )}
                        {dropdownSelection === "AddVirtualCard" && (
                            <div>
                                <div className='heading-doc'>9. Manage Virtual Card</div>
                                <div className='sub-heading-doc'>9.1 Add Virtual Card</div>
                                <div className='description-doc'>To add a Virtual Card:</div>
                                <ol>
                                    <li className='doc-points'> Login to the Fingertip platform.</li>
                                    <li>Click <b>Organisation</b> </li>
                                    <li>Click <b>Virtual Card.</b> </li>
                                    <li>Click the <b> Request </b>button.</li>
                                    <li>Fill in all the details.</li>
                                    <li>Click the <b>Submit for Approval</b> button.</li>
                                </ol>
                            </div>
                        )}
                        {dropdownSelection === "DeclineVirtualCard" && (
                            <div>
                                <div className='heading-doc'>9. Manage Virtual Card</div>
                                <div className='sub-heading-doc'>9.2 Decline Virtual Card</div>
                                <div className='description-doc'>To decline Virtual Card:</div>
                                <ol>
                                    <li className='doc-points'> Login to the Fingertip platform.</li>
                                    <li>Click <b>Approval.</b> </li>
                                    <li>Click <b>Virtual Card.</b> </li>
                                    <li>Double Click the <b> record.</b> </li>
                                    <li>Click <b>Decline</b>.</li>
                                    <li>Enter Reason.</li>
                                    <li>Click the <b>Save</b> button.</li>
                                </ol>
                            </div>
                        )}
                        {dropdownSelection === "ApproveVirtualCard" && (
                            <div>
                                <div className='heading-doc'>9. Manage Virtual Card</div>
                                <div className='sub-heading-doc'>9.3 Approve Virtual Card</div>
                                <div className='description-doc'>To approve Virtual Card:</div>
                                <ol>
                                    <li className='doc-points'>Login to the Fingertip platform.</li>
                                    <li>Click <b>Approval.</b></li>
                                    <li>Click <b>Virtual Card.</b> </li>
                                    <li>Double Click the <b>record.</b></li>
                                    <li>Click <b>Approve.</b></li>
                                    <li>Click the <b>Save</b> button.</li>
                                    <li>Enter the OTP that you have received.</li>
                                    <li>Click the <b>Verify</b> button.</li>
                                </ol>
                            </div>
                        )}
                        {dropdownSelection === "AddVendor" && (
                            <div>
                                <div className='heading-doc'>10. Manage Vendor</div>
                                <div className='sub-heading-doc'>10.1 Add Vendor</div>
                                <div className='description-doc'>To add a Vendor:</div>
                                <ol>
                                    <li className='doc-points'>Login to the Fingertip platform.</li>
                                    <li>Click <b>Onboarding</b> </li>
                                    <li>Click <b>Vendor.</b> </li>
                                    <li>Click the <b>Add</b> button.</li>
                                    <li>Fill in the Vendor overview details by using fetch or manually.</li>
                                    <li>Click the <b>Next</b> button.</li>
                                    <li>Fill in the Bank details.</li>
                                    <li>Click the <b>Next</b> button.</li>
                                    <li>Fill in the Address details.</li>
                                    <li>Click the <b>Next</b> button.</li>
                                    <li>Fill in the Director Info details by using fetch or manually.</li>
                                    <li>Click the <b>Next</b> button.</li>
                                    <li>Fill in the Statutory details.</li>
                                    <li>Click the <b>Next</b> button.</li>
                                    <li>Fill in the Point of Contact details.</li>
                                    <li>Click the <b>Submit for Approval</b> button.</li>
                                </ol>
                            </div>
                        )}
                        {dropdownSelection === "DeclineVendor" && (
                            <div>
                                <div className='heading-doc'>10. Manage Vendor</div>
                                <div className='sub-heading-doc'>10.2 Decline Vendor</div>
                                <div className='description-doc'>To decline Vendor:</div>
                                <ol>
                                    <li className='doc-points'>Login to the Fingertip platform.</li>
                                    <li>Click <b>Approval.</b> </li>
                                    <li>3.Click <b>Vendor.</b> </li>
                                    <li>Double Click the <b> record.</b> </li>
                                    <li>Verify Vendor overview details.</li>
                                    <li>Click the <b>Next</b> button.</li>
                                    <li>Verify Bank details.</li>
                                    <li>Click the <b>Next</b> button.</li>
                                    <li>Verify Address details.</li>
                                    <li>Click the <b>Next</b> button.</li>
                                    <li>Verify Director Info details.</li>
                                    <li>Click the <b>Next</b> button.</li>
                                    <li>Verify Statutory details.</li>
                                    <li>Click the <b>Next</b> button.</li>
                                    <li>Verify Point of Contact details.</li>
                                    <li>Click the <b>Next</b> button.</li>
                                    <li>Click the <b>decline</b> button.</li>
                                    <li>Enter reason.</li>
                                    <li>Click the <b>Save</b> button.</li>
                                </ol>
                            </div>
                        )}
                        {dropdownSelection === "ApproveVendor" && (
                            <div>
                                <div className='heading-doc'>10. Manage Vendor</div>
                                <div className='sub-heading-doc'>10.3 Approve Vendor</div>
                                <div className='description-doc'>To approve Vendor:</div>
                                <ol>
                                    <li className='doc-points'> Login to the Fingertip platform.</li>
                                    <li>Click <b>Approval.</b> </li>
                                    <li>Click <b>Vendor. </b></li>
                                    <li>Double Click the <b> record.</b> </li>
                                    <li>Verify Vendor overview details.</li>
                                    <li>Click the <b>Next</b> button.</li>
                                    <li>Verify Bank details.</li>
                                    <li>Click the <b>Next</b> button.</li>
                                    <li>Verify Address details.</li>
                                    <li>Click the <b>Next</b> button.</li>
                                    <li>Verify Director Info details.</li>
                                    <li>Click the <b>Next</b> button.</li>
                                    <li>Verify Statutory details.</li>
                                    <li>Click the <b>Next</b> button.</li>
                                    <li>Verify Point of Contact details.</li>
                                    <li>Click the <b>Next</b> button.</li>
                                    <li>Click the <b>Approve</b> button.</li>
                                    <li>Click the <b>Save</b> button.</li>
                                    <li>Enter the OTP that you have received.</li>
                                    <li>Click the <b>Verify</b> button.</li>
                                </ol>
                            </div>
                        )}
                        {dropdownSelection === "AddCustomer" && (
                            <div>
                                <div className='heading-doc'>11. Manage Customer</div>
                                <div className='sub-heading-doc'>11.1 Add Customer</div>
                                <div className='description-doc'>To add a customer:</div>
                                <ol>
                                    <li className='doc-points'> Login to the Fingertip platform.</li>
                                    <li>Click <b>Onboarding</b> </li>
                                    <li>Click <b>Customer.</b></li>
                                    <li>Click the <b>Add</b> button.</li>
                                    <li>Fill in the Customer overview details by using fetch or manually.</li>
                                    <li>Click the <b>Next</b> button.</li>
                                    <li>Fill in the Address details.</li>
                                    <li>Click the <b>Next</b> button.</li>
                                    <li>Fill in the Point of Contact details.</li>
                                    <li>Click the <b>Submit for Approval</b> button.</li>
                                </ol>
                            </div>
                        )}
                        {dropdownSelection === "DeclineCustomer" && (
                            <div>
                                <div className='heading-doc'>11. Manage Customer</div>
                                <div className='sub-heading-doc'>11.2 Decline Customer</div>
                                <div className='description-doc'>To decline a customer:</div>
                                <ol>
                                    <li className='doc-points'> Login to the Fingertip platform.</li>
                                    <li>Click <b>Approval.</b></li>
                                    <li>Click <b>Customer.</b></li>
                                    <li>Double Click the <b>record.</b></li>
                                    <li>Verify Customer overview details.</li>
                                    <li>Click the <b>Next</b> button.</li>
                                    <li>Verify Address details.</li>
                                    <li>Click the <b>Next</b> button.</li>
                                    <li>Verify Point of Contact details.</li>
                                    <li>Click the <b>Next</b> button.</li>
                                    <li>Click the <b>decline</b> button.</li>
                                    <li>Enter reason.</li>
                                    <li>Click the <b>Save</b> button.</li>
                                </ol>
                            </div>
                        )}
                        {dropdownSelection === "ApproveCustomer" && (
                            <div>
                                <div className='heading-doc'>11. Manage Customer</div>
                                <div className='sub-heading-doc'>11.3 Approve Customer</div>
                                <div className='description-doc'>To approve a customer:</div>
                                <ol>
                                    <li className='doc-points'> Login to the Fingertip platform.</li>
                                    <li>Click <b>Approval.</b> </li>
                                    <li>Click <b>Customer.</b> </li>
                                    <li>Double Click the <b>record.</b> </li>
                                    <li>Verify Customer overview details.</li>
                                    <li>Click the <b>Next</b> button.</li>
                                    <li>Verify Address details.</li>
                                    <li>Click the <b>Next</b> button.</li>
                                    <li>Verify Point of Contact details.</li>
                                    <li>Click the <b>Next</b> button.</li>
                                    <li>Click the <b>Approve</b> button.</li>
                                    <li>Click the <b>Save</b> button.</li>
                                    <li>Enter the OTP that you have received.</li>
                                    <li>Click the <b>Verify</b> button.</li>
                                </ol>
                            </div>
                        )}
                        {dropdownSelection === "AddInvoice" && (
                            <div>
                                <div className='heading-doc'>12 Manage Invoice</div>
                                <div className='sub-heading-doc'>12.1 Add invoice</div>
                                <div className='description-doc'>To add an invoice:</div>
                                <ol>
                                    <li className='doc-points'> Login to the Fingertip platform.</li>
                                    <li>Click <b>Payable.</b></li>
                                    <li>Click <b>Vendor.</b></li>
                                    <li>Click the <b>Invoice</b> button.</li>
                                    <li>Fill in the invoice details.</li>
                                    <li>Click the <b>Next</b> button.</li>
                                    <li>Select any payment method.</li>
                                    <li>Click the <b>Next</b> button.</li>
                                    <li>Verify the details you have entered / Selected</li>
                                    <li>Click <b>I have reviewed all the details presented above</b> check box.</li>
                                    <li>Click the <b>Submit for Approval</b> button.</li>
                                </ol>
                            </div>
                        )}
                        {dropdownSelection === "DeclineInvoice" && (
                            <div>
                                <div className='heading-doc'>12 Manage Invoice</div>
                                <div className='sub-heading-doc'>12.2 Decline Invoice</div>
                                <div className='description-doc'>To decline an invoice:</div>
                                <ol>
                                    <li className='doc-points'> Login to the Fingertip platform.</li>
                                    <li>Click <b>Approval.</b></li>
                                    <li>Click <b>Vendor.</b></li>
                                    <li>Double Click the <b>record.</b></li>
                                    <li>Verify invoice details.</li>
                                    <li>Click the <b>Next</b> button.</li>
                                    <li>Verify payment method.</li>
                                    <li>Click the <b>Next</b> button.</li>
                                    <li>Verify all the details.</li>
                                    <li>Click <b>I have reviewed all the details presented above</b> check box.</li>
                                    <li>Click the <b>decline</b> button.</li>
                                    <li>Enter reason.</li>
                                    <li>Click the <b>Submit</b> button.</li>
                                </ol>
                            </div>
                        )}
                        {dropdownSelection === "ApproveInvoice" && (
                            <div>
                                <div className='heading-doc'>12 Manage Invoice</div>
                                <div className='sub-heading-doc'>12.3 Approve Invoice</div>
                                <div className='description-doc'>To approve an invoice:</div>
                                <ol>
                                    <li className='doc-points'> Login to the Fingertip platform.</li>
                                    <li>Click <b>Approval.</b></li>
                                    <li>Click <b>Vendor.</b></li>
                                    <li>Double Click the <b>record.</b></li>
                                    <li>Verify invoice details.</li>
                                    <li>Click the <b>Next</b> button.</li>
                                    <li>Verify payment method.</li>
                                    <li>Click the <b>Next</b> button.</li>
                                    <li>Verify all the details.</li>
                                    <li>Click <b>I have reviewed all the details presented above</b> check box.</li>
                                    <li>Click the <b>Submit for approval</b> button.</li>
                                </ol>
                            </div>
                        )}
                        {dropdownSelection === "BulkUploadFingertipUser" && (
                            <div>
                                <div className='heading-doc'>13 Bulk Uploads</div>
                                <div className='sub-heading-doc'>13.1 Bulk Upload – Fingertip users</div>
                                <div className='description-doc'>To bulk upload the fingertip users:</div>
                                <ol>
                                    <li className='doc-points'> Login to the Fingertip platform.</li>
                                    <li>Click <b>Product Admin.</b></li>
                                    <li>Click <b>User.</b></li>
                                    <li>Click <b>Import</b> icon.</li>
                                    <li>Click <b>Download Template</b> button.</li>
                                    <li>Fill the data in template.</li>
                                    <li>Click <b>Browse</b> button to upload the data.</li>
                                    <li>Now Status excel downloaded for your references.</li>
                                </ol>
                            </div>
                        )}
                        {dropdownSelection === "BulkUploadOrganisationUser" && (
                            <div>
                                <div className='heading-doc'>13 Bulk Uploads</div>
                                <div className='sub-heading-doc'>13.2 Bulk Upload Organisation users</div>
                                <div className='description-doc'>To bulk upload the organisation users:</div>
                                <ol>
                                    <li className='doc-points'> Login to the Fingertip platform.</li>
                                    <li>Click <b>Onboarding.</b></li>
                                    <li>Click <b>User.</b></li>
                                    <li>Click <b>Import</b> icon.</li>
                                    <li>Click <b>Download Template</b> button.</li>
                                    <li>Fill the data in template.</li>
                                    <li>Click <b>Browse</b> button to upload the data.</li>
                                    <li>Now Status excel downloaded for your references.</li>
                                </ol>
                            </div>
                        )}
                        {dropdownSelection === "BulkUploadCompanyUser" && (
                            <div>
                                <div className='heading-doc'>13 Bulk Uploads</div>
                                <div className='sub-heading-doc'>13.3 Bulk Upload – Company users</div>
                                <div className='description-doc'>To bulk upload the company users:</div>
                                <ol>
                                    <li className='doc-points'> Login to the Fingertip platform</li>
                                    <li>Click <b>Organisation.</b></li>
                                    <li>Click <b>User.</b></li>
                                    <li>Click <b>Import</b> icon.</li>
                                    <li>Click <b>Download Template</b> button.</li>
                                    <li>Fill the data in template.</li>
                                    <li>Click <b>Browse</b> button to upload the data.</li>
                                    <li>Now Status excel downloaded for your references.</li>
                                </ol>
                            </div>
                        )}
                        {dropdownSelection === "BulkUploadCompany" && (
                            <div>
                                <div className='heading-doc'>13 Bulk Uploads</div>
                                <div className='sub-heading-doc'>13.4 Bulk Upload – Companies</div>
                                <div className='description-doc'>To bulk upload the companies:</div>
                                <ol>
                                    <li className='doc-points'> Login to the Fingertip platform.</li>
                                    <li>Click <b>Onboarding.</b>
                                    </li>
                                    <li>Click <b>Company.</b></li>
                                    <li>Click <b>Import</b> icon.</li>
                                    <li>Click <b>Download Template</b> button.</li>
                                    <li>Fill the data in template.</li>
                                    <li>Click <b>Browse</b> button to upload the data.</li>
                                    <li>Now Status excel downloaded for your references.</li>
                                </ol>
                            </div>
                        )}
                        {dropdownSelection === "BulkUploadCustomer" && (
                            <div>
                                <div className='heading-doc'>13 Bulk Uploads</div>
                                <div className='sub-heading-doc'>13.5 Bulk Upload – Customer</div>
                                <div className='description-doc'>To bulk upload the customers:</div>
                                <ol>
                                    <li className='doc-points'> Login to the Fingertip platform.</li>
                                    <li>Click <b>Organisation.</b></li>
                                    <li>Click <b>Customer.</b></li>
                                    <li>Click <b>Import</b> icon.</li>
                                    <li>Click <b>Download Template</b> button.</li>
                                    <li>Fill the data in template.</li>
                                    <li>Click <b>Browse</b> button to upload the data.</li>
                                    <li>Now Status excel downloaded for your references.</li>
                                </ol>
                            </div>
                        )}
                        {dropdownSelection === "BulkUploadVendor" && (
                            <div>
                                <div className='heading-doc'>13 Bulk Uploads</div>
                                <div className='sub-heading-doc'>13.6 Bulk Upload – Vendor</div>
                                <div className='description-doc'>To bulk upload the vendor:</div>
                                <ol>
                                    <li className='doc-points'> Login to the Fingertip platform.</li>
                                    <li>Click <b>Organisation.</b></li>
                                    <li>Click <b>Vendor.</b></li>
                                    <li>Click <b>Import</b> icon.</li>
                                    <li>Click <b>Download Template</b> button.</li>
                                    <li>Fill the data in template.</li>
                                    <li>Click <b>Browse</b> button to upload the data.</li>
                                    <li>Now Status excel downloaded for your references.</li>
                                </ol>
                            </div>
                        )}
                        {dropdownSelection === "BulkUploadInvoice" && (
                            <div>
                                <div className='heading-doc'>13 Bulk Uploads</div>
                                <div className='sub-heading-doc'>13.7 Bulk Upload – Invoice</div>
                                <div className='description-doc'>To bulk upload the Invoice:</div>
                                <ol>
                                    <li className='doc-points'> Login to the Fingertip platform.</li>
                                    <li>Click <b>Payable.</b></li>
                                    <li>Click <b>Vendor.</b></li>
                                    <li>Click <b>Import</b> icon.</li>
                                    <li>Click <b>Download Template</b> button.</li>
                                    <li>Fill the data in template.</li>
                                    <li>Click <b>Browse</b> button to upload the data.</li>
                                    <li>Now Status excel downloaded for your references.</li>
                                </ol>
                            </div>
                        )}
                    </div>
                    <div className='video-main'>
                        <div key={videoUrl}>
                            <video controls className='doc-video-size'>
                                <source
                                    src={videoUrl}
                                    type="video/mp4"
                                />
                                <track
                                    label="English"
                                    kind="subtitles"
                                    src={vttUrl}
                                    srcLang="en"
                                    default
                                />
                            </video>
                        </div>
                    </div>
                </div>
            </div>
            {
                isLoading &&
                <Loading />
            }
        </div>
    )
}

export default DocumentationVideos