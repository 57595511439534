import React, { useEffect, useState, forwardRef, useImperativeHandle, } from 'react'
import RadioOrCheckbox from '../../../common-component/form-elements/radio-or-checkbox'
import InputComponent from '../../../common-component/form-elements/input-component'
import ButtonComponent from '../../../common-component/form-elements/button-component'
import BusinessContactDetails from '../../../../config/onboarding/other-business-contact-grid'
import useAxiosPrivate from "../../../../services/hooks/useaxios-private";
import AlertBox from '../../../common-component/alert-box'
import { useDispatch, useSelector } from "react-redux";
import { resetFormModified } from "../../../../redux/action";
import Changesmodal from '../../../common-component/modals/changes-modal'

export interface ChildRef {
  next: () => void;
}
interface props {
  onboardingViewfun: (val: any) => void;
  iconStatus: any
  editData: any
  businessContactMain: any
  setShowPage: (val: any) => void;
  setIconStatus: (val: any) => void;
  setMainApiData: (val: any) => void;
  sendDataApi: () => void;
  mainApiData: any;
  setBusinessContactMain: (val: any) => void;
  isEditMode: boolean;
  isDisabled: boolean;
}
const PointOfContact = forwardRef<ChildRef, props>(({ onboardingViewfun, isDisabled, iconStatus, setMainApiData, editData, mainApiData, isEditMode, sendDataApi, setShowPage, setIconStatus, businessContactMain, setBusinessContactMain }, ref) => {

  const [radioActive, setRadioActive] = useState("Yes")
  const [companyOverviewErrors, setCompanyOverviewErrors] = useState<Record<string, boolean>>({})
  const [formDataEdit, setFormDataEdit] = useState(false)
  const [currentIndex, setCurrentIndex] = useState<number>()
  // const [businessContactMain, setBusinessContactMain] = useState(initData)
  const [noDuplicate, setNoDuplicate] = useState(false)
  const [showType, setShowType] = useState("warning") // error message showType
  const [showMessage, setShowMessage] = useState<string>("") // error message showMessage
  const [showAlertBox, setShowAlertBox] = useState(false) // error message ShowAlertBox
  const [currentContact, setCurrentContact] = useState<number>(0)
  const [disableState, setDisableState] = useState(true)
  const [isDuplicate, setIsDuplicate] = useState(false)
  const [isFormDirty, setIsFormDirty] = useState(false)
  const isFormModified = useSelector((state: any) => state.isFormModified);
  const [showChangesModal, setShowChangesModal] = useState<boolean>(false);
  const dispatch = useDispatch();
  // const [isDisabled, setIsDisabled] = useState<boolean>(false)



  // function retriveData(value: any, index: number) {
  //   setCurrentIndex(index)
  //   setFormDataEdit(true)
  //   setIsFormDirty(true);
  //   setCurrentContact(value.id)
  //   if (Object.values(value).length !== 3) {
  //     setBusinessContactMain({
  //       ...businessContactMain,
  //       ...value,
  //     })
  //   }

  // }

  const userValidationRules: any = {
    contactPersonName: {
      regex: /^[a-zA-Z][a-zA-Z /]*$/,
      field: "mandatory",
      shouldNotBe: ""
    },
    // /^[a-zA-Z /]*$/
    // /[A-Za-z][A-Za-z ]$/
    phoneNumber: {
      regex: /^[0-9]{10}$/,
      field: "mandatory",
      shouldNotBe: ""
    },
    email: {
      regex: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      field: "mandatory",
      shouldNotBe: ""
    },
    designation: {
      regex: businessContactMain?.designation !== "" && businessContactMain?.designation !== undefined ? /^[a-zA-Z][a-zA-Z /]*$/ : "",
      field: businessContactMain?.designation !== "" && businessContactMain?.designation !== undefined ? "mandatory" : "",
      shouldNotBe: ""
    },
  }



  const updatedIsValueValidAll: Record<string, boolean> = {};
  const validateForm = (): boolean => {
    const updatedFieldValidity: Record<string, boolean> = {};
    const isMandatoryfalse = true;
    const isRegexfalse = true;
    Object.keys(userValidationRules).forEach((field) => {
      const rule = userValidationRules[field];
      const value = businessContactMain && businessContactMain[field];
      const isValueValid = (rule.field === "mandatory") ? (value ? (value !== rule.shouldNotBe) : false) : true;
      const isRegexValid = rule.regex !== "" ? new RegExp(rule.regex).test(value) : true;
      updatedFieldValidity[field] = !isValueValid || !isRegexValid;
      updatedIsValueValidAll[field] = !isValueValid;
    });
    setCompanyOverviewErrors(updatedFieldValidity);
    const isFormValid = Object.keys(updatedFieldValidity).every((keys) => {
      return !updatedFieldValidity[keys]
    });
    return isFormValid;
  };

  function returnGird() {
    onboardingViewfun("grid")
    dispatch(resetFormModified(false));
  }

  const axiosPrivate = useAxiosPrivate();

  async function duplicateCheck() {
    let isDuplicate = false
    const finalData = {
      "pointOfContact": {
        "email": businessContactMain.email,
        "phoneNumber": businessContactMain.phoneNumber,
        "customerOverviewId": editData?.customerOverviewId ? editData?.customerOverviewId : ""
      }
    }
    try {
      const res = await axiosPrivate.post("companyadmin/organisation/customer/duplicate-checking", finalData)
      if (res.status === 200) {
        setIsDuplicate(true)
        isDuplicate = true
      }
    }
    catch (error: any) {
      setIsDuplicate(false)
      setShowAlertBox(true);
      setShowType("danger");
      setShowMessage(error.response.data.error);
      clearAlert('')
      isDuplicate = false

    } return isDuplicate;

  }


  function cancel() {
    setShowPage("Address")
    setIconStatus({
      ...iconStatus,
      companyIcon: "selected",
    })
  }


  const clearAlert = (status: any) => {
    const timer = setTimeout(() => {
      setShowAlertBox(false);
      setShowMessage("");

      clearTimeout(timer);
    }, 5000);
  }

  async function next() {
    var validateResult = validateForm();
    if (validateResult) {
      let isDuplicate = await duplicateCheck();
      if (isDuplicate) {
        if (isEditMode) {
          sendDataApi();
        } else {
          sendDataApi();

        }
      }
      else {

      }
    }
    else {
      setShowAlertBox(true);
      setShowType("danger");
      setShowMessage(Object.values(updatedIsValueValidAll).every((values) => !values) ?
        "Some of the field(s) are not in required format." : "Mandatory field(s) should not be left blank.")
      clearAlert('');
    }
  }



  return (
    <div className='form-wrapper'>
      <div className='form-area-address'>
        <div className='company-heading'>
          Point of Contact
        </div>

        <div className='form-main-admin'>
          <div>
            <InputComponent
              height={"40px"}
              width={"300px"}
              margin={"0px 45px 30px 0px"}
              padding={"0px 0px 0px 10px"}
              border={companyOverviewErrors.contactPersonName ? "1px solid red" : "1px solid #A9C3DC"}
              borderRadius={"0px"}
              backgroundColor={"white"}
              autoFocus
              placeHolder={"Enter Contact Person Name"}
              color={"black"}
              type={"text"}
              inputTitle={"Contact Person Name"}
              // disabled={isEditMode && mainApiData?.status === "Pending Approval" ? true : false}
              maxLength={100}
              required={true}
              inputValue={businessContactMain && businessContactMain.contactPersonName}
              getUser={(value: any) => {
                setBusinessContactMain({
                  ...businessContactMain,
                  contactPersonName: value
                })
                setIconStatus({
                  ...iconStatus,
                  pointofContact: "selected",
                })
                setIsFormDirty(true)

                dispatch(resetFormModified(true));
              }}
            />
          </div>
          <div>
            <InputComponent
              height={"40px"}
              width={"300px"}
              margin={"0px 45px 30px 0px"}
              padding={"0px 0px 0px 70px"}
              borderRadius={"0px"}
              backgroundColor={"white"}
              color={"black"}
              type={"mobile"}
              placeHolder={"Enter Phone Number"}
              maxLength={10}
              inputTitle={"Phone Number"}
              required={true}
              className={"mobilenumber-input"}
              // disabled={isEditMode && mainApiData?.status === "Pending Approval" ? true : false}

              border={companyOverviewErrors.phoneNumber ? "1px solid red" : "1px solid #A9C3DC"}
              inputValue={businessContactMain && businessContactMain.phoneNumber}
              getUser={(value: any) => {
                const val = value.replace(/[^0-9]/g, "")
                setBusinessContactMain({
                  ...businessContactMain,
                  phoneNumber: val
                })
                setIconStatus({
                  ...iconStatus,
                  pointofContact: "selected",
                })

                setIsFormDirty(true)
                dispatch(resetFormModified(true));
              }}
            />
          </div>
          <div className='mail-icon-vendor-input'>
            <InputComponent
              height={"40px"}
              width={"300px"}
              margin={"0px 0px 45px 0px"}
              padding={"0px 65px 0px 10px"}
              borderRadius={"0px"}
              backgroundColor={"white"}
              color={"black"}
              type={"text"}
              placeHolder={"Enter Email ID"}
              inputTitle={"Email ID"}
              required={true}
              maxLength={254}
              className={"gmail-input"}
              inputField={"gmail"}
              // disabled={isEditMode && mainApiData?.status === "Pending Approval" ? true : false}

              border={companyOverviewErrors.email ? "1px solid red" : "1px solid #A9C3DC"}
              inputValue={businessContactMain && businessContactMain.email}
              getUser={(value: any) => {
                setBusinessContactMain({
                  ...businessContactMain,
                  email: value
                })
                setIconStatus({
                  ...iconStatus,
                  pointofContact: "selected",
                })

                setIsFormDirty(true)
                dispatch(resetFormModified(true));
              }}
            />
          </div>

        </div>

        <div className='form-main-admin'>
          <div>
            <InputComponent
              height={"40px"}
              width={"300px"}
              margin={"0px 0px 30px 0px"}
              padding={"0px 0px 0px 10px"}
              border={companyOverviewErrors.designation ? "1px solid red" : "1px solid #A9C3DC"}
              borderRadius={"0px"}
              backgroundColor={"white"}
              placeHolder={"Enter Designation"}
              color={"black"}
              type={"text"}
              inputTitle={"Designation"}
              // disabled={isEditMode && mainApiData?.status === "Pending Approval" ? true : false}
              maxLength={100}
              required={false}
              inputValue={businessContactMain && businessContactMain.designation}
              getUser={(value: any) => {
                // const val = value.replace(/[A-Za-z][A-Za-z]$/, "")
                // const val = value.replace(/[A-Za-z0-9]/g, "")
                // const val = value.replace(/^[a-zA-Z][a-zA-Z /]*$/, "") 
                setBusinessContactMain({
                  ...businessContactMain,
                  designation: value
                })
                setIconStatus({
                  ...iconStatus,
                  pointofContact: "selected",
                })

                setIsFormDirty(true)
                dispatch(resetFormModified(true));
              }}
            />
          </div>
        </div>

        <div>
          <div className='footer-company'>
            {!isEditMode &&
              <ButtonComponent
                title={"Cancel"}
                height={"50px"}
                width={"150px"}
                margin={"0px 8px"}
                backgroundColor={"#888888"}
                color={"white"}
                className={"button-component-hover cancel"}
                handleClick={isFormModified ? () => { setShowChangesModal(true) } : returnGird}
              />
            }
            {
              isEditMode ?
                <ButtonComponent
                  title={"Cancel"}
                  height={"50px"}
                  width={"150px"}
                  margin={"0px 8px"}
                  backgroundColor={"#888888"}
                  color={"white"}
                  className={"button-component-hover cancel"}
                  handleClick={isFormModified ? () => { setShowChangesModal(true) } : returnGird} />
                :
                <ButtonComponent
                  title={"Previous"}
                  height={"50px"}
                  width={"150px"}
                  margin={"0px 8px"}
                  backgroundColor={"#888888"}
                  color={"white"}
                  className={"button-component-hover cancel"}
                  handleClick={cancel}
                />
            }
            <div >
              <ButtonComponent
                title={"Submit For Approval"}
                height={"50px"}
                width={"200px"}
                backgroundColor={"#0055D4"}
                color={"white"}
                disabled={(isDisabled) ? true : false}
                margin={"0px 8px"}
                className={(isDisabled) ?
                  "button-component-hover disabled" : "button-component-hover common-btn"}
                handleClick={next}
              />
            </div>
          </div>
        </div>
      </div>
      {
        showAlertBox &&
        <div className='alert-warp'>
          <AlertBox type={showType} message={showMessage} />
        </div>
      }
      {
        showChangesModal &&
        <Changesmodal
          closeModal={() => setShowChangesModal(false)}
          handleClose={() => {
            setShowChangesModal(false);
          }}
          leavePage={() => returnGird()} />
      }
    </div>
  )
})
export default PointOfContact
