import React, { useState } from 'react'
import ChooseCard from '../gst-approval/choose-card';


interface Props {
    gstView: (val: any) => void;
    transaction: any;
    makePaymentDatas: any;
}



function GstApprovalForm({ gstView, transaction,makePaymentDatas }: Props) {
    return (
        <div>
            <div>
                <ChooseCard
                    gstView={gstView}
                    transaction={transaction}
                    makePaymentDatas={makePaymentDatas}
                />
            </div>

        </div>
    )
}

export default GstApprovalForm