import React, { useEffect, useState, useRef, forwardRef, useImperativeHandle, } from 'react'
import InputComponent from '../../../common-component/form-elements/input-component'
import DropdownComponent from '../../../common-component/form-elements/dropdown-component'
import axios from 'axios'
import ButtonComponent from '../../../common-component/form-elements/button-component';
import BankRelationship from '../../../../config/onboarding/bank-relationship-grid';
import { v4 as uuidv4 } from 'uuid'
import useAxiosPrivate from "../../../../services/hooks/useaxios-private";
import AlertBox from '../../../common-component/alert-box';
import { useDispatch, useSelector } from "react-redux";
import { resetFormModified } from "../../../../redux/action";
import Changesmodal from '../../../common-component/modals/changes-modal';
import Loading from '../../../common-component/modals/loading-screen';
import Chart from 'chart.js/auto';

interface DropdownItem {
  label: string;
  value: string;
}
export interface ChildRef {
  next: () => void;
}
interface props {
  onboardingViewfun: (val: any) => void;
  setBankRelationshipMain: (val: any) => void;
  bankRelationshipMain: any;
  vendorBankApprovalDetails: any;
  mainApiData: any
  iconStatus: any;
  editData: any;
  setShowPage: (val: any) => void;
  setIconStatus: (val: any) => void;
  isEditMode: boolean;
  sendDataApi: () => void;
  setVendorDetails: any;
  vendorDetails: any;
  stepperStatus: any;
  setStepperStatus: any;
  saveVendorDatas: any
}

const BankDetails = forwardRef<ChildRef, props>(({ onboardingViewfun, mainApiData, vendorBankApprovalDetails, iconStatus, editData, isEditMode, setShowPage, sendDataApi, setIconStatus, setBankRelationshipMain, bankRelationshipMain, vendorDetails, setVendorDetails, stepperStatus,
  setStepperStatus }, ref) => {
  const [dropDownRegion, setDropDownRegion] = useState<DropdownItem[]>([])
  const [dropDownState, setDropDownState] = useState<DropdownItem[]>([])
  const [dropDownCities, setDropDownCities] = useState<DropdownItem[]>([])
  const [dropDownBranch, setDropDownBranch] = useState<DropdownItem[]>([])
  const [formDataEdit, setFormDataEdit] = useState(false)
  const [isDuplicate, setIsDuplicate] = useState(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [currentIndex, setCurrentIndex] = useState<number>()
  const [currentBankRelationship, setCurrentBankRelationship] = useState<number>(0)
  const axiosPrivate = useAxiosPrivate();
  //BankContact details
  const [bankRelationError, setBankRelationError] = useState<Record<string, boolean>>({})
  const [showType, setShowType] = useState("warning") // error message showType
  const [showMessage, setShowMessage] = useState<string>("") // error message showMessage
  const [showAlertBox, setShowAlertBox] = useState(false) // error message ShowAlertBox
  const [isFormDirty, setIsFormDirty] = useState(false)
  const isFormModified = useSelector((state: any) => state.isFormModified);
  const [showChangesModal, setShowChangesModal] = useState<boolean>(false);
  const dispatch = useDispatch();
  const [bankValues, setBankValues] = useState<any[]>([]);
  const [bankDetailsPercentage, setBankDetailsPercentage] = useState<any>(0);
  const [saveBtnDisabledStatus, setSaveBtnDisabledStatus] = useState<any>(true);


  function returnGird() {
    onboardingViewfun("grid")
    dispatch(resetFormModified(false));
  }

  async function duplicateCheck() {
    const finalData = {
      "bankDetails": {
        "accountNumber": vendorDetails?.bankDetails?.accountNumber,
        "upi": vendorDetails?.bankDetails?.upi,
        'razorpayAccountId': vendorDetails?.primaryKeyOfCurrentDatas
      }
    }
    let statusMessage: boolean = false;
    try {
      const res = await axiosPrivate.post("razorpay/vendor/user-creation/duplicateChecking", finalData)

      if (res.status === 200) {
        statusMessage = true;
        return true;
      }
    }
    catch (error: any) {
      setShowAlertBox(true)
      setShowType("danger")
      setShowMessage(error.response.data.error)
      clearAlert("")
      statusMessage = false;
      return false;
    }
  }

  const userValidationRules: any = {
    accountNumber: {
      regex: '',
      field: "",
      shouldNotBe: ""
    },
    ifscCode: {
      regex: vendorDetails?.bankDetails?.ifscCode !== "" ? /^[A-Z]{4}0[A-Z0-9]{6}$/ : "",
      field: "",
      shouldNotBe: ""
    },
    // bank: {
    //   regex: "",
    //   field: "mandatory",
    //   shouldNotBe: ""
    // },
    // upi: {
    //   regex: bankRelationshipMain?.upi ? /^[a-zA-Z0-9.\-_]{2,256}@[a-zA-Z.]{2,64}$/ : "",
    //   field: "",
    //   shouldNotBe: ""
    // },
    accountName: {
      regex: bankRelationshipMain?.accountName ? /^[a-zA-Z][A-Za-z ]+$/ : "",
      field: "",
      shouldNotBe: ""
    },
    // branch: {
    //   regex: bankRelationshipMain?.branch ? /^[a-zA-Z][A-Za-z. ]+$/ : "",
    //   field: "",
    //   shouldNotBe: ""
    // },


  }
  const updatedIsValueValidAll: Record<string, boolean> = {};
  const validateForm = (): boolean => {
    const updatedFieldValidity: Record<string, boolean> = {};
    Object.keys(userValidationRules).forEach((field) => {
      const rule = userValidationRules[field];
      const value = vendorDetails.bankDetails[field];
      console.log(rule, value, field, "-----------------")
      const isValueValid = (rule.field === "mandatory") ? (value ? (value !== rule.shouldNotBe) : false) : true;
      const isRegexValid = rule.regex !== "" ? new RegExp(rule.regex).test(value) : true;
      updatedFieldValidity[field] = !isValueValid || !isRegexValid;
      updatedIsValueValidAll[field] = !isValueValid;
    });
    setBankRelationError(updatedFieldValidity);
    const isFormValid = Object.keys(updatedFieldValidity).every((keys) => {
      return !updatedFieldValidity[keys]
    });
    return isFormValid;
  };

  useEffect(() => {
    fetchBankValues()
  }, [])
  const fetchBankValues = async () => {
    try {
      const response = await axiosPrivate.get("bank");
      if (response.status === 200) {
        const dropdownValues = response.data.bank.map((item: any) => ({
          label: item.bankName,
          value: item.bankName,
          id: item.bankId,
        }));
        setBankValues(dropdownValues);
        dropdownValues.sort((a: { label: string; }, b: { label: any; }) => a.label.localeCompare(b.label));
      }
    } catch (error) {
    }
  };

  async function next() {
    if (validateForm()) {
      if (await duplicateCheck()) {
        setShowPage('KYCDocuments')
      }
    } else {
      setShowAlertBox(true);
      setShowType("danger");
      setShowMessage(Object.values(updatedIsValueValidAll).every((values) => !values) ?
        "Some of the field(s) are not in required format." : "Mandatory field(s) should not be left blank.")
      clearAlert('');
    }
    // const isFormValid = validateForm()
    // if (isFormValid) {
    //   let isDuplicate = await duplicateCheck()
    //   if (isDuplicate) {

    //     if (isEditMode) {
    //       sendDataApi();
    //     } else {
    //       setShowPage("Address")
    //       setIconStatus({
    //         ...iconStatus,
    //         adminIcon: "completed",
    //         addressIcon: "selected"
    //       })
    //     }
    //   } else {

    //   }
    // } else {
    //   setShowAlertBox(true);
    //   setShowType("danger");
    //   setShowMessage(Object.values(updatedIsValueValidAll).every((values) => !values) ?
    //     "Some of the field(s) are not in required format." : "Mandatory field(s) should not be left blank.")
    //   clearAlert('');
    // }
  }
  useImperativeHandle(ref, () => {
    return {
      next: next
    }
  });


  const fetchData = async (url: string, setData: (data: any[]) => void, nestedProperty: string, id: any, representName: any) => {
    try {
      const response = await axiosPrivate.get(url);
      if (response.status === 200) {
        const dropdownValues = response.data[nestedProperty].map((item: any) => ({
          label: item[representName],
          value: item[representName],
          id: item[id],
        }));
        const sortedData = [...dropdownValues].sort((a, b) => a.label.localeCompare(b.label));
        setData([{ label: "Select", value: "Select", id: "" }, ...sortedData]);

      }
    } catch (error) {
    }
  };

  // const handleAdd = () => {
  //   let isValidAdd = validateForm()
  //   if (isValidAdd) {
  //     duplicateCheck();
  //     if (isDuplicate) {
  //       if (formDataEdit) {
  //         let data: any = []
  //         bankRelationshipMain.length > 0 && bankRelationshipMain.map((ele: any, i: number) => {
  //           if (i === currentIndex) {
  //             data.push(bankRelationshipMain)
  //           } else {
  //             data.push(ele)
  //           }
  //         })
  //         setBankRelationshipMain(data)
  //         setBankRelationshipMain(
  //           {
  //             accountNumber: "",
  //             ifscCode: "",
  //             bank: "",
  //             accountName: "",
  //             branch: "",
  //             Region: "",
  //             State: "",
  //             City: "",
  //             index: 0
  //           }
  //         )
  //         setIsFormDirty(false);
  //         setFormDataEdit(false)
  //       }
  //       else {
  //         let newrecord = bankRelationshipMain
  //         newrecord.push({ ...bankRelationshipMain })
  //         setBankRelationshipMain(newrecord)
  //         setBankRelationshipMain(
  //           {
  //             accountNumber: "",
  //             ifscCode: "",
  //             bank: "",
  //             accountName: "",
  //             branch: "",
  //             Region: "",
  //             State: "",
  //             City: "",
  //             index: 0
  //           }
  //         )
  //       }
  //       setFormDataEdit(false)
  //       setIsFormDirty(false);
  //     } else {

  //     }
  //   } else {
  //     setShowAlertBox(true);
  //     setShowType("danger")
  //     setShowMessage(Object.values(updatedIsValueValidAll).every((values) => !values) ?
  //       "Some of the field(s) are not in required format." : "Mandatory field(s) should not be left blank.")
  //     clearAlert('');
  //   }
  // }

  const clearAlert = (status: any) => {
    const timer = setTimeout(() => {
      setShowAlertBox(false);
      setShowMessage("");
      if (status === 200) {
        onboardingViewfun("form")
      }
      clearTimeout(timer);
    }, 5000);
  }
  function cancel() {
    setShowPage("VendorOverview")
    setIconStatus({ ...iconStatus, companyIcon: "selected", })
  }
  // percentage chart
  const chartRef: any = useRef(null);
  useEffect(() => {
    const data = {
      value: bankDetailsPercentage,
      max: 100,
      label: "Progress"
    };

    const config: any = {
      type: 'doughnut',
      data: {
        datasets: [{
          data: [data.value, data.max - data.value],
          backgroundColor: [bankDetailsPercentage == 100 ? "green" : '#D2A100', '#0055D4'],
          borderWidth: 0
        }]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        cutoutPercentage: 85,
        rotation: -90,
        circumference: 180,
        tooltips: {
          enabled: false
        },
        legend: {
          display: false
        },
        animation: {
          animateRotate: true,
          animateScale: false
        },
        title: {
          display: true,
          text: data.label,
          fontSize: 16
        }
      }
    };

    if (chartRef.current) {
      const chartCtx = chartRef.current.getContext('2d');
      const gaugeChart = new Chart(chartCtx, config);

      // Cleanup on unmount
      return () => {
        gaugeChart.destroy();
      };
    }
  }, [bankDetailsPercentage]);
  // percentage chart

  useEffect(() => {
    let count = 0;
    let mandatoryList = ['accountNumber', 'ifscCode', 'accountName']
    Object.keys(vendorDetails?.bankDetails)?.forEach((item: any) => {
      let dropdownValueStatus = typeof (vendorDetails?.bankDetails[item]) == 'object' ? vendorDetails?.bankDetails[item].label !== "Select" : true;
      if (vendorDetails?.bankDetails[item] !== "" && mandatoryList.includes(item) && dropdownValueStatus) {
        setStepperStatus({ ...stepperStatus, bankDetails: "working" })
        count++;
      }
    });
    let percentage: any = (count / 3) * 100
    if (percentage.toFixed(0) == 100) {
      setStepperStatus({ ...stepperStatus, bankDetails: "completed" })
    }
    setBankDetailsPercentage(percentage.toFixed(0))
  }, [vendorDetails?.bankDetails])

  async function saveVendorDatas() {
    try {
      setIsLoading(true)
      // pending percentage and list -- start
      let mandatoryListsAccountCreation = ['basicDetails', 'vendorOverView', 'pointOfContact', 'address']
      let mandatoryListsDocs = ['razorpayBankDetailsStatus', 'razorpayBusinessDocStatus', 'razorpayStakeholderDocStatus', 'razorpayStakeholderStatus']
      let mandatoryField: any = {
        basicDetails: 'emailId',
        vendorOverView: 'companyPAN',
        address: 'contact_street_1',
        pointOfContact: 'contactPersonName',
      }
      let final_percentage = 0;
      let final_list = [];
      for (const data of Object.keys(vendorDetails)) {
        if (mandatoryListsAccountCreation.includes(data)) {
          if (vendorDetails[data][mandatoryField[data]]) {
            final_percentage += 11.11
          } else {
            final_list.push(data)
          }
        }
      }
      if (vendorDetails?.statutoryGridDetails.length > 0) {
        final_percentage += 11.11
      } else {
        final_list.push('statutoryDetails')
      }
      for (const data of Object.keys(vendorDetails.basicDetails)) {
        if (mandatoryListsDocs.includes(data)) {
          if (vendorDetails.basicDetails[data] || data == 'razorpayBankDetailsStatus') {
            final_percentage += 11.11
          } else {
            final_list.push(data)
          }
        }
      }
      // pending percentage and list -- end
      const response = await axiosPrivate.post(`vendor/razorpay/create/bank-details/${vendorDetails.primaryKeyOfCurrentDatas}`, {
        basicDetails: vendorDetails.basicDetails,
        bankDetails: vendorDetails.bankDetails,
        overAllPercentage: final_percentage.toFixed(0),
        pendingList: final_list,
      });

      if (response.status == 200) {
        setVendorDetails({
          ...vendorDetails,
          basicDetails: {
            ...vendorDetails.basicDetails,
            razorpayBankDetailsStatus: true,
          },
        })
        setIsLoading(false)
        setShowAlertBox(true);
        setShowType("success");
        setShowMessage(`Record submitted successfully.`)
        clearAlert('');
      }
    } catch (error: any) {
      setIsLoading(false)
      setShowAlertBox(true);
      setShowType("danger");
      setShowMessage(error?.response?.data?.error);
      clearAlert('');
    }
  }
  useEffect(() => {
    setShowAlertBox(true);
    setShowType("danger");
    setShowMessage("Complete KYC documents and Stakeholder Info before submitting bank details")
    setTimeout(() => {
      setShowAlertBox(false);
      setShowMessage("");
    }, 8000)
  }, [])
  return (
    <>
      <div style={{ background: "#F8F8F8", height: "110px", width: "190px", position: "absolute", right: "0px", top: "0px", display: "flex", borderRadius: "0px 10px" }}>
        <div style={{ position: 'relative', height: '95px', width: '135px', left: "30px", top: "0px" }}>
          <canvas ref={chartRef}></canvas>
          <div style={{ width: "40px", position: "absolute", left: "50px", top: "58px", textAlign: "center" }}><strong style={{ fontSize: "16px" }}>{bankDetailsPercentage}%</strong></div>
          <div style={{ position: "absolute", left: "10px", fontSize: "16px", bottom: "-12px" }}>0%</div>
          <div style={{ position: "absolute", right: "-4px", fontSize: "16px", bottom: "-11px" }}>100%</div>
        </div>
      </div>
      <div className='form-wrapper' >
        <div className='form-area' >
          <div
            style={isEditMode && (vendorBankApprovalDetails?.status === "Approved") ?
              { pointerEvents: "none", opacity: 0.5 } : { pointerEvents: "auto", opacity: 1 }}
          >
            <div className='company-heading'>
              Bank Details
            </div>
            <div className='form-main-wrapper' style={vendorDetails?.basicDetails?.razorpayBankDetailsStatus ? { opacity: "0.5", pointerEvents: "none" } : {}}>
              <div key={uuidv4()} style={{ marginRight: "45px" }} className={bankRelationError.bank ? 'setErrorcompany' : "dropdown-margin dropdown-width"}>
                <DropdownComponent
                  width={"300px"}
                  title={"Bank"}
                  required={false}
                  isDisabled={isEditMode && (vendorBankApprovalDetails?.status === "Approved") ? true : false}
                  defaultValue={vendorDetails?.bankDetails?.bank?.label !== "" && vendorDetails?.bankDetails?.bank?.label !== null ? [vendorDetails?.bankDetails?.bank] : [{ label: "Select", value: "Select" }]}
                  options={bankValues}
                  getData={(value) => {
                    setSaveBtnDisabledStatus(false)
                    setVendorDetails({
                      ...vendorDetails,
                      bankDetails: {
                        ...vendorDetails.bankDetails,
                        bank: value,
                      }
                    })
                    // setIconStatus({
                    //   ...iconStatus,
                    //   adminIcon: "selected"
                    // })
                    setIsFormDirty(true)
                    dispatch(resetFormModified(true));
                  }}
                />
              </div>
              <div style={{ marginRight: "45px" }}>
                <InputComponent
                  height={"40px"}
                  width={"300px"}
                  margin={"0px 0px 30px 0px"}
                  padding={"0px 0px 0px 10px"}
                  border={bankRelationError.accountNumber ? "1px solid red" : "1px solid #A9C3DC"}
                  borderRadius={"0px"}
                  backgroundColor={"white"}
                  disabled={isEditMode && (vendorBankApprovalDetails?.status === "Approved") ? true : false}
                  placeHolder={'Enter Account Number'}
                  color={"black"}
                  type={"text"}
                  inputTitle={"Account Number"}
                  maxLength={20}
                  required={true}
                  inputValue={vendorDetails?.bankDetails?.accountNumber}
                  getUser={(value: any) => {
                    setSaveBtnDisabledStatus(false)
                    const numbersOnly = value.replace(/\D/g, "")
                    setVendorDetails({
                      ...vendorDetails,
                      bankDetails: {
                        ...vendorDetails.bankDetails,
                        accountNumber: numbersOnly,
                      }
                    })
                    // setIconStatus({
                    //   ...iconStatus,
                    //   adminIcon: "selected"
                    // })
                    setIsFormDirty(true)
                    dispatch(resetFormModified(true));
                  }}
                />
              </div>
              <div>
                <InputComponent
                  height={"40px"}
                  width={"300px"}
                  margin={"0px 0px 30px 0px"}
                  padding={"0px 0px 0px 10px"}
                  border={bankRelationError.ifscCode ? "1px solid red" : "1px solid #A9C3DC"}
                  borderRadius={"0px"}
                  backgroundColor={"white"}
                  placeHolder={'Enter IFSC Code '}
                  disabled={isEditMode && (vendorBankApprovalDetails?.status === "Approved") ? true : false}
                  color={"black"}
                  type={"text"}
                  required={true}
                  inputTitle={"IFSC Code"}
                  maxLength={11}
                  inputValue={vendorDetails?.bankDetails?.ifscCode}
                  getUser={(value: any) => {
                    setSaveBtnDisabledStatus(false)
                    const ifsc = value.toUpperCase().replace(/[^A-Z0-9]/g, "")
                    setVendorDetails({
                      ...vendorDetails,
                      bankDetails: {
                        ...vendorDetails.bankDetails,
                        ifscCode: ifsc,
                      }
                    })
                    setIsFormDirty(true)
                    dispatch(resetFormModified(true));
                  }}
                />
              </div>

            </div>
            <div className='form-main-wrapper' style={vendorDetails?.basicDetails?.razorpayBankDetailsStatus ? { opacity: "0.5", pointerEvents: "none" } : {}}>

              <div style={{ marginRight: "45px" }}>
                <InputComponent
                  height={"40px"}
                  width={"300px"}
                  margin={"0px 0px 30px 0px"}
                  padding={"0px 0px 0px 10px"}
                  borderRadius={"0px"}
                  backgroundColor={"white"}
                  disabled={isEditMode && (vendorBankApprovalDetails?.status === "Approved") ? true : false}
                  color={"black"}
                  required={true}
                  type={"mobile"}
                  maxLength={100}
                  inputTitle={"Account Name"}
                  placeHolder={"Enter Account Name"}
                  inputValue={vendorDetails?.bankDetails?.accountName}
                  // disabled={admin_details[i].isActive !== "No"}
                  border={bankRelationError.accountName ? "1px solid red" : "1px solid #A9C3DC"}
                  getUser={(value: any) => {
                    setSaveBtnDisabledStatus(false)
                    const onlyAlphabet = value.replace(/[^A-Za-z0-9 ]+/g, '')
                    const removeInitialSpaceAndAlphabetsOnly = value.replace(/^\s+/, "").replace(/[^a-zA-Z\s]/g, "");

                    setVendorDetails({
                      ...vendorDetails,
                      bankDetails: {
                        ...vendorDetails.bankDetails,
                        accountName: removeInitialSpaceAndAlphabetsOnly,
                      }
                    })
                    setIsFormDirty(true)
                    dispatch(resetFormModified(true));

                  }}
                />
              </div>
              <div style={{ marginRight: "45px" }}>
                <InputComponent
                  height={"40px"}
                  width={"300px"}
                  margin={"0px 0px 30px 0px"}
                  padding={"0px 0px 0px 10px"}
                  borderRadius={"0px"}
                  backgroundColor={"white"}
                  color={"black"}
                  required={false}
                  disabled={isEditMode && (vendorBankApprovalDetails?.status === "Approved") ? true : false}
                  type={"mobile"}
                  maxLength={100}
                  inputTitle={"Branch"}
                  placeHolder={"Enter Branch"}
                  inputValue={vendorDetails?.bankDetails?.branch}
                  // disabled={admin_details[i].isActive !== "No"}
                  border={bankRelationError.branch ? "1px solid red" : "1px solid #A9C3DC"}
                  getUser={(value: any) => {
                    setSaveBtnDisabledStatus(false)
                    const removeInitialSpaceAndAlphabetsOnly = value.replace(/^\s+/, "").replace(/[^a-zA-Z\s]/g, "");
                    setVendorDetails({
                      ...vendorDetails,
                      bankDetails: {
                        ...vendorDetails.bankDetails,
                        branch: removeInitialSpaceAndAlphabetsOnly,
                      }
                    })
                    setIsFormDirty(true)
                    dispatch(resetFormModified(true));

                  }}
                />
              </div>
              <div>
                <InputComponent
                  height={"40px"}
                  width={"300px"}
                  margin={"0px 0px 30px 0px"}
                  padding={"0px 0px 0px 10px"}
                  borderRadius={"0px"}
                  backgroundColor={"white"}
                  color={"black"}
                  required={false}
                  disabled={isEditMode && (vendorBankApprovalDetails?.status === "Approved") ? true : false}
                  type={"mobile"}
                  maxLength={100}
                  inputTitle={"UPI"}
                  placeHolder={"Enter UPI"}
                  inputValue={vendorDetails?.bankDetails?.upi}
                  // disabled={admin_details[i].isActive !== "No"}
                  border={bankRelationError.upi ? "1px solid red" : "1px solid #A9C3DC"}
                  getUser={(value: any) => {
                    setSaveBtnDisabledStatus(false)
                    const removeInitialSpace = value.replace(/^\s+/, "")

                    setVendorDetails({
                      ...vendorDetails,
                      bankDetails: {
                        ...vendorDetails.bankDetails,
                        upi: removeInitialSpace,
                      }
                    })
                    setIsFormDirty(true)
                    dispatch(resetFormModified(true));

                  }}
                />
              </div>
            </div>
          </div>
          <div>
            <div className='footer-company'>
              <ButtonComponent
                title={"Cancel"}
                height={"50px"}
                width={"150px"}
                margin={"0px 8px"}
                backgroundColor={"#888888"}
                color={"white"}
                className={"button-component-hover cancel"}
                handleClick={isFormModified ? () => { setShowChangesModal(true) } : returnGird}
              />
              <ButtonComponent
                title={"Previous"}
                height={"50px"}
                width={"150px"}
                disabled={false}
                backgroundColor={"#0055D4"}
                color={"white"}
                margin={"0px 8px"}
                className={"button-component-hover common-btn"}
                handleClick={() => { setShowPage('StakeholderInfo') }}
              />
              <ButtonComponent
                // title={isEditMode ? "Update" : "Save"}
                title={"Submit"}
                height={"50px"}
                width={"150px"}
                disabled={saveBtnDisabledStatus}
                backgroundColor={"#0055D4"}
                color={"white"}
                margin={"0px 8px"}
                className={saveBtnDisabledStatus ? "button-component disabled" : "button-component-hover common-btn"}
                // className={saveBtnDisabledStatus ? "button-component disabled" : (vendorDetails?.basicDetails?.razorpayBusinessDocStatus && vendorDetails?.basicDetails?.razorpayStakeholderDocStatus && vendorDetails?.basicDetails?.razorpayStakeholderStatus) ? "button-component-hover common-btn" : "button-component disabled"}
                handleClick={async () => {
                  const isFormValid = validateForm();
                  if (isFormValid) {
                    if (await duplicateCheck()) {
                      setIsFormDirty(false);
                      saveVendorDatas()
                      setSaveBtnDisabledStatus(true)
                    }
                  } else {
                    setShowAlertBox(true);
                    setShowType("danger");
                    setShowMessage(Object.values(updatedIsValueValidAll).every((values) => !values) ?
                      "Some of the field(s) are not in required format." : "Mandatory field(s) should not be left blank.")
                    clearAlert('');
                  }
                }}
              />
              {/* <ButtonComponent
                title={"Next"}
                height={"50px"}
                width={"150px"}
                disabled={showMessage && showType === "success" ? true : false}
                backgroundColor={"#0055D4"}
                color={"white"}
                margin={"0px 8px"}
                className={
                  showMessage && showType === "success" ?
                    "button-component-hover disabled" : "button-component-hover common-btn"}
                handleClick={next}
              /> */}
            </div>
          </div>
        </div>
        {
          isLoading &&
          <Loading />
        }
        {
          showAlertBox &&
          <div className='alert-warp'>
            <AlertBox type={showType} message={showMessage} />
          </div>

        }
        {
          showChangesModal &&
          <Changesmodal
            closeModal={() => setShowChangesModal(false)}
            handleClose={() => {
              setShowChangesModal(false);
            }}
            leavePage={() => returnGird()} />
        }

      </div >
    </>
  )
})
export default BankDetails
