export const VendorReportFilter: any = {
    "Vendor": [
        {
            cardTitle: "Vendor Code",
            cardType: "dropdown",
            selectedValue: "All",
            options: [
                { value: "All", label: "All" },
            ],
            isOpen: false
        },
        {
            cardTitle: "Name",
            cardType: "dropdown",
            selectedValue: "All",
            options: [
                { value: "All", label: "All" },
            ],
            isOpen: false
        },
        {
            cardTitle: "IsActive",
            cardType: "dropdown",
            selectedValue: "All",
            options: [
                { value: "All", label: "All" },
            ],
            isOpen: false
        },
        {
            cardTitle: "Is Approved",
            cardType: "dropdown",
            selectedValue: "All",
            options: [
                { value: "All", label: "All" },
            ],
            isOpen: false
        },
        {
            cardTitle: "Bank Approval Status",
            cardType: "dropdown",
            selectedValue: "All",
            options: [
                { value: "All", label: "All" },
            ],
            isOpen: false
        },
        {
            cardTitle: "Company Constitution",
            cardType: "dropdown",
            selectedValue: "All",
            options: [
                { value: "All", label: "All" },
            ],
            isOpen: false
        },
        {
            cardTitle: "Buisness Category",
            cardType: "dropdown",
            selectedValue: "All",
            options: [
                { value: "All", label: "All" },
            ],
            isOpen: false
        },
        {
            cardTitle: "GSTIN",
            cardType: "dropdown",
            selectedValue: "All",
            options: [
                { value: "All", label: "All" },
            ],
            isOpen: false
        },
        {
            cardTitle: "Bank",
            cardType: "dropdown",
            selectedValue: "All",
            options: [
                { value: "All", label: "All" },
            ],
            isOpen: false
        },
        {
            cardTitle: "Branch",
            cardType: "dropdown",
            selectedValue: "All",
            options: [
                { value: "All", label: "All" },
            ],
            isOpen: false
        },
        {
            cardTitle: "Account Number",
            cardType: "dropdown",
            selectedValue: "All",
            options: [
                { value: "All", label: "All" },
            ],
            isOpen: false
        },
        {
            cardTitle: "IFSC Code",
            cardType: "dropdown",
            selectedValue: "All",
            options: [
                { value: "All", label: "All" },
            ],
            isOpen: false
        },
        {
            cardTitle: "UPI",
            cardType: "dropdown",
            selectedValue: "All",
            options: [
                { value: "All", label: "All" },
            ],
            isOpen: false
        },
        {
            cardTitle: "Contact Person Name",
            cardType: "dropdown",
            selectedValue: "All",
            options: [
                { value: "All", label: "All" },
            ],
            isOpen: false
        },
        {
            cardTitle: "Phone Number",
            cardType: "dropdown",
            selectedValue: "All",
            options: [
                { value: "All", label: "All" },
            ],
            isOpen: false
        },
        {
            cardTitle: "Email",
            cardType: "dropdown",
            selectedValue: "All",
            options: [
                { value: "All", label: "All" },
            ],
            isOpen: false
        }
    ],
}


