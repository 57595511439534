import React, { useEffect, useRef, useState, } from 'react';
import '../../../styles/modals/department-add-modal.scss';
import { RxCross1 } from 'react-icons/rx';
import ButtonComponent from '../form-elements/button-component';
import IconButton from '../form-elements/icon-button';
import InputComponent from '../form-elements/input-component';
import DeleteModal from './delete-modal';
import AlertBox from '../alert-box';
import useAxiosPrivate from '../../../services/hooks/useaxios-private';

interface Styles {
    clearValue: (val: any) => void;
    setAccountIntegrationValues: (val: any) => void;
    getAccountIntegrationDetails: () => void;
    accountIntegrationValues: any[];
}

export default function AddAccountIntegration({
    clearValue,
    accountIntegrationValues,
    getAccountIntegrationDetails
}: Styles) {

    const [cardCategoryName, setCardCategoryName] = useState<string>("")
    const [editIndex, setEditIndex] = useState<number>()
    const [deleteIndex, setDeleteIndex] = useState<number>()
    const [isDepartmentEditMode, setIsDepartmentEditMode] = useState<boolean>(false)
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
    const [showType, setShowType] = useState("warning") 
    const [showMessage, setShowMessage] = useState<string>("") 
    const [showAlertBox, setShowAlertBox] = useState(false)
    const axiosPrivate = useAxiosPrivate();

    // Function for Save Button
    const saveAccountIntegration = async () => {
        if (cardCategoryName !== "" && new RegExp(/^[a-zA-Z][a-zA-Z /]*$/).test(cardCategoryName)) {
            try {
                const response = isDepartmentEditMode ?
                    await axiosPrivate.post(`/customeronboarding/accountsintegration/add`,
                        {
                            id: editIndex,
                            value: cardCategoryName,
                        }) : await axiosPrivate.post(`/customeronboarding/accountsintegration/add`,
                            {
                                id: "",
                                value: cardCategoryName,
                            })

                if (response.status === 200) {
                    setIsDepartmentEditMode(false)
                    setCardCategoryName("")
                    setShowAlertBox(true);
                    setShowType("success");
                    setShowMessage(response.data.message)
                    clearAleart();
                    getAccountIntegrationDetails();
                }
            } catch (error: any) {
                setShowAlertBox(true);
                setShowType("danger");
                setShowMessage(error.response.data.error)
                clearAleart();
            }
        } else {
            setShowAlertBox(true);
            setShowType("danger");
            setShowMessage(cardCategoryName === "" ?
                "Mandatory field(s) should not be left blank" :
                "Some of the field(s) are not in required format")
            clearAleart();
        }
    }

    // Function for clear the alert box 
    const clearAleart = () => {
        const timer = setTimeout(() => {
            setShowAlertBox(false);
            setShowMessage("");
            clearTimeout(timer);
        }, 5000);
    }

    // Function for edit icon
    const editDepartment = async (value: string, index: number) => {
        setEditIndex(index);
        setCardCategoryName(value);
        setIsDepartmentEditMode(true);
    };

    // Function for delete button
    const deletDepartment = async () => {
        try {
            const response = await axiosPrivate.delete(`/customeronboarding/accountsintegration/delete/${deleteIndex}`);
            if (response.status === 200) {
                setShowDeleteModal(false);
                // getDepartment && getDepartment(); 
                setShowAlertBox(true);
                setShowType("success");
                setShowMessage(response.data.message)
                clearAleart();
                setShowDeleteModal(false);
                getAccountIntegrationDetails();
                setIsDepartmentEditMode(false)
                setCardCategoryName("")
            }
        } catch (error: any) {
            setShowAlertBox(true);
            setShowType("danger");
            setShowMessage(error.response.data.error)
            clearAleart();
            setShowDeleteModal(false);
        }
    }

    // Function for local delete
    const ConfremationDelete = async (value: string, index: number) => {
        setDeleteIndex(index);
        setShowDeleteModal(true);
    };

    return (
        <div className={"department-add-modal"}>
            <div className={"container-add-modal"}>
                <div className={"close-icon"}>
                    <span className={"cross-icon"}>
                        <RxCross1 onClick={() => clearValue(false)
                        } style={{ fontSize: "40px" }} />
                    </span>
                </div>
                <div className='sub-container'>
                    <div className='department'>
                        <InputComponent
                            height={"40px"}
                            width={"350px"}
                            margin={"0px 10px 30px 0px"}
                            padding={"0px 0px 0px 10px"}
                            border={"1px solid #A9C3DC"}
                            borderRadius={"0px"}
                            backgroundColor={"white"}
                            color={"black"}
                            type={"text"}

                            maxLength={100}
                            inputTitle={"Account's Integration"}
                            placeHolder={"Enter Account's Integration"}
                            required={true}
                            inputValue={cardCategoryName}
                            getUser={(value: any) => {
                                const finalvalue = value.replace(/[^a-zA-Z ]/g, "")
                                setCardCategoryName(finalvalue)
                            }}
                        />

                        <ButtonComponent
                            title={isDepartmentEditMode ? "Update" : "Save"}
                            height={"40px"}
                            width={"130px"}
                            backgroundColor={"#0055D4"}
                            color={"white"}
                            margin={"0px 8px"}
                            disabled={cardCategoryName === ""}
                            className={cardCategoryName === "" ? "button-component disabled" : "button-component common-btn"}
                            handleClick={() => {
                                saveAccountIntegration();
                            }}
                        />
                    </div>

                    <div className='view-table-component' style={{ marginTop: "20px", maxHeight: '300px', overflowY: 'auto' }}>
                        <table>
                            <thead>
                                <th className='privilege'>Account's Integration</th>
                                <th className='access' style={{ paddingLeft: "27%", textAlign: "center" }}>Action</th>
                            </thead>
                            <tbody>
                                {
                                    accountIntegrationValues?.length === 0 ?
                                        <tr className='main-body-header'>
                                            <td style={{ position: "relative", left: "310px" }}>No records to show</td>
                                            <td></td>
                                        </tr> : accountIntegrationValues.map((data, index) => (
                                            <tr className='main-body-header' key={index} style={{ justifyContent: "space-between", padding: "" }}>
                                                <td className='first-name' style={{ padding: "5px", paddingLeft: "30px" }}>{data.value}</td>
                                                <td className='last-icon' style={{ display: "flex", justifyContent: "flex-end", padding: "5px" }}>
                                                    <IconButton
                                                        iconName={"Edit"}
                                                        height={"40px"}
                                                        width={"40px"}
                                                        fontSize={"30px"}
                                                        margin={"0px 8px"}
                                                        color={"white"}
                                                        backgroundColor={"#0055D4"}
                                                        hover={true}
                                                        handleClick={() => {
                                                            editDepartment(data.value, data.id);
                                                        }}
                                                    />
                                                    <IconButton
                                                        iconName={"Delete"}
                                                        height={"40px"}
                                                        width={"40px"}
                                                        fontSize={"25px"}
                                                        margin={"0px 8px"}
                                                        color={"white"}
                                                        backgroundColor={"#D60000"}
                                                        hover={true}
                                                        handleClick={() => {
                                                            ConfremationDelete(data.value, data.id);
                                                        }}
                                                    />
                                                </td>
                                            </tr>
                                        ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            {
                showDeleteModal &&
                <div>
                    <DeleteModal
                        getDelete={() => { deletDepartment() }}
                        clearValue={(value) => { setShowDeleteModal(value) }}
                        modelType={"grid-delete"}
                    />
                </div>
            }
            {
                showAlertBox &&
                <div className='alert-warp'>
                    <AlertBox type={showType} message={showMessage} />
                </div>
            }
        </div>
    )
}



