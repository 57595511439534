import React, { useEffect, useState } from 'react'
import DropdownComponent from '../../common-component/form-elements/dropdown-component';
import { v4 as uuidv4 } from 'uuid';
import ButtonComponent from '../../common-component/form-elements/button-component';
import Card from '../../common-component/cards/card';
import CardPanel from '../../common-component/cards/card-panel';
import { scales } from 'chart.js';
import { axiosPrivate } from '../../../middleware/axois-api';
import { cardDetails } from './vendor-form';
import { useDispatch } from 'react-redux';
import { resetFormModified } from '../../../redux/action';
import dummyCard from '../../../assets/images/dummy-card.png';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { BsPlusLg } from 'react-icons/bs';
import UpiAdd from '../../common-component/modals/upi-add-modal';
import PaymentMethod from '../../common-component/payment-method/payment-method';


interface paymentProps {
  selectedPaymentMethod: any
  SetSelectedPaymentMethod: (val: any) => void
  paymentNext: () => void;
  paymentBack: () => void;
  cards: cardDetails[];
  Banks: any[];
  getUpi: () => void;
  Upis: any[];
}

function SelectPaymentMethod({ cards, Banks, getUpi, Upis, selectedPaymentMethod, SetSelectedPaymentMethod, paymentNext, paymentBack }: paymentProps) {

  const dispatch = useDispatch();
  const PaymentMethodDD = [{ label: "Corporate Card", value: "Corporate Card", id: "1" },
  { label: "Net Banking", value: "Net Banking", id: "2" },
  { label: "UPI", value: "UPI", id: "2" },
  ]
  // { label: "Virtual Card", value: "Virtual Card", id: "3" },
  const [openUpiModal, setOpenUpiModal] = useState<boolean>(false);



  const handleSelectedCard = (card: any, index: number) => {
    SetSelectedPaymentMethod((selectedPaymentMethod: any) => ({
      ...selectedPaymentMethod,
      cardId: card.cardId,
      bankName: "",
      bankCode: "",
      UpiCode: "",
    }));
  }

  const handlePaymentMethodSwitch = (value: string) => {
    SetSelectedPaymentMethod((selectedPaymentMethod: any) => ({
      ...selectedPaymentMethod,
      PaymentMethod: value,
      cardId: "",
      bankName: "",
      bankCode: "",
      UpiCode: ""
    }));
  }



  return (
    <div className={"select-payment-method"}>
      <div style={{
        display: "flex", justifyContent: "center", position: "relative", zIndex: 3
      }}>
        <PaymentMethod
          value={selectedPaymentMethod.PaymentMethod}
          handleSelect={handlePaymentMethodSwitch}
        />
        <div>

        </div>
      </div>
      {
        selectedPaymentMethod.PaymentMethod === "Corporate Card" &&
        <>
          <div>
            <p className={"select-card"}>{"Select Card"}</p>
          </div>
          <div className={"Card-main-wrapper"}>
            <div className={"Card-sub-wrapper"}>
              {
                cards.length === 0 ?
                  <div className='select-payment-dummy-card-container'>
                    <img src={dummyCard} className='select-payment-dummy-card-image' />
                    <div className='select-payment-dummy-card-content'>{"You have no cards added"}</div>
                  </div>
                  : cards.map((card: any, index: number) => (
                    <div onClick={() => handleSelectedCard(card, index)}
                      className={selectedPaymentMethod.cardId === card.cardId ? "activeCardId" : "inActiveCard"}
                    >
                      <CardPanel
                        key={index} // Make sure to provide a unique key for each element in the array
                        bankName={card.bankName}
                        percentage={card.percentage}
                        limit={card.limit}
                        insideCardBalance={card.insideCardBalance}
                        lastBilledDue={card.lastBilledDue}
                        cardNumber={card.cardNumber}
                        cardNetwork={card.cardNetwork}
                        dueIn={card.dueIn}
                        billingCycle={card.billingCycle}
                        processing={card.processing}
                        outsideCardBalance={card.outsideCardBalance}
                        backgroundColor={card.backgroundColor}
                      />
                    </div>
                  ))}
            </div>
          </div>
        </>
      }
      {
        selectedPaymentMethod.PaymentMethod === "Virtual Card" &&
        <>
          <div>
            <p className={"select-card"}>{"Select Card"}</p>
          </div>
          {/* <div className={"Card-main-wrapper"}>
            <div className={"Card-sub-wrapper"}> */}
          {/* <Slider {...settings}> */}
          {
            cards.length === 0 ?
              <div className='select-payment-dummy-card-container'>
                <img src={dummyCard} className='select-payment-dummy-card-image' />
                <div className='select-payment-dummy-card-content'>{"You have no cards added"}</div>
              </div>
              :
              cards.map((card: any, index: number) => (
                <div onClick={() => handleSelectedCard(card, index)}
                  className={selectedPaymentMethod.cardId === card.cardId ? "activeCardId" : "inActiveCard"}
                >
                  <CardPanel
                    key={index} // Make sure to provide a unique key for each element in the array
                    bankName={card.bankName}
                    percentage={card.percentage}
                    limit={card.limit}
                    insideCardBalance={card.insideCardBalance}
                    lastBilledDue={card.lastBilledDue}
                    cardNumber={card.cardNumber}
                    cardNetwork={card.cardNetwork}
                    dueIn={card.dueIn}
                    billingCycle={card.billingCycle}
                    processing={card.processing}
                    outsideCardBalance={card.outsideCardBalance}
                    backgroundColor={card.backgroundColor}
                  />
                </div>
              ))

          }
        </>

      }
      {
        selectedPaymentMethod.PaymentMethod === "UPI" &&
        <>
          <>
            <div style={{ height: "300px" }}>
              <div style={{
                display: "flex", justifyContent: "center", marginTop: "70px", position: "relative"
              }}>
                {/* <div className={false ? 'setError' : 'dropdown-vendor-pay-plus'} */}
                <div className='fetch-group' style={{ marginRight: "0px" }}>
                  <div
                    key={uuidv4()}
                    className={false ? 'setError-with-plus' : 'dropdown-user-with-plus'}
                    style={{
                      //  position: "relative", zIndex: 5 
                      width: "300px"
                    }}
                  >
                    {/* style={false ? { pointerEvents: "none", opacity: "0.5", cursor: "auto" } : {}}> */}
                    <DropdownComponent key={uuidv4()}
                      options={Upis}
                      title={"Select UPI"}
                      // isDisabled={true}
                      required={true}
                      width={'350px'}
                      getData={(value: any) => {
                        SetSelectedPaymentMethod((selectedPaymentMethod: any) => ({
                          ...selectedPaymentMethod,
                          cardId: "",
                          bankName: "",
                          bankCode: "",
                          UpiCode: value.value,
                          UpiId: value.id,
                        }));
                        dispatch(resetFormModified(true));
                      }}
                      defaultValue={[{ label: selectedPaymentMethod.UpiCode === "" ? "Select" : selectedPaymentMethod.UpiCode, value: selectedPaymentMethod.UpiCode }]}
                    />
                  </div>
                  <div className='with-plus' style={{ marginTop: "35px", marginRight: "0px", marginBottom: "0px" }} onClick={() => {
                    setOpenUpiModal(true)
                  }}>
                    <BsPlusLg color='#004E9C' height={"14px"} />
                  </div>
                </div>
              </div>
            </div>
          </>
        </>

      }
      {
        selectedPaymentMethod.PaymentMethod === "Net Banking" &&
        <>
          <div style={{ height: "300px" }}>
            <div style={{
              display: "flex", justifyContent: "center", marginTop: "70px", position: "relative"
            }}>
              <div className={false ? 'setError' : 'dropdown-vendor-pay-plus'}
                style={false ? { pointerEvents: "none", opacity: "0.5", cursor: "auto" } : {}}>
                <DropdownComponent key={uuidv4()}
                  options={Banks}
                  title={"Select Bank"}
                  // isDisabled={true}
                  required={true}
                  width={'350px'}
                  getData={(value: any) => {
                    SetSelectedPaymentMethod((selectedPaymentMethod: any) => ({
                      ...selectedPaymentMethod,
                      bankName: value.value,
                      bankCode: value.id,
                      cardId: "",
                      UpiCode: ""
                    }));
                    dispatch(resetFormModified(true));
                  }}
                  defaultValue={[{ label: selectedPaymentMethod.bankName === "" ? "Select" : selectedPaymentMethod.bankName, value: selectedPaymentMethod.bankName }]}
                />
              </div>
            </div>
          </div>
        </>
      }
      <div>
        <div className='footer-vendor-pb' >
          <ButtonComponent
            title={"Back"}
            height={"50px"}
            width={"150px"}
            backgroundColor={"#0055D4"}
            color={"white"}
            margin={"0px 8px"}
            className={"button-component-hover common-btn"}
            handleClick={() => {
              paymentBack()
            }}
          />
          <ButtonComponent
            title={"Next"}
            height={"50px"}
            width={"150px"}
            backgroundColor={"#0055D4"}
            color={"white"}
            margin={"0px 8px"}
            className={"button-component common-btn"}
            handleClick={() => {
              paymentNext()
            }}
          />
        </div>
      </div>
      {
        openUpiModal &&
        <UpiAdd
          clearValue={(val: any) => { setOpenUpiModal(val) }}
          getUpi={() => getUpi()}
          Upis={Upis}
        />
      }
    </div >
  )
}

export default SelectPaymentMethod