import CustomEllipsisRenderer from "../../product-admin/users/users-aggrid-column-config";

export const directTaxColumnData = [
    {
        field: 'selection', headerName: '', minWidth: 60, maxWidth: 60, resizable: false, suppressMovable: true, filter: "agSetColumnFilter", suppressSizeToFit: false, headerCheckboxSelection: true, checkboxSelection: true, showDisabledCheckboxes: true, suppressRowTransform: true, cellStyle: { textAlign: 'left' }
    },
    {
        field: 'assessmentYear', headerName: 'Assessment Year', minWidth: 220, maxWidth: 220, resizable: false, filter: false, suppressMovable: true, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "number", cellRendererFramework: CustomEllipsisRenderer,
    },
    {
        field: 'challanNo', headerName: 'Challan', minWidth: 100, maxWidth: 100, resizable: false, filter: false, suppressMovable: true, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "number", cellRendererFramework: CustomEllipsisRenderer,
    },
    {
        field: 'totalAmount', headerName: 'Total Amount',headerClass: 'header-right-align', minWidth: 220, maxWidth: 220, resizable: false, filter: false, suppressMovable: true, suppressSizeToFit: false, cellStyle: { textAlign: 'right' }, filtertype: "number", cellRendererFramework: CustomEllipsisRenderer,
    },
    {
        field: 'majorHeadCode', headerName: 'Major Code', minWidth: 250, maxWidth: 250, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "number", cellRendererFramework: CustomEllipsisRenderer,
    },
    {
        field: 'minorHeadCode', headerName: 'Minor Code', minWidth: 250, maxWidth: 250, resizable: false,  suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "number", cellRendererFramework: CustomEllipsisRenderer,
    },
    {
        field: 'approvalStatus', headerName: 'Approval Status', minWidth: 220, maxWidth: 220, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, filtertype: "text", cellStyle: { textAlign: 'left' }, cellClassRules: {
            'paid-cell': (params: any) => params.value === 'Approved',
            'expired-cell': (params: any) => params.value === 'Declined',
            'pending-approval-cell': (params: any) => params.value === 'Pending Approval',
            // 'unpaid-cell': (params: any) => params.value === 'Unpaid',
        }, cellRendererFramework: CustomEllipsisRenderer,
    },
    {
        field: 'paymentStatus', headerName: 'Payment Status', minWidth: 220, maxWidth: 220, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, filtertype: "text", cellStyle: { textAlign: 'left' }, cellClassRules: {
            'paid-cell': (params: any) => params.value === 'Paid',
            'expired-cell': (params: any) => params.value === 'Unpaid',
            'pending-approval-cell': (params: any) => params.value === 'Pending',
        }, cellRendererFramework: CustomEllipsisRenderer,
    },
]

export const sampleDirectGridData = [
    {
        AssessmentYear: "2023-2024",
        Challan: "280",
        TotalTaxAmount: "4734.00",
        MajorCode: "(0020) Income-Tax on Companies (Corporate Tax)",
        MinorCode: "Self Assessment Tax (300)",
        ApprovalStatus: "Pending Approval",
        PaymentStatus: "Unpaid",
    },
    {
        AssessmentYear: "2023-2024",
        Challan: "281",
        TotalTaxAmount: "734.00",
        MajorCode: "(0020) Company Deductees",
        MinorCode: "TDS/TCS Payable by Taxpayer (200)",
        ApprovalStatus: "Pending Approval",
        PaymentStatus: "Unpaid",
    },
    {
        AssessmentYear: "2022-2023",
        Challan: "282",
        TotalTaxAmount: "1734.00",
        MajorCode: "(0020) Company Deductees",
        MinorCode: "Advance Tax (100)",
        ApprovalStatus: "Pending Approval",
        PaymentStatus: "Unpaid",
    },
    {
        AssessmentYear: "2022-2023",
        Challan: "283",
        TotalTaxAmount: "2734.00",
        MajorCode: "(0036) Banking Cash Transaction Tax",
        MinorCode: "Tax on Regular Assessment (400)",
        ApprovalStatus: "Pending Approval",
        PaymentStatus: "Unpaid",
    },
]