import { useEffect, useState } from 'react'
import "../../../styles/view-table.scss";
import IconButton from '../../common-component/form-elements/icon-button';
import DeleteModal from '../../common-component/modals/delete-modal';

interface Props {
    groupGrid: any[];
    privilegeEdit: (val: any) => void
    privilegeDelete: (val: any) => void
}

function PrivilageTable({
    groupGrid,
    privilegeEdit,
    privilegeDelete
}: Props) {

    const [showDeleteModel, setShowDeleteModel] = useState<boolean>(false)
    const [currentIndex, setCurrentIndex] = useState<number>();

    return (
        <>
            <div className='view-table-component' style={{ marginTop: "20px", height: "255px", overflowY: "auto" }}>
                <table >
                    <thead>
                        <th className='align-left'>Group</th>
                        <th className='align-left'>Privilage</th>
                        <th className='' style={{ paddingLeft: "10%", width: "20%" }}>Action</th>
                    </thead>
                    {groupGrid.length === 0 ? 
                    <tbody>
                        <tr className='main-body-header'>
                            <td className='norecordcls'></td>
                            <td className='norecordcls'>No records to show</td>
                            <td className='norecordcls'></td>
                        </tr>
                    </tbody> :
                        <tbody>

                            {groupGrid.map((data, index) => (
                                <tr className='main-body-header' key={index}>
                                    <td className='align-left'>{data.groupName}</td>
                                    <td className='align-left'>{data.privilegeName}</td>
                                    <td className='last-icon flexcls'>
                                        <IconButton
                                            iconName={"Edit"}
                                            height={"40px"}
                                            width={"40px"}
                                            fontSize={"30px"}
                                            margin={"0px 8px"}
                                            color={"white"}
                                            backgroundColor={"#0055D4"}
                                            hover={true}
                                            handleClick={() => {
                                                privilegeEdit(index);
                                            }}
                                        />
                                        <IconButton
                                            iconName={"Delete"}
                                            height={"40px"}
                                            width={"40px"}
                                            fontSize={"25px"}
                                            margin={"0px 8px"}
                                            color={"white"}
                                            backgroundColor={"#D60000"}
                                            hover={true}
                                            handleClick={() => {
                                                setShowDeleteModel(true)
                                                setCurrentIndex(index)
                                            }}
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    }
                </table>
            </div>
            {
                showDeleteModel &&
                <div >
                    <DeleteModal
                        getDelete={() => { privilegeDelete(currentIndex); setShowDeleteModel(false) }}
                        clearValue={(value) => { setShowDeleteModel(value) }}
                        modelType={"grid-delete"}
                    />
                </div>
            }
        </>
    )
}

export default PrivilageTable;