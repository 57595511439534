import React, { useEffect, useRef, useState, } from 'react';
import '../../../styles/modals/department-add-modal.scss';
import { RxCross1 } from 'react-icons/rx';
import ButtonComponent from '../form-elements/button-component';
import IconButton from '../form-elements/icon-button';
import InputComponent from '../form-elements/input-component';
import DeleteModal from './delete-modal';
import AlertBox from '../alert-box';
import useAxiosPrivate from '../../../services/hooks/useaxios-private';


interface Styles {
    clearValue: (val: any) => void;
    getExpenseType?: () => void;
    setExpenseValue: any;
    transactionMapping: any[];
    expenseValue: any[];
    organisationCardData: any;
    setExpenseValueGrid: any;
    setTransactionMappingData: any;

}



export default function AddExpenseValue({
    clearValue, getExpenseType, expenseValue, setExpenseValue, transactionMapping, setTransactionMappingData, organisationCardData, setExpenseValueGrid
}: Styles) {

    const [expenseName, setExpenseName] = useState<string>("")
    const [editIndex, setEditIndex] = useState<number>()
    const [deletIndex, setDeletIndex] = useState<number>()
    const [isExpenseTypeEditMode, setIsExpenseTypeEditMode] = useState<boolean>(false)
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
    const [showType, setShowType] = useState("warning") // error message showType
    const [showMessage, setShowMessage] = useState<string>("") // error message showMessage
    const [showAlertBox, setShowAlertBox] = useState(false) // error message ShowAlertBox
    const [currentExpenseName, setCurrentExpenseName] = useState()
    const axiosPrivate = useAxiosPrivate();

    const fetchExpenseData = async (url: string, setData: (data: any[]) => void, nestedProperty: string) => {
        try {

            const response = await axiosPrivate.get(url);
            console.log(response.data[nestedProperty]);
            if (response.status === 200) {

                const dropdownValues = response.data[nestedProperty].map((item: any) => ({
                    label: item.expenseName,
                    value: item.expenseName,
                    id: item.expenseId,
                }));

                const sortedData = [...dropdownValues].sort((a, b) => a.label.localeCompare(b.label));

                setData(sortedData);
                setExpenseValueGrid(dropdownValues);
            }
        } catch (error) {
            console.log('Error:', error);
        }
    };

    const saveExpenseType = async () => {
        if (expenseName !== "" && new RegExp(/^[a-zA-Z][a-zA-Z /]*$/).test(expenseName)) {
            if (expenseName === currentExpenseName && isExpenseTypeEditMode) {
                setIsExpenseTypeEditMode(false)
                setExpenseName("")
                setShowAlertBox(true);
                setShowType("success");
                setShowMessage("Expense Type updated successfully.")
                clearAleart();
                return;
            }
            try {
                const response = isExpenseTypeEditMode ?
                    await axiosPrivate.patch(`/companyadmin/organisation/card-expense/update/${editIndex}`, { expenseName: expenseName, expenseId: organisationCardData.expenseId, }) :
                    await axiosPrivate.post("/companyadmin/organisation/card-expense", { expenseName: expenseName });
                if (response.status === 200) {
                    console.log(expenseName === currentExpenseName)
                    setIsExpenseTypeEditMode(false)
                    setExpenseName("")
                    setShowAlertBox(true);
                    setShowType("success");
                    setShowMessage(response.data.message)
                    clearAleart();
                    fetchExpenseData(`/companyadmin/organisation/card-expense`, setExpenseValue, "CardExpenseDetails")
                    setTransactionMappingData((prevState: any) => ({
                        ...prevState,
                        transactionMappingExpense: "",
                        expenseId: ""
                    }));

                }
            } catch (error: any) {
                console.log(expenseName === currentExpenseName)
                setShowAlertBox(true);
                setShowType("danger");
                setShowMessage(error.response.data.error)
                clearAleart();
            }
        } else {
            setShowAlertBox(true);
            setShowType("danger");
            setShowMessage(expenseName === "" ?
                "Mandatory field(s) should not be left blank" :
                "Some of the field(s) are not in required format")
            clearAleart();

        }
    }

    const clearAleart = () => {
        const timer = setTimeout(() => {
            setShowAlertBox(false);
            setShowMessage("");
            clearTimeout(timer);
        }, 5000);
    }

    const editExpenseType = async (value: string, index: number) => {
        setEditIndex(index);
        setExpenseName(value);
        setIsExpenseTypeEditMode(true);
    };

    const deleteExpenseType = async () => {
        try {
            let data = transactionMapping.filter((x) => x.expenseId == deletIndex)
            if (data.length > 0) {
                setShowAlertBox(true);
                setShowType("danger");
                setShowMessage("Dependency Conflict! The selected record(s) cannot be deleted.")
                clearAleart();
                setShowDeleteModal(false);
                return;
            }
            const response = await axiosPrivate.delete(`/companyadmin/organisation/card-expense/delete/${deletIndex}`);
            if (response.status === 200) {
                setShowDeleteModal(false);
                getExpenseType && getExpenseType();
                setShowAlertBox(true);
                setShowType("success");
                setShowMessage(response.data.message)
                clearAleart();
                setTransactionMappingData((prevState: any) => ({
                    ...prevState,
                    transactionMappingExpense: "",
                    expenseId: ""
                }));
                setShowDeleteModal(false);
            }
        } catch (error: any) {
            setShowAlertBox(true);
            setShowType("danger");
            setShowMessage(error.response.data.error)
            clearAleart();
            setShowDeleteModal(false);
        }

    }

    const ConfremationDelete = async (value: string, index: number) => {
        setDeletIndex(index);
        setShowDeleteModal(true);
    };


    return (
        <div className={"department-add-modal"}>
            <div className={"container-add-modal"}>
                <div className={"close-icon"}>
                    <span className={"cross-icon"}>
                        <RxCross1 onClick={() => clearValue(false)
                        } style={{ fontSize: "40px" }} />
                    </span>
                </div>
                <div className='sub-container'>
                    <div className='department'>
                        <InputComponent
                            height={"40px"}
                            width={"350px"}
                            margin={"0px 10px 30px 0px"}
                            padding={"0px 0px 0px 10px"}
                            border={"1px solid #A9C3DC"}
                            borderRadius={"0px"}
                            backgroundColor={"white"}
                            color={"black"}
                            type={"text"}
                            maxLength={100}
                            inputTitle={"Expense Type"}
                            placeHolder={"Enter Expense Type"}
                            required={true}
                            inputValue={expenseName}
                            getUser={(value: any) => {
                                const finalvalue = value.replace(/[^a-zA-Z ]/g, "")
                                setExpenseName(finalvalue)
                            }}
                        />

                        <ButtonComponent
                            title={isExpenseTypeEditMode ? "Update" : "Save"}
                            height={"40px"}
                            width={"130px"}
                            backgroundColor={"#0055D4"}
                            color={"white"}
                            margin={"0px 8px"}
                            disabled={expenseName === ""}
                            className={expenseName === "" ? "button-component disabled" : "button-component common-btn"}
                            handleClick={() => {
                                saveExpenseType();

                            }}
                        />
                    </div>

                    <div className='view-table-component' style={{ marginTop: "20px", maxHeight: '300px', overflowY: 'auto' }}>
                        <table key={expenseValue.length}>
                            <thead>
                                <th className='privilege'>Expense</th>
                                <th className='access' style={{ paddingLeft: "27%" }}>Action</th>
                            </thead>
                            <tbody>
                                {
                                    expenseValue.length === 0 ?
                                        <tr className='expensetype-style-grid-differ'>
                                            {/* <td></td> */}
                                            <td colSpan={2}>No records to show</td>
                                            {/* <td></td> */}
                                        </tr>
                                        :
                                        expenseValue.map((data, index) => (
                                            <tr className='main-body-header' key={index} style={{ justifyContent: "space-between", padding: "" }}>
                                                <td className='first-name' style={{ padding: "5px", paddingLeft: "30px" }}>{data.value}</td>
                                                <td className='last-icon' style={{ display: "flex", justifyContent: "flex-end", padding: "5px" }}>
                                                    <IconButton
                                                        iconName={"Edit"}
                                                        height={"40px"}
                                                        width={"40px"}
                                                        fontSize={"30px"}
                                                        margin={"0px 8px"}
                                                        color={"white"}
                                                        backgroundColor={"#0055D4"}
                                                        hover={true}
                                                        handleClick={() => {
                                                            editExpenseType(data.value, data.id);
                                                            setCurrentExpenseName(data.value)
                                                        }}
                                                    />
                                                    <IconButton
                                                        iconName={"Delete"}
                                                        height={"40px"}
                                                        width={"40px"}
                                                        fontSize={"25px"}
                                                        margin={"0px 8px"}
                                                        color={"white"}
                                                        backgroundColor={"#D60000"}
                                                        hover={true}
                                                        handleClick={() => {
                                                            ConfremationDelete(data.value, data.id);
                                                        }}
                                                    />
                                                </td>
                                            </tr>
                                        ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            {
                showDeleteModal &&
                <div>
                    <DeleteModal
                        getDelete={deleteExpenseType}
                        clearValue={(value) => { setShowDeleteModal(value) }}
                        modelType={"grid-delete"}
                    />
                </div>
            }
            {
                showAlertBox &&
                <div className='alert-warp'>
                    <AlertBox type={showType} message={showMessage} />
                </div>
            }
        </div>
    )
}



