import axios from 'axios';
// const BASE_URL = 'https://172.25.10.44:443';
export const Socket_Io = 'https://trncdz.thefingertip-b2b.in';
export const BASE_URL = 'https://trncdz.thefingertip-b2b.in/v1';
// export const Socket_Io = 'http://172.25.10.185:9090/v1';
// export const BASE_URL = 'http://172.25.10.185:9090/v1/';
// const BASE_URL = 'http://172.25.10.185:8080/v1';

// const BASE_URL = 'http://172.25.10.208:3000/v1';
// const BASE_URL = 'http://172.25.10.210:3000/v1';
// const BASE_URL = 'http://172.26.10.111:3001/v1';
// const BASE_URL = 'https://172.26.10.110:3001/v1'; 
// const BASE_URL = 'http://172.26.10.9:3001/v1'; 
// const BASE_URL = 'https://172.25.10.44:443';


export default axios.create({
    baseURL: BASE_URL
});

export const axiosPrivate = axios.create({
    baseURL: BASE_URL,
    headers: { 'Content-Type': 'application/json' },
    withCredentials: false,
});  