import React, { Component } from 'react'
import Form from '../../components/product-admin/customer-onboarding/form'
import Grid from '../../components/product-admin/customer-onboarding/grid'

interface CustomerOb {
  userNavigate: string;
  companyAndKycDetails: any;
  isEditMode: boolean;
}

class CustomerOnboarding extends Component<{}, CustomerOb> {
  constructor(props: CustomerOb) {
    super(props);
    this.state = {
      userNavigate: "grid",
      companyAndKycDetails: {},
      isEditMode: false,
    };
  }

  render() {
    return (
      <div>
        {
          this.state.userNavigate === "form" &&
          <Form
            companyAndKycDetails={this.state.companyAndKycDetails}
            isEditMode={this.state.isEditMode}
            userNavigateFunction={(Val: string) => {
              this.setState({ userNavigate: Val }) 
            }}
            getEditMode={(value) => {
              this.setState({ isEditMode: value })
            }}
          />
        }
        {
          this.state.userNavigate === "grid" &&
          <Grid
            userNavigateFunction={(Val: string) => {
              this.setState({ userNavigate: Val })
            }}
            getEditMode={(val) =>
              this.setState({
                isEditMode: val
              })
            }
            getEditData={(name: string, data: any,) => {
              if (name === "form") {
                this.setState({
                  userNavigate: name,
                  companyAndKycDetails: { ...data },
                  isEditMode: true,
                })
              } 
              // else {
              //   this.setState({
              //     userNavigate: name,
              //     isEditMode: true,
              //   })
              // }
            }}

          />
        }
      </div>
    )
  }
}

export default CustomerOnboarding;