import React, { useEffect, useRef, useState, } from 'react';
import '../../../styles/modals/department-add-modal.scss';
import { RxCross1 } from 'react-icons/rx';
import ButtonComponent from '../form-elements/button-component';
import IconButton from '../form-elements/icon-button';
import InputComponent from '../form-elements/input-component';
import DeleteModal from './delete-modal';
import AlertBox from '../alert-box';
import useAxiosPrivate from '../../../services/hooks/useaxios-private';


interface Styles {
    clearValue: (val: any) => void;
    getVendorType?: () => void;
    setVendorTypeValues: any;
    // transactionMapping: any[];
    vendorTypeValues: any[];
    vendorTypeData: any;
    setVendorTypeData: any;
}



export default function AddVendorType({
    clearValue, getVendorType, vendorTypeValues, setVendorTypeValues, setVendorTypeData, vendorTypeData,
}: Styles) {

    const [vendorTypeName, setVendorTypeName] = useState<string>("")
    const [editIndex, setEditIndex] = useState<number>()
    const [deletIndex, setDeletIndex] = useState<number>()
    const [isVendorTypeEditMode, setIsVendorTypeEditMode] = useState<boolean>(false)
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
    const [showType, setShowType] = useState("warning") // error message showType
    const [showMessage, setShowMessage] = useState<string>("") // error message showMessage
    const [showAlertBox, setShowAlertBox] = useState(false) // error message ShowAlertBox
    const [currentVendorType, setCurrentVendorType] = useState()

    const axiosPrivate = useAxiosPrivate();

    const saveVendorType = async () => {
        if (vendorTypeName !== "" && new RegExp(/^[a-zA-Z][a-zA-Z /]*$/).test(vendorTypeName)) {
            if (vendorTypeName === currentVendorType && isVendorTypeEditMode) {
                setIsVendorTypeEditMode(false)
                setVendorTypeName("")
                setShowAlertBox(true);
                setShowType("success");
                setShowMessage("Vendor Type updated successfully.")
                clearAleart();
                return;
            }
            try {
                const response = isVendorTypeEditMode ?
                    await axiosPrivate.patch(`companyadmin/vendor/vendor-types/update/${editIndex}`, { vendorType: vendorTypeName, }) :
                    await axiosPrivate.post("companyadmin/vendor/vendor-types", { vendorType: vendorTypeName });
                if (response.status === 200) {
                    setIsVendorTypeEditMode(false)
                    setVendorTypeName("")
                    setShowAlertBox(true);
                    setShowType("success");
                    setShowMessage(response.data.message)
                    clearAleart();
                    getVendorType && getVendorType()

                    // fetchData(`/state/roc-cities/${vendorTypeData.state}`, setCityValues, 'RocCity', "rocCityId", "name");
                    setVendorTypeData((prevState: any) => ({
                        ...prevState,
                        cityName: "",
                        city: "",
                    }));

                }
            } catch (error: any) {
                setShowAlertBox(true);
                setShowType("danger");
                setShowMessage(error.response.data.error)
                clearAleart();
            }
        } else {
            setShowAlertBox(true);
            setShowType("danger");
            setShowMessage(vendorTypeName === "" ?
                "Mandatory field(s) should not be left blank" :
                "Some of the field(s) are not in required format")
            clearAleart();

        }
    }

    const clearAleart = () => {
        const timer = setTimeout(() => {
            setShowAlertBox(false);
            setShowMessage("");
            clearTimeout(timer);
        }, 5000);
    }

    const editVendorType = async (value: string, index: number) => {
        setEditIndex(index);
        setVendorTypeName(value);
        setIsVendorTypeEditMode(true);
    };

    const deleteVendorType = async () => {
        try {
            let data = vendorTypeValues.filter((x) => x.city == deletIndex)
            if (data.length > 0) {
                setShowAlertBox(true);
                setShowType("danger");
                setShowMessage("Dependency Conflict! The selected record(s) cannot be deleted.")
                clearAleart();
                setShowDeleteModal(false);
                return;
            }
            const response = await axiosPrivate.patch(`companyadmin/vendor/vendor-types/delete/${deletIndex}`);
            if (response.status === 200) {
                setShowDeleteModal(false);
                getVendorType && getVendorType();
                setShowAlertBox(true);
                setShowType("success");
                setShowMessage(response.data.message)
                clearAleart();
                setVendorTypeData((prevState: any) => ({
                    ...prevState,
                    vendorType: "",
                    vendorTypeId: "",
                }));
                setShowDeleteModal(false);
            }
        } catch (error: any) {
            setShowAlertBox(true);
            setShowType("danger");
            setShowMessage(error.response.data.error)
            clearAleart();
            setShowDeleteModal(false);
        }

    }

    const ConfremationDelete = async (value: string, index: number) => {
        setDeletIndex(index);
        setShowDeleteModal(true);
    };


    return (
        <div className={"department-add-modal"}>
            <div className={"container-add-modal"}>
                <div className={"close-icon"}>
                    <span className={"cross-icon"}>
                        <RxCross1 onClick={() => clearValue(false)
                        } style={{ fontSize: "40px" }} />
                    </span>
                </div>
                <div className='sub-container'>
                    <div className='department'>
                        <InputComponent
                            height={"40px"}
                            width={"350px"}
                            margin={"0px 10px 30px 0px"}
                            padding={"0px 0px 0px 10px"}
                            border={"1px solid #A9C3DC"}
                            borderRadius={"0px"}
                            backgroundColor={"white"}
                            color={"black"}
                            type={"text"}
                            maxLength={100}
                            inputTitle={"Vendor Type"}
                            placeHolder={"Enter Vendor Type"}
                            required={true}
                            inputValue={vendorTypeName}
                            getUser={(value: any) => {
                                const finalvalue = value.replace(/[^a-zA-Z ]/g, "")
                                setVendorTypeName(finalvalue)
                            }}
                        />

                        <ButtonComponent
                            title={isVendorTypeEditMode ? "Update" : "Save"}
                            height={"40px"}
                            width={"130px"}
                            backgroundColor={"#0055D4"}
                            color={"white"}
                            margin={"0px 8px"}
                            disabled={vendorTypeName === ""}
                            className={vendorTypeName === "" ? "button-component disabled" : "button-component common-btn"}
                            handleClick={() => {
                                saveVendorType();

                            }}
                        />
                    </div>

                    <div className='view-table-component' style={{ marginTop: "20px", maxHeight: '300px', overflowY: 'auto' }}>
                        <table key={vendorTypeValues.length}>
                            <thead>
                                <th className='privilege'>Vendor Type</th>
                                <th className='access' style={{ paddingLeft: "27%" }}>Action</th>
                            </thead>
                            <tbody>
                                {
                                    vendorTypeValues.length === 0 ?
                                        <tr className='expensetype-style-grid-differ'>
                                            {/* <td></td> */}
                                            <td colSpan={2}>No records to show</td>
                                            {/* <td></td> */}
                                        </tr>
                                        :
                                        vendorTypeValues.map((data, index) => (
                                            <>
                                                {
                                                    data.value !== "" &&
                                                    <tr className='main-body-header' key={index} style={{ justifyContent: "space-between", padding: "" }}>
                                                        <td className='first-name' style={{ padding: "5px", paddingLeft: "30px" }}>{data.value}</td>
                                                        <td className='last-icon' style={{ display: "flex", justifyContent: "flex-end", padding: "5px" }}>
                                                            <IconButton
                                                                iconName={"Edit"}
                                                                height={"40px"}
                                                                width={"40px"}
                                                                fontSize={"30px"}
                                                                margin={"0px 8px"}
                                                                color={"white"}
                                                                backgroundColor={"#0055D4"}
                                                                hover={true}
                                                                handleClick={() => {
                                                                    editVendorType(data.value, data.id);
                                                                    setCurrentVendorType(data.value)
                                                                }}
                                                            />
                                                            <IconButton
                                                                iconName={"Delete"}
                                                                height={"40px"}
                                                                width={"40px"}
                                                                fontSize={"25px"}
                                                                margin={"0px 8px"}
                                                                color={"white"}
                                                                backgroundColor={"#D60000"}
                                                                hover={true}
                                                                handleClick={() => {
                                                                    ConfremationDelete(data.value, data.id);
                                                                }}
                                                            />
                                                        </td>
                                                    </tr>
                                                }
                                            </>
                                        ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            {
                showDeleteModal &&
                <div>
                    <DeleteModal
                        getDelete={deleteVendorType}
                        clearValue={(value) => { setShowDeleteModal(value) }}
                        modelType={"grid-delete"}
                    />
                </div>
            }
            {
                showAlertBox &&
                <div className='alert-warp'>
                    <AlertBox type={showType} message={showMessage} />
                </div>
            }
        </div>
    )
}



