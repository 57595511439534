// import CustomEllipsisRenderer from "../../product-admin/users/users-aggrid-column-config"

// export const utilityApprovalColumnData = [
//     {
//         field: 'selection', headerName: '', minWidth: 60, maxWidth: 60, resizable: false, suppressMovable: true, filter: "agSetColumnFilter", suppressSizeToFit: false, headerCheckboxSelection: true, checkboxSelection: true, showDisabledCheckboxes: true, suppressRowTransform: true, cellStyle: { textAlign: 'center' }
//     },
//     {
//         field: 'utilityType', headerName: 'Utility Type', minWidth: 200, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "number", cellRendererFramework: CustomEllipsisRenderer,
//     },
//     {
//         field: 'state', headerName: 'State', minWidth: 200, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer,
//     },
//     {
//         field: 'boardProvider', headerName: 'Board/Provider', minWidth: 200, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer,
//     },
//     {
//         field: 'accountName', headerName: 'Account Name', minWidth: 200, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "number", cellRendererFramework: CustomEllipsisRenderer,
//     },
//     {
//         field: 'totalAmount', headerName: 'Total Amount(₹)', minWidth: 200, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "number", cellRendererFramework: CustomEllipsisRenderer,
//     },
//     {
//         field: 'status', headerName: 'Approval Status', minWidth: 200, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellClassRules: {
//             'success-cell': (params: any) => params.value === 'Approved',
//             'registered-cell': (params: any) => params.value === 'Pending Approval',
//             'denied-cell': (params: any) => params.value === 'Declined',
//         }, cellRendererFramework: CustomEllipsisRenderer,
//     },
//     {
//         field: 'status', headerName: 'Payment Status', minWidth: 200, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellClassRules: {
//             'success-cell': (params: any) => params.value === 'Approved',
//             'registered-cell': (params: any) => params.value === 'Pending Approval',
//             'denied-cell': (params: any) => params.value === 'Declined',
//         }, cellRendererFramework: CustomEllipsisRenderer,
//     },

//     {
//         field: 'transactionHistory', headerName: 'Transaction History', minWidth: 200, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer,
//     },






// ]


export const rowDatathis = [
    {
        utilityType: "Electricity",
        state: "Odisha",
        boardProvider: "Tamil Nadu Electricity Board (TNEB)",
        accountName: "Branch 1",
        totalAmount: "2,000.00",
        status: "Pending Approval",

    }, {
        utilityType: "Electricity",
        state: "TamilNadu",
        boardProvider: "Tamil Nadu Electricity Board (TNEB)",
        accountName: "Branch 1",
        totalAmount: "2,000.00",
        status: "Pending Approval",
    }, {
        utilityType: "Electricity",
        state: "Kerala",
        boardProvider: "Tamil Nadu Electricity Board (TNEB)",
        accountName: "Branch 1",
        totalAmount: "2,000.00",
        status: "Pending Approval",

    }, {
        utilityType: "Electricity",
        state: "Kerala",
        boardProvider: "Tamil Nadu Electricity Board (TNEB)",
        accountName: "Branch 1",
        totalAmount: "2,000.00",
        status: "Pending Approval",

    },


]