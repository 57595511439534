
import React, { useEffect, useState } from 'react'
import { BiPlus, BiMinus } from "react-icons/bi";
import "../../styles/view-table.scss";
import IconButton from '../../components/common-component/form-elements/icon-button';
import DeleteModal from '../../components/common-component/modals/delete-modal';
import "../../styles/onboarding/company/grid-icon.scss"


interface Props {
    adminDetails: any;
    retriveData: (value: any, index: number) => void;
    showmodal: (value: any, index: number) => void;
}

function AdminDetailsGrid({ adminDetails, retriveData, showmodal }: Props) {
    const [showDeleteModel, setShowDeleteModel] = useState<boolean>(false)

    return (
        <>
            <div className='view-table-component' style={{ marginTop: "40px", marginBottom: "40px", height: "205px", overflowY: "auto" }}>
                <table className='company-seperate-grid'>
                    <thead>
                        {/* <tr> */}
                        <th className='cus-width' style={{ paddingLeft: "20px", minWidth: "250px" }}>Name</th>
                        <th className='cus-width' style={{ minWidth: "200px" }}>Designation</th>
                        <th className='cus-width' style={{ minWidth: "165px", textAlign: "center", paddingRight: "20px" }}>Contact Number</th>
                        <th className='cus-width' style={{ minWidth: "250px" }}>Email ID</th>
                        <th className='cus-width' style={{ minWidth: "90px" }}>Is Active</th>
                        <th className='' style={{ minWidth: "120px", paddingLeft: "30px" }}>Action</th>
                        {/* </tr> */}
                    </thead>
                    <tbody >
                        {adminDetails?.length === 0 ?
                            <tr className='style-grid-differ'>
                                <td></td>
                                <td></td>
                                <td>No records to show</td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr> : adminDetails?.map((data: any, index: number) => (
                                <tr className='main-body-header' key={index}>
                                    <td className='left-icons' style={{ paddingLeft: "20px", minWidth: "250px", paddingRight: "20px" }}>{data.name}</td>
                                    <td className='left-icons' style={{ minWidth: "200px", paddingRight: "20px" }}>{data.designation}</td>
                                    <td className='left-icons' style={{ width: "165px", textAlign: "center", paddingRight: "20px" }}>{data.mobileNumber}</td>
                                    <td className='left-icons' style={{ minWidth: "250px", paddingRight: "20px" }}>{data.email}</td>
                                    <td className='left-icons' style={{ width: "90px" }}>{data.userIsActive}</td>
                                    <td className='last-icon flexcls' style={{ minWidth: "110px", justifyContent: "center" }}>
                                        {/* <IconButton
                                            iconName={"Edit"}
                                            height={"40px"}
                                            width={"40px"}
                                            fontSize={"30px"}
                                            margin={"0px 8px"}
                                            color={"white"}
                                            backgroundColor={"#0055D4"}
                                            hover={true}
                                            handleClick={() => {
                                                retriveData(data, index)
                                            }}
                                        /> */}
                                        <IconButton
                                            iconName={"Delete"}
                                            height={"40px"}
                                            width={"40px"}
                                            fontSize={"25px"}
                                            margin={"0px 8px"}
                                            color={"white"}
                                            backgroundColor={"#D60000"}
                                            hover={true}
                                            handleClick={() => {
                                                showmodal(data, index)
                                            }}
                                        />
                                        <IconButton
                                            iconName={"ShowEye"}
                                            height={"35px"}
                                            width={"30px"}
                                            fontSize={""}
                                            className={"icons"}
                                            cursor={"pointer"}
                                            color={"white"}
                                            backgroundColor={""}
                                            hover={false}
                                            handleClick={() => {
                                                retriveData(data, index)

                                            }}
                                        />
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default AdminDetailsGrid;
// 