import React, { Component } from 'react'
import GstApprovalGrid from '../../components/approval/gst-approval/gst-approval-grid';
import GstApprovalForm from '../../components/approval/gst-approval/gst-approval-form';


interface StateValues {
    gstView: string;
    isEditMode: boolean;
    transaction: string;
    makePaymentData: any;
}
export default class GstApproval extends Component<{}, StateValues> {
    constructor(props: StateValues) {
        super(props);
        this.state = {
            gstView: "grid",
            isEditMode: false,
            transaction: "",
            makePaymentData: [],
        };
    }
    addMode = () => {
        this.setState({
            isEditMode: false,
        })
    }
    render() {
        return (
            <div>
                {
                    this.state.gstView === "form" &&
                    <GstApprovalForm
                        gstView={(val) => {
                            this.setState({ gstView: val })
                        }}
                        transaction={this.state.transaction}
                        makePaymentDatas={this.state.makePaymentData}
                    />
                }
                {
                    this.state.gstView === "grid" &&
                    <GstApprovalGrid
                        gstView={(val) => {
                            this.setState({ gstView: val })
                        }}
                        transaction={(val: any) => {
                            this.setState({ transaction: val })
                        }}
                        makePaymentDataFun={(val: any) => {
                            this.setState({ makePaymentData: val })
                        }}
                        addMode={this.addMode}
                        getEditData={(nav: any, data: any) => this.setState({
                            gstView: nav,
                            isEditMode: true,
                        })}
                    />
                }
            </div>

        )
    }
}

