import React, { useEffect, useRef, useState } from 'react'
import VendorSummaryTable from './vendor-summary-reports';
import IvoicesDetailsReport from './ivoices-details-report';
import IvoiceSettlement from './invoice-settlements';
import { ReactComponent as Summary } from '../../../../assets/icons/reports/Summary.svg'
import { ReactComponent as InvoiceSettlement } from '../../../../assets/icons/reports/Invoice settlements.svg'
import { ReactComponent as InvoiceDetails } from '../../../../assets/icons/reports/Invoicedetails.svg'
import MainPageCard from '../../../common-component/cards/main-page-card';
import NavigateHeader from '../../../common-component/navigate-header';
import '../../../../styles/reports/reports.scss';
import Tooltip from 'rc-tooltip';
import IconButton from '../../../common-component/form-elements/icon-button';
import DownloadOption from '../../download-option';
import { CiSearch } from 'react-icons/ci';
import '../../../../styles/reports/report-table.scss';
import ButtonComponent from '../../../common-component/form-elements/button-component';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { toDate } from 'date-fns';
import { useReactToPrint } from 'react-to-print';


interface props {
    // setShowMessage: any
    // setShowType: any
    // payableValues: string
    // setShowAlertBox: (value: any) => void 
}
export default function VendorPayableReports({ }: props) {
    const todayDate = moment(new Date()).format('YYYY-MM-DD');
    const currentyear = new Date().getFullYear();
    const currentDate = new Date();
    const [mindate, setMindate] = useState<string>(todayDate);
    const [selectedVendorPayable, setSelectedVendorPayable] = useState("Summary")
    const [filterOpen, setFilterOpen] = useState<boolean>(false);
    const [searchBarValue, setSearchBarValue] = useState("")
    const [downloadoption, setDownloadoption] = useState<boolean>(false);
    const [getVendorOverViewId, setGetVendorOverViewId] = useState<any>("");
    const [getinvoiceId, setGetinvoiceId] = useState<any>("");
    currentDate.setMonth(currentDate.getMonth() - 1);
    const oneMonthAgo: string = currentDate.toISOString().split('T')[0];
    const [vendorDate, setVendorDate] = useState({
        validFrom: oneMonthAgo,
        validto: todayDate
    });



    const handleDoubleClick = (data: any) => {
    };

    const vendorReportsIcons = [
        { name: 'Summary', icon: <Summary />, palceholder: "Search by Name/Code" },
        { name: 'Invoices Details', icon: <InvoiceDetails />, palceholder: "Search by Vendor Name/Invoice" },
        { name: 'Invoice Settlements', icon: <InvoiceSettlement />, palceholder: "Search by Invoice" },

    ];
    function handleSignup(iconName: any) {
        setSelectedVendorPayable(iconName)
    }



    const handleToDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        // setMindate(moment(event.target.value).format("YYYY-MM-DD")
        const toDate = event.target.value;
        const currentDate = moment().format('YYYY-MM-DD'); // Get the current date
        if (toDate.length > 8) {
            const year = toDate.substring(0, 4);
            if (parseInt(year, 10) > currentyear) {
                return;
            }
            if (parseInt(year) > 999) {
                if (parseInt(year, 10) < parseInt(("").substring(0, 4))) {
                    return;
                }
            }
        }
        setVendorDate((prevsubscriptionData: any) => ({
            ...prevsubscriptionData,
            validto: toDate
        }))
    };

    const handleFromDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMindate(moment(event.target.value).format("YYYY-MM-DD"))
        const fromDate = event.target.value;
        const currentDate = moment().format('YYYY-MM-DD'); // Get the current date.
        if (fromDate.length > 8) {
            const year = fromDate.substring(0, 4);
            if (parseInt(year, 10) > currentyear) { return; }
            if (parseInt(year) > 999) {
                if (parseInt(year, 10) < 1900) {
                    return;
                }
            }
        }
        setVendorDate((prevsubscriptionData: any) => ({
            ...prevsubscriptionData,
            validFrom: fromDate,
            validto: ""
        }))
    };

    const conponentPDFInvoice: any = useRef()
    const conponentPDFInvoicefunction = useReactToPrint({
        content: () => conponentPDFInvoice.current,
        documentTitle: "InvoiceData",
        pageStyle: `
            @page {
              size: landscape;
              margin: 0.5in; // Adjust margins as needed
            }
          `,
    });

    // const conponentPDFSummary: any = useRef()
    // const conponentPDFSummaryfunction = useReactToPrint({
    //     content: () => conponentPDFInvoice.current,
    //     documentTitle: "Summary",
    //     pageStyle: `
    //         @page {
    //           size: landscape;
    //           margin: 0.5in; // Adjust margins as needed
    //         }
    //       `,
    // });
    return (
        <>

            <MainPageCard>
                <NavigateHeader
                    style={{ cursor: "pointer" }}
                    firstValue={"Reports"}
                    secondValue={"Vendor Payable"}
                    navigatePage={() => { }}
                />
                <div style={{ marginTop: "55px", display: "flex", justifyContent: "space-between" }}>

                    <div className='master-list-main' style={{ width: "300px" }}>
                        {
                            vendorReportsIcons.map((data: any, index: number) => (
                                <>
                                    <div className={selectedVendorPayable === data.name ? 'bottom-border-line' : ""}>
                                        <div key={index} onClick={() => {
                                            setSearchBarValue("")
                                            setGetinvoiceId("")
                                            setGetVendorOverViewId("")
                                            handleSignup(data.name) 
                                        }
                                        } className='check'>

                                            <Tooltip
                                                placement="bottom"
                                                overlay={data.name}
                                                overlayClassName="custom-tooltip"
                                            >
                                                <div className={""} >{data.icon}</div>
                                            </Tooltip>
                                        </div>
                                    </div>
                                    <div className={index === vendorReportsIcons.length - 1 ? "" : 'border-line'}>
                                    </div>
                                </>
                            ))}
                    </div>
                    <div style={{ display: "flex", alignItems: "center", gap: "10px", justifyContent: "end", marginTop: "10px", position: "relative" }}>
                        <div style={{ marginTop: "-27px" }}>
                            <div>
                                From
                            </div>
                            <input type='date' className='date-picker-vendor'
                                value={vendorDate.validFrom}
                                min={"YYYY-MM-DD"}
                                max={todayDate}

                                onChange={(e: any) => {
                                    handleFromDateChange(e)
                                }} />

                        </div>
                        <div style={{ marginTop: "-27px" }} key={uuidv4()}>
                            <div>
                                To
                            </div>
                            <input type='date' className='date-picker-vendor'
                                value={vendorDate.validto}
                                min={vendorDate.validFrom}
                                max={todayDate}
                                onChange={
                                    handleToDateChange
                                } />
                        </div>

                        <div>
                            <ButtonComponent
                                title={"Apply"}
                                height={"40px"}
                                width={"100px"}
                                backgroundColor={"#0055D4"}
                                disabled={true}
                                color={"white"}
                                margin={"0px 8px"}
                                className={"button-component-hover common-btn"}
                                handleClick={() => {
                                    // virtualCardSave();
                                }
                                }
                            />
                        </div>



                        <div className='searching-bar-report' >
                            <CiSearch className='search-icon' />
                            <input
                                className="input-area"
                                placeholder={vendorReportsIcons.find(obj => obj.name === selectedVendorPayable)?.palceholder}
                                value={searchBarValue}
                                onChange={(event: any) => {
                                    setSearchBarValue(event.target.value)
                                }}
                            />
                        </div>

                        <div className='filter-area' >
                            <IconButton
                                iconName={"Filter"}
                                height={"40px"}
                                width={"40px"}
                                fontSize={"30px"}
                                margin={"0px 8px"}
                                color={"white"}
                                backgroundColor={"#0055D4"}
                                hover={true}
                                handleClick={() => {
                                    setFilterOpen(true)
                                }}
                            />
                        </div>
                        <div>
                            <IconButton
                                iconName={"Download"}
                                height={"40px"}
                                width={"40px"}
                                fontSize={"25px"}
                                margin={"0px 8px"}
                                color={"white"}
                                backgroundColor={"#0055D4"}
                                hover={true}
                                handleClick={
                                    (event: React.MouseEvent) => {
                                        event?.stopPropagation()
                                        setDownloadoption(true)
                                    }
                                }
                            />
                        </div>
                        {
                            downloadoption &&
                            <div>
                                <DownloadOption
                                    // conponentPDF={conponentPDF}   
                                    getMethodFun={(value: any) => {
                                        conponentPDFInvoicefunction()

                                    }}
                                    handleclose={(value: any) => { setDownloadoption(value) }}
                                />
                            </div>
                        }
                    </div>
                </div>
                <div>
                    {
                        selectedVendorPayable === "Summary" &&
                        <VendorSummaryTable
                            searchBarValue={searchBarValue}
                            handleDoubleClick={handleDoubleClick}
                            filterOpen={filterOpen}
                            setFilterOpen={setFilterOpen}
                            conponentPDFInvoice={conponentPDFInvoice}
                            selectedVendorPayableFun={(value: any) => { setSelectedVendorPayable(value) }}
                            getVendorOverViewIdFun={(value: any) => { setGetVendorOverViewId(value) }}
                            searchBarValueNameFun={(value: any) => {
                                setSearchBarValue(value)
                            }}
                        />
                    }
                    {
                        selectedVendorPayable === "Invoices Details" &&
                        <IvoicesDetailsReport
                            downloadoption={downloadoption}
                            setDownloadoption={setDownloadoption}
                            filterOpen={filterOpen}
                            setFilterOpen={setFilterOpen}
                            searchBarValue={searchBarValue}
                            conponentPDFInvoice={conponentPDFInvoice}
                            conponentPDFInvoicefunction={conponentPDFInvoicefunction}
                            getVendorOverViewId={getVendorOverViewId}
                            selectedVendorPayableFun={(value: any) => { setSelectedVendorPayable(value) }}
                            getVendorOverViewIdFun={(value: any) => { setGetVendorOverViewId(value) }}
                            getVendorinvoiceIdFun={(value: any) => { setGetinvoiceId(value) }}
                            searchBarValueNameFun={(value: any) => {
                                setSearchBarValue(value)
                            }}
                        // handleDoubleClick={handleDoubleClick}
                        />
                    }
                    {
                        selectedVendorPayable === "Invoice Settlements" &&
                        <IvoiceSettlement
                            searchBarValue={searchBarValue}
                            filterOpen={filterOpen}
                            setFilterOpen={setFilterOpen}
                            getVendorOverViewId={getinvoiceId}
                        // handleDoubleClick={handleDoubleClick}
                        />

                    }
                </div>

            </MainPageCard>
            <div>
                {/* <div className='mastername-search-main'>
                    <div className='master-list-main' style={{ width: "560px" }}>
                        {
                            vendorPayableReports.map((data, index) => (
                                <>
                                    <div className={selectedVendorPayable === data.name ? 'vendor-details-active' : "vendor-details-inactive"} key={index} onClick={() =>
                                        handleSignup(data.name, index)}>
                                        <div >
                                            <p>{data.name}</p>
                                        </div>
                                    </div>
                                    <div className={index === vendorPayableReports.length - 1 ? "" : 'border-line'}>
                                    </div>
                                </>
                            ))}
                    </div>
                </div> */}
                {/* <div>
                    {
                        payableValues === "Summary" &&
                        <VendorSummaryTable
                            handleDoubleClick={handleDoubleClick}
                        />

                    }
                    {
                        payableValues === "Invoices Details" &&
                        <IvoicesDetailsReport
                            setShowAlertBox={(value: any) => setShowAlertBox(value)}
                            setShowType={""}
                            setShowMessage={""}
                        // handleDoubleClick={handleDoubleClick}
                        />

                    }
                    {
                        payableValues === "Invoice Settlements" &&
                        <IvoiceSettlement

                        // handleDoubleClick={handleDoubleClick}
                        />

                    }
                </div> */}
            </div>
        </>

    )
}
