import { format } from "date-fns/esm";

export function TitleCase(str: any) {
    if (str) {
        var splitStr = str.toLowerCase().split(' ');
        for (var i = 0; i < splitStr.length; i++) {
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
        }
        return splitStr.join(' ');
    } else {
        return str;
    }
}

export const DateConverter = (date: any) => {
    if (date) {
        const originalDate = new Date(date);
        return (format(originalDate, "dd-MM-yyyy"));
    } else {
        return ""
    }
}

export const DateConverterAgGrid = ({ value }: any) => {
    if (value) {
        const originalDate = new Date(value);
        // Check if originalDate is a valid date
        if (isNaN(originalDate.getTime())) {
            return <span>{""}</span>;
        }

        return <span>{format(originalDate, "dd-MM-yyyy")}</span>;
    } else {
        return <span>{""}</span>;
    }
}
