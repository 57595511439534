


export const organisationColumnData = [
    {
        field: 'selection', headerName: '', suppressCsvExport: true, minWidth: 60, maxWidth: 60, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, headerCheckboxSelection: true, checkboxSelection: true, showDisabledCheckboxes: true, suppressRowTransform: true, cellStyle: { textAlign: 'center' },
    },
    {
        field: 'organisationName', headerName: 'Organisation Name', minWidth: 200, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' },filtertype: "text",
    },
    {
        field: 'organisationCategory', headerName: 'Organisation Category', minWidth: 200, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' },filtertype: "text",
    },
    {
        field: 'internalApprovalStatus', headerName: 'Internal Approval Status --', minWidth: 200, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' },filtertype: "text",cellRenderer: (e: any) => {
            // Custom rendering logic
            let color = '';
            switch (e.data.internalApprovalStatus) {
                case 'NYS':
                    color = '#0055D4'
                    break;
                case 'Declined':
                    color = 'red'
                    break;
                case 'Approved':
                    color = 'green'
                    break;
                case 'Pending Approval':
                    color = '#D2A100'
                    break;
                default:
                    break;
            }
            return (
                <div style={{ color: color }}>
                    {e?.data?.internalApprovalStatus}
                </div>
            );
        }
    },
    {
        field: 'isActive', headerName: 'Is Active', minWidth: 200, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' },filtertype: "text",
    },
] 

export const rowData2 = [
    
    {
        organisationName:"ICICI",
        organisationCategory:"Bank",
        isActive:"Yes",
    },
    {
        description:"Software Development",
        isActive:"true",
        organisationCategory:"IT Industry",
        organisationId: 2,
        organisationName: "KQDMS"
    },
    {
        organisationName:"ICICI",
        organisationCategory:"Bank",
        isActive:"Yes",
    },
]