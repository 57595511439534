import React from 'react'

const PointOfContact = () => {
  return (
    <div>
      PointOfContact
      PointOfContact
    </div>
  )
}

export default PointOfContact
