import React, { useEffect, useRef, useState } from 'react'
import IconButton from '../../../common-component/form-elements/icon-button';
import { CiSearch } from 'react-icons/ci';
import ChartFilter from '../../../common-component/charts/chart-filter';
import { axiosPrivate } from '../../../../middleware/axois-api';
import '../../../../styles/reports/report-table.scss';
import TransationMapping from './transation-mapping';
import { handlePngPDFDownload } from '../../../common-component/export-pdf/Export-to-pdf';
import Loading from '../../../common-component/modals/loading-screen';
import { CardReportFilter } from '../../../../config/reports/master-reports/card-report-config';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { formatAmount } from '../../../../helpers/amountRenderer';
interface props {
    searchBarValue: string
    filterOpen: any
    setFilterOpen: any
    conponentPDFMaster: any

}
export default function CardGrid({ searchBarValue, filterOpen, setFilterOpen, conponentPDFMaster }: props) {
    const divRef = useRef<HTMLDivElement>(null);
    const [searchValue, setSearchValue] = useState<string>('');
    const [filteredData, setFilteredData] = useState<any[]>([]);
    const [orginalFilteredData, setOrginalFilteredData] = useState<any[]>([]);
    // const [filterOpen, setFilterOpen] = useState<boolean>(false);
    const [openDropdownIndex, setOpenDropdownIndex] = useState<number | null>(null);
    const [transactionMapping, setTransactionMapping] = useState<any[]>([])
    const [selectedFilteredData, setSelectedFilteredData] = useState<any>('All');
    const [transationMappingOpen, setTransationMappingOpen] = useState<boolean>(false);
    const [isVisible, setIsVisible] = useState(false);
    const [loadingScreen, setLoadingScreen] = useState<boolean>(false);

    const [storeFilterOptions, setStoreFilterOptions] = useState<any>([]);
    const [filterValue, setFilterValues] = useState<any>([])

    // all data filter

    // const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     const value = event.target.value;
    //     setSearchValue(value);

    //     const filtered = SampleDatasArun.filter((item) => {
    //         // Customize the condition based on your specific requirements
    //         return Object.values(item).some((val) => {
    //             if (val && typeof val === 'object') {
    //                 // If the value is an object and not null/undefined, check its values
    //                 return Object.values(val).some((nestedVal: any) =>
    //                     nestedVal && nestedVal.toString().toLowerCase().includes(value.toLowerCase())
    //                 );
    //             } else if (val) {
    //                 // If the value is not an object and not null/undefined, directly check it
    //                 return val.toString().toLowerCase().includes(value.toLowerCase());
    //             }
    //             return false; // Skip null/undefined values
    //         });
    //     });

    //     setFilteredData(filtered); 
    // };


    const downloadPDF = () => {
        const input = document.getElementById('table-to-pdf'); // Replace 'table-to-pdf' with the ID of your table
        if (!input) {
            console.error('Table not found');
            return;
        }

        html2canvas(input)
            .then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF();
                const imgWidth = 210; // A4 size
                const imgHeight = 150;
                const pageCount = Math.ceil(canvas.height / imgHeight);

                for (let i = 0; i < pageCount; i++) {
                    const offsetY = i * imgHeight;
                    pdf.addImage(imgData, 'PNG', 0, -offsetY, imgWidth, imgHeight);
                    if (i < pageCount - 1) {
                        pdf.addPage();
                    }
                }
                pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
                pdf.save('table-data.pdf');
            })
            .catch((error) => {
                console.error('Error converting table to PDF:', error);
            });
    };
    // two data filter

    useEffect(() => {
        const filtered = orginalFilteredData.filter((item) => {
            const cardDetails = item.Details;

            if (
                cardDetails &&
                (cardDetails["Number"]?.toLowerCase().includes(searchBarValue.toLowerCase()))
            ) {
                // Return an object containing the matched field and its value
                return {
                    matchedField: cardDetails["Number"]?.toLowerCase().includes(searchBarValue.toLowerCase())
                        ? "Number"
                        : "",
                    value: cardDetails["Number"],
                };
            }
            return null;
        });

        setFilteredData(filtered);
    }, [searchBarValue]);


    const fetchData = async () => {
        try {
            const response = await axiosPrivate.get('/companyadmin/master-reports/card');
            setFilteredData(response.data.cardDetailsReports)
            setOrginalFilteredData(response.data.cardDetailsReports)
            addDataForDropDownFilter(response.data.dropDownDatas)
        } catch (error) {
        }
    };

    const getExistingTransation = async (cardId: any) => {
        try {
            const response = await axiosPrivate.get(`companyadmin/organisation/card-transaction-mapping/${cardId}`);
            if (response.status === 200) {
                setTransationMappingOpen(true)
                setTransactionMapping(response.data.CardTransactionMappingDetails)
            }
        } catch (error) {
        }
    }

    function handleOrganisationsVsCompanies() {
        if (selectedFilteredData === 'All') {
            setOrginalFilteredData(orginalFilteredData)
            // fetchData()
        } else {
            const filteredData: any = orginalFilteredData.filter((ele: any) => ele.fgf === selectedFilteredData)
            setOrginalFilteredData(filteredData)
        }
        setFilterOpen(false)
    }
    // filter functions

    useEffect(() => {
        handleApplyButtonForFilter(CardReportFilter["Card"])
        setStoreFilterOptions(CardReportFilter["Card"])
        setFilterValues(CardReportFilter["Card"])
        fetchData()
    }, [])

    const handleOpenClose = (index: number) => {
        if (openDropdownIndex === index) {
            setOpenDropdownIndex(null); // Close the dropdown if it's already open
        } else {
            setOpenDropdownIndex(index); // Open the dropdown
        }
    };

    const updateFilterDatas = async (index: number, newData: any) => {
        setStoreFilterOptions((prevFilterDatas: any) => {
            const updatedFilterDatas = [...prevFilterDatas];
            updatedFilterDatas[index] = newData;
            return updatedFilterDatas;
        });

        setFilterValues((prevFilterDatas: any) => {
            const updatedFilterDatas = [...prevFilterDatas];
            updatedFilterDatas[index] = newData;
            return updatedFilterDatas;
        });
    };

    function addDataForDropDownFilter(res: any) {
        try {
            if (Object.keys(res).length > 0) {
                const updatedBillingCycle: any = [
                    { value: "All", label: "All" },
                    ...res['Billing Cycle'].map((elem: any) => ({ value: elem, label: elem }))
                ];
                const updatedCategory: any = [
                    { value: "All", label: "All" },
                    ...res.Category.map((elem: any) => ({ value: elem, label: elem }))
                ];
                const updatedCreditPeriod: any = [
                    { value: "All", label: "All" },
                    ...res['Credit Period'].map((elem: any) => ({ value: elem, label: elem }))
                ];
                const updatedCustomLimits: any = [
                    { value: "All", label: "All" },
                    ...res['Custom Limits'].map((elem: any) => ({ value: elem, label: elem }))
                ];
                const updatedDailyLimits: any = [
                    { value: "All", label: "All" },
                    ...res['Daily Limits'].map((elem: any) => ({ value: elem, label: elem }))
                ];
                const updatedIsActive: any = [
                    { value: "All", label: "All" },
                    ...res['Is Active'].map((elem: any) => ({ value: elem, label: elem }))
                ];
                const updatedIsApproved: any = [
                    { value: "All", label: "All" },
                    ...res['Is Approved'].map((elem: any) => ({ value: elem, label: elem }))
                ];
                const updatedIssuer: any = [
                    { value: "All", label: "All" },
                    ...res['Issuer'].map((elem: any) => ({ value: elem, label: elem }))
                ];
                const updatedMaxLimit: any = [
                    { value: "All", label: "All" },
                    ...res["Max Limit"].map((elem: any) => ({ value: elem, label: elem }))
                ];
                const updatedNameOnCard: any = [
                    { value: "All", label: "All" },
                    ...res['Name on Card'].map((elem: any) => ({ value: elem, label: elem }))
                ];
                const updatedNumber: any = [
                    { value: "All", label: "All" },
                    ...res['Number'].map((elem: any) => ({ value: elem, label: elem }))
                ];
                const updatedSingleTransactionLimits: any = [
                    { value: "All", label: "All" },
                    ...res["Single Transaction Limits"].map((elem: any) => ({ value: elem, label: elem }))
                ];
                const updatedType: any = [
                    { value: "All", label: "All" },
                    ...res['Type'].map((elem: any) => ({ value: elem, label: elem }))
                ];
                const updatedValidFrom: any = [
                    { value: "All", label: "All" },
                    ...res["Valid From"].map((elem: any) => ({ value: elem, label: elem }))
                ];
                const updatedValidThru: any = [
                    { value: "All", label: "All" },
                    ...res['Valid Thru'].map((elem: any) => ({ value: elem, label: elem }))
                ];
                setStoreFilterOptions((prevOptions: any) => {
                    return prevOptions.map((option: any) => {
                        switch (option.cardTitle) {
                            case "Billing Cycle":
                                return { ...option, options: updatedBillingCycle };
                            case "Category":
                                return { ...option, options: updatedCategory };
                            case "Credit Period":
                                return { ...option, options: updatedCreditPeriod };
                            case "Custom Limits":
                                return { ...option, options: updatedCustomLimits };
                            case "Daily Limits":
                                return { ...option, options: updatedDailyLimits };
                            case "Is Active":
                                return { ...option, options: updatedIsActive };
                            case "Is Approved":
                                return { ...option, options: updatedIsApproved };
                            case "Issuer":
                                return { ...option, options: updatedIssuer };
                            case "Name On Card":
                                return { ...option, options: updatedNameOnCard };
                            case "Number":
                                return { ...option, options: updatedNumber };
                            case "Single Transaction Limits":
                                return { ...option, options: updatedSingleTransactionLimits };
                            case "Type":
                                return { ...option, options: updatedType };
                            case "Valid From":
                                return { ...option, options: updatedValidFrom };
                            case "Valid Thru":
                                return { ...option, options: updatedValidThru };
                            default:
                                return option;
                        }
                    });
                });

                setFilterValues((prevOptions: any) => {
                    return prevOptions.map((option: any) => {
                        switch (option.cardTitle) {
                            case "Billing Cycle":
                                return { ...option, options: updatedBillingCycle };
                            case "Category":
                                return { ...option, options: updatedCategory };
                            case "Credit Period":
                                return { ...option, options: updatedCreditPeriod };
                            case "Custom Limits":
                                return { ...option, options: updatedCustomLimits };
                            case "Daily Limits":
                                return { ...option, options: updatedDailyLimits };
                            case "Is Active":
                                return { ...option, options: updatedIsActive };
                            case "Is Approved":
                                return { ...option, options: updatedIsApproved };
                            case "Issuer":
                                return { ...option, options: updatedIssuer };
                            case "Name On Card":
                                return { ...option, options: updatedNameOnCard };
                            case "Number":
                                return { ...option, options: updatedNumber };
                            case "Single Transaction Limits":
                                return { ...option, options: updatedSingleTransactionLimits };
                            case "Type":
                                return { ...option, options: updatedType };
                            case "Valid From":
                                return { ...option, options: updatedValidFrom };
                            case "Valid Thru":
                                return { ...option, options: updatedValidThru };
                            default:
                                return option;
                        }
                    });
                });

            }
        } catch (error) {
            console.log("error", error)
        }
    }

    const convertToFilterValues = (filterData: any) => {
        const filterValues: any = {};
        filterData.forEach((filter: any) => {
            const tittle = (filter.cardTitle).replace(/\s/g, '')
            filterValues[tittle] = filter.selectedValue;
        });
        return filterValues;
    };

    async function handleApplyButtonForFilter(data: any) {
        const convertValue = convertToFilterValues(filterValue)
        if (
            convertValue['BillingCycle'] == "All" &&
            convertValue['Category'] == "All" &&
            convertValue['CreditPeriod'] == "All" &&
            convertValue['CustomLimits'] == "All" &&
            convertValue['DailyLimits'] == "All" &&
            convertValue['IsActive'] == "All" &&
            convertValue['IsApproved'] == "All" &&
            convertValue['Issuer'] == "All" &&
            convertValue['MaxLimit'] == "All" &&
            convertValue['NameOnCard'] == "All" &&
            convertValue['Number'] == "All" &&
            convertValue['SingleTransactionLimits'] == "All" &&
            convertValue['Type'] == "All" &&
            convertValue['ValidFrom'] == "All" &&
            convertValue['ValidThru'] == "All"
        ) {
            setFilteredData(orginalFilteredData)
        } else {
            const filteredData: any = orginalFilteredData && orginalFilteredData.filter((ele: any) => {
                const BillingCycleCondtion = convertValue['BillingCycle'] === 'All' || ele.Overview['Billing Cycle'] === convertValue['BillingCycle'];
                const CategoryCondtion = convertValue['Category'] === 'All' || ele.Overview.Category === convertValue['Category'];
                const CreditPeriodCondtion = convertValue['CreditPeriod'] === 'All' || ele.Overview['Credit Period'] === convertValue['CreditPeriod'];
                const CustomLimitsCondtion = convertValue['CustomLimits'] === 'All' || ele.TransactionLimits['Custom Limits'] === convertValue['CustomLimits'];
                const DailyLimitsCondtion = convertValue['DailyLimits'] === 'All' || ele.TransactionLimits['Daily Limits'] == convertValue['DailyLimits'];
                const IsActiveCondtion = convertValue['IsActive'] === 'All' || ele.Status['Is Active'] === convertValue['IsActive'];
                const IsApprovedCondtion = convertValue['IsApproved'] === 'All' || ele.Status['Is Approved'] === convertValue['IsApproved'];
                const IssuerCondtion = convertValue['Issuer'] === 'All' || ele.Details.Issuer === convertValue['Issuer'];
                const MaxLimitCondtion = convertValue['MaxLimit'] === 'All' || ele.TransactionLimits['Max Limit'] === convertValue['MaxLimit'];
                const NameOnCardCondtion = convertValue['NameOnCard'] === 'All' || ele.Details['Name on Card'] === convertValue['NameOnCard'];
                const NumberCondtion = convertValue['Number'] === 'All' || ele.Details['Number'] === convertValue['Number'];
                const SingleTransactionLimitsCondtion = convertValue['SingleTransactionLimits'] === 'All' || ele.TransactionLimits['Single Transaction Limits'] === convertValue['SingleTransactionLimits'];
                const TypeCondtion = convertValue['Type'] === 'All' || ele.Overview.Type === convertValue['Type'];
                const ValidFromCondtion = convertValue['ValidFrom'] === 'All' || ele.Details['Valid From'] === convertValue['ValidFrom'];
                const ValidThruCondtion = convertValue['ValidThru'] === 'All' || ele.Details['Valid Thru'] === convertValue['ValidThru'];


                return BillingCycleCondtion
                    && CategoryCondtion
                    && CreditPeriodCondtion
                    && CustomLimitsCondtion
                    && DailyLimitsCondtion
                    && IsActiveCondtion
                    && IsApprovedCondtion
                    && IssuerCondtion
                    && MaxLimitCondtion
                    && NameOnCardCondtion
                    && NumberCondtion
                    && SingleTransactionLimitsCondtion
                    && TypeCondtion
                    && ValidFromCondtion
                    && ValidThruCondtion
            });
            setFilteredData(filteredData);
        }
        setFilterOpen(false)

    }

    // filter functions end

    return (
        <>
            {/* <button onClick={downloadPDF}>Download PDF</button> */}
            <div >
                <div className='report-table-component'
                    style={isVisible ? {
                        marginTop: "20px",
                        overflowX: "auto",
                        // style={{ visibility: isVisible ? "visible" : "hidden" }}
                        overflowY: "unset",
                        msOverflowX: "unset",
                        height: "auto"
                    } : {
                        marginTop: "20px",
                        overflowX: "auto",
                    }}
                >
                    <table ref={conponentPDFMaster}>
                        <thead>
                            {/* <tr> */}
                            <th className='report-heading' style={{ paddingLeft: "10px", }}>Details</th>
                            <th className='report-heading' style={{ paddingLeft: "10px" }}>Overview</th>
                            <th className='report-heading' style={{ paddingLeft: "10px" }}>Transaction Limits</th>
                            <th className='report-heading' style={{ paddingLeft: "10px" }}>Status</th>
                            {
                                !isVisible &&
                                <th className='report-heading' style={{ paddingLeft: "10px", width: "300px" }}>Transaction Limits Mapping</th>
                            }
                            {/* </tr> */}
                        </thead>
                        <tbody>
                            {
                                filteredData.length === 0 ?
                                    <tr className='style-grid-differ'>
                                        <td></td>
                                        <td></td>
                                        <td style={{ textAlign: "center" }}>No records to show</td>
                                        <td></td>
                                        <td></td>
                                    </tr> :
                                    filteredData.map((data: any, index: any) => (
                                        <tr className='main-body-header' key={index} >
                                            <td className='report-details' >
                                                {Object.entries(data.Details).map(([key, value]: any) => (
                                                    <p key={key} style={{ whiteSpace: "normal", width: "280px" }}>
                                                        <i>{key}</i>  - {value}
                                                    </p>
                                                ))}
                                            </td>
                                            <td className='report-details' >
                                                {Object.entries(data.Overview).map(([key, value]: any) => (
                                                    <p key={key} style={{ width: "280px", whiteSpace: "normal" }}>
                                                        <i>{key}</i> - {value}
                                                    </p>
                                                ))}
                                            </td>
                                            <td className='report-details'>
                                                {Object.entries(data.TransactionLimits).map(([key, value]: any) => (
                                                    <p key={key} style={{ width: "300px" }}>
                                                        <i>{key}</i>  -  {value == 'NA' ? value : formatAmount(value)}
                                                    </p>
                                                ))}
                                            </td>
                                            <td className='report-details' >
                                                {Object.entries(data.Status).map(([key, value]: any) => (
                                                    <p key={key}>
                                                        <i>{key}</i> - {value}
                                                    </p>
                                                ))}
                                            </td>
                                            {
                                                !isVisible &&
                                                <td className='report-details' >
                                                    <div className='view-invoice-eyeicon' style={{ width: "270px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                        <IconButton
                                                            iconName={"ShowEye"}
                                                            height={"35px"}
                                                            width={"30px"}
                                                            fontSize={""}
                                                            className={"icons"}
                                                            cursor={"pointer"}
                                                            color={"red"}
                                                            backgroundColor={""}
                                                            hover={false}
                                                            handleClick={() => {
                                                                getExistingTransation(data.cardId)
                                                            }} />
                                                    </div>
                                                </td>
                                            }
                                        </tr>
                                    ))}
                        </tbody>


                    </table>
                </div>
            </div>
            {filterOpen &&
                <div className="right-side-filter-component" >
                    <ChartFilter
                        datas={storeFilterOptions}
                        updateFilterDatas={updateFilterDatas}
                        handleOpenClose={handleOpenClose}
                        openDropdownIndex={openDropdownIndex}
                        handleApplyButtonForFilter={() => { handleApplyButtonForFilter('') }}
                        setOpenRightSideFilter={() => { setFilterOpen(false) }}
                    />
                </div>
            }
            {
                transationMappingOpen &&
                <TransationMapping
                    transactionMapping={transactionMapping}
                    clearValue={(value: any) => {
                        setTransationMappingOpen(value)
                    }}
                />
            }
            {
                loadingScreen &&
                <Loading />
            }
        </>
    )
}



