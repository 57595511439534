export const gropuByOptions = [
    { label: "Organisation", value: "Organisation" },
    { label: "Year", value: "Year" },
    { label: "Region", value: "Region" },
    { label: "State", value: "State" },
    { label: "City", value: "City" },
];

export const paOrganisationCheckBoxValue = {
    "organisationName": true,
    "companyName": true,
    "year": true,
    "month": true,
    "amount": true,
    "region": true,
    "state": true,
    "city": true,
    "cardType": true,
    "paymentMode": true,
    "cardNetwork": true,
    "segments": true,
}

export const OrganisationfilterDatas: any = {
    "Organisation": [
        {
            cardTitle: "Organisation",
            cardType: "dropdown",
            selectedValue: "All",
            options: [
                { value: "All", label: "All" },
            ],
            isOpen: false
        },
        {
            cardTitle: "Y value setting",
            cardType: "dropdown",
            selectedValue: "Count",
            options: [
                { value: "Count", label: "Count" },
                { value: "Value", label: "Value (cr)" },
                { value: "Percentage", label: "Percentage" },
            ],
            isOpen: false
        },
    ],
    "Year": [
        {
            cardTitle: "Organisation",
            cardType: "dropdown",
            selectedValue: "All",
            options: [
                { value: "All", label: "All" },
            ],
            isOpen: false
        },
        {
            cardTitle: "Year",
            cardType: "dropdown",
            selectedValue: "All",
            options: [
                { value: "All", label: "All" },
            ],
            isOpen: false
        },
        {
            cardTitle: "Y value setting",
            cardType: "dropdown",
            selectedValue: "Count",
            options: [
                { value: "Count", label: "Count" },
                { value: "Value", label: "Value (cr)" },
                { value: "Percentage", label: "Percentage" },
            ],
            isOpen: false
        },
    ],
    "Region": [
        {
            cardTitle: "Organisation",
            cardType: "dropdown",
            selectedValue: "All",
            options: [
                { value: "All", label: "All" },
            ],
            isOpen: false
        },
        {
            cardTitle: "Region",
            cardType: "dropdown",
            selectedValue: "All",
            options: [
                { value: "All", label: "All" },
                { value: "North", label: "North" },
                { value: "South", label: "South" },
                { value: "East", label: "East" },
                { value: "West", label: "West" },
            ],
            isOpen: false
        },
        {
            cardTitle: "Y value setting",
            cardType: "dropdown",
            selectedValue: "Count",
            options: [
                { value: "Count", label: "Count" },
                { value: "Value", label: "Value (cr)" },
                { value: "Percentage", label: "Percentage" },
            ],
            isOpen: false
        },
    ],
    "State": [
        {
            cardTitle: "Organisation",
            cardType: "dropdown",
            selectedValue: "All",
            options: [
                { value: "All", label: "All" },
            ],
            isOpen: false
        },
        {
            cardTitle: "Region",
            cardType: "dropdown",
            selectedValue: "All",
            options: [
                { value: "All", label: "All" },
                { value: "North", label: "North" },
                { value: "South", label: "South" },
                { value: "East", label: "East" },
                { value: "West", label: "West" },
            ],
            isOpen: false
        },
        {
            cardTitle: "State",
            cardType: "dropdown",
            selectedValue: "All",
            options: [
                { value: "All", label: "All" },
            ],
            isOpen: false
        },
        {
            cardTitle: "Y value setting",
            cardType: "dropdown",
            selectedValue: "Count",
            options: [
                { value: "Count", label: "Count" },
                { value: "Value", label: "Value (cr)" },
                { value: "Percentage", label: "Percentage" },
            ],
            isOpen: false
        },
    ],
    "City": [
        {
            cardTitle: "Organisation",
            cardType: "dropdown",
            selectedValue: "All",
            options: [
                { value: "All", label: "All" },
            ],
            isOpen: false
        },
        {
            cardTitle: "Region",
            cardType: "dropdown",
            selectedValue: "All",
            options: [
                { value: "All", label: "All" },
                { value: "North", label: "North" },
                { value: "South", label: "South" },
                { value: "East", label: "East" },
                { value: "West", label: "West" },
            ],
            isOpen: false
        },
        {
            cardTitle: "State",
            cardType: "dropdown",
            selectedValue: "All",
            options: [
                { value: "All", label: "All" },
            ],
            isOpen: false
        },
        {
            cardTitle: "Y value setting",
            cardType: "dropdown",
            selectedValue: "Count",
            options: [
                { value: "Count", label: "Count" },
                { value: "Value", label: "Value (cr)" },
                { value: "Percentage", label: "Percentage" },
            ],
            isOpen: false
        },
    ],
}

export const coloumDataSample = {
    field: '', headerName: '', headerClass: "custom-header", resizable: true, suppressMovable: true, filter: true, filterParams: { filterOptions: ['contains', 'equals'] }, cellStyle: { textAlign: 'center' }, suppressSizeToFit: false,
}

export const organizationVsCompaniesOptions = {
    maintainAspectRatio: false,
    type: 'line',
    responsive: true,
    layout: {},
    scales: {
        x: {
            title: {
                display: true,
                text: '',
                font: {
                    color: '#333333',
                    weight: 'bold',                    
                    size: 14, // You can adjust the font size as needed
                    letterSpacing: 3, // Adjust the letter spacing
                },
            },
            grid: {
                display: false,
                stacked: true,
                barPercentage: 1.5,
                categoryPercentage: 0.8,
                barThickness: 10,
            },
        },
        y: {
            title: {
                display: true,
                beginAtZero: true,
                font: {
                    color: '#333333',
                    weight: 'bold',                    
                    size: 14, // You can adjust the font size as needed
                    letterSpacing: 3, // Adjust the letter spacing
                },
                padding: {
                    bottom: 30, // Adjust the value as needed to control the spacing
                 },
                text: 'Transaction Value ( Count )',
            },
            grid: {
                display: true,
                stacked: true,
                color: '#B3CAE1',
                barPercentage: 1.5,
                categoryPercentage: 0.8,
                barThickness: 10,
            },
        },
    },
    plugins: {
        tooltip: {
            enabled: false
        },
        legend: {
            display: false,
            position: 'bottom' as const,
        },
        title: {
            display: false,
            font: {
                weight: 'bold',
                size: 25,
            },
            padding: 10,
            text: 'Organisation Vs Companies',
        },
    },
};

export const organizationVsCompaniesData = {
    labels: [],
    datasets: [
        {
            barPercentage: 1.1,
            barThickness: 25,
            data: [],
            backgroundColor: [],
            borderColor: [],
            borderWidth: 1
        }
    ]
};

// export const gropuByOptions: Record<string, { label: string; value: string }[]> = {
//     "Organisation": [
//         { label: "Organisation", value: "Organisation" },
//         { label: "Year", value: "Year" },
//         { label: "Region", value: "Region" },
//         { label: "State", value: "State" },
//         { label: "City", value: "City" },
//     ],
//     "Year": [
//         { label: "Organisation", value: "Organisation" },
//         { label: "Year", value: "Year" },
//         { label: "Region", value: "Region" },
//         { label: "State", value: "State" },
//         { label: "City", value: "City" },
//     ],
//     "Region": [
//         { label: "Organisation", value: "Organisation" },
//         { label: "Year", value: "Year" },
//         { label: "Region", value: "Region" },
//         { label: "State", value: "State" },
//         { label: "City", value: "City" },
//     ],
//     "State": [
//         { label: "Organisation", value: "Organisation" },
//         { label: "Year", value: "Year" },
//         { label: "Region", value: "Region" },
//         { label: "State", value: "State" },
//         { label: "City", value: "City" },
//     ],
//     "City": [
//         { label: "Organisation", value: "Organisation" },
//         { label: "Year", value: "Year" },
//         { label: "Region", value: "Region" },
//         { label: "State", value: "State" },
//         { label: "City", value: "City" },
//     ],
// };