import { useEffect, useState } from 'react'
import useAuth from '../../services/hooks/useauth';
import useAxiosPrivate from "../../services/hooks/useaxios-private";
import CompanyAdminReports from '../../components/reports/company-admin-reports/company-admin-reports';
import '../../styles/reports/reports.scss';

function Reports() {
    const [navbarTitle, setNavbarTitle] = useState("");
    const { auth } = useAuth();
    const axiosPrivate = useAxiosPrivate();

    useEffect(() => {
        if (auth.UserPrivilege) {
            setNavbarTitle(auth.UserPrivilege)
        } else {
            fetchData()
        }
    }, [])

    const fetchData = async () => {
        try {
            const response: any = await axiosPrivate.get('/user/get-privilege');
            setNavbarTitle(response.data.UserPrivilege)
        } catch (error) {
            console.log(error);
        }
    }

    return (
        // <div className='dashboard-component'>
        <div>

            {/* {
        (navbarTitle === "FingertipAdmin" || navbarTitle === "Customer maker" || navbarTitle === "Customer authorizer") &&
        <ProductAdminDashboard />
      }
      {
        (navbarTitle === "BankAdmin") &&
        <BankAdminDashboard />
      } */}
            {
                (navbarTitle === "ApprovedCompanyAdmin" || navbarTitle === "Approver" || navbarTitle === "Checker" || navbarTitle === "Maker" || navbarTitle === "Recommender") &&
                <CompanyAdminReports />
            }
        </div>
        // </div>
    )
}

export default Reports;
