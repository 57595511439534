import React, { useCallback, useEffect, useState, useRef } from 'react'
// import '../../../styles/dashboard/product-admin-dashboard.scss';
import '../../../styles/dashboard/log-report-dashboard/user-log-dashboard.scss';
import '../../../styles/line-chart.scss';
import VerticalBarChart from '../../common-component/charts/vertical-barchart';

import { v4 as uuidv4 } from 'uuid';
import useAxiosPrivate from "../../../services/hooks/useaxios-private";
import useAuth from '../../../services/hooks/useauth';
import { AiOutlineExpandAlt } from 'react-icons/ai';
import { ReactComponent as Grid } from '../../../assets/icons/grid.svg'
import { ReactComponent as Download } from '../../../assets/icons/download.svg'
import { ReactComponent as SuccessIcon } from '../../../assets/icons/successIcon.svg'
import { ReactComponent as InformationIcon } from '../../../assets/icons/information-icon.svg'
import { ReactComponent as WarningIcon } from '../../../assets/icons/warning.svg'
import { ReactComponent as FailureIcon } from '../../../assets/icons/failure.svg'
import axios from 'axios';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import DoughnutPieChart from '../../common-component/charts/doughnut-pie-chart';
import ChartAgGrid, { AgGridRef } from '../../common-component/charts/chart-ag-grid';
import { userEngagementEventOptions } from '../../../config/dashboard/user-logs/user-log-datas';
import moment from 'moment';
import LineChart from '../../common-component/charts/line-chart';
import { ReactComponent as RequestIcon } from '../../../assets/icons/request-icon.svg'
import { ReactComponent as DeclineIcon } from '../../../assets/icons/decline-icon.svg'
import { ReactComponent as SuspiciousIcon } from '../../../assets/icons/suspicious-icon.svg'

interface Record {
    id: number;
    RequestMethod: string;
    RequestURL: string;
    RequestHeaders: string;
    RequestPayload: string;
    ResponseStatusCode: string;
    ResponseTime: string;
    timestamp: string;
    user_id: string;
    createdAt: string;
    updatedAt: string;
}
interface CommonRecord {
    id: number;
    ipAddress: string;
    IntegratorName: string;
    IntegrationEndpoint: string;
    EventType: string;
    RequestParameters: string;
    ResponseData: string;
    ResponseTime: string;
    timestamp: string;
    user_id: string;
}
interface ApplicationErrorRecord {
    id: number;
    RequestURL: string;
    ResponseStatusCode: string;
    ResponseTime: string;
    user_id: string;
    createdAt: string;

}
function UserLogDashboard() {
    const currentDate = new Date();
    const axiosPrivate = useAxiosPrivate();
    const { auth } = useAuth();

    const userRegistrationEventsRef = useRef<AgGridRef>(null);


    // Chart And Grid Show
    const [chartViewStatus, setChartViewStatus] = useState({
        "utilizationCard": true,
        "billGeneration": true,
        "utilizationPayment": true,
        "utilizationSegments": true,
        "utilizationVendor": true,
    });

    //Minimize and Maximize 
    const [maximizeStatus, setMaximizeStatus] = useState({
        "isBarchart": true,
        "billGenerationDate": true,
        "utilizationPayment": true,
        "eventsReport": true,
        "retentionEvents": true,
        "payableEvents": true,
        "externalEvents": true,
        "applicationErrors": true,

    });
    const [gridView, setGridView] = useState(false)
    const [payableEventgridView, setPayableEventgridView] = useState(false)
    const [userEngagementgridView, setUserEngagementgridView] = useState(false)
    const [userRetentionGridView, setUserRetentionGridView] = useState(false)
    const [rowData, setRowData] = useState<Record[]>([]);
    const [originalRowData, setOriginalRowData] = useState<Record[]>([]);
    const [utilizationPaymentModeData, setUtilizationPaymentModeData] = useState<any>({ corporatePercentage: "0", netBankingPercentage: "0" });
    const todayDate = moment(new Date()).format('YYYY-MM-DD');
    const [requestSelectedDate, setRequestSelectedDate] = useState(todayDate);
    const [retensionSelectedDate, setRetensionSelectedDate] = useState(todayDate);
    const [requestlabels, setRequestLabels] = useState<any>();
    const [requestdata, setRequestData] = useState([]);
    const [requestFilterData, setRequestFilterData] = useState<any>();
    const [retentionEventsData, setRetentionEventsData] = useState<any>([]);

    const [performanceSelectedDate, setPerformanceSelectedDate] = useState(todayDate);
    const [performanceLabels, setPerformanceLabels] = useState<any>();
    const [performancedata, setPerformanceData] = useState<any>([]);
    const [paymentData, setPaymentData] = useState<any>();
    const [performanceFilterData, setPerformanceFilterData] = useState<any>();
    const [requestCountDatas, setRequestCountDatas] = useState<any>({
        successCount: "",
        informationCount: "",
        warningCount: "",
        failureCount: ""
    });

    const [requestAndResponseCountDatas, setRequestAndResponseCountDatas] = useState<any>({
        successCount: "",
        failureCount: ""
    });
    const [externalApiEventsDatas, setExternalApiEventsDatas] = useState<any>({ externalEventsPercentage: "0" });
    const [applicationCrashesDatas, setApplicationCrashesDatas] = useState<any>({ applicationCrashesPercentage: "0" });
    const [payableEventsDatas, setPayableEventsDatas] = useState<any>({ payableEventsDatasPercentage: "0" });
    const [externalApigridView, setExternalApiGridView] = useState(false)
    const [applicationErrorGridView, setApplicationErrorGridView] = useState(false)
    const [externalApirowData, setExternalApirowData] = useState<CommonRecord[]>([]);
    const [originalExternalApiRowData, setOriginalExternalApiRowData] = useState<CommonRecord[]>([]);
    const [applicationErrorRowData, setApplicationErrorRowData] = useState<ApplicationErrorRecord[]>([]);
    const [originalApplicationErrorRowData, setOriginalApplicationErrorRowData] = useState<ApplicationErrorRecord[]>([]);
    const [userRetentionRowData, setUserRetentionRowData] = useState<any[]>([]);
    const [originalUserRetentionRowData, setOriginalUserRetentionRowData] = useState<any[]>([]);

    // Get Utilization PaymentMode Datas

    async function getUtilizationPaymentModeDatas() {
        try {
            const currentDate = new Date();

            // Get the current month and year
            const currentMonth = currentDate.toLocaleString('default', { month: 'long' });
            const currentYear = currentDate.getFullYear().toString();

            const res = await axiosPrivate.post('user/activation/events', {
                "month": currentMonth,
                "year": currentYear
            })
            setOriginalRowData(res.data.records)
            setRowData(res.data.records)
            let corporatePercentage: any = []
            Object.values(res.data.statusCodePercentages).map((e: any) => {
                corporatePercentage.push(parseInt(e.slice(0, -1)))
            })
            let netBankingPercentage: any = []
            Object.values(res.data.statusCodeCounts).map((e: any) => {
                netBankingPercentage.push(e)
            })
            setUtilizationPaymentModeData({ corporatePercentage: corporatePercentage, netBankingPercentage: netBankingPercentage })

        } catch (error) {
            console.error("Error occurred while retrieving getUtilizationPaymentModeDatas.");
        }
    }

    useEffect(() => {
        getUtilizationPaymentModeDatas()
        getRetentionDatas()
    }, [])

    async function getUserRequestDetails() {
        try {
            const currentDate = new Date();

            // Get the current month and year
            const formattedCurrentDate = `${currentDate.getDate().toString().padStart(2, '0')}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getFullYear()}`;
            const currentMonth = currentDate.toLocaleString('default', { month: 'long' });
            const currentYear = currentDate.getFullYear().toString();
            const response = await axiosPrivate.post(`header/req-and-res/counts`, {
                "month": currentMonth,
                "year": currentYear,
                "date": formattedCurrentDate
            });
            if (response.status === 200) {
                setRequestAndResponseCountDatas({
                    successCount: response.data.groupedRecords.success.count,
                    failureCount: response.data.groupedRecords.failure.count
                })
            }
        } catch (error) {
            console.log(error)
        }
    }
    async function getUserPayments() {
        try {
            const currentDate = new Date();

            // Get the current month and year
            const formattedCurrentDate = `${currentDate.getDate().toString().padStart(2, '0')}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getFullYear()}`;
            const currentMonth = currentDate.toLocaleString('default', { month: 'long' });
            const currentYear = currentDate.getFullYear().toString();
            const response = await axiosPrivate.post(`/header/payments/counts`, {
                "month": currentMonth,
                "year": currentYear,
                "date": formattedCurrentDate
            });
            if (response.status === 200) {
                setPaymentData(response.data.counts)
            }
        } catch (error) {
            console.log(error);
        }
    }

    // external api events 

    async function getExternalApiEventDatas() {
        try {
            const currentDate = new Date();

            // Get the current month and year
            const currentMonth = currentDate.toLocaleString('default', { month: 'long' });
            const currentYear = currentDate.getFullYear().toString();
            const formattedCurrentDate = `${currentDate.getDate().toString().padStart(2, '0')}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getFullYear()}`;

            const res = await axiosPrivate.post('external/apis/events', {
                "month": currentMonth,
                "year": currentYear,
                "date": formattedCurrentDate
            })

            const counts = res.data.counts;
            const externalEventsPercentage = Object.values(counts).map((e: any) => parseInt(e));

            const externalRecords = res.data.records;
            const combinedExternalRecords: any[] = Object.values(externalRecords).flatMap((recordArray: any) => recordArray);

            setOriginalExternalApiRowData(combinedExternalRecords)
            setExternalApirowData(combinedExternalRecords)

            setExternalApiEventsDatas({ externalEventsPercentage });

            setExternalApiEventsDatas({ externalEventsPercentage: externalEventsPercentage })

        } catch (error) {
            console.error("Error occurred while retrieving getUtilizationPaymentModeDatas.");
        }
    }

    //Application Errors and crashes
    async function getApplicationErrorsDatas() {
        try {
            const currentDate = new Date();

            // Get the current month and year
            const currentMonth = currentDate.toLocaleString('default', { month: 'long' });
            const currentYear = currentDate.getFullYear().toString();
            const formattedCurrentDate = `${currentDate.getDate().toString().padStart(2, '0')}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getFullYear()}`;

            const res = await axiosPrivate.post('app/errors/crashes', {
                "month": currentMonth,
                "year": currentYear,
                "date": formattedCurrentDate
            })

            const counts = res.data.counts;
            const applicationCrashesDatas = Object.values(counts).map((e: any) => parseInt(e));

            const externalRecords = res.data.records;
            const combinedExternalRecords: any[] = Object.values(externalRecords).flatMap((recordArray: any) => recordArray);

            setOriginalApplicationErrorRowData(combinedExternalRecords)
            setApplicationErrorRowData(combinedExternalRecords)
            console.log(combinedExternalRecords)

            setApplicationCrashesDatas({ applicationCrashesDatas });

            setApplicationCrashesDatas({ applicationCrashesPercentage: applicationCrashesDatas })

        } catch (error) {
            console.error("Error occurred while retrieving getUtilizationPaymentModeDatas.");
        }
    }
    //User Retention Events
    const handleRetensionDateChange = (event: any) => {
        const dateValue = event.target.value;
        setRetensionSelectedDate(dateValue);
    };
    useEffect(() => {
        // Function to make the API call
        getRetentionDatas()
        // Set up the interval to make the API call every 3 minutes
        const intervalId = setInterval(() => {
            getRetentionDatas();
        }, 1 * 60 * 1000); // 3 minutes in milliseconds

        // Clean up the interval on component unmount
        return () => clearInterval(intervalId);
    }, [retensionSelectedDate]);

    async function getRetentionDatas() {
        try {
            const formattedDay = retensionSelectedDate ? new Date(retensionSelectedDate).toLocaleDateString() : ''; // Format date if selected
            const formattedDate = retensionSelectedDate ? new Date(retensionSelectedDate).toLocaleDateString('en-GB', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
            }).replace(/\//g, '-') : "";
            const response: any = await axiosPrivate.post('/user/retention/events',
                {
                    "month": formattedDay ? new Date(retensionSelectedDate).toLocaleString('default', { month: 'long' }) : '',
                    "year": formattedDay ? new Date(retensionSelectedDate).getFullYear().toString() : '',
                    "date": formattedDate ? formattedDate : ''
                }
            );
            setRetentionEventsData(response.data.sessionRecords.loggedIn.count)
            setUserRetentionRowData(response.data.sessionRecords.loggedIn.records)
            setOriginalUserRetentionRowData(response.data.sessionRecords.loggedIn.records)
        } catch (error) {
            console.error("Error occurred while retrieving getRetentionData.");
        }
    }


    // const handleDownloadExcel = (title: any) => {
    //     switch (title) {
    //         case 'utilizationCard':
    //             userRegistrationEventsRef.current!.onBtExport();
    //             break;


    //     }
    // }

    // const HandleBarChartClick = (barValue: any) => {
    //     const filteredRecords = rowData.filter(record => {
    //         return record.ResponseStatusCode === barValue;
    //     });

    //     setFilteredData(filteredRecords);
    //     // Additional logic if needed
    //     console.log("=========>",filteredRecords, filteredData, "==========>")
    // };

    const CustomEllipsisRenderer: React.FC<any> = (params: any) => {
        const value = params.value || "";
        const maxLength = 30; // Maximum characters allowed before truncation

        let displayValue = value;
        if (value.length > maxLength) {
            displayValue = value.substring(0, maxLength) + "...";
        }

        const cellStyle: React.CSSProperties = {
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            overflow: "hidden",
            paddingRight: "3px",
        };

        return <div style={cellStyle}>{displayValue}</div>;
    };

    useEffect(() => {
        // Function to make the API call
        getUserLogDetails();
        getUserRequestDetails();
        getUserPayments();
        getExternalApiEventDatas()
        getApplicationErrorsDatas()
        // Set up the interval to make the API call every 1 minutes
        const intervalId = setInterval(() => {
            getUserLogDetails();
            getUserRequestDetails();
            getUserPayments();
            getExternalApiEventDatas()
            getApplicationErrorsDatas()

        }, 1 * 60 * 1000); // 1 minutes in milliseconds

        // Clean up the interval on component unmount
        return () => clearInterval(intervalId);
    }, []);

    async function getUserLogDetails() {
        try {
            const currentDate = new Date();

            // Get the current month and year
            const formattedCurrentDate = `${currentDate.getDate().toString().padStart(2, '0')}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getFullYear()}`;
            const currentMonth = currentDate.toLocaleString('default', { month: 'long' });
            const currentYear = currentDate.getFullYear().toString();
            const response = await axiosPrivate.post(`header/records/counts`, {
                "month": currentMonth,
                "year": currentYear,
                "date": formattedCurrentDate
            });
            if (response.status === 200) {
                setRequestCountDatas({
                    successCount: response.data.successRecordCount,
                    informationCount: response.data.informationRecordCount,
                    warningCount: response.data.warningRecordCount,
                    failureCount: response.data.failureRecordCount
                })
            }
        } catch {

        }
    }

    //line chart

    const formatTime12Hour = (time24Hour: any) => {
        const hour = time24Hour % 12 || 12; // Convert 0 to 12
        const period = time24Hour >= 12 ? 'PM' : 'AM';
        return `${hour.toString().padStart(2, '0')}.00 ${period}`;
    };

    //Request line chart function
    const handleRequestDateChange = (event: any) => {
        const dateValue = event.target.value;
        setRequestSelectedDate(dateValue);
    };

    useEffect(() => {
        let newRecord: any = [];
        let newhrsRecord: any = [''];
        let startHour = 24 // currentDate.getHours();
        let endHour = 0;
        const data = requestFilterData && requestFilterData.filter((e: any) => e.hour >= endHour && e.hour <= startHour)
        data && data.map((e: any) => {
            let dataeve = parseFloat(e.totalCount);
            let datahrs = formatTime12Hour(e.hour);
            newRecord.push(dataeve);
            newhrsRecord.push(datahrs);
        });
        // Insert '0' at the second-to-last position
        newRecord.splice(newRecord.length, 0, 0);
        newhrsRecord.push('');
        const reversedMs = newRecord.reverse();
        const reversedHrs = newhrsRecord.reverse();
        setRequestData(reversedMs);
        setRequestLabels(reversedHrs);
    }, [requestFilterData]);

    useEffect(() => {
        // Function to make the API call
        fetchRequestData()
        // generateTimeList()
        // Set up the interval to make the API call every 3 minutes
        const intervalId = setInterval(() => {
            fetchRequestData();
            // generateTimeList()
        }, 1 * 60 * 1000); // 3 minutes in milliseconds

        // Clean up the interval on component unmount
        return () => clearInterval(intervalId);
    }, [requestSelectedDate]);

    const fetchRequestData = async () => {
        try {
            const formattedDay = requestSelectedDate ? new Date(requestSelectedDate).toLocaleDateString() : ''; // Format date if selected
            const formattedDate = requestSelectedDate ? new Date(requestSelectedDate).toLocaleDateString('en-GB', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
            }).replace(/\//g, '-') : "";
            const response: any = await axiosPrivate.post('/events/report',
                {
                    "month": formattedDay ? new Date(requestSelectedDate).toLocaleString('default', { month: 'long' }) : '',
                    "year": formattedDay ? new Date(requestSelectedDate).getFullYear().toString() : '',
                    "date": formattedDate ? formattedDate : ''
                }
            );
            setRequestFilterData(response.data.groupedRecords);

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };


    // Application performance issues per hour

    const handlePerformanceDateChange = (event: any) => {
        const dateValue = event.target.value;
        setPerformanceSelectedDate(dateValue);
    };
    useEffect(() => {
        let newRecord: any = [];
        let newhrsRecord: any = [''];
        let startHour = 24 // currentDate.getHours();
        let endHour = 0;
        const data = performanceFilterData && performanceFilterData.filter((e: any) => e.hour >= endHour && e.hour <= startHour)
        data && data.map((e: any) => {
            let dataeve = parseFloat(e.averageMs.replace(" ms", ""));
            let datahrs = formatTime12Hour(e.hour);
            newRecord.push(dataeve);
            newhrsRecord.push(datahrs);
        });
        // Insert '0' at the second-to-last position
        newRecord.splice(newRecord.length, 0, 0);
        newhrsRecord.push('');
        const reversedMs = newRecord.reverse();
        const reversedHrs = newhrsRecord.reverse();
        setPerformanceData(reversedMs);
        setPerformanceLabels(reversedHrs);
    }, [performanceFilterData]);

    useEffect(() => {
        // Function to make the API call
        fetchPerformanceData()
        // Set up the interval to make the API call every 3 minutes
        const intervalId = setInterval(() => {
            fetchPerformanceData();
        }, 1 * 60 * 1000); // 3 minutes in milliseconds

        // Clean up the interval on component unmount
        return () => clearInterval(intervalId);
    }, [performanceSelectedDate]);

    const fetchPerformanceData = async () => {
        try {
            const formattedDay = performanceSelectedDate ? new Date(performanceSelectedDate).toLocaleDateString() : ''; // Format date if selected
            const formattedDate = performanceSelectedDate ? new Date(performanceSelectedDate).toLocaleDateString('en-GB', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
            }).replace(/\//g, '-') : "";
            const response: any = await axiosPrivate.post('/application/performance',
                {
                    "month": formattedDay ? new Date(performanceSelectedDate).toLocaleString('default', { month: 'long' }) : '',
                    "year": formattedDay ? new Date(performanceSelectedDate).getFullYear().toString() : '',
                    "date": formattedDate ? formattedDate : ''
                }
            );
            console.log(response.data.groupedCounts[0].categorizedCounts);
            setPerformanceFilterData(response.data.groupedCounts);

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    return (
        <div className='user-log-dashboard'>
            {
                maximizeStatus.eventsReport &&
                <div className='header-datas'>
                    <div className='request-data'>
                        <div className='request-name-header'>{"Request"}</div>
                        <div className='request-data-header'>
                            <div className='datas'>
                                <div className='success-information'>
                                    <div className='request-success'>
                                        <SuccessIcon className='success-icon' />
                                        <div className='response-data'>
                                            <div style={{ fontSize: "30px", color: "#04C451" }}>{requestCountDatas?.successCount}</div>
                                            <div style={{ fontSize: "20px", color: "#04C451" }}>{"Success"}</div>
                                        </div>
                                    </div>
                                    <div className='request-information'>
                                        <InformationIcon className='success-icon' />
                                        <div className='response-data'>
                                            <div style={{ fontSize: "30px", color: "#0055D4" }}>{requestCountDatas?.informationCount}</div>
                                            <div style={{ fontSize: "20px", color: "#0055D4" }}>{"Information"}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className='failure-information'>
                                    <div className='request-warning'>
                                        <WarningIcon className='success-icon' />
                                        <div className='response-data'>
                                            <div style={{ fontSize: "30px", color: "#A0C40D" }}>{requestCountDatas?.warningCount}</div>
                                            <div style={{ fontSize: "20px", color: "#A0C40D" }}>{"Warning"}</div>
                                        </div>

                                    </div>
                                    <div className='request-failure'>
                                        <FailureIcon className='success-icon' />
                                        <div className='response-data'>
                                            <div style={{ fontSize: "30px", color: "#FF4056" }}>{requestCountDatas?.failureCount}</div>
                                            <div style={{ fontSize: "20px", color: "#FF4056" }}>{"Failure"}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className='request-Subscribers'>

                        <div className='request-data-header'>
                            <div className='datas'>
                                <div className='success-information'>
                                    <div className='request-success'>
                                        <SuccessIcon className='success-icon' />
                                        <div className='response-data'>
                                            <div style={{ fontSize: "30px", color: "#04C451" }}>{requestAndResponseCountDatas?.successCount}</div>
                                            <div style={{ fontSize: "20px", color: "#04C451" }}>{"Success"}</div>
                                        </div>
                                    </div>
                                    <div className='request-failure'>
                                        <FailureIcon className='success-icon' />
                                        <div className='response-data'>
                                            <div style={{ fontSize: "30px", color: "#FF4056" }}>{requestAndResponseCountDatas?.failureCount}</div>
                                            <div style={{ fontSize: "20px", color: "#FF4056" }}>{"Failure"}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='Subscribers-data-header'>

                            <div className='datas'>
                                <div className='success-information'>
                                    <div className='subscribers-request'>
                                        <RequestIcon className='success-icon' />
                                        <div className='response-data'>
                                            <div style={{ fontSize: "30px", color: "#A22DFF" }}>{"560"}</div>
                                            <div style={{ fontSize: "20px", color: "#A22DFF" }}>{"Request"}</div>
                                        </div>
                                    </div>
                                    <div className='request-warning'>
                                        {/* <div className='right-decoration'></div> */}
                                        <DeclineIcon className='success-icon' />
                                        <div className='response-data'>
                                            <div style={{ fontSize: "30px", color: "#A0C40D" }}>{"120"}</div>
                                            <div style={{ fontSize: "20px", color: "#A0C40D" }}>{"Decline"}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='request-name-header'>{"Request"}</div>
                        <div className='Subscribers-name-header'>{"Subscribers"}</div>
                    </div>
                </div>
            }
            {
                maximizeStatus.eventsReport &&
                <div className='transactions-layout'>
                    <div className='transactions-header'>
                        {"Transactions"}
                    </div>
                    <div className='transactions-payments'>
                        <div className='payment-history'>
                            <div className='payment-success'>
                                <SuccessIcon className='success-icon' />
                                <div className='response-data'>
                                    <div style={{ fontSize: "30px", color: "#04C451" }}>{paymentData?.success}</div>
                                    <div style={{ fontSize: "20px", color: "#04C451" }}>{"Success"}</div>
                                </div>
                            </div>
                            <div className='payment-failure'>
                                <FailureIcon className='success-icon' />
                                <div className='response-data'>
                                    <div style={{ fontSize: "30px", color: "#FF4056" }}>{paymentData?.failure}</div>
                                    <div style={{ fontSize: "20px", color: "#FF4056" }}>{"Failure"}</div>
                                </div>

                            </div>
                        </div>
                        <div className='transactions-payments-header'>{"Payments"}</div>
                    </div>
                    <div className='transactions-suspicious'>
                        <div className='suspicious-history'>
                            <div className='suspicious-success'>
                                <SuspiciousIcon className='success-icon' />
                                <div className='response-data'>
                                    <div style={{ fontSize: "30px", color: "#FF5508" }}>{"522"}</div>
                                </div>
                            </div>
                        </div>
                        <div className='suspicious-payments-header'>{"Suspicious"}</div>
                    </div>
                    <div className='transactions-payments'>
                        <div className='payment-history'>
                            <div className='payment-success'>
                                <SuccessIcon className='success-icon' />
                                <div className='response-data'>
                                    <div style={{ fontSize: "30px", color: "#04C451" }}>{"14"}</div>
                                    <div style={{ fontSize: "20px", color: "#04C451" }}>{"Success"}</div>
                                </div>
                            </div>
                            <div className='payment-failure'>
                                <FailureIcon className='success-icon' />
                                <div className='response-data'>
                                    <div style={{ fontSize: "30px", color: "#FF4056" }}>{"2"}</div>
                                    <div style={{ fontSize: "20px", color: "#FF4056" }}>{"Failure"}</div>
                                </div>

                            </div>
                        </div>
                        <div className='transactions-payments-header'>{"Settlements"}</div>
                    </div>
                </div>
            }


            {/* User Registration Events and  User Engagement Events*/}
            {
                (maximizeStatus.billGenerationDate || maximizeStatus.utilizationPayment) &&
                <div className='bill-generation-and-payment' style={{ height: ((maximizeStatus.billGenerationDate || maximizeStatus.utilizationPayment) && !maximizeStatus.isBarchart) ? "83vh" : "" }}>

                    {
                        maximizeStatus.billGenerationDate &&
                        <div className='utilization-payment' style={{ width: (maximizeStatus.billGenerationDate && !maximizeStatus.utilizationPayment) ? '100%' : "", height: (maximizeStatus.billGenerationDate && !maximizeStatus.utilizationPayment) ? '100%' : "" }}>
                            <div className='utilization-payment-title' style={{ left: (maximizeStatus.billGenerationDate && !maximizeStatus.utilizationPayment) ? "42%" : "", top: (maximizeStatus.billGenerationDate && !maximizeStatus.utilizationPayment) ? "20px" : "" }}>{"User Registration Events"}</div>
                            <div>
                                {
                                    gridView == false ?
                                        <>
                                            {
                                                utilizationPaymentModeData.netBankingPercentage == 0 ?
                                                    <div style={{ fontWeight: "bold" }}>No Records</div>
                                                    :
                                                    <DoughnutPieChart
                                                        width={(maximizeStatus.billGenerationDate && !maximizeStatus.utilizationPayment) ? '800px' : "500px"}
                                                        height={(maximizeStatus.billGenerationDate && !maximizeStatus.utilizationPayment) ? '550px' : "350px"}
                                                        options={[]}
                                                        orginalBarchartData={originalRowData}
                                                        HandleBarChartClick={(barValue) => {
                                                            // HandleBarChartClick(barValue)
                                                            console.log(barValue)
                                                            const a = originalRowData.filter((e) => e.ResponseStatusCode == barValue)
                                                            setGridView(true)
                                                            setRowData(a)
                                                        }}
                                                        data={{
                                                            labels: ["Res Code : 200", 'Res Code : 300', 'Res Code : 400', 'Res Code : 500'],
                                                            datasets: [
                                                                {
                                                                    label: ' Users Count',
                                                                    data: utilizationPaymentModeData.netBankingPercentage,
                                                                    // data: ["80", "20"],
                                                                    backgroundColor: ['#04C451', '#FF8700', '#4889F8', '#FF4056'],
                                                                    hoverBackgroundColor: ['green', '#a35804', 'darkblue', 'darkred'],
                                                                },
                                                            ],
                                                        }}
                                                    />

                                            }
                                        </>
                                        :

                                        <div className={(maximizeStatus.billGenerationDate && !maximizeStatus.utilizationPayment) ? 'user-logs-registration-events-resize' : 'user-logs-registration-events'}>
                                            <ChartAgGrid
                                                fileName={"Utilization Payment - Report"}
                                                ref={userRegistrationEventsRef}
                                                tabelRowData={rowData}
                                                tableColumnData={[
                                                    { field: 'ipAddress', headerName: "IP Address", cellStyle: { 'text-align': 'left' }, minWidth: 250, maxWidth: 250, cellRendererFramework: CustomEllipsisRenderer, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, },
                                                    { field: 'RequestMethod', headerName: "Request Method", cellStyle: { 'text-align': 'left' }, minWidth: 250, cellRendererFramework: CustomEllipsisRenderer, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, },
                                                    { field: 'RequestURL', headerName: "Request URL", cellStyle: { 'text-align': 'left' }, minWidth: 250, maxWidth: 250, cellRendererFramework: CustomEllipsisRenderer, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, },
                                                    { field: 'RequestHeaders', headerName: "Request Headers", cellStyle: { 'text-align': 'left' }, minWidth: 250, maxWidth: 250, cellRendererFramework: CustomEllipsisRenderer, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, },
                                                    { field: 'RequestPayload', headerName: "Request Payload", cellStyle: { 'text-align': 'left' }, minWidth: 250, maxWidth: 250, cellRendererFramework: CustomEllipsisRenderer, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, },
                                                    { field: 'ResponseStatusCode', headerName: "Response Status Code", cellStyle: { 'text-align': 'left' }, minWidth: 250, maxWidth: 250, cellRendererFramework: CustomEllipsisRenderer, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, },
                                                    { field: 'ResponseBody', headerName: "Response Body", cellStyle: { 'text-align': 'left' }, minWidth: 250, maxWidth: 250, cellRendererFramework: CustomEllipsisRenderer, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, },
                                                    { field: 'ResponseTime', headerName: "Response Time", cellStyle: { 'text-align': 'left' }, minWidth: 250, maxWidth: 250, cellRendererFramework: CustomEllipsisRenderer, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, },
                                                    { field: 'timestamp', headerName: "Time Stamp", cellStyle: { 'text-align': 'left' }, minWidth: 250, maxWidth: 250, cellRendererFramework: CustomEllipsisRenderer, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, },
                                                    { field: 'user_id', headerName: "User Login", cellStyle: { 'text-align': 'left' }, minWidth: 250, maxWidth: 250, cellRendererFramework: CustomEllipsisRenderer, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, },
                                                    { field: 'createdAt', headerName: "Created At", cellStyle: { 'text-align': 'left' }, minWidth: 250, maxWidth: 250, cellRendererFramework: CustomEllipsisRenderer, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, },
                                                    { field: 'updatedAt', headerName: "Updated At", cellStyle: { 'text-align': 'left' }, minWidth: 250, maxWidth: 250, cellRendererFramework: CustomEllipsisRenderer, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, },
                                                    // { field: 'Transaction Value(%)', cellStyle: { 'text-align': 'center' }, },
                                                ]}
                                                className={"User Registration Events"}
                                                paginationPageSize={(maximizeStatus.billGenerationDate && !maximizeStatus.utilizationPayment) ? 13 : 7}
                                            />
                                        </div>
                                }
                            </div>
                            <div className='expand-arrow' onClick={() => {
                                if (maximizeStatus.isBarchart == false) {
                                    setMaximizeStatus({
                                        "isBarchart": true,
                                        "billGenerationDate": true,
                                        "utilizationPayment": true,
                                        "eventsReport": true,
                                        "retentionEvents": true,
                                        "payableEvents": true,
                                        "externalEvents": true,
                                        "applicationErrors": true,

                                    })
                                } else {
                                    setMaximizeStatus({
                                        "isBarchart": false,
                                        "billGenerationDate": true,
                                        "utilizationPayment": false,
                                        "eventsReport": false,
                                        "retentionEvents": false,
                                        "payableEvents": false,
                                        "externalEvents": false,
                                        "applicationErrors": false,

                                    })
                                }
                            }}><AiOutlineExpandAlt style={{ fontSize: "30px" }} /></div>
                            <div className='features'>
                                {
                                    <Grid onClick={() => {
                                        if (gridView) {
                                            setGridView(false)
                                            setRowData(originalRowData)
                                        } else {
                                            setGridView(true)
                                        }
                                    }} />
                                    // <GraphIcon onClick={() => { }} />
                                }
                                <hr />
                                <Download className={`${chartViewStatus.utilizationPayment ? "disableState" : "activeState"}`} onClick={() => { }} />
                            </div>
                        </div>
                    }
                    {
                        maximizeStatus.utilizationPayment &&
                        <div className='utilization-payment' style={{ width: (!maximizeStatus.billGenerationDate && maximizeStatus.utilizationPayment) ? '100%' : "" }}>
                            <div className='utilization-payment-title' style={{ left: (maximizeStatus.utilizationPayment && !maximizeStatus.billGenerationDate) ? "42%" : "", top: (maximizeStatus.utilizationPayment && !maximizeStatus.billGenerationDate) ? "20px" : "" }}>{"User Engagement Events"}</div>
                            <div>
                                {
                                    userEngagementgridView == false ?
                                        <>
                                            {
                                                // !maximizeStatus.billGenerationDate == false ?
                                                // <div style={{ fontWeight: "bold" }}>No Records</div>
                                                // :
                                                <VerticalBarChart
                                                    width={(maximizeStatus.utilizationPayment && !maximizeStatus.billGenerationDate) ? '1200px' : "600px"}
                                                    height={(maximizeStatus.utilizationPayment && !maximizeStatus.billGenerationDate) ? '580px' : "350px"}
                                                    options={userEngagementEventOptions}
                                                    HandleBarChartClcik={(barValue) => {
                                                        console.log("first", barValue)
                                                    }
                                                    }
                                                    data={
                                                        {
                                                            labels: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
                                                            datasets: [{
                                                                barPercentage: 1.1,
                                                                barThickness: 40,
                                                                data: [50, 40, 20, 40, 10, 20, 45],
                                                                // data1: [45, 66, 22, 80, 89, 45, 45, 65, 70, 68, 43, 24],
                                                                backgroundColor: ["#04C451", "#FF4056", "#1686F6", "#A0C40D", "#FF75DF", "#216D9B", "#A22DFF"],
                                                                borderColor: ["#04C451", "#FF4056", "#1686F6", "#A0C40D", "#FF75DF", "#216D9B", "#A22DFF"],
                                                                borderWidth: 5
                                                            }]
                                                        }}
                                                    clickedEvent={false}


                                                />

                                            }
                                        </>
                                        :

                                        <div className={(maximizeStatus.utilizationPayment && !maximizeStatus.billGenerationDate) ? 'user-logs-registration-events-resize' : 'user-logs-registration-events'}>
                                            <ChartAgGrid
                                                fileName={"Utilization Payment - Report"}
                                                ref={userRegistrationEventsRef}
                                                tabelRowData={rowData}
                                                tableColumnData={[
                                                    { field: 'ipAddress', headerName: "IP Address", cellStyle: { 'text-align': 'left' }, minWidth: 250, maxWidth: 250, cellRendererFramework: CustomEllipsisRenderer, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, },
                                                    { field: 'RequestMethod', headerName: "Request Method", cellStyle: { 'text-align': 'left' }, minWidth: 250, cellRendererFramework: CustomEllipsisRenderer, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, },
                                                    { field: 'RequestURL', headerName: "Request URL", cellStyle: { 'text-align': 'left' }, minWidth: 250, maxWidth: 250, cellRendererFramework: CustomEllipsisRenderer, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, },
                                                    { field: 'RequestHeaders', headerName: "Request Headers", cellStyle: { 'text-align': 'left' }, minWidth: 250, maxWidth: 250, cellRendererFramework: CustomEllipsisRenderer, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, },
                                                    { field: 'RequestPayload', headerName: "Request Payload", cellStyle: { 'text-align': 'left' }, minWidth: 250, maxWidth: 250, cellRendererFramework: CustomEllipsisRenderer, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, },
                                                    { field: 'ResponseStatusCode', headerName: "Response Status Code", cellStyle: { 'text-align': 'left' }, minWidth: 250, maxWidth: 250, cellRendererFramework: CustomEllipsisRenderer, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, },
                                                    { field: 'ResponseBody', headerName: "Response Body", cellStyle: { 'text-align': 'left' }, minWidth: 250, maxWidth: 250, cellRendererFramework: CustomEllipsisRenderer, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, },
                                                    { field: 'ResponseTime', headerName: "Response Time", cellStyle: { 'text-align': 'left' }, minWidth: 250, maxWidth: 250, cellRendererFramework: CustomEllipsisRenderer, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, },
                                                    { field: 'timestamp', headerName: "Time Stamp", cellStyle: { 'text-align': 'left' }, minWidth: 250, maxWidth: 250, cellRendererFramework: CustomEllipsisRenderer, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, },
                                                    { field: 'user_id', headerName: "User Login", cellStyle: { 'text-align': 'left' }, minWidth: 250, maxWidth: 250, cellRendererFramework: CustomEllipsisRenderer, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, },
                                                    { field: 'createdAt', headerName: "Created At", cellStyle: { 'text-align': 'left' }, minWidth: 250, maxWidth: 250, cellRendererFramework: CustomEllipsisRenderer, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, },
                                                    { field: 'updatedAt', headerName: "Updated At", cellStyle: { 'text-align': 'left' }, minWidth: 250, maxWidth: 250, cellRendererFramework: CustomEllipsisRenderer, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, },
                                                    { field: 'Transaction Value(%)', cellStyle: { 'text-align': 'center' }, },
                                                ]}
                                                className={"User Registration Events"}
                                                paginationPageSize={(maximizeStatus.utilizationPayment && !maximizeStatus.billGenerationDate) ? 13 : 7}
                                            />
                                        </div>
                                }
                            </div>
                            <div className='expand-arrow' onClick={() => {
                                if (maximizeStatus.isBarchart == false) {
                                    setMaximizeStatus({
                                        "isBarchart": true,
                                        "billGenerationDate": true,
                                        "utilizationPayment": true,
                                        "eventsReport": true,
                                        "retentionEvents": true,
                                        "payableEvents": true,
                                        "externalEvents": true,
                                        "applicationErrors": true,


                                    })
                                } else {
                                    setMaximizeStatus({
                                        "isBarchart": false,
                                        "billGenerationDate": false,
                                        "utilizationPayment": true,
                                        "eventsReport": false,
                                        "retentionEvents": false,
                                        "payableEvents": false,
                                        "externalEvents": false,
                                        "applicationErrors": false,

                                    })
                                }
                            }}><AiOutlineExpandAlt style={{ fontSize: "30px" }} /></div>
                            <div className='features' style={{ opacity: "0.5", cursor: "not-allowed" }}>
                                {
                                    <Grid
                                    // onClick={() => {
                                    //     if (userEngagementgridView) {
                                    //         setUserEngagementgridView(false)
                                    //         setRowData(originalRowData)
                                    //     } else {
                                    //         setUserEngagementgridView(true)
                                    //     }
                                    // }} 
                                    />
                                    // <GraphIcon onClick={() => { }} />
                                }
                                <hr />
                                <Download className={`${chartViewStatus.utilizationPayment ? "disableState" : "activeState"}`} onClick={() => { }} />
                            </div>
                        </div>
                    }


                </div>
            }
            {/* User Retention Events and Payable Events*/}
            {
                (maximizeStatus.retentionEvents || maximizeStatus.payableEvents) &&
                <div className='bill-generation-and-payment' style={{ height: ((maximizeStatus.retentionEvents || maximizeStatus.payableEvents) && !maximizeStatus.isBarchart) ? "83vh" : "" }}>
                    {
                        maximizeStatus.retentionEvents &&
                        <div className='utilization-payment' style={{ width: (maximizeStatus.retentionEvents && !maximizeStatus.payableEvents) ? '100%' : "" }}>
                            <div className='utilization-payment-title' style={{ left: "25px" }}>{"User Retention Events"}</div>
                            <input type="date" className='user-log-retension' value={retensionSelectedDate} max={todayDate}
                                onChange={handleRetensionDateChange} />
                            <div>
                                {
                                    userRetentionGridView == false ?
                                        <>
                                            {
                                                !retentionEventsData ?
                                                    <div style={{ fontWeight: "bold" }}>No Records</div>
                                                    :
                                                    <VerticalBarChart
                                                        width={(maximizeStatus.retentionEvents && !maximizeStatus.payableEvents) ? '1200px' : "600px"}
                                                        height={(maximizeStatus.retentionEvents && !maximizeStatus.payableEvents) ? '580px' : "350px"}
                                                        options={{
                                                            maintainAspectRatio: false,
                                                            type: 'line',
                                                            responsive: true,
                                                            layout: {},
                                                            scales: {
                                                                x: {
                                                                    title: {
                                                                        display: false,
                                                                        text: 'Organization',
                                                                        font: {
                                                                            weight: 'bold',
                                                                        },
                                                                    },
                                                                    grid: {
                                                                        display: false,
                                                                        stacked: true,
                                                                        barPercentage: 1.5,
                                                                        categoryPercentage: 0.8,
                                                                        barThickness: 10,
                                                                    },
                                                                },
                                                                y: {
                                                                    title: {
                                                                        display: true,
                                                                        beginAtZero: true,
                                                                        font: {
                                                                            weight: 'bold',
                                                                        },
                                                                        text: 'No of Users',
                                                                    },
                                                                    suggestedMax: Math.max(retentionEventsData) + 2,
                                                                    grid: {
                                                                        display: true,
                                                                        stacked: true,
                                                                        color: '#B3CAE1',
                                                                        barPercentage: 1.5,
                                                                        categoryPercentage: 0.8,
                                                                        barThickness: 4,
                                                                    },
                                                                },
                                                            },
                                                            plugins: {
                                                                tooltip: {
                                                                    enabled: true
                                                                },
                                                                legend: {
                                                                    display: false,
                                                                    position: 'bottom' as const,
                                                                },
                                                                title: {
                                                                    display: false,
                                                                    font: {
                                                                        weight: 'bold',
                                                                        size: 25,
                                                                    },
                                                                    padding: 10,
                                                                    text: 'User Engagement Events',
                                                                },
                                                            },
                                                        }}
                                                        HandleBarChartClcik={(barValue) => {
                                                            console.log("first", barValue)
                                                            setUserRetentionGridView(true)

                                                        }
                                                        }
                                                        data={
                                                            {
                                                                labels: [retensionSelectedDate],
                                                                datasets: [{
                                                                    barPercentage: 1.1,
                                                                    barThickness: 40,
                                                                    data: [retentionEventsData],
                                                                    label: ' Users ',
                                                                    // data1: [45, 66, 22, 80, 89, 45, 45, 65, 70, 68, 43, 24],
                                                                    backgroundColor: ["#04C451", "#FF4056", "#1686F6", "#A0C40D", "#FF75DF", "#216D9B", "#A22DFF"],
                                                                    borderColor: ["#04C451", "#FF4056", "#1686F6", "#A0C40D", "#FF75DF", "#216D9B", "#A22DFF"],
                                                                    borderWidth: 5
                                                                }],

                                                            }}
                                                        clickedEvent={false}

                                                    />

                                            }
                                        </>
                                        :

                                        <div className={(maximizeStatus.retentionEvents && !maximizeStatus.payableEvents) ? 'user-logs-registration-events-resize' : 'user-logs-registration-events'}>
                                            <ChartAgGrid
                                                fileName={"Utilization Payment - Report"}
                                                ref={userRegistrationEventsRef}
                                                tabelRowData={userRetentionRowData}
                                                tableColumnData={[
                                                    { field: 'userId', headerName: "User Id", cellStyle: { 'text-align': 'left' }, minWidth: 250, maxWidth: 250, cellRendererFramework: CustomEllipsisRenderer, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, },
                                                    { field: 'loggedInTime', headerName: "loggedIn Time", cellStyle: { 'text-align': 'left' }, minWidth: 250, cellRendererFramework: CustomEllipsisRenderer, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, },
                                                    { field: 'createdAt', headerName: "created At", cellStyle: { 'text-align': 'left' }, minWidth: 250, maxWidth: 250, cellRendererFramework: CustomEllipsisRenderer, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, },
                                                ]}
                                                className={"User Registration Events"}
                                                paginationPageSize={(maximizeStatus.retentionEvents && !maximizeStatus.payableEvents) ? 13 : 7}
                                            />
                                        </div>
                                }
                            </div>
                            <div className='expand-arrow' onClick={() => {
                                if (maximizeStatus.isBarchart == false) {
                                    setMaximizeStatus({
                                        "isBarchart": true,
                                        "billGenerationDate": true,
                                        "utilizationPayment": true,
                                        "eventsReport": true,
                                        "retentionEvents": true,
                                        "payableEvents": true,
                                        "externalEvents": true,
                                        "applicationErrors": true,
                                    })
                                } else {
                                    setMaximizeStatus({
                                        "isBarchart": false,
                                        "billGenerationDate": false,
                                        "utilizationPayment": false,
                                        "eventsReport": false,
                                        "retentionEvents": true,
                                        "payableEvents": false,
                                        "externalEvents": false,
                                        "applicationErrors": false,

                                    })
                                }
                            }}><AiOutlineExpandAlt style={{ fontSize: "30px" }} /></div>
                            <div className='features'>
                                {
                                    <Grid onClick={() => {
                                        if (userRetentionGridView) {
                                            setUserRetentionGridView(false)
                                            setOriginalUserRetentionRowData(originalUserRetentionRowData)
                                        } else {
                                            setUserRetentionGridView(true)
                                        }
                                    }} />
                                    // <GraphIcon onClick={() => { }} />
                                }
                                <hr />
                                <Download className={`${chartViewStatus.utilizationVendor ? "disableState" : "activeState"}`} onClick={() => { }} />
                            </div>
                        </div>
                    }
                    {
                        maximizeStatus.payableEvents &&
                        <div className='utilization-payment' style={{ width: (!maximizeStatus.retentionEvents && maximizeStatus.payableEvents) ? '100%' : "" }}>
                            <div className='utilization-payment-title' style={{ left: (maximizeStatus.payableEvents && !maximizeStatus.retentionEvents) ? "42%" : "" }}>{"Payable Events"}</div>
                            <div>
                                {
                                    payableEventgridView == false ?
                                        <>
                                            {
                                                // payableEventsDatas.payableEventsDatasPercentage == 0 ?
                                                //     <div style={{ fontWeight: "bold" }}>No Records</div>
                                                //     :
                                                <DoughnutPieChart
                                                    width={(maximizeStatus.payableEvents && !maximizeStatus.retentionEvents) ? '800px' : "500px"}
                                                    height={(maximizeStatus.payableEvents && !maximizeStatus.retentionEvents) ? '550px' : "350px"}
                                                    options={[]}
                                                    orginalBarchartData={originalRowData}
                                                    HandleBarChartClick={(barValue) => {
                                                        // HandleBarChartClick(barValue)
                                                        // console.log(barValue)
                                                        // const a = originalRowData.filter((e) => e.ResponseStatusCode == barValue)
                                                        // setGridView(true)
                                                        // setRowData(a)
                                                    }}
                                                    data={{
                                                        labels: ["Vendor", 'Utility', 'Virtual Card', 'Excise', 'Direct Tax', 'Gst'],
                                                        datasets: [
                                                            {
                                                                label: ' Users Count',
                                                                data: ["30", "30", "20", "10", "10"],
                                                                // data: ["80", "20"],
                                                                backgroundColor: ['#04C451', '#FF8700', '#4889F8', '#FF4056', '#FF75DF'],
                                                                hoverBackgroundColor: ['green', '#a35804', 'darkblue', 'darkred', '#771b62'],
                                                            },
                                                        ],
                                                    }}
                                                />

                                            }
                                        </>
                                        :

                                        <div className={(maximizeStatus.payableEvents && !maximizeStatus.retentionEvents) ? 'user-logs-registration-events-resize' : 'user-logs-registration-events'}>
                                            <ChartAgGrid
                                                fileName={"Utilization Payment - Report"}
                                                ref={userRegistrationEventsRef}
                                                tabelRowData={rowData}
                                                tableColumnData={[
                                                    { field: 'ipAddress', headerName: "IP Address", cellStyle: { 'text-align': 'left' }, minWidth: 250, maxWidth: 250, cellRendererFramework: CustomEllipsisRenderer, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, },
                                                    { field: 'RequestMethod', headerName: "Request Method", cellStyle: { 'text-align': 'left' }, minWidth: 250, cellRendererFramework: CustomEllipsisRenderer, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, },
                                                    { field: 'RequestURL', headerName: "Request URL", cellStyle: { 'text-align': 'left' }, minWidth: 250, maxWidth: 250, cellRendererFramework: CustomEllipsisRenderer, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, },
                                                    { field: 'RequestHeaders', headerName: "Request Headers", cellStyle: { 'text-align': 'left' }, minWidth: 250, maxWidth: 250, cellRendererFramework: CustomEllipsisRenderer, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, },
                                                    { field: 'RequestPayload', headerName: "Request Payload", cellStyle: { 'text-align': 'left' }, minWidth: 250, maxWidth: 250, cellRendererFramework: CustomEllipsisRenderer, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, },
                                                    { field: 'ResponseStatusCode', headerName: "Response Status Code", cellStyle: { 'text-align': 'left' }, minWidth: 250, maxWidth: 250, cellRendererFramework: CustomEllipsisRenderer, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, },
                                                    { field: 'ResponseBody', headerName: "Response Body", cellStyle: { 'text-align': 'left' }, minWidth: 250, maxWidth: 250, cellRendererFramework: CustomEllipsisRenderer, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, },
                                                    { field: 'ResponseTime', headerName: "Response Time", cellStyle: { 'text-align': 'left' }, minWidth: 250, maxWidth: 250, cellRendererFramework: CustomEllipsisRenderer, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, },
                                                    { field: 'timestamp', headerName: "Time Stamp", cellStyle: { 'text-align': 'left' }, minWidth: 250, maxWidth: 250, cellRendererFramework: CustomEllipsisRenderer, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, },
                                                    { field: 'user_id', headerName: "User Login", cellStyle: { 'text-align': 'left' }, minWidth: 250, maxWidth: 250, cellRendererFramework: CustomEllipsisRenderer, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, },
                                                    { field: 'createdAt', headerName: "Created At", cellStyle: { 'text-align': 'left' }, minWidth: 250, maxWidth: 250, cellRendererFramework: CustomEllipsisRenderer, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, },
                                                    { field: 'updatedAt', headerName: "Updated At", cellStyle: { 'text-align': 'left' }, minWidth: 250, maxWidth: 250, cellRendererFramework: CustomEllipsisRenderer, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, },
                                                    // { field: 'Transaction Value(%)', cellStyle: { 'text-align': 'center' }, },
                                                ]}
                                                className={"User Registration Events"}
                                                paginationPageSize={(maximizeStatus.payableEvents && !maximizeStatus.retentionEvents) ? 13 : 7}
                                            />
                                        </div>
                                }
                            </div>
                            <div className='expand-arrow' onClick={() => {
                                if (maximizeStatus.isBarchart == false) {
                                    setMaximizeStatus({
                                        "isBarchart": true,
                                        "billGenerationDate": true,
                                        "utilizationPayment": true,
                                        "eventsReport": true,
                                        "retentionEvents": true,
                                        "payableEvents": true,
                                        "externalEvents": true,
                                        "applicationErrors": true,

                                    })
                                } else {
                                    setMaximizeStatus({
                                        "isBarchart": false,
                                        "billGenerationDate": false,
                                        "utilizationPayment": false,
                                        "eventsReport": false,
                                        "retentionEvents": false,
                                        "payableEvents": true,
                                        "externalEvents": false,
                                        "applicationErrors": false,

                                    })
                                }
                            }}><AiOutlineExpandAlt style={{ fontSize: "30px" }} /></div>
                            <div className='features' style={{ opacity: "0.5", cursor: "not-allowed" }}>
                                {
                                    <Grid
                                    //  onClick={() => {
                                    //     if (payableEventgridView) {
                                    //         setPayableEventgridView(false)
                                    //         setRowData(originalRowData)
                                    //     } else {
                                    //         setPayableEventgridView(true)
                                    //     }
                                    // }} 
                                    />
                                    // <GraphIcon onClick={() => { }} />
                                }
                                <hr />
                                <Download className={`${chartViewStatus.utilizationSegments ? "disableState" : "activeState"}`} onClick={() => { }} />
                            </div>
                        </div>
                    }

                </div>
            }
            {/* External API Events and Application Errors and Crashes*/}
            {
                (maximizeStatus.externalEvents || maximizeStatus.applicationErrors) &&
                <div className='bill-generation-and-payment' style={{ height: ((maximizeStatus.externalEvents || maximizeStatus.applicationErrors) && !maximizeStatus.isBarchart) ? "83vh" : "" }}>
                    {
                        maximizeStatus.externalEvents &&
                        <div className='utilization-payment' style={{ width: (!maximizeStatus.applicationErrors && maximizeStatus.externalEvents) ? '100%' : "" }}>
                            <div className='utilization-payment-title' style={{ left: (!maximizeStatus.applicationErrors && maximizeStatus.externalEvents) ? "42%" : "" }}>{"External API Events"}</div>
                            <div>
                                {
                                    externalApigridView == false ?
                                        <>
                                            {
                                                externalApiEventsDatas.externalEventsPercentage == 0 ?
                                                    <div style={{ fontWeight: "bold" }}>No Records</div>
                                                    :
                                                    <DoughnutPieChart
                                                        width={(!maximizeStatus.applicationErrors && maximizeStatus.externalEvents) ? '800px' : "500px"}
                                                        height={(!maximizeStatus.applicationErrors && maximizeStatus.externalEvents) ? '550px' : "350px"}
                                                        options={[]}
                                                        orginalBarchartData={originalExternalApiRowData}
                                                        HandleBarChartClick={(barValue) => {
                                                            // HandleBarChartClick(barValue)
                                                            const a = originalExternalApiRowData.filter((e) => e.IntegratorName == barValue)
                                                            setExternalApiGridView(true)
                                                            setExternalApirowData(a)
                                                        }}
                                                        data={{
                                                            labels: ["HyperVerge", 'lyra', 'razorpay', 'bbps', 'valuefirst', 'signdesk'],
                                                            datasets: [
                                                                {
                                                                    label: ' Users Count',
                                                                    data: externalApiEventsDatas.externalEventsPercentage,
                                                                    // data: ["80", "20"],
                                                                    backgroundColor: ['#04C451', '#FF4056', '#FF8700', '#4889F8', '#216D9B', "#D26ABA"],
                                                                    hoverBackgroundColor: ['darkgreen', 'darkred', '#7b4305', '#072d6d', '#032b43', '#550542'],
                                                                },
                                                            ],
                                                        }}
                                                    />

                                            }
                                        </>
                                        :

                                        <div className={(!maximizeStatus.applicationErrors && maximizeStatus.externalEvents) ? 'user-logs-registration-events-resize' : 'user-logs-registration-events'}>
                                            <ChartAgGrid
                                                fileName={"Utilization Payment - Report"}
                                                ref={userRegistrationEventsRef}
                                                tabelRowData={externalApirowData}
                                                tableColumnData={[
                                                    { field: 'EventType', headerName: "Event Type", cellStyle: { 'text-align': 'left' }, minWidth: 250, maxWidth: 250, cellRendererFramework: CustomEllipsisRenderer, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, },
                                                    { field: 'ipAddress', headerName: "IP Address", cellStyle: { 'text-align': 'left' }, minWidth: 250, maxWidth: 250, cellRendererFramework: CustomEllipsisRenderer, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, },
                                                    { field: 'IntegrationEndpoint', headerName: "Integration Endpoint", cellStyle: { 'text-align': 'left' }, minWidth: 250, maxWidth: 250, cellRendererFramework: CustomEllipsisRenderer, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, },
                                                    { field: 'IntegratorName', headerName: "Integrator Name", cellStyle: { 'text-align': 'left' }, minWidth: 250, maxWidth: 250, cellRendererFramework: CustomEllipsisRenderer, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, },
                                                    { field: 'ResponseData', headerName: "Response Data", cellStyle: { 'text-align': 'left' }, minWidth: 250, maxWidth: 250, cellRendererFramework: CustomEllipsisRenderer, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, },
                                                    { field: 'ResponseTime', headerName: "Response Time", cellStyle: { 'text-align': 'left' }, minWidth: 250, maxWidth: 250, cellRendererFramework: CustomEllipsisRenderer, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, },
                                                    { field: 'timestamp', headerName: "Time Stamp", cellStyle: { 'text-align': 'left' }, minWidth: 250, maxWidth: 250, cellRendererFramework: CustomEllipsisRenderer, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, },

                                                    // { field: 'Transaction Value(%)', cellStyle: { 'text-align': 'center' }, },
                                                ]}
                                                className={"User Registration Events"}
                                                paginationPageSize={(!maximizeStatus.applicationErrors && maximizeStatus.externalEvents) ? 13 : 7}
                                            />
                                        </div>
                                }
                            </div>
                            <div className='expand-arrow' onClick={() => {
                                if (maximizeStatus.isBarchart == false) {
                                    setMaximizeStatus({
                                        "isBarchart": true,
                                        "billGenerationDate": true,
                                        "utilizationPayment": true,
                                        "eventsReport": true,
                                        "retentionEvents": true,
                                        "payableEvents": true,
                                        "externalEvents": true,
                                        "applicationErrors": true,
                                    })
                                } else {
                                    setMaximizeStatus({
                                        "isBarchart": false,
                                        "billGenerationDate": false,
                                        "utilizationPayment": false,
                                        "eventsReport": false,
                                        "retentionEvents": false,
                                        "payableEvents": false,
                                        "externalEvents": true,
                                        "applicationErrors": false,
                                    })
                                }
                            }}><AiOutlineExpandAlt style={{ fontSize: "30px" }} /></div>
                            <div className='features'>
                                {
                                    <Grid onClick={() => {
                                        if (externalApigridView) {
                                            setExternalApiGridView(false)
                                            setExternalApirowData(originalExternalApiRowData)
                                        } else {
                                            setExternalApiGridView(true)
                                        }
                                    }} />
                                    // <GraphIcon onClick={() => { }} />
                                }
                                <hr />
                                <Download className={`${chartViewStatus.utilizationSegments ? "disableState" : "activeState"}`} onClick={() => { }} />
                            </div>
                        </div>
                    }
                    {
                        maximizeStatus.applicationErrors &&
                        <div className='utilization-payment' style={{ width: (!maximizeStatus.externalEvents && maximizeStatus.applicationErrors) ? '100%' : "" }}>
                            <div className='utilization-payment-title' style={{ left: (maximizeStatus.applicationErrors && !maximizeStatus.externalEvents) ? "42%" : "" }}>{"Application Errors and Crashes"}</div>
                            <div>
                                {
                                    applicationErrorGridView == false ?
                                        <>
                                            {
                                                applicationCrashesDatas.applicationCrashesPercentage == 0 ?
                                                    <div style={{ fontWeight: "bold" }}>No Records</div>
                                                    :
                                                    <DoughnutPieChart
                                                        width={(maximizeStatus.applicationErrors && !maximizeStatus.externalEvents) ? '800px' : "500px"}
                                                        height={(maximizeStatus.applicationErrors && !maximizeStatus.externalEvents) ? '550px' : "350px"}
                                                        options={[]}
                                                        orginalBarchartData={originalApplicationErrorRowData}
                                                        HandleBarChartClick={(barValue) => {
                                                            // HandleBarChartClick(barValue)
                                                            console.log(barValue)
                                                            const a = originalApplicationErrorRowData.filter((e) => {
                                                                if (barValue === 'failure') {
                                                                    return e.ResponseStatusCode.startsWith('5');
                                                                } else if (barValue === 'Information') {
                                                                    return e.ResponseStatusCode.startsWith('3');
                                                                } else if (barValue === 'Warning') {
                                                                    return e.ResponseStatusCode.startsWith('4');
                                                                }
                                                            });

                                                            setApplicationErrorGridView(true)
                                                            setApplicationErrorRowData(a)
                                                        }}
                                                        data={{
                                                            labels: ["Information", 'Warning', 'failure'],
                                                            datasets: [
                                                                {
                                                                    label: 'Count ',
                                                                    data: applicationCrashesDatas.applicationCrashesPercentage,
                                                                    // data: ["80", "20"],
                                                                    backgroundColor: ['#FF8700', '#4889F8', '#FF4056'],
                                                                    hoverBackgroundColor: ['#7b4305', '#072d6d', 'darkred'],
                                                                },
                                                            ],
                                                        }}
                                                    />

                                            }
                                        </>
                                        :

                                        <div className={(maximizeStatus.applicationErrors && !maximizeStatus.externalEvents) ? 'user-logs-registration-events-resize' : 'user-logs-registration-events'}>
                                            <ChartAgGrid
                                                fileName={"Utilization Payment - Report"}
                                                ref={userRegistrationEventsRef}
                                                tabelRowData={applicationErrorRowData}
                                                tableColumnData={[
                                                    { field: 'RequestURL', headerName: "Request URL", cellStyle: { 'text-align': 'left' }, minWidth: 250, maxWidth: 250, cellRendererFramework: CustomEllipsisRenderer, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, },
                                                    { field: 'ResponseStatusCode', headerName: "Response Status Code", cellStyle: { 'text-align': 'left' }, minWidth: 250, cellRendererFramework: CustomEllipsisRenderer, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, },
                                                    { field: 'ResponseTime', headerName: "Response Time", cellStyle: { 'text-align': 'left' }, minWidth: 250, maxWidth: 250, cellRendererFramework: CustomEllipsisRenderer, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, },
                                                    { field: 'createdAt', headerName: "Created At", cellStyle: { 'text-align': 'left' }, minWidth: 250, maxWidth: 250, cellRendererFramework: CustomEllipsisRenderer, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, },
                                                    { field: 'user_id', headerName: "User Id", cellStyle: { 'text-align': 'left' }, minWidth: 250, maxWidth: 250, cellRendererFramework: CustomEllipsisRenderer, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, },
                                                ]}
                                                className={"User Registration Events"}
                                                paginationPageSize={(maximizeStatus.applicationErrors && !maximizeStatus.externalEvents) ? 13 : 7}
                                            />
                                        </div>
                                }
                            </div>
                            <div className='expand-arrow' onClick={() => {
                                if (maximizeStatus.isBarchart == false) {
                                    setMaximizeStatus({
                                        "isBarchart": true,
                                        "billGenerationDate": true,
                                        "utilizationPayment": true,
                                        "eventsReport": true,
                                        "retentionEvents": true,
                                        "payableEvents": true,
                                        "externalEvents": true,
                                        "applicationErrors": true,
                                    })
                                } else {
                                    setMaximizeStatus({
                                        "isBarchart": false,
                                        "billGenerationDate": false,
                                        "utilizationPayment": false,
                                        "eventsReport": false,
                                        "retentionEvents": false,
                                        "payableEvents": false,
                                        "externalEvents": false,
                                        "applicationErrors": true,
                                    })
                                }
                            }}><AiOutlineExpandAlt style={{ fontSize: "30px" }} /></div>
                            <div className='features'>
                                {
                                    <Grid onClick={() => {
                                        if (applicationErrorGridView) {
                                            setApplicationErrorGridView(false)
                                            setApplicationErrorRowData(originalApplicationErrorRowData)
                                        } else {
                                            setApplicationErrorGridView(true)

                                        }
                                    }} />
                                    // <GraphIcon onClick={() => { }} />
                                }
                                <hr />
                                <Download className={`${chartViewStatus.utilizationSegments ? "disableState" : "activeState"}`} onClick={() => { }} />
                            </div>
                        </div>
                    }

                </div>
            }
            {
                maximizeStatus.eventsReport &&
                <>
                    <div className='line-chart-userlog'>
                        <div className='chart-header'>
                            <div className='header-name'>All Events Reports</div>
                            <input type="date" className='user-log-date' value={requestSelectedDate} max={todayDate}
                                onChange={handleRequestDateChange} />
                        </div>
                        <div className='line-chart-container'>
                            <LineChart
                                width={"100%"}
                                height={"600px"}
                                options={{
                                    responsive: true,
                                    scales: {
                                        x: {
                                            suggestedMin: 0,
                                            beginAtZero: true,
                                            min: 0,
                                            grid: {
                                                display: false,
                                            },
                                        },
                                        y: {
                                            beginAtZero: true,
                                            grid: {
                                                display: true,
                                            },
                                            suggestedMax: Math.max(...requestdata) + 10,
                                            title: {
                                                display: true,
                                                text: 'Requests',
                                                font: {
                                                    weight: 'bold',
                                                    size: 20
                                                },
                                            },
                                        },
                                    },
                                }}
                                data={{
                                    labels: requestlabels,
                                    datasets: [
                                        {
                                            label: 'Request',
                                            data: requestdata,
                                            borderColor: '#B69CFF',
                                            backgroundColor: '#0055D4',
                                            pointRadius: (context: any) => {
                                                return context.dataIndex === 0 || context.dataIndex === requestlabels?.length - 1 ? 0 : 8;
                                            },
                                            pointHoverRadius: 12,
                                        },
                                    ],
                                }}
                            />
                        </div>
                    </div>
                    <div className='line-chart-userlog'>
                        <div className='chart-header'>
                            <div className='header-name'>Application performance issues per hour</div>
                            <input type="date" className='user-log-date' value={performanceSelectedDate} max={todayDate}
                                onChange={handlePerformanceDateChange} />
                        </div>
                        <div className='line-chart-container'>
                            <LineChart
                                width={"100%"}
                                height={"600px"}
                                options={{
                                    responsive: true,
                                    scales: {
                                        x: {
                                            suggestedMin: 0,
                                            beginAtZero: true,
                                            min: 0,
                                            grid: {
                                                display: false,
                                            },
                                        },
                                        y: {
                                            beginAtZero: true,
                                            grid: {
                                                display: true,
                                            },
                                            suggestedMax: Math.max(...performancedata) + 10,
                                            title: {
                                                display: true,
                                                text: 'MS',
                                                font: {
                                                    weight: 'bold',
                                                    size: 20
                                                },
                                            },
                                        },
                                    },
                                }}
                                data={{
                                    labels: performanceLabels,
                                    datasets: [
                                        {
                                            label: 'MS',
                                            data: performancedata,
                                            borderColor: '#B69CFF',
                                            backgroundColor: '#0055D4',
                                            pointRadius: (context: any) => {
                                                return context.dataIndex === 0 || context.dataIndex === performanceLabels?.length - 1 ? 0 : 8;
                                            },
                                            pointHoverRadius: 12,
                                        },
                                    ],
                                }}
                            />
                        </div>
                    </div>
                </>

            }




        </div>

    )
}
export default UserLogDashboard;