import React, { Component } from 'react'
import GstForm from '../../components/gst/gst-form'
import GstGrid from '../../components/gst/gst-grid'

interface StateValues {
    gstView: string;
    isEditMode: boolean;
    transaction: string;
}
export default class Gst extends Component<{}, StateValues> {
    constructor(props: StateValues) {
        super(props);
        this.state = {
            gstView: "grid",
            isEditMode: false,
            transaction: ""
        };
    }
    addMode = () => {
        this.setState({
            isEditMode: false,
        })
    }
    render() {
        return (
            <div>
                {
                    this.state.gstView === "form" &&
                    <GstForm
                        gstView={(val) => {
                            this.setState({ gstView: val })
                        }}
                        transaction={this.state.transaction}
                    />
                }
                {
                    this.state.gstView === "grid" &&
                    <GstGrid
                        gstView={(val) => {
                            this.setState({ gstView: val })
                        }}
                        transaction={(val:any)=>{
                            this.setState({ transaction: val })
                        }}
                        addMode={this.addMode}
                        getEditData={(nav: any, data: any) => this.setState({
                            gstView: nav,
                            // editData: { ...data },
                            isEditMode: true,
                        })}
                    />
                }
            </div>

        )
    }
}

