import React, { Component } from 'react'
import CustomerForm from '../../components/organisation/customer/customer-form'
import CustomerGrid from '../../components/organisation/customer/customer-grid'



interface StateValues {
    onboardingView: string;
    editData: any;
    isEditMode: boolean;
}

export default class Customer extends Component<{}, StateValues> {
    constructor(props: StateValues) {
        super(props);
        this.state = {
            onboardingView: "grid",
            editData: {},
            isEditMode: false,
        };
        this.addMode = this.addMode.bind(this);
    }

    addMode = () => {
        this.setState({
            editData: {},
            isEditMode: false,
        })
    }

    render() {
        return (
            <div>
                {
                    this.state.onboardingView === "form" &&
                    <CustomerForm
                        onboardingViewfun={(val: any) => {
                            this.setState({ onboardingView: val })
                        }}
                        editData={this.state.editData}
                        isEditMode={this.state.isEditMode}
                        getEditData={(data: any) => this.setState({
                            editData: { ...data },
                            // isEditMode: false,
                        })}
                    />
                }
                {
                    this.state.onboardingView === "grid" &&
                    <CustomerGrid
                        onboardingViewfun={(val: any) => {
                            this.setState({ onboardingView: val })
                        }}
                        addMode={this.addMode}
                        getEditData={(nav: any, data: any) => this.setState({
                            onboardingView: nav,
                            editData: { ...data },
                            isEditMode: true,
                        })}

                    />
                }
            </div>
        )
    }
}
