import React, { useEffect, useRef, useState, } from 'react';
import '../../../styles/modals/department-add-modal.scss';
import { RxCross1 } from 'react-icons/rx';
import ButtonComponent from '../form-elements/button-component';
import IconButton from '../form-elements/icon-button';
import InputComponent from '../form-elements/input-component';
import DeleteModal from './delete-modal';
import AlertBox from '../alert-box';
import useAxiosPrivate from '../../../services/hooks/useaxios-private';


interface Styles {
    clearValue: (val: any) => void;
    getDepartment?: () => void;
    department: any[];
    setDepartment: any;
    productAdminUserData: any;

}



export default function AddDepartment({
    clearValue, getDepartment, department, setDepartment, productAdminUserData
}: Styles) {

    const [departmentName, setDepartmentName] = useState<string>("")
    const [editIndex, setEditIndex] = useState<number>()
    const [deletIndex, setDeletIndex] = useState<number>()
    const [isDepartmentEditMode, setIsDepartmentEditMode] = useState<boolean>(false)
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
    const [showType, setShowType] = useState("warning") // error message showType
    const [showMessage, setShowMessage] = useState<string>("") // error message showMessage
    const [showAlertBox, setShowAlertBox] = useState(false) // error message ShowAlertBox
    const axiosPrivate = useAxiosPrivate();

    const fetchData = async (url: string, setData: (data: any[]) => void, nestedProperty: string) => {
        try {
            const response = await axiosPrivate.get(url);
            if (response.status === 200) {
                const dropdownValues = response.data[nestedProperty].map((item: any) => ({
                    label: item.departmentName,
                    value: item.departmentName,
                    id: item.departmentId,
                }));
                const sortedData = [...dropdownValues].sort((a, b) => a.label.localeCompare(b.label));

                setData(sortedData);
            }
        } catch (error) {
            console.log('Error:', error);
        }
    };

    const saveDepartment = async () => {
        if (departmentName !== "" && new RegExp(/^[a-zA-Z][a-zA-Z /]*$/).test(departmentName)) {
            try {
                const response = isDepartmentEditMode ?
                    await axiosPrivate.patch(`product-admin/user-department/${editIndex}`, {
                        departmentName: departmentName,
                        organisationId: productAdminUserData.organization,
                        companyId: productAdminUserData.company
                    }) :
                    await axiosPrivate.post("product-admin/user-department", {
                        departmentName: departmentName,
                        organisationId: productAdminUserData.organization,
                        companyId: productAdminUserData.company
                    });
                if (response.status === 200) {
                    setIsDepartmentEditMode(false)
                    setDepartmentName("")
                    setShowAlertBox(true);
                    setShowType("success");
                    setShowMessage(response.data.message)
                    clearAleart();
                    getDepartment && getDepartment();
                    // fetchData(`product-admin/user-department/${productAdminUserData.organization}/${productAdminUserData.company}`, setDepartment, "departmentDetails");
                }
            } catch (error: any) {
                setShowAlertBox(true);
                setShowType("danger");
                setShowMessage(error.response.data.error)
                clearAleart();
            }
        } else {
            setShowAlertBox(true);
            setShowType("danger");
            setShowMessage(departmentName === "" ?
                "Mandatory field(s) should not be left blank" :
                "Some of the field(s) are not in required format")
            clearAleart();
        }
    }

    const clearAleart = () => {
        const timer = setTimeout(() => {
            setShowAlertBox(false);
            setShowMessage("");
            clearTimeout(timer);
        }, 5000);
    }

    const editDepartment = async (value: string, index: number) => {
        setEditIndex(index);
        setDepartmentName(value);
        setIsDepartmentEditMode(true);
    };

    const deletDepartment = async () => {
        try {
            const response = await axiosPrivate.delete(`product-admin/user-department/${deletIndex}`);
            if (response.status === 200) {
                setShowDeleteModal(false);
                getDepartment && getDepartment();
                setShowAlertBox(true);
                setShowType("success");
                setShowMessage(response.data.message)
                clearAleart();
                setShowDeleteModal(false);
            }
        } catch (error: any) {
            setShowAlertBox(true);
            setShowType("danger");
            setShowMessage(error.response.data.error)
            clearAleart();
            setShowDeleteModal(false);
        }

    }

    const ConfremationDelete = async (value: string, index: number) => {
        setDeletIndex(index);
        setShowDeleteModal(true);
    };

    return (
        <div className={"department-add-modal"}>
            <div className={"container-add-modal"}>
                <div className={"close-icon"}>
                    <span className={"cross-icon"}>
                        <RxCross1 onClick={() => clearValue(false)
                        } style={{ fontSize: "40px" }} />
                    </span>
                </div>
                <div className='sub-container'>
                    <div className='department'>
                        <InputComponent
                            height={"40px"}
                            width={"350px"}
                            margin={"0px 10px 30px 0px"}
                            padding={"0px 0px 0px 10px"}
                            border={"1px solid #A9C3DC"}
                            borderRadius={"0px"}
                            backgroundColor={"white"}
                            color={"black"}
                            type={"text"}
                            maxLength={100}
                            inputTitle={"Department"}
                            placeHolder={"Enter department name"}
                            required={false}
                            inputValue={departmentName}
                            getUser={(value: any) => {
                                const finalvalue = value.replace(/[^a-zA-Z ]/g, "")
                                setDepartmentName(finalvalue)
                            }}
                        />

                        <ButtonComponent
                            title={isDepartmentEditMode ? "Update" : "Save"}
                            height={"40px"}
                            width={"130px"}
                            backgroundColor={"#0055D4"}
                            color={"white"}
                            margin={"0px 8px"}
                            disabled={departmentName === ""}
                            className={departmentName === "" ? "button-component disabled" : "button-component common-btn"}
                            handleClick={() => {
                                saveDepartment();

                            }}
                        />
                    </div>

                    <div className='view-table-component' style={{ marginTop: "20px", maxHeight: '300px', overflowY: 'auto' }}>
                        <table>
                            <thead>
                                <th className='privilege'>Department</th>
                                <th className='access' style={{ paddingLeft: "27%" }}>Action</th>
                            </thead>
                            <tbody>
                                {department.length === 0 ?
                                    <tr className='expensetype-style-grid-differ'>
                                        {/* <td></td> */}
                                        <td colSpan={2}>No records to show</td>
                                        {/* <td></td> */}
                                    </tr>
                                    :

                                    department.map((data, index) => (
                                        <tr className='main-body-header' key={index} style={{ justifyContent: "space-between", padding: "" }}>
                                            <td className='first-name' style={{ padding: "5px", paddingLeft: "30px" }}>{data.value}</td>
                                            <td className='last-icon' style={{ display: "flex", justifyContent: "flex-end", padding: "5px" }}>
                                                <IconButton
                                                    iconName={"Edit"}
                                                    height={"40px"}
                                                    width={"40px"}
                                                    fontSize={"30px"}
                                                    margin={"0px 8px"}
                                                    color={"white"}
                                                    backgroundColor={"#0055D4"}
                                                    hover={true}
                                                    handleClick={() => {
                                                        editDepartment(data.value, data.id);
                                                    }}
                                                />
                                                <IconButton
                                                    iconName={"Delete"}
                                                    height={"40px"}
                                                    width={"40px"}
                                                    fontSize={"25px"}
                                                    margin={"0px 8px"}
                                                    color={"white"}
                                                    backgroundColor={"#D60000"}
                                                    hover={true}
                                                    handleClick={() => {
                                                        ConfremationDelete(data.value, data.id);
                                                    }}
                                                />
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            {
                showDeleteModal &&
                <div>
                    <DeleteModal
                        getDelete={deletDepartment}
                        clearValue={(value) => { setShowDeleteModal(value) }}
                        modelType={"grid-delete"}
                    />
                </div>
            }
            {
                showAlertBox &&
                <div className='alert-warp'>
                    <AlertBox type={showType} message={showMessage} />
                </div>
            }
        </div>
    )
}



