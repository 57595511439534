import React, { useEffect, useState } from 'react'
import IconButton from '../../../common-component/form-elements/icon-button'
import { CiSearch } from 'react-icons/ci'
import DocumentModal from '../../../common-component/modals/document-modal';
import VendorSettlementInvoice from '../../../common-component/modals/vendor-payable-reports';
import ChartFilter from '../../../common-component/charts/chart-filter';
import { invoicesSettlementReportFilter } from '../../../../config/reports/vendor-payable-reports/invoice-settlements-config';
import { axiosPrivate } from '../../../../middleware/axois-api';
import { formatAmount } from '../../../../helpers/amountRenderer';
interface props {
    searchBarValue: string
    filterOpen: any
    setFilterOpen: any
    getVendorOverViewId: any
}
export default function IvoiceSettlement({ filterOpen, setFilterOpen, searchBarValue, getVendorOverViewId }: props) {
    const [searchValue, setSearchValue] = useState<string>('');
    const [invoicerOpen, setInvoicerOpen] = useState<boolean>(false);
    const [filteredData, setFilteredData] = useState<any[]>([]);
    const [showDocumentModal, setShowDocumentModal] = useState<boolean>(false);
    const [currentFile, SetCurrentFile] = useState("")
    const [showAlertBox, setShowAlertBox] = useState<boolean>(false)
    const [showMessage, setShowMessage] = useState<string>("")
    const [showType, setShowType] = useState<string>("warning")

    const [storeFilterOptions, setStoreFilterOptions] = useState<any>([]);
    const [filterValue, setFilterValues] = useState<any>([])
    const [orginalRowData, setOrginalRowData] = useState<any[]>([]);
    const [vendorSettlementowData, setVendorSettlementowData] = useState<any[]>([]);
    const [openDropdownIndex, setOpenDropdownIndex] = useState<number | null>(null);

    useEffect(() => {
        if (searchBarValue === "") {
            setFilteredData(orginalRowData)
        } else {
            const searchBarFilterData: any = {}
            for (const [key, value] of Object.entries(orginalRowData)) {
                const filterVal =  value.filter((e: any) => e.invoiceNumber.includes(searchBarValue))
                searchBarFilterData[key] = filterVal
            }
            setFilteredData(searchBarFilterData)
        }

        // const filtered = orginalRowData.filter((item) => {
        //     console.log(item)
        //     const cardDetails = item.invoiceNumber;

        //     if (
        //         cardDetails &&
        //         (cardDetails["invoiceNumber"]?.toLowerCase().includes(searchBarValue.toLowerCase()))
        //     ) {
        //         // Return an object containing the matched field and its value
        //         return {
        //             matchedField: cardDetails["invoiceNumber"]?.toLowerCase().includes(searchBarValue.toLowerCase())
        //                 ? "invoiceNumber"
        //                 : "",
        //             value: cardDetails["invoiceNumber"],
        //         };
        //     }
        //     return null;
        // });

        // setFilteredData(filtered);
    }, [searchBarValue]);


    useEffect(() => {
        // handleApplyButtonForFilter(invoicesSettlementReportFilter["invoicesSettlement"])
        setStoreFilterOptions(invoicesSettlementReportFilter["invoicesSettlement"])
        setFilterValues(invoicesSettlementReportFilter["invoicesSettlement"])
        // fetchData()
    }, [])

    useEffect(() => {
        fetchData()
    }, [])

    const fetchData = async () => {
        try {
            const response = await axiosPrivate.post('companyadmin/vendor-payable-reports/invoice-settlements', { invoiceid: getVendorOverViewId });
            setOrginalRowData(response.data.finalData)
            setFilteredData(response.data.finalData)
            // addDataForDropDownFilter(response.data.dropDownDatas) 
        } catch (error) {
        }
    };

    // useEffect(() => {
    //     const filtered = orginalRowData.filter((item: any) => {
    //         const invoiceNumber = item.invoiceNumber; 

    //         if (
    //             invoiceNumber &&
    //             invoiceNumber.toLowerCase().includes(searchBarValue.toLowerCase())
    //         ) {
    //             // Return an object containing the matched field and its value
    //             return {
    //                 matchedField: "invoiceNumber",
    //                 value: invoiceNumber,
    //             };
    //         }

    //         return null;
    //     });
    //     setFilteredData(filtered);
    // }, [searchBarValue])



    const convertToFilterValues = (filterData: any) => {
        const filterValues: any = {};
        filterData.forEach((filter: any) => {
            const tittle = (filter.cardTitle).replace(/\s/g, '')
            filterValues[tittle] = filter.selectedValue;
        });
        return filterValues;
    };


    // function addDataForDropDownFilter(res: any) {
    //     try {
    //         if (Object.keys(res).length > 0) {
    //             const updatedInvoiceNumber: any = [
    //                 { value: "All", label: "All" },
    //                 ...res['Invoice Number'].map((elem: any) => ({ value: elem, label: elem }))
    //             ];
    //             const updatedVendorName: any = [
    //                 { value: "All", label: "All" },
    //                 ...res["Vendor Name"].map((elem: any) => ({ value: elem, label: elem }))
    //             ];
    //             const updatedAmounttoVendor: any = [
    //                 { value: "All", label: "All" },
    //                 ...res['Amount to Vendor'].map((elem: any) => ({ value: elem, label: elem }))
    //             ];
    //             const updatedTransactionCharges: any = [
    //                 { value: "All", label: "All" },
    //                 ...res['Transaction Charges'].map((elem: any) => ({ value: elem, label: elem }))
    //             ];
    //             const updatedTotalAmount: any = [
    //                 { value: "All", label: "All" },
    //                 ...res['Total Amount'].map((elem: any) => ({ value: elem, label: elem }))
    //             ];
    //             const updatedCurrency: any = [
    //                 { value: "All", label: "All" },
    //                 ...res['Currency'].map((elem: any) => ({ value: elem, label: elem }))
    //             ];
    //             const updatedPaymentMethod: any = [
    //                 { value: "All", label: "All" },
    //                 ...res['Payment Method'].map((elem: any) => ({ value: elem, label: elem }))
    //             ];
    //             const updatedTransactionInformation: any = [
    //                 { value: "All", label: "All" },
    //                 ...res["Transaction Information"].map((elem: any) => ({ value: elem, label: elem }))
    //             ];
    //             const updatedPaymentInitiatedAt: any = [
    //                 { value: "All", label: "All" },
    //                 ...res['Payment Initiated At'].map((elem: any) => ({ value: elem, label: elem }))
    //             ];
    //             const updatedPaymentInitiatedBy: any = [
    //                 { value: "All", label: "All" },
    //                 ...res["Payment Initiated By"].map((elem: any) => ({ value: elem, label: elem }))
    //             ];
    //             const updatedProcessedAt: any = [
    //                 { value: "All", label: "All" },
    //                 ...res['Processed At'].map((elem: any) => ({ value: elem, label: elem }))
    //             ];
    //             const updatedReversedAt: any = [
    //                 { value: "All", label: "All" },
    //                 ...res["Reversed At"].map((elem: any) => ({ value: elem, label: elem }))
    //             ];
    //             const updatedTransactionStatus: any = [
    //                 { value: "All", label: "All" },
    //                 ...res['Transaction Status'].map((elem: any) => ({ value: elem, label: elem }))
    //             ];
    //             const updatedFailureReason: any = [
    //                 { value: "All", label: "All" },
    //                 ...res['Failure Reason'].map((elem: any) => ({ value: elem, label: elem }))
    //             ];
    //             const updatedTransactionRefNumber: any = [
    //                 { value: "All", label: "All" },
    //                 ...res['Transaction Ref Number'].map((elem: any) => ({ value: elem, label: elem }))
    //             ];
    //             const updatedPayoutID: any = [
    //                 { value: "All", label: "All" },
    //                 ...res['Payout ID'].map((elem: any) => ({ value: elem, label: elem }))
    //             ];

    //             setStoreFilterOptions((prevOptions: any) => {
    //                 return prevOptions.map((option: any) => {
    //                     switch (option.cardTitle) {
    //                         case "Invoice Number":
    //                             return { ...option, options: updatedInvoiceNumber };
    //                         case "Vendor Name":
    //                             return { ...option, options: updatedVendorName };
    //                         case "Amount to Vendor":
    //                             return { ...option, options: updatedAmounttoVendor };
    //                         case "Transaction Charges":
    //                             return { ...option, options: updatedTransactionCharges };
    //                         case "Total Amount":
    //                             return { ...option, options: updatedTotalAmount };
    //                         case "Currency":
    //                             return { ...option, options: updatedCurrency };
    //                         case "Payment Method":
    //                             return { ...option, options: updatedPaymentMethod };
    //                         case "Transaction Information":
    //                             return { ...option, options: updatedTransactionInformation };
    //                         case "Payment Initiated At":
    //                             return { ...option, options: updatedPaymentInitiatedAt };
    //                         case "Payment Initiated By":
    //                             return { ...option, options: updatedPaymentInitiatedBy };
    //                         case "Processed At":
    //                             return { ...option, options: updatedProcessedAt };
    //                         case "Reversed At":
    //                             return { ...option, options: updatedReversedAt };
    //                         case "Transaction Status":
    //                             return { ...option, options: updatedTransactionStatus };
    //                         case "Failure Reason":
    //                             return { ...option, options: updatedFailureReason };
    //                         case "Transaction Ref Number":
    //                             return { ...option, options: updatedTransactionRefNumber };
    //                         case "Payout ID":
    //                             return { ...option, options: updatedPayoutID };

    //                         default:
    //                             return option;
    //                     }
    //                 });
    //             });

    //             setFilterValues((prevOptions: any) => {
    //                 return prevOptions.map((option: any) => {
    //                     switch (option.cardTitle) {
    //                         case "Invoice Number":
    //                             return { ...option, options: updatedInvoiceNumber };
    //                         case "Vendor Name":
    //                             return { ...option, options: updatedVendorName };
    //                         case "Amount to Vendor":
    //                             return { ...option, options: updatedAmounttoVendor };
    //                         case "Transaction Charges":
    //                             return { ...option, options: updatedTransactionCharges };
    //                         case "Total Amount":
    //                             return { ...option, options: updatedTotalAmount };
    //                         case "Currency":
    //                             return { ...option, options: updatedCurrency };
    //                         case "Payment Method":
    //                             return { ...option, options: updatedPaymentMethod };
    //                         case "Transaction Information":
    //                             return { ...option, options: updatedTransactionInformation };
    //                         case "Payment Initiated At":
    //                             return { ...option, options: updatedPaymentInitiatedAt };
    //                         case "Payment Initiated By":
    //                             return { ...option, options: updatedPaymentInitiatedBy };
    //                         case "Processed At":
    //                             return { ...option, options: updatedProcessedAt };
    //                         case "Reversed At":
    //                             return { ...option, options: updatedReversedAt };
    //                         case "Transaction Status":
    //                             return { ...option, options: updatedTransactionStatus };
    //                         case "Failure Reason":
    //                             return { ...option, options: updatedFailureReason };
    //                         case "Transaction Ref Number":
    //                             return { ...option, options: updatedTransactionRefNumber };
    //                         case "Payout ID":
    //                             return { ...option, options: updatedPayoutID };

    //                         default:
    //                             return option;
    //                     }
    //                 });
    //             });

    //         }
    //     } catch (error) {
    //         console.log("error", error)
    //     }
    // }

    // async function handleApplyButtonForFilter(data: any) {
    //     const convertValue = convertToFilterValues(filterValue)
    //     console.log(convertValue)
    //     if (
    //         convertValue['BillingCycle'] == "All" &&
    //         convertValue['Category'] == "All" &&
    //         convertValue['CreditPeriod'] == "All" &&
    //         convertValue['CustomLimits'] == "All" &&
    //         convertValue['DailyLimits'] == "All" &&
    //         convertValue['IsActive'] == "All" &&
    //         convertValue['IsApproved'] == "All" &&
    //         convertValue['Issuer'] == "All" &&
    //         convertValue['MaxLimit'] == "All" &&
    //         convertValue['NameOnCard'] == "All" &&
    //         convertValue['Number'] == "All" &&
    //         convertValue['SingleTransactionLimits'] == "All" &&
    //         convertValue['Type'] == "All" &&
    //         convertValue['ValidFrom'] == "All" &&
    //         convertValue['ValidThru'] == "All"
    //     ) {
    //         setFilteredData(orginalRowData)
    //         console.log(orginalRowData)
    //     } else {
    //         console.log(orginalRowData)
    //         const filteredData: any = orginalRowData && orginalRowData.filter((ele: any) => {
    //             console.log(ele.TransactionLimits['Daily Limits'])
    //             const BillingCycleCondtion = convertValue['BillingCycle'] === 'All' || ele.Overview['Billing Cycle'] === convertValue['BillingCycle'];
    //             const CategoryCondtion = convertValue['Category'] === 'All' || ele.Overview.Category === convertValue['Category'];
    //             const CreditPeriodCondtion = convertValue['CreditPeriod'] === 'All' || ele.Overview['Credit Period'] === convertValue['CreditPeriod'];
    //             const CustomLimitsCondtion = convertValue['CustomLimits'] === 'All' || ele.TransactionLimits['Custom Limits'] === convertValue['CustomLimits'];
    //             const DailyLimitsCondtion = convertValue['DailyLimits'] === 'All' || ele.TransactionLimits['Daily Limits'] == convertValue['DailyLimits'];
    //             const IsActiveCondtion = convertValue['IsActive'] === 'All' || ele.Status['Created At'] === convertValue['IsActive'];
    //             const IsApprovedCondtion = convertValue['IsApproved'] === 'All' || ele.Status['Is Approved'] === convertValue['IsApproved'];
    //             const IssuerCondtion = convertValue['Issuer'] === 'All' || ele.Details.Issuer === convertValue['Issuer'];
    //             const MaxLimitCondtion = convertValue['MaxLimit'] === 'All' || ele.TransactionLimits['Discount'] === convertValue['MaxLimit'];
    //             const NameOnCardCondtion = convertValue['NameOnCard'] === 'All' || ele.Details['Name on Card'] === convertValue['NameOnCard'];
    //             const NumberCondtion = convertValue['Number'] === 'All' || ele.Details['Number'] === convertValue['Number'];
    //             const SingleTransactionLimitsCondtion = convertValue['SingleTransactionLimits'] === 'All' || ele.TransactionLimits['Single Transaction Limits'] === convertValue['SingleTransactionLimits'];
    //             const TypeCondtion = convertValue['Type'] === 'All' || ele.Overview.Type === convertValue['Type'];
    //             const ValidFromCondtion = convertValue['ValidFrom'] === 'All' || ele.Details['Valid From'] === convertValue['ValidFrom'];
    //             const ValidThruCondtion = convertValue['ValidThru'] === 'All' || ele.Details['Valid Thru'] === convertValue['ValidThru'];


    //             return BillingCycleCondtion
    //                 && CategoryCondtion
    //                 && CreditPeriodCondtion
    //                 && CustomLimitsCondtion
    //                 && DailyLimitsCondtion
    //                 && IsActiveCondtion
    //                 && IsApprovedCondtion
    //                 && IssuerCondtion
    //                 && MaxLimitCondtion
    //                 && NameOnCardCondtion
    //                 && NumberCondtion
    //                 && SingleTransactionLimitsCondtion
    //                 && TypeCondtion
    //                 && ValidFromCondtion
    //                 && ValidThruCondtion
    //         });
    //         setFilteredData(filteredData);
    //     }
    //     setFilterOpen(false)

    // }

    const updateFilterDatas = async (index: number, newData: any) => {
        setStoreFilterOptions((prevFilterDatas: any) => {
            const updatedFilterDatas = [...prevFilterDatas];
            updatedFilterDatas[index] = newData;
            return updatedFilterDatas;
        });
    }

    const handleOpenClose = (index: number) => {
        if (openDropdownIndex === index) {
            setOpenDropdownIndex(null); // Close the dropdown if it's already open
        } else {
            setOpenDropdownIndex(index); // Open the dropdown
        }
    };

    return (
        <>
            <div>

                <div className='report-table-component' style={{ marginTop: "20px", marginBottom: "40px", overflow: "auto" }}>
                    <table>
                        <thead>
                            {/* <tr> */}
                            <th className='report-heading' style={{ paddingLeft: "20px", minWidth: "100px" }}>View</th>
                            <th className='report-heading' style={{ minWidth: "" }}>Invoice Number</th>
                            <th className='report-heading' style={{ minWidth: "" }}>Vendor Name</th>
                            <th className='report-heading' style={{ minWidth: "200px", textAlign: "right" }}>Amount to Vendor</th>
                            <th className='report-heading' style={{ minWidth: "", textAlign: "right", paddingRight: "25px" }}>Transaction Charges</th>
                            <th className='report-heading' style={{ minWidth: "", textAlign: "right", paddingRight: "20px" }}>Total  Amount</th>
                            <th className='report-heading' style={{ minWidth: "" }} >Currency</th>
                            <th className='report-heading' style={{ minWidth: "" }} >Payment Method</th>
                            <th className='report-heading' style={{ minWidth: "" }}  >Transaction Information</th>
                            <th className='report-heading' style={{ minWidth: "" }} >Payment Initiated At</th>
                            <th className='report-heading' style={{ minWidth: "" }} >Payment Initiated By</th>
                            <th className='report-heading' style={{ minWidth: "" }} >Processed At</th>
                            <th className='report-heading' style={{ minWidth: "" }} >Reversed At</th>
                            <th className='report-heading' style={{ minWidth: "" }} >Transation Status</th>
                            <th className='report-heading' style={{ minWidth: "" }} >Failure Reason</th>
                            <th className='report-heading' style={{ minWidth: "" }} >Transaction Ref number</th>
                            <th className='report-heading' style={{ minWidth: "250px" }} >Payout ID</th>
                            {/* </tr> */}
                        </thead>
                        <tbody>
                            {

                                filteredData.length === 0 ?
                                    <tr className='style-grid-differ'>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td style={{ textAlign: "center" }}>No records to show</td>
                                        <td></td>
                                        <td></td>

                                    </tr> :

                                    Object.values(filteredData).map((data: any, index: any) => (
                                        data.map((insideData: any, index: any) => (
                                            <tr style={{ border: "1px solid #004E9C4C", marginBottom: "5px" }}>
                                                {
                                                    index == 0 &&
                                                    <>
                                                        <td rowSpan={data.length} className='report-details' style={{ paddingLeft: "0px", border: "1px solid #004E9C4C" }}>
                                                            <div className='view-invoice-eyeicon' style={{ width: "80px", display: "flex", justifyContent: "center" }} >
                                                                <IconButton
                                                                    iconName={"ShowEye"}
                                                                    height={"35px"}
                                                                    width={"30px"}
                                                                    fontSize={""}
                                                                    className={"icons"}
                                                                    cursor={"pointer"}
                                                                    color={"red"}
                                                                    backgroundColor={""}
                                                                   
                                                                    hover={false}
                                                                    handleClick={() => {
                                                                        setVendorSettlementowData(data[0].invoiceDatas)
                                                                        setInvoicerOpen(!invoicerOpen);
                                                                        SetCurrentFile("")
                                                                    }} />
                                                            </div>
                                                        </td>
                                                        <td rowSpan={data.length} className='report-details' style={{ width: "200px", border: "1px solid #004E9C4C" }}>
                                                            <p style={{ width: "200px",paddingLeft:"5px" }}>{insideData.invoiceNumber}</p>
                                                        </td>
                                                        <td rowSpan={data.length} className='report-details' style={{ width: "200px", border: "1px solid #004E9C4C" }} >
                                                            <p style={{ width: "200px", whiteSpace: "normal",paddingLeft:"5px" }}>{insideData.vendorName}</p>
                                                        </td>

                                                    </>
                                                }
                                                <td className='report-details' style={{ width: "500px" }} >
                                                    <p style={{ width: "200px", textAlign: "right" }}>{formatAmount(insideData.amountToVendor)}</p>
                                                </td>
                                                <td className='report-details' >
                                                    <p style={{ width: "225px", textAlign: "right" }}>{formatAmount(insideData.transactionCharges)}</p>
                                                </td>
                                                <td className='report-details' >
                                                    <p style={{ width: "230px", textAlign: "right" }}>{formatAmount(insideData.totalAmount)}</p>
                                                </td>
                                                <td className='report-details' >
                                                    <p style={{ width: "200px" }}>{insideData.currency}</p>
                                                </td>
                                                <td className='report-details'>
                                                    <p style={{ width: "200px" }}>{insideData.paymentMethod}</p>
                                                </td>
                                                <td className='report-details' >
                                                    <p style={{ width: "200px" }}>{insideData.transactionInformation}</p>
                                                </td>
                                                <td className='report-details' >
                                                    <p style={{ width: "300px" }}>{insideData.paymentInitiatedAt}</p>
                                                </td>
                                                <td className='report-details' >
                                                    <p style={{ width: "200px" }}>{insideData.paymentInitiatedBy}</p>
                                                </td>
                                                <td className='report-details' >
                                                    <p style={{ width: "200px" }}>{insideData.processedAt}</p>
                                                </td>
                                                <td className='report-details' >
                                                    <p style={{ width: "200px" }}>{insideData.reversedAt}</p>
                                                </td>
                                                <td className='report-details' >
                                                    <p style={insideData.transactionStatus === "PAID" || insideData.transactionStatus === "Paid" ? { width: "200px", color: "#009E60" } : { width: "200px" }}>{insideData.transactionStatus}</p>
                                                </td>
                                                <td className='report-details' >
                                                    <p style={{ width: "200px" }}>{insideData.failureReason}</p>
                                                </td>
                                                <td className='report-details' >
                                                    <p style={{ width: "250px" }}>{insideData.transactionRefNumber}</p>
                                                </td>
                                                <td className='report-details' >
                                                    <p style={{ width: "250px" }}>{insideData.payoutId}</p>
                                                </td>
                                            </tr>
                                        ))

                                    ))
                            }

                        </tbody>

                    </table>
                </div>


            </div>
            {
                showDocumentModal &&
                <DocumentModal
                    closeDocument={(val: boolean) => { setShowDocumentModal(val); }}
                    document={currentFile}
                    showDocumentModal={showDocumentModal}
                />
            }
            {
                invoicerOpen &&
                <VendorSettlementInvoice
                    invoice={""}
                    currentActor={""}
                    approvalHistory={[]}
                    vendorSettlementowData={vendorSettlementowData}
                    invoiceDetails={() => { "" }}
                    closeDocument={(val: boolean) => { setInvoicerOpen(val); }}
                />
            }
            {
                filterOpen &&
                <div className="right-side-filter-component">
                    <ChartFilter
                        datas={storeFilterOptions}
                        updateFilterDatas={updateFilterDatas}
                        handleOpenClose={handleOpenClose}
                        openDropdownIndex={openDropdownIndex}
                        handleApplyButtonForFilter={() => {
                            //  handleApplyButtonForFilter("")  
                        }}
                        setOpenRightSideFilter={() => { setFilterOpen(false) }}
                    />
                </div>
            }
        </>
    )
}






