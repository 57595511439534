import { useEffect, useRef, useState } from 'react'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut, getElementAtEvent } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

interface type {
    width: string;
    height: string;
    options: any;
    data: any;
    orginalBarchartData?: any;
    HandleBarChartClick?: (barValue: string, label: any, selectedDatasetIndex:any) => void;
}



const DoughnutPieChart = ({ width, height, options, data, HandleBarChartClick, orginalBarchartData }: type) => {
    const chartRef: any = useRef();
// console.log(data, "======>")
    // const onClick = (event: any) => {
    //     console.log(event)
    //     if (chartRef.current) {
    //         const elements = getElementAtEvent(chartRef.current, event);
    //         if (elements && elements.length > 0) {
    //             const selectedIndex: any = elements[0].index;
    //             const selectedDatasetIndex: any = elements[0].datasetIndex;
    //             // const selectBarValue = orginalBarchartData[selectedIndex].ResponseStatusCode
    //             HandleBarChartClick && HandleBarChartClick(selectedIndex, selectedDatasetIndex);
    //         }
    //     }
    // };

    const onClick = (event: any) => {
        if (chartRef.current) {
            const elements = getElementAtEvent(chartRef.current, event);
            if (elements && elements.length > 0) {
                const selectedIndex: any = elements[0].index;
                const selectedDatasetIndex: any = elements[0].datasetIndex;
    
                // Assuming labels are available in the chart data
                const labels = data.labels;
                const selectedLabel = labels[selectedIndex];
                const numericPart = selectedLabel.split(' ').pop();
                HandleBarChartClick && HandleBarChartClick(numericPart, selectedIndex, selectedDatasetIndex);
            }
        }
    };
    

    return (
        <div className='pie-chart-container' style={{ width: width, height: height, display: "flex", justifyContent: "center" }}>
            <Doughnut data={data} options={options} ref={chartRef} onClick={onClick} />
        </div>
    );
};

export default DoughnutPieChart;


