import { useCallback, useMemo, useRef, useState, useEffect, useImperativeHandle, forwardRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { v4 as uuidv4 } from 'uuid';
import '../../../styles/payable/vendor/bulk-payment-ag-grid.scss';


interface Props {
    tabelRowData?: any;
    tableColumnData?: any;
    doubleClickUpdate?: ((val: any) => void) | undefined;
    setBulkInvoices?: ((value: any) => void) | undefined;
    getEdit?: (data: any) => void;
    getCurruntData?: (data: any) => void;
    fileName?: string
    displaylength?: number
    singleSelect?: boolean
};

export interface AgGridRef {
    onBtExport: () => void;
    clearFilters: () => void;

}


const BulkPaymentAgGrid = forwardRef(({
    tabelRowData,
    tableColumnData,
    doubleClickUpdate,
    getEdit,
    getCurruntData,
    setBulkInvoices,
    fileName,
    displaylength,
    singleSelect
}: Props, ref) => {
    const gridRef = useRef<any>(null)
    const containerStyle = useMemo(() => ({ width: '100%', height: '100%' }), []);
    const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
    const [columnDefs, setColumnDefs] = useState(tableColumnData);
    const [isGridEyeFilter, setIsGridEyeFilter] = useState<boolean>(false);
    const [headerList, setHeaderList] = useState<any[]>([]);
    // let value = {
    //     field: 'empty', headerName: '', minWidth: 75, maxWidth: 75, resizable: false, suppressMovable: true,
    //     cellStyle: { textAlign: 'center' },
    //     headerComponentFramework: ColumnHeadingsFillterIcon,
    //     headerComponentParams: { handleGridEyeFilter: (props: boolean) => handleGridEyeFilter(props), isGridEyeFilter },
    // };


    // const onGridCellValueChanged = (event: any) => {

    //     const newValue = event.newValue;
    //     const { rowIndex, colDef } = event;

    //     // Ensure that bulkInvoices is an array of arrays
    //     if (!Array.isArray(tabelRowData)) {
    //         console.error('Invalid bulkInvoices structure');
    //         return;
    //     }

    //     // Update the data based on the unique invoice_payable_bulk_id
    //     const updatedBulkInvoices = tabelRowData.map((invoice, index) => {
    //         if (rowIndex === index) {
    //             return {
    //                 ...invoice,
    //                 [colDef.field]: newValue,
    //             };
    //         }
    //         return invoice;
    //     }
    //     );

    //     // Update the state with the new data
    //     setBulkInvoices && setBulkInvoices(updatedBulkInvoices);
    // };



    // useEffect(() => {
    //     const displaylengthCount = displaylength ? displaylength : 8   //Condition for displaying length

    //     if (tableColumnData.length > displaylengthCount) {   // 8 Act------------------------>
    //         let slicedTableColumnData = tableColumnData.slice(0, displaylengthCount);
    //         setColumnDefs([...slicedTableColumnData, value])
    //     } else {
    //         setColumnDefs([...tableColumnData, value])
    //     }
    // }, []);


    useEffect(() => {
        if (gridRef.current.api) {
            onGridReady(null);
        }
    }, [columnDefs])


    useEffect(() => {
        window.addEventListener('error', e => {
            if (e.message === 'ResizeObserver loop limit exceeded' || e.message === 'ResizeObserver loop completed with undelivered notifications.') {
                const resizeObserverErrDiv = document.getElementById(
                    'webpack-dev-server-client-overlay-div'
                );
                const resizeObserverErr = document.getElementById(
                    'webpack-dev-server-client-overlay'
                );
                if (resizeObserverErr) {
                    resizeObserverErr.setAttribute('style', 'display: none');
                }
                if (resizeObserverErrDiv) {
                    resizeObserverErrDiv.setAttribute('style', 'display: none');
                }
            }
        });
    }, []);


    // useEffect(() => {
    //     const value: any[] = tableColumnData
    //         .filter((column: any) => column.headerName)
    //         .map((column: any) => ({ name: column.headerName, id: uuidv4() }));
    //     setHeaderList(value)
    // }, [columnDefs]);


    const handleGridEyeFilter = (iconClicked: boolean) => {
        setIsGridEyeFilter(!iconClicked)
    }

    const getParams = () => {
        return {
            fileName: fileName,
            processCellCallback: (params: any) => {
                if (params.column.getColDef().field === 'selection') {
                    // Handle the multi-select cell value appropriately (assuming it's an array of objects)
                    if (Array.isArray(params.value)) {
                        const selectedValues = params.value.map((item: any) => item.name).join(', ');
                        return selectedValues;
                    } else if (params.value && typeof params.value === 'object') {
                        // Handle the case where params.value is an object (e.g., single selected value)
                        return params.value.name; // Assuming the object has a property 'name'
                    } else {
                        // Handle the case where params.value is not an array or object (e.g., return an empty string)
                        return '';
                    }
                }
                return params.value;
            },
        };
    };


    useImperativeHandle(ref, () => {
        return {
            onBtExport: onBtExport,
            clearFilters: clearFilters,
        }
    });


    const onBtExport = useCallback(() => {
        const gridApi = gridRef.current.api;
        const originalColumnDefs = gridApi.getColumnDefs();
        gridApi.setColumnDefs(originalColumnDefs.filter((colDef: any) => colDef.field !== 'selection'));

        // Export the data as CSV with modified columnDefs
        gridApi.exportDataAsCsv(getParams());

        // Revert back to the original columnDefs
        gridApi.setColumnDefs(originalColumnDefs);
    }, [gridRef]);

    const clearFilters = useCallback(() => {
        gridRef.current!.api.setFilterModel(null);
    }, []);

    const getCurrentData = () => {
        if (gridRef.current) {
            const rowData = gridRef.current.api.getModel().rowsToDisplay.map((rowNode: any) =>
                rowNode.data
            );
            return rowData;
        }
        return [];
    };

    // Example of how to use getCurrentData
    const handleGetDataClick = () => {
        const currentData = getCurrentData();
    };

    // function handleHeaderNames(filteredData: any) {
    //     if (filteredData.length > 0) {
    //         let tmpColumnData: any = [];
    //         tmpColumnData.push(tableColumnData[0]);
    //         filteredData.map((item: any, index: number) => {
    //             tableColumnData.map(function (col: any) {
    //                 if (col.headerName == item.name) {
    //                     tmpColumnData.push(col)
    //                 }
    //             });
    //         });
    //         const filteredColumns = tmpColumnData.filter((column: any) => !tmpColumnData.includes(column.headerName));
    //         setColumnDefs(filteredColumns)
    //     } else {
    //         setColumnDefs([tableColumnData[0], value])
    //     }
    // }

    // const defaultColDef = useMemo(() => {
    //     return {
    //         editable: false,
    //         sortable: true,
    //         filter: true,
    //         resizable: true,
    //     };
    // }, []);

    const cellClickedListener = useCallback((event: any) => {
        // const rowNode = event.api.getRowNode(event.rowIndex);
        // rowNode.setSelected(!rowNode.isSelected())
        event.data && getCurruntData?.(event.data);
        // event.data && selectedData?.(event.data);
    }, []);


    const MulticellClickedListener = useCallback((event: any) => {
        event.data && getCurruntData?.(event.data);

    }, []);



    const onGridReady = useCallback((params: any) => {
        gridRef.current.api.sizeColumnsToFit();
        // gridRef.current.api.addEventListener('paginationChanged', (e:any) =>
        // {
        //     resetPaginationSelection();
        // });
    }, []);

    const resetPaginationSelection = () => {
        //Deselect previously selected rows to reset selection
        gridRef.current.api.deselectAll();

        //Initialize pagination data
        let paginationSize = gridRef.current.api.paginationGetPageSize();
        let currentPageNum = gridRef.current.api.paginationGetCurrentPage();
        let totalRowsCount = gridRef.current.api.getDisplayedRowCount();

        //Calculate current page row indexes
        let currentPageRowStartIndex = (currentPageNum * paginationSize);
        let currentPageRowLastIndex = (currentPageRowStartIndex + paginationSize);
        if (currentPageRowLastIndex > totalRowsCount) currentPageRowLastIndex = (totalRowsCount);

        for (let i = 0; i < totalRowsCount; i++) {
            //Set isRowSelectable=true attribute for current page rows, and false for other page rows
            let isWithinCurrentPage = (i >= currentPageRowStartIndex && i < currentPageRowLastIndex);
            gridRef.current.api.getDisplayedRowAtIndex(i).setRowSelectable(isWithinCurrentPage);
        }
    };

    const handleCellDoubleClick = useCallback((event: any) => {
        event.data && getEdit?.(event.data)
    }, []);

    const onSelectionChanged = useCallback(() => {
        var selectedRows = gridRef.current.api.getSelectedRows();
        getCurruntData && getCurruntData(selectedRows);
    }, []);

    return (
        <div style={containerStyle} className='aggrid-component' data-testid={"column-header"}>
            <div className={`ag-theme-alpine ag-theme-alpine-Bulk`}>
                <AgGridReact
                    ref={gridRef}
                    rowHeight={40}
                    groupHeaderHeight={30}
                    headerHeight={40}
                    paginationPageSize={10}
                    pagination={false}
                    rowData={tabelRowData}
                    columnDefs={columnDefs}
                    rowSelection={singleSelect ? 'single' : 'multiple'}
                    rowMultiSelectWithClick={singleSelect ? false : true}
                    // defaultColDef={defaultColDef}
                    onGridReady={onGridReady}
                    onComponentStateChanged={onGridReady}
                    onGridSizeChanged={onGridReady}
                    // onCellClicked={cellClickedListener}
                    // onRowSelected={MulticellClickedListener}
                    suppressRowTransform={true}
                    onCellDoubleClicked={handleCellDoubleClick}
                    onSelectionChanged={onSelectionChanged}
                // onCellEditingStopped={(params) =>}
                // onCellValueChanged={(params) =>}
                // onCellKeyPress={onGridCellValueChanged}
                // onCellEditingChanged={onGridCellValueChanged}
                // onCellKeyPress={}
                ></AgGridReact>
            </div>
        </div>
    );
});

export default BulkPaymentAgGrid;