
import React, { useEffect, useRef, useState } from 'react'
import IconButton from '../../common-component/form-elements/icon-button';
import MainPageCard from '../../common-component/cards/main-page-card';
import NavigateHeader from '../../common-component/navigate-header';
import '../../../styles/payable/direct-tax/direct-tax-approval-grid.scss'
import { v4 as uuidv4 } from 'uuid'
import { gstColumnData } from '../../../config/gst/gst';
import AgGrid, { AgGridRef } from '../../common-component/grids-and-tables/ag-grid'
import GridFilter from '../../common-component/grids-and-tables/grid-filter';
import { Filter } from '../../../helpers/filtterFunction'
import CustomEllipsisRenderer from '../../../config/product-admin/users/users-aggrid-column-config';
import { DateConverterAgGrid } from "../../../helpers/capitalizetext";
import cellEyeIcon from "../../common-component/grids-and-tables/cell-eye-icon";
import GstViewChallanModal from '../../common-component/modals/gst-view-challan-modal';
import { axiosPrivate } from '../../../middleware/axois-api';
import ButtonComponent from '../../common-component/form-elements/button-component';
import AlertBox from '../../common-component/alert-box';
import Loading from '../../common-component/modals/loading-screen';
import { directTaxColumnData, sampleDirectGridData } from '../../../config/payable/direct-tax.tsx/direct-tax-config-data';

interface Props {
    gstView: (val: any) => void;
    addMode?: () => void;
    getEditData?: (nav: any, data: any) => void;
    transaction?: (val: any) => void;
    makePaymentDataFun?: (val: any) => void;
    directTaxFormDatasFun?: (val: any) => void;
    formDataByIdFun?: (val: any) => void;
}
interface IFilterValue {
    [key: string]: {
        columnName: string;
        filterType: string;
        filterWord: string;
    };
}
function DirectTaxApprovalGrid({ gstView, transaction, makePaymentDataFun, directTaxFormDatasFun, formDataByIdFun }: Props) {


    const companyDownloadRef = useRef<AgGridRef>(null);
    const [filter, setFilter] = useState(false);
    const [filterRestore, setFilterRestore] = useState<IFilterValue>({})
    const [filterMethod, setFilterMethod] = useState("")
    const [curruntData, setCurruntData] = useState<any>([]);
    const [rowData, setRowData] = useState<any>([]);
    const [orginalRowData, setOrginalRowData] = useState<any>([]);
    const [rowExpandDataExpand, setRowExpandDataExpand] = useState<any>([]);
    const [gstEyeShowModal, setGstEyeShowModal] = useState<boolean>(false);
    const [totalAmountData, setTotalAmountData] = useState('');
    const [mainChallanDetails, setMainChallanDetails] = useState([]);
    const [approvalStatus, setApprovalStatus] = useState([]);
    const [mainData, setMainData] = useState('');
    const [currentData, setCurrentData] = useState([]);

    const [showType, setShowType] = useState("warning") // error message showType
    const [showMessage, setShowMessage] = useState<string>("") // error message showMessage
    const [showAlertBox, setShowAlertBox] = useState(false) // error message ShowAlertBox
    const [loadingScreen, setLoadingScreen] = useState(false);


    //functions

    const handleFilter = (value: any, filterType: string) => {
        Filter(value, filterType, orginalRowData, setRowData)
    }

    // const calculateTotal = () => {
    //     let sum = 0;
    //     rowExpandDataExpand.forEach((item: any) => {
    //         const total = parseFloat(item?.Total.replace(/,/g, ''));
    //         sum += total;
    //     });
    //     return sum.toFixed(2); // Round to two decimal places if needed
    // };

    const formatAmountWithCommas = (amount: any) => {
        return parseFloat(amount).toLocaleString('en-US', {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
    };
    const handleDownloadExcel = () => {
        companyDownloadRef.current!.onBtExport();
    }
    //useEffects

    // useEffect(() => {
    //     const total = calculateTotal();
    //     const formattedTotal = formatAmountWithCommas(total);
    //     setTotalAmountData(formattedTotal)
    // }, [rowExpandDataExpand]);

    // useEffect(() => {
    //     getGstDataMainGrid()
    // }, []);

    // grid data

    async function getGstDataMainGrid() {
        // setRowData(sampleDirectGridData)
        // setOrginalRowData(sampleDirectGridData)
        // await axiosPrivate.get(`/gst-payable/get-gst-data`)
        //     .then((response: any) => {
        //         setRowData(response.data.gstData)
        //         setOrginalRowData(response.data.gstData)
        //     })
        //     .catch(error => {
        //         console.error(error);
        //     });
    }

    // async function getTableId(val: any) {
    //     await axiosPrivate.get(`/gst-payable/get-gst-expand-data/${val.payableGstId}`)
    //         .then((response: any) => {
    //             setRowExpandDataExpand(response.data.gstExpandDetails)
    //         })
    //         .catch(error => {
    //             console.error(error);
    //         });
    // }

    const clearAlert = (status: any) => {
        const timer = setTimeout(() => {
            setShowAlertBox(false);
            setShowMessage("");
            clearTimeout(timer);
        }, 5000);
    }

    async function makePayment() {
        gstView('form')
        // setLoadingScreen(true)
        // const isPaidChallan = currentData.some((item: any) => item.PaymentStatus === 'Paid');
        // if (isPaidChallan) {
        //     setShowAlertBox(true)
        //     setLoadingScreen(false)
        //     setShowType('danger')
        //     setShowMessage("Selected Challan has already been paid.");
        //     clearAlert('');
        // } else {
        //     const data = {
        //         makePaymentChallanIds: currentData.map((val: any) => {
        //             return (val.payableGstId).toString();
        //         })
        //     };
        //     // setMakePaymentData(data)
        //     makePaymentDataFun(data)
        //     await axiosPrivate.post(`gst-payable/get-makepayment-challan-datas`, data)
        //         .then((response: any) => {
        //             setLoadingScreen(false)
        //             transaction(response.data.MakePaymentDetails)
        //             gstView("form");
        //         })
        //         .catch(error => {
        //             setLoadingScreen(false)
        //             setShowType('danger')
        //             setShowMessage(`Error : ${error}`);
        //             clearAlert('');
        //             console.error(error);
        //         });
        // }

    }

    useEffect(() => {
        getGridData()
    }, [])

    async function getGridData() {
        try {
            const res = await axiosPrivate.get('/directtax-payable/get-grid-datas');
            setRowData(res.data.directTaxGridData)
        } catch (error) {
            console.log("Error in getGridData function");
        }
    }
    async function getFormDataById(id: any) {
        try {
            const res = await axiosPrivate.post(`/directtax-payable/get-form-datas/${id}`);
            if (res.status == 200) {
                if(formDataByIdFun){
                    formDataByIdFun(res.data.decryptedDirectTaxDetails)
                }
            }


        } catch (error) {
            console.log("Error in getFormDataById function");
        }
    }
    return (
        <MainPageCard>
            <div className='header-grid-gst'>
                <div className='left-header'>
                    <NavigateHeader
                        firstValue={"Approval"}
                        secondValue={"Direct Tax"}
                    />
                </div>
                <IconButton
                    iconName={"Filter"}
                    height={"40px"}
                    width={"40px"}
                    fontSize={"30px"}
                    margin={"0px 8px"}
                    color={"white"}
                    backgroundColor={"#0055D4"}
                    hover={true}
                    handleClick={(event: React.MouseEvent) => {
                        event?.stopPropagation()
                        setFilter(!filter);
                    }}
                />
                <IconButton
                    iconName={"Delete"}
                    height={"40px"}
                    width={"40px"}
                    fontSize={"25px"}
                    margin={"0px 8px"}
                    color={"white"}
                    disabled={true}
                    opacity={"0.5"}
                    cursor={"not-allowed"}
                    backgroundColor={"#D60000"}
                    hover={curruntData.length > 0}
                    handleClick={() => {
                    }}
                />
                <IconButton
                    iconName={"Add"}
                    height={"40px"}
                    width={"75px"}
                    fontSize={"25px"}
                    margin={"0px 8px"}
                    color={"white"}
                    backgroundColor={"#0055D4"}
                    hover={false}
                    disabled={true}
                    opacity={"0.5"}
                    cursor={"not-allowed"}
                    handleClick={() => {
                        // gstView("form");
                        // addMode();
                        // handleTransactionGenerate()
                    }}
                />
                <IconButton
                    iconName={"Upload"}
                    height={"40px"}
                    width={"40px"}
                    fontSize={"25px"}
                    margin={"0px 0px 0px 8px"}
                    color={"white"}
                    backgroundColor={"#0055D4"}
                    hover={false}
                    disabled={true}
                    opacity={"0.5"}
                    cursor={"not-allowed"}
                    handleClick={() => {
                    }}
                />
                <IconButton
                    iconName={"Download"}
                    height={"40px"}
                    width={"40px"}
                    fontSize={"25px"}
                    margin={"0px 8px"}
                    color={"white"}
                    backgroundColor={"#0055D4"}
                    hover={true}
                    handleClick={() => {
                        handleDownloadExcel()
                    }}
                />
            </div>
            <div className='direct-tax-group-grid'>
                <AgGrid
                    tabelRowData={rowData}
                    tableColumnData={directTaxColumnData}
                    ref={companyDownloadRef}
                    fileName={"DirectTaxApprovalData"}
                    getCurruntData={(val) => {
                        setCurrentData(val);
                        if(makePaymentDataFun){
                            makePaymentDataFun(val)
                        }
                    }}
                    doubleClickUpdate={() => {
                    }}
                    getEdit={(val: any) => {
                        if (formDataByIdFun) {
                            getFormDataById(val.payableDirectTaxId)
                        }
                        // if (directTaxFormDatasFun) {
                        //     directTaxFormDatasFun(val)
                        // }
                        // console.log("value", val);
                        // getTableId(val)
                    }}
                />
            </div>
            <div className='footer-gst-makepayment'>
                <div style={currentData && currentData.length > 0 ? {} : { cursor: "not-Allowed" }}>
                    <ButtonComponent
                        title={"Make Payment"}
                        height={"50px"}
                        width={"150px"}
                        margin={"0px 8px"}
                        backgroundColor={"#0055D4"}
                        color={"white"}
                        disabled={currentData && currentData.length > 0 ? false : true}
                        className={currentData && currentData.length > 0 ? "button-component-hover common-btn" : "button-component-hover disabled"}
                        handleClick={() => {
                            makePayment()

                        }}
                    />
                </div>
            </div>

            {
                filter &&
                <div className='filter' key={uuidv4()}>
                    <GridFilter
                        filterRestore={filterRestore}
                        filterMethod={filterMethod}
                        tabelRowData={rowData}
                        data={gstColumnData.slice(1)}
                        handleClick={(Value: any, filterType: string) => {
                            if (filterType !== "") {
                                setFilterRestore(Value)
                                setFilter(!filter)
                                setFilterMethod(filterType)
                                handleFilter(Value, filterType)
                                setCurruntData([]);

                            } else {
                                setFilterMethod("")
                                setFilterRestore({})
                                setFilter(!filter)
                                setRowData(orginalRowData)
                                setCurruntData([]);
                            }
                        }}
                    />
                </div>
            }
            {
                showAlertBox &&
                <div className='alert-warp'>
                    <AlertBox type={showType} message={showMessage} />
                </div>

            }
            {
                loadingScreen &&
                <div className='alert-warp'>
                    <Loading />
                </div>

            }
        </MainPageCard >
    )
}

export default DirectTaxApprovalGrid