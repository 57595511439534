import React, { useEffect, useState, forwardRef, useImperativeHandle, } from 'react'
import InputComponent from '../../../common-component/form-elements/input-component'
import DropdownComponent from '../../../common-component/form-elements/dropdown-component'
import ButtonComponent from '../../../common-component/form-elements/button-component'
import StatutoryDetailsGrid from '../../../../config/organisation/company/statutory-details-grid'
import { v4 as uuidv4 } from 'uuid'
import Loading from '../../../common-component/modals/loading-screen'
import useAxiosPrivate from "../../../../services/hooks/useaxios-private";
import AlertBox from '../../../common-component/alert-box'
import { useDispatch, useSelector } from "react-redux";
import { resetFormModified } from "../../../../redux/action";
import Changesmodal from '../../../common-component/modals/changes-modal';
import DeleteModal from '../../../common-component/modals/delete-modal'
import ExciseDetailsGrid from '../../../../config/organisation/company/excise-info-grid'

// statutoryDetails
interface Props {
  excsieDetailsMain: any;
  onboardingViewfun: (val: any) => void;
  sendDataApi: () => void;
  iconStatus: any
  editData: any
  setShowPage: (val: any) => void;
  setIconStatus: (val: any) => void;
  setExcsieDetailsMain: (value: any) => void;
  isEditMode: boolean;
  mainApiData: any;


}
interface DropdownItem {
  label: string;
  value: string;
}
export interface ChildRef {
  next: (val: any) => void;
}
const ExciseDetails = forwardRef<ChildRef, Props>(({ excsieDetailsMain, mainApiData, editData, isEditMode, iconStatus, setShowPage, sendDataApi, setIconStatus, onboardingViewfun, setExcsieDetailsMain }, ref) => {
  const initData: any = {
    centralExciseRegNumber: "",
    documentType: "",
    dutyType: "",
    dutyType_id: "",
    location: "",
    index: 0

  }
  const [formDataEdit, setFormDataEdit] = useState(false)
  const [currentExcise, setCurrentExcise] = useState<number>()
  const [formData, setFormData] = useState(initData);
  const [companyOverviewErrors, setCompanyOverviewErrors] = useState<Record<string, boolean>>({})
  const [exciseDocumentType, setExciseDocumentType] = useState<any[]>([]);
  const [exciseDutyType, setExciseDutyType] = useState<DropdownItem[]>([]);
  const [exciseLocation, setExciseLocation] = useState<DropdownItem[]>([]);
  const [noDuplicate, setNoDuplicate] = useState(false)
  const [showType, setShowType] = useState("warning") // error message showType
  const [showMessage, setShowMessage] = useState<string>("") // error message showMessage
  const [dutyTypeId, setDutyTypeId] = useState<any>("") // error message showMessage
  const [showAlertBox, setShowAlertBox] = useState(false) // error message ShowAlertBox
  const [isFormDirty, setIsFormDirty] = useState(false)
  const isFormModified = useSelector((dutyType: any) => dutyType.isFormModified);
  const [showChangesModal, setShowChangesModal] = useState<boolean>(false);
  const dispatch = useDispatch();
  const [currentDeletVal, setCurrentDeletVal] = useState<any>({});
  const [deleteIndex, setDeleteIndex] = useState<number>(0)
  const [showDeleteModel, setShowDeleteModel] = useState<boolean>(false)
  const [currIndex, setCurrIndex] = useState<number>()
  const userValidationRules: any = {
    centralExciseRegNumber: {
      regex: /^[A-Z0-9][A-Z0-9]*$/,
      // /^[A-Za-z][A-Za-z\s]*$/,
      field: "mandatory",
      shouldNotBe: ""
    },
    dutyType: {
      regex: "",
      field: "mandatory",
      shouldNotBe: ""
    },
    location: {
      regex: "",
      field: "mandatory",
      shouldNotBe: ""
    },
    documentType: {
      regex: "",
      field: "mandatory",
      shouldNotBe: ""
    },
  }

  const updatedIsValueValidAll: Record<string, boolean> = {};
  const validateForm = (): boolean => {
    const updatedFieldValidity: Record<string, boolean> = {};
    const isMandatoryfalse = true;
    const isRegexfalse = true;
    Object.keys(userValidationRules).forEach((field) => {
      const rule = userValidationRules[field];
      const value = formData && formData[field];
      const isValueValid = (rule.field === "mandatory") ? (value ? (value !== rule.shouldNotBe) : false) : true;
      const isRegexValid = rule.regex !== "" ? new RegExp(rule.regex).test(value) : true;
      updatedFieldValidity[field] = !isValueValid || !isRegexValid;
      updatedIsValueValidAll[field] = !isValueValid;
    });
    setCompanyOverviewErrors(updatedFieldValidity);
    const isFormValid = Object.keys(updatedFieldValidity).every((keys) => {
      return !updatedFieldValidity[keys]
    });
    return isFormValid;
  };


  const axiosPrivate = useAxiosPrivate();

  useEffect(() => {
    // fetchStatutoryState()
  }, [])


  // const fetchStatutoryState = async () => {
  //   try {
  //     const response = await axiosPrivate.get("dutyType");
  //     if (response.status === 200) {
  //       const dropdownValues = response.data.states.map((item: any) => ({
  //         label: item.name,
  //         value: item.name,
  //         id: item.stateId,
  //       }));
  //       const sortedData = [...dropdownValues].sort((a, b) => a.label.localeCompare(b.label));
  //       setExciseDocumentType(sortedData);
  //     }
  //   } catch (error) {
  //   }
  // };

  async function duplicateCheck() {
    // let finalData = {
    //   "centralExciseRegNumber": formData.centralExciseRegNumber,
    // }
    let statusMessage: boolean = false;
    try {
      const fieldsToCheck = ['centralExciseRegNumber'];
      const todisplay = ['Central Excise Reg Number'];
      const failedFields: any[] = [];
      let isDuplicate = false
      if (!formDataEdit) {
        isDuplicate = excsieDetailsMain.some((item: any) => {
          const matches = fieldsToCheck.map((field, i) => {
            if (formData[field] !== '') {
              if (item[field] === formData[field]) {
                failedFields.push(todisplay[i]);
              }
              return item[field] === formData[field];
            }
            return false;
          });
          return matches.some(match => match);
        });
      } else {
        isDuplicate = excsieDetailsMain.some((item: any, index: any) => {
          const matches = fieldsToCheck.map((field, i) => {
            if (formData[field] && formData[field] !== '' && currIndex != index) {
              if (item[field] === formData[field]) {
                failedFields.push(todisplay[i]);
              }
              return item[field] === formData[field];
            }
            return false;
          });
          return matches.some(match => match);
        });
      }
      if (!isDuplicate) {
        const res = currentExcise ? await axiosPrivate.patch(`companyadmin/duplicateCheck/excise/${currentExcise}`, { 'centralExciseRegNumber': formData.centralExciseRegNumber }) : await axiosPrivate.post("companyadmin/duplicateCheck/excise", { 'centralExciseRegNumber': formData.centralExciseRegNumber })
        if (res.status === 200) {
          statusMessage = true;
        }
      } else {
        statusMessage = false;
        setShowAlertBox(true)
        setShowType("danger")
        setShowMessage(`${failedFields[0]} already exists`)
        clearAlert("")
      }

    }
    catch (error: any) {
      statusMessage = false;
      setShowAlertBox(true)
      setShowType("danger")
      setShowMessage(error.response.data.error)
      clearAlert("")
    }
    return statusMessage;
  }
  const clearAlert = (status: any) => {
    const timer = setTimeout(() => {
      setShowAlertBox(false);
      setShowMessage("");
      if (status === 400) {

      }
      clearTimeout(timer);
    }, 5000);
  }


  async function handelAdd() {
    if (!validateForm()) {
      setShowAlertBox(true);
      setShowType("danger");
      setShowMessage(Object.values(updatedIsValueValidAll).every((values) => !values) ?
        "Some of the field(s) are not in required format." : "Mandatory field(s) should not be left blank.")
      clearAlert('');
      return;
    }
    let Duplicate = await duplicateCheck();
    if (!Duplicate) return;
    if (formDataEdit) {
      let data: any = []
      excsieDetailsMain.length > 0 && excsieDetailsMain.map((ele: any, i: number) => {
        if (i === formData.index) {
          data.push(formData)
        } else {
          data.push(ele)
        }
      })
      setExcsieDetailsMain(data)
      setFormDataEdit(false)
      setFormData(
        {
          centralExciseRegNumber: "",
          documentType: "",
          dutyType: "",
          location: "",
          dutyType_id:"",
          index: 0
        }
      )
      setIsFormDirty(false);
      setShowAlertBox(true);
      setShowType("success");
      setShowMessage("Excise Details updated successfully.")
      clearAlert('');
    }
    else {
      let newrecord = [...excsieDetailsMain]
      newrecord.push({ ...formData })
      setExcsieDetailsMain(newrecord)
      setFormData(
        {
          centralExciseRegNumber: "",
          documentType: "",
          dutyType: "",
          dutyType_id:"",
          location: "",
          index: 0
        }
      )
      setIsFormDirty(false);
      setShowAlertBox(true);
      setShowType("success");
      setShowMessage("Excise Details added successfully.")
      clearAlert('');
    }
    setIsFormDirty(false);
    setFormDataEdit(false);

  }

  function next(next: any = "") {
    if (isEditMode && excsieDetailsMain.length > 0) {
      sendDataApi();
    } else {
      if (excsieDetailsMain.length > 0) {
        setShowPage(next != "" ? next : "BankRelationshipManager")
        if (next != "") {
          switch (next) {
            case "CompanyOverview":
              setIconStatus({
                ...iconStatus,
                companyIcon: "selected",
              })
              break;
            case "Address":
              setIconStatus({
                ...iconStatus,
                companyIcon: "completed",
                addressIcon: "selected",
              })
              break;
            case "DirectorsInfo":
              setIconStatus({
                ...iconStatus,
                directorIcon: "selected",
                addressIcon: "completed"
              })
              break;
            case "StatutoryDetails":
              setIconStatus({
                ...iconStatus,
                statutoryIcon: "selected",
                directorIcon: "completed"
              })
              break;
            case "ExciseDetails":
              setIconStatus({
                ...iconStatus,
                ExciseDetailsIcon: "selected",
                statutoryIcon: "completed"
              })
              break;
            case "AdminDetails":
              setIconStatus({
                ...iconStatus,
                adminIcon: "selected",
                ExciseDetailsIcon: "completed"
              })
              break;

            default:
              break;
          }
        } else {
          setIconStatus({
            ...iconStatus,
            ExciseDetailsIcon: "completed",
            relationshipIcon: "selected"
          })
        }
      }
    }
  }


  useImperativeHandle(ref, () => {
    return {
      next: next
    }
  });

  function returnGird() {
    onboardingViewfun("grid")
    dispatch(resetFormModified(false));
  }

  function retriveData(value: any, index: number) {
    console.log(value)
    fetchData(`companyadmin/excise/document-type/${value.dutyType_id}`, setExciseDocumentType, 'documentTypes', "documentTypeId", "decryptedDocumentType");
    fetchData(`companyadmin/excise/locations/${value.dutyType_id}`, setExciseLocation, 'exciseLocations', "exciseLocationId", "exciseLocation");
    setCurrIndex(index)
    setFormDataEdit(true)
    setCurrentExcise(value.exciseId)
    setIsFormDirty(true);
    setCompanyOverviewErrors({})
    if (Object.values(value).length !== 3) {
      setFormData(
        {
          ...value,
          index: index
        }
      )
    }

  }

  const clearAleart = () => {
    const timer = setTimeout(() => {
      setShowAlertBox(false);
      setShowMessage("");
      clearTimeout(timer);
    }, 5000);
  }


  const ExciseInfoDelete = async () => {
    if (currentDeletVal.exciseId) {
      try {
        const response = await axiosPrivate.patch(`/companyadmin/company-approval/excise/${currentDeletVal.exciseId}`);
        if (response.status === 200) {
          setShowDeleteModel(false);
          const newArray = [...excsieDetailsMain];
          newArray.splice(deleteIndex, 1);
          setExcsieDetailsMain(newArray);
          setShowAlertBox(true)
          setShowType("success")
          setShowMessage(response.data.message)
          setFormDataEdit(false)
          setFormData(
            {
              centralExciseRegNumber: "",
              documentType: "",
              dutyType: "",
              dutyType_id:"",
              location: "",
              index: 0
            }
          )
          clearAleart()
        }
      } catch (error) {
      }
    } else if (currentDeletVal.index === 0) {
      const newArray = [...excsieDetailsMain];
      newArray.splice(deleteIndex, 1);
      setExcsieDetailsMain(newArray)
      setShowDeleteModel(false)
      setShowAlertBox(true)
      setShowType("success")
      setShowMessage("The selected record(s) has been deleted")
      setFormDataEdit(false)
      setFormData(
        {
          centralExciseRegNumber: "",
          documentType: "",
          dutyType: "",
          location: "",
          dutyType_id:"",
          index: 0
        }
      )
      clearAleart()
    }
  };

  useEffect(() => {
    fetchData("companyadmin/excise/get-duty-type", setExciseDutyType, "dutyType", "dutyTypeId", "name");
     if (isEditMode) {
        // fetchData(`state/cities/${editData.vendorOverView?.stateId}`, setDropDownRocCities, 'city', "cityId", "name");
    }
  }, [])
  const fetchData = async (url: string, setData: (data: any[]) => void, nestedProperty: string, id: any, representName: any) => {
    try {
      const response = await axiosPrivate.get(url);
      if (response.status === 200) {
        const dropdownValues = response.data[nestedProperty].map((item: any) => ({
          label: item[representName],
          value: item[representName],
          id: item[id],
        }));
        const sortedData = [...dropdownValues].sort((a, b) => a.label.localeCompare(b.label));
        setData([...sortedData]);

      }
    } catch (error) {
    }
  };
  return (
    <>
      <div className='form-wrapper'>
        <div className='form-area'>
          <div className='company-heading'>
            Excise Details
          </div>
          <div className='form-main-end'>
            <div>
              <InputComponent
                height={"40px"}
                width={"300px"}
                margin={"0px 45px 30px 0px"}
                padding={"0px 0px 0px 10px"}
                border={companyOverviewErrors && companyOverviewErrors.centralExciseRegNumber ? "1px solid red" : "1px solid #A9C3DC"}
                borderRadius={"0px"}
                backgroundColor={"white"}
                color={"black"}
                type={"text"}
                // disabled={mainApiData?.kycApprovalStatus === "Approved"}
                maxLength={15}
                placeHolder={"Enter Central Excise Registration No"}
                inputTitle={"Central Excise Registration No"}
                required={true}
                inputValue={formData && formData.centralExciseRegNumber}
                getUser={(val) => {
                  let value = val.toUpperCase()
                  setFormData({
                    ...formData,
                    centralExciseRegNumber: value,
                    // location: value.substring(0, 2)
                  })
                  setIconStatus({
                    ...iconStatus,
                    ExciseDetailsIcon: "selected"
                  })
                  setIsFormDirty(true)
                  dispatch(resetFormModified(true));
                }}
              />
            </div>
            <div style={{ marginRight: "45px", marginBottom: "30px" }} key={uuidv4()} className={companyOverviewErrors.dutyType ? 'setErrorcompany' : ""}>
              <DropdownComponent
                width={"300px"}
                title={"Duty Type"}
                required={true}
                defaultValue={[{ label: formData?.dutyType === (undefined || "") ? "Select" : formData.dutyType, value: formData && formData.dutyType }]}
                options={exciseDutyType}
                // options={[{label:"Aadhar", value:"Aadhar", id:1}]}
                getData={(value) => {
                  setFormData({
                    ...formData,
                    dutyType: value.value,
                    dutyType_id: value.id,
                    documentType: "",
                    location: ""
                  })
                  setDutyTypeId(value.id)
                  if (value.id == "") {
                    setExciseDocumentType([]);
                    setExciseLocation([]);
                  } else {
                    setExciseDocumentType([]);
                    setExciseLocation([]);
                    fetchData(`companyadmin/excise/document-type/${value.id}`, setExciseDocumentType, 'documentTypes', "documentTypeId", "decryptedDocumentType");
                    fetchData(`companyadmin/excise/locations/${value.id}`, setExciseLocation, 'exciseLocations', "exciseLocationId", "exciseLocation");
                  }
                  setIconStatus({
                    ...iconStatus,
                    ExciseDetailsIcon: "selected"
                  })
                  setIsFormDirty(true)
                  dispatch(resetFormModified(true));
                }}
              />
            </div>
            <div style={{ marginRight: "45px", marginBottom: "30px" }} key={uuidv4()} className={companyOverviewErrors.documentType ? 'setErrorcompany' : ""}>
              <DropdownComponent
                width={"300px"}
                required={true}
                // isDisabled={mainApiData?.kycApprovalStatus === "Approved"}
                title={"Document Type"}
                defaultValue={[{ label: formData?.documentType === (undefined || "") ? "Select" : formData.documentType, value: formData?.documentType === undefined ? "Select" : formData.documentType }]}
                options={exciseDocumentType}
                getData={(value) => {
                  setFormData({
                    ...formData,
                    documentType: value.value,
                    documentTypeId: value.id
                  })
                  setIconStatus({
                    ...iconStatus,
                    ExciseDetailsIcon: "selected"
                  })
                  setIsFormDirty(true)
                  dispatch(resetFormModified(true));
                }}
              />
            </div>

            {/* <div>
              <InputComponent
                height={"40px"}
                width={"300px"}
                margin={"0px 45px 30px 0px"}
                padding={"0px 0px 0px 10px"}
                border={companyOverviewErrors && companyOverviewErrors.CompanyTan ? "1px solid red" : "1px solid #A9C3DC"}
                borderRadius={"0px"}
                backgroundColor={"white"}
                color={"black"}
                type={"text"}
                maxLength={10}
                inputValue={formData && formData?.CompanyTan}
                placeHolder={"Enter Company TAN"}
                inputTitle={"Company TAN"}
                getUser={(val) => {
                  let value = val.toUpperCase()
                  setFormData({
                    ...formData,
                    CompanyTan: value
                  })
                  setIconStatus({
                    ...iconStatus,
                    statutoryIcon: "selected"
                  })
                  setIsFormDirty(true)
                  dispatch(resetFormModified(true));
                }}
              />
            </div> */}
          </div>
          <div className='form-main-end'>
            <div style={{ marginRight: "45px", marginBottom: "30px" }} key={uuidv4()} className={companyOverviewErrors.location ? 'setErrorcompany' : ""}>
              <DropdownComponent
                width={"300px"}
                title={"Location"}
                // isDisabled={mainApiData?.kycApprovalStatus === "Approved"}

                required={true}
                defaultValue={[{ label: formData?.location === (undefined || "") ? "Select" : formData.location, value: formData && formData.location }]}
                options={exciseLocation}
                getData={(value) => {
                  setFormData({
                    ...formData,
                    location: value.value
                  })
                  setIconStatus({
                    ...iconStatus,
                    ExciseDetailsIcon: "selected"
                  })
                  setIsFormDirty(true)
                  dispatch(resetFormModified(true));
                }}
              />
            </div>
          </div>

          <div>
            <ButtonComponent
              title={formDataEdit ? "Update" : 'Add'}
              height={"50px"}
              width={"150px"}
              backgroundColor={"#0055D4"}
              color={"white"}
              // disabled={mainApiData?.kycApprovalStatus === "Approved"}
              margin={"0px 8px"}
              className={"button-component-hover common-btn"}
              handleClick={() => {
                handelAdd()
              }}
            />
          </div>
          <div className={"Company-DetailsGrid"}>
            <ExciseDetailsGrid
              mainApiData={mainApiData}
              statutoryDetails={excsieDetailsMain}
              retriveData={retriveData}
              showmodal={(val: any, index: any) => {
                setShowDeleteModel(true);
                setDeleteIndex(index);
                setCurrentDeletVal(val);
              }}
            />
          </div>
          <div className='footer-company'>
            <div>
              {/* <ButtonComponent
                title={"Cancel"}
                height={"50px"}
                width={"150px"}
                margin={"0px 8px"}
                backgroundColor={"#888888"}
                color={"white"}
                className={"button-component-hover cancel"}
                handleClick={isFormModified ? () => { setShowChangesModal(true) } : returnGird}
              /> */}


              <ButtonComponent
                title={isEditMode ? "Update" : "Next"}
                height={"50px"}
                width={"150px"}
                backgroundColor={"#0055D4"}
                color={"white"}
                margin={"0px 8px"}
                handleClick={next}
                disabled={excsieDetailsMain?.length == 0 || isFormDirty}
                className={excsieDetailsMain?.length == 0 || isFormDirty ? "button-component disabled" : "button-component-hover common-btn"}
              />
            </div>
          </div>
        </div>
      </div>
      {
        showAlertBox &&
        <div className='alert-warp'>
          <AlertBox type={showType} message={showMessage} />
        </div>

      }
      {
        showChangesModal &&
        <Changesmodal
          closeModal={() => setShowChangesModal(false)}
          handleClose={() => {
            setShowChangesModal(false);
          }}
          leavePage={() => returnGird()} />
      }
      {
        showDeleteModel &&
        <div >
          <DeleteModal
            getDelete={() => { ExciseInfoDelete() }}

            clearValue={(value) => {
              setShowDeleteModel(value);
              setCurrentDeletVal({})
            }}
            modelType={"grid-delete"}
          />
        </div>
      }
    </>
  )
})
export default ExciseDetails

