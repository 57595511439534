import React, { useEffect, useState, forwardRef, useImperativeHandle, useCallback } from 'react'
import InputComponent from '../../../common-component/form-elements/input-component'
import DropdownComponent from '../../../common-component/form-elements/dropdown-component'
import ButtonComponent from '../../../common-component/form-elements/button-component'
import { v4 as uuidv4 } from 'uuid'
import useAxiosPrivate from "../../../../services/hooks/useaxios-private";
import AlertBox from '../../../common-component/alert-box'
import { useDispatch, useSelector } from "react-redux";
import { resetFormModified } from "../../../../redux/action";
import Changesmodal from '../../../common-component/modals/changes-modal';
import RadioOrCheckbox from '../../../common-component/form-elements/radio-or-checkbox'
import useAuth from "../../../../services/hooks/useauth";
import OtpVerifyModal from '../../../common-component/modals/otp-verify-modal'
import Loading from '../../../common-component/modals/loading-screen'
import IconButton from '../../../common-component/form-elements/icon-button'
import VendorApprovalModal from '../../../common-component/modals/vendor-approval-modal'
import VendorBankApprovalTable from '../../../../config/organisation/vendor/vendor-bank-approval-aggrid-config'


export interface ChildRef {
    next: (val: any) => void;
}
interface DropdownItem {
    label: string;
    value: string;
}
interface props {
    mainApiData: any
    isMatchingDirectorsFound?: any
    vendorDirectorsInfo?: any
    companyDirectorsInfo?: any
    setMainApiData: (val: any) => void;
    setVendorDirectorsInfo: (val: any) => void;
    setCompanyDirectorsInfo: (val: any) => void;
    onboardingViewfun: (val: any) => void;
    isEditMode: boolean;
    isDisabled: boolean;
    setStepperStatus: any;
    stepperStatus: any;
    iconStatus: any;
    editData: any;
    // getVendorBankApproval: any;
    vendorDetails: any;
    setVendorDetails: any;
    setShowPage: (val: any) => void;
    sendDataApi: () => void;
    setIconStatus: (val: any) => void;
    saveVendorDatas: () => void;
}
const VendorApprovalMainPage = forwardRef<ChildRef, props>(({ mainApiData, isEditMode, onboardingViewfun, setMainApiData, sendDataApi, iconStatus, editData, setShowPage, isMatchingDirectorsFound, setIconStatus, setStepperStatus, stepperStatus, setCompanyDirectorsInfo, companyDirectorsInfo, vendorDirectorsInfo, setVendorDetails, vendorDetails }, ref) => {

    const [approvalErrors, setApprovalErrors] = useState<Record<string, boolean>>({})
    const [addressState, setAddressState] = useState<any[]>([]);
    const [addressCities, setAddressCities] = useState<any[]>([]);
    const [isFormDirty, setIsFormDirty] = useState(false)
    const [showType, setShowType] = useState("warning") // error message showType
    const [showMessage, setShowMessage] = useState<string>("") // error message showMessage
    const [showAlertBox, setShowAlertBox] = useState(false) // error message ShowAlertBox
    const [showDirectorDin, setShowDirectorDin] = useState(false) // error message ShowAlertBox
    const isFormModified = useSelector((state: any) => state.isFormModified);
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const dispatch = useDispatch();
    const [showChangesModal, setShowChangesModal] = useState<boolean>(false);
    const [isDisabled, setIsDisabled] = useState<boolean>(true);
    const [isLocked, setIsLocked] = useState<boolean>(false);
    const [otpVerification, setOtpVerification] = useState<boolean>(false);
    const [dropDownState, setDropDownState] = useState<DropdownItem[]>([])
    const [dropDownCities, setDropDownCities] = useState<DropdownItem[]>([])
    const [dropDownRocCities, setDropDownRocCities] = useState<DropdownItem[]>([])
    const [processName, setProcessName] = useState<string>("Yes");
    const [getVendorBankApproval, setGetVendorBankApproval] = useState<any[]>([]);
    const [venderAppprovalInputs, setVenderAppprovalInputs] = useState<any>(
        {
            venderApprovalremarks: "",
            venderApprovalRequest: "",
        }
    );

    const [saveBtnEnabledStatus, setSaveBtnEnabledStatus] = useState<any>(true);
    const [sendBankRequest, setSendBankRequest] = useState<any>({
        bank: '',
        cardId: ''
    });
    const [bankDropDown, setDankDropDown] = useState<any>([])

    const { auth } = useAuth();
    const axiosPrivate = useAxiosPrivate();

    const userValidationRules: any = {
        approvalRemarks: {
            regex: vendorDetails.basicDetails.companyInternalApproval === "Declined" ? /^[^\s].*/ : "",
            field: (vendorDetails.basicDetails.companyInternalApproval === "Declined") ? "mandatory" : "",
            shouldNotBe: "",
            title: "basicDetails"
        },
    }
    const updatedIsValueValidAll: Record<string, boolean> = {};
    const validateForm = (): boolean => {
        const updatedFieldValidity: Record<string, boolean> = {};
        const isMandatoryfalse = true;
        const isRegexfalse = true;
        Object.keys(userValidationRules).forEach((field) => {
            const rule = userValidationRules[field];
            let value = vendorDetails?.basicDetails[field]
            // if (userValidationRules[field].title == "overview") {
            //     value = vendorDetails?.vendorOverView[field];
            // } else {
            //     value = vendorDetails?.basicDetails[field];
            // }
            const isValueValid = (rule.field === "mandatory") ? (value ? (value !== rule.shouldNotBe) : false) : true;
            const isRegexValid = rule.regex !== "" ? new RegExp(rule.regex).test(value) : true;
            updatedFieldValidity[field] = !isValueValid || !isRegexValid;
            updatedIsValueValidAll[field] = !isValueValid;
        });
        setApprovalErrors(updatedFieldValidity);
        const isFormValid = Object.keys(updatedFieldValidity).every((keys) => {
            return !updatedFieldValidity[keys]
        });
        return isFormValid;
    };

    function returnGird() {
        onboardingViewfun("grid")
        dispatch(resetFormModified(false));
    }

    async function CheckValue() {
        try {
            const res = await axiosPrivate.post("/companyadminvendor/approval/remarks", venderAppprovalInputs)
        } catch (error) {
            console.log("Post Not Send", error)
        }

    }

    const clearAlert = (status: any) => {
        const timer = setTimeout(() => {
            setShowAlertBox(false);
            setShowMessage("");
            if (status === 400) {

            }
            clearTimeout(timer);
        }, 5000);
    }
    useEffect(() => {
        getVendorBankApprovalBankDetails()
    }, [])

    async function createVendorBankApprovalDetails() {
        try {
            const response = await axiosPrivate.post(`razorpay/vendor-bank-approval-datas/create/${vendorDetails.primaryKeyOfCurrentDatas}`, {
                cardId: sendBankRequest.cardId
            });
            getrBankApprovalBankDetails()
            // setGetVendorBankApproval(response.data.results)
        } catch (error) {
        }
    }

    async function getVendorBankApprovalBankDetails() {
        try {
            const response = await axiosPrivate.get(`razorpay/vendor-approval/get/bankData/${vendorDetails.primaryKeyOfCurrentDatas}`);
            const bankDatasDD = response?.data?.bankDataOverAll.map((item: any) => ({
                label: item.values,
                value: item.values,
                id: item.id,
                cardId: item.cardId
            }));
            setDankDropDown(bankDatasDD)
        } catch (error) {
        }
    }

    const next = async (next: any = "") => {
        const isFormValid = validateForm();
        if (isFormValid) {

            if (isEditMode) {
                sendDataApi();
                setIsDisabled(true)
            } else {
                sendDataApi();
                setShowPage("Approval")
                setIconStatus({
                    ...iconStatus,
                    ApprovalIcon: "completed",
                })
            }
            setIsFormDirty(false);
        } else {
            setShowAlertBox(true);
            setShowType("danger");
            setShowMessage(Object.values(updatedIsValueValidAll).every((values) => !values) ?
                "Some of the field(s) are not in required format." : "Mandatory field(s) should not be left blank.")
            clearAlert('');
        }
    }

    useImperativeHandle(ref, () => {
        return {
            next: next
        }
    });
    async function resendOtp() {
        let res;
        try {

            res = await axiosPrivate.post('companyadmin/bank/resend-otp-update/approval', {
                approvedBy: auth.token, pageName: "vendorApproval",
                processName: processName, currentDataId: parseInt(editData?.vendorOverView?.vendorOverviewId)

            });

            if (res.status === 200) {
                setShowAlertBox(true)
                setShowType("success")
                setShowMessage(res.data.message)
                clearAlert("")
            }
        } catch (error: any) {
            setShowAlertBox(true);
            setShowType("danger")
            setShowMessage(error.response.data.error)
            clearAlert("")
        }
    }
    useEffect(() => {
        getrBankApprovalBankDetails()
    }, [])
    async function getrBankApprovalBankDetails() {
        try {
            const response = await axiosPrivate.get(`razorpay/vendor-approval/get/bank/approval/deatils/${vendorDetails.primaryKeyOfCurrentDatas}`);
            console.log(response.data)
            setGetVendorBankApproval(response.data.bank)
            // setDankDropDown(bankDatasDD)
        } catch (error) {
            console.log(error)
        }
    }

    async function saveVendorDatas() {
        try {
            const formData = new FormData();
            setIsLoading(true)
            const response = await axiosPrivate.post(`vendor/razorpay/update/vendor-approval/${vendorDetails.primaryKeyOfCurrentDatas}`, {
                vendorDetails: vendorDetails,
            });
            formData.append(`vendorDetails`, JSON.stringify(vendorDetails))

            if (response.status == 200) {
                // if (vendorDetails?.basicDetails?.companyInternalApproval == "Approved") {
                //     await axiosPrivate.post('razorpay/vendor-details/mid-creation', formData)
                // }
                setIsLoading(false)
                setShowAlertBox(true);
                setShowType("success");
                setShowMessage(`Record ${vendorDetails?.basicDetails?.companyInternalApproval} successfully.`)
                clearAlert('');
                setTimeout(() => {
                    returnGird()
                }, 4000)
            }
        } catch (error: any) {
            setIsLoading(false)
            setShowAlertBox(true);
            setShowType("danger");
            setShowMessage(error?.response?.data?.error);
            clearAlert('');
        }
    }
    return (
        <>
            <div className='form-wrapper'>
                <div className='form-area-address'>
                    <div>
                        <div className='company-heading'>
                            Vendor Status
                        </div>
                        <div>
                            <div className='form-input' style={{ opacity: "0.5" }}>
                                <div style={{ width: "auto" }}>

                                </div>
                                <div className='radio-input vendor-form-radio-input'>
                                    <p>Is Active<span>*</span></p>
                                    <div className='radio-btn-main'>
                                        <RadioOrCheckbox
                                            value={"Yes"}
                                            name={"ActiveCard"}
                                            type={"radio"}
                                            margin={"0px 30px 0px 0px"}
                                            checkedValue={vendorDetails?.vendorOverView?.isActive}
                                            getVal={(value) => {
                                                return;
                                                setIsFormDirty(true)
                                                setIsDisabled(false)
                                                setVendorDetails({
                                                    ...vendorDetails,
                                                    vendorOverView: {
                                                        ...vendorDetails.vendorOverView,
                                                        isActive: value,
                                                        remarks: ""
                                                    }
                                                })
                                                setIconStatus({
                                                    ...iconStatus,
                                                    ApprovalIcon: "selected",
                                                })
                                                setProcessName("activation")
                                                dispatch(resetFormModified(true));
                                                // setVenderAppprovalInputs({
                                                //     ...venderAppprovalInputs,
                                                //     venderApprovalremarks: value
                                                // })
                                                // if (venderAppprovalInputs.venderApprovalremarks.length) {
                                                //     setStepperStatus({ ...stepperStatus, basicDetails: "working" })
                                                // }
                                            }}
                                        />
                                        <div>
                                            <RadioOrCheckbox
                                                value={"No"}
                                                type={"radio"}
                                                name={"Activecard"}
                                                checkedValue={vendorDetails?.vendorOverView?.isActive}
                                                getVal={(val) => {
                                                    return;
                                                    setIsFormDirty(true)
                                                    setIsDisabled(false)
                                                    setVendorDetails({
                                                        ...vendorDetails,
                                                        vendorOverView: {
                                                            ...vendorDetails.vendorOverView,
                                                            isActive: val,
                                                        }
                                                    })
                                                    setIconStatus({
                                                        ...iconStatus,
                                                        ApprovalIcon: "selected",
                                                    })
                                                    setProcessName("deactivation")
                                                    dispatch(resetFormModified(true));
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>

                            </div>
                            {/* {mainApiData?.isActive !== "Yes" && */}
                            <div style={{ pointerEvents: "none", opacity: 0.5 }}>
                                <InputComponent
                                    height={"40px"}
                                    width={"520px"}
                                    margin={"0px 60px 30px 0px"}
                                    padding={"0px 0px 0px 10px"}
                                    border={approvalErrors?.remarks ? "1px solid red" : "1px solid #A9C3DC"}
                                    borderRadius={"0px"}
                                    backgroundColor={"white"}
                                    color={"black"}
                                    maxLength={250}
                                    type={"text"}
                                    disabled={vendorDetails.vendorOverView?.isActive === "Yes" ? true : false}
                                    inputTitle={"Remarks"}
                                    required={true}
                                    placeHolder={"Reason"}
                                    inputValue={vendorDetails?.vendorOverView?.remarks}
                                    getUser={(value: any) => {
                                        setIsFormDirty(true)
                                        setVendorDetails({
                                            ...vendorDetails,
                                            vendorOverView: {
                                                ...vendorDetails.vendorOverView,
                                                remarks: value,
                                            }
                                        })
                                        // if (mainApiData.remarks.length) {
                                        //     setStepperStatus({ ...stepperStatus, Approval: "working" })

                                        // }

                                        // dispatch(resetFormModified(true));
                                        setVenderAppprovalInputs({
                                            ...venderAppprovalInputs,
                                            venderApprovalremarks: value
                                        })
                                        if (venderAppprovalInputs.venderApprovalremarks.length) {
                                            setStepperStatus({ ...stepperStatus, Approval: "working" })
                                        }
                                    }}
                                />
                            </div>
                            {/* } */}
                        </div>
                        <div>
                            <div className='director-din-main'>
                                <div>
                                    <div>
                                        <div className='company-heading'>
                                            Approval
                                        </div>
                                        <div className='approval-request'>{"This new request required your approval."}</div>
                                        <div className='form-input'>
                                            <div style={{ width: "auto" }}>
                                            </div>
                                            <div className='radio-input vendor-form-radio-input'>

                                                <div className='radio-btn-main-vendor'>
                                                    <RadioOrCheckbox
                                                        value={"Approved"}
                                                        name={"Approve"}
                                                        label={"Approve"}
                                                        type={"radio"}
                                                        margin={"0px 30px 0px 0px"}
                                                        checkedValue={vendorDetails.basicDetails.companyInternalApproval}
                                                        getVal={(value) => {
                                                            setSaveBtnEnabledStatus(false)
                                                            setIsFormDirty(true)
                                                            setIsDisabled(false)
                                                            setVendorDetails({
                                                                ...vendorDetails,
                                                                basicDetails: {
                                                                    ...vendorDetails.basicDetails,
                                                                    companyInternalApproval: value,
                                                                    approvalRemarks: ""
                                                                }
                                                            })
                                                            setIconStatus({
                                                                ...iconStatus,
                                                                ApprovalIcon: "selected",
                                                            })
                                                            setApprovalErrors({
                                                                ...approvalErrors,
                                                                approvalRemarks: false,
                                                            })
                                                            setProcessName("approve")
                                                            dispatch(resetFormModified(true));
                                                        }}
                                                    />
                                                    <div>
                                                        <RadioOrCheckbox
                                                            value={"Declined"}
                                                            type={"radio"}
                                                            name={"Decline"}
                                                            label={"Decline"}
                                                            checkedValue={vendorDetails.basicDetails.companyInternalApproval}
                                                            getVal={(val) => {
                                                                setSaveBtnEnabledStatus(false)
                                                                setIsFormDirty(true)
                                                                setIsDisabled(false)
                                                                setVendorDetails({
                                                                    ...vendorDetails,
                                                                    basicDetails: {
                                                                        ...vendorDetails.basicDetails,
                                                                        companyInternalApproval: val,
                                                                    }
                                                                })
                                                                setIconStatus({
                                                                    ...iconStatus,
                                                                    ApprovalIcon: "selected",
                                                                })
                                                                dispatch(resetFormModified(true));
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className='director-din-sub'>
                                        <div className='director-din-header'>{"Director's DIN Verification"}</div>
                                        <div className='director-din-sub-content'>
                                            <div>{"Vendor related to the corporate:"}</div>
                                            <div className='sub-content-status'>{isMatchingDirectorsFound === true ?
                                                "Yes" : "No"}</div>
                                        </div>
                                        <div className='director-din-footer'>
                                            <div
                                                style={isMatchingDirectorsFound === false ? { pointerEvents: "none", opacity: "0.5" } : {}}
                                            >
                                                <IconButton
                                                    iconName={showDirectorDin === false ? "ShowEye" : "Eye"}
                                                    height={"40px"}
                                                    width={"40px"}
                                                    fontSize={""}
                                                    className={"icons"}
                                                    cursor={isMatchingDirectorsFound === false ? "not-allowed" : "pointer"}
                                                    color={"white"}
                                                    backgroundColor={"#004E9C"}
                                                    hover={false}
                                                    handleClick={() => {
                                                        if (isMatchingDirectorsFound === true) {
                                                            setShowDirectorDin(true)
                                                        } else {
                                                            setShowDirectorDin(false)
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={
                            vendorDetails.basicDetails.companyInternalApproval === "Declined" ?
                                { pointerEvents: "auto", opacity: 1 } : { pointerEvents: "none", opacity: 0.5 }
                        }>
                            <InputComponent
                                height={"50px"}
                                width={"750px"}
                                margin={"0px 60px 30px 0px"}
                                padding={"0px 0px 0px 10px"}
                                border={approvalErrors.approvalRemarks ? "1px solid red" : "1px solid #A9C3DC"}
                                borderRadius={"0px"}
                                backgroundColor={"white"}
                                color={"black"}
                                maxLength={250}
                                type={"text"}
                                disabled={vendorDetails.basicDetails.companyInternalApproval === "Declined" ? false : true}
                                inputTitle={"Reason for Declining Request"}
                                required={true}
                                placeHolder={"Reason"}
                                inputValue={vendorDetails.basicDetails.approvalRemarks}
                                getUser={(value: any) => {
                                    setIsFormDirty(true)
                                    setVendorDetails({
                                        ...vendorDetails,
                                        basicDetails: {
                                            ...vendorDetails.basicDetails,
                                            approvalRemarks: value,
                                        }
                                    })
                                    // setIconStatus({
                                    //     ...iconStatus,
                                    //     ApprovalIcon: "selected",
                                    // })
                                    // dispatch(resetFormModified(true));

                                    setVenderAppprovalInputs({
                                        ...venderAppprovalInputs,
                                        approvalRemarks: value
                                    })
                                    if (venderAppprovalInputs.venderApprovalRequest.length) {
                                        setStepperStatus({ ...stepperStatus, Approval: "working" })
                                    }
                                }}
                            />
                        </div>
                        {/* <div className='vendor-bank-approval-check'>
                            <div className='company-heading'>
                                {"Vendor Bank Approval"}
                            </div>

                            <div style={{ display: "flex" }}>

                                <div key={uuidv4()}
                                // className={addressErrors.city ? 'setErrorcompany' : "dropdown-width"}
                                >
                                    <DropdownComponent
                                        width={"300px"}
                                        title={"Bank"}
                                        required={false}
                                        options={bankDropDown}
                                        isDisabled={false}
                                        // mainApiData?.vendorOverView?.city === undefined ? "Select" : mainApiData?.vendorOverView?.CompanySubCategory
                                        defaultValue={[
                                            {
                                                label: sendBankRequest.bank === "" ? "Select" : sendBankRequest.bank,
                                                value: sendBankRequest.bank === "" ? "" : sendBankRequest.bank
                                            }
                                        ]}
                                        getData={(value: any) => {
                                            setIsFormDirty(true)
                                            dispatch(resetFormModified(true));
                                            setSendBankRequest((prevState: any) => ({
                                                ...prevState,
                                                bank: value.value,
                                                cardId: value.cardId
                                            }));
                                        }}
                                    />
                                </div>
                                <div>
                                    <ButtonComponent
                                        title={"Send Request"}
                                        height={"45px"}
                                        width={"150px"}
                                        margin={"30px 20px 0px"}
                                        backgroundColor={"#0055D4"}
                                        color={"white"}
                                        className={bankDropDown?.length > 0 ? "button-component-hover common-btn" : "button-component-hover disabled"}
                                        handleClick={() => { createVendorBankApprovalDetails() }}
                                    />
                                </div>
                            </div>
                        </div> */}
                        {/* <VendorBankApprovalTable
                            getVendorBankApproval={getVendorBankApproval}
                            retriveData={() => { }}
                            getrBankApprovalBankDetailsFun={() => {
                                getrBankApprovalBankDetails()
                            }}
                            showmodal={(val: any, index: any) => {

                            }}
                        /> */}
                    </div>
                    <div>
                        <div className='footer-company'>
                            <ButtonComponent
                                title={"Cancel"}
                                height={"50px"}
                                width={"150px"}
                                margin={"0px 8px"}
                                backgroundColor={"#888888"}
                                color={"white"}
                                className={"button-component-hover cancel"}
                                handleClick={isFormModified ? () => { setShowChangesModal(true) } : returnGird}
                            />

                            <ButtonComponent
                                title={"Save"}
                                height={"50px"}
                                width={"150px"}
                                backgroundColor={"#0055D4"}
                                color={"white"}
                                margin={"0px 8px"}
                                disabled={saveBtnEnabledStatus}
                                className={
                                    saveBtnEnabledStatus ?
                                        "button-component-hover disabled" : "button-component-hover common-btn"}
                                handleClick={() => {
                                    if (validateForm()) {
                                        saveVendorDatas()
                                    } else {
                                        setShowAlertBox(true);
                                        setShowType("danger");
                                        setShowMessage(Object.values(updatedIsValueValidAll).every((values) => !values) ?
                                            "Some of the field(s) are not in required format." : "Mandatory field(s) should not be left blank.")
                                        clearAlert('');
                                        return;
                                    }
                                    // if (vendorDetails?.basicDetails?.companyInternalApproval == "Declined" && vendorDetails?.basicDetails?.approvalRemarks !== "") {
                                    //     setShowAlertBox(true);
                                    //     setShowType("danger");
                                    //     setShowMessage("Mandatory field(s) should not be left blank.")
                                    //     clearAlert('');
                                    // } else {
                                    //     // saveVendorDatas()
                                    // }

                                    // const isValid = validateForm();
                                    // setShowMessage(Object.values(updatedIsValueValidAll).every((values) => !values) ? "Some of the field(s) are not in required format." : "Mandatory field(s) should not be left blank.")
                                    // if (isValid) {


                                    //     if ((mainApiData?.status === "Approved") || (editData.vendorOverView?.isActive !== mainApiData.isActive) && (isFormDirty === true)) {
                                    //         setIsLoading(true)
                                    //         try {

                                    //             let res = await axiosPrivate.post('companyadmin/bank/otp-update/approval', {
                                    //                 approvedBy: auth.token, pageName: "vendorApproval",
                                    //                 processName: processName, currentDataId: parseInt(editData?.vendorOverView?.vendorOverviewId)
                                    //             });
                                    //             if (res.status === 200) {
                                    //                 setIsLoading(false)
                                    //                 setOtpVerification(true)
                                    //                 setShowAlertBox(true)
                                    //                 setShowType("success")
                                    //                 setIsDisabled(true)
                                    //                 setShowMessage(res.data.message)
                                    //                 clearAlert("")
                                    //             }
                                    //         } catch (error: any) {
                                    //             setIsLoading(false)
                                    //             setShowAlertBox(true);
                                    //             setShowType("danger")
                                    //             setShowMessage(error.response.data.error)
                                    //             clearAlert("")
                                    //         }
                                    //     } else {
                                    //         next()
                                    //     }

                                    // } else {
                                    //     next()
                                    // }
                                    if (venderAppprovalInputs.venderApprovalremarks.length || venderAppprovalInputs.venderApprovalRequest.length) {
                                        setStepperStatus({ ...stepperStatus, Approval: "completed" })
                                        CheckValue()
                                    }

                                    setVenderAppprovalInputs({
                                        ...venderAppprovalInputs,
                                        venderApprovalremarks: "",
                                        venderApprovalRequest: "",
                                    })
                                }
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
            {
                isLoading &&
                <Loading />
            }
            {
                showAlertBox &&
                <div className='alert-warp'>
                    <AlertBox type={showType} message={showMessage} />
                </div>
            }

            {
                showDirectorDin &&
                <div className='alert-warp'>
                    <VendorApprovalModal

                        companyDirectorsInfo={companyDirectorsInfo}
                        vendorDirectorsInfo={vendorDirectorsInfo}
                        clearValue={(value) => {
                            setShowDirectorDin(false)
                        }}

                    // adminDataDelete={adminDataDelete}
                    />
                </div>
            }
            {
                showChangesModal &&
                <Changesmodal
                    closeModal={() => setShowChangesModal(false)}
                    handleClose={() => {
                        setShowChangesModal(false);
                    }}
                    leavePage={() => returnGird()} />
            }
            {
                otpVerification &&
                <div className='otp-model'>
                    <OtpVerifyModal
                        otpVerify={resendOtp}
                        leavePage={() => {
                            setOtpVerification(false)
                            onboardingViewfun("form")
                        }
                        }
                        isLocked={isLocked}
                        setIsLocked={setIsLocked}
                        getStatus={async (stringFromArray) => {
                            let res;
                            try {

                                res = await axiosPrivate.post('companyadmin/bank/verify-otp/update', {
                                    otp: stringFromArray,
                                    approvedBy: auth.token,

                                });
                                if (res.status === 200) {
                                    setIsLocked(true)
                                    next()
                                }
                            } catch (error: any) {
                                setShowAlertBox(true);
                                setShowType("danger")
                                setShowMessage(error.response.data.error)
                                clearAlert("")
                                setIsLocked(false)

                            }
                            // organisationBankDataSave()
                            // userNavigateFunction("grid")
                        }
                        }
                    />
                </div>
            }
        </>
    )
})
export default VendorApprovalMainPage
