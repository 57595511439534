import React from 'react'
import CorporateCard from '../../../assets/images/CorporateCard.png'
import VirtualCard from '../../../assets/images/VirtualCard.png'
import NetBanking from '../../../assets/images/NetBanking.png'
import UPI from '../../../assets/images/UPIPayments.png'
import '../../../styles/common-component/payment-method.scss'

interface PaymentMethodPropes {
    value: string
    handleSelect: (value: string) => void
}


export default function PaymentMethod({ value, handleSelect }: PaymentMethodPropes) {

    const PaymentMethodIcons = [
        { name: 'Corporate Card', value: "Corporate Card", icon: "CorporateCard", disabled: false },
        { name: 'Net Banking', value: "Net Banking", icon: "NetBanking", disabled: false },
        { name: 'UPI Payments', value: "UPI", icon: "UPI", disabled: false },
        { name: 'Virtual Card', value: "Virtual Card", icon: "VirtualCard", disabled: true },
    ];

    return (
        <div className='wrapper-paymentMethod'>
            {
                PaymentMethodIcons.map((iconData, index) => (
                    <div style={iconData.disabled ? { pointerEvents: "none", opacity: 0.6 } : {}}>
                        <div style={PaymentMethodIcons.length !== (index + 1) ? { borderRight: "2px solid #A9C3DC" } : {}} className={value === iconData.value ? 'paymentMethod-active' : "paymentMethod-icon-main"} key={index} onClick={() => {
                            !iconData.disabled && handleSelect(iconData.value)
                        }}>
                            <div className={`paymentMethod-icon ${iconData.icon}`} >
                                {/* <img className='paymentMethod-images' src={iconData.icon} alt="" /> */}
                            </div>
                            <div className='paymentMethod-icon-text'>
                                <p>{iconData.name}</p>
                            </div>
                            <div className='selection-bar-wrapper'>{value === iconData.value && <div className='selection-bar'></div>}</div>
                        </div>
                    </div>
                ))}
        </div>
    )
}
