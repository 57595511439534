import "../../styles/view-table.scss";
import "../../styles/onboarding/company/grid-icon.scss"


interface Props {
    mainChallanDetails: any;
}

function GstMainEyeIconGrid({ mainChallanDetails }: Props) {
    const formatAmountWithCommas = (amount: any) => {
        return parseFloat(amount).toLocaleString('en-US', {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
    };
    return (
        <>
            <div className='view-table-component' style={{ marginTop: "40px", marginBottom: "40px" }}>
                <table className='company-seperate-grid'>
                    <thead>
                        <th className='cus-width' style={{ paddingLeft: "20px", minWidth: "200px", textAlign: "left" }}>Tax Liability</th>
                        <th className='cus-width' style={{ minWidth: "125px", textAlign: "right" }}>{"Tax (₹)"}</th>
                        <th className='cus-width' style={{ minWidth: "125px", textAlign: "right" }}>{"Interest (₹)"}</th>
                        <th className='cus-width' style={{ minWidth: "140px", textAlign: "right" }}>{"Penality (₹)"}</th>
                        <th className='cus-width' style={{ minWidth: "140px", textAlign: "right" }}>{"Fees (₹)"}</th>
                        <th className='' style={{ minWidth: "140px", textAlign: "right" }}>{"Other (₹)"}</th>
                        <th className='' style={{ minWidth: "180px", textAlign: "right", paddingRight: "10px" }}>{"Total (₹)"}</th>
                    </thead>
                    <tbody >
                        {mainChallanDetails && mainChallanDetails?.length === 0 ?
                            <tr className='style-grid-differ'>
                                <td></td>
                                <td></td>
                                <td>No records to show</td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            :

                            mainChallanDetails && mainChallanDetails?.map((data: any, index: number) => (
                                <tr className='main-body-header' style={{ height: "50px" }}>
                                    <td className='left-icons' style={{ paddingLeft: "20px", minWidth: "200px", paddingRight: "20px", textAlign: "left" }}>{data.TaxLiability}</td>
                                    <td className='left-icons' style={{ minWidth: "125px", textAlign: "right" }}>{formatAmountWithCommas(data.Tax)}</td>
                                    <td className='left-icons' style={{ minWidth: "125px", textAlign: "right" }}>{formatAmountWithCommas(data.Interest)}</td>
                                    <td className='left-icons' style={{ minWidth: "140px", textAlign: "right" }}>{formatAmountWithCommas(data.Penalty)}</td>
                                    <td className='left-icons' style={{ width: "140px", textAlign: "right" }}>{formatAmountWithCommas(data.Fees)}</td>
                                    <td className='left-icons' style={{ width: "140px", textAlign: "right" }}>{formatAmountWithCommas(data.Other)}</td>
                                    <td className='left-icons' style={{ width: "180px", textAlign: "right", paddingRight: "10px" }}>{formatAmountWithCommas(data.Total)}</td>
                                </tr>
                            ))}

                    </tbody>
                </table>
                <table>

                    <tbody>
                        <tr className='main-body-header' style={{ height: "50px" }}>
                            <td key={mainChallanDetails.length} style={{ textAlign: "left", paddingLeft: "20px" }}>
                                Total Challan Amount: ₹{formatAmountWithCommas(mainChallanDetails[0]?.TotalChallanAmount)}
                            </td>
                        </tr>
                        <tr className='main-body-header' style={{ height: "50px" }}>
                            <td key={mainChallanDetails.length} style={{ textAlign: "left", paddingLeft: "20px" }}>
                                Total Challan Amount (In Words): {mainChallanDetails[0]?.TotalChallanAmountInWords}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div >
        </>
    )
}

export default GstMainEyeIconGrid;
// 