import { useEffect, useState } from 'react'
// import { BiPlus, BiMinus } from "react-icons/bi";
// import { FiEdit3 } from 'react-icons/fi';
// import { RiDeleteBin6Line } from 'react-icons/ri';
import IconButton from '../../common-component/form-elements/icon-button';
import DeleteModal from '../../common-component/modals/delete-modal';
import "../../../styles/view-table.scss";

interface propsType {
    tableData: any;
    disabled: boolean;
    checkDelete: (id: any) => void;
    retriveData: (value: any, index: number) => void;

}

function AdminDetailsTable({ tableData, disabled, checkDelete, retriveData }: propsType) {
    const [showDeleteModel, setShowDeleteModel] = useState<boolean>(false)
    const [idData, setIdData] = useState("")
    return (
        <>
            <div className='view-table-component admin-details-table' style={{ marginTop: "20px", overflowX: "auto" }}>
                <table>
                    <thead>
                        <th className='privilege left-align'>Name</th>
                        <th className='access left-align'>Designation</th>
                        <th className='access center-align' >Contact Number</th>
                        <th className='access left-align' >Email ID</th>
                        <th className='access center-align' >Is Active</th>
                        <th className='access center-align' >Action</th>
                    </thead>
                    <tbody>
                        {tableData?.length === 0 ?
                            <tr className='main-body-header'>
                                <td></td>
                                <td></td>
                                <td colSpan={2} style={{ textAlign: "center" }}>No records to show</td>
                                <td></td>
                                <td></td>
                            </tr> :
                            tableData[0]?.name !== "" &&
                            tableData.map((data: any, index: number) => (
                                <tr className='main-body-header' key={index} onClick={(e) => {
                                    const ele = e.target as HTMLElement;
                                    if (ele.tagName.toLocaleLowerCase() !== "svg" && ele.tagName.toLocaleLowerCase() != "g" && ele.className !== "icon-button") {

                                    }
                                }}>
                                    <td className='first-name left-align'>{data.name}</td>
                                    <td className='middle-icons left-align'>{data.designation}</td>
                                    <td className='middle-icons center-align'>{data.mobileNumber}</td>
                                    <td className='middle-icons left-align'>{data.email}</td>
                                    <td className='middle-icons center-align'>{data.userIsActive}</td>
                                    <td className='last-icon center-align' style={{ justifyContent: "center" }}>
                                        <IconButton
                                            iconName={"Delete"}
                                            height={"30px"}
                                            width={"30px"}
                                            fontSize={"25px"}
                                            margin={"0px 8px"}
                                            color={"white"}
                                            backgroundColor={"#D60000"}
                                            hover={!disabled && true}
                                            handleClick={() => {
                                                setShowDeleteModel(true)
                                                setIdData(data.userId)
                                            }}
                                        />
                                        <IconButton
                                            iconName={"ShowEye"}
                                            height={"35px"}
                                            width={"30px"} 
                                            fontSize={""}
                                            className={"icons"}
                                            cursor={"pointer"}
                                            color={"white"}
                                            backgroundColor={""}
                                            hover={false}
                                            handleClick={() => {
                                                retriveData(data, index)

                                            }}
                                        />

                                    </td>

                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
            {
                showDeleteModel &&
                <div >
                    <DeleteModal
                        getDelete={() => {
                            setShowDeleteModel(false)
                            checkDelete(idData)
                        }}
                        clearValue={(value) => {
                            setShowDeleteModel(value)
                        }}
                        modelType={"grid-delete"}
                    />
                </div>
            }
        </>
    )
}

export default AdminDetailsTable;