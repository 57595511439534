import React, { memo, useState, useEffect, useRef } from 'react'
import MainPageCard from '../../common-component/cards/main-page-card'
import NavigateHeader from '../../common-component/navigate-header'
import IconButton from '../../common-component/form-elements/icon-button'
import "../../../styles/product-admin/users/grid.scss"
import AgGrid, { AgGridRef } from '../../common-component/grids-and-tables/ag-grid'
import { userColumnData, rowDatathis } from '../../../config/product-admin/users/users-aggrid-column-config';
import { axiosPrivate } from '../../../middleware/axois-api'
import FileUpload from '../../common-component/modals/file-upload-modal'
import DeleteModal from '../../common-component/modals/delete-modal'
import { v4 as uuidv4 } from 'uuid';
import GridFilter from '../../common-component/grids-and-tables/grid-filter'
import AlertBox from '../../common-component/alert-box'
import useAuth from '../../../services/hooks/useauth'
import { Filter } from '../../../helpers/filtterFunction'
import Loading from '../../common-component/modals/loading-screen'
interface Props {
  userNavigateFunction: (val: string) => void;
  getEditData?: ((data: any) => void);
  modeFunction: (val: boolean) => void;
  setUserType: (val: string) => void;
}

interface IFilterValue {
  [key: string]: {
    columnName: string;
    filterType: string;
    filterWord: string;
  };
}

const Grid = memo(({ userNavigateFunction, modeFunction, setUserType, getEditData }: Props) => {

  const gridDataDownloadRef = useRef<AgGridRef>(null);
  const [rowData, setRowData] = useState<any>([]);
  const [orginalRowData, setOrginalRowData] = useState<any>([]);
  const [curruntData, setCurruntData] = useState<any>([]);
  const [fileUpload, setFileUpload] = useState<boolean>(false);
  const [uploadedDataStore, setuploadedDataStore] = useState<any>([]);
  const [filter, setFilter] = useState(false);
  const [filterRestore, setFilterRestore] = useState<IFilterValue>({})
  const [filterMethod, setFilterMethod] = useState("")

  const [showType, setShowType] = useState("warning") // error message showType
  const [showMessage, setShowMessage] = useState<string>("") // error message showMessage
  const [showAlertBox, setShowAlertBox] = useState(false) // error message ShowAlertBox
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
  const [templateLink, setTemplateLink] = useState<string>("")
  const { auth } = useAuth();
  const [loadingScreen, setLoadingScreen] = useState<boolean>(false);


  const clearAleart = () => {
    const timer = setTimeout(() => {
      setShowAlertBox(false);
      setShowMessage("");
      clearTimeout(timer);
    }, 5000);
  }

  useEffect(() => {
    fetchData();
    getUserType();
  }, []);


  const getUserType = async () => {
    try {
      const res = await axiosPrivate.get(`/user/get-privilege`)
      if (res.status === 200) {
        setUserType(res.data.UserType);
        switch (res.data.UserType) {
          case "FingerTipUser":
            setTemplateLink("bulkupload_templates/Product-Admin-bulkUpload.xlsx")
            break;
          case "CompanyUser":
            setTemplateLink("bulkupload_templates/Company-Admin-bulkUpload.xlsx")
            break;
          case "BankUser":
            setTemplateLink("bulkupload_templates/Organisation-Admin-bulkUpload.xlsx")
            break;
          default:
            setTemplateLink("bulkupload_templates/Product-Admin-bulkUpload.xlsx")
            break;
        }
      }
    } catch (error) {
    }
  }

  const fetchData = async () => {
    try {
      const response = await axiosPrivate.get(`product-admin/user`);
      setRowData(response.data);
      setOrginalRowData(response.data);
    } catch (error) {
    }
  };

  const getUploadedUserData = (val: any) => {
    setuploadedDataStore(val);
  }

  const handleDownloadExcel = () => {
    gridDataDownloadRef.current!.onBtExport();
  }

  const clearValue = (val: any) => {
    setFileUpload(val);
  }

  function getUserData(data: any) {
    getEditData && getEditData(data);
  }

  // Function for Filter values
  const handleFilter = (value: any, filterType: string) => {
    Filter(value, filterType, orginalRowData, setRowData)
  }

  const getCurruntData = (val: any) => {
    setCurruntData(val);
  }

  const getDelete = async () => {
    if (curruntData.length === 1) {
      axiosPrivate.delete(`/product-admin/user/${curruntData[0].userId}`)
        .then(response => {
          if (response.status === 200) {
            setShowAlertBox(true)
            setShowType("success")
            setShowMessage(response.data.message)
            clearAleart();
            modeFunction(false)
            setShowDeleteModal(false);
            fetchData();
            setCurruntData([]);
          }
        })
        .catch(error => {
          setShowAlertBox(true);
          setShowType("warning");
          setShowMessage(error.response.data.error)
          setShowDeleteModal(false);
          clearAleart();
        });
    } else {
      let idList: any = [];
      curruntData.map((e: any, i: number) => {
        idList.push({
          id: e.userId
        })
      })
      try {
        const res = await axiosPrivate.delete("product-admin/delete/multiple-user", {
          data: {
            data: idList
          }
        })
        if (res.status === 200) {
          if (res.data.deletedIds.length === 0) {
            setShowAlertBox(true)
            setShowType("warning")
            setShowMessage("Dependency Conflict! The selected record(s) cannot be deleted.")
            clearAleart();
            modeFunction(false)
            setShowDeleteModal(false);
            fetchData();
            setCurruntData([]);
          } else {
            setShowAlertBox(true)
            setShowType("success")
            setShowMessage(res.data.message)
            clearAleart()
            modeFunction(false)
            setShowDeleteModal(false)
            fetchData();
            setCurruntData([]);
          }
        }
      } catch (error: any) {
        setShowAlertBox(true)
        setShowType("danger")
        setShowMessage(error.response.data.error)
        clearAleart()
        setShowDeleteModal(false)
      }
    }
  };


  const UploadMessage = (message: any, type: any) => {
    setShowAlertBox(true);
    setShowMessage(message)
    setShowType(type);
    clearAleart();
  }

  return (
    <MainPageCard>
      <div className='header-main'>
        <div className='navigater'>
          <NavigateHeader
            // firstValue={"Product Admin"}
            firstValue={auth.UserType === "FingerTipUser" ? "Fingertip Admin" : auth.UserType === "BankUser" ? "Onboarding" : "Organisation"}
            secondValue={"Users"}
          />
        </div>
        <div className='icon-buttons' >
          {/* <div style={{ display: "none" }}>
            <IconButton
              iconName={"ClearFilter"}
              height={"40px"}
              width={"40px"}
              fontSize={"30px"}
              margin={"0px 8px"}
              color={"white"}
              backgroundColor={"#0055D4"}
              hover={true}
              handleClick={() => {
                gridDataDownloadRef.current!.clearFilters()
              }}
            />
          </div> */}
          <IconButton
            iconName={"Filter"}
            height={"40px"}
            width={"40px"}
            fontSize={"30px"}
            margin={"0px 8px"}
            color={"white"}
            backgroundColor={"#0055D4"}
            hover={true}
            handleClick={(event: React.MouseEvent) => {
              event?.stopPropagation()
              setFilter(!filter);
            }}
          />
          {
            filter &&
            <div className='filter' key={uuidv4()} style={{ zIndex: "10" }}>
              <GridFilter
                filterRestore={filterRestore}
                filterMethod={filterMethod}
                tabelRowData={rowData}
                data={userColumnData.slice(1)}
                handleClick={(Value: any, filterType: string) => {
                  if (filterType !== "") {
                    setFilterRestore(Value)
                    setFilter(!filter)
                    setFilterMethod(filterType)
                    handleFilter(Value, filterType)
                  } else {
                    setFilterMethod("")
                    setFilterRestore({})
                    setFilter(!filter)
                    setRowData(orginalRowData)
                  }
                }}
              />
            </div>
          }
          <IconButton
            iconName={"Delete"}
            height={"40px"}
            width={"40px"}
            fontSize={"25px"}
            margin={"0px 8px"}
            color={"white"}
            backgroundColor={"#D60000"}
            hover={curruntData.length > 0}
            opacity={curruntData.length > 0 ? "1" : "0.5"}
            cursor={curruntData.length > 0 ? "pointer" : "default"}
            handleClick={() => {
              if (curruntData.length > 0) {
                setShowDeleteModal(!showDeleteModal)
              }
            }}
          />
          <IconButton
            iconName={"Add"}
            height={"40px"}
            width={"75px"}
            fontSize={"25px"}
            margin={"0px 8px"}
            color={"white"}
            backgroundColor={"#0055D4"}
            hover={true}
            handleClick={() => {
              userNavigateFunction("form")
              modeFunction(false)
            }}
          />
          <IconButton
            iconName={"Upload"}
            height={"40px"}
            width={"40px"}
            fontSize={"25px"}
            margin={"0px 0px 0px 8px"}
            color={"white"}
            backgroundColor={"#0055D4"}
            hover={true}
            // opacity={"0.5"}
            // cursor={"not-allowed"}
            handleClick={() => {
              setFileUpload(true)
            }}
          />
          <div>
            <IconButton
              iconName={"Download"}
              height={"40px"}
              width={"40px"}
              fontSize={"25px"}
              margin={"0px 8px"}
              color={"white"}
              backgroundColor={"#0055D4"}
              hover={true}
              handleClick={handleDownloadExcel}
            />
          </div>
        </div>
      </div>
      <div>

        <AgGrid
          ref={gridDataDownloadRef}
          fileName={"User"}
          tabelRowData={rowData}
          displaylength={9}
          // tabelRowData={rowDatathis}
          tableColumnData={userColumnData}
          doubleClickUpdate={() => {
            userNavigateFunction("form");
            modeFunction(false)
          }}
          getEdit={(data) => getUserData(data)}
          getCurruntData={getCurruntData}
        />

      </div>
      {/* {
        filter &&
        <div className='filter' key={uuidv4()}>
          <GridFilter
            filterRestore={filterRestore}
            filterMethod={filterMethod}
            tabelRowData={rowData}
            data={userColumnData}
            handleClick={(Value: any, filterType: string) => {
              if (filterType !== "") {
                setFilterRestore(Value)
                setFilter(!filter)
                setFilterMethod(filterType)
                handleFilter(Value, filterType)
              } else {
                setFilterMethod("")
                setFilterRestore({})
                setFilter(!filter)
                setRowData(orginalRowData)
              }
            }}
          />
        </div>
      } */}
      {
        fileUpload &&
        <FileUpload
          template={templateLink}
          clearValue={clearValue}
          headerData={userColumnData}
          gridUpdate={() => { fetchData() }}
          templateName={"User"}
          excelFileName={"User-BulkUpload-Status"}
          getUploadedUserData={getUploadedUserData}
          loadingScreenFunction={(val: boolean) => { setLoadingScreen(val) }}

          setshowUploadMessage={UploadMessage}
          apiUrl={'bulk-upload/user'}
        />
      }
      {
        showDeleteModal &&
        <div >
          <DeleteModal
            getDelete={getDelete}
            clearValue={(value) => { setShowDeleteModal(value) }}
            modelType={"grid-delete"}
          />
        </div>
      }
      {
        showAlertBox &&
        <div className='alert-warp'>
          <AlertBox type={showType} message={showMessage} />
        </div>
      }
      {
        loadingScreen &&
        <Loading />
      }
    </MainPageCard>
  )
})

export default Grid;
