import { Component } from 'react'
//Components
import Form from '../../../components/approval/product-admin/organisation-approval/form'
import Grid from '../../../components/approval/product-admin/organisation-approval/grid'

interface OrganisastionValues {
    organisationFun: string;
    editData: any;
    isEditMode: boolean;
    userIsActive: boolean;
    currentEdit: string;
}

class OrganisationApprovalMain extends Component<{}, OrganisastionValues> {
    constructor(props: OrganisastionValues) {
        super(props);
        this.state = {
            organisationFun: "grid",
            editData: {},
            isEditMode: false,
            userIsActive: false,
            currentEdit: "Organisation Details",
        };
    }

    render() {
        return (
            <div>
                {
                    this.state.organisationFun === "addForm" &&
                    <Form
                        editData={this.state.editData}
                        isEditMode={this.state.isEditMode}
                        userIsActive={this.state.userIsActive}
                        currentEdit={this.state.currentEdit}
                        organisationFun={(Val: any) => {
                            this.setState({ organisationFun: Val })
                        }}
                        getEditMode={(value: any) => {
                            this.setState({ isEditMode: value })
                        }}
                    />
                }
                {
                    this.state.organisationFun === "grid" &&
                    <Grid
                        getEditMode={(val: any) =>
                            this.setState({
                                isEditMode: val
                            })
                        }
                        getEditData={(name: string, data: any,) => {
                            if (name === "addForm") {
                                this.setState({
                                    organisationFun: name,
                                    editData: { ...data },
                                    isEditMode: true,
                                    userIsActive: true
                                })
                            } else {
                                this.setState({
                                    organisationFun: name,
                                    isEditMode: true,
                                })
                            }
                        }}
                    />
                }
            </div>
        )
    }
}

export default OrganisationApprovalMain;