import React, { useEffect, useRef, useState } from 'react'
import MainPageCard from '../../common-component/cards/main-page-card'
import NavigateHeader from '../../common-component/navigate-header'
import IconButton from '../../common-component/form-elements/icon-button'
import AgGrid, { AgGridRef } from '../../common-component/grids-and-tables/ag-grid'
import { directTaxColumnData, sampleDirectGridData } from '../../../config/payable/direct-tax.tsx/direct-tax-config-data'
import { axiosPrivate } from '../../../middleware/axois-api'
interface Props {
    directTaxViewfun: (val: any) => void;
    formDataByIdFun: (val: any) => void;
}
export default function DirectTaxGrid({ directTaxViewfun, formDataByIdFun }: Props) {

    const companyDownloadRef = useRef<AgGridRef>(null);
    const [rowData, setRowData] = useState<any>();
    const handleDownloadExcel = () => {
        companyDownloadRef.current!.onBtExport();
    }

    useEffect(() => {
        getGridData()
    }, [])
    async function getGridData() {
        try {
            const res = await axiosPrivate.get('/directtax-payable/get-grid-datas');
            setRowData(res.data.directTaxGridData)
        } catch (error) {
            console.log("Error in getGridData function");
        }
    }

    async function getFormDataById(id: any) {
        try {
            const res = await axiosPrivate.post(`/directtax-payable/get-form-datas/${id}`);
            if (res.status == 200) {
                formDataByIdFun(res.data.decryptedDirectTaxDetails)
            }


        } catch (error) {
            console.log("Error in getFormDataById function");
        }
    }
    return (
        <>
            <MainPageCard>
                <div className='direct-tax-header' style={{ display: "flex", justifyContent: "space-between" }}>
                    <div style={{ width: "100%" }}>
                        <NavigateHeader
                            firstValue={"Payable"}
                            secondValue={"Direct Tax"}
                        />
                    </div>
                    <div className='icon-buttons' style={{ display: "flex" }}>
                        {/* <div style={{ display: "flex" }}> */}
                        <IconButton
                            iconName={"Filter"}
                            height={"40px"}
                            width={"40px"}
                            fontSize={"30px"}
                            margin={"0px 8px"}
                            color={"white"}
                            backgroundColor={"#0055D4"}
                            hover={true}
                            cursor={"pointer"}
                            handleClick={(event: React.MouseEvent) => {
                                // event?.stopPropagation()
                                // setFilter(!filter);
                            }}
                        />
                        <IconButton
                            iconName={"Delete"}
                            height={"40px"}
                            width={"40px"}
                            fontSize={"30px"}
                            margin={"0px 8px"}
                            color={"white"}
                            backgroundColor={"red"}
                            hover={false}
                            cursor={"not-allowed"}
                            disabled={true}
                            opacity={"0.2"}
                            handleClick={(event: React.MouseEvent) => {
                                // event?.stopPropagation()
                                // setFilter(!filter);
                            }}
                        />
                        {/* </div> */}
                        <IconButton
                            iconName={"Add"}
                            height={"40px"}
                            width={"100px"}
                            fontSize={"25px"}
                            margin={"0px 8px"}
                            color={"white"}
                            backgroundColor={"#0055D4"}
                            cursor={"pointer"}
                            hover={true}
                            handleClick={() => {
                                directTaxViewfun('form')
                                formDataByIdFun([])
                                // if (currentActor === "Maker" || currentActor === "singleUser") {
                                //     navigate("/organisation/vendor");
                                // }
                            }}
                        />
                        <IconButton
                            iconName={"Upload"}
                            height={"40px"}
                            width={"40px"}
                            fontSize={"25px"}
                            margin={"0px 0px 0px 8px"}
                            color={"white"}
                            backgroundColor={"#0055D4"}
                            cursor={"not-allowed"}
                            hover={false}
                            opacity={"0.2"}
                            handleClick={() => {
                                // if (currentActor === "Maker" || currentActor === "singleUser") {
                                //     setFileUpload(true);
                                // }
                            }}
                        />
                        <IconButton
                            iconName={"Download"}
                            height={"40px"}
                            width={"40px"}
                            fontSize={"25px"}
                            margin={"0px 8px"}
                            color={"white"}
                            backgroundColor={"#0055D4"}
                            hover={true}
                            handleClick={() => { }}
                        />
                    </div>
                </div>
                <div style={{ marginTop: "40px", height: "600px" }}>
                    <AgGrid
                        tabelRowData={rowData}
                        tableColumnData={directTaxColumnData}
                        ref={companyDownloadRef}
                        fileName={"DirectTaxData"}
                        getCurruntData={() => {
                        }}
                        doubleClickUpdate={(val) => {
                        }}
                        getEdit={(val) => {
                            getFormDataById(val.payableDirectTaxId)
                        }}
                    />
                </div>
            </MainPageCard>
        </>
    )
}
