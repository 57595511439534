import React, { useState } from 'react'
import "../../../styles/payable/vendor/vendor-grid.scss"
import { RxCross1 } from 'react-icons/rx';
import ButtonComponent from '../../common-component/form-elements/button-component';
import InputComponent from '../../common-component/form-elements/input-component';
import { resetFormModified } from '../../../redux/action';
import { useDispatch } from 'react-redux';
import AlertBox from '../../common-component/alert-box';
import { axiosPrivate } from '../../../middleware/axois-api';


interface Styles {
    closeModal: () => void;
    leavePage?: () => void;
    handleSave: () => void;
    currentEditBulk?: any
    bulkInvoices: any[]
    setCurrentEditBulk: (val: any) => void;
    setBulkInvoices: (val: any) => void;
    savedMessage: () => void;
}

export default function EditbulkPopup({ closeModal,
    leavePage,
    currentEditBulk,
    setCurrentEditBulk,
    bulkInvoices,
    setBulkInvoices,
    handleSave,
    savedMessage

}: Styles) {

    const dispatch = useDispatch();
    const [bulkInputErrors, setBulkInputErrors] = useState<Record<string, boolean>>({});// error displayer 
    const [showMessage, setShowMessage] = useState<string>("") //AlertBox Message State
    const [showType, setShowType] = useState<string>("warning") //AlertBox warning Statef
    const [showAlertBox, setShowAlertBox] = useState<boolean>(false) //AlertBox open State
    const [isEditStarted, setIsEditStarted] = useState<boolean>(false) //AlertBox open State

    // function to show thousand separator
    const formatAmount = (amount: any) => {
        if (amount && amount !== ".") {
            const onlyNumber = amount.toString().replace(/[^0-9.]/g, "");
            // Handle empty input
            if (onlyNumber === "") {
                return "";
            }
            // Split input into integer and decimal parts
            const parts = onlyNumber.split(".");
            const integerPart = parts[0];
            const decimalPart = parts[1] || "";
            // Format the integer part with commas
            const formattedInteger = parseFloat(integerPart).toLocaleString('en-IN');
            // Handle complete decimal input (e.g., "5000.50")
            if (decimalPart !== "") {
                return `${formattedInteger}.${decimalPart}`;
            }
            // Handle incomplete decimal input (e.g., "5000.")
            if (amount.toString().endsWith(".")) {
                return `${formattedInteger}.`;
            }
            // Return formatted integer
            return formattedInteger;
        }
        return "";
    };

    const validInvoice: any = {
        isValid: {
            invoiceNumber: {
                regex: "",
                field: "mandatory",
                shouldNotBe: ""
            },
            baseAmount: {
                regex: "",
                field: "mandatory",
                shouldNotBe: ""
            },
            amountToVendor: {
                regex: "",
                field: "mandatory",
                shouldNotBe: ""
            },
        }
    };

    const updatedIsValueValidAll: Record<string, boolean> = {};
    const validateForm = (): boolean => {
        const updatedFieldValidity: Record<string, boolean> = {};
        Object.keys(validInvoice.isValid).forEach((field) => {
            const rule = validInvoice.isValid[field];
            const value = currentEditBulk[field];
            // Check if the field value meets the conditions based on rule.field and rule.shouldNotBe
            const isValueValid = (rule.field === "mandatory") ? (value !== rule.shouldNotBe) : true;
            // If   a regular expression is provided, test the field value against it
            const isRegexValid = rule.regex !== "" ? new RegExp(rule.regex).test(value) : true;
            updatedFieldValidity[field] = !isValueValid || !isRegexValid;
            updatedIsValueValidAll[field] = !isValueValid
        });
        setBulkInputErrors(updatedFieldValidity);
        // Check if any field has validation errors
        const isFormValid = Object.keys(updatedFieldValidity).every((keys) => {
            return !updatedFieldValidity[keys]
        });
        return isFormValid;
    };

    const saveChanges = async () => {
        let isValid = validateForm();
        if (isValid) {
            if (parseFloat(currentEditBulk.baseAmount) < parseFloat(currentEditBulk.amountToVendor) || parseFloat(currentEditBulk.amountToVendor) <= 0) {
                setShowAlertBox(true);
                setShowType("danger");
                setShowMessage("Amount to vendor should not be zero and greater than invoice amount");
                setBulkInputErrors({
                    baseAmount: true,
                    amountToVendor: true
                })
                const timer = setTimeout(() => {
                    setShowAlertBox(false)
                    setShowMessage("");
                    clearTimeout(timer);
                }, 5000);
                return;
            }
            let isInvoiceNumberChanged = bulkInvoices.find((obj: any) => obj.invoice_payable_bulk_id === currentEditBulk.invoice_payable_bulk_id && obj.invoiceNumber === currentEditBulk.invoiceNumber);
            if (isInvoiceNumberChanged) {
                let index = bulkInvoices.indexOf(isInvoiceNumberChanged);
                if (index !== -1) {
                    let bulk = [...bulkInvoices];
                    bulk[index] = currentEditBulk;
                    setBulkInvoices(bulk);
                    closeModal();
                    savedMessage();
                    setIsEditStarted(false);
                }
            } else {
                let alreadyExist = bulkInvoices.find((obj: any) => obj.vendorOverviewId === currentEditBulk.vendorOverviewId && obj.invoiceNumber === currentEditBulk.invoiceNumber);
                if (alreadyExist) {
                    setShowAlertBox(true);
                    setShowType("danger");
                    setShowMessage("Invoice number already exist.");
                    setBulkInputErrors({
                        invoiceNumber: true,
                    })
                    const timer = setTimeout(() => {
                        setShowAlertBox(false)
                        setShowMessage("");
                        clearTimeout(timer);
                    }, 5000);
                } else {
                    try {
                        const response = await axiosPrivate.post(`/vendor-payable/invoice/checkinvoicenumber/duplicate`, {
                            vendorOverviewId: currentEditBulk.vendorOverviewId,
                            invoiceNumber: currentEditBulk.invoiceNumber,
                        });
                        if (response.data.isDuplicate === false) {
                            let foundObject = bulkInvoices.find((obj: any) => obj.invoice_payable_bulk_id === currentEditBulk.invoice_payable_bulk_id);
                            let index = bulkInvoices.indexOf(foundObject);
                            let bulk = [...bulkInvoices];
                            bulk[index] = currentEditBulk;
                            setBulkInvoices(bulk);
                            closeModal();
                            savedMessage();
                            setIsEditStarted(false);
                        } else {
                            setShowAlertBox(true);
                            setShowType("danger");
                            setShowMessage("Invoice number already exist.");
                            setBulkInputErrors({
                                invoiceNumber: true,
                            })
                            const timer = setTimeout(() => {
                                setShowAlertBox(false)
                                setShowMessage("");
                                clearTimeout(timer);
                            }, 5000);
                            return;
                        }
                    } catch (error) {
                    }
                }
            }
        } else {
            setShowAlertBox(true);
            setShowType("danger");
            setShowMessage(Object.values(updatedIsValueValidAll).every((values) => !values) ?
                "Some of the field(s) are not in required format." : "Mandatory field(s) should not be left blank.")
            const timer = setTimeout(() => {
                setShowAlertBox(false)
                setShowMessage("");
                clearTimeout(timer);
            }, 5000);
        }
    }

    return (
        <>
            <div className={"payment-Edit-Bulk"}>
                <div className={"container"}>
                    <div className={"close-icon"}>
                        <span className={"cross-icon"}>
                            <RxCross1 style={{ fontSize: "40px" }} onClick={closeModal} />
                        </span>
                    </div>
                    <div>
                        <div className={"vendor-display-edit-fields"}>
                            <div className={"display-vendor-details"}>
                                <p>{"Vendor Name"}</p>
                                <p>{currentEditBulk.vendorName}</p>
                            </div>
                            <div className={"display-vendor-details"}>
                                <p>{"Account Number"}</p>
                                <p>{currentEditBulk.accountNo}</p>
                            </div>
                            <div className={"display-vendor-details"}>
                                <p>{"IFSC Code"}</p>
                                <p>{currentEditBulk.IFSC}</p>
                            </div>
                        </div>
                        <div className={'bulk-input-edit-fields'}>
                            <div>
                                <InputComponent
                                    height={"40px"}
                                    width={"280px"}
                                    margin={"0px 0px 0px 0px"}
                                    padding={"10px"}
                                    border={bulkInputErrors.invoiceNumber ? "1px solid red" : "1px solid #A9C3DC"}
                                    borderRadius={"0px"}
                                    backgroundColor={"white"}
                                    autoFocus
                                    color={"black"}
                                    type={"text"}
                                    maxLength={16}
                                    inputTitle={'Invoice Number'}
                                    required={true}
                                    disabled={false}
                                    placeHolder={'Enter invoice number'}
                                    inputValue={currentEditBulk?.invoiceNumber}
                                    getUser={(value) => {
                                        let invoiceNumber = value.replace(/ /g, "");
                                        setCurrentEditBulk((currentEditBulk: any) => ({
                                            ...currentEditBulk,
                                            invoiceNumber: invoiceNumber,
                                        }));
                                        setIsEditStarted(true)
                                        dispatch(resetFormModified(true));
                                    }}
                                />
                            </div>
                            <div>
                                <InputComponent
                                    height={"40px"}
                                    width={"280px"}
                                    margin={"0px 0px 0px 0px"}
                                    padding={"10px"}
                                    border={bulkInputErrors.baseAmount ? "1px solid red" : "1px solid #A9C3DC"}
                                    borderRadius={"0px"}
                                    backgroundColor={"white"}
                                    color={"black"}
                                    type={"text"}
                                    maxLength={15}
                                    textAlign={"right"}
                                    inputTitle={'Payment Amount'}
                                    disabled={false}
                                    required={true}
                                    placeHolder={'0'}
                                    className={"vendor-input-type"}
                                    inputValue={formatAmount(currentEditBulk.baseAmount)}
                                    getUser={(value) => {
                                        if (value.startsWith('.')) {
                                            value = value.substring(1);
                                        }
                                        let baseAmount = value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, '$1').replace(/^(\d*\.\d{0,2}).*/, '$1');
                                        setCurrentEditBulk((invoiceDetails: any) => ({
                                            ...invoiceDetails,
                                            baseAmount: baseAmount,
                                        }));
                                        dispatch(resetFormModified(true));
                                        setIsEditStarted(true)

                                    }}
                                />
                            </div>
                            <div>
                                <InputComponent
                                    height={"40px"}
                                    width={"280px"}
                                    margin={"0px 0px 0px 0px"}
                                    padding={"10px"}
                                    border={bulkInputErrors.amountToVendor ? "1px solid red" : "1px solid #A9C3DC"}
                                    borderRadius={"0px"}
                                    backgroundColor={"white"}
                                    color={"black"}
                                    type={"text"}
                                    maxLength={15}
                                    textAlign={"right"}
                                    inputTitle={'Amount To Vendor'}
                                    disabled={false}
                                    required={true}
                                    placeHolder={'0'}
                                    className={"vendor-input-type"}
                                    inputValue={formatAmount(currentEditBulk.amountToVendor)}
                                    getUser={(value) => {
                                        if (value.startsWith('.')) {
                                            value = value.substring(1);
                                        }
                                        let amountToVendor = value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, '$1').replace(/^(\d*\.\d{0,2}).*/, '$1');
                                        setCurrentEditBulk((invoiceDetails: any) => ({
                                            ...invoiceDetails,
                                            amountToVendor: amountToVendor,
                                        }));
                                        dispatch(resetFormModified(true));
                                        setIsEditStarted(true)

                                    }}
                                />
                            </div>
                        </div>

                        <div className={"buttons"} >
                            <ButtonComponent
                                title={"Cancel"}
                                height={"48px"}
                                width={"150px"}
                                backgroundColor={"#888888"}
                                color={"white"}
                                margin={"0px 8px"}
                                className={"button-component-hover cancel"}
                                handleClick={() => {
                                    closeModal()
                                }}
                            />

                            <ButtonComponent
                                title={"Update"}
                                height={"48px"}
                                width={"150px"}
                                backgroundColor={"#0055D4"}
                                disabled={isEditStarted ? false : true}
                                color={"white"}
                                margin={"0px 8px"}
                                className={isEditStarted ? "button-component-hover common-btn" : "button-component-hover disabled"}
                                handleClick={() => {
                                    if (isEditStarted) {
                                        saveChanges()
                                    }
                                }}
                            />

                        </div>
                    </div>
                </div>
            </div>
            {
                showAlertBox &&
                <AlertBox type={showType} message={showMessage} />
            }
        </>

    )
}

