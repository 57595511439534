import CustomEllipsisRenderer from "../../../product-admin/users/users-aggrid-column-config"

export const virutualCardApprovalColumnData = [
    {
        field: 'selection', headerName: '', minWidth: 60, maxWidth: 60, resizable: false, suppressMovable: true, filter: "agSetColumnFilter", suppressSizeToFit: false, headerCheckboxSelection: true, checkboxSelection: true, showDisabledCheckboxes: true, suppressRowTransform: true, cellStyle: { textAlign: 'center' }
    },
    {
        field: 'requestNumber', headerName: 'Request No', minWidth: 200, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "number", cellRendererFramework: CustomEllipsisRenderer,
    },
    {
        field: 'employeeName', headerName: 'Employee Name', minWidth: 200, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer,
    },
    {
        field: 'vendorName', headerName: 'Vendor Name', minWidth: 200, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer,
    },
    {
        field: 'createDate', headerName: 'Requested Date', minWidth: 200, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "number", cellRendererFramework: CustomEllipsisRenderer,
    },

    {
        field: 'virtualCardAmount', headerName: 'Virtual Card Amount', minWidth: 250, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "number", cellRendererFramework: CustomEllipsisRenderer,
    },
    {
        field: 'status', headerName: 'Status', minWidth: 200, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellClassRules: {
            'success-cell': (params: any) => params.value === 'Approved',
            'registered-cell': (params: any) => params.value === 'Pending Approval',
            'denied-cell': (params: any) => params.value === 'Declined',
        }, cellRendererFramework: CustomEllipsisRenderer,
    },
    {
        field: 'approvedBy', headerName: 'Approved / Declined by', minWidth: 200, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer,
    },
    {
        field: 'virtualCardNo', headerName: 'Virtual Card No', minWidth: 200, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "number", cellRendererFramework: CustomEllipsisRenderer,
    },
    {
        field: 'virtualCardExpiryDate', headerName: 'Virtual Card Expiry', minWidth: 250, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer,
    },






]


export const rowDatathis = [
    {
        requestNo: "32932",
        Date: "03/05/2023",
        employeeName: "Arun",
        vendorName: "KQ",
        AccountNumber: "78945612301",
        IFSC: "UTIB0045678",
        virCardno: "78945612301",
        virCardAmount: "84000.00",
        VirCardExpiry: "06/08/2023",
        status: "Approved",
        approvedBy: "naveen",

    },
    {
        requestNo: "32932",
        Date: "03/05/2023",
        employeeName: "Arun",
        vendorName: "KQ",
        AccountNumber: "78945612301",
        IFSC: "UTIB0045678",
        virCardno: "78945612301",
        virCardAmount: "84000.00",
        VirCardExpiry: "06/08/2023",
        status: "Approved",
        approvedBy: "naveen",

    },
    {
        requestNo: "32932",
        Date: "03/05/2023",
        employeeName: "Arun",
        vendorName: "KQ",
        AccountNumber: "78945612301",
        IFSC: "UTIB0045678",
        virCardno: "78945612301",
        virCardAmount: "84000.00",
        VirCardExpiry: "06/08/2023",
        status: "Approved",
        approvedBy: "naveen",

    },
    {
        requestNo: "32932",
        Date: "03/05/2023",
        employeeName: "Arun",
        vendorName: "KQ",
        AccountNumber: "78945612301",
        IFSC: "UTIB0045678",
        virCardno: "78945612301",
        virCardAmount: "84000.00",
        VirCardExpiry: "06/08/2023",
        status: "Approved",
        approvedBy: "naveen",

    },

]