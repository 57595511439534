import React, { useState } from 'react'
import ChooseCard from './choose-card';


interface Props {
    excisePaymentView: (val: any) => void;
    transaction: any;
    makePaymentDatas: any;
}



function ExcsieApprovalForm({ excisePaymentView, transaction,makePaymentDatas }: Props) {
    return (
        <div>
          
            <div>
                <ChooseCard
                    excisePaymentView={excisePaymentView}
                    transaction={transaction}
                    makePaymentDatas={makePaymentDatas}
                />
            </div>

        </div>
    )
}

export default ExcsieApprovalForm