import { useState, useEffect, useRef } from 'react';
import '../../../styles/grid-filter.scss';
import ButtonComponent from '../form-elements/button-component';
import DropdownComponent from '../form-elements/dropdown-component';
import { v4 as uuidv4 } from 'uuid';

// sending colum data
// data={
//     [
//       { field: 'date', filtertype: "number", headerName: 'The full Name of the athlete' },
//       {
//         field: 'age',
//         headerName: 'The number of Years since the athlete was born',
//         initialWidth: 120,
//         filtertype: "number",
//       },
//     ]
//   }

interface Todo {
    id: number;
    text: string;
}
interface IFilterValue {
    [key: string]: {
        columnName: string;
        filterType: string;
        filterWord: string;
    };
}
interface Props {
    // data: ColDef[]
    data: any;
    tabelRowData?: any;
    handleClick: (Value: any, filterType: string) => void
    filterRestore: IFilterValue;
    filterMethod: string;
}

interface ColDef {
    field: string;
    headerName: string;
    initialWidth?: number;
    filtertype: string;
}

export default function GridFilter({ tabelRowData, data, handleClick, filterRestore, filterMethod }: Props) {
    const [todos, setTodos] = useState<Todo[]>([{ id: Date.now(), text: "" }]);
    const [inputText, setInputText] = useState<string>('');
    // const [isOpend, setisOpend] = useState<boolean>(false);
    const [totalDatas, setTotalDatas] = useState<{ [key: string]: any }>({});
    const [dataFilter, setdataFilter] = useState("Any")
    const [columnDefs, setColumnDefs] = useState<ColDef[]>(data);
    const gridFilterRef = useRef<HTMLDivElement | null>(null);

    const filtertypeList: any = {
        "text": [
            { value: "Contains", label: "Contains" },
            { value: "Not contains", label: "Not contains" },
            { value: "Equals", label: "Equals" },
            { value: "Not equal", label: "Not equal" },
            { value: "Starts with", label: "Starts with" },
            { value: "Ends with", label: "Ends with" },
            { value: "Blank", label: "Blank" },
            { value: "Not blank", label: "Not blank" },
        ],
        "number": [
            { value: "Equals", label: "Equals" },
            { value: "Not equal", label: "Not equal" },
            { value: "Less than", label: "Less than" },
            { value: "Less than or equals", label: "Less than or equals" },
            { value: "Greater than", label: "Greater than" },
            { value: "Greater than or equals", label: "Greater than or equals" },
            { value: "Blank", label: "Blank" },
            { value: "Not blank", label: "Not blank" },
        ],
        "date": [
            { value: "Equals", label: "Equals" },
            { value: "Greater than", label: "Greater than" },
            { value: "Less than", label: "Less than" },
            { value: "Not equal", label: "Not equal" },
            { value: "Blank", label: "Blank" },
            { value: "Not blank", label: "Not blank" },
        ],
    }

    let isOpend = false;
    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        isOpend = true;
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    const handleClickOutside = (event: MouseEvent) => {
        if (isOpend && gridFilterRef.current && !gridFilterRef.current.contains(event.target as Node) && (event.target as HTMLElement).className !== " css-d7l1ni-option" && (event.target as HTMLElement).className !== " css-tr4s17-option" && (event.target as HTMLElement).className !== "close-button") {
            handleClick("", "")
        }
    };

    useEffect(() => {
        if (Object.keys(filterRestore).length !== 0) {
            let value: Todo[] = [];
            Object.keys(filterRestore).map((e: any) => {
                value.push({
                    id: e, text: filterRestore[e].filterWord
                })
            })
            setdataFilter(filterMethod)
            setTodos(value)
            setTotalDatas(filterRestore)
        }

    }, [filterRestore, filterMethod])

    const fieldName = columnDefs.map((e) => {
        return {
            value: e.field,
            label: e.headerName,
        }
    }
    );

    // const checkFiltertype = columnDefs.filter((e) => e.filtertype == "number")
    // const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    //     setTotalDatas(prev => ({
    //         ...prev,
    //         [todos.length]: { ...prev[todos.length], filterWord: e.target.value }
    //     }))
    //     setInputText(e.target.value);
    // };

    const handleAddTodo = () => {
        if (todos.length >= 3) return;
        const newTodo: Todo = {
            id: Date.now(),
            text: inputText,
        };
        setTodos((prevTodos) => [...prevTodos, newTodo]);
        setInputText('');
    };


    const handleDeleteTodo = (id: number) => {
        setTodos((prevTodos) => prevTodos.filter((todo) => todo.id !== id));

        if (totalDatas.hasOwnProperty(id)) {
            // Create a copy of the totalDatas object
            const updatedTotalDatas = { ...totalDatas };
            // Remove the item with the specified id from the copy
            delete updatedTotalDatas[id];
            // Set the updated totalDatas in the state
            setTotalDatas(updatedTotalDatas);
        }
    };

    // const filterData = Object.values(totalDatas);

    const filteredOrgData = () => {
        handleClick(totalDatas, dataFilter)
    }

    return (
        <div ref={gridFilterRef}>
            <div className='grid-filter' data-testid="filter-item" tabIndex={0}  >
                <div className='filter-type'>
                    <div className='dropdown' key={uuidv4()}>
                        <DropdownComponent
                            defaultValue={[
                                {
                                    value: dataFilter,
                                    label: dataFilter
                                }
                            ]}
                            options={[
                                { value: "All", label: "All" },
                                { value: "Any", label: "Any" }
                            ]}

                            getData={(val) => {
                                setdataFilter(val.value)
                            }}
                        />
                    </div>
                    <div className='add-button'>
                        <ButtonComponent
                            title={"Add"}
                            height={"40px"}
                            width={"100%"}
                            // boxShadow={"0px 10px 20px #0066FF80"}
                            // disabled={true} 
                            backgroundColor={"#0055D4"}
                            color={"white"}
                            handleClick={handleAddTodo}
                            className={`button-component-hover ${todos.length == 3 ? 'disabled' : 'common-btn'}`}
                        // className={`button-component-hover disabled`}
                        />
                    </div>
                </div>
                {
                    todos.map((todo, i) => (
                        <div key={todo.id} className='column-filter'>
                            <div className='dropdown'>
                                <DropdownComponent
                                    defaultValue={[
                                        {
                                            label: Object.values(totalDatas)[i]?.columnName ? fieldName.filter((ele) => { return ele.value == Object.values(totalDatas)[i]?.columnName })[0].label : "Select",
                                            value: Object.values(totalDatas)[i]?.columnName ? Object.values(totalDatas)[i]?.columnName : "Select"
                                        }
                                    ]}
                                    options={fieldName}
                                    getData={(val) => {
                                        setTotalDatas(prev => ({
                                            ...prev,
                                            [todo.id]: { ...prev[todo.id], columnName: val.value }
                                        }))
                                    }}
                                />
                            </div>
                            <div className='dropdown'
                                onClick={() => { }}>
                                <DropdownComponent
                                    defaultValue={[
                                        {
                                            label: Object.values(totalDatas)[i]?.filterType ? Object.values(totalDatas)[i]?.filterType : "Select",
                                            value: Object.values(totalDatas)[i]?.filterType ? Object.values(totalDatas)[i]?.filterType : "Select"
                                        }
                                    ]}
                                    options={
                                        totalDatas[todo.id]?.columnName === undefined ? []
                                            :
                                            filtertypeList[(columnDefs.filter((item) => item.field === totalDatas[todo.id]?.columnName))[0].filtertype]
                                        // checkFiltertype.some((item) => item.field === totalDatas[todo.id]?.columnName) ?
                                        //     [
                                        //         { value: "Greater than", label: "Greater than" },
                                        //         { value: "Less than", label: "Less than" },
                                        //     ]
                                        //     :
                                        //     [
                                        //         { value: "Equal", label: "Equal" },
                                        //         { value: "Not Equal", label: "Not Equal" },
                                        //     ]
                                    }
                                    getData={(val) => {
                                        setTotalDatas(prev => ({
                                            ...prev,
                                            [todo.id]: { ...prev[todo.id], filterType: val.value }
                                        }))
                                    }}
                                />
                            </div>
                            <div className='searching-input'> 
                                <input
                                    value={Object.values(totalDatas)[i]?.filterWord}
                                    placeholder='Type a filter'
                                    autoFocus
                                    onChange={(e) => {
                                        setTotalDatas(prev => ({
                                            ...prev,
                                            [todo.id]: { ...prev[todo.id], filterWord: e.target.value }
                                        }))
                                    }} />
                            </div>
                            {todos.length > 1 ?
                                <div className='close-button' onClick={() => handleDeleteTodo(todo.id)}>
                                    {"X"}
                                </div> :
                                <div style={{ width: '40px' }}></div>
                            }
                        </div>
                    )
                    )}
                <div className='buttons'>
                    <div style={{ width: "150px" }}>
                        <ButtonComponent
                            title={"Clear"}
                            height={"45px"}
                            width={"100%"}
                            // boxShadow={"0px 10px 20px #0066FF80"}
                            backgroundColor={"#888888"}
                            color={"white"}
                            handleClick={() => handleClick("", "")}
                            className={"button-component-hover cancel"}
                        />
                    </div>
                    <div style={{ width: "150px" }}>
                        <ButtonComponent
                            title={"Apply"}
                            height={"45px"}
                            width={"100%"}
                            // boxShadow={"0px 10px 20px #0066FF80"}
                            backgroundColor={"#0055D4"}
                            color={"white"}
                            handleClick={filteredOrgData}
                            className={"button-component-hover common-btn"}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};