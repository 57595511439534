import React, { Component } from 'react'
import VendorForm from '../../components/payable/vendor/vendor-form'
import VendorGrid from '../../components/payable/vendor/vendor-grid'
import { axiosPrivate } from '../../middleware/axois-api';
import Loading from '../../components/common-component/modals/loading-screen';
import GroupPay from '../../components/payable/vendor/group-pay';

interface vendorValues {
    vendorFun: string;
    UserPrivilege: string;
    approvalProcessFlow: any;
    currentActor: string;
    loadingScreen: boolean;
    isEditMode: boolean;
    isBulkPayment: boolean;
    editData: any;
    groupInvoices: any;
}
export default class Vendor extends Component<{}, vendorValues> {
    constructor(props: vendorValues) {
        super(props);
        this.state = {
            vendorFun: "grid",
            approvalProcessFlow: [],
            groupInvoices: [],
            editData: {},
            currentActor: "-",
            UserPrivilege: "-",
            isEditMode: false,
            isBulkPayment: false,
            loadingScreen: false,
        };
    }

    render() {
        return (
            <div>
                {
                    this.state.vendorFun === "grid" &&
                    <>
                        <VendorGrid
                            vendorVewFun={(val: any) => {
                                this.setState({ vendorFun: val })
                            }}
                            editData={this.state.editData}
                            approvalProcessFlow={this.state.approvalProcessFlow}
                            currentActor={this.state.currentActor}
                            getEditData={(data: any, isEditMode: boolean, nav: string) => {
                                this.setState({
                                    vendorFun: nav,
                                    isEditMode: isEditMode,
                                })
                                if (nav === "form")
                                    this.setState({
                                        editData: { ...data },
                                    })
                                else {
                                    this.setState({
                                        groupInvoices: data,
                                    })
                                }
                            }}
                            setUserPrivilege={(data: any) => {
                                this.setState({
                                    UserPrivilege: data,
                                })
                            }}
                            loggedUser={(value: any,) => {
                                this.setState({
                                    approvalProcessFlow: value.approvalProcessFlow,
                                    currentActor: value.currentUser
                                })
                            }}
                            SetEditData={(data: any, isEditMode: boolean) => {
                                this.setState({
                                    editData: { ...data },
                                    isEditMode: isEditMode,
                                })
                            }}
                            SetIsBulkPayment={(value: boolean) => {
                                this.setState({
                                    isBulkPayment: value,
                                })
                            }}

                        />
                    </>

                }
                {
                    this.state.vendorFun === "form" &&
                    <VendorForm
                        vendorVewFun={(val: any) => {
                            this.setState({ vendorFun: val })
                        }}
                        approvalProcessFlow={this.state.approvalProcessFlow}
                        currentActor={this.state.currentActor}
                        isEditMode={this.state.isEditMode}
                        editData={this.state.editData}
                        isBulkPayment={this.state.isBulkPayment}
                    />
                }
                {
                    this.state.vendorFun === "groupPay" &&
                    <GroupPay
                        vendorVewFun={(val: any) => {
                            this.setState({ vendorFun: val })
                        }}
                        currentActor={this.state.currentActor}

                        groupInvoices={this.state.groupInvoices}
                    />
                }
                {
                    this.state.loadingScreen &&
                    <Loading />
                }


            </div>

        )
    }
}
