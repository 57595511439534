import React, { useEffect, useRef, useState } from 'react'
import MainPageCard from '../../components/common-component/cards/main-page-card'
import ButtonComponent from '../../components/common-component/form-elements/button-component'
import AgGrid, { AgGridRef } from '../../components/common-component/grids-and-tables/ag-grid'
import { TallyColumnData, tallyRowData } from '../../config/product-admin/tally/tally-config'
import { ImSwitch } from "react-icons/im";
import { BiToggleRight, BiToggleLeft } from "react-icons/bi";
import { axiosPrivate } from '../../middleware/axois-api'
import '../../styles/product-admin/Tally/tally.scss'
import GridFilter from '../../components/common-component/grids-and-tables/grid-filter'
import { Filter } from '../../helpers/filtterFunction'
import { v4 as uuidv4 } from 'uuid';
import IconButton from '../../components/common-component/form-elements/icon-button'


interface IFilterValues {
    [key: string]: {
        columnName: string;
        filterType: string;
        filterWord: string;
    };
}

function TallyAccountMain() {

    const tallyDownloadRef = useRef<AgGridRef>(null);
    const [rowData, setRowData] = useState<any>([]);
    const [filter, setFilter] = useState(false);
    const [filterRestore, setFilterRestore] = useState<IFilterValues>({});
    const [filterMethod, setFilterMethod] = useState("");
    const [curruntData, setCurruntData] = useState<any>();
    const [orginalRowData, setOrginalRowData] = useState<any>([]);
    const [syncIconColor, setSyncIconColor] = useState<any>();
    const [lastLogin, setLastLogin] = useState<any>();

    const handleDownloadExcel = () => {
        tallyDownloadRef.current!.onBtExport();
    }

    useEffect(() => {
        tallySyncInformation();
        tallyTableData();
    }, []);

    const tallySyncInformation = async () => {
        try {
            const response = await axiosPrivate.get('/tally-integration/check/tally/available');
            setSyncIconColor(response)
        } catch (error) {
        }
    };

    const tallyTableData = async () => {
        try {
            const response = await axiosPrivate.get('/tally-integration/get/tally/tabledata');
            setRowData(response.data.decreptData);
            setOrginalRowData(response.data.decreptData)
            setLastLogin(response.data.lastTime === null || undefined || "" ? "" : response.data.lastTime)

        } catch (error) {
        }
    }

    const handleTallySync = async () => {
        try {
            const response = await axiosPrivate.get('/tally-integration/save');
        } catch (error) {
        }
    }

    // Function for Filter values
    const handleFilter = (value: any, filterType: string) => {
        Filter(value, filterType, orginalRowData, setRowData)
    }

    return (
        <div>
            <MainPageCard>
                <div className={"tally-main-sync"} >
                    <div className={"tally-mian-sub-control"}>

                        <ImSwitch
                            className={'sync-status-icon'}
                            style={{ color: syncIconColor === true ? 'green' : 'red' }}
                            onClick={() => { }}
                        />

                        {/* {syncIconColor === 'Success' ? <BiToggleRight style={{ color: "green", fontSize: "40px" }} /> : <BiToggleLeft style={{ color: "red", fontSize: "40px" }} />
                    } */}

                        <IconButton
                            iconName={"tallySync"}
                            height={"40px"}
                            width={"150px"}
                            fontSize={"23px"}
                            margin={"0px 8px"}
                            color={"white"}
                            backgroundColor={syncIconColor === true ? "#0055D4" : "#afb1b6"}
                            hover={syncIconColor === true ? true : false}
                            disabled={syncIconColor === true ? false : true}
                            handleClick={handleTallySync}
                        />

                        <div>
                            {`Last Login Date: ${lastLogin}`}
                        </div>
                    </div>

                    <div className={"tally-mian-icons-control"}>
                        <IconButton
                            iconName={"Filter"}
                            height={"40px"}
                            width={"40px"}
                            fontSize={"30px"}
                            margin={"0px 8px"}
                            color={"white"}
                            backgroundColor={"#0055D4"}
                            hover={true}
                            handleClick={(event: React.MouseEvent) => {
                                event?.stopPropagation()
                                setFilter(!filter);
                            }}
                        />

                        <IconButton
                            iconName={"Download"}
                            height={"40px"}
                            width={"40px"}
                            fontSize={"25px"}
                            margin={"0px 8px"}
                            color={"white"}
                            backgroundColor={"#0055D4"}
                            hover={true}
                            handleClick={handleDownloadExcel}
                        />
                    </div>
                </div>

                <div className={"tally-sync-main-ag-grid"} >
                    <AgGrid
                        ref={tallyDownloadRef}
                        fileName={""}
                        tabelRowData={rowData}
                        tableColumnData={TallyColumnData}
                        doubleClickUpdate={() => { }}
                        getEdit={() => { }}
                        getCurruntData={() => { }}
                        hidePaginationDD={true}
                        displaylength={18}
                    />
                </div>

                <div className={"tally-notes"}>
                    <h5>Notes</h5>
                    <p>Tally should be On & Connecting.<span>*</span></p>
                    <p>Tally should be select on fingertip company.<span>*</span></p>
                </div>

                {
                    filter &&
                    <div className='tally-sync-filter' key={uuidv4()} >
                        <GridFilter
                            filterRestore={filterRestore}
                            filterMethod={filterMethod}
                            tabelRowData={rowData}
                            data={TallyColumnData}
                            handleClick={(Value: any, filterType: string) => {
                                if (filterType !== "") {
                                    setFilterRestore(Value)
                                    setFilter(!filter)
                                    setFilterMethod(filterType)
                                    handleFilter(Value, filterType)
                                    setCurruntData([]);
                                } else {
                                    setFilterMethod("")
                                    setFilterRestore({})
                                    setFilter(!filter)
                                    setRowData(orginalRowData)
                                    setCurruntData([]);
                                }
                            }}
                        />
                    </div>
                }
            </MainPageCard>
        </div>
    )
}

export default TallyAccountMain