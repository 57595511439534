import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import '../../styles/slider-component.scss';

interface propeType {
    marks: any
    max: number
    value: number
}

function SliderComponent({ marks, max, value }: propeType) {

    return (
        <div data-testid="slider-component">
            <Slider
                className="rc-slider"
                dots={true}
                value={value}
                min={0}
                step={0}
                max={max}
                marks={marks}
                data-testid="rc-slider-dot"
            />
        </div>
    )
}

export default SliderComponent;