import { useState, useEffect } from 'react';
import { TbListDetails } from 'react-icons/tb';
import { HiArrowNarrowRight } from 'react-icons/hi';
import '../../../styles/modals/show-log-details.scss';
interface MainData {
    cardTitle: string;
    cardType: string;
    selectedValue: any | string;
    isOpen: boolean;
    options: Option[] | string[];
}
interface Option {
    value: string;
    label: string;
}
interface FilterData {
    setOpenRightSideFilter: () => void;
    getUserLogDeatil: any

}

export default function CommonShowLogDetails({
    setOpenRightSideFilter,
    getUserLogDeatil
}: FilterData) {
    const [dropDownData, setDropDownData] = useState<any>([]);

    // useEffect(() => {
    //     setDropDownData(datas)
    // }, [datas])

    // function handleSearchForFilter(event: any) {
    //     const searchQuery = event.target.value.toLowerCase();

    //     if (searchQuery === "") {
    //         // If the input is empty, set dropDownData back to the original data
    //         setDropDownData(datas);
    //     } else {
    //         const filterData = datas.filter((e: any) => e.cardTitle.toLowerCase().includes(searchQuery))
    //         setDropDownData(filterData);
    //     }
    // }

    function getStatusColor(ResponseStatusCode: any) {
        console.log(ResponseStatusCode)
        switch (ResponseStatusCode) {
            case "200":
                return "green";
            default:
                return "red";
        }
    }
    return (
        <>
            <div className='main-user-log-container'>
                <div className='user-log-heading'>
                    <span>
                        <TbListDetails className='user-log-detail-icon' />
                        <h2>{"Details"}</h2>
                    </span>
                    <span onClick={() => { setOpenRightSideFilter() }}>
                        <HiArrowNarrowRight className='user-log-arrow-icon' />
                    </span>
                </div>

                <div className='log-details-main'>
                    <div style={{ display: "flex" }}>
                        <div className='log-details-heading'>{"Request Method :"}</div>
                        <div className='log-details' style={{ paddingLeft: "20px" }}> {getUserLogDeatil.RequestMethod}</div>
                    </div>
                    <div style={{ display: "flex" }}>
                        <div className='log-details-heading'>{"Request URL :"}</div>
                        <div className='log-details' style={{ paddingLeft: "7px" }}> {getUserLogDeatil.RequestURL}</div>
                    </div>
                    <div style={{ display: "flex" }}>
                        <div className='log-details-heading'>{"Requested Time :"}</div>
                        <div className='log-details' style={{ paddingLeft: "10px" }}> {getUserLogDeatil.RequestedTime}</div>
                    </div>
                    <div style={{ display: "flex" }}>
                        <div className='log-details-heading'>{"Response Time :"}</div>
                        <div className='log-details' style={{ paddingLeft: "10px" }}> {getUserLogDeatil.ResponseTime}</div>
                    </div>
                    <div style={{ display: "flex" }}>
                        <div className='log-details-heading'>{"Response Code :"}</div>
                        <div className='log-details' style={{ paddingLeft: "10px", color: getStatusColor(getUserLogDeatil.ResponseStatusCode) }}> {getUserLogDeatil.ResponseStatusCode}</div>
                    </div>
                    <div>
                        <div className='request-payload'>
                            <div className='request-payload-header'>{"Request Payload"}</div>
                            <div>{JSON.stringify(getUserLogDeatil.RequestPayload, null, 2)}</div>
                        </div>
                    </div>
                    <div>
                        <div className='request-payload'>
                            <div className='request-payload-header'>{"Response Body"}</div>
                            <div>{JSON.stringify(getUserLogDeatil.ResponseBody, null, 2)}</div>
                        </div>
                    </div>
                </div>

                {/* <div className='rightside-button-main'>
                    <ButtonComponent
                        title={"Apply"}
                        height={"40px"}
                        width={"100px"}
                        backgroundColor={"#0055D4"}
                        disabled={false}
                        color={"white"}
                        margin={"0px 0px"}
                        className={"button-component-hover common-btn"}
                        handleClick={() => { handleApplyButtonForFilter() }}
                    />
                </div> */}
            </div>
        </>
    );
}

// sending data
// datas={[
//     {
//       cardTitle: "Card Management drop",
//       cardType: "dropdown",
//       selectedValue: "",
//       options: [
//         { value: "TamilNadu", label: "TamilNadu" },
//         { value: "Kerala", label: "Kerala" },
//         { value: "Andhra Pradesh", label: "Andhra Pradesh" }
//       ],
//       isOpen: false
//     },
//     {
//       cardTitle: "Card Management check",
//       cardType: "checkbox",
//       isOpen: false,
//       selectedValue: [],
//       options: ["check", "hello"]
//     },
//     {
//       cardTitle: "Card Management drop",
//       cardType: "dropdown",
//       selectedValue: "",
//       options: [
//         { value: "TamilNadu", label: "TamilNadu" },
//         { value: "Kerala", label: "Kerala" },
//         { value: "Andhra Pradesh", label: "Andhra Pradesh" }
//       ],
//       isOpen: false
//     },
//     {
//       cardTitle: "Card Management check",
//       cardType: "checkbox",
//       isOpen: false,
//       selectedValue: [],
//       options: ["check1", "hello1", "check12", "hello12", "check13", "hello13",]
//     }
//   ]}

// const handleCheckbox = (index: number, data: string) => {
//     setFilterDatas((prevFilterDatas: any) => {
//         const updatedFilterDatas = [...prevFilterDatas];
//         const updatedData = { ...updatedFilterDatas[index] };
//         const selectedValues = updatedData.selectedValue as string[];
//         if (selectedValues.includes(data)) {
//             updatedData.selectedValue = selectedValues.filter((value) => value !== data);
//         } else {
//             updatedData.selectedValue = [...selectedValues, data];
//         }
//         updatedFilterDatas[index] = updatedData;
//         return updatedFilterDatas;
//     });
// };