import React, { useEffect, useState } from 'react'
import { BiPlus, BiMinus } from "react-icons/bi";
import "../../../styles/view-table.scss";
import IconButton from '../../../components/common-component/form-elements/icon-button';
import DeleteModal from '../../../components/common-component/modals/delete-modal';
import "../../../styles/onboarding/company/grid-icon.scss"




interface Props {

    directorApiData: any
    retriveData: (value: any, index: number) => void;
    showmodal: (value: any, index: number) => void;
}

function VendorDirectorDinTable({ directorApiData, retriveData, showmodal
}: Props) {
    console.log(directorApiData)

    return (
        <>
            <div className='view-table-component' style={{ marginTop: "40px", marginBottom: "40px", height: "155px", overflowY: "auto" }} >
                <table>
                    <thead style={{ zIndex: "0" }}>
                        {/* <tr> */}
                            <th className='align-differ' >Name</th>
                            <th className='tan'>Director Din</th>
                            <th className='status' style={{ paddingLeft: "5px" }}>Designation</th>
                        {/* </tr> */}
                    </thead>
                    <tbody >
                        {directorApiData?.length === 0 ?
                            <tr>
                                <td className='style-grid' style={{ marginTop: "0px" }} colSpan={4}>No records to show</td>
                            </tr> :
                            directorApiData?.map((data: any, index: number) => (
                                // <div  style={{ marginTop: "0px" }} key={index}>
                                    <tr style={{ justifyContent: "space-between" }} className='main-body-header'>
                                        <td className='align-differ' style={{height:"40px"}}>{data.name}</td>
                                        <td className='tan'>{data.din}</td>
                                        <td className='status' style={{ paddingLeft: "5px" }}>{data.designation}</td>
                                    </tr>
                                // </div>
                            ))}
                    </tbody>
                </table>
            </div>
            {/* {
                showDeleteModel &&
                <div >
                    <DeleteModal
                        getDelete={() => { }}
                        clearValue={(value) => { setShowDeleteModel(value) }}
                        modelType={"grid-delete"}
                    />
                </div>
            } */}
        </>
    )
}

export default VendorDirectorDinTable;