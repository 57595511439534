import { useEffect, useState } from 'react'
//Component
import NavigateHeader from '../../../common-component/navigate-header';
import MainPageCard from '../../../common-component/cards/main-page-card';
import InputComponent from '../../../common-component/form-elements/input-component';
import DropdownComponent from '../../../common-component/form-elements/dropdown-component';
import RadioOrCheckbox from '../../../common-component/form-elements/radio-or-checkbox';
import ButtonComponent from '../../../common-component/form-elements/button-component';
import TextArea from '../../../common-component/form-elements/text-area';
import useAxiosPrivate from "../../../../services/hooks/useaxios-private";
import AlertBox from '../../../common-component/alert-box';
import DeleteModal from '../../../common-component/modals/delete-modal';
import Loading from '../../../common-component/modals/loading-screen';
import Changesmodal from '../../../common-component/modals/changes-modal';
import ApprovalOrganisationTable from './approval-organisation-table';
//Assets
import { v4 as uuidv4 } from 'uuid';
import { ReactComponent as Correct } from '../../../../assets/icons/correct.svg'
import { ReactComponent as InProgerss } from '../../../../assets/icons/in-progerss.svg'
import { ReactComponent as NotWork } from '../../../../assets/icons/not-work.svg'
import { ReactComponent as OrganisationDetails } from '../../../../assets/icons/organisation-details.svg'
import { ReactComponent as Admin } from '../../../../assets/icons/admin-details.svg'
import { ReactComponent as Approval } from '../../../../assets/icons/approval.svg'
// import AdminDetailsTable from './admin-details-table';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { resetFormModified } from "../../../../redux/action";
//Style
import "../../../../styles/product-admin/organisation/form.scss";


interface Admin {
    designation: string;
    email: string;
    name: string;
    userIsActive: string;
    mobileNumber: string;
    remark: string;
    userId: number | string;
}
// State interface
interface AppState {
    admin_details: Admin[];
}
interface ValidationRules {
    organisationUserData: {
        organisationName: {
            regex: string;
            field: string;
        };
        organisationCategory: {
            regex: string;
            field: string;
        };
    };
    admin_details: {
        name: {
            regex: string;
            field: string;
        };
        designation: {
            regex: string;
            field: string;
        };
        mobileNumber: {
            regex: string;
            field: string;
        };
        email: {
            regex: string;
            field: string;
        };
    };
}
// Initial state
const initialState: AppState = {
    admin_details: [],
};

interface FormProps {
    editData: any;
    isEditMode: boolean;
    currentEdit: string;
    userIsActive?: boolean;
    getDelete?: () => void;
    getEditMode: (value: boolean) => void;
    organisationFun: (val: any) => void;
}

const Form: React.FC<FormProps> = ({
    organisationFun,
    editData,
    isEditMode,
    getEditMode,
    currentEdit,
}) => {
    const [showType, setShowType] = useState("warning") // error message showType
    const [showMessage, setShowMessage] = useState<string>("") // error message showMessage
    const [showAlertBox, setShowAlertBox] = useState(false) // error message ShowAlertBox
    const [editIsActive, setEditIsActive] = useState<boolean>(false);
    const [resetPassword, setResetPassword] = useState()
    const [newUser, setNewUser] = useState<number>(0);
    const [showResetModel, setShowResetModel] = useState<boolean>(false)
    const [currentPage, setCurrentPage] = useState(currentEdit);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [showChangesModal, setShowChangesModal] = useState(false);
    const [isFormDirty, setIsFormDirty] = useState(false);
    const [isDisabled, setIsDisabled] = useState<boolean>(true)
    const [Disbled, setDisbled] = useState<boolean>(false)
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [iconStatus, setIconStatus] = useState({
        organisationDetails: "inprogress",
        adminDetails: "notwork",
    })
    const isFormModified = useSelector((state: any) => state.isFormModified);
    const dispatch = useDispatch();
    const [adminButtonStatus, setAdminButtonStatus] = useState({
        addButton: "open",
        editButton: "open",
        updateButton: "open",
    })

    const [organisationStepper, setOrganisationStepper] = useState({
        "organisationDetails": false,
        "adminDetails": false,
    })

    const [organisationUserData, setOrganisationUserData] = useState({
        organisationName: "",
        organisationCategory: "",
        subCategory: "",
        description: "",
        isActive: "Yes",
        pan: "",
        gstin: "",
        location: "",
        organisationId: "",
        address: "",
        Remarks: "",
        approval: "",
        approvalRemarks: "",
        declinerequest: "",
        activeYes: "",
        activeNo: "",
        approvalRedio: "",
        internalApprovalStatus: "",
        internalApprovalStatusRemarks: ""
    })
    const [adminDetailsForm, setAdminDetailsForm] = useState({
        designation: "",
        email: "",
        userIsActive: "Yes",
        name: "",
        mobileNumber: "",
        remark: "",
        userId: "",
    })
    const [admin_details, setAdmindetails] = useState<Admin[]>([]
    )
    const [organisationUserDataErrors, setOrganisationUserDataErrors] = useState({
        organisationName: true,
        organisationCategory: true,
        subCategory: true,
        description: true,
        isActive: true,
        Remarks: true,
        organisationId: true,
        pan: true,
        gstin: true,
        location: true,
        address: true,
    })

    const [admin_details_errors, setAdmindetailsErrors] = useState<any>({
        designation: true,
        email: true,
        isActive: true,
        name: true,
        mobileNumber: true,
        remark: true
    })
    const axiosPrivate = useAxiosPrivate();

    //   form edit data 
    useEffect(() => {
        if (isEditMode) {
            if (Object.keys(editData).length > 0) {
                setOrganisationUserData({
                    organisationName: editData.organisationName || "",
                    organisationCategory: editData.organisationCategory || "",
                    subCategory: editData.subCategory || "",
                    description: editData.description || "",
                    isActive: editData.isActive || "",
                    organisationId: editData.organisationId || "",
                    pan: editData?.pan || "",
                    gstin: editData?.gstin || "",
                    location: editData?.location || "",
                    address: editData?.address || "",
                    Remarks: editData.Remarks ? editData.Remarks : "",
                    approval: editData.approval ? editData.approval : "",
                    approvalRemarks: editData.approvalRemarks ? editData.approvalRemarks : "",
                    declinerequest: editData.declinerequest ? editData.declinerequest : "",
                    activeYes: editData.activeYes ? editData.activeYes : "",
                    activeNo: editData.activeNo ? editData.activeNo : "",
                    approvalRedio: editData.approvalRedio ? editData.approvalRedio : "",
                    internalApprovalStatus: editData.internalApprovalStatus ? editData.internalApprovalStatus : "",
                    internalApprovalStatusRemarks: editData.internalApprovalStatusRemarks ? editData.internalApprovalStatusRemarks : "",
                })
            }
            let adminDetails: Admin[] = [];

            if (editData.users) {
                Object.values(editData.users).map((elem: any, i: number) => {
                    adminDetails.push({
                        designation: elem.designation,
                        email: elem.email,
                        name: elem.name,
                        userIsActive: elem.userIsActive,
                        mobileNumber: elem.mobileNumber,
                        remark: elem.remark ? elem.remark : "",
                        userId: elem.userId,
                    })
                    if (elem.userIsActive == "Yes") {
                        setAdminDetailsForm({
                            designation: elem.designation,
                            email: elem.email,
                            userIsActive: elem.userIsActive,
                            name: elem.name,
                            mobileNumber: elem.mobileNumber,
                            remark: elem.remark ? elem.remark : "",
                            userId: elem.userId
                        })
                    }
                })
            }
            if (editData?.users?.length == 0) {
                let data = JSON.parse(editData?.adminDetails)
                setAdminDetailsForm({
                    ...adminDetailsForm,
                    name: data?.name,
                    designation: data?.designation,
                    email: data?.email,
                    mobileNumber: data?.mobileNumber,
                })
            }
            setAdmindetails(adminDetails)
            adminDetails[(adminDetails).length - 2]?.userIsActive === "No" && setEditIsActive(true)
            setIconStatus({
                ...iconStatus,
                organisationDetails: "correct",
                adminDetails: "correct",
            })
        }
    }, [editData])
    console.log("organisationUserData", organisationUserData)

    useEffect(() => {
        if (organisationUserData.isActive === "Yes") {
            setOrganisationUserData((prevState) => ({
                ...prevState,
                Remarks: "",
            }));
        }
    }, [organisationUserData.isActive])
    // warningMsg 
    const clearAleart = (status: any) => {
        const timer = setTimeout(() => {
            setShowAlertBox(false)
            setShowMessage("");
            if (status === 200 && currentPage !== "Organisation Details") {
                organisationFun("grid")
                getEditMode(false)
            }
            clearTimeout(timer);
        }, 5000);
    }

    function validateState() {
        const organisationValue = {
            organisationName: organisationUserData.organisationName !== "" && new RegExp(/^[a-zA-Z][a-zA-Z/ !-@#$%^&*]*$/).test(organisationUserData.organisationName),
            gstin: organisationUserData.gstin !== "" && new RegExp(/^[0-9]{2}[A-Z]{5}[0-9]{4}[0-9A-Z]{1}[0-9A-Z]{1}[0-9A-Z]{1}[0-9A-Z]{1}$/).test(organisationUserData.gstin),
            location: organisationUserData.location !== "" && organisationUserData.location !== undefined && organisationUserData.location !== null,
            pan: organisationUserData.pan !== "" && new RegExp(/[A-Z]{5}\d{4}[A-Z]{1}/).test(organisationUserData.pan),
            organisationCategory: organisationUserData.organisationCategory !== "",
            subCategory: true,
            description: true,
            address: true,
            isActive: true,
            organisationId: true,

            ...(organisationUserData.isActive === "No" ? { Remarks: new RegExp(/^[^\s].*/).test(organisationUserData.Remarks) } : { Remarks: true })
        }
        if (currentPage === "Organisation Details") {
            setOrganisationUserDataErrors(organisationValue)
        }
        const adminValue = {
            name: adminDetailsForm.name !== "" && new RegExp(/^[a-zA-Z][a-zA-Z /]*$/).test(adminDetailsForm.name),
            designation: adminDetailsForm.designation !== "" && new RegExp(/^[a-zA-Z][a-zA-Z /]*$/).test(adminDetailsForm.designation),
            email: adminDetailsForm.email !== "" && new RegExp(/^[a-z0-9._-]+@[a-z0-9-]+\.[a-z]{2,}$/).test(adminDetailsForm.email),
            mobileNumber: adminDetailsForm.mobileNumber !== "" && new RegExp(/^[0-9]{10}$/).test(adminDetailsForm.mobileNumber),
            isActive: true,

            ...(adminDetailsForm.userIsActive === "No" ? { remark: new RegExp(/^[^\s].*/).test(adminDetailsForm.remark) } : { remark: true })

        }
        let allMandatoryFilledOrg = true;
        let allMandatoryFilledAdmin = true;
        if (organisationUserData.organisationName === "" || organisationUserData.gstin === "" || organisationUserData.location === "" || organisationUserData.pan === "" || organisationUserData.organisationCategory === "" || (organisationUserData.isActive === "No" && organisationUserData.Remarks === "")) {
            allMandatoryFilledOrg = false;
        }
        if (adminDetailsForm.name === "" || adminDetailsForm.designation === "" || adminDetailsForm.email === "" || adminDetailsForm.mobileNumber === "" || (adminDetailsForm.userIsActive === "No" && adminDetailsForm.remark === "")) {
            allMandatoryFilledAdmin = false;
        }
        if (currentPage === "Admin Details") {
            setOrganisationUserDataErrors(organisationValue)
            setAdmindetailsErrors(adminValue)
        }
        return { organisationValue, adminValue, allMandatoryFilledOrg, allMandatoryFilledAdmin };
    }
    //send Approval data
    async function handleSumitOrganisationApproval() {
        try {
            const res = await axiosPrivate.post('/organisation/approval/remarks', organisationUserData)
        } catch (error) {
            console.log("Approval Post Not Send", error)
        }
    }
    //  send data and save btn function  and  stepper
    async function save() {
        let adminDetails: any = [];
        // const { organisationValue, adminValue, allMandatoryFilledOrg, allMandatoryFilledAdmin } = validateState()
        if (currentPage === "Organisation Details") {
            // const isValidForm = Object.values(organisationValue).every(key => key)
            setCurrentPage("Admin Details")
            setIconStatus({
                ...iconStatus,
                adminDetails: "notwork",
                // adminDetails: "inprogress",
            })
            // if (isValidForm) {
            //     if (isEditMode) {
            //         setIconStatus({
            //             ...iconStatus,
            //             organisationDetails: "correct",
            //         })
            //         await updateData(adminDetails)
            //         return;
            //     }
            //     setOrganisationStepper((prevState) => ({
            //         ...prevState,
            //         organisationDetails: !organisationStepper.organisationDetails,
            //     }));

            //     setIconStatus({
            //         ...iconStatus,
            //         organisationDetails: "correct",
            //         adminDetails: "inprogress",
            //     })

            // } else {
            //     setShowAlertBox(true);
            //     setShowType("danger")
            //     setShowMessage(allMandatoryFilledOrg ? "Some of the field(s) are not in required format." : "Mandatory field(s) should not be left blank.")
            //     clearAleart("")
            // }
        } else if (currentPage === "Admin Details") {
            // const isValidForm = Object.values(adminValue).every(key => key)
            setCurrentPage("Approval")
            setIconStatus({
                ...iconStatus,
                adminDetails: "inprogress",
            })
            // if (isValidForm) {
            //     setIconStatus({ ...iconStatus, adminDetails: "correct" })

            //     if (isEditMode) {
            //         admin_details.map((e: any) => {
            //             if (e.userId == adminDetailsForm.userId && e.userId != "") {
            //                 adminDetails.push({
            //                     admin_details: adminDetailsForm
            //                 })
            //             } else {
            //                 adminDetails.push({
            //                     admin_details: e
            //                 })
            //             }
            //         });
            //         if (adminDetailsForm.userId == "") {
            //             adminDetails.push({
            //                 admin_details: adminDetailsForm
            //             })
            //         }
            //     } else {
            //         adminDetails.push({
            //             admin_details: adminDetailsForm
            //         })
            //     }
            //     await updateData(adminDetails)
            // } else {
            //     setShowAlertBox(true);
            //     setShowType("danger")
            //     setShowMessage(allMandatoryFilledAdmin ? "Some of the field(s) are not in required format." : "Mandatory field(s) should not be left blank.")
            //     clearAleart("")
            // }
        } else if (currentPage === "Approval") {
            if (organisationUserData.internalApprovalStatus == "Approve" || organisationUserData.internalApprovalStatus == "Decline") {
                setIconStatus({
                    ...iconStatus,
                    adminDetails: "correct",
                })
                // handleSumitOrganisationApproval()
                updateData(adminDetails)
                // setOrganisationUserData(
                //     {
                //         ...organisationUserData,
                //         approvalRemarks: "",
                //         declinerequest: "",
                //     }
                // )
            }
        }
    }

    const updateData = async (adminDetails: any) => {
        setIsLoading(true)
        try {
            const res = isEditMode ?
                await axiosPrivate.patch(`/organisation/${organisationUserData.organisationId}`,
                    {
                        "organisation": organisationUserData,
                        "admins": [...adminDetails],
                        adminDetails: adminDetailsForm
                    })
                :
                await axiosPrivate.post('/organisation',
                    {
                        "organisation": organisationUserData,
                        "admins": [...adminDetails],
                    }
                )

            if (res.status === 200) {
                setShowAlertBox(true)
                setShowType("success")
                setShowMessage(res.data.message)
                setIsLoading(false)
                clearAleart(res.status)
                setIsFormDirty(false)
                setIsDisabled(false)
                dispatch(resetFormModified(false));
            }
        } catch (error: any) {
            setIsLoading(false)
            if (error.status === 500) {
                setShowAlertBox(true);
                setShowType("danger")
                setShowMessage(error.response.data.error)
                clearAleart("")
                setIsDisabled(true)
            } else if (error.code === "ERR_NETWORK") {
                setShowAlertBox(true);
                setShowType("danger")
                setShowMessage(error.message)
                clearAleart("")
            } else {
                setShowAlertBox(true);
                setShowType("danger")
                setShowMessage(error.response.data.error)
                clearAleart("")
            }
        }
    }
    const OrganisationDropdown = [{ label: "Bank", value: "Bank" }, { label: "Network", value: "Network" }, { label: "PG", value: "PG" }]
    const OrganisationSubDropdown = [{ label: "Select", value: "" }, { label: "Value added services", value: "Value added services" }]

    const returnGird = () => {
        if (isFormDirty) {
            setShowChangesModal(true)
        } else {
            organisationFun("grid")
            getEditMode(false)
        }
    }
    const [checkOrg, setCheckOrg] = useState<any>([{}])

    useEffect(() => {
        if (editData.organisationCategory !== "Select") {
            setCurrentPage('Organisation Details')
            setCheckOrg([{
                label: editData.organisationCategory,
                value: editData.organisationCategory,
            }])
        }
    }, [editData.organisationCategory])

    useEffect(() => {
    }, [checkOrg])

    // adminresetPassword
    const adminResetPassword = async () => {
        axiosPrivate.patch(`organisation/resend/set-up/password/${admin_details[newUser].userId}`)
            .then(response => {
                setShowResetModel(false)
            })
            .catch(error => {
            });
    };

    const adminDetailsIcon = () => {
        const { organisationValue, adminValue } = validateState()
        if (!isEditMode) {
            return;
        }
        if (Object.values(organisationValue).every(key => key)) {
            setCurrentPage("Admin Details")
            if (isEditMode) {
                setIconStatus({ ...iconStatus, organisationDetails: "correct", adminDetails: "correct" })
            } else {
                setIconStatus({ ...iconStatus, organisationDetails: "correct", adminDetails: "inprogress" })
            }
        } else {
            setShowAlertBox(true);
            setShowType("danger")
            setShowMessage("Mandatory field(s) should not be left blank.")
            clearAleart("")
        }
    }

    const adminDataDelete = (id: any) => {
        axiosPrivate.delete(`organisation/admin/delete/${id}`)
            .then(response => {
                if (response.status === 200) {
                    setShowAlertBox(true);
                    setShowType("success")
                    setShowMessage(response.data.message)
                    clearAleart(response.status)
                }
            })
            .catch(error => {
                setShowAlertBox(true);
                setShowType("danger")
                setShowMessage(error.response.data.error)
                clearAleart("")
            });
    }

    const orginationMenueClick = () => {
        const { organisationValue, adminValue } = validateState()
        if (!isEditMode) return
        if (Object.values(adminValue).every(key => key)) {
            setCurrentPage("Organisation Details")
            isEditMode && setIconStatus({ ...iconStatus, adminDetails: "correct" })
        } else {
            setShowAlertBox(true);
            setShowType("danger")
            setShowMessage("Mandatory field(s) should not be left blank.")
            clearAleart("")
        }
    }

    function retriveData(value: any, index: number) {
        if (value) {
            setAdminDetailsForm({
                ...value,
                designation: value?.designation,
                email: value?.email,
                userIsActive: value?.userIsActive,
                name: value?.name,
                mobileNumber: value?.mobileNumber,
                remark: value?.remark,
                index: index,
                // userId: value?.userId, 
            })
            // setUserDataErrors({})
        } else {
            setAdminDetailsForm({
                designation: "",
                email: "",
                userIsActive: "",
                name: "",
                mobileNumber: "",
                remark: "",
                userId: "",
            })
        }
        if (value?.userIsActive === "No") {
            setDisbled(true)
        } else {
            setDisbled(false)
        }

        if (Object.keys(value).length > 0) {
            setAdminDetailsForm(
                {
                    ...value,
                    remark: value.remark,
                    index: index
                }
            )
        }
    }
    function cancel() {
        if (currentPage === "Admin Details") {
            setCurrentPage("Organisation Details")
            setIconStatus({
                ...iconStatus,
                adminDetails: "notwork",
            })

        }
        else if (currentPage === "Approval") {
            setCurrentPage("Admin Details")
            setIconStatus({
                ...iconStatus,
                adminDetails: "notwork",
            })
        }
    }

    return (
        <>
            <MainPageCard>
                <NavigateHeader
                    style={{ cursor: "pointer" }}
                    firstValue={currentPage === "Organisation Details" || currentPage === "Admin Details" ? "Product Admin" : "Approval"}
                    secondValue={currentPage === "Organisation Details" || currentPage === "Admin Details" ? "Organisation" : "Customer"}
                    navigatePage={() => { returnGird() }}
                />
                <div className='form-top-main'>
                    <div>
                        <div className='leftnavigation-form' >
                            {/* form  stepper  */}
                            <div className='left-navigation' style={{ width: "300px", height: "66vh" }} >
                                <div className='testing-stepper' style={{ width: "300px" }}>
                                    <div className='contents'>
                                        <OrganisationDetails
                                            className={`left-icon ${![""].includes(iconStatus.organisationDetails) && "active"}`}
                                            style={(!isEditMode) ? { pointerEvents: "none", cursor: "none" } : {}}
                                            onClick={() => {
                                                orginationMenueClick()
                                            }}
                                        />
                                        <div
                                            className={`content-click  ${currentPage === "Organisation Details" ? "active-page" : ""}`}
                                            style={(!isEditMode) ? { pointerEvents: "none", cursor: "none" } : {}}
                                            onClick={() => {
                                                orginationMenueClick()
                                            }}
                                        >
                                            Organisation Details<span className='star-span'>*</span>
                                        </div>
                                        <div className='tick-image-green'>
                                            {/* {(iconStatus.organisationDetails === "inprogress") && <InProgerss />} */}
                                            {
                                                // iconStatus.organisationDetails === "correct" && 
                                                <Correct />
                                            }
                                        </div>
                                        <div className={`vertical-line`} >
                                        </div>
                                    </div>
                                    <div className='contents'>
                                        <Admin
                                            className={`left-icon ${!["notwork"].includes(iconStatus.adminDetails) && "active"}`}
                                            style={(!isEditMode) ? { pointerEvents: "none", cursor: "none" } : {}}
                                            onClick={() => {
                                                adminDetailsIcon()
                                            }} />
                                        <div
                                            className={`content-click  ${currentPage === "Admin Details" ? "active-page" : ""}`}
                                            style={(!isEditMode) ? { pointerEvents: "none", cursor: "none" } : {}}
                                            onClick={() => { adminDetailsIcon() }}
                                        >
                                            Admin Details<span className='star-span'>*</span>
                                        </div>
                                        <div className='tick-image-green'>
                                            {/* {iconStatus.adminDetails === "notwork" && <NotWork />} */}
                                            {/* {(iconStatus.adminDetails === "inprogress") && <InProgerss />} */}
                                            {<Correct />}
                                        </div>
                                        <div className={`vertical-line`} >
                                        </div>
                                    </div>
                                    <div className='contents'>
                                        <Approval
                                            className={`left-icon ${currentPage === "Approval" ? "active" : ""}`}
                                            // style={(!isEditMode) ? { pointerEvents: "none", cursor: "none" } : {}}
                                            onClick={() => {
                                                // setCurrentPage("Approval")
                                            }} />
                                        <div
                                            className={`content-click  ${currentPage === "Approval" ? "active-page" : ""}`}
                                            // style={(!isEditMode) ? { pointerEvents: "none", cursor: "none" } : {}}
                                            onClick={() => {
                                                setCurrentPage("Approval")
                                            }}
                                        >
                                            Approval
                                        </div>
                                        <div className='tick-image-green'>
                                            {/* {<NotWork />} */}
                                            {/* {<InProgerss />} */}
                                            {/* {currentPage === "Approval" ? <Correct /> : <NotWork />} */}
                                            {iconStatus.adminDetails === "notwork" && <NotWork />}
                                            {iconStatus.adminDetails === "inprogress" && <InProgerss />}
                                            {iconStatus.adminDetails === "correct" && <Correct />}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* form Organisation Details */}
                            {
                                currentPage === "Organisation Details" &&
                                <div style={{ height: "550px", overflow: "auto" }}>
                                    <div className='center center-approval'>
                                        <p > Organisation Details</p>
                                        <div className='form-input'>
                                            <InputComponent
                                                height={"40px"}
                                                width={"300px"}
                                                margin={"0px 60px 30px 0px"}
                                                padding={"0px 0px 0px 10px"}
                                                borderRadius={"0px"}
                                                backgroundColor={"white"}
                                                color={"black"}
                                                type={"text"}
                                                placeHolder='Enter Organisation Name'
                                                inputTitle={"Organisation Name"}
                                                required={true}
                                                disabled={true}
                                                maxLength={25}
                                                inputValue={organisationUserData.organisationName}
                                                border={
                                                    !organisationUserDataErrors.organisationName ?
                                                        "1px solid red" : "1px solid #A9C3DC"
                                                }
                                                getUser={(value: any) => {
                                                    setIsFormDirty(true)
                                                    setOrganisationUserData((prevState) => ({
                                                        ...prevState,
                                                        organisationName: value,
                                                    }));
                                                    dispatch(resetFormModified(true));
                                                    isEditMode && setIconStatus({ ...iconStatus, organisationDetails: "inprogress" })
                                                    setIsDisabled(true)
                                                }}
                                            />
                                            <div className='dropdown drop-margin' key={uuidv4()}>
                                                <DropdownComponent
                                                    options={OrganisationDropdown}
                                                    title={"Organisation Category"}
                                                    required={true}
                                                    isDisabled={true}
                                                    width='300px'
                                                    className={!organisationUserDataErrors.organisationCategory ? "errorhighlight" : "input-select"}
                                                    getData={(val) => {
                                                        setIsFormDirty(true)
                                                        setOrganisationUserData((prevState) => ({
                                                            ...prevState,
                                                            organisationCategory: val.value,
                                                        }));
                                                        dispatch(resetFormModified(true));
                                                        isEditMode && setIconStatus({ ...iconStatus, organisationDetails: "inprogress" })
                                                        setIsDisabled(true)
                                                    }}
                                                    defaultValue={
                                                        [{
                                                            value: organisationUserData.organisationCategory !== "" ? organisationUserData.organisationCategory : "Select",
                                                            label: organisationUserData.organisationCategory !== "" ? organisationUserData.organisationCategory : "Select",
                                                        }]
                                                    }
                                                />
                                            </div>
                                            <div className='dropdown' key={uuidv4()} >
                                                <DropdownComponent
                                                    options={OrganisationSubDropdown}
                                                    title={"Sub Category"}
                                                    isDisabled={true}
                                                    width='300px'
                                                    getData={(val) => {
                                                        setIsFormDirty(true)
                                                        setOrganisationUserData((prevState) => ({
                                                            ...prevState,
                                                            subCategory: val.value,
                                                        }));
                                                        dispatch(resetFormModified(true));
                                                        isEditMode && setIconStatus({ ...iconStatus, organisationDetails: "inprogress" })
                                                        setIsDisabled(true)
                                                    }}
                                                    defaultValue={[
                                                        {
                                                            label: (organisationUserData.subCategory === "" || organisationUserData.subCategory === undefined || organisationUserData.subCategory === null) ? "Select" : organisationUserData.subCategory,
                                                            value: (organisationUserData.subCategory === "" || organisationUserData.subCategory === undefined || organisationUserData.subCategory === null) ? "" : organisationUserData.subCategory
                                                        }
                                                    ]}
                                                />
                                            </div>
                                        </div>
                                        <div className='form-input'>
                                            <InputComponent
                                                height={"40px"}
                                                width={"300px"}
                                                margin={"0px 60px 30px 0px"}
                                                padding={"0px 0px 0px 10px"}
                                                borderRadius={"0px"}
                                                backgroundColor={"white"}
                                                color={"black"}
                                                type={"text"}
                                                disabled={true}
                                                placeHolder='Enter PAN'
                                                inputTitle={"PAN"}
                                                required={true}
                                                maxLength={10}
                                                inputValue={organisationUserData.pan}
                                                border={
                                                    !organisationUserDataErrors.pan ?
                                                        "1px solid red" : "1px solid #A9C3DC"
                                                }
                                                getUser={(val: any) => {
                                                    let value = val.toUpperCase()
                                                    setIsFormDirty(true)
                                                    setOrganisationUserData((prevState) => ({
                                                        ...prevState,
                                                        pan: value,
                                                    }));
                                                    dispatch(resetFormModified(true));
                                                    isEditMode && setIconStatus({ ...iconStatus, organisationDetails: "inprogress" })
                                                    setIsDisabled(true)
                                                }}
                                            />
                                            <InputComponent
                                                height={"40px"}
                                                width={"350px"}
                                                margin={"0px 60px 30px 0px"}
                                                padding={"0px 0px 0px 10px"}
                                                borderRadius={"0px"}
                                                backgroundColor={"white"}
                                                color={"black"}
                                                disabled={true}
                                                type={"text"}
                                                placeHolder='Enter GSTIN'
                                                inputTitle={"GSTIN"}
                                                required={true}
                                                maxLength={15}
                                                inputValue={organisationUserData.gstin}
                                                border={
                                                    !organisationUserDataErrors.gstin ?
                                                        "1px solid red" : "1px solid #A9C3DC"
                                                }
                                                getUser={(val: any) => {
                                                    let value = val.toUpperCase()
                                                    setIsFormDirty(true)
                                                    setOrganisationUserData((prevState) => ({
                                                        ...prevState,
                                                        gstin: value,
                                                    }));
                                                    dispatch(resetFormModified(true));
                                                    isEditMode && setIconStatus({ ...iconStatus, organisationDetails: "inprogress" })
                                                    setIsDisabled(true)
                                                }}
                                            />
                                            <InputComponent
                                                height={"40px"}
                                                width={"350px"}
                                                margin={"0px 0px 30px 0px"}
                                                padding={"0px 0px 0px 10px"}
                                                borderRadius={"0px"}
                                                backgroundColor={"white"}
                                                disabled={true}
                                                color={"black"}
                                                type={"text"}
                                                placeHolder='Enter Head of location'
                                                inputTitle={"Head of location"}
                                                required={true}
                                                maxLength={25}
                                                inputValue={organisationUserData.location}
                                                border={
                                                    !organisationUserDataErrors.location ?
                                                        "1px solid red" : "1px solid #A9C3DC"
                                                }
                                                getUser={(value: any) => {
                                                    setIsFormDirty(true)
                                                    setOrganisationUserData((prevState) => ({
                                                        ...prevState,
                                                        location: value,
                                                    }));
                                                    dispatch(resetFormModified(true));
                                                    isEditMode && setIconStatus({ ...iconStatus, organisationDetails: "inprogress" })
                                                    setIsDisabled(true)
                                                }}
                                            />
                                        </div>
                                        <div style={{ display: "flex" }}>
                                            <div style={{ width: "100%" }}>
                                                <TextArea
                                                    name={"Address"}
                                                    placeHolder={"Enter Address"}
                                                    width={"95%"}
                                                    disabled={true}
                                                    // cursor={""}
                                                    height={"80px"}
                                                    margin={"0px 0px 30px 0px"}
                                                    padding={"5px 0px 0px 10px"}
                                                    maxLength={500}
                                                    inputValue={organisationUserData.address}
                                                    getUser={(val) => {
                                                        setIsFormDirty(true)
                                                        setOrganisationUserData((prevState) => ({
                                                            ...prevState,
                                                            address: val,
                                                        }));
                                                        dispatch(resetFormModified(true));
                                                        isEditMode && setIconStatus({ ...iconStatus, organisationDetails: "inprogress" })
                                                        setIsDisabled(true)
                                                    }}
                                                />
                                            </div>
                                            <div style={{ width: "100%" }}>
                                                <TextArea
                                                    name={"Description"}
                                                    placeHolder={"Enter Description"}
                                                    width={"100%"}
                                                    disabled={true}
                                                    height={"80px"}
                                                    margin={"0px 0px 30px 0px"}
                                                    padding={"5px 0px 0px 10px"}
                                                    maxLength={500}
                                                    // cursor={""}
                                                    inputValue={organisationUserData.description}
                                                    getUser={(val) => {
                                                        setIsFormDirty(true)
                                                        setOrganisationUserData((prevState) => ({
                                                            ...prevState,
                                                            description: val,
                                                        }));
                                                        dispatch(resetFormModified(true));
                                                        isEditMode && setIconStatus({ ...iconStatus, organisationDetails: "inprogress" })
                                                        setIsDisabled(true)
                                                    }}
                                                />
                                            </div>

                                        </div>
                                        <div className='radio-input flex-class'>
                                            <div className='isactive-width' >
                                                <div className=''>Is Active <span className='required'>*</span></div>
                                                <div className='radio-btn-main' style={
                                                    { pointerEvents: "none", opacity: 0.5, width: "100%" }}>
                                                    <RadioOrCheckbox
                                                        value={"Yes"}
                                                        type={"radio"}
                                                        name={"Yes"}
                                                        margin={"0px 30px 0px 0px"}
                                                        // disabled={!isEditMode}
                                                        checkedValue={organisationUserData.isActive}
                                                        getVal={(val) => {
                                                            setIsFormDirty(true)
                                                            setOrganisationUserData((prevState) => ({
                                                                ...prevState,
                                                                isActive: val,
                                                            }));
                                                            setOrganisationUserDataErrors((prevState) => ({
                                                                ...prevState,
                                                                isActive: true,
                                                            }));
                                                            dispatch(resetFormModified(true));
                                                            setIsDisabled(true)
                                                            isEditMode && setIconStatus({ ...iconStatus, organisationDetails: "inprogress" })
                                                        }}
                                                    />
                                                    {
                                                        <div style={{ pointerEvents: "none", opacity: 0.5 }}>

                                                            <RadioOrCheckbox
                                                                value={"No"}
                                                                type={"radio"}
                                                                name={"No"}
                                                                checkedValue={organisationUserData.isActive}
                                                                disabled={!isEditMode}
                                                                getVal={(val) => {
                                                                    setIsFormDirty(true)
                                                                    setOrganisationUserData((prevState) => ({
                                                                        ...prevState,
                                                                        isActive: val,
                                                                    }));
                                                                    dispatch(resetFormModified(true));
                                                                    isEditMode && setIconStatus({ ...iconStatus, organisationDetails: "inprogress" })
                                                                    setIsDisabled(true)
                                                                }}
                                                            />
                                                        </div>
                                                    }
                                                </div>
                                            </div>

                                            {organisationUserData.isActive === "No" &&
                                                <div style={{ width: "80%" }}>
                                                    <InputComponent
                                                        height={"40px"}
                                                        width={"75%"}
                                                        margin={"0px 0px 30px 0px"}
                                                        padding={"0px 0px 0px 10px"}

                                                        borderRadius={"0px"}
                                                        backgroundColor={"white"}
                                                        color={"black"}
                                                        type={"text"}
                                                        required={true}
                                                        placeHolder={"Reason"}
                                                        maxLength={250}
                                                        border={
                                                            organisationUserDataErrors.Remarks ?
                                                                "1px solid #A9C3DC" : "1px solid red"
                                                        }
                                                        inputTitle={"Remarks"}
                                                        disabled={organisationUserData.isActive === "No" ? false : true}
                                                        inputValue={organisationUserData.Remarks}
                                                        getUser={(value: any) => {
                                                            setIsFormDirty(true)
                                                            setOrganisationUserData((prevState) => ({
                                                                ...prevState,
                                                                Remarks: value,
                                                            }));
                                                            dispatch(resetFormModified(true));
                                                            isEditMode && setIconStatus({ ...iconStatus, organisationDetails: "inprogress" })
                                                            setIsDisabled(true)
                                                        }}
                                                    />
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>}
                            {/* Form Admin Details*/}
                            {
                                currentPage === "Admin Details" &&

                                <div className='admin-details-main-top'>
                                    <div className='center center-approval'>
                                        <p>Admin Details</p>
                                        {
                                            <div className='admin-details'>
                                                <div className='admin-details-main'
                                                //  style={
                                                //     Disbled
                                                //         ?
                                                //         { pointerEvents: "none", opacity: 0.5 } : { pointerEvents: "auto", opacity: 1 }
                                                // } 
                                                >
                                                    <InputComponent
                                                        height={"40px"}
                                                        width={"350px"}
                                                        margin={"0px 60px 30px 0px"}
                                                        padding={"0px 0px 0px 10px"}
                                                        borderRadius={"0px"}
                                                        backgroundColor={"white"}
                                                        color={"black"}
                                                        maxLength={100}
                                                        type={"text"}
                                                        inputTitle={"Name"}
                                                        required={true}
                                                        placeHolder={"Enter Name"}
                                                        inputValue={adminDetailsForm.name}
                                                        disabled={true}
                                                        border={
                                                            admin_details_errors.name ?
                                                                "1px solid #A9C3DC" : "1px solid red"
                                                        }
                                                        getUser={(detail) => {
                                                            setIsFormDirty(true)
                                                            setAdminDetailsForm({
                                                                ...adminDetailsForm,
                                                                name: detail
                                                            });
                                                            dispatch(resetFormModified(true));
                                                            isEditMode && setIconStatus({ ...iconStatus, adminDetails: "inprogress" })
                                                            setIsDisabled(true)
                                                        }}
                                                    />
                                                    <InputComponent
                                                        height={"40px"}
                                                        width={"350px"}
                                                        margin={"0px 60px 30px 0px"}
                                                        padding={"0px 0px 0px 10px"}
                                                        borderRadius={"0px"}
                                                        backgroundColor={"white"}
                                                        color={"black"}
                                                        type={"text"}
                                                        inputTitle={"Designation"}
                                                        required={true}
                                                        maxLength={100}
                                                        placeHolder={"Enter Designation"}
                                                        inputValue={adminDetailsForm.designation}
                                                        disabled={true}
                                                        border={
                                                            admin_details_errors.designation ?
                                                                "1px solid #A9C3DC" : "1px solid red"
                                                        }
                                                        getUser={(Designation) => {
                                                            setIsFormDirty(true)
                                                            setAdminDetailsForm({
                                                                ...adminDetailsForm,
                                                                designation: Designation
                                                            });
                                                            dispatch(resetFormModified(true));
                                                            isEditMode && setIconStatus({ ...iconStatus, adminDetails: "inprogress" })
                                                            setIsDisabled(true)
                                                        }}
                                                    />
                                                </div>
                                                <div className='admin-details-main'
                                                // style={
                                                //     adminButtonStatus.addButton === "disable" || adminDetailsForm.userId != ""
                                                //         ?
                                                //         { pointerEvents: "none", opacity: 0.5 } : { pointerEvents: "auto", opacity: 1 }
                                                // }
                                                >
                                                    <InputComponent
                                                        height={"40px"}
                                                        width={"350px"}
                                                        margin={"0px 60px 30px 0px"}
                                                        padding={"0px 0px 0px 70px"}
                                                        borderRadius={"0px"}
                                                        backgroundColor={"white"}
                                                        color={"black"}
                                                        type={"mobile"}
                                                        placeHolder={"Enter Contact Number"}
                                                        maxLength={10}
                                                        inputTitle={"Contact Number"}
                                                        required={true}
                                                        className={"mobilenumber-input"}
                                                        inputValue={adminDetailsForm.mobileNumber}
                                                        disabled={true}
                                                        border={
                                                            admin_details_errors.mobileNumber ?
                                                                "1px solid #A9C3DC" : "1px solid red"
                                                        }
                                                        getUser={(Number) => {
                                                            setIsFormDirty(true)
                                                            const value = Number.replace(/[^0-9]/g, "")
                                                            setAdminDetailsForm({
                                                                ...adminDetailsForm,
                                                                mobileNumber: value
                                                            });
                                                            dispatch(resetFormModified(true));
                                                            isEditMode && setIconStatus({ ...iconStatus, adminDetails: "inprogress" })
                                                            setIsDisabled(true)
                                                        }}
                                                    />
                                                    <InputComponent
                                                        height={"40px"}
                                                        width={"350px"}
                                                        margin={"0px 0px 45px 0px"}
                                                        padding={"0px 50px 0px 10px"}
                                                        borderRadius={"0px"}
                                                        backgroundColor={"white"}
                                                        color={"black"}
                                                        type={"text"}
                                                        placeHolder={"Enter Email ID"}
                                                        inputTitle={"Email ID"}
                                                        required={true}
                                                        maxLength={254}
                                                        className={"gmail-input"}
                                                        inputValue={adminDetailsForm.email}
                                                        disabled={true}
                                                        inputField={"gmail"}
                                                        border={
                                                            admin_details_errors.email ?
                                                                "1px solid #A9C3DC" : "1px solid red"
                                                        }
                                                        getUser={(val) => {
                                                            setIsFormDirty(true)
                                                            setAdminDetailsForm({
                                                                ...adminDetailsForm,
                                                                email: val
                                                            });
                                                            dispatch(resetFormModified(true));
                                                            isEditMode && setIconStatus({ ...iconStatus, adminDetails: "inprogress" })
                                                            setIsDisabled(true)
                                                        }}
                                                    />
                                                </div>

                                                <div className='isActive-flex' style={
                                                    Disbled ?
                                                        { pointerEvents: "none", opacity: 0.5 } : { pointerEvents: "auto", opacity: 1 }
                                                }  >
                                                    <div className='radio-input organisation-radio-input'>
                                                        <div className=''>Is Active<span className='required'>*</span></div>
                                                        <div className='radio-btn-flex' style={
                                                            { pointerEvents: "none", opacity: 0.5, width: "100%" }}>
                                                            <RadioOrCheckbox
                                                                value={"Yes"}
                                                                type={"radio"}
                                                                name={"Yes"}
                                                                // disabled={adminDetailsForm.userId == ""}
                                                                margin={"0px 30px 0px 0px"}
                                                                checkedValue={adminDetailsForm.userIsActive}
                                                                getVal={(val) => {

                                                                    setIsFormDirty(true)
                                                                    setAdminDetailsForm({
                                                                        ...adminDetailsForm,
                                                                        userIsActive: val,
                                                                        remark: ""
                                                                    });
                                                                    dispatch(resetFormModified(true));
                                                                    isEditMode && setIconStatus({ ...iconStatus, adminDetails: "inprogress" })
                                                                }}
                                                            />
                                                            {
                                                                <div
                                                                    style={
                                                                        { pointerEvents: "none", opacity: 0.5, width: "100%" }}
                                                                >

                                                                    <RadioOrCheckbox
                                                                        value={"No"}
                                                                        type={"radio"}
                                                                        name={"No"}
                                                                        disabled={adminDetailsForm.userId == "" || Disbled}
                                                                        checkedValue={adminDetailsForm.userIsActive}
                                                                        getVal={(val) => {
                                                                            setIsFormDirty(true)
                                                                            setAdminDetailsForm({
                                                                                ...adminDetailsForm,
                                                                                userIsActive: val
                                                                            });
                                                                            dispatch(resetFormModified(true));
                                                                            isEditMode && setIconStatus({ ...iconStatus, adminDetails: "inprogress" })
                                                                            setIsDisabled(true)
                                                                        }}
                                                                    />
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                    {
                                                        adminDetailsForm.userIsActive === "No" &&
                                                        <div className='input-btn' style={{ width: "100%" }}>

                                                            <div style={
                                                                Disbled ?
                                                                    { pointerEvents: "none", opacity: 0.5, width: "100%" }
                                                                    :
                                                                    { pointerEvents: "auto", opacity: 1, width: "100%" }
                                                            }
                                                            >
                                                                <InputComponent
                                                                    height={"40px"}
                                                                    width={"70%"}
                                                                    margin={"0px 0px 30px 0px"}
                                                                    padding={"0px 0px 0px 10px"}
                                                                    borderRadius={"0px"}
                                                                    backgroundColor={"white"}
                                                                    color={"black"}
                                                                    type={"text"}
                                                                    inputTitle={"Remarks"}
                                                                    disabled={Disbled ? true : false}
                                                                    required={true}
                                                                    border={
                                                                        admin_details_errors.remark ?
                                                                            "1px solid #A9C3DC" : "1px solid red"}
                                                                    placeHolder={"Reason"}
                                                                    inputValue={adminDetailsForm.remark}
                                                                    points={""}
                                                                    maxLength={250}
                                                                    getUser={(value) => {
                                                                        setIsFormDirty(true)
                                                                        setAdminDetailsForm({
                                                                            ...adminDetailsForm,
                                                                            remark: value
                                                                        });
                                                                        dispatch(resetFormModified(true));
                                                                        isEditMode && setIconStatus({ ...iconStatus, adminDetails: "inprogress" })
                                                                        setIsDisabled(true)
                                                                    }}
                                                                />
                                                            </div>
                                                            <div />
                                                        </div>
                                                    }

                                                </div>

                                                <div>
                                                    <ApprovalOrganisationTable
                                                        retriveData={retriveData}
                                                        disabled={adminButtonStatus.addButton === "disable"}
                                                        tableData={admin_details}
                                                        checkDelete={adminDataDelete}
                                                    />
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            }
                            {/* Form Approval*/}
                            {
                                currentPage === "Approval" &&
                                <div className='center'>
                                    <p>Status</p>
                                    <div className='radio-input flex-class'>
                                        <div className='isactive-width' >
                                            <div className='' style={{ pointerEvents: "none", opacity: 0.5 }}>Is Active <span className='required'>*</span></div>
                                            <div className='radio-btn-main' style={{ pointerEvents: "none", opacity: 0.5, width: "100%" }}>
                                                <RadioOrCheckbox
                                                    value={"Yes"}
                                                    type={"radio"}
                                                    name={"Yes"}
                                                    margin={"0px 30px 0px 0px"}
                                                    // disabled={!isEditMode}
                                                    checkedValue={organisationUserData.isActive}
                                                    getVal={(val) => {
                                                        setIsFormDirty(true)
                                                        setOrganisationUserData((prevState) => ({
                                                            ...prevState,
                                                            isActive: val,
                                                        }));
                                                        setOrganisationUserDataErrors((prevState) => ({
                                                            ...prevState,
                                                            isActive: true,
                                                        }));
                                                        dispatch(resetFormModified(true));
                                                        setIsDisabled(true)
                                                        isEditMode && setIconStatus({ ...iconStatus, organisationDetails: "inprogress" })
                                                    }}
                                                />
                                                {
                                                    <div
                                                    // style={organisationUserData.isActive === "No" ? { pointerEvents: "auto", opacity: 1 } : isEditMode ? { pointerEvents: "auto", opacity: 1 } : { pointerEvents: "none", opacity: 0.5 }}
                                                    >
                                                        <RadioOrCheckbox
                                                            value={"No"}
                                                            type={"radio"}
                                                            name={"No"}
                                                            checkedValue={organisationUserData.isActive}
                                                            disabled={false}
                                                            getVal={(val) => {
                                                                setIsFormDirty(true)
                                                                setOrganisationUserData((prevState) => ({
                                                                    ...prevState,
                                                                    isActive: val,
                                                                }));
                                                                dispatch(resetFormModified(true));
                                                                isEditMode && setIconStatus({ ...iconStatus, organisationDetails: "inprogress" })
                                                                setIsDisabled(true)
                                                            }}
                                                        />
                                                    </div>
                                                }
                                            </div>
                                        </div>

                                        {/* {organisationUserData.isActive === "No" &&
                                            <div style={{ width: "80%" }}>
                                                <InputComponent
                                                    height={"40px"}
                                                    width={"75%"}
                                                    margin={"0px 0px 30px 0px"}
                                                    padding={"0px 0px 0px 10px"}

                                                    borderRadius={"0px"}
                                                    backgroundColor={"white"}
                                                    color={"black"}
                                                    type={"text"}
                                                    required={true}
                                                    placeHolder={"Reason"}
                                                    maxLength={250}
                                                    border={
                                                        organisationUserDataErrors.Remarks ?
                                                            "1px solid #A9C3DC" : "1px solid red"
                                                    }
                                                    inputTitle={"Remarks"}
                                                    disabled={organisationUserData.isActive === "No" ? false : true}
                                                    inputValue={organisationUserData.Remarks}
                                                    getUser={(value: any) => {
                                                        setIsFormDirty(true)
                                                        setOrganisationUserData((prevState) => ({
                                                            ...prevState,
                                                            Remarks: value,
                                                        }));
                                                        dispatch(resetFormModified(true));
                                                        isEditMode && setIconStatus({ ...iconStatus, organisationDetails: "inprogress" })
                                                        setIsDisabled(true)
                                                    }}
                                                />
                                            </div>
                                        } */}
                                    </div>

                                    <div style={(organisationUserData.isActive === "Yes") ? { pointerEvents: "none", opacity: "0.5" } : {}} className='approval-remark-input'>
                                        <InputComponent
                                            height={"40px"}
                                            width={"100%"}
                                            margin={"0px 60px 30px 0px"}
                                            padding={"0px 0px 0px 10px"}
                                            borderRadius={"0px"}
                                            backgroundColor={"white"}
                                            color={"black"}
                                            type={"text"}
                                            // disabled={organisationUserData.isActive === "No" ? false : true}
                                            placeHolder='Reason'
                                            inputTitle={"Remark"}
                                            required={true}
                                            // autoFocus
                                            maxLength={25}
                                            inputValue={organisationUserData.approvalRemarks}
                                            border={
                                                !organisationUserDataErrors.organisationId ?
                                                    "1px solid red" : "1px solid #A9C3DC"
                                            }
                                            getUser={(value: any) => {
                                                setIsFormDirty(true)
                                                setOrganisationUserData((prevState) => ({
                                                    ...prevState,
                                                    approvalRemarks: value,
                                                }));
                                                // dispatch(resetFormModified(true));
                                                // isEditMode && setIconStatus({ ...iconStatus, organisationDetails: "inprogress" })
                                                // setIsDisabled(true)
                                            }}
                                        />
                                    </div>
                                    <p>Approval</p>
                                    <div className='radio-input flex-class'>
                                        <div className='isactive-width' >
                                            <div className='required-approval-text'>This new required your approval.</div>
                                            <div className='radio-btn-main'>
                                                <RadioOrCheckbox
                                                    value={"Approve"}
                                                    type={"radio"}
                                                    name={"Approve"}
                                                    margin={"0px 30px 0px 0px"}
                                                    // disabled={!isEditMode}
                                                    checkedValue={organisationUserData.internalApprovalStatus}
                                                    getVal={(val) => {
                                                        console.log("val", val)
                                                        setIsFormDirty(true)
                                                        setOrganisationUserData((prevState) => ({
                                                            ...prevState,
                                                            internalApprovalStatus: val,
                                                        }));
                                                        setOrganisationUserDataErrors((prevState) => ({
                                                            ...prevState,
                                                            isActive: true,
                                                        }));
                                                        dispatch(resetFormModified(true));
                                                        setIsDisabled(true)
                                                        isEditMode && setIconStatus({ ...iconStatus, organisationDetails: "inprogress" })
                                                    }}
                                                />
                                                {
                                                    <div
                                                    // style={organisationUserData.isActive === "No" ? { pointerEvents: "auto", opacity: 1 } : isEditMode ? { pointerEvents: "auto", opacity: 1 } : { pointerEvents: "none", opacity: 0.5 }}
                                                    >
                                                        <RadioOrCheckbox
                                                            value={"Decline"}
                                                            type={"radio"}
                                                            name={"Decline"}
                                                            checkedValue={organisationUserData.internalApprovalStatus}
                                                            disabled={false}
                                                            getVal={(val) => {
                                                                setIsFormDirty(true)
                                                                setOrganisationUserData((prevState) => ({
                                                                    ...prevState,
                                                                    internalApprovalStatus: val,
                                                                }));
                                                                dispatch(resetFormModified(true));
                                                                isEditMode && setIconStatus({ ...iconStatus, organisationDetails: "inprogress" })
                                                                setIsDisabled(true)
                                                            }}
                                                        />
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>

                                    <div className={`approval-text-area ${organisationUserData.internalApprovalStatus === "Decline" ? "approval-text-area-decline" : ""}`} style={(organisationUserData.internalApprovalStatus == "Approve" || organisationUserData.internalApprovalStatus == "") ? { opacity: "0.5", pointerEvents: "none" } : {}}>
                                        <TextArea
                                            name={"Reason for declining Request"}
                                            placeHolder={"Reason"}
                                            required={true}
                                            width={"100%"}
                                            height={"80px"}
                                            // disabled={organisationUserData.approvalRedio === "Decline" ? false : true}
                                            margin={"0px 0px 30px 0px"}
                                            padding={"5px 0px 0px 10px"}
                                            maxLength={500}
                                            // cursor={!organisationUserData.declineRedio ? "not-allowed" : ""}
                                            inputValue={organisationUserData.internalApprovalStatusRemarks}
                                            getUser={(val) => {
                                                setIsFormDirty(true)
                                                setOrganisationUserData((prevState) => ({
                                                    ...prevState,
                                                    internalApprovalStatusRemarks: val,
                                                }));
                                                // dispatch(resetFormModified(true));
                                                // isEditMode && setIconStatus({ ...iconStatus, organisationDetails: "inprogress" })
                                                // setIsDisabled(true)
                                            }}
                                        />
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>

                <div className='footer'>
                    <ButtonComponent
                        title={"Cancel"}
                        height={"50px"}
                        width={"150px"}
                        margin={"0px 8px"}
                        backgroundColor={"#888888"}
                        color={"white"}
                        className={"button-component-hover cancel"}
                        handleClick={returnGird}
                    />
                    {(!isEditMode && (currentPage === "Admin Details" || currentPage === "Approval")) &&
                        < ButtonComponent
                            title={"Previous"}
                            height={"50px"}
                            width={"150px"}
                            margin={"0px 8px"}
                            backgroundColor={"#888888"}
                            color={"white"}
                            className={"button-component-hover cancel"}
                            handleClick={cancel}
                        />
                    }
                    <ButtonComponent
                        title={currentPage === "Admin Details" ? "Next" :
                            currentPage === "Organisation Details" ? "Next" : isEditMode ? "Update" : "Save"}
                        height={"50px"}
                        width={"150px"}
                        backgroundColor={"#0055D4"}
                        disabled={
                            // showMessage && showType === "success" || !isDisabled
                            organisationUserData.isActive === "No" || organisationUserData.internalApprovalStatus === "Decline" || organisationUserData.internalApprovalStatus === "Approve" ? false : currentPage === "Admin Details" || currentPage === "Organisation Details" ? false : true
                        }
                        color={"white"}
                        margin={"0px 8px"}
                        className={
                            organisationUserData.isActive === "No" || organisationUserData.internalApprovalStatus === "Decline" || organisationUserData.internalApprovalStatus === "Approve" || currentPage === "Admin Details" || currentPage === "Organisation Details" ? "button-component-hover common-btn" : "button-component-hover disabled"
                            // !isDisabled || showMessage && showType === "success" 
                            // ? 
                            // "button-component-hover disabled"
                            // : 
                            // "button-component-hover common-btn"
                        }
                        handleClick={save}
                    />
                </div>
                {
                    showAlertBox &&
                    <div className='alert-warp'>
                        <AlertBox type={showType} message={showMessage} />
                    </div>
                }
            </MainPageCard >
            {
                showResetModel &&
                <div >
                    <DeleteModal
                        clearValue={(value) => { setShowResetModel(value) }}
                        getDelete={adminResetPassword}
                        modelType={"Remark"}
                        resetPassword={resetPassword}
                        setResetPassword={(val: any) => {
                            setResetPassword(val)
                        }}
                    />
                </div>
            }
            {
                isLoading &&
                <Loading />
            }
            {
                showChangesModal &&
                <Changesmodal
                    closeModal={() => setShowChangesModal(false)}
                    leavePage={() => {
                        setShowChangesModal(false);
                        setIsFormDirty(false)
                        organisationFun("grid")
                        getEditMode(false)
                    }}
                    handleClose={() => setShowChangesModal(false)} />
            }
        </>
    )
}

export default Form;