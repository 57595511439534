import React, { useState } from 'react';
import { BsArrowRight } from 'react-icons/bs';
import { RxCross1 } from 'react-icons/rx';
import '../../../styles/modals/delete-modal.scss';
import ButtonComponent from '../form-elements/button-component';
import InputComponent from '../form-elements/input-component';


interface Styles {
    clearValue: (val: any) => void;
    getDelete: () => void;
    modelType?: string;
    setResetPassword?: (val: any) => void;
    resetPassword?: string;
}

export default function DeleteModal({
    clearValue, getDelete, modelType, setResetPassword, resetPassword
}: Styles) {
    const [storeRemarks, setStoreRemarks] = useState<string>("") // Remarks 

    return (
        <div className={"delete-modal"}>
            {
                modelType === "grid-delete" &&
                <div className={"container"}>
                    <div className={"close-icon"}>
                        <span className={"cross-icon"}>
                            <RxCross1 onClick={() => clearValue(false)
                            } style={{ fontSize: "40px" }} />
                        </span>
                    </div>
                    <div className={"title-head"}>{"Are you sure?"}</div>
                    <div className={"content"}>{"Do you want to delete the selected record(s)? This process cannot be undone."}</div>
                    <div className={"buttons"}>
                        <ButtonComponent
                            title={"Cancel"}
                            height={"50px"}
                            width={"150px"}
                            backgroundColor={"#888888"}
                            color={"white"}
                            margin={"0px"}
                            className={"button-component-hover cancel"}
                            handleClick={() => clearValue(false)}
                        />
                        <ButtonComponent
                            title={"Delete"}
                            height={"50px"}
                            width={"150px"}
                            backgroundColor={"red"}
                            color={"white"}
                            margin={"0px"}
                            className={"button-component-hover cancel"}
                            handleClick={getDelete}
                        />
                    </div>
                </div>

            }
            {
                modelType === "grid-remark" &&
                <div className={"container"}>
                    <div className={"close-icon"}>
                        <span className={"cross-icon"}>
                            <RxCross1 onClick={() => clearValue(false)
                            } style={{ fontSize: "40px" }} />
                        </span>
                    </div>
                    <div className={"title-head"}>{"Are you sure?"}</div>
                    <div className={"content"}>{"A password reset link will be sent to user's registered email address."}</div>

                    <InputComponent
                        border={'1px solid #A9C3DC'}
                        height={"40px"}
                        width={"300px"}
                        margin={"0px 0px 30px 0px"}
                        padding={"0px 0px 0px 10px"}
                        borderRadius={"0px"}
                        backgroundColor={"white"}
                        color={"black"}
                        type={"text"}
                        inputTitle={"Remarks"}
                        required={true}
                        placeHolder={"Reason"}
                        inputValue={storeRemarks}
                        points={""}
                        maxLength={150}
                        getUser={(value: any) => {
                            setStoreRemarks(value)
                        }}
                    />
                    <div className={"buttons"}>
                        <ButtonComponent
                            title={"Cancel"}
                            height={"50px"}
                            width={"150px"}
                            backgroundColor={"#888888"}
                            color={"white"}
                            margin={"0px"}
                            className={"button-component-hover cancel"}
                            handleClick={() => clearValue(false)}
                        />
                        <ButtonComponent
                            title={"Proceed"}
                            height={"50px"}
                            width={"150px"}
                            backgroundColor={"#0055D4"}
                            color={"white"}
                            margin={"0px"}
                            disabled={(storeRemarks !== "" && RegExp(/^[^\s].*/).test(storeRemarks)) ? false : true}
                            className={storeRemarks !== "" && RegExp(/^[^\s].*/).test(storeRemarks) ? "button-component-hover common-btn" : "button-component disabled"}
                            handleClick={() => {
                                setResetPassword && setResetPassword(storeRemarks);
                            }}
                        />
                    </div> 
                </div>
            }
           
           { modelType === "profile-image" &&
                <div className={"container"}>
                    <div className={"close-icon"}>
                        <span className={"cross-icon"}>
                            <RxCross1 onClick={() => clearValue(false)
                            } style={{ fontSize: "40px" }} />
                        </span>
                    </div>
                    <div className={"title-head"}>{"Are you sure?"}</div>
                    <div className={"content"}>{"Do you want to delete your profile image? This process cannot be undone."}</div>
                    <div className={"buttons"}>
                        <ButtonComponent
                            title={"Cancel"}
                            height={"50px"}
                            width={"150px"}
                            backgroundColor={"#888888"}
                            color={"white"}
                            margin={"0px"}
                            className={"button-component-hover cancel"}
                            handleClick={() => clearValue(false)}
                        />
                        <ButtonComponent
                            title={"Delete"}
                            height={"50px"}
                            width={"150px"}
                            backgroundColor={"red"}
                            color={"white"}
                            margin={"0px"}
                            className={"button-component-hover cancel"}
                            handleClick={getDelete}
                        />
                    </div>
                </div>

            }
        </div>
    )
}



