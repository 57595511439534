
import React, { useEffect, useRef, useState } from 'react'
import IconButton from '../../common-component/form-elements/icon-button';
import MainPageCard from '../../common-component/cards/main-page-card';
import NavigateHeader from '../../common-component/navigate-header';
import '../../../styles/gst/gst-grid.scss'
import { v4 as uuidv4 } from 'uuid'
import { gstColumnData } from '../../../config/gst/gst';
import AgGrid, { AgGridRef } from '../../common-component/grids-and-tables/ag-grid'
import GridFilter from '../../common-component/grids-and-tables/grid-filter';
import { Filter } from '../../../helpers/filtterFunction'
import CustomEllipsisRenderer from '../../../config/product-admin/users/users-aggrid-column-config';
import { DateConverterAgGrid } from "../../../helpers/capitalizetext";
import cellEyeIcon from "../../../components/common-component/grids-and-tables/cell-eye-icon";
import GstViewChallanModal from '../../common-component/modals/gst-view-challan-modal';
import { axiosPrivate } from '../../../middleware/axois-api';
import ButtonComponent from '../../common-component/form-elements/button-component';
import AlertBox from '../../common-component/alert-box';
import Loading from '../../common-component/modals/loading-screen';

interface Props {
    gstView: (val: any) => void;
    addMode: () => void;
    getEditData: (nav: any, data: any) => void;
    transaction: (val: any) => void;
    makePaymentDataFun: (val: any) => void;
}
interface IFilterValue {
    [key: string]: {
        columnName: string;
        filterType: string;
        filterWord: string;
    };
}
function GstApprovalGrid({ gstView, transaction, makePaymentDataFun }: Props) {


    const companyDownloadRef = useRef<AgGridRef>(null);
    const [filter, setFilter] = useState(false);
    const [filterRestore, setFilterRestore] = useState<IFilterValue>({})
    const [filterMethod, setFilterMethod] = useState("")
    const [curruntData, setCurruntData] = useState<any>([]);
    const [rowData, setRowData] = useState<any>([]);
    const [orginalRowData, setOrginalRowData] = useState<any>([]);
    const [rowExpandDataExpand, setRowExpandDataExpand] = useState<any>([]);
    const [gstEyeShowModal, setGstEyeShowModal] = useState<boolean>(false);
    const [totalAmountData, setTotalAmountData] = useState('');
    const [mainChallanDetails, setMainChallanDetails] = useState([]);
    const [approvalStatus, setApprovalStatus] = useState([]);
    const [mainData, setMainData] = useState('');
    const [currentData, setCurrentData] = useState([]);

    const [showType, setShowType] = useState("warning") // error message showType
    const [showMessage, setShowMessage] = useState<string>("") // error message showMessage
    const [showAlertBox, setShowAlertBox] = useState(false) // error message ShowAlertBox
    const [loadingScreen, setLoadingScreen] = useState(false);


    //functions

    const handleFilter = (value: any, filterType: string) => {
        Filter(value, filterType, orginalRowData, setRowData)
    }
    const calculateTotal = () => {
        let sum = 0;
        rowExpandDataExpand.forEach((item: any) => {
            const total = parseFloat(item?.Total.replace(/,/g, ''));
            sum += total;
        });
        return sum.toFixed(2); // Round to two decimal places if needed
    };

    const formatAmountWithCommas = (amount: any) => {
        return parseFloat(amount).toLocaleString('en-US', {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
    };
    const handleDownloadExcel = () => {
        companyDownloadRef.current!.onBtExport();
    }
    //useEffects

    useEffect(() => {
        const total = calculateTotal();
        const formattedTotal = formatAmountWithCommas(total);
        setTotalAmountData(formattedTotal)
    }, [rowExpandDataExpand]);

    useEffect(() => {
        getGstDataMainGrid()
    }, []);

    // grid data

    const gstExpandColumnData = [

        {
            field: 'GSTIN', headerName: 'GSTIN', suppressCellSelection: false,
            minWidth: 220, maxWidth: 220, resizable: false, filter: false, suppressMovable: true, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "number", cellRendererFramework: CustomEllipsisRenderer,
        },
        {
            field: 'CPIN', headerName: 'CPIN', minWidth: 220, maxWidth: 220, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "number", cellRendererFramework: CustomEllipsisRenderer,
        },
        {
            field: 'Total', headerName: 'Total(₹)', headerClass: 'header-right-align', minWidth: 180, maxWidth: 180, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'right' }, filtertype: "number", cellRendererFramework: CustomEllipsisRenderer,
        },
        {
            field: 'CreatedDate', headerName: 'Created Date', minWidth: 180, maxWidth: 180, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "number",
        },
        {
            field: 'ExpiryDate', headerName: 'Expiry Date', minWidth: 180, maxWidth: 180, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "number",
        },
        {
            field: 'ApprovalStatus', headerName: 'Approval Status', minWidth: 180, maxWidth: 180, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, filtertype: "text", cellStyle: { textAlign: 'left' }, cellClassRules: {
                'paid-cell': (params: any) => params.value === 'Approved',
                'expired-cell': (params: any) => params.value === 'Declined',
                'pending-approval-cell': (params: any) => params.value === 'Pending Approval',
                // 'unpaid-cell': (params: any) => params.value === 'Declined',
            }, cellRendererFramework: CustomEllipsisRenderer,
        },
        {
            field: 'ViewChallan',
            headerName: 'View Challan',
            minWidth: 150,
            resizable: false,
            headerClass: "header-center-align",
            suppressMovable: true,
            filter: false,
            suppressSizeToFit: false,
            cellStyle: { textAlign: 'center', color: "#0055d5" },
            filtertype: "text",
            cellRendererFramework: cellEyeIcon,
            cellRendererParams: (params: any) => {
                const handleIconClick = async () => {
                    setGstEyeShowModal(!gstEyeShowModal);
                    try {
                        const response = await axiosPrivate.post(`/gst-payable/get-gst-challan-datas`, {
                            payableGstDetailsId: params.data.payableGstDetailsId,
                            payableGstId: params.data.payableGstId
                        });
                        setMainChallanDetails(response.data.gstChallanDetails)
                        setApprovalStatus(response.data.approvalStatusDetails)
                        setMainData('main')
                    } catch (error) {
                        console.log(error)
                    }
                };


                return {
                    handleIconClick: handleIconClick,
                    showIcon: true
                };
            }
        }
    ]

    //apis


    async function getGstDataMainGrid() {
        await axiosPrivate.get(`/gst-payable/get-gst-data`)
            .then((response: any) => {
                setRowData(response.data.gstData)
                setOrginalRowData(response.data.gstData)
            })
            .catch(error => {
                console.error(error);
            });
    }

    async function getTableId(val: any) {
        await axiosPrivate.get(`/gst-payable/get-gst-expand-data/${val.payableGstId}`)
            .then((response: any) => {
                setRowExpandDataExpand(response.data.gstExpandDetails)
            })
            .catch(error => {
                console.error(error);
            });
    }

    const clearAlert = (status: any) => {
        const timer = setTimeout(() => {
            setShowAlertBox(false);
            setShowMessage("");
            clearTimeout(timer);
        }, 5000);
    }

    async function makePayment() {
        setLoadingScreen(true)
        const isPaidChallan = currentData.some((item: any) => item.PaymentStatus === 'Paid');
        if (isPaidChallan) {
            setShowAlertBox(true)
            setLoadingScreen(false)
            setShowType('danger')
            setShowMessage("Selected Challan has already been paid.");
            clearAlert('');
        } else {
            const data = {
                makePaymentChallanIds: currentData.map((val: any) => {
                    return (val.payableGstId).toString();
                })
            };
            // setMakePaymentData(data)
            makePaymentDataFun(data)
            await axiosPrivate.post(`gst-payable/get-makepayment-challan-datas`, data)
                .then((response: any) => {
                    setLoadingScreen(false)
                    transaction(response.data.MakePaymentDetails)
                    gstView("form");
                })
                .catch(error => {
                    setLoadingScreen(false)
                    setShowType('danger')
                    setShowMessage(`Error : ${error}`);
                    clearAlert('');
                    console.error(error);
                });
        }

    }

    return (
        <MainPageCard>
            <div className='header-grid-gst'>
                <div className='left-header'>
                    <NavigateHeader firstValue={"Payable"}
                        secondValue={"GST"} />
                </div>
                <IconButton
                    iconName={"Filter"}
                    height={"40px"}
                    width={"40px"}
                    fontSize={"30px"}
                    margin={"0px 8px"}
                    color={"white"}
                    backgroundColor={"#0055D4"}
                    hover={true}
                    handleClick={(event: React.MouseEvent) => {
                        event?.stopPropagation()
                        setFilter(!filter);
                    }}
                />
                <IconButton
                    iconName={"Delete"}
                    height={"40px"}
                    width={"40px"}
                    fontSize={"25px"}
                    margin={"0px 8px"}
                    color={"white"}
                    disabled={true}
                    opacity={"0.5"}
                    cursor={"not-allowed"}
                    backgroundColor={"#D60000"}
                    hover={curruntData.length > 0}
                    handleClick={() => {
                    }}
                />
                <IconButton
                    iconName={"Add"}
                    height={"40px"}
                    width={"75px"}
                    fontSize={"25px"}
                    margin={"0px 8px"}
                    color={"white"}
                    backgroundColor={"#0055D4"}
                    hover={false}
                    disabled={true}
                    opacity={"0.5"}
                    cursor={"not-allowed"}
                    handleClick={() => {
                        // gstView("form");
                        // addMode();
                        // handleTransactionGenerate()
                    }}
                />
                <IconButton
                    iconName={"Upload"}
                    height={"40px"}
                    width={"40px"}
                    fontSize={"25px"}
                    margin={"0px 0px 0px 8px"}
                    color={"white"}
                    backgroundColor={"#0055D4"}
                    hover={false}
                    disabled={true}
                    opacity={"0.5"}
                    cursor={"not-allowed"}
                    handleClick={() => {
                    }}
                />
                <IconButton
                    iconName={"Download"}
                    height={"40px"}
                    width={"40px"}
                    fontSize={"25px"}
                    margin={"0px 8px"}
                    color={"white"}
                    backgroundColor={"#0055D4"}
                    hover={true}
                    handleClick={() => {
                        handleDownloadExcel()
                    }}
                />
            </div>
            <div className='gst-group-grid'>
                <AgGrid
                    tabelRowData={rowData}
                    tableColumnData={gstColumnData}
                    ref={companyDownloadRef}
                    fileName={"GSTApprovalData"}
                    getCurruntData={(val) => {
                        setCurrentData(val);
                    }}
                    doubleClickUpdate={() => {
                    }}
                    getEdit={(val: any) => {
                        getTableId(val)
                    }}
                />
            </div>
            {
                rowExpandDataExpand && rowExpandDataExpand.length > 0 ?
                    <div className='gst-individual-grid'>
                        <div className='challan-area'>
                            <div className='challan-text'>Total Challan: {rowExpandDataExpand ? rowExpandDataExpand.length : 0}</div>
                            <div className='total-text'>Total Amount: ₹{totalAmountData}</div>
                        </div>
                        <div className='gst-grid-two'>
                            <AgGrid
                                fileName={"GST Challan"}
                                tabelRowData={rowExpandDataExpand}
                                tableColumnData={gstExpandColumnData}
                                hidePaginationDD={true}
                                doubleClickUpdate={() => {
                                }}
                            />
                        </div>
                    </div>
                    :
                    ""
            }
            <div className='footer-gst-makepayment'>
                <div style={currentData && currentData.length > 0 ? {} : { cursor: "not-Allowed" }}>
                    <ButtonComponent
                        title={"Make Payment"}
                        height={"50px"}
                        width={"150px"}
                        margin={"0px 8px"}
                        backgroundColor={"#0055D4"}
                        color={"white"}
                        disabled={currentData && currentData.length > 0 ? false : true}
                        className={currentData && currentData.length > 0 ? "button-component-hover common-btn" : "button-component-hover disabled"}
                        handleClick={() => {
                            makePayment()

                        }}
                    />
                </div>


            </div>

            {
                filter &&
                <div className='filter' key={uuidv4()}>
                    <GridFilter
                        filterRestore={filterRestore}
                        filterMethod={filterMethod}
                        tabelRowData={rowData}
                        data={gstColumnData.slice(1)}
                        handleClick={(Value: any, filterType: string) => {
                            if (filterType !== "") {
                                setFilterRestore(Value)
                                setFilter(!filter)
                                setFilterMethod(filterType)
                                handleFilter(Value, filterType)
                                setCurruntData([]);

                            } else {
                                setFilterMethod("")
                                setFilterRestore({})
                                setFilter(!filter)
                                setRowData(orginalRowData)
                                setCurruntData([]);
                            }
                        }}
                    />
                </div>
            }
            {
                showAlertBox &&
                <div className='alert-warp'>
                    <AlertBox type={showType} message={showMessage} />
                </div>

            }
            {
                loadingScreen &&
                <div className='alert-warp'>
                    <Loading />
                </div>

            }
            {
                gstEyeShowModal &&
                <div className='alert-warp'>
                    <GstViewChallanModal
                        clearValue={() => {
                            setGstEyeShowModal(false)
                        }}
                        mainChallanDetails={mainChallanDetails}
                        mainData={mainData}
                        approvalStatus={approvalStatus}

                    />
                </div>
            }
        </MainPageCard >
    )
}

export default GstApprovalGrid