import React, { useEffect, useRef, useState } from 'react'
import ButtonComponent from '../../../common-component/form-elements/button-component'
import IconButton from '../../../common-component/form-elements/icon-button'

import Chart from 'chart.js/auto';
import { ReactComponent as Vendor_Kyc } from '../../../../assets/icons/vendor_kyc_doc.svg'
import DocumentModal from '../../../common-component/modals/document-modal';
import { useDispatch, useSelector } from 'react-redux';
import Changesmodal from '../../../common-component/modals/changes-modal';
import { resetFormModified } from '../../../../redux/action';
import { axiosPrivate } from '../../../../middleware/axois-api';
import FileUpload from '../../../common-component/file-upload';
import Loading from '../../../common-component/modals/loading-screen';

interface Props {
    setShowPage: any
    vendorDetails: any
    setVendorDetails: any
    stepperStatus: any;
    setStepperStatus: any;
    saveVendorDatas: (val: any) => void;
    onboardingViewfun: (val: any) => void;
    isEditMode: any
}
export default function KycDocuments({ setShowPage, vendorDetails, setVendorDetails, stepperStatus,
    setStepperStatus, onboardingViewfun, isEditMode }: Props) {
    const chartRef: any = useRef(null);
    const isFormModified = useSelector((state: any) => state.isFormModified);
    const [showChangesModal, setShowChangesModal] = useState<boolean>(false);
    const [showDocumentModal, setShowDocumentModal] = useState(false)
    const [currentDoc, setCurrentDoc] = useState<any>()
    const [kycDocPercentage, setKycDocPercentage] = useState<any>(0)
    const [saveBtnDisabledStatus, setSaveBtnDisabledStatus] = useState<any>(false)
    const [overAllPercentageCount, setOverAllPercentageCount] = useState<any>(0)
    const [showType, setShowType] = useState("warning") // error message showType
    const [showMessage, setShowMessage] = useState<string>("") // error message showMessage
    const [showAlertBox, setShowAlertBox] = useState(false)
    const [loadingScreen, setLoadingScreen] = useState(false)
    const dispatch = useDispatch();
    function returnGird() {
        onboardingViewfun("grid")
        dispatch(resetFormModified(false));
    }

    const [documents, setDocuments] = useState<any>({
        'Proprietorship': [
            // { "documentType": 'Owner PAN/Signtory PAN', "fileName": "", "file": "", "razorpayKycDocId": '' },
            // { "documentType": "Aadhar/Passport/Voter's ID", "fileName": "", "file": "", "razorpayKycDocId": '' },
            { "documentType": "GST", "fileName": "", "file": "", "razorpayKycDocId": '' },
            { "documentType": "MSME", "fileName": "", "file": "", "razorpayKycDocId": '' },
            { "documentType": "Shops Establishment", "fileName": "", "file": "", "razorpayKycDocId": '' },
        ],
        'Partnership': [
            { "documentType": 'Business PAN', "fileName": "", "file": "", "razorpayKycDocId": '' },
            { "documentType": "Aadhar/Passport/Voter's ID", "fileName": "", "file": "", "razorpayKycDocId": '' },
            { "documentType": "Partnershipd Deed", "fileName": "", "file": "", "razorpayKycDocId": '' },
        ],
        'Private_limited': [
            { "documentType": 'Business PAN', "fileName": "", "file": "", "razorpayKycDocId": '' },
            { "documentType": "Aadhar/Passport/Voter's ID", "fileName": "", "file": "", "razorpayKycDocId": '' },
            { "documentType": "Certificate of Incorporation", "fileName": "", "file": "", "razorpayKycDocId": '' },
        ],
        'Public_limited': [
            { "documentType": 'Business PAN', "fileName": "", "file": "", "razorpayKycDocId": '' },
            { "documentType": "Aadhar/Passport/Voter's ID", "fileName": "", "file": "", "razorpayKycDocId": '' },
            { "documentType": "Certificate of Incorporation", "fileName": "", "file": "", "razorpayKycDocId": '' },
        ],
        'LLP': [
            { "documentType": 'Business PAN', "fileName": "", "file": "", "razorpayKycDocId": '' },
            { "documentType": "Aadhar/Passport/Voter's ID", "fileName": "", "file": "", "razorpayKycDocId": '' },
            { "documentType": "Certificate of Incorporation", "fileName": "", "file": "", "razorpayKycDocId": '' },
        ],
        'NGO': [
            { "documentType": 'Business PAN', "fileName": "", "file": "", "razorpayKycDocId": '' },
            { "documentType": "Aadhar/Passport/Voter's ID", "fileName": "", "file": "", "razorpayKycDocId": '' },
            { "documentType": "NGO certificate", "fileName": "", "file": "", "razorpayKycDocId": '' },
            { "documentType": '80G / 12A Form', "fileName": "", "file": "", "razorpayKycDocId": '' },
        ],
        'Trust': [
            { "documentType": 'Business PAN', "fileName": "", "file": "", "razorpayKycDocId": '' },
            { "documentType": "Aadhar/Passport/Voter's ID", "fileName": "", "file": "", "razorpayKycDocId": '' },
            { "documentType": "Trust Certificate", "fileName": "", "file": "", "razorpayKycDocId": '' },
        ],
        'Society': [
            { "documentType": 'Business PAN', "fileName": "", "file": "", "razorpayKycDocId": '' },
            { "documentType": "Aadhar/Passport/Voter's ID", "fileName": "", "file": "", "razorpayKycDocId": '' },
            { "documentType": "Society Certificate", "fileName": "", "file": "", "razorpayKycDocId": '' },
        ],
    })
    const [currentSelectedDoc, setCurrentSelectedDoc] = useState<any>()
    useEffect(() => {
        if (vendorDetails?.kycDocuments.length > 0) {
            setCurrentSelectedDoc(vendorDetails?.kycDocuments)
        } else {
            setCurrentSelectedDoc(documents[vendorDetails?.vendorOverView?.businessType.label])
        }
    }, [])

    // old one
    // function handleFileSelect(fileName: any, index: any) {
    //     const updatedKycDocuments = vendorDetails.kycDocuments.map((doc: any, i: any) =>
    //         i === index ? { ...doc, fileName: fileName[0].name, file: fileName } : doc
    //     );
    //     setVendorDetails({
    //         ...vendorDetails,
    //         kycDocuments: updatedKycDocuments
    //     });
    // };
    // new one

    function handleFileSelect(fileName: any, index: any) {
        const updatedKycDocuments = currentSelectedDoc.map((doc: any, i: any) =>
            i === index ? { ...doc, fileName: fileName[0].name, file: fileName } : doc
        );
        setCurrentSelectedDoc(updatedKycDocuments);
    };
    // percentage chart
    useEffect(() => {
        const data = {
            value: kycDocPercentage,
            max: 100,
            label: "Progress"
        };

        const config: any = {
            type: 'doughnut',
            data: {
                datasets: [{
                    data: [data.value, data.max - data.value],
                    backgroundColor: [kycDocPercentage == 100 ? "green" : '#D2A100', '#0055D4'],
                    borderWidth: 0
                }]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                cutoutPercentage: 85,
                rotation: -90,
                circumference: 180,
                tooltips: {
                    enabled: false
                },
                legend: {
                    display: false
                },
                animation: {
                    animateRotate: true,
                    animateScale: false
                },
                title: {
                    display: true,
                    text: data.label,
                    fontSize: 16
                }
            }
        };

        if (chartRef.current) {
            const chartCtx = chartRef.current.getContext('2d');
            const gaugeChart = new Chart(chartCtx, config);

            // Cleanup on unmount
            return () => {
                gaugeChart.destroy();
            };
        }
    }, [kycDocPercentage]);
    // percentage chart
    useEffect(() => {
        if (vendorDetails?.kycDocuments?.length == 0) return
        let count = 0;
        vendorDetails?.kycDocuments?.forEach((item: any) => {
            if (item.fileName !== "") {
                setStepperStatus({ ...stepperStatus, KYCDocuments: "working" })
                count++;
            }
        });
        let percentage: any = (count / vendorDetails?.kycDocuments?.length) * 100
        if (percentage.toFixed(0) == 100) {
            setStepperStatus({ ...stepperStatus, KYCDocuments: "completed" })
        }
        overAllPercentage()
        setKycDocPercentage(percentage.toFixed(0))

    }, [vendorDetails?.kycDocuments])

    async function uploadKycDoc(kycDocuments: any) {
        try {
            const formData = new FormData();
            if (kycDocuments.length > 0) {
                kycDocuments.map((doc: any, index: any) => {
                    formData.append(`kycDoc`, doc.file[0]);
                });
            }
            formData.append(`vendorDetails`, JSON.stringify(vendorDetails))
            const res = await axiosPrivate.post('razorpay/vendor/kyc-creation', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data', // Important: Set the content type to multipart/form-data
                },
            })

        } catch (error) {
            console.log(error)
        }

    }
    async function overAllPercentage() {
        try {
            const formData = new FormData();
            // get overAll datas
            let count = 0;
            let mandatoryList: any = {
                basicDetails: ['emailId', 'contactNumber'],
                vendorOverView: ['companyCIN', vendorDetails?.vendorOverView?.companyCIN == "" ? 'companyLLPIN' : "", 'companyPAN', 'legalBusinessName', 'businessType', 'businessCategory', 'businessSubCategory', 'businessModel', 'website', 'description'],
                statutoryDetails: [],
                statutoryGridDetails: [],
                stakeholderInfo: [],
                address: ['contact_street_1', 'contact_street_2', 'contact_city', 'contact_state', 'contact_postalCode', 'contact_country', 'registered_street_1', 'registered_street_2', 'registered_city', 'registered_state', 'registered_postalCode', 'registered_country',],
                bankDetails: ['accountNumber', 'ifscCode', 'accountName'],
                pointOfContact: ['contactPersonName', 'contactNumber', 'email'],

            }
            let list: any = {}
            Object.keys(vendorDetails).forEach((mainKey) => {
                let skipDatas = ['statutoryGridDetails', 'kycDocuments', 'primaryKeyOfCurrentDatas', 'pendingList', 'overAllPercentage']
                if (!skipDatas.includes(mainKey)) {
                    Object.keys(vendorDetails[mainKey])?.forEach((item: any) => {
                        console.log("vendorDetails[mainKey][item]", vendorDetails[mainKey][item])
                        let dropdownValueStatus = typeof (vendorDetails[mainKey][item]) == 'object' ? vendorDetails[mainKey][item]?.label !== "Select" : true;
                        if (vendorDetails[mainKey][item] == "" && mandatoryList[mainKey]?.includes(item)) {
                            if (!list[mainKey]) {
                                list[mainKey] = [item]
                            } else {
                                list[mainKey].push(item)
                            }
                        }
                        // console.log( mandatoryList[mainKey] ? mandatoryList[mainKey]?.includes(item) : true)
                        if (vendorDetails[mainKey][item] !== "" && mandatoryList[mainKey]?.includes(item) && dropdownValueStatus) {
                            count++;
                        }
                    });
                }
            })

            let percentage: any = (count / 29) * 100
            console.log(percentage.toFixed(0))
            setOverAllPercentageCount(percentage.toFixed(0))

        } catch (error: any) {
            console.log(error)
        }

    }

    // async function next() {
    //     if (validateForm()) {
    //         if (await duplicateCheck()) {
    //             setShowPage('KYCDocuments')
    //         }
    //     } else {
    //         setShowAlertBox(true);
    //         setShowType("danger");
    //         setShowMessage(Object.values(updatedIsValueValidAll).every((values) => !values) ?
    //             "Some of the field(s) are not in required format." : "Mandatory field(s) should not be left blank.")
    //         clearAlert('');
    //     }
    //     // const isFormValid = validateForm()
    //     // if (isFormValid) {
    //     //   let isDuplicate = await duplicateCheck()
    //     //   if (isDuplicate) {

    //     //     if (isEditMode) {
    //     //       sendDataApi();
    //     //     } else {
    //     //       setShowPage("Address")
    //     //       setIconStatus({
    //     //         ...iconStatus,
    //     //         adminIcon: "completed",
    //     //         addressIcon: "selected"
    //     //       })
    //     //     }
    //     //   } else {

    //     //   }
    //     // } else {
    //     //   setShowAlertBox(true);
    //     //   setShowType("danger");
    //     //   setShowMessage(Object.values(updatedIsValueValidAll).every((values) => !values) ?
    //     //     "Some of the field(s) are not in required format." : "Mandatory field(s) should not be left blank.")
    //     //   clearAlert('');
    //     // }
    // }

    const clearAlert = (status: any) => {
        const timer = setTimeout(() => {
            setShowAlertBox(false);
            setShowMessage("");
            clearTimeout(timer);
        }, 5000);
    }
    async function saveVendorDatas() {
        try {
            // pending percentage and list -- start
            let mandatoryListsAccountCreation = ['basicDetails', 'vendorOverView', 'pointOfContact', 'address']
            let mandatoryListsDocs = ['razorpayBankDetailsStatus', 'razorpayBusinessDocStatus', 'razorpayStakeholderDocStatus', 'razorpayStakeholderStatus']
            let mandatoryField: any = {
                basicDetails: 'emailId',
                vendorOverView: 'companyPAN',
                address: 'contact_street_1',
                pointOfContact: 'contactPersonName',
            }
            let final_percentage = 0;
            let final_list = [];
            for (const data of Object.keys(vendorDetails)) {
                if (mandatoryListsAccountCreation.includes(data)) {
                    if (vendorDetails[data][mandatoryField[data]]) {
                        final_percentage += 11.11
                    } else {
                        final_list.push(data)
                    }
                }
            }
            if (vendorDetails?.statutoryGridDetails.length > 0) {
                final_percentage += 11.11
            } else {
                final_list.push('statutoryDetails')
            }
            for (const data of Object.keys(vendorDetails.basicDetails)) {
                if (mandatoryListsDocs.includes(data)) {
                    if (vendorDetails.basicDetails[data] || data == 'razorpayBusinessDocStatus') {
                        final_percentage += 11.11
                    } else {
                        final_list.push(data)
                    }
                }
            }
            // pending percentage and list -- end

            const formData = new FormData();
            if (currentSelectedDoc.length > 0) {
                currentSelectedDoc.map((doc: any, index: any) => {
                    formData.append(`kycDoc`, doc.file[0]);
                });
            }
            formData.append(`basicDetails`, JSON.stringify(vendorDetails.basicDetails))
            formData.append(`kycDocuments`, JSON.stringify(currentSelectedDoc))
            formData.append(`primaryKeyOfCurrentDatas`, JSON.stringify(vendorDetails.primaryKeyOfCurrentDatas))
            formData.append(`overAllPercentage`, JSON.stringify(final_percentage.toFixed(0)))
            formData.append(`pendingList`, JSON.stringify(final_list))
            setLoadingScreen(true)
            const response = await axiosPrivate.post(`vendor/razorpay/create/upload-kyc-docs/${vendorDetails.primaryKeyOfCurrentDatas}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data', // Important: Set the content type to multipart/form-data
                },
            });
            if (response.status == 200) {

                if (response?.data?.recentCreatedKycData) {
                    setVendorDetails({
                        ...vendorDetails,
                        basicDetails: {
                            ...vendorDetails.basicDetails,
                            razorpayBusinessDocStatus: true
                        },
                        kycDocuments: response?.data?.recentCreatedKycData,
                    })
                }
                setLoadingScreen(false)
                setShowAlertBox(true);
                setShowType("success");
                setShowMessage(`Record submitted successfully.`)
                clearAlert('');
            }
        } catch (error: any) {
            setLoadingScreen(false)
            setShowAlertBox(true);
            setShowType("danger");
            setShowMessage(error?.response?.data?.error);
            clearAlert('');
        }
    }
    return (
        <>

            <div style={{ background: "#F8F8F8", height: "110px", width: "190px", position: "absolute", right: "0px", top: "0px", display: "flex", borderRadius: "0px 10px" }}>
                <div style={{ position: 'relative', height: '95px', width: '135px', left: "30px", top: "0px" }}>
                    <canvas ref={chartRef}></canvas>
                    <div style={{ width: "40px", position: "absolute", left: "50px", top: "58px", textAlign: "center" }}><strong style={{ fontSize: "16px" }}>{kycDocPercentage}%</strong></div>
                    <div style={{ position: "absolute", left: "10px", fontSize: "16px", bottom: "-12px" }}>0%</div>
                    <div style={{ position: "absolute", right: "-4px", fontSize: "16px", bottom: "-11px" }}>100%</div>
                </div>
            </div>
            <div className='form-wrapper'>
                <div className='form-area-address'>
                    <div >
                        <div className='company-heading'>
                            KYC Documents
                        </div>
                        <div style={vendorDetails?.basicDetails?.razorpayBusinessDocStatus ? { opacity: "0.5", pointerEvents: "none" } : {}}>
                            <div className='kyc-doc-header'>
                                <div style={{ width: "40%", paddingLeft: "20px" }}>Document Type</div>
                                <div style={{ width: "35%" }}>File Name</div>
                                <div style={{ width: "25%" }}>Action</div>
                            </div>
                            {
                                (
                                    (vendorDetails?.vendorOverView?.businessType?.label == "" || vendorDetails?.vendorOverView?.businessType?.label == "Select")) ?
                                    <div className='empty-document'>
                                        <Vendor_Kyc />
                                        <div className='contenct-text'>
                                            *To upload the KYC documents, please select the business Type in the <span onClick={() => {
                                                setShowPage('VendorOverview')
                                            }}>Vendor Overview Screen</span>
                                        </div>
                                    </div>
                                    :
                                    (
                                        // vendorDetails?.kycDocuments.length > 4
                                        // ?
                                        // vendorDetails?.kycDocuments.slice(0, vendorDetails?.kycDocuments.length / 2)
                                        // :
                                        currentSelectedDoc)
                                        ?.map((e: any, i: any) => (
                                            <div className='kyc-doc-tbody'>
                                                <div style={{ width: "40%", paddingLeft: "20px" }}>
                                                    {e.documentType}
                                                    {/* <span style={{ color: "red" }}>*</span> */}
                                                </div>
                                                <div style={{ width: "35%" }}>{e.fileName}</div>
                                                <div style={{ width: "25%", display: "flex" }}>
                                                    <div>
                                                        <FileUpload
                                                            type={"browse-file-only"}
                                                            width={'120px'}
                                                            getFileName={(file: any) => {
                                                                setSaveBtnDisabledStatus(false)
                                                                handleFileSelect(file, i)
                                                            }}
                                                        />
                                                    </div>
                                                    <IconButton
                                                        iconName={"ShowEye"}
                                                        height={"40px"}
                                                        width={"40px"}
                                                        fontSize={"30px"}
                                                        margin={"0px 8px"}
                                                        color={"white"}
                                                        backgroundColor={e?.fileName == "" ? "grey" : "#0055D4"}
                                                        hover={e?.fileName == "" ? false : true}
                                                        cursor={e?.fileName == "" ? 'default' : "pointer"}
                                                        handleClick={(event: React.MouseEvent) => {
                                                            if (e?.fileName !== "") {
                                                                setShowDocumentModal(true)
                                                                setCurrentDoc(e?.file ? e.file[0] : e.fileLocation)
                                                            }
                                                        }}
                                                    />
                                                    {/* <IconButton
                                                    iconName={"Delete"}
                                                    height={"40px"}
                                                    width={"40px"}
                                                    fontSize={"30px"}
                                                    margin={"0px 8px"}
                                                    color={"white"}
                                                    backgroundColor={"red"}
                                                    hover={true}
                                                    disabled
                                                    handleClick={(event: React.MouseEvent) => {
                                                    }}
                                                /> */}
                                                </div>
                                            </div>
                                        ))

                            }
                        </div>

                    </div>
                    <div>
                        <div className='footer-company'>
                            <ButtonComponent
                                title={"Cancel"}
                                height={"50px"}
                                width={"150px"}
                                margin={"0px 8px"}
                                backgroundColor={"#888888"}
                                color={"white"}
                                className={"button-component-hover cancel"}
                                handleClick={isFormModified ? () => { setShowChangesModal(true) } : returnGird}
                            />
                            <ButtonComponent
                                title={"Previous"}
                                height={"50px"}
                                width={"150px"}
                                disabled={false}
                                backgroundColor={"#0055D4"}
                                color={"white"}
                                margin={"0px 8px"}
                                className={"button-component-hover common-btn"}
                                handleClick={() => { setShowPage('Address') }}
                            />
                            <ButtonComponent
                                // title={isEditMode ? "Update" : "Save"}
                                title={"Submit"}
                                height={"50px"}
                                width={"150px"}
                                disabled={currentSelectedDoc?.every((e: any) => e.fileName !== "") ? false : true}
                                backgroundColor={"#0055D4"}
                                color={"white"}
                                margin={"0px 8px"}
                                className={
                                    currentSelectedDoc?.every((e: any) => e.fileName !== "") ? vendorDetails?.basicDetails?.razorpayBusinessDocStatus ? "button-component disabled" : "button-component-hover common-btn" : "button-component disabled"
                                    // currentSelectedDoc?.length > 0 ? "button-component disabled" : vendorDetails?.basicDetails?.razorpayBusinessDocStatus ? "button-component disabled" : "button-component-hover common-btn"
                                }
                                handleClick={() => {
                                    if (!vendorDetails?.basicDetails?.razorpayBusinessDocStatus) {
                                        saveVendorDatas()
                                        setSaveBtnDisabledStatus(true)
                                    }
                                }}
                            />
                            <ButtonComponent
                                title={"Next"}
                                height={"50px"}
                                width={"150px"}
                                // disabled={showMessage && showType === "success" ? true : false}
                                backgroundColor={"#0055D4"}
                                color={"white"}
                                margin={"0px 8px"}
                                className={
                                    vendorDetails?.basicDetails?.razorpayBusinessDocStatus ? "button-component-hover common-btn" : "button-component-hover disabled"}
                                // handleClick={next}
                                handleClick={() => { setShowPage('StakeholderInfo') }}
                            />
                            {/* <ButtonComponent
                                title={"Submit For Approval"}
                                height={"50px"}
                                width={"220px"}
                                // disabled={vendorDetails?.overAllPercentage !== "100"}
                                disabled={overAllPercentageCount > 90 && kycDocPercentage == 100 ? false : true}
                                backgroundColor={"#0055D4"}
                                color={"white"}
                                margin={"0px 8px"}
                                className={(overAllPercentageCount > 90 && kycDocPercentage == 100) ? "button-component-hover common-btn" : "button-component disabled"}
                                handleClick={() => {
                                    saveVendorDatas('Submit')
                                }}
                            /> */}
                        </div>
                    </div>
                </div>
            </div>
            {
                showChangesModal &&
                <Changesmodal
                    closeModal={() => setShowChangesModal(false)}
                    handleClose={() => {
                        setShowChangesModal(false);
                    }}
                    leavePage={() => returnGird()} />
            }
            {
                showDocumentModal &&
                <DocumentModal
                    modalType={'Vendor Details'}
                    closeDocument={(val: boolean) => { setShowDocumentModal(false) }}
                    document={currentDoc}
                    showDocumentModal={showDocumentModal}
                />
            }
            {
                loadingScreen &&
                <Loading />
            }

        </>
    )
}
