import { useEffect, useRef, useState } from 'react'
import NavigateHeader from '../../common-component/navigate-header';
import '../../../styles/gst/gst-form.scss'
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'
import { useDispatch } from "react-redux";
import { resetFormModified } from "../../../redux/action";
import ButtonComponent from '../../common-component/form-elements/button-component';
import { Checkbox, FormControl, InputLabel, ListItemIcon, ListItemText, MenuItem, Select } from "@mui/material"
import AgGrid, { AgGridRef } from '../../common-component/grids-and-tables/ag-grid';
import cellEyeIcon from "../../../components/common-component/grids-and-tables/cell-eye-icon";
import { DateConverterAgGrid } from "../../../helpers/capitalizetext";
import { axiosPrivate } from "../../../middleware/axois-api";
import CustomEllipsisRenderer from '../../../config/product-admin/users/users-aggrid-column-config';
import GstViewChallanModal from '../../common-component/modals/gst-view-challan-modal';
import GstApprovalModel from '../../common-component/modals/gst-approval-model';
import Loading from '../../common-component/modals/loading-screen';
import AlertBox from '../../common-component/alert-box';
import ChartFilter from '../../common-component/charts/chart-filter';
import IconButton from '../../common-component/form-elements/icon-button';

export const OrganisationfilterDatas: any = {
    "ChallanStatus": [
        {
            cardTitle: "Challan Status",
            cardType: "dropdown",
            selectedValue: "Unpaid",
            options: [
                { value: "All", label: "All" },
                { value: "Paid", label: "Paid" },
                { value: "Unpaid", label: "Unpaid" },
                { value: "Pending Approval", label: "Pending Approval" },
                { value: "Expired", label: "Expired" },
            ],
            isOpen: false
        },
    ],

}

interface Props {
    gstView: (val: any) => void;
    transaction: any;
}
interface GstDataType {
    GSTIN: string;
    CPIN: string;
    Total: string;
    CreatedDate: string;
    ExpiryDate: string;
    ChallanStatus: string;
}
interface SingleSelectDataItem {
    GSTIN: string;
    CPIN: string;
    Total: string;
    CreatedDate: string;
    ExpiryDate: string;
    ChallanStatus: string;
}
const initData: any = {
    fromDate: "",
    toDate: "",
    gstDropdownVal: "",
}

function FetchChallan({ gstView, transaction }: Props) {
    const [gstData, setGstData] = useState(initData);
    const dispatch = useDispatch();
    const gridDownloadRef = useRef<AgGridRef>(null);
    const todayDate = moment(new Date()).format('YYYY-MM-DD');
    const [selectValue, setSelectValue] = useState<string[]>([]);
    const [selectLabel, setSelectLabel] = useState<GstDataType[]>([]);
    const [displayText, setDisplayText] = useState('');
    const [filterOpen, setFilterOpen] = useState<boolean>(false);
    const [singleSelectData, setSingleSelectData] = useState<SingleSelectDataItem[]>([]);
    const [totalAmountData, setTotalAmountData] = useState('');
    const [selectedAmountData, setSelectedAmountData] = useState('');
    const [gstEyeShowModal, setGstEyeShowModal] = useState<boolean>(false);
    const [approvalModal, setapprovalModal] = useState<boolean>(false);

    //filter
    const [storeFilterOptions, setStoreFilterOptions] = useState<any>(OrganisationfilterDatas["ChallanStatus"]);
    const [openDropdownIndex, setOpenDropdownIndex] = useState<number | null>(null);
    const [selectedFilteredData, setSelectedFilteredData] = useState<any>('Unpaid');


    const [originalRowData, setOriginalRowData] = useState([]);
    const [rowData, setRowData] = useState([]);
    const [currentChallanDetails, setCurrentChallanDetails] = useState([]);
    const [gstDropdownValues, setGstDropdownValues] = useState<any>([]);
    const [mindate, setMindate] = useState<string>(todayDate);

    const [loadingScreen, setLoadingScreen] = useState<boolean>(false);
    const [showType, setShowType] = useState("warning") // error message showType
    const [showMessage, setShowMessage] = useState<string>("") // error message showMessage
    const [showAlertBox, setShowAlertBox] = useState(false) // error message ShowAlertBox

    const [subscriptionErrors, setSubscriptionErrors] = useState<Record<string, boolean>>({})
    const OptionsValue = gstDropdownValues?.map((item: any) => item.value)
    const OptionsLabel = gstDropdownValues?.map((item: any) => item.label)
    const isAllSelected = gstDropdownValues?.length > 0 && selectValue.length === gstDropdownValues.length;

    //grid data

    const gstChallanColumnData = [

        {
            field: 'selection', headerName: '', minWidth: 60, maxWidth: 60, resizable: false, suppressMovable: true, filter: "agSetColumnFilter", suppressSizeToFit: false, headerCheckboxSelection: true, checkboxSelection: true, showDisabledCheckboxes: true, suppressRowTransform: true, cellStyle: { textAlign: 'left' }
        },
        {
            field: 'GSTIN', headerName: 'GSTIN', minWidth: 220, maxWidth: 220, resizable: false, filter: false, suppressMovable: true, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "number", cellRendererFramework: CustomEllipsisRenderer,
        },
        {
            field: 'CPIN', headerName: 'CPIN', minWidth: 220, maxWidth: 220, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "number", cellRendererFramework: CustomEllipsisRenderer,
        },
        {
            field: 'Total', headerName: 'Total(₹)', minWidth: 180, maxWidth: 180, headerClass: 'header-right-align', resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'right' }, filtertype: "number", cellRendererFramework: CustomEllipsisRenderer,
        },
        {
            field: 'CreatedDate', headerName: 'Created Date', minWidth: 220, maxWidth: 220, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "number",
        },
        {
            field: 'ExpiryDate', headerName: 'Expiry Date', minWidth: 220, maxWidth: 220, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "number",
        },
        {
            field: 'ChallanStatus', headerName: 'Challan Status', minWidth: 180, maxWidth: 180, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, filtertype: "text", cellStyle: { textAlign: 'left' }, cellClassRules: {
                'paid-cell': (params: any) => params.value === 'Paid',
                'expired-cell': (params: any) => params.value === 'Expired',
                'pending-approval-cell': (params: any) => params.value === 'Pending Approval',
                'unpaid-cell': (params: any) => params.value === 'Unpaid',
            }, cellRendererFramework: CustomEllipsisRenderer,
        },
        {
            field: 'ViewChallan',
            headerName: 'View Challan',
            minWidth: 150,
            resizable: false,
            suppressMovable: true,
            filter: false,
            suppressSizeToFit: false,
            cellStyle: { textAlign: 'center', color: "#0055d5" },
            filtertype: "text",
            cellRendererFramework: cellEyeIcon,
            cellRendererParams: (params: any) => {
                const handleIconClick = async () => {
                    setCurrentChallanDetails(params.data)
                    setGstEyeShowModal(!gstEyeShowModal);
                };


                return {
                    handleIconClick: handleIconClick,
                    showIcon: true
                };
            }
        }
    ]

    //api's

    async function fetchDataFunction(gstData: any) {
        setLoadingScreen(true)
        try {
            const res = await axiosPrivate.post("/gst-payable/get-challan-datas", { gstData }).then((res) => {

                if (res.status === 200) {
                    setOriginalRowData(res.data.GstDatas);
                    let data = res.data.GstDatas;
                    const filteredData: any = data && data.filter((ele: any) => ele.ChallanStatus === ("Pending Approval") || ele.ChallanStatus === ("Unpaid"))
                    setRowData(filteredData)
                    setLoadingScreen(false)
                    setShowAlertBox(true);
                    setShowType("success");
                    setShowMessage("Challan(s) have been fetched successfully.");
                    clearAlert('');
                }
            });
        } catch (error: any) {
            setLoadingScreen(false)
            setShowAlertBox(true);
            setShowType("danger");
            setShowMessage(error?.response?.data?.error);
            clearAlert('');
        }
    }
    const fetchDropdownValues = async () => {
        try {
            const response = await axiosPrivate.get("/gst-payable/get-gst-numbers");
            if (response.status === 200) {
                const dropdownValues = response?.data?.gstNumbersList?.map((item: any) => ({
                    label: item.label,
                    value: item.value,
                    id: item.id,
                }));
                const sortedData = [...dropdownValues].sort((a, b) => a.label.localeCompare(b.label));
                setGstDropdownValues(sortedData)
            }
        } catch (error) {
        }
    };

    const next = async () => {

        const isPaidChallan = singleSelectData.some(item => (item.ChallanStatus === 'Expired') || (item.ChallanStatus === 'Paid') || (item.ChallanStatus === 'Pending Approval'));
        if (isPaidChallan) {
            setShowAlertBox(true)
            setShowType('danger')
            setShowMessage("Selected Challan has already been paid or waiting for the Approval.");
            clearAlert('');
        }
        else {
            try {
                const res = await axiosPrivate.post("/gst-payable/create-gst-datas", {
                    singleSelectData,
                    "transactionNo": transaction && transaction !== 0 ? transaction : '10001',
                    approvalStatus: "Pending Approval",
                    paymentStatus: "Pending"
                }).then((res) => {

                    if (res.status === 200) {
                        setapprovalModal(true)
                        clearAlert('');
                    }
                });
            } catch (error: any) {
                setapprovalModal(false)
                setShowMessage(error?.response?.data?.error);
                clearAlert('');
            }
        }
    }

    //functions

    function handleOrganisationsVsCompanies() {
        if (selectedFilteredData === 'All') {
            setRowData(originalRowData)
        } else {
            const filteredData: any = originalRowData.filter((ele: any) => ele.ChallanStatus === selectedFilteredData)
            setRowData(filteredData)
        }
        setFilterOpen(false)
    }


    const handleOpenClose = (index: number) => {
        if (openDropdownIndex === index) {
            setOpenDropdownIndex(null); // Close the dropdown if it's already open
        } else {
            setOpenDropdownIndex(index); // Open the dropdown
        }
    };

    function handelFilter() {
        setFilterOpen(true)
    }
    const handelSelectAll = (e: any) => {
        const value = e.target.value;
        if (value.includes("all")) {

            setSelectValue((prevValue) =>
                ((prevValue && prevValue.length) === (gstDropdownValues && gstDropdownValues.length) ? [] : OptionsValue) as never[]
            );
            setGstData({
                ...gstData,
                gstDropdownVal: OptionsValue
            })
            setSelectLabel((prevLabel) =>
                ((prevLabel && prevLabel.length) === (gstDropdownValues && gstDropdownValues.length) ? [] : OptionsLabel) as never[]
            );
            return;
        }
        setSelectValue([])
        setSelectValue(value)
        setGstData({
            ...gstData,
            gstDropdownVal: value
        })
        setSelectLabel(value.map((optionValue: any) => {
            const optionLabeldrop = gstDropdownValues.find((item: any) => item.value === optionValue)
            return optionLabeldrop ? optionLabeldrop.label : ""
        }))

    }

    const clearAlert = (status: any) => {
        const timer = setTimeout(() => {
            setShowAlertBox(false);
            setShowMessage("");
            clearTimeout(timer);
        }, 4000);
    }
    const isDateValid = () => {
        const fromDate = new Date(gstData.fromDate).setHours(0, 0, 0, 0);
        const toDate = new Date(gstData.toDate).setHours(0, 0, 0, 0);
        if (gstData.fromDate !== "" && gstData.toDate !== "") {
            if ((toDate >= fromDate)) {
                setSubscriptionErrors({
                    fromDate: false,
                    toDate: false,
                });
                return true;
            } else {
                return false;
            }
        } else {
            setSubscriptionErrors({
                fromDate: false,
                toDate: false,
            });
            return true;
        }
    };
    function handelFetch() {
        let checkValidDate: boolean = isDateValid();
        if (checkValidDate) {
            fetchDataFunction(gstData)
        } else {
            setShowAlertBox(true)
            setShowType("warning")
            setShowMessage("Date field is not in required format.")
            setSubscriptionErrors({
                fromDate: true,
                toDate: true,
            });
            setTimeout(() => {
                setShowAlertBox(false);
            }, 5000);
        }
    }
    const cancel = () => {
        gstView("grid")
        // setPageNav(true)
        // dispatch(resetFormModified(false));

    }
    const calculateTotal = () => {
        let sum = 0;
        rowData.forEach((item: any) => {
            const total = parseFloat(item?.Total.replace(/,/g, ''));
            sum += total;
        });
        return sum.toFixed(2); // Round to two decimal places if needed
    };
    const formatAmountWithCommas = (amount: any) => {
        return parseFloat(amount).toLocaleString('en-US', {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
    };
    const calculateTotalInitial = () => {
        let sum = 0;
        singleSelectData.forEach(item => {
            const total = parseFloat(item?.Total.replace(/,/g, ''));
            sum += total;
        });
        return sum.toFixed(2); // Round to two decimal places if needed
    };
    const formatAmountWithCommasInitial = (amount: any) => {
        return parseFloat(amount).toLocaleString('en-US', {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
    };
    const updateFilterDatas = (index: number, newData: any) => {
        setSelectedFilteredData(newData.selectedValue)
        setStoreFilterOptions((prevFilterDatas: any) => {
            const updatedFilterDatas = [...prevFilterDatas];
            updatedFilterDatas[index] = newData;
            return updatedFilterDatas;
        });
    };
    //useEffects

    useEffect(() => {
        const total = calculateTotal();
        const formattedTotal = formatAmountWithCommas(total);
        setTotalAmountData(formattedTotal)
    }, [rowData]);
    useEffect(() => {
        const total = calculateTotalInitial();
        const formattedTotal = formatAmountWithCommasInitial(total);
        setSelectedAmountData(formattedTotal)
    }, [singleSelectData]);
    useEffect(() => {
        if (Array.isArray(selectLabel) && selectLabel.length > 0) {
            setDisplayText(selectLabel.join(', '));
        } else if (!Array.isArray(selectLabel)) {
            setDisplayText(selectLabel);
        } else {
            setDisplayText("");
        }
    }, [selectLabel]);
    useEffect(() => {
        fetchDropdownValues()
    }, []);

    useEffect(() => {

        //     updateFilterDatas(0,OrganisationfilterDatas);
    }, []);

    const userValidationRules: any = {
        fromDate: {
            regex: "",
            field: "",
            shouldNotBe: ""
        },
        toDate: {
            regex: "",
            field: "",
            shouldNotBe: ""
        },
        gstDropdownVal: {
            regex: "",
            field: "mandatory",
            shouldNotBe: ""
        },

    }
    const updatedIsValueValidAll: Record<string, boolean> = {};
    const validateForm = (): boolean => {
        const updatedFieldValidity: Record<string, boolean> = {};
        const isMandatoryfalse = true;
        const isRegexfalse = true;
        Object.keys(userValidationRules).forEach((field) => {
            const rule = userValidationRules[field];
            const value = gstData && gstData[field];
            const isValueValid = (rule.field === "mandatory") ? (value ? (value !== rule.shouldNotBe) : false) : true;
            const isRegexValid = rule.regex !== "" ? new RegExp(rule.regex).test(value) : true;
            updatedFieldValidity[field] = !isValueValid || !isRegexValid;
            updatedIsValueValidAll[field] = !isValueValid;
        });
        setSubscriptionErrors(updatedFieldValidity);
        const isFormValid = Object.keys(updatedFieldValidity).every((keys) => {
            return !updatedFieldValidity[keys]
        });
        return isFormValid;
    };
    const handleFromDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setMindate(moment(e.target.value).format("YYYY-MM-DD"))

        const fromDate = e.target.value;

        const currentDate = moment().format('YYYY-MM-DD'); // Get the current date

        if (fromDate.length > 8) {
            const year = fromDate.substring(0, 4);
            if (parseInt(year, 10) > 2100) {
                return;
            }
            if (parseInt(year) > 999) {
                if (parseInt(year, 10) < 1900) {
                    return;
                }
            }
        }

        setGstData((prevsubscriptionData: any) => ({
            ...prevsubscriptionData,
            fromDate: fromDate,
            toDate: ""
        }));
    };

    const handleToDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {

        const toDate = e.target.value;
        const currentDate = moment().format('YYYY-MM-DD'); // Get the current date

        if (toDate.length > 8) {
            const year = toDate.substring(0, 4);
            if (parseInt(year, 10) > 2100) {
                return;
            }
            if (parseInt(year) > 999) {
                if (parseInt(year, 10) < parseInt((gstData.fromDate).substring(0, 4))) {
                    return;
                }
            }
        }
        setGstData((prevsubscriptionData: any) => ({
            ...prevsubscriptionData,
            toDate: toDate
        }));

    };



    return (
        <div className='company-form-gst'>
            <div className='navigation-header' style={{ height: "5vh", }}>
                <div className='left-header'>
                    <NavigateHeader firstValue={"Payable"}
                        style={{ cursor: "pointer" }}
                        secondValue={"GST"}
                        navigatePage={() => gstView("grid")}
                    />
                </div>
            </div>
            <div className='gst-wrapper'>
                <div className='form-main-wrapper' style={{ gap: "15px", alignItems: "end" }} >
                    <div className='main-dd-flex'>
                        <div className='title'>From Date</div>
                        <div>
                            <input
                                type="date"
                                id="from-date-picker"
                                className="date-picker"
                                style={{ border: subscriptionErrors.fromDate ? '1px solid red' : '' }}
                                value={gstData?.fromDate}
                                max={todayDate}
                                onChange={handleFromDateChange}
                            />
                        </div>
                    </div>
                    <div className='main-dd-flex'>
                        <div className='title'>To Date</div>
                        <div>
                            <input
                                type="date"
                                id="to-date-picker"
                                className="date-picker"
                                value={gstData?.toDate}
                                style={{ border: subscriptionErrors.toDate ? '1px solid red' : '' }}
                                min={mindate}
                                max={moment(mindate).add(1, 'years').format('YYYY-MM-DD')}
                                onChange={handleToDateChange}
                            />
                        </div>
                    </div>
                    <div className='gst-fetch-flex'>
                        <div>
                            GSTIN<span className='required'>*</span>
                        </div>
                        <FormControl style={{ border: "1px solid #A9C3DC", }}>
                            {
                                selectValue.length > 0 ?
                                    ""
                                    :
                                    <InputLabel shrink={false} id="placeholder-dropdown"
                                        style={{ userSelect: "none", cursor: "pointer", color: "rgb(185 179 179)" }}
                                    >
                                        {"Select an Option"}
                                    </InputLabel>
                            }

                            <Select value={selectValue}
                                id="multi-select"
                                onChange={handelSelectAll}
                                renderValue={() => displayText}
                                multiple
                                className='dropdown-style-gst' aria-expanded='true'>
                                <MenuItem value="all">
                                    <ListItemIcon>
                                        <Checkbox checked={isAllSelected}></Checkbox>
                                    </ListItemIcon>
                                    <ListItemText primary="Select All"></ListItemText>
                                </MenuItem>
                                {gstDropdownValues && gstDropdownValues.map((options: any) => (
                                    <MenuItem key={options.id} value={options.value}>
                                        <ListItemIcon>
                                            <Checkbox checked={selectValue.includes(options.value as string)} name="select-checkbox"></Checkbox>
                                        </ListItemIcon>
                                        <ListItemText primary={options.label}></ListItemText>
                                    </MenuItem>
                                ))}
                            </Select>{""}
                        </FormControl>
                        <div style={selectValue && selectValue.length > 0 ? {} : { cursor: "not-Allowed" }}>
                            <ButtonComponent
                                title={"Fetch Now"}
                                height={"41px"}
                                width={"120px"}
                                backgroundColor={"#0055D4"}
                                color={"white"}
                                margin={"0px 8px"}
                                disabled={selectValue && selectValue.length > 0 ? false : true}
                                className={selectValue && selectValue.length > 0 ? "button-component-hover common-btn" : "button-component-hover disabled"}
                                handleClick={() => { handelFetch() }}
                            />
                        </div>
                    </div>
                    <div className='transaction-area'>
                        <div className='transaction-text'>Transaction No: TRN-{transaction && transaction !== 0 ? transaction : "10001"}</div>
                        <div className='filter-area' >
                            <IconButton
                                iconName={"Filter"}
                                height={"40px"}
                                width={"40px"}
                                fontSize={"30px"}
                                margin={"0px 8px"}
                                color={"white"}
                                backgroundColor={"#0055D4"}
                                hover={true}
                                handleClick={handelFilter}
                            />
                        </div>
                    </div>
                    <div className='challan-area'>
                        <div className='challan-text'>Selected Challans: {singleSelectData ? singleSelectData.length : 0}</div>
                        <div className='total-text'>Total Amount: ₹{selectedAmountData}</div>
                    </div>
                </div>
                <div className='gst-grid-two'>
                    <AgGrid
                        fileName={"GST Challan"}
                        tabelRowData={rowData}
                        tableColumnData={gstChallanColumnData}
                        hidePaginationDD={true}
                        doubleClickUpdate={() => {

                        }}
                        getCurruntData={(data) => {
                            setSingleSelectData(data)
                        }} />
                </div>
                <div className='challan-area'>
                    <div className='challan-text'>Total Challan: {rowData ? rowData.length : 0}</div>
                    <div key={uuidv4()} className='total-text'>Total Amount: ₹{totalAmountData}</div>
                </div>
                <div className='footer-gst'>
                    <div>
                        <ButtonComponent
                            title={"Previous"}
                            height={"50px"}
                            width={"150px"}
                            margin={"0px 8px"}
                            backgroundColor={"#0055D4"}
                            color={"white"}
                            className={"button-component-hover common-btn"}
                            handleClick={cancel}
                        />
                    </div>
                    <div style={singleSelectData && singleSelectData.length > 0 ? {} : { cursor: "not-Allowed" }}>
                        <ButtonComponent
                            title={"Submit for approval"}
                            height={"50px"}
                            width={"200px"}
                            backgroundColor={"#0055D4"}
                            color={"white"}
                            disabled={singleSelectData && singleSelectData.length > 0 ? false : true}
                            margin={"0px 8px"}
                            className={singleSelectData && singleSelectData.length > 0 ? "button-component-hover common-btn" : "button-component-hover disabled"}
                            handleClick={next}
                        />
                    </div>
                </div>
                {
                    gstEyeShowModal &&
                    <div className='alert-warp'>
                        <GstViewChallanModal
                            clearValue={(value) => {
                                setGstEyeShowModal(false)
                            }}
                            currentChallanDetails={currentChallanDetails}
                        />
                    </div>
                }
                {
                    approvalModal &&
                    <div className='alert-warp'>
                        <GstApprovalModel
                            clearValue={(value) => {
                                setapprovalModal(false)
                            }}
                            gstView={gstView}
                            setLoadingScreen={setLoadingScreen}
                            loadingScreen={loadingScreen}
                        />
                    </div>
                }
                {
                    loadingScreen &&
                    <Loading />
                }
                {
                    showAlertBox &&
                    <div className='alert-warp'>
                        <AlertBox type={showType} message={showMessage} />
                    </div>

                }
                {
                    filterOpen &&
                    <div className='right-side-filter-component'>
                        <ChartFilter
                            datas={storeFilterOptions}
                            updateFilterDatas={updateFilterDatas}
                            handleOpenClose={handleOpenClose}
                            openDropdownIndex={openDropdownIndex}
                            handleApplyButtonForFilter={handleOrganisationsVsCompanies}
                            setOpenRightSideFilter={() => { setFilterOpen(false) }}
                        />
                    </div>
                }
            </div>
        </div>
    )
}

export default FetchChallan