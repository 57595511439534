import React, { useCallback, useEffect, useState, useRef } from 'react'
import OrganizationVsCompanies from '../organization-vs-companies';
import FilterHeader from '../filter-header';
// import '../../../styles/dashboard/product-admin-dashboard.scss';
import '../../../styles/dashboard/company-admin-dashboard/company-admin-dashboard.scss';
import CardPanel from '../../common-component/cards/card-panel';
import PaymentReminder from '../../common-component/payment-reminder';
import VerticalBarChart from '../../common-component/charts/vertical-barchart';
import { billGenerationDateData, billGenerationDateOptions, paymentData, paymentOptions, utilizationCardData, utilizationCardOptions, utilizationSegmentsData, utilizationSegmentsEmptyData, utilizationSegmentsOptions, utilizationVendorData, utilizationVendorOptions } from '../../../config/dashboard/company-admin/company-admin-datas';
import dummyCard from '../../../assets/images/dummy-card.png'


import useAxiosPrivate from "../../../services/hooks/useaxios-private";
import useAuth from '../../../services/hooks/useauth';
import { AiOutlineExpandAlt } from 'react-icons/ai';
import PieChart from '../../common-component/charts/pie-chart';
import { format } from "date-fns/esm";
import { ReactComponent as Filter } from '../../../assets/icons/filter.svg'
import { ReactComponent as Grid } from '../../../assets/icons/grid.svg'
import { ReactComponent as GraphIcon } from '../../../assets/icons/graph-icon.svg'
import { ReactComponent as Download } from '../../../assets/icons/download.svg'
import ApexChart from '../../common-component/charts/bar-chart-v1';
import ChartAgGrid, { AgGridRef } from '../../common-component/charts/chart-ag-grid';
import axios from 'axios';
import { PiechartVersion } from '../../common-component/charts/pie-chart-v1';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
const settings = {
  // dots: true,
  infinite: false,
  speed: 750,
  slidesToShow: 3,
  initialSlide: 0,
  slidesToScroll: 1,
  centerMode: false,
  focusOnSelect: false,
  variableWidth: true,
  accessibility: true,
  // arrows: true,
};


function CompanyAdminDashboard() {

  const axiosPrivate = useAxiosPrivate();
  const [navbarTitle, setNavbarTitle] = useState("");
  const [currentUserTitle, setCurrentUserTitle] = useState("");
  const { auth } = useAuth();
  const [cardCenterDatas, setCardCenterDatas] = useState<any>([]);
  const [payementReminderAmount, setPayementReminderAmount] = useState<any>();
  const [utilizationCardDatas, setUtilizationCardDatas] = useState<any>({ label: [], data: [] });
  const [utilizationCardLimit, setUtilizationCardLimit] = useState<any>([]);
  const [billgenerationDatas, setBillgenerationDatas] = useState<any>({ label: [], data: [] });
  const [utilizationVendor, setUtilizationVendor] = useState<any>({ label: [], data: [] });
  const [transactionHistoryData, setTransactionHistoryData] = useState<any>([]);
  const [approvalStatusDatas, setApprovalStatusDatas] = useState<any>([]);
  const [approvalStatusDisableDatas, setApprovalStatusDisableDatas] = useState<any>([]);
  const [approvalStatusTitleBg, setApprovalStatusTitleBg] = useState<any>({
    Maker: "#0055D4",
    Checker: "#EE8000",
    Recommender: "#837aeb",
    Approver: "#097C39",
    Payment: "#097C39",
  });
  const [paymentCompletedCount, setPaymentCompletedCount] = useState<any>(0);
  const utilizationCardRef = useRef<AgGridRef>(null);
  const billgenerationDateRef = useRef<AgGridRef>(null);
  const utilizationPaymentRef = useRef<AgGridRef>(null);
  const utilizationSegmentsRef = useRef<AgGridRef>(null);
  const utilizationVendorRef = useRef<AgGridRef>(null);

  //RowDatas
  const [billGenerationRowData, setBillGenerationRowData] = useState([]);
  const [utilizationVendorRowData, setUtilizationVendorRowData] = useState([]);
  const [utilizationCardRowData, setUtilizationCardRowData] = useState([]);

  // Chart And Grid Show
  const [chartViewStatus, setChartViewStatus] = useState({
    "utilizationCard": true,
    "billGeneration": true,
    "utilizationPayment": true,
    "utilizationSegments": true,
    "utilizationVendor": true,
  });

  //Minimize and Maximize 
  const [maximizeStatus, setMaximizeStatus] = useState({
    "isBarchart": true,
    "utilizationCard": true,
    "billGenerationDate": true,
    "utilizationPayment": true,
    "utilizationSegments": true,
    "utilizationVendor": true,
  });

  const [utilizationPaymentModeData, setUtilizationPaymentModeData] = useState<any>({ corporatePercentage: "0", netBankingPercentage: "0", upiAmuontPercentage: "0" });

  const formatAmount = (amount: any) => {
    if (amount && amount !== ".") {
      const onlyNumber = amount.toString().replace(/[^0-9.]/g, "");
      // Handle empty input
      if (onlyNumber === "") {
        return "";
      }
      // Split input into integer and decimal parts
      const parts = onlyNumber.split(".");
      const integerPart = parts[0];
      const decimalPart = parts[1] || "";
      // Format the integer part with commas
      const formattedInteger = parseFloat(integerPart).toLocaleString('en-IN');
      // Handle complete decimal input (e.g., "5000.50")
      if (decimalPart !== "") {
        return `${formattedInteger}.${decimalPart}`;
      }
      // Handle incomplete decimal input (e.g., "5000.")
      if (amount.toString().endsWith(".")) {
        return `${formattedInteger}.`;
      }
      // Return formatted integer
      return formattedInteger;
    }
    return "";
  };
  async function getCardCenterData() {
    try {
      const res = await axiosPrivate.get('company-admin/card-details')
      setCardCenterDatas(res.data.decryptCardDetails)
      setUtilizationCardDatas({ label: res.data.utilizationCardLabel, data: res.data.utilizationCardData })
      let utilizationVendorlabel: any = []
      let utilizationVendordata: any = []
      let a: any = []
      Object.keys(res.data.utilizationVendorData).map((e) => {
        utilizationVendorlabel.push(e)
      })
      const mappedArray: any = Object.keys(res.data.utilizationVendorData).map(key => {
        return {
          'Vendor': key,
          "Transaction Value": formatAmount(res.data.utilizationVendorData[key])
        };
      });
      setUtilizationVendorRowData(mappedArray)
      Object.values(res.data.utilizationVendorData).map((e) => {
        utilizationVendordata.push(e)
      })
      setUtilizationVendor({ label: utilizationVendorlabel, data: utilizationVendordata })
      setUtilizationCardLimit(res.data.utilizationCardLimit)
      setTransactionHistoryData(res.data.transactionHistory)
    } catch (error) {
      console.error("Error occurred while retrieving card center data.");
    }
  }
  async function getPaymentReminderData() {
    try {
      const res = await axiosPrivate.get('company-admin/card-payment-reminder')
      setPayementReminderAmount(res.data.reminderAmount)
    } catch (error) {
      console.error("Error occurred while retrieving payment reminder data.");
    }
  }
  async function paymentCompletedCountFunction() {
    try {
      const res = await axiosPrivate.get('company-admin/payment-complete-check')
      setPaymentCompletedCount(res.data.vendorPaymentDataCount)
    } catch (error) {
      console.error("Error occurred while retrieving PaymentCompletedCountFunction.");
    }
  }
  async function getApprovalStatusFunction() {
    try {
      const res = await axiosPrivate.get('company-admin/approval-status')
      const userTitle = await loggedUserCall(res.data)
      // setApprovalStatusDatas(res.data)
    } catch (error) {
      console.error("Error occurred while retrieving PaymentCompletedCountFunction.");
    }
  }
  useEffect(() => {
    getCardCenterData()
    getPaymentReminderData()
    paymentCompletedCountFunction()
    getApprovalStatusFunction()
  }, [])
  useEffect(() => {
    let label: any = [];
    let data: any = [];
    let billgenerationData: any = []
    cardCenterDatas && cardCenterDatas.map((e: any) => {
      label.push(`${e.cardNumber}`)
      data.push(e.dueDays)
      billgenerationData.push({ CardNumber: `${e.cardNumber}`, ['Due in']: e.dueDays })
    })
    setBillGenerationRowData(billgenerationData)
    setBillgenerationDatas({ label: label, data: data })
  }, [cardCenterDatas])

  function formatDate(dateString: any) {
    const originalDate = new Date(dateString)
    const formattedDateTime = format(originalDate, "dd-MMM-yyyy HH:mm:ss");
    return formattedDateTime;
  }
  // chartAggrid Datas Download
  const handleDownloadExcel = (title: any) => {
    switch (title) {
      case 'utilizationCard':
        utilizationCardRef.current!.onBtExport();
        break;
      case 'billgenerationDate':
        billgenerationDateRef.current!.onBtExport();
        break;
      case 'utilizationPayment':
        utilizationPaymentRef.current!.onBtExport();
        break;
      case 'utilizationSegments':
        utilizationSegmentsRef.current!.onBtExport();
        break;
      case 'utilizationVendor':
        utilizationVendorRef.current!.onBtExport();
        break;
      default:
        break;
    }
  }
  useEffect(() => {
    const data = utilizationCardDatas && utilizationCardDatas.label.map((e: any, i: any) => {
      return {
        ['Card Number']: e,
        ['Limit']: formatAmount(utilizationCardLimit[i]),
        ['Utilization']: formatAmount(utilizationCardDatas.data[i]),
      }

    })
    setUtilizationCardRowData(data)
  }, [utilizationCardDatas])

  const loggedUserCall = async (data: any) => {
    try {
      const response = await axiosPrivate.get(`/vendor-payable/logedin-user`);
      if (response.status === 200) {
        if (response.data.userData.currentUser == 'singleUser') {
          let singleUser: any = { ...data };
          // delete singleUser['Maker'];
          // delete singleUser['Checker'];
          // delete singleUser['Recommender'];
          // delete singleUser['Approver'];
          setApprovalStatusDisableDatas(['Maker', 'Checker', 'Recommender', 'Approver'])
          setApprovalStatusDatas(singleUser)
        } else {
          await fetchData(data)
        }
        // setCurrentUserTitle('singleUser')
      }
    } catch (error) {
      console.log(error)
    }
  }
  const fetchData = async (data: any) => {
    try {
      const response: any = await axiosPrivate.get('/user/get-privilege');
      switch (response.data.UserPrivilege) {
        case "Checker":
          // let Checker: any = { ...data };
          // delete Checker['Maker'];
          // setApprovalStatusDatas(Checker)

          setApprovalStatusDisableDatas(['Maker'])
          break;
        case "Recommender":
          // let Recommender: any = { ...data };
          // delete Recommender['Maker'];
          // delete Recommender['Checker'];
          // setApprovalStatusDatas(Recommender)
          setApprovalStatusDisableDatas(['Maker', 'Checker'])
          break;
        case "Approver":
          // let Approver: any = { ...data };
          // delete Approver['Maker'];
          // delete Approver['Checker'];
          // delete Approver['Recommender'];
          setApprovalStatusDisableDatas(['Maker', 'Checker', 'Recommender'])
          // setApprovalStatusDatas(Approver)
          break;
        case "Payment":
          // let Payment: any = { ...data };
          // delete Payment['Maker'];
          // delete Payment['Checker'];
          // delete Payment['Recommender'];
          // delete Payment['Approver'];
          setApprovalStatusDisableDatas(['Maker', 'Checker', 'Recommender', 'Approver'])
          // setApprovalStatusDatas(Payment)
          break;
        default:
          setApprovalStatusDatas(data)
          break;

      }
      setApprovalStatusDatas(data)
      // setNavbarTitle(response.data.UserPrivilege)
    } catch (error) {
      console.log(error);
    }
  }

  // Get Utilization PaymentMode Datas

  async function getUtilizationPaymentModeDatas() {
    try {
      const res = await axiosPrivate.get('company-admin/utilization-paymentmode-data')
      const { netbankingPercentage, corporateCardPercentage, upiAmuontPercentage } = res.data
      setUtilizationPaymentModeData({ corporatePercentage: corporateCardPercentage, netBankingPercentage: netbankingPercentage, upiAmuontPercentage: upiAmuontPercentage })

    } catch (error) {
      console.error("Error occurred while retrieving getUtilizationPaymentModeDatas.");
    }
  }
  useEffect(() => {
    getUtilizationPaymentModeDatas()
  }, [])


  return (
    <div className='company-admin-dashboard'>
      {
        maximizeStatus.isBarchart &&
        <>
          <div className='card-center-container'>
            <h4>{"Cards Center"}</h4>
            <div className={`${cardCenterDatas.length == 0 ? "card-panels-nocards" : "card-panels"}`}>
              {
                cardCenterDatas && cardCenterDatas.length == 0 ?
                  <div className='dummy-card-container'>
                    <img src={dummyCard} className='dummy-card-image' />
                    <div className='dummy-card-content'>{"You have no cards added"}</div>
                  </div>
                  :
                  // <Slider {...settings}>
                  //   {
                  cardCenterDatas && cardCenterDatas.map((e: any, i: any) => (
                    <div style={{ marginRight: "15px" }} key={i}>
                      <CardPanel
                        bankName={e.bankName}
                        percentage={e.percentage}
                        limit={e.limit}
                        insideCardBalance={e.insideCardBalance}
                        lastBilledDue={e.lastBilledDue}
                        cardNumber={e.cardNumber}
                        cardNetwork={e.cardNetwork}
                        dueIn={e.dueIn}
                        billingCycle={e.billingCycle}
                        processing={e.processing}
                        outsideCardBalance={e.outsideCardBalance}
                        backgroundColor={e.backgroundColor}
                      />
                    </div>
                  ))
                //   }
                // </Slider>
              }
            </div>
          </div>
          <div className='payment-reminder-container'>
            <h4>{"Payment Reminder"}</h4>
            <div className='payment-reminders'>
              <PaymentReminder
                paymentTitle={"Vendor Payment"}
                paymentAmount={payementReminderAmount ? payementReminderAmount : "--"}
                background={"transparent linear-gradient(247deg, #0055D4 0%, #011D60 100%) 0% 0% no-repeat padding-box"}
              />
              <PaymentReminder
                paymentTitle={"GST"}
                paymentAmount={'--'}
                background={"transparent linear-gradient(247deg, #007DAC 0%, #0C7B00 100%) 0% 0% no-repeat padding-box"}
              />
              <PaymentReminder
                paymentTitle={"Utility"}
                paymentAmount={'--'}
                background={"transparent linear-gradient(247deg, #4A007B 0%, #0061DF 100%) 0% 0% no-repeat padding-box"}
              />
              <PaymentReminder
                paymentTitle={"Direct Tax"}
                paymentAmount={'--'}
                background={"transparent linear-gradient(247deg, #009A9A 0%, #003D98 100%) 0% 0% no-repeat padding-box"}
              />
            </div>
          </div>
          {/* Transaction History */}
          <div className='user-details'>
            <div className='transaction-history-container'>
              <h4>{"Transaction History"}</h4>
              <div className='table-header'>
                <div>{"Name/Business"}</div>
                <div>{"Date"}</div>
                <div>{"Invoice ID"}</div>
                <div>{"Amount"}</div>
                <div>{"Transaction Charge"}</div>
                <div>{"Total Amount"}</div>
                <div>{"Payment Mode"}</div>
                <div>{"Status"}</div>
              </div>
              <div className='table-container'>
                {
                  transactionHistoryData && transactionHistoryData.length == 0 ?
                    <div className='table-datas empty-data' style={{ height: "230px" }}>
                      {"No Records"}
                    </div>
                    :
                    transactionHistoryData && transactionHistoryData.map((e: any, i: any) =>
                      <div className='table-datas' key={i}>
                        <div>
                          <li>{e['Name/Business']}</li>
                          {/* <li>{"2222"}</li> */}
                        </div>
                        <div>
                          <li>{formatDate(e.Date)}</li>
                          {/* <li>{""}</li> */}
                        </div>
                        <div>{e.InvoiceId}</div>
                        <div>{formatAmount(e.Amount)}</div>
                        <div>{formatAmount(e['Transaction Charge'])}</div>
                        <div>{formatAmount(e['Total Amount'])}</div>
                        <div>{e['Payment Mode']}</div>
                        <div style={{ color: (e.Status === "Paid" || e.Status === "PAID") ? "green" : "red" }}>{e.Status}</div>
                      </div>
                    )
                }


              </div>
            </div>
          </div>
          {/* Approval Status */}
          <div className='user-details'>
            <div className='approval-status-container'>
              <h4>{"Approval Status"}</h4>
              {
                approvalStatusDatas && Object.values(approvalStatusDatas).length == 0 ?
                  <div className='actors-status no-datas' >
                    {"No Records"}
                  </div>
                  :
                  <div className='actors-status'>
                    {
                      approvalStatusDatas && Object.values(approvalStatusDatas).map((e: any, i: any) => (
                        <div className='actors-status-datas' key={i} style={{ background: approvalStatusDisableDatas.includes(e.title) ? '#d9d9d9' : "", color: approvalStatusDisableDatas.includes(e.title) ? '#a19a9a' : "" }}>
                          <div className='datats'>
                            <div className='initiated-count'>
                              {
                                e.hasOwnProperty('completed') ?
                                  <>
                                    <h3 style={{ color: "green" }}>{e.completed} </h3>
                                    <p>{"Completed"}</p>
                                  </>
                                  :
                                  <>
                                    <h3>{approvalStatusDisableDatas.includes(e.title) ? 0 : (e.hasOwnProperty('initiated') ? e.initiated : e.pending)} </h3>
                                    <p>{e.hasOwnProperty('initiated') ? "Initiated" : "Pending"}</p></>
                              }
                            </div>
                            <div className='status-count' style={{ color: approvalStatusDisableDatas.includes(e.title) && "#a19a9a" }}>
                              {
                                e.hasOwnProperty('failed') ?
                                  <>
                                    <span style={{ marginLeft: "50%", translate: "-50%" }}>
                                      <h3 style={{ color: "red" }}>{e.failed}</h3>
                                      <p>{"Declined"}</p>
                                    </span>
                                  </>
                                  :
                                  <>
                                    <span>
                                      <h3 style={{ color: approvalStatusDisableDatas.includes(e.title) ? '#a19a9a' : "green" }}>{approvalStatusDisableDatas.includes(e.title) ? 0 : e.approved}</h3>
                                      <p>{"Approved"}</p>
                                    </span>
                                    <span>
                                      <h3 style={{ color: approvalStatusDisableDatas.includes(e.title) ? '#a19a9a' : "red" }}>{approvalStatusDisableDatas.includes(e.title) ? 0 : e.declined}</h3>
                                      <p>{"Declined"}</p>
                                    </span>
                                  </>
                              }
                            </div>
                          </div>
                          <div className='actor-name' style={{ backgroundColor: approvalStatusDisableDatas.includes(e.title) ? "#a19a9a" : approvalStatusTitleBg[e.title] }}>{e.title == navbarTitle ? "To Do" : e.title}</div>
                        </div>
                      ))
                    }

                    {/* <div className='actors-status-datas'>
                  <div className='datats'>
                    <div className='initiated-count'>
                      <h3>150</h3>
                      <p>{"Initiated"}</p>
                    </div>
                    <div className='status-count'>
                      <span>
                        <h3 style={{ color: "green" }}>150</h3>
                        <p>{"Approved"}</p>
                      </span>
                      <span>
                        <h3 style={{ color: "red" }}>150</h3>
                        <p>{"Rejected"}</p>
                      </span>
                    </div>
                  </div>
                  <div className='actor-name'>{"Maker"}</div>
                </div> */}
                  </div>
              }
            </div>
          </div>
        </>
      }
      {/* utilizationCard */}
      {
        maximizeStatus.utilizationCard &&
        <>
          <div
            className='utilization-card'
            style={{ height: (maximizeStatus.utilizationCard && !maximizeStatus.isBarchart) ? "80vh" : "", margin: "0" }}
          >
            <div className='utilization-card-title'>{"Utilization - Card"}</div>
            {
              chartViewStatus.utilizationCard ?
                <>
                  <div className='utilization-details'>
                    <ApexChart showlabels={false} reversed={true} label={utilizationCardDatas.label} data={utilizationCardDatas.data} />
                    <div className='chart-title'>{"Utilization"}</div>
                  </div>
                  <div className='limit-details'>
                    <ApexChart showlabels={true} reversed={false} label={utilizationCardDatas.label} data={utilizationCardLimit} />
                    <div className='chart-title'>{"Limit"}</div>
                  </div>
                </>
                :
                <div className='utilization-card-grid' >
                  <ChartAgGrid
                    fileName={"Utilization Card - Report"}
                    ref={utilizationCardRef}
                    tabelRowData={utilizationCardRowData}
                    tableColumnData={[
                      { field: 'Card Number', cellStyle: { 'text-align': 'center' }, minWidth: 250 },
                      { field: 'Limit', cellStyle: { 'text-align': 'center' }, },
                      { field: 'Utilization', cellStyle: { 'text-align': 'right' }, }
                    ]}
                    className={"company-admin-utilization-card"}
                    paginationPageSize={7}
                  />
                </div>
            }

            {/* <div className='physical-card'>{"Physical Card"}</div> */}
            <div className='expand-arrow' onClick={() => {
              if (maximizeStatus.isBarchart == false) {
                setMaximizeStatus({
                  "isBarchart": true,
                  "utilizationCard": true,
                  "billGenerationDate": true,
                  "utilizationPayment": true,
                  "utilizationSegments": true,
                  "utilizationVendor": true,
                })
              } else {
                setMaximizeStatus({
                  "isBarchart": false,
                  "utilizationCard": true,
                  "billGenerationDate": false,
                  "utilizationPayment": false,
                  "utilizationSegments": false,
                  "utilizationVendor": false,
                })
              }

            }}><AiOutlineExpandAlt style={{ fontSize: "30px" }} /></div>
            <div className='features'>
              {
                chartViewStatus.utilizationCard ?
                  <Grid onClick={() => { setChartViewStatus({ ...chartViewStatus, utilizationCard: !chartViewStatus.utilizationCard, }) }} /> :
                  <GraphIcon onClick={() => { setChartViewStatus({ ...chartViewStatus, utilizationCard: !chartViewStatus.utilizationCard, }) }} />

              }
              <hr />
              <Download className={`${chartViewStatus.utilizationCard ? "disableState" : "activeState"}`} onClick={() => { !chartViewStatus.utilizationCard && handleDownloadExcel('utilizationCard') }} />
            </div>
          </div>
        </>
      }
      {/* bill-generation-and-payment */}
      {
        (maximizeStatus.billGenerationDate || maximizeStatus.utilizationPayment) &&
        <div className='bill-generation-and-payment' style={{ height: ((maximizeStatus.billGenerationDate || maximizeStatus.utilizationPayment) && !maximizeStatus.isBarchart) ? "75vh" : "" }}>
          {
            maximizeStatus.billGenerationDate &&
            <div className='bill-generation' style={{ width: (maximizeStatus.billGenerationDate && !maximizeStatus.utilizationPayment) ? '100%' : "", height: (maximizeStatus.billGenerationDate && !maximizeStatus.utilizationPayment) ? '100%' : "" }}>
              {
                chartViewStatus.billGeneration ?
                  <>
                    <VerticalBarChart
                      width={"100%"}
                      height={"100%"}
                      options={billGenerationDateOptions}
                      data={billGenerationDateData}
                      dataAvailable={true}
                      barLabel={billgenerationDatas.label}
                      barData={billgenerationDatas.data}
                      clickedEvent={false}

                    />
                    <div className='chart-title' style={{ left: (maximizeStatus.billGenerationDate && !maximizeStatus.utilizationCard) ? "45%" : "" }}>{"Bill Generation Date"}</div>
                  </>
                  :
                  <div className='utilization-card-grid'>
                    <ChartAgGrid
                      fileName={"Bill generation date - Report"}
                      ref={billgenerationDateRef}
                      tabelRowData={billGenerationRowData}
                      tableColumnData={[
                        { field: 'CardNumber', cellStyle: { 'text-align': 'center' }, minWidth: 250 },
                        { field: 'Due in', cellStyle: { 'text-align': 'center' } },
                      ]}
                      className={"company-admin-utilization-card"}
                      paginationPageSize={7}
                    />
                  </div>
              }

              {/* <div className='physical-card'>{"Physical Card"}</div> */}
              <div className='expand-arrow' onClick={() => {
                if (maximizeStatus.isBarchart == false) {
                  setMaximizeStatus({
                    "isBarchart": true,
                    "utilizationCard": true,
                    "billGenerationDate": true,
                    "utilizationPayment": true,
                    "utilizationSegments": true,
                    "utilizationVendor": true,
                  })
                } else {
                  setMaximizeStatus({
                    "isBarchart": false,
                    "utilizationCard": false,
                    "billGenerationDate": true,
                    "utilizationPayment": false,
                    "utilizationSegments": false,
                    "utilizationVendor": false,
                  })
                }
              }}><AiOutlineExpandAlt style={{ fontSize: "30px" }} /></div>
              <div className='features'>
                {
                  chartViewStatus.billGeneration ?
                    <Grid onClick={() => { setChartViewStatus({ ...chartViewStatus, billGeneration: !chartViewStatus.billGeneration, }) }} /> :
                    <GraphIcon onClick={() => { setChartViewStatus({ ...chartViewStatus, billGeneration: !chartViewStatus.billGeneration, }) }} />
                }
                <hr />
                <Download className={`${chartViewStatus.billGeneration ? "disableState" : "activeState"}`} onClick={() => { !chartViewStatus.billGeneration && handleDownloadExcel('billgenerationDate') }} />
              </div>
            </div>
          }
          {
            maximizeStatus.utilizationPayment &&
            <div className='utilization-payment' style={{ width: (!maximizeStatus.billGenerationDate && maximizeStatus.utilizationPayment) ? '100%' : "" }}>
              <div className='utilization-payment-title' style={{ left: (maximizeStatus.utilizationPayment && !maximizeStatus.utilizationCard) ? "42%" : "" }}>{"Utilization - Payment Mode"}</div>
              {
                chartViewStatus.utilizationPayment ?
                  <>
                    {
                      utilizationPaymentModeData.corporatePercentage == (0 || "NaN") ?
                        <div style={{ fontWeight: "bold" }}>No Records</div>
                        :
                        <PieChart
                          width={"500px"}
                          height={"350px"}
                          options={paymentOptions}
                          data={{
                            labels: ['Corporate Card', 'Net Banking', 'UPI'],
                            datasets: [
                              {
                                data: [utilizationPaymentModeData.corporatePercentage, utilizationPaymentModeData.netBankingPercentage, utilizationPaymentModeData.upiAmuontPercentage],
                                // data: ["80", "20"],
                                backgroundColor: ['red', 'blue', 'orange'],
                                hoverBackgroundColor: ['darkred', 'darkblue', 'darkorange'],
                              },
                            ],
                          }}
                        />

                    }


                    {/* <PiechartVersion maximizaStatus={!maximizeStatus.billGenerationDate && maximizeStatus.utilizationPayment} paymentCompletedCount={paymentCompletedCount} /> */}
                  </>
                  :
                  <div className='utilization-card-grid' style={{ width: "85%", height: "85%" }}>
                    <ChartAgGrid
                      fileName={"Utilization Payment - Report"}
                      ref={utilizationPaymentRef}
                      tabelRowData={
                        [
                          { 'Payment Mode': "Corporate Card", 'Transaction Value(%)': utilizationPaymentModeData.corporatePercentage },
                          { 'Payment Mode': "Net Banking", 'Transaction Value(%)': utilizationPaymentModeData.netBankingPercentage },
                        ]
                      }
                      tableColumnData={[
                        { field: 'Payment Mode', cellStyle: { 'text-align': 'center' }, minWidth: 250 },
                        { field: 'Transaction Value(%)', cellStyle: { 'text-align': 'center' }, },
                      ]}
                      className={"company-admin-utilization-card"}
                      paginationPageSize={7}
                    />
                  </div>
              }
              <div className='expand-arrow' onClick={() => {
                if (maximizeStatus.isBarchart == false) {
                  setMaximizeStatus({
                    "isBarchart": true,
                    "utilizationCard": true,
                    "billGenerationDate": true,
                    "utilizationPayment": true,
                    "utilizationSegments": true,
                    "utilizationVendor": true,
                  })
                } else {
                  setMaximizeStatus({
                    "isBarchart": false,
                    "utilizationCard": false,
                    "billGenerationDate": false,
                    "utilizationPayment": true,
                    "utilizationSegments": false,
                    "utilizationVendor": false,
                  })
                }
              }}><AiOutlineExpandAlt style={{ fontSize: "30px" }} /></div>
              <div className='features'>
                {
                  chartViewStatus.utilizationPayment ?
                    <Grid onClick={() => { paymentCompletedCount > 0 && setChartViewStatus({ ...chartViewStatus, utilizationPayment: !chartViewStatus.utilizationPayment, }) }} className={`${paymentCompletedCount > 0 ? "activeState" : "disableState"}`} /> :
                    <GraphIcon onClick={() => { setChartViewStatus({ ...chartViewStatus, utilizationPayment: !chartViewStatus.utilizationPayment, }) }} />
                }
                <hr />
                <Download className={`${chartViewStatus.utilizationPayment ? "disableState" : "activeState"}`} onClick={() => { !chartViewStatus.utilizationPayment && handleDownloadExcel('utilizationPayment') }} />
              </div>
              {/* <DonutChart/> */}
            </div>
          }
        </div>
      }
      {/* segments-and-vendor */}
      {
        (maximizeStatus.utilizationSegments || maximizeStatus.utilizationVendor) &&
        <div className='segments-and-vendor' style={{ height: ((maximizeStatus.utilizationSegments || maximizeStatus.utilizationVendor) && !maximizeStatus.billGenerationDate) ? "75vh" : "" }}>
          {
            maximizeStatus.utilizationSegments &&
            <div className='utilization-segments' style={{ width: (maximizeStatus.utilizationSegments && !maximizeStatus.utilizationVendor) ? '100%' : "" }}>
              <div className='chart-title' style={{ left: (maximizeStatus.utilizationSegments && !maximizeStatus.utilizationCard) ? "42%" : "" }}>{"Utilization - Segments"}</div>
              {
                chartViewStatus.utilizationSegments ?
                  <>
                    {
                      paymentCompletedCount > 0 ?

                        <VerticalBarChart
                          width={"100%"}
                          height={(maximizeStatus.utilizationSegments && !maximizeStatus.utilizationVendor) ? "100%" : "400px"}
                          options={utilizationSegmentsOptions}
                          data={utilizationSegmentsData}
                          clickedEvent={false}

                        /> :
                        <VerticalBarChart
                          width={"100%"}
                          height={(maximizeStatus.utilizationSegments && !maximizeStatus.utilizationVendor) ? "100%" : "400px"}
                          options={utilizationSegmentsOptions}
                          data={utilizationSegmentsEmptyData}
                          clickedEvent={false}

                        />
                    }
                  </>
                  :
                  <div className='utilization-card-grid'>
                    <ChartAgGrid
                      fileName={"Utilization Segments - Report"}
                      ref={utilizationSegmentsRef}
                      tabelRowData={[{ 'Segments': "Vendor Payment", 'Transaction Value(%)': "100" }]}
                      tableColumnData={[
                        {
                          field: 'Segments', cellStyle: { 'text-align': 'center' }, textminWidth: 250
                        },
                        {
                          field: 'Transaction Value(%)', cellStyle: { 'text-align': 'center' },
                        },
                      ]}
                      className={"company-admin-utilization-card"}
                      paginationPageSize={7}
                    />
                  </div>
              }

              {/* <div className='physical-card'>{"Physical Card"}</div> */}
              <div className='expand-arrow' onClick={() => {
                if (maximizeStatus.isBarchart == false) {
                  setMaximizeStatus({
                    "isBarchart": true,
                    "utilizationCard": true,
                    "billGenerationDate": true,
                    "utilizationPayment": true,
                    "utilizationSegments": true,
                    "utilizationVendor": true,
                  })
                } else {
                  setMaximizeStatus({
                    "isBarchart": false,
                    "utilizationCard": false,
                    "billGenerationDate": false,
                    "utilizationPayment": false,
                    "utilizationSegments": true,
                    "utilizationVendor": false,
                  })
                }
              }}><AiOutlineExpandAlt style={{ fontSize: "30px" }} /></div>
              <div className='features'>
                {
                  chartViewStatus.utilizationSegments ?
                    <Grid onClick={() => { paymentCompletedCount > 0 && setChartViewStatus({ ...chartViewStatus, utilizationSegments: !chartViewStatus.utilizationSegments, }) }} className={`${paymentCompletedCount > 0 ? "activeState" : "disableState"}`} />
                    :
                    <GraphIcon onClick={() => { setChartViewStatus({ ...chartViewStatus, utilizationSegments: !chartViewStatus.utilizationSegments, }) }} />
                }

                <hr />
                <Download className={`${chartViewStatus.utilizationSegments ? "disableState" : "activeState"}`} onClick={() => { !chartViewStatus.utilizationSegments && handleDownloadExcel('utilizationSegments') }} />
              </div>
            </div>
          }
          {
            maximizeStatus.utilizationVendor &&
            <div className='vendor' style={{ width: (!maximizeStatus.utilizationSegments && maximizeStatus.utilizationVendor) ? '100%' : "" }}>
              <div className='chart-title' style={{ left: (maximizeStatus.utilizationVendor && !maximizeStatus.utilizationCard) ? "42%" : "" }}>{"Utilization - Vendor"}</div>
              {
                chartViewStatus.utilizationVendor ?
                  <VerticalBarChart
                    width={"100%"}
                    height={(!maximizeStatus.utilizationSegments && maximizeStatus.utilizationVendor) ? "100%" : "400px"}
                    options={utilizationVendorOptions}
                    data={{
                      labels: utilizationVendor.label,
                      datasets: [{
                        barPercentage: 1.1,
                        barThickness: 40,
                        data: utilizationVendor.data,
                        backgroundColor: ["#0C7B00", "#02548F", "#C2498F"],
                        borderColor: ["#0C7B00", "#02548F", "#C2498F"],
                        borderWidth: 5
                      }]
                    }}
                    clickedEvent={false}

                  />
                  :
                  <div className='utilization-card-grid'>
                    <ChartAgGrid
                      fileName={"Utilization Vendor - Report"}
                      ref={utilizationVendorRef}
                      tabelRowData={utilizationVendorRowData}
                      tableColumnData={[
                        { field: 'Vendor', cellStyle: { 'text-align': 'left' }, minWidth: 250 },
                        { field: 'Transaction Value', cellStyle: { 'text-align': 'right' }, },
                      ]}
                      className={"company-admin-utilization-card"}
                      paginationPageSize={7}
                    />
                  </div>
              }
              {/* <div className='physical-card'>{"Physical Card"}</div> */}
              <div className='expand-arrow' onClick={() => {
                if (maximizeStatus.isBarchart == false) {
                  setMaximizeStatus({
                    "isBarchart": true,
                    "utilizationCard": true,
                    "billGenerationDate": true,
                    "utilizationPayment": true,
                    "utilizationSegments": true,
                    "utilizationVendor": true,
                  })
                } else {
                  setMaximizeStatus({
                    "isBarchart": false,
                    "utilizationCard": false,
                    "billGenerationDate": false,
                    "utilizationPayment": false,
                    "utilizationSegments": false,
                    "utilizationVendor": true,
                  })
                }
              }}><AiOutlineExpandAlt style={{ fontSize: "30px" }} /></div>
              <div className='features'>
                {
                  chartViewStatus.utilizationVendor ?
                    <Grid onClick={() => { setChartViewStatus({ ...chartViewStatus, utilizationVendor: !chartViewStatus.utilizationVendor, }) }} />
                    :
                    <GraphIcon onClick={() => { setChartViewStatus({ ...chartViewStatus, utilizationVendor: !chartViewStatus.utilizationVendor, }) }} />
                }
                <hr />
                <Download className={`${chartViewStatus.utilizationVendor ? "disableState" : "activeState"}`} onClick={() => { !chartViewStatus.utilizationVendor && handleDownloadExcel('utilizationVendor') }} />
              </div>
            </div>
          }
        </div>
      }
    </div>

  )
}
export default CompanyAdminDashboard;