import { useState, useEffect } from 'react'
import MainPageCard from '../../common-component/cards/main-page-card'
import NavigateHeader from '../../common-component/navigate-header'
import RadioOrCheckbox from '../../common-component/form-elements/radio-or-checkbox'
import DropdownComponent from '../../common-component/form-elements/dropdown-component'
import InputComponent from '../../common-component/form-elements/input-component'
import ButtonComponent from '../../common-component/form-elements/button-component'
import useAxiosPrivate from "../../../services/hooks/useaxios-private";
import { v4 as uuidv4 } from 'uuid';
import AlertBox from '../../common-component/alert-box'
import Changesmodal from '../../common-component/modals/changes-modal'
import TextArea from '../../common-component/form-elements/text-area'
import "../../../styles/organisation/bank/form.scss"
import Loading from '../../common-component/modals/loading-screen'
import { useDispatch } from "react-redux";
import { resetFormModified } from "../../../redux/action";
import ExciseDocumentUploadModal from '../../common-component/modals/excise-document-upload-modal'
import IconButton from '../../common-component/form-elements/icon-button'
import DocumentModal from '../../common-component/modals/document-modal'
import moment from 'moment';
import ChallanShowModal from '../../common-component/modals/challan-show-modal'
import DeleteModal from '../../common-component/modals/delete-modal'

interface FormProps {
  userNavigateFunction: (val: string) => void;
  userNavigate: string;
  isEditMode: boolean;
  modeFunction: (val: boolean) => void;
  getEditMode: (value: boolean) => void;
  editData: any
}

export interface ExcisePaymentDataType {
  centralExciseRegistrationNo: string;
  documentType: string;
  dutyType: string;
  location: string;

  accountName: string;
  accountNumber: string;
  ifscCode: string;
  challanExpiryDate: string;
  amount: string;
  [key: string]: string;
}

export interface excisePaymentValidationRulesInterface {
  [key: string]: {
    [key: string]: {
      regex: RegExp | string;
      field: string;
      shouldNotBe: string;
    };
  }
}



const Form: React.FC<FormProps> = ({ userNavigateFunction, getEditMode, isEditMode, editData, modeFunction }) => {
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
  const [componentNavigator, setComponentNavigator] = useState("form-page");
  const [showType, setShowType] = useState("warning") // error message showType
  const [showMessage, setShowMessage] = useState<string>("") // error message showMessage
  const [showAlertBox, setShowAlertBox] = useState(false) // error message ShowAlertBox
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [exciseNumberData, setexciseNumberData] = useState<any[]>([]);
  const [exciseDocumentType, setExciseDocumentType] = useState<any[]>([]);
  const [exciseDutyType, setExciseDutyType] = useState<any[]>([]);
  const [exciseLocation, setExciseLocation] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [excisePaymentDataErrors, setExcisePaymentDataErrors] = useState<Record<string, boolean>>({});
  const [showChangesModal, setShowChangesModal] = useState(false);
  const [showDocumentModal, setShowDocumentModal] = useState<boolean>(false);
  const [currentFile, SetCurrentFile] = useState("")
  const [file, SetFile] = useState<any>("")
  const [fileName, setFileName] = useState<any>("")
  const [currentDeleteExciseDocument, setCurrentDeleteExciseDocument] = useState<any>("");
  const [excisePaymentData, setExcisePaymentData] = useState<ExcisePaymentDataType>({
    centralExciseRegistrationNo: "",
    documentType: "",
    dutyType: "",
    location: "",

    accountName: "",
    accountNumber: "",
    ifscCode: "",
    challanExpiryDate: "",
    amount: "",
  });

  const todayDate = moment(new Date()).format('YYYY-MM-DD');
  const dispatch = useDispatch();
  const axiosPrivate = useAxiosPrivate();

  // regux for mandatory while click save 
  const excisePaymentValidationRules: excisePaymentValidationRulesInterface = {
    ExcisePayment_details: {
      centralExciseRegistrationNo: {
        regex: "",
        field: "mandatory",
        shouldNotBe: ""
      },
      documentType: {
        regex: "",
        field: "mandatory",
        shouldNotBe: ""
      },
      dutyType: {
        regex: "",
        field: "mandatory",
        shouldNotBe: ""
      },
      location: {
        regex: "",
        field: "mandatory",
        shouldNotBe: ""
      },
      accountName: {
        regex: /^[a-zA-Z][A-Za-z. ]+$/,
        field: "mandatory",
        shouldNotBe: ""
      },
      accountNumber: {
        regex: /^\d+$/,
        field: "mandatory",
        shouldNotBe: ""
      },
      ifscCode: {
        regex: /^[A-Z]{4}0[A-Z0-9]{6}$/,
        field: "mandatory",
        shouldNotBe: ""
      },
      challanExpiryDate: {
        regex: "",
        field: "mandatory",
        shouldNotBe: ""
      },
      amount: {
        regex: "",
        field: "mandatory",
        shouldNotBe: ""
      },
    }
  }

  // useEffect for retreving data while edit 
  useEffect(() => {
    if (!isEditMode) return;
    setExcisePaymentData({
      centralExciseRegistrationNo: editData.centralExciseRegistrationNo,
      documentType: editData.documentType,
      dutyType: editData.dutyType,
      location: editData.location,
      file: editData.file,
      fileName: editData.fileName,
      accountName: editData.accountName,
      accountNumber: editData.accountNumber,
      ifscCode: editData.ifscCode,
      challanExpiryDate: editData.challanExpiryDate,
      amount: editData.amount,
      // declineRemarks: editData.declineRemarks || "",
      payableExciseId: editData.payableExciseId
    });
    SetFile(editData.file)
    setFileName(editData.fileName)
    console.log(editData)
  }, [isEditMode]);

  // Regux validation
  const updatedIsValueValidAll: Record<string, boolean> = {};
  const validateForm = (): boolean => {
    const updatedFieldValidity: Record<string, boolean> = {};
    Object.keys(excisePaymentValidationRules.ExcisePayment_details).forEach((field) => {
      const rule = excisePaymentValidationRules.ExcisePayment_details[field];
      const value = excisePaymentData[field];
      // Check if the field value meets the conditions based on rule.field and rule.shouldNotBe
      const isValueValid = (rule.field === "mandatory") ? (value !== rule.shouldNotBe) : true;
      // If a regular expression is provided, test the field value against it
      const isRegexValid = rule.regex !== "" ? new RegExp(rule.regex).test(value) : true;
      updatedFieldValidity[field] = !isValueValid || !isRegexValid;
      updatedIsValueValidAll[field] = !isValueValid

    });
    setExcisePaymentDataErrors(updatedFieldValidity);
    const isFormValid = Object.keys(updatedFieldValidity).every((keys) => {
      return !updatedFieldValidity[keys]
    }
    );

    return isFormValid;
  };

  // Timeout function for messages
  const clearAleart = (status: any) => {
    const timer = setTimeout(() => {
      setShowAlertBox(false);
      setShowMessage("");
      if (status === 200) {
        userNavigateFunction("grid")
      }
      clearTimeout(timer);
    }, 5000);
  }

  // This function call while click save button
  async function excisePaymentDataSave() {
    const isValid = validateForm();
    if (isValid) {
      setIsLoading(true)
      const dataToSend = {
        ...excisePaymentData,
        status: "Pending Approval",
        approvedBy: "",
      };
      const formData = new FormData();

      try {
        console.log(file)
        const checkIfFile = (obj: any): boolean => {
          return obj instanceof File;
        }
        if (checkIfFile(file)) {
          formData.append(`file`, file);
        } else {
          formData.append(`file`, '');
        }
        formData.append('excisePaymentData', JSON.stringify(dataToSend));
        console.log(formData)
        const res = isEditMode ?
          await axiosPrivate.patch(`excise-payable/update-excise-details/${excisePaymentData.payableExciseId}`, formData
            , {
              headers: {
                'Content-Type': 'multipart/form-data', // Important: Set the content type to multipart/form-data
              },
            }) :
          await axiosPrivate.post('excise-payable/store-excise-details', formData, {
            headers: {
              'Content-Type': 'multipart/form-data', // Important: Set the content type to multipart/form-data
            },
          })
        if (res.status === 200) {
          setIsLoading(false)
          setShowAlertBox(true)
          getEditMode(false)
          setShowType("success")
          setShowMessage(res.data.message)
          clearAleart(res.status)
          dispatch(resetFormModified(false));
          // userNavigateFunction("grid")
        }
      } catch (error: any) {
        setIsLoading(false)
        if (error.status === 500) {
          setShowAlertBox(true);
          setShowType("danger")
          setShowMessage(error.response.data.error)
          clearAleart("")

        } else if (error.code === "ERR_NETWORK") {
          setShowAlertBox(true);
          setShowType("danger")
          setShowMessage(error.response.data.error)
          clearAleart("")
        } else {
          setShowAlertBox(true);
          setShowType("danger")
          setShowMessage(error.response.data.error)
          clearAleart("")
        }
      }
    } else {
      setShowAlertBox(true)
      setShowType("warning")
      setShowMessage(Object.values(updatedIsValueValidAll).every((values) => !values) ? "Some of the field(s) are not in required format." : "Mandatory field(s) should not be left blank.")
      clearAleart("")
    }

  }
  // This function call while click cancel button
  const excisePaymentDataCancel = () => {
    if (isFormDirty) {
      setShowChangesModal(true)
    } else {

      setExcisePaymentData({
        centralExciseRegistrationNo: "",
        documentType: "",
        dutyType: "",
        location: "",
        accountName: "",
        accountNumber: "",
        ifscCode: "",
        challanExpiryDate: "",
        amount: "",

      })
      modeFunction(false)
      userNavigateFunction("grid")
    }
  }

  const getDocument = (val: any) => {
    console.log(val)
    SetFile(val)
    setFileName(val.name)
  }


  const formatAmount = (amount: any) => {
    if (amount && amount !== ".") {
      const onlyNumber = amount.toString().replace(/[^0-9.]/g, "");
      // Handle empty input
      if (onlyNumber === "") {
        return "";
      }
      // Split input into integer and decimal parts
      const parts = onlyNumber.split(".");
      const integerPart = parts[0];
      const decimalPart = parts[1] || "";
      // Format the integer part with commas
      const formattedInteger = parseFloat(integerPart).toLocaleString('en-IN');
      // Handle complete decimal input (e.g., "5000.50")
      if (decimalPart !== "") {
        return `${formattedInteger}.${decimalPart}`;
      }
      // Handle incomplete decimal input (e.g., "5000.")
      if (amount.toString().endsWith(".")) {
        return `${formattedInteger}.`;
      }
      // Return formatted integer
      return formattedInteger;
    }
    return "";
  };
  useEffect(() => {
    fetchExciseNumber()
  }, [])
  const fetchExciseNumber = async () => {
    try {
      const response = await axiosPrivate.get("excise-payable/get-excise-number");
      if (response.status === 200) {
        const dropdownValues = response.data.decryptedExcisePayment.map((item: any) => ({
          label: item.centralExciseRegistrationNo,
          value: item.centralExciseRegistrationNo,
          id: item.centralExciseRegistrationNoId,
        }));
        setexciseNumberData(dropdownValues);
      }
    } catch (error) {
    }
  };
  const handleDeleteRow = async () => {
    if (currentDeleteExciseDocument) {
      try {
        const response = await axiosPrivate.patch(`excise-payable/challan/delete/${currentDeleteExciseDocument}`);
        if (response.status === 200) {
          SetFile("")
          setFileName("")
          // getOverallDetails(parseInt(kycData.companyid))
          setShowDeleteModal(false)

          setShowAlertBox(true)
          setShowType("success")
          setShowMessage(response.data.message)
          clearAleart("")
        }
      } catch (error: any) {
        setShowAlertBox(true)
        setShowType("warning")
        setShowMessage(error.message)
        clearAleart("")
      }
    } else {
      SetFile("")
      setFileName("")
      setShowDeleteModal(false)
      setShowAlertBox(true)
      setShowType("success")
      setShowMessage("Record(s) successfully deleted.")
      clearAleart("")
    }
  };

  return (
    <>
      <MainPageCard>
        <NavigateHeader
          style={{
            cursor: "pointer"
          }}
          firstValue={"Payable"}
          secondValue={"Excise Payment"} navigatePage={() => { excisePaymentDataCancel() }} />
        {componentNavigator === "form-page" &&
          <div>
            <div className='form-top-main'>
              <div style={{ height: "570px", overflow: "scroll", overflowX: "hidden" }}>
                <div className='form-input'>
                  <div className={excisePaymentDataErrors.centralExciseRegistrationNo ? 'setError' : 'dropdown-excise-no'} key={uuidv4()}>
                    <DropdownComponent
                      options={exciseNumberData}
                      title={"Central Excise Registration No"}
                      required={true}
                      // isDisabled={excisePaymentData.status === "Pending Approval"}
                      // border={excisePaymentDataErrors.centralExciseRegistrationNo ? "1px solid red" : "1px solid #A9C3DC"}
                      getData={async (val) => {
                        setIsFormDirty(true)
                        setExcisePaymentData((prevState) => ({
                          ...prevState,
                          centralExciseRegistrationNo: val.value,
                        }));
                        dispatch(resetFormModified(true));
                        const centralExciseRegNumber = val.value
                        try {
                          const res = await axiosPrivate.post("excise-payable/get-excise-details", { centralExciseRegNumber });
                          if (res.status === 200) {
                            console.log(res.data)
                            setExcisePaymentData((prevState) => ({

                              ...prevState,
                              documentType: res.data.decryptedExciseDetails.exciseDocumentType,
                              dutyType: res.data.decryptedExciseDetails.exciseDutyType,
                              location: res.data.decryptedExciseDetails.exciselocation,
                            }));
                            // setExciseDocumentType([{
                            //   label: res.data.decryptedExciseDetails[0].exciseDocumentType,
                            //   value: res.data.decryptedExciseDetails[0].exciseDocumentType
                            // }])
                            // setExciseDutyType([{
                            //   label: res.data.decryptedExciseDetails[0].exciseDutyType,
                            //   value: res.data.decryptedExciseDetails[0].exciseDutyType
                            // }])
                            // setExciseLocation(([{
                            //   label: res.data.decryptedExciseDetails[0].exciselocation,
                            //   value: res.data.decryptedExciseDetails[0].exciselocation
                            // }]))
                          }
                        } catch {

                        }
                      }}
                      defaultValue={[{ label: excisePaymentData.centralExciseRegistrationNo ? excisePaymentData.centralExciseRegistrationNo : "Select", value: excisePaymentData.centralExciseRegistrationNo }]}
                    />
                  </div>
                </div>
                <div className='form-input' style={{ opacity: "0.5" }}>
                  <div className={excisePaymentDataErrors.documentType ? 'setError' : 'dropdown-excise-no'} key={uuidv4()}>
                    <DropdownComponent
                      options={exciseDocumentType}
                      title={"Document Type"}
                      required={true}
                      isDisabled={true}
                      // isDisabled={excisePaymentData.status === "Pending Approval"}
                      // border={excisePaymentDataErrors.documentType ? "1px solid red" : "1px solid #A9C3DC"}
                      getData={(val) => {
                        setIsFormDirty(true)
                        setExcisePaymentData((prevState) => ({
                          ...prevState,
                          documentType: val.value,
                        }));
                        dispatch(resetFormModified(true));
                      }}
                      defaultValue={[{ label: excisePaymentData.documentType ? excisePaymentData.documentType : "Select", value: excisePaymentData.documentType }]}
                    />
                  </div>
                  <div className={excisePaymentDataErrors.dutyType ? 'setError' : 'dropdown-excise-no'} key={uuidv4()}>
                    <DropdownComponent
                      options={exciseDutyType}
                      title={"Duty Type"}
                      required={true}
                      isDisabled={true}
                      // isDisabled={excisePaymentData.status === "Pending Approval"}
                      // border={excisePaymentDataErrors.dutyType ? "1px solid red" : "1px solid #A9C3DC"}
                      getData={(val) => {
                        setIsFormDirty(true)
                        setExcisePaymentData((prevState) => ({
                          ...prevState,
                          dutyType: val.value,
                        }));
                        dispatch(resetFormModified(true));
                      }}
                      defaultValue={[{ label: excisePaymentData.dutyType ? excisePaymentData.dutyType : "Select", value: excisePaymentData.dutyType }]}
                    />
                  </div>
                  <div className={excisePaymentDataErrors.location ? 'setError' : 'dropdown-excise-no'} key={uuidv4()}>
                    <DropdownComponent
                      options={exciseLocation}
                      title={"Location"}
                      required={true}
                      isDisabled={true}
                      // isDisabled={excisePaymentData.status === "Pending Approval"}
                      // border={excisePaymentDataErrors.location ? "1px solid red" : "1px solid #A9C3DC"}
                      getData={(val) => {
                        setIsFormDirty(true)
                        setExcisePaymentData((prevState) => ({
                          ...prevState,
                          location: val.value,
                        }));
                        dispatch(resetFormModified(true));
                      }}
                      defaultValue={[{ label: excisePaymentData.location ? excisePaymentData.location : "Select", value: excisePaymentData.location }]}
                    />
                  </div>
                </div>
                <div className='challan-details'>{"Challan Details"}</div>
                <div className='document-view-modal'>
                  {/* //style={file !== "" ? { marginTop: "20px", opacity: "0.5", pointerEvents: "none", cursor: "not-allowed" } : { marginTop: "20px", opacity: "1", pointerEvents: "auto", cursor: "default" }} */}
                  <div style={file !== "" ? { marginTop: "20px", opacity: "0.5", pointerEvents: "none", cursor: "not-allowed" } : { marginTop: "20px", opacity: "1", pointerEvents: "auto", cursor: "default" }}>
                    <ExciseDocumentUploadModal
                      getDocument={(val: any) => { getDocument(val) }}
                      currentUpload={"others"} fileName={fileName} />
                  </div>
                  <div className='view-delete-icon'>
                    <div className='eye-icon'>
                      <div className='excise-document-view-icon'>

                        <IconButton
                          //(showDocumentModal && currentFile === reversedData.file) ? "Eye" : "ShowEye"
                          iconName={(showDocumentModal && currentFile === file) ? "Eye" : "ShowEye"}
                          height={"35px"}
                          width={"30px"}
                          fontSize={""}
                          className={"icons"}
                          cursor={file === "" ? "not-allowed" : "pointer"}
                          color={"white"}
                          backgroundColor={""}
                          opacity={file === "" ? "0.5" : "1"}
                          hover={false}
                          handleClick={() => {
                            if (file === "") {
                              return;
                            }
                            setShowDocumentModal(!showDocumentModal);
                            SetCurrentFile(file)
                          }}
                        />
                      </div>
                      <div className='delete-icon'>
                        <IconButton
                          iconName={"Delete"}
                          height={"35px"}
                          width={"35px"}
                          fontSize={"20"}
                          className={"icons"}
                          cursor={file === "" ? "not-allowed" : "pointer"}
                          // disabled={true ? true : false}
                          opacity={file === "" ? "0.5" : "1"}
                          color={"white"}
                          backgroundColor={"red"}
                          hover={false}
                          handleClick={() => {
                            if (file === "") {
                              return;
                            }
                            setShowDeleteModal(true)
                            setCurrentDeleteExciseDocument(editData.payableExciseId)
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className='form-input' style={{ marginTop: "40px" }}>
                  <InputComponent
                    height={"40px"}
                    width={"350px"}
                    margin={"0px 60px 30px 0px"}
                    padding={"0px 0px 0px 10px"}
                    border={excisePaymentDataErrors.accountName ? "1px solid red" : "1px solid #A9C3DC"}
                    borderRadius={"0px"}
                    backgroundColor={"white"}
                    color={"black"}
                    type={"text"}
                    maxLength={100}
                    inputTitle={"Account Name"}
                    // disabled={excisePaymentData.status === "Pending Approval"}
                    placeHolder={"Enter Account Name"}
                    required={true}
                    inputValue={excisePaymentData.accountName}
                    getUser={(value: any) => {
                      setIsFormDirty(true)

                      const onlyAlphabet = value.replace(/[^A-Za-z. ]+/g, '')
                      setExcisePaymentData((prevState) => ({
                        ...prevState,
                        accountName: onlyAlphabet,
                      }));
                      dispatch(resetFormModified(true));
                    }}
                  />
                  <InputComponent
                    height={"40px"}
                    width={"350px"}
                    margin={"0px 60px 30px 0px"}
                    padding={"0px 0px 0px 10px"}
                    border={excisePaymentDataErrors.accountNumber ? "1px solid red" : "1px solid #A9C3DC"}
                    borderRadius={"0px"}
                    backgroundColor={"white"}
                    color={"black"}
                    type={"mobile"}
                    maxLength={16}
                    // disabled={excisePaymentData.status === "Pending Approval"}
                    inputTitle={"Account Number"}
                    placeHolder={"Enter Account Number"}
                    required={true}
                    inputValue={excisePaymentData.accountNumber}
                    getUser={(value: any) => {
                      setIsFormDirty(true)
                      const number = value.replace(/[^0-9]/g, "")
                      setExcisePaymentData((prevState) => ({
                        ...prevState,
                        accountNumber: number,
                      }));
                      dispatch(resetFormModified(true));
                    }}
                  />
                  <InputComponent
                    height={"40px"}
                    width={"350px"}
                    margin={"0px 60px 30px 0px"}
                    padding={"0px 0px 0px 10px"}
                    border={excisePaymentDataErrors.ifscCode ? "1px solid red" : "1px solid #A9C3DC"}
                    borderRadius={"0px"}
                    backgroundColor={"white"}
                    color={"black"}
                    type={"text"}
                    maxLength={11}
                    // disabled={excisePaymentData.status === "Pending Approval"}
                    inputTitle={"IFSC Code"}
                    placeHolder={"Enter IFSC Code"}
                    required={true}
                    inputValue={excisePaymentData.ifscCode}
                    getUser={(value: any) => {
                      setIsFormDirty(true)
                      const ifsc = value.toUpperCase().replace(/[^A-Z0-9]/g, "")
                      setExcisePaymentData((prevState) => ({
                        ...prevState,
                        ifscCode: ifsc,
                      }));
                      dispatch(resetFormModified(true));
                    }}
                  />
                </div>
                <div className='form-input' style={{ marginTop: "40px" }}>
                  {/* <InputComponent
                    height={"40px"}
                    width={"350px"}
                    margin={"0px 60px 30px 0px"}
                    padding={"0px 0px 0px 10px"}
                    border={excisePaymentDataErrors.challanExpiryDate ? "1px solid red" : "1px solid #A9C3DC"}
                    borderRadius={"0px"}
                    backgroundColor={"white"}
                    color={"black"}
                    type={"mobile"}
                    maxLength={16}
                    // disabled={excisePaymentData.status === "Pending Approval"}
                    inputTitle={"Challan Expiry date"}
                    placeHolder={"Enter Challan Expiry date"}
                    required={true}
                    inputValue={excisePaymentData.challanExpiryDate}
                    getUser={(value: any) => {
                      setIsFormDirty(true)
                      const number = value.replace(/[^0-9/-]/g, "")
                      setExcisePaymentData((prevState) => ({
                        ...prevState,
                        challanExpiryDate: number,
                      }));
                      dispatch(resetFormModified(true));
                    }}
                  /> */}
                  <div>
                    <div className='title'>Challan Expiry date<span className='required'>*</span></div>
                    <input
                      type="date"
                      id="date-picker"
                      className="date-picker"
                      style={{
                        border: excisePaymentDataErrors.challanExpiryDate ? "1px solid red" : "", width: "350px"
                      }}
                      // max={todayDate}
                      value={
                        excisePaymentData.challanExpiryDate &&
                        moment(excisePaymentData.challanExpiryDate).format("YYYY-MM-DD")
                      }

                      onChange={(e: any) => {
                        if (e.target.value.length > 8) {
                          const year = e.target.value.substring(0, 4);
                          // if (parseInt(year, 10) > 2023) {
                          //   return;
                          // }
                          // if (year > 999) {
                          //   if (parseInt(year, 10) < 1900) {
                          //     return;
                          //   }
                          // }
                        }
                        setExcisePaymentData((prevState) => ({
                          ...prevState,
                          challanExpiryDate: e.target.value,
                        }));
                        setIsFormDirty(true)
                        dispatch(resetFormModified(true));
                      }}
                    />
                  </div>
                  <div style={{ marginLeft: "55px" }}>
                    <InputComponent
                      height={"40px"}
                      width={"350px"}
                      margin={"0px 60px 30px 0px"}
                      padding={"0px 5px 0px 30px"}
                      border={excisePaymentDataErrors.amount ? "1px solid red" : "1px solid #A9C3DC"}
                      borderRadius={"0px"}
                      backgroundColor={"white"}
                      color={"black"}
                      type={"text"}
                      maxLength={11}
                      // disabled={excisePaymentData.status === "Pending Approval"}
                      inputTitle={"Amount"}
                      textAlign={"right"}
                      placeHolder={"Enter Amount"}
                      required={true}
                      inputValue={formatAmount(excisePaymentData.amount)}
                      getUser={(value: any) => {
                        setIsFormDirty(true)
                        if (value.startsWith('.')) {
                          value = value.substring(1);
                        }
                        const onlyNumber = value.replace(/[^0-9]/g, "").replace(/(\..*)\./g, '$1').replace(/^(\d*\.\d{0,2}).*/, '$1');
                        setExcisePaymentData((prevState) => ({
                          ...prevState,
                          amount: onlyNumber,
                        }));
                        dispatch(resetFormModified(true));
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='footer-user' style={{ marginTop: "35px" }}>
              <ButtonComponent
                title={"Cancel"}
                height={"50px"}
                width={"150px"}
                margin={"0px 8px"}
                backgroundColor={"#888888"}
                color={"white"}
                className={"button-component-hover cancel"}
                handleClick={excisePaymentDataCancel}
              />
              {/* {!isEditMode && <ButtonComponent
                                title={"Submit for Approval"}
                                height={"50px"}
                                width={"200px"}
                                backgroundColor={"#0055D4"}
                                color={"white"}
                                margin={"0px 8px"}
                                className={"button-component-hover common-btn"}
                                handleClick={excisePaymentDataSave}
                            />
                            } */}
              {/* {isEditMode && */}
              <ButtonComponent
                title={"Submit for Approval"}
                height={"50px"}
                width={"200px"}
                // disabled={isButtonDisabled ? true : false}
                disabled={file === "" || (showMessage && showType === "success") ? true : false}
                backgroundColor={"#0055D4"}
                color={"white"}
                margin={"0px 8px"}
                className={file === "" || (showMessage && showType === "success") ? "button-component-hover disabled" : "button-component-hover common-btn"}
                handleClick={
                  excisePaymentDataSave
                }
              />
              {/* } */}
            </div>

          </div>
        }

      </MainPageCard >
      {
        showAlertBox &&
        <div className='alert-warp'>
          <AlertBox type={showType} message={showMessage} />
        </div>

      }
      {
        isLoading &&
        <Loading />
      }
      {showDeleteModal && <DeleteModal     //Delete Button Component
        clearValue={(val: boolean) => { setShowDeleteModal(val); }}
        getDelete={handleDeleteRow}
        modelType={"grid-delete"}
      />}
      {showDocumentModal &&
        <DocumentModal                    //Eye button Component
          closeDocument={(val: boolean) => { setShowDocumentModal(val); }}
          document={file}
          showDocumentModal={showDocumentModal}
        // exciseId={editData.payableExciseId}
        />}
      {
        showChangesModal &&
        <Changesmodal
          closeModal={() => setShowChangesModal(false)}
          leavePage={() => {
            setShowChangesModal(false);
            setIsFormDirty(false)
            userNavigateFunction("grid")
            getEditMode(false)
          }}
          handleClose={() => setShowChangesModal(false)} />
      }

    </>
  )
}

export default Form;