const CustomEllipsisRenderer: React.FC<any> = (params: any) => {
    const value = params.value || "";
    const maxLength = 35; // Maximum characters allowed before truncation

    let displayValue = value;
    if (value.length > maxLength) {
        displayValue = value.substring(0, maxLength) + "...";
    }

    const cellStyle: React.CSSProperties = {
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden",
        paddingRight: "3px",
    };
    return <div style={cellStyle}>{displayValue}</div>;
};

export default CustomEllipsisRenderer;

export const coloumDataSample = {
    field: '', headerName: '', minWidth: 270, headerClass: "custom-header", resizable: false, suppressMovable: false, filter: false, filterParams: { filterOptions: ['contains', 'equals'] }, cellStyle: { textAlign: 'center' }, suppressSizeToFit: false, cellRendererFramework: CustomEllipsisRenderer,
}