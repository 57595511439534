// import React, { useEffect } from 'react';

// // Declare global Razorpay interface
// declare global {
//   interface Window {
//     Razorpay: {
//       new (options: Razorpay.Options): Razorpay.RazorpayInstance;
//     };
//   }
// }

// namespace Razorpay {
//   export interface Options {
//     key: string;
//     amount: string;
//     currency: string;
//     name: string;
//     description: string;
//     image?: string;
//     order_id: string;
//     callback_url?: string;
//     // meth
//     prefill?: {
//       name?: string;
//       email?: string;
//       contact?: string;
//     };
//     notes?: {
//       address?: string;
//     };
//     theme?: {
//       color?: string;
//     };
//   }

//   export interface RazorpayInstance {
//     open(): void;
//   }
// }

// const PaymentButton: React.FC = () => {
//   useEffect(() => {
//     // Load Razorpay script dynamically
//     const script = document.createElement('script');
//     script.src = 'https://checkout.razorpay.com/v1/checkout.js';
//     script.async = true;
//     document.body.appendChild(script);

//     // Clean up script on component unmount
//     return () => {
//       document.body.removeChild(script);
//     };
//   }, []);
//   var razorpay:any = new Razorpay({
//     key: '<YOUR_KEY_ID>',
//       // logo, displayed in the payment processing popup
//     image: 'https://i.imgur.com/n5tjHFD.jpg',
//   });

//   const handlePayment = () => {
//     const options: any = {
//       key: "rzp_test_ve87Lf9GefpQ4W", // Enter the Key ID generated from the Dashboard
//     //   amount: "200", // Amount is in currency subunits. Default currency is INR. Hence, 200 refers to 200 paise
//     //   currency: "INR",
//     //   name: "Acme Corp", // Your business name
//     //   description: "Test Transaction",
//     //   image: "https://example.com/your_logo",
//     //   order_id: "order_OekhBsCZwGXcKr", // This is a sample Order ID. Replace with actual order ID
//     //   method: 'card',
//     //   'card[name]': 'Gaurav Kumar',
//     //   'card[number]': '4111111111111111',
//     //   'card[cvv]': '566',
//     //   'card[expiry_month]': '10',
//     //   'card[expiry_year]': '20',
//     //   callback_url: "https://eneqd3r9zrjok.x.pipedream.net/",
//     //   prefill: {
//     //     name: "Gaurav Kumar", // Your customer's name
//     //     email: "gaurav.kumar@example.com",
//     //     contact: "9000090000", // Provide the customer's phone number for better conversion rates
//     //   },
//     //   notes: {
//     //     address: "Razorpay Corporate Office",
//     //   },
//     //   theme: {
//     //     color: "#3399cc",
//     //   },
//     amount: 5000,
//     email: 'gaurav.kumar@example.com',
//     contact: '9123456780',
//     order_id: 'order_Oeklkl55ffigy7',
//     method: 'card',
//     'card[name]': 'Gaurav Kumar',
//     'card[number]': '4111111111111111',
//     'card[cvv]': '566',
//     'card[expiry_month]': '10',
//     'card[expiry_year]': '20'

//     };

//     // Check if Razorpay is loaded
//     if (window.Razorpay) {
//       const rzp1 = new window.Razorpay(options);
//       rzp1.open();
//     } else {
//       alert("Razorpay SDK failed to load. Please try again.");
//     }
//   };

//   return (
//     <button onClick={handlePayment} id="rzp-button1">
//       Pay
//     </button>
//   );
// };

// export default PaymentButton;


// import React, { useEffect } from 'react';

// // Declare global Razorpay interface
// declare global {
//   interface Window {
//     Razorpay: {
//       new (options: Razorpay.Options): Razorpay.RazorpayInstance;
//     };
//   }
// }

// namespace Razorpay {
//   export interface Options {
//     key: string;
//     image?: string;
//   }

//   export interface RazorpayInstance {
//     createPayment(options: PaymentOptions): void;
//   }

//   export interface PaymentOptions {
//     amount: number;
//     email: string;
//     contact: string;
//     order_id: string;
//     method: string;
//     emi_duration?: number;
//     'card[name]': string;
//     'card[number]': string;
//     'card[cvv]': string;
//     'card[expiry_month]': string;
//     'card[expiry_year]': string;
//   }
// }

// const PaymentButton: React.FC = () => {
//   useEffect(() => {
//     // Load Razorpay script dynamically
//     const script = document.createElement('script');
//     script.src = 'https://checkout.razorpay.com/v1/checkout.js';
//     script.async = true;
//     document.body.appendChild(script);

//     // Clean up script on component unmount
//     return () => {
//       document.body.removeChild(script);
//     };
//   }, []);

//   const handlePayment = () => {
//     if (window.Razorpay) {
//       const razorpay = new window.Razorpay({
//         key: 'rzp_test_ve87Lf9GefpQ4W', // Replace with your actual Key ID
//         image: 'https://i.imgur.com/n5tjHFD.jpg' // Logo displayed in the payment processing popup
//       });

//       razorpay.createPayment({
//         amount: 200, // Amount in the smallest currency unit (e.g., paise for INR)
//         email: 'gaurav.kumar@example.com',
//         contact: '9000090000',
//         order_id: 'order_Oekqto8crpkJEW', // Order ID from your backend
//         method: 'card',
//         'card[name]': 'Gaurav Kumar',
//         'card[number]': '4111111111111111',
//         'card[cvv]': '566',
//         'card[expiry_month]': '10',
//         'card[expiry_year]': '20'
//       });
//     } else {
//       alert("Razorpay SDK failed to load. Please try again.");
//     }
//   };

//   return (
//     <button onClick={handlePayment}>
//       Pay
//     </button>
//   );
// };

// export default PaymentButton;



import React, { useEffect } from 'react';
import { BASE_URL } from '../../../middleware/axois-api';

// Declare global Razorpay interface
declare global {
  interface Window {
    Razorpay: {
      new(options: Razorpay.Options): Razorpay.RazorpayInstance;
    };
  }
}

namespace Razorpay {
  export interface Options {
    key: string;
    amount: string;
    currency: string;
    // name: string;
    description: string;
    image: string;
    order_id: string;
    callback_url: string;
    prefill: any;
    notes: any;
    theme: any;
    // 'card[name]'?:string;
    // 'card[number]'?:string;
    // 'card[cvv]'?:string;
    // 'card[expiry_month]'?:string;
    // 'card[expiry_year]'?:string;
  }

  export interface RazorpayInstance {
    open(): void;
    on(event: string, callback: (response: any) => void): void;
  }
}

const PaymentButton: React.FC = () => {
  useEffect(() => {
    // Load Razorpay script dynamically
    const script = document.createElement('script');
    script.src = 'https://checkout.razorpay.com/v1/checkout.js';
    script.async = true;
    document.body.appendChild(script);

    // Clean up script on component unmount
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const handlePayment = () => {
    try {

      if (window.Razorpay) {
        // const razorpay: any = new window.Razorpay({
        //   key: 'rzp_test_ve87Lf9GefpQ4W',
        //   amount: '100', // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
        //   "currency": "INR",
        //   // "name": "Acme Corp", //your business name
        //   "description": "Test Transaction",
        //   "image": "https://example.com/your_logo",
        //   "order_id": "order_OslrUAaaffYOMO", //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
        //   // "callback_url": "https://eneqd3r9zrjok.x.pipedream.net/",
        //   "callback_url": "www.google.com",
        //   "prefill": { //We recommend using the prefill parameter to auto-fill customer's contact information especially their phone number
        //     "name": "Gaurav Kumar", //your customer's name
        //     "email": "gaurav.kumar@example.com",
        //     "contact": "9788244826", //Provide the customer's phone number for better conversion rates 
        //     'card[number]': '4111111111111111',
        //     'card[expiry]': '1123',
        //     'card[cvv]': '123'
        //   },
        //   "notes": {
        //     "address": "Razorpay Corporate Office"
        //   },
        //   "theme": {
        //     "color": "#3399cc"
        //   }
        // });

        // // prefill: {   
        // //     name: 'QARazorpay',
        // //          email: 'qa.testing@razorpay.com',     contact: '+918888888888',     'card[number]': '4111111111111111',     'card[expiry]': '1123',     'card[cvv]': '123'   } }
        // razorpay.open(); // Open Razorpay checkout

        // razorpay.on('payment.success', (response: any) => {
        //   alert(`Payment Successful! Payment ID: ${response.razorpay_payment_id}`);
        //   alert(`Order ID: ${response.razorpay_order_id}`);
        //   alert(`Signature: ${response.razorpay_signature}`);
        // });

        // razorpay.on('payment.error', (response: any) => {
        //   alert(`Payment Error: ${response.error.description}`);
        // });



        // // new version

        const options: any = {
          key: '',
          amount: '100', // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
          "currency": "INR",
          // "name": "Acme Corp", //your business name
          "description": "Test Transaction",
          "image": "https://example.com/your_logo",
          "order_id": "order_Ot1pJGkzZuDdOi", //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
          // "callback_url": "https://eneqd3r9zrjok.x.pipedream.net/",
          "callback_url": `${BASE_URL}/payment-success`,
          "prefill": { //We recommend using the prefill parameter to auto-fill customer's contact information especially their phone number
            "name": "Gaurav Kumar", //your customer's name
            "email": "gaurav.kumar@example.com",
            "contact": "9360345303", //Provide the customer's phone number for better conversion rates 
            'card[number]': '4111111111111111',
            'card[expiry]': '1123',
            'card[cvv]': '123'
          },
          "notes": {
            "address": "Razorpay Corporate Office"
          },
          "theme": {
            "color": "#3399cc"
          }
        };

        // Assuming you have the access token, set it in the Razorpay headers
        const accessToken = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJFUzI1NiJ9.eyJhdWQiOiJPOTVTRTlWNDZXaXBacSIsImp0aSI6Ik9zbGtTQ3pheEgyaDBWIiwiaWF0IjoxNzI1MzgyOTk3LCJuYmYiOjE3MjUzODI5OTcsInN1YiI6IiIsImV4cCI6MTczMzI0NTM5NywidXNlcl9pZCI6Ik9zZnlLMThQSGVPMFJrIiwibWVyY2hhbnRfaWQiOiJPc2Z5SFkxa2xTZVYxdiIsInNjb3BlcyI6WyJyZWFkX3dyaXRlIl19.sI1e31KqdKc-PZbNRP8GtV9feJPfk2Z7gJFuRdeJBUf-eYiDrY3Ieu6gSfybjM2VGhCXb0lG4Osk5C3zYPin1g'; // Replace with your actual access token

        // Initialize the Razorpay payment with access token
        const razorpayInstance: any = new window.Razorpay(options);
        const response = razorpayInstance.open({
          headers: {
            'Authorization': `Bearer ${accessToken}`
          }
        });


        // console.log(response)
      } else {
        alert('Razorpay SDK failed to load. Please try again.');
      }
    } catch (error) {
      console.log(error)
    }
  };

  return (
    <button onClick={handlePayment} id="btn">
      Pay with Razorpay12
    </button>
  );
};

export default PaymentButton;
