import React, { forwardRef, useState } from 'react'
import RadioOrCheckbox from '../../../common-component/form-elements/radio-or-checkbox';
import InputComponent from '../../../common-component/form-elements/input-component';
import TextArea from '../../../common-component/form-elements/text-area';
import ButtonComponent from '../../../common-component/form-elements/button-component';
import { useDispatch, useSelector } from 'react-redux';
import { resetFormModified } from '../../../../redux/action';

interface Props {
  onboardingViewfun: (val: any) => void;
  isEditMode: boolean;
  setShowPage: (val: any) => void;
  setIconStatus: (val: any) => void;
  iconStatus: any;

}

export interface ChildRef {
  next: (val: any) => void;
}

const Approval = forwardRef<ChildRef, Props>(({onboardingViewfun, isEditMode, setShowPage, setIconStatus, iconStatus },ref) => {
  const dispatch = useDispatch();
  const isFormModified = useSelector((state: any) => state.isFormModified);
  const [organisationUserData, setOrganisationUserData] = useState({
    organisationName: "",
    organisationCategory: "",
    subCategory: "",
    description: "",
    isActive: "Yes",
    pan: "",
    gstin: "",
    location: "",
    organisationId: "",
    address: "",
    Remarks: "",
    approval: "",
    approvalRemarks: "",
    declinerequest: "",
    activeYes: "",
    activeNo: "",
    approvalRedio: "",
  })

  const [organisationUserDataErrors, setOrganisationUserDataErrors] = useState({
    organisationName: true,
    organisationCategory: true,
    subCategory: true,
    description: true,
    isActive: true,
    Remarks: true,
    organisationId: true,
    pan: true,
    gstin: true,
    location: true,
    address: true,
  })
  const [isFormDirty, setIsFormDirty] = useState(false)
  const [showChangesModal, setShowChangesModal] = useState<boolean>(false);

  function returnGird() {
    onboardingViewfun("grid")
    dispatch(resetFormModified(false));
  }

  function cancel() {
    setShowPage("AdminDetails")
    setIconStatus({
      ...iconStatus,
      companyIcon: "selected",
    })
  }

  return (
    <div className='center'>
      <p>Status</p>
      <div className='radio-input flex-class'>
        <div className='isactive-width' >
          <div className=''>Is Active <span className='required'>*</span></div>
          <div className='radio-btn-main'>
            <RadioOrCheckbox
              value={"Yes"}
              type={"radio"}
              name={"Yes"}
              margin={"0px 30px 0px 0px"}
              // disabled={!isEditMode}
              checkedValue={organisationUserData.isActive}
              getVal={(val) => {
                setIsFormDirty(true)
                setOrganisationUserData((prevState) => ({
                  ...prevState,
                  isActive: val,
                }));
                setOrganisationUserDataErrors((prevState) => ({
                  ...prevState,
                  isActive: true,
                }));
                // dispatch(resetFormModified(true));
                // setIsDisabled(true)
                // isEditMode && setIconStatus({ ...iconStatus, organisationDetails: "inprogress" })
              }}
            />
            {
              <div
              // style={organisationUserData.isActive === "No" ? { pointerEvents: "auto", opacity: 1 } : isEditMode ? { pointerEvents: "auto", opacity: 1 } : { pointerEvents: "none", opacity: 0.5 }}
              >

                <RadioOrCheckbox
                  value={"No"}
                  type={"radio"}
                  name={"No"}
                  checkedValue={organisationUserData.isActive}
                  disabled={false}
                  getVal={(val) => {
                    setIsFormDirty(true)
                    setOrganisationUserData((prevState) => ({
                      ...prevState,
                      isActive: val,
                    }));
                    // dispatch(resetFormModified(true));
                    // isEditMode && setIconStatus({ ...iconStatus, organisationDetails: "inprogress" })
                    // setIsDisabled(true)
                  }}
                />
              </div>
            }
          </div>
        </div>

        {/* {organisationUserData.isActive === "No" &&
              <div style={{ width: "80%" }}>
                <InputComponent
                  height={"40px"}
                  width={"75%"}
                  margin={"0px 0px 30px 0px"}
                  padding={"0px 0px 0px 10px"}
 
                  borderRadius={"0px"}
                  backgroundColor={"white"}
                  color={"black"}
                  type={"text"}
                  required={true}
                  placeHolder={"Reason"}
                  maxLength={250}
                  border={
                      organisationUserDataErrors.Remarks ?
                          "1px solid #A9C3DC" : "1px solid red"
                  }
                  inputTitle={"Remarks"}
                  disabled={organisationUserData.isActive === "No" ? false : true}
                  inputValue={organisationUserData.Remarks}
                  getUser={(value: any) => {
                      setIsFormDirty(true)
                      setOrganisationUserData((prevState) => ({
                          ...prevState,
                          Remarks: value,
                      }));
                      dispatch(resetFormModified(true));
                      isEditMode && setIconStatus({ ...iconStatus, organisationDetails: "inprogress" })
                      setIsDisabled(true)
                  }}
                />
              </div>
            } */}
      </div>

      <div className='approval-remark-input'>
        <InputComponent
          height={"40px"}
          width={"100%"}
          margin={"0px 60px 30px 0px"}
          padding={"0px 0px 0px 10px"}
          borderRadius={"0px"}
          backgroundColor={"white"}
          color={"black"}
          type={"text"}
          disabled={organisationUserData.isActive === "No" ? false : true}
          placeHolder='Reason'
          inputTitle={"Remark"}
          required={true}
          autoFocus
          maxLength={25}
          inputValue={organisationUserData.approvalRemarks}
          border={
            !organisationUserDataErrors.organisationId ?
              "1px solid red" : "1px solid #A9C3DC"
          }
          getUser={(value: any) => {
            setIsFormDirty(true)
            setOrganisationUserData((prevState) => ({
              ...prevState,
              approvalRemarks: value,
            }));
            // dispatch(resetFormModified(true));
            // isEditMode && setIconStatus({ ...iconStatus, organisationDetails: "inprogress" })
            // setIsDisabled(true)
          }}
        />
      </div>
      <p>Approval</p>
      <div className='radio-input flex-class'>
        <div className='isactive-width' >
          <div className='required-approval-text'>This new required your approval.</div>
          <div className='radio-btn-main'>
            <RadioOrCheckbox
              value={"Approve"}
              type={"radio"}
              name={"Approve"}
              margin={"0px 30px 0px 0px"}
              // disabled={!isEditMode}
              checkedValue={organisationUserData.approvalRedio}
              getVal={(val) => {
                setIsFormDirty(true)
                setOrganisationUserData((prevState) => ({
                  ...prevState,
                  approvalRedio: val,
                }));
                setOrganisationUserDataErrors((prevState) => ({
                  ...prevState,
                  isActive: true,
                }));
                // dispatch(resetFormModified(true));
                // setIsDisabled(true)
                // isEditMode && setIconStatus({ ...iconStatus, organisationDetails: "inprogress" })
              }}
            />
            {
              <div
              // style={organisationUserData.isActive === "No" ? { pointerEvents: "auto", opacity: 1 } : isEditMode ? { pointerEvents: "auto", opacity: 1 } : { pointerEvents: "none", opacity: 0.5 }}
              >
                <RadioOrCheckbox
                  value={"Decline"}
                  type={"radio"}
                  name={"Decline"}
                  checkedValue={organisationUserData.approvalRedio}
                  disabled={false}
                  getVal={(val) => {
                    setIsFormDirty(true)
                    setOrganisationUserData((prevState) => ({
                      ...prevState,
                      approvalRedio: val,
                    }));
                    // dispatch(resetFormModified(true));
                    // isEditMode && setIconStatus({ ...iconStatus, organisationDetails: "inprogress" })
                    // setIsDisabled(true)
                  }}
                />
              </div>
            }
          </div>
        </div>
      </div>

      <div style={{ width: "100%" }}>
        <TextArea
          name={"Reason for declining Request"}
          placeHolder={"Reason"}
          // required={true}
          width={"100%"}
          height={"80px"}
          disabled={organisationUserData.approvalRedio === "Decline" ? false : true}
          margin={"0px 0px 30px 0px"}
          padding={"5px 0px 0px 10px"}
          maxLength={500}
          // cursor={!organisationUserData.declineRedio ? "not-allowed" : ""}
          inputValue={organisationUserData.declinerequest}
          getUser={(val) => {
            setIsFormDirty(true)
            setOrganisationUserData((prevState) => ({
              ...prevState,
              declinerequest: val,
            }));
            // dispatch(resetFormModified(true));
            // isEditMode && setIconStatus({ ...iconStatus, organisationDetails: "inprogress" })
            // setIsDisabled(true)
          }}
        />
      </div>
      <div>
            <div className='footer-company'>
              {/* {!isEditMode && */}
                <ButtonComponent
                  title={"Cancel"}
                  height={"50px"}
                  width={"150px"}
                  margin={"0px 8px"}
                  backgroundColor={"#888888"}
                  color={"white"}
                  className={"button-component-hover cancel"}
                  handleClick={isFormModified ? () => { setShowChangesModal(true) } : returnGird}
                />
              {/* }
              {
                isEditMode ?
                  <ButtonComponent
                    title={"Cancel"}
                    height={"50px"}
                    width={"150px"}
                    margin={"0px 8px"}
                    backgroundColor={"#888888"}
                    color={"white"}
                    className={"button-component-hover cancel"}
                    handleClick={isFormModified ? () => { setShowChangesModal(true) } : returnGird}
                  /> : */}
                  <ButtonComponent
                    title={"Previous"}
                    height={"50px"}
                    width={"150px"}
                    margin={"0px 8px"}
                    backgroundColor={"#0055D4"}
                    color={"white"}
                    className={"button-component-hover common-btn"}
                    handleClick={cancel}
                  />
              {/* } */}
              {/* <ButtonComponent
                title={"Save"}
                height={"50px"}
                width={"150px"}
                backgroundColor={"#0055D4"}
                color={"white"}
                margin={"0px 8px"}
                className={"button-component-hover common-btn"}
                handleClick={next}
              /> */}
              {/* <ButtonComponent
                title={isEditMode ? "Update" : "Next"}
                height={"50px"}
                width={"150px"}
                backgroundColor={"#0055D4"}
                color={"white"}
                margin={"0px 8px"}
                handleClick={next}
                disabled={statutoryDetailsMain?.length == 0 || isFormDirty}
                className={statutoryDetailsMain?.length == 0 || isFormDirty ? "button-component disabled" : "button-component-hover common-btn"}
              /> */}
            </div>
          </div>
    </div>
  )
})

export default Approval
