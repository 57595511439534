import React, { useEffect, useState } from 'react'

import { RxCross1 } from 'react-icons/rx';
import InputComponent from '../form-elements/input-component';
import ButtonComponent from '../form-elements/button-component';
import RadioOrCheckbox from '../form-elements/radio-or-checkbox';
import EmployeeDetailsGrid from '../../virtualcard/employee-details-grid';
import useAxiosPrivate from '../../../services/hooks/useaxios-private';
import AlertBox from '../alert-box';
import '../../../styles/modals/virtualcard-form-model.scss'
import Loading from './loading-screen';

interface Styles {
    clearValue: (value: any) => void;
    virtualCard: (value: any) => void;
    clearEmployeeData: (value: any) => void;
    setEmployeeDetailsAll: (value: any) => void;
    employeedetails: (value: any) => void;
    employeeDetailsAll: any
    virtualForm: any
    postdata: () => void

}
export interface userValidationRulesInterface {
    [key: string]: {
        [key: string]: {
            regex: RegExp | string;
            field: any;
            shouldNotBe: string;
        };
    }
}
export default function VirtualCardForm({ clearValue, virtualCard, employeeDetailsAll, virtualForm, employeedetails, setEmployeeDetailsAll, clearEmployeeData, postdata }: Styles) {
    const [formDataEdit, setFormDataEdit] = useState(false)
    const [userDataErrors, setUserDataErrors] = useState<Record<string, boolean>>({});
    const [showType, setShowType] = useState("warning") // error message showType
    const [showMessage, setShowMessage] = useState<string>("") // error message showMessage
    const [showAlertBox, setShowAlertBox] = useState(false) // error message ShowAlertBox
    const axiosPrivate = useAxiosPrivate();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);



    const [employeeDetails, setEmployeeDetails] = useState<any>({
        employeeName: "",
        employeeId: "",
        department: "",
        designation: "",
        emailId: "",
        contactNumber: "",
        remark: "",
        status: "Yes",
    })

    const [disableEmployeeDetails, setDisableEmployeeDetails] = useState<any>({
        // employeeName: false,
        employeeId: false,

        emailId: false,
        contactNumber: false,
        // status: false,

    })

    // warningMsg  
    const clearAleart = (status: any) => {
        const timer = setTimeout(() => {
            setShowAlertBox(false)
            setShowMessage("");
            clearTimeout(timer);
        }, 5000);
    }
    // warningMsg  
    const deleteClearAleart = (status: any) => {
        const timer = setTimeout(() => {
            setShowAlertBox(false)
            setShowMessage("");
            clearTimeout(timer);
        }, 5000);
    }

    const userValidationRules: userValidationRulesInterface = {
        virtualCard_form: {
            employeeName: {
                regex: /^[a-zA-Z][a-zA-Z /]*$/,
                field: "mandatory",
                shouldNotBe: ""
            },
            employeeId: {
                regex: /^[a-zA-Z0-9/]*$/,
                field: "mandatory",
                shouldNotBe: ""
            },
            department: {
                regex: /^[a-zA-Z][a-zA-Z /]*$/,
                field: "mandatory",
                shouldNotBe: ""
            },
            designation: {
                regex: /^[a-zA-Z][a-zA-Z /]*$/,
                field: "mandatory",
                shouldNotBe: ""
            },

            emailId: {
                regex: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                field: "mandatory",
                shouldNotBe: ""
            },
            contactNumber: {
                regex: /^[0-9]{10}$/,
                field: "mandatory",
                shouldNotBe: ""
            },
            remark: {
                regex: (employeeDetails.status !== "Yes" && formDataEdit) ? /^[^\s].*/ : "",
                field: (employeeDetails.status !== "Yes" && formDataEdit) ? "mandatory" : "",
                shouldNotBe: ""
            },

        }
    }

    const updatedIsValueValidAll: Record<string, boolean> = {};
    const validateForm = (): boolean => {
        const updatedFieldValidity: Record<string, boolean> = {};
        const isMandatoryfalse = true;
        const isRegexfalse = true;
        Object.keys(userValidationRules.virtualCard_form).forEach((field: any) => {
            const rule = userValidationRules.virtualCard_form[field];
            const value = employeeDetails[field];
            // Check if the field value meets the conditions based on rule.field and rule.shouldNotBe
            const isValueValid = (rule.field === "mandatory") ? (value !== rule.shouldNotBe) : true;
            // If a regular expression is provided, test the field value against it
            const isRegexValid = rule.regex !== "" ? new RegExp(rule.regex).test(value) : true;
            updatedFieldValidity[field] = !isValueValid || !isRegexValid;
            updatedIsValueValidAll[field] = !isValueValid
        });
        setUserDataErrors(updatedFieldValidity);
        // Check if any field has validation errors
        const isFormValid = Object.keys(updatedFieldValidity).every((keys) => {
            return !updatedFieldValidity[keys]
        });
        return isFormValid;
    };



    function retriveData(value: any, index: number) {
        if (value.id) {
            setDisableEmployeeDetails({
                employeeId: true,
                emailId: true,
                contactNumber: true,

            })
            setUserDataErrors({})
        } else {
            setDisableEmployeeDetails({
                employeeId: false,
                emailId: false,
                contactNumber: false,
            })
        }

        setFormDataEdit(true)
        if (Object.keys(value).length > 0) {
            setEmployeeDetails(
                {
                    ...value,
                    remark: value.employee_remark,
                    index: index
                }
            )
        }

    }

    async function addRecords() {
        const valid = validateForm();
        if (valid) {
            storeData()
            // let employeeDetailsAlltemp = [...employeeDetailsAll];
            // employeeDetailsAlltemp.push({ ...employeeDetails });
            // setEmployeeDetailsAll(employeeDetailsAlltemp);

            // setEmployeeDetails({
            //     employeeName: "",
            //     employeeId: "",
            //     department: "",
            //     designation: "",
            //     emailId: "",
            //     contactNumber: "",
            //     status: "Yes",
            //     remark: "",
            //     index: 0,
            // });
        } else {
            setShowAlertBox(true);
            setShowType("danger")
            setShowMessage(Object.values(updatedIsValueValidAll).every((values) => !values) ?
                "Some of the field(s) are not in required format." : "Mandatory field(s) should not be left blank.")
            clearAleart('')
        }

    }


    async function storeData() {
        try {

            const res = await axiosPrivate.post('/virtual-card/employee-details/add', employeeDetails);

            if (res.data.duplicateCheck) {
                if (!res.data.duplicateCheck.isEmployeeId && !res.data.duplicateCheck.emailId && !res.data.duplicateCheck.contactNumber) {

                } else {
                    setShowAlertBox(true);
                    setShowType("danger")
                    clearAleart("")
                    if (res.data.duplicateCheck.isEmployeeId) {
                        setShowMessage("Employee ID already exists.")
                    } else if (res.data.duplicateCheck.emailId) {
                        setShowMessage("Email already exists.")
                    } else if (res.data.duplicateCheck.contactNumber) {
                        setShowMessage("Contact Number already exists.")
                    } else {
                        setShowMessage("Some of the field(s) already available.")
                    }

                    setUserDataErrors({
                        ...userDataErrors,
                        employeeId: res.data.duplicateCheck.isEmployeeId,
                        emailId: res.data.duplicateCheck.emailId,
                        contactNumber: res.data.duplicateCheck.contactNumber
                    })

                }
            } else {
                setShowAlertBox(true)
                setShowType("success")
                setShowMessage(res.data.message)
                // setIsLoading(false)
                clearAleart("")
                // clearAleart(res.status)
                handelAdd()
            }
        } catch (error: any) {
            console.log(error)
            // clearValue(false)
        }
    }

    // employee delete api 

    function handelAdd() {
        postdata()
        // if (formDataEdit) {
        let data: any = []
        data.push(employeeDetails)
        employeeDetailsAll.length > 0 && employeeDetailsAll.map((ele: any, i: number) => {
            if (i === employeeDetails.index) {
                data.push({ ...employeeDetails, ["emailId"]: (employeeDetails.emailId).toLowerCase() })
            } else {
                data.push(ele)
            }
        })

        setEmployeeDetailsAll(data)
        setFormDataEdit(false)
        setEmployeeDetails(
            {
                employeeName: "",
                employeeId: "",
                department: "",
                designation: "",
                emailId: "",
                contactNumber: "",
                remark: "",
                status: "Yes",
                index: 0
            }
        )
        setDisableEmployeeDetails({
            emailId: false,
            employeeId: false,
            contactNumber: false,
            // employeeName: false,
            // department: false,
            // designation: false,
            // status: false,
        })
        // }
    }

    // delete function api
    const adminDataDelete = (id: any, index: any) => {
        if (id) {
            axiosPrivate.delete(`/virtual-card/employee-details/delete/${id}`)
                .then(response => {
                    if (response.status === 200) {
                        setShowAlertBox(true);
                        setShowType("success")
                        setShowMessage(response.data.message)
                        deleteClearAleart('')
                        postdata()
                        clearEmployeeData({
                            ...virtualForm,
                            employeeName: ""
                        })

                        employeedetails({})

                    }
                })
                .catch(error => {
                    setShowAlertBox(true);
                    setShowType("danger")
                    setShowMessage(error.response.data.error)
                    deleteClearAleart('')
                });
        }
    }


    return (
        <>
            <div className={"department-add-modal-virtual"}>
                <div className={"container-virtual"}>
                    <div className={"close-icon"}>
                        <span className={"cross-icon"}>
                            <RxCross1 onClick={() => { clearValue(false) }
                            } style={{ fontSize: "40px" }} />
                        </span>
                    </div>
                    <div>
                        <p className='employee-form-title'>
                            Employee Details
                        </p>
                    </div>
                    <div className='sub-container-virtual'>
                        <div className='  department-virtual'>
                            <InputComponent
                                height={"40px"}
                                width={"350px"}
                                margin={"0px 60px 30px 0px"}
                                padding={"0px 0px 0px 10px"}
                                border={userDataErrors.employeeName ?
                                    "1px solid red" : "1px solid #A9C3DC"}
                                borderRadius={"0px"}
                                backgroundColor={"white"}
                                color={"black"}
                                type={"text"}
                                maxLength={50}
                                inputTitle={"Employee Name"}
                                autoFocus
                                placeHolder={"Enter Employee Name"}
                                required={true}
                                inputValue={employeeDetails.employeeName}
                                getUser={(val: any) => {
                                    setEmployeeDetails({
                                        ...employeeDetails,
                                        employeeName: val
                                    });
                                }}
                            />
                            <div >
                                <InputComponent
                                    height={"40px"}
                                    width={"350px"}
                                    margin={"0px 60px 30px 0px"}
                                    padding={"0px 0px 0px 10px"}
                                    border={userDataErrors.employeeId ?
                                        "1px solid red" : "1px solid #A9C3DC"}
                                    borderRadius={"0px"}
                                    backgroundColor={"white"}
                                    color={"black"}
                                    type={"text"}
                                    maxLength={15}
                                    inputTitle={"Employee ID"}
                                    placeHolder={"Enter Employee ID"}
                                    disabled={disableEmployeeDetails.employeeI}
                                    required={true}
                                    inputValue={employeeDetails.employeeId}
                                    getUser={(val: any) => {
                                        setEmployeeDetails({
                                            ...employeeDetails,
                                            employeeId: val
                                        });
                                    }}
                                />
                            </div>
                            <InputComponent
                                height={"40px"}
                                width={"350px"}
                                margin={"0px 10px 30px 0px"}
                                padding={"0px 0px 0px 10px"}
                                border={userDataErrors.department ?
                                    "1px solid red" : "1px solid #A9C3DC"}
                                borderRadius={"0px"}
                                backgroundColor={"white"}
                                color={"black"}
                                type={"text"}
                                maxLength={50}
                                inputTitle={"Department"}
                                placeHolder={"Enter Department"}
                                required={true}
                                inputValue={employeeDetails.department}
                                getUser={(val: any) => {
                                    setEmployeeDetails({
                                        ...employeeDetails,
                                        department: val
                                    });
                                }}
                            />
                        </div>
                        <div className='  department-virtual'>
                            <InputComponent
                                height={"40px"}
                                width={"350px"}
                                margin={"0px 60px 30px 0px"}

                                padding={"0px 0px 0px 10px"}
                                border={userDataErrors.designation ?
                                    "1px solid red" : "1px solid #A9C3DC"}
                                borderRadius={"0px"}
                                backgroundColor={"white"}
                                color={"black"}
                                type={"text"}
                                maxLength={50}
                                inputTitle={"Designation"}
                                placeHolder={"Enter Designation"}
                                required={true}
                                inputValue={employeeDetails.designation}
                                getUser={(val: any) => {

                                    setEmployeeDetails({
                                        ...employeeDetails,
                                        designation: val
                                    });
                                }}
                            />
                            <div>
                                <InputComponent
                                    height={"40px"}
                                    width={"350px"}
                                    margin={"0px 60px 30px 0px"}
                                    padding={"0px 50px 0px 10px"}
                                    borderRadius={"0px"}
                                    backgroundColor={"white"}
                                    color={"black"}
                                    type={"text"}
                                    placeHolder={"Enter Email ID"}
                                    inputTitle={"Email ID"}
                                    required={true}
                                    maxLength={254}
                                    className={"gmail-input"}
                                    inputValue={employeeDetails.emailId}
                                    inputField={"gmail"}
                                    border={userDataErrors.emailId ?
                                        "1px solid red" : "1px solid #A9C3DC"}
                                    getUser={(val) => {
                                        setEmployeeDetails({
                                            ...employeeDetails,
                                            emailId: val
                                        });
                                    }}
                                />
                            </div>
                            <div>
                                <InputComponent
                                    height={"40px"}
                                    width={"350px"}
                                    margin={"0px 60px 30px 0px"}
                                    padding={"0px 0px 0px 70px"}
                                    borderRadius={"0px"}
                                    backgroundColor={"white"}
                                    color={"black"}
                                    type={"mobile"}
                                    placeHolder={"Enter Contact Number"}
                                    maxLength={10}
                                    inputTitle={"Contact Number"}
                                    required={true}
                                    className={"mobilenumber-input"}
                                    inputValue={employeeDetails.contactNumber}
                                    border={userDataErrors.contactNumber ?
                                        "1px solid red" : "1px solid #A9C3DC"}
                                    getUser={(Number) => {
                                        // setIsFormDirty(true)
                                        const value = Number.replace(/[^0-9]/g, "")
                                        setEmployeeDetails({
                                            ...employeeDetails,
                                            contactNumber: value
                                        });
                                    }}
                                />
                            </div>
                        </div>
                        <div className='  department-virtual'>
                            <div className='radio-input organisation-radio-input'>
                                <div className=''>Is Active<span className='required'>*</span></div>
                                <div className='radio-btn-virtual' >
                                    <RadioOrCheckbox
                                        value={"Yes"}
                                        type={"radio"}
                                        name={"Yes"}
                                        checkedValue={employeeDetails.status}
                                        getVal={(val) => {
                                            setEmployeeDetails({
                                                ...employeeDetails,
                                                status: val,
                                                remark: ""
                                            });
                                            setUserDataErrors({})
                                        }}
                                    />
                                    {
                                        <div style={employeeDetails.status === "No" ? { pointerEvents: "auto", opacity: 1 } : formDataEdit ? { pointerEvents: "auto", opacity: 1 } : { pointerEvents: "none", opacity: 0.5 }}>

                                            <RadioOrCheckbox
                                                value={"No"}
                                                name={"No"}
                                                type={"radio"}

                                                checkedValue={employeeDetails.status}
                                                getVal={(val) => {

                                                    setEmployeeDetails({
                                                        ...employeeDetails,
                                                        status: val
                                                    });
                                                }}
                                            />
                                        </div>
                                    }
                                </div>
                            </div>
                            <div style={employeeDetails.status === "No" ? { pointerEvents: "auto", opacity: 1 } : { pointerEvents: "none", opacity: 0.5 }} >
                                <InputComponent
                                    height={"40px"}
                                    width={windowWidth > 1870 ? "760px" : windowWidth > 1500 ? "500px" : windowWidth > 1190 ? "450px" : "350px"}
                                    margin={"0px 60px 30px 0px"}

                                    padding={"0px 0px 0px 10px"}
                                    border={userDataErrors.remark ?
                                        "1px solid red" : "1px solid #A9C3DC"}
                                    borderRadius={"0px"}
                                    backgroundColor={"white"}
                                    color={"black"}
                                    type={"text"}
                                    maxLength={250}
                                    disabled={employeeDetails.status === "Yes"}
                                    inputTitle={"Remark"}
                                    placeHolder={"Reason"}
                                    required={true}
                                    inputValue={employeeDetails.remark}
                                    getUser={(val: any) => {
                                        setEmployeeDetails({
                                            ...employeeDetails,
                                            remark: val
                                        });
                                    }}
                                />
                            </div>
                        </div>
                        <div>
                            <ButtonComponent
                                title={formDataEdit ? "Update" : "Save"
                                }
                                height={"50px"}
                                width={"150px"}
                                backgroundColor={"#0055D4"}
                                color={"white"}
                                margin={"0px 8px"}
                                className={"button-component-hover common-btn"}
                                handleClick={() => {
                                    addRecords()

                                }}
                            />
                        </div>
                        <div>
                            <EmployeeDetailsGrid
                                employeeDetailsAll={employeeDetailsAll}
                                retriveData={retriveData}
                                checkDelete={(id: any, index: any) => { adminDataDelete(id, index) }}
                                clearValue={clearValue}
                            />
                        </div>
                        {
                            isLoading &&
                            <Loading />
                        }

                        {
                            showAlertBox &&
                            <div className='alert-warp'>
                                <AlertBox type={showType} message={showMessage} />
                            </div>
                        }
                    </div>
                </div>
            </div>

        </>
    )
}
