import React from 'react'

const KYCDocuments = () => {
  return (
    <div>
      KYCDocuments
      KYCDocuments
    </div>
  )
}

export default KYCDocuments
