import React, { useEffect, useState } from 'react'
import { BiPlus, BiMinus } from "react-icons/bi";
import "../../../styles/view-table.scss";
import IconButton from '../../../components/common-component/form-elements/icon-button';
import "../../../styles/onboarding/company/grid-icon.scss"
import AlertBox from '../../../components/common-component/alert-box';


interface Props {
    approvalData: any;
    retriveData: (value: any, index: number) => void;
    showmodal: (value: any, index: number) => void;
}

function ApprovalProcessGrid({ approvalData, retriveData, showmodal }: Props) {


    const [showType, setShowType] = useState("warning") // error message showType
    const [showMessage, setShowMessage] = useState<string>("") // error message showMessage
    const [showAlertBox, setShowAlertBox] = useState(false) // error message ShowAlertBox

    const formatAmount = (amount: any) => {
        const onlyNumber = amount?.toString().replace(/[^0-9.]/g, "");
        if (!onlyNumber) { return ""; }
        // Split input into integer and decimal parts     
        // const parts = onlyNumber?.split(".");
        const integerPart = onlyNumber; const decimalPart = "";
        // Format the integer part with commas      
        const formattedInteger = parseFloat(integerPart).toLocaleString('en-IN');
        // Handle complete decimal input (e.g., "5000.50") 
        if (decimalPart !== "") { return `${formattedInteger}.${decimalPart}`; }
        // Handle incomplete decimal input (e.g., "5000.")        
        if (amount?.toString().endsWith(".")) { return `${formattedInteger}.`; }
        // Return formatted integer 
        return formattedInteger;
    };
    const clearAlert = () => {
        const timer = setTimeout(() => {
            setShowAlertBox(false);
            setShowMessage("");
            clearTimeout(timer);
        }, 5000);
    }


    function handleEdit(data: any, index: any) {
        if (data?.noSwitchApprovalProcess === true) {
            setShowAlertBox(true)
            setShowType("danger");
            setShowMessage("Cannot edit data, dependency exists.");
            clearAlert();
        } else {
            retriveData(data, index)
        }

    }
    function handleDelete(data: any, index: any) {
        if (data?.noSwitchApprovalProcess === true) {
            setShowAlertBox(true)
            setShowType("danger");
            setShowMessage("Cannot edit data, dependency exists.");
            clearAlert();
        } else {
            showmodal(data, index)
        }

    }


    return (
        <>
            <div className='view-table-component' style={{ marginTop: "40px", marginBottom: "40px", height: "205px", overflowY: "auto" }}>
                <table className='company-seperate-grid'>
                    <thead>
                        <tr>
                            <th className='cus-width' style={{ paddingLeft: "15px", minWidth: "250px" }}>Department</th>
                            <th className='cus-width' style={{ minWidth: "180px", textAlign: "center" }}>Min</th>
                            <th className='cus-width' style={{ minWidth: "180px", textAlign: "center" }}>Max</th>
                            <th className='cus-width' style={{ minWidth: "250px" }}>Maker</th>
                            <th className='cus-width' style={{ minWidth: "250px" }}>Checker</th>
                            <th className='cus-width' style={{ minWidth: "250px" }}>Recommender</th>
                            <th className='cus-width' style={{ minWidth: "250px" }}>Approver</th>
                            <th className='' style={{ minWidth: "120px", paddingLeft: "30px" }}>Action</th>
                        </tr>
                    </thead>
                    <tbody >
                        {!approvalData || approvalData?.length === 0 || (approvalData?.length === 1 && approvalData[0]?.approvalType === "Single") ?
                            <tr>
                                <td className='style-grid'>No records to show</td>
                            </tr> :
                            approvalData?.map((data: any, index: number) => (
                                data.approvalType == "Group" && <div className='main-body-header'>
                                    <tr key={index}>
                                        <td className='left-icons' style={{ paddingLeft: "15px", minWidth: "250px", paddingRight: "20px" }}>{data.departmentValue}</td>
                                        <td className='' style={{ minWidth: "180px", textAlign: "center" }}>{formatAmount(data.min)}</td>
                                        <td className='' style={{ minWidth: "180px", textAlign: "center" }}>{formatAmount(data.max)}</td>
                                        <td className='left-icons' style={{ minWidth: "250px" }}>{data.makerValue || data.makerGroupValue}</td>
                                        <td className='left-icons' style={{ minWidth: "250px" }}>{data.checkerValue || data.checkerGroupValue}</td>
                                        <td className='left-icons' style={{ minWidth: "250px" }}>{data.recommenderValue || data.recommenderGroupValue}</td>
                                        <td className='left-icons' style={{ minWidth: "250px" }}>{data.approverValue || data.approverGroupValue}</td>
                                        <td className='last-icon flexcls' style={{ minWidth: "110px", justifyContent: "center" }}>
                                            <IconButton
                                                iconName={"Edit"}
                                                height={"40px"}
                                                width={"40px"}
                                                fontSize={"30px"}
                                                margin={"0px 8px"}
                                                color={"white"}
                                                backgroundColor={"#0055D4"}
                                                hover={true}
                                                handleClick={() => {
                                                    handleEdit(data, index)

                                                }}
                                            />
                                            <IconButton
                                                iconName={"Delete"}
                                                height={"40px"}
                                                width={"40px"}
                                                fontSize={"25px"}
                                                margin={"0px 8px"}
                                                color={"white"}
                                                backgroundColor={"#D60000"}
                                                hover={true}
                                                handleClick={() => {
                                                    handleDelete(data, index)
                                                }}
                                            />
                                        </td>
                                    </tr>
                                </div>
                            ))}
                    </tbody>
                </table>
                {
                    showAlertBox &&
                    <div className='alert-warp'>
                        <AlertBox type={showType} message={showMessage} />
                    </div>

                }
            </div>
        </>
    )
}

export default ApprovalProcessGrid;