import React, { useEffect, useState } from 'react'

import { RxCross1 } from 'react-icons/rx';
import InputComponent from '../form-elements/input-component';
import ButtonComponent from '../form-elements/button-component';
import RadioOrCheckbox from '../form-elements/radio-or-checkbox';
import EmployeeDetailsGrid from '../../virtualcard/employee-details-grid';
import useAxiosPrivate from '../../../services/hooks/useaxios-private';
import AlertBox from '../alert-box';
import '../../../styles/modals/virtualcard-form-model.scss'
import Loading from './loading-screen';
import DirectorDinTable from '../../../config/organisation/vendor/vendor-approval-grid';
import CompanyDirectorDinTable from '../../../config/organisation/vendor/vendor-approval-grid';
import VendorDirectorDinTable from '../../../config/organisation/vendor/vendor-approval-din-grid';

interface Styles {
    clearValue: (value: any) => void;
    vendorDirectorsInfo: (value: any) => void;
    companyDirectorsInfo: (value: any) => void;

}
export interface userValidationRulesInterface {
    [key: string]: {
        [key: string]: {
            regex: RegExp | string;
            field: any;
            shouldNotBe: string;
        };
    }
}
export default function VendorApprovalModal({ clearValue, vendorDirectorsInfo, companyDirectorsInfo }: Styles) {
    const [formDataEdit, setFormDataEdit] = useState(false)
    const [userDataErrors, setUserDataErrors] = useState<Record<string, boolean>>({});
    const [showType, setShowType] = useState("warning") // error message showType
    const [showMessage, setShowMessage] = useState<string>("") // error message showMessage
    const [showAlertBox, setShowAlertBox] = useState(false) // error message ShowAlertBox
    const axiosPrivate = useAxiosPrivate();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    return (
        <>
            <div className={"department-add-modal-virtual"}>
                <div className={"container-virtual"}>
                    <div className={"close-icon"}>
                        <span className={"cross-icon"}>
                            <RxCross1 onClick={() => { clearValue(false) }
                            } style={{ fontSize: "40px" }} />
                        </span>
                    </div>
                    <div>
                        <p className='employee-form-title'>
                            Director's Verification
                        </p>
                    </div>
                    <div className='sub-container-virtual'>
                        <div style={{ fontWeight: "500", fontSize: "20px" }}>{"Company Director's Info"}</div>
                        <div style={{ marginRight: "5px" }}>
                            <CompanyDirectorDinTable
                                directorApiData={companyDirectorsInfo}
                                retriveData={() => {

                                }}
                                showmodal={(val: any, index: any) => {

                                }}
                            />
                        </div>
                        <div style={{ fontWeight: "500", fontSize: "20px" }}>{"Vendor Director's Info"}</div>
                        <div style={{ marginRight: "5px" }}>
                            <VendorDirectorDinTable
                                directorApiData={vendorDirectorsInfo}
                                retriveData={() => {

                                }}
                                showmodal={(val: any, index: any) => {

                                }}
                            />
                        </div>
                        <div className='vendor-director-din'>
                            <ButtonComponent
                                title={"Ok"}
                                height={"50px"}
                                width={"150px"}
                                backgroundColor={"#0055D4"}
                                color={"white"}
                                margin={"0px 0px"}
                                className={"button-component-hover common-btn"}
                                handleClick={() => {
                                    clearValue(false)

                                }}
                            />
                        </div>
                        {
                            isLoading &&
                            <Loading />
                        }

                        {
                            showAlertBox &&
                            <div className='alert-warp'>
                                <AlertBox type={showType} message={showMessage} />
                            </div>
                        }
                    </div>
                </div>
            </div>

        </>
    )
}
