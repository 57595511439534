export const CustomerGroupByOptions = [
    { label: "Organisation", value: "Organisation" },
    // { label: "Customer", value: "Customer" },
    { label: "Year", value: "Year" },
    { label: "Region", value: "Region" },
    { label: "State", value: "State" },
    { label: "City", value: "City" },
];

export const paCustomerCheckBoxValue = {
    "organisationName": true,
    "companyName": true,
    "year": true,
    "month": true,
    "amount": true,
    "region": true,
    "state": true,
    "city": true,
    "cardType": true,
    "paymentMode": true,
    "cardNetwork": true,
    "segments": true,
}

export const customersVsTransactionfilterDatas: any = {
    "Organisation": [
        {
            cardTitle: "Organisation",
            cardType: "dropdown",
            selectedValue: "All",
            options: [
                { value: "All", label: "All" },
            ],
            isOpen: false
        },
        {
            cardTitle: "Customer",
            cardType: "dropdown",
            selectedValue: "All",
            options: [
                { value: "All", label: "All" },
            ],
            isOpen: false
        },
        {
            cardTitle: "Y value setting",
            cardType: "dropdown",
            selectedValue: "Percentage",
            options: [
                { value: "Value", label: "Value (cr)" },
                { value: "Percentage", label: "Percentage" },
            ],
            isOpen: false
        },
    ],
    // "Customer": [
    //     {
    //         cardTitle: "Organisation",
    //         cardType: "dropdown",
    //         selectedValue: "All",
    //         options: [
    //             { value: "All", label: "All" },
    //         ],
    //         isOpen: false
    //     },
    //     {
    //         cardTitle: "Customer",
    //         cardType: "dropdown",
    //         selectedValue: "All",
    //         options: [
    //             { value: "All", label: "All" },
    //         ],
    //         isOpen: false
    //     },
    //     {
    //         cardTitle: "Y value setting",
    //         cardType: "dropdown",
    //         selectedValue: "Percentage",
    //         options: [
    //             { value: "Value", label: "Value (cr)" },
    //             { value: "Percentage", label: "Percentage" },
    //         ],
    //         isOpen: false
    //     },
    // ],
    "Year": [
        {
            cardTitle: "Organisation",
            cardType: "dropdown",
            selectedValue: "All",
            options: [
                { value: "All", label: "All" },
            ],
            isOpen: false
        },
        {
            cardTitle: "Customer",
            cardType: "dropdown",
            selectedValue: "All",
            options: [
                { value: "All", label: "All" },
            ],
            isOpen: false
        },
        {
            cardTitle: "Year",
            cardType: "dropdown",
            selectedValue: "All",
            options: [
                { value: "All", label: "All" },
            ],
            isOpen: false
        },
        {
            cardTitle: "Y value setting",
            cardType: "dropdown",
            selectedValue: "Percentage",
            options: [
                { value: "Value", label: "Value (cr)" },
                { value: "Percentage", label: "Percentage" },
            ],
            isOpen: false
        },
    ],
    "Region": [
        {
            cardTitle: "Organisation",
            cardType: "dropdown",
            selectedValue: "All",
            options: [
                { value: "All", label: "All" },
            ],
            isOpen: false
        },
        {
            cardTitle: "Customer",
            cardType: "dropdown",
            selectedValue: "All",
            options: [
                { value: "All", label: "All" },
            ],
            isOpen: false
        },
        {
            cardTitle: "Region",
            cardType: "dropdown",
            selectedValue: "All",
            options: [
                { value: "All", label: "All" },
                { value: "North", label: "North" },
                { value: "South", label: "South" },
                { value: "East", label: "East" },
                { value: "West", label: "West" },
            ],
            isOpen: false
        },
        {
            cardTitle: "Y value setting",
            cardType: "dropdown",
            selectedValue: "Percentage",
            options: [
                { value: "Value", label: "Value (cr)" },
                { value: "Percentage", label: "Percentage" },
            ],
            isOpen: false
        },
    ],
    "State": [
        {
            cardTitle: "Organisation",
            cardType: "dropdown",
            selectedValue: "All",
            options: [
                { value: "All", label: "All" },
            ],
            isOpen: false
        },
        {
            cardTitle: "Customer",
            cardType: "dropdown",
            selectedValue: "All",
            options: [
                { value: "All", label: "All" },
            ],
            isOpen: false
        },
        {
            cardTitle: "Region",
            cardType: "dropdown",
            selectedValue: "All",
            options: [
                { value: "All", label: "All" },
                { value: "North", label: "North" },
                { value: "South", label: "South" },
                { value: "East", label: "East" },
                { value: "West", label: "West" },
            ],
            isOpen: false
        },
        {
            cardTitle: "State",
            cardType: "dropdown",
            selectedValue: "All",
            options: [
                { value: "All", label: "All" },
            ],
            isOpen: false
        },
        {
            cardTitle: "Y value setting",
            cardType: "dropdown",
            selectedValue: "Percentage",
            options: [
                { value: "Value", label: "Value (cr)" },
                { value: "Percentage", label: "Percentage" },
            ],
            isOpen: false
        },
    ],
    "City": [
        {
            cardTitle: "Organisation",
            cardType: "dropdown",
            selectedValue: "All",
            options: [
                { value: "All", label: "All" },
            ],
            isOpen: false
        },
        {
            cardTitle: "Customer",
            cardType: "dropdown",
            selectedValue: "All",
            options: [
                { value: "All", label: "All" },
            ],
            isOpen: false
        },
        {
            cardTitle: "Region",
            cardType: "dropdown",
            selectedValue: "All",
            options: [
                { value: "All", label: "All" },
                { value: "North", label: "North" },
                { value: "South", label: "South" },
                { value: "East", label: "East" },
                { value: "West", label: "West" },
            ],
            isOpen: false
        },
        {
            cardTitle: "State",
            cardType: "dropdown",
            selectedValue: "All",
            options: [
                { value: "All", label: "All" },
            ],
            isOpen: false
        },
        {
            cardTitle: "Y value setting",
            cardType: "dropdown",
            selectedValue: "Percentage",
            options: [
                { value: "Value", label: "Value (cr)" },
                { value: "Percentage", label: "Percentage" },
            ],
            isOpen: false
        },
    ],
}

const CustomEllipsisRenderer: React.FC<any> = (params: any) => {
    const value = params.value || "";
    const maxLength = 30; // Maximum characters allowed before truncation

    let displayValue = value;
    if (value.length > maxLength) {
        displayValue = value.substring(0, maxLength) + "...";
    }

    const cellStyle: React.CSSProperties = {
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden",
        paddingRight: "3px",
    };

    return <div style={cellStyle}>{displayValue}</div>;
};

export default CustomEllipsisRenderer;

export const coloumDataSample = {
    field: '', headerName: '', minWidth: 250, headerClass: "custom-header", resizable: true, suppressMovable: true, filter: true, filterParams: { filterOptions: ['contains', 'equals'] }, cellStyle: { textAlign: 'center' }, suppressSizeToFit: false, cellRendererFramework: CustomEllipsisRenderer,
}

export const customersVsTransactionOptions = {
    maintainAspectRatio: false,
    type: 'line',
    responsive: true,
    indexAxis: 'y' as const,
    layout: {},
    scales: {
        x: {
            title: {
                display: true,
                text: 'Transaction Value ( % )',
                font: {
                    color: '#333333',
                    weight: 'bold',
                    size: 14, // You can adjust the font size as needed
                    letterSpacing: 3,
                },
            },
            grid: {
                display: false,
                stacked: true,
                barPercentage: 1.5,
                categoryPercentage: 0.8,
                barThickness: 10,
            },
        },
        y: {
            title: {
                display: true,
                beginAtZero: true,
                font: {
                    color: '#333333',
                    weight: 'bold',
                    size: 14, // You can adjust the font size as needed
                    letterSpacing: 3,
                },
                padding: {
                    bottom: 30, // Adjust the value as needed to control the spacing
                 },
                text: '',
            },
            grid: {
                display: true,
                stacked: true,
                color: '#B3CAE1',
                barPercentage: 1.5,
                categoryPercentage: 0.8,
                barThickness: 10,
            },
        },
    },
    plugins: {
        tooltip: {
            enabled: false
        },
        legend: {
            display: false,
            position: 'bottom' as const,
        },
        title: {
            display: false,
            font: {
                weight: 'bold',
                size: 25,
            },
            padding: 10,
            text: 'Utilization - Customer (Top 5)',
        },
    },
};

export const customersVsTransactionData = {
    labels: [],
    datasets: [
        {
            barPercentage: 1.1,
            barThickness: 25,
            data: [],
            backgroundColor: [],
            borderColor: [],
            borderWidth: 1
        }
    ]
};