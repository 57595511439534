import React, { useEffect, useState } from 'react'
import MainPageCard from '../../common-component/cards/main-page-card'
import NavigateHeader from '../../common-component/navigate-header'
import { ReactComponent as Electricityicon } from '../../../assets/icons/electricity/Electricity.svg'
import { ReactComponent as Mobile } from '../../../assets/icons/electricity/Mobile.svg'
import { ReactComponent as DTH } from '../../../assets/icons/electricity/DTH.svg'
import { ReactComponent as Fastag } from '../../../assets/icons/electricity/Fastag.svg'
import { ReactComponent as MunicipalTax } from '../../../assets/icons/electricity/MunicipalTax.svg'
import { ReactComponent as GasCylinder } from '../../../assets/icons/electricity/GasCylinder.svg'
import { ReactComponent as Broadband } from '../../../assets/icons/electricity/Broadband.svg'
import { ReactComponent as Waterbill } from '../../../assets/icons/electricity/waterbill.svg'
import { ReactComponent as Pipedgas } from '../../../assets/icons/electricity/pipedgas.svg'
import { ReactComponent as Insurance } from '../../../assets/icons/electricity/Insurance.svg'
import Electricity from './Electricity/electricity-home'
import "../../../styles/payable/utility/utility-form.scss"

interface UtilityFormProps {
    utilityNavigator: string
    userNavigateFunction: (val: string) => void;
}
const UtilityForm: React.FC<UtilityFormProps> = ({ }) => {
    const [selectedUtility, setSelectedUtility] = useState("")

    const utilityIcons = [
        { name: 'Electricity', icon: <Electricityicon />, },
        { name: 'Mobile', icon: <Mobile />, },
        { name: 'Gas', icon: <GasCylinder />, },
        { name: 'Piped Gas', icon: <Pipedgas />, },
        { name: 'Water Bill', icon: <Waterbill />, },
        { name: 'Broadband', icon: <Broadband />, },
        { name: 'DTH', icon: <DTH />, },
        { name: 'Insurance', icon: <Insurance />, },
        { name: 'Municipal Tax', icon: <MunicipalTax />, },
        { name: 'FASTag', icon: <Fastag />, },

    ];


    function handleSignup(iconName: any) {
        setSelectedUtility(iconName)
    }


    return (
        <div>
            <MainPageCard>
                <NavigateHeader
                    style={{
                        cursor: "pointer"
                    }}
                    firstValue={"Payable"}
                    secondValue={"Utility"} navigatePage={() => { "" }} />
                <div>
                    <div className='wrapper-utility'>
                        {
                            utilityIcons.map((iconData, index) => (
                                <>
                                    <div className={selectedUtility === iconData.name ? 'utility-active' : "utility-icon-main"} key={index} onClick={() => handleSignup(iconData.name)}>
                                        <div className={"utility-icon"} >{iconData.icon}</div>
                                        <div className='utility-icon-text'>
                                            <p>{iconData.name}</p>
                                        </div>
                                        <div className='boder-line'>
                                        </div>
                                    </div>

                                </>
                            ))}
                    </div>
                    {
                        selectedUtility === "Electricity" &&
                        <Electricity />

                    }
                </div >
            </MainPageCard >
        </div >
    )
}
export default UtilityForm;

