import React, { useEffect, useState } from 'react'
import { BiPlus, BiMinus } from "react-icons/bi";
import "../../../styles/view-table.scss";
import IconButton from '../../../components/common-component/form-elements/icon-button';
import DeleteModal from '../../../components/common-component/modals/delete-modal';
import "../../../styles/onboarding/company/grid-icon.scss"




interface Props {

    directorApiData: any
    retriveData: (value: any, index: number) => void;
    showmodal: (value: any, index: number) => void;
    mainApiData: any;
}

function DirectorTable({ directorApiData, mainApiData, retriveData, showmodal
}: Props) {
    return (
        <>
            <div className='view-table-component' style={{ marginTop: "20px" }}>
                <table>
                    <thead>
                        <tr>
                            <th className='align-differ' style={{ width: "50%" }}>Name</th>
                            <th className='align-din' style={{ paddingLeft: "20px" }}>DIN</th>
                            <th className='align-left'>Designation</th>
                            <th className='' style={{ paddingLeft: "7%", width: "20%" }}>Action</th>
                        </tr>
                    </thead>
                    <tbody >
                        {!directorApiData || directorApiData?.length === 0 ?
                            <tr>
                                <td className='style-grid' colSpan={4}>No records to show</td>
                            </tr> :
                            directorApiData?.map((data: any, index: number) => (
                                <div className='main-body-header' key={index}>
                                    <tr>
                                        <td className='align-differ' style={{ width: "50%" }}>{data?.DirectorName}</td>
                                        <td className='align-din'>{data?.DirectorDin}</td>
                                        <td className='align-left'>{data?.DirectorDesignation}</td>
                                        <td className='last-icon flexcls'>
                                            <IconButton
                                                iconName={"Edit"}
                                                height={"40px"}
                                                width={"40px"}
                                                fontSize={"30px"}
                                                margin={"0px 8px"}
                                               
                                                color={"white"}
                                                disabled={mainApiData?.kycApprovalStatus === "Approved" ? true : false}
                                                backgroundColor={"#0055D4"}
                                                hover={mainApiData?.kycApprovalStatus === "Approved" ? false : true}

                                                handleClick={() => {
                                                    retriveData(data, index)
                                                }}
                                            />
                                            <IconButton
                                                iconName={"Delete"}
                                                height={"40px"}
                                                width={"40px"}              
                                                fontSize={"25px"}
                                                margin={"0px 8px"}
                                                disabled={mainApiData?.kycApprovalStatus === "Approved" ? true : false}

                                                color={"white"}
                                                backgroundColor={"#D60000"}
                                                hover={mainApiData?.kycApprovalStatus === "Approved" ? false : true}
                                                handleClick={() => {
                                                    showmodal(data, index)
                                                }}
                                            />
                                        </td>
                                    </tr>
                                </div>
                            ))}
                    </tbody>
                </table>
            </div>
            {/* {
                showDeleteModel &&
                <div >
                    <DeleteModal
                        getDelete={() => { }}
                        clearValue={(value) => { setShowDeleteModel(value) }}
                        modelType={"grid-delete"}
                    />
                </div>
            } */}
        </>
    )
}

export default DirectorTable;