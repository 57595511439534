import { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import PrivateRoutes from './private-routes';
import UsersLoginMain from '../pages/login/users-login-main';
import InitialSetup from '../pages/initial-setup/initial-setup';
import CompanyForm from '../components/onboarding/company-form';
import Organisation from '../pages/product-admin/organisation';
import Company from '../pages/onboarding/company';
import OrganisationCompany from '../pages/organisation/company';
import Users from '../pages/product-admin/users';
import useAuth from "../services/hooks/useauth";
import LoginError from '../components/login-error/login-error';
import AccountError from '../components/login-error/account-error';
import Bank from '../pages/organisation/bank';
import BankApproval from '../pages/approval/bank-approval';
import CustomerOnboarding from '../pages/product-admin/customer-onboarding';
import KycUpload from '../pages/organisation/kyc';
import VirtualCard from '../pages/virtualcard/virtualcard';
import VirtualCardApproval from '../pages/approval/virtualcard';
import MasterCard from '../pages/organisation/card';
import MasterCardApproval from '../pages/approval/card-approval';
import Vendor from '../pages/payable/vendor';
import Customer from '../pages/organisation/customer';
import MasterVendor from '../pages/organisation/vendor';
import CustomerApproval from '../pages/approval/customer-approval';
import VendorApproval from '../pages/approval/vendor-approval';
import Dashboard from '../pages/dashboard/dashboard';
import Notification from '../components/notification-component/notification';
import SigninOption from '../components/initial-setup/sign-in-option';
import LaunchingScreen from '../components/common-component/launching-screen/launching-screen';
import Documentation from '../pages/documentation/documentation';
import VendorBankApproval from '../pages/product-admin/vendor-bank-approval';
import DocumentationVideos from '../pages/documentation/documentation-videos';
import GstApproval from '../pages/approval/gst-approval';
import Utility from '../pages/payable/utility';
import UtilityApproval from '../pages/approval/utility-approval';
import PayableDirectTax from '../pages/payable/direct-tax';
import DirectTaxApproval from '../pages/approval/direct-tax-approval';
import Gst from '../pages/payable/gst';
import ExcisePayment from '../pages/payable/excise';
import ExcsiePaymentApproval from '../pages/approval/excise-approval';
import { axiosPrivate } from '../middleware/axois-api';
import Reports from '../pages/reports/reports';
import Accounts from '../pages/organisation/Accounts/accounts-main';
import TallyAccountMain from '../pages/product-admin/tally-account-main';
import UserLogs from '../pages/product-admin/log-reports/user-log-event';
import SecurityLogs from '../pages/product-admin/log-reports/security-log-evevnt';
import IntegrationLogs from '../pages/product-admin/log-reports/integration-log-event';
import AdministrationLog from '../pages/product-admin/log-reports/administration';
import UserLogDashboard from '../components/dashboard/user-log-dashboard/log-dasboard';
import MasterDetailsReports from '../components/reports/company-admin-reports/masters-reports/master-details-reports';
import VendorPayableReports from '../components/reports/company-admin-reports/vendor-payable-reports/vendor-payable-reports';
import OrganisationApprovalMain from '../pages/approval/product-admin/organisation-approval';
import VendorBankInternalApproval from '../pages/approval/product-admin/vendor-bank-approval';
import CompanyOnboarding from '../pages/approval/product-admin/company-onboarding';
import MasterVendorOnboarding from '../pages/product-admin/vendor';
import VendorLoginMain from '../pages/vendor-login/vendor-login-main';
import VendorLoginInvoice from '../pages/payable/vendor-login';
// import VendorLoginMain from '../pages/vendor-login/vendor-login-main';




function PageRoutes() {
    const { auth } = useAuth();

    const [activeTitleMyaccount, setActiveTitleMyaccount] = useState<string>("signinOptions")
    const [documentationNav, setDocumentationNav] = useState<any>("")
    // auth.token ? true : false//

    const [usersAccess, setUsersAccess] = useState<any>([])

    useEffect(() => {
        getPrivilegedUser()
    }, []);

    const getPrivilegedUser = async () => {
        try {

            const res = await axiosPrivate.get(`/product-admin/user/features`);
            if (res.status === 200) {

                console.log(res.data.features, "page route");
                setUsersAccess(res.data.features)

                // const dashBoard = res.data.features.filter((item: any) => item.pbiTitle === 'Dashboard');
                // const Organisation = res.data.features.filter((item: any) => item.pbiTitle === "Organisation");
                // const Approval = res.data.features.filter((item: any) => item.pbiTitle === "Approval");
                // const Payable = res.data.features.filter((item: any) => item.pbiTitle === "Payable");
                // const dashBoardpbiNames = dashBoard.map((item: any) => item.pbiName);
                // const OrganisationpbiNames = Organisation.map((item: any) => item.pbiName);
                // const ApprovalpbiNames = Approval.map((item: any) => item.pbiName);
                // const PayablepbiNames = Payable.map((item: any) => item.pbiName);

                // let update = {
                //     ...usersAccess,
                //     dashBoardMenu: dashBoardpbiNames,
                //     dashBoard: dashBoardpbiNames.length > 0,
                //     Organisation: OrganisationpbiNames.length > 0,
                //     Approval: ApprovalpbiNames.length > 0,
                //     Payable: PayablepbiNames.length > 0,
                //     OrganisationSubMenu: OrganisationpbiNames,
                //     ApprovalSubMenu: ApprovalpbiNames,
                //     PayableSubMenu: PayablepbiNames,
                // }
                // setUsersAccess(update)
            }
        }
        catch (error: any) {
        }

    }

    return (
        <Router>
            <Routes>
                <Route path="/login" element={<UsersLoginMain />} />
                <Route path="/" element={<UsersLoginMain />} />
                <Route path="/loginerror" element={<LoginError />} />
                <Route path="/accounterror" element={<AccountError />} />
                <Route element={<PrivateRoutes
                    isAuthenticated={auth.token ? true : false}
                    redirectPath={"/"}
                    setActiveTitleMyaccount={(val: string) => { setActiveTitleMyaccount(val) }}
                    setDocumentationNav={(val: string) => { setDocumentationNav(val) }}
                    documentationNav={documentationNav}
                />} >


                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route path="log/dashboard" element={<UserLogDashboard />} />
                    <Route path="/reports" element={<Reports />} />


                    <Route path="/documentation" element={<Documentation setDocumentationNav={(val: string) => { setDocumentationNav(val) }}
                        documentationNav={documentationNav} />} />


                    <Route path="/welcome" element={<LaunchingScreen />} />

                    <Route path="/notification" element={<Notification
                        setActiveTitleMyaccount={(val: string) => { setActiveTitleMyaccount(val) }} />} />
                    {
                        // usersAccess.some((item: any) => item.pbiName === "Company") &&
                        <Route path="/bank-admin/onboarding/company" element={<Company />} />
                    }
                    <Route path="/product-admin/organisation" element={<Organisation />} />
                    <Route path="/documentation/videos" element={<DocumentationVideos setDocumentationNav={(val: string) => { setDocumentationNav(val) }} />} />
                    <Route path="/user-profile" element={<InitialSetup activeTitled={activeTitleMyaccount} />} />
                    <Route path="/organisation/bank" element={<Bank />} />
                    <Route path="/gst" element={<Gst />} />
                    <Route path="/payable/excise-payment" element={<ExcisePayment />} />
                    <Route path="/gst/approval" element={<GstApproval />} />
                    <Route path="/excise-payment/approval" element={<ExcsiePaymentApproval />} />
                    <Route path="/product-admin/vendor-bank-approval" element={<VendorBankApproval />} />
                    <Route path="/approval/bank" element={<BankApproval />} />
                    <Route path="/product-admin/user" element={<Users />} />
                    <Route path="/product-admin/Customer-onboarding" element={<CustomerOnboarding />} />
                    <Route path="/organisation/kycUpload" element={<KycUpload />} />
                    <Route path="/virtualcard" element={<VirtualCard />} />
                    <Route path="/virtualcardApproval" element={<VirtualCardApproval />} />
                    <Route path="/organisation/card" element={<MasterCard />} />
                    <Route path="/payable/vendor" element={<Vendor />} />
                    <Route path="/payable/approver/vendor" element={<Vendor />} />
                    <Route path="/approval/card" element={<MasterCardApproval />} />
                    <Route path="/organisation/customer" element={<Customer />} />
                    <Route path="/organisation/company" element={<OrganisationCompany />} />
                    <Route path="/organisation/vendor" element={<MasterVendor />} />
                    <Route path="/approval/vendor" element={<VendorApproval />} />
                    <Route path="/organisation/customer" element={<Customer />} />
                    <Route path="/approval/customer" element={<CustomerApproval />} />
                    <Route path="/payable/utility" element={<Utility />} />
                    <Route path="/approval/utility" element={<UtilityApproval />} />
                    <Route path="/payable/direct-tax" element={<PayableDirectTax />} />
                    <Route path="/direct-tax/approval" element={<DirectTaxApproval />} />
                    <Route path="/accounts-integration" element={<Accounts />} />
                    <Route path="/log-report/user-log" element={<UserLogs />} />
                    <Route path="/log-report/security-log" element={<SecurityLogs />} />
                    <Route path="/log-report/integration-log" element={<IntegrationLogs />} />
                    <Route path="/log-report/administration-log" element={<AdministrationLog />} />
                    <Route path="/product-admin/tally-integration" element={<TallyAccountMain />} />
                    <Route path="/reports/vendorpayable" element={<VendorPayableReports />} />
                    <Route path="/reports/masterdetails" element={<MasterDetailsReports />} />
                    <Route path="/approval/company-onboarding" element={<CompanyOnboarding />} />
                    <Route path="/approval/vendor-bank" element={<VendorBankInternalApproval />} />
                    <Route path="/approval/organisation" element={<OrganisationApprovalMain />} />
                    <Route path="/product-admin/vendor-onboarding" element={<MasterVendorOnboarding />} />
                    <Route path="/vendor/login" element={<VendorLoginMain />} />
                    <Route path="/vendor/login/payable" element={<VendorLoginInvoice />} />
                </Route>
            </Routes> 
        </Router>
    )
}

export default PageRoutes;

