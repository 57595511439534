import React, { useEffect, useState } from 'react'
import { BiPlus, BiMinus } from "react-icons/bi";
import "../../../styles/view-table.scss";
import IconButton from '../../../components/common-component/form-elements/icon-button';
import DeleteModal from '../../../components/common-component/modals/delete-modal';
import "../../../styles/onboarding/company/grid-icon.scss"




interface Props {
    editMode?: boolean;
    directorApiData: any
    vendorBankApproval?: any;
    retriveData: (value: any, index: number) => void;
    showmodal: (value: any, index: number) => void;
}

function DirectorTable({ directorApiData, retriveData, showmodal, editMode, vendorBankApproval }: Props) {
    return (

        <>
            <div className='view-table-component ' style={{ marginTop: "20px", height: "405px", overflowY: "auto" }}>
                <table>
                    <thead>
                        {/* <tr> */}
                        <th className='align-differ' style={{ width: "25%" }}>Name</th>
                        <th className='' style={{ width: "10%", textAlign: "left" }}>Relation</th>
                        <th className='' style={{ width: "20%", textAlign: "left" }}>DIN</th>
                        <th className='' style={{ width: "30%", paddingLeft: "10px" }}>Designation</th>
                        <th className='' style={{ width: "30%", paddingLeft: "10px" }}>Ownership %</th>
                        <th className='' style={{ textAlign: "center", paddingLeft: "2%", width: "15%" }}>Action</th>
                        {/* </tr> */}
                    </thead>
                    <tbody >
                        {directorApiData?.length === 0 ?
                            <tr className='style-grid-differ'>
                                <td></td>
                                <td></td>
                                <td style={{ textAlign: "center" }}>No records to show</td>
                                <td></td>
                                <td></td>
                            </tr> :
                            directorApiData?.map((data: any, index: number) => (
                                // <div className='main-body-header' style={{ marginTop: "0px" }} key={index}>
                                <tr className='main-body-header' style={{ marginTop: "0px" }} key={index} >
                                    <td className='' style={{ width: "25%", paddingLeft: "10px" }}>{data.name}</td>
                                    <td className='' style={{ width: "10%" }}>{data.relation}</td>
                                    <td className='align-left' style={{ width: "20%" }}>{data.din}</td>
                                    <td className='align-left' style={{ width: "30%" }}>{data.designation}</td>
                                    <td className='align-left' style={{ width: "30%" }}>{data.ownership}</td>
                                    <td className='last-icon flexcls'>
                                        <IconButton
                                            iconName={"Edit"}
                                            height={"40px"}
                                            width={"40px"}
                                            fontSize={"30px"}
                                            margin={"0px 8px"}
                                            color={"white"}
                                            backgroundColor={"#0055D4"}
                                            hover={false}
                                            cursor={"not-allowed"}
                                            opacity={"0.5"}
                                            // disabled={editMode ? true : false}
                                            handleClick={() => {
                                                if (editMode) return;
                                                retriveData(data, index)
                                            }}
                                        />
                                        <IconButton
                                            iconName={"Delete"}
                                            height={"40px"}
                                            width={"40px"}
                                            fontSize={"25px"}
                                            margin={"0px 8px"}
                                            color={"white"}
                                            backgroundColor={"#D60000"}
                                            hover={false}
                                            cursor={"not-allowed"}
                                            opacity={"0.5"}
                                            handleClick={() => {
                                                if (editMode) return;
                                                showmodal(data, index)
                                            }}
                                        />
                                    </td>
                                </tr>
                                // </div>
                            ))}
                    </tbody>
                </table>
            </div>
            {/* {
                showDeleteModel &&
                <div >
                    <DeleteModal
                        getDelete={() => { }}
                        clearValue={(value) => { setShowDeleteModel(value) }}
                        modelType={"grid-delete"}
                    />
                </div>
            } */}
        </>
    )
}

export default DirectorTable;