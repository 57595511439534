import store from './redux/store';
import PageRoutes from './routes/page-routes';
import './styles/app.scss';
import { Provider } from "react-redux";

function App() {

  return (
    <div className="App">
      <Provider store={store}>
        <PageRoutes />
      </Provider>
    </div>
  );
}

export default App;