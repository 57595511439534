import React, { useState, useEffect } from 'react'
import FilterHeader from './filter-header';
import { dashBoardSize } from "../../config/dashboard/product-admin-dashboard-config";
import '../../styles/dashboard/product-admin-dashboard.scss';

// Import your dashboard components here
import OrganizationVsCompanies from './organization-vs-companies';
import PaRegionVsTransactionValue from './pa-region-vs-transaction-value';
import PaCardVsTransactionValue from './pa-card-vs-transaction-value';
import PaCustomersVsTransactionValue from './pa-customers-vs-transaction-value';
import PaCardNetworkVsTransactionValue from './pa-card-network-vs-transaction-value';
import PaSegmentsVsTransactionValue from './pa-segments-vs-transaction-value';
import PaPaymentModeVsTransactionValue from './pa-payment-mode-vs-transaction-value';
import VendorBankApproval from './vendor-bank-approval';
import OnboardingStatus from './onboarding-status';
import PaTransactionHistory from './pa-transaction-history';
interface propsType {
  Component: any,
  openDashboard: boolean,
  dashBoardWidth: any,
  setIsOpenEmpty: any,
  setOpenOneDashboard: any,
}

function DashboardComponent({ Component, openDashboard, dashBoardWidth, setIsOpenEmpty, setOpenOneDashboard }: propsType) {
  return (
    <Component
      isOpenEmpty={openDashboard}
      setIsOpenEmpty={setIsOpenEmpty}
      dashBoardWidth={dashBoardWidth}
      setOpenOneDashboard={setOpenOneDashboard}
    />
  );
}

function ProductAdminDashboard() {
  const [dashBoardWidth, setDashBoardWidth] = useState(dashBoardSize);
  const [openOneDashboard, setOpenOneDashboard] = useState<string>("");
  const [isOpenEmpty, setIsOpenEmpty] = useState<boolean>(true);
  const width = window.innerWidth;
  const height = window.innerHeight;

  useEffect(() => {
    const width = window.innerWidth;

    if (width < 1869 && width > 1601) {
      setDashBoardWidth({
        cardWidth: `${(width - 410)}px`,
        cardHeight: "400px",
        chartWidth: `${(width - 600)}px`,
        chartHeight: "300px",
      })
    } else if (width < 1601) {
      setDashBoardWidth({
        cardWidth: `${(width - 210)}px`,
        cardHeight: "400px",
        chartWidth: `${(width - 400)}px`,
        chartHeight: "300px",
      })
    }
  }, [])

  function changeDashBoardSize() {
    if (openOneDashboard == "") {
      if (width < 1869 && width > 1601) {
        setDashBoardWidth({
          cardWidth: `${(width - 410)}px`,
          // cardHeight: `${(height - 350)}px`,
          cardHeight: "800px",
          chartWidth: `${(width - 600)}px`,
          // chartHeight: `${(height - 450)}px`,
          chartHeight: "545px",
        })
      } else if (width < 1601) {
        setDashBoardWidth({
          cardWidth: `${(width - 210)}px`,
          // cardHeight: `${(height - 350)}px`,
          cardHeight: "800px",
          chartWidth: `${(width - 400)}px`,
          // chartHeight: `${(height - 450)}px`,
          chartHeight: "545px",
        })
      } else {
        setDashBoardWidth({
          cardWidth: `${(width - 410)}px`,
          // cardHeight: `${(height - 300)}px`,
          cardHeight: "800px",
          chartWidth: `${(width - 600)}px`,
          // chartHeight: `${(height - 400)}px`,
          chartHeight: "545px",
        })
      }
    } else {
      if (width < 1869 && width > 1601) {
        setDashBoardWidth({
          cardWidth: `${(width - 410)}px`,
          cardHeight: "400px",
          chartWidth: `${(width - 600)}px`,
          chartHeight: "300px",
        })

      } else if (width < 1601) {
        setDashBoardWidth({
          cardWidth: `${(width - 210)}px`,
          cardHeight: "400px",
          chartWidth: `${(width - 400)}px`,
          chartHeight: "300px",
        })
      } else {
        setDashBoardWidth(dashBoardSize)
      }
    }
  }

  // Define your dashboard components here
  const dashboardComponents = [
    { Component: PaRegionVsTransactionValue, key: "paRegionVsTransactionValue" },
    { Component: PaCardVsTransactionValue, key: "paCardVsTransactionValue" },
    { Component: PaCustomersVsTransactionValue, key: "paCustomersVsTransactionValue" },
    { Component: PaPaymentModeVsTransactionValue, key: "paPaymentModeVsTransactionValue" },
    { Component: PaCardNetworkVsTransactionValue, key: "paCardNetworkVsTransactionValue" },
    { Component: PaSegmentsVsTransactionValue, key: "paSegmentsVsTransactionValue" },
    { Component: OrganizationVsCompanies, key: "organizationVsCompanies" },
    { Component: VendorBankApproval, key: "vendorBankApproval" },
    { Component: OnboardingStatus, key: "onboardingStatus" },
    { Component: PaTransactionHistory, key: "paTransactionHistory" },
  ];

  return (
    <div className='product-admin-dashboard-component'>
      {/* <FilterHeader /> */}
      <div
        className='product-admin-dashboard-main'
        style={{ display: !isOpenEmpty || (width < 1869 && width > 1601) || (width < 1601) ? "block" : "grid" }}
      >
        {
          openOneDashboard === "" ?
            (
              dashboardComponents.map((componentData) => (
                <div key={componentData.key} className={componentData.key}>
                  <DashboardComponent
                    Component={componentData.Component}
                    openDashboard={isOpenEmpty}
                    dashBoardWidth={dashBoardWidth}
                    setIsOpenEmpty={() => setIsOpenEmpty(false)}
                    setOpenOneDashboard={(value: any) => {
                      setOpenOneDashboard(value)
                      changeDashBoardSize()
                    }}
                  />
                </div>
              ))
            )
            :
            (
              dashboardComponents.map((componentData) => (
                <div key={componentData.key}>
                  {
                    openOneDashboard === componentData.key && (
                      <DashboardComponent
                        Component={componentData.Component}
                        openDashboard={isOpenEmpty}
                        dashBoardWidth={dashBoardWidth}
                        setIsOpenEmpty={() => setIsOpenEmpty(true)}
                        setOpenOneDashboard={(value: any) => {
                          if (openOneDashboard === value) {
                            changeDashBoardSize()
                            setOpenOneDashboard("")
                          } else {
                            changeDashBoardSize()
                            setOpenOneDashboard(value)
                          }
                        }}
                      />
                    )
                  }
                </div>
              ))
            )
        }
      </div>
    </div>
  )
}

export default ProductAdminDashboard;