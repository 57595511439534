import React, { useEffect, useRef, useState } from 'react'
import IconButton from '../../../common-component/form-elements/icon-button';
import { CiSearch } from 'react-icons/ci';
import ChartFilter from '../../../common-component/charts/chart-filter';
import { axiosPrivate } from '../../../../middleware/axois-api';
import '../../../../styles/reports/report-table.scss';
import { handlePngPDFDownload } from '../../../common-component/export-pdf/Export-to-pdf';
import { VirtualCardReportFilter } from '../../../../config/reports/master-reports/virtual-card-report-config ';
import { formatAmount } from '../../../../helpers/amountRenderer';


const OrganisationfilterDatas: any = {
    "Vendor": [
        {
            cardTitle: "Vendor Code",
            cardType: "dropdown",
            selectedValue: "All",
            options: [
                { value: "All", label: "All" },
            ],
            isOpen: false
        },
        {
            cardTitle: "Name",
            cardType: "dropdown",
            selectedValue: "Name",
            options: [
                { value: "All", label: "All" },
            ],
            isOpen: false
        },

    ],
}
interface props {
    searchBarValue: string
    filterOpen: any
    setFilterOpen: any
    conponentPDFMaster: any


}
export default function VirtualCardGrid({ searchBarValue, filterOpen, setFilterOpen, conponentPDFMaster }: props) {
    const divRef = useRef<HTMLDivElement>(null);
    const [searchValue, setSearchValue] = useState<string>('');
    const [filteredData, setFilteredData] = useState<any[]>([]);
    const [orginalFilteredData, setOrginalFilteredData] = useState<any[]>([]);
    const [openDropdownIndex, setOpenDropdownIndex] = useState<number | null>(null);

    const [storeFilterOptions, setStoreFilterOptions] = useState<any>([]);
    const [filterValue, setFilterValues] = useState<any>([])
    const [orginalRowData, setOrginalRowData] = useState<any[]>([]);


    // all data filter
    // const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     const value = event.target.value;
    //     setSearchValue(value);

    //     const filtered = SampleDatasArun.filter((item) => {
    //         // Customize the condition based on your specific requirements
    //         return Object.values(item).some((val) => {
    //             if (val && typeof val === 'object') {
    //                 // If the value is an object and not null/undefined, check its values
    //                 return Object.values(val).some((nestedVal: any) =>
    //                     nestedVal && nestedVal.toString().toLowerCase().includes(value.toLowerCase())
    //                 );
    //             } else if (val) {
    //                 // If the value is not an object and not null/undefined, directly check it
    //                 return val.toString().toLowerCase().includes(value.toLowerCase());
    //             }
    //             return false; // Skip null/undefined values
    //         });
    //     });

    //     setFilteredData(filtered); 
    // };


    // const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     const value = event.target.value;
    //     setSearchValue(value);

    //     // Filter data based on the search value
    //     const filtered = SampleDatasArun.filter((item) => {
    //         // Customize the condition based on your specific requirements 
    //         return item.VendorDeatils.Name.toLowerCase().includes(value.toLowerCase());
    //     });

    //     setFilteredData(filtered);
    // };


    // two data filter

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setSearchValue(value);
        if (value === "") {
            fetchData()
        } else {
            // Filter data based on the search value
            const filtered = orginalFilteredData.filter((item) => {
                const vendorDetails = item.VendorDetails;

                if (
                    vendorDetails &&
                    (vendorDetails["Vendor Code"]?.toLowerCase().includes(value.toLowerCase()) ||
                        vendorDetails["Name"]?.toLowerCase().includes(value.toLowerCase()))
                ) {
                    // Return an object containing the matched field and its value
                    return {
                        matchedField: vendorDetails["Vendor Code"]?.toLowerCase().includes(value.toLowerCase())
                            ? "Vendor Code"
                            : "Name",
                        value: vendorDetails["Vendor Code"] || vendorDetails["Name"],
                    };
                }

                return null;
            });

            setFilteredData(filtered);
        }
    };

    useEffect(() => {
        const filtered = orginalFilteredData.filter((item) => {
            const vendorDetails = item.VendorDetails;

            if (
                vendorDetails &&
                (vendorDetails["Vendor Code"]?.toLowerCase().includes(searchBarValue.toLowerCase()) ||
                    vendorDetails["Name"]?.toLowerCase().includes(searchBarValue.toLowerCase()))
            ) {
                // Return an object containing the matched field and its value
                return {
                    matchedField: vendorDetails["Vendor Code"]?.toLowerCase().includes(searchBarValue.toLowerCase())
                        ? "Vendor Code"
                        : "Name",
                    value: vendorDetails["Vendor Code"] || vendorDetails["Name"],
                };
            }

            return null;
        });

        setFilteredData(filtered);
    }, [searchBarValue]);

    const fetchData = async () => {
        try {
            const response = await axiosPrivate.get('/companyadmin/master-reports/virtualcard');
            setFilteredData(response.data.virtualCardDetails)
            setOrginalRowData(response.data.virtualCardDetails)
            addDataForDropDownFilter(response.data.dropDownDatas)
        } catch (error) {
        }
    };

    // filter functions
    useEffect(() => {
        handleApplyButtonForFilter(VirtualCardReportFilter["VirtualCard"])
        setStoreFilterOptions(VirtualCardReportFilter["VirtualCard"])
        setFilterValues(VirtualCardReportFilter["VirtualCard"])
        fetchData()
    }, [])

    const handleOpenClose = (index: number) => {
        if (openDropdownIndex === index) {
            setOpenDropdownIndex(null); // Close the dropdown if it's already open
        } else {
            setOpenDropdownIndex(index); // Open the dropdown
        }
    };

    const updateFilterDatas = async (index: number, newData: any) => {
        setStoreFilterOptions((prevFilterDatas: any) => {
            const updatedFilterDatas = [...prevFilterDatas];
            updatedFilterDatas[index] = newData;
            return updatedFilterDatas;
        });

        setFilterValues((prevFilterDatas: any) => {
            const updatedFilterDatas = [...prevFilterDatas];
            updatedFilterDatas[index] = newData;
            return updatedFilterDatas;
        });
    };

    function addDataForDropDownFilter(res: any) {
        if (Object.keys(res).length > 0) {

            const updateNameOnCard: any = [
                { value: "All", label: "All" },
                ...res['Name on Card'].map((elem: any) => ({ value: elem, label: elem }))
            ];
            const updateNumber: any = [
                { value: "All", label: "All" },
                ...res['Number'].map((elem: any) => ({ value: elem, label: elem }))
            ];
            const updateIssuer: any = [
                { value: "All", label: "All" },
                ...res['Issuer'].map((elem: any) => ({ value: elem, label: elem }))
            ];
            const updateValidFrom: any = [
                { value: "All", label: "All" },
                ...res['Valid From'].map((elem: any) => ({ value: elem, label: elem }))
            ];
            const updateValidThru: any = [
                { value: "All", label: "All" },
                ...res['Valid Thru'].map((elem: any) => ({ value: elem, label: elem }))
            ];
            const updateIsApproved: any = [
                { value: "All", label: "All" },
                ...res['Is Approved'].map((elem: any) => ({ value: elem, label: elem }))
            ];
            const updateMaxLimit: any = [
                { value: "All", label: "All" },
                ...res['Max Limit'].map((elem: any) => ({ value: elem, label: elem }))
            ];
            const updateNoofTransactions: any = [
                { value: "All", label: "All" },
                ...res['No of Transactions'].map((elem: any) => ({ value: elem, label: elem }))
            ];
            const updateEmployeeName: any = [
                { value: "All", label: "All" },
                ...res['Employee Name'].map((elem: any) => ({ value: elem, label: elem }))
            ];
            const updateEmployeeID: any = [
                { value: "All", label: "All" },
                ...res['Employee ID'].map((elem: any) => ({ value: elem, label: elem }))
            ];
            const updateDepartment: any = [
                { value: "All", label: "All" },
                ...res['Department'].map((elem: any) => ({ value: elem, label: elem }))
            ];
            const updateDesignation: any = [
                { value: "All", label: "All" },
                ...res['Designation'].map((elem: any) => ({ value: elem, label: elem }))
            ];
            const updateEmailID: any = [
                { value: "All", label: "All" },
                ...res['Email ID'].map((elem: any) => ({ value: elem, label: elem }))
            ];
            const updateContactNumber: any = [
                { value: "All", label: "All" },
                ...res['Contact Number'].map((elem: any) => ({ value: elem, label: elem }))
            ];
            const updateVendorName: any = [
                { value: "All", label: "All" },
                ...res['Vendor Name'].map((elem: any) => ({ value: elem, label: elem }))
            ];
            const updateVendorCode: any = [
                { value: "All", label: "All" },
                ...res['Vendor Code'].map((elem: any) => ({ value: elem, label: elem }))
            ];
            const updateGSTIN: any = [
                { value: "All", label: "All" },
                ...res['GSTIN'].map((elem: any) => ({ value: elem, label: elem }))
            ];
            const updateAccountNumber: any = [
                { value: "All", label: "All" },
                ...res['Account Number'].map((elem: any) => ({ value: elem, label: elem }))
            ];
            const updateIFSCCode: any = [
                { value: "All", label: "All" },
                ...res['IFSC Code'].map((elem: any) => ({ value: elem, label: elem }))
            ];
            setStoreFilterOptions((prevOptions: any) => {
                return prevOptions.map((option: any) => {
                    switch (option.cardTitle) {
                        case "Name on Card":
                            return { ...option, options: updateNameOnCard };
                        case "Number":
                            return { ...option, options: updateNumber };
                        case "Issuer":
                            return { ...option, options: updateIssuer };
                        case "Valid From":
                            return { ...option, options: updateValidFrom };
                        case "Valid Thru":
                            return { ...option, options: updateValidThru };
                        case "Is Approved":
                            return { ...option, options: updateIsApproved };
                        case "Max Limit":
                            return { ...option, options: updateMaxLimit };
                        case "No of Transactions":
                            return { ...option, options: updateNoofTransactions };
                        case "Employee Name":
                            return { ...option, options: updateEmployeeName };
                        case "Employee ID":
                            return { ...option, options: updateEmployeeID };
                        case "Department":
                            return { ...option, options: updateDepartment };
                        case "Designation":
                            return { ...option, options: updateDesignation };
                        case "Email ID":
                            return { ...option, options: updateEmailID };
                        case "Contact Number":
                            return { ...option, options: updateContactNumber };
                        case "Vendor Name":
                            return { ...option, options: updateVendorName };
                        case "Vendor Code":
                            return { ...option, options: updateVendorCode };
                        case "GSTIN":
                            return { ...option, options: updateGSTIN };
                        case "Account Number":
                            return { ...option, options: updateAccountNumber };
                        case "IFSC Code":
                            return { ...option, options: updateIFSCCode };
                        default:
                            return option;
                    }
                });
            });

            setFilterValues((prevOptions: any) => {
                return prevOptions.map((option: any) => {
                    switch (option.cardTitle) {
                        case "Name on Card":
                            return { ...option, options: updateNameOnCard };
                        case "Number":
                            return { ...option, options: updateNumber };
                        case "Issuer":
                            return { ...option, options: updateIssuer };
                        case "Valid From":
                            return { ...option, options: updateValidFrom };
                        case "Valid Thru":
                            return { ...option, options: updateValidThru };
                        case "Is Approved":
                            return { ...option, options: updateIsApproved };
                        case "Max Limit":
                            return { ...option, options: updateMaxLimit };
                        case "No of Transactions":
                            return { ...option, options: updateNoofTransactions };
                        case "Employee Name":
                            return { ...option, options: updateEmployeeName };
                        case "Employee ID":
                            return { ...option, options: updateEmployeeID };
                        case "Department":
                            return { ...option, options: updateDepartment };
                        case "Designation":
                            return { ...option, options: updateDesignation };
                        case "Email ID":
                            return { ...option, options: updateEmailID };
                        case "Contact Number":
                            return { ...option, options: updateContactNumber };
                        case "Vendor Name":
                            return { ...option, options: updateVendorName };
                        case "Vendor Code":
                            return { ...option, options: updateVendorCode };
                        case "GSTIN":
                            return { ...option, options: updateGSTIN };
                        case "Account Number":
                            return { ...option, options: updateAccountNumber };
                        case "IFSC Code":
                            return { ...option, options: updateIFSCCode };
                        default:
                            return option;
                    }
                });
            });

        }
    }

    const convertToFilterValues = (filterData: any) => {
        const filterValues: any = {};
        filterData.forEach((filter: any) => {
            const tittle = (filter.cardTitle).replace(/\s/g, '')
            filterValues[tittle] = filter.selectedValue;
        });
        return filterValues;
    };

    async function handleApplyButtonForFilter(data: any) {
        const convertValue = convertToFilterValues(filterValue)

        if (
            convertValue['NameonCard'] == "All" &&
            convertValue['Number'] == "All" &&
            convertValue['Issuer'] == "All" &&
            convertValue['ValidFrom'] == "All" &&
            convertValue['ValidThru'] == "All" &&
            convertValue['IsApproved'] == "All" &&
            convertValue['MaxLimit'] == "All" &&
            convertValue['NoofTransactions'] == "All" &&
            convertValue['EmployeeName'] == "All" &&
            convertValue['EmployeeID'] == "All" &&
            convertValue['Department'] == "All" &&
            convertValue['Designation'] == "All" &&
            convertValue['EmailID'] == "All" &&
            convertValue['ContactNumber'] == "All" &&
            convertValue['VendorName'] == "All" &&
            convertValue['VendorCode'] == "All" &&
            convertValue['GSTIN'] == "All" &&
            convertValue['AccountNumber'] == "All" &&
            convertValue['IFSCCode'] == "All"
        ) {
            setFilteredData(orginalRowData)
        } else {
            const filteredData: any = orginalRowData && orginalRowData.filter((ele: any) => {
                const NameOnCardCondition = convertValue['NameonCard'] === 'All' || ele.VirtualCardDetails['Name on Card'] === convertValue['NameonCard'];
                const NumberCondition = convertValue['Number'] === 'All' || ele.VirtualCardDetails['Number'] === convertValue['Number'];
                const IssuerCondition = convertValue['Issuer'] === 'All' || ele.VirtualCardDetails['Issuer'] === convertValue['Issuer'];
                const ValidFromCondition = convertValue['ValidFrom'] === 'All' || ele.VirtualCardDetails['Valid From'] === convertValue['ValidFrom'];
                const ValidThruCondition = convertValue['ValidThru'] === 'All' || ele.VirtualCardDetails['Valid Thru'] === convertValue['ValidThru'];
                const IsApprovedCondition = convertValue['IsApproved'] === 'All' || ele.VirtualCardDetails['Is Approved'] === convertValue['IsApproved'];
                const MaxLimitCondition = convertValue['MaxLimit'] === 'All' || ele.TransactionLimits['Max Limit'] === convertValue['MaxLimit'];
                const NoofTransactionsCondition = convertValue['NoofTransactions'] === 'All' || ele.TransactionLimits['No of Transactions'] === convertValue['NoofTransactions'];
                const EmployeeNameCondition = convertValue['EmployeeName'] === 'All' || ele.EmployeeDetails['Employee Name'] === convertValue['EmployeeName'];
                const EmployeeIDCondition = convertValue['EmployeeID'] === 'All' || ele.EmployeeDetails['Employee ID'] === convertValue['EmployeeID'];
                const DepartmentCondition = convertValue['Department'] === 'All' || ele.EmployeeDetails['Department'] === convertValue['Department'];
                const DesignationCondition = convertValue['Designation'] === 'All' || ele.EmployeeDetails['Designation'] === convertValue['Designation'];
                const EmailIDCondition = convertValue['EmailID'] === 'All' || ele.EmployeeDetails['Email ID'] === convertValue['EmailID'];
                const ContactNumberCondition = convertValue['ContactNumber'] === 'All' || ele.EmployeeDetails['Contact Number'] === convertValue['ContactNumber'];
                const VendorNameCondition = convertValue['VendorName'] === 'All' || ele.VendorDetails['Vendor Name'] === convertValue['VendorName'];
                const VendorCodeCondition = convertValue['VendorCode'] === 'All' || ele.VendorDetails['Vendor Code'] === convertValue['VendorCode'];
                const GSTINCondition = convertValue['GSTIN'] === 'All' || ele.VendorDetails['GSTIN'] === convertValue['GSTIN'];
                const AccountNumberCondition = convertValue['AccountNumber'] === 'All' || ele.VendorDetails['Account Number'] === convertValue['AccountNumber'];
                const IFSCCodeCondition = convertValue['IFSCCode'] === 'All' || ele.VendorDetails['IFSC Code'] === convertValue['IFSCCode'];

                return NameOnCardCondition
                    && NumberCondition
                    && IssuerCondition
                    && ValidFromCondition
                    && ValidThruCondition
                    && IsApprovedCondition
                    && MaxLimitCondition
                    && NoofTransactionsCondition
                    && EmployeeNameCondition
                    && EmployeeIDCondition
                    && DepartmentCondition
                    && DesignationCondition
                    && EmailIDCondition
                    && ContactNumberCondition
                    && VendorNameCondition
                    && VendorCodeCondition
                    && GSTINCondition
                    && AccountNumberCondition
                    && IFSCCodeCondition
            });
            setFilteredData(filteredData);
        }
        setFilterOpen(false)
    }

    // filter functions end

    return (
        <>

            <div className='report-table-component' style={{ marginTop: "20px", overflowX: "auto" }}>
                <table ref={conponentPDFMaster}>
                    <thead>
                        {/* <tr> */}
                        <th className='report-heading' style={{ paddingLeft: "10px", }}>Virtual Card Details</th>
                        <th className='report-heading' style={{ paddingLeft: "10px" }}>Source Card Details</th>
                        <th className='report-heading' style={{ paddingLeft: "10px" }}>Transaction Limits</th>
                        <th className='report-heading' style={{ paddingLeft: "10px" }}>Employee Details</th>
                        <th className='report-heading' style={{ paddingLeft: "10px" }}>Vendor Details</th>
                        {/* </tr> */}
                    </thead>
                    <tbody>
                        {filteredData.length === 0 ?
                            <tr className='style-grid-differ'>

                                <td></td>
                                <td></td>
                                <td style={{ textAlign: "center" }}>No records to show</td>
                                <td></td>
                                <td></td>
                            </tr>
                            :
                            filteredData.map((data: any, index: any) => (
                                <tr className='main-body-header' key={index} >
                                    <td className='report-details' >
                                        {Object.entries(data.VirtualCardDetails).map(([key, value]: any) => (
                                            <p key={key} style={{ whiteSpace: "normal", width: "350px" }}>
                                                <i>{key}</i>  - { value}
                                            </p> 
                                        ))}
                                    </td>
                                    <td className='report-details' >
                                        {Object.entries(data.SourceCardDetails).map(([key, value]: any) => (
                                            <p key={key} style={{ width: "350px", whiteSpace: "normal" }}>
                                                <i>{key}</i> - {value}
                                            </p>
                                        ))}
                                    </td>
                                    <td className='report-details' >
                                        {Object.entries(data.TransactionLimits).map(([key, value]: any) => (
                                            <p key={key}>
                                                <i>{key}</i> - {value}
                                            </p>
                                        ))}
                                    </td>
                                    <td className='report-details' >
                                        {Object.entries(data.EmployeeDetails).map(([key, value]: any) => (
                                            <p key={key} style={{ width: "350px", whiteSpace: "normal" }}>
                                                {/* <> */}
                                                <i>{key}</i> -{key == 'Max Limit' ? value == "NA" ? value : formatAmount(value) : value}
                                                {/* </> */}
                                            </p>
                                        ))}
                                    </td>
                                    <td className='report-details' >
                                        {Object.entries(data.VendorDetails).map(([key, value]: any) => (
                                            <p key={key} style={{ width: "350px", whiteSpace: "normal" }}>
                                                {/* <> */}
                                                <i>{key}</i> - {value}
                                                {/* </> */}
                                            </p>
                                        ))}
                                    </td>
                                </tr>
                            ))}
                    </tbody>


                </table>
            </div>

            {filterOpen &&
                <div className="right-side-filter-component" >
                    <ChartFilter
                        datas={storeFilterOptions}
                        updateFilterDatas={updateFilterDatas}
                        handleOpenClose={handleOpenClose}
                        openDropdownIndex={openDropdownIndex}
                        handleApplyButtonForFilter={() => { handleApplyButtonForFilter("") }}
                        setOpenRightSideFilter={() => { setFilterOpen(false) }}
                    />
                </div>
            }
        </>
    )
}

