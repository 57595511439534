import "../../styles/view-table.scss";
import "../../styles/onboarding/company/grid-icon.scss"
import "../../styles/gst/gst-challan-details.scss"
interface Props {

}
function GstPaymentHistory({ }: Props) {
    return (
        <>
            <div className="payment-history-border">
                <div className="payment-history">
                    Payment History
                </div>
                <div className='view-table-component' style={{ marginTop: "40px", marginBottom: "20px", height: "110px", overflowY: "auto" }}>
                    <table className='company-seperate-grid'>
                        <thead>
                            <th className='cus-width' style={{ paddingLeft: "20px", minWidth: "150px", textAlign: "left" }}>Date</th>
                            <th className='cus-width' style={{ minWidth: "150px", textAlign: "left" }}>{"Card Number"}</th>
                            <th className='cus-width' style={{ minWidth: "150px", textAlign: "right", paddingRight: "60px" }}>{"Amount (₹)"}</th>
                            <th className='cus-width' style={{ minWidth: "200px", textAlign: "left" }}>{"Transaction Ref No"}</th>
                            <th className='cus-width' style={{ minWidth: "170px", textAlign: "left" }}>{"Payment Status"}</th>
                        </thead>
                        <tbody >
                            <tr className='style-grid-differ'>
                                <td></td>
                                <td></td>
                                <td>No records to show</td>
                                <td></td>
                            </tr>
                            {/* {approvalStatus?.length === 0 ?
                                
                                </tr> : approvalStatus?.map((data: any, index: number) => (
                                    <tr className='main-body-header' style={{ height: "50px" }}>
                                        <td className='left-icons' style={{ paddingLeft: "20px", minWidth: "200px", paddingRight: "20px", textAlign: "left" }}>{"06/08/2023"}</td>
                                        <td className='left-icons' style={{ minWidth: "200px", textAlign: "left" }}>{"45xx xxxx xxxx xx00"}</td>
                                        <td className='left-icons' style={{ minWidth: "125px", textAlign: "right", paddingRight: "60px" }}>{"43200.00"}</td>
                                        <td className='left-icons' style={{ minWidth: "200px", textAlign: "left" }}>{"245378"}</td>
                                        <td className='left-icons' style={{ width: "200px", textAlign: "left" }}>{"Debited"}</td>
                                    </tr>
                                ))} */}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}

export default GstPaymentHistory