import React, { useEffect, useState, forwardRef, useImperativeHandle, } from 'react'
import InputComponent from '../../../common-component/form-elements/input-component'
import DropdownComponent from '../../../common-component/form-elements/dropdown-component'
import ButtonComponent from '../../../common-component/form-elements/button-component'
import StatutoryDetailsGrid from '../../../../config/onboarding/statutory-details-grid'
import { v4 as uuidv4 } from 'uuid'
import Loading from '../../../common-component/modals/loading-screen'
import useAxiosPrivate from "../../../../services/hooks/useaxios-private";
import AlertBox from '../../../common-component/alert-box'
import { useDispatch, useSelector } from "react-redux";
import { resetFormModified } from "../../../../redux/action";
import Changesmodal from '../../../common-component/modals/changes-modal';
import DeleteModal from '../../../common-component/modals/delete-modal'

// statutoryDetails
interface Props {
  statutoryDetailsMain: any;
  onboardingViewfun: (val: any) => void;
  sendDataApi: () => void;
  iconStatus: any
  editData: any
  setShowPage: (val: any) => void;
  setIconStatus: (val: any) => void;
  setStatutoryDetailsMain: (value: any) => void;
  isEditMode: boolean;

}

interface DropdownItem {
  label: string;
  value: string;
}

export interface ChildRef {
  next: (val: any) => void;
}
const StatutoryDetails = forwardRef<ChildRef, Props>(({ statutoryDetailsMain, editData, isEditMode, iconStatus, setShowPage, sendDataApi, setIconStatus, onboardingViewfun, setStatutoryDetailsMain }, ref) => {
  const initData: any = {
    // CompanyCIN: "",
    // CompanyLLPIN: "",
    // CompanyPAN: "",
    CompanyGSTIN: "",
    CompanyTan: "",
    status: "Active",
    state: "",
    state_code: "",
    document_id: "",
    index: 0

  }
  const [formDataEdit, setFormDataEdit] = useState(false)
  const [currentSatutory, setCurrentSatutory] = useState<number>()
  const [formData, setFormData] = useState(initData);
  const [companyOverviewErrors, setCompanyOverviewErrors] = useState<Record<string, boolean>>({})
  const [StatutoryState, setStatutoryState] = useState<any[]>([]);
  const [noDuplicate, setNoDuplicate] = useState(false)
  const [showType, setShowType] = useState("warning") // error message showType
  const [showMessage, setShowMessage] = useState<string>("") // error message showMessage
  const [showAlertBox, setShowAlertBox] = useState(false) // error message ShowAlertBox
  const [isFormDirty, setIsFormDirty] = useState(false)
  const isFormModified = useSelector((state: any) => state.isFormModified);
  const [showChangesModal, setShowChangesModal] = useState<boolean>(false);
  const dispatch = useDispatch();
  const [currentDeletVal, setCurrentDeletVal] = useState<any>({});
  const [deleteIndex, setDeleteIndex] = useState<number>(0)
  const [showDeleteModel, setShowDeleteModel] = useState<boolean>(false)
  const [currIndex, setCurrIndex] = useState<number>()
  const [dropDownState, setDropDownState] = useState<DropdownItem[]>([])
  const userValidationRules: any = {
    // CompanyCIN: {
    //   regex: "",
    //   field: "",
    //   shouldNotBe: ""
    // },
    // CompanyLLPIN: {
    //   regex: "",
    //   field: "",
    //   shouldNotBe: ""
    // },
    // CompanyPAN: {
    //   regex: "",
    //   field: "",
    //   shouldNotBe: ""
    // },
    CompanyGSTIN: {
      regex: "",
      field: "",
      shouldNotBe: ""
    },
    CompanyTAN: {
      regex: formData.CompanyTAN ? "" : "",
      field: "",
      shouldNotBe: ""
    },
    status: {
      regex: "",
      field: "",
      shouldNotBe: ""
    },
    state: {
      regex: "",
      field: "",
      shouldNotBe: ""
    },
    state_code: {
      regex: "",
      field: "",
      shouldNotBe: ""
    },
  }

  const updatedIsValueValidAll: Record<string, boolean> = {};
  const validateForm = (): boolean => {
    const updatedFieldValidity: Record<string, boolean> = {};
    const isMandatoryfalse = true;
    const isRegexfalse = true;
    Object.keys(userValidationRules).forEach((field) => {
      const rule = userValidationRules[field];
      const value = formData && formData[field];
      console.log(rule, value, field)
      const isValueValid = (rule.field === "mandatory") ? (value ? (value !== rule.shouldNotBe) : false) : true;
      const isRegexValid = rule.regex !== "" ? new RegExp(rule.regex).test(value) : true;
      updatedFieldValidity[field] = !isValueValid || !isRegexValid;
      updatedIsValueValidAll[field] = !isValueValid;
    });
    setCompanyOverviewErrors(updatedFieldValidity);
    const isFormValid = Object.keys(updatedFieldValidity).every((keys) => {
      return !updatedFieldValidity[keys]
    });
    return isFormValid;
  };


  const axiosPrivate = useAxiosPrivate();

  useEffect(() => {
    fetchStatutoryState()
  }, [])


  const fetchStatutoryState = async () => {
    try {
      const response = await axiosPrivate.get("state");
      if (response.status === 200) {
        const dropdownValues = response.data.states.map((item: any) => ({
          label: item.name,
          value: item.name,
          id: item.stateId,
        }));
        const sortedData = [...dropdownValues].sort((a, b) => a.label.localeCompare(b.label));
        setStatutoryState(sortedData);
      }
    } catch (error) {
    }
  };

  // async function duplicateCheck() {
  //   let finalData = {
  //     "CompanyCIN": formData.CompanyCIN,
  //     "CompanyLLPIN": formData.CompanyLLPIN,
  //     "CompanyPAN": formData.CompanyPAN,
  //     "CompanyGSTIN": formData.CompanyGSTIN,
  //     "CompanyTAN": formData.CompanyTAN,
  //     "document_id": formData.document_id,
  //   }
  //   let statusMessage: boolean = false;
  //   try {
  //     const fieldsToCheck = ['CompanyCIN', 'CompanyTAN'];
  //     const todisplay = ['GSTIN', 'Company Tan'];
  //     const failedFields: any[] = [];
  //     let isDuplicate = false
  //     if (!formDataEdit) {
  //       isDuplicate = statutoryDetailsMain.some((item: any) => {
  //         const matches = fieldsToCheck.map((field, i) => {
  //           if (formData[field] !== '') {
  //             if (item[field] === formData[field]) {
  //               failedFields.push(todisplay[i]);
  //             }
  //             return item[field] === formData[field];
  //           }
  //           return false;
  //         });
  //         return matches.some(match => match);
  //       });
  //     } else {
  //       isDuplicate = statutoryDetailsMain.some((item: any, index: any) => {
  //         const matches = fieldsToCheck.map((field, i) => {
  //           if (formData[field] && formData[field] !== '' && currIndex != index) {
  //             if (item[field] === formData[field]) {
  //               failedFields.push(todisplay[i]);
  //             }
  //             return item[field] === formData[field];
  //           }
  //           return false;
  //         });
  //         return matches.some(match => match);
  //       });
  //     }
  //     if (!isDuplicate) {
  //       const res = await axiosPrivate.post(`bank-admin/company/duplicate-checking/${currentSatutory}`, { 'statutoryDetails': finalData })
  //       if (res.status === 200) {
  //         statusMessage = true;
  //       }
  //     } else {
  //       statusMessage = false;
  //       setShowAlertBox(true)
  //       setShowType("danger")
  //       setShowMessage(`${failedFields[0]} already exists`)
  //       clearAlert("")
  //     }

  //   }
  //   catch (error: any) {
  //     statusMessage = false;
  //     setShowAlertBox(true)
  //     setShowType("danger")
  //     setShowMessage(error.response.data.error)
  //     clearAlert("")
  //   }
  //   return statusMessage;
  // }
  const clearAlert = (status: any) => {
    const timer = setTimeout(() => {
      setShowAlertBox(false);
      setShowMessage("");
      if (status === 400) {

      }
      clearTimeout(timer);
    }, 5000);
  }


  async function handelAdd() {
    console.log(updatedIsValueValidAll, '////')
    if (!validateForm()) {
      setShowAlertBox(true);
      setShowType("danger");
      setShowMessage(Object.values(updatedIsValueValidAll).every((values) => !values) ?
        "Some of the field(s) are not in required format." : "Mandatory field(s) should not be left blank.")
      clearAlert('');
      return;
    }
    // let Duplicate = await duplicateCheck();
    // if (!Duplicate) return;
    if (formDataEdit) {
      let data: any = []
      statutoryDetailsMain.length > 0 && statutoryDetailsMain.map((ele: any, i: number) => {
        if (i === formData.index) {
          data.push(formData)
        } else {
          data.push(ele)
        }
      })
      setStatutoryDetailsMain(data)
      setFormDataEdit(false)
      setFormData(
        {
          // CompanyCIN: "",
          // CompanyLLPIN: "",
          // CompanyPAN: "",
          CompanyGSTIN: "",
          CompanyTan: "",
          document_id: "",
          status: "Active",
          state: "",
          state_code: "",
          index: 0
        }
      )
      setIsFormDirty(false);
      setShowAlertBox(true);
      setShowType("success");
      setShowMessage("Statutory Details updated successfully.")
      clearAlert('');
    }
    else {
      let newrecord = [...statutoryDetailsMain]
      newrecord.push({ ...formData })
      setStatutoryDetailsMain(newrecord)
      setFormData(
        {
          // CompanyCIN: "",
          // CompanyLLPIN: "",
          // CompanyPAN: "",
          CompanyGSTIN: "",
          CompanyTan: "",
          document_id: "",
          status: "Active",
          state: "",
          state_code: "",
          index: 0
        }
      )
    }
    setIsFormDirty(false);
    setFormDataEdit(false);

  }

  // function next(next: any = "") {
  //   console.log('object')
  //   if (isEditMode && statutoryDetailsMain.length > 0) {
  //     sendDataApi();
  //   } else {
  //     // if (statutoryDetailsMain.length > 0) {
  //     //   setShowPage(next == "" ? next : "DirectorsInfo")
  //       if (next == "") {
  //         switch (next) {
  //           case "CompanyOverview":
  //             setIconStatus({
  //               ...iconStatus,
  //               companyIcon: "selected",
  //             })
  //             break;
  //           case "Address":
  //             setIconStatus({
  //               ...iconStatus,
  //               companyIcon: "completed",
  //               addressIcon: "selected",
  //             })
  //             break;
  //           case "DirectorsInfo":
  //             setIconStatus({
  //               ...iconStatus,
  //               directorIcon: "selected",
  //               addressIcon: "completed"
  //             })
  //             break;
  //           case "StatutoryDetails":
  //             setIconStatus({
  //               ...iconStatus,
  //               statutoryIcon: "selected",
  //               directorIcon: "completed"
  //             })
  //             break;
  //           case "AdminDetails":
  //             setIconStatus({
  //               ...iconStatus,
  //               adminIcon: "selected",
  //               // statutoryIcon: "completed"
  //               addressIcon: "completed"
  //             })
  //             break;

  //           default:
  //             break;
  //         }
  //       } else {
  //         setIconStatus({
  //           ...iconStatus,
  //           statutoryIcon: "completed",
  //           directorIcon: "selected"
  //         })
  //       }
  //     // }
  //   }
  // }

  async function next(next: any = "") {

    const isFormValid = validateForm();
    // if (mainApiData.OrganisationName && isFormValid) {
    // let isvalidDOB = isDateValid()
    // if (!isvalidDOB) {
    //   setShowAlertBox(true);
    //   setShowType("danger");
    //   setShowMessage("Some of the field(s) are not in required format.")
    //   clearAlert('');
    //   return;
    // }
    // const Duplicate = await duplicateCheck();
    if (true) {
      if (false) {
        sendDataApi();
        setIconStatus({
          ...iconStatus,
          companyIcon: "completed",
        })
        dispatch(resetFormModified(false));
      } else {
        setShowPage(next != "" ? next : "DirectorsInfo")
        if (next != "") {
          switch (next) {
            case "CompanyOverview":
              setIconStatus({
                ...iconStatus,
                companyIcon: "selected",
              })
              break;
            case "StatutoryDetails":
              setIconStatus({
                ...iconStatus,
                statutoryIcon: "selected",
                directorIcon: "completed"
              })
              break;
            case "DirectorsInfo":
              setIconStatus({
                ...iconStatus,
                directorIcon: "selected",
                addressIcon: "completed"
              })
              break;
            case "Address":
              setIconStatus({
                ...iconStatus,
                companyIcon: "completed",
                addressIcon: "selected",
              })
              break;
            case "AdminDetails":
              setIconStatus({
                ...iconStatus,
                adminIcon: "selected",
                // statutoryIcon: "completed"
                addressIcon: "completed"
              })
              break;
            default:
              break;
          }
        } else {
          setIconStatus({
            ...iconStatus,
            statutoryIcon: "completed",
            directorIcon: "selected"
          })
        }
      }
    } else {
    }

    setIsFormDirty(false);
    // } else {
    //   setShowAlertBox(true);
    //   setShowType("danger");
    //   setShowMessage((Object.values(updatedIsValueValidAll).every((values) => !values)) ? "Some of the field(s) are not in required format." : (!mainApiData.OrganisationName) ? "Mandatory field(s) should not be left blank." : "Mandatory field(s) should not be left blank.")
    //   if (!mainApiData.OrganisationName) {
    //     setCompanyOverviewErrors((prevState) => ({
    //       ...prevState,
    //       OrganisationName: true
    //     }));

    //   }
    //   clearAlert('');
    // }
  }

  useImperativeHandle(ref, () => {
    return {
      next: next
    }
  });

  function returnGird() {
    onboardingViewfun("grid")
    dispatch(resetFormModified(false));
  }

  function retriveData(value: any, index: number) {
    setCurrIndex(index)
    setFormDataEdit(true)
    setCurrentSatutory(value.satutoryid)
    setIsFormDirty(true);
    setCompanyOverviewErrors({})
    if (Object.values(value).length !== 3) {
      setFormData(
        {
          ...value,
          index: index
        }
      )
    }

  }

  const clearAleart = () => {
    const timer = setTimeout(() => {
      setShowAlertBox(false);
      setShowMessage("");
      clearTimeout(timer);
    }, 5000);
  }


  const DirectorInfoDelete = async () => {
    if (currentDeletVal.satutoryid) {
      try {
        const response = await axiosPrivate.patch(`/bank-admin/company/delete-satutory-data/${currentDeletVal.satutoryid}`);
        if (response.status === 200) {
          setShowDeleteModel(false);
          const newArray = [...statutoryDetailsMain];
          newArray.splice(deleteIndex, 1);
          setStatutoryDetailsMain(newArray);
          setShowAlertBox(true)
          setShowType("success")
          setShowMessage(response.data.message)
          setFormData(
            {
              // CompanyCIN: "",
              // CompanyLLPIN: "",
              // CompanyPAN: "",
              CompanyGSTIN: "",
              CompanyTan: "",
              document_id: "",
              status: "Active",
              state: "",
              state_code: "",
              index: 0
            }
          )
          setFormDataEdit(false)
          clearAleart()
        }
      } catch (error) {
      }
    } else if (currentDeletVal.index === 0) {
      const newArray = [...statutoryDetailsMain];
      newArray.splice(deleteIndex, 1);
      setStatutoryDetailsMain(newArray)
      setShowDeleteModel(false)
      setShowAlertBox(true)
      setShowType("success")
      setShowMessage("The selected record(s) has been deleted")
      setFormData(
        {
          // CompanyCIN: "",
          // CompanyLLPIN: "",
          // CompanyPAN: "",
          CompanyGSTIN: "",
          CompanyTan: "",
          document_id: "",
          status: "Active",
          state: "",
          state_code: "",
          index: 0
        }
      )
      setFormDataEdit(false)
      clearAleart()
    }
    else if (currentDeletVal) {
      const newArray = [...statutoryDetailsMain];
      newArray.splice(deleteIndex, 1);
      setStatutoryDetailsMain(newArray)
      setShowDeleteModel(false)
      setShowAlertBox(true)
      setShowType("success")
      setShowMessage("The selected record(s) has been deleted")
      setFormData(
        {
          // CompanyCIN: "",
          // CompanyLLPIN: "",
          // CompanyPAN: "",
          CompanyGSTIN: "",
          CompanyTan: "",
          document_id: "",
          status: "Active",
          state: "",
          state_code: "",
          index: 0
        }
      )
      setFormDataEdit(false)
      clearAleart()
    }
  };

  function cancel() {
    setShowPage("CompanyOverview")
    setIconStatus({
      ...iconStatus,
      companyIcon: "selected",
    })
  }


  const postgstn = async () => {
    try {
      const res = await axiosPrivate.get(`getstate/bycompany-gstin/${(formData.CompanyCIN).substring(0, 2)}`)
      if (res.status === 200) {
        setFormData({
          ...formData,
          state: res.data.stateValue.state
        })
      } else {
        setFormData({
          ...formData,
          state: ""
        })
      }
    }
    catch (error: any) {
    }
  }

  // console.log(formData, '--------------')
  return (
    <>
      <div className='form-wrapper'>
        <div className='form-area'>
          <div className="center-approval">
            <div className='company-heading'>
              Statutory Details
            </div>
            {/* <div className='form-main-wrapper'>
              <div>
                <InputComponent
                  height={"40px"}
                  width={"300px"}
                  margin={"0px 45px 30px 0px"}
                  padding={"0px 0px 0px 10px"}
                  border={companyOverviewErrors && companyOverviewErrors.CompanyCIN ? "1px solid red" : "1px solid #A9C3DC"}
                  borderRadius={"0px"}
                  backgroundColor={"white"}
                  color={"black"}
                  type={"text"}
                  placeHolder={"Enter CIN"}
                  inputTitle={"Company CIN"}
                  required={true}
                  maxLength={21}
                  inputValue={formData && formData.CompanyCIN}
                  onFocusOut={postgstn}
                  disabled={true}
                  getUser={(val) => {
                    let value = val.replace(/[^A-Za-z0-9]/g, '').replace(/^\s+/, '').toUpperCase()
                    setFormData({
                      ...formData,
                      CompanyCIN: value,
                      state_code: value.substring(0, 2)
                    })
                    setIconStatus({
                      ...iconStatus,
                      statutoryIcon: "selected"
                    })
                    setIsFormDirty(true)
                    dispatch(resetFormModified(true));
                  }}
                />
              </div>
              <div>
                <InputComponent
                  height={"40px"}
                  width={"300px"}
                  margin={"0px 45px 30px 0px"}
                  padding={"0px 0px 0px 10px"}
                  border={companyOverviewErrors && companyOverviewErrors.CompanyLLPIN ? "1px solid red" : "1px solid #A9C3DC"}
                  borderRadius={"0px"}
                  backgroundColor={"white"}
                  color={"black"}
                  type={"text"}
                  placeHolder={"Enter Company LLPIN"}
                  inputTitle={"Company LLPIN"}
                  required={true}
                  maxLength={8}
                  inputValue={formData && formData.CompanyLLPIN}
                  onFocusOut={postgstn}
                  disabled={true}
                  getUser={(val) => {
                    let value = val.replace(/[^A-Z0-9-]/g, '')
                    setFormData({
                      ...formData,
                      CompanyLLPIN: value,
                      state_code: value.substring(0, 2)
                    })
                    setIconStatus({
                      ...iconStatus,
                      statutoryIcon: "selected"
                    })
                    setIsFormDirty(true)
                    dispatch(resetFormModified(true));
                  }}
                />
              </div>
              <div>
                <InputComponent
                  height={"40px"}
                  width={"300px"}
                  margin={"0px 45px 30px 0px"}
                  padding={"0px 0px 0px 10px"}
                  border={companyOverviewErrors && companyOverviewErrors.CompanyPAN ? "1px solid red" : "1px solid #A9C3DC"}
                  borderRadius={"0px"}
                  backgroundColor={"white"}
                  color={"black"}
                  type={"text"}
                  placeHolder={"Enter Company PAN"}
                  inputTitle={"Company PAN"}
                  required={true}
                  inputValue={formData && formData.CompanyPAN}
                  onFocusOut={postgstn}
                  maxLength={10}
                  disabled={true}
                  getUser={(val) => {
                    let value = val.replace(/[^A-Z0-9-]/g, '')
                    setFormData({
                      ...formData,
                      CompanyPAN: value,
                      state_code: value.substring(0, 2)
                    })
                    setIconStatus({
                      ...iconStatus,
                      statutoryIcon: "selected"
                    })
                    setIsFormDirty(true)
                    dispatch(resetFormModified(true));
                  }}
                />
              </div>
              {/* <div>
              <InputComponent
                height={"40px"}
                width={"300px"}
                margin={"0px 45px 30px 0px"}
                padding={"0px 0px 0px 10px"}
                border={companyOverviewErrors && companyOverviewErrors.CompanyTan ? "1px solid red" : "1px solid #A9C3DC"}
                borderRadius={"0px"}
                backgroundColor={"white"}
                color={"black"}
                type={"text"}
                maxLength={10}
                inputValue={formData && formData?.CompanyTan}
                placeHolder={"Enter Company TAN"}
                inputTitle={"Company TAN"}
                getUser={(val) => {
                  let value = val.toUpperCase()
                  setFormData({
                    ...formData,
                    CompanyTan: value
                  })
                  setIconStatus({
                    ...iconStatus,
                    statutoryIcon: "selected"
                  })
                  setIsFormDirty(true)
                  dispatch(resetFormModified(true));
                }}
              />
            </div> */}
            {/* <div style={{ marginRight: "45px", marginBottom: "30px" }} key={uuidv4()} className={companyOverviewErrors.status ? 'setErrorcompany' : ""}>
              <DropdownComponent
                width={"300px"}
                required={true}
                title={"Status"}
                defaultValue={[{ label: formData?.status === (undefined || "") ? "Select" : formData.status, value: formData?.status === undefined ? "Select" : formData.status }]}
                options={[{ label: "Active", value: "Active" }, { label: "InActive", value: "InActive" }]}
                getData={(value) => {
                  setFormData({
                    ...formData,
                    status: value.value
                  })
                  setIconStatus({
                    ...iconStatus,
                    statutoryIcon: "selected"
                  })
                  setIsFormDirty(true)
                  dispatch(resetFormModified(true));
                }}
              />
            </div>
            <div style={{ marginRight: "45px", marginBottom: "30px" }} key={uuidv4()} className={companyOverviewErrors.state ? 'setErrorcompany' : ""}>
              <DropdownComponent
                width={"300px"}
                title={"Company Reg State"}
                required={true}
                defaultValue={[{ label: formData?.state === (undefined || "") ? "Select" : formData.state, value: formData && formData.state }]}
                options={StatutoryState}
                getData={(value) => {
                  setFormData({
                    ...formData,
                    state: value.value
                  })
                  setIconStatus({
                    ...iconStatus,
                    statutoryIcon: "selected"
                  })
                  setIsFormDirty(true)
                  dispatch(resetFormModified(true));
                }}
              />
            </div> //
            
            </div> */}

            <div className='form-main-wrapper'>
              <div>
                <InputComponent
                  height={"40px"}
                  width={"300px"}
                  margin={"0px 45px 30px 0px"}
                  padding={"0px 0px 0px 10px"}
                  border={companyOverviewErrors && companyOverviewErrors.CompanyGSTIN ? "1px solid red" : "1px solid #A9C3DC"}
                  borderRadius={"0px"}
                  backgroundColor={"white"}
                  color={"black"}
                  type={"text"}
                  placeHolder={"Enter Company GSTIN"}
                  inputTitle={"Company GSTIN"}
                  required={true}
                  maxLength={15}
                  inputValue={formData && formData.CompanyGSTIN}
                  onFocusOut={postgstn}
                  getUser={(val) => {
                    let value = val.replace(/[^A-Za-z0-9]/g, '')
                    setFormData({
                      ...formData,
                      CompanyGSTIN: value,
                      state_code: value.substring(0, 2)
                    })
                    setIconStatus({
                      ...iconStatus,
                      statutoryIcon: "selected"
                    })
                    setIsFormDirty(true)
                    dispatch(resetFormModified(true));
                  }}
                />
              </div>
              <div>
                <InputComponent
                  height={"40px"}
                  width={"300px"}
                  margin={"0px 45px 30px 0px"}
                  padding={"0px 0px 0px 10px"}
                  border={companyOverviewErrors && companyOverviewErrors.CompanyTan ? "1px solid red" : "1px solid #A9C3DC"}
                  borderRadius={"0px"}
                  backgroundColor={"white"}
                  color={"black"}
                  type={"text"}
                  placeHolder={"Enter Company TAN"}
                  inputTitle={"Company TAN"}
                  inputValue={formData && formData.CompanyTan}
                  onFocusOut={postgstn}
                  maxLength={10}
                  getUser={(val) => {
                    let value = val.replace(/[^A-Za-z0-9]/g, '')
                    setFormData({
                      ...formData,
                      CompanyTan: value,
                      // state_code: value.substring(0, 2)
                    })
                    setIconStatus({
                      ...iconStatus,
                      statutoryIcon: "selected"
                    })
                    setIsFormDirty(true)
                    dispatch(resetFormModified(true));
                  }}
                />
              </div>
              <div style={{ marginRight: "45px", marginBottom: "30px" }} key={uuidv4()} className={companyOverviewErrors.status ? 'setErrorcompany' : ""}>
                <DropdownComponent
                  width={"300px"}
                  required={true}
                  title={"Status"}
                  // defaultValue={[{ label: formData && (formData?.status === (undefined || "")) ? "Active" : formData?.status, value: formData && (formData?.status === (undefined)) ? "Active" : formData?.status }]}
                  defaultValue={[{ label: formData?.status === '' ? "Active" : formData.status, value: formData?.status === '' ? "Active" : formData.status }]}
                  options={[{ label: "Active", value: "Active" }, { label: "InActive", value: "InActive" }]}
                  getData={(value) => {
                    setFormData({
                      ...formData,
                      status: value.value
                    })
                    setIconStatus({
                      ...iconStatus,
                      statutoryIcon: "selected"
                    })
                    setIsFormDirty(true)
                    dispatch(resetFormModified(true));
                  }}
                />
              </div>
            </div>
            <div className='form-main-wrapper'>
              <div style={{ marginRight: "45px", marginBottom: "30px" }} key={uuidv4()} className={companyOverviewErrors.state ? 'setErrorcompany' : ""}>
                <DropdownComponent
                  width={"300px"}
                  title={"Company Reg State"}
                  required={true}
                  // defaultValue={[{ label: formData?.state === (undefined || "") ? "Select" : formData.state, value: formData && formData.state }]}
                  defaultValue={[{ label: formData?.state === '' ? "Select" : formData.state, value: formData?.state === '' ? "" : formData.state }]}
                  options={dropDownState}
                  getData={(value) => {
                    setFormData({
                      ...formData,
                      state: value.value
                    })
                    setIconStatus({
                      ...iconStatus,
                      statutoryIcon: "selected"
                    })
                    setIsFormDirty(true)
                    dispatch(resetFormModified(true));
                  }}
                />
              </div>
              <div style={{ opacity: 0.5 }}>
                <InputComponent
                  height={"40px"}
                  width={"300px"}
                  margin={"0px 45px 30px 0px"}
                  padding={"0px 0px 0px 10px"}
                  border={companyOverviewErrors && companyOverviewErrors.state_code ? "1px solid red" : "1px solid #A9C3DC"}
                  borderRadius={"0px"}
                  backgroundColor={"white"}
                  color={"black"}
                  type={"text"}
                  inputTitle={"State Code"}
                  placeHolder={"State Code"}
                  maxLength={2}
                  required={true}
                  disabled={true}
                  inputValue={formData && formData.state_code}
                  getUser={(value) => {
                    const val = value.replace(/[^0-9]/g, "")
                    setFormData({
                      ...formData,
                      state_code: val
                    })
                    setIconStatus({
                      ...iconStatus,
                      statutoryIcon: "selected"
                    })
                    setIsFormDirty(true)
                    dispatch(resetFormModified(true));
                  }}
                />
              </div>
            </div>

            <div>
              <ButtonComponent
                title={formDataEdit ? "Update" : 'Add'}
                height={"50px"}
                width={"150px"}
                backgroundColor={"#0055D4"}
                color={"white"}
                margin={"0px 8px"}
                className={"button-component-hover common-btn"}
                handleClick={() => {
                  handelAdd()
                }}
                disabled={true}
              />
            </div>
            <div className={"Company-DetailsGrid"} >
              <StatutoryDetailsGrid
                statutoryDetails={statutoryDetailsMain}
                retriveData={retriveData}
                showmodal={(val: any, index: any) => {
                  setShowDeleteModel(true);
                  setDeleteIndex(index);
                  setCurrentDeletVal(val);
                }}
              />
            </div>
          </div>

          <div>
            <div className='footer-company'>
              {/* {!isEditMode && */}
                <ButtonComponent
                  title={"Cancel"}
                  height={"50px"}
                  width={"150px"}
                  margin={"0px 8px"}
                  backgroundColor={"#888888"}
                  color={"white"}
                  className={"button-component-hover cancel"}
                  handleClick={isFormModified ? () => { setShowChangesModal(true) } : returnGird}
                />
              {/* } */}
              {/* {
                isEditMode ?
                  <ButtonComponent
                    title={"Cancel"}
                    height={"50px"}
                    width={"150px"}
                    margin={"0px 8px"}
                    backgroundColor={"#888888"}
                    color={"white"}
                    className={"button-component-hover cancel"}
                    handleClick={isFormModified ? () => { setShowChangesModal(true) } : returnGird}
                  /> : */}
                  <ButtonComponent
                    title={"Previous"}
                    height={"50px"}
                    width={"150px"}
                    margin={"0px 8px"}
                    backgroundColor={"#0055D4"}
                    color={"white"}
                    className={"button-component-hover common-btn"}
                    handleClick={cancel}
                  />
              {/* } */}
              {/* <ButtonComponent
                title={"Save"}
                height={"50px"}
                width={"150px"}
                backgroundColor={"#0055D4"}
                color={"white"}
                margin={"0px 8px"}
                className={"button-component-hover common-btn"}
                handleClick={next}
              /> */}
              <ButtonComponent
                title={"Next"}
                height={"50px"}
                width={"150px"}
                backgroundColor={"#0055D4"}
                color={"white"}
                margin={"0px 8px"}
                className={"button-component-hover common-btn"}
                handleClick={next}
              // disabled={false}
              // disabled={statutoryDetailsMain?.length != 0 || isFormDirty}
              />
            </div>
          </div>
        </div>
      </div>
      {
        showAlertBox &&
        <div className='alert-warp'>
          <AlertBox type={showType} message={showMessage} />
        </div>

      }
      {
        showChangesModal &&
        <Changesmodal
          closeModal={() => setShowChangesModal(false)}
          handleClose={() => {
            setShowChangesModal(false);
          }}
          leavePage={() => returnGird()} />
      }
      {
        showDeleteModel &&
        <div >


          <DeleteModal
            getDelete={() => { DirectorInfoDelete() }}

            clearValue={(value) => {
              setShowDeleteModel(value);
              setCurrentDeletVal({})
            }}
            modelType={"grid-delete"}

          />
        </div>
      }
    </>
  )
})
export default StatutoryDetails

