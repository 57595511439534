import "../../styles/view-table.scss";
import "../../styles/onboarding/company/grid-icon.scss"
import "../../styles/gst/gst-challan-details.scss"
interface Props {
    approvalStatus?: any;
}
function GstApprovalStatus({ approvalStatus }: Props) {
    return (
        <>
            <div className="payment-history-border" style={{ marginTop: "45px" }}>
                <div className="payment-history">
                    Approval Status
                </div>
                <div className='view-table-component' style={{ marginTop: "40px", marginBottom: "20px", minHeight: "110px", maxHeight: "auto", overflowY: "auto" }}>
                    <table className='company-seperate-grid'>
                        <thead>
                            <th className='cus-width' style={{ paddingLeft: "20px", minWidth: "150px", textAlign: "left" }}>{"Date"}</th>
                            <th className='cus-width' style={{ minWidth: "150px", textAlign: "left" }}>{"Actor"}</th>
                            <th className='cus-width' style={{ minWidth: "150px", textAlign: "left", paddingRight: "60px" }}>{"Status"}</th>
                            <th className='cus-width' style={{ minWidth: "300px", textAlign: "left" }}>{"Remarks"}</th>
                        </thead>
                        <tbody >
                            {approvalStatus == undefined || approvalStatus?.length == 0  ?
                                <tr className='style-grid-differ'>
                                    <td></td>
                                    <td></td>
                                    <td style={{ alignItems: "center" }}>No records to show</td>
                                    <td></td>
                                </tr> : approvalStatus?.map((data: any, index: number) => (

                                    <tr className='main-body-header' style={{ height: "50px" }}>
                                        <td className='left-icons' style={{ paddingLeft: "20px", minWidth: "150px", paddingRight: "20px", textAlign: "left" }}>{data.date}</td>
                                        <td className='left-icons' style={{ minWidth: "150px", textAlign: "left" }}>{data.actor}</td>
                                        <td className='left-icons' style={{ minWidth: "150px", textAlign: "left", paddingRight: "60px" }}>{data.status}</td>
                                        <td className='left-icons' style={{ minWidth: "300px", textAlign: "left" }}>{data.remarks}</td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}

export default GstApprovalStatus


