import React, { useState, useRef, ChangeEvent, KeyboardEvent, useEffect } from 'react'
import '../../../styles/modals/password-setup-modal.scss';
import { RxCross1 } from 'react-icons/rx';
import InputComponent from '../form-elements/input-component';
import useAuth from '../../../services/hooks/useauth';
import useAxiosPrivate from "../../../services/hooks/useaxios-private";
import { IoIosEye, IoIosEyeOff } from 'react-icons/io';
import AlertBox from '../alert-box';
import ButtonComponent from '../form-elements/button-component';


interface Styles {
    getUser: (val: any) => void;
    resetPasswordnotification: (val: any) => void;
    isLocked: boolean
    setIsLocked: (val: any) => void;
    email: string;
}

export default function Passwordsetupmodal({
    getUser,
    resetPasswordnotification,
    isLocked,
    setIsLocked,
    email
}: Styles) {

    const length = 6;
    const { auth, setAuth } = useAuth();
    const axiosPrivate = useAxiosPrivate();

    const [currentModal, setCurrentModal] = useState<string | null>("manage-password")
    const [currentProcess, setCurrentProcess] = useState<string | null>("")
    const [userPassword, setUserPassword] = useState<string | null>("********")
    const [password, setPassword] = useState<string>("")
    const [confirmPassword, setconfirmPassword] = useState<string>("")
    const [passwordVisible, setPasswordVisible] = useState<boolean>(false)
    const [showPassword, setShowPassword] = useState<boolean>()
    const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>()
    const [showAlertBox, setShowAlertBox] = useState<boolean>(false)  //AlertBox State
    const [showMessage, setShowMessage] = useState<string>("")   //AlertBox Message State
    const [showType, setShowType] = useState<string>("warning")   //AlertBox Type State
    const [otp, setOTP] = useState<string[]>(Array(length).fill(''));
    const [attemptsRemaining, setAttemptsRemaining] = useState(3);
    const [errorMessage, setErrorMessage] = useState("otp-number");
    const [disabled, setDisabled] = useState<boolean>(true)
    const [isPasswordValid, setIsPasswordValid] = useState<boolean>(false)
    const [isConfirmPasswordValid, setIsConfirmPasswordValid] = useState<boolean>(false)
    const [disabledResentotp, setDisabledResentotp] = useState<boolean>(false);

    useEffect(() => {
        if (auth.otpCountRemaining) {
            setAttemptsRemaining(auth.otpCountRemaining)
        }
    }, [auth.otpCountRemaining])

    const inputRefs = useRef<HTMLInputElement[]>([]);

    const handleChange = (e: ChangeEvent<HTMLInputElement>, index: number) => {

        const value = e.target.value;
        if (!(/^\d*$/.test(value))) return; // Regex for only  Number in input field
        if (e.target.value.length > 1) return;
        const updatedOTP = [...otp];
        updatedOTP[index] = e.target.value;
        setOTP(updatedOTP);

        if (e.target.value !== '' && index < length - 1) {
            inputRefs.current[index + 1].focus();
        }
    };

    const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>, index: number,) => {

        if (e.key === 'Backspace' && index > 0 && otp[index] === '') {
            inputRefs.current[index - 1].focus();
        }

        if (e.key === 'Enter') {

            if (currentModal === "enter-otp") {
                otpSubmit()
            } else if (currentModal === "reset-current-password") {
                handleUpdatePassword()
            }
        }

    };

    const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>,) => {
        e.preventDefault();

        const pasteData = e.clipboardData.getData('text/plain');
        const numbersOnly = pasteData.replace(/\D/g, '');
        const newOTP = numbersOnly.slice(0, length).split('');

        const updatedOTP = newOTP.concat(Array(length - newOTP.length).fill(''));

        setOTP(updatedOTP);
        inputRefs.current[0].focus();

    };

    // Function for clicking the Enter button to move on submit button
    const handleKeyDown = (e: any) => {
        if (e.key === 'Enter') {
            handleUpdatePassword()
        }

        if (e.keyCode === 32) {
            e.preventDefault();
        }
    };

    const handleKeyPressed = (e: any) => {
        if (e.keyCode === 32) {
            e.preventDefault();
        }
    }

    // Async Function for OTP Submit Button
    async function otpSubmit() {

        if (currentProcess === "view-password") {

            const combinedOtp = otp.join("")
            try {
                const res = await axiosPrivate.post("/verify/otp/show-password", {
                    "email": email,
                    "otp": combinedOtp
                })

                if (res.status === 200) {
                    const currentpassword = (res.data.usersPassword)
                    setUserPassword(currentpassword)
                    setCurrentModal("manage-password");
                    setPasswordVisible(true)
                    setShowPassword(false)

                    setShowAlertBox(true)
                    setShowType("success")
                    setShowMessage(res.data.message)

                    setTimeout(() => {
                        setShowAlertBox(false);
                    }, 5000);
                }
                setOTP(Array(length).fill(''))
            }
            catch (error: any) {
                if (error.response.data.error) {
                    setShowAlertBox(true)
                    setShowType("warning")
                    setShowMessage(error.response.data.error)
                }
                setErrorMessage("wrong-otp")

                if (error?.response?.data?.isLocked === "true") {
                    setIsLocked(true)
                }

                setAuth({
                    ...auth,
                    otpCountRemaining: error.response.data.otpRemainingCount
                })
                setAttemptsRemaining(error.response.data.otpRemainingCount)

                setTimeout(() => {
                    setShowAlertBox(false);
                }, 5000);
            }
        }
        else if (currentProcess === "reset-password") {
            const combinedOtp = otp.join("")
            try {
                const res = await axiosPrivate.post("/verify/otp/sign-in", {
                    "email": email,
                    "otp": combinedOtp
                })

                if (res.status === 200) {
                    setCurrentModal("reset-current-password");
                    setPasswordVisible(true)
                    setShowPassword(false)

                    setShowAlertBox(true)
                    setShowType("success")
                    setShowMessage(res.data.message)


                    setTimeout(() => {
                        setShowAlertBox(false);
                    }, 5000);
                }

                setOTP(Array(length).fill(''))
            }
            catch (error: any) {
                if (error.response.data.error) {
                    setShowAlertBox(true)
                    setShowType("warning")
                    setShowMessage(error.response.data.error)
                }
                setErrorMessage("wrong-otp")

                if (error?.response?.data?.isLocked === "true") {
                    setIsLocked(true)
                }

                setAuth({
                    ...auth,
                    otpCountRemaining: error.response.data.otpRemainingCount
                })
                setAttemptsRemaining(error.response.data.otpRemainingCount)

                setTimeout(() => {
                    setShowAlertBox(false);
                }, 5000);
            }
        }
    }

    // Function for Resend OTP
    async function resendOtpVerify() {

        if (currentProcess === "view-password") {
            const updatedOtp = Array(length).fill('');
            setOTP(updatedOtp);
            setErrorMessage("otp-number")
            setDisabledResentotp(true)
            try {
                const res = await axiosPrivate.post("/resend/otp/sign-in/password", { "email": email, })
                if (res.status === 200) {
                    setShowAlertBox(true)
                    setShowType("success")
                    setShowMessage(res.data.message)

                    setTimeout(() => {
                        setShowAlertBox(false);
                    }, 5000);
                }
            }
            catch (error: any) {
                if (error.response.data.error) {
                    setShowAlertBox(true)
                    setShowType("warning")
                    setShowMessage(error.response.data.error)
                }

                if (error?.response?.data?.isLocked === "true") {
                    setIsLocked(true)
                }
                setTimeout(() => {
                    setShowAlertBox(false);
                }, 5000);
            } finally {
                setDisabledResentotp(false)
            }
        } else if (currentProcess === "reset-password") {
            const updatedOtp = Array(length).fill('');
            setOTP(updatedOtp);
            setErrorMessage("otp-number")
            setDisabledResentotp(true)
            try {
                const res = await axiosPrivate.post("/resend/otp/sign-in/password/manage", { "email": email, })

                if (res.status === 200) {
                    setShowAlertBox(true)
                    setShowType("success")
                    setShowMessage(res.data.message)

                    setTimeout(() => {
                        setShowAlertBox(false);
                    }, 5000);
                }
            }
            catch (error: any) {
                if (error.response.data.error) {
                    setShowAlertBox(true)
                    setShowType("warning")
                    setShowMessage(error.response.data.error)
                }

                if (error?.response?.data?.isLocked === "true") {
                    setIsLocked(true)
                }

                setTimeout(() => {
                    setShowAlertBox(false);
                }, 5000);
            } finally {
                setDisabledResentotp(false)
            }
        }
    }

    // Function for Show Password
    const handleShowPassword = async () => {
        if (passwordVisible) return;
        setCurrentModal("enter-otp")
        // setSwitches("text")
        setShowMessage("")
        try {
            const res = await axiosPrivate.post("/otp/passwords/management", {
                "email": email
            })

            if (res.status === 200) {
                setCurrentProcess("view-password")

                setAuth({
                    ...auth,
                    otpCountRemaining: res.data.otpRemainingCount
                })
                setAttemptsRemaining(res.data.otpRemainingCount)

                setShowAlertBox(true)
                setShowType("success")
                setShowMessage(res.data.message)


                setTimeout(() => {
                    setShowAlertBox(false);
                }, 5000);
            }
        }
        catch (error: any) {
            if (error.response.data.error) {
                setShowAlertBox(true)
                setShowType("warning")
                setShowMessage(error.response.data.error)
            }
            setErrorMessage("wrong-otp")

            setTimeout(() => {
                setShowAlertBox(false);
            }, 5000);
        }
    }

    // Function for Reset Button 
    const handleResetButton = async () => {
        setCurrentModal("enter-otp")
        setErrorMessage("otp-number")
        const updatedOtp = Array(length).fill('');
        setOTP(updatedOtp);
        try {
            const res = await axiosPrivate.post("/otp/passwords/showPass", {
                "email": email
            })

            if (res.status === 200) {
                setCurrentProcess("reset-password")

                setAuth({
                    ...auth,
                    otpCountRemaining: res.data.otpRemainingCount
                })
                setAttemptsRemaining(res.data.otpRemainingCount)

                setShowAlertBox(true)
                setShowType("success")
                setShowMessage(res.data.message)

                setTimeout(() => {
                    setShowAlertBox(false);
                }, 5000);
            }
        }
        catch (error: any) {
            if (error.response.data.error) {
                setShowAlertBox(true)
                setShowType("warning")
                setShowMessage(error.response.data.error)
            }
            setErrorMessage("wrong-otp")

            setTimeout(() => {
                setShowAlertBox(false);
            }, 5000);
        }
    }

    // Async Function for Enter password to Update Password
    async function handleUpdatePassword() {

        const minLength = 12;
        const hasLowerCase = /[a-z]/.test(password);
        const hasUpperCase = /[A-Z]/.test(password);
        const hasSpecialChar = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/.test(password);
        const hasNumber = /\d/.test(password);
        if (
            password.length < minLength ||
            !hasLowerCase ||
            !hasUpperCase ||
            !hasSpecialChar ||
            !hasNumber
        ) return;
        try {
            const res = await axiosPrivate.patch("/sigin-in/options/update/password", {
                "email": email,
                "newPassword": password,
                "confirmNewPassword": confirmPassword
            })
            resetPasswordnotification(true);
            getUser(false);
        }
        catch (error: any) {
            if (error.response.data.error) {
                setShowAlertBox(true)
                setShowType("warning")
                setShowMessage(error.response.data.error)
            }

            setTimeout(() => {
                setShowAlertBox(false);
            }, 5000);
        }
    }

    function isValidPassword(password: string, confirmPassword: string) {
        const minLength = 12;
        const hasLowerCase = /[a-z]/.test(password);
        const hasUpperCase = /[A-Z]/.test(password);
        const hasSpecialChar = /^(?=.*[!@#$%_-])[a-zA-Z0-9!@#$%_-]+$/.test(password);
        const hasNumber = /\d/.test(password);

        if (
            password.length < minLength ||
            !hasLowerCase ||
            !hasUpperCase ||
            !hasSpecialChar ||
            !hasNumber || password !== confirmPassword
        ) {
            setDisabled(true);
        }
        else {
            setDisabled(false);
        }
    }

    // Function for Toggle to show password
    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    // Function for Toggle to show Confirm password
    const toggleShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };


    return (
        <>
            {/* After manage button is clicked */}
            {currentModal === "manage-password" &&
                <div className={"password-setup-modal"}>
                    <div className={"password-container"}>
                        <div className={"close-icon"}>
                            <span className={"cross-icon"}>
                                <RxCross1 style={{ fontSize: "40px" }} onClick={() => { getUser(false) }} />
                            </span>
                        </div>
                        <div className={'password-setup-title'}>
                            <div className={"title"}>{"Manage Password"}</div>
                            <div className={"sub-title"}>{"Current Password"}</div>
                            <div className={"content"}>
                                <div className='input-Main'>
                                    <InputComponent
                                        height={"40px"}
                                        width={"100%"}
                                        padding={"5px"}
                                        border={""}
                                        borderRadius={""}
                                        backgroundColor={""}
                                        color={""}
                                        type="text"
                                        getUser={(val) => { setUserPassword(val) }}
                                        inputValue={userPassword}
                                        readOnly
                                    />
                                </div>

                                {passwordVisible ? <div className='eyeCloseicon' style={{ cursor: passwordVisible ? "default" : "pointer" }}>
                                    <IoIosEyeOff
                                        style={{ fontSize: "35px", color: "#0055D4", padding: "5px" }}
                                    /></div> : <div className='eyeCloseicon' style={{ cursor: passwordVisible ? "default" : "pointer" }}>
                                    <IoIosEye
                                        style={{ fontSize: "35px", color: "#0055D4", padding: "5px" }}
                                        onClick={handleShowPassword}
                                    /></div>}
                            </div>
                            <div className='resetPassword'>
                                <a href='#' onClick={handleResetButton}>Reset Password</a>
                            </div>
                            <div className={"buttons"} >
                                <ButtonComponent
                                    title={"Cancel"}
                                    height={"50px"}
                                    width={"130px"}
                                    backgroundColor={"#888888"}
                                    color={"white"}
                                    className={"button-component-hover cancel"}
                                    handleClick={() => { getUser(false) }}
                                />
                            </div>
                        </div>

                    </div>
                </div>
            }

            {/* After reset & Eye button is clicked */}
            {currentModal === "enter-otp" &&
                <div className={"otp-verification-modal"}>
                    <div className={"container"}>
                        <div className={"close-icon"}>
                            <span className={"cross-icon"}>
                                <RxCross1 style={{ fontSize: "40px" }} onClick={() => { getUser(false) }} />
                            </span>
                        </div>
                        <div className={'pin-setup-title'}>
                            <div className={"title"}>{"Making sure it's you"}</div>
                            <div className={"main-title"}>
                                <p className={'sub-title-otp'}>{"OTP verification"}</p>
                                <p>{"Enter first 3 digits of the OTP sent to your Mobile and last 3 digits sent to your Email."}</p>
                                <p>{"This OTP will expire in 3 mins."}</p>
                            </div>
                            <div className={"content"}>
                                <div className={'input-Main'}>
                                    <div className={errorMessage === "wrong-otp" ? "otp-number wrong-otp" : "otp-number"}>
                                        {otp.map((digit, index) => (
                                            <input
                                                key={index}
                                                type={"text"}
                                                className={`${digit === "" ? (index < 3 ? 'mobile-placeholder' : 'email-placeholder') : ''}`}
                                                value={digit}
                                                maxLength={1}
                                                onChange={(e) => handleChange(e, index)}
                                                onKeyDown={(e) => handleKeyPress(e, index)}
                                                onPaste={(e) => handlePaste(e)}
                                                ref={(ref) => (inputRefs.current[index] = ref as HTMLInputElement)}
                                            />
                                        ))}
                                    </div></div>
                            </div>

                            <div className='resend-text'>Didn't get a code?<button className='resend-button'
                                disabled={disabledResentotp || isLocked}
                                onClick={resendOtpVerify}>Click to resend.</button>
                            </div>

                            <div className={"buttons"} >
                                <ButtonComponent
                                    title={"Submit"}
                                    height={"50px"}
                                    width={"130px"}
                                    backgroundColor={"#0055D4"}
                                    color={"white"}
                                    handleClick={otpSubmit}
                                    disabled={otp.filter((digit) => { return digit === "" }).length > 0 || isLocked}
                                    className={otp.filter((digit) => { return digit === "" }).length > 0 || isLocked ? "button-component-hover disabled" :
                                        "button-component common-btn"}
                                />
                            </div>
                            <div className='resend-timing'>{"*Attempts remaining :" + " " + attemptsRemaining}</div>
                        </div>
                    </div>
                </div>
            }

            {/* Enter New Password & Confirm Password */}
            {currentModal === "reset-current-password" &&
                <div className={"passwordReset-setup-modal"}>
                    <div className={"passwordReset-container"}>
                        <div className={"close-icon"}>
                            <span className={"cross-icon"}>
                                <RxCross1 style={{ fontSize: "40px" }} onClick={() => { getUser(false) }} />
                            </span>
                        </div>
                        <div className={'password-setup-title'}>
                            <div className={"title"}>{"Reset Password"}</div>
                            <div className={"sub-title"}>{"New Password"}</div>
                            <div className={"content"}>
                                <div className='input-Main'>
                                    <InputComponent
                                        height={"40px"}
                                        width={"100%"}
                                        padding={"10px 10px"}
                                        border={""}
                                        borderRadius={""}
                                        backgroundColor={""}
                                        color={""}
                                        placeHolder={"New Password"}
                                        type={showPassword ? 'text' : 'password'}
                                        getUser={(value: any) => { setPassword(value); isValidPassword(value, confirmPassword); }}
                                        inputValue={password}
                                        blockCopyPaste={true}
                                        maxLength={16}
                                        handleKeyDown={handleKeyPressed}
                                        fieldStatus={(val: any) => { setIsPasswordValid(val) }}
                                        points={[
                                            { name: "Atleast 12 characters", pattern: "12 character" },
                                            { name: "Atleast one lower case", pattern: "lowercase" },
                                            { name: "Atleast one upper case", pattern: "uppercase" },
                                            { name: "Atleast One Number", pattern: "number" },
                                            { name: "Atleast one special character <br /> (Allowed: ~, !, @, #, $, %, _, -)", pattern: "specialCharacter" }
                                        ]}
                                    />
                                </div>
                                {showPassword ? <div className='eyeCloseicon' style={{ cursor: "pointer" }}>
                                    <IoIosEyeOff
                                        style={{ fontSize: "35px", color: "#0055D4", padding: "5px" }}
                                        onClick={toggleShowPassword}
                                    /></div> : <div className='eyeCloseicon' style={{ cursor: "pointer" }}>
                                    <IoIosEye
                                        style={{ fontSize: "35px", color: "#0055D4", padding: "5px" }}
                                        onClick={toggleShowPassword}
                                    /></div>}
                            </div>

                            <div className={"sub-title"}>{"Confirm Password"}</div>
                            <div className={"content"}>
                                <div className='input-Main'>
                                    <InputComponent
                                        height={"40px"}
                                        width={"100%"}
                                        padding={"10px 10px"}
                                        border={""}
                                        borderRadius={""}
                                        backgroundColor={""}
                                        color={""}
                                        showTooltipTitle={false}
                                        placeHolder={"Confirm Password"}
                                        points={[{ name: "Password do not match", pattern: "confirmpassword" },]}
                                        type={showConfirmPassword ? 'text' : 'password'}
                                        getUser={(value: any) => { setconfirmPassword(value); isValidPassword(password, value); }}
                                        inputValue={confirmPassword}
                                        password={password}
                                        disabled={(password === "" || isPasswordValid) ? true : false}
                                        blockCopyPaste={true}
                                        handleKeyDown={handleKeyDown}
                                        maxLength={16}
                                        fieldStatus={(val: any) => { setIsConfirmPasswordValid(val) }}
                                    />
                                </div>
                                {showConfirmPassword ? <div className='eyeCloseicon' style={{ cursor: "pointer" }}>
                                    <IoIosEyeOff
                                        style={{ fontSize: "35px", color: "#0055D4", padding: "5px" }}
                                        onClick={(password === "" || isPasswordValid) ? () => { } : toggleShowConfirmPassword}
                                    /></div> : <div className='eyeCloseicon' style={{ cursor: "pointer" }}>
                                    <IoIosEye
                                        style={{ fontSize: "35px", color: "#0055D4", padding: "5px" }}
                                        onClick={(password === "" || isPasswordValid) ? () => { } : toggleShowConfirmPassword}
                                    /></div>}
                            </div>
                            <div className={"buttons"} >
                                <ButtonComponent
                                    title={"Cancel"}
                                    height={"50px"}
                                    width={"130px"}
                                    backgroundColor={"#888888"}
                                    color={"white"}
                                    className={"button-component-hover cancel"}
                                    handleClick={() => { getUser(false) }}
                                />
                                <ButtonComponent
                                    title={"Save"}
                                    height={"50px"}
                                    disabled={disabled}
                                    width={"130px"}
                                    backgroundColor={"#0055D4"}
                                    color={"white"}
                                    className={disabled ? "button-component-hover disabled" : "button-component common-btn"}
                                    handleClick={handleUpdatePassword}
                                />
                            </div>
                        </div>

                    </div>
                </div>
            }

            {showAlertBox && <AlertBox type={showType} message={showMessage} />}

        </>
    )
}

