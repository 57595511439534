import React, { Component } from 'react'
import From from '../../components/approval/virtualcard/form'
import Grid from '../../components/approval/virtualcard/grid'



interface vendorValues {
  virtualCardFun: string;
  isEditMode: boolean,
  editData: {}

}

class VirtualCardApproval extends Component<{}, vendorValues> {
  constructor(props: vendorValues) {
    super(props);
    this.state = {
      virtualCardFun: "grid",
      isEditMode: false,
      editData: {}
    };
  }

  render() {
    return (
      <div>
        {
          this.state.virtualCardFun === "form" &&
          <From
            getEditMode={(val: any) =>
              this.setState({
                isEditMode: val
              })
            }
            virtualCard={(val: any) => {
              this.setState({ virtualCardFun: val })
            }}
            editData={this.state.editData}
            isEditMode={this.state.isEditMode}
            // virtualCardFun={this.state.virtualCardFun}
            modeFunction={(val: boolean) => {

              this.setState({
                isEditMode:val
              })
            }}
          />
        }
        {
          this.state.virtualCardFun === "grid" &&
          <Grid
            getEditMode={(val) =>
              this.setState({
                isEditMode:val
              })
            }
            virtualCard={(val: string) => {
              this.setState({
                virtualCardFun: val,
              })
            }}

            modeFunction={(val: boolean) => {
              this.setState({
                isEditMode: val
              })
            }}

            getEditData={(name: string, data: any,) => {
              this.setState({
                virtualCardFun: "form",
                editData: { ...data },
                isEditMode: true,
              })
            }
            }

          />
        }
      </div>
    )
  }
}

export default VirtualCardApproval;