import React, { useState, useRef, ChangeEvent, KeyboardEvent, useEffect } from 'react'
import { RxCross1 } from 'react-icons/rx';
import useAxiosPrivate from "../../../services/hooks/useaxios-private";
import useAuth from '../../../services/hooks/useauth';
import AlertBox from '../alert-box';
import { IoIosEye, IoIosEyeOff } from 'react-icons/io';
import ButtonComponent from '../form-elements/button-component';
import '../../../styles/modals/otp-verify-modal.scss';
import { encryptToken } from '../../../services/utils/token-utils';



interface Styles {
    isLocked: boolean
    setIsLocked: (val: any) => void;
    leavePage: () => void;
    getStatus: (val: any) => void
    otpVerify: () => void

}

export default function OtpVerifyModal({
    otpVerify,
    getStatus,
    isLocked,
    leavePage,

}: Styles) {

    const { auth, setAuth } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const length = 6;

    const [showmanagePin, setShowManagePin] = useState<boolean>(false)
    const [userPin, setUserPin] = useState<string | null>("")
    const [pin, setPin] = useState<string[]>(Array(length).fill(''));
    const [showPassword, setShowPassword] = useState<boolean>()
    const [otp, setOTP] = useState<string[]>(Array(length).fill(''));
    const [showAlertBox, setShowAlertBox] = useState<boolean>(false)  //AlertBox State
    const [showMessage, setShowMessage] = useState<string>("")   //AlertBox Message State
    const [showType, setShowType] = useState<string>("warning")   //AlertBox Type State
    const [attemptsRemaining, setAttemptsRemaining] = useState(3);
    const [errorMessage, setErrorMessage] = useState<string>("otp-number");
    const [disabledResentotp, setDisabledResentotp] = useState<boolean>(false);
    const [hideMobileNumber, setHideMobileNumber] = useState<string>("")


    useEffect(() => {
        if (auth.otpCountRemaining) {
            setAttemptsRemaining(auth.otpCountRemaining)
        }
    }, [auth.otpCountRemaining])



    // Function for PIN field 
    const inputRefs = useRef<HTMLInputElement[]>([]);

    const handleChange = (e: ChangeEvent<HTMLInputElement>, index: number, inputName: string) => {
        if (inputName === "pin") {
            const value = e.target.value;
            if (!(/^\d*$/.test(value))) return; // Regex for only  Number in input field
            if (e.target.value.length > 1) return;
            const updatedPIN = [...pin];
            updatedPIN[index] = e.target.value;
            setPin(updatedPIN);

            if (e.target.value !== '' && index < length - 1) {
                inputRefs.current[index + 1].focus();
            }
        } else {
            const value = e.target.value;
            if (!(/^\d*$/.test(value))) return;  // Regex for only  Number in input field 
            if (e.target.value.length > 1) return;
            const updatedOTP = [...otp];
            updatedOTP[index] = e.target.value;
            setOTP(updatedOTP);
            if (e.target.value !== '' && index < length - 1) {
                inputRefs.current[index + 1].focus();
            }
        }
    }


    const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>, index: number, inputName: string) => {
        if (e.key === 'Backspace' && index > 0 && otp[index] === '') {
            inputRefs.current[index - 1].focus();
        }

        if (e.key === 'Enter') {
            getStatus(stringFromArray)
        }
    }

    const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>, inputName: string) => {
        e.preventDefault();
        if (inputName === "pin") {
            const pasteData = e.clipboardData.getData('text/plain');
            const numbersOnly = pasteData.replace(/\D/g, '');
            const newPIN = numbersOnly.slice(0, length).split('');
            const updatedPIN = newPIN.concat(Array(length - newPIN.length).fill(''));
            setPin(updatedPIN);
            inputRefs.current[0].focus();
        } else {
            const pasteData = e.clipboardData.getData('text/plain');
            const numbersOnly = pasteData.replace(/\D/g, '');
            const newOTP = numbersOnly.slice(0, length).split('');

            const updatedOTP = newOTP.concat(Array(length - newOTP.length).fill(''));

            setOTP(updatedOTP);
            inputRefs.current[0].focus();
        }
    }
    useEffect(() => {
        fetchData()
    }, [])

    const fetchData = async () => {
        try {
            const response = await axiosPrivate.get('user/get-privilege');
            mobileNumber(response.data.email)
        } catch (error) {

        }
    };
    async function mobileNumber(email: any) {
        try {
            const res = await axiosPrivate.post('user/mobile_number', { "email": encryptToken(email)})
            if (res.data?.user.maskedVal) {
                setHideMobileNumber(res.data.user.maskedVal)
            }

        } catch (error: any) {
        }
    }



    // Function for toggle to show password
    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };
    const stringFromArray = otp.join('');

    function autoFocus(e: any, index: number, arg2: string) {
        throw new Error('Function not implemented.');
    }

    return (

        <>
            <div className={"approval-otp-verification-modal"}>
                <div className={"approval-container"}>
                    <div className={'approval-pin-setup-title'}>
                        <div className={"approval-title"}>{"Authenticate the validated data using the below option"}</div>
                        <div className='approval-otp-container'>
                            <div className={"approval-main-title"}>
                                <div className='mt-20'>
                                    <p className={'approval-sub-title-otp'}>{"OTP"}</p>
                                    <div className='approval-sub-title'>{"A 6-digit OTP has been sent to your registered " + hideMobileNumber + ". This OTP will expire in 3 mins."}</div>
                                </div>
                                <div className={"approval-content"}>
                                    <div className={'approval-input-Main'}>
                                        <div className={errorMessage === "approval-wrong-otp" ? "approval-otp-number approval-wrong-otp" : "approval-otp-number"}>

                                            {otp.map((digit, index) => (
                                                <input
                                                    key={index}
                                                    type="text"
                                                    value={digit}
                                                    autoFocus={index === 0}
                                                    maxLength={1}
                                                    onChange={(e) => handleChange(e, index, "otp")}
                                                    onKeyDown={(e) => handleKeyPress(e, index, "otp")}
                                                    onPaste={(e) => handlePaste(e, "otp")}
                                                    ref={(ref) => (inputRefs.current[index] = ref as HTMLInputElement)}
                                                />
                                            ))}

                                        </div></div>
                                </div>

                                <div><button className='approval-resend-button' onClick={() => {
                                    otpVerify()
                                }}>Resend OTP?</button></div>
                            </div>
                        </div>
                        <div className={"approval-buttons"} >
                            <ButtonComponent
                                title={"Cancel"}
                                height={"50px"}
                                width={"130px"}
                                margin={"0px 8px"}
                                backgroundColor={"#888888"}
                                color={"white"}
                                handleClick={() => {
                                    leavePage()

                                }}
                                className="button-component-hover cancel"
                            />
                            <ButtonComponent
                                title={"Verify"}
                                height={"50px"}
                                width={"130px"}
                                backgroundColor={"#0055D4"}
                                color={"white"}
                                handleClick={() => {
                                    getStatus(stringFromArray)
                                }}
                                disabled={otp.filter((digit) => { return digit === "" }).length > 0 || isLocked}
                                className={otp.filter((digit) => { return digit === "" }).length > 0 || isLocked ? "button-component-hover disabled" : "button-component common-btn"}
                            />

                        </div>
                    </div>
                </div>
            </div>





        </>

    )
}

