import React, { useEffect, useState } from 'react'
import { BiPlus, BiMinus } from "react-icons/bi";
import "../../../styles/view-table.scss";
import IconButton from '../../../components/common-component/form-elements/icon-button';
import DeleteModal from '../../../components/common-component/modals/delete-modal';
import "../../../styles/onboarding/company/grid-icon.scss"




interface Props {
    editMode?: boolean;
    zIndex?: string;
    statutoryDetails: any;
    retriveData: (value: any, index: number) => void;
    showmodal: (value: any, index: number) => void;
    updateDatas: (value: any) => void;
}

function StatutoryDetailsUpdatedGrid({ statutoryDetails, retriveData, showmodal, editMode, zIndex, updateDatas }: Props) {
    const [showDeleteModel, setShowDeleteModel] = useState<boolean>(false)
    let aa: any = [
        {
            companyGstin: "123456789012",
            status: "Active",
            companyRegState: "Delhi",
            stateCode: "DL",
        }
    ]
    return (
        <>
            <div className='view-table-component' style={{ marginTop: "40px", marginBottom: "40px", height: "205px", overflowY: "auto" }} >
                <table>
                    <thead style={zIndex ? {} : { zIndex: "0" }}>
                        {/* <tr> */}
                        {/* <th className='align-differ' >Company CIN</th>
                        <th className='align-differ' >Company LLPIN</th>
                        <th className='align-differ' >Company PAN</th> */}
                        <th className='align-differ' >Company GSTIN</th>
                        <th className='status' style={{ paddingLeft: "5px" }}>Status</th>
                        <th className='access' style={{ paddingLeft: "5px" }}>Company Reg State</th>
                        <th className='access'>State Code</th>
                        <th className='' style={{ minWidth: "90px" }}>Action</th>
                        {/* </tr> */}
                    </thead>
                    <tbody >
                        {statutoryDetails?.length === 0 ?
                            <tr className='style-grid-differ'>
                                <td></td>
                                <td></td>
                                <td>No records to show</td>
                                <td></td>
                                <td></td>
                            </tr> :
                            statutoryDetails?.map((data: any, index: number) => (
                                // <div className='main-body-header' style={{ marginTop: "0px" }} key={index}>
                                <tr className='main-body-header' style={{ marginTop: "0px" }} key={index}>
                                    {/* <td className='align-differ'>{data.companyCin}</td>
                                    <td className='align-differ'>{data.companyLLPIN}</td>
                                    <td className='align-differ'>{data.companyPan}</td> */}
                                    <td className='align-differ'>{data.companyGSTIN}</td>
                                    {/* <td className='tan'>{data.tan}</td> */}
                                    <td className='status' style={{ paddingLeft: "5px" }}>{data.status}</td>
                                    <td className='access' style={{ paddingLeft: "5px" }}>{data.companyRegState}</td>
                                    <td className='middle-icons'>{data.stateCode}</td>
                                    <td className='last-icon flexcls'>
                                        <IconButton
                                            iconName={"Edit"}
                                            height={"40px"}
                                            width={"40px"}
                                            fontSize={"30px"}
                                            margin={"0px 8px"}
                                            color={"white"}
                                            backgroundColor={"#0055D4"}
                                            hover={editMode ? false : true}
                                            cursor={editMode ? "not-allowed" : "pointer"}
                                            opacity={editMode ? "0.5" : "1"}
                                            handleClick={() => {
                                                // if (editMode) return;
                                                updateDatas(data)
                                                // retriveData(data, index)
                                            }}
                                        />
                                        <IconButton
                                            iconName={"Delete"}
                                            height={"40px"}
                                            width={"40px"}
                                            fontSize={"25px"}
                                            margin={"0px 8px"}
                                            color={"white"}
                                            backgroundColor={"#D60000"}
                                            hover={editMode ? false : true}
                                            cursor={editMode ? "not-allowed" : "pointer"}
                                            opacity={editMode ? "0.5" : "1"}
                                            handleClick={() => {
                                                // if (editMode) return;
                                                showmodal(data, index)
                                            }}
                                        />
                                    </td>
                                </tr>
                                // </div>
                            ))}
                    </tbody>
                </table>
            </div>
            {/* {
                showDeleteModel &&
                <div >
                    <DeleteModal
                        getDelete={() => { }}
                        clearValue={(value) => { setShowDeleteModel(value) }}
                        modelType={"grid-delete"}
                    />
                </div>
            } */}
        </>
    )
}

export default StatutoryDetailsUpdatedGrid;