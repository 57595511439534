import React, { useState, useRef, ChangeEvent, KeyboardEvent, useEffect } from 'react'
import '../../../styles/modals/pin-setup-modal.scss';
import { RxCross1 } from 'react-icons/rx';
import useAxiosPrivate from "../../../services/hooks/useaxios-private";
import useAuth from '../../../services/hooks/useauth';
import AlertBox from '../alert-box';
import { IoIosEye, IoIosEyeOff } from 'react-icons/io';
import ButtonComponent from '../form-elements/button-component';


interface Props {
    getUser: (val: any) => void;
    successnotification: (val: any) => void;
    changeButtonType: () => void;
    isLocked: boolean
    setIsLocked: (val: any) => void;
    email: string
}

export default function Pinsetupmodal({
    getUser,
    successnotification,
    changeButtonType,
    isLocked,
    setIsLocked,
    email
}: Props) {

    const { auth, setAuth } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const length = 6;

    const [showmanagePin, setShowManagePin] = useState<boolean>(false)
    const [userPin, setUserPin] = useState<string | null>("")
    const [pin, setPin] = useState<string[]>(Array(length).fill(''));
    const [showPassword, setShowPassword] = useState<boolean>()
    const [otp, setOTP] = useState<string[]>(Array(length).fill(''));
    const [showAlertBox, setShowAlertBox] = useState<boolean>(false)
    const [showMessage, setShowMessage] = useState<string>("")
    const [showType, setShowType] = useState<string>("warning")
    const [attemptsRemaining, setAttemptsRemaining] = useState(3);
    const [errorMessage, setErrorMessage] = useState<string>("otp-number");
    const [disabledResentotp, setDisabledResentotp] = useState<boolean>(false);

    useEffect(() => {
        if (auth.otpCountRemaining) {
            setAttemptsRemaining(auth.otpCountRemaining)
        }
    }, [auth.otpCountRemaining])

    // Function for PIN field 
    const inputRefs = useRef<HTMLInputElement[]>([]);

    console.log(email)
    // Function for input onchange 
    const handleChange = (e: ChangeEvent<HTMLInputElement>, index: number, inputName: string) => {
        if (inputName === "pin") {
            const value = e.target.value;
            if (!(/^\d*$/.test(value))) return; // Regex for only  Number in input field
            if (e.target.value.length > 1) return;
            const updatedPIN = [...pin];
            updatedPIN[index] = e.target.value;
            setPin(updatedPIN);

            if (e.target.value !== '' && index < length - 1) {
                inputRefs.current[index + 1].focus();
            }
        } else {
            const value = e.target.value;
            if (!(/^\d*$/.test(value))) return;  // Regex for only  Number in input field 
            if (e.target.value.length > 1) return;
            const updatedOTP = [...otp];
            updatedOTP[index] = e.target.value;
            setOTP(updatedOTP);
            if (e.target.value !== '' && index < length - 1) {
                inputRefs.current[index + 1].focus();
            }
        }
    }

    // Function for input key press 
    const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>, index: number, inputName: string) => {
        if (inputName === "pin") {
            if (e.key === 'Backspace' && index > 0 && pin[index] === '') {
                inputRefs.current[index - 1].focus();
            }
        } else {
            if (e.key === 'Backspace' && index > 0 && otp[index] === '') {
                inputRefs.current[index - 1].focus();
            }
        }

        if (e.key === 'Enter') {
            if (inputName === "pin") {
                handlePinSave()
            } else {
                handleOtpSubmit()
            }
        }
    }

    // Function for paste value in input field 
    const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>, inputName: string) => {
        e.preventDefault();
        if (inputName === "pin") {
            const pasteData = e.clipboardData.getData('text/plain');
            const numbersOnly = pasteData.replace(/\D/g, '');
            const newPIN = numbersOnly.slice(0, length).split('');
            const updatedPIN = newPIN.concat(Array(length - newPIN.length).fill(''));
            setPin(updatedPIN);
            inputRefs.current[0].focus();
        } else {
            const pasteData = e.clipboardData.getData('text/plain');
            const numbersOnly = pasteData.replace(/\D/g, '');
            const newOTP = numbersOnly.slice(0, length).split('');

            const updatedOTP = newOTP.concat(Array(length - newOTP.length).fill(''));

            setOTP(updatedOTP);
            inputRefs.current[0].focus();
        }
    }

    // API calling for Enter the Password & Submit Password
    async function handleOtpSubmit() {
        const stringFromArray = otp.join('');
        try { 
            const res = await axiosPrivate.post("/verify/otp/sign-in", {
                "email": email,
                "otp": stringFromArray,
            })

            if (res.status === 200) {
                setShowManagePin(true)
                setShowAlertBox(true)
                setShowType("success")
                setShowMessage(res.data.message)

                setTimeout(() => {
                    setShowAlertBox(false);
                }, 3000);
            }
        }
        catch (error: any) {
            if (error.response.data.error) {
                setShowAlertBox(true)
                setShowType("warning")
                setShowMessage(error.response.data.error)
            }
            setErrorMessage("wrong-otp")

            if (error?.response?.data?.isLocked === "true") {
                setIsLocked(true)
            }

            setAuth({
                ...auth,
                otpCountRemaining: error.response.data.otpRemainingCount
            })
            setAttemptsRemaining(error.response.data.otpRemainingCount)

            setTimeout(() => {
                setShowAlertBox(false);
            }, 3000);
        }
    }

    // Function for Resend OTP
    async function resendOtpVerify() {
        const updatedOtp = Array(length).fill('');
        setOTP(updatedOtp);
        setErrorMessage("otp-number")
        setDisabledResentotp(true)
        try {
            const res = await axiosPrivate.post("/resend/otp/sign-in/set-pin", { "email": email, })

            if (res.status === 200) {
                setShowAlertBox(true)
                setShowType("success")
                setShowMessage(res.data.message)

                setTimeout(() => {
                    setShowAlertBox(false);
                }, 3000);
            }
        }
        catch (error: any) {
            if (error.response.data.error) {
                setShowAlertBox(true)
                setShowType("warning")
                setShowMessage(error.response.data.error)
            }

            if (error?.response?.data?.isLocked === "true") {
                setIsLocked(true)
            }

            setTimeout(() => {
                setShowAlertBox(false);
            }, 3000);
        }
        finally {
            setDisabledResentotp(false)
        }

    }

    // Function for identify the Pin number rules
    const isValidArray = (newPin: string[]): boolean => {
        for (let i = 0; i < newPin.length - 2; i++) {
            const num1 = parseInt(newPin[i], 10);
            const num2 = parseInt(newPin[i + 1], 10);
            const num3 = parseInt(newPin[i + 2], 10);

            // Check for more than 2 sequential ascending or descending index values
            if ((num2 - num1 === 1 && num3 - num2 === 1) || (num1 - num2 === 1 && num2 - num3 === 1)) {
                return false; // More than 2 sequential index values (ascending or descending)
            }

            // Check for repeating numbers in nearest index values
            if (num1 === num2 || num2 === num3) {
                return false; // Repeating numbers in nearest index values
            }
        }
        return true;
    };
    
    // API calling for PIN setting & Submit pin
    async function handlePinSave() {
        const isValidPin = isValidArray(pin)
        if (isValidPin) {
            let combinedPin = pin.join("")

            try {
                const res = await axiosPrivate.post("/sign-in/options/pin", {
                    "email": email,
                    "password": userPin,
                    "pin": combinedPin
                })

                if (res.status === 200) {
                    getUser(false);
                    successnotification(true)
                    setShowPassword(false)
                    changeButtonType()
                }
            }
            catch (error: any) {
                if (error.response.data.error) {
                    setShowAlertBox(true)
                    setShowType("warning")
                    setShowMessage(error.response.data.error)
                }

                setTimeout(() => {
                    setShowAlertBox(false);
                }, 3000);
            }
        } else {
            setShowAlertBox(true)
            setShowType("warning")
            setShowMessage("Your PIN cannot have sequential numbers or repeated numbers.")
            setTimeout(() => {
                setShowAlertBox(false);
            }, 3000);
        }
    }

    // Function for toggle to show password
    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    return (

        <>

            {!showmanagePin ?

                // After Manage Button is clicked
                <div className={"otp-verification-modal"}>
                    <div className={"container"}>
                        <div className={"close-icon"}>
                            <span className={"cross-icon"}>
                                <RxCross1 style={{ fontSize: "40px" }} onClick={() => { getUser(false) }} />
                            </span>
                        </div>
                        <div className={'pin-setup-title'}>
                            <div className={"title"}>{"Making sure it's you"}</div>
                            <div className={"main-title"}>
                                <p className={'sub-title-otp'}>{"OTP verification"}</p>
                                <p>{"Enter first 3 digits of the OTP sent to your Mobile and last 3 digits sent to your Email."}</p>
                                <p>{"This OTP will expire in 3 mins."}</p>
                            </div>
                            <div className={"content"}>
                                <div className={'input-Main'}>
                                    <div className={errorMessage === "wrong-otp" ? "otp-number wrong-otp" : "otp-number"}>

                                        {otp.map((digit, index) => (
                                            <input
                                                key={index}
                                                type="text"
                                                className={`${digit === "" ? (index < 3 ? 'mobile-placeholder' : 'email-placeholder') : ''}`}
                                                value={digit}
                                                maxLength={1}
                                                onChange={(e) => handleChange(e, index, "otp")}
                                                onKeyDown={(e) => handleKeyPress(e, index, "otp")}
                                                onPaste={(e) => handlePaste(e, "otp")}
                                                ref={(ref) => (inputRefs.current[index] = ref as HTMLInputElement)}
                                            />
                                        ))}

                                    </div></div>
                            </div>

                            <div className='resend-text'>Didn't get a code?<button className='resend-button'
                                disabled={disabledResentotp || isLocked}
                                onClick={resendOtpVerify}>Click to resend.</button></div>

                            <div className={"buttons"} >
                                <ButtonComponent
                                    title={"Submit"}
                                    height={"50px"}
                                    width={"130px"}
                                    backgroundColor={"#0055D4"}
                                    color={"white"}
                                    handleClick={handleOtpSubmit}
                                    disabled={otp.filter((digit) => { return digit === "" }).length > 0 || isLocked}
                                    className={otp.filter((digit) => { return digit === "" }).length > 0 || isLocked ? "button-component-hover disabled" : "button-component common-btn"}
                                />
                            </div>
                            <div className='resend-timing'>{"*Attempts remaining: " + attemptsRemaining}</div>
                        </div>
                    </div>
                </div>

                :

                // New PIN setup
                <div className={"pin-setup-modal"}>
                    <div className={"container"}>
                        <div className={"close-icon"}>
                            <span className={"cross-icon"}>
                                <RxCross1 style={{ fontSize: "40px" }} onClick={() => { getUser(false) }} />
                            </span>
                        </div>
                        <div className={'pin-setup-title'}>
                            <div className={"title"}>{"Manage PIN"}</div>
                            <div className={"sub-title"}>{"Enter New PIN"}</div>
                            <div className={"content"}>
                                <div className='pin-number'>
                                    {pin.map((digit, index) => (
                                        <input
                                            key={index}
                                            type={showPassword ? 'text' : 'password'}
                                            value={digit}
                                            maxLength={1}
                                            onChange={(e) => handleChange(e, index, "pin")}
                                            onKeyDown={(e) => handleKeyPress(e, index, "pin")}
                                            onPaste={(e) => handlePaste(e, "pin")}
                                            ref={(ref) => (inputRefs.current[index] = ref as HTMLInputElement)}
                                            data-testid="pin-digit-input"
                                        />
                                    ))}
                                    {showPassword ? <div className='eyeCloseIcon' style={{ cursor: "pointer" }}>
                                        <IoIosEyeOff
                                            style={{ fontSize: "35px", color: "#0055D4", padding: "5px" }}
                                            onClick={toggleShowPassword}
                                        /></div> : <div className='eyeCloseIcon' style={{ cursor: "pointer" }}>
                                        <IoIosEye
                                            style={{ fontSize: "35px", color: "#0055D4", padding: "5px" }}
                                            onClick={toggleShowPassword}
                                        /></div>}
                                </div>


                            </div>

                            <div className={"buttons"} >
                                <ButtonComponent
                                    title={"Cancel"}
                                    height={"50px"}
                                    width={"130px"}
                                    backgroundColor={"#888888"}
                                    color={"white"}
                                    className={"button-component-hover cancel"}
                                    handleClick={() => { getUser(false) }}
                                />
                                <ButtonComponent
                                    title={"Save"}
                                    height={"50px"}
                                    width={"130px"}
                                    backgroundColor={"#0055D4"}
                                    color={"white"}
                                    handleClick={handlePinSave}
                                    disabled={pin.filter((digit) => { return digit === "" }).length > 0}
                                    className={pin.filter((digit) => { return digit === "" }).length > 0 ? "button-component-hover disabled" : "button-component common-btn"}
                                />
                            </div>

                        </div>
                    </div>
                </div>
            }

            {showAlertBox && <AlertBox type={showType} message={showMessage} />}
        </>

    )
}

