import { ReactComponent as Groupby } from '../../assets/icons/groupby.svg'
import DropdownComponent from '../common-component/form-elements/dropdown-component';
import "../../styles/dashboard/filter-tooltip.scss";
interface propTypes {
    groupByOptions: any;
    storeGroupByOption: any;
    setStoreGroupByOption: (Value: any) => void;
}

function FilterToolTip({ groupByOptions, setStoreGroupByOption, storeGroupByOption }: propTypes) {
    return (
        <div className='fillter-tooltip-component'>
            <div className='filter-header'>
                <div>
                    <Groupby className='icon' />
                </div>
                <p>{"Filters and slicers affecting this visual"}</p>
            </div>
            <div className={'dropdown-box'}>
                <DropdownComponent
                    isDisabled={false}
                    options={groupByOptions}
                    width='100px'
                    getData={(val) => {
                        setStoreGroupByOption(val)
                    }}
                    defaultValue={[
                        {
                            label: storeGroupByOption.label !== "" ? storeGroupByOption.label : "Organisation",
                            value: storeGroupByOption.value !== "" ? storeGroupByOption.value : "Organisation",
                        }
                    ]}
                />
            </div>
            <div className='triangle'></div>
        </div>
    )
}

export default FilterToolTip;