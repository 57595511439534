import React, { useEffect, useRef, useState } from 'react'
import MainPageCard from '../../../components/common-component/cards/main-page-card'
import AgGrid, { AgGridRef } from '../../../components/common-component/grids-and-tables/ag-grid';
import { userLogColumnData, rowDatathis } from '../../../config/log-reports/user-log/user-log-events-config';
import '../../../styles/product-admin/log-reports/administration.scss'
import DropdownComponent from '../../../components/common-component/form-elements/dropdown-component';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import useAxiosPrivate from "../../../services/hooks/useaxios-private";

// import UserLogDetailsModal from '../modals/user-log-details';
import CommonShowLogDetails from '../../../components/common-component/modals/show-log-deatils';
import InputComponent from '../../../components/common-component/form-elements/input-component';
import ButtonComponent from '../../../components/common-component/form-elements/button-component';
import AlertBox from '../../../components/common-component/alert-box';

interface Props {
    getEditData: (nav: any, data: any) => void;

}

export default function AdministrationLog() {
    const gridDataDownloadRef = useRef<AgGridRef>(null);
    const [adminDetailsErrors, setAdminDetailsErrors] = useState<Record<string, boolean>>({})
    const [showType, setShowType] = useState("warning") // error message showType
    const [showMessage, setShowMessage] = useState<string>("") // error message showMessage
    const [getExistingEmail, setGetExistingEmail] = useState<string>("") // error message showMessage
    const [showAlertBox, setShowAlertBox] = useState(false) // error message ShowAlertBox
    const [adminDatas, setAdminDatas] = useState<any>({
        email: ""
    })


    const axiosPrivate = useAxiosPrivate();

    const adminValidationRules: any = {
        vendorData: {
            email: {
                regex: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                field: "mandatory",
                shouldNotBe: ""
            },
        }
    }
    const updatedIsValueValidAll: Record<string, boolean> = {};
    const validateForm = (): boolean => {
        const updatedFieldValidity: Record<string, boolean> = {};
        const isMandatoryfalse = true;
        const isRegexfalse = true;
        Object.keys(adminValidationRules.vendorData).forEach((field) => {
            const rule = adminValidationRules.vendorData[field];
            const value = adminDatas[field];
            console.log(value, rule, field)
            const isValueValid = (rule.field === "mandatory") ? (value ? (value !== rule.shouldNotBe) : false) : true;
            const isRegexValid = rule.regex !== "" ? new RegExp(rule.regex).test(value) : true;
            updatedFieldValidity[field] = !isValueValid || !isRegexValid;
            updatedIsValueValidAll[field] = !isValueValid;
            console.log(value, isValueValid, isRegexValid)
        });
        setAdminDetailsErrors(updatedFieldValidity);
        const isFormValid = Object.keys(updatedFieldValidity).every((keys) => {
            return !updatedFieldValidity[keys]
        });
        return isFormValid;
    };


    async function SubmitAdminEmail() {
        const isFormValid = validateForm()
        if (isFormValid) {
            try {
                const res = await axiosPrivate.post('administration/email/update', {
                    "oldEmail": getExistingEmail, "newEmail": adminDatas.email
                });
                if (res.status === 200) {
                    setShowAlertBox(true);
                    setShowType("success");
                    setShowMessage(res.data.message)
                    setTimeout(() => {
                        setShowAlertBox(false);
                    }, 5000);
                }
            } catch (error: any) {
                setShowAlertBox(true);
                setShowType("danger");
                setShowMessage(error.response.data.error)
                setTimeout(() => {
                    setShowAlertBox(false);
                }, 5000);
            }
        } else {
            setShowAlertBox(true);
            setShowType("danger");
            setShowMessage(Object.values(updatedIsValueValidAll).every((values) => !values) ?
                "Some of the field(s) are not in required format." : "Mandatory field(s) should not be left blank.")
            setTimeout(() => {
                setShowAlertBox(false);
            }, 5000);
        }
    }
    async function getAdminEmail() {
        try {
            const response = await axiosPrivate.get(`administration`);
            if (response.status === 200) {
                // console.log(response.data.vendorCode)
                setAdminDatas({
                    ...adminDatas,
                    email: response.data.result[0].email,

                })
                setGetExistingEmail(response.data.result[0].email)
                // setVendorCode(response.data.vendorCode)
            }
        } catch (error) {
        }
    }

    useEffect(() => {
        getAdminEmail()
    }, [])
    return (
        <>
            <div className='admin-conatiner'>
                <div className='administration-layout'>
                    <div className='admin-left-panel'></div>
                    <div className='admin-right-panel'></div>
                </div>
                <div className='login-area'>
                    <div className='admin-header'>{"Send Notification Via"}</div>
                    <div className='email-input'>
                        <InputComponent
                            height={"40px"}
                            width={"350px"}
                            margin={"0px 60px 30px 0px"}
                            padding={"0px 0px 0px 10px"}
                            border={adminDetailsErrors.email ? "1px solid red" : "1px solid #A9C3DC"}
                            borderRadius={"0px"}
                            backgroundColor={"white"}
                            autoFocus={true}
                            color={"black"}
                            type={"mobile"}
                            maxLength={254}
                            inputTitle={"Email ID"}
                            placeHolder={"Enter Email ID"}
                            required={true}
                            inputValue={adminDatas.email}
                            getUser={(value: any) => {
                                setAdminDatas({
                                    ...adminDatas,
                                    email: value
                                })
                            }}
                        />
                    </div>
                    {/* <div style={{ marginLeft: "40px" }}>
                        <InputComponent
                            height={"40px"}
                            width={"350px"}
                            margin={"0px 60px 30px 0px"}
                            padding={"0px 0px 0px 10px"}
                            border={"1px solid #A9C3DC"}
                            borderRadius={"0px"}
                            backgroundColor={"white"}
                            autoFocus={true}
                            // disabled={organisationBankData.status === "Pending Approval"}
                            color={"black"}
                            type={"mobile"}
                            maxLength={10}
                            inputTitle={"WhatsApp"}
                            placeHolder={"Enter No"}
                            required={true}
                            // inputValue={organisationBankData.customerId}
                            getUser={(value: any) => {

                            }}
                        />
                    </div> */}

                    <div className='admin-footer'>
                        <ButtonComponent
                            title={"Sumbit"}
                            height={"50px"}
                            width={"180px"}
                            backgroundColor={"#0055D4"}
                            color={"white"}
                            margin={"0px 8px"}
                            className={"button-component-hover common-btn"}
                            handleClick={() => {
                                SubmitAdminEmail()
                            }}
                        />
                    </div>
                </div>


            </div>
            {
                showAlertBox &&
                <div className='alert-warp'>
                    <AlertBox type={showType} message={showMessage} />
                </div>
            }
        </>
    )
}
