import React, { Component } from 'react'
import UtilityForm from '../../components/approval/utility-approval/approval-form';
import UtilityGrid from '../../components/approval/utility-approval/approval-grid';
interface utilityValues {
    utilityFun: string;
    isEditMode: boolean,
    editData: {}
}
class UtilityApproval extends Component<{}, utilityValues>{
    constructor(props: utilityValues) {
        super(props);
        this.state = {
            utilityFun: "UtilityGrid",
            isEditMode: false,
            editData: {}
        };
    }
    render() {
        return (
            <div>
                {
                    this.state.utilityFun === "UtilityForm" &&
                    <UtilityForm
                        utilityFun={(val: any) => {
                            this.setState({ utilityFun: val })
                        }}
                    />
                }
                {
                    this.state.utilityFun === "UtilityGrid" &&
                    <UtilityGrid
                        getEditMode={(val: any) =>
                            this.setState({
                                isEditMode: val
                            })
                        }
                        utilityFun={(val: any) => {
                            this.setState({ utilityFun: val })
                        }}
                        getEditData={(name: string, data: any,) => {
                            this.setState({
                                utilityFun: name,
                                editData: { ...data },
                                isEditMode: true,
                            })
                        }}
                    />
                }
            </div>
        )
    }
}
export default UtilityApproval;
