import { useCallback, useEffect, useState, useRef } from 'react'
import VerticalBarChart from '../common-component/charts/vertical-barchart';
import { coloumDataSample, RegionVsTransactionValueData, RegionVsTransactionfilterDatas, regionGroupByOptions, vendorPieOptions, leftOptions, rightOptions } from '../../config/dashboard/vendor-bank-approval-config';
import ChartFilter from '../common-component/charts/chart-filter';
import useAxiosPrivate from '../../services/hooks/useaxios-private';
import ChartAgGrid, { AgGridRef } from '../common-component/charts/chart-ag-grid';
import SaveFilterModal from './save-filter-modal';
import DownloadChartDataModal from './download-chart-data-modal';
import update from 'immutability-helper'
import AlertBox from '../common-component/alert-box';
import DeleteModal from '../common-component/modals/delete-modal';
import CardTwoChart from '../common-component/cards/card-two-chart';
import PieChart from '../common-component/charts/pie-chart';
import '../../styles/dashboard/organization-vs-companies.scss';

const checkBoxValue = ["Organisation Name", "Company Name", "Company Constitution",]
interface propsType {
    isOpenEmpty?: boolean;
    dashBoardWidth?: any;
    setIsOpenEmpty?: any;
    setOpenOneDashboard?: any;
}

function VendorBankApproval({ dashBoardWidth, setOpenOneDashboard, isOpenEmpty, setIsOpenEmpty }: propsType) {
    // groupByIcon
    const [groupByOptions, setGroupByOptions] = useState<any>([]);
    const [storeGroupByOption, setStoreGroupByOption] = useState<any>({});
    const [openGroupBy, setOpenGroupBy] = useState<boolean>(false);
    // filterIcon
    const [storeFilterOptions, setStoreFilterOptions] = useState<any>([]);
    const [allFilterOptions, setAllFilterOptions] = useState<any>([]);
    const [openSideFilter, setOpenSideFilter] = useState<boolean>(false);
    // rightSideFilter
    const [allDropDownOptions, setAllDropDownOptions] = useState<any>({});
    const [openRightSideFilter, setOpenRightSideFilter] = useState<boolean>(false);
    const [openDropdownIndex, setOpenDropdownIndex] = useState<number | null>(null);
    // barChart
    const [leftBarchartData, setLeftBarchartData] = useState<any>(RegionVsTransactionValueData);
    const [rightBarchartData, setRightBarchartData] = useState<any>(RegionVsTransactionValueData);
    const [orginalBarchartData, setOrginalBarchartData] = useState({});
    const [leftBarchartOptions, setLeftBarchartOptions] = useState(leftOptions);
    const [rightBarchartOptions, setRightBarchartOptions] = useState(rightOptions);
    // pieChart
    const [piechartData, setPiechartData] = useState<any>(RegionVsTransactionValueData);
    const [piechartOptions, setPiechartOptions] = useState<any>(vendorPieOptions);
    // gridIcon
    const [isOpenGrid, setIsOpenGrid] = useState<boolean>(false);
    const [tableColumnData, setTableColumnData] = useState<any[]>([]);
    const [tableRowData, setTableRowData] = useState<any[]>([]);
    // saveFilterModal
    const [isSaveFilterModal, setIsSaveFilterModal] = useState<boolean>(false);
    const [isApplyButton, setIsApplyButton] = useState("")
    const [allSaveFilterTableData, setAllSaveFilterTableData] = useState<any[]>([]);
    const [storeDeleteIndex, setStoreDeleteIndex] = useState("")
    const [storeSaveFilterInputData, setStoreSaveFilterInputData] = useState<any>({
        name: "",
        description: ""
    });
    // DownloadModal
    const [isDownloadModal, setIsDownloadModal] = useState<boolean>(false);
    const [switchBtnOverAll, setSwitchBtnOverAll] = useState<boolean>(false);
    const [switchBtnDetail, setSwitchBtnDetail] = useState<boolean>(false);
    const [checkBoxData, setCheckBoxData] = useState(checkBoxValue);
    const [checkedHeaderdData, setCheckedHeaderdData] = useState([]);
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
    // alertBox
    const [showAlertBox, setShowAlertBox] = useState<boolean>(false)
    const [showType, setShowType] = useState<string>("warning")
    const [showMessage, setShowMessage] = useState<string>("")
    // initial-call-api
    const [isInitialCall, setIsInitialCall] = useState<boolean>(true);
    // chart width
    const [chartInitialWidth, setChartInitialWidth] = useState<any>({});
    // axiosPrivate
    const axiosPrivate = useAxiosPrivate();
    const gridDataDownloadRef = useRef<AgGridRef>(null);
    const width = window.innerWidth;

    // initialSetep
    useEffect(() => {
        setGroupByOptions(regionGroupByOptions)
        setStoreGroupByOption({
            value: regionGroupByOptions[0].value,
            label: regionGroupByOptions[0].label
        })
        setAllFilterOptions(RegionVsTransactionfilterDatas)
        setStoreFilterOptions(RegionVsTransactionfilterDatas[regionGroupByOptions[0].value])

        if (width < 1869 && width > 1601) {
            setChartInitialWidth({
                leftChartWidth: `${(width - 1300)}px`,
                RightChartWidth: `${(width - 1200)}px`,
                pieChartWidth: `${(width - 1410)}px`,
            })
        } else if (width < 1601) {
            setChartInitialWidth({
                leftChartWidth: `${(200)}px`,
                RightChartWidth: `${(300)}px`,
                pieChartWidth: `${(200)}px`,
            })
        } else {
            setChartInitialWidth({
                leftChartWidth: `${(width - 1510)}px`,
                RightChartWidth: `${(width - 1410)}px`,
                pieChartWidth: `${(width - 1510)}px`,
            })
        }
    }, [])

    // initialApiCall
    useEffect(() => {
        if (groupByOptions && groupByOptions.length !== 0 && storeFilterOptions.length !== 0 && isInitialCall) {
            // getDropDownData("All")
            getBarDataForApi(null, null)
            // getSaveFilterData()
            setIsInitialCall(false)
        }
    }, [groupByOptions, storeFilterOptions])

    // handleGroupIconFunctions
    async function handleStoreGroupByOption(value: any) {
        setStoreGroupByOption({ value: value.value, label: value.label });
        setStoreFilterOptions(allFilterOptions[value.value]);
        // setGroupByOptions(gropuByOptions)
        setOpenGroupBy(false);
        addDataForDropDownFilter(allDropDownOptions);
        setIsApplyButton("");
        setIsOpenGrid(false);

        const convertValue = convertToFilterValues(allFilterOptions[value.value]);
        await getBarDataForApi(value.value, convertValue);
        await getDropDownData("All");
    }

    function handleOpenGroupBy() {
        setOpenGroupBy(!openGroupBy);
        setOpenSideFilter(false);
        setOpenRightSideFilter(false);
        setOpenDropdownIndex(null)
    }

    // handleFilterIconFunctions
    const handleOpenClose = (index: number) => {
        if (openDropdownIndex === index) {
            setOpenDropdownIndex(null); // Close the dropdown if it's already open
        } else {
            setOpenDropdownIndex(index); // Open the dropdown
        }
    };

    const updateFilterDatas = async (index: number, newData: any) => {
        const region = newData.cardTitle
        if (region === "Region") {
            await getDropDownData(newData.selectedValue)
        }
        setStoreFilterOptions((prevFilterDatas: any) => {
            const updatedFilterDatas = [...prevFilterDatas];
            updatedFilterDatas[index] = newData;
            return updatedFilterDatas;
        });
    };

    function addDataForDropDownFilter(res: any) {

        if (Object.keys(res).length > 0) {
            const updatedYears: any = [
                { value: "All", label: "All" },
                ...res.data.years.map((elem: any) => ({ value: elem, label: elem }))
            ];
            const updatedOrganisations: any = [
                { value: "All", label: "All" },
                ...res.data.organisations.map((elem: any) => ({ value: elem, label: elem }))
            ];
            const updatedMonths: any = [
                { value: "All", label: "All" },
                ...res.data.months.map((elem: any) => ({ value: elem, label: elem }))
            ];
            const updatedStates: any = [
                { value: "All", label: "All" },
                ...res.data.states.map((elem: any) => ({ value: elem, label: elem }))
            ];

            setStoreFilterOptions((prevOptions: any) => {
                return prevOptions.map((option: any) => {
                    switch (option.cardTitle) {
                        case "Organisation":
                            return { ...option, options: updatedOrganisations };
                        case "Year":
                            return { ...option, options: updatedYears };
                        case "Month":
                            return { ...option, options: updatedMonths };
                        case "State":
                            return { ...option, options: updatedStates };
                        default:
                            return option;
                    }
                });
            });
        }
    }

    function handleOpenSideFilter() {
        setOpenSideFilter(!openSideFilter);
        setOpenGroupBy(false);
        setOpenRightSideFilter(false);
        setOpenDropdownIndex(null)
    }

    function handleOpenRightSideFilter() {
        setOpenRightSideFilter(true);
        setOpenSideFilter(false);
        setOpenGroupBy(false);
        setOpenDropdownIndex(null)
    }

    async function handleApplyButtonForFilter() {
        await getBarDataForApi(null, null)
        setOpenRightSideFilter(false)
        setIsOpenGrid(false)
        setIsApplyButton("");
    }

    // handleSaveFilterIconFunctions
    async function handleSaveFilterDelete() {
        try {
            const res = await axiosPrivate.delete(`/dashboard/filter-data/${storeDeleteIndex}`);
            if (res.status === 200) {
                await getSaveFilterData()
                setShowDeleteModal(false)
                setShowAlertBox(true)
                setShowType("success")
                setShowMessage(res.data.message)
                clearAleart();
            }
        } catch (error: any) {
            console.log(`Error:${error}`)
            setShowDeleteModal(false)
            setShowAlertBox(true)
            setShowType("danger")
            setShowMessage(error.response.data.error)
            clearAleart()
        }
    }

    function handleDeleteForSaveFilter(id: any) {
        setStoreDeleteIndex(id)
        setShowDeleteModal(true)
    }

    async function handleApplyButton(data: any) {
        setIsApplyButton(data.id)
        setStoreGroupByOption({
            value: data.groupBy,
            label: data.groupBy
        })
        setStoreFilterOptions(allFilterOptions[data.groupBy])
        setStoreFilterOptions((prevOptions: any) => {
            return prevOptions.map((option: any) => {
                switch (option.cardTitle) {
                    case "Organisation":
                        return { ...option, selectedValue: data.filterValues.organisationName };
                    case "Year":
                        return { ...option, selectedValue: data.filterValues.year };
                    case "State":
                        return { ...option, selectedValue: data.filterValues.state };
                    case "Y value setting":
                        return { ...option, selectedValue: data.filterValues.Yvaluesetting };
                    case "Region":
                        return { ...option, selectedValue: data.filterValues.region };
                    default:
                        return option;
                }
            });
        });
        const convertValue = {
            Organisation: data.filterValues.organisationName,
            Year: data.filterValues.year,
            State: data.filterValues.state,
            Yvaluesetting: data.filterValues.Yvaluesetting,
            Region: data.filterValues.region,
        }
        await getBarDataForApi(data.groupBy, convertValue)
    }

    function handleSaveFilterInput(value: string, type: string) {
        if (type === "name") {
            setStoreSaveFilterInputData({ ...storeSaveFilterInputData, name: value });
        } else {
            setStoreSaveFilterInputData({ ...storeSaveFilterInputData, description: value });
        };
    }

    async function handleSaveFilterAddButton() {
        if (storeSaveFilterInputData.name === "") {
            setShowAlertBox(true)
            setShowType("danger")
            setShowMessage("Mandatory field(s) should not be left blank.")
            clearAleart();
        } else {
            const payLoad = {
                name: storeSaveFilterInputData.name,
                title: "ProductAdminUtilizationVsRegion",
            }
            try {
                const res = await axiosPrivate.post(`/dashboard/filter-data/duplicate-checking`, payLoad);
                if (res.status === 200) {
                    setStoreSaveFilterInputData({
                        name: "",
                        description: ""
                    })
                    saveFilterDataForApi()
                }
            } catch (error: any) {
                console.log(`Error:${error}`)
                setShowAlertBox(true)
                setShowType("danger")
                setShowMessage(error.response.data.error)
                clearAleart()
            }
        }
    }

    async function saveFilterDataForApi() {
        const convertValue = convertToFilterValues(storeFilterOptions);
        const payLoad = {
            "name": storeSaveFilterInputData.name,
            "description": storeSaveFilterInputData.description,
            "groupBy": storeGroupByOption.value,
            "title": "ProductAdminUtilizationVsRegion",
            "filterValues": {
                "organisationName": convertValue.Organisation,
                "year": convertValue.Year,
                "region": convertValue.Region,
                "state": convertValue.State,
                "Yvaluesetting": convertValue.Yvaluesetting,
            },
        }
        try {
            const res = await axiosPrivate.post(`/dashboard/filter-data`, payLoad);
            if (res.status === 200) {
                getSaveFilterData()
                setShowAlertBox(true)
                setShowType("success")
                setShowMessage(res.data.message)
                clearAleart();
            }
        } catch (error: any) {
            console.log(`Error:${error}`)
            setShowAlertBox(true)
            setShowType("danger")
            setShowMessage(error.response.data.error)
            clearAleart()
        }
    }

    async function handleSaveFilterClearButton() {
        // setGroupByOptions(gropuByOptions)
        setStoreGroupByOption({
            value: groupByOptions[0].value,
            label: groupByOptions[0].label
        })
        setStoreFilterOptions(allFilterOptions[groupByOptions[0].value])
        setIsApplyButton("")
        const convert = convertToFilterValues(allFilterOptions[groupByOptions[0].value])
        await getBarDataForApi(groupByOptions[0].value, convert)
    }

    async function getSaveFilterData() {
        try {
            const res = await axiosPrivate.get(`/dashboard/filter-data/ProductAdminUtilizationVsRegion`);
            if (res.status === 200) {
                setAllSaveFilterTableData(res.data.DashboardFilterData)
            }
        } catch (error: any) {
            console.log(`Error:${error}`)
            setShowAlertBox(true)
            setShowType("danger")
            setShowMessage(error.response.data.error)
            clearAleart()
        }
    }

    function handleSaveFilterModal() {
        setIsSaveFilterModal(true)
        setOpenGroupBy(false)
        setOpenSideFilter(false)
        setOpenRightSideFilter(false)
        setIsOpenGrid(false)
    }

    // handleGridViewIconFunctions
    function handleConvertTableData(data: any) {
        let addColumnData: any[] = [];
        let addRowData: any[] = [];


        Object.keys(data.data.filteredFinalTableData[0]).map((dataset: any) => {
            if (["Roc City", "Status"].includes(dataset)) {
                addColumnData.push({
                    ...coloumDataSample,
                    minWidth: 200,
                    field: dataset,
                    headerName: dataset,
                })
            } else if (dataset === "Approver Email Id") {
                addColumnData.push({
                    ...coloumDataSample,
                    minWidth: 500,
                    field: dataset,
                    headerName: dataset,
                })
            } else {
                addColumnData.push({
                    ...coloumDataSample,
                    minWidth: 250,
                    field: dataset,
                    headerName: dataset,
                })
            }
        })

        data.data.filteredFinalTableData.map((dataset: any, i: number) => {
            let rowData: any = {};
            Object.keys(dataset).map((elem: any) => {
                Object.assign(rowData, { [elem]: dataset[elem] });
            })
            addRowData.push(rowData);
        })

        setTableColumnData(addColumnData)
        setTableRowData(addRowData)
    }

    function handleOpenGrid() {
        setIsOpenGrid(!isOpenGrid)
        setOpenGroupBy(false)
        setOpenSideFilter(false)
        setOpenRightSideFilter(false)
    }

    // handleforClickBarChart-Bar
    async function HandleBarChartClcik(barValue: string, label: string) {
        const monthList = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

        if (monthList.includes(label)) return;
        switch (storeGroupByOption.value) {
            case "Organisation":
                const realDataYear = allFilterOptions["Year"];
                let convertOrganisationValue = convertToFilterValues(realDataYear);
                convertOrganisationValue = { ...convertOrganisationValue, ["Organisation"]: label };
                await getBarDataForApi(null, convertOrganisationValue);

                let updateOrganisation: any = [];
                realDataYear.map((e: any) => {
                    if (e.cardTitle === "Organisation") {
                        updateOrganisation.push({ ...e, ["selectedValue"]: label });
                    } else {
                        updateOrganisation.push({ ...e });
                    }
                })
                setStoreFilterOptions(updateOrganisation);
                setStoreGroupByOption({
                    value: "Year",
                    label: "Year"
                })
                break;
            case "Year":
                let convertYearValue = convertToFilterValues(storeFilterOptions);
                convertYearValue = { ...convertYearValue, ["Region"]: barValue, ['Year']: label };
                await getBarDataForApi(null, convertYearValue);

                let updateYearAndOrganisation: any = [];
                storeFilterOptions.map((e: any) => {
                    if (e.cardTitle === "Region") {
                        updateYearAndOrganisation.push({ ...e, ["selectedValue"]: barValue });
                    } else if (e.cardTitle === "Year") {
                        updateYearAndOrganisation.push({ ...e, ["selectedValue"]: label });
                    } else {
                        updateYearAndOrganisation.push({ ...e });
                    }
                })
                setStoreFilterOptions(updateYearAndOrganisation);
                break;
            // case "Region":               
            //     const realDataState = allFilterOptions["State"];
            //     let convertStateValue = convertToFilterValues(realDataState);
            //     convertStateValue = { ...convertStateValue, ["Organisation"]: barValue, ['Region']: label };
            //     await getBarDataForApi(null, convertStateValue);

            //     let updateStateAndOrganisation: any = [];
            //     realDataState.map((e: any) => {
            //         if (e.cardTitle === "Organisation") {
            //             updateStateAndOrganisation.push({ ...e, ["selectedValue"]: barValue });
            //         } else if (e.cardTitle === "Region") {
            //             updateStateAndOrganisation.push({ ...e, ["selectedValue"]: label });
            //         } else {
            //             updateStateAndOrganisation.push({ ...e });
            //         }
            //     })
            //     setStoreFilterOptions(updateStateAndOrganisation);
            //     setStoreGroupByOption({
            //         value: "State",
            //         label: "State"
            //     })
            //     break;
            case "State":
                const realDataCity = allFilterOptions["City"];
                let convertCityValue = convertToFilterValues(realDataCity);
                convertCityValue = { ...convertCityValue, ["Region"]: barValue, ['State']: label };
                await getBarDataForApi(null, convertCityValue);

                let updateOrganisationAndCity: any = [];
                realDataCity.map((e: any) => {
                    if (e.cardTitle === "Organisation") {
                        updateOrganisationAndCity.push({ ...e, ["selectedValue"]: barValue });
                    } else if (e.cardTitle === "State") {
                        updateOrganisationAndCity.push({ ...e, ["selectedValue"]: label });
                    } else {
                        updateOrganisationAndCity.push({ ...e });
                    }
                })
                setStoreFilterOptions(updateOrganisationAndCity);
                setStoreGroupByOption({
                    value: "City",
                    label: "City"
                })
                break;
            default:
                break;
        }
    }

    // handleDownloadModal
    function handleDownloadBtn() {
        setIsDownloadModal(false)
    }

    function handleCheckBox(event: any, value: string) {
        if (event.target.checked) {
            let addValue: any = checkedHeaderdData;
            if (addValue.length !== 0) {
                addValue.push({
                    id: checkedHeaderdData[checkedHeaderdData.length - 1]["id"] + 1,
                    name: value
                })
            } else {
                addValue.push({
                    id: 1,
                    name: value
                })
            }
            setCheckedHeaderdData(addValue)
        } else {
            let addValue: any = checkedHeaderdData.filter((e: any) => e.name !== value);
            setCheckedHeaderdData(addValue)
        }
    }

    const moveHeader = useCallback((dragIndex: number, hoverIndex: number) => {
        setCheckedHeaderdData((prevData: any) =>
            update(prevData, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, prevData[dragIndex]],
                ],
            }),
        )
    }, [])

    function handleDownloadModall() {
        setIsDownloadModal(true)
        setOpenGroupBy(false)
        setOpenSideFilter(false)
        setOpenRightSideFilter(false)
        setIsOpenGrid(false)
    }

    const handleDownloadExcel = () => {
        gridDataDownloadRef.current!.onBtExport();
    }

    // chartApiFunctions
    async function getBarDataForApi(groupBy: any, filterValue: any) {
        const payLoad: any = {
            groupBy: Object.keys(storeGroupByOption).length !== 0 ? (groupBy || storeGroupByOption.value) : groupByOptions[0].value,
            filterValues: storeFilterOptions.length !== 0 ? (filterValue || convertToFilterValues(storeFilterOptions)) : convertToFilterValues(allFilterOptions[groupByOptions[0].value])
        }
        try {
            // const res = await axiosPrivate.post(`product-admin/vendorapproval/report-data`, payLoad);
            const res = await axiosPrivate.post(`product-admin/vendorapproval/report-data`);
            if (res.status === 200) {
                setDataForDashboard(res)
                handleConvertTableData(res)
            }
        } catch (error) {
            console.log(`Error:${error}`)
        }
    }

    async function getDropDownData(regain: any) {
        try {
            const res = await axiosPrivate.get(`/product-admin/dropdown-report-data/${regain || "All"}`);
            if (res.status === 200) {
                addDataForDropDownFilter(res)
            }
        } catch (error) {
            console.log(`Error:${error}`)
        }
    }

    // convertAndSetDataFunctions
    const convertToFilterValues = (filterData: any) => {
        const filterValues: any = {};
        filterData.forEach((filter: any) => {
            const tittle = (filter.cardTitle).replace(/\s/g, '')
            filterValues[tittle] = filter.selectedValue;
        });
        return filterValues;
    };

    function setDataForDashboard(res: any) {
        let updatedRightDatasets = {};
        let updatedLeftDatasets = {};
        let updatedRightData: any = {};
        let updatedLeftData: any = {};

        updatedRightDatasets = {
            label: res.data.label.length > 0 ? res.data.label.map((label: any) => `${label.slice(0, 10)}...`) : [" company - 1 ", " company - 2 ", " company - 3 ", " company - 4 "],
            data: res.data.pending,
            backgroundColor: "#0055D4",
            borderColor: "#0055D4",
        }
        updatedRightData = {
            labels: res.data.label.length > 0 ? res.data.label.map((label: any) => `${label.slice(0, 10)}...`) : [" company - 1 ", " company - 2 ", " company - 3 ", " company - 4 "],
            datasets: [updatedRightDatasets],
        };
        setRightBarchartData(updatedRightData);

        updatedLeftDatasets = {
            label: res.data.label,
            data: res.data.approve,
            backgroundColor: "#FF8700",
            borderColor: "#FF8700",
        }
        updatedLeftData = {
            labels: res.data.label,
            datasets: [updatedLeftDatasets],
        };
        setLeftBarchartData(updatedLeftData);

        let converPieChartData = {
            labels: ["Approved", "Pending Approval"],
            datasets: [
                {
                    barPercentage: 1.1,
                    barThickness: 25,
                    data: [res.data.approveTotal, res.data.pendingTotal],
                    backgroundColor: ["#FF8700", "#0055D4"],
                    borderColor: ["#FF8700", "#0055D4"],
                    borderWidth: 1
                }
            ]
        }

        setPiechartData(converPieChartData)
        const updateOptions: any = { ...rightBarchartOptions }
        setRightBarchartOptions(updateOptions)
    }

    // clearAleart
    const clearAleart = () => {
        const timer = setTimeout(() => {
            setShowAlertBox(false)
            setShowMessage("");
            clearTimeout(timer);
        }, 5000);
    }

    return (
        <div className='organization-vs-companies-component'>
            <div className='charts-main'>
                <CardTwoChart
                    width={!isOpenEmpty || (width < 1869 && width > 1601) || (width < 1601) ? dashBoardWidth.cardWidth : "1510px"}
                    height={isOpenEmpty ? "400px" : dashBoardWidth.cardHeight}
                    isOpenEmpty={isOpenEmpty}
                    setIsOpenEmpty={setIsOpenEmpty}
                    currentChart={"vendorBankApproval"}
                    chartHeading={"Vendor Bank Approval"}
                    groupByOptions={groupByOptions}
                    storeGroupByOption={storeGroupByOption}
                    openGroupBy={openGroupBy}
                    openSideFilter={openSideFilter}
                    storeFilterOptions={storeFilterOptions}
                    setOpenOneDashboard={setOpenOneDashboard}
                    setStoreGroupByOption={handleStoreGroupByOption}
                    setOpenGroupBy={handleOpenGroupBy}
                    setOpenSideFilter={handleOpenSideFilter}
                    setOpenRightSideFilter={handleOpenRightSideFilter}
                    setIsOpenGrid={handleOpenGrid}
                    setIsSaveFilterModal={handleSaveFilterModal}
                    setIsDownloadModal={handleDownloadModall}
                    isOpenGrid={isOpenGrid}
                    handleDownloadExcel={handleDownloadExcel}
                >
                    {
                        isOpenGrid ?
                            <ChartAgGrid
                                fileName={"VendorBankApprovalReport"}
                                ref={gridDataDownloadRef}
                                tabelRowData={tableRowData}
                                tableColumnData={tableColumnData}
                                className={!isOpenEmpty ? 'ag-theme-alpine-chart-single' : 'ag-theme-alpine-chart'}
                            />
                            :
                            <div className='chart-main-dual' >
                                <div className='chart-pie'>
                                    <PieChart
                                        width={chartInitialWidth.pieChartWidth}
                                        height={isOpenEmpty ? "250px" : "300px"}
                                        options={piechartOptions}
                                        data={piechartData}
                                        clickedEvent={false}
                                    />
                                </div>
                                <div className='chart-dual' >
                                    <div
                                        className='left-chart'
                                        style={{
                                            width: chartInitialWidth.leftChartWidth,
                                            height: isOpenEmpty ? "300px" : "545px"
                                        }}
                                    >
                                        <VerticalBarChart
                                            width={chartInitialWidth.leftChartWidth}
                                            height={isOpenEmpty ? "275px" : "500px"}
                                            options={leftBarchartOptions}
                                            data={leftBarchartData}
                                            clickedEvent={false}
                                        />
                                    </div>
                                    <div
                                        className='right-chart'
                                        style={{
                                            width: chartInitialWidth.RightChartWidth,
                                            height: isOpenEmpty ? "300px" : "545px"
                                        }}
                                    >
                                        <VerticalBarChart
                                            width={chartInitialWidth.RightChartWidth}
                                            height={isOpenEmpty ? "275px" : "500px"}
                                            options={rightBarchartOptions}
                                            data={rightBarchartData}
                                            clickedEvent={false}
                                        />
                                    </div>
                                    <p className='bottom-tittle'>{"Company Name"}</p>
                                </div>
                            </div>
                    }
                </CardTwoChart>
            </div>
            {
                openRightSideFilter &&
                <div className='right-side-filter-component'>
                    <ChartFilter
                        datas={storeFilterOptions}
                        updateFilterDatas={updateFilterDatas}
                        handleOpenClose={handleOpenClose}
                        openDropdownIndex={openDropdownIndex}
                        handleApplyButtonForFilter={handleApplyButtonForFilter}
                        setOpenRightSideFilter={() => { setOpenRightSideFilter(false) }}
                    />
                </div>
            }
            {
                isSaveFilterModal &&
                <SaveFilterModal
                    storeSaveFilterInputData={handleSaveFilterInput}
                    storeInputData={storeSaveFilterInputData}
                    handleSaveFilterAddButton={handleSaveFilterAddButton}
                    handleSaveFilterClearButton={handleSaveFilterClearButton}
                    allSaveFilterTableData={allSaveFilterTableData}
                    setShowDeleteModal={handleDeleteForSaveFilter}
                    handleApplyButton={handleApplyButton}
                    isApplyButton={isApplyButton}
                    hide={() => { setIsSaveFilterModal(false) }}
                />
            }
            {
                isDownloadModal &&
                <DownloadChartDataModal
                    storeGroupByOption={storeGroupByOption.value}
                    storeFilterOptions={storeFilterOptions}
                    switchBtnOverAll={switchBtnOverAll}
                    switchBtnDetail={switchBtnDetail}
                    handleDownloadBtn={handleDownloadBtn}
                    checkBoxData={checkBoxData}
                    checkedHeaderdData={checkedHeaderdData}
                    moveHeader={moveHeader}
                    handleCheckBox={handleCheckBox}
                    hide={() => setIsDownloadModal(false)}
                    setSwitchBtnOverAll={() => { setSwitchBtnOverAll(!switchBtnOverAll) }}
                    setSwitchBtnDetail={() => { setSwitchBtnDetail(!switchBtnDetail) }}
                />
            }
            {
                showAlertBox &&
                <div className='alert-warp' >
                    <AlertBox type={showType} message={showMessage} />
                </div>
            }
            {
                showDeleteModal &&
                <DeleteModal
                    modelType={"grid-delete"}
                    getDelete={handleSaveFilterDelete}
                    clearValue={(value) => {
                        setStoreDeleteIndex("")
                        setShowDeleteModal(value)
                    }}
                />
            }
        </div>
    )
}

export default VendorBankApproval;