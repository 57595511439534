import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react'
import InputComponent from '../../common-component/form-elements/input-component'
import IconButton from '../../common-component/form-elements/icon-button'
import DropdownComponent from '../../common-component/form-elements/dropdown-component'
import ButtonComponent from '../../common-component/form-elements/button-component';
import { v4 as uuidv4 } from 'uuid'
import useAxiosPrivate from "../../../services/hooks/useaxios-private";
import AlertBox from '../../common-component/alert-box';
import { useDispatch, useSelector } from "react-redux";
import { resetFormModified } from "../../../redux/action";
import Changesmodal from '../../common-component/modals/changes-modal';
import moment from 'moment';
// import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';

interface DropdownItem {
  label: string;
  value: string;
}
export interface ChildRef {
  next: (val: any) => void;
}
interface props {
  fetchDatas: (value: any, chooseType: any) => void;
  companyOverviewPan: string;
  setCompanyOverviewPan: (value: any) => void;
  onboardingViewfun: (val: any) => void;
  mainApiData: any;
  setMainApiData: (val: any) => void;
  setIsFormDirty: (val: any) => void;
  setChooseType: (val: any) => void;
  sendDataApi: () => void;
  iconStatus: any
  setShowPage: (val: any) => void;
  setIconStatus: (val: any) => void;
  chooseType: any;
  showPage: any;
  isEditMode: boolean;
  editData: any;


}
let pancardno = "AAGCV3794D";
let directorpan = 'DUQPA2883C';

const CompanyOverview = forwardRef<ChildRef, props>(({ mainApiData, setIsFormDirty, showPage, setShowPage, setIconStatus, sendDataApi, editData, iconStatus, isEditMode, onboardingViewfun, setMainApiData, fetchDatas, companyOverviewPan, setCompanyOverviewPan, setChooseType, chooseType }, ref) => {
  const [constitutionData, setConstitutionData] = useState<DropdownItem[]>([])
  const [companyDataType, setCompanyDataType] = useState(false)
  const [businessData, setBusinessData] = useState<DropdownItem[]>([])
  const [subCategoryData, setSubCategoryData] = useState<DropdownItem[]>([])
  const [organisationNameData, setOrganisationNameData] = useState<DropdownItem[]>([])
  const [companyOverviewErrors, setCompanyOverviewErrors] = useState<Record<string, boolean>>({})
  const [showType, setShowType] = useState("warning") // error message showType
  const [showMessage, setShowMessage] = useState<string>("") // error message showMessage
  const [showAlertBox, setShowAlertBox] = useState(false) // error message ShowAlertBox
  const isFormModified = useSelector((state: any) => state.isFormModified);
  const dispatch = useDispatch();
  const [showChangesModal, setShowChangesModal] = useState<boolean>(false);
  const [errorChoosetype, setErrorChoosetype] = useState<boolean>(false);
  const [bankDropdownDisable, setBankDropdownDisable] = useState<boolean>(false);
  const todayDate = moment(new Date()).format('YYYY-MM-DD');
  const currentyear = new Date().getFullYear();

  //<----Dont change the order for id's
  const [businessType, setBusinessType] = useState<any>([
    {
      label: "Proprietorship",
      value: "Proprietorship",
      id: 1
    },
    {
      label: "Partnership",
      value: "Partnership",
      id: 2
    },
    {
      label: "Private_limited",
      value: "Private_limited",
      id: 3
    },
    {
      label: "Public_limited",
      value: "Public_limited",
      id: 4
    },
    {
      label: "LLP",
      value: "LLP",
      id: 5
    },
    {
      label: "NGO",
      value: "NGO",
      id: 6
    },
    {
      label: "Trust",
      value: "Trust",
      id: 7
    },
    {
      label: "Society",
      value: "Society",
      id: 8
    },
  ])
  const [businessCategory, setBusinessCategory] = useState<any>([
    {
      label: "Financial services",
      value: "Financial services",
      id: 1
    },
    {
      label: "Education",
      value: "Education",
      id: 2
    },
    {
      label: "Healthcare",
      value: "Healthcare",
      id: 3
    },
    {
      label: "Utilities",
      value: "Utilities",
      id: 4
    },
    {
      label: "Government",
      value: "Government",
      id: 5
    },
    {
      label: "Logistics",
      value: "Logistics",
      id: 6
    },
    {
      label: "Tours and travel",
      value: "Tours and travel",
      id: 7
    },
    {
      label: "Transport",
      value: "Transport",
      id: 8
    },
    {
      label: "Ecommerce",
      value: "Ecommerce",
      id: 9
    },
    {
      label: "Food",
      value: "Food",
      id: 10
    },
    {
      label: "It and software",
      value: "It and software",
      id: 11
    },
    {
      label: "Gaming",
      value: "Gaming",
      id: 12
    },
    {
      label: "Media and entertainment",
      value: "Media and entertainment",
      id: 13
    },
    {
      label: "Services",
      value: "Services",
      id: 14
    },
    {
      label: "Housing",
      value: "Housing",
      id: 15
    },
    {
      label: "Not for profit",
      value: "Not for profit",
      id: 16
    },
    {
      label: "Social",
      value: "Social",
      id: 17
    },
    {
      label: "Others",
      value: "Others",
      id: 18
    },
  ])
  const [businessSubCategoryAllList, setBusinessSubCategoryAllList] = useState<any>({
    "1": [
      {
        "label": "Mutualfund",
        "value": "Mutual fund",
        "id": 1
      },
      {
        "label": "Lending",
        "value": "Lending",
        "id": 2
      },
      {
        "label": "Cryptocurrency",
        "value": "Cryptocurrency",
        "id": 3
      },
      {
        "label": "Insurance",
        "value": "Insurance",
        "id": 4
      },
      {
        "label": "NBFC",
        "value": "NBFC",
        "id": 5
      },
      {
        "label": "Cooperatives",
        "value": "Cooperatives",
        "id": 6
      },
      {
        "label": "Pension fund",
        "value": "Pension fund",
        "id": 7
      },
      {
        "label": "Forex",
        "value": "Forex",
        "id": 8
      },
      {
        "label": "Securities",
        "value": "Securities",
        "id": 9
      },
      {
        "label": "Commodities",
        "value": "Commodities",
        "id": 10
      },
      {
        "label": "Accounting",
        "value": "Accounting",
        "id": 11
      },
      {
        "label": "Financial advisor",
        "value": "Financial advisor",
        "id": 12
      },
      {
        "label": "Crowdfunding",
        "value": "Crowdfunding",
        "id": 13
      },
      {
        "label": "Trading",
        "value": "Trading",
        "id": 14
      },
      {
        "label": "Betting",
        "value": "Betting",
        "id": 15
      },
      {
        "label": "Get rich schemes",
        "value": "Get rich schemes",
        "id": 16
      },
      {
        "label": "Moneysend funding",
        "value": "Moneysend funding",
        "id": 17
      },
      {
        "label": "Wire transfers and money orders",
        "value": "Wire transfers and money orders",
        "id": 18
      },
      {
        "label": "Tax preparation services",
        "value": "Tax preparation services",
        "id": 19
      },
      {
        "label": "Tax payments",
        "value": "Tax payments",
        "id": 20
      },
      {
        "label": "Digital goods",
        "value": "Digital goods",
        "id": 21
      },
      {
        "label": "ATMs",
        "value": "ATMs",
        "id": 22
      }
    ],
    "2": [
      {
        "label": "College",
        "value": "College",
        "id": 1
      },
      {
        "label": "Schools",
        "value": "Schools",
        "id": 2
      },
      {
        "label": "University",
        "value": "University",
        "id": 3
      },
      {
        "label": "Professional courses",
        "value": "Professional courses",
        "id": 4
      },
      {
        "label": "Distance learning",
        "value": "Distance learning",
        "id": 5
      },
      {
        "label": "Day care",
        "value": "Day care",
        "id": 6
      },
      {
        "label": "Coaching",
        "value": "Coaching",
        "id": 7
      },
      {
        "label": "E-learning",
        "value": "E-learning",
        "id": 8
      },
      {
        "label": "Vocational and trade schools",
        "value": "Vocational and trade schools",
        "id": 9
      },
      {
        "label": "Sporting clubs",
        "value": "Sporting clubs",
        "id": 10
      },
      {
        "label": "Dance halls studios and schools",
        "value": "Dance halls studios and schools",
        "id": 11
      },
      {
        "label": "Correspondence schools",
        "value": "Correspondence schools",
        "id": 12
      }
    ],
    "3": [
      {
        "label": "Pharmacy",
        "value": "Pharmacy",
        "id": 1
      },
      {
        "label": "Clinic",
        "value": "Clinic",
        "id": 2
      },
      {
        "label": "Hospital",
        "value": "Hospital",
        "id": 3
      },
      {
        "label": "Lab",
        "value": "Lab",
        "id": 4
      },
      {
        "label": "Dietician",
        "value": "Dietician",
        "id": 5
      },
      {
        "label": "Fitness",
        "value": "Fitness",
        "id": 6
      },
      {
        "label": "Health coaching",
        "value": "Health coaching",
        "id": 7
      },
      {
        "label": "Health products",
        "value": "Health products",
        "id": 8
      },
      {
        "label": "Drug stores",
        "value": "Drug stores",
        "id": 9
      },
      {
        "label": "Healthcare marketplace",
        "value": "Healthcare marketplace",
        "id": 10
      },
      {
        "label": "Osteopaths",
        "value": "Osteopaths",
        "id": 11
      },
      {
        "label": "Medical equipment and supply stores",
        "value": "Medical equipment and supply stores",
        "id": 12
      },
      {
        "label": "Podiatrists and Chiropodists",
        "value": "Podiatrists and Chiropodists",
        "id": 13
      },
      {
        "label": "Dentists and Orthodontists",
        "value": "Dentists and Orthodontists",
        "id": 14
      },
      {
        "label": "Hardware Stores",
        "value": "Hardware Stores",
        "id": 15
      },
      {
        "label": "Ophthalmologists",
        "value": "Ophthalmologists",
        "id": 16
      },
      {
        "label": "Orthopedic  goods stores",
        "value": "Orthopedic  goods stores",
        "id": 17
      },
      {
        "label": "Testing laboratories",
        "value": "Testing laboratories",
        "id": 18
      },
      {
        "label": "Doctors",
        "value": "Doctors",
        "id": 19
      },
      {
        "label": "Health practitioners medical services",
        "value": "Health practitioners medical services",
        "id": 20
      },
      {
        "label": "Testing laboratories",
        "value": "Testing laboratories",
        "id": 21
      }
    ],
    "4": [
      {
        "label": "Electricity",
        "value": "Electricity",
        "id": 1
      },
      {
        "label": "Gas",
        "value": "Gas",
        "id": 2
      },
      {
        "label": "Telecom",
        "value": "Telecom",
        "id": 3
      },
      {
        "label": "Water",
        "value": "Water",
        "id": 4
      },
      {
        "label": "Cable",
        "value": "Cable",
        "id": 5
      },
      {
        "label": "Broadband",
        "value": "Broadband",
        "id": 6
      },
      {
        "label": "DTH",
        "value": "DTH",
        "id": 7
      },
      {
        "label": "Internet provider",
        "value": "Internet provider",
        "id": 8
      },
      {
        "label": "Bill and recharge aggregators",
        "value": "Bill and recharge aggregators",
        "id": 9
      }
    ],
    "5": [
      {
        "label": "Central",
        "value": "Central",
        "id": 1
      },
      {
        "label": "State",
        "value": "State",
        "id": 2
      },
      {
        "label": "Intra government purchases",
        "value": "Intra government purchases",
        "id": 3
      },
      {
        "label": "Government postal services",
        "value": "Government postal services",
        "id": 4
      }
    ],
    "6": [
      {
        "label": "Freight",
        "value": "Freight",
        "id": 1
      },
      {
        "label": "Courier",
        "value": "Courier",
        "id": 2
      },
      {
        "label": "Warehousing",
        "value": "Warehousing",
        "id": 3
      },
      {
        "label": "Distribution",
        "value": "Distribution",
        "id": 4
      },
      {
        "label": "End to end logistics",
        "value": "End to end logistics",
        "id": 5
      },
      {
        "label": "Courier services",
        "value": "Courier services",
        "id": 6
      }
    ],
    "7": [
      {
        "label": "Aviation",
        "value": "Aviation",
        "id": 1
      },
      {
        "label": "Accommodation",
        "value": "Accommodation",
        "id": 2
      },
      {
        "label": "OTA",
        "value": "OTA",
        "id": 3
      },
      {
        "label": "Travel agency",
        "value": "Travel agency",
        "id": 4
      },
      {
        "label": "Tourist attractions and exhibits",
        "value": "Tourist attractions and exhibits",
        "id": 5
      },
      {
        "label": "Timeshares",
        "value": "Timeshares",
        "id": 6
      },
      {
        "label": "Aquariums dolphinariums and seaquariums",
        "value": "Aquariums dolphinariums and seaquariums",
        "id": 7
      }
    ],
    "8": [
      {
        "label": "Cab hailing",
        "value": "Cab hailing",
        "id": 1
      },
      {
        "label": "Bus",
        "value": "Bus",
        "id": 2
      },
      {
        "label": "Automobile rentals",
        "value": "Automobile rentals",
        "id": 3
      },
      {
        "label": "Cruise lines",
        "value": "Cruise lines",
        "id": 4
      },
      {
        "label": "Parking lots and garages",
        "value": "Parking lots and garages",
        "id": 5
      },
      {
        "label": "Transportation",
        "value": "Transportation",
        "id": 6
      },
      {
        "label": "Bridge and road tolls",
        "value": "Bridge and road tolls",
        "id": 7
      },
      {
        "label": "Freight transport",
        "value": "Freight transport",
        "id": 8
      },
      {
        "label": "Truck and utility trailer rentals",
        "value": "Truck and utility trailer rentals",
        "id": 9
      }
    ],
    "9": [
      {
        "label": "Ecommerce marketplace",
        "value": "Ecommerce marketplace",
        "id": 1
      },
      {
        "label": "Agriculture",
        "value": "Agriculture",
        "id": 2
      },
      {
        "label": "Books",
        "value": "Books",
        "id": 3
      },
      {
        "label": "Electronics and furniture",
        "value": "Electronics and furniture",
        "id": 4
      },
      {
        "label": "Coupons",
        "value": "Coupons",
        "id": 5
      },
      {
        "label": "Rental",
        "value": "Rental",
        "id": 6
      },
      {
        "label": "Fashion and lifestyle",
        "value": "Fashion and lifestyle",
        "id": 7
      },
      {
        "label": "Gifting",
        "value": "Gifting",
        "id": 8
      },
      {
        "label": "Grocery",
        "value": "Grocery",
        "id": 9
      },
      {
        "label": "Baby products",
        "value": "Baby products",
        "id": 10
      },
      {
        "label": "Office supplies",
        "value": "Office supplies",
        "id": 11
      },
      {
        "label": "Wholesale",
        "value": "Wholesale",
        "id": 12
      },
      {
        "label": "Religious products",
        "value": "Religious products",
        "id": 13
      },
      {
        "label": "Pet products",
        "value": "Pet products",
        "id": 14
      },
      {
        "label": "Sports products",
        "value": "Sports products",
        "id": 15
      },
      {
        "label": "Arts and collectibles",
        "value": "Arts and collectibles",
        "id": 16
      },
      {
        "label": "Sexual wellness products",
        "value": "Sexual wellness products",
        "id": 17
      },
      {
        "label": "Drop shipping",
        "value": "Drop shipping",
        "id": 18
      },
      {
        "label": "Crypto machinery",
        "value": "Crypto machinery",
        "id": 19
      },
      {
        "label": "Tobacco",
        "value": "Tobacco",
        "id": 20
      },
      {
        "label": "Weapons and Ammunitions",
        "value": "Weapons and Ammunitions",
        "id": 21
      },
      {
        "label": "Stamps and Coins stores",
        "value": "Stamps and Coins stores",
        "id": 22
      },
      {
        "label": "Office equipment",
        "value": "Office equipment",
        "id": 23
      },
      {
        "label": "Automobile parts and equipements",
        "value": "Automobile parts and equipements",
        "id": 24
      },
      {
        "label": "Garden supply stores",
        "value": "Garden supply stores",
        "id": 25
      },
      {
        "label": "Household appliance stores",
        "value": "Household appliance stores",
        "id": 26
      },
      {
        "label": "Non Durable Goods",
        "value": "Non Durable Goods",
        "id": 27
      },
      {
        "label": "Pawn Shops",
        "value": "Pawn Shops",
        "id": 28
      },
      {
        "label": "Electrical parts and equipment",
        "value": "Electrical parts and equipment",
        "id": 29
      },
      {
        "label": "Wig and toupee shops",
        "value": "Wig and toupee shops",
        "id": 30
      },
      {
        "label": "Gift novelty and souvenir shops",
        "value": "Gift novelty and souvenir shops",
        "id": 31
      },
      {
        "label": "Duty free stores",
        "value": "Duty free stores",
        "id": 32
      },
      {
        "label": "Office and commercial furniture",
        "value": "Office and commercial furniture",
        "id": 33
      },
      {
        "label": "Dry goods",
        "value": "Dry goods",
        "id": 34
      },
      {
        "label": "Books and Publications",
        "value": "Books and Publications",
        "id": 35
      },
      {
        "label": "Camera and photographic stores",
        "value": "Camera and photographic stores",
        "id": 36
      },
      {
        "label": "Record shops",
        "value": "Record shops",
        "id": 37
      },
      {
        "label": "Meat supply stores",
        "value": "Meat supply stores",
        "id": 38
      },
      {
        "label": "Leather goods and luggage",
        "value": "Leather goods and luggage",
        "id": 39
      },
      {
        "label": "Snowmobile dealers",
        "value": "Snowmobile dealers",
        "id": 40
      },
      {
        "label": "Men and boys clothing stores",
        "value": "Men and boys clothing stores",
        "id": 41
      },
      {
        "label": "Paint supply stores",
        "value": "Paint supply stores",
        "id": 42
      },
      {
        "label": "Automotive parts",
        "value": "Automotive parts",
        "id": 43
      },
      {
        "label": "Jewellery and watch stores",
        "value": "Jewellery and watch stores",
        "id": 44
      },
      {
        "label": "Auto store home supply stores",
        "value": "Auto store home supply stores",
        "id": 45
      },
      {
        "label": "Tent stores",
        "value": "Tent stores",
        "id": 46
      },
      {
        "label": "Shoe stores retail",
        "value": "Shoe stores retail",
        "id": 47
      },
      {
        "label": "Petroleum and petroleum products",
        "value": "Petroleum and petroleum products",
        "id": 48
      },
      {
        "label": "Department stores",
        "value": "Department stores",
        "id": 49
      },
      {
        "label": "Automotive tire stores",
        "value": "Automotive tire stores",
        "id": 50
      },
      {
        "label": "Sport apparel stores",
        "value": "Sport apparel stores",
        "id": 51
      },
      {
        "label": "Variety stores",
        "value": "Variety stores",
        "id": 52
      },
      {
        "label": "Chemicals and allied products",
        "value": "Chemicals and allied products",
        "id": 53
      },
      {
        "label": "Commercial equipments",
        "value": "Commercial equipments",
        "id": 54
      },
      {
        "label": "Fireplace parts and accessories",
        "value": "Fireplace parts and accessories",
        "id": 55
      },
      {
        "label": "Family clothing stores",
        "value": "Family clothing stores",
        "id": 56
      },
      {
        "label": "Fabric and sewing stores",
        "value": "Fabric and sewing stores",
        "id": 57
      },
      {
        "label": "Home supply warehouse",
        "value": "Home supply warehouse",
        "id": 58
      },
      {
        "label": "Art supply stores",
        "value": "Art supply stores",
        "id": 59
      },
      {
        "label": "Camper recreational and utility trailer dealers",
        "value": "Camper recreational and utility trailer dealers",
        "id": 60
      },
      {
        "label": "Clocks and silverware stores",
        "value": "Clocks and silverware stores",
        "id": 61
      },
      {
        "label": "Discount stores",
        "value": "Discount stores",
        "id": 62
      },
      {
        "label": "School supplies and stationery",
        "value": "School supplies and stationery",
        "id": 63
      },
      {
        "label": "Second hand stores",
        "value": "Second hand stores",
        "id": 64
      },
      {
        "label": "Watch and jewellery repair stores",
        "value": "Watch and jewellery repair stores",
        "id": 65
      },
      {
        "label": "Liquor Stores",
        "value": "Liquor Stores",
        "id": 66
      },
      {
        "label": "Boat dealers",
        "value": "Boat dealers",
        "id": 67
      },
      {
        "label": "Opticians optical goods and eyeglasse stores",
        "value": "Opticians optical goods and eyeglasse stores",
        "id": 68
      },
      {
        "label": "Wholesale footwear stores",
        "value": "Wholesale footwear stores",
        "id": 69
      },
      {
        "label": "Cosmetic stores",
        "value": "Cosmetic stores",
        "id": 70
      },
      {
        "label": "Home furnishing stores",
        "value": "Home furnishing stores",
        "id": 71
      },
      {
        "label": "Antique stores",
        "value": "Antique stores",
        "id": 72
      },
      {
        "label": "Plumbing and heating equipment",
        "value": "Plumbing and heating equipment",
        "id": 73
      },
      {
        "label": "Telecommunication equipment stores",
        "value": "Telecommunication equipment stores",
        "id": 74
      },
      {
        "label": "Women clothing",
        "value": "Women clothing",
        "id": 75
      },
      {
        "label": "Florists",
        "value": "Florists",
        "id": 76
      },
      {
        "label": "Computer software stores",
        "value": "Computer software stores",
        "id": 77
      },
      {
        "label": "Building matrial stores",
        "value": "Building matrial stores",
        "id": 78
      },
      {
        "label": "Candy nut confectionery shops",
        "value": "Candy nut confectionery shops",
        "id": 79
      },
      {
        "label": "Glass and wallpaper stores",
        "value": "Glass and wallpaper stores",
        "id": 80
      },
      {
        "label": "Commercial photography and graphic design services",
        "value": "Commercial photography and graphic design services",
        "id": 81
      },
      {
        "label": "Video game supply stores",
        "value": "Video game supply stores",
        "id": 82
      },
      {
        "label": "Fuel dealers",
        "value": "Fuel dealers",
        "id": 83
      },
      {
        "label": "Draperyand window coverings stores",
        "value": "Draperyand window coverings stores",
        "id": 84
      },
      {
        "label": "Hearing aids stores",
        "value": "Hearing aids stores",
        "id": 85
      },
      {
        "label": "Automotive paint shops",
        "value": "Automotive paint shops",
        "id": 86
      },
      {
        "label": "Durable goods stores",
        "value": "Durable goods stores",
        "id": 87
      },
      {
        "label": "Uniforms and commercial clothing stores",
        "value": "Uniforms and commercial clothing stores",
        "id": 88
      },
      {
        "label": "Fur shops",
        "value": "Fur shops",
        "id": 89
      },
      {
        "label": "Industrial supplies",
        "value": "Industrial supplies",
        "id": 90
      },
      {
        "label": "Bicycle stores",
        "value": "Bicycle stores",
        "id": 91
      },
      {
        "label": "Second hand stores",
        "value": "Second hand stores",
        "id": 92
      },
      {
        "label": "Motorcycle shops and dealers",
        "value": "Motorcycle shops and dealers",
        "id": 93
      },
      {
        "label": "Children and infants wear stores",
        "value": "Children and infants wear stores",
        "id": 94
      },
      {
        "label": "Women Accessory stores",
        "value": "Women Accessory stores",
        "id": 95
      },
      {
        "label": "Construction materials",
        "value": "Construction materials",
        "id": 96
      },
      {
        "label": "Books periodicals and newspaper",
        "value": "Books periodicals and newspaper",
        "id": 97
      },
      {
        "label": "Floor covering stores",
        "value": "Floor covering stores",
        "id": 98
      },
      {
        "label": "Crystal and glassware stores",
        "value": "Crystal and glassware stores",
        "id": 99
      },
      {
        "label": "Accessory and apparel stores",
        "value": "Accessory and apparel stores",
        "id": 100
      },
      {
        "label": "Hardware equipment and supply stores",
        "value": "Hardware equipment and supply stores",
        "id": 101
      },
      {
        "label": "Computers peripheral equipment software",
        "value": "Computers peripheral equipment software",
        "id": 102
      },
      {
        "label": "Automobile and truck dealers",
        "value": "Automobile and truck dealers",
        "id": 103
      },
      {
        "label": "Aircraft and farm equipment dealers",
        "value": "Aircraft and farm equipment dealers",
        "id": 104
      },
      {
        "label": "Antique shops sales and repairs",
        "value": "Antique shops sales and repairs",
        "id": 105
      },
      {
        "label": "Hearing aids stores",
        "value": "Hearing aids stores",
        "id": 106
      },
      {
        "label": "Music stores",
        "value": "Music stores",
        "id": 107
      },
      {
        "label": "Furniture and home furnishing store",
        "value": "Furniture and home furnishing store",
        "id": 108
      }
    ],
    "10": [
      {
        "label": "Online food ordering",
        "value": "Online food ordering",
        "id": 1
      },
      {
        "label": "Restaurant",
        "value": "Restaurant",
        "id": 2
      },
      {
        "label": "Catering",
        "value": "Catering",
        "id": 3
      },
      {
        "label": "Alcohol",
        "value": "Alcohol",
        "id": 4
      },
      {
        "label": "Restaurant search and booking",
        "value": "Restaurant search and booking",
        "id": 5
      },
      {
        "label": "Dairy products",
        "value": "Dairy products",
        "id": 6
      },
      {
        "label": "Bakeries",
        "value": "Bakeries",
        "id": 7
      }
    ],
    "11": [
      {
        "label": "Saas",
        "value": "Saas",
        "id": 1
      },
      {
        "label": "Paas",
        "value": "Paas",
        "id": 2
      },
      {
        "label": "Iaas",
        "value": "Iaas",
        "id": 3
      },
      {
        "label": "Consulting and outsourcing",
        "value": "Consulting and outsourcing",
        "id": 4
      },
      {
        "label": "Web development",
        "value": "Web development",
        "id": 5
      },
      {
        "label": "Technical support",
        "value": "Technical support",
        "id": 6
      },
      {
        "label": "Data processing",
        "value": "Data processing",
        "id": 7
      }
    ],
    "12": [
      {
        "label": "Game developer",
        "value": "Game developer",
        "id": 1
      },
      {
        "label": "Esports",
        "value": "Esports",
        "id": 2
      },
      {
        "label": "Online casino",
        "value": "Online casino",
        "id": 3
      },
      {
        "label": "Fantasy sports",
        "value": "Fantasy sports",
        "id": 4
      },
      {
        "label": "Gaming marketplace",
        "value": "Gaming marketplace",
        "id": 5
      }
    ],
    "13": [
      {
        "label": "Video on demand",
        "value": "Video on demand",
        "id": 1
      },
      {
        "label": "Music streaming",
        "value": "Music streaming",
        "id": 2
      },
      {
        "label": "Multiplex",
        "value": "Multiplex",
        "id": 3
      },
      {
        "label": "Content and publishing",
        "value": "Content and publishing",
        "id": 4
      },
      {
        "label": "Ticketing",
        "value": "Ticketing",
        "id": 5
      },
      {
        "label": "News",
        "value": "News",
        "id": 6
      },
      {
        "label": "Video game arcades",
        "value": "Video game arcades",
        "id": 7
      },
      {
        "label": "Video type production and distribution",
        "value": "Video type production and distribution",
        "id": 8
      },
      {
        "label": "Bowling alleys",
        "value": "Bowling alleys",
        "id": 9
      },
      {
        "label": "Billiard and pool establishments",
        "value": "Billiard and pool establishments",
        "id": 10
      },
      {
        "label": "Amusement parks and circuses",
        "value": "Amusement parks and circuses",
        "id": 11
      },
      {
        "label": "Ticket agencies",
        "value": "Ticket agencies",
        "id": 12
      }
    ],
    "14": [
      {
        "label": "Repair and cleaning",
        "value": "Repair and cleaning",
        "id": 1
      },
      {
        "label": "Interior design and architect",
        "value": "Interior design and architect",
        "id": 2
      },
      {
        "label": "Movers and packers",
        "value": "Movers and packers",
        "id": 3
      },
      {
        "label": "Legal",
        "value": "Legal",
        "id": 4
      },
      {
        "label": "Event planning",
        "value": "Event planning",
        "id": 5
      },
      {
        "label": "Service centre",
        "value": "Service centre",
        "id": 6
      },
      {
        "label": "Consulting",
        "value": "Consulting",
        "id": 7
      },
      {
        "label": "Ad and marketing",
        "value": "Ad and marketing",
        "id": 8
      },
      {
        "label": "Services classifieds",
        "value": "Services classifieds",
        "id": 9
      },
      {
        "label": "Multi level marketing",
        "value": "Multi level marketing",
        "id": 10
      },
      {
        "label": "Construction services",
        "value": "Construction services",
        "id": 11
      },
      {
        "label": "Architectural services",
        "value": "Architectural services",
        "id": 12
      },
      {
        "label": "Car washes",
        "value": "Car washes",
        "id": 13
      },
      {
        "label": "Motor home rentals",
        "value": "Motor home rentals",
        "id": 14
      },
      {
        "label": "Stenographic and secretarial support services",
        "value": "Stenographic and secretarial support services",
        "id": 15
      },
      {
        "label": "Chiropractors",
        "value": "Chiropractors",
        "id": 16
      },
      {
        "label": "Automotive service shops",
        "value": "Automotive service shops",
        "id": 17
      },
      {
        "label": "Shoe repair shops",
        "value": "Shoe repair shops",
        "id": 18
      },
      {
        "label": "Telecommunication service",
        "value": "Telecommunication service",
        "id": 19
      },
      {
        "label": "Fines",
        "value": "Fines",
        "id": 20
      },
      {
        "label": "Security agencies",
        "value": "Security agencies",
        "id": 21
      },
      {
        "label": "Tailors",
        "value": "Tailors",
        "id": 22
      },
      {
        "label": "Type setting and engraving services",
        "value": "Type setting and engraving services",
        "id": 23
      },
      {
        "label": "Small appliance repair shops",
        "value": "Small appliance repair shops",
        "id": 24
      },
      {
        "label": "Photography labs",
        "value": "Photography labs",
        "id": 25
      },
      {
        "label": "Dry cleaners",
        "value": "Dry cleaners",
        "id": 26
      },
      {
        "label": "Massage parlors",
        "value": "Massage parlors",
        "id": 27
      },
      {
        "label": "Electronic repair shops",
        "value": "Electronic repair shops",
        "id": 28
      },
      {
        "label": "Cleaning and sanitation services",
        "value": "Cleaning and sanitation services",
        "id": 29
      },
      {
        "label": "Nursing care facilities",
        "value": "Nursing care facilities",
        "id": 30
      },
      {
        "label": "Direct marketing",
        "value": "Direct marketing",
        "id": 31
      },
      {
        "label": "Lottery",
        "value": "Lottery",
        "id": 32
      },
      {
        "label": "Veterinary services",
        "value": "Veterinary services",
        "id": 33
      },
      {
        "label": "Affliated auto rental",
        "value": "Affliated auto rental",
        "id": 34
      },
      {
        "label": "Alimony and child support",
        "value": "Alimony and child support",
        "id": 35
      },
      {
        "label": "Airport flying fields",
        "value": "Airport flying fields",
        "id": 36
      },
      {
        "label": "Golf courses",
        "value": "Golf courses",
        "id": 37
      },
      {
        "label": "Tire retreading and repair shops",
        "value": "Tire retreading and repair shops",
        "id": 38
      },
      {
        "label": "Television cable services",
        "value": "Television cable services",
        "id": 39
      },
      {
        "label": "Recreational and sporting camps",
        "value": "Recreational and sporting camps",
        "id": 40
      },
      {
        "label": "Barber and beauty shops",
        "value": "Barber and beauty shops",
        "id": 41
      },
      {
        "label": "Agricultural cooperatives",
        "value": "Agricultural cooperatives",
        "id": 42
      },
      {
        "label": "Carpentry contractors",
        "value": "Carpentry contractors",
        "id": 43
      },
      {
        "label": "Wrecking and salvaging services",
        "value": "Wrecking and salvaging services",
        "id": 44
      },
      {
        "label": "Automobile towing services",
        "value": "Automobile towing services",
        "id": 45
      },
      {
        "label": "Video tape rental stores",
        "value": "Video tape rental stores",
        "id": 46
      },
      {
        "label": "Miscellaneous repair shops",
        "value": "Miscellaneous repair shops",
        "id": 47
      },
      {
        "label": "Motor homes and parts",
        "value": "Motor homes and parts",
        "id": 48
      },
      {
        "label": "Horse or dog racing",
        "value": "Horse or dog racing",
        "id": 49
      },
      {
        "label": "Laundry services",
        "value": "Laundry services",
        "id": 50
      },
      {
        "label": "Electrical contractors",
        "value": "Electrical contractors",
        "id": 51
      },
      {
        "label": "Debt marriage personal counseling service",
        "value": "Debt marriage personal counseling service",
        "id": 52
      },
      {
        "label": "Air conditioning and refrigeration repair shops",
        "value": "Air conditioning and refrigeration repair shops",
        "id": 53
      },
      {
        "label": "Credit reporting agencies",
        "value": "Credit reporting agencies",
        "id": 54
      },
      {
        "label": "Heating and plumbing contractors",
        "value": "Heating and plumbing contractors",
        "id": 55
      },
      {
        "label": "Carpet and upholstery cleaning services",
        "value": "Carpet and upholstery cleaning services",
        "id": 56
      },
      {
        "label": "Swimming pools",
        "value": "Swimming pools",
        "id": 57
      },
      {
        "label": "Roofing and metal work contractors",
        "value": "Roofing and metal work contractors",
        "id": 58
      },
      {
        "label": "Internet service providers",
        "value": "Internet service providers",
        "id": 59
      },
      {
        "label": "Recreational camps",
        "value": "Recreational camps",
        "id": 60
      },
      {
        "label": "Masonry contractors",
        "value": "Masonry contractors",
        "id": 61
      },
      {
        "label": "Exterminating and disinfecting services",
        "value": "Exterminating and disinfecting services",
        "id": 62
      },
      {
        "label": "Ambulance services",
        "value": "Ambulance services",
        "id": 63
      },
      {
        "label": "Funeral services and crematories",
        "value": "Funeral services and crematories",
        "id": 64
      },
      {
        "label": "Metal service centres",
        "value": "Metal service centres",
        "id": 65
      },
      {
        "label": "Copying and blueprinting services",
        "value": "Copying and blueprinting services",
        "id": 66
      },
      {
        "label": "Fuel dispensers",
        "value": "Fuel dispensers",
        "id": 67
      },
      {
        "label": "Welding repair",
        "value": "Welding repair",
        "id": 68
      },
      {
        "label": "Mobile home dealers",
        "value": "Mobile home dealers",
        "id": 69
      },
      {
        "label": "Concrete work contractors",
        "value": "Concrete work contractors",
        "id": 70
      },
      {
        "label": "Boat rentals",
        "value": "Boat rentals",
        "id": 71
      },
      {
        "label": "Personal shoppers and shopping clubs",
        "value": "Personal shoppers and shopping clubs",
        "id": 72
      },
      {
        "label": "Door to door sales",
        "value": "Door to door sales",
        "id": 73
      },
      {
        "label": "Travel related direct marketing",
        "value": "Travel related direct marketing",
        "id": 74
      },
      {
        "label": "Lottery and betting",
        "value": "Lottery and betting",
        "id": 75
      },
      {
        "label": "Bands orchestras and miscellaneous entertainers",
        "value": "Bands orchestras and miscellaneous entertainers",
        "id": 76
      },
      {
        "label": "Furniture repair and refinishing",
        "value": "Furniture repair and refinishing",
        "id": 77
      },
      {
        "label": "Contractors",
        "value": "Contractors",
        "id": 78
      },
      {
        "label": "Direct marketing and subscription merchants",
        "value": "Direct marketing and subscription merchants",
        "id": 79
      },
      {
        "label": "Typewriter stores sales service and rentals",
        "value": "Typewriter stores sales service and rentals",
        "id": 80
      },
      {
        "label": "Recreation services",
        "value": "Recreation services",
        "id": 81
      },
      {
        "label": "Direct marketing insurance services",
        "value": "Direct marketing insurance services",
        "id": 82
      },
      {
        "label": "Business services",
        "value": "Business services",
        "id": 83
      },
      {
        "label": "Inbound telemarketing merchants",
        "value": "Inbound telemarketing merchants",
        "id": 84
      },
      {
        "label": "Public warehousing",
        "value": "Public warehousing",
        "id": 85
      },
      {
        "label": "Outbound telemarketing merchants",
        "value": "Outbound telemarketing merchants",
        "id": 86
      },
      {
        "label": "Clothing rental stores",
        "value": "Clothing rental stores",
        "id": 87
      },
      {
        "label": "Transportation services",
        "value": "Transportation services",
        "id": 88
      },
      {
        "label": "Electric razor stores",
        "value": "Electric razor stores",
        "id": 89
      },
      {
        "label": "Service stations",
        "value": "Service stations",
        "id": 90
      },
      {
        "label": "Photographic studio",
        "value": "Photographic studio",
        "id": 91
      },
      {
        "label": "Professional services",
        "value": "Professional services",
        "id": 92
      }
    ],
    "15": [
      {
        "label": "Developer",
        "value": "Developer",
        "id": 1
      },
      {
        "label": "Facility management",
        "value": "Facility management",
        "id": 2
      },
      {
        "label": "rwa",
        "value": "rwa",
        "id": 3
      },
      {
        "label": "Coworking",
        "value": "Coworking",
        "id": 4
      },
      {
        "label": "Realestate classifieds",
        "value": "Realestate classifieds",
        "id": 5
      },
      {
        "label": "Space rental",
        "value": "Space rental",
        "id": 6
      }
    ],
    "16": [
      {
        "label": "Charity",
        "value": "Charity",
        "id": 1
      },
      {
        "label": "Educational",
        "value": "Educational",
        "id": 2
      },
      {
        "label": "Religious",
        "value": "Religious",
        "id": 3
      },
      {
        "label": "Personal",
        "value": "Personal",
        "id": 4
      }
    ],
    "17": [
      {
        "label": "Matchmaking",
        "value": "Matchmaking",
        "id": 1
      },
      {
        "label": "Social network",
        "value": "Social network",
        "id": 2
      },
      {
        "label": "Messaging",
        "value": "Messaging",
        "id": 3
      },
      {
        "label": "Professional network",
        "value": "Professional network",
        "id": 4
      },
      {
        "label": "Neighbourhood network",
        "value": "Neighbourhood network",
        "id": 5
      },
      {
        "label": "Political organizations",
        "value": "Political organizations",
        "id": 6
      },
      {
        "label": "Automobile associations and clubs",
        "value": "Automobile associations and clubs",
        "id": 7
      },
      {
        "label": "Country amd athletic clubs",
        "value": "Country amd athletic clubs",
        "id": 8
      },
      {
        "label": "Associations and membership",
        "value": "Associations and membership",
        "id": 9
      }
    ]
  })
  const [currentBusinesSubCategory, setCurrentBusinesSubCategory] = useState([])
  //Dont change the order for id's ------>
  useEffect(() => {
    getConstitutionData()
    getBusinessData()
    getSubCategoryData()
    if (!isEditMode) {
      fetchBankValues();
    }
  }, [])

  const axiosPrivate = useAxiosPrivate();
  useImperativeHandle(ref, () => {
    return {
      next: next
    }
  });
  async function getConstitutionData() {
    try {
      const response = await axiosPrivate.get("/bank-admin/company/dropdown/constituion")
      const ddRegionVal = response.data.companyConstitutions.map((e: any, i: number) => {
        const dropdownItem: DropdownItem = {
          label: e.constitution,
          value: e.constitution,
        };
        return dropdownItem;
      })
      const sortedData = [...ddRegionVal].sort((a, b) => a.label.localeCompare(b.label));
      setConstitutionData(sortedData)
    }
    catch (error) {
    }
  }
  async function getBusinessData() {
    try {
      const response = await axiosPrivate.get("/bank-admin/company/dropdown/buisnessCategory")
      const ddRegionVal = response.data.buisnessCategory.map((e: any, i: number) => {
        const dropdownItem: DropdownItem = {
          label: e.buisnessCategory,
          value: e.buisnessCategory,
        };
        return dropdownItem;
      })
      const sortedData = [...ddRegionVal].sort((a, b) => a.label.localeCompare(b.label));
      setBusinessData(sortedData)
    }
    catch (error) {
    }
  }
  async function getSubCategoryData() {
    try {
      const response = await axiosPrivate.get("/bank-admin/company/dropdown/sub-category")
      const ddRegionVal = response.data.subCategory.map((e: any, i: number) => {
        const dropdownItem: DropdownItem = {
          label: e.subCategory,
          value: e.subCategory,
        };
        return dropdownItem;
      })
      const sortedData = [...ddRegionVal].sort((a, b) => a.label.localeCompare(b.label));
      setSubCategoryData(sortedData)
    }
    catch (error) {
    }
  }
  // function for bank dropdown
  const fetchBankValues = async () => {
    try {
      const response = await axiosPrivate.get("/product-admin/get-organizations/bank");
      if (response.status === 200) {
        const dropdownValues = response.data.decryptedOrganisation.map((item: any) => ({
          label: item.organisationName,
          value: item.organisationName,
          id: item.organisation,
        }));
        const sortedData = [...dropdownValues].sort((a, b) => a.label.localeCompare(b.label));
        if (sortedData.length === 1) {
          setMainApiData({
            ...mainApiData,
            OrganisationName: sortedData[0].value,
            OrganisationId: sortedData[0].id,
          });
          setBankDropdownDisable(true)
          setOrganisationNameData(sortedData);
        } else {
          setOrganisationNameData(sortedData);
        }
      }
    } catch (error) {
    }
  };
  const userValidationRules: any = {
    companyData: {
      CompanyName: {
        regex: "",
        field: "mandatory",
        shouldNotBe: ""
      },
      CompanyClass: {
        regex: "",
        field: "mandatory",
        shouldNotBe: ""
      },

      CompanyCategory: {
        regex: "",
        field: "mandatory",
        shouldNotBe: ""
      },
      CompanySubCategory: {
        regex: "",
        field: "mandatory",
        shouldNotBe: ""
      },
      CompanyRegNumber: {
        regex: /^[0-9]{2,}$/,
        field: "mandatory",
        shouldNotBe: ""
      },
      pan: {
        regex: /[A-Z]{5}\d{4}[A-Z]{1}/,
        field: "mandatory",
        shouldNotBe: ""
      },
      CompanyCin: {
        regex: (mainApiData && mainApiData?.companyData?.CompanyCin) ? /\b[A-Z]\d{5}[A-Z]{2}\d{4}[A-Z]{3}\d{6}\b/ : "",
        field: (mainApiData && !mainApiData?.companyData?.CompanyCin && !mainApiData?.companyData?.CompanyLLPIN) ? "mandatory" : "",
        shouldNotBe: ""
      },
      CompanyLLPIN: {
        regex: (mainApiData && mainApiData?.companyData?.CompanyLLPIN) ? /\b[A-Z]{3}-\d{4}\b/ : "",
        field: (mainApiData && !mainApiData?.companyData?.CompanyCin && !mainApiData?.companyData?.CompanyLLPIN) ? "mandatory" : "",
        shouldNotBe: ""
      },
      CompanyMcaIndustry: {
        regex: "",
        field: "",
        shouldNotBe: ""
      },
      CompanyMcaIndustryDivision: {
        regex: "",
        field: "",
        shouldNotBe: ""
      },
      CompanyMcaIndustryGroup: {
        regex: "",
        field: "",
        shouldNotBe: ""
      },
      CompanyDateOfInc: {
        regex: "",
        field: "mandatory",
        shouldNotBe: ""
      },
      CompanyTan: {
        regex: /^[A-Z]{4}[0-9]{5}[A-Z]{1}$/,
        field: "mandatory",
        shouldNotBe: ""
      },
      Description: {
        regex: '',
        field: "mandatory",
        shouldNotBe: ""
      },
      // OrganisationName: {
      //   regex: "",
      //   field: "mandatory",
      //   shouldNotBe: ""
      // },
    }
  }

  const updatedIsValueValidAll: Record<string, boolean> = {};
  const validateForm = (): boolean => {
    const updatedFieldValidity: Record<string, boolean> = {};
    const isMandatoryfalse = true;
    const isRegexfalse = true;
    Object.keys(userValidationRules.companyData).forEach((field) => {
      const rule = userValidationRules.companyData[field];
      const value = mainApiData?.companyData[field];
      console.log(value, rule, field)
      const isValueValid = (rule.field === "mandatory") ? (value ? (value !== rule.shouldNotBe) : false) : true;
      const isRegexValid = rule.regex !== "" ? new RegExp(rule.regex).test(value) : true;
      updatedFieldValidity[field] = !isValueValid || !isRegexValid;
      updatedIsValueValidAll[field] = !isValueValid;
      console.log(value, isValueValid, isRegexValid)
    });
    setCompanyOverviewErrors(updatedFieldValidity);
    const isFormValid = Object.keys(updatedFieldValidity).every((keys) => {
      return !updatedFieldValidity[keys]
    });
    return isFormValid;
  };

  function handelFectch() {

    let regexPattern;
    if (chooseType === "CIN") {
      regexPattern = /\b[A-Z]\d{5}[A-Z]{2}\d{4}[A-Z]{3}\d{6}\b/;
    } else if (chooseType === "PAN") {
      regexPattern = /[A-Z]{5}\d{4}[A-Z]{1}/;
    } else if (chooseType === "LLPIN") {
      regexPattern = /\b[A-Z]{3}-\d{4}\b/;
    } else {
      return;
    }
    const isMatch = regexPattern.test(companyOverviewPan);
    if (isMatch) {
      fetchDatas(companyOverviewPan, chooseType);
      setErrorChoosetype(false)
      setCompanyDataType(true)
    } else {
      setShowAlertBox(true);
      setShowType("danger");
      (companyOverviewPan == "" || companyOverviewPan == undefined) ?
        setShowMessage(chooseType + " should not be empty.") :
        setShowMessage(chooseType + " is not in required format.");
      clearAlert('');
      setErrorChoosetype(true)
    }

  }

  async function duplicateCheck() {
    const finalData = {
      "CompanyValue": companyOverviewPan,
      "CompanyLLPIN": mainApiData?.companyData?.CompanyLLPIN,
      "CompanyCin": mainApiData?.companyData?.CompanyCin,
      "CompanyRegNumber": mainApiData?.companyData?.CompanyRegNumber,
      "pan": mainApiData?.companyData?.pan,
      "CompanyName": mainApiData?.companyData?.CompanyName,
      "CompanyTan": mainApiData?.companyData?.CompanyTan,
    }
    let statusMessage: boolean = false;
    try {
      const res = await axiosPrivate.post(`bank-admin/company/duplicate-checking/${editData?.CompanyData?.companyData?.id}`, { "companyOverView": finalData })
      if (res.status === 200) {
        statusMessage = true;
      }
    }
    catch (error: any) {
      setShowAlertBox(true)
      setShowType("danger")
      setShowMessage(error?.response?.data?.error)
      clearAlert("")
      statusMessage = false;

    }
    return statusMessage;
  }

  const clearAlert = (status: any) => {
    const timer = setTimeout(() => {
      setShowAlertBox(false);
      setShowMessage("");
      if (status === 400) {

      }
      clearTimeout(timer);
    }, 5000);
  }

  const isDateValid = () => {
    const fromDate = new Date(mainApiData?.companyData.CompanyDateOfInc);
    let isOk = false
    if (mainApiData?.companyData?.CompanyDateOfInc) {
      if (fromDate < new Date() && fromDate > new Date("1850-01-01")) {
        isOk = true
        setCompanyOverviewErrors({ CompanyDateOfInc: false })
      }
      else {
        isOk = false
        setCompanyOverviewErrors({ CompanyDateOfInc: true })
      }
    } else {
      isOk = true
      setCompanyOverviewErrors({ CompanyDateOfInc: false })
    }
    return isOk;
  }

  async function next(next: any = "") {
    const isFormValid = validateForm();
    if (mainApiData.OrganisationName && isFormValid) {
      let isvalidDOB = isDateValid()
      if (!isvalidDOB) {
        setShowAlertBox(true);
        setShowType("danger");
        setShowMessage("Some of the field(s) are not in required format.")
        clearAlert('');
        return;
      }
      const Duplicate = await duplicateCheck();
      if (Duplicate) {
        if (isEditMode) {
          sendDataApi();
          setIconStatus({
            ...iconStatus,
            companyIcon: "completed",
          })
          dispatch(resetFormModified(false));
        } else {
          setShowPage(next != "" ? next : "Address")
          if (next != "") {
            switch (next) {
              case "CompanyOverview":
                setIconStatus({
                  ...iconStatus,
                  companyIcon: "selected",
                })
                break;
              case "Address":
                setIconStatus({
                  ...iconStatus,
                  companyIcon: "completed",
                  addressIcon: "selected",
                })
                break;
              case "DirectorsInfo":
                setIconStatus({
                  ...iconStatus,
                  directorIcon: "selected",
                  addressIcon: "completed"
                })
                break;
              case "StatutoryDetails":
                setIconStatus({
                  ...iconStatus,
                  statutoryIcon: "selected",
                  directorIcon: "completed"
                })
                break;
              case "AdminDetails":
                setIconStatus({
                  ...iconStatus,
                  adminIcon: "selected",
                  statutoryIcon: "completed"
                })
                break;

              default:
                break;
            }
          } else {
            setIconStatus({
              ...iconStatus,
              companyIcon: "completed",
              addressIcon: "selected"
            })
          }
        }
      } else {
      }

      setIsFormDirty(false);
    } else {
      setShowAlertBox(true);
      setShowType("danger");
      setShowMessage((Object.values(updatedIsValueValidAll).every((values) => !values)) ? "Some of the field(s) are not in required format." : (!mainApiData.OrganisationName) ? "Mandatory field(s) should not be left blank." : "Mandatory field(s) should not be left blank.")
      if (!mainApiData.OrganisationName) {
        setCompanyOverviewErrors((prevState) => ({
          ...prevState,
          OrganisationName: true
        }));

      }
      clearAlert('');
    }
  }
  function returnGird() {
    onboardingViewfun("grid")
    dispatch(resetFormModified(false));
  }
  let type: any = [{ label: "PAN", value: "PAN" }, { label: "CIN", value: "CIN" }, { label: "LLPIN", value: "LLPIN" }]
  return (
    <>
      <div className='form-wrapper'>
        <div className='form-area'>
          <div className='form-main-end'>
            <div style={isEditMode || bankDropdownDisable ? { pointerEvents: "none", opacity: 0.5, marginRight: "45px", marginBottom: "30px" } : { marginRight: "45px", marginBottom: "30px" }} key={uuidv4()} className={companyOverviewErrors.OrganisationName ? 'setErrorcompany' : "dropdown-width"}>
              <DropdownComponent
                width={"300px"}
                title={"Organisation"}
                required={true}
                isDisabled={bankDropdownDisable}
                defaultValue={[
                  {
                    label:
                      mainApiData?.OrganisationName === undefined
                        ? "Select"
                        : mainApiData?.OrganisationName,
                    value:
                      mainApiData?.OrganisationName === undefined
                        ? "Select"
                        : mainApiData?.OrganisationName,
                  },
                ]
                }
                options={organisationNameData}
                getData={(value: any) => {
                  setIsFormDirty(true)
                  dispatch(resetFormModified(true));
                  setMainApiData({
                    ...mainApiData,
                    OrganisationName: value.value,
                    OrganisationId: value.id,
                  })
                  setIconStatus({
                    ...iconStatus,
                    companyIcon: "selected",
                  })
                }}
              />
            </div>
            <div className='dropdown-width' key={uuidv4()} style={isEditMode ? { pointerEvents: "none", opacity: 0.5, marginRight: "45px", marginBottom: "30px" } : { marginRight: "45px", marginBottom: "30px" }}>
              <DropdownComponent
                width={"300px"}
                required={true}
                title={"Choose PAN/LLPIN/CIN"}
                defaultValue={[{ label: mainApiData && (mainApiData?.ChooseType === (undefined)) ? "PAN" : mainApiData?.ChooseType, value: mainApiData && (mainApiData?.ChooseType === (undefined)) ? "PAN" : mainApiData?.ChooseType }]}
                options={type}
                isDisabled={isEditMode}
                getData={(value: any) => {
                  setIsFormDirty(true)
                  dispatch(resetFormModified(true));
                  setMainApiData({
                    ...mainApiData,
                    ChooseType: value.value,
                    CompanyValue: "",
                  })
                  setCompanyOverviewPan("")
                  setChooseType(value.value)
                  setIconStatus({
                    ...iconStatus,
                    companyIcon: "selected",
                  })
                }}

              />
            </div>
            <div className='input-fetch-flex'>
              <div style={isEditMode ? { pointerEvents: "none", opacity: 0.5 } : {}}>
                <InputComponent
                  height={"40px"}
                  width={"250px"}
                  padding={"0px 0px 0px 10px"}
                  margin={"0px 0px 35px 0px"}
                  border={errorChoosetype ? "1px solid red" : "1px solid #A9C3DC"}
                  borderRadius={"0px"}
                  backgroundColor={"white"}
                  disabled={isEditMode}
                  color={"black"}
                  type={"text"}
                  maxLength={
                    chooseType === "CIN" ? 21 :
                      chooseType === "PAN" ? 10 :
                        chooseType === "LLPIN" ? 8 : undefined
                  }
                  required={true}
                  placeHolder={`Enter Company ${((mainApiData?.ChooseType !== (undefined)) && (mainApiData?.ChooseType !== "")) ? mainApiData?.ChooseType : "PAN"}`}
                  inputTitle={`Company ${((mainApiData?.ChooseType !== (undefined)) && (mainApiData?.ChooseType !== "")) ? mainApiData?.ChooseType : "PAN"}`}
                  inputValue={mainApiData?.CompanyValue}
                  getUser={(value) => {
                    const userRes = value.toUpperCase()
                    setIsFormDirty(true)
                    dispatch(resetFormModified(true));
                    setMainApiData({
                      ...mainApiData,
                      CompanyValue: userRes
                    })
                    setCompanyOverviewPan(userRes)
                    setIconStatus({
                      ...iconStatus,
                      companyIcon: "selected",
                    })
                  }}
                />
              </div>
              <div style={isEditMode ? { pointerEvents: "none", opacity: 0.5 } : {}}>
                <IconButton
                  iconName={"Fetch"}
                  height={"40px"}
                  width={"100px"}
                  fontSize={""}
                  color={""}
                  border={""}
                  borderRadius={"0px"}
                  disabled={isEditMode}
                  backgroundColor={"#0055D4"}
                  hover={mainApiData?.ChooseType !== (undefined || null) ? true : false}
                  margin={"0px 0px 0px 0px"}
                  handleClick={() => {
                    if (isEditMode) return;
                    handelFectch()
                  }}
                />
              </div>
            </div>
          </div>
          <div className='company-heading'>
            Company Overview
          </div>
          <div className='form-main-wrapper'>
            <div>
              <InputComponent
                height={"40px"}
                width={"300px"}
                margin={"0px 45px 30px 0px"}
                padding={"0px 0px 0px 10px"}
                border={companyOverviewErrors.CompanyName ?
                  "1px solid red" : "1px solid #A9C3DC"
                }
                borderRadius={"0px"}
                backgroundColor={"white"}
                color={"black"}
                type={"text"}
                placeHolder={"Enter Company Name"}
                inputTitle={"Company Name"}
                required={true}
                maxLength={100}
                inputValue={mainApiData && mainApiData?.companyData?.CompanyName}
                autoFocus
                getUser={(value: any) => {
                  setIsFormDirty(true)
                  dispatch(resetFormModified(true));
                  const val = value.replace(/^[^A-Za-z0-9]+|[^\w\s\W]/g, "");
                  setMainApiData({
                    ...mainApiData,
                    companyData: {
                      ...mainApiData?.companyData,
                      CompanyName: val,
                    }
                  })
                  setIconStatus({
                    ...iconStatus,
                    companyIcon: "selected",
                  })

                }}

              />
            </div>
            <div style={{ marginRight: "45px", marginBottom: "30px" }} key={uuidv4()} className={companyOverviewErrors.CompanyClass ? 'setErrorcompany' : "dropdown-width"}>
              <DropdownComponent
                width={"300px"}
                required={true}
                title={"Company Constitution"}
                defaultValue={[{ label: mainApiData?.companyData?.CompanyClass === undefined ? "Select" : mainApiData?.companyData?.CompanyClass, value: mainApiData?.companyData?.CompanyClass === undefined ? "Select" : mainApiData?.companyData?.CompanyClass }]}
                options={businessType}
                getData={(value: any) => {
                  setIsFormDirty(true)
                  dispatch(resetFormModified(true));
                  setMainApiData({
                    ...mainApiData,
                    companyData: {
                      ...mainApiData?.companyData,
                      CompanyClass: value.value,
                    }
                  })
                  setIconStatus({
                    ...iconStatus,
                    companyIcon: "selected",
                  })
                }}
              />
            </div>
            <div style={{ marginRight: "45px", marginBottom: "30px" }} key={uuidv4()} className={companyOverviewErrors.CompanyCategory ? 'setErrorcompany' : "dropdown-width"}>
              <DropdownComponent
                width={"300px"}
                title={"Business Category"}
                required={true}
                defaultValue={[{ label: mainApiData?.companyData?.CompanyCategory === undefined ? "Select" : mainApiData?.companyData?.CompanyCategory, value: mainApiData?.companyData?.CompanyCategory === undefined ? "Select" : mainApiData?.companyData?.CompanyCategory }]}
                options={businessCategory}
                getData={(value: any) => {
                  setIsFormDirty(true)
                  dispatch(resetFormModified(true));
                  setMainApiData({
                    ...mainApiData,
                    companyData: {
                      ...mainApiData?.companyData,
                      CompanyCategory: value.value,
                    }
                  })
                  setIconStatus({
                    ...iconStatus,
                    companyIcon: "selected",
                  })
                  setCurrentBusinesSubCategory(businessSubCategoryAllList[value.id])
                }}
              />
            </div>
          </div>
          <div className='form-main-wrapper'>
            <div style={{ marginRight: "45px", marginBottom: "30px" }} key={uuidv4()} className={companyOverviewErrors.CompanySubCategory ? 'setErrorcompany' : "dropdown-width"}>
              <DropdownComponent
                width={"300px"}
                title={"Company Sub Category"}
                required={true}
                defaultValue={[{ label: mainApiData?.companyData?.CompanySubCategory === undefined ? "Select" : mainApiData?.companyData?.CompanySubCategory, value: mainApiData?.companyData?.CompanySubCategory === undefined ? "Select" : mainApiData?.companyData?.CompanySubCategory }]}
                options={currentBusinesSubCategory}
                getData={(value: any) => {
                  setIsFormDirty(true)
                  dispatch(resetFormModified(true));
                  setMainApiData({
                    ...mainApiData,
                    companyData: {
                      ...mainApiData?.companyData,
                      CompanySubCategory: value.value,
                    }
                  })
                  setIconStatus({
                    ...iconStatus,
                    companyIcon: "selected",
                  })
                }}
              />
            </div>
            <div>
              <InputComponent
                height={"40px"}
                width={"300px"}
                padding={"0px 0px 0px 10px"}
                margin={"0px 45px 30px 0px"}
                border={companyOverviewErrors.CompanyRegNumber ?
                  "1px solid red" : "1px solid #A9C3DC"
                }
                borderRadius={"0px"}
                backgroundColor={"white"}
                color={"black"}
                type={"text"}
                inputTitle={"Company Registration Number"}
                placeHolder={"Enter Registration Number"}
                required={true}
                maxLength={6}
                inputValue={mainApiData && mainApiData?.companyData?.CompanyRegNumber}
                getUser={(value: any) => {
                  setIsFormDirty(true)
                  dispatch(resetFormModified(true));
                  const val = value.replace(/[^0-9]/g, "")
                  setMainApiData({
                    ...mainApiData,
                    companyData: {
                      ...mainApiData?.companyData,
                      CompanyRegNumber: val,
                    }
                  });
                  setIconStatus({
                    ...iconStatus,
                    companyIcon: "selected",
                  })
                }}
              />
            </div>
            <div>
              <InputComponent
                height={"40px"}
                width={"300px"}
                margin={"0px 0px 30px 0px"}
                padding={"0px 0px 0px 10px"}
                border={companyOverviewErrors.pan ?
                  "1px solid red" : "1px solid #A9C3DC"
                }
                borderRadius={"0px"}
                backgroundColor={"white"}
                color={"black"}
                type={"text"}
                inputTitle={"Company PAN No"}
                placeHolder={"Enter PAN No"}
                maxLength={10}
                required={true}
                inputValue={mainApiData && !mainApiData?.companyData?.pan ? "" : mainApiData?.companyData?.pan}
                getUser={(val: any) => {
                  setIsFormDirty(true)
                  dispatch(resetFormModified(true));
                  let value = val.toUpperCase()
                  setMainApiData({
                    ...mainApiData,
                    companyData: {
                      ...mainApiData?.companyData,
                      pan: value,
                    }
                  })
                  setIconStatus({
                    ...iconStatus,
                    companyIcon: "selected",
                  })
                }}
              />
            </div>
          </div>
          <div className='form-main-wrapper'>
            <div>
              <InputComponent
                height={"40px"}
                width={"300px"}
                margin={"0px 45px 30px 0px"}
                padding={"0px 0px 0px 10px"}
                border={companyOverviewErrors && companyOverviewErrors.CompanyTan ? "1px solid red" : "1px solid #A9C3DC"}
                borderRadius={"0px"}
                backgroundColor={"white"}
                color={"black"}
                type={"text"}
                required={true}
                maxLength={10}
                inputValue={mainApiData && mainApiData?.companyData?.CompanyTan}
                placeHolder={"Enter Company TAN"}
                inputTitle={"Company TAN"}
                getUser={(val) => {
                  let value = val.toUpperCase()
                  setMainApiData({
                    ...mainApiData,
                    companyData: {
                      ...mainApiData?.companyData,
                      CompanyTan: value,
                    }
                  })
                  setIconStatus({
                    ...iconStatus,
                    companyIcon: "selected",
                  })
                }}
              />
            </div>
            <div>
              <InputComponent
                height={"40px"}
                width={"300px"}
                margin={"0px 45px 30px 0px"}
                padding={"0px 0px 0px 10px"}
                border={companyOverviewErrors.CompanyCin ?
                  "1px solid red" : "1px solid #A9C3DC"
                }
                borderRadius={"0px"}
                backgroundColor={"white"}
                color={"black"}
                type={"text"}
                inputTitle={"Company CIN No"}
                placeHolder={"Enter CIN No"}
                maxLength={21}
                inputValue={mainApiData && mainApiData?.companyData?.CompanyCin}
                getUser={(val: any) => {
                  setIsFormDirty(true)
                  dispatch(resetFormModified(true));
                  let value = val.toUpperCase()
                  setMainApiData({
                    ...mainApiData,
                    companyData: {
                      ...mainApiData?.companyData,
                      CompanyCin: value,
                    }
                  })
                  setIconStatus({
                    ...iconStatus,
                    companyIcon: "selected",
                  })
                }}
              />
            </div>
            <div>
              <InputComponent
                height={"40px"}
                width={"300px"}
                margin={"0px 45px 30px 0px"}
                padding={"0px 0px 0px 10px"}
                border={companyOverviewErrors.CompanyLLPIN ?
                  "1px solid red" : "1px solid #A9C3DC"
                }
                borderRadius={"0px"}
                backgroundColor={"white"}
                color={"black"}
                type={"text"}
                maxLength={8}
                placeHolder={"Enter LLPIN"}
                inputTitle={"Company LLPIN"}
                inputValue={mainApiData && mainApiData?.companyData?.CompanyLLPIN}
                getUser={(val: any) => {
                  setIsFormDirty(true)
                  dispatch(resetFormModified(true));
                  let value = val.toUpperCase();
                  setMainApiData({
                    ...mainApiData,
                    companyData: {
                      ...mainApiData?.companyData,
                      CompanyLLPIN: value,
                    }
                  })
                  setIconStatus({
                    ...iconStatus,
                    companyIcon: "selected",
                  })
                }}
              />
            </div>

          </div>
          <div className='form-main-end'>
            <div>
              <InputComponent
                height={"40px"}
                width={"300px"}
                padding={"0px 0px 0px 10px"}
                margin={"0px 45px 30px 0px"}
                border={companyOverviewErrors.CompanyMcaIndustry ?
                  "1px solid red" : "1px solid #A9C3DC"
                }
                borderRadius={"0px"}
                backgroundColor={"white"}
                color={"black"}
                type={"text"}
                maxLength={100}
                inputTitle={"Company MCA Industry"}
                placeHolder={"Enter MCA Industry"}
                inputValue={mainApiData && mainApiData?.companyData?.CompanyMcaIndustry}
                getUser={(value: any) => {
                  const val = value.replace(/^[^A-Za-z0-9]+|[^\w\s\W]/g, "");
                  setIsFormDirty(true)
                  dispatch(resetFormModified(true));
                  setMainApiData({
                    ...mainApiData,
                    companyData: {
                      ...mainApiData?.companyData,
                      CompanyMcaIndustry: val,
                    }
                  })
                  setIconStatus({
                    ...iconStatus,
                    companyIcon: "selected",
                  })
                }}
              />
            </div>
            <div>
              <InputComponent
                height={"40px"}
                width={"300px"}
                padding={"0px 0px 0px 10px"}
                margin={"0px 45px 30px 0px"}
                border={companyOverviewErrors.CompanyMcaIndustryDivision ?
                  "1px solid red" : "1px solid #A9C3DC"
                }
                borderRadius={"0px"}
                backgroundColor={"white"}
                color={"black"}
                type={"text"}
                maxLength={100}
                inputTitle={"Company MCA Industry Division"}
                placeHolder={"Enter MCA Industry Division"}
                inputValue={mainApiData && mainApiData?.companyData?.CompanyMcaIndustryDivision}
                getUser={(value: any) => {
                  const val = value.replace(/^[^A-Za-z0-9]+|[^\w\s\W]/g, "");
                  setIsFormDirty(true)
                  dispatch(resetFormModified(true));
                  setMainApiData({
                    ...mainApiData,
                    companyData: {
                      ...mainApiData?.companyData,
                      CompanyMcaIndustryDivision: val,
                    }
                  })
                  setIconStatus({
                    ...iconStatus,
                    companyIcon: "selected",
                  })
                }}
              />
            </div>
            <div>
              <InputComponent
                height={"40px"}
                width={"300px"}
                margin={"0px 45px 30px 0px"}
                padding={"0px 0px 0px 10px"}
                maxLength={100}
                border={companyOverviewErrors.CompanyMcaIndustryGroup ?
                  "1px solid red" : "1px solid #A9C3DC"
                }
                borderRadius={"0px"}
                backgroundColor={"white"}
                color={"black"}
                type={"text"}
                inputValue={mainApiData && mainApiData?.companyData?.CompanyMcaIndustryGroup}
                inputTitle={"Company MCA Industry Group"}
                placeHolder={"Enter MCA Industry Group"}
                getUser={(value: any) => {
                  const val = value.replace(/^[^A-Za-z0-9]+|[^\w\s\W]/g, "");
                  setIsFormDirty(true)
                  dispatch(resetFormModified(true));
                  setMainApiData({
                    ...mainApiData,
                    companyData: {
                      ...mainApiData?.companyData,
                      CompanyMcaIndustryGroup: val,
                    }
                  })
                  setIconStatus({
                    ...iconStatus,
                    companyIcon: "selected",
                  })
                }}
              />
            </div>
            <div style={{ marginRight: "45px" }}>
              <div className='title'>Company Date Of Inc<span className='required'>*</span></div>
              <input
                type="date"
                id="date-picker"
                className="date-picker"
                style={{
                  border: companyOverviewErrors.CompanyDateOfInc ? "1px solid red" : "",
                }}

                value={mainApiData?.companyData?.CompanyDateOfInc &&
                  moment(mainApiData?.companyData.CompanyDateOfInc).format("YYYY-MM-DD")}
                max={todayDate}
                onChange={(e: any) => {
                  if (e.target.value.length > 8) {
                    const year = e.target.value.substring(0, 4);
                    if (parseInt(year, 10) > currentyear) {
                      return;
                    }
                    if (year > 999) {
                      if (parseInt(year, 10) < 1900) {
                        return;
                      }
                    }
                  }
                  setMainApiData({
                    ...mainApiData,
                    companyData: {
                      ...mainApiData?.companyData,
                      CompanyDateOfInc: e.target.value,
                    }
                  });
                  setIconStatus({
                    ...iconStatus,
                    companyIcon: "selected",
                  });
                  setIsFormDirty(true)
                  dispatch(resetFormModified(true));
                }}
              />

            </div>
            <div>
              <InputComponent
                height={"40px"}
                width={"300px"}
                margin={"0px 45px 30px 0px"}
                padding={"0px 0px 0px 10px"}
                maxLength={100}
                border={companyOverviewErrors.Description ?
                  "1px solid red" : "1px solid #A9C3DC"
                }
                borderRadius={"0px"}
                backgroundColor={"white"}
                color={"black"}
                type={"text"}
                inputValue={mainApiData && mainApiData?.companyData?.Description}
                inputTitle={"Description"}
                placeHolder={"Enter Description"}
                required={true}
                getUser={(value: any) => {
                  const val = value.replace(/^[^A-Za-z0-9]+|[^\w\s\W]/g, "");
                  setIsFormDirty(true)
                  dispatch(resetFormModified(true));
                  setMainApiData({
                    ...mainApiData,
                    companyData: {
                      ...mainApiData?.companyData,
                      Description: val,
                    }
                  })
                  setIconStatus({
                    ...iconStatus,
                    companyIcon: "selected",
                  })
                }}
              />
            </div>
            {/* <div>
              <DatePicker
                // selected={selectedDate}
                selected={mainApiData?.companyData?.CompanyDateOfInc && new Date(mainApiData.companyData.CompanyDateOfInc)}
                onChange={(date) => {
                  setIsFormDirty(true);
                  dispatch(resetFormModified(true));
                  setMainApiData({
                    ...mainApiData,
                    companyData: {
                      ...mainApiData?.companyData,
                      CompanyDateOfInc: date
                    }
                  });
                  setIconStatus({
                    ...iconStatus,
                    companyIcon: "selected"
                  });
                }}
                placeholderText="DD-MM-YYYY"
                dateFormat="dd-mm-yyyy"
                className="date-picker"
              />
            </div> */}
          </div>
          <div>
            <div className='footer-company'>
              <ButtonComponent
                title={"Cancel"}
                height={"50px"}
                width={"150px"}
                margin={"0px 8px"}
                backgroundColor={"#888888"}
                color={"white"}
                className={"button-component-hover cancel"}
                handleClick={isFormModified ? () => { setShowChangesModal(true) } : returnGird}
              />


              <ButtonComponent
                title={isEditMode ? "Update" : "Next"}
                height={"50px"}
                width={"150px"}
                backgroundColor={"#0055D4"}
                color={"white"}
                margin={"0px 8px"}
                className={"button-component-hover common-btn"}
                handleClick={next}
              />
            </div>
          </div>
        </div>
      </div >
      {
        showAlertBox &&
        <div className='alert-warp'>
          <AlertBox type={showType} message={showMessage} />
        </div>

      }
      {
        showChangesModal &&
        <Changesmodal
          closeModal={() => setShowChangesModal(false)}
          handleClose={() => {
            setShowChangesModal(false);
          }}
          leavePage={() => returnGird()} />
      }
    </>
  )
})

export default CompanyOverview;
