import React, { useEffect, useState } from 'react';
import { RxCross1 } from 'react-icons/rx';
import { IoIosAddCircleOutline } from 'react-icons/io';
import { AiOutlineMinusCircle } from 'react-icons/ai';
import "../../../styles/modals/document-modal.scss"
import { axiosPrivate } from '../../../middleware/axois-api';
import Loading from './loading-screen';
import AlertBox from '../alert-box';

interface props {
    closeDocument: (val: any) => void;
    document: any;
    showDocumentModal?: any
    modalType?: any
}

export default function DocumentModal({ closeDocument, document, showDocumentModal,modalType }: props) {
    const [pdfUrl, setPdfUrl] = useState<string | null>("");
    const [imgUrl, setImgUrl] = useState<string | null>("");
    const [zoomLevel, setZoomLevel] = useState(600);
    const [loadingScreen, setLoadingScreen] = useState(false)
    const [showAlertBox, setShowAlertBox] = useState<boolean>(false)
    const [showMessage, setShowMessage] = useState<string>("")
    const [showType, setShowType] = useState<string>("warning")

    // UseEffect for local files...
    useEffect(() => {
        if (document?.name) {
            const fileExtension = (document.name.split('.').pop() || '').toLowerCase();

            const isValidExtension = fileExtension === 'pdf' || fileExtension === 'jpg' || fileExtension === 'jpeg' || fileExtension === 'png';
            const isPdf = fileExtension === 'pdf';

            if (!isValidExtension) {
                return;
            }

            const fileurl = URL.createObjectURL(document)

            if (isPdf) {
                setPdfUrl(fileurl);
            } else {
                setImgUrl(fileurl);
            }
        }
    }, [document]);

    // UseEffect for API calling to rendering the documents 
    useEffect(() => {
        if (!document?.name) {
            const downloadfile = async () => {
                setLoadingScreen(true)
                try {
                    const response = await axiosPrivate.post(modalType == "Vendor Details" ? "get/razorpay/vendor/kyc-datas":`customeronboarding/download`, { document: document },
                        {
                            responseType: "arraybuffer"
                        });

                    const contentType = response.headers['content-type'];
                    const isPdf = contentType === 'application/pdf' ? contentType === 'application/pdf' : false;
                    const blob = new Blob([response.data], { type: contentType });
                    const objectURL = URL.createObjectURL(blob);

                    // const fileURL = URL.createObjectURL(response.data);
                    const fileURL = objectURL;

                    if (isPdf) {
                        setPdfUrl(fileURL);
                    } else {
                        setImgUrl(fileURL);
                    }
                    if (response.status === 200) {
                        setLoadingScreen(false)
                    }
                    
                } catch (error: any) {
                    setShowAlertBox(true)
                    setShowMessage(error.message)
                    setShowType("warning")
                    setLoadingScreen(false)
                    setTimeout(() => {
                        setShowAlertBox(false);
                    }, 5000);
                }
            }
            downloadfile()
        }
    }, [showDocumentModal])

    // Function for ZoomIn..
    const handleZoomIn = () => {
        if (zoomLevel < 1500) {
            setZoomLevel(zoomLevel + 200);
        }
    };

    // Function for ZoomOut..
    const handleZoomOut = () => {
        if (zoomLevel > 300) {
            setZoomLevel(zoomLevel - 200);
        }
    };

    return (
        <>
            <div className={"document-modal"}>
                <div className={"container"}>
                    <div className={"close-icon"}>
                        <span className={"cross-icon"}>
                            <RxCross1 onClick={(val) => closeDocument(false)} style={{ fontSize: "40px" }} />
                        </span>
                    </div>

                    <div className='document-content'>
                        <>
                            {pdfUrl ? (
                                <div style={{ height: "600px", width: "600px" }}>
                                    <object
                                        data={pdfUrl}
                                        type="application/pdf"
                                        width="100%"
                                        height="100%"
                                        aria-label="Online PDF"
                                    ></object>
                                </div>
                            ) : imgUrl ? (
                                <div style={{ overflow: "hidden", overflowY: "auto", overflowX: "auto", maxHeight: "600px", maxWidth: "600px" }}>
                                    <img src={imgUrl}
                                        alt="Image"
                                        style={{
                                            maxHeight: `${zoomLevel}px`,
                                            maxWidth: `${zoomLevel}px`,
                                        }} />
                                </div>
                            ) : (
                                <div>No valid file found</div>
                            )}
                            {imgUrl &&
                                <div style={{ position: 'absolute', top: 670, left: 590, display: "flex", gap: "3px" }}>
                                    <AiOutlineMinusCircle className={"zoomOut"} onClick={handleZoomOut} />
                                    <IoIosAddCircleOutline className={"zoomIn"} onClick={handleZoomIn} />
                                </div>
                            }
                        </>
                    </div>
                </div>
            </div>
            {showAlertBox && <AlertBox type={showType} message={showMessage} />}
            {loadingScreen && <Loading />}
        </>
    )
}



