import CustomEllipsisRenderer from "../../product-admin/users/users-aggrid-column-config"

export const customerApprovalColumnData = [
    {
        field: 'selection', headerName: '', minWidth: 60, maxWidth: 60, resizable: false, suppressMovable: true, filter: "agSetColumnFilter", suppressSizeToFit: false, headerCheckboxSelection: true, checkboxSelection: true, showDisabledCheckboxes: true, suppressRowTransform: true, cellStyle: { textAlign: 'center' }
    },
    {
        field: 'customerCode', headerName: 'Customer Code', minWidth: 200, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "number", cellRendererFramework: CustomEllipsisRenderer,
    },
    {
        field: 'customerName', headerName: 'Customer Name', minWidth: 200, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer,
    },

    {
        field: 'companyConstitution', headerName: 'Company Constitution', minWidth: 200, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer,
    },
    {
        field: 'businessCategory', headerName: 'Company Sub Category', minWidth: 200, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer,
    },
    {
        field: 'bank', headerName: 'Bank', minWidth: 200, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "number", cellRendererFramework: CustomEllipsisRenderer,
    },
    {
        field: 'accountNumber', headerName: 'AccountNumber', minWidth: 200, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "number", cellRendererFramework: CustomEllipsisRenderer,
    },
    {
        field: 'ifscCode', headerName: 'IFSCCode', minWidth: 200, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "number", cellRendererFramework: CustomEllipsisRenderer,
    },
    {
        field: 'isActive', headerName: 'Is Active', minWidth: 120, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer,
    },
    {
        field: 'status', headerName: 'Status', minWidth: 200, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellClassRules: {
            'success-cell': (params: any) => params.value === 'Approved',
            'registered-cell': (params: any) => params.value === 'Pending Approval',
            'denied-cell': (params: any) => params.value === 'Declined',
        }, cellRendererFramework: CustomEllipsisRenderer,
    },

    {
        field: 'approvedBy', headerName: 'Approved / Declined by', minWidth: 200, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer,
    },






]


export const rowDatathis = [
    {
        CustomerID: "FTKQ0001",
        CompanyConstitution: "Private Limited",
        businessCategory: "Software",
        companyName: "KnowledgeQ",
        isActive: "Yes",
        status: "Approved",
        approvedBy: "",

    }, {
        CustomerID: "FTKQ0002",
        CompanyConstitution: "Private Limited",
        businessCategory: "Software",
        companyName: "KCICS",
        isActive: "Yes",
        status: "Approved",
        approvedBy: "",

    }, {
        CustomerID: "FTKQ0003",
        CompanyConstitution: "Private Limited",
        businessCategory: "Software",
        companyName: "KCICS",
        isActive: "Yes",
        status: "Approved",
        approvedBy: "",

    }, {
        CustomerID: "FTKQ0003",
        CompanyConstitution: "Private Limited",
        businessCategory: "Software",
        companyName: "KQ",
        isActive: "Yes",
        status: "Approved",
        approvedBy: "",

    },


]