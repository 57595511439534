import { useState, useEffect } from 'react'
import MainPageCard from '../../common-component/cards/main-page-card'
import NavigateHeader from '../../common-component/navigate-header'
import "../../../styles/product-admin/users/form.scss"
import RadioOrCheckbox from '../../common-component/form-elements/radio-or-checkbox'
import DropdownComponent from '../../common-component/form-elements/dropdown-component'
import InputComponent from '../../common-component/form-elements/input-component'
import ButtonComponent from '../../common-component/form-elements/button-component'
import { BsPlusLg } from 'react-icons/bs'
import Privilege from './privilege'
import DeleteModal from '../../common-component/modals/delete-modal'
import AlertBox from '../../common-component/alert-box'
import Changesmodal from '../../common-component/modals/changes-modal'
import Loading from '../../common-component/modals/loading-screen'
import { useDispatch, useSelector } from "react-redux";
import { resetFormModified } from "../../../redux/action";
import { v4 as uuidv4 } from 'uuid';
import useAxiosPrivate from '../../../services/hooks/useaxios-private'
import useAuth from '../../../services/hooks/useauth'
import { format } from "date-fns";
import AddDepartment from '../../common-component/modals/department-add-modal'
import { Checkbox, FormControl, InputLabel, ListItemIcon, ListItemText, MenuItem, Select } from "@mui/material"
import { CgLayoutGrid } from 'react-icons/cg'
import { log } from 'console'

interface FormProps {
  userNavigateFunction: (val: string) => void;
  userNavigate: string;
  isEditMode: boolean;
  modeFunction: (val: boolean) => void;
  editData: any;
  userType: string;
}

export interface UserDataType {
  organization: string;
  organizationName: string;
  company: string;
  companyName: string;
  group: string;
  groupName: string;
  // privilege: string;
  // privilegeName: string;
  name: string;
  email: string;
  mobileNumber: string;
  reportingTo: string;
  reportingToName: string;
  ifscCode: string;
  region: string;
  regionName: string;
  state: string;
  stateName: string;
  city: string;
  cityName: string;
  branch: string;
  branchName: string;
  userIsActive: string;
  isAccountLocked: string;
  userIsActiveRemarks: string;
  isAccountLockedRemarks: string;
  [key: string]: string;
}

export interface userValidationRulesInterface {
  [key: string]: {
    [key: string]: {
      regex: RegExp | string;
      field: string;
      shouldNotBe: string;
    };
  };
}
interface GstDataType {
  GSTIN: string;
  CPIN: string;
  Total: string;
  CreatedDate: string;
  ExpiryDate: string;
  ChallanStatus: string;
  allanStatus: string;
}
// const initData: any = {
//   privilegDropdownVal: "",
//   privilegDropdownId: "",
// }

const Form: React.FC<FormProps> = ({ userNavigateFunction, userNavigate, editData, userType, isEditMode, modeFunction }) => {

  const [productAdminUserData, setProductAdminUserData] = useState<UserDataType>({
    branch: "",
    branchName: "",
    city: "",
    cityName: "",
    company: "",
    companyName: "",
    email: "",
    group: "",
    groupName: "",
    departmentId: "",
    departmentName: "",
    ifscCode: "",
    isAccountLocked: "No",
    isAccountLockedRemarks: "",
    mobileNumber: "",
    name: "",
    organization: "",
    organizationName: "",
    // privilege: "",
    // privilegeName: "",
    region: "",
    regionName: "",
    reportingTo: "",
    reportingToName: "",
    state: "",
    stateName: "",
    userIsActive: "Yes",
    userIsActiveRemarks: "",
  });
  const [disableBankUser, setDisableBankUser] = useState({
    region: false,
    state: false,
    city: false,
    branch: false,
  });
  const [userDataErrors, setUserDataErrors] = useState<Record<string, boolean>>({});
  const [ProductAdminGroupValues, setProductAdminGroupValues] = useState<any[]>([]);
  const [OrganaisationsValues, setOrganaisationsValues] = useState<any[]>([]);
  const [OrgAdminGroupValues, setOrgAdminGroupValues] = useState<any[]>([]);
  const [componentNavigator, setComponentNavigator] = useState("form-page");
  const [showChangesModal, setShowChangesModal] = useState<boolean>(false);
  const [showResetModel, setShowResetModel] = useState<boolean>(false)
  const [privilegeValue, setPrivilegeValue] = useState<any[]>([]);
  const [showAlertBox, setShowAlertBox] = useState<boolean>(false) // error message ShowAlertBox
  const [companyValues, setCompanyValues] = useState<any[]>([{ label: "Select", value: "Select", id: "" }]);
  const [formattedTime, setFormattedTime] = useState("warning"); // error message showType
  const [RegionValues, setRegionValues] = useState<any[]>([]);
  const [BranchValues, setBranchValues] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showMessage, setShowMessage] = useState<string>(""); // error message showMessage
  const [StateValues, setStateValues] = useState<any[]>([]);
  const [groupValues, setGroupValues] = useState<any[]>([]);
  const [displayText, setDisplayText] = useState('');
  const [selectedFeatures, setSelectedFeatures] = useState<any[]>([]);
  const [errorFeatures, setErrorFeatures] = useState<boolean>(false);
  const [windowWidth] = useState<number>(window.innerWidth);
  const [CityValues, setCityValues] = useState<any[]>([]);
  const [userValue, setUserValue] = useState<any[]>([]);
  const updatedIsValueValidAll: Record<string, boolean> = {};
  const [formattedDate, setFormattedDate] = useState("") // error message showType
  const [showType, setShowType] = useState("warning") // error message showType
  const isFormModified = useSelector((state: any) => state.isFormModified);
  const axiosPrivate = useAxiosPrivate();
  const dispatch = useDispatch();
  const { auth } = useAuth();
  const [navToDepart, setnavToDepart] = useState<boolean>(false)
  const [department, setDepartment] = useState<any[]>([]);

  const [accessDropdownValues, setAccessDropdownValues] = useState<any>([]);

  const OptionsId = accessDropdownValues?.map((item: any) => item.id)
  // console.log(OptionsId, "OptionsIdOptionsIdOptionsId");

  // FingerTipUser
  // CompanyUser
  // BankUser 
  const userValidationRules: userValidationRulesInterface = {
    User_details: {
      organizationName: {
        regex: "",
        field: "mandatory",
        shouldNotBe: ""
      },
      companyName: {
        regex: "",
        field: "",
        shouldNotBe: ""
      },
      groupName: {
        regex: /^[a-zA-Z][a-zA-Z ]*$/,
        field: "mandatory",
        shouldNotBe: ""
      },
      privilegeName: {
        regex: "",
        field: "",
        shouldNotBe: ""
      },
      name: {
        regex: /^[a-zA-Z][a-zA-Z /]*$/,
        field: "mandatory",
        shouldNotBe: ""
      },
      email: {
        // regex: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
        regex: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        field: "mandatory",
        shouldNotBe: ""
      },
      mobileNumber: {
        regex: /^[0-9]{10}$/,
        field: "mandatory",
        shouldNotBe: ""
      },
      reportingToName: {
        regex: "",
        field: "",
        shouldNotBe: ""
      },
      ifscCode: {
        // regex: (productAdminUserData.ifscCode !== "") ? /^[A-Z]{4}0[A-Z0-9]{6}$/: "",
        regex: "",
        field: "",
        shouldNotBe: ""
      },
      regionName: {
        regex: "",
        field: "",
        shouldNotBe: ""

      },
      stateName: {
        regex: "",
        field: "",
        shouldNotBe: ""
      },
      cityName: {
        regex: "",
        field: "",
        shouldNotBe: ""
      },
      branchName: {
        regex: "",
        field: "",
        shouldNotBe: ""
      },
      userIsActive: {
        regex: "",
        field: "mandatory",
        shouldNotBe: ""

      },
      isAccountLocked: {
        regex: "",
        field: "mandatory",
        shouldNotBe: ""

      },
      userIsActiveRemarks: {
        regex: isEditMode && productAdminUserData.userIsActive === "No" ? /^[^\s].*/ : "",
        field: isEditMode && productAdminUserData.userIsActive === "No" ? "mandatory" : "",
        shouldNotBe: ""
      },
      isAccountLockedRemarks: {
        regex: isEditMode && productAdminUserData.isAccountLocked === "Yes" ? /^[^\s].*/ : "",
        field: isEditMode && productAdminUserData.isAccountLocked === "Yes" ? "mandatory" : "",
        shouldNotBe: ""
      },
      departmentName: {
        regex: "",
        // field: productAdminUserData.company !== "" ? "mandatory" : "", 
        field: productAdminUserData.company !== "" ? "" : "",
        shouldNotBe: ""
      },
    }
  }

  useEffect(() => {
    const fetchDataAsync = async () => {
      fetchData(
        `region`,
        setRegionValues,
        'region',
        "regionId",
        "name"
      );
      if (userType === "BankUser") {
        getBankUserData();
      }
      fetchDataPrivilege(`productAdmin/user/privilege`, setGroupValues, "privilegeDatas");

      if (!isEditMode) {
        fetchData(
          `product-admin/get-organizations`,
          setOrganaisationsValues,
          'decryptedOrganisation',
          "organisation",
          "organisationName"
        );
      }
    };

    fetchDataAsync(); // Call the async function

  }, []);

  useEffect(() => {
    if (!isEditMode) return;
    setProductAdminUserData({
      branch: editData.branch ? editData.branch : "",
      branchName: editData.branchName ? editData.branchName : "",
      city: editData.city ? editData.city : "",
      cityName: editData.cityName ? editData.cityName : "",
      company: editData.company ? editData.company : "",
      companyName: editData.companyName ? editData.companyName : "",
      email: editData.email,
      group: editData.group,
      groupName: editData.groupName,
      departmentId: editData.departmentId ? editData.departmentId : "",
      departmentName: editData.departmentName ? editData.departmentName : "",
      ifscCode: editData.ifscCode,
      mobileNumber: editData.mobileNumber,
      name: editData.name,
      organization: editData.organization,
      organizationName: editData.organizationName,
      // privilege: editData.privilege,
      // privilegeName: editData.privilegeName,
      region: editData.region ? editData.region : "",
      regionName: editData.regionName ? editData.regionName : "",
      reportingTo: editData.reportingTo || "",
      reportingToName: editData.reportingToName || "",
      state: editData.state ? editData.state : "",
      stateName: editData.stateName ? editData.stateName : "",
      userIsActive: editData.userIsActive,
      userIsActiveRemarks: editData.userIsActiveRemarks === null ? "" : editData.userIsActiveRemarks,
      isAccountLocked: editData.isAccountLocked,
      isAccountLockedRemarks: editData.isAccountLockedRemarks === null ? "" : editData.isAccountLockedRemarks,
    });
    const companyId = editData.company || "";
    const orgName = editData.organizationName.toLowerCase();
    const userType =
      (companyId === "") && orgName === "fingertip"
        ? "FingerTipUser"
        : (companyId !== "") ? "CompanyUser" : ((companyId === "") && orgName !== "fingertip")
          ? "BankUser" : "";

    getFeaturesOnEdit(editData.group, userType, editData.privilege);


    // await groupDataGet(editData.organization, editData.company ? editData.company : "");
    // await fetchDataPrivilege(`productAdmin/user/privilege`, setGroupValues, "privilegeDatas");
    if (editData.company) {
      fetchDataDropDownData(
        `product-admin/user-department/${editData.organization}/${editData.company}`,
        setDepartment,
        "departmentDetails"
      );
    }
    DateTimeConverter(editData.lastLoginAt);
    if (editData.region) {
      fetchData(`region/state/${editData.region}`, setStateValues, 'states', "stateId", "name");
    }
    if (editData.state) {
      fetchData(`state/cities/${editData.state}`, setCityValues, 'city', "cityId", "name");
    }
    if (editData.branch) {
      fetchData(`get-branchBy-city/${editData.city}`, setBranchValues, 'filteredBranch', "branchId", "branchName");
    }
    reportingtoUserGet(editData.organization, editData.company ? editData.company : "", editData.userId);
  }, [isEditMode]);

  const getFeatures = async (priviledgeId: any, userType: any) => {
    try {
      const res = await axiosPrivate.get(`/product-admin/features/${userType}/${priviledgeId}`);

      if (res.status === 200) {

        const groupdropdownValues = res.data.features.map((item: any) => ({
          value: item.name,
          id: item.featureId,
        }
        ));
        setAccessDropdownValues(groupdropdownValues)
        setSelectedFeatures(groupdropdownValues?.map((item: any) => item.id));
        console.log(groupdropdownValues?.map((item: any) => item.id));

        let selectedValues: any = groupdropdownValues?.map((item: any) => item.id).map((optionValue: any) => {
          const optionLabeldrop = groupdropdownValues.find((item: any) => item.id === optionValue);
          return optionLabeldrop ? optionLabeldrop.value : "";
        });

        if (Array.isArray(selectedValues) && selectedValues.length > 0) {
          setDisplayText(selectedValues.join(', '));
        } else if (!Array.isArray(selectedValues)) {
          setDisplayText(selectedValues);
        } else {
          setDisplayText("");
        }
      }
    }
    catch (error: any) {
      setAccessDropdownValues([])
    }
  }

  const getFeaturesOnEdit = async (priviledgeId: any, userType: any, privilege: any) => {
    try {
      const res = await axiosPrivate.get(`/product-admin/features/${userType}/${priviledgeId}`);

      if (res.status === 200) {

        const groupdropdownValues = res.data.features.map((item: any) => ({
          value: item.name,
          id: item.featureId,
        }
        ));
        setAccessDropdownValues(groupdropdownValues)
        setSelectedFeatures(privilege || []);

        let selectedValues: any = privilege.map((optionValue: any) => {
          const optionLabeldrop = groupdropdownValues.find((item: any) => item.id === optionValue);
          return optionLabeldrop ? optionLabeldrop.value : "";
        });

        if (Array.isArray(selectedValues) && selectedValues.length > 0) {
          setDisplayText(selectedValues.join(', '));
        } else if (!Array.isArray(selectedValues)) {
          setDisplayText(selectedValues);
        } else {
          setDisplayText("");
        }
      }
    }
    catch (error: any) {
      setAccessDropdownValues([])
    }
  }

  const handelSelectAll = (e: any) => {
    const value = e.target.value;
    let selectedValues = ""
    if (value.includes("all")) {
      setSelectedFeatures((prevValue: any) => {
        return (prevValue && prevValue.length === accessDropdownValues?.length) ? [] : accessDropdownValues?.map((item: any) => item.id) || [];
      });
      var index = value.indexOf('all');
      if (index !== -1) {
        value.splice(index, 1);
      }
      selectedValues = accessDropdownValues?.map((item: any) => item.id).map((optionValue: any) => {
        const optionLabeldrop = accessDropdownValues.find((item: any) => item.id === optionValue);
        return optionLabeldrop ? optionLabeldrop.value : "";
      });
    } else {
      setSelectedFeatures(value);
      selectedValues = value.map((optionValue: any) => {
        const optionLabeldrop = accessDropdownValues.find((item: any) => item.id === optionValue);
        return optionLabeldrop ? optionLabeldrop.value : "";
      });
    }
    if (Array.isArray(selectedValues) && selectedValues.length > 0) {
      setDisplayText(selectedValues.join(', '));
    } else if (!Array.isArray(selectedValues)) {
      setDisplayText(selectedValues);
    } else {
      setDisplayText("");
    }
  };

  const getBankUserData = async () => {
    try {
      const res = await axiosPrivate.get(`product-admin/getLoggedinUserData`);
      if (res.status === 200) {
        let currentBankUser = res.data.reportingToUsers
        if (currentBankUser.privilegeName === "Branch") {
          setDisableBankUser((prevState) => ({
            ...prevState,
            region: true,
            state: true,
            city: true,
            branch: true,
          }));

          setProductAdminUserData((prevState) => ({
            ...prevState,
            ifscCode: currentBankUser.ifscCode,
            branch: currentBankUser.branch,
            branchName: currentBankUser.branchName,
            city: currentBankUser.city,
            cityName: currentBankUser.cityName,
            state: currentBankUser.state,
            stateName: currentBankUser.stateName,
            region: currentBankUser.region,
            regionName: currentBankUser.regionName,
          }));
        } else if (currentBankUser.privilegeName === "City") {
          setDisableBankUser((prevState) => ({
            ...prevState,
            region: true,
            state: true,
            city: true,
          }));
          setProductAdminUserData((prevState) => ({
            ...prevState,
            city: currentBankUser.city,
            cityName: currentBankUser.cityName,
            state: currentBankUser.state,
            stateName: currentBankUser.stateName,
            region: currentBankUser.region,
            regionName: currentBankUser.regionName,
          }));

          fetchData(`get-branchBy-city/${currentBankUser.city}`, setBranchValues, 'filteredBranch', "branchId", "branchName");
        } else if (currentBankUser.privilegeName === "State") {
          setDisableBankUser((prevState) => ({
            ...prevState,
            region: true,
            state: true,
          }));
          setProductAdminUserData((prevState) => ({
            ...prevState,
            state: currentBankUser.state,
            stateName: currentBankUser.stateName,
            region: currentBankUser.region,
            regionName: currentBankUser.regionName,
          }));
          fetchData(`state/cities/${currentBankUser.state}`, setCityValues, 'city', "cityId", "name");
        } else if (currentBankUser.privilegeName === "Region") {
          setDisableBankUser((prevState) => ({
            ...prevState,
            region: true,
          }));
          setProductAdminUserData((prevState) => ({
            ...prevState,
            region: currentBankUser.region,
            regionName: currentBankUser.regionName,
          }));
          fetchData(`region/state/${currentBankUser.region}`, setStateValues, 'states', "stateId", "name");
        }
      }
    }
    catch (error: any) {
    }
  }

  const DateTimeConverter = (date: any) => {
    const originalDate = new Date(date);
    // Formatting date to "01-Jul-2023"
    setFormattedDate(format(originalDate, "dd-MMM-yyyy"));
    // Formatting time to "14:01"
    setFormattedTime(format(originalDate, "HH:mm"));
  }

  const companeyDataGet = async (id: number) => {
    try {
      const res = await axiosPrivate.get(`product-admin/get-companies/${id}`)
      if (res.status === 200) {
        const groupdropdownValues = res.data.map((item: any) => ({
          label: item.companyName,
          value: item.companyName,
          id: item.company,
        }
        ));
        const sortedData = [...groupdropdownValues].sort((a, b) => a.label.localeCompare(b.label));
        if (userType === "CompanyUser") {
          setProductAdminUserData((prevState) => ({
            ...prevState,
            company: sortedData[0].id,
            companyName: sortedData[0].value,
          }));
          setCompanyValues([{ label: "Select", value: "Select", id: "" }, ...groupdropdownValues]);
          fetchDataDropDownData(
            `product-admin/user-department/${id}/${sortedData[0].id}`,
            setDepartment,
            "departmentDetails"
          );
          // groupDataGet(id, sortedData[0].id);
          setCompanyValues(sortedData);
          reportingtoUserGet(id, sortedData[0].id, '');
        } else {
          setCompanyValues([{ label: "Select", value: "Select", id: "" }, ...groupdropdownValues]);
        }
      }
    }
    catch (error: any) {
    }
  }

  const groupDataGet = async (organizationId: any, companyId: any) => {
    try {
      const res = await axiosPrivate.post(`product-admin/get-all-groups`, {
        organizationId: organizationId,
        companyId: companyId,
      });
      if (res.status === 200) {
        const groupdropdownValues = res.data.groups.map((item: any) => ({
          label: item.groupName,
          value: item.groupName,
          id: item.groupId,
        }
        ));
        const sortedData = [...groupdropdownValues].sort((a, b) => a.label.localeCompare(b.label));
        setGroupValues(sortedData)
        setPrivilegeValue(res.data.groups)
      }
    }
    catch (error: any) {
    }
  }
  // FingerTipUser
  // CompanyUser
  // BankUser 

  const reportingtoUserGet = async (organizationId: any, companyId: any, userId: any) => {
    try {
      const res = await axiosPrivate.post(`product-admin/reportingto`, {
        organizationId: organizationId,
        companyId: companyId,
        userId: userId
      });

      if (res.status === 200) {
        const groupdropdownValues = res.data.reportingToUsers.map((item: any) => ({
          label: item.name,
          value: item.name,
          id: item.userId,
        }));
        const sortedData = [...groupdropdownValues].sort((a, b) => a.label.localeCompare(b.label));
        setUserValue(sortedData);
      }
    }
    catch (error: any) {
    }
  }

  const sendmailForResetPassward = async (val: any) => {
    setIsLoading(true);
    try {
      if (editData.userId) {
        const res = await axiosPrivate.patch(`user/resend/set-up/password/${editData.userId}`, { resetPasswordRemarks: val });
        if (res.status === 200) {
          setShowResetModel(false);
          setShowAlertBox(true);
          setIsLoading(false);
          setShowType("success");
          setShowMessage(res.data.message);
          clearAleart("resetPasward");
        }
      }
    }
    catch (error: any) {
      setShowResetModel(false);
      setShowAlertBox(true);
      setIsLoading(false);
      setShowType("danger");
      setShowMessage(error.response.data.message);
      clearAleart('resetPasward');
    }
  }

  const fetchData = async (url: string, setData: (data: any[]) => void, nestedProperty: string, id: any, representName: any) => {
    try {
      const response = await axiosPrivate.get(url);
      if (response.status === 200) {
        const dropdownValues = response.data[nestedProperty].map((item: any) => ({
          label: item[representName],
          value: item[representName],
          id: item[id],
        }));
        const sortedData = [...dropdownValues].sort((a, b) => a.label.localeCompare(b.label));
        if (setData === setOrganaisationsValues && sortedData.length === 1) {
          setProductAdminUserData((prevState) => ({
            ...prevState,
            organization: sortedData[0].id,
            organizationName: sortedData[0].value,
          }));
          setData(sortedData);
          companeyDataGet(sortedData[0].id);
          // groupDataGet(sortedData[0].id, '');
          reportingtoUserGet(sortedData[0].id, '', '');

        } else if (setData === setRegionValues || setData === setStateValues || setData === setCityValues || setData === setBranchValues || setData === setOrganaisationsValues) {
          setData([{ label: "Select", value: "Select", id: "" }, ...sortedData]);
        } else {
          setData(sortedData);
        }
      }
    } catch (error) {
    }
  };

  // warningMsg 
  const clearAleart = (status: any) => {
    const timer = setTimeout(() => {
      setShowAlertBox(false)
      setShowMessage("");
      if (status === 200) {
        userNavigateFunction("grid");
        modeFunction(false)
      }
      if (status === "resetPasward") {
        userNavigateFunction("grid");
        modeFunction(false)
      }
      clearTimeout(timer);
    }, 5000);
  }

  const handelFectchIfsc = async (id: any) => {
    try {
      const res = await axiosPrivate.get(`get-ifscBy-branch/${id}`)
      if (res.status === 200) {
        setProductAdminUserData((prevState) => ({
          ...prevState,
          ifscCode: res.data.ifsc
        }));
      }
    }
    catch (error: any) {
    }
  }

  const validateForm = (): boolean => {
    const updatedFieldValidity: Record<string, boolean> = {};
    Object.keys(userValidationRules.User_details).forEach((field) => {
      const rule = userValidationRules.User_details[field];
      const value = productAdminUserData[field];
      // Check if the field value meets the conditions based on rule.field and rule.shouldNotBe
      const isValueValid = (rule.field === "mandatory") ? (value !== rule.shouldNotBe) : true;
      // If a regular expression is provided, test the field value against it
      const isRegexValid = rule.regex !== "" ? new RegExp(rule.regex).test(value) : true;
      updatedFieldValidity[field] = !isValueValid || !isRegexValid;
      updatedIsValueValidAll[field] = !isValueValid
    });
    setUserDataErrors(updatedFieldValidity);
    // Check if any field has validation errors
    const isFormValid = Object.keys(updatedFieldValidity).every((keys) => {
      return !updatedFieldValidity[keys]
    });
    return isFormValid;
  };

  async function productAdminUserDataSave() {
    const isValid = validateForm();
    setErrorFeatures(false)
    if (isValid) {
      if (selectedFeatures.length >= 1) {
        setIsLoading(true);
        try {
          const res = isEditMode ?
            await axiosPrivate.patch(`product-admin/user/${editData.userId}`, { ...productAdminUserData, selectedFeatures })
            :
            await axiosPrivate.post(`product-admin/user`, { ...productAdminUserData, selectedFeatures })
          if (res.status === 200) {
            setShowAlertBox(true);
            setIsLoading(false);
            setShowType("success");
            setShowMessage(res.data.message);
            dispatch(resetFormModified(false));
            clearAleart(res.status);
            setProductAdminUserData((prevState) => ({
              ...prevState,
              branch: "",
              branchName: "",
              city: "",
              cityName: "",
              // company: "",
              // companyName: "",
              departmentId: "",
              departmentName: "",
              email: "",
              group: "",
              groupName: "",
              ifscCode: "",
              isAccountLocked: "No",
              isAccountLockedRemarks: "",
              mobileNumber: "",
              name: "",
              // organization: "",
              // organizationName: "",
              // privilege: "",
              // privilegeName: "",
              region: "",
              regionName: "",
              reportingTo: "",
              reportingToName: "",
              state: "",
              stateName: "",
              userIsActive: "Yes",
              userIsActiveRemarks: ""
            }));
          }
        }
        catch (error: any) {
          setIsLoading(false)
          setShowAlertBox(true);
          setShowType("danger")
          setShowMessage(error.response.data.error)
          clearAleart(error.status)
          if (error.response.data.error === "Email ID already exists.") {
            setUserDataErrors({
              email: true
            })
          }
          if (error.response.data.error === "Contact Number already exists.") {
            setUserDataErrors({
              mobileNumber: true
            })
          }
        }
      } else {
        setShowAlertBox(true);
        setShowType("danger")
        setShowMessage("Mandatory field(s) should not be left blank.")
        clearAleart('');
        setErrorFeatures(true)
      }
    }
    else {
      setShowAlertBox(true);
      setShowType("danger")
      setShowMessage(Object.values(updatedIsValueValidAll).every((values) => !values) ?
        "Some of the field(s) are not in required format." : "Mandatory field(s) should not be left blank.")
      clearAleart('');
    }
  }

  const productAdminUserDataCancel = () => {
    setProductAdminUserData({
      branch: "",
      branchName: "",
      city: "",
      cityName: "",
      company: "",
      companyName: "",
      email: "",
      group: "",
      groupName: "",
      ifscCode: "",
      isAccountLocked: "No",
      isAccountLockedRemarks: "",
      mobileNumber: "",
      name: "",
      organization: "",
      organizationName: "",
      privilege: "",
      privilegeName: "",
      region: "",
      regionName: "",
      reportingTo: "",
      reportingToName: "",
      state: "",
      stateName: "",
      userIsActive: "Yes",
      userIsActiveRemarks: ""
    });
    userNavigateFunction("grid");
    dispatch(resetFormModified(false));
    modeFunction(false);
  }

  const fetchDataDropDownData = async (url: string, setData: (data: any[]) => void, nestedProperty: string) => {

    try {
      const response = await axiosPrivate.get(url);
      if (response.status === 200) {
        const dropdownValues = response.data[nestedProperty].map((item: any) => ({
          label: item.departmentName,
          value: item.departmentName,
          id: item.departmentId,
        }));
        const sortedData: any[] = [...dropdownValues].sort((a, b) => a.label.localeCompare(b.label));

        setData([{ label: "Select", value: "", id: "" }, ...sortedData]);
      }
    } catch (error) {
    }
  };

  const fetchDataPrivilege = async (url: string, setData: (data: any[]) => void, nestedProperty: string) => {
    try {
      const response = await axiosPrivate.get(url);
      if (response.status === 200) {
        const dropdownValues = response.data[nestedProperty].map((item: any) => ({
          label: item.privilegeName,
          value: item.privilegeName,
          id: item.privilegeId,
        }));
        setData(dropdownValues);

        const ProductAdmin = response.data.ProductadminPrivilegeDatas.map((item: any) => ({
          label: item.privilegeName,
          value: item.privilegeName,
          id: item.privilegeId,
        }));
        setProductAdminGroupValues(ProductAdmin)

        const OrgAdmin = response.data.orgAdminPrivilegeDatas.map((item: any) => ({
          label: item.privilegeName,
          value: item.privilegeName,
          id: item.privilegeId,
        }));
        setOrgAdminGroupValues(OrgAdmin)


      }
    } catch (error) {
    }
  };

  return (
    <>
      <MainPageCard>
        <NavigateHeader
          firstValue={userType === "FingerTipUser" ? "Fingertip Admin" : userType === "BankUser" ? "Onboarding" : "Organisation"}
          style={{ cursor: "pointer" }}
          secondValue={"Users"}
          navigatePage={(isFormModified ? () => { setShowChangesModal(true) } : productAdminUserDataCancel)}
        />
        {
          componentNavigator === "form-page" &&
          <>
            <div className='scroll-controler'>
              {
                isEditMode && editData.lastLoginAt !== null &&
                (
                  <div className='last-login-design'>
                    <p>Last login</p>
                    <p> {formattedDate} <span> {formattedTime} </span></p>
                  </div>
                )
              }
              <div className='form-top-main-user'>
                <div className='form-input'>
                  <div
                    key={uuidv4()}
                    className={userDataErrors.organizationName ? 'setError' : 'dropdown-user'}
                    style={(isEditMode || OrganaisationsValues.length === 1) ? { pointerEvents: "none", opacity: 1 } : {}}
                  >
                    <DropdownComponent
                      options={OrganaisationsValues}
                      title={"Organisation"}
                      isDisabled={isEditMode || OrganaisationsValues.length === 1}
                      required={userType === "FingerTipUser" ? true : false}
                      getData={(val) => {
                        setProductAdminUserData((prevState) => ({
                          ...prevState,
                          organization: val.id,
                          organizationName: val.value,
                          companyName: "",
                          company: "",
                          group: "",
                          groupName: "",
                          privilege: '',
                          privilegeName: "",
                          reportingTo: "",
                          reportingToName: "",
                          ifscCode: "",
                          branch: "",
                          branchName: "",
                          departmentId: "",
                          departmentName: "",
                        }));
                        companeyDataGet(val.id);
                        // groupDataGet(val.id, '');
                        reportingtoUserGet(val.id, '', '');
                        dispatch(resetFormModified(true));
                        setDisplayText("")
                        setSelectedFeatures([])
                        setAccessDropdownValues([])

                      }}
                      defaultValue={[{
                        label: productAdminUserData.organizationName === "" ? "Select" : productAdminUserData.organizationName,
                        value: productAdminUserData.organizationName
                      }]}
                    />
                  </div>
                  <div
                    key={uuidv4()}
                    className={userDataErrors.companyName ? 'setError' : 'dropdown-user'}
                    style={isEditMode ? { pointerEvents: "none", opacity: "0.5" } : {}}
                  >
                    <DropdownComponent
                      options={companyValues}
                      title={"Company"}
                      isDisabled={isEditMode || userType === "CompanyUser"}
                      required={false}
                      getData={(val) => {
                        setProductAdminUserData((prevState) => ({
                          ...prevState,
                          companyName: val.value,
                          company: val.id,
                          group: "",
                          groupName: "",
                          privilege: '',
                          reportingTo: "",
                          reportingToName: "",
                          privilegeName: "",
                          branch: "",
                          branchName: "",
                          ifscCode: "",
                          departmentId: "",
                          departmentName: "",
                        }));
                        if (val.id !== "") {
                          setProductAdminUserData((prevState) => ({
                            ...prevState,
                            group: "",
                            groupName: "",
                            privilege: '',
                            privilegeName: "",
                          }));
                        }
                        setDisplayText("")
                        setSelectedFeatures([])
                        setAccessDropdownValues([])
                        // groupDataGet(productAdminUserData.organization, val.id);
                        fetchDataDropDownData(
                          `product-admin/user-department/${productAdminUserData.organization}/${val.id}`,
                          setDepartment,
                          "departmentDetails"
                        );
                        reportingtoUserGet(productAdminUserData.organization, val.id, '');
                        dispatch(resetFormModified(true));
                      }}
                      defaultValue={[{ label: productAdminUserData.companyName === "" ? "Select" : productAdminUserData.companyName, value: productAdminUserData.companyName }]}
                    />
                  </div>
                </div>
                <div className='form-input'>
                  {
                    productAdminUserData.company !== "" &&
                    <div className='fetch-group' >
                      <div
                        key={uuidv4()}
                        className={userDataErrors.departmentName ? 'setError-with-plus' : 'dropdown-user-with-plus'}
                      // style={{ position: "relative", zIndex: 5 }}
                      >
                        <DropdownComponent
                          options={department}
                          title={"Department"}
                          required={false}
                          getData={(val) => {
                            setProductAdminUserData((prevState) => ({
                              ...prevState,
                              departmentId: val.id,
                              departmentName: val.value,
                            }));
                            dispatch(resetFormModified(true));
                          }}
                          defaultValue={[
                            {
                              label: productAdminUserData.departmentName === "" ? "Select" : productAdminUserData.departmentName,
                              value: productAdminUserData.departmentName
                            }
                          ]}
                        />
                      </div>
                      <div className='with-plus' onClick={() => {
                        setnavToDepart(true)
                      }}>
                        <BsPlusLg color='#004E9C' height={"14px"} />
                      </div>
                    </div>
                  }
                  <div
                    key={uuidv4()}
                    className={userDataErrors.groupName ? 'setError' : 'dropdown-user'}
                  // style={{ pointerEvents: "none", opacity: "0.5" }} userDataErrors.privilegeName ? 'setError' : 
                  >

                    <DropdownComponent
                      options={productAdminUserData.company === "" ? (productAdminUserData.organizationName).toLowerCase() === "fingertip" ? ProductAdminGroupValues : OrgAdminGroupValues : groupValues}
                      title={"Group"}
                      required={true}
                      isDisabled={productAdminUserData.organizationName === "" || productAdminUserData.organizationName === null}
                      getData={(val) => {
                        setProductAdminUserData((prevState) => ({
                          ...prevState,
                          group: val.id,
                          groupName: val.value,
                        }));
                        setDisplayText("")
                        setSelectedFeatures([])
                        setAccessDropdownValues([])

                        dispatch(resetFormModified(true));
                        const companyId = productAdminUserData.company;
                        const orgName = productAdminUserData.organizationName.toLowerCase();
                        const userType =
                          (companyId === "") && orgName === "fingertip"
                            ? "FingerTipUser"
                            : (companyId !== "") ? "CompanyUser" : ((companyId === "") && orgName !== "fingertip")
                              ? "BankUser" : "";


                        getFeatures(val.id, userType);

                        // getFeatures(val.id, (productAdminUserData.organizationName).toLowerCase() !== "fingertip" ? "BankUser" : "FingerTipUser")
                      }}
                      defaultValue={[{
                        label: productAdminUserData.groupName === "" ? "Select" : productAdminUserData.groupName,
                        value: productAdminUserData.groupName
                      }]}
                    />

                  </div>
                  {
                    // productAdminUserData.company !== "" &&
                    <div style={{ display: "flex", flexDirection: "column", marginTop: "5px" }} className={errorFeatures ? 'user-multi-select  multi-select-error' : "user-multi-select"}>
                      <p>Features<span style={{ color: "red" }}>*</span></p>
                      <FormControl style={{ border: "1px solid #A9C3DC" }}>
                        {
                          selectedFeatures.length > 0 ?
                            ""
                            :
                            <InputLabel shrink={false} id="placeholder-dropdown"
                              style={{ userSelect: "none", cursor: "pointer", color: "rgb(185 179 179)" }}
                            >
                              {"Select an Option"}
                            </InputLabel>
                        }
                        <Select value={selectedFeatures}
                          id="multi-select-5"
                          onChange={handelSelectAll}
                          renderValue={() => displayText}
                          multiple
                          disabled={productAdminUserData.organizationName === "" || productAdminUserData.organizationName === null || productAdminUserData.groupName === null || productAdminUserData.groupName === ""}
                          className='dropdown-style-user ' aria-expanded='true'>
                          <MenuItem value="all">
                            <ListItemIcon>
                              <Checkbox checked={accessDropdownValues?.length > 0 && selectedFeatures.length === accessDropdownValues.length}></Checkbox>
                            </ListItemIcon>
                            <ListItemText primary="Select All"></ListItemText>
                          </MenuItem>
                          {accessDropdownValues && accessDropdownValues.map((options: any) => (
                            <MenuItem key={options.id} value={options.id}>
                              <ListItemIcon>
                                <Checkbox checked={selectedFeatures.includes(options.id as string)} name="select-checkbox"></Checkbox>
                              </ListItemIcon>
                              <ListItemText primary={options.value}></ListItemText>
                            </MenuItem>
                          ))}
                        </Select>{""}
                      </FormControl>
                    </div>

                  }
                </div>

                <div className='form-input'>
                  <InputComponent
                    height={"40px"}
                    width={"350px"}
                    margin={"0px 60px 30px 0px"}
                    padding={"0px 0px 0px 10px"}
                    border={userDataErrors.name ? "1px solid red" : "1px solid #A9C3DC"}
                    borderRadius={"0px"}
                    backgroundColor={"white"}
                    color={"black"}
                    type={"text"}
                    maxLength={100}
                    inputTitle={"Name"}
                    placeHolder={"Enter Name"}
                    required={true}
                    inputValue={productAdminUserData.name}
                    getUser={(value: any) => {
                      setProductAdminUserData((prevState) => ({
                        ...prevState,
                        name: value,
                      }));
                      dispatch(resetFormModified(true));
                    }}
                  />

                  <div style={isEditMode ? { pointerEvents: "none", opacity: "0.5" } : {}}>
                    <InputComponent
                      height={"40px"}
                      width={"350px"}
                      margin={"0px 60px 30px 0px"}
                      padding={"0px 50px 0px 10px"}
                      border={userDataErrors.email ? "1px solid red" : "1px solid #A9C3DC"}
                      borderRadius={"0px"}
                      backgroundColor={"white"}
                      color={"black"}
                      type={"email"}
                      inputTitle={"Email ID"}
                      required={true}
                      placeHolder={'Enter Email ID'}
                      className={"gmail-input"}
                      inputField={"gmail"}
                      maxLength={100}
                      disabled={isEditMode}
                      inputValue={productAdminUserData.email}
                      getUser={(value: any) => {
                        setProductAdminUserData((prevState) => ({
                          ...prevState,
                          email: value,
                        }));
                        dispatch(resetFormModified(true));
                      }}
                    />
                  </div>
                  <div style={isEditMode ? { pointerEvents: "none", opacity: "0.5" } : {}}>
                    <InputComponent
                      height={"40px"}
                      width={"350px"}
                      margin={"0px 0px 30px 0px"}
                      padding={"0px 0px 0px 70px"}
                      border={userDataErrors.mobileNumber ? "1px solid red" : "1px solid #A9C3DC"}
                      borderRadius={"0px"}
                      backgroundColor={"white"}
                      color={"black"}
                      type={"text"}
                      inputTitle={"Contact Number"}
                      placeHolder={'Enter Contact Number'}
                      required={true}
                      maxLength={10}
                      disabled={isEditMode}
                      className={"mobilenumber-input"}
                      inputValue={productAdminUserData.mobileNumber}
                      getUser={(Number) => {
                        const value = Number.replace(/[^0-9]/g, "")
                        setProductAdminUserData((prevState) => ({
                          ...prevState,
                          mobileNumber: value,
                        }));
                        dispatch(resetFormModified(true));
                      }}
                    />
                  </div>
                </div>
                <div className='form-input'>
                  <div
                    key={uuidv4()}
                    className={userDataErrors.reportingToName ? 'setError' : 'dropdown-user'}
                  >
                    <DropdownComponent
                      options={userValue}
                      title={"Reporting To"}
                      // required={true}
                      getData={(val) => {
                        setProductAdminUserData((prevState) => ({
                          ...prevState,
                          reportingTo: val.id,
                          reportingToName: val.value,
                        }));
                        dispatch(resetFormModified(true));
                      }}

                      defaultValue={[{ label: productAdminUserData.reportingToName === "" ? "Select" : productAdminUserData.reportingToName, value: productAdminUserData.reportingToName }]}
                    />
                  </div>
                  {
                    ((productAdminUserData.company === null || productAdminUserData.company === "") &&
                      <>
                        <div key={uuidv4()} className={userDataErrors.regionName ? 'setError' : 'dropdown-user'} style={disableBankUser.region ? { pointerEvents: "none", opacity: "0.5", cursor: "auto" } : {}}>
                          <DropdownComponent
                            options={RegionValues}
                            title={"Region"}
                            required={false}
                            isDisabled={disableBankUser.region}
                            getData={(val) => {
                              setProductAdminUserData((prevState) => ({
                                ...prevState,
                                region: val.id,
                                regionName: val.value,
                                state: "",
                                stateName: "",
                                city: "",
                                cityName: "",
                                ifscCode: "",
                                branch: "",
                                branchName: "",
                              }));
                              if (val.id == "") {
                                setStateValues([]);
                                setCityValues([]);
                                setBranchValues([]);
                              } else {
                                setCityValues([]);
                                setBranchValues([]);
                                fetchData(`region/state/${val.id}`, setStateValues, 'states', "stateId", "name");
                              }
                              dispatch(resetFormModified(true));
                            }}
                            defaultValue={[{ label: productAdminUserData.regionName === "" ? "Select" : productAdminUserData.regionName, value: productAdminUserData.regionName }]}
                          />
                        </div>

                        <div key={uuidv4()} className={userDataErrors.stateName ? 'setError' : 'dropdown-user'} style={disableBankUser.state ? { pointerEvents: "none", opacity: "0.5", cursor: "auto", marginRight: "0px" } : { marginRight: "0px" }} >
                          <DropdownComponent
                            options={StateValues}
                            title={"State"}
                            required={false}
                            isDisabled={disableBankUser.state}
                            getData={(val) => {
                              setProductAdminUserData((prevState) => ({
                                ...prevState,
                                state: val.id,
                                stateName: val.value,
                                city: "",
                                cityName: "",
                                ifscCode: "",
                                branch: "",
                                branchName: "",
                              }));
                              if (val.id == "") {
                                setCityValues([]);
                                setBranchValues([]);
                              } else {
                                setBranchValues([]);
                                fetchData(`state/cities/${val.id}`, setCityValues, 'city', "cityId", "name");
                              }
                              dispatch(resetFormModified(true));
                            }}
                            defaultValue={[{ label: productAdminUserData.stateName === "" ? "Select" : productAdminUserData.stateName, value: productAdminUserData.stateName }]}
                          />
                        </div>
                      </>
                    )}
                </div>

                <div className='form-input'>
                  {
                    ((productAdminUserData.company === null || productAdminUserData.company === "") &&
                      <div key={uuidv4()} className={userDataErrors.cityName ? 'setError' : 'dropdown-user'} style={disableBankUser.city ? { pointerEvents: "none", opacity: "0.5", cursor: "auto" } : {}}>
                        <DropdownComponent
                          options={CityValues}
                          title={"City"}
                          required={false}
                          isDisabled={disableBankUser.city}
                          getData={(val) => {
                            setProductAdminUserData((prevState) => ({
                              ...prevState,
                              city: val.id,
                              cityName: val.value,
                              ifscCode: "",
                              branch: "",
                              branchName: "",
                            }));
                            if (val.id === "") {
                              setBranchValues([]);
                            } else {
                              fetchData(`get-branchBy-city/${val.id}`, setBranchValues, 'filteredBranch', "branchId", "branchName");
                            }
                            dispatch(resetFormModified(true));
                          }}
                          defaultValue={[{ label: productAdminUserData.cityName === "" ? "Select" : productAdminUserData.cityName, value: productAdminUserData.cityName }]}
                        />
                      </div>
                    )}
                  {/* && auth.UserType === "BankUser" || "FingerTipUser" */}

                  {
                    ((productAdminUserData.company === null || productAdminUserData.company === "") && (productAdminUserData.organizationName).toLowerCase() !== "fingertip") &&
                    <div className={userDataErrors.branchName ? 'setError' : 'dropdown-user'} style={disableBankUser.branch ? { pointerEvents: "none", opacity: "0.5", cursor: "auto" } : {}}>
                      <DropdownComponent key={uuidv4()}
                        options={BranchValues}
                        title={"Branch"}
                        isDisabled={disableBankUser.branch}
                        required={false}
                        width={'350px'}
                        getData={(val) => {
                          setProductAdminUserData((prevState) => ({
                            ...prevState,
                            branch: val.id,
                            branchName: val.value,
                          }));
                          if (val.id == "") {
                            setProductAdminUserData((prevState) => ({
                              ...prevState,
                              ifscCode: ""
                            }));
                          } else {
                            handelFectchIfsc(val.id);
                          }
                          dispatch(resetFormModified(true));

                        }}
                        defaultValue={[{ label: productAdminUserData.branchName === "" ? "Select" : productAdminUserData.branchName, value: productAdminUserData.branchName }]}
                      />
                    </div>
                  }
                  {/* && auth.UserType === "BankUser" || "FingerTipUser" */}
                  {
                    ((productAdminUserData.company === null || productAdminUserData.company === "") && (productAdminUserData.organizationName).toLowerCase() !== "fingertip") &&
                    <>
                      <div className='fetch-button-addedcss' style={{ pointerEvents: "none", opacity: "0.5" }}>
                        <InputComponent
                          height={"40px"}
                          width={"350px"}
                          margin={"0px 0px 30px 0px"}
                          padding={"0px 0px 0px 10px"}
                          border={userDataErrors.ifscCode ? "1px solid red" : "1px solid #A9C3DC"}
                          borderRadius={"0px"}
                          backgroundColor={"white"}
                          color={"black"}
                          type={"text"}
                          inputTitle={"IFSC code"}
                          placeHolder={'Enter IFSC code'}
                          inputField={"ifsc"}
                          required={false}
                          maxLength={11}
                          disabled={true}
                          inputValue={productAdminUserData.ifscCode}
                          getUser={(value: any) => {
                            const finalvalue = value.replace(/[^a-zA-Z0-9]/g, "").toUpperCase();
                            setProductAdminUserData((prevState) => ({
                              ...prevState,
                              ifscCode: finalvalue,
                            }));
                            dispatch(resetFormModified(true));
                          }}
                        />
                      </div>
                    </>
                  }

                </div>
                <div className='form-input'>
                  <div className='radio-input user-form-radio-input'>
                    <p>Is Active<span>*</span></p>
                    <div className='radio-btn-main' >
                      <RadioOrCheckbox
                        value={"Yes"}
                        type={"radio"}
                        margin={"0px 30px 0px 0px"}
                        name={"userIsActive1"}
                        checkedValue={productAdminUserData.userIsActive}
                        getVal={(val) => {
                          setProductAdminUserData((prevState) => ({
                            ...prevState,
                            userIsActive: val,
                            userIsActiveRemarks: "",
                          }));
                          dispatch(resetFormModified(true));
                        }}
                      />
                      <div style={(isEditMode) ? {} : { pointerEvents: "none", opacity: "0.5" }} >
                        <RadioOrCheckbox
                          value={"No"}
                          type={"radio"}
                          name={"userIsActive2"}
                          checkedValue={productAdminUserData.userIsActive}
                          disabled={!isEditMode}
                          getVal={(val) => {
                            setProductAdminUserData((prevState) => ({
                              ...prevState,
                              userIsActive: val,
                            }));
                            dispatch(resetFormModified(true));
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  {
                    (isEditMode && productAdminUserData.userIsActive === "No") &&
                    <div style={(isEditMode && productAdminUserData.userIsActive === "No") ? {} : { pointerEvents: "none", opacity: "0.5" }}>
                      <InputComponent
                        height={"40px"}
                        width={windowWidth > 1250 ? "760px" : "350px"}
                        margin={"0px 60px 30px 0px"}
                        padding={"0px 0px 0px 10px"}
                        border={userDataErrors.userIsActiveRemarks ? "1px solid red" : "1px solid #A9C3DC"}
                        borderRadius={"0px"}
                        backgroundColor={"white"}
                        color={"black"}
                        type={"text"}
                        inputTitle={"Remarks"}
                        required={true}
                        placeHolder={"Reason"}
                        maxLength={250}
                        disabled={(isEditMode && productAdminUserData.userIsActive === "No") ? false : true}
                        inputValue={productAdminUserData.userIsActiveRemarks}
                        getUser={(value: any) => {
                          setProductAdminUserData((prevState) => ({
                            ...prevState,
                            userIsActiveRemarks: value,
                          }));
                          dispatch(resetFormModified(true));
                        }}
                      />
                    </div>
                  }
                </div>

                <div className='form-input'>
                  <div className='radio-input user-form-radio-input'>
                    <p>Is Account Locked<span>*</span></p>
                    <div className='radio-btn-main'>
                      <div style={(isEditMode) ? {} : { pointerEvents: "none", opacity: "0.5" }}>
                        <RadioOrCheckbox
                          value={"Yes"}
                          type={"radio"}
                          disabled={!isEditMode}
                          name={"isAccountLocked1"}
                          margin={"0px 30px 0px 0px"}
                          checkedValue={productAdminUserData.isAccountLocked}
                          getVal={(val) => {
                            setProductAdminUserData((prevState) => ({
                              ...prevState,
                              isAccountLocked: val,
                            }));
                            dispatch(resetFormModified(true));
                          }}
                        />
                      </div>
                      <RadioOrCheckbox
                        value={"No"}
                        type={"radio"}
                        name={"isAccountLocked2"}
                        checkedValue={productAdminUserData.isAccountLocked}
                        getVal={(val) => {
                          setProductAdminUserData((prevState) => ({
                            ...prevState,
                            isAccountLocked: val,
                            isAccountLockedRemarks: "",
                          }));
                          dispatch(resetFormModified(true));
                        }}
                      />
                    </div>
                  </div>
                  {
                    (isEditMode && productAdminUserData.isAccountLocked === "Yes") &&
                    <div style={(isEditMode && productAdminUserData.isAccountLocked === "Yes") ? {} : { pointerEvents: "none", opacity: "0.5" }}>
                      <InputComponent
                        height={"40px"}
                        width={windowWidth > 1250 ? "760px" : "350px"}
                        margin={"0px 60px 30px 0px"}
                        padding={"0px 0px 0px 10px"}
                        border={userDataErrors.isAccountLockedRemarks ? "1px solid red" : "1px solid #A9C3DC"}
                        borderRadius={"0px"}
                        backgroundColor={"white"}
                        color={"black"}
                        type={"text"}
                        inputTitle={"Remarks"}
                        disabled={(isEditMode && productAdminUserData.isAccountLocked === "Yes") ? false : true}
                        placeHolder={"Reason"}
                        maxLength={250}
                        required={true}
                        inputValue={productAdminUserData.isAccountLockedRemarks}
                        getUser={(value: any) => {
                          setProductAdminUserData((prevState) => ({
                            ...prevState,
                            isAccountLockedRemarks: value,
                          }));
                          dispatch(resetFormModified(true));
                        }}
                      />
                    </div>
                  }
                </div>
              </div>
            </div>
            <div className='footer-user-pb'>
              <ButtonComponent
                title={"Cancel"}
                height={"50px"}
                width={"150px"}
                margin={"0px 8px"}
                backgroundColor={"#888888"}
                color={"white"}
                className={"button-component-hover cancel"}
                handleClick={(isFormModified ? () => { setShowChangesModal(true) } :
                  productAdminUserDataCancel)}
              />
              <ButtonComponent
                title={isEditMode ? "Update" : "Save"}
                height={"50px"}
                width={"150px"}
                backgroundColor={"#0055D4"}
                color={"white"}
                margin={"0px 8px"}
                disabled={showMessage && showType === "success" ? true : false}
                className={showMessage && showType === "success" ? "button-component-hover disabled" : "button-component-hover common-btn"}
                handleClick={productAdminUserDataSave}
              />
              {isEditMode &&
                <ButtonComponent
                  title={"Reset Password"}
                  height={"50px"}
                  width={"150px"}
                  backgroundColor={"#0055D4"}
                  color={"white"}
                  margin={"0px 8px"}
                  className={"button-component common-btn"}
                  handleClick={() => {
                    setShowResetModel(true)
                  }}
                />
              }
            </div>
          </>

        }
        {
          componentNavigator === "Privilege-page" &&
          <div>
            <Privilege
              groupValues={privilegeValue}
              productAdminUserData={productAdminUserData}
              isEditMode={isEditMode}
              userNavigate={userNavigate}
              componentNavigatorFun={(val: string) => {
                setComponentNavigator(val)
                // groupDataGet(productAdminUserData.organization, productAdminUserData.company);
              }}
            />
          </div>
        }
        {
          navToDepart &&
          <div>
            <AddDepartment
              productAdminUserData={productAdminUserData}
              clearValue={(val) => {
                setnavToDepart(val);
              }}
              getDepartment={() => {
                setProductAdminUserData((prevState) => ({
                  ...prevState,
                  departmentId: "",
                  departmentName: "",
                }));
                fetchDataDropDownData(`product-admin/user-department/${productAdminUserData.organization}/${productAdminUserData.company}`, setDepartment, "departmentDetails");
              }}
              setDepartment={setDepartment}
              department={department.filter((e) => e.value !== "")}
            />
          </div>
        }
      </MainPageCard >
      {
        showResetModel &&
        <div >
          <DeleteModal
            getDelete={() => { }}
            setResetPassword={(val) => { sendmailForResetPassward(val) }}
            clearValue={(value) => { setShowResetModel(value) }}
            modelType={"grid-remark"}
          />
        </div>
      }
      {
        showAlertBox &&
        <div className='alert-warp'>
          <AlertBox type={showType} message={showMessage} />
        </div>
      }
      {
        isLoading &&
        <Loading />
      }
      {
        showChangesModal &&
        <Changesmodal
          closeModal={() => setShowChangesModal(false)}
          handleClose={() => {
            setShowChangesModal(false);
          }}
          leavePage={() => productAdminUserDataCancel()} />
      }
    </>
  )
}

export default Form;

{/* <div className='fetch-group'>

  <div key={uuidv4()} className={userDataErrors.groupName ? 'setError-with-plus' : 'dropdown-user-with-plus'}>
    <DropdownComponent
      options={groupValues}
      // options={privilegeValue}
      title={"Group"}
      required={true}
      getData={(val) => {
        setProductAdminUserData((prevState) => ({
          ...prevState,
          group: val.id,
          groupName: val.value,
        }));
        const foundPrivilege = privilegeValue.find((privilegeObj) => privilegeObj.groupId === val.id);
        if (foundPrivilege) {
          setProductAdminUserData((prevState) => ({
            ...prevState,
            privilege: foundPrivilege.privilege,
            privilegeName: foundPrivilege.privilegeName,
          }));
        };
        dispatch(resetFormModified(true));
      }}
      defaultValue={[{
        label: productAdminUserData.groupName === "" ? "Select" :
          productAdminUserData.groupName, value: productAdminUserData.groupName
      }]}
    />
  </div>
  <div
    className='with-plus'
    style={
      (productAdminUserData.company === null || productAdminUserData.company === "") ?
        { pointerEvents: "none", opacity: "0.5", cursor: "auto" } : {}
    }
    onClick={() => {
      if (productAdminUserData.company === null || productAdminUserData.company === "") return;
      setComponentNavigator("Privilege-page")

    }}
  >
    <BsPlusLg color='#004E9C' height={"14px"} />
  </div>
</div>  */}