import React, { useEffect, useState } from 'react';
import RadioOrCheckbox from '../common-component/form-elements/radio-or-checkbox';
import ButtonComponent from '../common-component/form-elements/button-component';
import { MdDialpad } from 'react-icons/md';
import { ImKey } from 'react-icons/im';
import Pinsetupmodal from '../common-component/modals/pin-setup-modal';
import AlertBox from '../common-component/alert-box';
import Passwordsetupmodal from '../common-component/modals/password-setup-modal';
import Pinresetmodal from '../common-component/modals/pin-reset-modal';
import useAuth from '../../services/hooks/useauth';
import useAxiosPrivate from '../../services/hooks/useaxios-private';
import { useDispatch } from 'react-redux';
import { resetFormModified } from '../../redux/action';
import '../../styles/intial-setup/sign-in-option.scss';
import Loading from '../common-component/modals/loading-screen';

interface Props {
    setIsFormDirty: (val: any) => void;
    email: string
}

export default function SigninOption({ setIsFormDirty, email }: Props) {

    const { auth, setAuth } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const dispatch = useDispatch();

    const [pinSelectedOption, setPinSelectedOption] = useState('Password');
    const [otpSelectedOption, setOtpSelectedOption] = useState('Mobile OTP');
    const [showPinComponent, setShowPinComponent] = useState<boolean>(false);
    const [showPinResetComponent, setShowPinResetComponent] = useState<boolean>(false);
    const [showPasswordComponent, setShowPasswordComponent] = useState<boolean>(false);
    const [successnotify, setSuccessnotify] = useState<string>("");
    const [resetpasswordNotify, setResetpasswordNotify] = useState<boolean>(false);
    const [resetpinNotify, setResetpinNotify] = useState<string>("");
    const [isFactorAunthenticationChanged, setIsFactorAunthenticationChanged] = useState<boolean>(false);
    const [isPinSetup, setIsPinSetup] = useState<boolean>(false);
    const [isLocked, setIsLocked] = useState<boolean>(false);
    const [showAlertBox, setShowAlertBox] = useState<boolean>(false)  //AlertBox State
    const [showMessage, setShowMessage] = useState<string>("")   //AlertBox Message State
    const [showType, setShowType] = useState<string>("warning")   //AlertBox Type State
    const [attemptsRemaining, setAttemptsRemaining] = useState(3);
    const [loadingScreen, setLoadingScreen] = useState(false)

    // Function for Set Notification
    useEffect(() => {
        let timer: NodeJS.Timeout;

        if (successnotify) {
            timer = setTimeout(() => {
                setSuccessnotify("");
            }, 5000);
        }
        return () => {
            clearTimeout(timer);
        };
    }, [successnotify]);

    // Function for Set Notification
    useEffect(() => {
        let timer: NodeJS.Timeout;

        if (resetpasswordNotify) {
            timer = setTimeout(() => {
                setResetpasswordNotify(false);
            }, 5000);
        }
        return () => {
            clearTimeout(timer);
        };
    }, [resetpasswordNotify]);

    // Function for Get userPin Status
    useEffect(() => {
        const getUserDetails = async () => {
            try {
                const res = await axiosPrivate.get(`/user/${email}`,)
                if (res.status === 200) {
                    setIsPinSetup(res?.data.userStatus.userPinStatus);
                    if (res?.data.userStatus.firstFactorAuthentication) {
                        setPinSelectedOption(res?.data.userStatus.firstFactorAuthentication)
                    }
                    if (res?.data.userStatus.secondFactorAuthentication) {
                        setOtpSelectedOption(res?.data.userStatus.secondFactorAuthentication)
                    }
                    if (res?.data.userStatus.isLocked === "true") {
                        setIsLocked(true)
                    }
                }
            }
            catch (error) {
            }
        }
        if (email) {
            getUserDetails();
        }
    }, [email]);

    // Function for Verify OTP
    async function handlePinOtpVerification() {

        try {
            const res = await axiosPrivate.post("/otp/passwords/management", {
                "email": email
            })

            if (res.status === 200) {
                setAuth({
                    ...auth,
                    otpCountRemaining: res.data.otpRemainingCount
                })
                setAttemptsRemaining(res.data.otpRemainingCount)
                setShowAlertBox(true)
                setShowType("success")
                setShowMessage(res.data.message)


                setTimeout(() => {
                    setShowAlertBox(false);
                }, 5000);
            }
        }
        catch (error: any) {
            if (error.response.data.error) {
                setShowAlertBox(true)
                setShowType("warning")
                setShowMessage(error.response.data.error)

                setTimeout(() => {
                    setShowAlertBox(false);
                }, 5000);
            }
        }
    }

    // Function for Factor Aunthetication
    async function handleAunthenticationSave() {
        setLoadingScreen(true)
        try {
            const res = await axiosPrivate.post("/user/authentication-factor", {
                "email": email,
                "firstFactor": pinSelectedOption,
                "secondFactor": otpSelectedOption,
            })
            setIsFactorAunthenticationChanged(false);
            setIsFormDirty(false);
            dispatch(resetFormModified(false));
            setLoadingScreen(false)

            setShowAlertBox(true)
            setShowType("success")
            setShowMessage(res.data.message)

            setTimeout(() => {
                setShowAlertBox(false);
            }, 5000);
        }
        catch (error: any) {
            if (error.response.data.error) {
                setShowAlertBox(true)
                setShowType("warning")
                setShowMessage(error.response.data.error)
                setLoadingScreen(false)

                setTimeout(() => {
                    setShowAlertBox(false);
                }, 5000);
            }
        }

    }

    // Button Function for Setup And Manage PIN
    const handleSetupPin = () => {
        if (isPinSetup) {
            setShowPinResetComponent(!showPinResetComponent);
        } else {
            setShowPinComponent(!showPinComponent);
            handlePinOtpVerification()
        }
    }

    return (
        <>
            <div className='signin-option'>
                <div className='title'>
                    {"Set up two factor authentication"}
                </div>
                <div className='factor-row'>
                    <div className='first-factor'>
                        <div className='factor-row-title'>{"First Factor"}</div>
                        <div className='radio-buttons'>
                            <RadioOrCheckbox
                                type='radio'
                                value={"Password"}
                                getVal={(val) => {
                                    setPinSelectedOption(val);
                                    setIsFactorAunthenticationChanged(true)
                                    setIsFormDirty(true);
                                    dispatch(resetFormModified(true));
                                }}
                                checkedValue={pinSelectedOption}
                                name={"securityPassword"}
                                id={"Password"}
                            />
                            <RadioOrCheckbox
                                type='radio'
                                value={"PIN"}
                                getVal={(val) => {
                                    setPinSelectedOption(val);
                                    setIsFactorAunthenticationChanged(true)
                                    setIsFormDirty(true);
                                    dispatch(resetFormModified(true));
                                }}
                                name={"securityPin"}
                                checkedValue={pinSelectedOption}
                                id={"PIN"}
                                disabled={!isPinSetup}
                            />
                        </div>
                    </div>
                    <div className='second-factor'>
                        <div className='factor-row-title'>{"Second Factor"}</div>
                        <div className='radio-buttons'>
                            <RadioOrCheckbox
                                type='radio'
                                value={"Mobile OTP"}
                                getVal={(val) => {
                                    setOtpSelectedOption(val);
                                    setIsFactorAunthenticationChanged(true)
                                    setIsFormDirty(true);
                                    dispatch(resetFormModified(true));
                                }}
                                name={"MobileOtp"}
                                checkedValue={otpSelectedOption}
                                id={"Mobile OTP"}
                            />
                            <RadioOrCheckbox
                                type='radio'
                                value={"Email OTP"}
                                getVal={(val) => {
                                    setOtpSelectedOption(val);
                                    setIsFactorAunthenticationChanged(true)
                                    setIsFormDirty(true);
                                    dispatch(resetFormModified(true));
                                }}
                                name={"EmailOtp"}
                                checkedValue={otpSelectedOption}
                                id={"Email OTP"}
                            />
                        </div>
                    </div>

                </div>
                <div className='sign-in-option-container'>
                    <div className='title'>{"Sign in Options"}</div>
                    <div className='pin'>
                        <div className='left-side-contents'>
                            <MdDialpad className='sign-in-icon' />
                            <div className='sign-in-title'>
                                <span>{"PIN"}</span>
                                <span style={{ fontSize: "15px" }}>{"Set up PIN Unlock"}</span>
                            </div>
                        </div>
                        <ButtonComponent
                            title={isPinSetup ? "Manage" : "Set up"}
                            height={"5.5vh"}
                            width={"16vh"}
                            backgroundColor={"#0055D4"}
                            color={"white"}
                            // boxShadow={""}
                            className={isLocked ? "button-component disabled" : "button-component common-btn"}
                            handleClick={handleSetupPin}
                            disabled={isLocked}
                        />
                    </div>
                    <div>
                        {showPinComponent && <Pinsetupmodal
                            changeButtonType={() => {
                                setIsPinSetup(true)
                            }}
                            getUser={(val) => {
                                setShowPinComponent(val)
                            }}
                            successnotification={(value) => { setSuccessnotify(value) }}
                            isLocked={isLocked}
                            setIsLocked={setIsLocked}
                            email={email}
                        />}
                    </div>

                    <div>
                        {showPinResetComponent && <Pinresetmodal
                            getUser={(val) => { setShowPinResetComponent(val) }}
                            resetpinNotify={(value) => { setSuccessnotify(value) }}
                            isLocked={isLocked}
                            setIsLocked={setIsLocked}
                            email={email}
                        />}
                    </div>


                    <div className='password'>
                        <div className='left-side-contents'>
                            <ImKey className='sign-in-icon' />
                            <div className='sign-in-title'>
                                <span>{"Password"}</span>
                                <span style={{ fontSize: "15px" }}>{"Set up Password Unlock"}</span>
                            </div>
                        </div>
                        <ButtonComponent
                            title={"Manage"}
                            height={"5.5vh"}
                            width={"16vh"}
                            backgroundColor={"#0055D4"}
                            color={"white"}
                            // boxShadow={""}
                            className={isLocked ? "button-component disabled" : "button-component common-btn"}
                            disabled={isLocked}
                            handleClick={() => { setShowPasswordComponent(!showPinComponent); }}
                        />
                    </div>
                    <div>
                        {showPasswordComponent && <Passwordsetupmodal
                            getUser={(val) => { setShowPasswordComponent(val) }}
                            resetPasswordnotification={(value) => { setResetpasswordNotify(value) }}
                            isLocked={isLocked}
                            setIsLocked={setIsLocked}
                            email={email}
                        />}
                        {/* {showPasswordComponent && <Changesmodal />} */}

                    </div>
                </div>



                {/* Notification Component Area */}
                {successnotify && <AlertBox type={"success"} message={"Success! PIN registration completed successfully."} />}
                {resetpasswordNotify && <AlertBox type={"success"} message={"Success! Your password  has been changed successfully."} />}
                {resetpinNotify && <AlertBox type={"success"} message={"Success! Your PIN  has been changed successfully."} />}
                {showAlertBox && <AlertBox type={showType} message={showMessage} />}


            </div>


            <div className={"signin-option-buttons"}>
                <div className={"buttons save-Button"} >
                    <ButtonComponent
                        title={"Save"}
                        height={"50px"}
                        width={"130px"}
                        backgroundColor={"#0055D4"}
                        color={"white"}
                        className={isFactorAunthenticationChanged ? "button-component common-btn" : "button-component disabled"}
                        handleClick={handleAunthenticationSave}
                        disabled={!isFactorAunthenticationChanged}
                    />
                </div>
            </div>

            {loadingScreen && <Loading />}
        </>
    )
}
