import React, { useState } from 'react'
import { RxCross1 } from 'react-icons/rx'
import { formatAmount } from '../../../../helpers/amountRenderer';
interface props {
    clearValue: (value: any) => void;
    transactionMapping: any

}
const TransationMapping: React.FC<props> = ({ clearValue, transactionMapping }) => {
    const [paymentHistory, setPaymentHistory] = useState([]);
    return (
        <>
            <div className={"add-electricity-popup"}>
                <div className={"container-electricity"} style={{ width: "1020px", height: "415px" }} >
                    <div className={"close-icon"}>
                        <span className={"cross-icon"}>
                            <RxCross1 onClick={() => { clearValue(false) }} style={{ fontSize: "40px" }} />
                        </span>
                    </div>
                    <div className={""} >
                        <div className='view-table-component' style={{ marginTop: "20px", marginBottom: "20px", maxHeight: "210px", overflow: "auto" }}>
                            <table>
                                <thead className='vendor-payment-approval-status-head'>
                                    <th style={{ paddingLeft: "20px", width: "270px" }}>Department/ Unit/ SBU</th>
                                    <th style={{ textAlign: "right", padding: "10px" }}>Max Limit</th>
                                    <th style={{ paddingLeft: "45px",width:"270px" }}>Payments</th>
                                    <th>Expense Type</th> 
                                </thead>
                                <tbody >
                                    {transactionMapping.length === 0 ?
                                        <tr className='main-body-header'>
                                            <td></td>
                                            <td style={{ textAlign: "center" }}>No records to show</td>
                                            <td></td>
                                            <td></td>

                                        </tr>
                                        :
                                        transactionMapping.map((data: any, index: number) => ( 
                                            <tr className='main-body-header' key={index}>
                                                <td style={{ paddingLeft: "20px" }} >{data.transactionMappingDepartmentName}</td>
                                                <td style={{ textAlign: "right", padding: "10px" }}>{formatAmount(data.transactionMappingMaxLimit)}</td>

                                                <td style={{ paddingLeft: "45px",width:"270px" }}>{data.transactionMappingPayments}</td>
                                                <td>{data.transactionMappingExpense}</td>

                                            </tr>
                                        ))
                                    }

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}
export default TransationMapping;