import React from "react";

const CustomEllipsisRenderer: React.FC<any> = (params: any) => {
    const value = params.value || "";
    const maxLength = 30; // Maximum characters allowed before truncation

    let displayValue = value;
    if (value.length > maxLength) {
        displayValue = value.substring(0, maxLength) + "...";
    }

    const cellStyle: React.CSSProperties = {
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden",
        paddingRight: "3px",
    };

    return <div style={cellStyle}>{displayValue}</div>;
};

export default CustomEllipsisRenderer;




export const userColumnData = [
    {
        field: 'selection', headerName: '', minWidth: 60, maxWidth: 60, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, headerCheckboxSelection: true, checkboxSelection: true, showDisabledCheckboxes: true, suppressRowTransform: true, cellStyle: { textAlign: 'left' }
    },
    {
        field: 'organizationName', headerName: 'Organisation', minWidth: 250, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer,
    },
    {
        field: 'companyName', headerName: 'Company', minWidth: 250, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer,
    },
    {
        field: 'groupName', headerName: 'Group', minWidth: 250, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer,
    },
    {
        field: 'departmentName', headerName: 'Department', minWidth: 250, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer,
    },
    // {
    //     field: 'privilegeName', headerName: 'Privilege', minWidth: 250, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype:"text", cellRendererFramework: CustomEllipsisRenderer,
    // },

    {
        field: 'name', headerName: 'Name', minWidth: 250, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer,
    },
    {
        field: 'mobileNumber', headerName: 'Contact No', minWidth: 250, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "number", cellRendererFramework: CustomEllipsisRenderer,
    },
    {
        field: 'email', headerName: 'Email ID', minWidth: 250, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer,
    },
    {
        field: 'reportingToName', headerName: 'Reporting To', minWidth: 250, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer,
    },
    {
        field: 'ifscCode', headerName: 'IFSC code', minWidth: 250, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer,
    },
    {
        field: 'regionName', headerName: 'Region', minWidth: 250, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer,
    },
    {
        field: 'stateName', headerName: 'State', minWidth: 250, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer,
    },
    {
        field: 'cityName', headerName: 'City', minWidth: 250, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer,
    },
    {
        field: 'branchName', headerName: 'Branch', minWidth: 250, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer,
    },
    {
        field: 'userIsActive', headerName: 'Is Active', minWidth: 250, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer,
    },
    {
        field: 'isAccountLocked', headerName: 'Is Account Locked', minWidth: 250, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer,
    },
]

// sanmple row data 
export const rowDatathis = [
    {
        branch: "1",
        branchName: "ghji",
        city: "1",
        cityName: "weqrffxgvtrnuery8o,mouio,u9,oweq",
        company: "1yumo57ymoo8'l/k.,cfujhbdyfuneriyumnetyniktu767",
        companyName: "wf7jr67j8k857m8irghnjfhigumryuiert",
        email: "awetyft@uiuyguguyhygyuuy.jhvghtdftyy",
        group: "1",
        groupName: "fwtwqe6",
        ifscCode: "sga",
        isAccountLocked: "No",
        isAccountLockedRemarks: "erft",
        mobileNumber: "ty",
        name: "fsdht",
        organization: "jfgjgjdtjdtujdrtuerwaygfvsd6ryqe5hybwu",
        organizationName: "jfgjgjdtjdtujdrtuerwaygfvsd6ryqe5hybwu",
        privilege: "sdfgsgyne6ri",
        privilegeName: "mrt79,i9r69,.7y0r7.0",
        region: ",tip.t9u8[-tm9p[m68op.tu",
        regionName: "dfhsgvkrthuqieuytqbwu6toqwe846bqeur@guyurhjghjbf",
        reportingTo: "",
        reportingToName: "ghrtutw4uer578",
        state: "",
        stateName: "",
        userIsActive: "Yes",
        userIsActiveRemarks: ""
    },

]


let privilage = {
    "message": "Privilege Datas retrieved successfully.",
    "privilegeDatas": [
        {
            "privilegeId": 1,
            "privilegeName": "Maker"
        },
        {
            "privilegeId": 2,
            "privilegeName": "Admin"
        },
        {
            "privilegeId": 3,
            "privilegeName": "Checker"
        },
        {
            "privilegeId": 4,
            "privilegeName": "Recommender"
        },
        {
            "privilegeId": 5,
            "privilegeName": "Approver"
        },
        // {
        //     "privilegeId": 6,
        //     "privilegeName": "Master data creators"
        // },
        {
            "privilegeId": 7,
            "privilegeName": "Report viewer"
        },
        // {
        //     "privilegeId": 9,
        //     "privilegeName": "Master data authorizer"
        // }
    ],
    "orgAdminPrivilegeDatas": [
        {
            "privilegeId": 2,
            "privilegeName": "Admin"
        },
        {
            "privilegeId": 11,
            "privilegeName": "BI Reports"
        }
    ],
    "ProductadminPrivilegeDatas": [
        {
            "privilegeId": 2,
            "privilegeName": "Admin"
        },
        {
            "privilegeId": 11,
            "privilegeName": "BI Reports"
        },
        {
            "privilegeId": 12,
            "privilegeName": "Customer authorizer"
        },
        {
            "privilegeId": 13,
            "privilegeName": "Customer maker"
        }
    ]
}