import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import { ReactComponent as Vendorpayment } from "../../assets/icons/vendor-payment.svg";
import { ReactComponent as Utility } from "../../assets/icons/utility.svg";
import { ReactComponent as Directtax } from "../../assets/icons/directtax.svg";
import "../../styles/payment-reminder.scss";

interface Props {
  paymentTitle: string;
  paymentAmount: any;
  background: string;
}

// example  background={'linear-gradient(247deg, #0055D4 10%, #011D60 100%)'}

export default function PaymentReminder({
  paymentTitle,
  paymentAmount,
  background
}: Props) {
  let iconName: any;
  switch (paymentTitle) {
    case "GST":
      iconName = "%"
      break;
    case "Vendor Payment":
      iconName = <Vendorpayment />
      break;
    case "Utility":
      iconName = <Utility />
      break;
    case "Direct Tax":
      iconName = <Directtax />
      break;
    default:
      break;
  }
  const formatAmount = (amount: any) => {
    if (amount && amount !== ".") {
      const onlyNumber = amount.toString().replace(/[^0-9.]/g, "");
      // Handle empty input
      if (onlyNumber === "") {
        return "--";
      }
      // Split input into integer and decimal parts
      const parts = onlyNumber.split(".");
      const integerPart = parts[0];
      const decimalPart = parts[1] || "";
      // Format the integer part with commas
      const formattedInteger = parseFloat(integerPart).toLocaleString('en-IN');
      // Handle complete decimal input (e.g., "5000.50")
      if (decimalPart !== "") {
        return `${formattedInteger}.${decimalPart}`;
      }
      // Handle incomplete decimal input (e.g., "5000.")
      if (amount.toString().endsWith(".")) {
        return `${formattedInteger}.`;
      }
      // Return formatted integer
      return formattedInteger;
    }
    return "--";
  };
  return (
    <div className="payment-reminder">
      <div className="amount-details" style={{ background }}>
        <div className="icon">
          <div>
            {iconName}
          </div>
        </div>
        <div className="content">
          <span className="detail">{paymentTitle}</span>
          <span className="detail">{`₹${formatAmount(paymentAmount)}`}</span>
          <span>{"as of today"}</span>
        </div>
      </div>
      <div className="view-details">
        <div>
          {"View Details "}
          <HiOutlineArrowNarrowRight style={{ fontSize: "20px" }} />
        </div>
      </div>
    </div>
  );
}