import React from 'react'
import TallyMain from './tally-main'

function Accounts() {
    return (
        <div>
            <TallyMain /> 
        </div>
    )
}

export default Accounts;