// import MastercardImg from '../../../assets/images/mastercard-img.png';
import VisaImg from '../../../assets/images/visa-img.png';
// import img from '../assets/images/mastercardimg.png';
import '../../../styles/card-panel.scss';
import masterCardImg from "../../../assets/images/mastercard-img.png";
import visaCardImg from "../../../assets/images/visa-img.png";
import rupayCardImg from "../../../assets/images/rupay-img.png";
import amexCardImg from "../../../assets/images/amex-img.png";

interface Props {
    bankName?: string;
    percentage?: number;
    limit?: number;
    insideCardBalance?: number;
    lastBilledDue?: number;
    cardNumber?: number;
    cardNetwork?: string;
    dueIn?: number | string;
    billingCycle?: string;
    processing?: number;
    outsideCardBalance?: number;
    backgroundColor?: string;
}


export default function CardPanel({ percentage, limit, cardNetwork, insideCardBalance, lastBilledDue, dueIn, cardNumber, billingCycle, processing, outsideCardBalance, backgroundColor, bankName }: Props) {
    const formatAmount = (amount: any) => {
        if (amount && amount !== ".") {
            const onlyNumber = amount.toString().replace(/[^0-9.]/g, "");
            // Handle empty input
            if (onlyNumber === "") {
                return "--";
            }
            // Split input into integer and decimal parts
            const parts = onlyNumber.split(".");
            const integerPart = parts[0];
            const decimalPart = parts[1] || "";
            // Format the integer part with commas
            const formattedInteger = parseFloat(integerPart).toLocaleString('en-IN');
            // Handle complete decimal input (e.g., "5000.50")
            if (decimalPart !== "") {
                return `${formattedInteger}.${decimalPart}`;
            }
            // Handle incomplete decimal input (e.g., "5000.")
            if (amount.toString().endsWith(".")) {
                return `${formattedInteger}.`;
            }
            // Return formatted integer
            return formattedInteger;
        }
        return "--";
    };
    return (
        <div className='card-panel'>
            <div className='percentage' >
                <p className='value' style={{ backgroundColor }}>{`${percentage}%`}</p>
            </div>
            <div className={`card ${bankName}`}>
                <div className='chip'>
                    <div className='chipImg'>
                    </div>
                    <div style={{ borderRight: "2px solid white" }}>
                        <div className='card-title'>{"Limit"}</div>
                        <div>{`₹${formatAmount(limit)}`}</div>
                    </div>
                    <div style={{ textAlign: "right" }}>
                        <div className='card-title'>{"Balance"}</div>
                        <div>{`₹${formatAmount(insideCardBalance)}`}</div>
                    </div>
                </div>
                <div className='card-number'>{`${cardNumber}`}</div>
                <div className='due-details'>
                    <div>
                        <span className='card-title'>{"Last Billed Due"}</span>
                        <span>{`₹${lastBilledDue}`}</span> 
                    </div>
                    <div>
                        <span className='card-title'>
                            {"Due In"}
                        </span>
                        <span>
                            {/* {"10 Days (8 Oct)"} */}
                            {`${dueIn}`}
                        </span>
                    </div>
                    <div className='mastercard-img'>

                        {
                            cardNetwork === "3" &&
                            <img src={amexCardImg} />}
                        {
                            cardNetwork === "4" &&
                            <img src={visaCardImg} />
                        }
                        {
                            cardNetwork === "5" &&
                            <img src={masterCardImg} />
                        }
                        {
                            cardNetwork === "6" &&
                            <img src={rupayCardImg} />
                        }

                    </div>
                </div>
            </div>
            <div className='card-statement' style={{ backgroundColor }}>
                <div className='billingCycle'>
                    <span style={{ fontSize: "15px" }}>{"Billing Cycle"}</span>
                    <span style={{ fontSize: "13px" }}>{billingCycle}</span>
                </div>
                <div className='processing'>
                    <span style={{ fontSize: "15px" }}>{"Processing"}</span>
                    <span style={{ fontSize: "13px" }}>{`₹${formatAmount(processing)}`}</span>
                </div>
                <div className='balance'>
                    <span style={{ fontSize: "15px" }} >{"Balance"}</span>
                    <span style={{ fontSize: "13px" }}>{`₹${formatAmount(outsideCardBalance)}`}</span>
                </div>
            </div>
        </div>
    )
}
