import React, { useEffect, useState, forwardRef, useImperativeHandle, useCallback } from 'react'

import { RxCross1 } from 'react-icons/rx';
import InputComponent from '../../../common-component/form-elements/input-component';
import DropdownComponent from '../../../common-component/form-elements/dropdown-component';
import RadioOrCheckbox from '../../../common-component/form-elements/radio-or-checkbox';
import ButtonComponent from '../../../common-component/form-elements/button-component';
import AlertBox from '../../../common-component/alert-box';
import { v4 as uuidv4 } from 'uuid'
import { axiosPrivate } from '../../../../middleware/axois-api';
import { mainElectricityConfig } from '../../../../config/electricity/electricity-board-config';


interface LinkAccountProps {
    clearValue: (value: any) => void;
    getAccountGrid: () => void;
    setElectricityAccount: (value: any) => void;
    electricityAccount: any;
    editIndex: any;
    isAccountEditMode: any;
    setBoardinput: (value: any) => void;
    setIsAccountEditMode: (value: boolean) => void;
    boardinput: []
}
export interface userValidationRulesInterface {
    [key: string]: {
        [key: string]: {
            regex: RegExp | string;
            field: any;
            shouldNotBe: string;
        };
    }
}
interface DropdownItem {
    label: string;
    value: string;
}

const LinkAccount: React.FC<LinkAccountProps> = ({ clearValue, setElectricityAccount, getAccountGrid, setIsAccountEditMode, isAccountEditMode, electricityAccount, editIndex, boardinput, setBoardinput }) => {
    const [userDataErrors, setUserDataErrors] = useState<Record<string, boolean>>({});
    const [showType, setShowType] = useState("warning") // error message showType
    const [showMessage, setShowMessage] = useState<string>("") // error message showMessage
    const [showAlertBox, setShowAlertBox] = useState(false) // error message ShowAlertBox
    // const [isAccountEditMode, setIsAccountEditMode] = useState<boolean>(false)
    const [addressState, setAddressState] = useState<any[]>([]);
    const [dropDownState, setDropDownState] = useState<DropdownItem[]>([])
    const [boards, setBoards] = useState<DropdownItem[]>([])


    useEffect(() => {
        fetchData("state", setDropDownState, "states", "stateId", "name");
    }, [])
    useEffect(() => {
        console.log(electricityAccount)
    }, [electricityAccount])

    const userValidationRules: userValidationRulesInterface = {
        virtualCard_form: {
            AccountName: {
                regex: "",
                field: "mandatory",
                shouldNotBe: ""
            },
            employeeId: {
                regex: /^[a-zA-Z0-9/]*$/,
                field: "mandatory",
                shouldNotBe: ""
            },
            department: {
                regex: /^[a-zA-Z][a-zA-Z /]*$/,
                field: "mandatory",
                shouldNotBe: ""
            },
            designation: {
                regex: /^[a-zA-Z][a-zA-Z /]*$/,
                field: "mandatory",
                shouldNotBe: ""
            },

            emailId: {
                regex: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/,
                field: "mandatory",
                shouldNotBe: ""
            },
            contactNumber: {
                regex: /^[0-9]{10}$/,
                field: "mandatory",
                shouldNotBe: ""
            },
            remark: {
                regex: /^[^\s].*/,
                field: " ",
                shouldNotBe: ""
            },

        }
    }


    //  clearAleart
    const clearAleart = (status: any) => {
        const timer = setTimeout(() => {
            setShowAlertBox(false);
            setShowMessage("");
            if (status === 200) {
                clearValue(false)
            }
            clearTimeout(timer);
        }, 5000);
    }

    useEffect(() => {
        fetchAddressValues()
    }, [])

    // get State API
    const fetchAddressValues = async () => {
        try {
            const response = await axiosPrivate.get("state");
            if (response.status === 200) {
                const dropdownValues = response.data.states.map((item: any) => ({
                    label: item.name,
                    value: item.name,
                    id: item.stateId,
                }));
                setAddressState(dropdownValues);
            }
        } catch (error) {
        }
    };

    // Save AccountDetails API 
    const saveAccount = async () => {
        try {
            const response = isAccountEditMode ?
                await axiosPrivate.patch(`utility/electricity/update/account/${editIndex}`, {
                    electricityAccount
                }) : await axiosPrivate.post("/utility/electricity/create/account", { electricityAccount });
            if (response.status === 200) {
                setIsAccountEditMode(false)
                setElectricityAccount({
                    accountName: "",
                    state: "",
                    stateId: "",
                    electricityBoard: "",
                    electricityBoardId: "",
                    isActive: "Yes",
                    remarks: ""
                })
                getAccountGrid()
                setBoardinput([])
                clearAleart(response.status)
                setShowAlertBox(true);
                setShowType("success");
                setShowMessage(response.data.message)
            }
        } catch (error: any) {
            setShowAlertBox(true);
            setShowType("danger");
            clearAleart("")
            setShowMessage(error.response.data.error)
        }
    }

    // fetch url state and city API
    const fetchData = async (url: string, setData: (data: any[]) => void, nestedProperty: string, id: any, representName: any) => {
        try {
            const response = await axiosPrivate.get(url);
            if (response.status === 200) {
                const dropdownValues = response.data[nestedProperty].map((item: any) => ({
                    label: item[representName],
                    value: item[representName],
                    id: item[id],
                }));
                const sortedData = [...dropdownValues].sort((a, b) => a.label.localeCompare(b.label));
                // { label: "Select", value: "Select", id: "" }, 
                setData([...sortedData]);

            }
        } catch (error) {
        }
    };


    return (
        <>
            <div className={"add-electricity-popup"}>
                <div className={"container-electricity"}>
                    {/* <div className={"close-icon"}>
                        <span className={"cross-icon"}>
                            <RxCross1 onClick={() => {
                                setElectricityAccount({})
                                clearValue(false)
                            }
                            } style={{ fontSize: "40px" }} />
                        </span>
                    </div> */}
                    <div>
                        <p className='Electricity-form-title'>
                            Add Electricity Details
                        </p>
                    </div>
                    <div className='Electricity-account-main'>
                        <div className='Electricity-account'>
                            <InputComponent
                                height={"40px"}
                                width={"320px"}
                                margin={"0px 60px 30px 0px"}
                                padding={"0px 0px 0px 10px"}
                                border={"1px solid #A9C3DC"}
                                borderRadius={"0px"}
                                backgroundColor={"white"}
                                color={"black"}
                                type={"text"}
                                maxLength={100}
                                autoFocus
                                inputTitle={"Account Name"}
                                placeHolder={"Enter Account Name"}
                                required={true}
                                inputValue={electricityAccount.accountName}
                                getUser={(val: any) => {
                                    setElectricityAccount({
                                        ...electricityAccount,
                                        accountName: val
                                    });

                                }}
                            />
                            <div style={isAccountEditMode ?
                                { pointerEvents: "none", opacity: 0.5 } : { pointerEvents: "auto", opacity: 1 }}>
                                <div key={uuidv4()} style={{ marginRight: "60px", marginBottom: "20px" }} >
                                    <DropdownComponent
                                        width={"320px"}
                                        required={true}
                                        title={"State"}
                                        defaultValue={[{ label: (electricityAccount?.state === undefined || electricityAccount?.state === "") ? "Select" : (electricityAccount.state), value: (electricityAccount && electricityAccount.state) }]}
                                        options={dropDownState}
                                        isDisabled={isAccountEditMode ? true : false}
                                        getData={(value: any) => {
                                            setElectricityAccount({
                                                accountName: electricityAccount.accountName,
                                                state: value.value,
                                                stateId: value.id,
                                                isActive: "Yes",
                                                remarks: "",
                                                electricityBoard: "",
                                                electricityBoardId: ""
                                            });

                                            setBoardinput([]);
                                            if (value.id == "") {
                                                setBoardinput([]);
                                            } else {
                                                fetchData(`/utility/electricity/board/get/${value.id}`, setBoards, 'electricityBoards', "electricityBoardId", "electricityBoard");
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                            <div style={isAccountEditMode ?
                                { pointerEvents: "none", opacity: 0.5 } : { pointerEvents: "auto", opacity: 1 }} >
                                <div key={uuidv4()} style={{ marginBottom: "20px", width: "320px", marginRight: "60px" }}>
                                    <DropdownComponent
                                        width={"320px"}
                                        required={true}
                                        title={"Electricity Board"}
                                        defaultValue={[{ label: (electricityAccount?.electricityBoard === undefined || electricityAccount?.electricityBoard === "") ? "Select" : (electricityAccount?.electricityBoard), value: electricityAccount?.electricityBoard === undefined ? "" : (electricityAccount?.electricityBoard) }]}
                                        options={boards}
                                        isDisabled={isAccountEditMode ? true : false}
                                        getData={(value: any) => {
                                            setElectricityAccount({
                                                accountName: electricityAccount.accountName,
                                                state: electricityAccount.state,
                                                stateId: electricityAccount.stateId,
                                                isActive: "Yes",
                                                remarks: "",
                                                electricityBoard: value.value,
                                                electricityBoardId: value.id
                                            });
                                            let findFields = mainElectricityConfig.find((obj: any) => obj.providerBiller === value.value);

                                            console.log(findFields)
                                            if (Array.isArray(findFields?.Inputs)) {
                                                setBoardinput(findFields?.Inputs);
                                            } else {
                                                setBoardinput([]);

                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div style={isAccountEditMode ?
                            { pointerEvents: "none", opacity: 0.5 } : { pointerEvents: "auto", opacity: 1 }}  >
                            <div style={{ display: "flex", flexWrap: "wrap" }} >
                                {
                                    // boardinput.length > 0 && 
                                    boardinput.map((data: any, index: number) => (
                                        <div >
                                            <InputComponent
                                                height={"40px"}
                                                width={"320px"}
                                                margin={"0px 60px 30px 0px"}
                                                padding={"0px 0px 0px 10px"}
                                                border={"1px solid #A9C3DC"}
                                                borderRadius={"0px"}
                                                backgroundColor={"white"}
                                                color={"black"}
                                                type={"text"}
                                                disabled={isAccountEditMode ? true : false}
                                                maxLength={50}
                                                inputTitle={data.title}
                                                // placeHolder={data.placeHolder}
                                                placeHolder={`Enter ${data.title}`}
                                                required={data.required}
                                                inputValue={electricityAccount[data.fieldName]}
                                                getUser={(value: any) => {
                                                    const val = value.replace(/[^0-9]/g, "")
                                                    setElectricityAccount({
                                                        ...electricityAccount,
                                                        [data.fieldName]: val,
                                                    });
                                                }}
                                            />
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                        <div className='Electricity-IsActive' >
                            <div className='radio-input vendor-form-radio-input' style={{ width: "380px" }}>
                                <div className=''>Is Active<span className='required'>*</span></div>
                                <div className='radio-btn-flex' >
                                    <RadioOrCheckbox
                                        value={"Yes"}
                                        type={"radio"}
                                        name={"Yes"}
                                        margin={"0px 30px 0px 0px"}
                                        checkedValue={electricityAccount.isActive}
                                        getVal={(value: any) => {
                                            setElectricityAccount({
                                                ...electricityAccount,
                                                isActive: value,
                                                remarks: ""
                                            });
                                            // setIsFormDirty(true)
                                            // dispatch(resetFormModified(true)); 
                                            // setRadioActive(value) 
                                        }}
                                    />
                                    {
                                        <div style={isAccountEditMode ? { pointerEvents: "auto", opacity: 1 } : { pointerEvents: "none", opacity: 0.5 }} >
                                            <RadioOrCheckbox
                                                value={"No"}
                                                type={"radio"}
                                                disabled={!isAccountEditMode ? true : false}
                                                name={"No"}
                                                checkedValue={electricityAccount.isActive}
                                                getVal={(value: any) => {
                                                    setElectricityAccount({
                                                        ...electricityAccount,
                                                        isActive: value
                                                    });
                                                    // setIsFormDirty(true)
                                                    // dispatch(resetFormModified(true));
                                                    // setRadioActive(value)
                                                }}
                                            />
                                        </div>
                                    }
                                </div>
                            </div>
                            <div style={electricityAccount.isActive === "No" ? { pointerEvents: "auto", opacity: 1, width: "550px" } : { pointerEvents: "none", opacity: 0.5, width: "550px" }} >
                                <InputComponent
                                    height={"40px"}
                                    width={"100%"}
                                    margin={"0px 0px 45px 0px"}
                                    padding={"0px 0px 0px 10px"}
                                    borderRadius={"0px"}
                                    backgroundColor={"white"}
                                    color={"black"}
                                    disabled={electricityAccount.isActive !== "No" ? true : false}
                                    type={"text"}
                                    placeHolder={"Reason"}
                                    inputTitle={"Remarks"}
                                    required={true}
                                    maxLength={250}
                                    border={"1px solid #A9C3DC"}
                                    inputValue={electricityAccount.remarks}
                                    getUser={(value: any) => {
                                        setElectricityAccount({
                                            ...electricityAccount,
                                            remarks: value
                                        });
                                        // const val = value.replace(/[^A-Za-z] /g, "")
                                        // setMainApiData({
                                        //     ...mainApiData,
                                        //     // ...mainApiData?.customerOverView,
                                        //     remarks: val,

                                        // })
                                        // setIsFormDirty(true)
                                        // dispatch(resetFormModified(true));
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='Electricity-account-footer'>
                        <ButtonComponent
                            title={"Cancel"}
                            height={"50px"}
                            width={"150px"}
                            margin={"0px 8px"}
                            backgroundColor={"#888888"}
                            color={"white"}
                            className={"button-component-hover cancel"}
                            handleClick={() => {
                                setElectricityAccount({
                                    accountName: "",
                                    state: "",
                                    stateId: "",
                                    electricityBoard: "",
                                    electricityBoardId: "",
                                    isActive: "Yes",
                                    remarks: ""
                                })
                                clearValue(false)
                            }}
                        />
                        <div>
                            <ButtonComponent
                                title={isAccountEditMode ? "Update" : "Save"}
                                height={"50px"}
                                width={"150px"}
                                backgroundColor={"#0055D4"}
                                color={"white"}
                                margin={"0px 8px"}
                                className={showType === "success" ? "button-component-hover disabled" : "button-component-hover common-btn"}
                                handleClick={() => {
                                    saveAccount()

                                }}
                            />
                        </div>

                    </div>
                </div>
                {
                    showAlertBox &&
                    <div className='alert-warp'>
                        <AlertBox type={showType} message={showMessage} />
                    </div>
                }
            </div>
        </>
    );
};

export default LinkAccount;