import '../../styles/navigate-header.scss';

interface Props{
    firstValue:string;
    secondValue:string;
    style?:any;
    navigatePage?:()=>void;
}
export default function NavigateHeader({firstValue, secondValue, style, navigatePage}:Props) {
    return (
        <div className='navigate-header-component'>
            <ul className='bread-crumbs' >
                <li><span>{firstValue}</span></li>
                <li
                onClick={()=>{
                    navigatePage && navigatePage()
                }}
                style={style}
                ><a>{secondValue}</a></li>
            </ul>
        </div>
    )
}