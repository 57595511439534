import React, { useEffect, useState, forwardRef, useImperativeHandle, } from 'react'
import InputComponent from '../../common-component/form-elements/input-component'
import DropdownComponent from '../../common-component/form-elements/dropdown-component'
import ButtonComponent from '../../common-component/form-elements/button-component'
import StatutoryDetailsGrid from '../../../config/onboarding/statutory-details-grid'
import { v4 as uuidv4 } from 'uuid'
import Loading from '../../common-component/modals/loading-screen'
import useAxiosPrivate from "../../../services/hooks/useaxios-private";
import AlertBox from '../../common-component/alert-box'
import { useDispatch, useSelector } from "react-redux";
import { resetFormModified } from "../../../redux/action";
import Changesmodal from '../../common-component/modals/changes-modal';
import DeleteModal from '../../common-component/modals/delete-modal'

// statutoryDetails
interface Props {
  statutoryDetailsMain: any;
  onboardingViewfun: (val: any) => void;
  sendDataApi: () => void;
  iconStatus: any
  editData: any
  setShowPage: (val: any) => void;
  setIconStatus: (val: any) => void;
  setStatutoryDetailsMain: (value: any) => void;
  isEditMode: boolean;

}
export interface ChildRef {
  next: (val: any) => void;
}
const StatutoryDetails = forwardRef<ChildRef, Props>(({ statutoryDetailsMain, editData, isEditMode, iconStatus, setShowPage, sendDataApi, setIconStatus, onboardingViewfun, setStatutoryDetailsMain }, ref) => {
  const initData: any = {
    document_id: "",
    CompanyTan: "",
    status: "",
    state: "",
    state_code: "",
    index: 0

  }
  const [formDataEdit, setFormDataEdit] = useState(false)
  const [currentSatutory, setCurrentSatutory] = useState<number>()
  const [formData, setFormData] = useState(initData);
  const [companyOverviewErrors, setCompanyOverviewErrors] = useState<Record<string, boolean>>({})
  const [StatutoryState, setStatutoryState] = useState<any[]>([]);
  const [noDuplicate, setNoDuplicate] = useState(false)
  const [showType, setShowType] = useState("warning") // error message showType
  const [showMessage, setShowMessage] = useState<string>("") // error message showMessage
  const [showAlertBox, setShowAlertBox] = useState(false) // error message ShowAlertBox
  const [isFormDirty, setIsFormDirty] = useState(false)
  const isFormModified = useSelector((state: any) => state.isFormModified);
  const [showChangesModal, setShowChangesModal] = useState<boolean>(false);
  const dispatch = useDispatch();
  const [currentDeletVal, setCurrentDeletVal] = useState<any>({});
  const [deleteIndex, setDeleteIndex] = useState<number>(0)
  const [showDeleteModel, setShowDeleteModel] = useState<boolean>(false)
  const [currIndex, setCurrIndex] = useState<number>()
  const userValidationRules: any = {
    document_id: {
      regex: /^[0-9]{2}[A-Z]{5}[0-9]{4}[0-9A-Z]{1}[0-9A-Z]{1}[0-9A-Z]{1}[0-9A-Z]{1}$/,
      field: "mandatory",
      shouldNotBe: ""
    },
    CompanyTan: {
      regex: formData.CompanyTan ? /^[A-Z]{4}[0-9]{5}[A-Z]{1}$/ : "",
      field: "",
      shouldNotBe: ""
    },
    state: {
      regex: "",
      field: "mandatory",
      shouldNotBe: ""
    },
    state_code: {
      regex: /^[0-9]{2}$/,
      field: "mandatory",
      shouldNotBe: ""
    },
    status: {
      regex: "",
      field: "mandatory",
      shouldNotBe: ""
    },
  }

  const updatedIsValueValidAll: Record<string, boolean> = {};
  const validateForm = (): boolean => {
    const updatedFieldValidity: Record<string, boolean> = {};
    const isMandatoryfalse = true;
    const isRegexfalse = true;
    Object.keys(userValidationRules).forEach((field) => {
      const rule = userValidationRules[field];
      const value = formData && formData[field];
      const isValueValid = (rule.field === "mandatory") ? (value ? (value !== rule.shouldNotBe) : false) : true;
      const isRegexValid = rule.regex !== "" ? new RegExp(rule.regex).test(value) : true;
      updatedFieldValidity[field] = !isValueValid || !isRegexValid;
      updatedIsValueValidAll[field] = !isValueValid;
    });
    setCompanyOverviewErrors(updatedFieldValidity);
    const isFormValid = Object.keys(updatedFieldValidity).every((keys) => {
      return !updatedFieldValidity[keys]
    });
    return isFormValid;
  };


  const axiosPrivate = useAxiosPrivate();

  useEffect(() => {
    fetchStatutoryState()
  }, [])


  const fetchStatutoryState = async () => {
    try {
      const response = await axiosPrivate.get("state");
      if (response.status === 200) {
        const dropdownValues = response.data.states.map((item: any) => ({
          label: item.name,
          value: item.name,
          id: item.stateId,
        }));
        const sortedData = [...dropdownValues].sort((a, b) => a.label.localeCompare(b.label));
        setStatutoryState(sortedData);
      }
    } catch (error) {
    }
  };

  async function duplicateCheck() {
    let finalData = {
      "document_id": formData.document_id,
      "CompanyTan": formData.CompanyTan
    }
    let statusMessage: boolean = false;
    try {
      const fieldsToCheck = ['document_id', 'CompanyTan'];
      const todisplay = ['GSTIN', 'Company Tan'];
      const failedFields: any[] = [];
      let isDuplicate = false
      if (!formDataEdit) {
        isDuplicate = statutoryDetailsMain.some((item: any) => {
          const matches = fieldsToCheck.map((field, i) => {
            if (formData[field] !== '') {
              if (item[field] === formData[field]) {
                failedFields.push(todisplay[i]);
              }
              return item[field] === formData[field];
            }
            return false;
          });
          return matches.some(match => match);
        });
      } else {
        isDuplicate = statutoryDetailsMain.some((item: any, index: any) => {
          const matches = fieldsToCheck.map((field, i) => {
            if (formData[field] && formData[field] !== '' && currIndex != index) {
              if (item[field] === formData[field]) {
                failedFields.push(todisplay[i]);
              }
              return item[field] === formData[field];
            }
            return false;
          });
          return matches.some(match => match);
        });
      }
      if (!isDuplicate) {
        const res = await axiosPrivate.post(`bank-admin/company/duplicate-checking/${currentSatutory}`, { 'statutoryDetails': finalData })
        if (res.status === 200) {
          statusMessage = true;
        }
      } else {
        statusMessage = false;
        setShowAlertBox(true)
        setShowType("danger")
        setShowMessage(`${failedFields[0]} already exists`)
        clearAlert("")
      }

    }
    catch (error: any) {
      statusMessage = false;
      setShowAlertBox(true)
      setShowType("danger")
      setShowMessage(error.response.data.error)
      clearAlert("")
    }
    return statusMessage;
  }
  const clearAlert = (status: any) => {
    const timer = setTimeout(() => {
      setShowAlertBox(false);
      setShowMessage("");
      if (status === 400) {

      }
      clearTimeout(timer);
    }, 5000);
  }


  async function handelAdd() {
    if (!validateForm()) {
      setShowAlertBox(true);
      setShowType("danger");
      setShowMessage(Object.values(updatedIsValueValidAll).every((values) => !values) ?
        "Some of the field(s) are not in required format." : "Mandatory field(s) should not be left blank.")
      clearAlert('');
      return;
    }
    let Duplicate = await duplicateCheck();
    if (!Duplicate) return;
    if (formDataEdit) {
      let data: any = []
      statutoryDetailsMain.length > 0 && statutoryDetailsMain.map((ele: any, i: number) => {
        if (i === formData.index) {
          data.push(formData)
        } else {
          data.push(ele)
        }
      })
      setStatutoryDetailsMain(data)
      setFormDataEdit(false)
      setFormData(
        {
          document_id: "",
          CompanyTan: "",
          status: "Select",
          state: "Select",
          state_code: "",
          index: 0
        }
      )
      setIsFormDirty(false);
      setShowAlertBox(true);
      setShowType("success");
      setShowMessage("Statutory Details updated successfully.")
      clearAlert('');
    }
    else {
      let newrecord = [...statutoryDetailsMain]
      newrecord.push({ ...formData })
      setStatutoryDetailsMain(newrecord)
      setFormData(
        {
          document_id: "",
          CompanyTan: "",
          status: "Select",
          state: "Select",
          state_code: "",
          index: 0
        }
      )
    }
    setIsFormDirty(false);
    setFormDataEdit(false);

  }

  function next(next: any = "") {
    if (isEditMode && statutoryDetailsMain.length > 0) {
      sendDataApi();
    } else {
      if (statutoryDetailsMain.length > 0) {
        setShowPage(next != "" ? next : "AdminDetails")
        if (next != "") {
          switch (next) {
            case "CompanyOverview":
              setIconStatus({
                ...iconStatus,
                companyIcon: "selected",
              })
              break;
            case "Address":
              setIconStatus({
                ...iconStatus,
                companyIcon: "completed",
                addressIcon: "selected",
              })
              break;
            case "DirectorsInfo":
              setIconStatus({
                ...iconStatus,
                directorIcon: "selected",
                addressIcon: "completed"
              })
              break;
            case "StatutoryDetails":
              setIconStatus({
                ...iconStatus,
                statutoryIcon: "selected",
                directorIcon: "completed"
              })
              break;
            case "AdminDetails":
              setIconStatus({
                ...iconStatus,
                adminIcon: "selected",
                statutoryIcon: "completed"
              })
              break;

            default:
              break;
          }
        } else {
          setIconStatus({
            ...iconStatus,
            statutoryIcon: "completed",
            adminIcon: "selected"
          })
        }
      }
    }
  }


  useImperativeHandle(ref, () => {
    return {
      next: next
    }
  });

  function returnGird() {
    onboardingViewfun("grid")
    dispatch(resetFormModified(false));
  }

  function retriveData(value: any, index: number) {
    setCurrIndex(index)
    setFormDataEdit(true)
    setCurrentSatutory(value.satutoryid)
    setIsFormDirty(true);
    setCompanyOverviewErrors({})
    if (Object.values(value).length !== 3) {
      setFormData(
        {
          ...value,
          index: index
        }
      )
    }

  }

  const clearAleart = () => {
    const timer = setTimeout(() => {
      setShowAlertBox(false);
      setShowMessage("");
      clearTimeout(timer);
    }, 5000);
  }


  const DirectorInfoDelete = async () => {
    if (currentDeletVal.satutoryid) {
      try {
        const response = await axiosPrivate.patch(`/bank-admin/company/delete-satutory-data/${currentDeletVal.satutoryid}`);
        if (response.status === 200) {
          setShowDeleteModel(false);
          const newArray = [...statutoryDetailsMain];
          newArray.splice(deleteIndex, 1);
          setStatutoryDetailsMain(newArray);
          setShowAlertBox(true)
          setShowType("success")
          setShowMessage(response.data.message)
          setFormData(
            {
              document_id: "",
              CompanyTan: "",
              status: "Select",
              state: "Select",
              state_code: "",
              index: 0
            }
          )
          setFormDataEdit(false)
          clearAleart()
        }
      } catch (error) {
      }
    } else if (currentDeletVal.index === 0) {
      const newArray = [...statutoryDetailsMain];
      newArray.splice(deleteIndex, 1);
      setStatutoryDetailsMain(newArray)
      setShowDeleteModel(false)
      setShowAlertBox(true)
      setShowType("success")
      setShowMessage("The selected record(s) has been deleted")
      setFormData(
        {
          document_id: "",
          CompanyTan: "",
          status: "Select",
          state: "Select",
          state_code: "",
          index: 0
        }
      )
      setFormDataEdit(false)
      clearAleart()
    }
    else if (currentDeletVal) {
      const newArray = [...statutoryDetailsMain];
      newArray.splice(deleteIndex, 1);
      setStatutoryDetailsMain(newArray)
      setShowDeleteModel(false)
      setShowAlertBox(true)
      setShowType("success")
      setShowMessage("The selected record(s) has been deleted")
      setFormData(
        {
          document_id: "",
          CompanyTan: "",
          status: "Select",
          state: "Select",
          state_code: "",
          index: 0
        }
      )
      setFormDataEdit(false)
      clearAleart()
    }
  };

  function cancel() {
    setShowPage("DirectorsInfo")
    setIconStatus({
      ...iconStatus,
      directorIcon: "selected",
    })
  }


  const postgstn = async () => {
    try {
      const res = await axiosPrivate.get(`getstate/bycompany-gstin/${(formData.document_id).substring(0, 2)}`)
      if (res.status === 200) {
        setFormData({
          ...formData,
          state: res.data.stateValue.state
        })
      }else{
        setFormData({
          ...formData,
          state: ""
        })
      }
    }
    catch (error: any) {
    }
  }
  return (
    <>
      <div className='form-wrapper'>
        <div className='form-area'>
          <div className='company-heading'>
            Statutory Details
          </div>
          <div className='form-main-wrapper'>
            <div>
              <InputComponent
                height={"40px"}
                width={"300px"}
                margin={"0px 45px 30px 0px"}
                padding={"0px 0px 0px 10px"}
                border={companyOverviewErrors && companyOverviewErrors.document_id ? "1px solid red" : "1px solid #A9C3DC"}
                borderRadius={"0px"}
                backgroundColor={"white"}
                color={"black"}
                type={"text"}
                maxLength={15}
                placeHolder={"Enter Company GSTIN"}
                inputTitle={"Company GSTIN"}
                required={true}
                inputValue={formData && formData.document_id}
                onFocusOut={postgstn}
                getUser={(val) => {
                  let value = val.toUpperCase()
                  setFormData({
                    ...formData,
                    document_id: value,
                    state_code: value.substring(0, 2)
                  })
                  setIconStatus({
                    ...iconStatus,
                    statutoryIcon: "selected"
                  })
                  setIsFormDirty(true)
                  dispatch(resetFormModified(true));
                }}
              />
            </div>
            {/* <div>
              <InputComponent
                height={"40px"}
                width={"300px"}
                margin={"0px 45px 30px 0px"}
                padding={"0px 0px 0px 10px"}
                border={companyOverviewErrors && companyOverviewErrors.CompanyTan ? "1px solid red" : "1px solid #A9C3DC"}
                borderRadius={"0px"}
                backgroundColor={"white"}
                color={"black"}
                type={"text"}
                maxLength={10}
                inputValue={formData && formData?.CompanyTan}
                placeHolder={"Enter Company TAN"}
                inputTitle={"Company TAN"}
                getUser={(val) => {
                  let value = val.toUpperCase()
                  setFormData({
                    ...formData,
                    CompanyTan: value
                  })
                  setIconStatus({
                    ...iconStatus,
                    statutoryIcon: "selected"
                  })
                  setIsFormDirty(true)
                  dispatch(resetFormModified(true));
                }}
              />
            </div> */}
            <div style={{ marginRight: "45px", marginBottom: "30px" }} key={uuidv4()} className={companyOverviewErrors.status ? 'setErrorcompany' : ""}>
              <DropdownComponent
                width={"300px"}
                required={true}
                title={"Status"}
                defaultValue={[{ label: formData?.status === (undefined || "") ? "Select" : formData.status, value: formData?.status === undefined ? "Select" : formData.status }]}
                options={[{ label: "Active", value: "Active" }, { label: "InActive", value: "InActive" }]}
                getData={(value) => {
                  setFormData({
                    ...formData,
                    status: value.value
                  })
                  setIconStatus({
                    ...iconStatus,
                    statutoryIcon: "selected"
                  })
                  setIsFormDirty(true)
                  dispatch(resetFormModified(true));
                }}
              />
            </div>
            <div style={{ marginRight: "45px", marginBottom: "30px" }} key={uuidv4()} className={companyOverviewErrors.state ? 'setErrorcompany' : ""}>
              <DropdownComponent
                width={"300px"}
                title={"Company Reg State"}
                required={true}
                defaultValue={[{ label: formData?.state === (undefined || "") ? "Select" : formData.state, value: formData && formData.state }]}
                options={StatutoryState}
                getData={(value) => {
                  setFormData({
                    ...formData,
                    state: value.value
                  })
                  setIconStatus({
                    ...iconStatus,
                    statutoryIcon: "selected"
                  })
                  setIsFormDirty(true)
                  dispatch(resetFormModified(true));
                }}
              />
            </div>
          </div>
          <div className='form-main-wrapper'>


            <div style={{ opacity: 0.5 }}>
              <InputComponent
                height={"40px"}
                width={"300px"}
                margin={"0px 45px 30px 0px"}
                padding={"0px 0px 0px 10px"}
                border={companyOverviewErrors && companyOverviewErrors.state_code ? "1px solid red" : "1px solid #A9C3DC"}
                borderRadius={"0px"}
                backgroundColor={"white"}
                color={"black"}
                type={"text"}
                inputTitle={"State Code"}
                placeHolder={"Enter State Code"}
                maxLength={2}
                required={true}
                disabled={true}
                inputValue={formData && formData.state_code}
                getUser={(value) => {
                  // const val = value.replace(/[^0-9]/g, "")
                  // setFormData({
                  //   ...formData,
                  //   state_code: val
                  // })
                  setIconStatus({
                    ...iconStatus,
                    statutoryIcon: "selected"
                  })
                  setIsFormDirty(true)
                  dispatch(resetFormModified(true));
                }}
              />
            </div>
          </div>
          <div>
            <ButtonComponent
              title={formDataEdit ? "Update" : 'Add'}
              height={"50px"}
              width={"150px"}
              backgroundColor={"#0055D4"}
              color={"white"}
              margin={"0px 8px"}
              className={"button-component-hover common-btn"}
              handleClick={() => {
                handelAdd()
              }}
            />
          </div>
          <div className={"Company-DetailsGrid"} >
            <StatutoryDetailsGrid
              statutoryDetails={statutoryDetailsMain}
              retriveData={retriveData}
              showmodal={(val: any, index: any) => {
                setShowDeleteModel(true);
                setDeleteIndex(index);
                setCurrentDeletVal(val);
              }}
            />
          </div>
          <div>
            <div className='footer-company'>
              {!isEditMode &&
                <ButtonComponent
                  title={"Cancel"}
                  height={"50px"}
                  width={"150px"}
                  margin={"0px 8px"}
                  backgroundColor={"#888888"}
                  color={"white"}
                  className={"button-component-hover cancel"}
                  handleClick={isFormModified ? () => { setShowChangesModal(true) } : returnGird}
                />
              }
              {
                isEditMode ?
                  <ButtonComponent
                    title={"Cancel"}
                    height={"50px"}
                    width={"150px"}
                    margin={"0px 8px"}
                    backgroundColor={"#888888"}
                    color={"white"}
                    className={"button-component-hover cancel"}
                    handleClick={isFormModified ? () => { setShowChangesModal(true) } : returnGird}
                  /> :
                  <ButtonComponent
                    title={"Previous"}
                    height={"50px"}
                    width={"150px"}
                    margin={"0px 8px"}
                    backgroundColor={"#888888"}
                    color={"white"}
                    className={"button-component-hover cancel"}
                    handleClick={cancel}
                  />
              }
              <ButtonComponent
                title={isEditMode ? "Update" : "Next"}
                height={"50px"}
                width={"150px"}
                backgroundColor={"#0055D4"}
                color={"white"}
                margin={"0px 8px"}
                handleClick={next}
                disabled={statutoryDetailsMain?.length == 0 || isFormDirty}
                className={statutoryDetailsMain?.length == 0 || isFormDirty ? "button-component disabled" : "button-component-hover common-btn"}
              />
            </div>
          </div>
        </div>
      </div>
      {
        showAlertBox &&
        <div className='alert-warp'>
          <AlertBox type={showType} message={showMessage} />
        </div>

      }
      {
        showChangesModal &&
        <Changesmodal
          closeModal={() => setShowChangesModal(false)}
          handleClose={() => {
            setShowChangesModal(false);
          }}
          leavePage={() => returnGird()} />
      }
      {
        showDeleteModel &&
        <div >


          <DeleteModal
            getDelete={() => { DirectorInfoDelete() }}

            clearValue={(value) => {
              setShowDeleteModel(value);
              setCurrentDeletVal({})
            }}
            modelType={"grid-delete"}

          />
        </div>
      }
    </>
  )
})
export default StatutoryDetails

