import React, { useState } from 'react'
import MainPageCard from '../../common-component/cards/main-page-card'
import NavigateHeader from '../../common-component/navigate-header'
import DropdownComponent from '../../common-component/form-elements/dropdown-component'
import { v4 as uuidv4 } from 'uuid';
import MasterDetailsReports from './masters-reports/master-details-reports';
import VendorPayableReports from './vendor-payable-reports/vendor-payable-reports';
import AlertBox from '../../common-component/alert-box';

interface Props {

}
export default function CompanyAdminReports({ }: Props) {
    const [reportsfor, setReportsfor] = useState<any>("Master Details");
    const [matsterValues, setMatsterValues] = useState<any>("Vendor");
    const [payableValues, setPayableValues] = useState<any>("Summary");
    const [showAlertBox, setShowAlertBox] = useState<boolean>(false)
    const [showMessage, setShowMessage] = useState<string>("")
    const [showType, setShowType] = useState<string>("warning")

    const ReportsValues = [{ label: "Master Details", value: "Master Details" },
    { label: "Vendor Payable", value: "Vendor Payable" }
    ]
    const masterReportsValues = [{ label: "Vendor", value: "Vendor" },
    { label: "Card", value: "Card" },
    { label: "Bank", value: "Bank" },
    { label: "Customer", value: "Customer" },
    { label: "Virtual Card", value: "Virtual Card" },
    { label: "Users", value: "Users" },
    ]
    const vendorpayableValues = [{ label: "Summary", value: "Summary" },
    { label: "Invoices Details", value: "Invoices Details" },
    { label: "Invoice Settlements", value: "Invoice Settlements" },

    ]


    return (
        <>
            <MainPageCard>
                <div className='header-main'>
                    <div className='navigater'>
                        <div className='navigate-header-component'>
                            <NavigateHeader
                                style={{ cursor: "pointer" }}
                                firstValue={"Company Admin"}
                                secondValue={"Reports"}
                                navigatePage={() => { }}
                            />
                        </div>
                    </div>
                </div>
                <div style={{ display: "flex", marginTop: "45px" }}>
                    <div className='dropdown drop-margin' key={uuidv4()} >
                        <DropdownComponent
                            options={ReportsValues}
                            title={"Reports For"}
                            required={true}
                            width='300px'
                            className={"input-select"}
                            getData={(val) => {
                                setReportsfor(val.value)
                            }}
                            defaultValue={
                                [{
                                    value: reportsfor !== "" ? reportsfor : "Master Details",
                                    label: reportsfor !== "" ? reportsfor : "Master Details",
                                }]
                            }
                        />
                    </div>
                    {
                        reportsfor === "Master Details" ?
                            <div className='dropdown drop-margin' key={uuidv4()} >
                                <DropdownComponent
                                    options={masterReportsValues}
                                    title={"Master Details"}
                                    required={true}
                                    width='300px'
                                    className={"input-select"}
                                    getData={(val) => {
                                        setMatsterValues(val.value)
                                    }}
                                    defaultValue={
                                        [{
                                            value: matsterValues !== "" ? matsterValues : "Vendor",
                                            label: matsterValues !== "" ? matsterValues : "Vendor",
                                        }]
                                    }
                                />
                            </div>
                            :
                            <div className='dropdown drop-margin' key={uuidv4()}>
                                <DropdownComponent
                                    options={vendorpayableValues}
                                    title={"Vendor Payable"}
                                    required={true}
                                    width='300px'
                                    className={"input-select"}
                                    getData={(val) => {
                                        setPayableValues(val.value)
                                    }}
                                    defaultValue={
                                        [{
                                            value: payableValues !== "" ? payableValues : "Summary",
                                            label: payableValues !== "" ? payableValues : "Summary",
                                        }]
                                    }
                                />
                            </div>
                    }
                </div>
{/* 
                {
                    reportsfor === "Master Details" &&
                    <div>
                        <MasterDetailsReports
                            matsterValues={matsterValues}

                            reportsfor={reportsfor}
                        />
                    </div>

                } */}


                {
                    reportsfor === "Vendor Payable" &&
                    <div>
                        <VendorPayableReports
                            // payableValues={payableValues}
                            // setShowAlertBox={(value: any) => setShowAlertBox(value)}
                            // setShowType={""}
                            // setShowMessage={""}
                        />
                    </div>

                }
            </MainPageCard >
            {/* {showAlertBox && <AlertBox type={showType} message={showMessage} />
            } */}

        </>
    )
}
