import React, { useEffect, useState } from 'react';
import { RxCross1 } from 'react-icons/rx';
import DropdownComponent from '../form-elements/dropdown-component';
import ButtonComponent from '../form-elements/button-component';
import TextArea from '../form-elements/text-area';
import '../../../styles/modals/document-remark-modal.scss';

interface props {
    documentDeclineStatus: any;
    clearDoumentDeclinemodal: (val: any) => void;
    closeDocument: (val: any) => void;
    setDocumentDeclineStatus: (val: any, type: string) => void;
    updateAproveStatus: () => void;
}

export default function DoumentDeclinemodal({
    clearDoumentDeclinemodal,
    closeDocument,
    documentDeclineStatus,
    setDocumentDeclineStatus,
    updateAproveStatus
}: props) {

    return (
        <div className={"document-decline-modal"}>
            <div className={"container"}>
                <div className={"close-icon"}>
                    <span className={"cross-icon"}>
                        <RxCross1
                            onClick={() => {
                                setDocumentDeclineStatus("", "remarks")
                                clearDoumentDeclinemodal(false)}}
                            style={{ fontSize: "40px" }} />
                    </span>
                </div>
                <div>
                    <div>
                        <p>Remarks</p>
                    </div>
                    <div >
                        <TextArea
                            name={""}
                            placeHolder={"Reason"}
                            width={"320px"}
                            height={"140px"}
                            margin={"10px 0px 0px 0px"}
                            padding={"10px"}
                            maxLength={250}
                            inputValue={documentDeclineStatus.Documentremarks}
                            getUser={(val) => {
                                setDocumentDeclineStatus(val, "remarks");
                            }}
                        />
                    </div>
                </div>
                <div className={"buttons"} >
                    <ButtonComponent
                        title={"Submit"}
                        height={"50px"}
                        width={"130px"}
                        backgroundColor={"#0055D4"}
                        color={"white"}
                        disabled={documentDeclineStatus.DocumentStatus === "Select" || documentDeclineStatus.Documentremarks === "" ? true : false}
                        className={documentDeclineStatus.DocumentStatus === "Select" || documentDeclineStatus.Documentremarks === "" ? "button-component disabled" : "button-component common-btn"}
                        handleClick={() => {
                            closeDocument(false)
                            updateAproveStatus()
                        }}
                    />
                </div>
            </div>
        </div>
    )
}