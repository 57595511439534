import React, { Component } from 'react'
import UtilityForm from '../../components/payable/utility/utility-form';

interface utilityValues {
    utilityNavigator: string;
    // UserPrivilege: string;
    // approvalProcessFlow: any;
    // currentActor: string;
    // loadingScreen: boolean;
    // isEditMode: boolean;
    // isBulkPayment: boolean;
    // editData: any;
}
export default class Utility extends Component<{}, utilityValues> {
    constructor(props: utilityValues) {
        super(props);
        this.state = {
            utilityNavigator: "form",
            // approvalProcessFlow: [],
            // editData: {},
            // currentActor: "-",
            // UserPrivilege: "-",
            // isEditMode: false,
            // isBulkPayment: false,
            // loadingScreen: false,
        };
    }

    render() {
        return (
            <div>
                {

                    this.state.utilityNavigator === "form" &&
                    < UtilityForm
                        userNavigateFunction={(val: string) => {
                            this.setState({
                                utilityNavigator: val,
                            })
                        }}
                        utilityNavigator={this.state.utilityNavigator}
                    />
                }

            </div>

        )
    }
}

