import React, { useEffect, useRef, useState } from 'react';
import '../../../styles/modals/document-upload-modal.scss';
import { RxCross1 } from 'react-icons/rx';
import ButtonComponent from '../form-elements/button-component';
import { LuUpload } from 'react-icons/lu';
import AlertBox from '../alert-box';
import { useDispatch, useSelector } from "react-redux";
import { resetFormModified } from "../../../redux/action";
import IconButton from '../form-elements/icon-button';

interface Props {
    getDocument: (val: any) => void;
    // buttonDisabled?: (val: any) => void;
    currentUpload: string;
    fileName: string;
}

export default function ExciseDocumentUploadModal({
    getDocument,
    // buttonDisabled,
    currentUpload,
    fileName
}: Props) {

    const fileInputRef = useRef<HTMLInputElement>(null);
    const [showAlertBox, setShowAlertBox] = useState<boolean>(false)  //AlertBox State
    const [showMessage, setShowMessage] = useState<string>("")   //AlertBox Message State
    const [showType, setShowType] = useState<string>("warning")   //AlertBox Type State

    const isFormModified = useSelector((state: any) => state.isFormModified);
    const dispatch = useDispatch();


    const dragOver = (e: any) => {
        e.preventDefault();
    }

    const dragEnter = (e: any) => {
        e.preventDefault();
    }

    const dragLeave = (e: any) => {
        e.preventDefault();
    }


    const fileDrop = (e: any) => {

        e.preventDefault();
        e.stopPropagation();

        let fileObj;

        if (e.dataTransfer) {
            const { files } = e.dataTransfer;
            fileObj = files[0];

            const allowedFormats = ["image/jpeg", "image/jpg", "image/png", "application/pdf"];
            const validFormat = allowedFormats.includes(fileObj.type);

            if (!validFormat) {
                setShowAlertBox(true);
                setShowType("warning");
                setShowMessage("Unsupported file format. Upload PDF or JPG or PNG file format.");

                setTimeout(() => {
                    setShowAlertBox(false);
                }, 5000);

                return; // Stop execution if the format is not valid
            }

            if (fileObj.size <= 2000000) {
                getDocument(fileObj)
                // buttonDisabled && buttonDisabled(true);
                fileInputRef.current!.value = '';
            } else {
                setShowAlertBox(true)
                setShowType("warning")
                setShowMessage("File size exceeds the maximum 2MB. Compress the file and upload again")

                setTimeout(() => {
                    setShowAlertBox(false);
                }, 5000);
            }
        } else {
            setShowAlertBox(true)
            setShowType("warning")
            setShowMessage("File does not uploaded")

            setTimeout(() => {
                setShowAlertBox(false);
            }, 5000);
        }
    }

    const handleUploadDocuments = () => {
        fileInputRef.current!.click();
    };

    const fileHandler = (e: any) => {
        e.preventDefault();
        const fileObj = e.target.files[0];

        const allowedFormats = ["image/jpeg", "image/jpg", "image/png", "application/pdf"];
        const validFormat = allowedFormats.includes(fileObj.type);

        if (!validFormat) {
            setShowAlertBox(true);
            setShowType("warning");
            setShowMessage("Unsupported file format. Upload PDF or JPG or PNG file format.");

            setTimeout(() => {
                setShowAlertBox(false);
            }, 5000);

            return; // Stop execution if the format is not valid
        }

        dispatch(resetFormModified(true));

        if (fileObj.size <= 2000000) {
            getDocument(fileObj)
            // buttonDisabled && buttonDisabled(true);

            if (fileInputRef.current) {
                fileInputRef.current.value = "";
            }

        } else {
            setShowAlertBox(true)
            setShowType("warning")
            setShowMessage("File size exceeds the maximum 2MB. Compress the file and upload again")

            setTimeout(() => {
                setShowAlertBox(false);
            }, 5000);
        }
    };

    return (
        <>
            <div className={"document-upload-kyc"}>
                <div
                    className={"sub-container-kyc"}
                    onDragOver={dragOver}
                    onDragEnter={dragEnter}
                    onDragLeave={dragLeave}
                    onDrop={fileDrop}
                    onClick={handleUploadDocuments}>
                    <input
                        type='file'
                        ref={fileInputRef}
                        onChange={fileHandler}
                        style={{ display: 'none' }}
                        accept='.pdf, .jpg, .jpeg, .png'
                    />
                    <div className={"upload-area-flex"} >

                        {
                            fileName ? <div style={{ margin: '10px 5px 0px 5px' }}>{`${fileName}`}</div> :
                                <>
                                    <LuUpload
                                        style={{
                                            height: "30px", width: "30px", fontSize: "30px",
                                            margin: "5px 0px 0px 8px", color: "#0055D4",
                                            backgroundColor: "white"
                                        }} />

                                    <div style={{ margin: '10px 5px 0px 5px' }}>Drop items here or <span className={"browse-files"}>Browse Files</span></div>
                                </>
                        }

                      </div>
                </div>
                <div className={"notes"}>
                    {"Formats allowed: JPG or PNG or PDF. Maximum file size allowed: 2MB"}
                </div>
            </div>
            {showAlertBox && <AlertBox type={showType} message={showMessage} />}
        </>
    )
}


