import React, { useEffect, useState, forwardRef, useImperativeHandle, } from 'react'
import InputComponent from '../../../common-component/form-elements/input-component'
import RadioOrCheckbox from '../../../common-component/form-elements/radio-or-checkbox'
import { ReactComponent as Previous } from '../../../../assets/icons/next.svg'
import { ReactComponent as Next } from '../../../../assets/icons/previous.svg'
import { ReactComponent as Correct } from '../../../../assets/icons/plan_correct.svg'
import { ReactComponent as Close } from '../../../../assets/icons/plan_close.svg'
import ButtonComponent from '../../../common-component/form-elements/button-component'
import moment from 'moment'
import SubscriptionValidityGrid from '../../../../config/onboarding/subscription-validity-grid'
import { useDispatch, useSelector } from "react-redux";
import { resetFormModified } from "../../../../redux/action";
import { axiosPrivate } from '../../../../middleware/axois-api'
import AlertBox from '../../../common-component/alert-box'
interface props {
  onboardingViewfun: (val: any) => void;
  setSubscriptionMain: (val: any) => void;
  subscriptionMain: any;
  editData: any;
  sendDataApi: () => void;
  isEditMode: boolean;
  iconStatus: any;
  setIconStatus: (val: any) => void;
  setShowPage: (val: any) => void;
}
export interface ChildRef {
  next: () => void;
}

const SubscriptionValidity = forwardRef<ChildRef, props>(({ onboardingViewfun, setShowPage, setIconStatus, iconStatus, setSubscriptionMain, editData, subscriptionMain, sendDataApi, isEditMode }, ref) => {
  const initData = {
    from: "",
    to: "",
    isActive: "",
    remarks: "",
    generated_key: "",
    Subscription: "",
    index: 0,
  }
  const [subscriptionData, setSubscriptionData] = useState(initData)
  const [subscription, setSubscription] = useState("Silver")
  const [currentIndex, setCurrentIndex] = useState<number>()
  const [formDataEdit, setFormDataEdit] = useState(false)
  const [showType, setShowType] = useState("warning") // error message showType
  const [showMessage, setShowMessage] = useState<string>("") // error message showMessage
  const [showAlertBox, setShowAlertBox] = useState(false) // error message ShowAlertBox
  const [disableState, setDisableState] = useState(true)
  const [subscriptionState, setSubscriptionState] = useState(false)
  const [isFormDirty, setIsFormDirty] = useState(false)
  const isFormModified = useSelector((state: any) => state.isFormModified);
  const [licenseKeyValue, setLicenseKeyValue] = useState<any>()
  const dispatch = useDispatch();
  const todayDate = moment(new Date()).format('YYYY-MM-DD');
  const [mindate, setMindate] = useState<any>();
  useEffect(() => {
    setSubscriptionData({
      ...subscriptionData,
      isActive: "Yes"
    })

  }, [])
  useEffect(() => {
    if (subscriptionMain) {
      let activePlan = subscriptionMain.find((item: any) => item.isActive === "Yes");
      setSubscriptionData({
        from: activePlan?.from,
        to: activePlan?.to,
        isActive: activePlan?.isActive,
        remarks: activePlan?.remarks,
        generated_key: activePlan?.generated_key,
        Subscription: activePlan?.Subscription,
        index: 0,
      })
    }
    // getSubscriptionAll();
  }, [subscriptionMain])


  function next() {

    if (isEditMode) {
      sendDataApi();
    } else {
      setShowPage("ApprovalProcess")
      setIconStatus({
        ...iconStatus,
        subscriptionIcon: "completed",
        approvalIcon: "selected",
      })
    }
  }

  useImperativeHandle(ref, () => {
    return {
      next: next
    }
  });
  const clearAlert = (status: any) => {
    const timer = setTimeout(() => {
      setShowAlertBox(false);
      setShowMessage("");
      if (status === 400) {

      }
      clearTimeout(timer);
    }, 5000);
  }



  function update() {
    if (isEditMode) {
      sendDataApi();
      setIconStatus({
        ...iconStatus,
        subscriptionIcon: "completed",
      })

    } else {
      sendDataApi();
      onboardingViewfun("grid");
      setIconStatus({
        ...iconStatus,
        subscriptionIcon: "completed",
      })
    }
  }


  function retriveData(value: any, index: number) {

    setCurrentIndex(index)
    setFormDataEdit(true)
    setSubscription(value.Subscription)
    if (Object.values(value).length !== 3) {
      setSubscriptionData({
        ...subscriptionData,
        ...value.Subscription,

      })
    }
  }

  useEffect(() => {
    if (subscriptionData?.from !== "" &&
      subscriptionData?.to !== "" &&
      subscriptionData?.remarks !== "" &&
      subscriptionData?.generated_key !== "" &&
      subscriptionState
    ) {
      setDisableState(false)
    } else {
      setDisableState(true)
    }
  }, [subscriptionData, subscriptionState])


  async function handelAddButton() {


    if (subscriptionData.generated_key) {
      await axiosPrivate.post(`/customeronboarding/store-subscription-data/key`, {
        "generated_key": subscriptionData.generated_key
      })
        .then(response => {
          setSubscriptionData(response.data);
          getSubscriptionAll();
          setShowAlertBox(true);
          setShowType("success");
          setShowMessage("License Key Successfully Added.");
          clearAlert('');
        })
        .catch(error => {
          console.error(error);
          setShowAlertBox(true);
          setShowType("danger");
          setShowMessage("Subscription Data Not Found.");
          clearAlert('');
        });
    } else {
      setShowAlertBox(true);
      setShowType("danger");
      setShowMessage("License Key should not be empty.")
      clearAlert('');
    }

  }
  async function getSubscriptionAll() {
    await axiosPrivate.get(`company-approval/subscription-added`, {
    })
      .then(response => {
        setSubscriptionMain(response.data);
      })
      .catch(error => {
        console.error(error);
      });
  }

  const handleFromDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(resetFormModified(true));
    setMindate(moment(e.target.value).format("YYYY-MM-DD"))
    setSubscriptionData({
      ...subscriptionData,
      Subscription: subscription,
    })


    const fromDate = e.target.value;

    setSubscriptionData((prevsubscriptionData: any) => ({
      ...prevsubscriptionData,
      from: fromDate,
      to: ""
    }));
  };

  const handleToDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(resetFormModified(true));

    const toDate = e.target.value;
    setSubscriptionData((prevsubscriptionData: any) => ({
      ...prevsubscriptionData,
      to: toDate
    }));

  };
  return (
    <div className='form-wrapper'>
      <div className='form-area'>
        <div className='flex-form-subscription'>
          <div className='form-subscription'>
            <div className='company-heading'>
              Subscription Validity
            </div>
            <div className='subscription-main' style={{ opacity: "0.4", pointerEvents: "none" }}>
              <div className={`${subscriptionState ? "subscription-border-active" : "subscription-border"}`} >
                {/* <div
                  className={`previous-main ${subscription !== "Platinum" ? "" : "disabled"}`}
                  onClick={() => { 
                    if (subscription == "Platinum") return;
                    setSubscription(prevSubscription => {
                      if (prevSubscription === "Silver") return "Gold";
                      if (prevSubscription === "Gold") return "Platinum";
                      return "Silver";
                    });
                  }}
                >
                </div> */}
                <div className={`${subscriptionState ? "silver-class-active" : "silver-class"}`}>
                  <p className='silver-text' >{subscriptionData?.Subscription}</p>
                </div>
                <div className='feature-main'
                  onClick={() => { setSubscriptionState(!subscriptionState) }}>
                  <div><b>Features</b></div>
                  <div>
                    {subscriptionData?.Subscription === "Silver" || subscriptionData?.Subscription === "Gold" || subscriptionData?.Subscription === "Platinum" ? (
                      <Correct />
                    ) : (
                      <Close />
                    )}
                    BI Reports
                  </div>
                  <div>
                    {subscriptionData?.Subscription === "Gold" || subscriptionData?.Subscription === "Platinum" ? (
                      <Correct />
                    ) : (
                      <Close />
                    )}
                    Virtual
                  </div>
                  <div>
                    {subscriptionData?.Subscription === "Platinum" ? (
                      <Correct />
                    ) : (
                      <Close />
                    )}
                    Cross Bountry
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='form-width'>
            <div className='license-key-company'>
              <div className='input-btn'>
                <InputComponent
                  height={"50px"}
                  width={"470px"}
                  margin={"0px 0px 30px 0px"}
                  padding={"0px 0px 0px 10px"}
                  border={"1px solid #A9C3DC"}
                  borderRadius={"0px"}
                  backgroundColor={"white"}
                  color={"black"}
                  type={"text"}
                  required={true}
                  inputTitle={"License Key"}
                  placeHolder={"Enter License Key"}
                  points={""}
                  maxLength={100}
                  inputValue={subscriptionData && subscriptionData?.generated_key}
                  getUser={(value: any) => {
                    const val = value.replace(/[^A-Za-z0-9-]/g, "")
                    setSubscriptionData({
                      ...subscriptionData,
                      generated_key: val
                    })
                    setIconStatus({
                      ...iconStatus,
                      subscriptionIcon: "selected",
                    })
                    dispatch(resetFormModified(true));
                  }}
                />
              </div>
              <div style={{ marginTop: "20px", marginBottom: "20px" }}>
                <ButtonComponent
                  title={'Add'}
                  height={"50px"}
                  width={"150px"}
                  backgroundColor={"#0055D4"}
                  color={"white"}
                  margin={"5px 0px 0px 20px"}
                  className={"button-component-hover common-btn"}
                  handleClick={() => {
                    handelAddButton()
                  }}

                />
              </div>
            </div>
            <div className='form-main-wrapper' style={{ opacity: "0.4", pointerEvents: "none" }}>
              <div style={{ marginRight: "45px" }}>
                <div className='title'>Valid From<span className='required'>*</span></div>
                <input
                  type='date'
                  className='date-picker'
                  min={todayDate}
                  disabled={true}
                  value={subscriptionData && subscriptionData?.from && moment(subscriptionData?.from).format('YYYY-MM-DD')}
                  onChange={handleFromDateChange}
                />
              </div>
              <div>
                <div className='title'>Valid To<span className='required' >*</span></div>
                <input
                  type='date'
                  className='date-picker'
                  disabled={true}
                  min={mindate}
                  value={subscriptionData && subscriptionData?.to && moment(subscriptionData?.to).format('YYYY-MM-DD')}
                  onChange={handleToDateChange}
                />
              </div>
            </div>
            <div className='radio-input organisation-radio-input margin-isactive' style={{ opacity: "0.4", pointerEvents: "none" }}>
              <div className=''>Is Active<span className='required'>*</span></div>
              <div className='radio-btn-flex' >
                <RadioOrCheckbox
                  value={"Yes"}
                  name={"Yes"}
                  type={"radio"}
                  disabled={true}
                  margin={"0px 30px 0px 0px"}
                  checkedValue={subscriptionData?.isActive}
                  getVal={(val) => {
                    setSubscriptionData({
                      ...subscriptionData,
                      isActive: val
                    })
                    setIconStatus({
                      ...iconStatus,
                      subscriptionIcon: "selected",
                    })
                    dispatch(resetFormModified(true));

                  }}
                />
                {
                  <RadioOrCheckbox
                    value={"No"}
                    name={"No"}
                    type={"radio"}
                    disabled={true}
                    checkedValue={subscriptionData?.isActive}
                    getVal={(val) => {
                      setSubscriptionData({
                        ...subscriptionData,
                        isActive: val
                      })
                      setIconStatus({
                        ...iconStatus,
                        subscriptionIcon: "selected",
                      })
                      dispatch(resetFormModified(true));

                    }}
                  />
                }
              </div>
            </div>
            <div className='input-btn' style={{ opacity: "0.4", pointerEvents: "none" }}>
              <InputComponent
                height={"40px"}
                width={"650px"}
                margin={"0px 0px 30px 0px"}
                padding={"0px 0px 0px 10px"}
                border={"1px solid #A9C3DC"}
                borderRadius={"0px"}
                backgroundColor={"white"}
                color={"black"}
                type={"text"}
                inputTitle={"Remarks"}
                disabled={true}
                required={true}
                placeHolder={"Reason"}
                points={""}
                maxLength={100}
                inputValue={subscriptionData && subscriptionData?.remarks}
                getUser={(value: any) => {
                  const val = value.replace(/[^A-Za-z]/g, "")
                  setSubscriptionData({
                    ...subscriptionData,
                    remarks: val
                  })
                  setIconStatus({
                    ...iconStatus,
                    subscriptionIcon: "selected",
                  })
                  dispatch(resetFormModified(true));

                }}
              />
            </div>
          </div>
        </div>
        <div style={{ marginRight: "30px" }}>
          <SubscriptionValidityGrid
            subscriptionMain={subscriptionMain}
            retriveData={retriveData}
          />
        </div>
        <div className='footer-company'>
          <div >
            <ButtonComponent
              title={isEditMode ? "Update" : "Save"}
              height={"50px"}
              width={"150px"}
              backgroundColor={"#0055D4"}
              color={"white"}
              margin={"0px 8px"}
              handleClick={update}
              disabled={subscriptionMain && subscriptionMain?.length === 0}
              className={subscriptionMain && subscriptionMain?.length === 0 ? "button-component disabled" : "button-component-hover common-btn"}
            // className={"button-component-hover common-btn"}
            />
          </div>
        </div>
      </div>
      {
        showAlertBox &&
        <div className='alert-warp'>
          <AlertBox type={showType} message={showMessage} />
        </div>
      }
    </div>

  )
})
export default SubscriptionValidity
