import React, { Component } from 'react'
import Form from '../../components/organisation/bank/form'
import Grid from '../../components/organisation/bank/grid'


interface UsersValues {
  userNavigate: string;
  isEditMode: boolean;
  editData: {};
}

class Bank extends Component<{}, UsersValues> {
  constructor(props: UsersValues) {
    super(props);
    this.state = {
      userNavigate: "grid",
      isEditMode: false,
      editData: {}

    };
  }

  render() {
    return (
      <div>
        {
          this.state.userNavigate === "form" &&
          <Form
            getEditMode={(val) =>
              this.setState({
                isEditMode: val
              })
            }
            userNavigateFunction={(val: string) => {
              this.setState({
                userNavigate: val,
              })
            }}

            editData={this.state.editData}
            isEditMode={this.state.isEditMode}
            userNavigate={this.state.userNavigate}
            modeFunction={(val: boolean) => {

              this.setState({
                isEditMode: val
              })
            }}
          />
        }
        {
          this.state.userNavigate === "grid" &&
          <Grid
            getEditMode={(val) =>
              this.setState({
                isEditMode: val
              })
            }
            userNavigateFunction={(val: string) => {
              this.setState({
                userNavigate: val,
              })
            }}
            modeFunction={(val: boolean) => {
              this.setState({
                isEditMode: val
              })
            }}

            getEditData={(name: string, data: any,) => {
              this.setState({
                userNavigate: "form",
                editData: { ...data },
                isEditMode: true,
              })
            }
            }

          />
        }
      </div>
    )
  }
}

export default Bank;