import { useState } from 'react';
import { Alert } from 'react-bootstrap';

import { CiWarning } from 'react-icons/ci';
import { BiErrorCircle } from 'react-icons/bi';
import { GrCircleInformation } from 'react-icons/gr';
import { BsCheckCircle } from 'react-icons/bs';
import { HiOutlineLightBulb } from 'react-icons/hi';
import '../../styles/alert-box.scss';

interface Props {
    type: string | any;
    message: string | any;
}
interface iconsInterface {
    [key: string]: JSX.Element;
}
const icons: iconsInterface = {
    "warning": <CiWarning />,
    "danger": <BiErrorCircle />,
    "primary": <GrCircleInformation />,
    "success": <BsCheckCircle />,
    "info": <HiOutlineLightBulb />,
}
const alertType:any = {
    "warning": "Warning",
    "danger": "Error",
    "primary": "Information",
    "success": "Success",
    "info": "Remainder"
}
export default function AlertBox({ type, message }: Props) {
    const [show, setShow] = useState(true)

    setTimeout(() => {
        setShow(false)
    }, 2000)

    return (
        <Alert variant={type} onClose={() => setShow(false)} dismissible className={`alert-box ${type}-alert`} style={{zIndex:"21"}}>
            <p>
                {icons[type]}
                <div>
                    <div className={"alert-type"}>{alertType[type]}</div>
                    <div>{message}</div>
                </div>
            </p>
        </Alert>
    )
}