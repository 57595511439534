import React, { useEffect, useState } from 'react'
import IconButton from '../../../common-component/form-elements/icon-button';
import { CiSearch } from 'react-icons/ci';
import { axiosPrivate } from '../../../../middleware/axois-api';
import ChartFilter from '../../../common-component/charts/chart-filter';
import { BankReportFilter } from '../../../../config/reports/master-reports/bank-report-config';
interface props {
    searchBarValue: string
    filterOpen: any
    setFilterOpen: any
    conponentPDFMaster: any

}
export default function BankGrid({ searchBarValue, filterOpen, setFilterOpen, conponentPDFMaster }: props) {
    const [searchValue, setSearchValue] = useState<string>('');
    const [filteredData, setFilteredData] = useState<any[]>([]);
    const [orginalFilteredData, setOrginalFilteredData] = useState<any[]>([]);
    // const [filterOpen, setFilterOpen] = useState<boolean>(false);
    const [openDropdownIndex, setOpenDropdownIndex] = useState<number | null>(null);

    const [storeFilterOptions, setStoreFilterOptions] = useState<any>([]);
    const [filterValue, setFilterValues] = useState<any>([])
    const [orginalRowData, setOrginalRowData] = useState<any[]>([]);

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setSearchValue(value);
        if (value === "") {
            fetchData()
        } else {
            // Filter data based on the search value
            const filtered = orginalFilteredData.filter((item) => {
                const AccountDetails = item.AccountDetails;

                if (
                    AccountDetails &&
                    (AccountDetails["Account Number"]?.toLowerCase().includes(value.toLowerCase()))
                ) {
                    // Return an object containing the matched field and its value
                    return {
                        matchedField: AccountDetails["Account Number"]?.toLowerCase().includes(value.toLowerCase())
                            ? "Account Number"
                            : "",
                        value: AccountDetails["Account Number"],
                    };
                }

                return null;
            });

            setFilteredData(filtered);
        }
    };

    const fetchData = async () => {
        try {
            const response = await axiosPrivate.get('/companyadmin/master-reports/bank');
            setFilteredData(response.data.bankDetailsReport)
            setOrginalRowData(response.data.bankDetailsReport)
            addDataForDropDownFilter(response.data.dropDownDatas)
        } catch (error) {
        }
    };

    useEffect(() => {
        if (searchBarValue === "") {
            fetchData()
        } else {
            const filtered = orginalRowData.filter((item) => {
                const AccountDetails = item.AccountDetails;
                if (
                    AccountDetails &&
                    (AccountDetails["Account Number"]?.toLowerCase().includes(searchBarValue.toLowerCase()))
                ) {
                    // Return an object containing the matched field and its value
                    return {
                        matchedField: AccountDetails["Account Number"]?.toLowerCase().includes(searchBarValue.toLowerCase())
                            ? "Account Number"
                            : "",
                        value: AccountDetails["Account Number"],
                    };
                }

                return null;
            });

            setFilteredData(filtered);
        }
    }, [searchBarValue]);

    // filter functions

    useEffect(() => {
        handleApplyButtonForFilter(BankReportFilter["Bank"])
        setStoreFilterOptions(BankReportFilter["Bank"])
        setFilterValues(BankReportFilter["Bank"])
        fetchData()
    }, [])

    const handleOpenClose = (index: number) => {
        if (openDropdownIndex === index) {
            setOpenDropdownIndex(null); // Close the dropdown if it's already open
        } else {
            setOpenDropdownIndex(index); // Open the dropdown
        }
    };

    const updateFilterDatas = async (index: number, newData: any) => {
        setStoreFilterOptions((prevFilterDatas: any) => {
            const updatedFilterDatas = [...prevFilterDatas];
            updatedFilterDatas[index] = newData;
            return updatedFilterDatas;
        });

        setFilterValues((prevFilterDatas: any) => {
            const updatedFilterDatas = [...prevFilterDatas];
            updatedFilterDatas[index] = newData;
            return updatedFilterDatas;
        });
    };

    function addDataForDropDownFilter(res: any) {

        if (Object.keys(res).length > 0) {

            const updatedAccountNumber: any = [
                { value: "All", label: "All" },
                ...res['Account Number'].map((elem: any) => ({ value: elem, label: elem }))
            ];
            const updatedBankName: any = [
                { value: "All", label: "All" },
                ...res['Bank Name'].map((elem: any) => ({ value: elem, label: elem }))
            ];
            const updatedBranch: any = [
                { value: "All", label: "All" },
                ...res.Branch.map((elem: any) => ({ value: elem, label: elem }))
            ];
            const updatedCustomerID: any = [
                { value: "All", label: "All" },
                ...res['Customer ID'].map((elem: any) => ({ value: elem, label: elem }))
            ];
            const updatedIFSCCode: any = [
                { value: "All", label: "All" },
                ...res['IFSC Code'].map((elem: any) => ({ value: elem, label: elem }))
            ];
            const updatedIsActive: any = [
                { value: "All", label: "All" },
                ...res['Is Active'].map((elem: any) => ({ value: elem, label: elem }))
            ];
            const updatedIsApproved: any = [
                { value: "All", label: "All" },
                ...res['Is Approved'].map((elem: any) => ({ value: elem, label: elem }))
            ];
            const updatedPriority: any = [
                { value: "All", label: "All" },
                ...res.Priority.map((elem: any) => ({ value: elem, label: elem }))
            ];
            setStoreFilterOptions((prevOptions: any) => {
                return prevOptions.map((option: any) => {
                    switch (option.cardTitle) {
                        case "Account Number":
                            return { ...option, options: updatedAccountNumber };
                        case "Bank Name":
                            return { ...option, options: updatedBankName };
                        case "Branch":
                            return { ...option, options: updatedBranch };
                        case "Customer ID":
                            return { ...option, options: updatedCustomerID };
                        case "IFSC Code":
                            return { ...option, options: updatedIFSCCode };
                        case "Is Active":
                            return { ...option, options: updatedIsActive };
                        case "Is Approved":
                            return { ...option, options: updatedIsApproved };
                        case "Priority":
                            return { ...option, options: updatedPriority };

                        default:
                            return option;
                    }
                });
            });

            setFilterValues((prevOptions: any) => {
                return prevOptions.map((option: any) => {
                    switch (option.cardTitle) {
                        case "Account Number":
                            return { ...option, options: updatedAccountNumber };
                        case "Bank Name":
                            return { ...option, options: updatedBankName };
                        case "Branch":
                            return { ...option, options: updatedBranch };
                        case "Customer ID":
                            return { ...option, options: updatedCustomerID };
                        case "IFSC Code":
                            return { ...option, options: updatedIFSCCode };
                        case "Is Active":
                            return { ...option, options: updatedIsActive };
                        case "Is Approved":
                            return { ...option, options: updatedIsApproved };
                        case "Priority":
                            return { ...option, options: updatedPriority };

                        default:
                            return option;
                    }
                });
            });

        }
    }

    const convertToFilterValues = (filterData: any) => {
        const filterValues: any = {};
        filterData.forEach((filter: any) => {
            const tittle = (filter.cardTitle).replace(/\s/g, '')
            filterValues[tittle] = filter.selectedValue;
        });
        return filterValues;
    };

    async function handleApplyButtonForFilter(data: any) {
        const convertValue = convertToFilterValues(filterValue)
        if (
            convertValue['AccountNumber'] == "All" &&
            convertValue['BankName'] == "All" &&
            convertValue['Branch'] == "All" &&
            convertValue['CustomerID'] == "All" &&
            convertValue['IFSCCode'] == "All" &&
            convertValue['IsActive'] == "All" &&
            convertValue['IsApproved'] == "All" &&
            convertValue['Priority'] == "All"
        ) {
            setFilteredData(orginalRowData)
        } else {
            const filteredData: any = orginalRowData && orginalRowData.filter((ele: any) => {
                const AccountNumberCondition = convertValue['AccountNumber'] === 'All' || ele.AccountDetails['Account Number'] === convertValue['AccountNumber'];
                const BankNameCondition = convertValue['BankName'] === 'All' || ele.BankDetails['Bank Name'] === convertValue['BankName'];
                const BranchCondition = convertValue['Branch'] === 'All' || ele.BankDetails.Branch === convertValue['Branch'];
                const CustomerIDCondition = convertValue['CustomerID'] === 'All' || ele.AccountDetails['Customer ID'] === convertValue['CustomerID'];
                const IFSCCodeCondition = convertValue['IFSCCode'] === 'All' || ele.AccountDetails['IFSC Code'] === convertValue['IFSCCode'];
                const IsActiveCondition = convertValue['IsActive'] === 'All' || ele.Status['Is Active'] === convertValue['IsActive'];
                const IsApprovedCondition = convertValue['IsApproved'] === 'All' || ele.Status['Is Approved'] === convertValue['IsApproved'];
                const PriorityCondition = convertValue['Priority'] === 'All' || ele.Status.Priority === convertValue['Priority'];

                return AccountNumberCondition
                    && BankNameCondition
                    && BranchCondition
                    && CustomerIDCondition
                    && IFSCCodeCondition
                    && IsActiveCondition
                    && IsApprovedCondition
                    && PriorityCondition
            });
            setFilteredData(filteredData);
        }


    }

    // filter functions end
    return (
        <>
            <div className='report-table-component' style={{ marginTop: "20px", overflowX: "auto" }}>
                <table ref={conponentPDFMaster}>
                    <thead>
                        {/* <tr> */}
                        <th className='report-heading' style={{ paddingLeft: "10px", }}>Account Details</th>
                        <th className='report-heading' style={{ paddingLeft: "10px" }}>Bank Details</th>
                        <th className='report-heading' style={{ paddingLeft: "10px" }}>Status</th>

                        {/* </tr> */}
                    </thead>
                    <tbody>

                        {filteredData.length === 0 ?
                            <tr className='style-grid-differ'>

                                <td></td>
                                <td >No records to show</td>
                                <td></td>

                            </tr>
                            :
                            filteredData.map((data: any, index: any) => (
                                <tr className='main-body-header' key={index} >
                                    <td className='report-details' >
                                        {Object.entries(data.AccountDetails).map(([key, value]: any) => (
                                            <p key={key} style={{ whiteSpace: "normal", width: "350px" }}>
                                                <i>{key}</i>  - {value}
                                            </p>
                                        ))}
                                    </td>
                                    <td className='report-details' >
                                        {Object.entries(data.BankDetails).map(([key, value]: any) => (
                                            <p key={key} style={{ width: "350px", whiteSpace: "normal" }}>
                                                <i>{key}</i> - {value}
                                            </p>
                                        ))}
                                    </td>

                                    <td className='report-details' >
                                        {Object.entries(data.Status).map(([key, value]: any) => (
                                            <p key={key}>
                                                <i>{key}</i> - {value}
                                            </p>
                                        ))}
                                    </td>

                                </tr>
                            ))}
                    </tbody>
                </table>
            </div>

            {filterOpen &&
                <div className="right-side-filter-component" >
                    <ChartFilter
                        datas={storeFilterOptions}
                        updateFilterDatas={updateFilterDatas}
                        handleOpenClose={handleOpenClose}
                        openDropdownIndex={openDropdownIndex}
                        handleApplyButtonForFilter={() => { handleApplyButtonForFilter("") }}
                        setOpenRightSideFilter={() => { setFilterOpen(false) }}
                    />
                </div>
            }
        </>
    )
}
