import React, { useEffect, useState } from 'react'
import { BiPlus, BiMinus } from "react-icons/bi";
import "../../../styles/card-form-grid.scss";
import IconButton from '../../../components/common-component/form-elements/icon-button';
import DeleteModal from '../../../components/common-component/modals/delete-modal';
import { tr } from 'date-fns/locale';




interface Props {
    transactionMapping: any;
    retriveData: (value: any, index: number) => void;
    showmodal: (value: any, index: number) => void;
}

function CardFormTableGrid({ transactionMapping, retriveData, showmodal }: Props) {
    const [showDeleteModel, setShowDeleteModel] = useState<boolean>(false)
    // const [columnData, setColumnData] = useState<any>(directorApiData)
    return (
        <>
            <div>

                <table className='subscriptionTable' style={{ marginTop: "40px", marginBottom: "40px" }}>
                    {/* <tr> */}
                    <thead className='headings'>
                        <th className='headingTitle-card'>Department</th>
                        <th className='headingTitle-card'>Max Limits</th>
                        <th className='headingTitle-card'>Payments</th>
                        <th className='headingTitle-card'>Expense Type</th>
                        <th className='headingTitle-card'>Action</th>
                        {/* </tr> */}
                    </thead>
                    <tbody className='bodyContents'>
                        {transactionMapping.length === 0 ?
                            <tr className='main-style-grid-differ'>
                                <td></td>
                                <td></td>
                                <td>No records to show</td>
                                <td></td>
                            </tr> :
                            transactionMapping.map((data: any, index: number) => (
                                // <div className='main-body-header'>
                                <tr className='tableDatas' key={index}>
                                    <td className='middle-icons-card'>{data?.transactionMappingDepartmentName !== "" && data.transactionMappingDepartmentName}</td>
                                    <td className='middle-icons-card'>{data?.transactionMappingMaxLimit !== "" && parseInt(data.transactionMappingMaxLimit).toLocaleString('en-IN')}</td>
                                    <td className='middle-icons-card'>{data?.transactionMappingPayments !== "" && data.transactionMappingPayments}</td>
                                    <td className='middle-icons-card'>{data?.transactionMappingExpense !== "" && data.transactionMappingExpense}</td>
                                    {/* <div className='middle-icons'>{"data?.CompanyTan"}</div> */}
                                    <td className='last-icon flexcls'>
                                        <IconButton
                                            iconName={"Edit"}
                                            height={"40px"}
                                            width={"40px"}
                                            fontSize={"30px"}
                                            margin={"0px 8px"}
                                            color={"white"}
                                            backgroundColor={"#0055D4"}
                                            hover={true}
                                            handleClick={() => {
                                                retriveData(data, index);
                                            }}
                                        />
                                        <IconButton
                                            iconName={"Delete"}
                                            height={"40px"}
                                            width={"40px"}
                                            fontSize={"25px"}
                                            margin={"0px 8px"}
                                            color={"white"}
                                            backgroundColor={"#D60000"}
                                            hover={true}
                                            handleClick={() => {
                                                showmodal(data, index)
                                            }}
                                        />
                                    </td>
                                </tr>
                                // </div>
                            ))}
                    </tbody>

                </table>
            </div>
            {
                showDeleteModel &&
                <div >
                    <DeleteModal
                        getDelete={() => { }}
                        clearValue={(value) => { setShowDeleteModel(value) }}
                        modelType={"grid-delete"}
                    />
                </div>
            }
        </>
    )
}

export default CardFormTableGrid;