import React from 'react'
import '../../../styles/modals/changes-modal.scss'
import { RxCross1 } from 'react-icons/rx';
import ButtonComponent from '../form-elements/button-component';


interface Styles {
    closeModal: () => void;
    leavePage: () => void;
    countdown: any
}

export default function SessionTimeoutmodal({ closeModal, leavePage, countdown }: Styles) {

    return (

        <div className={"changes-modal"}>
            <div className={"container"} style={{padding:"20px"}}>
                <div className={'changes-title'}>
                    <div className={"sub-title"}>{`Your session will expire in ${countdown} seconds.`}</div>
                    
                    <div className={"buttons"} style={{marginTop:"30px"}} >
                        <ButtonComponent
                            title={"Stay"}
                            height={"50px"}
                            width={"140px"}
                            backgroundColor={"#888888"}
                            color={"white"}
                            className={"button-component-hover cancel"}
                            handleClick={closeModal}
                        />
                        <ButtonComponent
                            title={"Leave"}
                            height={"50px"}
                            width={"140px"}
                            backgroundColor={"#0055D4"}
                            color={"white"}
                            handleClick={leavePage}
                            className={"button-component common-btn"}
                        />
                    </div>
                </div>
            </div>



        </div >

    )
}

