import { useState, useEffect } from 'react'
import MainPageCard from '../../common-component/cards/main-page-card'
import NavigateHeader from '../../common-component/navigate-header'
import RadioOrCheckbox from '../../common-component/form-elements/radio-or-checkbox'
import DropdownComponent from '../../common-component/form-elements/dropdown-component'
import InputComponent from '../../common-component/form-elements/input-component'
import ButtonComponent from '../../common-component/form-elements/button-component'
import useAxiosPrivate from "../../../services/hooks/useaxios-private";
import { v4 as uuidv4 } from 'uuid';
import AlertBox from '../../common-component/alert-box'
import Changesmodal from '../../common-component/modals/changes-modal'
import TextArea from '../../common-component/form-elements/text-area'
import "../../../styles/organisation/bank/form.scss"
import Loading from '../../common-component/modals/loading-screen'
import { useDispatch } from "react-redux";
import { resetFormModified } from "../../../redux/action";
import { BsPlusLg } from 'react-icons/bs'
import AddRocCity from '../../common-component/modals/add-roc-city-model'

interface FormProps {
  userNavigateFunction: (val: string) => void;
  userNavigate: string;
  isEditMode: boolean;
  modeFunction: (val: boolean) => void;
  getEditMode: (value: boolean) => void;
  editData: any
}

export interface BankDataType {
  state: string,
  city: string;
  branch: string;
  ifscCode: string;
  name: string;
  emailId: string;
  isActive: string;
  status: string;
  remarks: string;
  [key: string]: string;
}

export interface vendorBankApprovalValidationRulesInterface {
  [key: string]: {
    [key: string]: {
      regex: RegExp | string;
      field: string;
      shouldNotBe: string;
    };
  }
}



const Form: React.FC<FormProps> = ({ userNavigateFunction, getEditMode, isEditMode, editData, modeFunction }) => {
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
  const [componentNavigator, setComponentNavigator] = useState("form-page");
  const [showType, setShowType] = useState("warning") // error message showType
  const [showMessage, setShowMessage] = useState<string>("") // error message showMessage
  const [showAlertBox, setShowAlertBox] = useState(false) // error message ShowAlertBox
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [addRocCity, setAddRocCity] = useState<boolean>(false);
  // const [isDisabled, setIsDisabled] = useState<boolean>(true)
  const [userDataErrors, setUserDataErrors] = useState<Record<string, boolean>>({});
  const [showChangesModal, setShowChangesModal] = useState(false);
  const [BranchValues, setBranchValues] = useState<any[]>([]);
  const [StateValues, setStateValues] = useState<any[]>([]);
  const [CityValues, setCityValues] = useState<any[]>([]);
  const [bankValues, setBankValues] = useState<any[]>([]);
  const [vendorBankApprovalData, setVendorBankApprovalData] = useState<BankDataType>({
    state: "",
    stateName: "",
    city: "",
    cityName: "",
    bankName: "",
    bank: "",
    ifscCode: "",
    branch: "",
    branchId: "",
    name: "",
    isActive: "Yes",
    emailId: "",
    status: "",
    remarks: "",
    approvedBy: "",
    isRequired: "Yes",
    internalApprovalStatus: "Pending Approval",
    internalApprovalStatusRemarks: "",

  });
  console.log("vendorBankApprovalData", vendorBankApprovalData)
  const dispatch = useDispatch();
  const axiosPrivate = useAxiosPrivate();

  // regux for mandatory while click save 
  const vendorBankApprovalValidationRules: vendorBankApprovalValidationRulesInterface = {
    Vendor_Bank_Approval_details: {
      state: {
        regex: "",
        field: "mandatory",
        shouldNotBe: ""
      },
      city: {
        regex: "",
        field: "mandatory",
        shouldNotBe: ""
      },
      bank: {
        regex: "",
        field: "mandatory",
        shouldNotBe: ""
      },
      emailId: {
        regex: /^[a-z0-9._-]+@[a-z0-9-]+\.[a-z]{2,}$/,
        field: "mandatory",
        shouldNotBe: ""
      },
      name: {
        regex: /^[a-zA-Z][A-Za-z. ]+$/,
        field: "mandatory",
        shouldNotBe: ""
      },
      isActive: {
        regex: "",
        field: "mandatory",
        shouldNotBe: ""

      },
      remarks: {
        regex: (vendorBankApprovalData.isActive === "No") ? /^[^\s].*/ : "",
        field: (vendorBankApprovalData.isActive === "No") ? "mandatory" : "",
        shouldNotBe: ""

      },

    }
  }

  // useEffect for retreving data while edit 
  useEffect(() => {
    if (!isEditMode) return;
    setVendorBankApprovalData({
      state: editData.stateId,
      stateName: editData.state,
      cityName: editData.city || "",
      city: editData.cityId,
      ifscCode: editData.ifscCode,
      bankName: editData.bankId,
      bank: editData.bank,
      branchId: editData.branchId,
      branch: editData.branch || "",
      name: editData.name,
      emailId: editData.emailId,
      isActive: editData.isActive,
      status: editData.status,
      approvedBy: editData.approvedBy,
      remarks: editData.remarks || "",
      vendorApprovalId: editData.vendorAppId,
      internalApprovalStatus: editData.internalApprovalStatus,
      internalApprovalStatusRemarks: editData.internalApprovalStatusRemarks,
      isRequired: editData.isRequired,

    });

    fetchData(`/state/roc-cities`, setCityValues, 'RocCity', "rocCityId", "name");
    // fetchData(`state/cities/${editData.stateId}`, setCityValues, 'city', "cityId", "name");
    // fetchData(`/state/roc-cities/${editData.stateId}`, setCityValues, 'RocCity', "rocCityId", "name");
    fetchData(`get-branchBy-city/${editData.cityId}`, setBranchValues, 'filteredBranch', "branchId", "branchName");
  }, [isEditMode]);



  useEffect(() => {
    fetchBankValues()
    fetchData(`region/state`, setStateValues, 'states', "stateId", "name");
    fetchData(`/state/roc-cities`, setCityValues, 'RocCity', "rocCityId", "name");

  }, [])

  // fetchData(`state/cities/${val.id}`, setCityValues, 'city', "cityId", "name");
  // fetchData(`get-branchBy-city/${val.id}`, setBranchValues, 'filteredBranch', "branchId", "branchName");

  const fetchData = async (url: string, setData: (data: any[]) => void, nestedProperty: string, id: any, representName: any) => {
    try {
      const response = await axiosPrivate.get(url);
      if (response.status === 200) {
        const dropdownValues = response.data[nestedProperty].map((item: any) => ({
          label: item[representName],
          value: item[representName],
          id: item[id],
        }));
        const sortedData = [...dropdownValues].sort((a, b) => a.label.localeCompare(b.label));
        if (setData !== setStateValues && setData !== setCityValues) {
          setData([{ value: "", label: "Select", id: "" }, ...sortedData]);
        } else {
          setData(sortedData);
        }
      }
    } catch (error) {
    }
  };
  // handelFectchIfsc(val.id);
  // 

  const handelFectchIfsc = async (id: any) => {
    try {
      const res = await axiosPrivate.get(`get-ifscBy-branch/${id}`)
      if (res.status === 200) {
        setVendorBankApprovalData((prevState) => ({
          ...prevState,
          ifscCode: res.data.ifsc
        }));
      }
    }
    catch (error: any) {
    }
  }

  // Regux validation
  const updatedIsValueValidAll: Record<string, boolean> = {};
  const validateForm = (): boolean => {
    const updatedFieldValidity: Record<string, boolean> = {};
    Object.keys(vendorBankApprovalValidationRules.Vendor_Bank_Approval_details).forEach((field) => {
      const rule = vendorBankApprovalValidationRules.Vendor_Bank_Approval_details[field];
      const value = vendorBankApprovalData[field];
      // Check if the field value meets the conditions based on rule.field and rule.shouldNotBe
      const isValueValid = (rule.field === "mandatory") ? (value !== rule.shouldNotBe) : true;
      // If a regular expression is provided, test the field value against it
      const isRegexValid = rule.regex !== "" ? new RegExp(rule.regex).test(value) : true;
      updatedFieldValidity[field] = !isValueValid || !isRegexValid;
      updatedIsValueValidAll[field] = !isValueValid

    });
    setUserDataErrors(updatedFieldValidity);
    const isFormValid = Object.keys(updatedFieldValidity).every((keys) => {
      return !updatedFieldValidity[keys]
    }
    );

    return isFormValid;
  };

  // Timeout function for messages
  const clearAleart = (status: any) => {
    const timer = setTimeout(() => {
      setShowAlertBox(false);
      setShowMessage("");
      if (status === 200) {
        userNavigateFunction("grid")
      }
      clearTimeout(timer);
    }, 5000);
  }

  // This function call while click save button
  async function vendorBankApprovalDataSave() {
    const isValid = validateForm();
    if (isValid) {
      setIsLoading(true)
      try {
        const res = isEditMode ?
          await axiosPrivate.patch(`vendor/approvals/update/${editData.vendorAppId}`, {
            vendorBankApprovalData:
            {
              state: vendorBankApprovalData.state,
              stateName: vendorBankApprovalData.stateName,
              city: vendorBankApprovalData.city || "",
              cityName: vendorBankApprovalData.cityName,
              bankName: vendorBankApprovalData.bankName,
              bank: vendorBankApprovalData.bank,
              ifscCode: vendorBankApprovalData.ifscCode,
              branch: vendorBankApprovalData.branch,
              branchId: vendorBankApprovalData.branchId,
              name: vendorBankApprovalData.name,
              isActive: vendorBankApprovalData.isActive,
              emailId: vendorBankApprovalData.emailId,
              status: vendorBankApprovalData.status,
              remarks: vendorBankApprovalData.remarks,
              approvedBy: vendorBankApprovalData.approvedBy,
              isRequired: vendorBankApprovalData.isRequired,
              internalApprovalStatus: 'Pending Approval',
            }
          }) :
          await axiosPrivate.post('vendor/approvals', {
            vendorBankApprovalData:
            {
              state: vendorBankApprovalData.state,
              stateName: vendorBankApprovalData.stateName,
              city: vendorBankApprovalData.city || "",
              cityName: vendorBankApprovalData.cityName,
              ifscCode: vendorBankApprovalData.ifscCode,
              bankName: vendorBankApprovalData.bankName,
              bank: vendorBankApprovalData.bank,
              branch: vendorBankApprovalData.branch,
              branchId: vendorBankApprovalData.branchId,
              name: vendorBankApprovalData.name,
              isActive: vendorBankApprovalData.isActive,
              emailId: vendorBankApprovalData.emailId,
              status: vendorBankApprovalData.status,
              remarks: vendorBankApprovalData.remarks,
              approvedBy: vendorBankApprovalData.approvedBy,
              isRequired: vendorBankApprovalData.isRequired,
              internalApprovalStatus: 'Pending Approval',

            }
          })
        if (res.status === 200) {
          setIsLoading(false)
          setShowAlertBox(true)
          getEditMode(false)
          setShowType("success")
          setShowMessage(res.data.message)
          clearAleart(res.status)
          dispatch(resetFormModified(false));
          // userNavigateFunction("grid")
        }
      } catch (error: any) {
        setIsLoading(false)
        if (error.status === 500) {
          setShowAlertBox(true);
          setShowType("danger")
          setShowMessage(error.response.data.error)
          clearAleart("")

        } else if (error.code === "ERR_NETWORK") {
          setShowAlertBox(true);
          setShowType("danger")
          setShowMessage(error.response.data.error)
          clearAleart("")
        } else {
          setShowAlertBox(true);
          setShowType("danger")
          setShowMessage(error.response.data.error)
          clearAleart("")
        }
      }
    } else {
      setShowAlertBox(true)
      setShowType("danger")
      setShowMessage(Object.values(updatedIsValueValidAll).every((values) => !values) ? "Some of the field(s) are not in required format." : "Mandatory field(s) should not be left blank.")
      clearAleart("")
    }

  }

  // This function call while click cancel button
  const vendorBankApprovalDataCancel = () => {
    if (isFormDirty) {
      setShowChangesModal(true)
    } else {

      setVendorBankApprovalData({
        state: "",
        stateName: "",
        city: "",
        cityName: "",
        bankName: "",
        bank: "",
        name: "",
        ifscCode: "",
        branch: "",
        branchId: "",
        emailId: "",
        isActive: "",
        status: "",
        remarks: "",

      })
      modeFunction(false)
      userNavigateFunction("grid")
    }
  }

  const fetchBankValues = async () => {
    try {
      const response = await axiosPrivate.get("bank");
      if (response.status === 200) {
        const dropdownValues = response.data.bank.map((item: any) => ({
          label: item.bankName,
          value: item.bankName,
          id: item.bankId,
        }));
        setBankValues(dropdownValues);
      }
    } catch (error) {
    }
  };
  return (
    <>
      <MainPageCard>
        <NavigateHeader
          style={{
            cursor: "pointer"
          }}
          firstValue={"Fingertip Admin"}
          secondValue={"VB Master"} navigatePage={() => { vendorBankApprovalDataCancel() }} />
        {componentNavigator === "form-page" &&
          <div>
            <div className='form-top-main'>
              {
                (vendorBankApprovalData.internalApprovalStatus == "Approve" || vendorBankApprovalData.internalApprovalStatus == "Decline") &&
                <div className="form-border" style={{ height: "220px" }}>
                  <div style={{ height: "150px" }}>
                    <div
                      className="form-outer-main"
                      style={{
                        height: "40px",
                        backgroundColor: "#0055D4",
                        display: "flex",
                        alignItems: "center",
                        paddingLeft: "1%",
                      }}
                    >
                      <p>This new request required your approval.</p>
                    </div>
                    <div className="form-inputss">
                      <div className="radio-input user-form-radio-input form-radio">
                        <div className="radio-btn-main" style={{ pointerEvents: "none" }}>
                          <RadioOrCheckbox
                            value={"Approve"}
                            type={"radio"}
                            name={"bankApprovalApp"}
                            margin={"0px 30px 0px 0px"}

                            checkedValue={vendorBankApprovalData.internalApprovalStatus}
                            getVal={(val) => {
                              // setIsFormDirty(true);
                              // setVendorBankApprovalData((prevState) => ({
                              //     ...prevState,
                              //     internalApprovalStatus: val,
                              //     remark: "",
                              // }));
                              // dispatch(resetFormModified(true));
                            }}
                          />

                          <RadioOrCheckbox
                            value={"Decline"}
                            name={"bankApprovalIdapp"}
                            type={"radio"}
                            checkedValue={vendorBankApprovalData.internalApprovalStatus}
                            getVal={(val) => {
                              // setIsFormDirty(true);
                              // setVendorBankApprovalData((prevState) => ({
                              //     ...prevState,
                              //     internalApprovalStatus: val,
                              //     remark: "",
                              // }));
                              // dispatch(resetFormModified(true));
                            }}
                          />
                        </div>
                      </div>
                      <div className="border-line-inputs"></div>
                      <div
                        className="form-inputsss"
                      // style={
                      //     organisationUserData.internalApprovalStatus === "Approve"
                      //         ? { pointerEvents: "none", opacity: 0.5 }
                      //         : { pointerEvents: "auto", opacity: 1 }
                      // }
                      >
                        <InputComponent
                          height={"50px"}
                          width={windowWidth > 1250 ? "97%" : "100%"}
                          margin={"0px 60px 30px 0px"}
                          padding={"0px 0px 0px 10px"}
                          border={"1px solid #A9C3DC"}
                          borderRadius={"0px"}
                          backgroundColor={"white"}
                          color={"black"}
                          maxLength={250}
                          type={"text"}
                          disabled={true

                          }
                          inputTitle={"Reason For Declining Request"}
                          required={true}
                          placeHolder={"Reason"}
                          inputValue={vendorBankApprovalData.internalApprovalStatusRemarks}
                          getUser={(value: any) => {
                            // setIsFormDirty(true);
                            // setVendorBankApprovalData((prevState) => ({
                            //     ...prevState,
                            //     remarks: value,
                            // }));
                            // dispatch(resetFormModified(true));
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              }
              <div className='form-input' >
                <div className={userDataErrors.bank ? 'setError' : 'dropdown-vendor-bank-approval'} key={uuidv4()} >
                  <DropdownComponent
                    options={bankValues}
                    title={"Bank"}
                    required={true}
                    // isDisabled={organisationBankData.status === "Pending Approval"}
                    // border={userDataErrors.bank ? "1px solid red" : "1px solid #A9C3DC"}
                    getData={(val) => {
                      setIsFormDirty(true)
                      setVendorBankApprovalData((prevState) => ({
                        ...prevState,
                        bankName: val.value,
                        bank: val.id

                      }));
                      dispatch(resetFormModified(true));
                    }}
                    defaultValue={[{ label: vendorBankApprovalData.bankName === "" ? "Select" : vendorBankApprovalData.bankName, value: vendorBankApprovalData.bankName }]}

                  />
                </div>
                <div className={userDataErrors.state ? 'setError' : 'dropdown-vendor-bank-approval'} key={uuidv4()}>
                  <DropdownComponent
                    options={StateValues}
                    title={"State"}
                    required={true}
                    // isDisabled={vendorBankApprovalData.state}
                    getData={(val) => {
                      setVendorBankApprovalData((prevState) => ({
                        ...prevState,
                        stateName: val.value,
                        state: val.id,
                        cityName: "",
                        city: "",
                        ifscCode: "",
                        branchId: "",
                        branch: "",
                      }));
                      if (val.id == "") {
                        // setCityValues([]);
                        setBranchValues([]);
                      } else {
                        setBranchValues([]);
                        // fetchData(`/state/roc-cities/${val.id}`, setCityValues, 'RocCity', "rocCityId", "name");
                        // fetchData(`state/cities/${val.id}`, setCityValues, 'city', "cityId", "name");

                      }
                      dispatch(resetFormModified(true));
                    }}
                    defaultValue={[{ label: vendorBankApprovalData.stateName === "" ? "Select" : vendorBankApprovalData.stateName, value: vendorBankApprovalData.stateName }]}
                  />
                </div>
                <div className='rocCity-plus'>
                  <div className={userDataErrors.city ? 'setError-rocCity' : 'dropdown-vendor-bank-approval-rocCity'} key={uuidv4()}>
                    <DropdownComponent
                      options={CityValues}
                      title={"ROC City"}
                      required={true}
                      // isDisabled={vendorBankApprovalData.city}
                      getData={(val) => {
                        setVendorBankApprovalData((prevState) => ({
                          ...prevState,
                          cityName: val.value,
                          city: val.id,
                          ifscCode: "",
                          branchId: "",
                          branch: "",
                        }));
                        if (val.id === "") {
                          setBranchValues([]);
                        } else {
                          fetchData(`get-branchBy-city/${val.id}`, setBranchValues, 'filteredBranch', "branchId", "branchName");
                        }
                        dispatch(resetFormModified(true));
                      }}
                      defaultValue={[{ label: vendorBankApprovalData.cityName === "" ? "Select" : vendorBankApprovalData.cityName, value: vendorBankApprovalData.cityName === "" ? "Select" : vendorBankApprovalData.cityName }]}
                    />
                  </div>
                  <div className='issuer-plus' onClick={() => {
                    setAddRocCity(true)
                  }}>
                    <BsPlusLg color='#004E9C' height={"14px"} />
                  </div>
                </div>
              </div>
              <div className='form-input'>
                <InputComponent
                  height={"40px"}
                  width={"350px"}
                  margin={"0px 60px 30px 0px"}
                  padding={"0px 0px 0px 10px"}
                  border={userDataErrors.branch ? "1px solid red" : "1px solid #A9C3DC"}
                  borderRadius={"0px"}
                  backgroundColor={"white"}
                  color={"black"}
                  type={"text"}
                  maxLength={100}
                  inputTitle={"Branch"}
                  placeHolder={"Enter Branch"}
                  required={false}
                  inputValue={vendorBankApprovalData.branch}
                  getUser={(value: any) => {
                    setIsFormDirty(true)
                    const onlyAlphabet = value.replace(/[^A-Za-z. ]+/g, '')
                    setVendorBankApprovalData((prevState) => ({
                      ...prevState,
                      branch: onlyAlphabet,
                    }));
                    dispatch(resetFormModified(true));
                  }}
                />
                {/* <div className={userDataErrors.branch ? 'setError' : 'dropdown-vendor-bank-approval'} key={uuidv4()}>
                  <DropdownComponent key={uuidv4()}
                    options={BranchValues}
                    title={"Branch"}
                    // isDisabled={disableBankUser.branch}
                    required={false}
                    width={'350px'}
                    getData={(val) => {
                      setVendorBankApprovalData((prevState) => ({
                        ...prevState,
                        branchId: val.id,
                        branch: val.value,
                      }));
                      if (val.id == "") {
                        setVendorBankApprovalData((prevState) => ({
                          ...prevState,
                          ifscCode: ""
                        }));
                      } else {
                        handelFectchIfsc(val.id);
                      }
                      dispatch(resetFormModified(true));

                    }}
                    defaultValue={[{ label: vendorBankApprovalData.branch === "" ? "Select" : vendorBankApprovalData.branch, value: vendorBankApprovalData.branch }]}
                  />
                </div> */}

                {/* <div style={{ opacity: "0.5" }}>
                  <InputComponent
                    height={"40px"}
                    width={"350px"}
                    margin={"0px 60px 30px 0px"}
                    padding={"0px 0px 0px 10px"}
                    border={userDataErrors.ifscCode ? "1px solid red" : "1px solid #A9C3DC"}
                    borderRadius={"0px"}
                    backgroundColor={"white"}
                    color={"black"}
                    type={"text"}
                    maxLength={11}
                    disabled={true}
                    inputTitle={"IFSC Code"}
                    placeHolder={"Enter IFSC Code"}
                    required={false}
                    inputValue={vendorBankApprovalData.ifscCode}
                    getUser={(value: any) => {
                      setIsFormDirty(true)
                      const ifsc = value.toUpperCase().replace(/[^A-Z0-9]/g, "")
                      setVendorBankApprovalData((prevState) => ({
                        ...prevState,
                        ifscCode: ifsc,
                      }));
                      dispatch(resetFormModified(true));
                    }}
                  />
                </div> */}
                <InputComponent
                  height={"40px"}
                  width={"350px"}
                  margin={"0px 60px 30px 0px"}
                  padding={"0px 0px 0px 10px"}
                  border={userDataErrors.name ? "1px solid red" : "1px solid #A9C3DC"}
                  borderRadius={"0px"}
                  backgroundColor={"white"}
                  color={"black"}
                  type={"text"}
                  maxLength={100}
                  inputTitle={"Contact Name"}
                  placeHolder={"Enter Contact Name"}
                  required={true}
                  inputValue={vendorBankApprovalData.name}
                  getUser={(value: any) => {
                    setIsFormDirty(true)

                    const onlyAlphabet = value.replace(/[^A-Za-z. ]+/g, '')
                    setVendorBankApprovalData((prevState) => ({
                      ...prevState,
                      name: onlyAlphabet,
                    }));
                    dispatch(resetFormModified(true));
                  }}
                />
                <div className='mail-icon-vendor-bank-input' style={{ marginRight: "20px" }}>
                  <InputComponent
                    height={"40px"}
                    width={"350px"}
                    margin={"0px 0px 45px 0px"}
                    padding={"0px 40px 0px 10px"}
                    borderRadius={"0px"}
                    backgroundColor={"white"}
                    color={"black"}
                    type={"text"}
                    placeHolder={"Enter Contact Email ID"}
                    inputTitle={"Contact Email ID"}
                    required={true}
                    maxLength={254}
                    className={"gmail-input"}
                    inputField={"gmail"}
                    border={userDataErrors.emailId ? "1px solid red" : "1px solid #A9C3DC"}
                    inputValue={vendorBankApprovalData && vendorBankApprovalData.emailId}
                    getUser={(value: any) => {
                      setVendorBankApprovalData((prevState) => ({
                        ...prevState,
                        emailId: value,
                      }));
                      setIsFormDirty(true)
                      dispatch(resetFormModified(true));
                    }}
                  />
                </div>

              </div>
              <div className='form-input'>
                <div className="radio-input user-form-radio-input" style={{ marginTop: "0px" }}>
                  <p style={{ marginBottom: "5px" }}>
                    Vendor Approval Required?<span>*</span>
                  </p>
                  <div className="radio-btn-main">
                    <RadioOrCheckbox
                      value={"Yes"}
                      type={"radio"}
                      name={"bankApprovals"}
                      margin={"0px 30px 0px 0px"}
                      checkedValue={vendorBankApprovalData.isRequired}
                      getVal={(val) => {
                        console.log("val", val)
                        setIsFormDirty(true);
                        setVendorBankApprovalData((prevState) => ({
                          ...prevState,
                          isRequired: val,
                          approve: "",
                        }));
                        dispatch(resetFormModified(true));
                      }}
                    />

                    <div>
                      <RadioOrCheckbox
                        value={"No"}
                        name={"bankApprovalIds"}
                        type={"radio"}
                        checkedValue={vendorBankApprovalData.isRequired}
                        getVal={(val) => {
                          setIsFormDirty(true);
                          setVendorBankApprovalData((prevState) => ({
                            ...prevState,
                            isRequired: val,
                            approve: "",
                          }));
                          dispatch(resetFormModified(true));
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className='radio-input user-form-radio-input'>
                  <p>Is Active<span>*</span></p>
                  <div className='radio-btn-main'>
                    <RadioOrCheckbox
                      value={"Yes"}
                      type={"radio"}
                      name={"bankApproval"}
                      margin={"0px 30px 0px 0px"}
                      checkedValue={vendorBankApprovalData.isActive}
                      getVal={(val) => {
                        setIsFormDirty(true)
                        setVendorBankApprovalData((prevState) => ({
                          ...prevState,
                          isActive: val,
                          remarks: ""
                        }));
                        dispatch(resetFormModified(true));
                      }}
                    />
                    <div style={isEditMode ? { pointerEvents: "auto", opacity: "1" } : { pointerEvents: "none", opacity: "0.5" }}>
                      <RadioOrCheckbox
                        value={"No"}
                        name={"bankApprovalId"}
                        type={"radio"}
                        checkedValue={vendorBankApprovalData.isActive}
                        getVal={(val) => {
                          setIsFormDirty(true)
                          setVendorBankApprovalData((prevState) => ({
                            ...prevState,
                            isActive: val,
                          }));
                          dispatch(resetFormModified(true));
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div style={vendorBankApprovalData.isActive === "Yes" ?
                  { pointerEvents: "none", opacity: 0.5, minHeight: "154px" } : { pointerEvents: "auto", opacity: 1, height: "60px" }} >
                  {vendorBankApprovalData.isActive !== "Yes" &&
                    <InputComponent
                      height={"40px"}
                      width={windowWidth > 1250 ? "760px" : "350px"}
                      margin={"0px 60px 30px 0px"}
                      padding={"0px 0px 0px 10px"}
                      border={userDataErrors.remarks ? "1px solid red" : "1px solid #A9C3DC"}
                      borderRadius={"0px"}
                      backgroundColor={"white"}
                      color={"black"}
                      maxLength={250}
                      type={"text"}
                      disabled={vendorBankApprovalData.isActive === "Yes" ? true : false}
                      inputTitle={"Remarks"}
                      required={true}
                      placeHolder={"Reason"}
                      inputValue={vendorBankApprovalData.remarks}
                      getUser={(value: any) => {
                        setIsFormDirty(true)
                        setVendorBankApprovalData((prevState) => ({
                          ...prevState,
                          remarks: value,
                        }));
                        dispatch(resetFormModified(true));
                      }}
                    />
                  }
                </div>

              </div>
            </div>
            <div className='footer-vendor-bank-approval'>
              <ButtonComponent
                title={"Cancel"}
                height={"50px"}
                width={"150px"}
                margin={"0px 8px"}
                backgroundColor={"#888888"}
                color={"white"}
                className={"button-component-hover cancel"}
                handleClick={vendorBankApprovalDataCancel}
              />
              <ButtonComponent
                title={isEditMode ? "Update" : "Save"}
                height={"50px"}
                width={"150px"}
                backgroundColor={"#0055D4"}
                color={"white"}
                disabled={showMessage && showType === "success" ? true : false}
                margin={"0px 8px"}
                className={showMessage && showType === "success" ? "button-component-hover disabled" : "button-component-hover common-btn"}
                handleClick={vendorBankApprovalDataSave}
              />

              {/* {isEditMode &&
                <ButtonComponent
                  title={"Submit for Approval"}
                  height={"50px"}
                  width={"200px"}
                  disabled={vendorBankApprovalData.status === "Pending Approval" || vendorBankApprovalData.status === "Approved" ? true : false}
                  backgroundColor={"#0055D4"}
                  color={"white"}
                  margin={"0px 8px"}
                  className={vendorBankApprovalData.status === "Pending Approval" || vendorBankApprovalData.status === "Approved" ? "button-component-hover disabled" : "button-component common-btn"}
                  handleClick={
                    vendorBankApprovalDataSave
                  }
                />
              } */}
            </div>
          </div>
        }

      </MainPageCard >
      {
        showAlertBox &&
        <div className='alert-warp'>
          <AlertBox type={showType} message={showMessage} />
        </div>

      }
      {
        isLoading &&
        <Loading />
      }
      {
        showChangesModal &&
        <Changesmodal
          closeModal={() => setShowChangesModal(false)}
          leavePage={() => {
            setShowChangesModal(false);
            setIsFormDirty(false)
            userNavigateFunction("grid")
            getEditMode(false)
          }}
          handleClose={() => setShowChangesModal(false)} />
      }
      {addRocCity &&
        <AddRocCity
          vendorBankApprovalData={
            vendorBankApprovalData
          }
          setCityValues={setCityValues}
          // setTransactionMappingData={setTransactionMappingData}getDepartment
          clearValue={() => {
            setAddRocCity(false)
          }}
          getRocCity={() => {
            // fetchData(`/state/roc-cities/${vendorBankApprovalData.state}`, setCityValues, 'RocCity', "rocCityId", "name");
          }}
          fetchData={fetchData}
          setRocCityValues={setCityValues}
          rocCityValues={CityValues}
          setVendorBankApprovalData={setVendorBankApprovalData}

        />
      }
    </>
  )
}

export default Form;
