
import React, { useState, useEffect, useRef } from 'react'
import NavigateHeader from '../../common-component/navigate-header';
import { useDispatch, useSelector } from 'react-redux';
import { faMapLocationDot } from '@fortawesome/free-solid-svg-icons';
import { BsBuildingExclamation, BsFileEarmarkCheckFill, BsFillPersonLinesFill, BsPersonFillGear } from 'react-icons/bs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactComponent as Notworking } from '../../../../src/assets/icons/not-work.svg'
import { ReactComponent as Progress } from '../../../../src/assets/icons/in-progerss.svg'
import { ReactComponent as Correct } from '../../../../src/assets/icons/correct.svg'
import { ReactComponent as ApprovalIcon } from '../../../../src/assets/icons/approval.svg'
import '../../../styles/onboarding/company/company-form.scss'
import CustomerOverview, { ChildRef } from '../customer-approval/approval-form-list/customer-overview';
import { axiosPrivate } from '../../../middleware/axois-api';
import { resetFormModified } from '../../../redux/action';
import AlertBox from '../../common-component/alert-box';
import Address from '../customer-approval/approval-form-list/address';
import PointOfContact from '../customer-approval/approval-form-list/point-of-contact';
import Loading from '../../common-component/modals/loading-screen';
import Changesmodal from '../../common-component/modals/changes-modal';
import Approval from './approval-form-list/appproval';
import BankDetails from './approval-form-list/bank-details';
import { RiBankFill } from 'react-icons/ri';


interface Props {
  onboardingViewfun: (val: any) => void;
  getEditData: (data: any) => void;
  editData: any;
  isEditMode: boolean
}


export default function CustomerForm({ onboardingViewfun, getEditData, editData, isEditMode }: Props) {

  const isFormModified = useSelector((state: any) => state.isFormModified);
  const [showChangesModalcancel, setShowChangesModalcancel] = useState(false);
  const [showPage, setShowPage] = useState("CustomerOverview");
  const [showChangesModalEdit, setShowChangesModalEdit] = useState(false);
  const [setNextNav, setSetNextNav] = useState("");
  const [isFormDirty, setIsFormDirty] = useState(false);
  const sideStatusRef = useRef<ChildRef | any>(null);
  const [loadingScreen, setLoadingScreen] = useState<boolean>(false);
  const [businessContactMain, setBusinessContactMain] = useState<any>({});
  const [companyOverviewPan, setCompanyOverviewPan] = useState<any>();
  const [chooseType, setChooseType] = useState<any>("PAN");
  const [vendorBankApprovalDetails, setVendorBankApprovalDetails] = useState<any[]>([]);

  const [bankRelationshipMain, setBankRelationshipMain] = useState<any>([]);

  const [showType, setShowType] = useState("warning") // error message showType
  const [showMessage, setShowMessage] = useState<string>("") // error message showMessage
  const [showAlertBox, setShowAlertBox] = useState(false) // error message ShowAlertBox

  const dispatch = useDispatch();



  const [mainApiData, setMainApiData] = useState<any>({});


  useEffect(() => {
    if (!isEditMode) return;
    if (editData === undefined) return;
    setBusinessContactMain(editData?.customerDetails?.pointOfContact)
    setMainApiData(editData?.customerDetails?.customerOverView)
    setBankRelationshipMain(editData?.customerDetails?.bankDetails)

    setIconStatus({
      CustomerIcon: "completed",
      addressIcon: "completed",
      pointofContact: "completed",
      adminIcon: "completed",
      Approval: editData?.customerDetails?.customerOverView.status !== "Pending Approval" ? "completed" : "selected",
    });
    getVendorBankApprovalDetails()

  }, [editData]);


  const [iconStatus, setIconStatus] = useState<any>({
    CustomerIcon: "selected",
    addressIcon: "notworking",
    pointofContact: "notworking",
    Approval: "notworking",
    adminIcon: "notworking",
    
  }); 
  




  async function fetchDataFunction(companyPan: any, chooseType: any) {
    setLoadingScreen(true)
    try {
      const res = await axiosPrivate.post("companyadmin/organisation/vendor/get-vendor-details", { chooseTypeValue: companyPan, chooseType: chooseType, PBName: "customer" }).then((res) => {

        if (res.status === 200) {
          setLoadingScreen(false)
          setMainApiData({
            ...mainApiData,
            isActive: "Yes",
            ...res.data.vendorMainDetails,
          })

          setShowAlertBox(true);
          setShowType("success");
          setShowMessage(res.data.message);
          clearAlert('');
          // setIsFormDirty(false)
          // dispatch(resetFormModified(false));
        }
      });
    } catch (error: any) {
      setLoadingScreen(false)
      setShowAlertBox(true);
      setShowType("danger");
      setShowMessage(error.response.data.error);
      clearAlert('');
    }
  }


  async function getUserData() {
    // showPage === "AdminDetails"
    await axiosPrivate.get(`bank-admin/company/${editData?.CompanyData?.companyData?.id}`)
      .then(response => {
        getEditData(response.data.results);
      })
      .catch(error => {
        console.error(error);
      });
  }

  async function sendDataApi() {
    const address = {
      "country": mainApiData?.country,
      "pinCode": mainApiData?.pinCode,
      "address": mainApiData?.address,
      "website": mainApiData?.website,
      "city": mainApiData?.city,
      "stateId": mainApiData?.stateId,
      "cityId": mainApiData?.cityId,
      "state": mainApiData?.state,
    }
    const customerDetails = {
      "country": mainApiData?.country,
      "pinCode": mainApiData?.pinCode,
      "address": mainApiData?.address,
      "website": mainApiData?.website,
      "city": mainApiData?.city,
      "stateId": mainApiData?.stateId,
      "cityId": mainApiData?.cityId,
      "state": mainApiData?.state,
      "customerName": mainApiData?.customerName,
      "customerCode": mainApiData?.customerCode,
      "companyConstitutionId": mainApiData?.companyConstitutionId,
      "businessCategoryId": mainApiData?.businessCategoryId,
      "typesOfBillingCycleId": mainApiData?.typesOfBillingCycleId,
      "noOfdays": mainApiData?.noOfdays,
      "isActive": mainApiData?.isActive,
      "remarks": mainApiData?.remarks,
      "status": mainApiData?.status,
      "declineRemarks": mainApiData?.declineRemarks,
    }

    const finalData = {
      "messageType": "Action",
      "chooseType": mainApiData?.chooseType === (undefined) ? "PAN" : mainApiData?.chooseType,
      "chooseTypeValue": mainApiData.chooseTypeValue,
      "PBName": "customer",
      "customerOverView": {
        ...customerDetails,
        // status: "Pending Approval",
        // approvedBy: ""
      },
      "address": { ...address },
      "pointOfContact": { ...businessContactMain },
      "bankDetails": { ...bankRelationshipMain }

    }
    setLoadingScreen(true)
    try {
      const res = isEditMode ? await axiosPrivate.patch(`companyadmin/organisation/update-customer/${editData?.customerOverviewId}`, finalData) : await axiosPrivate.post("companyadmin/organisation/create-customer", finalData)
      if (res.status === 200) {
        setLoadingScreen(false)
        setShowAlertBox(true);
        setShowType("success");
        setShowMessage(res.data.message);
        clearAlert(res.status);
        dispatch(resetFormModified(false));
        getUserData();
        setIconStatus({
          CustomerIcon: "completed",
          addressIcon: "completed",
          pointofContact: "completed",
          Approval: "completed",
          adminIcon: "completed",
         
        });
      }
      clearAlert('');
    }
    catch (error: any) {
      setLoadingScreen(false)
      setShowAlertBox(true);
      setShowType("danger");
      setShowMessage(error.response.data.message);
      clearAlert('');
    }
  }

  async function getVendorBankApprovalDetails() {
    try {
      const response = await axiosPrivate.get(`companyadminvendor/grid/${editData?.vendorOverView?.vendorOverviewId}`);
      setVendorBankApprovalDetails(response.data.results)
    } catch (error) {
    }
  }


  const clearAlert = (status: any) => {
    const timer = setTimeout(() => {
      setShowAlertBox(false);
      setShowMessage("");
      if (status === 200) {
        // (!isEditMode || showPage === "AdminDetails") &&
        onboardingViewfun("grid");
      }
      clearTimeout(timer);
    }, 5000);
  }


  const handleStepClick = (step: any) => () => {
    if (iconStatus[step.icon] === "notworking") return;
    if (!isEditMode && iconStatus[step.icon] !== "completed") {
      if (isFormModified && showPage !== step.page) {
        if (!isEditMode) {
          // setShowChangesModalEdit(true);
          setSetNextNav(step.page);
        }
      } else {
        // handleSideBar(step.page); 
        setShowPage(step.page);
      }
    } else {
      // if (isFormModified && showPage !== step.page) {
      //   // setShowChangesModalEdit(true);
      //   setSetNextNav(step.page);
      // } else {
      setShowPage(step.page);
      // }
    }
  };

  const navPageDestination = () => {
    setShowPage(setNextNav);
    setShowChangesModalEdit(false);
    // dispatch(resetFormModified(false));
    setSetNextNav('');
    getUserData()


  };

  const steps = [
    { image: <BsBuildingExclamation style={{ color: "#0055d4", fontSize: "25px", marginTop: "3px" }} />, heading: "Customer Overview", page: "CustomerOverview", icon: "CustomerIcon", isManditary: false },
    { image: <RiBankFill style={{ color: "#0055d4", fontSize: "27px", marginTop: "3px" }} />, heading: "Bank Details", page: "BankDetails", icon: "adminIcon"},
    
    { image: <FontAwesomeIcon icon={faMapLocationDot} style={{ color: "#0055d4", fontSize: "25px", marginTop: "3px" }} />, heading: "Address", page: "Address", icon: "addressIcon", isManditary: false },
    { image: <BsFillPersonLinesFill style={{ color: "#0055d4", fontSize: "25px", marginTop: "3px" }} />, heading: "Point of Contact", page: "pointofContact", icon: "pointofContact", isManditary: false },
    { image: <ApprovalIcon className='approval-icon' style={{ color: "#0055d4", fontSize: "25px", marginTop: "3px" }} />, heading: "Approval", page: "Approval", icon: "Approval", isManditary: false },
    // { image: <BsFileEarmarkCheckFill style={{ color: "#0055d4", fontSize: "25px", marginTop: "3px" }} />, heading: "Statutory Details", page: "StatutoryDetails", icon: "statutoryIcon", isManditary: true },
    // { image: <BankRelationshipImg className='stepper-image' />, heading: "Bank's Relationship", page: "BankRelationshipManager", icon: "relationshipIcon" },
    // { image: <BsPersonFillGear style={{ color: "#0055d4", fontSize: "27px", marginTop: "3px" }} />, heading: "Admin Details", page: "AdminDetails", icon: "adminIcon", isManditary: true },
    // { image: <OtherBusinessContactImg className='stepper-image' />, heading: "Other Business Contact", page: "PointofContact", icon: "businessIcon" },
    // { image: <SubscriptionValidityImg className='stepper-image' />, heading: "Subscription Validity", page: "SubscriptionValidity", icon: "subscriptionIcon" },
    // ... if other steps ...
  ];

  return (
    <>

      <div className='company-form'>
        <div className='navigation-header' style={{ height: "10vh", }}>
          <div className='left-header'>
            <NavigateHeader firstValue={"Approval"}
              style={{ cursor: "pointer" }}
              secondValue={"Customer"} navigatePage={
                isFormModified
                  ? () => {
                    setShowChangesModalcancel(true);
                  } : () => {
                    // dispatch(resetFormModified(false));
                    onboardingViewfun("grid");
                  }
              }
            />
          </div>
        </div>
        <div className='form-contents' style={{ height: "63vh", width: '100%', }}>
          <div style={{ width: '300px', height: '63vh' }}>
            <div className='side-navigation-main-company'>
              <div className='position-stepper-list'>
                {steps.map((step, index) => (
                  <div className='stepper-main' key={index}>
                    <div className='flex-image-text' onClick={handleStepClick(step)}>
                      <div>{step.image}</div>
                      <div className='heading-icon'>
                        <div className={`stepper-heading ${showPage === step.page ? "active" : ""}`}>
                          {step.heading}
                          {step.isManditary &&
                            <span style={{ color: "red" }}>*</span>
                          }
                        </div>
                      </div>
                    </div>
                    <div className='stepper-icon'>
                      {iconStatus[step.icon] === "notworking" && <Notworking className='icon-tick' />}
                      {iconStatus[step.icon] === "selected" && <Progress className='icon-tick' />}
                      {iconStatus[step.icon] === "completed" && <Correct className='icon-tick' />}
                    </div>
                    {index !== steps.length - 1 &&
                      <div className='stepper-line'></div>
                    }
                  </div>
                ))}
              </div>
            </div>

          </div>
          {
            showPage === "CustomerOverview" &&
            <CustomerOverview
              setIsFormDirty={setIsFormDirty}
              ref={sideStatusRef}
              mainApiData={mainApiData}
              setMainApiData={setMainApiData}
              setShowPage={setShowPage}
              showPage={showPage}
              iconStatus={iconStatus}
              setIconStatus={setIconStatus}
              fetchDatas={(value: any) => {
                fetchDataFunction(companyOverviewPan, chooseType)
              }}
              sendDataApi={sendDataApi}
              onboardingViewfun={onboardingViewfun}
              companyOverviewPan={companyOverviewPan}
              setCompanyOverviewPan={setCompanyOverviewPan}
              setChooseType={setChooseType}
              chooseType={chooseType}
              isEditMode={isEditMode}
              editData={editData}
            />
          }
          {
            showPage === "BankDetails" &&
            <BankDetails
              ref={sideStatusRef}
              mainApiData={mainApiData}
              sendDataApi={sendDataApi}
              editData={editData}
              bankRelationshipMain={bankRelationshipMain}
              setBankRelationshipMain={setBankRelationshipMain}
              setShowPage={setShowPage}
              iconStatus={iconStatus}
              setIconStatus={setIconStatus}
              onboardingViewfun={onboardingViewfun}
              isEditMode={isEditMode}
            />
          }

          {
            showPage === "Address" &&
            <Address
              ref={sideStatusRef}
              mainApiData={mainApiData}
              setMainApiData={setMainApiData}
              onboardingViewfun={onboardingViewfun}
              sendDataApi={sendDataApi}
              setShowPage={setShowPage}
              iconStatus={iconStatus}
              editData={editData}
              setIconStatus={setIconStatus}
              isEditMode={isEditMode}
            />
          }
          {
            showPage === "pointofContact" &&
            <PointOfContact
              editData={editData}
              setShowPage={setShowPage}
              iconStatus={iconStatus}
              setIconStatus={setIconStatus}
              onboardingViewfun={onboardingViewfun}
              sendDataApi={sendDataApi}
              setBusinessContactMain={setBusinessContactMain}
              businessContactMain={businessContactMain}
              isEditMode={isEditMode}
              mainApiData={mainApiData}
            // setMainApiData={setMainApiData}
            />
          }

          {
            showPage === "Approval" &&
            <Approval
              ref={sideStatusRef}
              mainApiData={mainApiData}
              setMainApiData={setMainApiData}
              onboardingViewfun={onboardingViewfun}
              sendDataApi={sendDataApi}
              setShowPage={setShowPage}
              iconStatus={iconStatus}
              editData={editData}
              setIconStatus={setIconStatus}
              isEditMode={isEditMode}
            />
          }
        </div>
        {
          loadingScreen &&
          <Loading />
        }
        {
          showAlertBox &&
          <div className='alert-warp'>
            <AlertBox type={showType} message={showMessage} />
          </div>

        }
        {
          showChangesModalcancel &&
          <Changesmodal
            closeModal={() => setShowChangesModalcancel(false)}
            leavePage={() => {
              setShowChangesModalcancel(false);
              dispatch(resetFormModified(false));
              setIsFormDirty(false)
              onboardingViewfun("grid")
              // getEditMode(false)
            }}
            handleClose={() => setShowChangesModalcancel(false)} />
        }
        {
          showChangesModalEdit &&
          <Changesmodal
            closeModal={() => setShowChangesModalEdit(false)}
            leavePage={navPageDestination}
            handleClose={() => setShowChangesModalEdit(false)} />
        }
      </div>
    </>
  )

}
