import MasterApprovalVendorForm from '../../components/approval/vendor-approval/approval-form';
import MasterApprovalVendorGrid from '../../components/approval/vendor-approval/approval-grid';
import MasterVendorForm from '../../components/organisation/vendor/vendor-form';
import MasterVendorGrid from '../../components/organisation/vendor/vendor-grid';

import React, { Component } from 'react'

interface StateValues {
  onboardingView: string;
  editData: any;
  isEditMode: boolean;
  editedShowPage: string;

}
export default class MasterVendorApproval extends Component<{}, StateValues> {

  constructor(props: StateValues) {
    super(props);
    this.state = {
      onboardingView: "grid",
      editedShowPage: "",
      editData: {},
      isEditMode: false,
    };
    this.addMode = this.addMode.bind(this);
  }

  addMode = () => {
    this.setState({
      // onboardingView: "form",
      editData: {},
      isEditMode: false,
      editedShowPage: "BasicDetails"
    })
  }

  render() {
    return (
      <div>
        {
          this.state.onboardingView === "form" &&
          <MasterApprovalVendorForm
            onboardingViewfun={(val) => {
              this.setState({ onboardingView: val })
            }}
            editData={this.state.editData}
            isEditMode={this.state.isEditMode}
            editedShowPage={this.state.editedShowPage}
            getEditData={(data: any) => this.setState({
              editData: { ...data },
              // isEditMode: false,
            })}
          />
        }
        {
          this.state.onboardingView === "grid" &&
          <MasterApprovalVendorGrid
            onboardingViewfun={(val) => {
              this.setState({ onboardingView: val })
            }}
            addMode={this.addMode}
            getEditData={(nav: any, data: any) => this.setState({
              onboardingView: nav,
              editData: { ...data },
              isEditMode: true,
            })}
            editedShowPageFun={(val: any) => {
              this.setState({
                editedShowPage: val
              })
            }}
            getDoubleClickData={(data) => {
              this.setState({
                editData: { ...data },
                isEditMode: true,
                editedShowPage: "BasicDetails"
              })
            }}

          />
        }


      </div>

    )
  }
}

