import React, { useEffect, useState } from 'react'
import IconButton from '../../../common-component/form-elements/icon-button';
import { CiSearch } from 'react-icons/ci';
import ChartFilter from '../../../common-component/charts/chart-filter';
import { axiosPrivate } from '../../../../middleware/axois-api';
import '../../../../styles/reports/report-table.scss';
import { format } from 'date-fns';
import { UserReportFilter } from '../../../../config/reports/master-reports/user-report-config';


interface props {
    searchBarValue: string
    filterOpen: any
    setFilterOpen: any
    conponentPDFMaster: any

}
export default function UserGrid({ searchBarValue, filterOpen, setFilterOpen, conponentPDFMaster }: props) {
    const [searchValue, setSearchValue] = useState<string>('');
    const [filteredData, setFilteredData] = useState<any[]>([]);
    const [orginalFilteredData, setOrginalFilteredData] = useState<any[]>([]);
    const [openDropdownIndex, setOpenDropdownIndex] = useState<number | null>(null);

    const [storeFilterOptions, setStoreFilterOptions] = useState<any>([]);
    const [filterValue, setFilterValues] = useState<any>([])
    const [orginalRowData, setOrginalRowData] = useState<any[]>([]);


    const DateTimeConverter = (date: any) => {
        if (date !== "NA") {
            if (date) {

                const originalDate = new Date(date);
                // Formatting date to "01-Jul-2023"
                return format(originalDate, "dd-MMM-yyyy HH:mm");
            }
        }
        return "NA"
    }

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setSearchValue(value);
        if (value === "") {
            fetchData()
        } else {
            // Filter data based on the search value
            const filtered = orginalRowData.filter((item) => {
                const UserDetails: any = item.UserDetails;
                if (
                    UserDetails &&
                    (UserDetails["Name"]?.toLowerCase().includes(value.toLowerCase()) ||
                        UserDetails["Email ID"]?.toLowerCase().includes(value.toLowerCase()))
                ) {
                    // Return an object containing the matched field and its value
                    return {
                        matchedField: UserDetails["Name"]?.toLowerCase().includes(value.toLowerCase())
                            ? "Email ID"
                            : "Name",
                        value: UserDetails["Name"] || UserDetails["Email ID"],
                    };
                }

                return null;
            });

            setFilteredData(filtered);
        }
    };

    useEffect(() => {
        // Filter data based on the search value
        const filtered = orginalRowData.filter((item) => {
            const UserDetails: any = item.UserDetails;
            if (
                UserDetails &&
                (UserDetails["Name"]?.toLowerCase().includes(searchBarValue.toLowerCase()) ||
                    UserDetails["Email ID"]?.toLowerCase().includes(searchBarValue.toLowerCase()))
            ) {
                // Return an object containing the matched field and its value
                return {
                    matchedField: UserDetails["Name"]?.toLowerCase().includes(searchBarValue.toLowerCase())
                        ? "Email ID "
                        : "Name",
                    value: UserDetails["Name"] || UserDetails["Email ID"],
                };
            }

            return null;
        });

        setFilteredData(filtered);

    }, [searchBarValue]);

    const fetchData = async () => {
        try {
            const response = await axiosPrivate.get('/companyadmin/master-reports/user');
            setFilteredData(response.data.userDetails)
            setOrginalRowData(response.data.userDetails)
            addDataForDropDownFilter(response.data.dropDownDatas)
        } catch (error) {
        }
    }
    // filter functions

    useEffect(() => {
        handleApplyButtonForFilter(UserReportFilter["User"])
        setStoreFilterOptions(UserReportFilter["User"])
        setFilterValues(UserReportFilter["User"])
        fetchData()
    }, [])

    const handleOpenClose = (index: number) => {
        if (openDropdownIndex === index) {
            setOpenDropdownIndex(null); // Close the dropdown if it's already open
        } else {
            setOpenDropdownIndex(index); // Open the dropdown
        }
    };

    const updateFilterDatas = async (index: number, newData: any) => {
        setStoreFilterOptions((prevFilterDatas: any) => {
            const updatedFilterDatas = [...prevFilterDatas];
            updatedFilterDatas[index] = newData;
            return updatedFilterDatas;
        });

        setFilterValues((prevFilterDatas: any) => {
            const updatedFilterDatas = [...prevFilterDatas];
            updatedFilterDatas[index] = newData;
            return updatedFilterDatas;
        });
    };

    function addDataForDropDownFilter(res: any) {
        if (Object.keys(res).length > 0) {

            const updatedOrganization: any = [
                { value: "All", label: "All" },
                ...res['Organisation'].map((elem: any) => ({ value: elem, label: elem }))
            ];
            const updatedCompany: any = [
                { value: "All", label: "All" },
                ...res['Company'].map((elem: any) => ({ value: elem, label: elem }))
            ];
            const updatedDepartment: any = [
                { value: "All", label: "All" },
                ...res['Department'].map((elem: any) => ({ value: elem, label: elem }))
            ];
            const updatedGroup: any = [
                { value: "All", label: "All" },
                ...res['Group'].map((elem: any) => ({ value: elem, label: elem }))
            ];
            const updatedName: any = [
                { value: "All", label: "All" },
                ...res['Name'].map((elem: any) => ({ value: elem, label: elem }))
            ];
            const updatedEmailID: any = [
                { value: "All", label: "All" },
                ...res['Email ID'].map((elem: any) => ({ value: elem, label: elem }))
            ];
            const updatedContactNumber: any = [
                { value: "All", label: "All" },
                ...res['Contact Number'].map((elem: any) => ({ value: elem, label: elem }))
            ];
            const updatedReportingTo: any = [
                { value: "All", label: "All" },
                ...res['Reporting To'].map((elem: any) => ({ value: elem, label: elem }))
            ];
            const updatedLastLogin: any = [
                { value: "All", label: "All" },
                ...res['Last Login'].map((elem: any) => ({ value: elem, label: elem }))
            ];
            const updatedInactiveDays: any = [
                { value: "All", label: "All" },
                ...res['Inactive Days'].map((elem: any) => ({ value: elem, label: elem }))
            ];
            const updatedIsActive: any = [
                { value: "All", label: "All" },
                ...res['Is Active'].map((elem: any) => ({ value: elem, label: elem }))
            ];
            const updatedIsAccountLocked: any = [
                { value: "All", label: "All" },
                ...res['Is Account Locked'].map((elem: any) => ({ value: elem, label: elem }))
            ];

            setStoreFilterOptions((prevOptions: any) => {
                return prevOptions.map((option: any) => {
                    switch (option.cardTitle) {
                        case "Organisation":
                            return { ...option, options: updatedOrganization };
                        case "Company":
                            return { ...option, options: updatedCompany };
                        case "Department":
                            return { ...option, options: updatedDepartment };
                        case "Group":
                            return { ...option, options: updatedGroup };
                        case "Name":
                            return { ...option, options: updatedName };
                        case "Email ID":
                            return { ...option, options: updatedEmailID };
                        case "Contact Number":
                            return { ...option, options: updatedContactNumber };
                        case "Reporting To":
                            return { ...option, options: updatedReportingTo };
                        case "Last Login":
                            return { ...option, options: updatedLastLogin };
                        case "Inactive Days":
                            return { ...option, options: updatedInactiveDays };
                        case "Is Active":
                            return { ...option, options: updatedIsActive };
                        case "Is Account Locked":
                            return { ...option, options: updatedIsAccountLocked };

                        default:
                            return option;
                    }
                });
            });

            setFilterValues((prevOptions: any) => {
                return prevOptions.map((option: any) => {
                    switch (option.cardTitle) {
                        case "Organisation":
                            return { ...option, options: updatedOrganization };
                        case "Company":
                            return { ...option, options: updatedCompany };
                        case "Department":
                            return { ...option, options: updatedDepartment };
                        case "Group":
                            return { ...option, options: updatedGroup };
                        case "Name":
                            return { ...option, options: updatedName };
                        case "Email ID":
                            return { ...option, options: updatedEmailID };
                        case "Contact Number":
                            return { ...option, options: updatedContactNumber };
                        case "Reporting To":
                            return { ...option, options: updatedReportingTo };
                        case "Last Login":
                            return { ...option, options: updatedLastLogin };
                        case "Inactive Days":
                            return { ...option, options: updatedInactiveDays };
                        case "Is Active":
                            return { ...option, options: updatedIsActive };
                        case "Is Account Locked":
                            return { ...option, options: updatedIsAccountLocked };

                        default:
                            return option;
                    }
                });
            });

        }
    }

    const convertToFilterValues = (filterData: any) => {
        const filterValues: any = {};
        filterData.forEach((filter: any) => {
            const tittle = (filter.cardTitle).replace(/\s/g, '')
            filterValues[tittle] = filter.selectedValue;
        });
        return filterValues;
    };

    async function handleApplyButtonForFilter(data: any) {
        const convertValue = convertToFilterValues(filterValue)
        if (
            convertValue['Organisation'] == "All" &&
            convertValue['Company'] == "All" &&
            convertValue['Department'] == "All" &&
            convertValue['Group'] == "All" &&
            convertValue['Name'] == "All" &&
            convertValue['EmailID'] == "All" &&
            convertValue['ContactNumber'] == "All" &&
            convertValue['ReportingTo'] == "All" &&
            convertValue['LastLogin'] == "All" &&
            convertValue['InactiveDays'] == "All" &&
            convertValue['IsActive'] == "All" &&
            convertValue['IsAccountLocked'] == "All"

        ) {
            setFilteredData(orginalRowData)
        } else {
            const filteredData: any = orginalRowData && orginalRowData.filter((ele: any) => {
                const OrganisationCondition = convertValue['Organisation'] === 'All' || ele.EntityInformation['Organisation'] === convertValue['Organisation'];
                const CompanyCondition = convertValue['Company'] === 'All' || ele.EntityInformation['Company'] === convertValue['Company'];
                const DepartmentCondition = convertValue['Department'] === 'All' || ele.EntityInformation['Department'] === convertValue['Department'];
                const GroupCondition = convertValue['Group'] === 'All' || ele.EntityInformation['Group'] === convertValue['Group'];
                const NameCondition = convertValue['Name'] === 'All' || ele.UserDetails['Name'] === convertValue['Name'];
                const EmailIDCondition = convertValue['EmailID'] === 'All' || ele.UserDetails['Email ID'] === convertValue['EmailID'];
                const ContactNumberCondition = convertValue['ContactNumber'] === 'All' || ele.UserDetails['Contact Number'] === convertValue['ContactNumber'];
                const ReportingToCondition = convertValue['ReportingTo'] === 'All' || ele.UserDetails['Reporting To'] === convertValue['ReportingTo'];
                const LastLoginCondition = convertValue['LastLogin'] === 'All' || ele.LoginDetails['Last Login'] === convertValue['LastLogin'];
                const InactiveDaysCondition = convertValue['InactiveDays'] === 'All' || ele.LoginDetails['Inactive Days'] === convertValue['InactiveDays'];
                const IsActiveCondition = convertValue['IsActive'] === 'All' || ele.Status['Is Active'] === convertValue['IsActive'];
                const IsAccountLockedCondition = convertValue['IsAccountLocked'] === 'All' || ele.Status['Is Account Locked'] === convertValue['IsAccountLocked'];

                return OrganisationCondition
                    && CompanyCondition
                    && DepartmentCondition
                    && GroupCondition
                    && NameCondition
                    && EmailIDCondition
                    && ContactNumberCondition
                    && ReportingToCondition
                    && LastLoginCondition
                    && InactiveDaysCondition
                    && IsActiveCondition
                    && IsAccountLockedCondition

            });
            setFilteredData(filteredData);
        }


    }

    // filter functions end

    return (
        <>

            <div className='report-table-component' style={{ marginTop: "20px", overflowX: "auto" }}>
                <table ref={conponentPDFMaster}>
                    <thead>
                        {/* <tr> */}
                        <th className='report-heading' style={{ paddingLeft: "10px" }}>Entity Information</th>
                        <th className='report-heading' style={{ paddingLeft: "10px" }}>User Details</th>
                        <th className='report-heading' style={{ paddingLeft: "10px" }}>Login Details</th>
                        <th className='report-heading' style={{ paddingLeft: "10px" }}>Status</th>
                        {/* </tr> */}
                    </thead>
                    <tbody>
                        {
                            filteredData?.length === 0 ?
                                <tr className='style-grid-differ'>

                                    <td></td>
                                    <td style={{ textAlign: "center" }}>No records to show</td>
                                    <td></td>
                                    <td></td>
                                </tr> :

                                filteredData?.map((data: any, index: any) => (
                                    <tr className='main-body-header' key={index} >
                                        <td className='report-details' >
                                            {Object.entries(data?.EntityInformation).map(([key, value]: any) => (
                                                <p key={key} style={{ whiteSpace: "normal", width: "250px" }}>
                                                    <i>{key}</i>  - {value}
                                                </p>
                                            ))}
                                        </td>
                                        <td className='report-details' >
                                            {Object.entries(data?.UserDetails).map(([key, value]: any) => (
                                                <p key={key} style={{ width: "350px", whiteSpace: "normal" }}>
                                                    <i>{key}</i> - {value}
                                                </p>
                                            ))}
                                        </td>

                                        <td className='report-details' >
                                            {Object.entries(data?.LoginDetails).map(([key, value]: any) => (
                                                <p key={key} style={{ width: "260px", whiteSpace: "normal" }} >

                                                    <i>{key}</i> - {key == "Last Login" ? DateTimeConverter(value) : value}

                                                </p>
                                            ))}
                                        </td>
                                        <td className='report-details' >
                                            {Object.entries(data?.Status).map(([key, value]: any) => (
                                                <p key={key} style={{ width: "200px", whiteSpace: "normal" }}>
                                                    {/* <> */}
                                                    <i>{key}</i> - {value}
                                                    {/* </> */}
                                                </p>
                                            ))}
                                        </td>
                                    </tr>
                                ))
                        }
                    </tbody>


                </table>
            </div>

            {filterOpen &&
                <div className="right-side-filter-component" >
                    <ChartFilter
                        datas={storeFilterOptions}
                        updateFilterDatas={updateFilterDatas}
                        handleOpenClose={handleOpenClose}
                        openDropdownIndex={openDropdownIndex}
                        handleApplyButtonForFilter={() => { handleApplyButtonForFilter("") }}
                        setOpenRightSideFilter={() => { setFilterOpen(false) }}
                    />
                </div>
            }
        </>
    )
}




