import React, { useEffect, useState } from 'react'
import IconButton from '../../../common-component/form-elements/icon-button';
import { CiSearch } from 'react-icons/ci';
import ChartFilter from '../../../common-component/charts/chart-filter';
import { SummaryReportFilter } from '../../../../config/reports/vendor-payable-reports/summary-report-config';
import { formatAmount } from '../../../../helpers/amountRenderer';
import { axiosPrivate } from '../../../../middleware/axois-api';
interface prpos {
    handleDoubleClick: any;
    searchBarValue: string
    filterOpen: any
    setFilterOpen: any
    conponentPDFInvoice: any
    getVendorOverViewIdFun: (value: any) => void
    selectedVendorPayableFun: (value: any) => void
    searchBarValueNameFun: (value: any) => void
}

export default function VendorSummaryTable({ getVendorOverViewIdFun, searchBarValueNameFun, selectedVendorPayableFun, handleDoubleClick, searchBarValue, filterOpen, setFilterOpen, conponentPDFInvoice }: prpos) {
    const [searchValue, setSearchValue] = useState<string>('');
    const [filteredData, setFilteredData] = useState<any[]>([]);
    const [vendorID, setVendorID] = useState<any>("");

    // const [filterOpen, setFilterOpen] = useState<boolean>(false);

    const [storeFilterOptions, setStoreFilterOptions] = useState<any>([]);
    const [filterValue, setFilterValues] = useState<any>([])
    const [orginalRowData, setOrginalRowData] = useState<any[]>([]);
    const [openDropdownIndex, setOpenDropdownIndex] = useState<number | null>(null);


    useEffect(() => {
        handleApplyButtonForFilter(SummaryReportFilter["Summary"])
        setStoreFilterOptions(SummaryReportFilter["Summary"])
        setFilterValues(SummaryReportFilter["Summary"])
        fetchData()
    }, [])

    const fetchData = async () => {
        try {
            const response = await axiosPrivate.get(`companyadmin/vendor-payable-reports/summary/${vendorID}`);
            setOrginalRowData(response.data.payableInvoiceMainDatas)
            setFilteredData(response.data.payableInvoiceMainDatas)
            addDataForDropDownFilter(response.data.dropDownDatas)
        } catch (error) {
        }
    };

    const convertToFilterValues = (filterData: any) => {
        const filterValues: any = {};
        filterData.forEach((filter: any) => {
            const tittle = (filter.cardTitle).replace(/\s/g, '')
            filterValues[tittle] = filter.selectedValue;
        });
        return filterValues;
    };

    function addDataForDropDownFilter(res: any) {
        try {
            if (Object.keys(res).length > 0) {
                const updatedVendorCode: any = [
                    { value: "All", label: "All" },
                    ...res['Vendor Code'].map((elem: any) => ({ value: elem, label: elem }))
                ];
                const updatedName: any = [
                    { value: "All", label: "All" },
                    ...res['Vendor Name'].map((elem: any) => ({ value: elem, label: elem }))
                ];


                setStoreFilterOptions((prevOptions: any) => {
                    return prevOptions.map((option: any) => {
                        switch (option.cardTitle) {
                            case "Vendor Code":
                                return { ...option, options: updatedVendorCode };
                            case "Vendor Name":
                                return { ...option, options: updatedName };
                            default:
                                return option;
                        }
                    });
                });

                setFilterValues((prevOptions: any) => {
                    return prevOptions.map((option: any) => {
                        switch (option.cardTitle) {
                            case "Vendor Code":
                                return { ...option, options: updatedVendorCode };
                            case "Vendor Name":
                                return { ...option, options: updatedName };
                            default:
                                return option;
                        }
                    });
                });
            }
        } catch (error) {
            console.log("error", error)
        }
    }

    async function handleApplyButtonForFilter(data: any) {
        const convertValue = convertToFilterValues(filterValue)
        if (
            convertValue['VendorCode'] == "All" &&
            convertValue['vendorName'] == "All"
        ) {
            setFilteredData(orginalRowData)
        } else {
            const filteredData: any = orginalRowData && orginalRowData.filter((ele: any) => {
                const VendorNameCondtion = convertValue['VendorName'] === 'All' || ele.VendorDetails['Vendor Name'] === convertValue['VendorName'];
                const VendorCodeCondtion = convertValue['VendorCode'] === 'All' || ele.VendorDetails['Vendor Code'] === convertValue['VendorCode'];
                return VendorNameCondtion
                    && VendorCodeCondtion
            });
            setFilteredData(filteredData);
        }
        // setFilterOpen(false)

    }

    const updateFilterDatas = async (index: number, newData: any) => {
        setStoreFilterOptions((prevFilterDatas: any) => {
            const updatedFilterDatas = [...prevFilterDatas];
            updatedFilterDatas[index] = newData;
            return updatedFilterDatas;
        });
        setFilterValues((prevFilterDatas: any) => {
            const updatedFilterDatas = [...prevFilterDatas];
            updatedFilterDatas[index] = newData;
            return updatedFilterDatas;
        });
    }

    const handleOpenClose = (index: number) => {
        if (openDropdownIndex === index) {
            setOpenDropdownIndex(null); // Close the dropdown if it's already open
        } else {
            setOpenDropdownIndex(index); // Open the dropdown
        }
    };

    // two data filter
    useEffect(() => {
        const filtered = orginalRowData.filter((item: any) => {
            const VendorDetails = item.VendorDetails;

            if (
                VendorDetails &&
                (VendorDetails["Vendor Name"]?.toLowerCase().includes(searchBarValue.toLowerCase()) ||
                    VendorDetails["Vendor Code"]?.toLowerCase().includes(searchBarValue.toLowerCase()))
            ) {
                // Return an object containing the matched field and its value
                return {
                    matchedField: VendorDetails["Vendor Name"]?.toLowerCase().includes(searchBarValue.toLowerCase())
                        ? "Vendor Name"
                        : "Vendor Code",
                    value: VendorDetails["Vendor Name"] || VendorDetails["Vendor Code"],
                };
            }

            return null;
        });
        setFilteredData(filtered);
    }, [searchBarValue])



    useEffect(() => {
        fetchData()
    }, [])



    return (
        <>
            <div>
                <div className='report-table-component' style={{ marginTop: "20px", overflowX: "auto" }}>
                    <table ref={conponentPDFInvoice}>
                        <thead>
                            <th className='report-heading' style={{ paddingLeft: "10px", width: "300px" }}>Vendor Details</th>
                            <th className='report-heading' style={{ paddingLeft: "10px" }}>#Invoice</th>
                            <th className='report-heading' style={{ paddingLeft: "10px" }}>Amount Details</th>
                            <th className='report-heading' style={{ paddingLeft: "10px" }}>Pending Invoice Status</th>

                        </thead>
                        <tbody>
                            {

                                filteredData.length === 0 ?
                                    <tr className='style-grid-differ'>
                                        <td></td>
                                        <td style={{ textAlign: "center" }}>No records to show</td>
                                        <td></td>
                                        <td></td>

                                    </tr> :

                                    filteredData.map((data: any, index: number) => (
                                        <tr className='main-body-header' key={index} onDoubleClick={(event) => {
                                            getVendorOverViewIdFun(data.vendorOverviewId)
                                            selectedVendorPayableFun("Invoices Details")
                                            searchBarValueNameFun(data.VendorDetails["Vendor Name"])

                                        }} style={{ cursor: "pointer" }} >
                                            <td className='report-details' >
                                                {Object.entries(data.VendorDetails).map(([key, value]: any) => (
                                                    <p key={key} style={{ whiteSpace: "normal", width: "300px" }}>
                                                        <i>{key}</i>  - {value}
                                                    </p>
                                                ))}
                                            </td>
                                            <td className='report-details' >
                                                {Object.entries(data.Invoice).map(([key, value]: any) => (
                                                    <div key={key} style={{ whiteSpace: "normal", display: "flex", gap: "45px" }}>

                                                        <i style={{ width: "60px" }}>{key}</i> -
                                                        <p >{value}</p>
                                                    </div>
                                                ))}
                                            </td>
                                            <td className='report-details' >
                                                {Object.entries(data.AmountDetails).map(([key, value]: any) => (
                                                    <div style={{ display: "flex", width: "280px" }}>
                                                        <p style={{ width: "115px" }} key={key} >
                                                            <i>{key}</i>
                                                        </p>
                                                        <p>:</p>
                                                        <p style={{ textAlign: "right", width: "130px" }}> {formatAmount(value)}</p>
                                                    </div>
                                                ))}
                                            </td>
                                            <td className='report-details' >
                                                {Object.entries(data.PendingInvoiceStatus).map(([key, value]: any) => (
                                                    <p key={key} style={{ width: "260px", whiteSpace: "normal" }}>
                                                        <i>{key}</i> - {value}
                                                    </p>
                                                ))}
                                            </td>
                                        </tr>
                                    ))}
                        </tbody>
                    </table>
                </div>
            </div>
            {filterOpen &&
                <div className="right-side-filter-component">
                    <ChartFilter
                        datas={storeFilterOptions}
                        updateFilterDatas={updateFilterDatas}
                        handleOpenClose={handleOpenClose}
                        openDropdownIndex={openDropdownIndex}
                        handleApplyButtonForFilter={() => { handleApplyButtonForFilter("") }}
                        setOpenRightSideFilter={() => { setFilterOpen(false) }}
                    />
                </div>
            }
        </>
    )
}

export const SampleDatasSummary = [
    {
        "VendorDeatils": {
            "Vendor Code": "1",
            "Name": "FARMER PRODUCER COMPANY LIMITED FARMER PRODUCER COMPANY LIMITED",

        },
        "Invoice": {
            "Total": "02",
            "Paid": "01",
            "Outstanding": "01",
            "Overdue": "00",

        },

        "AmountDetails": {
            "Total": "3,240.00",
            "Paid": "2,160.00",
            "Outstanding": "1,080.00",
            "Overdue": "1,080.00",


        },
        "PendingInvoiceStatus": {
            "Checker": "Nill",
            "Recommender": "Nill",
            "Approver": "Nill",
            "Payment": " 1 Queued",

        },

    },

    {
        "VendorDeatils": {
            "Vendor Code": "2",
            "Name": "FARMER PRODUCER COMPANY LIMITED FARMER PRODUCER COMPANY LIMITED",

        },
        "Invoice": {
            "Total": "02",
            "Paid": "01",
            "Outstanding": "01",
            "Overdue": "00",

        },

        "AmountDetails": {
            "Total": "3,240.00",
            "Paid": "2,160.00",
            "Outstanding": "1,080.00",
            "Overdue": "1,080.00",


        },
        "PendingInvoiceStatus": {
            "Checker": "Nill",
            "Recommender": "Nill",
            "Approver": "Nill",
            "Payment": " 1 Queued",

        },

    },

    {
        "VendorDeatils": {
            "Vendor Code": "3",
            "Name": "FARMER PRODUCER COMPANY LIMITED FARMER PRODUCER COMPANY LIMITED",

        },
        "Invoice": {
            "Total": "02",
            "Paid": "01",
            "Outstanding": "01",
            "Overdue": "00",

        },

        "AmountDetails": {
            "Total": "3,240.00",
            "Paid": "2,160.00",
            "Outstanding": "1,080.00",
            "Overdue": "1,080.00",


        },
        "PendingInvoiceStatus": {
            "Checker": "Nill",
            "Recommender": "Nill",
            "Approver": "Nill",
            "Payment": " 1 Queued",

        },

    },
]
