import { useCallback, useEffect, useState, useRef } from 'react'
import VerticalBarChart from '../common-component/charts/vertical-barchart';
import { coloumDataSample, cardVsTransactionValueData, cardVsTransactionValueOptions, cardVsTransactionfilterDatas, cardGroupByOptions, regionAdminGroupByOptions, RegionAdminfilterData, cardCheckBoxValue } from '../../config/dashboard/card-vs-transaction-value-config';
import Card from '../common-component/cards/card';
import ChartFilter from '../common-component/charts/chart-filter';
import useAxiosPrivate from '../../services/hooks/useaxios-private';
import ChartAgGrid, { AgGridRef } from '../common-component/charts/chart-ag-grid';
import SaveFilterModal from './save-filter-modal';
import DownloadChartDataModal from './download-chart-data-modal';
import update from 'immutability-helper'
import AlertBox from '../common-component/alert-box';
import DeleteModal from '../common-component/modals/delete-modal';
import '../../styles/dashboard/organization-vs-companies.scss';

interface propsType {
    dashBoardWidth?: any;
    setOpenOneDashboard?: any;
    isOpenEmpty?: boolean;
    setIsOpenEmpty?: any;
}

function CardVsTransactionValue({ dashBoardWidth, setOpenOneDashboard, isOpenEmpty, setIsOpenEmpty }: propsType) {
    // groupByIcon
    const [groupByOptions, setGroupByOptions] = useState<any>([]);
    const [storeGroupByOption, setStoreGroupByOption] = useState<any>({});
    const [openGroupBy, setOpenGroupBy] = useState<boolean>(false);
    // filterIcon
    const [storeFilterOptions, setStoreFilterOptions] = useState<any>([]);
    const [allFilterOptions, setAllFilterOptions] = useState<any>([]);
    const [openSideFilter, setOpenSideFilter] = useState<boolean>(false);
    // rightSideFilter
    const [allDropDownOptions, setAllDropDownOptions] = useState<any>({});
    const [openRightSideFilter, setOpenRightSideFilter] = useState<boolean>(false);
    const [openDropdownIndex, setOpenDropdownIndex] = useState<number | null>(null);
    // barChart
    const [barchartData, setBarchartData] = useState(cardVsTransactionValueData);
    const [orginalBarchartData, setOrginalBarchartData] = useState({});
    const [barchartOptions, setBarchartOptions] = useState(cardVsTransactionValueOptions);
    // gridIcon
    const [isOpenGrid, setIsOpenGrid] = useState<boolean>(false);
    const [tableColumnData, setTableColumnData] = useState<any[]>([]);
    const [tableRowData, setTableRowData] = useState<any[]>([]);
    // saveFilterModal
    const [isSaveFilterModal, setIsSaveFilterModal] = useState<boolean>(false);
    const [isApplyButton, setIsApplyButton] = useState("")
    const [allSaveFilterTableData, setAllSaveFilterTableData] = useState<any[]>([]);
    const [storeDeleteIndex, setStoreDeleteIndex] = useState("")
    const [storeSaveFilterInputData, setStoreSaveFilterInputData] = useState<any>({
        name: "",
        description: ""
    });
    // DownloadModal
    const [isDownloadModal, setIsDownloadModal] = useState<boolean>(false);
    const [switchBtnOverAll, setSwitchBtnOverAll] = useState<boolean>(true);
    const [switchBtnDetail, setSwitchBtnDetail] = useState<boolean>(false);
    const [checkBoxData, setCheckBoxData] = useState({});
    const [checkedHeaderdData, setCheckedHeaderdData] = useState([]);
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
    // alertBox
    const [showAlertBox, setShowAlertBox] = useState<boolean>(false)
    const [showType, setShowType] = useState<string>("warning")
    const [showMessage, setShowMessage] = useState<string>("")
    // initial-call-api
    const [isInitialCall, setIsInitialCall] = useState<boolean>(true);
    // axiosPrivate
    const axiosPrivate = useAxiosPrivate();
    const gridDataDownloadRef = useRef<AgGridRef>(null);

    // initialSetep
    useEffect(() => {
        const apiCall = async () => {
            var userType: any = await handleFindUserType();
            if (userType.data.userType === "region") {
                setGroupByOptions(regionAdminGroupByOptions)
                setStoreGroupByOption({
                    value: regionAdminGroupByOptions[0].value,
                    label: regionAdminGroupByOptions[0].label
                })
                setAllFilterOptions(RegionAdminfilterData)
                setStoreFilterOptions(RegionAdminfilterData[regionAdminGroupByOptions[0].value])
            } else {
                setGroupByOptions(cardGroupByOptions)
                setStoreGroupByOption({
                    value: cardGroupByOptions[0].value,
                    label: cardGroupByOptions[0].label
                })
                setAllFilterOptions(cardVsTransactionfilterDatas)
                setStoreFilterOptions(cardVsTransactionfilterDatas[cardGroupByOptions[0].value])
            }
            setCheckBoxData(cardCheckBoxValue)
            let convertDragValue: any = [];
            Object.keys(cardCheckBoxValue).map((value, i) => {
                convertDragValue.push({
                    id: i + 1,
                    name: value
                })
            })
            setCheckedHeaderdData(convertDragValue)
        }
        return () => {
            apiCall()
        };
    }, [])

    // initialApiCall
    useEffect(() => {
        if (groupByOptions && groupByOptions.length !== 0 && storeFilterOptions.length !== 0 && isInitialCall) {
            getDropDownData("All")
            getBarDataForApi(null, null)
            getSaveFilterData()
            setIsInitialCall(false)
        }
    }, [groupByOptions, storeFilterOptions])

    // handleGroupIconFunctions
    async function handleStoreGroupByOption(value: any) {
        setStoreGroupByOption({ value: value.value, label: value.label });
        setStoreFilterOptions(allFilterOptions[value.value])
        // setGroupByOptions(gropuByOptions)
        setOpenGroupBy(false)
        addDataForDropDownFilter(allDropDownOptions)
        setIsApplyButton("")
        setIsOpenGrid(false)

        const convertValue = convertToFilterValues(allFilterOptions[value.value])
        await getBarDataForApi(value.value, convertValue)
        await getDropDownData("All")
    }

    function handleOpenGroupBy() {
        setOpenGroupBy(!openGroupBy);
        setOpenSideFilter(false);
        setOpenRightSideFilter(false);
        setOpenDropdownIndex(null)
    }

    // handleFilterIconFunctions
    const handleOpenClose = (index: number) => {
        if (openDropdownIndex === index) {
            setOpenDropdownIndex(null); // Close the dropdown if it's already open
        } else {
            setOpenDropdownIndex(index); // Open the dropdown
        }
    };

    const updateFilterDatas = async (index: number, newData: any) => {
        const region = newData.cardTitle
        if (region === "Region") {
            await getDropDownData(newData.selectedValue)
        }
        setStoreFilterOptions((prevFilterDatas: any) => {
            const updatedFilterDatas = [...prevFilterDatas];
            updatedFilterDatas[index] = newData;
            return updatedFilterDatas;
        });
    };

    function addDataForDropDownFilter(res: any) {

        if (Object.keys(res).length > 0) {
            const updatedCard: any = [
                { value: "All", label: "All" },
                ...res.data.cards.map((elem: any) => ({ value: elem, label: elem }))
            ];
            const updatedYears: any = [
                { value: "All", label: "All" },
                ...res.data.years.map((elem: any) => ({ value: elem, label: elem }))
            ];
            const updatedStates: any = [
                { value: "All", label: "All" },
                ...res.data.states.map((elem: any) => ({ value: elem, label: elem }))
            ];

            setStoreFilterOptions((prevOptions: any) => {
                return prevOptions.map((option: any) => {
                    switch (option.cardTitle) {
                        case "Card":
                            return { ...option, options: updatedCard };
                        case "Year":
                            return { ...option, options: updatedYears };
                        case "State":
                            return { ...option, options: updatedStates };
                        case "Region":
                            return { ...option, selectedValue: res.data.region ? res.data.region : "All" }
                        default:
                            return option;
                    }
                });
            });
        }
    }

    function handleOpenSideFilter() {
        setOpenSideFilter(!openSideFilter);
        setOpenGroupBy(false);
        setOpenRightSideFilter(false);
        setOpenDropdownIndex(null)
    }

    function handleOpenRightSideFilter() {
        setOpenRightSideFilter(true);
        setOpenSideFilter(false);
        setOpenGroupBy(false);
        setOpenDropdownIndex(null)
    }

    async function handleApplyButtonForFilter() {
        await getBarDataForApi(null, null)
        setOpenRightSideFilter(false)
        setIsOpenGrid(false)
        setIsApplyButton("");
    }

    // handleSaveFilterIconFunctions
    async function handleSaveFilterDelete() {
        try {
            const res = await axiosPrivate.delete(`/dashboard/filter-data/${storeDeleteIndex}`);
            if (res.status === 200) {
                await getSaveFilterData()
                setShowDeleteModal(false)
                setShowAlertBox(true)
                setShowType("success")
                setShowMessage(res.data.message)
                clearAleart();
            }
        } catch (error: any) {
            console.log(`Error:${error}`)
            setShowDeleteModal(false)
            setShowAlertBox(true)
            setShowType("danger")
            setShowMessage(error.response.data.error)
            clearAleart()
        }
    }

    function handleDeleteForSaveFilter(id: any) {
        setStoreDeleteIndex(id)
        setShowDeleteModal(true)
    }

    async function handleApplyButton(data: any) {
        setIsApplyButton(data.id)
        setStoreGroupByOption({
            value: data.groupBy,
            label: data.groupBy
        })
        setStoreFilterOptions(allFilterOptions[data.groupBy])
        setStoreFilterOptions((prevOptions: any) => {
            return prevOptions.map((option: any) => {
                switch (option.cardTitle) {
                    case "Card":
                        return { ...option, selectedValue: data.filterValues.card };
                    case "Year":
                        return { ...option, selectedValue: data.filterValues.year };
                    case "State":
                        return { ...option, selectedValue: data.filterValues.state };
                    case "Y value setting":
                        return { ...option, selectedValue: data.filterValues.Yvaluesetting };
                    case "Region":
                        return { ...option, selectedValue: data.filterValues.region };
                    default:
                        return option;
                }
            });
        });
        const convertValue = {
            Card: data.filterValues.card,
            Year: data.filterValues.year,
            State: data.filterValues.state,
            Yvaluesetting: data.filterValues.Yvaluesetting,
            Region: data.filterValues.region,
        }
        await getBarDataForApi(data.groupBy, convertValue)
    }

    function handleSaveFilterInput(value: string, type: string) {
        if (type === "name") {
            setStoreSaveFilterInputData({ ...storeSaveFilterInputData, name: value });
        } else {
            setStoreSaveFilterInputData({ ...storeSaveFilterInputData, description: value });
        };
    }

    async function handleSaveFilterAddButton() {
        if (storeSaveFilterInputData.name === "") {
            setShowAlertBox(true)
            setShowType("danger")
            setShowMessage("Mandatory field(s) should not be left blank.")
            clearAleart();
        } else {
            const payLoad = {
                name: storeSaveFilterInputData.name,
                title: "Utilization Vs Product",
            }
            try {
                const res = await axiosPrivate.post(`/dashboard/filter-data/duplicate-checking`, payLoad);
                if (res.status === 200) {
                    setStoreSaveFilterInputData({
                        name: "",
                        description: ""
                    })
                    saveFilterDataForApi()
                }
            } catch (error: any) {
                console.log(`Error:${error}`)
                setShowAlertBox(true)
                setShowType("danger")
                setShowMessage(error.response.data.error)
                clearAleart()
            }
        }
    }

    async function saveFilterDataForApi() {
        const convertValue = convertToFilterValues(storeFilterOptions);
        const payLoad = {
            "name": storeSaveFilterInputData.name,
            "description": storeSaveFilterInputData.description,
            "groupBy": storeGroupByOption.value,
            "title": "Utilization Vs Product",
            "filterValues": {
                "card": convertValue.Card,
                "year": convertValue.Year,
                "region": convertValue.Region,
                "state": convertValue.State,
                "Yvaluesetting": convertValue.Yvaluesetting,
            },
        }
        try {
            const res = await axiosPrivate.post(`/dashboard/filter-data`, payLoad);
            if (res.status === 200) {
                getSaveFilterData()
                setShowAlertBox(true)
                setShowType("success")
                setShowMessage(res.data.message)
                clearAleart();
            }
        } catch (error: any) {
            console.log(`Error:${error}`)
            setShowAlertBox(true)
            setShowType("danger")
            setShowMessage(error.response.data.error)
            clearAleart()
        }
    }

    async function handleSaveFilterClearButton() {
        // setGroupByOptions(groupByOptions)
        setStoreGroupByOption({
            value: groupByOptions[0].value,
            label: groupByOptions[0].label
        })
        setStoreFilterOptions(allFilterOptions[groupByOptions[0].value])
        setIsApplyButton("")
        const convert = convertToFilterValues(allFilterOptions[groupByOptions[0].value])
        await getBarDataForApi(groupByOptions[0].value, convert)
    }

    async function getSaveFilterData() {
        try {
            const res = await axiosPrivate.get(`/dashboard/filter-data/Utilization Vs Product`);
            if (res.status === 200) {
                setAllSaveFilterTableData(res.data.DashboardFilterData)
            }
        } catch (error: any) {
            console.log(`Error:${error}`)
            setShowAlertBox(true)
            setShowType("danger")
            setShowMessage(error.response.data.error)
            clearAleart()
        }
    }

    function handleSaveFilterModal() {
        setIsSaveFilterModal(true)
        setOpenGroupBy(false)
        setOpenSideFilter(false)
        setOpenRightSideFilter(false)
        setIsOpenGrid(false)
    }

    // handleGridViewIconFunctions
    function handleConvertTableData(data: any) {

        const groupByValue = data.data.chartNames.chartFooter;
        let addColumnData: any[] = [];
        let addRowData: any[] = [];

        addColumnData.push({
            ...coloumDataSample,
            field: groupByValue,
            headerName: groupByValue,
        })

        Object.keys(data.data.dataValues).map((dataset: any) => {
            addColumnData.push({
                ...coloumDataSample,
                field: dataset,
                headerName: dataset,
            })
        })

        data.data.label.map((label: any, index: number) => {
            let rowData: any = {};

            Object.keys(data.data.dataValues).map((dataset: any) => {
                Object.assign(rowData, { [groupByValue]: label });
                Object.assign(rowData, { [dataset]: data.data.dataValues[dataset][index] });
            })
            addRowData.push(rowData);
        })
        setTableColumnData(addColumnData)
        setTableRowData(addRowData)
    }

    function handleOpenGrid() {
        setIsOpenGrid(!isOpenGrid)
        setOpenGroupBy(false)
        setOpenSideFilter(false)
        setOpenRightSideFilter(false)
    }

    // handleforClickBarChart-Bar
    async function HandleBarChartClcik(barValue: string, label: string) {
        const monthList = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

        if (monthList.includes(label)) return;
        switch (storeGroupByOption.value) {
            case "Card":
                const realDataYear = allFilterOptions["Year"];
                let convertCardValue = convertToFilterValues(realDataYear);
                convertCardValue = { ...convertCardValue, ["Card"]: label };
                await getBarDataForApi(null, convertCardValue);

                let updateCard: any = [];
                realDataYear.map((e: any) => {
                    if (e.cardTitle === "Card") {
                        updateCard.push({ ...e, ["selectedValue"]: label });
                    } else {
                        updateCard.push({ ...e });
                    }
                })
                setStoreFilterOptions(updateCard);
                setStoreGroupByOption({
                    value: "Year",
                    label: "Year"
                })
                break;
            case "Year":
                let convertYearValue = convertToFilterValues(storeFilterOptions);
                convertYearValue = { ...convertYearValue, ["Card"]: barValue, ['Year']: label };
                await getBarDataForApi(null, convertYearValue);

                let updateYearAndOrganisation: any = [];
                storeFilterOptions.map((e: any) => {
                    if (e.cardTitle === "Card") {
                        updateYearAndOrganisation.push({ ...e, ["selectedValue"]: barValue });
                    } else if (e.cardTitle === "Year") {
                        updateYearAndOrganisation.push({ ...e, ["selectedValue"]: label });
                    } else {
                        updateYearAndOrganisation.push({ ...e });
                    }
                })
                setStoreFilterOptions(updateYearAndOrganisation);
                break;
            case "Region":
                const realDataState = allFilterOptions["State"];
                let convertStateValue = convertToFilterValues(realDataState);
                convertStateValue = { ...convertStateValue, ["Card"]: barValue, ['Region']: label };
                await getBarDataForApi(null, convertStateValue);

                let updateStateAndOrganisation: any = [];
                realDataState.map((e: any) => {
                    if (e.cardTitle === "Card") {
                        updateStateAndOrganisation.push({ ...e, ["selectedValue"]: barValue });
                    } else if (e.cardTitle === "Region") {
                        updateStateAndOrganisation.push({ ...e, ["selectedValue"]: label });
                    } else {
                        updateStateAndOrganisation.push({ ...e });
                    }
                })
                setStoreFilterOptions(updateStateAndOrganisation);
                setStoreGroupByOption({
                    value: "State",
                    label: "State"
                })
                break;
            case "State":
                const realDataCity = allFilterOptions["City"];
                let convertCityValue = convertToFilterValues(realDataCity);
                convertCityValue = { ...convertCityValue, ["Card"]: barValue, ['State']: label };
                await getBarDataForApi(null, convertCityValue);

                let updateOrganisationAndCity: any = [];
                realDataCity.map((e: any) => {
                    if (e.cardTitle === "Card") {
                        updateOrganisationAndCity.push({ ...e, ["selectedValue"]: barValue });
                    } else if (e.cardTitle === "State") {
                        updateOrganisationAndCity.push({ ...e, ["selectedValue"]: label });
                    } else {
                        updateOrganisationAndCity.push({ ...e });
                    }
                })
                setStoreFilterOptions(updateOrganisationAndCity);
                setStoreGroupByOption({
                    value: "City",
                    label: "City"
                })
                break;
            default:
                break;
        }
    }

    // handleDownloadModal
    async function handleDownloadBtn() {
        setIsDownloadModal(false);
        const payLoad: any = {
            filterOptions: {
                groupBy: storeGroupByOption.value,
                filterValues: convertToFilterValues(storeFilterOptions),
            },
            overAllReportData: tableRowData,
            ...(switchBtnDetail ? { detailReport: checkedHeaderdData.map((elem: any) => elem.name) } : {})
        };

        try {
            const res = await axiosPrivate.post('bank-admin/region/download', payLoad, {
                responseType: 'blob', // Ensure the response is treated as binary data (Blob)
            });

            if (res.status === 200) {
                // Create a Blob object from the response data
                const blob = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

                // Create a temporary URL for the Blob
                const url = window.URL.createObjectURL(blob);

                // Create an anchor element to trigger the download
                const a = document.createElement('a');
                a.href = url;
                a.download = 'UtilizationVsProductReport.xlsx';
                a.style.display = 'none';

                // Add the anchor element to the DOM and trigger a click event to download the file
                document.body.appendChild(a);
                a.click();

                // Clean up
                window.URL.revokeObjectURL(url);
            }
        } catch (error) {
            console.log(`Error: ${error}`);
        }
    }

    function handleCheckBox(event: any, value: string) {
        if (event.target.checked) {
            let addValue: any = checkedHeaderdData;
            if (addValue.length !== 0) {
                addValue.push({
                    id: checkedHeaderdData[checkedHeaderdData.length - 1]["id"] + 1,
                    name: value
                })
            } else {
                addValue.push({
                    id: 1,
                    name: value
                })
            }
            setCheckedHeaderdData(addValue)
            setCheckBoxData({ ...checkBoxData, [value]: event.target.checked })
        } else {
            let addValue: any = checkedHeaderdData.filter((e: any) => e.name !== value);

            setCheckedHeaderdData(addValue)
            setCheckBoxData({ ...checkBoxData, [value]: event.target.checked })
        }
    }

    const moveHeader = useCallback((dragIndex: number, hoverIndex: number) => {
        setCheckedHeaderdData((prevData: any) =>
            update(prevData, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, prevData[dragIndex]],
                ],
            }),
        )
    }, [])

    function handleDownloadModall() {
        setIsDownloadModal(true)
        setOpenGroupBy(false)
        setOpenSideFilter(false)
        setOpenRightSideFilter(false)
        setIsOpenGrid(false)
    }

    const handleDownloadExcel = () => {
        gridDataDownloadRef.current!.onBtExport();
    }

    // chartApiFunctions
    async function getBarDataForApi(groupBy: any, filterValue: any) {
        const payLoad: any = {
            groupBy: Object.keys(storeGroupByOption).length !== 0 ? (groupBy || storeGroupByOption.value) : groupByOptions[0].value,
            filterValues: storeFilterOptions.length !== 0 ? (filterValue || convertToFilterValues(storeFilterOptions)) : convertToFilterValues(allFilterOptions[groupByOptions[0].value])
        }
        try {
            const res = await axiosPrivate.post(`bank-admin/card/report-data`, payLoad);
            if (res.status === 200) {
                setDataForDashboard(res)
                handleConvertTableData(res)
            }
        } catch (error) {
            console.log(`Error:${error}`)
        }
    }

    async function getDropDownData(regain: any) {
        try {
            const res = await axiosPrivate.get(`bank-admin/card/dropdown-report-data/${regain || "All"}`);
            if (res.status === 200) {
                addDataForDropDownFilter(res)
            }
        } catch (error) {
            console.log(`Error:${error}`)
        }
    }

    async function handleFindUserType() {
        try {
            const res = await axiosPrivate.get(`/bank-admin/user/type`);
            if (res.status === 200) {
                return res;
            }
        } catch (error) {
            console.log(`Error:${error}`)
        }
    }

    // convertAndSetDataFunctions
    const convertToFilterValues = (filterData: any) => {
        const filterValues: any = {};
        filterData.forEach((filter: any) => {
            const tittle = (filter.cardTitle).replace(/\s/g, '')
            filterValues[tittle] = filter.selectedValue;
        });
        return filterValues;
    };

    function setDataForDashboard(res: any) {
        const generateColor = () => {
            return '#' + Math.random().toString(16).substr(-6);
        };

        const colors: any[] = [];
        for (let i = 0; i < Object.keys(res.data.dataValues).slice(0, 5).length; i += 1) {
            colors.push({ hexCode: generateColor() });
        }

        let updatedDatasets = {};
        let updatedData: any = {};

        let notSliceUpdatedDatasets = {};
        let notSliceUpdatedData: any = {};

        if (Object.keys(res.data.dataValues).length === 1) {

            updatedDatasets = Object.keys(res.data.dataValues).slice(0, 5).map((label, i) => ({
                label: label.length > 10 ? label.substring(0, 10) + '...' : label,
                data: res.data.dataValues[label],
                backgroundColor: colors[i].hexCode,
                borderColor: colors[i].hexCode,
                // barPercentage: 1.1,
                // barThickness: 25,
                // borderWidth: 1
            }));
            notSliceUpdatedDatasets = Object.keys(res.data.dataValues).map((label, i) => ({
                label: label,
                data: res.data.dataValues[label],
            }));

            updatedData = {
                labels: res.data.label.slice(0, 5).map((label: any) => label.length > 10 ? label.substring(0, 10) + '...' : label),
                datasets: updatedDatasets,
            };
            notSliceUpdatedData = {
                labels: res.data.label,
                datasets: notSliceUpdatedDatasets,
            }
        } else {
            updatedDatasets = Object.keys(res.data.dataValues).slice(0, 5).map((label, i) => ({
                label: label.length > 10 ? label.substring(0, 10) + '...' : label,
                data: res.data.dataValues[label],
                backgroundColor: colors[i].hexCode,
                borderColor: colors[i].hexCode,
                // barPercentage: 0.25,
                // barThickness: 15,
                // borderWidth: 1
            }));
            notSliceUpdatedDatasets = Object.keys(res.data.dataValues).map((label, i) => ({
                label: label,
                data: res.data.dataValues[label],
            }));

            updatedData = {
                labels: res.data.label.slice(0, 5).map((label: any) => label.length > 10 ? label.substring(0, 10) + '...' : label),
                datasets: updatedDatasets,
            };
            notSliceUpdatedData = {
                labels: res.data.label,
                datasets: notSliceUpdatedDatasets,
            }
        }

        setBarchartData(updatedData);
        setOrginalBarchartData(notSliceUpdatedData)
        const storefilteroptions = storeFilterOptions.length !== 0 ? storeFilterOptions : allFilterOptions[groupByOptions[0].value];
        const convertValue = convertToFilterValues(storefilteroptions);

        const updateOptions: any = {
            ...barchartOptions,
            ["scales"]: {
                ...barchartOptions.scales,
                ["x"]: {
                    ...barchartOptions.scales.x,
                    ["title"]: {
                        ...barchartOptions.scales.x.title,
                        ...{
                            ["text"]: ` Transaction Value ( ${convertValue.Yvaluesetting == "Percentage" ? '%' : convertValue.Yvaluesetting} )`
                          }
                    }
                },
                ["y"]: {
                    ...barchartOptions.scales.y,
                    ["title"]: {
                        ...barchartOptions.scales.y.title,
                        // ["text"]: res.data.chartNames.chartFooter
                        ["text"]: "Cards"
                    }
                },
            },
            ["plugins"]: {
                ...barchartOptions.plugins,
                ["legend"]: {
                    ...barchartOptions.plugins.legend,
                    ["display"]: Object.keys(res.data.dataValues).length !== 1
                }
            }
        }
        setBarchartOptions(updateOptions)
    }

    // clearAleart
    const clearAleart = () => {
        const timer = setTimeout(() => {
            setShowAlertBox(false)
            setShowMessage("");
            clearTimeout(timer);
        }, 5000);
    }

    return (
        <div className='organization-vs-companies-component'>
            <div className='charts-main'>
                <Card
                    width={dashBoardWidth.cardWidth}
                    height={dashBoardWidth.cardHeight}
                    isOpenEmpty={isOpenEmpty}
                    setIsOpenEmpty={setIsOpenEmpty}
                    currentChart={"cardVsTransactionValue"}
                    chartHeading={"Utilization - Product"}
                    groupByOptions={groupByOptions}
                    storeGroupByOption={storeGroupByOption}
                    openGroupBy={openGroupBy}
                    openSideFilter={openSideFilter}
                    storeFilterOptions={storeFilterOptions}
                    setOpenOneDashboard={setOpenOneDashboard}
                    setStoreGroupByOption={handleStoreGroupByOption}
                    setOpenGroupBy={handleOpenGroupBy}
                    setOpenSideFilter={handleOpenSideFilter}
                    setOpenRightSideFilter={handleOpenRightSideFilter}
                    setIsOpenGrid={handleOpenGrid}
                    setIsSaveFilterModal={handleSaveFilterModal}
                    setIsDownloadModal={handleDownloadModall}
                    isOpenGrid={isOpenGrid}
                    handleDownloadExcel={handleDownloadExcel}
                >
                    {
                        isOpenGrid ?
                            <ChartAgGrid
                                fileName={"Dashboard Report"}
                                ref={gridDataDownloadRef}
                                tabelRowData={tableRowData}
                                tableColumnData={tableColumnData}
                                className={!isOpenEmpty ? 'ag-theme-alpine-chart-single' : 'ag-theme-alpine-chart'}
                            />
                            :
                            <VerticalBarChart
                                width={dashBoardWidth.chartWidth}
                                height={dashBoardWidth.chartHeight}
                                options={barchartOptions}
                                data={barchartData}
                                orginalBarchartData={orginalBarchartData}
                                HandleBarChartClcik={HandleBarChartClcik}
                            />
                    }
                </Card>
            </div>
            {
                openRightSideFilter &&
                <div className='right-side-filter-component'>
                    <ChartFilter
                        datas={storeFilterOptions}
                        updateFilterDatas={updateFilterDatas}
                        handleOpenClose={handleOpenClose}
                        openDropdownIndex={openDropdownIndex}
                        handleApplyButtonForFilter={handleApplyButtonForFilter}
                        setOpenRightSideFilter={() => { setOpenRightSideFilter(false) }}
                    />
                </div>
            }
            {
                isSaveFilterModal &&
                <SaveFilterModal
                    storeSaveFilterInputData={handleSaveFilterInput}
                    storeInputData={storeSaveFilterInputData}
                    handleSaveFilterAddButton={handleSaveFilterAddButton}
                    handleSaveFilterClearButton={handleSaveFilterClearButton}
                    allSaveFilterTableData={allSaveFilterTableData}
                    setShowDeleteModal={handleDeleteForSaveFilter}
                    handleApplyButton={handleApplyButton}
                    isApplyButton={isApplyButton}
                    hide={() => { setIsSaveFilterModal(false) }}
                />
            }
            {
                isDownloadModal &&
                <DownloadChartDataModal
                    storeGroupByOption={storeGroupByOption.value}
                    storeFilterOptions={storeFilterOptions}
                    switchBtnOverAll={switchBtnOverAll}
                    switchBtnDetail={switchBtnDetail}
                    handleDownloadBtn={handleDownloadBtn}
                    checkBoxData={checkBoxData}
                    checkedHeaderdData={checkedHeaderdData}
                    moveHeader={moveHeader}
                    handleCheckBox={handleCheckBox}
                    hide={() => setIsDownloadModal(false)}
                    setSwitchBtnOverAll={() => { setSwitchBtnOverAll(!switchBtnOverAll) }}
                    setSwitchBtnDetail={() => { setSwitchBtnDetail(!switchBtnDetail) }}
                />
            }
            {
                showAlertBox &&
                <div className='alert-warp' >
                    <AlertBox type={showType} message={showMessage} />
                </div>
            }
            {
                showDeleteModal &&
                <DeleteModal
                    modelType={"grid-delete"}
                    getDelete={handleSaveFilterDelete}
                    clearValue={(value) => {
                        setStoreDeleteIndex("")
                        setShowDeleteModal(value)
                    }}
                />
            }
        </div>
    )
}

export default CardVsTransactionValue;