import React from 'react';
import { RxCross1 } from 'react-icons/rx';
import { DateConverter, TitleCase } from '../../../../helpers/capitalizetext';

interface ElectricityDetailsProps {
    clearValue: (value: any) => void;
    paymentHistory: any
}

const ElectricityHistory: React.FC<ElectricityDetailsProps> = ({ clearValue, paymentHistory }) => {
    return (
        <>
            <div className={"add-electricity-popup"}>
                <div className={"container-electricity"} style={{ width: "1020px", height: "550px" }} >
                    <div className={"close-icon"}>
                        <span className={"cross-icon"}>
                            <RxCross1 onClick={() => { clearValue(false) }
                            } style={{ fontSize: "40px" }} />
                        </span>
                    </div>
                    <div className='transaction-history-main'>
                        <div>
                            <div className='transaction-history-heading' >
                                <p>Electricity Details</p>
                            </div>

                            <div className='electricity-data' >
                                <div>
                                    <p>State</p>
                                    <p>Electricity Board</p>
                                    <p>Consumer Number</p>
                                </div>
                                <div>
                                    <p>: Tamil Nadu</p>
                                    <p>: TNEB</p>
                                    <p>: 4561237890</p>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className={"review-details-table-main"} style={{ border: "1px solid black", marginTop: "40px", position: "relative" }}>
                        <p className={"review-details-table-title"}>{"Payment History"}</p>
                        <div className='view-table-component' style={{ marginTop: "20px", marginBottom: "20px", maxHeight: "210px", overflow: "auto" }}>
                            <table>
                                <thead className='vendor-payment-approval-status-head'>
                                    <th style={{ paddingLeft: "20px" }}>Date</th>
                                    <th>Payment Method</th>
                                    <th style={{ textAlign: "center", paddingRight: "20px" }}>Amount</th>
                                    <th>Transaction Ref No</th>
                                    <th>Payment status</th>
                                </thead>
                                <tbody >
                                    {paymentHistory.length === 0 ?
                                        <tr className='main-body-header'>
                                            <td></td>
                                            <td></td>
                                            <td style={{ textAlign: "center" }}>No records to show</td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        :
                                        paymentHistory.map((data: any, index: number) => (
                                            <tr className='main-body-header' key={index}>
                                                <td>{DateConverter(data.date)}</td>
                                                <td>{TitleCase(data.payment_type)}</td>
                                                {/* <td style={{ textAlign: "right", paddingRight: "20px" }}>{formatAmount(data.payment_amount)}</td> */}
                                                <td style={{ textAlign: "right", paddingRight: "20px" }}>{data.payment_amount}</td>
                                                <td>{data.transaction_ref_no}</td>
                                                <td >{TitleCase(data.transactionResult)}</td>
                                            </tr>
                                        ))
                                    }

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
};

export default ElectricityHistory;