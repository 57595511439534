import React from 'react';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { FaKey } from 'react-icons/fa';
import { IoFingerPrintSharp, IoAdd } from 'react-icons/io5';
import { MdDialpad } from 'react-icons/md';
import { TfiImport } from 'react-icons/tfi';
import { BiFilter } from 'react-icons/bi';
import { LuFilterX } from "react-icons/lu";
import { BiCaretDown } from 'react-icons/bi';
import { RxCross1 } from 'react-icons/rx';
import Otp from '../../../assets/icons/icon-otp.svg'
import { ReactComponent as Fetch } from '../../../assets/icons/fetch.svg'
import Expand from '../../../assets/icons/expand.svg'
import { ReactComponent as Message } from '../../../assets/icons/message.svg'
import { ReactComponent as MessageNotify } from '../../../assets/icons/messageNotify.svg'
import { ReactComponent as Eye } from '../../../assets/icons/eyeshowoff.svg'
import { ReactComponent as Notification } from '../../../assets/icons/notification_tick.svg'
import { IoMdSync } from "react-icons/io";
import '../../../styles/icon-button.scss';
import { FiEdit3 } from 'react-icons/fi';
import { ReactComponent as ShowEye } from '../../../assets/icons/ShowEye.svg'
import { ReactComponent as Documentation } from '../../../assets/icons/documentationicon.svg'

interface Props {
    iconName: string;
    height: string;
    width: string;
    fontSize: string;
    color: string;
    backgroundColor: string;
    margin?: string;
    border?: string;
    className?: string
    hover: boolean;
    borderRadius?: string;
    padding?: string;
    disabled?: boolean;
    transform?: string;
    opacity?: string;
    cursor?: string;
    handleClick: (val: any) => void;
}

export default function IconButton({ disabled, iconName, height, width, fontSize, color, border, backgroundColor, transform, borderRadius, hover, padding, opacity, cursor, handleClick, margin }: Props) {
    const className = hover ? 'icon-button hover' : 'icon-button';

    const handleKeyPress = (event: any) => {
        if (event.key === 'Enter' && hover) {
            // If Enter key is pressed while focused,   trigger the click action
            handleClick(event);
        }
    };


    return (
        <div tabIndex={hover ? 0 : -1}
            data-testid="icon-button"
            className={className}
            style={{ height, width, border, color, backgroundColor, margin, borderRadius, padding, transform, opacity, cursor }}
            onClick={handleClick}
            onKeyDown={handleKeyPress}
        >
            {iconName === "Delete" && <RiDeleteBin6Line data-testid="delete-icon" style={{ fontSize }} />}
            {iconName === "Key" && <FaKey style={{ fontSize }} />}
            {iconName === "FingerPrint" && <IoFingerPrintSharp style={{ fontSize }} />}
            {iconName === "DialPad" && <MdDialpad style={{ fontSize }} />}
            {iconName === "DownArrow" && <BiCaretDown style={{ fontSize }} />}
            {iconName === "Edit" && <FiEdit3 style={{ fontSize }} />}
            {iconName === "Download" && <TfiImport style={{ fontSize }} />}
            {iconName === "Upload" && <TfiImport style={{ fontSize, transform: "rotate(180deg)" }} />}
            {iconName === "Filter" && <BiFilter style={{ fontSize }} />}
            {iconName === "ClearFilter" && <LuFilterX style={{ fontSize }} />}
            {iconName === "Otp" && <img src={Otp} />}
            {iconName === "Add" && <div ><IoAdd className='add-icon' />{` Add`}</div>}
            {iconName === "Vendor" && <div ><IoAdd className='add-icon' />{` Vendor`}</div>}
            {iconName === "Invoice" && <div ><IoAdd className='add-icon' />{` Invoice`}</div>}
            {iconName === "eyeFillter" && <div ><IoAdd className='add-icon' />{` Add`}</div>}
            {iconName === "Fetch" && <div className='fetch-icon'><Fetch />{` Fetch`}</div >}
            {iconName === "Expand" && <div className='expand-icon'><img src={Expand} />{` Expand All`}</div >}
            {iconName === "Message" && <div className='message-icon' style={{ border: "1px solid #0055D4", borderRadius: "5px" }}><Message />{` `}</div >}
            {iconName === "MessageNotify" && <div className='messagenotify-icon' style={{ border: "1px solid #0055D4", borderRadius: "5px" }}><MessageNotify />{``}</div >}
            {iconName === "Eye" && <div className='eye-icon'><Eye />{``}</div >}
            {iconName === "Notification" && <div className='notification-icon'><Notification />{``}</div >}
            {iconName === "Eye-white" && <div className='eye-icon'><Eye />{``}</div >}
            {iconName === "Request" && <div ><IoAdd className='add-icon' />{` Request`}</div>}
            {iconName === "ShowEye" && <div className='eye-icon'><ShowEye />{``}</div >}
            {iconName === "Documentation" && <div className='document-icon'><Documentation />{``}</div >}
            {iconName === "decline" && <div><RxCross1 style={{ fontSize, }} className='add-icon' />{` Decline`}</div>}
            {iconName === "tallySync" && <div><IoMdSync style={{ fontSize, color }} className='tally-icon' />{` Tally Sync`}</div>}
        </div>
    )
}
 