import React, { useEffect, useState, forwardRef, useImperativeHandle, useCallback, useRef } from 'react'
import InputComponent from '../../../common-component/form-elements/input-component'
import DropdownComponent from '../../../common-component/form-elements/dropdown-component'
import ButtonComponent from '../../../common-component/form-elements/button-component'
import { v4 as uuidv4 } from 'uuid'
import useAxiosPrivate from "../../../../services/hooks/useaxios-private";
import AlertBox from '../../../common-component/alert-box'
import { useDispatch, useSelector } from "react-redux";
import { resetFormModified } from "../../../../redux/action";
import Changesmodal from '../../../common-component/modals/changes-modal';
import Chart from 'chart.js/auto';

export interface ChildRef {
  next: (val: any) => void;
}
interface DropdownItem {
  label: string;
  value: string;
}
interface props {
  mainApiData: any
  setMainApiData: (val: any) => void;
  onboardingViewfun: (val: any) => void;
  isEditMode: boolean;
  iconStatus: any;
  editData: any;
  setShowPage: (val: any) => void;
  sendDataApi: () => void;
  setIconStatus: (val: any) => void;
  vendorDetails: any;
  setVendorDetails: any
  stepperStatus: any;
  setStepperStatus: any;
  saveVendorDatas: any;
}
const Address = forwardRef<ChildRef, props>(({ mainApiData, isEditMode, onboardingViewfun, setMainApiData, sendDataApi, iconStatus, editData, setShowPage, setIconStatus, vendorDetails, setVendorDetails, stepperStatus,
  setStepperStatus, saveVendorDatas }, ref) => {

  const [addressErrors, setAddressErrors] = useState<Record<string, boolean>>({})
  const [addressState, setAddressState] = useState<any[]>([]);
  const [addressCities, setAddressCities] = useState<any[]>([]);
  const [isFormDirty, setIsFormDirty] = useState(false)
  const [showType, setShowType] = useState("warning") // error message showType
  const [showMessage, setShowMessage] = useState<string>("") // error message showMessage
  const [showAlertBox, setShowAlertBox] = useState(false) // error message ShowAlertBox
  const isFormModified = useSelector((state: any) => state.isFormModified);
  const dispatch = useDispatch();
  const [showChangesModal, setShowChangesModal] = useState<boolean>(false);
  const [dropDownState, setDropDownState] = useState<DropdownItem[]>([])
  const [dropDownCities, setDropDownCities] = useState<DropdownItem[]>([])
  const [dropDownRocCities, setDropDownRocCities] = useState<DropdownItem[]>([])
  const [addressPercentage, setAddressPercentage] = useState<any>(0)


  const userValidationRules: any = {

    // country: {
    //   regex: "",
    //   field: "",
    //   shouldNotBe: ""
    // },
    // state: {
    //   regex: "",
    //   field: "",
    //   shouldNotBe: ""
    // },
    // city: {
    //   regex: "",
    //   field: "",
    //   shouldNotBe: ""
    // },

    // pinCode: {
    //   // regex: /^[0-9]{6}$/,
    //   regex: "",
    //   field: "",
    //   shouldNotBe: ""
    // },

    // website: {
    //   regex: mainApiData?.website ? /^(https?:\/\/)?([a-z0-9.-]+)\.([a-z]{2,})(:[0-9]+)?(\/.*)?$/ : "",
    //   field: "",
    //   shouldNotBe: ""
    // },

    // address: {
    //   regex: "",
    //   field: "mandatory",
    //   shouldNotBe: ""
    // },

  }

  const updatedIsValueValidAll: Record<string, boolean> = {};
  const validateForm = (): boolean => {
    const updatedFieldValidity: Record<string, boolean> = {};
    const isMandatoryfalse = true;
    const isRegexfalse = true;
    Object.keys(userValidationRules.mainApiData).forEach((field) => {
      const rule = userValidationRules.mainApiData[field];
      const value = mainApiData[field];
      const isValueValid = (rule.field === "mandatory") ? (value ? (value !== rule.shouldNotBe) : false) : true;
      const isRegexValid = rule.regex !== "" ? new RegExp(rule.regex).test(value) : true;
      updatedFieldValidity[field] = !isValueValid || !isRegexValid;
      updatedIsValueValidAll[field] = !isValueValid;
    });
    setAddressErrors(updatedFieldValidity);
    const isFormValid = Object.keys(updatedFieldValidity).every((keys) => {
      return !updatedFieldValidity[keys]
    });
    return isFormValid;
  };

  function returnGird() {
    onboardingViewfun("grid")
    dispatch(resetFormModified(false));
  }

  // if (isEditMode) {
  //   fetchData(`state/cities/${editData?.vendorDetails?.address?.stateId}`,
  //     setDropDownRocCities, 'city', "cityId", "name");
  // } 
  // else if (mainApiData?.stateId) {
  //   fetchData(`state/cities/${mainApiData?.stateId}`,
  //     setDropDownRocCities, 'city', "cityId", "name");
  // }
  // "state": mainApiData.vendorOverView?.state,
  // fetchData(`/state/roc-cities/${editData.vendorOverView?.state}`, setDropDownRocCities, 'RocCity', "rocCityId", "name");

  useEffect(() => {
    fetchData("state", setDropDownState, "states", "stateId", "name");
    if (isEditMode) {
      fetchData(`state/cities/${editData.vendorOverView?.stateId}`, setDropDownRocCities, 'city', "cityId", "name");
    }
  }, [])

  useEffect(() => {
    fetchAddressValues()
    fetchAddressCity()
  }, [])
  const axiosPrivate = useAxiosPrivate();
  const fetchAddressValues = async () => {
    try {
      const response = await axiosPrivate.get("state");
      if (response.status === 200) {
        const dropdownValues = response.data.states.map((item: any) => ({
          label: item.name,
          value: item.name,
          id: item.stateId,
        }));
        setAddressState(dropdownValues);
      }
    } catch (error) {
    }
  };
  const fetchAddressCity = async () => {
    try {
      const response = await axiosPrivate.get("cities");
      if (response.status === 200) {
        const dropdownValues = response.data.cities.map((item: any) => ({
          label: item.name,
          value: item.name,
          id: item.cityId,
        }));
        setAddressCities(dropdownValues);
      }
    } catch (error) {
    }
  };

  const fetchData = async (url: string, setData: (data: any[]) => void, nestedProperty: string, id: any, representName: any) => {
    try {
      const response = await axiosPrivate.get(url);
      if (response.status === 200) {
        const dropdownValues = response.data[nestedProperty].map((item: any) => ({
          label: item[representName],
          value: item[representName],
          id: item[id],
        }));
        const sortedData = [...dropdownValues].sort((a, b) => a.label.localeCompare(b.label));
        setData([...sortedData]);

      }
    } catch (error) {
    }
  };

  async function duplicateCheck() {
    const finalData = {
      "CompanyEmail": mainApiData.vendorOverView?.CompanyEmail,
      "CompanyContactNumber": mainApiData.vendorOverView?.ContactNumber
    }

    let statusMessage: boolean = false;
    try {
      const res = isEditMode ? await axiosPrivate.patch(`bank-admin/company/duplicate-check/addressData/${editData?.CompanyData?.vendorOverView?.id}`, finalData) : await axiosPrivate.post("bank-admin/company/duplicate-check/addressData", finalData)
      if (res.status === 200) {
        statusMessage = true;
      }
    }
    catch (error: any) {
      setShowAlertBox(true)
      setShowType("danger")
      setShowMessage(error.response.data.error)
      clearAlert("")
      statusMessage = false;
    }
    return statusMessage;
  }
  const clearAlert = (status: any) => {
    const timer = setTimeout(() => {
      setShowAlertBox(false);
      setShowMessage("");
      if (status === 400) {

      }
      clearTimeout(timer);
    }, 5000);
  }

  const next = async (next: any = "") => {
    setShowPage('BankDetails')
    // const isFormValid = validateForm();
    // if (true) {
    //   const Duplicate = await duplicateCheck();
    //   if (Duplicate) {
    //     if (isEditMode) {
    //       sendDataApi();
    //       setIconStatus({
    //         ...iconStatus,
    //         companyIcon: "completed",
    //         addressIcon: "selected"
    //       })
    //       dispatch(resetFormModified(false));
    //     } else {
    //       setShowPage(next != "" ? next : "DirectorsInfo")
    //       if (next != "") {
    //         switch (next) {
    //           case "VendorOverview":
    //             setIconStatus({
    //               ...iconStatus,
    //               companyIcon: "selected",
    //             })
    //             break;
    //           case "BankDetails":
    //             setIconStatus({
    //               ...iconStatus,
    //               adminIcon: "selected"
    //             })
    //             break;
    //           case "Address":
    //             setIconStatus({
    //               ...iconStatus,
    //               companyIcon: "completed",
    //               addressIcon: "selected",
    //             })
    //             break;
    //           case "DirectorsInfo":
    //             setIconStatus({
    //               ...iconStatus,
    //               directorIcon: "selected",
    //               addressIcon: "completed"
    //             })
    //             break;
    //           case "StatutoryDetails":
    //             setIconStatus({
    //               ...iconStatus,
    //               statutoryIcon: "selected",
    //               directorIcon: "completed"
    //             })
    //             break;
    //           case "PointOfContact":
    //             setIconStatus({
    //               ...iconStatus,
    //               PointOfContactIcon: "selected",
    //               statutoryIcon: "completed"
    //             })
    //             break;
    //           default:
    //             break;
    //         }
    //       } else {
    //         setIconStatus({
    //           ...iconStatus,
    //           addressIcon: "completed",
    //           directorIcon: "selected"
    //         })
    //       }
    //     }
    //   } else {
    //     setIconStatus({
    //       ...iconStatus,
    //       addressIcon: "completed",
    //       directorIcon: "selected"
    //     })
    //   }

    //   setIsFormDirty(false);
    // } else {
    //   setShowAlertBox(true);
    //   setShowType("danger");
    //   setShowMessage(Object.values(updatedIsValueValidAll).every((values) => !values) ?
    //     "Some of the field(s) are not in required format." : "Mandatory field(s) should not be left blank.")
    //   clearAlert('');
    // }
  }

  useImperativeHandle(ref, () => {
    return {
      next: next
    }
  });
  function cancel() {
    setShowPage("BankDetails")
    setIconStatus({ ...iconStatus, adminIcon: "selected", })
  }
  // percentage chart
  const chartRef: any = useRef(null);
  useEffect(() => {
    const data = {
      value: addressPercentage,
      max: 100,
      label: "Progress"
    };

    const config: any = {
      type: 'doughnut',
      data: {
        datasets: [{
          data: [data.value, data.max - data.value],
          backgroundColor: [addressPercentage == 100 ? "green" : '#D2A100', '#0055D4'],
          borderWidth: 0
        }]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        cutoutPercentage: 85,
        rotation: -90,
        circumference: 180,
        tooltips: {
          enabled: false
        },
        legend: {
          display: false
        },
        animation: {
          animateRotate: true,
          animateScale: false
        },
        title: {
          display: true,
          text: data.label,
          fontSize: 16
        }
      }
    };

    if (chartRef.current) {
      const chartCtx = chartRef.current.getContext('2d');
      const gaugeChart = new Chart(chartCtx, config);

      // Cleanup on unmount
      return () => {
        gaugeChart.destroy();
      };
    }
  }, [addressPercentage]);
  // percentage chart

  useEffect(() => {
    let count = 0;
    let mandatoryList = [
      'contact_street_1', 'contact_street_2', 'contact_city', 'contact_state', 'contact_postalCode', 'contact_country', 'registered_street_1', 'registered_street_2', 'registered_city', 'registered_state', 'registered_postalCode', 'registered_country',
    ]
    Object.keys(vendorDetails?.address)?.forEach((item: any) => {
      let dropdownValueStatus = typeof (vendorDetails?.address[item]) == 'object' ? vendorDetails?.address[item].label !== "Select" : true;
      if (vendorDetails?.address[item] !== "" && mandatoryList.includes(item) && dropdownValueStatus) {
        setStepperStatus({ ...stepperStatus, address: "working" })
        count++;
      }
    });
    let percentage: any = (count / 12) * 100
    if (percentage.toFixed(0) == 100) {
      setStepperStatus({ ...stepperStatus, address: "completed" })
    }
    setAddressPercentage(percentage.toFixed(0))
  }, [vendorDetails?.address])
  return (
    <>
      {/* <div style={{ background: "#F8F8F8", height: "110px", width: "190px", position: "absolute", right: "0px", top: "0px", display: "flex", borderRadius: "0px 10px" }}>
        <div style={{ position: 'relative', height: '95px', width: '135px', left: "30px", top: "0px" }}>
          <canvas ref={chartRef}></canvas>
          <div style={{ width: "40px", position: "absolute", left: "50px", top: "58px", textAlign: "center" }}><strong style={{ fontSize: "16px" }}>{addressPercentage}%</strong></div>
          <div style={{ position: "absolute", left: "10px", fontSize: "16px", bottom: "-12px" }}>0%</div>
          <div style={{ position: "absolute", right: "-4px", fontSize: "16px", bottom: "-11px" }}>100%</div>
        </div>
      </div> */}
      <div className='form-wrapper'>
        <div className='form-area-address'>
          <div style={{ pointerEvents: "none", opacity: 0.5 }}>
            <div>
              <div className='company-heading'>
                Contact Address
              </div>
              <div className='form-main-wrapper'>
                <div style={{ marginRight: "45px" }}>
                  <InputComponent
                    height={"40px"}
                    width={"300px"}
                    margin={"0px 0px 30px 0px"}
                    padding={"0px 0px 0px 10px"}
                    border={addressErrors.website ? "1px solid red" : "1px solid #A9C3DC"}
                    borderRadius={"0px"}
                    backgroundColor={"white"}
                    color={"black"}
                    disabled={true}
                    type={"text"}
                    autoFocus
                    maxLength={100}
                    placeHolder={"Enter Street 1"}
                    inputValue={vendorDetails?.address?.contact_street_1}
                    inputTitle={"Street 1"}
                    required={true}
                    getUser={(value: any) => {
                      const removeInitialSpace = value.replace(/^\s+/, "")
                      setIsFormDirty(true)
                      dispatch(resetFormModified(true));
                      setVendorDetails({
                        ...vendorDetails,
                        address: {
                          ...vendorDetails.address,
                          contact_street_1: removeInitialSpace,
                        }
                      })
                      // setIconStatus({
                      //   ...iconStatus,
                      //   addressIcon: "selected"
                      // })
                    }}
                  />
                </div>
                <div style={{ marginRight: "45px" }}>
                  <InputComponent
                    height={"40px"}
                    width={"300px"}
                    margin={"0px 0px 30px 0px"}
                    padding={"0px 0px 0px 10px"}
                    border={addressErrors.website ? "1px solid red" : "1px solid #A9C3DC"}
                    borderRadius={"0px"}
                    backgroundColor={"white"}
                    color={"black"}
                    disabled={true}
                    type={"text"}
                    maxLength={100}
                    placeHolder={"Enter Street 2"}
                    inputValue={vendorDetails?.address?.contact_street_2}
                    inputTitle={"Street 2"}
                    required={true}
                    getUser={(value: any) => {
                      const removeInitialSpace = value.replace(/^\s+/, "")
                      setIsFormDirty(true)
                      dispatch(resetFormModified(true));
                      const onlyAlphabet = value.replace(/[^A-Za-z0-9.,:/ ]+/g, '')
                      setVendorDetails({
                        ...vendorDetails,
                        address: {
                          ...vendorDetails.address,
                          contact_street_2: removeInitialSpace,
                        }
                      })
                      // setIconStatus({
                      //   ...iconStatus,
                      //   addressIcon: "selected"
                      // })
                    }}
                  />
                </div>
                <div key={uuidv4()} className={addressErrors.city ? 'setErrorcompany' : "dropdown-width"}>
                  <DropdownComponent
                    width={"300px"}
                    title={"City"}
                    required={true}
                    options={dropDownRocCities}
                    isDisabled={true}
                    // mainApiData?.vendorOverView?.city === undefined ? "Select" : mainApiData?.vendorOverView?.CompanySubCategory
                    defaultValue={vendorDetails?.address?.contact_city.label !== "" && vendorDetails?.address?.contact_city.label !== null ? [vendorDetails?.address?.contact_city] : [{ label: "Select", value: "Select" }]}
                    getData={(value: any) => {
                      setIsFormDirty(true)
                      dispatch(resetFormModified(true));
                      setVendorDetails({
                        ...vendorDetails,
                        address: {
                          ...vendorDetails.address,
                          contact_city: value,
                        }
                      })
                      // setIconStatus({
                      //   ...iconStatus,
                      //   addressIcon: "selected"
                      // })
                    }}
                  />
                </div>
              </div>
              <div className='form-main-wrapper'>
                <div key={uuidv4()} style={{ marginRight: "45px" }} className={addressErrors.state ? 'setErrorcompany' : "dropdown-width"}>
                  <DropdownComponent
                    width={"300px"}
                    required={true}
                    isDisabled={true}
                    title={"State"}
                    defaultValue={vendorDetails?.address?.contact_state.label !== ""&& vendorDetails?.address?.contact_state.label !== null ? [vendorDetails?.address?.contact_state] : [{ label: "Select", value: "Select" }]}
                    options={dropDownState}
                    getData={(value: any) => {
                      setIsFormDirty(true)
                      dispatch(resetFormModified(true));
                      setVendorDetails({
                        ...vendorDetails,
                        address: {
                          ...vendorDetails.address,
                          contact_state: value,
                        }
                      })
                      // setMainApiData({
                      //   ...mainApiData,
                      //   state: value.value,
                      //   stateId: value.id,
                      //   city: undefined,
                      //   CompanyRegCity: undefined,

                      // })
                      if (value.id == "") {
                        setDropDownRocCities([]);
                      } else {
                        setDropDownRocCities([]);
                        fetchData(`state/cities/${value.id}`, setDropDownRocCities, 'city', "cityId", "name");
                      }
                      setIconStatus({
                        ...iconStatus,
                        addressIcon: "selected"
                      })
                    }}
                  />
                </div>
                <div style={{ marginRight: "45px" }}>
                  <InputComponent
                    height={"40px"}
                    width={"300px"}
                    margin={"0px 0px 30px 0px"}
                    padding={"0px 0px 0px 10px"}
                    disabled={true}
                    border={addressErrors.pinCode ? "1px solid red" : "1px solid #A9C3DC"}
                    borderRadius={"0px"}
                    backgroundColor={"white"}
                    color={"black"}
                    type={"text"}
                    maxLength={6}
                    placeHolder={"Enter Postal Code"}
                    inputValue={vendorDetails?.address?.contact_postalCode}
                    inputTitle={"Postal Code"}
                    required={true}
                    getUser={(value: any) => {
                      const numbersOnly = value.replace(/\D/g, "")
                      setIsFormDirty(true)
                      dispatch(resetFormModified(true));
                      const val = value.replace(/[^0-9]/g, "")
                      setVendorDetails({
                        ...vendorDetails,
                        address: {
                          ...vendorDetails.address,
                          contact_postalCode: numbersOnly,
                        }
                      })
                      if (value.id == "") {
                        setDropDownCities([]);
                      } else {
                        setDropDownCities([]);
                        fetchData(`state/cities/${value.id}`, setDropDownCities, 'city', "cityId", "name");
                      }
                      // setIconStatus({
                      //   ...iconStatus,
                      //   addressIcon: "selected"
                      // })
                    }}
                  />
                </div>
                <div style={{ opacity: "0.7", marginRight: "45px" }} className={addressErrors.country ? 'setErrorcompany' : "dropdown-margin dropdown-width"}>
                  <DropdownComponent
                    width={"300px"}
                    required={true}
                    title={"Country"}
                    defaultValue={[{ label: "India", value: "India" }]}
                    options={[{ label: "India", value: "India" }]}
                    isDisabled={true}
                    getData={(value: any) => {

                      setMainApiData({
                        ...mainApiData,
                        country: value.value,

                      })
                      setIsFormDirty(true)
                      dispatch(resetFormModified(true));
                      setIconStatus({
                        ...iconStatus,
                        addressIcon: "selected"
                      })
                    }}
                  />
                </div>

              </div>
              {/* <div>
              <div className='title'>Address<span className='required'>*</span></div>
              <textarea className='address-textarea' placeholder='Enter Address'
                // disabled={isEditMode && (mainApiData?.status === "Pending Approval") ? true : false}
                style={{ padding: "0px 0px 0px 10px", border: (addressErrors.address) ? "1px solid red" : "" }}
                value={mainApiData && (mainApiData?.address !== "" ? mainApiData?.address : "")}
                maxLength={500} onChange={(e) => {
                  const val = e.target.value.replace(/^\s+/, "");
                  setIsFormDirty(true)
                  dispatch(resetFormModified(true));
                  setMainApiData({
                    ...mainApiData,
                    address: val,

                  })
                  setIconStatus({
                    ...iconStatus,
                    addressIcon: "selected"
                  })
                }} />
            </div> */}
            </div>
            <div>
              <div className='company-heading'>
                Registered Address
              </div>
              <div style={{ marginRight: "45px", marginBottom: "37px" }} className='checkbox-flex'>
                <label className='label-flex'>
                  <input
                    disabled={true}
                    type="checkbox"
                    checked={vendorDetails?.address?.sameAsContactAddress == "Yes"}
                    onClick={(e: any) => {
                      const value = e.target.checked ? 'Yes' : 'No';
                      if (value == "Yes") {
                        setVendorDetails({
                          ...vendorDetails,
                          address: {
                            ...vendorDetails.address,
                            registered_street_1: vendorDetails?.address?.contact_street_1,
                            registered_street_2: vendorDetails?.address?.contact_street_2,
                            registered_city: vendorDetails?.address?.contact_city,
                            registered_state: vendorDetails?.address?.contact_state,
                            registered_postalCode: vendorDetails?.address?.contact_postalCode,
                            sameAsContactAddress: value,
                          }
                        })
                      } else {
                        setVendorDetails({
                          ...vendorDetails,
                          address: {
                            ...vendorDetails.address,
                            registered_street_1: '',
                            registered_street_2: '',
                            registered_city: '',
                            registered_state: '',
                            registered_postalCode: '',
                            sameAsContactAddress: value,
                          }
                        })
                      }

                    }} />
                  <span className='signatory-checkbox'>Same as Contact Address</span></label>
              </div>
              <div className='form-main-wrapper'>
                <div style={{ marginRight: "45px" }}>
                  <InputComponent
                    height={"40px"}
                    width={"300px"}
                    margin={"0px 0px 30px 0px"}
                    padding={"0px 0px 0px 10px"}
                    border={addressErrors.website ? "1px solid red" : "1px solid #A9C3DC"}
                    borderRadius={"0px"}
                    backgroundColor={"white"}
                    color={"black"}
                    disabled={true}
                    type={"text"}
                    maxLength={100}
                    placeHolder={"Enter Street 1"}
                    inputValue={vendorDetails?.address?.registered_street_1}
                    inputTitle={"Street 1"}
                    required={true}
                    getUser={(value: any) => {
                      const removeInitialSpace = value.replace(/^\s+/, "")
                      setIsFormDirty(true)
                      dispatch(resetFormModified(true));
                      // const onlyAlphabet = value.replace(/[^A-Za-z0-9.,:/ ]+/g, '')
                      setVendorDetails({
                        ...vendorDetails,
                        address: {
                          ...vendorDetails.address,
                          registered_street_1: removeInitialSpace,
                        }
                      })
                      // setIconStatus({
                      //   ...iconStatus,
                      //   addressIcon: "selected"
                      // })
                    }}
                  />
                </div>
                <div style={{ marginRight: "45px" }}>
                  <InputComponent
                    height={"40px"}
                    width={"300px"}
                    margin={"0px 0px 30px 0px"}
                    padding={"0px 0px 0px 10px"}
                    border={addressErrors.website ? "1px solid red" : "1px solid #A9C3DC"}
                    borderRadius={"0px"}
                    backgroundColor={"white"}
                    color={"black"}
                    disabled={true}
                    type={"text"}
                    maxLength={100}
                    placeHolder={"Enter Street 2"}
                    inputValue={vendorDetails?.address?.registered_street_2}
                    inputTitle={"Street 2"}
                    required={true}
                    getUser={(value: any) => {
                      const removeInitialSpace = value.replace(/^\s+/, "")
                      setIsFormDirty(true)
                      dispatch(resetFormModified(true));
                      const onlyAlphabet = value.replace(/[^A-Za-z0-9.,:/ ]+/g, '')
                      setVendorDetails({
                        ...vendorDetails,
                        address: {
                          ...vendorDetails.address,
                          registered_street_2: removeInitialSpace,
                        }
                      })
                      // setIconStatus({
                      //   ...iconStatus,
                      //   addressIcon: "selected"
                      // })
                    }}
                  />
                </div>
                <div key={uuidv4()} className={addressErrors.city ? 'setErrorcompany' : "dropdown-width"}>
                  <DropdownComponent
                    width={"300px"}
                    title={"City"}
                    required={true}
                    options={dropDownRocCities}
                    isDisabled={true}
                    // mainApiData?.vendorOverView?.city === undefined ? "Select" : mainApiData?.vendorOverView?.CompanySubCategory
                    defaultValue={vendorDetails?.address?.registered_city.label !== "" && vendorDetails?.address?.registered_city.label !== null? [vendorDetails?.address?.registered_city] : [{ label: "Select", value: "Select" }]}
                    getData={(value: any) => {
                      setIsFormDirty(true)
                      dispatch(resetFormModified(true));
                      setVendorDetails({
                        ...vendorDetails,
                        address: {
                          ...vendorDetails.address,
                          registered_city: value,
                        }
                      })
                      // setIconStatus({
                      //   ...iconStatus,
                      //   addressIcon: "selected"
                      // })
                    }}
                  />
                </div>
              </div>
              <div className='form-main-wrapper'>
                <div key={uuidv4()} style={{ marginRight: "45px" }} className={addressErrors.state ? 'setErrorcompany' : "dropdown-width"}>
                  <DropdownComponent
                    width={"300px"}
                    required={true}
                    isDisabled={true}
                    title={"State"}
                    defaultValue={vendorDetails?.address?.registered_state.label !== ""  && vendorDetails?.address?.registered_state.label !== null? [vendorDetails?.address?.registered_state] : [{ label: "Select", value: "Select" }]}
                    options={dropDownState}
                    getData={(value: any) => {
                      setIsFormDirty(true)
                      dispatch(resetFormModified(true));
                      setVendorDetails({
                        ...vendorDetails,
                        address: {
                          ...vendorDetails.address,
                          registered_state: value,
                        }
                      })
                      // setMainApiData({
                      //   ...mainApiData,
                      //   state: value.value,
                      //   stateId: value.id,
                      //   city: undefined,
                      //   CompanyRegCity: undefined,

                      // })
                      if (value.id == "") {
                        setDropDownRocCities([]);
                      } else {
                        setDropDownRocCities([]);
                        fetchData(`state/cities/${value.id}`, setDropDownRocCities, 'city', "cityId", "name");
                      }
                      setIconStatus({
                        ...iconStatus,
                        addressIcon: "selected"
                      })
                    }}
                  />
                </div>
                <div style={{ marginRight: "45px" }}>
                  <InputComponent
                    height={"40px"}
                    width={"300px"}
                    margin={"0px 0px 30px 0px"}
                    padding={"0px 0px 0px 10px"}
                    disabled={true}
                    border={addressErrors.pinCode ? "1px solid red" : "1px solid #A9C3DC"}
                    borderRadius={"0px"}
                    backgroundColor={"white"}
                    color={"black"}
                    type={"text"}
                    maxLength={6}
                    placeHolder={"Enter Postal Code"}
                    inputValue={vendorDetails?.address?.registered_postalCode}
                    inputTitle={"Postal Code"}
                    required={true}
                    getUser={(value: any) => {
                      const numbersOnly = value.replace(/\D/g, "")
                      setIsFormDirty(true)
                      dispatch(resetFormModified(true));
                      const val = value.replace(/[^0-9]/g, "")
                      setVendorDetails({
                        ...vendorDetails,
                        address: {
                          ...vendorDetails.address,
                          registered_postalCode: numbersOnly,
                        }
                      })
                      // setIconStatus({
                      //   ...iconStatus,
                      //   addressIcon: "selected"
                      // })
                    }}
                  />
                </div>
                <div style={{ opacity: "0.7", marginRight: "45px" }} className={addressErrors.country ? 'setErrorcompany' : "dropdown-margin dropdown-width"}>
                  <DropdownComponent
                    width={"300px"}
                    required={true}
                    title={"Country"}
                    defaultValue={[{ label: "India", value: "India" }]}
                    options={[{ label: "India", value: "India" }]}
                    isDisabled={true}
                    getData={(value: any) => {

                      setMainApiData({
                        ...mainApiData,
                        country: value.value,

                      })
                      setIsFormDirty(true)
                      dispatch(resetFormModified(true));
                      setIconStatus({
                        ...iconStatus,
                        addressIcon: "selected"
                      })
                    }}
                  />
                </div>

              </div>
            </div>
          </div>
          <div>
            <div className='footer-company'>
              <ButtonComponent
                title={"Cancel"}
                height={"50px"}
                width={"150px"}
                margin={"0px 8px"}
                backgroundColor={"#888888"}
                color={"white"}
                className={"button-component-hover cancel"}
                handleClick={isFormModified ? () => { setShowChangesModal(true) } : returnGird}
              />
              <ButtonComponent
                title={"Previous"}
                height={"50px"}
                width={"150px"}
                disabled={false}
                backgroundColor={"#0055D4"}
                color={"white"}
                margin={"0px 8px"}
                className={"button-component-hover common-btn"}
                handleClick={() => { setShowPage('PointOfContact') }}
              />
              {/* <ButtonComponent
                title={isEditMode ? "Update" : "Save"}
                height={"50px"}
                width={"150px"}
                disabled={false}
                backgroundColor={"#0055D4"}
                color={"white"}
                margin={"0px 8px"}
                className={"button-component-hover common-btn"}
                handleClick={() => {
                  // saveVendorDatas() 
                }}
              /> */}
              <ButtonComponent
                title={"Next"}
                height={"50px"}
                width={"150px"}
                disabled={showMessage && showType === "success" ? true : false}
                backgroundColor={"#0055D4"}
                color={"white"}
                margin={"0px 8px"}
                className={
                  showMessage && showType === "success" ?
                    "button-component-hover disabled" : "button-component-hover common-btn"}
                handleClick={() => {
                  setShowPage('KYCDocuments')
                }}
              />
            </div>
          </div>
        </div>
      </div>
      {
        showAlertBox &&
        <div className='alert-warp'>
          <AlertBox type={showType} message={showMessage} />
        </div>
      }
      {
        showChangesModal &&
        <Changesmodal
          closeModal={() => setShowChangesModal(false)}
          handleClose={() => {
            setShowChangesModal(false);
          }}
          leavePage={() => returnGird()} />
      }
    </>
  )
})
export default Address
