import { useState, useEffect } from 'react';
import { BsFilter } from 'react-icons/bs';
import { HiArrowNarrowRight } from 'react-icons/hi';
import { CiSearch } from 'react-icons/ci';
import { AiFillCaretDown } from 'react-icons/ai';
import DropdownComponent from '../form-elements/dropdown-component';
import ButtonComponent from '../form-elements/button-component';
import '../../../styles/common-component-charts/chart-filter.scss';
interface MainData {
    cardTitle: string;
    cardType: string;
    selectedValue: any | string;
    isOpen: boolean;
    options: Option[] | string[];
}
interface Option {
    value: string;
    label: string;
}
interface FilterData {
    datas: MainData[];
    setOpenRightSideFilter: () => void;
    handleApplyButtonForFilter: () => void;
    updateFilterDatas: (index: number, newData: FilterData) => void;
    handleOpenClose: (index: number) => void;
    openDropdownIndex: any;
}

export default function ChartFilter({
    datas,
    setOpenRightSideFilter,
    handleApplyButtonForFilter,
    updateFilterDatas,
    handleOpenClose,
    openDropdownIndex
}: FilterData) {
    const [dropDownData, setDropDownData] = useState<any>([]);

    useEffect(() => {
        setDropDownData(datas)
    }, [datas])

    function handleSearchForFilter(event: any) {
        const searchQuery = event.target.value.toLowerCase();

        if (searchQuery === "") {
            // If the input is empty, set dropDownData back to the original data
            setDropDownData(datas);
        } else {
            const filterData = datas.filter((e: any) => e.cardTitle.toLowerCase().includes(searchQuery))
            setDropDownData(filterData);
        }
    }

    return (
        <>
            <div className='main-filter-container'>
                <div className='heading'>
                    <span>
                        <BsFilter className='filter-icon' />
                        <h2>{"Filters"}</h2>
                    </span>
                    <span onClick={() => { setOpenRightSideFilter() }}>
                        <HiArrowNarrowRight className='arrow-icon' />
                    </span>
                </div>
                <div className='searching-bar'>
                    <CiSearch className='search-icon' />
                    <input onChange={handleSearchForFilter} />
                </div>
                <div className='sub-title-dropDown' >

                    {
                        dropDownData.length > 0 &&
                        dropDownData.map((data: any, index: number) => (
                            <div className='contents' key={index}>
                                <span data-testid="open-close" onClick={() => !data.isDissable && handleOpenClose(index)}>
                                    <AiFillCaretDown className='downarrow-icon' />
                                    <div className='details'>
                                        <span className='type-name'>{data.cardTitle}</span>
                                        <span className='card-type'>{` is (${data.selectedValue})`}</span>
                                    </div>
                                </span>
                                {
                                    data.cardType === "dropdown" && openDropdownIndex === index && (
                                        <div className='dropdown-datas'>
                                            <DropdownComponent
                                                defaultValue={[{
                                                    label: data.selectedValue !== "" ? data.selectedValue : "All",
                                                    value: data.selectedValue !== "" ? data.selectedValue : "All",
                                                }]}
                                                options={data.options}
                                                getData={(val) => {
                                                    updateFilterDatas(index, { ...data, selectedValue: val.value });
                                                }}
                                            />
                                        </div>
                                    )
                                }
                                <>
                                    {
                                        /* {
                                            data.cardType === "checkbox" && openDropdownIndex === index && (
                                                <>
                                                    <div className='checkbox-field-search'>
                                                        <div className='searching-bar'>
                                                            <CiSearch className='search-icon' />
                                                            <input />
                                                        </div>
                                                    </div>
                                                    <span className='checkbox-field'>
                                                        <input
                                                            data-testid={`checkbox-${index}`}
                                                            type='checkbox'
                                                            onChange={(e) => {
                                                                setSelectedState(!selectedState)
                                                                console.log(e.target.checked)
                                                            }}
                                                        />
                                                        <label htmlFor='check'>{"Select All"}</label>
                                                    </span>
            
                                                    {data.options.map((option: any, optionIndex: number) => (
            
                                                        <div key={optionIndex} className='checkbox-field'>
                                                            <input
                                                                data-testid={`checkbox-${index}-${optionIndex}`}
                                                                type='checkbox'
                                                                checked={
                                                                    selectedState ? true :
                                                                        (data.selectedValue as string[]).includes(option)
                                                                }
                                                                onChange={() => handleCheckbox(index, option)}
                                                            />
                                                            <label>{typeof option === "string" && option}</label>
                                                        </div>
                                                    ))}
                                                </>
                                            )
                                        } */
                                    }
                                </>
                            </div>
                        ))
                    }
                </div>
                <div className='rightside-button-main'>
                    <ButtonComponent
                        title={"Apply"}
                        height={"40px"}
                        width={"100px"}
                        backgroundColor={"#0055D4"}
                        disabled={false}
                        color={"white"}
                        margin={"0px 0px"}
                        className={"button-component-hover common-btn"}
                        handleClick={() => { handleApplyButtonForFilter() }}
                    />
                </div>
            </div>
        </>
    );
}

// sending data
// datas={[
//     {
//       cardTitle: "Card Management drop",
//       cardType: "dropdown",
//       selectedValue: "",
//       options: [
//         { value: "TamilNadu", label: "TamilNadu" },
//         { value: "Kerala", label: "Kerala" },
//         { value: "Andhra Pradesh", label: "Andhra Pradesh" }
//       ],
//       isOpen: false
//     },
//     {
//       cardTitle: "Card Management check",
//       cardType: "checkbox",
//       isOpen: false,
//       selectedValue: [],
//       options: ["check", "hello"]
//     },
//     {
//       cardTitle: "Card Management drop",
//       cardType: "dropdown",
//       selectedValue: "",
//       options: [
//         { value: "TamilNadu", label: "TamilNadu" },
//         { value: "Kerala", label: "Kerala" },
//         { value: "Andhra Pradesh", label: "Andhra Pradesh" }
//       ],
//       isOpen: false
//     },
//     {
//       cardTitle: "Card Management check",
//       cardType: "checkbox",
//       isOpen: false,
//       selectedValue: [],
//       options: ["check1", "hello1", "check12", "hello12", "check13", "hello13",]
//     }
//   ]}

// const handleCheckbox = (index: number, data: string) => {
//     setFilterDatas((prevFilterDatas: any) => {
//         const updatedFilterDatas = [...prevFilterDatas];
//         const updatedData = { ...updatedFilterDatas[index] };
//         const selectedValues = updatedData.selectedValue as string[];
//         if (selectedValues.includes(data)) {
//             updatedData.selectedValue = selectedValues.filter((value) => value !== data);
//         } else {
//             updatedData.selectedValue = [...selectedValues, data];
//         }
//         updatedFilterDatas[index] = updatedData;
//         return updatedFilterDatas;
//     });
// };