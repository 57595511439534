import React, { useEffect, useRef, useState, } from 'react';
import '../../../styles/modals/rupay-otp-modal.scss';
import { RxCross1 } from 'react-icons/rx';
import ButtonComponent from '../form-elements/button-component';
import IconButton from '../form-elements/icon-button';
import InputComponent from '../form-elements/input-component';
import DeleteModal from './delete-modal';
import AlertBox from '../alert-box';
import useAxiosPrivate from '../../../services/hooks/useaxios-private';
import rupayImage from '../../../assets/images/rupay-img.png'
import ssl from '../../../assets/images/SSL.png'
import { formatAmount } from '../../../helpers/amountRenderer';
import { format } from "date-fns/esm";
import { async } from 'q';
import { Console } from 'console';
interface Styles {
    clearValue: (val: any) => void;
    executeResponce: (val: any) => void;
    tranctionFailed: (val: any) => void;
    rupaytranctionDetails: any
}



export default function RupayOtpModal({
    clearValue, rupaytranctionDetails, tranctionFailed, executeResponce
}: Styles) {

    const [otp, setOtp] = useState<string>("")
    const [showType, setShowType] = useState("warning") // error message showType
    const [showMessage, setShowMessage] = useState<string>("") // error message showMessage
    const [showAlertBox, setShowAlertBox] = useState(false) // error message ShowAlertBox
    const axiosPrivate = useAxiosPrivate();
    const [timeLeft, setTimeLeft] = useState(59);

    useEffect(() => {
        const intervalId = setInterval(() => {
            // Decrease timeLeft every second
            setTimeLeft((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
        }, 1000);
        // Cleanup the interval when the component is unmounted
        return () => clearInterval(intervalId);
    }, []);

    function formatDate(dateString: any) {
        const originalDate = new Date(dateString)
        const formattedDateTime = format(originalDate, "dd-MMM-yyyy HH:mm:ss");
        return formattedDateTime;
    }

    const clearAleart = () => {
        const timer = setTimeout(() => {
            setShowAlertBox(false);
            setShowMessage("");
            clearTimeout(timer);
        }, 5000);
    }


    const submit = async () => {
        console.log("submited")

        try {
            const response = await axiosPrivate.post(`/vendor-payable/rupayotp-submit`, {
                otp: otp,
                uuid: rupaytranctionDetails.uuid,
                transactionUuid: rupaytranctionDetails.transactionUuid,
                payment_history_id: rupaytranctionDetails.payment_history_id,
            })
            if (response.status === 200) {
                // if (response.data.rupayResponce.responseCode == '00') {
                try {
                    const uuidRes: any = await axiosPrivate.post(`/vendor-payable/invoice/lyra-payment-status/${rupaytranctionDetails.uuid}`);
                    // setMessagePopUp(true)
                    console.log(uuidRes, ',=-------->>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>')
                    if (uuidRes.data.status === "Paid" || uuidRes.data.status === "PAID") {
                        let result = {
                            type: "Payment Success!",
                            message: "Your transaction has been executed flawlessly.",
                            status: "Paid",
                            transactionUuid: rupaytranctionDetails.transactionUuid
                        }
                        executeResponce(result)
                        clearValue(false)
                    } else {
                        let result = {
                            type: "Payment Declined",
                            message: "We regret to inform you that the transaction has been declined. Feel free to retry later.",
                            status: "Failed",
                            transactionUuid: rupaytranctionDetails.transactionUuid
                        }
                        executeResponce(result)
                        clearValue(false)
                    }
                } catch (error) {
                    console.error("Error while checking payment status:", error);

                }

            }
        } catch (error: any) {
            console.log(error)
            let result = {
                type: "Payment Declined",
                message: "We regret to inform you that the transaction has been declined. Feel free to retry later.",
                status: "Failed",
                transactionUuid: rupaytranctionDetails.transactionUuid
            }
            clearValue(false)
            executeResponce(result)

        }
    }


    const ResendOtp = async () => {
        try {
            const response = await axiosPrivate.post(`/vendor-payable/rupay-resend-submit`, {
                uuid: rupaytranctionDetails.uuid,
                transactionUuid: rupaytranctionDetails.transactionUuid,
                payment_history_id: rupaytranctionDetails.payment_history_id,
            })
            if (response.status === 200) {
                setTimeLeft(59)
                // if (response.data.isValid) {
                // } else {
                //     setShowAlertBox(true);
                //     setShowType("warning")
                //     setShowMessage(response.data.message)
                // }
                // clearValue(false)
            }
        } catch (error: any) {
            setShowAlertBox(true)
            setShowType("warning")
            setShowMessage(error.message)
            setTimeout(() => {
                setShowAlertBox(false);
            }, 5000);
        }
    }


    // const cancel = () => {
    //     tranctionFailed()
    // }

    return (
        <div className={"rupay-add-modal"}>
            <div className={"container-add-modal"}>
                {/* <div className={"close-icon"}>
                    <span className={"cross-icon"}>
                        <RxCross1 onClick={() => clearValue(false)
                        } style={{ fontSize: "40px" }} />
                    </span>
                </div> */}
                <div className='sub-container'>
                    <div className='rupay-main'>
                        <div >
                            <img src={rupayImage} className='Rupay-image' />
                        </div>
                        <div className='rupay-sub'>
                            <div>
                                <div className='enter-otp-rupay'>
                                    <p style={{ width: '82%' }}>
                                        Please enter your One Time Password (OTP) sent
                                        to your registered Mobile No
                                    </p>
                                </div>

                                <div className={"rupay-details-main"}>
                                    <div>
                                        <div className={"invoice-details-sub"}>
                                            <p><b>{"Card No:"}</b></p>
                                            <p><b>{rupaytranctionDetails.cardNumber}</b></p>
                                        </div>
                                        <div className={"invoice-details-sub"}>
                                            <p>{"Merchant"}</p>
                                            <p>{'Transcendz Paysol Pvt Limited'}</p>
                                        </div>
                                        <div className={"invoice-details-sub"}>
                                            <p>{"Amount"}</p>
                                            <p>{formatAmount(rupaytranctionDetails.Amount)} {'INR'}</p>
                                        </div>

                                        <div className={"invoice-details-sub"}>
                                            <p><b>{"Enter OTP"}</b></p>
                                            <p>
                                                <InputComponent
                                                    height={"40px"}
                                                    width={"150px"}
                                                    margin={"0px 0px 0px 0px"}
                                                    padding={"10px"}
                                                    border={"2px solid #A9C3DC"}
                                                    borderRadius={"10px"}
                                                    backgroundColor={"white"}
                                                    autoFocus
                                                    color={"black"}
                                                    type={"text"}
                                                    maxLength={6}
                                                    textAlign={'center'}
                                                    disabled={false}
                                                    placeHolder={'Enter otp'}
                                                    className={"vendor-input-type"}
                                                    inputValue={otp}
                                                    getUser={(value) => {
                                                        const number = value.replace(/[^0-9]/g, "")
                                                        setOtp(number)
                                                    }}
                                                />

                                            </p>
                                        </div>
                                        <div className={"invoice-details-sub"}>
                                            <p></p>
                                            <div className='ResendOtp' style={timeLeft === 0 ? { pointerEvents: "auto", cursor: 'pointer', opacity: 1 } : { pointerEvents: "none", cursor: 'auto', opacity: 0.5 }} onClick={() => {
                                                if (timeLeft === 0) {
                                                    ResendOtp()
                                                }
                                            }}><u>Resend OTP
                                                </u> {timeLeft !== 0 && `00:${timeLeft.toString().length === 1 ? 0 : ""}${timeLeft}`} </div>
                                        </div>
                                    </div>
                                    <div className={"buttons review-details-buttons"}>
                                        <ButtonComponent
                                            title={<b>{"Submit"}</b>}
                                            height={"50px"}
                                            width={"150px"}
                                            backgroundColor={"white"}
                                            border={'2px solid gray'}
                                            color={"Black"}
                                            margin={"0px 8px"}
                                            borderRadius={"15px"}
                                            disabled={otp === ''}
                                            className={"button-component-hover common-btn"}
                                            handleClick={() => {
                                                submit()
                                            }}
                                        />
                                        <ButtonComponent
                                            title={<b>{"Cancel"}</b>}
                                            height={"50px"}
                                            width={"150px"}
                                            backgroundColor={"white"}
                                            // #0055D4
                                            border={'2px solid gray'}
                                            color={"Black"}
                                            margin={"0px 8px"}
                                            borderRadius={"15px"}
                                            className={"button-component-hover common-btn"}
                                            handleClick={() => {
                                                let result = {
                                                    type: "Payment Declined",
                                                    Message: "We regret to inform you that the transaction has been declined. Feel free to retry later.",
                                                    Status: "Failed",
                                                    TransactionUuid: rupaytranctionDetails.transactionUuid
                                                }
                                                // tranctionFailed(result) 
                                                executeResponce(result)
                                                clearValue(false)
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ display: "flex", justifyContent: 'space-evenly', alignItems: 'flex-end', paddingRight: '50px' }}>
                            <div>
                                <p>IP Address {rupaytranctionDetails.ip}</p>
                                <p>Date & Time {formatDate(new Date())}</p>
                            </div>
                            <img src={ssl} className='ssl-image' />
                        </div>
                    </div>

                </div>
            </div>

            {
                showAlertBox &&
                <div className='alert-warp'>
                    <AlertBox type={showType} message={showMessage} />
                </div>
            }
        </div>
    )
}





// RuPay
// Please enter your One Time Password (OTP) sent
// to your registered Mobile No
// Card No:
// XXXX XXXX XXXX 1234
// Merchant
// ABC merchant
// Amount
// 2000.00 INR
// Enter OTP
// Submit
// Cancel
// Security warning message showing the IP Address & Access time SSL Certificate validation logo