import React from "react";

const CustomEllipsisRenderer: React.FC<any> = (params: any) => {
    const value = params.value || "";
    const maxLength = 30; // Maximum characters allowed before truncation

    let displayValue = value;
    if (value.length > maxLength) {
        displayValue = value.substring(0, maxLength) + "...";
    }

    const cellStyle: React.CSSProperties = {
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden",
        paddingRight: "3px",
    };

    return <div style={cellStyle}>{displayValue}</div>;
};

export default CustomEllipsisRenderer;

export const excisePaymentColumnData = [
    {
        field: 'selection', headerName: '', minWidth: 60, maxWidth: 60, resizable: false, suppressMovable: true, filter: "agSetColumnFilter", suppressSizeToFit: false, headerCheckboxSelection: true, checkboxSelection: true, showDisabledCheckboxes: true, suppressRowTransform: true, cellStyle: { textAlign: 'center' }
    },
    {
        field: 'centralExciseRegistrationNo', headerName: 'Central Excise Registration No', minWidth: 200, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "number", cellRendererFramework: CustomEllipsisRenderer
    },
    {
        field: 'documentType', headerName: 'Document Type', minWidth: 200, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "number", cellRendererFramework: CustomEllipsisRenderer
    },
    {
        field: 'dutyType', headerName: 'Duty Type', minWidth: 200, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer
    },
    // {
    //     field: 'accountName', headerName: 'Account Name', minWidth: 200, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer
    // },

    {
        field: 'amount', headerName: 'Amount(₹)', minWidth: 122, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'right' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer
    },
    // {
    //     field: 'address', headerName: 'Address', minWidth: 200, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype : "text"
    // },

    // {
    //     field: 'CheckerStatus', headerName: 'Checker Status', minWidth: 200, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellClassRules: {
    //         'success-cell': (params: any) => params.value === 'Approved',
    //         'registered-cell': (params: any) => params.value === 'Pending Approval',
    //         'denied-cell': (params: any) => params.value === 'Declined',
    //     }, cellRendererFramework: CustomEllipsisRenderer
    // },
    {
        field: 'approvalStatus', headerName: 'Approver Status', minWidth: 200, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellClassRules: {
            'paid-cell': (params: any) => params.value === 'Approved',
            'expired-cell': (params: any) => params.value === 'Declined',
            'pending-approval-cell': (params: any) => params.value === 'Pending Approval',
            // 'unpaid-cell': (params: any) => params.value === 'Unpaid',
        }, cellRendererFramework: CustomEllipsisRenderer,
    },
    {
        field: 'paymentStatus', headerName: 'Payment Status', minWidth: 200, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellClassRules: {
            'paid-cell': (params: any) => params.value === 'Paid',
            'expired-cell': (params: any) => params.value === 'Unpaid',
            'pending-approval-cell': (params: any) => params.value === 'Pending',
        }, cellRendererFramework: CustomEllipsisRenderer,
    },
    {
        field: 'accountNumber', headerName: 'Account Number', minWidth: 200, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer
    },
    {
        field: 'ifscCode', headerName: 'IFSC Code', minWidth: 200, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer
    },
    {
        field: 'challanExpiryDate', headerName: 'Chellan Expiry Date', minWidth: 200, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer
    },
    {
        field: 'approvedBy', headerName: 'Approved / Declined by', minWidth: 200, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer
    },

    // {
    //     field: 'remarks', headerName: 'Remarks', minWidth: 200, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype : "text"
    // },



]


export const rowDatathis = [
    {
        CentralExciseRegistrationNo: "12ASSDW3434DFDF",
        DocumentType: "ECCS",
        DutyType: "CE",
        AccountNumber: "145825787565826",
        IFSCCode: "KVBL0877878",
        ChellanExpiryDate: "01/02/2022",
        amount: "12,000",
        CheckerStatus: "Approved",
        ApproverStatus: "Approved",
        PaymentStatus: "Approved",
        approvedBy: "Nagarajan",

    },
    {
        CentralExciseRegistrationNo: "12ASSDW3434DFDK",
        DocumentType: "Top Up",
        DutyType: "CE",
        AccountNumber: "9748575487575",
        IFSCCode: "KVBL0877877",
        ChellanExpiryDate: "02/02/2022",
        amount: "12,000",
        CheckerStatus: "Approved",
        ApproverStatus: "Approved",
        PaymentStatus: "Approved",
        approvedBy: "Nagarajan",

    },
    {
        CentralExciseRegistrationNo: "12ASSDW3434DFDA",
        DocumentType: "ICES",
        DutyType: "CE",
        AccountNumber: "8784578578575",
        IFSCCode: "KVBL0877874",
        ChellanExpiryDate: "03/02/2022",
        amount: "12,000",
        CheckerStatus: "Approved",
        ApproverStatus: "Approved",
        PaymentStatus: "Approved",
        approvedBy: "Nagarajan",

    },
]