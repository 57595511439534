import React, { useState, useEffect } from 'react';
import FilterHeader from './filter-header';
import { dashBoardSize } from "../../config/dashboard/product-admin-dashboard-config";
import '../../styles/dashboard/product-admin-dashboard.scss';

// Import your dashboard components here
import CustomersVsTransactionValue from './customers-vs-transaction-value';
import RegionVsTransactionValue from './region-vs-transaction-value';
import CardVsTransactionValue from './card-vs-transaction-value';
import CardNetworkVsTransactionValue from './card-network-vs-transaction-value';
import SegmentsVsTransactionValue from './segments-vs-transaction-value';
import PaymentModeVsTransactionValue from './payment-mode-vs-transaction-value';
interface propsType {
  Component: any,
  openDashboard: boolean,
  dashBoardWidth: any,
  setIsOpenEmpty: any,
  setOpenOneDashboard: any,
}

function DashboardComponent({ Component, openDashboard, dashBoardWidth, setIsOpenEmpty, setOpenOneDashboard }: propsType) {
  return (
    <Component
      isOpenEmpty={openDashboard}
      setIsOpenEmpty={setIsOpenEmpty}
      dashBoardWidth={dashBoardWidth}
      setOpenOneDashboard={setOpenOneDashboard}
    />
  );
}

function BankAdminDashboard() {
  const [dashBoardWidth, setDashBoardWidth] = useState(dashBoardSize);
  const [openOneDashboard, setOpenOneDashboard] = useState<string>("");
  const [isOpenEmpty, setIsOpenEmpty] = useState<boolean>(true);

  useEffect(() => {
    const width = window.innerWidth;

    if (width < 1869 && width > 1601) {
      setDashBoardWidth({
        cardWidth: `${(width - 410)}px`,
        cardHeight: "400px",
        chartWidth: `${(width - 600)}px`,
        chartHeight: "300px",
      })
    } else if (width < 1601) {
      setDashBoardWidth({
        cardWidth: `${(width - 210)}px`,
        cardHeight: "400px",
        chartWidth: `${(width - 400)}px`,
        chartHeight: "300px",
      })
    }
  }, [])

  function changeDashBoardSize() {
    const width = window.innerWidth;
    const height = window.innerHeight;

    if (openOneDashboard == "") {
      if (width < 1869 && width > 1601) {
        setDashBoardWidth({
          cardWidth: `${(width - 410)}px`,
          cardHeight: `${(height - 350)}px`,
          chartWidth: `${(width - 600)}px`,
          chartHeight: `${(height - 450)}px`,
        })
      } else if (width < 1601) {
        setDashBoardWidth({
          cardWidth: `${(width - 210)}px`,
          cardHeight: `${(height - 350)}px`,
          chartWidth: `${(width - 400)}px`,
          chartHeight: `${(height - 450)}px`,
        })
      } else {
        setDashBoardWidth({
          cardWidth: `${(width - 410)}px`,
          cardHeight: `${(height - 300)}px`,
          chartWidth: `${(width - 600)}px`,
          chartHeight: `${(height - 400)}px`,
        })
      }
    } else {
      if (width < 1869 && width > 1601) {
        setDashBoardWidth({
          cardWidth: `${(width - 410)}px`,
          cardHeight: "400px",
          chartWidth: `${(width - 600)}px`,
          chartHeight: "300px",
        })

      } else if (width < 1601) {
        setDashBoardWidth({
          cardWidth: `${(width - 210)}px`,
          cardHeight: "400px",
          chartWidth: `${(width - 400)}px`,
          chartHeight: "300px",
        })
      } else {
        setDashBoardWidth(dashBoardSize)
      }
    }
  }

  // Define your dashboard components here
  const dashboardComponents = [
    { Component: RegionVsTransactionValue, key: "regionVsTransactionValue" },
    { Component: CardVsTransactionValue, key: "cardVsTransactionValue" },
    { Component: CustomersVsTransactionValue, key: "customersVsTransactionValue" },
    { Component: CardNetworkVsTransactionValue, key: "cardNetworkVsTransactionValue" },
    { Component: SegmentsVsTransactionValue, key: "segmentsVsTransactionValue" },
    { Component: PaymentModeVsTransactionValue, key: "paymentModeVsTransactionValue" },
  ];

  return (
    <div className='product-admin-dashboard-component'>
      {/* <FilterHeader /> */}
      <div className='bank-admin-dashboard-main'>
        {
          openOneDashboard === "" ?
            (
              dashboardComponents.map((componentData) => (
                <React.Fragment key={componentData.key}>
                  <DashboardComponent
                    Component={componentData.Component}
                    openDashboard={isOpenEmpty}
                    dashBoardWidth={dashBoardWidth}
                    setIsOpenEmpty={() => setIsOpenEmpty(false)}
                    setOpenOneDashboard={(value: any) => {
                      setOpenOneDashboard(value)
                      changeDashBoardSize()
                    }}
                  />
                </React.Fragment>
              ))
            )
            :
            (
              dashboardComponents.map((componentData) => (
                <React.Fragment key={componentData.key}>
                  {
                    openOneDashboard === componentData.key && (
                      <DashboardComponent
                        Component={componentData.Component}
                        openDashboard={isOpenEmpty}
                        dashBoardWidth={dashBoardWidth}
                        setIsOpenEmpty={() => setIsOpenEmpty(true)}
                        setOpenOneDashboard={(value: any) => {
                          if (openOneDashboard === value) {
                            changeDashBoardSize()
                            setOpenOneDashboard("")
                          } else {
                            changeDashBoardSize()
                            setOpenOneDashboard(value)
                          }
                        }}
                      />
                    )
                  }
                </React.Fragment>
              ))
            )
        }
      </div>
    </div>
  );
}

export default BankAdminDashboard;