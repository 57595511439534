import React, { useRef, useState } from 'react'
import VendorGrid, { SampleDatasArun } from './vendor-grid';
import { CiSearch } from 'react-icons/ci';
import IconButton from '../../../common-component/form-elements/icon-button';
import ChartFilter from '../../../common-component/charts/chart-filter';
import CardGrid from './card-grid';
import BankGrid from './bank-grid';
import CustomerGrid from './customer-grid';
import UserGrid from './user-grid';
import VirtualCardGrid from './virtual-card-grid';
import MainPageCard from '../../../common-component/cards/main-page-card';
import NavigateHeader from '../../../common-component/navigate-header';
import '../../../../styles/reports/reports.scss';
import { ReactComponent as Vendor } from '../../../../assets/icons/reports/Vendor.svg'
import { ReactComponent as Card } from '../../../../assets/icons/reports/Card.svg'
import { ReactComponent as Bank } from '../../../../assets/icons/reports/Bankicons.svg'
import { ReactComponent as VirtualCard } from '../../../../assets/icons/reports/Virtual Card.svg'
import { ReactComponent as Customer } from '../../../../assets/icons/reports/Customer.svg'
import { ReactComponent as User } from '../../../../assets/icons/reports/Users.svg'
import Tooltip from 'rc-tooltip';
import DownloadOption from '../../download-option';
import { handlePngPDFDownload } from '../../../common-component/export-pdf/Export-to-pdf';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { useReactToPrint } from 'react-to-print';


interface props {
}

export default function MasterDetailsReports({ }: props) {
    const [filterOpen, setFilterOpen] = useState<boolean>(false);
    const [selectedMasterName, setSelectedMasterName] = useState("Vendor")
    const [searchBarValue, setSearchBarValue] = useState("")
    const [downloadoption, setDownloadoption] = useState<boolean>(false);
    const divRef = useRef<HTMLDivElement>(null);


    const masterReportsIcons = [
        { name: 'Vendor', icon: <Vendor />, palceholder: "Search by Name/Code" },
        { name: 'Card', icon: <Card />, palceholder: "Search by Card Number" },
        { name: 'Bank', icon: <Bank />, palceholder: "Search by Account Number" },
        { name: 'Customer', icon: <Customer />, palceholder: "Search by Name/Code" },
        { name: 'Virtual Card', icon: <VirtualCard />, palceholder: "Search by Card Number" },
        { name: 'Users', icon: <User />, palceholder: "Search by Name/Email ID" },
    ];



    // all data filter
    // const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     const value = event.target.value;
    //     setSearchValue(value);

    //     const filtered = SampleDatasArun.filter((item) => {
    //         // Customize the condition based on your specific requirements
    //         return Object.values(item).some((val) => {
    //             if (val && typeof val === 'object') {
    //                 // If the value is an object and not null/undefined, check its values
    //                 return Object.values(val).some((nestedVal: any) =>
    //                     nestedVal && nestedVal.toString().toLowerCase().includes(value.toLowerCase())
    //                 );
    //             } else if (val) {
    //                 // If the value is not an object and not null/undefined, directly check it
    //                 return val.toString().toLowerCase().includes(value.toLowerCase());
    //             }
    //             return false; // Skip null/undefined values
    //         });
    //     });

    //     setFilteredData(filtered); 
    // };


    // const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     const value = event.target.value;
    //     setSearchValue(value);

    //     // Filter data based on the search value
    //     const filtered = SampleDatasArun.filter((item) => {
    //         // Customize the condition based on your specific requirements 
    //         return item.VendorDeatils.Name.toLowerCase().includes(value.toLowerCase());
    //     });

    //     setFilteredData(filtered);
    // };


    // two data filter

    const downloadPDF = () => {
        const input = document.getElementById('table-to-pdf'); // Replace 'table-to-pdf' with the ID of your table
        if (!input) {
            console.error('Table not found');
            return;
        }

        html2canvas(input)
            .then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF();
                const imgWidth = 210; // A4 size
                const imgHeight = (canvas.height * imgWidth) / canvas.width;

                pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
                pdf.save('table-data.pdf');
            })
            .catch((error) => {
                console.error('Error converting table to PDF:', error);
            });
    };
    function handleSignup(iconName: any) {
        setFilterOpen(false)
        setSelectedMasterName(iconName)
    }

    const conponentPDFMaster: any = useRef()
    const conponentPDFMasterfunction = useReactToPrint({
        content: () => conponentPDFMaster.current,
        documentTitle: "MasterData",
        pageStyle: `
            @page {
              size: landscape;
              margin: 0.5in; // Adjust margins as needed
            }
          `,
    });
    return (
        <>
            <MainPageCard>
                <NavigateHeader
                    style={{ cursor: "pointer" }}
                    firstValue={"Reports"}
                    secondValue={"Master Details"}
                    navigatePage={() => { }}
                />
      
                <div style={{ height: "660px",overflowY:"scroll", marginTop:"70px"}}>
                    <div style={{display: "flex", justifyContent: "space-between" }} >
                        <div className='master-list-main'>
                            {
                                masterReportsIcons.map((data: any, index: number) => (
                                    <>
                                        <div className={selectedMasterName === data.name ? 'bottom-border-line' : ""} >
                                            <div key={index} onClick={() =>
                                                handleSignup(data.name)
                                            } className='check'>

                                                <Tooltip
                                                    placement="bottom"
                                                    overlay={data.name}
                                                    overlayClassName="custom-tooltip"
                                                >
                                                    <div className={""} >{data.icon}</div>
                                                </Tooltip>
                                            </div>
                                        </div>
                                        <div className={index === masterReportsIcons.length - 1 ? "" : 'border-line'}>

                                        </div>
                                    </>
                                ))}
                        </div>
                        <div style={{ display: "flex", alignItems: "center", gap: "10px", justifyContent: "end", marginTop: "10px", position: "relative" }}>
                            <div className='searching-bar-report' >
                                <CiSearch className='search-icon' />
                                <input
                                    className="input-area"
                                    placeholder={masterReportsIcons.find(obj => obj.name === selectedMasterName)?.palceholder}
                                    value={searchBarValue}
                                    onChange={(event: any) => {
                                        setSearchBarValue(event.target.value)
                                    }}
                                />
                            </div>

                            <div className='filter-area' >
                                <IconButton
                                    iconName={"Filter"}
                                    height={"40px"}
                                    width={"40px"}
                                    fontSize={"30px"}
                                    margin={"0px 8px"}
                                    color={"white"}
                                    backgroundColor={"#0055D4"}
                                    disabled={true}
                                    hover={true}
                                    handleClick={() => {
                                        setFilterOpen(true)
                                    }}
                                />
                            </div>
                            <div>
                                <IconButton
                                    iconName={"Download"}
                                    height={"40px"}
                                    width={"40px"}
                                    fontSize={"25px"}
                                    margin={"0px 8px"}
                                    color={"white"}
                                    backgroundColor={"#0055D4"}
                                    hover={true}
                                    handleClick={
                                        (event: React.MouseEvent) => {
                                            event?.stopPropagation()
                                            // handlePngPDFDownload(divRef)
                                            setDownloadoption(true)
                                        }
                                    }
                                />
                            </div>
                            {
                                downloadoption &&
                                <div>
                                    <DownloadOption
                                        getMethodFun={(value: any) => {
                                            conponentPDFMasterfunction()

                                        }}
                                        handleclose={(value: any) => { setDownloadoption(value) }}
                                    />
                                </div>
                            }
                        </div>

                    </div>
                    <div>
                        {
                            selectedMasterName === "Vendor" &&
                            <VendorGrid
                                conponentPDFMaster={conponentPDFMaster}
                                filterOpen={filterOpen}
                                setFilterOpen={setFilterOpen}
                                searchBarValue={searchBarValue}
                            />
                        }
                        {
                            selectedMasterName === "Card" &&
                            <CardGrid
                                conponentPDFMaster={conponentPDFMaster}
                                filterOpen={filterOpen}
                                setFilterOpen={setFilterOpen}
                                searchBarValue={searchBarValue}
                            />
                        }
                        {
                            selectedMasterName === "Bank" &&
                            <BankGrid
                                conponentPDFMaster={conponentPDFMaster}
                                filterOpen={filterOpen}
                                setFilterOpen={setFilterOpen}
                                searchBarValue={searchBarValue}

                            />
                        }
                        {
                            selectedMasterName === "Customer" &&
                            <CustomerGrid
                                filterOpen={filterOpen}
                                setFilterOpen={setFilterOpen}
                                conponentPDFMaster={conponentPDFMaster}
                                searchBarValue={searchBarValue}
                            />
                        }
                        {
                            selectedMasterName === "Virtual Card" &&
                            <VirtualCardGrid
                                conponentPDFMaster={conponentPDFMaster}
                                filterOpen={filterOpen}
                                setFilterOpen={setFilterOpen}
                                searchBarValue={searchBarValue}

                            />
                        }
                        {
                            selectedMasterName === "Users" &&
                            <UserGrid
                                conponentPDFMaster={conponentPDFMaster}
                                filterOpen={filterOpen}
                                setFilterOpen={setFilterOpen}
                                searchBarValue={searchBarValue}
                            />
                        }
                    </div>
                </div>



            </MainPageCard>

        </>
    )
}
