import React, { useEffect, useState, forwardRef, useImperativeHandle, } from 'react'
import InputComponent from '../../common-component/form-elements/input-component'
import DropdownComponent from '../../common-component/form-elements/dropdown-component'
import IconButton from '../../common-component/form-elements/icon-button'
import ButtonComponent from '../../common-component/form-elements/button-component'
import DirectorTable from '../../../config/onboarding/director-info-grid'
import useAxiosPrivate from "../../../services/hooks/useaxios-private";
import Changesmodal from '../../common-component/modals/changes-modal';
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'
import { useDispatch, useSelector } from "react-redux";
import { resetFormModified } from "../../../redux/action";
import AlertBox from '../../common-component/alert-box'
import DeleteModal from '../../common-component/modals/delete-modal'
import FileUpload from '../../common-component/file-upload'
import DocumentModal from '../../common-component/modals/document-modal'


export interface ChildRef {
  next: (val: any) => void;
}
interface DropdownItem {
  label: string;
  value: string;
}
interface props {

  directorApiData: any;
  singleDirectorInfoPan: any;
  singleDirectorAddressInfoPan: any;
  directorInfoPan: any;
  fetchDirectorPan: (value: any) => void;
  setDirectorInfoPan: (value: any) => void;
  setDirectorApiData: (value: any) => void;
  setSingleDirectorInfoPan: (value: any) => void;
  onboardingViewfun: (val: any) => void;
  isEditMode: boolean;
  sendDataApi: () => void;
  iconStatus: any
  setShowPage: (val: any) => void;
  setIconStatus: (val: any) => void;
  editData: any;
}
const initData: any = {
  dateOfBirth: "",
  gender: "Select",
  email: "",
  contactNumber: "",
  secondaryContactNumber: "",
  ownership: "",
  relation: "",
  maskedAadhaarNumber: "",
  country: "",
  street: "",
  state: "",
  city: "",
  pincode: "",
  address: "",
  isAuthSignatory: "",
  DirectorName: "",
  DirectorDin: "",
  DirectorDesignation: "",
  directorPan: "",
  index: 0
}

const DirectorsInfo = forwardRef<ChildRef, props>(({ directorApiData, isEditMode, setDirectorApiData, sendDataApi, setIconStatus, editData, setSingleDirectorInfoPan, setShowPage, iconStatus, fetchDirectorPan, directorInfoPan, onboardingViewfun, setDirectorInfoPan, singleDirectorInfoPan, singleDirectorAddressInfoPan, }, ref) => {
  const [showType, setShowType] = useState("danger") // error message showType
  const [showMessage, setShowMessage] = useState<string>("") // error message showMessage
  const [showAlertBox, setShowAlertBox] = useState(false) // error message ShowAlertBox
  const [formData, setFormData] = useState(initData)
  const [address, setAddress] = useState<any>()
  const [directorInfoErrors, seDirectorInfoErrors] = useState<Record<string, boolean>>({})
  const [addButton, setAddButton] = useState(false)
  const [currentFormDataEdit, setCurrentFormDataEdit] = useState(false);
  const [currentDirector, setCurrentDirector] = useState<number>();
  const [directorInfoState, setDirectorInfoState] = useState<any[]>([]);
  const [directorInfoCities, setDirectorInfoCities] = useState<any[]>([]);
  const [noDuplicate, setNoDuplicate] = useState(false)
  const [isFormDirty, setIsFormDirty] = useState(false)
  const isFormModified = useSelector((state: any) => state.isFormModified);
  const [showChangesModal, setShowChangesModal] = useState<boolean>(false);
  const [showDeleteModel, setShowDeleteModel] = useState<boolean>(false)
  const [deleteIndex, setDeleteIndex] = useState<number>(0)
  const [currentDeletVal, setCurrentDeletVal] = useState<any>({});
  const [dropDownState, setDropDownState] = useState<DropdownItem[]>([])
  const [dropDownCities, setDropDownCities] = useState<DropdownItem[]>([])
  const dispatch = useDispatch();
  const [currIndex, setCurrIndex] = useState<number>()
  const todayDate = moment(new Date()).format('YYYY-MM-DD');
  const [showDocumentModal, setShowDocumentModal] = useState(false)
  const [currentDoc, setCurrentDoc] = useState<any>()
  const [stakeHolderDoc, setStakeHolderDoc] = useState<any>(
    [{ documentType: "Owner PAN / Signatory PAN", fileName: "", razoryPayDocumentType: "personal_pan", file: "" },
    { documentType: "Aadhar Front", fileName: "", razoryPayDocumentType: "aadhar_front", file: "" },
    { documentType: "Aadhar Back", fileName: "", razoryPayDocumentType: "aadhar_back", file: "" }
    ]
  );
  function handleFileSelect(fileName: any, index: any) {
    const updatedKycDocuments = stakeHolderDoc?.map((doc: any, i: any) =>
      i === index ? { ...doc, fileName: fileName[0].name, file: fileName } : doc
    );
    setFormData({ ...formData, stakeHolderDoc: updatedKycDocuments })
    setStakeHolderDoc(updatedKycDocuments);
  };
  const [formDataError, setFormDataError] = useState({
    DirectorDesignation: false,
    DirectorName: false,
    DirectorDin: false,
  })


  function handelDirectorPan() {
    const isNameValid = formData.DirectorName !== undefined || formData.DirectorName !== "";
    const isDesignationValid = formData.DirectorDesignation !== undefined || formData.DirectorDesignation !== "";
    const isDinValid = formData.DirectorDin !== undefined || formData.DirectorDin !== "";
    if (isNameValid && isDesignationValid && isDinValid) {
      fetchDirectorPan(directorInfoPan);
      setFormDataError({
        DirectorDesignation: false,
        DirectorName: false,
        DirectorDin: false,
      });
    } else {
      setFormDataError({
        DirectorName: !isNameValid,
        DirectorDesignation: !isDesignationValid,
        DirectorDin: !isDinValid,
      });
      setShowAlertBox(true);
      setShowType("danger");
      setShowMessage("Mandatory field(s) should not be left blank.")
      clearAlert('');
    }
  }

  function returnGird() {
    onboardingViewfun("grid")
    dispatch(resetFormModified(false));
  }
  const fetchData = async (url: string, setData: (data: any[]) => void, nestedProperty: string, id: any, representName: any) => {
    try {
      const response = await axiosPrivate.get(url);
      if (response.status === 200) {
        const dropdownValues = response.data[nestedProperty].map((item: any) => ({
          label: item[representName],
          value: item[representName],
          id: item[id],
        }));
        const sortedData = [...dropdownValues].sort((a, b) => a.label.localeCompare(b.label));
        setData([...sortedData]);
      }
    } catch (error) {
    }
  };

  async function duplicateCheck() {
    const finalData = {
      "DirectorDin": formData.DirectorDin,
      "email": formData.email,
      "contactNumber": formData.contactNumber,
      "maskedAadhaarNumber": formData.maskedAadhaarNumber,

    }
    let statusMessage: boolean = false;
    try {

      const fieldsToCheck = ['DirectorDin', 'directorPan', 'email', 'contactNumber', 'maskedAadhaarNumber'];
      const todisplay = ['Director Din', 'Director Pan', 'Email', 'Contact Number', 'Aadhar Number'];
      const failedFields: any[] = [];
      let isDuplicate = false;
      if (!currentFormDataEdit) {
        isDuplicate = directorApiData.some((item: any) => {
          const matches = fieldsToCheck.map((field, i) => {
            if (formData[field] && formData[field] !== '') {
              if (item[field] === formData[field]) {
                failedFields.push(todisplay[i]);
              }
              return item[field] === formData[field];
            }
            return false;
          });
          return matches.some(match => match);
        });
      } else {

        isDuplicate = directorApiData.some((item: any, index: any) => {
          const matches = fieldsToCheck.map((field, i) => {
            if (formData[field] && formData[field] !== '' && currIndex !== index) {
              if (item[field] === formData[field]) {
                failedFields.push(todisplay[i]);
              }
              return item[field] === formData[field];
            }
            return false;
          });
          return matches.some(match => match);
        });
      }
      if (!isDuplicate) {
        const res = await axiosPrivate.post(`bank-admin/company/duplicate-checking/${currentDirector}`, { "directorsInfo": finalData })
        if (res.status === 200) {
          statusMessage = true;
        }
      } else {
        statusMessage = false
        setShowAlertBox(true)
        setShowType("danger")
        setShowMessage(`${failedFields[0]} already exists.`)
        clearAlert("")
      }
    }
    catch (error: any) {
      statusMessage = false
      setShowAlertBox(true)
      setShowType("danger")
      setShowMessage(error.response.data.error);
      clearAlert("")
    }
    return statusMessage;
  }
  const clearAlert = (status: any) => {
    const timer = setTimeout(() => {
      setShowAlertBox(false);
      setShowMessage("");
      if (status === 400) {

      }
      clearTimeout(timer);
    }, 5000);
  }
  useEffect(() => {
    fetchData("state", setDropDownState, "states", "stateId", "name");
  }, [])
  const isDateValid = () => {
    const fromDate = new Date(formData.dateOfBirth);
    let isOk = false
    if (formData.dateOfBirth && fromDate.toString() == "Invalid Date") return false;
    if (formData.dateOfBirth) {
      if (fromDate < new Date() && fromDate > new Date("1900-01-01")) {
        isOk = true
        seDirectorInfoErrors({ dateOfBirth: false })
      }
      else {
        isOk = false
        seDirectorInfoErrors({ dateOfBirth: true })
      }
    } else {
      isOk = true
      seDirectorInfoErrors({ dateOfBirth: false })
    }
    return isOk;
  }
  console.log("directorInfoErrors", directorInfoErrors)
  async function handelAdd() {
    if (!validateForm()) {
      setShowAlertBox(true);
      setShowType("danger");
      setShowMessage(Object.values(updatedIsValueValidAll).every((values) => !values) ?
        "Some of the field(s) are not in required format." : "Mandatory field(s) should not be left blank.")
      clearAlert('');
      return;
    }
    if (!stakeHolderDoc.every((e: any) => e.file !== "")) {
      setShowAlertBox(true);
      setShowType("danger");
      setShowMessage("Upload directors documents.")
      clearAlert('');
      return;
    }
    let isvalidDOB = isDateValid()
    if (!isvalidDOB) {
      setShowAlertBox(true);
      setShowType("danger");
      setShowMessage("Some of the field(s) are not in required format.")
      clearAlert('');
      return;
    }

    let Duplicate = await duplicateCheck();
    setDirectorInfoPan("")
    setSingleDirectorInfoPan("")
    setAddress("")

    if (!Duplicate) return;
    if (currentFormDataEdit) {
      let data: any = [];
      directorApiData.length > 0 && directorApiData.map((ele: any, i: number) => {
        if (i === formData.index) {
          data.push(formData)
        } else {
          data.push(ele)
        }
      })
      setDirectorApiData(data)
      setFormData(
        {
          dateOfBirth: "",
          gender: "Select",
          email: "",
          contactNumber: "",
          secondaryContactNumber: "",
          stakeHolderDoc: stakeHolderDoc,
          ownership: "",
          relation: "",
          maskedAadhaarNumber: "",
          country: "",
          street: "",
          state: "",
          city: "",
          pincode: "",
          address: "",
          DirectorName: "",
          isAuthSignatory: "",
          DirectorDin: "",
          DirectorDesignation: "",
          directorPan: "",
          index: 0
        }
      )
      setStakeHolderDoc(
        [{ documentType: "Owner PAN / Signatory PAN", fileName: "", razoryPayDocumentType: "personal_pan", file: "" },
        { documentType: "Aadhar Front", fileName: "", razoryPayDocumentType: "aadhar_front", file: "" },
        { documentType: "Aadhar Back", fileName: "", razoryPayDocumentType: "aadhar_back", file: "" }
        ]
      )
      next()
      setDropDownCities([]);
      setIsFormDirty(false);
      setCurrentFormDataEdit(false);
      setShowAlertBox(true);
      setShowType("success");
      setShowMessage("Director Info updated successfully.")
      clearAlert('');
    }
    else {
      let newrecord = directorApiData
      newrecord.push({ ...formData })
      setDirectorApiData(newrecord)
      setFormData(
        {
          dateOfBirth: "",
          gender: "Select",
          email: "",
          contactNumber: "",
          secondaryContactNumber: "",
          stakeHolderDoc: stakeHolderDoc,
          ownership: "",
          relation: "",
          maskedAadhaarNumber: "",
          country: "",
          street: "",
          state: "",
          city: "",
          pincode: "",
          address: "",
          isAuthSignatory: "",
          DirectorName: "",
          DirectorDin: "",
          DirectorDesignation: "",
          directorPan: "",
          index: 0
        }
      )
      setStakeHolderDoc(
        [{ documentType: "Owner PAN / Signatory PAN", fileName: "", razoryPayDocumentType: "personal_pan", file: "" },
        { documentType: "Aadhar Front", fileName: "", razoryPayDocumentType: "aadhar_front", file: "" },
        { documentType: "Aadhar Back", fileName: "", razoryPayDocumentType: "aadhar_back", file: "" }
        ]
      )
      next()
      setIsFormDirty(false);
    }
    setCurrentFormDataEdit(false);

  }

  const userValidationRules: any = {
    DirectorName: {
      regex: /^[A-Za-z][A-Za-z\s]*$/,
      field: "mandatory",
      shouldNotBe: ""
    },
    DirectorDin: {
      regex: /^[0-9]{7,8}$/,
      field: "mandatory",
      shouldNotBe: ""
    },
    DirectorDesignation: {
      regex: /^[A-Za-z][A-Za-z\s]*$/,
      field: "mandatory",
      shouldNotBe: ""
    },
    directorPan: {
      regex: formData?.directorPan ? /[A-Z]{5}\d{4}[A-Z]{1}/ : "",
      field: "mandatory",
      shouldNotBe: ""
    },
    email: {
      regex: formData?.email ? /^[a-z0-9._-]+@[a-z0-9-]+\.[a-z]{2,}$/ : "",
      field: "mandatory",
      shouldNotBe: ""
    },
    contactNumber: {
      regex: formData?.contactNumber ? /^[0-9]{10}$/ : "",
      field: "mandatory",
      shouldNotBe: ""
    },
    secondaryContactNumber: {
      regex: formData?.secondaryContactNumber ? /^[0-9]{10}$/ : "",
      field: "mandatory",
      shouldNotBe: ""
    },
    ownership: {
      regex: '',
      field: "mandatory",
      shouldNotBe: ""
    },
    relation: {
      regex: '',
      field: "mandatory",
      shouldNotBe: ""
    },
    maskedAadhaarNumber: {
      regex: (formData?.maskedAadhaarNumber && formData?.maskedAadhaarNumber.substring(0, 1)) == "X" ? "" : formData?.maskedAadhaarNumber ? /^\d{4} \d{4} \d{4}$/ : "",
      field: "",
      shouldNotBe: ""
    },
    pincode: {
      regex: formData?.pincode ? /^[0-9]{6}$/ : "",
      field: "mandatory",
      shouldNotBe: ""
    },
    state: {
      regex: "",
      field: "mandatory",
      shouldNotBe: ""
    },
    city: {
      regex: "",
      field: "mandatory",
      shouldNotBe: ""
    },
    street: {
      regex: "",
      field: "mandatory",
      shouldNotBe: ""
    },
  }

  const updatedIsValueValidAll: Record<string, boolean> = {};
  const validateForm = (): boolean => {
    const updatedFieldValidity: Record<string, boolean> = {};
    Object.keys(userValidationRules).forEach((field) => {
      const rule = userValidationRules[field];
      const value = formData[field];
      const isValueValid = (rule.field === "mandatory") ? (value ? (value !== rule.shouldNotBe) : false) : true;
      const isRegexValid = rule.regex !== "" ? new RegExp(rule.regex).test(value) : true;
      updatedFieldValidity[field] = !isValueValid || !isRegexValid;
      updatedIsValueValidAll[field] = !isValueValid;
    });
    seDirectorInfoErrors(updatedFieldValidity);
    const isFormValid = Object.keys(updatedFieldValidity).every((keys) => {
      return !updatedFieldValidity[keys]
    });
    return isFormValid;
  };


  function next(next: any = "") {
    if (isEditMode && directorApiData.length > 0) {
      sendDataApi();
    } else {
      if (directorApiData.length > 0) {
        // setShowPage(next != "" ? next : "StatutoryDetails")
        // if (next != "") {
        //   switch (next) {
        //     case "CompanyOverview":
        //       setIconStatus({
        //         ...iconStatus,
        //         companyIcon: "selected",
        //       })
        //       break;
        //     case "Address":
        //       setIconStatus({
        //         ...iconStatus,
        //         companyIcon: "completed",
        //         addressIcon: "selected",
        //       })
        //       break;
        //     case "DirectorsInfo":
        //       setIconStatus({
        //         ...iconStatus,
        //         directorIcon: "selected",
        //         addressIcon: "completed"
        //       })
        //       break;
        //     case "StatutoryDetails":
        //       setIconStatus({
        //         ...iconStatus,
        //         statutoryIcon: "selected",
        //         directorIcon: "completed"
        //       })
        //       break;
        //     case "AdminDetails":
        //       setIconStatus({
        //         ...iconStatus,
        //         adminIcon: "selected",
        //         statutoryIcon: "completed"
        //       })
        //       break;

        //     default:
        //       break;
        //   }
        // } else {
        //   setIconStatus({
        //     ...iconStatus,
        //     directorIcon: "completed",
        //     statutoryIcon: "selected"
        //   })
        // }
        return;
      }
    }
  }

  useImperativeHandle(ref, () => {
    return {
      next: next
    }
  });

  function retriveData(value: any, index: number) {
    const found: any = dropDownState.find((privilegeObj: any) => {
      return privilegeObj.value === value.state;
    });
    fetchData(`state/cities/${found?.id}`, setDropDownCities, 'city', "cityId", "name");
    setCurrIndex(index)
    setCurrentFormDataEdit(true)
    setCurrentDirector(value.id)
    seDirectorInfoErrors({})
    setIsFormDirty(true);
    if (value) {
      setFormData(
        {
          ...value,
          dateOfBirth: value?.dateOfBirth,
          gender: value?.gender,
          email: value?.email,
          contactNumber: value?.contactNumber || "",
          secondaryContactNumber: value?.secondaryContactNumber || "",
          stakeHolderDoc: value?.stakeHolderDoc || stakeHolderDoc,
          ownership: value?.ownership || "",
          relation: value?.relation || "",
          maskedAadhaarNumber: value?.maskedAadhaarNumber || "",
          country: value?.country,
          street: value?.street || "",
          state: value?.state,
          city: value?.city,
          pincode: value?.pincode || "",
          address: value?.address || "",
          directorPan: value?.directorPan,
          DirectorName: value?.DirectorName,
          DirectorDin: value?.DirectorDin,
          DirectorDesignation: value?.DirectorDesignation,
          index: index
        }
      )
    } else {
      setFormData(
        {
          dateOfBirth: "",
          gender: "Select",
          email: "",
          contactNumber: "",
          secondaryContactNumber: "",
          stakeHolderDoc: stakeHolderDoc,
          ownership: "",
          relation: "",
          maskedAadhaarNumber: "",
          country: "",
          street: "",
          state: "",
          city: "",
          pincode: "",
          address: "",
          DirectorName: "",
          DirectorDin: "",
          DirectorDesignation: "",
          directorPan: "",
          index: 0
        }
      )
    }
  }

  useEffect(() => {
    if (Object.values(singleDirectorInfoPan).length !== 0) {
      setFormData({
        ...formData,
        ...singleDirectorInfoPan.panData,
        address: `${singleDirectorInfoPan.address?.line1 || ""} ${singleDirectorInfoPan.address?.line2 || ""} ${singleDirectorInfoPan.address?.street || ""} ${singleDirectorInfoPan.address?.state},${singleDirectorInfoPan.address?.city} ${singleDirectorInfoPan.address?.pincode}`
      })
    }
  }, [singleDirectorInfoPan])


  useEffect(() => {
    if (Object.values(singleDirectorAddressInfoPan).length !== 0) {
      setFormData({
        ...formData,
        address: `${singleDirectorAddressInfoPan?.line1 || ""} ${singleDirectorAddressInfoPan?.line2 || ""} ${singleDirectorAddressInfoPan?.street || ""} ${singleDirectorAddressInfoPan?.state},${singleDirectorAddressInfoPan?.city} ${singleDirectorAddressInfoPan?.pincode}`
      })
    }
  }, [singleDirectorAddressInfoPan])


  useEffect(() => {
    fetchDirectorInfoState()
    fetchDirectorInfoCities()
  }, [])

  const axiosPrivate = useAxiosPrivate();

  const fetchDirectorInfoState = async () => {
    try {
      const response = await axiosPrivate.get("state");
      if (response.status === 200) {
        const dropdownValues = response.data.states.map((item: any) => ({
          label: item.name,
          value: item.name,
          id: item.stateId,
        }));
        setDirectorInfoState([{ label: "Select", value: "Select", id: "", }, ...dropdownValues]);
      }
    } catch (error) {
    }
  };

  const fetchDirectorInfoCities = async () => {
    try {
      const response = await axiosPrivate.get("cities");
      if (response.status === 200) {
        const dropdownValues = response.data.cities.map((item: any) => ({
          label: item.name,
          value: item.name,
          id: item.citiesId,
        }));
        setDirectorInfoCities([{ label: "Select", value: "Select", id: "", }, ...dropdownValues]);
      }
    } catch (error) {
    }
  };
  const clearAleart = () => {
    const timer = setTimeout(() => {
      setShowAlertBox(false);
      setShowMessage("");
      clearTimeout(timer);
    }, 5000);
  }
  const DirectorInfoDelete = async () => {
    if (currentDeletVal.id) {
      try {
        const response = await axiosPrivate.patch(`/bank-admin/company/delete-director-info/${currentDeletVal.id}`);
        if (response.status === 200) {
          setShowDeleteModel(false);
          const newArray = [...directorApiData];
          newArray.splice(deleteIndex, 1);
          setDirectorApiData(newArray);
          setShowAlertBox(true)
          setShowType("success")
          setShowMessage(response.data.message)
          setFormData(
            {
              dateOfBirth: "",
              gender: "Select",
              email: "",
              contactNumber: "",
              secondaryContactNumber: "",
              stakeHolderDoc: stakeHolderDoc,
              ownership: "",
              relation: "",
              maskedAadhaarNumber: "",
              country: "",
              street: "",
              state: "",
              city: "",
              pincode: "",
              address: "",
              DirectorName: "",
              DirectorDin: "",
              DirectorDesignation: "",
              directorPan: "",
              index: 0
            }
          )
          setCurrentFormDataEdit(false)
          clearAleart()
        }
      } catch (error) {
      }
    } else if (currentDeletVal.index === 0) {
      const newArray = [...directorApiData];
      newArray.splice(deleteIndex, 1);
      setDirectorApiData(newArray)
      setShowDeleteModel(false)
      setShowAlertBox(true)
      setShowType("success")
      setShowMessage("The selected record(s) has been deleted")
      setFormData(
        {
          dateOfBirth: "",
          gender: "Select",
          email: "",
          contactNumber: "",
          secondaryContactNumber: "",
          stakeHolderDoc: stakeHolderDoc,
          ownership: "",
          relation: "",
          maskedAadhaarNumber: "",
          country: "",
          street: "",
          state: "",
          city: "",
          pincode: "",
          address: "",
          DirectorName: "",
          DirectorDin: "",
          DirectorDesignation: "",
          directorPan: "",
          index: 0
        }
      )
      setCurrentFormDataEdit(false)
      clearAleart()
    }
    else if (currentDeletVal) {
      const newArray = [...directorApiData];
      newArray.splice(deleteIndex, 1);
      setDirectorApiData(newArray)
      setShowDeleteModel(false)
      setShowAlertBox(true)
      setShowType("success")
      setShowMessage("The selected record(s) has been deleted")
      setFormData(
        {
          dateOfBirth: "",
          gender: "Select",
          email: "",
          contactNumber: "",
          secondaryContactNumber: "",
          stakeHolderDoc: stakeHolderDoc,
          ownership: "",
          relation: "",
          maskedAadhaarNumber: "",
          country: "",
          street: "",
          state: "",
          city: "",
          pincode: "",
          address: "",
          DirectorName: "",
          DirectorDin: "",
          DirectorDesignation: "",
          directorPan: "",
          index: 0
        }
      )
      setCurrentFormDataEdit(false)
      clearAleart()
    }
  };
  const handleCardNumberChange = (value: string) => {
    const number = value.replace(/\D/g, "");
    const formattedNumber = number
      .replace(/(\d{4})(?=\d)/g, "$1 ")
      .trim();
    setFormData((prevState: any) => ({
      ...prevState,
      maskedAadhaarNumber: formattedNumber,
    }));
  };
  function cancel() {
    setShowPage("Address")
    setIconStatus({
      ...iconStatus,
      addressIcon: "selected",
    })
  }
  return (
    <>
      <div className='form-wrapper'>
        <div className='form-area'>
          <div className='company-heading'>
            Directors Info
          </div>
          <div className='form-main-wrapper'>
            <div>
              <InputComponent
                height={"40px"}
                width={"300px"}
                margin={"0px 45px 30px 0px"}
                padding={"0px 0px 0px 10px"}
                border={directorInfoErrors.DirectorName || formDataError.DirectorName ? "1px solid red" : "1px solid #A9C3DC"}
                borderRadius={"0px"}
                backgroundColor={"white"}
                color={"black"}
                type={"text"}
                inputTitle={"Name"}
                placeHolder={"Enter Name"}
                maxLength={100}
                required={true}
                inputValue={formData && formData?.DirectorName}
                getUser={(value: any) => {
                  setFormData({ ...formData, DirectorName: value })
                  setIconStatus({ ...iconStatus, directorIcon: "selected" })
                  setIsFormDirty(true)
                  dispatch(resetFormModified(true));
                }}
              />
            </div>
            <div>
              <InputComponent
                height={"40px"}
                width={"300px"}
                margin={"0px 45px 30px 0px"}
                padding={"0px 0px 0px 10px"}
                border={directorInfoErrors.DirectorDin || formDataError.DirectorDin ? "1px solid red" : "1px solid #A9C3DC"}
                borderRadius={"0px"}
                backgroundColor={"white"}
                color={"black"}
                placeHolder={"Enter DIN"}
                type={"text"}
                inputTitle={"DIN"}
                maxLength={8}
                required={true}
                inputValue={formData && formData?.DirectorDin}
                getUser={(value: any) => {
                  const val = value.replace(/[^0-9]/g, "")
                  setFormData({ ...formData, DirectorDin: val })
                  setIconStatus({ ...iconStatus, directorIcon: "selected" })
                  setIsFormDirty(true)
                  dispatch(resetFormModified(true));
                }}
              />
            </div>
            <div>
              <InputComponent
                height={"40px"}
                width={"300px"}
                margin={"0px 45px 30px 0px"}
                padding={"0px 0px 0px 10px"}
                border={directorInfoErrors.DirectorDesignation || formDataError.DirectorDesignation ? "1px solid red" : "1px solid #A9C3DC"}
                borderRadius={"0px"}
                backgroundColor={"white"}
                color={"black"}
                type={"text"}
                placeHolder={"Enter Designation"}
                required={true}
                maxLength={100}
                inputTitle={"Designation"}
                inputValue={formData && formData?.DirectorDesignation}
                getUser={(value: any) => {
                  setFormData({ ...formData, DirectorDesignation: value })
                  setIconStatus({ ...iconStatus, directorIcon: "selected" })
                  setIsFormDirty(true)
                  dispatch(resetFormModified(true));
                }}
              />
            </div>
          </div>
          <div className='input-fetch-flex'>

            <div>
              <InputComponent
                height={"40px"}
                width={"250px"}
                padding={"0px 0px 0px 10px"}
                margin={"0px 0px 30px 0px"}
                border={directorInfoErrors.directorPan ? "1px solid red" : "1px solid #A9C3DC"}
                borderRadius={"0px"}
                backgroundColor={"white"}
                color={"black"}
                type={"text"}
                required={true}
                placeHolder={"Enter PAN"}
                inputTitle={"PAN"}
                maxLength={10}
                inputValue={formData && formData.directorPan}
                getUser={(val) => {
                  let value = val.toUpperCase()
                  setDirectorInfoPan(value)
                  setFormData({ ...formData, directorPan: value })
                  setIconStatus({ ...iconStatus, directorIcon: "selected" })
                  setIsFormDirty(true)
                  dispatch(resetFormModified(true));
                }}
              />
            </div>
            <div style={(RegExp(/[A-Z]{5}\d{4}[A-Z]{1}/).test(formData?.directorPan)) ? {} : { pointerEvents: "none", opacity: 0.3 }}>
              <IconButton
                iconName={"Fetch"}
                height={"40px"}
                width={"100px"}
                fontSize={""}
                color={""}
                border={""}
                borderRadius={"0px"}
                backgroundColor={"#0055D4"}
                hover={RegExp(/[A-Z]{5}\d{4}[A-Z]{1}/).test(formData?.directorPan) ? true : false}
                disabled={RegExp(/[A-Z]{5}\d{4}[A-Z]{1}/).test(formData?.directorPan) ? true : false}
                margin={"4px 0px 0px 0px"}
                handleClick={() => {
                  handelDirectorPan()
                }}
              />
            </div>
          </div>
          <div className='form-main-wrapper'>
            <div style={{ marginRight: "45px", marginBottom: "30px" }}>
              <div className='title'>Date Of Birth</div>
              <input type='date' className='date-picker'
                value={formData?.dateOfBirth && moment(formData.dateOfBirth).format('YYYY-MM-DD')}
                max={todayDate}
                style={{ border: (directorInfoErrors.dateOfBirth) ? "1px solid red" : "" }}
                onChange={(e: any) => {
                  if (e.target.value.length > 8) {
                    const year = e.target.value.substring(0, 4);
                    if (parseInt(year, 10) > 2023) {
                      return;
                    }
                    if (year > 999) {
                      if (parseInt(year, 10) < 1900) {
                        return;
                      }
                    }
                  }
                  setFormData({ ...formData, dateOfBirth: e.target.value })
                  setIconStatus({ ...iconStatus, directorIcon: "selected" })
                  setIsFormDirty(true)
                  dispatch(resetFormModified(true));
                }} />

            </div>
            <div key={uuidv4()} style={{ marginRight: "45px", marginBottom: "30px" }}>
              <DropdownComponent
                width={"300px"}
                title={"Gender"}
                options={[{ label: 'Select', value: "Select" }, { label: 'Male', value: "Male" }, { label: 'Female', value: "Female" }]}
                defaultValue={[{ label: (formData?.gender === undefined || formData?.gender === "" || formData?.gender === null) ? "Select" : (formData.gender), value: formData && (formData.gender) }]}
                getData={(value) => {
                  setFormData({ ...formData, gender: value.value, })
                  setIconStatus({ ...iconStatus, directorIcon: "selected" })
                  setIsFormDirty(true)
                  dispatch(resetFormModified(true));
                }}
              />
            </div>
            <div>
              <InputComponent
                height={"40px"}
                width={"300px"}
                margin={"0px 45px 30px 0px"}
                padding={"0px 40px 0px 10px"}
                borderRadius={"0px"}
                backgroundColor={"white"}
                color={"black"}
                type={"text"}
                placeHolder={"Enter Email"}
                inputTitle={"Email"}
                maxLength={254}
                required={true}
                className={"address-input"}
                inputValue={formData && formData?.email}
                inputField={"gmail"}
                border={directorInfoErrors.email ? "1px solid red" : "1px solid #A9C3DC"}
                getUser={(val) => {
                  setFormData({ ...formData, email: val })
                  setIconStatus({ ...iconStatus, directorIcon: "selected" })
                  setIsFormDirty(true)
                  dispatch(resetFormModified(true));
                }}
              />
            </div>
          </div>
          <div className='form-main-wrapper'>
            <div>
              <InputComponent
                height={"40px"}
                width={"300px"}
                margin={"0px 45px 30px 0px"}
                padding={"0px 0px 0px 70px"}
                borderRadius={"0px"}
                backgroundColor={"white"}
                color={"black"}
                type={"mobile"}
                maxLength={10}
                placeHolder={"Enter Contact Number"}
                inputTitle={"Primary Contact Number"}
                required={true}
                className={"mobilenumber-input"}
                inputValue={formData && formData?.contactNumber}
                // disabled={admin_details[i].isActive !== "No"}
                border={directorInfoErrors.contactNumber ? "1px solid red" : "1px solid #A9C3DC"}
                getUser={(value: any) => {
                  const val = value.replace(/[^0-9]/g, "")
                  setFormData({ ...formData, contactNumber: val })
                  setIconStatus({ ...iconStatus, directorIcon: "selected" })
                  setIsFormDirty(true)
                  dispatch(resetFormModified(true));
                }}
              />
            </div>
            <div>
              <InputComponent
                height={"40px"}
                width={"300px"}
                margin={"0px 45px 30px 0px"}
                padding={"0px 0px 0px 70px"}
                borderRadius={"0px"}
                backgroundColor={"white"}
                color={"black"}
                type={"mobile"}
                maxLength={10}
                required={true}
                placeHolder={"Enter Contact Number"}
                inputTitle={"Secondary Contact Number"}
                className={"mobilenumber-input"}
                inputValue={formData && formData?.secondaryContactNumber}
                // disabled={admin_details[i].isActive !== "No"}
                border={directorInfoErrors.secondaryContactNumber ? "1px solid red" : "1px solid #A9C3DC"}
                getUser={(value: any) => {
                  const val = value.replace(/[^0-9]/g, "")
                  setFormData({ ...formData, secondaryContactNumber: val })
                  setIconStatus({ ...iconStatus, directorIcon: "selected" })
                  setIsFormDirty(true)
                  dispatch(resetFormModified(true));
                }}
              />
            </div>
            <div>
              <InputComponent
                height={"40px"}
                width={"300px"}
                margin={"0px 45px 30px 0px"}
                padding={"10px"}
                border={directorInfoErrors.maskedAadhaarNumber ? "1px solid red" : "1px solid #A9C3DC"}
                borderRadius={"0px"}
                backgroundColor={"white"}
                color={"black"}
                type={"text"}
                maxLength={14}
                inputTitle={"Aadhaar Number"}
                placeHolder={"Enter Aadhaar Number"}
                inputValue={formData && formData?.maskedAadhaarNumber}
                getUser={(value: any) => {
                  handleCardNumberChange(value)
                  setIconStatus({ ...iconStatus, directorIcon: "selected" })
                  setIsFormDirty(true)
                  dispatch(resetFormModified(true));
                }}
              />
            </div>
            <div style={{ opacity: "0.7", marginRight: "45px", marginBottom: "30px" }} className={directorInfoErrors.Country ? 'setErrorcompany' : "dropdown-margin dropdown-width"}>
              <DropdownComponent
                width={"300px"}
                title={"Country"}
                isDisabled={true}
                defaultValue={[{ label: "India", value: "India" }]}
                options={[{ label: "India", value: "India" }]}
                getData={(value: any) => {
                  setFormData({ ...formData, country: value.value })
                  setIconStatus({ ...iconStatus, directorIcon: "selected" })
                  setIsFormDirty(true)
                  dispatch(resetFormModified(true));
                }}
              />
            </div>
            <div>
              <InputComponent
                height={"40px"}
                width={"300px"}
                margin={"0px 45px 30px 0px"}
                padding={"10px"}
                border={directorInfoErrors.ownership ? "1px solid red" : "1px solid #A9C3DC"}
                borderRadius={"0px"}
                backgroundColor={"white"}
                color={"black"}
                type={"text"}
                placeHolder={"Enter Ownership %"}
                maxLength={100}
                required={true}
                inputTitle={"Ownership %"}
                inputValue={formData && formData.ownership}
                getUser={(value: any) => {
                  const numbersOnly = value
                    .replace(/^\D|(?<=\..*)\./g, "") // Remove non-digit characters and allow only one dot
                    .replace(/^(\d{0,3})(\.\d{0,2})?.*$/, "$1$2"); // Limit to 2 digits before and after the dot
                  setFormData({ ...formData, ownership: numbersOnly })
                  setIconStatus({ ...iconStatus, directorIcon: "selected" })
                  setIsFormDirty(true)
                  dispatch(resetFormModified(true));
                }}
              />
            </div>
            <div style={{ marginRight: "45px", width: "300px" }} key={uuidv4()} className={directorInfoErrors.relation ? 'setError-vendorType' : 'dropdown-vendorType'}>
              <DropdownComponent
                width={"300px"}
                title={"Relation"}
                required={true}
                // isDisabled={isEditMode && (vendorBankApprovalDetails?.status === "Approved") ? true : false}
                options={[{ label: 'Director', value: "Director" }, { label: 'Executive', value: "Executive" }]}
                defaultValue={formData.relation ? [{ label: formData.relation, value: formData.relation }] : [{ label: "Select", value: "Select" }]}
                getData={(value) => {
                  setFormData({ ...formData, relation: value.value })
                  setIconStatus({ ...iconStatus, directorIcon: "selected" })
                  setIsFormDirty(true)
                  dispatch(resetFormModified(true));

                  // // setSaveBtnDisabledStatus(false)
                  // // setVendorDetails({
                  // //   ...vendorDetails,
                  // //   stakeholderInfo: {
                  // //     ...vendorDetails.stakeholderInfo,
                  // //     relation: value,
                  // //   }
                  // // })
                  // // setIconStatus({ ...iconStatus, directorIcon: "selected" })
                  // setIsFormDirty(true)
                  // dispatch(resetFormModified(true));
                }}
              />
            </div>
          </div>
          <div className='form-main-wrapper'>
            <div key={uuidv4()} style={{ marginRight: "45px", marginBottom: "30px" }} className={directorInfoErrors.state ? 'setError-vendorType' : ''}>
              <DropdownComponent
                width={"300px"}
                title={"State"}
                required={true}
                defaultValue={formData.state ? [{ label: formData.state, value: formData.state }] : [{ label: "Select", value: "Select" }]}
                // defaultValue={[{ label: (formData?.state === undefined || formData?.state === null || formData?.state === "") ? "Select" : formData.state, value: formData && formData.state }]}
                options={[{ label: "Select", value: "Select", id: "" }, ...dropDownState]}
                getData={(value: any) => {
                  setFormData({ ...formData, state: value.value, city: undefined })
                  setIconStatus({ ...iconStatus, directorIcon: "selected" })
                  if (value.id == "") {
                    setDropDownCities([]);
                  } else {
                    setDropDownCities([]);
                    fetchData(`state/cities/${value.id}`, setDropDownCities, 'city', "cityId", "name");
                  }
                  setIsFormDirty(true)
                  dispatch(resetFormModified(true));
                }}
              />
            </div>
            <div key={uuidv4()} style={{ marginRight: "45px", marginBottom: "30px" }} className={directorInfoErrors.city ? 'setError-vendorType' : ''}>
              <DropdownComponent
                width={"300px"}
                title={"City"}
                required={true}
                defaultValue={formData.city ? [{ label: formData.city, value: formData.city }] : [{ label: "Select", value: "Select" }]}
                // defaultValue={[{ label: (formData?.city === undefined || formData?.city === null) ? "Select" : (formData.city), value: formData && (formData.city) }]}
                // options={dropDownCities}
                options={[{ label: "Select", value: "Select", id: "" }, ...dropDownCities]}
                getData={(value: any) => {
                  setFormData({ ...formData, city: value.value })
                  setIconStatus({ ...iconStatus, directorIcon: "selected" })
                  setIsFormDirty(true)
                  dispatch(resetFormModified(true));
                }}
              />
            </div>
            <div>
              <InputComponent
                height={"40px"}
                width={"300px"}
                margin={"0px 45px 30px 0px"}
                padding={"10px"}
                border={directorInfoErrors.street ? "1px solid red" : "1px solid #A9C3DC"}
                borderRadius={"0px"}
                backgroundColor={"white"}
                color={"black"}
                type={"text"}
                required={true}
                placeHolder={"Enter Street"}
                maxLength={100}
                inputTitle={"Street"}
                inputValue={formData && formData.street}
                getUser={(value: any) => {
                  const val = value.replace(/^\s+/, "");
                  setFormData({ ...formData, street: val })
                  setIconStatus({ ...iconStatus, directorIcon: "selected" })
                  setIsFormDirty(true)
                  dispatch(resetFormModified(true));
                }}
              />
            </div>
          </div>
          <div className='form-main-directors'>
            <div>
              <InputComponent
                height={"40px"}
                width={"300px"}
                margin={"0px 45px 30px 0px"}
                padding={"10px"}
                border={directorInfoErrors.pincode ? "1px solid red" : "1px solid #A9C3DC"}
                borderRadius={"0px"}
                backgroundColor={"white"}
                color={"black"}
                type={"text"}
                maxLength={6}
                required={true}
                inputTitle={"Pin Code"}
                inputValue={formData && formData.pincode}
                placeHolder={"Enter Pin Code"}
                getUser={(value: any) => {
                  const val = value.replace(/[^0-9]/g, "")
                  setFormData({ ...formData, pincode: val })
                  setIconStatus({ ...iconStatus, directorIcon: "selected" })
                  setIsFormDirty(true)
                  dispatch(resetFormModified(true));
                }}
              />
            </div>

            <div className='checkbox-flex' style={{ marginRight: "45px", marginBottom: "30px" }}>
              <label className='label-flex'>
                <input type="checkbox" checked={formData.isAuthSignatory === 'Yes'} onClick={(e: any) => {
                  const value = e.target.checked ? 'Yes' : 'No';
                  setFormData({ ...formData, isAuthSignatory: value })

                }} />
                <span className='signatory-checkbox'>Is Auth signatory?</span></label>
            </div>
          </div>
          <div>
            <div className='title'>Address</div>
            <textarea className='address-textarea' placeholder='Enter Address'
              maxLength={500} value={formData && formData?.address}
              onChange={(e: any) => {
                const val = e.target.value.replace(/^\s+/, "");
                setFormData({ ...formData, address: val })
                setIconStatus({ ...iconStatus, directorIcon: "selected" })
                setIsFormDirty(true)
                dispatch(resetFormModified(true));
              }}
            />
          </div>
          <div>
            <div className='company-heading'>
              Directors Documents
            </div>
            <div className='kyc-doc-header'>
              <div style={{ width: "40%", paddingLeft: "20px" }}>Document Type</div>
              <div style={{ width: "35%" }}>File Name</div>
              <div style={{ width: "25%" }}>Action</div>
            </div>
            {
              stakeHolderDoc?.map((e: any, i: any) => (

                <div className='kyc-doc-tbody'>
                  <div style={{ width: "40%", paddingLeft: "20px" }}>{e.documentType}<span style={{ color: "red" }}>*</span></div>
                  <div style={{ width: "35%" }}>{e.fileName}</div>
                  <div style={{ width: "25%", display: "flex" }}>
                    <div>
                      <FileUpload
                        type={"browse-file-only"}
                        width={'120px'}
                        getFileName={(file: any) => {
                          // setSaveBtnDisabledStatus(false)
                          handleFileSelect(file, i)
                        }}
                      />
                    </div>
                    <IconButton
                      iconName={"ShowEye"}
                      height={"40px"}
                      width={"40px"}
                      fontSize={"30px"}
                      margin={"0px 8px"}
                      color={"white"}
                      backgroundColor={e?.fileName == "" ? "grey" : "#0055D4"}
                      hover={e?.fileName == "" ? false : true}
                      cursor={e?.fileName == "" ? 'default' : "pointer"}
                      handleClick={(event: React.MouseEvent) => {
                        if (e?.fileName !== "") {
                          setShowDocumentModal(true)
                          setCurrentDoc(e?.file ? e.file[0] : e.fileLocation)
                        }
                      }}
                    />
                  </div>
                </div>
              )
              )

            }
          </div>
          <div style={{ marginTop: "20px" }}>
            <ButtonComponent
              title={currentFormDataEdit ? 'Update' : 'Add'}
              height={"50px"}
              width={"150px"}
              backgroundColor={"#0055D4"}
              color={"white"}
              margin={"0px 8px"}
              className={"button-component-hover common-btn"}
              handleClick={() => {

                handelAdd()
              }}
            />
          </div>

          <div style={{ marginRight: "5px" }}>
            <DirectorTable
              buttonDisabled={true}
              directorApiData={directorApiData}
              retriveData={retriveData}
              showmodal={(val: any, index: any) => {
                setShowDeleteModel(true);
                setDeleteIndex(index);
                setCurrentDeletVal(val);
              }}
            />
          </div>

          <div>
            <div className='footer-company'>
              {!isEditMode &&
                <ButtonComponent
                  title={"Cancel"}
                  height={"50px"}
                  width={"150px"}
                  margin={"0px 8px"}
                  backgroundColor={"#888888"}
                  color={"white"}
                  className={"button-component-hover cancel"}
                  handleClick={isFormModified ? () => { setShowChangesModal(true) } : returnGird}
                />
              }
              {
                isEditMode ?
                  <ButtonComponent
                    title={"Cancel"}
                    height={"50px"}
                    width={"150px"}
                    margin={"0px 8px"}
                    backgroundColor={"#888888"}
                    color={"white"}
                    className={"button-component-hover cancel"}
                    handleClick={isFormModified ? () => { setShowChangesModal(true) } : returnGird}
                  /> :
                  <ButtonComponent
                    title={"Previous"}
                    height={"50px"}
                    width={"150px"}
                    margin={"0px 8px"}
                    backgroundColor={"#888888"}
                    color={"white"}
                    className={"button-component-hover cancel"}
                    handleClick={cancel}
                  />
              }
              <ButtonComponent
                title={isEditMode ? "Update" : "Next"}
                height={"50px"}
                width={"150px"}
                backgroundColor={"#0055D4"}
                color={"white"}
                margin={"0px 8px"}
                disabled={directorApiData?.length == 0 || isFormDirty}
                className={directorApiData?.length == 0 || isFormDirty ? "button-component disabled" : "button-component-hover common-btn"}
                handleClick={
                  () => {
                    if (isEditMode) {
                      setShowAlertBox(true);
                      setShowType("success");
                      setShowMessage(`Company data updated successfully.`)
                      clearAlert('');
                    } else {
                      setShowPage("StatutoryDetails")
                      setIconStatus({
                        ...iconStatus,
                        statutoryIcon: "selected",
                        directorIcon: "completed"
                      })
                    }
                  }
                  // next
                }
              />
            </div>
          </div>
        </div>
      </div>
      {
        showAlertBox &&
        <div className='alert-warp'>
          <AlertBox type={showType} message={showMessage} />
        </div>

      }
      {
        showChangesModal &&
        <Changesmodal
          closeModal={() => setShowChangesModal(false)}
          handleClose={() => {
            setShowChangesModal(false);
          }}
          leavePage={() => returnGird()} />
      }
      {
        showDeleteModel &&
        <div >
          <DeleteModal
            getDelete={() => { DirectorInfoDelete() }}

            clearValue={(value) => {
              setShowDeleteModel(value);
              setCurrentDeletVal({})
            }}
            modelType={"grid-delete"}

          />
        </div>
      }
      {
        showDocumentModal &&
        <DocumentModal
          modalType={'Vendor Details'}
          closeDocument={(val: boolean) => { setShowDocumentModal(false) }}
          document={currentDoc}
          showDocumentModal={showDocumentModal}
        />
      }
    </>
  )
})
export default DirectorsInfo
