import React, { useEffect, useRef, useState } from 'react'
import IconButton from '../../../common-component/form-elements/icon-button'
import { CiSearch } from 'react-icons/ci'
import DocumentModal from '../../../common-component/modals/document-modal';
import ChartFilter from '../../../common-component/charts/chart-filter';
import { InvoiceDetailsReportFilter } from '../../../../config/reports/vendor-payable-reports/invoice-details-config';
import { axiosPrivate } from '../../../../middleware/axois-api';
import { formatAmount } from '../../../../helpers/amountRenderer';
interface props {
    searchBarValue: string
    filterOpen: any
    setFilterOpen: any
    downloadoption: any
    setDownloadoption: any
    conponentPDFInvoice: any
    conponentPDFInvoicefunction: any
    getVendorOverViewId: any
    getVendorOverViewIdFun: (value: any) => void;
    selectedVendorPayableFun: (value: any) => void;
    getVendorinvoiceIdFun: (value: any) => void;
    searchBarValueNameFun: (value: any) => void;

}
export default function IvoicesDetailsReport({ searchBarValue, filterOpen, downloadoption, searchBarValueNameFun, setDownloadoption, setFilterOpen, conponentPDFInvoice, conponentPDFInvoicefunction, getVendorOverViewIdFun, selectedVendorPayableFun, getVendorinvoiceIdFun, getVendorOverViewId }: props) {
    const [searchValue, setSearchValue] = useState<string>('');
    const [filteredData, setFilteredData] = useState<any[]>([]);
    const [showDocumentModal, setShowDocumentModal] = useState<boolean>(false);
    const [currentFile, SetCurrentFile] = useState("")
    const [showAlertBox, setShowAlertBox] = useState<boolean>(false)
    const [showMessage, setShowMessage] = useState<string>("")
    const [showType, setShowType] = useState<string>("warning")


    const [storeFilterOptions, setStoreFilterOptions] = useState<any>([]);
    const [filterValue, setFilterValues] = useState<any>([])
    const [orginalRowData, setOrginalRowData] = useState<any>([]);
    const [openDropdownIndex, setOpenDropdownIndex] = useState<number | null>(null);


    useEffect(() => {
        const filtered = orginalRowData.filter((item: any) => {
            const InvoiceDetails = item.InvoiceDetails;

            if (
                InvoiceDetails &&
                (InvoiceDetails["Vendor Name"]?.toLowerCase().includes(searchBarValue.toLowerCase()) ||
                    InvoiceDetails["Invoice Number"]?.toLowerCase().includes(searchBarValue.toLowerCase()))
            ) {
                // Return an object containing the matched field and its value
                return {
                    matchedField: InvoiceDetails["Vendor Name"]?.toLowerCase().includes(searchBarValue.toLowerCase())
                        ? "Vendor Name"
                        : "Invoice Number",
                    value: InvoiceDetails["Vendor Name"] || InvoiceDetails["Invoice Number"],
                };
            }

            return null;
        });
        setFilteredData(filtered);
    }, [searchBarValue])

    useEffect(() => {
        fetchData()
    }, [])



    useEffect(() => {
        handleApplyButtonForFilter(InvoiceDetailsReportFilter["InvoiceDetails"])
        setStoreFilterOptions(InvoiceDetailsReportFilter["InvoiceDetails"])
        setFilterValues(InvoiceDetailsReportFilter["InvoiceDetails"])
        fetchData()
    }, [])

    const convertToFilterValues = (filterData: any) => {
        const filterValues: any = {};
        filterData.forEach((filter: any) => {
            const tittle = (filter.cardTitle).replace(/\s/g, '')
            filterValues[tittle] = filter.selectedValue;
        });
        return filterValues;
    };

    const fetchData = async () => {
        try {
            const response = await axiosPrivate.post(`companyadmin/vendor-payable-reports/invoice-details/`, { vendorid: getVendorOverViewId });
            setOrginalRowData(response.data.finalData)
            setFilteredData(response.data.finalData)
            addDataForDropDownFilter(response.data.dropDownData)
        } catch (error) {
        }
    };

    const updateFilterDatas = async (index: number, newData: any) => {
        setStoreFilterOptions((prevFilterDatas: any) => {
            const updatedFilterDatas = [...prevFilterDatas];
            updatedFilterDatas[index] = newData;
            return updatedFilterDatas;
        });
        setFilterValues((prevFilterDatas: any) => {
            const updatedFilterDatas = [...prevFilterDatas];
            updatedFilterDatas[index] = newData;
            return updatedFilterDatas;
        });
    }


    function addDataForDropDownFilter(res: any) {
        try {
            if (Object.keys(res).length > 0) {
                const updatedVendorName: any = [
                    { value: "All", label: "All" },
                    ...res['Vendor Name'].map((elem: any) => ({ value: elem, label: elem }))
                ];
                const updatedVendorCode: any = [
                    { value: "All", label: "All" },
                    ...res["Vendor Code"].map((elem: any) => ({ value: elem, label: elem }))
                ];
                const updatedInvoiceNumber: any = [
                    { value: "All", label: "All" },
                    ...res['Invoice Number'].map((elem: any) => ({ value: elem, label: elem }))
                ];
                const updatedInvoiceDate: any = [
                    { value: "All", label: "All" },
                    ...res['Invoice Date'].map((elem: any) => ({ value: elem, label: elem }))
                ];
                const updatedDueDate: any = [
                    { value: "All", label: "All" },
                    ...res['Due Date'].map((elem: any) => ({ value: elem, label: elem }))
                ];
                const updatedExpenceType: any = [
                    { value: "All", label: "All" },
                    ...res['Expence Type'].map((elem: any) => ({ value: elem, label: elem }))
                ];
                const updatedOverdueDays: any = [
                    { value: "All", label: "All" },
                    ...res['Overdue Days'].map((elem: any) => ({ value: elem, label: elem }))
                ];
                const updatedCreatedAt: any = [
                    { value: "All", label: "All" },
                    ...res["Created At"].map((elem: any) => ({ value: elem, label: elem }))
                ];
                const updatedChecker: any = [
                    { value: "All", label: "All" },
                    ...res['Checker'].map((elem: any) => ({ value: elem, label: elem }))
                ];
                const updatedRecommender: any = [
                    { value: "All", label: "All" },
                    ...res['Recommender'].map((elem: any) => ({ value: elem, label: elem }))
                ];
                const updatedApprover: any = [
                    { value: "All", label: "All" },
                    ...res['Approver'].map((elem: any) => ({ value: elem, label: elem }))
                ];
                const updatedPayment: any = [
                    { value: "All", label: "All" },
                    ...res['Payment'].map((elem: any) => ({ value: elem, label: elem }))
                ];
                const updatedGSTIN: any = [
                    { value: "All", label: "All" },
                    ...res['GSTIN'].map((elem: any) => ({ value: elem, label: elem }))
                ];
                const updatedAccountNumber: any = [
                    { value: "All", label: "All" },
                    ...res['Account Number'].map((elem: any) => ({ value: elem, label: elem }))
                ];

                const updatedIFSCCode: any = [
                    { value: "All", label: "All" },
                    ...res['IFSC Code'].map((elem: any) => ({ value: elem, label: elem }))
                ];

                setStoreFilterOptions((prevOptions: any) => {
                    return prevOptions.map((option: any) => {
                        switch (option.cardTitle) {
                            case "Vendor Name":
                                return { ...option, options: updatedVendorName };
                            case "Vendor Code":
                                return { ...option, options: updatedVendorCode };
                            case "Invoice Number":
                                return { ...option, options: updatedInvoiceNumber };
                            case "Invoice Date":
                                return { ...option, options: updatedInvoiceDate };
                            case "Due Date":
                                return { ...option, options: updatedDueDate };
                            case "Expence Type":
                                return { ...option, options: updatedExpenceType };
                            case "Overdue Days":
                                return { ...option, options: updatedOverdueDays };
                            case "Created At":
                                return { ...option, options: updatedCreatedAt };
                            case "Checker":
                                return { ...option, options: updatedChecker };
                            case "Recommender":
                                return { ...option, options: updatedRecommender };
                            case "Approver":
                                return { ...option, options: updatedApprover };
                            case "Payment":
                                return { ...option, options: updatedPayment };
                            case "GSTIN":
                                return { ...option, options: updatedGSTIN };
                            case "Account Number":
                                return { ...option, options: updatedAccountNumber };
                            case "IFSC Code":
                                return { ...option, options: updatedIFSCCode };

                            default:
                                return option;
                        }
                    });
                });
                setFilterValues((prevOptions: any) => {
                    return prevOptions.map((option: any) => {
                        switch (option.cardTitle) {
                            case "Vendor Name":
                                return { ...option, options: updatedVendorName };
                            case "Vendor Code":
                                return { ...option, options: updatedVendorCode };
                            case "Invoice Number":
                                return { ...option, options: updatedInvoiceNumber };
                            case "Invoice Date":
                                return { ...option, options: updatedInvoiceDate };
                            case "Due Date":
                                return { ...option, options: updatedDueDate };
                            case "Expence Type":
                                return { ...option, options: updatedExpenceType };
                            case "Overdue Days":
                                return { ...option, options: updatedOverdueDays };
                            case "Created At":
                                return { ...option, options: updatedCreatedAt };
                            case "Checker":
                                return { ...option, options: updatedChecker };
                            case "Recommender":
                                return { ...option, options: updatedRecommender };
                            case "Approver":
                                return { ...option, options: updatedApprover };
                            case "Payment":
                                return { ...option, options: updatedPayment };
                            case "GSTIN":
                                return { ...option, options: updatedGSTIN };
                            case "Account Number":
                                return { ...option, options: updatedAccountNumber };
                            case "IFSC Code":
                                return { ...option, options: updatedIFSCCode };

                            default:
                                return option;
                        }
                    });
                });

            }
        } catch (error) {
            console.log("error", error)
        }
    }

    async function handleApplyButtonForFilter(data: any) {
        const convertValue = convertToFilterValues(filterValue)
        if (
            convertValue['VendorName'] == "All" &&
            convertValue['VendorCode'] == "All" &&
            convertValue['InvoiceNumber'] == "All" &&
            convertValue['InvoiceDate'] == "All" &&
            convertValue['DueDate'] == "All" &&
            convertValue['ExpenceType'] == "All" &&
            convertValue['OverdueDays'] == "All" &&
            convertValue['CreatedAt'] == "All" &&
            convertValue['Checker'] == "All" &&
            convertValue['Recommender'] == "All" &&
            convertValue['Approver'] == "All" &&
            convertValue['Payment'] == "All" &&
            convertValue['GSTIN'] == "All" &&
            convertValue['AccountNumber'] == "All" &&
            convertValue['IFSCCode'] == "All"

        ) {
            setFilteredData(orginalRowData)
        } else {

            const filteredData: any = orginalRowData && orginalRowData.filter((ele: any) => {
                const VendorNameCondtion = convertValue['VendorName'] === 'All' || ele.InvoiceDetails['Vendor Name'] === convertValue['VendorName'];
                const VendorCodeCondtion = convertValue['VendorCode'] === 'All' || ele.InvoiceDetails["Vendor Code"] === convertValue['VendorCode'];
                const InvoiceNumberCondtion = convertValue['InvoiceNumber'] === 'All' || ele.InvoiceDetails['Invoice Number'] === convertValue['InvoiceNumber'];
                const InvoiceDatesCondtion = convertValue['InvoiceDate'] === 'All' || ele.InvoiceDetails['Invoice Date'] === convertValue['InvoiceDate'];
                const DueDateCondtion = convertValue['DueDate'] === 'All' || ele.InvoiceDetails['Due Date'] == convertValue['DueDate'];
                const ExpenceTypeCondtion = convertValue['ExpenceType'] === 'All' || ele.InvoiceDetails['Expence Type'] === convertValue['ExpenceType'];
                const OverdueDaysCondtion = convertValue['OverdueDays'] === 'All' || ele.InvoiceDetails['Overdue Days'] === convertValue['OverdueDays'];
                const CreatedAtCondtion = convertValue['CreatedAt'] === 'All' || ele.InvoiceDetails['Created At'] === convertValue['CreatedAt'];
                const CheckerCondtion = convertValue['Checker'] === 'All' || ele.InvoiceStatus['Checker'] === convertValue['Checker'];
                const RecommenderCondtion = convertValue['Recommender'] === 'All' || ele.InvoiceStatus['Recommender'] === convertValue['Recommender'];
                const ApproverCondtion = convertValue['Approver'] === 'All' || ele.InvoiceStatus['Approver'] === convertValue['Approver']
                const GSTINCondtion = convertValue['GSTIN'] === 'All' || ele.VendorAccountDetails['GSTIN'] === convertValue['GSTIN'];
                const AccountNumberCondtion = convertValue['AccountNumber'] === 'All' || ele.VendorAccountDetails['Account Number'] === convertValue['AccountNumber'];
                const IFSCCodeCondtion = convertValue['IFSCCode'] === 'All' || ele.VendorAccountDetails['IFSC Code'] === convertValue['IFSCCode'];

                const PaymentCondtion = convertValue['Payment'] === 'All' || ele.InvoiceStatus['Payment'] === convertValue['Payment'];

                return VendorNameCondtion
                    && VendorCodeCondtion
                    && InvoiceNumberCondtion
                    && InvoiceDatesCondtion
                    && DueDateCondtion
                    && ExpenceTypeCondtion
                    && OverdueDaysCondtion
                    && CreatedAtCondtion
                    && CheckerCondtion
                    && RecommenderCondtion
                    && ApproverCondtion
                    && GSTINCondtion
                    && AccountNumberCondtion
                    && IFSCCodeCondtion
                    && PaymentCondtion
            });
            setFilteredData(filteredData);
        }
        // setFilterOpen(false)

    }





    const handleOpenClose = (index: number) => {
        if (openDropdownIndex === index) {
            setOpenDropdownIndex(null); // Close the dropdown if it's already open
        } else {
            setOpenDropdownIndex(index); // Open the dropdown
        }
    };



    return (
        <>
            <div>
                <div className='report-table-component' style={{ marginTop: "20px", overflowX: "auto" }} >
                    <table ref={conponentPDFInvoice} style={{ width: "100%" }}>
                        <thead>
                            {/* <tr> */}

                            <th className='report-heading' style={{ paddingLeft: "20px", minWidth: "100px" }}>View </th>
                            <th className='report-heading' style={{ paddingLeft: "10px", width: "320px" }}>Invoice Details</th>
                            <th className='report-heading' style={{ paddingLeft: "10px", width: "300px" }}>Invoice Amount Details</th>
                            <th className='report-heading' style={{ paddingLeft: "10px", width: "350px" }}>Invoice Status</th>
                            <th className='report-heading' style={{ paddingLeft: "10px", width: "350px" }}>Vendor Account Details</th>

                            {/* </tr> */}
                        </thead>
                        <tbody>
                            {

                                filteredData.length === 0 ?
                                    <tr className='style-grid-differ'>
                                        <td></td>
                                        <td></td>
                                        <td style={{ textAlign: "center" }}>No records to show</td>
                                        <td></td>
                                        <td></td>

                                    </tr> :
                                    filteredData.map((data: any, index: number) => (
                                        <tr className='main-body-header' key={index} onDoubleClick={(event) => {
                                            searchBarValueNameFun(data.InvoiceDetails["Invoice Number"])
                                            getVendorinvoiceIdFun(data.invoice_payable_id)
                                            getVendorOverViewIdFun("")
                                            selectedVendorPayableFun("Invoice Settlements") 
                                        }} style={{ cursor: "pointer" }} >
                                            <td className='report-details' style={{ textAlign: "center", pointerEvents: "none" }}>
                                                <div className='view-invoice-eyeicon' >
                                                    <IconButton
                                                        iconName={"ShowEye"}
                                                        height={"35px"}
                                                        width={"30px"}
                                                        fontSize={""}
                                                        className={"icons"}
                                                        cursor={"pointer"}
                                                        // disabled={true}
                                                        color={"red"}
                                                        backgroundColor={""}
                                                        hover={false}
                                                        handleClick={() => {
                                                            // setElectricityHistory(true)
                                                            setShowDocumentModal(!showDocumentModal);
                                                            SetCurrentFile(data.invoiceImage)
                                                        }} />
                                                </div>

                                            </td>
                                            <td className='report-details'>
                                                {Object.entries(data.InvoiceDetails).map(([key, value]: any) => (
                                                    <p style={{ width: "370px", whiteSpace: "normal" }}>
                                                        <i>{key}</i> - {value}
                                                    </p>
                                                ))}
                                            </td>
                                            <td className='report-details' >
                                                {Object.entries(data.InvoiceAmountDetails).map(([key, value]: any) => (
                                                    <div style={{ display: "flex", width: "300px" }}>
                                                        <p style={{ width: "150px" }}  >
                                                            <i>{key}</i>
                                                        </p>
                                                        <p>:</p>
                                                        <p style={{ textAlign: "right", width: "130px" }}> {formatAmount(value)}</p>
                                                    </div>
                                                ))}
                                            </td>
                                            <td className='report-details' >
                                                {Object.entries(data.InvoiceStatus).map(([key, value]: any) => (
                                                    <p key={key} style={{ whiteSpace: "normal", width: "280px" }}>
                                                        <i>{key}</i> - {value}
                                                    </p>
                                                ))}
                                            </td>
                                            <td className='report-details' >
                                                {Object.entries(data.VendorAccountDetails).map(([key, value]: any) => (
                                                    <p key={key} style={{ whiteSpace: "normal", width: "330px" }}>
                                                        {/* <> */}
                                                        <i>{key}</i> - {value}
                                                        {/* </> */}
                                                    </p>
                                                ))}
                                            </td>
                                        </tr>
                                    ))}
                        </tbody>

                    </table>
                </div>
            </div>
            {
                showDocumentModal &&
                <DocumentModal
                    closeDocument={(val: boolean) => { setShowDocumentModal(val); }}
                    document={currentFile}
                    showDocumentModal={showDocumentModal}
                />
            }
            {
                filterOpen &&
                <div className="right-side-filter-component">
                    <ChartFilter
                        datas={storeFilterOptions}
                        updateFilterDatas={updateFilterDatas}
                        handleOpenClose={handleOpenClose}
                        openDropdownIndex={openDropdownIndex}
                        handleApplyButtonForFilter={() => { handleApplyButtonForFilter("") }}
                        setOpenRightSideFilter={() => { setFilterOpen(false) }}
                    />
                </div>
            }
        </>
    )
}

export const SampleDatasInvoice = [
    {
        "ViewInvoice": {


        },
        "InvoiceDetails": {
            "Name": "FARMER PRODUCER COMPANY LIMITED FARMER PRODUCER COMPANY LIMITED",
            "Vendor Code": "1",
            "Invoice Number ": "INV-001/23-24",
            "Invoice Date ": "25/10/2023",
            "Due Date ": "31/01/2024",
            "Overdue Days": "21 days",
            "Created At": "25/12/2023",

        },

        "InvoiceAmountDetails": {
            "Base Amount": "1,000.00",
            "Discount": "0.00",
            "Sub Total": "1,000.00",
            "GST": "1,080.00",
            "Invoices Amount": "1,080.00",
            "Payment Amount": "1,080.00",
            "Amount paid": "1,080.00",
            "Outstanding": "1,080.00",


        },
        "InvoiceStatus": {
            "Checker": "Approved by ARUN",
            "Recommender": "Approved by ARUN",
            "Approver": "Approved by sanjay",
            "Payment": "Successed",

        },
        "VendorAccountDetails": {
            "GSTIN ": "33AAACH7413R1Z2",
            "Bank": "Axis",
            "Account Number": "30000110007457",
            "IFSC Code": "UCBA0003000",
            "UPI": "Upi@123",

        },

    },
    {
        "ViewInvoice": {

        },
        "InvoiceDetails": {
            "Name": "FARMER PRODUCER COMPANY LIMITED FARMER PRODUCER COMPANY LIMITED",
            "Vendor Code": "1",
            "Invoice Number ": "INV-001/23-24",
            "Invoice Date ": "25/10/2023",
            "Due Date ": "31/01/2024",
            "Overdue Days": "21 days",
            "Created At": "25/12/2023",

        },

        "InvoiceAmountDetails": {
            "Base Amount": "1,000.00",
            "Discount": "0.00",
            "Sub Total": "1,000.00",
            "GST": "1,080.00",
            "Invoices Amount": "1,080.00",
            "Payment Amount": "1,080.00",
            "Amount paid": "1,080.00",
            "Outstanding": "1,080.00",


        },
        "InvoiceStatus": {
            "Checker": "Approved by ARUN",
            "Recommender": "Approved by ARUN",
            "Approver": "Approved by sanjay",
            "Payment": "Successed",

        },
        "VendorAccountDetails": {
            "GSTIN ": "33AAACH7413R1Z2",
            "Bank": "Axis",
            "Account Number": "30000110007457",
            "IFSC Code": "UCBA0003000",
            "UPI": "Upi@123",

        },

    },
    {
        "ViewInvoice": {


        },
        "InvoiceDetails": {
            "Name": "FARMER PRODUCER COMPANY LIMITED FARMER PRODUCER COMPANY LIMITED",
            "Vendor Code": "1",
            "Invoice Number ": "INV-001/23-24",
            "Invoice Date ": "25/10/2023",
            "Due Date ": "31/01/2024",
            "Overdue Days": "21 days",
            "Created At": "25/12/2023",

        },

        "InvoiceAmountDetails": {
            "Base Amount": "1,000.00",
            "Discount": "0.00",
            "Sub Total": "1,000.00",
            "GST": "1,080.00",
            "Invoices Amount": "1,080.00",
            "Payment Amount": "1,080.00",
            "Amount paid": "1,080.00",
            "Outstanding": "1,080.00",


        },
        "InvoiceStatus": {
            "Checker": "Approved by ARUN",
            "Recommender": "Approved by ARUN",
            "Approver": "Approved by sanjay",
            "Payment": "Successed",

        },
        "VendorAccountDetails": {
            "GSTIN ": "33AAACH7413R1Z2",
            "Bank": "Axis",
            "Account Number": "30000110007457",
            "IFSC Code": "UCBA0003000",
            "UPI": "Upi@123",

        },

    },





]