import React, { useState, useEffect } from 'react'
import ButtonComponent from '../../common-component/form-elements/button-component';
import InputComponent from '../../common-component/form-elements/input-component';
import DropdownComponent from '../../common-component/form-elements/dropdown-component';
import ViewTable from '../../common-component/grids-and-tables/view-table';
import PrivilageTable from './privilage-table';
import { BsPlusLg } from 'react-icons/bs';
import AddDepartment from '../../common-component/modals/department-add-modal';
import useAxiosPrivate from "../../../services/hooks/useaxios-private";
import { useDispatch } from "react-redux";
import { resetFormModified } from "../../../redux/action";
import { v4 as uuidv4 } from 'uuid';
import Changesmodal from '../../common-component/modals/changes-modal';
import AlertBox from '../../common-component/alert-box';

// import "../../../styles/product-admin/users/privilege.scss"

interface privilegeProp {
    componentNavigatorFun: (val: string) => void;
    userNavigate: string;
    isEditMode: boolean;
    productAdminUserData: any
    groupValues: any
}

const Privilege: React.FC<privilegeProp> = ({
    componentNavigatorFun,
    userNavigate,
    isEditMode,
    groupValues,
    productAdminUserData
}) => {

    // for ui 
    const [showType, setShowType] = useState("warning") // error message showType
    const [showMessage, setShowMessage] = useState<string>("") // error message showMessage
    const [showAlertBox, setShowAlertBox] = useState(false) // error message ShowAlertBox
    const [showResetModel, setShowResetModel] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [showChangesModal, setShowChangesModal] = useState(false);
    const [groupEdit, setGroupEdit] = useState(false);
    const [groupEditIndex, setGroupEditIndex] = useState<number>();

    // for functionality 
    const [navToDepart, setnavToDepart] = useState<boolean>(false)
    const [productData, setProductData] = useState<any>({});
    const [tableDatas, setTableDatas] = useState<any[]>([]);
    const [department, setDepartment] = useState<any[]>([]);
    const [privilegeDD, setPrivilegeDD] = useState<any[]>([]);
    const [groupGrid, setgroupGrid] = useState<any[]>([]);
    const [privilegeData, setPrivilegeData] = useState({
        organisationId: productAdminUserData.organization,
        companyId: productAdminUserData.company,
        department: "",
        departmentName: "",
        groupName: "",
        privilege: "",
        privilegeName: "",
        isCustomGroup: "",
        privilegeData: {},
    });
    const [privilegeError, setprivilegeError] = useState({
        departmentName: false,
        groupName: false,
        privilegeName: false,
    });

    const [localModified, setlocalModified] = useState<boolean>(false)
    const dispatch = useDispatch();
    const axiosPrivate = useAxiosPrivate();

    useEffect(() => {
        fetchData(`product-admin/user-department/${productAdminUserData.organization}/${productAdminUserData.company}`, setDepartment, "departmentDetails");
        fetchDataPrivilege(`productAdmin/user/privilege`, setPrivilegeDD, "privilegeDatas");
        getFeatureAccess();
        groupDataGet(productAdminUserData.organization, productAdminUserData.company);
    }, []);

    const groupDataGet = async (organizationId: any, companyId: any) => {
        try {
            // const res = await axiosPrivate.get(`user/groups`)
            const res = await axiosPrivate.post(`product-admin/get-all-groups`, {
                organizationId: organizationId,
                companyId: companyId,
            });
            if (res.status === 200) {
                setgroupGrid(res.data.groups)
            }
        }
        catch (error: any) {
        }
    }

    useEffect(() => {
        setPrivilegeData((prevState) => ({
            ...prevState,
            privilegeData: productData,
        }));
    }, [productData]);

    const getFeatureAccess = async () => {
        await axiosPrivate.get(`feature-list`)
            .then(response => {
                if (response.status === 200) {
                    loadPrivilageData(response.data.featureList)
                }
            })
            .catch(error => {
            });
    }

    function loadPrivilageData(res: any) {
        const transformedData = (res).reduce((acc: any, item: any) => {
            const featureCategory = item.module.featureCategory;
            if (!acc[featureCategory]) {
                acc[featureCategory] = {
                    module: { featureCategory, accessCategory: item.features[0].accessCategory },
                    features: [],
                };
            }
            acc[featureCategory].features.push({
                accessCategory: item.features[0].accessCategory,
                featureListId: item.features[0].featureListId,
                features: item.features[0].features,
            });
            return acc;
        }, {});
        setPrivilegeData((prevState) => ({
            ...prevState,
            privilegeData: transformedData
        }));
        setProductData && setProductData(transformedData);
    }

    const clearAleart = () => {
        const timer = setTimeout(() => {
            setShowAlertBox(false);
            setShowMessage("");
            clearTimeout(timer);
        }, 5000);
    }

    const fetchData = async (url: string, setData: (data: any[]) => void, nestedProperty: string) => {
        try {
            const response = await axiosPrivate.get(url);
            if (response.status === 200) {
                const dropdownValues = response.data[nestedProperty].map((item: any) => ({
                    label: item.departmentName,
                    value: item.departmentName,
                    id: item.departmentId,
                }));
                const sortedData = [...dropdownValues].sort((a, b) => a.label.localeCompare(b.label));
                setData(sortedData);
            }
        } catch (error) {
        }
    };

    const fetchDataPrivilege = async (url: string, setData: (data: any[]) => void, nestedProperty: string) => {
        try {
            const response = await axiosPrivate.get(url);
            if (response.status === 200) {
                const dropdownValues = response.data[nestedProperty].map((item: any) => ({
                    label: item.privilegeName,
                    value: item.privilegeName,
                    id: item.privilegeId,
                }));
                setData(dropdownValues);
            }
        } catch (error) {
        }
    };

    const postPrivilageID = async (id: any) => {
        try {
            const response = await axiosPrivate.post(`productAdmin/user/privilege/${id}`);
            if (response.status === 200) {
                loadPrivilageData(response.data.data)
            }
        } catch (error) {
        }
    }

    const privilegeDataSave = async () => {
        setIsLoading(true);
        if (privilegeData.departmentName !== "" && privilegeData.groupName !== "" && new RegExp(/^[a-zA-Z][a-zA-Z ]*$/).test(privilegeData.groupName) && privilegeData.privilege !== "") {
            try {
                const res = groupEdit ? await axiosPrivate.patch(`user/groups/update/${groupEditIndex}`, privilegeData) : await axiosPrivate.post(`user/groups`, privilegeData)
                if (res.status === 200) {
                    setIsLoading(false);
                    setShowAlertBox(true);
                    setShowType("success");
                    setShowMessage(res.data.message);
                    dispatch(resetFormModified(false));
                    clearAleart();
                    setGroupEdit(false);
                    setGroupEditIndex(0);
                    getFeatureAccess();
                    setlocalModified(false);
                    setprivilegeError({
                        departmentName: false,
                        groupName: false,
                        privilegeName: false,
                    });
                    groupDataGet(productAdminUserData.organization, productAdminUserData.company);
                    setPrivilegeData({
                        organisationId: productAdminUserData.organization,
                        companyId: productAdminUserData.company,
                        department: "",
                        departmentName: "",
                        groupName: "",
                        privilege: "",
                        privilegeName: "",
                        isCustomGroup: "",
                        privilegeData: {},
                    })
                }
            }
            catch (error: any) {
                setIsLoading(false)
                if (error.status === 500 || error.status === 404) {

                    setShowAlertBox(true);
                    setShowType("danger")
                    setShowMessage(error.response.data.message)
                    clearAleart();
                } else if (error.code === "ERR_NETWORK") {
                    setShowAlertBox(true);
                    setShowType("danger")
                    setShowMessage(error.response.data.message)
                    clearAleart();
                } else {
                    setShowAlertBox(true);
                    setShowType("danger")
                    setShowMessage(error.response.data.message)
                    clearAleart();
                }
            }
        } else {
            setprivilegeError({
                departmentName: privilegeData.departmentName === "",
                groupName: privilegeData.groupName === "" && new RegExp(/^[a-zA-Z][a-zA-Z ]*$/).test(privilegeData.groupName) ? false : true,
                privilegeName: privilegeData.privilege === "",
            });
            setShowAlertBox(true);
            setShowType("danger")
            setShowMessage((privilegeData.groupName === ""
                || privilegeData.privilege === ""
                || privilegeData.departmentName === "") ? "Mandatory field(s) should not be left blank." :
                "Some of the field(s) are not in required format.")
            clearAleart();
        }
    }

    const privilegeEdit = async (editindex: any) => {
        try {
            const res = await axiosPrivate.get(`user/groups/getSelected-Groups/${groupGrid[editindex].groupId}`)
            if (res.status === 200) {
                setPrivilegeData({
                    organisationId: productAdminUserData.organization,
                    companyId: productAdminUserData.company,
                    department: res.data.group.department,
                    departmentName: res.data.group.departmentName,
                    groupName: res.data.group.groupName,
                    privilege: res.data.group.privilege,
                    privilegeName: res.data.group.privilegeName,
                    isCustomGroup: res.data.group.isCustomGroup,
                    privilegeData: res.data.group.privilegeData,
                });
                loadPrivilageData(res.data.group.privilegeData);
                setGroupEdit(true);
                setGroupEditIndex(res.data.group.groupId);
            }
        }
        catch (error: any) {
        }
    }

    const privilegeDelete = async (deletIndex: any) => {
        setIsLoading(true);
        try {
            const res = await axiosPrivate.delete(`user/groups/delete/${groupGrid[deletIndex].groupId}`)
            if (res.status === 200) {
                groupDataGet(productAdminUserData.organization, productAdminUserData.company);
                setIsLoading(false);
                setShowAlertBox(true);
                setShowType("success");
                setShowMessage(res.data.message);
                clearAleart();
            }
        }
        catch (error: any) {
            setIsLoading(false);
            setShowAlertBox(true);
            setShowType("danger");
            // setShowMessage(error.responce.data.error); 
            setShowMessage(error.response.data.message);
            clearAleart();
        }
    }

    const privilegeCancel = () => {
        componentNavigatorFun("form-page")
        setlocalModified(false);
        setShowChangesModal(false);
    }

    return (
        <>
            <div className='scroll-controler'>
                <div className='form-top-main-user'>
                    <div className='form-input'>

                        <div className='fetch-group' >
                            <div
                                key={uuidv4()}
                                className={privilegeError.departmentName ? 'setError-with-plus' : 'dropdown-user-with-plus'}
                                style={{ position: "relative", zIndex: 5 }}
                            >
                                <DropdownComponent
                                    options={department}
                                    title={"Department"}
                                    required={true}
                                    getData={(val) => {
                                        setPrivilegeData((prevState) => ({
                                            ...prevState,
                                            department: val.id,
                                            departmentName: val.value,
                                        }));
                                        dispatch(resetFormModified(true));
                                        setlocalModified(true)
                                    }}
                                    defaultValue={[{ label: privilegeData.departmentName === "" ? "Select" : privilegeData.departmentName, value: privilegeData.departmentName }]}
                                />
                            </div>
                            <div className='with-plus' onClick={() => {
                                setnavToDepart(true)
                            }}>
                                <BsPlusLg color='#004E9C' height={"14px"} />
                            </div>
                        </div>
                        <InputComponent
                            height={"40px"}
                            width={"350px"}
                            margin={"0px 60px 30px 0px"}
                            padding={"0px 0px 0px 10px"}
                            border={privilegeError.groupName ? "1px solid red" : "1px solid #A9C3DC"}
                            borderRadius={"0px"}
                            backgroundColor={"white"}
                            color={"black"}
                            type={"text"}
                            maxLength={100}
                            inputTitle={"Group Name"}
                            placeHolder={"Enter Group Name"}
                            required={true}
                            inputValue={privilegeData.groupName}
                            getUser={(value: any) => {
                                setPrivilegeData((prevState) => ({
                                    ...prevState,
                                    groupName: value,
                                }));
                                dispatch(resetFormModified(true));
                                setlocalModified(true)

                            }}
                        />

                        <div key={uuidv4()} className={privilegeError.privilegeName ? 'setError' : 'dropdown-user'} style={{ position: "relative", zIndex: 5, marginRight: "0px" }}>
                            <DropdownComponent
                                options={privilegeDD}
                                title={"Privilege"}
                                required={true}
                                getData={(val) => {
                                    setPrivilegeData((prevState) => ({
                                        ...prevState,
                                        privilege: val.id,
                                        privilegeName: val.value,
                                        isCustomGroup: val.value === "Custom" ? "true" : "false",
                                    }));
                                    dispatch(resetFormModified(true));
                                    postPrivilageID(val.id);
                                    setlocalModified(true)


                                }}
                                defaultValue={[{ label: privilegeData.privilegeName == "" ? "Select" : privilegeData.privilegeName, value: privilegeData.privilegeName }]}
                            />
                        </div>
                    </div>
                </div>
                <div className='user-ViewTable-wrapper'>
                    <ViewTable
                        tableDatas={tableDatas}
                        axisToChoose={privilegeData.privilegeName !== "Custom"}
                        rowDisabled={false}
                        setProductData={(value: any) => setProductData(value)}
                        productData={productData} />

                </div>
                <div>
                    <PrivilageTable
                        groupGrid={groupGrid}
                        privilegeEdit={privilegeEdit}
                        privilegeDelete={privilegeDelete}
                    />
                </div>
            </div>

            <div className='footer-user-pb'>
                <ButtonComponent
                    title={"Cancel"}
                    height={"50px"}
                    width={"150px"}
                    margin={"0px 8px"}
                    backgroundColor={"#888888"}
                    color={"white"}
                    className={"button-component-hover cancel"}
                    handleClick={localModified ? () => { setShowChangesModal(true) } : privilegeCancel}
                />
                <ButtonComponent
                    title={groupEdit ? "Update" : "Save"}
                    height={"50px"}
                    width={"150px"}
                    backgroundColor={"#0055D4"}
                    color={"white"}
                    margin={"0px 8px"}
                    className={"button-component-hover common-btn"}
                    handleClick={privilegeDataSave}
                />
            </div>
            {
                navToDepart &&
                <div>
                    <AddDepartment
                        productAdminUserData={productAdminUserData}
                        clearValue={(val) => {
                            setnavToDepart(val);
                        }}
                        getDepartment={() => {
                            fetchData(`product-admin/user-department/${productAdminUserData.organization}/${productAdminUserData.company}`, setDepartment, "departmentDetails");
                        }}
                        setDepartment={setDepartment}
                        department={department}
                    />
                </div>
            }
            {
                showChangesModal &&
                <Changesmodal
                    closeModal={() => setShowChangesModal(false)}
                    handleClose={() => {
                        setShowChangesModal(false);
                    }}
                    leavePage={() => privilegeCancel()} />
            }
            {
                showAlertBox &&
                <div className='alert-warp'>
                    <AlertBox type={showType} message={showMessage} />
                </div>
            }
        </>
    )
}

export default Privilege;

