import React, { useState, useEffect, useRef } from 'react'
import NavigateHeader from '../../../components/common-component/navigate-header'
import '../../../styles/onboarding/company/company-form.scss'
import CompanyOverview from './company-form-lists/company-overview'
import Address, { ChildRef } from './company-form-lists/address'
import AdminDetails from './company-form-lists/admin-details'
import DirectorsInfo from './company-form-lists/directors-info'
import StatutoryDetails from './company-form-lists/statutory-details'
import ApprovalOnboarding from './company-form-lists/approval'
import BankRelationshipManager from './company-form-lists/bank-relationship-manager'
// import PointOfContact from './company-form-lists/point-of-contact'
import SubscriptionValidity from './company-form-lists/subscription-validity'
import Privilege from './company-form-lists/privilege'
import ButtonComponent from '../../common-component/form-elements/button-component';
import { BsBuildingExclamation } from 'react-icons/bs';
import { BsPersonFillGear } from 'react-icons/bs';
import { BsFillPersonLinesFill } from 'react-icons/bs';
import { BsBank2 } from 'react-icons/bs';
import { BsFileEarmarkCheckFill } from 'react-icons/bs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapLocationDot } from '@fortawesome/free-solid-svg-icons';
//svg
import { ReactComponent as Notworking } from '../../../../src/assets/icons/not-work.svg'
import { ReactComponent as Progress } from '../../../../src/assets/icons/in-progerss.svg'
import { ReactComponent as Correct } from '../../../../src/assets/icons/correct.svg'
import { ReactComponent as Approval } from '../../../../src/assets/icons/approval.svg'
import Loading from '../../common-component/modals/loading-screen';
import AlertBox from '../../common-component/alert-box';
import Changesmodal from '../../common-component/modals/changes-modal';
import { useDispatch, useSelector } from "react-redux";
import { resetFormModified } from "../../../redux/action";
import useAxiosPrivate from '../../../services/hooks/useaxios-private';
import VendorBankApproval from './company-form-lists/privilege'
import BankDetails from './company-form-lists/BankDetails'
import PointOfContact from './company-form-lists/PointOfContact'
import KYCDocuments from './company-form-lists/KYCDocuments'

interface Props {
  onboardingViewfun: (val: any) => void;
  getEditData: (data: any) => void;
  editData: any;
  isEditMode: boolean
}

export default function CompanyForm({ onboardingViewfun, getEditData, editData, isEditMode }: Props) {

  const [showChangesModalcancel, setShowChangesModalcancel] = useState(false);
  const [showChangesModalEdit, setShowChangesModalEdit] = useState(false);
  const [isFormDirty, setIsFormDirty] = useState(false);

  const [showPage, setShowPage] = useState("CompanyOverview");
  const [setNextNav, setSetNextNav] = useState("");

  const [mainApiData, setMainApiData] = useState<any>({
    companyData: {}
  });
  const [showType, setShowType] = useState("warning") // error message showType
  const [showMessage, setShowMessage] = useState<string>("") // error message showMessage
  const [showAlertBox, setShowAlertBox] = useState(false) // error message ShowAlertBox

  const [directorApiData, setDirectorApiData] = useState<any>([]);

  const [statutoryDetailsMain, setStatutoryDetailsMain] = useState<any>([]);
  const [bankRelationshipMain, setBankRelationshipMain] = useState<any>([]);
  const [adminDetailsMain, setAdminDetailsMain] = useState<any>([]);
  const [businessContactMain, setBusinessContactMain] = useState<any>([]);
  const [subscriptionMain, setSubscriptionMain] = useState<any>([]);

  const [companyOverviewPan, setCompanyOverviewPan] = useState<any>();
  const [chooseType, setChooseType] = useState<any>("PAN");
  const [directorInfoPan, setDirectorInfoPan] = useState<any>();
  const [singleDirectorInfoPan, setSingleDirectorInfoPan] = useState<any>({});
  const [singleDirectorAddressInfoPan, setSingleDirectorAddressInfoPan] = useState<any>({});
  const [loadingScreen, setLoadingScreen] = useState<boolean>(false);
  const [userPrivilege, setUserPrivilege] = useState<any>("");
  const sideStatusRef = useRef<ChildRef>(null);
  const isFormModified = useSelector((state: any) => state.isFormModified);
  const axiosPrivate = useAxiosPrivate();
  const dispatch = useDispatch();

  const [iconStatus, setIconStatus] = useState<any>({
    companyIcon: "selected",
    addressIcon: "notworking",
    directorIcon: "notworking",
    statutoryIcon: "notworking",
    relationshipIcon: "notworking",
    adminIcon: "notworking",
    businessIcon: "notworking",
    subscriptionIcon: "notworking",
    vendorBankApprovalIcon: "notworking",
    bankIcon: "notworking",
    pointOfContactIcon: "notworking",
    KYCDocumentsIcon: "notworking",
  });

  useEffect(() => {
    // console.log(editData.CompanyData,"----------------------")
    if (!isEditMode) return;
    setStatutoryDetailsMain(editData.statutory)
    setBusinessContactMain(editData.otherBusiness)
    setAdminDetailsMain(editData.adminDetails)
    setBankRelationshipMain(editData.banks_relationship)
    setSubscriptionMain(editData.subscription)
    setDirectorApiData(editData.directors)
    setMainApiData(editData.CompanyData)
    setIconStatus({
      companyIcon: "completed",
      addressIcon: "completed",
      directorIcon: "completed",
      statutoryIcon: "completed",
      relationshipIcon: "completed",
      adminIcon: "completed",
      businessIcon: "completed",
      subscriptionIcon: "completed",
      vendorBankApprovalIcon: "completed",
      bankIcon: "completed",
      pointOfContactIcon: "completed",
      KYCDocumentsIcon: "completed",
    });
  }, [editData]);

  async function fetchDataFunction(companyPan: any, chooseType: any) {
    setLoadingScreen(true)
    try {
      const res = await axiosPrivate.post("/company-info", { CompanyValue: companyPan, ChooseType: chooseType }).then((res) => {

        if (res.status === 200) {
          setLoadingScreen(false)
          setMainApiData({
            ...mainApiData,
            companyData: { ...res.data.results.companyData, CompanyRocCity: "", CompanyRegState: "" },

          })
          setBankRelationshipMain(res.data.results.banks_relationship)
          setAdminDetailsMain(res.data.results.adminDetails)
          setBusinessContactMain(res.data.results.otherBusiness)
          setSubscriptionMain(res.data.results.subscription)
          setDirectorApiData(res.data.results.directorData)
          setStatutoryDetailsMain(res.data.results.gstinData)
          setShowAlertBox(true);
          setShowType("success");
          setShowMessage("Company record has been fetched and filled successfully.");
          clearAlert('');
        }
      });
    } catch (error: any) {
      setLoadingScreen(false)
      setShowAlertBox(true);
      setShowType("danger");
      setShowMessage(error?.response?.data?.error);
      clearAlert('');
    }
  }
  // console.log(mainApiData.companyData,"mainApiData.companyData")
  async function fetchDirectorDataFunction(directorPan: any) {
    setLoadingScreen(true)
    try {

      const res = await axiosPrivate.post("pan-card/details", { "pan": directorPan }).then((res) => {
        if (res.status === 200) {
          setLoadingScreen(false)
          setSingleDirectorInfoPan({
            "panData": {
              dateOfBirth: res.data.panData.dateOfBirth,
              gender: res.data.panData.gender,
              email: res.data.panData.email.toLowerCase().replace(/(\d{4})(?=\d)/g, "$1 ").trim(),
              contactNumber: res.data.panData.contactNumber,
              maskedAadhaarNumber: res.data.panData.maskedAadhaarNumber,
              country: res.data.panData.country,
              street: res.data.panData.street,
              state: res.data.panData.state,
              city: res.data.panData.city,
              pincode: res.data.panData.pincode,
              address: res.data.panData.address,
              DirectorName: res.data.panData.name,
              directorPan: res.data.panData.directorPan,
            },
            "address": {
              city: res.data.address.city,
              state: res.data.address.state,
              pincode: res.data.address.pincode
            }
          })
          setShowAlertBox(true);
          setShowType("success");
          setShowMessage("PAN Data has been fetched and filled successfully.");
          clearAlert('');
        }

      })
    } catch (error) {
      setLoadingScreen(false)
      setShowAlertBox(true);
      setShowType("danger");
      setShowMessage("Could not fetch PAN data. Enter values manually to complete this operation.");
      clearAlert('');
    }
  }

  async function getUserData() {
    await axiosPrivate.get(`bank-admin/company/${editData?.CompanyData?.companyData?.id}`)
      .then(response => {
        getEditData(response.data.results);
      })
      .catch(error => {
        console.error(error);
      });
  }
  console.log(mainApiData)
  async function sendDataApi() {
    console.log('main1')
    const address = {
      "Pincode": mainApiData.companyData?.CompanyRegPinCode,
      "country": mainApiData.companyData?.Country,
      "CompanyRegState": mainApiData.companyData?.CompanyRegState,
      "CompanyRocCity": mainApiData.companyData?.CompanyRocCity,
      "CompanyRegCity": mainApiData.companyData?.CompanyRegCity,
      "CompanyMcaStatus": mainApiData.companyData?.CompanyMcaStatus,
      "CompanyEmail": mainApiData.companyData?.CompanyEmail,
      "CompanyWebsite": mainApiData.companyData?.CompanyWebsite,
      "CompanyFullAddress": mainApiData.companyData?.CompanyFullAddress,
      "ContactNumber": mainApiData.companyData?.ContactNumber,
    }
    const finalData = {
      "ChooseType": chooseType,
      "CompanyValue": mainApiData.CompanyValue,
      "OrganisationName": mainApiData.OrganisationName,
      "OrganisationId": mainApiData.OrganisationId,
      "companyData": { ...mainApiData.companyData },
      "address": { ...address },
      "adminDetails": [...adminDetailsMain],
      "directors": [...directorApiData],
      "statutory": [...statutoryDetailsMain],
    }
    setLoadingScreen(true)
    try {
      console.log('main2')

      const res = isEditMode ? await axiosPrivate.patch(`bank-admin/company/${editData?.CompanyData?.companyData?.id}`, finalData) : await axiosPrivate.post("bank-admin/company", finalData)
      setLoadingScreen(false)
      console.log('main3')

      if (res.status === 200) {
        console.log('main4')

        setLoadingScreen(false)
        setShowAlertBox(true);
        setShowType("success");
        setShowMessage(res.data.message);
        clearAlert(res.status);
        dispatch(resetFormModified(false));
        getUserData();
        setIconStatus({
          companyIcon: "completed",
          addressIcon: "completed",
          directorIcon: "completed",
          statutoryIcon: "completed",
          relationshipIcon: "completed",
          adminIcon: "completed",
          businessIcon: "completed",
          subscriptionIcon: "completed",
          vendorBankApprovalIcon: "completed",
          bankIcon: "completed",
          pointOfContactIcon: "completed",
          KYCDocumentsIcon: "completed",
        });
      }
    }
    catch (error: any) {
      setLoadingScreen(false)
      setShowAlertBox(true);
      setShowType("danger");
      setShowMessage(error.response.data.message);
      clearAlert('');
    }
  }


  const clearAlert = (status: any) => {
    const timer = setTimeout(() => {
      setShowAlertBox(false);
      setShowMessage("");
      if (status === 200) {
        (!isEditMode || showPage === "VendorBankApproval") && onboardingViewfun("grid");
      }
      clearTimeout(timer);
    }, 5000);
  }

  const handleSideBar = (next: any) => {
    sideStatusRef.current!.next(next);
  }

  const handleStepClick = (step: any) => () => {
    if (iconStatus[step.icon] === "notworking") return;
    // if (!isEditMode && iconStatus[step.icon] !== "completed") {
    //   if (isFormModified && showPage !== step.page) {
    //     if (!isEditMode) {
    //       setShowChangesModalEdit(true);
    //       setSetNextNav(step.page);
    //     }
    //   } else {
    //     // handleSideBar(step.page);
    //     setShowPage(step.page);
    //   }
    // } else {
    if (isFormModified && showPage !== step.page) {
      setShowChangesModalEdit(true);
      setSetNextNav(step.page);
    } else {
      setShowPage(step.page);
    }
    // }
  };

  const navPageDestination = () => {
    setShowPage(setNextNav);
    setShowChangesModalEdit(false);
    dispatch(resetFormModified(false));
    setSetNextNav('');
    getUserData()


  };


  // <BsBuildingExclamation style={{ color: "#0055d4", fontSize: "25px", marginTop: "3px" }} />
  const steps = [
    { image: <BsBuildingExclamation style={{ color: "#0055d4", fontSize: "25px", marginTop: "3px" }} />, heading: "Company Overview", page: "CompanyOverview", icon: "companyIcon", isManditary: true },
    { image: <BsFileEarmarkCheckFill style={{ color: "#0055d4", fontSize: "25px", marginTop: "3px" }} />, heading: "Statutory Details", page: "StatutoryDetails", icon: "statutoryIcon", isManditary: true },
    { image: <BsFillPersonLinesFill style={{ color: "#0055d4", fontSize: "25px", marginTop: "3px" }} />, heading: "Stakeholder Info", page: "DirectorsInfo", icon: "directorIcon", isManditary: true },
    { image: <FontAwesomeIcon icon={faMapLocationDot} style={{ color: "#0055d4", fontSize: "25px", marginTop: "3px" }} />, heading: "Address", page: "Address", icon: "addressIcon", isManditary: true },
    { image: <BsBank2 style={{ color: "#0055d4", fontSize: "25px", marginTop: "3px" }} />, heading: "Bank Details", page: "BankDetails", icon: "bankIcon", isManditary: true },
    { image: <FontAwesomeIcon icon={faMapLocationDot} style={{ color: "#0055d4", fontSize: "25px", marginTop: "3px" }} />, heading: "Point of Contact", page: "PointofContact", icon: "pointOfContactIcon", isManditary: true },
    { image: <FontAwesomeIcon icon={faMapLocationDot} style={{ color: "#0055d4", fontSize: "25px", marginTop: "3px" }} />, heading: "KYC Documents", page: "KYCDocuments", icon: "KYCDocumentsIcon", isManditary: true },
    { image: <Approval style={{ color: "#0055d4", fontSize: "27px", marginTop: "3px" }} />, heading: "Approval", page: "Approval", icon: "adminIcon", isManditary: true },
    // { image: <BankRelationshipImg className='stepper-image' />, heading: "Bank's Relationship", page: "BankRelationshipManager", icon: "relationshipIcon" },
    // { image: <BsPersonFillGear style={{ color: "#0055d4", fontSize: "27px", marginTop: "3px" }} />, heading: "Admin Details", page: "AdminDetails", icon: "adminIcon", isManditary: true },
    // { image: <BsBank2 style={{ color: "#0055d4", fontSize: "27px", marginTop: "3px" }} />, heading: "Vendor Bank Approval", page: "VendorBankApproval", icon: "vendorBankApprovalIcon", isManditary: false },
    // { image: <OtherBusinessContactImg className='stepper-image' />, heading: "Other Business Contact", page: "PointofContact", icon: "businessIcon" },
    // { image: <SubscriptionValidityImg className='stepper-image' />, heading: "Subscription Validity", page: "SubscriptionValidity", icon: "subscriptionIcon" },
    // ... if other steps ...
  ];

  useEffect(() => {
    const fetchPrivillageData = async () => {
      try {
        const response = await axiosPrivate.get('/user/get-privilege');
        setUserPrivilege(response.data.UserPrivilege)

      } catch (error) {
      }
    };
    fetchPrivillageData();
  }, []);
  return (
    <div className='company-form'>
      <div className='navigation-header' style={{ height: "10vh", }}>
        <div className='left-header'>
          <NavigateHeader firstValue={userPrivilege === "FingertipAdmin" ? "Fingertip Admin" : "Approval"}
            style={{ cursor: "pointer" }}

            secondValue={"Company"} navigatePage={
              isFormModified
                ? () => {
                  setShowChangesModalcancel(true);
                } : () => {
                  // dispatch(resetFormModified(false));
                  onboardingViewfun("grid");
                }
            }
          />
        </div>
      </div>
      <div className='form-contents' style={{ height: "63vh", width: '100%', }}>
        <div style={{ width: '300px', height: '63vh' }}>
          <div className='side-navigation-main-company'>
            <div className='position-stepper-list'>
              {steps.map((step, index) => (
                <div className='stepper-main' key={index} style={isEditMode ? {} : { pointerEvents: "none" }}>
                  <div className='flex-image-text' onClick={isEditMode ? handleStepClick(step) : undefined}>
                    <div>{step.image}</div>
                    <div className='heading-icon'>
                      <div className={`stepper-heading ${showPage === step.page ? "active" : ""}`}>
                        {step.heading}
                        {step.isManditary &&
                          <span style={{ color: "red" }}>*</span>
                        }
                      </div>
                    </div>
                  </div>
                  <div className='stepper-icon'>
                    {iconStatus[step.icon] === "notworking" && <Notworking className='icon-tick' />}
                    {iconStatus[step.icon] === "selected" && <Progress className='icon-tick' />}
                    {iconStatus[step.icon] === "completed" && <Correct className='icon-tick' />}
                  </div>
                  {index !== steps.length - 1 &&
                    <div className='stepper-line'></div>
                  }
                </div>
              ))}
            </div>
          </div>

        </div>

        {/* setShowPage("PointofContact")
                      setIconStatus({
                        ...iconStatus,
                        businessIcon: "selected"
                      }) */}
        {
          showPage === "CompanyOverview" &&
          <CompanyOverview
            setIsFormDirty={setIsFormDirty}
            ref={sideStatusRef}
            mainApiData={mainApiData}
            setMainApiData={setMainApiData}
            setShowPage={setShowPage}
            showPage={showPage}
            iconStatus={iconStatus}
            setIconStatus={setIconStatus}
            fetchDatas={(value) => {
              fetchDataFunction(companyOverviewPan, chooseType)
            }}
            sendDataApi={sendDataApi}
            onboardingViewfun={onboardingViewfun}
            companyOverviewPan={companyOverviewPan}
            setCompanyOverviewPan={setCompanyOverviewPan}
            setChooseType={setChooseType}
            chooseType={chooseType}
            isEditMode={isEditMode}
            editData={editData}
          />
        }
        {
          showPage === "StatutoryDetails" &&
          <StatutoryDetails
            ref={sideStatusRef}
            sendDataApi={sendDataApi}
            editData={editData}
            setShowPage={setShowPage}
            iconStatus={iconStatus}
            setIconStatus={setIconStatus}
            onboardingViewfun={onboardingViewfun}
            statutoryDetailsMain={statutoryDetailsMain}
            setStatutoryDetailsMain={setStatutoryDetailsMain}
            isEditMode={isEditMode}
          />
        }
        {
          showPage === "DirectorsInfo" &&
          <DirectorsInfo
            ref={sideStatusRef}
            setShowPage={setShowPage}
            iconStatus={iconStatus}
            setIconStatus={setIconStatus}
            onboardingViewfun={onboardingViewfun}
            directorApiData={directorApiData}
            editData={editData}
            fetchDirectorPan={(value) => {
              fetchDirectorDataFunction(value)
            }}
            setDirectorApiData={(val: any) => {
              setDirectorApiData(val)
            }}
            sendDataApi={sendDataApi}
            setDirectorInfoPan={(val: string) => {
              setDirectorInfoPan(val)
            }}
            directorInfoPan={directorInfoPan}
            singleDirectorInfoPan={singleDirectorInfoPan}
            setSingleDirectorInfoPan={setSingleDirectorInfoPan}
            singleDirectorAddressInfoPan={singleDirectorAddressInfoPan}
            isEditMode={isEditMode}
          />
        }
        {
          showPage === "Address" &&
          <Address
            ref={sideStatusRef}
            mainApiData={mainApiData}
            setMainApiData={setMainApiData}
            onboardingViewfun={onboardingViewfun}
            sendDataApi={sendDataApi}
            setShowPage={setShowPage}
            iconStatus={iconStatus}
            editData={editData}
            setIconStatus={setIconStatus}
            isEditMode={isEditMode}
          />
        }


        {/* {
          showPage === "BankRelationshipManager" &&
          <BankRelationshipManager
            ref={sideStatusRef}
            sendDataApi={sendDataApi}
            editData={editData}
            bankRelationshipMain={bankRelationshipMain}
            setBankRelationshipMain={setBankRelationshipMain}
            setShowPage={setShowPage}
            iconStatus={iconStatus}
            setIconStatus={setIconStatus}
            onboardingViewfun={onboardingViewfun}
            isEditMode={isEditMode}
          />
        } */}
        {
          showPage === "AdminDetails" &&
          <AdminDetails
            ref={sideStatusRef}
            sendDataApi={sendDataApi}
            editData={editData}
            setShowPage={setShowPage}
            iconStatus={iconStatus}
            setIconStatus={setIconStatus}
            onboardingViewfun={onboardingViewfun}
            setAdminDetailsMain={setAdminDetailsMain}
            adminDetailsMain={adminDetailsMain}
            isEditMode={isEditMode}
          />
        }

        {
          showPage === "BankDetails" &&
          <BankDetails

          />
        }
        {/* {
          showPage === "PointofContact" &&
          <PointOfContact
            // ref={sideStatusRef}
            editData={editData}
            setShowPage={setShowPage}
            iconStatus={iconStatus}
            setIconStatus={setIconStatus}
            onboardingViewfun={onboardingViewfun}
            sendDataApi={sendDataApi}
            setBusinessContactMain={setBusinessContactMain}
            businessContactMain={businessContactMain}
            isEditMode={isEditMode}
          />
        } */}
        {
          showPage === "PointofContact" &&
          <PointOfContact

          />
        }
        {
          showPage === "KYCDocuments" &&
          <KYCDocuments

          />
        }
        {
          showPage === "Approval" &&
          <ApprovalOnboarding
            onboardingViewfun={onboardingViewfun}
            isEditMode={isEditMode}
            setShowPage={setShowPage}
            setIconStatus={setIconStatus}
            iconStatus={iconStatus}

          />
        }
        {/* {
          showPage === "SubscriptionValidity" &&
          <SubscriptionValidity
            editData={editData}
            setShowPage={setShowPage}
            getEditData={getEditData}
            setSubscriptionMain={setSubscriptionMain}
            subscriptionMain={subscriptionMain}
            onboardingViewfun={onboardingViewfun}
            sendDataApi={sendDataApi}
            isEditMode={isEditMode}
            iconStatus={iconStatus}
            setIconStatus={setIconStatus}
          />
        } */}

        {/* {
          showPage === "VendorBankApproval" &&
          <VendorBankApproval
            onboardingViewfun={onboardingViewfun}
            isEditMode={isEditMode}
            sendDataApi={sendDataApi}
            setShowPage={setShowPage}
            setMainApiData={setMainApiData}
            iconStatus={iconStatus}
            setIconStatus={setIconStatus}
            mainApiData={mainApiData}
            editData={editData}


          />
        } */}
      </div>
      {
        loadingScreen &&
        <Loading />
      }
      {
        showAlertBox &&
        <div className='alert-warp'>
          <AlertBox type={showType} message={showMessage} />
        </div>

      }
      {
        showChangesModalcancel &&
        <Changesmodal
          closeModal={() => setShowChangesModalcancel(false)}
          leavePage={() => {
            setShowChangesModalcancel(false);
            dispatch(resetFormModified(false));
            setIsFormDirty(false)
            onboardingViewfun("grid")
            // getEditMode(false)
          }}
          handleClose={() => setShowChangesModalcancel(false)} />
      }
      {
        showChangesModalEdit &&
        <Changesmodal
          closeModal={() => setShowChangesModalEdit(false)}
          leavePage={navPageDestination}
          handleClose={() => setShowChangesModalEdit(false)} />
      }
    </div>
  )
}



