import React, { useEffect, useState } from 'react'
import { BiPlus, BiMinus } from "react-icons/bi";
import "../../../styles/view-table.scss";
import IconButton from '../../../components/common-component/form-elements/icon-button';
import DeleteModal from '../../../components/common-component/modals/delete-modal';
import "../../../styles/payable/direct-tax/direct-tax-make-payment-grid.scss";
import { axiosPrivate } from '../../../middleware/axois-api';
import Loading from '../../../components/common-component/modals/loading-screen';
import AlertBox from '../../../components/common-component/alert-box';
import GstViewChallanModal from '../../common-component/modals/gst-view-challan-modal';




interface Props {
    selectedGridChallan: any;
    setGstEyeShowModal: (val: any) => void;
    selectedGridChallanFun: (val: any) => void;
    gstEyeShowModal: any;
    makePaymentDatas: any;
}

function DirectTaxMakePaymentGrid({ selectedGridChallan, setGstEyeShowModal, gstEyeShowModal, makePaymentDatas, selectedGridChallanFun }: Props) {
    const [showDeleteModel, setShowDeleteModel] = useState<boolean>(false)
    const [approvalChallanDetails, setApprovalChallanDetails] = useState<any>([]);
    const [approvalStatus, setApprovalStatus] = useState<any>([]);
    const [deleteId, setDeleteId] = useState<any>('');

    const [loadingScreen, setLoadingScreen] = useState<boolean>(false);
    const [showType, setShowType] = useState("warning") // error message showType
    const [showMessage, setShowMessage] = useState<string>("") // error message showMessage
    const [showAlertBox, setShowAlertBox] = useState(false) // error message ShowAlertBox

    const [makePaymentEyeShow, setMakePaymentEyeShow] = useState(false) // error message ShowAlertBox

    const clearAlert = (status: any) => {
        const timer = setTimeout(() => {
            setShowAlertBox(false);
            setShowMessage("");
            clearTimeout(timer);
        }, 3000);
    }

    // async function gstChallanDelete() {
    //     setLoadingScreen(true)
    //     await axiosPrivate.delete(`/gst-payable/delete-challans/${deleteId}`)
    //         .then(async (response: any) => {
    //             setShowDeleteModel(false)
    //             setLoadingScreen(false)
    //             setShowAlertBox(true)
    //             setShowType('success')
    //             setShowMessage("The record have been deleted.")

    //             //deleted challan details and update
    //             await axiosPrivate.post(`gst-payable/get-makepayment-challan-datas`, makePaymentDatas)
    //                 .then((response: any) => {
    //                     selectedGridChallanFun(response.data.MakePaymentDetails)
    //                     clearAlert('')
    //                 })
    //                 .catch(error => {
    //                     console.error(error);
    //                 });
    //         })
    //         .catch(error => {
    //             console.error(error);
    //             setShowDeleteModel(false)
    //             setLoadingScreen(false)
    //             setShowAlertBox(true)
    //             setShowType('error')
    //             setShowMessage(error.data.message)
    //         });

    // }
    const data = [
        {
            assessmentYear: "2022-2023",
            challan: "280",
            referenceNo: "1012",
            incomeTaxAmount: "1,000",
            surchargeAmount: "1,000",
            educationCessAmount: "1,000",
            interestAmount: "1,000",
            penaltyAmount: "1,000",
            otherAmount: "1,000",
            totalAmount: "1,000",
        },
        {
            assessmentYear: "2022-2023",
            challan: "280",
            referenceNo: "101",
            incomeTaxAmount: "1,000",
            surchargeAmount: "1,000",
            educationCessAmount: "1,000",
            interestAmount: "1,000",
            penaltyAmount: "1,000",
            otherAmount: "1,000",
            totalAmount: "1,000",
        },
        {
            assessmentYear: "2022-2023",
            challan: "280",
            referenceNo: "101",
            incomeTaxAmount: "1,000",
            surchargeAmount: "1,000",
            educationCessAmount: "1,000",
            interestAmount: "1,000",
            penaltyAmount: "1,000",
            otherAmount: "1,000",
            totalAmount: "1,000",
        },
    ]

    const [makePaymentIdsDatas, setMakePaymentIdsDatas] = useState<any>([])
    async function getMakePaymentDatas() {
        let paymentId = []
        for (const data of makePaymentDatas) { 
            paymentId.push(data.payableDirectTaxId)
        }
        try {
            const res = await axiosPrivate.post(`/directtax-payable/payment-datas`, { payableDirectTaxIds: paymentId });
          
            if (res.status == 200) {
                setMakePaymentIdsDatas(res.data.MakePaymentDetails)
            }
        } catch (error) {
            console.log("Error in getMakePaymentDatas function");
        }
    }
    useEffect(() => {
        getMakePaymentDatas()
    }, [makePaymentDatas])
    return (
        <>
            <div className='view-table-component ' style={{ marginTop: "20px", height: "205px", overflowY: "auto" }}>
                <table>
                    <thead>
                        <th className='align-differ' style={{ minWidth: "200px", textAlign: "center" }}>Assessment Year</th>
                        <th className='' style={{ minWidth: "100px", paddingLeft: "10px" }}>Challan</th>
                        <th className='' style={{ minWidth: "180px", paddingLeft: "10px" }}>Reference No</th>
                        <th className='' style={{ minWidth: "250px", paddingLeft: "10px" }}>Income Tax Amount(₹)</th>
                        <th className='' style={{ minWidth: "250px", paddingLeft: "10px" }}>Surcharge Amount(₹)</th>
                        <th className='' style={{ minWidth: "250px", paddingLeft: "10px" }}>Education Cess Amount(₹)</th>
                        <th className='' style={{ minWidth: "200px", paddingLeft: "10px" }}>Interest Amount(₹)</th>
                        <th className='' style={{ minWidth: "200px", paddingLeft: "10px" }}>Penalty Amount(₹)</th>
                        <th className='' style={{ minWidth: "200px", paddingLeft: "10px" }}>Other Amount(₹)</th>
                        <th className='' style={{ minWidth: "200px", paddingLeft: "10px" }}>Total Amount(₹)</th>
                        <th className='' style={{ textAlign: "center", width: "200px" }}>Action</th>
                    </thead>
                    <tbody >
                        {
                            makePaymentIdsDatas?.length === 0 ?
                                <tr className='style-grid-differ'>
                                    <td></td>
                                    <td>No records to show</td>
                                    <td></td>
                                    <td></td>
                                </tr> :
                                makePaymentIdsDatas?.map((makePaymentIdsDatas: any, index: number) => (
                                    <tr className='main-body-header' style={{ marginTop: "0px" }} key={index}>
                                        <td className='align-differ' style={{ minWidth: "200px", textAlign: "center" }}>{
                                        makePaymentIdsDatas?.assessmentYear}</td>
                                        <td className='align-left' style={{ minWidth: "100px" }}>{makePaymentIdsDatas?.challanNo}</td>
                                        <td className='align-left' style={{ minWidth: "180px" }}>{makePaymentIdsDatas?.referenceNumber}</td>
                                        <td className='align-left' style={{ minWidth: "250px", textAlign: "right", paddingRight: "50px" }}>{makePaymentIdsDatas?.incomeTaxAmount}</td>
                                        <td className='align-left' style={{ minWidth: "250px", textAlign: "right", paddingRight: "60px" }}>{makePaymentIdsDatas?.surchargeAmount}</td>
                                        <td className='align-left' style={{ minWidth: "250px", textAlign: "right", paddingRight: "20px" }}>{makePaymentIdsDatas?.educationCessAmount}</td>
                                        <td className='align-left' style={{ minWidth: "200px", textAlign: "right", paddingRight: "20px" }}>{makePaymentIdsDatas?.interestAmount}</td>
                                        <td className='align-left' style={{ minWidth: "200px", textAlign: "right", paddingRight: "30px" }}>{makePaymentIdsDatas?.penaltyAmount}</td>
                                        <td className='align-left' style={{ minWidth: "200px", textAlign: "right", paddingRight: "45px" }}>{makePaymentIdsDatas?.otherAmount}</td>
                                        <td className='align-left' style={{ minWidth: "200px", textAlign: "right", paddingRight: "55px" }}>{makePaymentIdsDatas?.totalAmount}</td>
                                        <td className='last-icon flexcls'>
                                            <IconButton
                                                iconName={"ShowEye"}
                                                height={"40px"}
                                                width={"40px"}
                                                fontSize={"30px"}
                                                margin={"0px 8px"}
                                                color={"white"}
                                                backgroundColor={""}
                                                hover={false}
                                                handleClick={async () => {
                                                    setMakePaymentEyeShow(true)
                                                    try {
                                                        const response = await axiosPrivate.get(`/directtax-payable/get-approval-data/${makePaymentIdsDatas.payableDirectTaxId}`);
                                                        setApprovalStatus(response.data.approvalStatusDetails)
                                                    } catch (error) {
                                                        console.log(error)
                                                    }
                                                }}
                                            />
                                            {/* selectedGridChallan?.length === 1 ? : {} */}
                                            <div style={{ opacity: "0.5", cursor: "not-allowed" }}>
                                                <IconButton
                                                    iconName={"Delete"}
                                                    height={"40px"}
                                                    width={"40px"}
                                                    fontSize={"25px"}
                                                    margin={"0px 8px"}
                                                    color={"white"}
                                                    cursor={"not-allowed"}
                                                    // selectedGridChallan?.length === 1 ?  : "pointer"
                                                    backgroundColor={"#D60000"}
                                                    hover={false}
                                                    // selectedGridChallan?.length === 1 ?  : true
                                                    disabled={true}
                                                    handleClick={() => {

                                                        // if (selectedGridChallan?.length === 1) {
                                                        // } else {
                                                        //     setShowDeleteModel(true)
                                                        //     setDeleteId(data?.payableGstDetailsId)
                                                        // }
                                                    }}
                                                />
                                            </div>


                                        </td>
                                    </tr>
                                ))}
                    </tbody>
                </table>
            </div>
            {/* {
                showDeleteModel &&
                <div >
                    <DeleteModal
                        getDelete={() => { gstChallanDelete() }}
                        clearValue={(value) => { setShowDeleteModel(value) }}
                        modelType={"grid-delete"}
                    />
                </div>
            } */}
            {
                loadingScreen &&
                <Loading />
            }
            {
                showAlertBox &&
                <div className='alert-warp'>
                    <AlertBox type={showType} message={showMessage} />
                </div>

            }
            {
                makePaymentEyeShow &&
                <GstViewChallanModal
                    clearValue={(val: boolean) => {
                        // setMakePaymentModel(val)
                        setMakePaymentEyeShow(val)
                    }}
                    pbNameValue={"Direct-Tax"}
                    approvalStatus={approvalStatus}
                // gstView={() => { }}
                // setLoadingScreen={setLoadingScreen}
                // loadingScreen={loadingScreen}
                />
            }
        </>
    )
}

export default DirectTaxMakePaymentGrid;
