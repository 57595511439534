import React, { useEffect, useRef, useState, } from 'react';
import '../../../styles/modals/upi-waiting-modal.scss';
import { RxCross1 } from 'react-icons/rx';
import AlertBox from '../alert-box';
import useAxiosPrivate from '../../../services/hooks/useaxios-private';
import { formatAmount } from '../../../helpers/amountRenderer';
import { format } from "date-fns/esm";

interface Styles {
  clearValue: (val: boolean) => void;
  upiTranctionDetails: any,
  executeResponce: (val: any) => void;
}



export default function UpiwaitingModal({
  clearValue, upiTranctionDetails, executeResponce
}: Styles) {

  const [showType, setShowType] = useState("warning") // error message showType
  const [showMessage, setShowMessage] = useState<string>("") // error message showMessage
  const [showAlertBox, setShowAlertBox] = useState(false) // error message ShowAlertBox
  const axiosPrivate = useAxiosPrivate();
  const [timeLeft, setTimeLeft] = useState(59);
  const initialTime = 5 * 60; // 5 minutes in seconds
  const [remainingTime, setRemainingTime] = useState(initialTime);

  useEffect(() => {
    const intervalId = setInterval(() => {
      // Decrease timeLeft every second
      setTimeLeft((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
    }, 1000);
    // Cleanup the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, []);


  useEffect(() => {
    const intervalId = setInterval(() => {
      setRemainingTime((prevTime) => {
        if (prevTime > 0) {
          return prevTime - 1;
        } else {
          clearInterval(intervalId);
          // You can add additional logic when the timer reaches 00:00
          return 0;
        }
      });
    }, 1000);

    // Clear the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);
  // Convert remaining seconds to minutes and seconds
  const minutes = Math.floor(remainingTime / 60);
  const seconds = remainingTime % 60;

  // Format the time to display as "mm:ss"
  const formattedTime = `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;


  useEffect(() => {
    let intervalId: any;

    const checkPaymentStatus = () => {
      // Set up the interval to call handleCloseWebView every 5 seconds
      intervalId = setInterval(async () => {
        try {
          const uuidRes: any = await axiosPrivate.post(`/vendor-payable/invoice/lyra-payment-status/${upiTranctionDetails.uuid}`);
          setShowAlertBox(true)
          if (uuidRes.data.status === "DUE") {
            console.log(uuidRes.data.status)
            setShowType("DUE")
            setShowMessage(`DUE`)
          } else if (uuidRes.data.status === "PAID") {
            console.log(uuidRes.data.status)
            clearValue(false)
            executeResponce(uuidRes.data)
          } else {
            console.log(uuidRes.data.status, "else")
            executeResponce(uuidRes.data)
            clearValue(false)
          }

        } catch (error) {
          console.error("Error while checking payment status:", error);
        }

      }, 10000);
    };

    checkPaymentStatus();

    // Clean up the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []);



  function formatDate(dateString: any) {
    const originalDate = new Date(dateString)
    const formattedDateTime = format(originalDate, "dd-MMM-yyyy HH:mm:ss");
    return formattedDateTime;
  }

  const clearAleart = () => {
    const timer = setTimeout(() => {
      setShowAlertBox(false);
      setShowMessage("");
      clearTimeout(timer);
    }, 5000);
  }

  const refresh = async () => {
    // Set up the interval to call handleCloseWebView every 5 seconds
    try {
      const uuidRes: any = await axiosPrivate.post(`/vendor-payable/invoice/lyra-payment-status/${upiTranctionDetails.uuid}`);
      setShowAlertBox(true)
      if (uuidRes.data.status === "DUE") {
        console.log(uuidRes.data.status)
        setShowType("DUE")
        setShowMessage(`DUE`)
      } else if (uuidRes.data.status === "PAID") {
        console.log(uuidRes.data.status)
        clearValue(false)
        executeResponce(uuidRes.data)
      } else {
        console.log(uuidRes.data.status, "else")
        executeResponce(uuidRes.data)
        clearValue(false)
      }

    } catch (error) {
      console.error("Error while checking payment status:", error);
    }
  }


  return (
    <div className={"upiwaiting-modal"}>
      <div className={"container-modal"}>
        {/* <div className={"close-icon"}>
          <span className={"cross-icon"}>
            <RxCross1 onClick={() => clearValue(false)
            } style={{ fontSize: "40px" }} />
          </span>
        </div> */}
        <div className='sub-container'>
          <div className='upiwaiting-main'>
            <div className='waiting-image'>
            </div>
            <div style={{ display: "flex", justifyContent: 'center', alignItems: "center", flexDirection: 'column', marginTop: "10px" }}>
              <p style={{ fontSize: '22px' }}> <b >
                {upiTranctionDetails?.customerName}
                {/* {"SIVAPRAKASH S"} */}
              </b></p>
              <p className='Amount-upi-waiting'>
                ₹ {`${formatAmount(upiTranctionDetails?.amount)}`}
              </p>
            </div>
            <div className=''>
              <p>
                Click on the notification recived on your UPI & enter your UPI PIN.
                <h6 style={{ color: '#0055D4', cursor: 'pointer' }} onClick={refresh}>Refresh</h6>
              </p>
            </div>
            <div style={{ display: "flex", justifyContent: 'center', alignItems: "center", marginTop: "10px" }}>
              <p className='time-upi-waiting'>
                {formattedTime}
              </p>
            </div>
            <div className=''>
              <p>
                <b>Note:</b>   This pop-up will automatically close once your payment is processed.
              </p>
              <p>
                Do not close this page or press back.
              </p>

            </div>

          </div>
        </div>
      </div>

      {
        showAlertBox &&
        <div className='alert-warp'>
          <AlertBox type={showType} message={showMessage} />
        </div>
      }
    </div>
  )
}

