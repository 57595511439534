import React, { useEffect, useState } from 'react'
import { RxCross1 } from 'react-icons/rx'
import "../../../styles/product-admin/kyc/kyctransactionmodel.scss"
import InputComponent from '../../common-component/form-elements/input-component'
import IconButton from '../../common-component/form-elements/icon-button'
import ButtonComponent from '../../common-component/form-elements/button-component'
import { axiosPrivate } from '../../../middleware/axois-api'
import Loading from '../../common-component/modals/loading-screen'
import AlertBox from '../../common-component/alert-box'
import DeleteModal from '../../common-component/modals/delete-modal'

interface props {
    setShowRateCard: any,
    kycTransactionData: any,
    setKycTransactionData: any,
    kycCurrentMode: any,
    setKycCurrentMode: any,
    companyDetails: any,
    validateFormRateCard: any,
    updatedIsValueValidAll: any,
    userDataErrors: any,
    setUserDataErrors: any,
    tableData: any
    setTableData: any
}
export default function KycTransactionModel({
    setShowRateCard,
    kycTransactionData,
    kycCurrentMode,
    setKycCurrentMode,
    setKycTransactionData,
    companyDetails,
    validateFormRateCard,
    updatedIsValueValidAll,
    userDataErrors,
    setUserDataErrors,
    tableData,
    setTableData,
}: props) {

    const [editIndex, setEditIndex] = useState(null);
    const [mode, setMode] = useState<string>("Add");
    const [loadingScreen, setLoadingScreen] = useState<boolean>(false)
    const [showAlertBox, setShowAlertBox] = useState<boolean>(false)
    const [showMessage, setShowMessage] = useState<string>("")
    const [showType, setShowType] = useState<string>("warning")
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
    const [deleteIndex, setDeleteIndex] = useState<number>()
    const [rateCardId, setRateCardId] = useState<any>()
    const [isSaveDisabled, setIsSaveDisabled] = useState(true);

    // Function for Save Disabled ------------------------>
    useEffect(() => {
        setIsSaveDisabled(tableData.length === 0);
    }, [tableData]);

    // Function for Edit------------------------->
    const handleEdit = (index: any) => {
        console.log(kycTransactionData, tableData, "Datas")
        const rowData = tableData[index];
        setKycTransactionData({
            rateCardId: rowData.rateCardId,
            from: rowData.from,
            to: rowData.to,
            mdr: rowData.mdr,
            organisationId: rowData.organisationId,
            company_id: rowData.company_id,
            customerOnboardingId: rowData.customerOnboardingId,
            transactionChargesId: rowData.transactionChargesId
        });
        setEditIndex(index);
        setMode("edit")
    };

    // Function for Save------------------------->
    const handleAdd = async () => {

        // Condition to check From Value is Lesser Than To Amount ----------------------------->
        const fromValue = parseFloat(kycTransactionData.from);
        const toValue = parseFloat(kycTransactionData.to);
        if (isNaN(fromValue) || isNaN(toValue)) {
            setShowMessage("Both 'From' and 'To' values must be valid numbers.");
            setShowType("warning");
            setShowAlertBox(true);
            setTimeout(() => {
                setShowAlertBox(false);
            }, 5000);
            return;
        }

        if (fromValue >= toValue) {
            setShowMessage("The 'From' amount must be less than the 'To' amount.");
            setShowType("danger")
            setShowAlertBox(true);
            setTimeout(() => {
                setShowAlertBox(false);
            }, 5000);
            return;
        }

        // const invalidFields = ['from', 'to', 'mdr'];
        // const invalidValue = invalidFields.some(field => kycTransactionData[field] === "0");
        // if (invalidValue) {
        //     setShowAlertBox(true);
        //     setShowType("warning");
        //     setShowMessage("Please do not enter 0 as value.");

        //     setTimeout(() => {
        //         setShowAlertBox(false);
        //     }, 5000);
        //     return;
        // }

        // Edit Mode API ---------------------------------->
        if (kycTransactionData.rateCardId) {
            try {
                const response = await axiosPrivate.patch(`customer-onboarding/rate-card/update`, {
                    kycTransactionData: [kycTransactionData]
                })

                const newKycTransactionModel = [...tableData];
                if (editIndex !== null) {
                    newKycTransactionModel[editIndex] = kycTransactionData;
                    setEditIndex(null);
                }

                setTableData(newKycTransactionModel);
                setKycTransactionData({
                    from: "",
                    to: "",
                    mdr: ""
                });

                setShowAlertBox(true);
                setShowType("success");
                setShowMessage(response.data.message)
                setTimeout(() => {
                    setShowAlertBox(false);
                }, 5000);

            } catch (error: any) {
                setShowAlertBox(true);
                setShowType("success");
                setShowMessage(error.response.data.error)
                setTimeout(() => {
                    setShowAlertBox(false);
                }, 5000);
            }
        } else {
            const newKycTransactionModel = [...tableData];
            if (editIndex !== null) {
                newKycTransactionModel[editIndex] = kycTransactionData;
                setEditIndex(null);
            } else {
                newKycTransactionModel.push(kycTransactionData);
            }

            setTableData(newKycTransactionModel);
            setKycTransactionData({
                from: "",
                to: "",
                mdr: ""
            });
        }
        setMode("Add")
    };

    // Function for Delete------------------------->
    const handleDelete = async (rateCardId: any, index: any) => {
        if (rateCardId) {
            try {
                const response = await axiosPrivate.patch(`/customer-onboarding/rate-card/delete/${rateCardId}`)
                if (response.status === 200) {
                    setTableData(tableData.filter((_: any, idx: number) => idx !== index));
                    setShowDeleteModal(false)
                    setShowAlertBox(true);
                    setShowType("success");
                    setShowMessage(response.data.message)
                    setTimeout(() => {
                        setShowAlertBox(false);
                    }, 5000);
                }
            } catch (error: any) {
                setShowDeleteModal(false)
                setShowAlertBox(true);
                setShowType("warning");
                setShowMessage(error.response.data.error)
                setTimeout(() => {
                    setShowAlertBox(false);
                }, 5000);
            }
        } else {
            setTableData(tableData.filter((_: any, idx: number) => idx !== index));
            setShowDeleteModal(false)
        }
    };

    return (
        <div className={"department-add-modal-virtual"}>
            <div className={"container-virtual"}>
                <div className={"close-icon"}>
                    <span className={"cross-icon"}>
                        <RxCross1 onClick={() => { setShowRateCard(false); setUserDataErrors({}) }} style={{ fontSize: "40px" }} />
                    </span>
                </div>
                <div>
                    <p className='employee-form-title'>
                        Rate Card
                    </p>
                </div>
                <div className='kyc-transaction-main'>
                    <div className='kyc-transaction-inputs'>
                        <div style={{ marginRight: '60px' }}>
                            <InputComponent
                                height={"50px"}
                                width={"300px"}
                                margin={"0px 0px 0px 0px"}
                                padding={"0px 20px 0px 0px"}
                                textAlign={"right"}
                                border={userDataErrors.from ? "1px solid red" : "1px solid #A9C3DC"}
                                borderRadius={"0px"}
                                backgroundColor={"white"}
                                color={"black"}
                                inputValue={kycTransactionData.from}
                                required={true}
                                inputTitle={'From ₹'}
                                type={"text"}
                                maxLength={10}
                                placeHolder={'Enter From Amount'}
                                getUser={(value) => {
                                    if (!isNaN(value) && value.length <= 10) {
                                        setKycTransactionData({ ...kycTransactionData, from: value })
                                    }
                                }}
                            />
                        </div>
                        <div style={{ marginRight: '60px' }}>
                            <InputComponent
                                height={"50px"}
                                width={"300px"}
                                margin={"0px 0px 0px 0px"}
                                textAlign={"right"}
                                padding={"0px 20px 0px 0px"}
                                border={userDataErrors.to ? "1px solid red" : "1px solid #A9C3DC"}
                                borderRadius={"0px"}
                                backgroundColor={"white"}
                                inputTitle={'To ₹'}
                                color={"black"}
                                required={true}
                                inputValue={kycTransactionData.to}
                                type={"text"}
                                maxLength={10}
                                placeHolder={'Enter To Amount'}
                                getUser={(value) => {
                                    if (!isNaN(value) && value.length <= 10) {
                                        setKycTransactionData({ ...kycTransactionData, to: value })
                                    }
                                }}
                            />
                        </div>
                        <div style={{ marginRight: '30px' }}>
                            <InputComponent
                                height={"50px"}
                                width={"300px"}
                                textAlign={"right"}
                                margin={"0px 0px 0px 0px"}
                                padding={"0px 20px 0px 0px"}
                                border={userDataErrors.mdr ? "1px solid red" : "1px solid #A9C3DC"}
                                borderRadius={"0px"}
                                backgroundColor={"white"}
                                inputTitle={'MDR%'}
                                color={"black"}
                                required={true}
                                type={"text"}
                                maxLength={10}
                                inputValue={kycTransactionData.mdr}
                                placeHolder={'Enter MDR%'}
                                getUser={(value) => {
                                    if (!isNaN(value) && value.length <= 10) {
                                        setKycTransactionData({ ...kycTransactionData, mdr: value })
                                    }
                                }}
                            />
                        </div>
                        <div style={{ marginTop: '35px' }}>
                            <ButtonComponent
                                title={mode === 'Add' ? 'Add' : 'Update'}
                                height={"50px"}
                                width={"130px"}
                                backgroundColor={"#0055D4"}
                                color={"white"}
                                margin={"0px 0px"}
                                disabled={false}
                                className={"button-component-hover common-btn"}
                                handleClick={() => {
                                    const valid = validateFormRateCard()
                                    if (valid) {
                                        handleAdd()
                                    } else {
                                        setShowAlertBox(true);
                                        setShowType("danger")
                                        setShowMessage(Object.values(updatedIsValueValidAll).every((values) => !values) ?
                                            "Some of the field(s) are not in required format." : "Mandatory field(s) should not be left blank.")
                                        setTimeout(() => {
                                            setShowAlertBox(false);
                                        }, 5000);
                                    }
                                }}
                            />
                        </div>
                    </div>
                    <div className='kyc-transaction-table'>
                        <div className='document-table-component' style={{ height: "181px" }} >
                            <table>
                                <thead>
                                    <tr>
                                        <th className='from-row'>From ₹</th>
                                        <th className='to-row'>To ₹</th>
                                        <th className='mdr-row'>MDR %</th>
                                        <th className='action-row'>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {tableData?.length === 0 ?
                                        <div className={'main-body-header'}>
                                            <tr>
                                                <td></td>
                                                <td></td>
                                                <td>No records to show</td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                        </div> : tableData.map((data: any, i: any) => (
                                            <div className={'main-body-header'} key={i}>
                                                <tr>
                                                    <td className={'from-row'}>{data.from} </td>
                                                    <td className={'to-row'}>{data.to} </td>
                                                    <td className={"mdr-row"}>{data.mdr}</td>
                                                    <td className={'action-row'}>
                                                        <IconButton
                                                            iconName={"Edit"}
                                                            height={"40px"}
                                                            width={"40px"}
                                                            fontSize={"30px"}
                                                            className={"icon-button"}
                                                            cursor={"pointer"}
                                                            margin={""}
                                                            color={"white"}
                                                            backgroundColor={"#0055D4"}
                                                            hover={false}
                                                            handleClick={() => handleEdit(i)}
                                                        />
                                                        <IconButton
                                                            iconName={"Delete"}
                                                            height={"40px"}
                                                            width={"40px"}
                                                            fontSize={"30px"}
                                                            className={"icons"}
                                                            cursor={"pointer"}
                                                            margin={""}
                                                            color={"white"}
                                                            backgroundColor={"#D60000"}
                                                            hover={true}
                                                            handleClick={() => {
                                                                setDeleteIndex(i);
                                                                setRateCardId(data.rateCardId)
                                                                setShowDeleteModal(true)
                                                            }}
                                                        />
                                                    </td>
                                                </tr>
                                            </div>
                                        ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className='kyc-transaction-button'>
                        <ButtonComponent
                            title={"Save"}
                            height={"50px"}
                            width={"130px"}
                            backgroundColor={"#0055D4"}
                            color={"white"}
                            margin={"0px 0px"}
                            disabled={isSaveDisabled}
                            className={isSaveDisabled ? "button-component disabled" : 'button-component-hover common-btn'}
                            handleClick={
                                () => { setShowRateCard(false) }
                            }
                        />
                    </div>
                </div>
            </div>
            {
                loadingScreen && <Loading />
            }
            {
                showDeleteModal &&
                <div>
                    <DeleteModal
                        getDelete={() => { handleDelete(rateCardId, deleteIndex) }}
                        clearValue={(value) => { setShowDeleteModal(value) }}
                        modelType={"grid-delete"}
                    />
                </div>
            }
            {showAlertBox &&
                <AlertBox type={showType} message={showMessage}
                />
            }
        </div>
    )
}
