
import React, { useEffect, useState, forwardRef, useImperativeHandle, } from 'react'
import { RxCross1 } from 'react-icons/rx';
import "../../../styles/modals/license-key-modal.scss"
import Loading from './loading-screen';
import AlertBox from '../alert-box';
import SubscriptionValidity from '../../product-admin/customer-onboarding/subscription-validity'
import "../../../styles/product-admin/kyc/subscription-validity.scss"

interface props {
    closeDocument: (val: any) => void;
    companyId: number;
    licenseKey: string;
    setlicenseKey: any;
}

export default function LicenseKeyModal({
    closeDocument,
    companyId,
    licenseKey,
    setlicenseKey
}: props) {

    const [loadingScreen, setLoadingScreen] = useState(false)
    const [showAlertBox, setShowAlertBox] = useState<boolean>(false)
    const [showMessage, setShowMessage] = useState<string>("")
    const [showType, setShowType] = useState<string>("warning")




    return (
        <>
            <div className={"license-key-modal"}>
                <div className={"container"}>
                    <div className={"close-icon"}>
                        <span className={"cross-icon"}>
                            <RxCross1 onClick={(val) => closeDocument(false)} style={{ fontSize: "40px" }} />
                        </span>
                    </div>

                    <div className='document-content'>
                        <>
                            <SubscriptionValidity
                                companyId={companyId}
                                licenseKey={licenseKey}
                                setlicenseKey={setlicenseKey}
                                setShowAlertBox={(val: boolean) => { setShowAlertBox(val) }}
                                setShowMessage={(val: string) => { setShowMessage(val) }}
                                setShowType={(val: string) => { setShowType(val) }}
                            />
                        </>
                    </div>
                </div>
            </div>
            {showAlertBox && <AlertBox type={showType} message={showMessage} />}
            {loadingScreen && <Loading />}
        </>
    )
}



