import React, { useEffect, useState } from 'react';
import { RxCross1 } from 'react-icons/rx';
import ButtonComponent from '../form-elements/button-component';
import DoumentDeclinemodal from './document-decline-modal';
import { AiOutlineMinusCircle } from 'react-icons/ai';
import { IoIosAddCircleOutline } from 'react-icons/io';
import { axiosPrivate } from '../../../middleware/axois-api';
import Loading from './loading-screen';
import AlertBox from '../alert-box';
// import "../../../styles/modals/vendor-payment-invoice.scss"
import "../../../styles/modals/vendor-payment-invoice-modal.scss"
import format from 'date-fns/format';
import { DateConverter, TitleCase } from '../../../helpers/capitalizetext';

interface props {
    vendorEyeShowmodal?: any;
    approvalHistory: any[];
    paymentHistory: any[];
    invoice?: any;
    currentActor: string;
    invoiceDetails?: any;
    closeDocument: (val: any) => void;
    type?: any
}

export default function VendorPaymentInvoice({
    closeDocument,
    currentActor,
    invoice,
    vendorEyeShowmodal,
    paymentHistory,
    approvalHistory,
    invoiceDetails,
    type,
}: props) {
    console.log(type, "type")

    // https://www.africau.edu/images/default/sample.pdf

    const [pdfUrl, setPdfUrl] = useState<string>("");
    const [imgUrl, setImgUrl] = useState<string>("");
    const [zoomLevel, setZoomLevel] = useState(600);
    const [showDeclineModal, setShowDeclineModal] = useState(false);
    const [loadingScreen, setLoadingScreen] = useState(false)
    const [showAlertBox, setShowAlertBox] = useState<boolean>(false)
    const [showMessage, setShowMessage] = useState<string>("")
    const [showType, setShowType] = useState<string>("warning")


    const checkIfFile = (obj: any): boolean => {
        return obj instanceof File;
    }


    useEffect(() => {
        let isfile = checkIfFile(invoice);
        if (isfile) {
            if (invoice.name) {
                const fileExtension = (invoice.name.split('.').pop() || '').toLowerCase();

                const isValidExtension = fileExtension === 'pdf' || fileExtension === 'jpg' || fileExtension === 'jpeg' || fileExtension === 'png';
                const isPdf = fileExtension === 'pdf';

                if (!isValidExtension) {
                    return;
                }
                const fileurl = URL.createObjectURL(invoice)
                if (isPdf) {
                    setPdfUrl(fileurl);
                } else {
                    setImgUrl(fileurl);
                }
            }
        } else if (invoice) {
            downloadfile();
        }
    }, []);

    // Function for showing pdf or image files...
    // useEffect(() => {
    const downloadfile = async () => {
        setLoadingScreen(true)
        try {
            const response = await axiosPrivate.post(`customeronboarding/download`, { document: invoice },
                {
                    responseType: "arraybuffer"
                });

            const contentType = response.headers['content-type'];
            // contentType.startsWith('image/')
            const isPdf = contentType === 'application/pdf' ? contentType === 'application/pdf' : false;
            const blob = new Blob([response.data], { type: contentType });
            const objectURL = URL.createObjectURL(blob);

            // const fileURL = URL.createObjectURL(response.data);
            const fileURL = objectURL;

            if (response.status === 200) {
                setLoadingScreen(false)
            }
            if (isPdf) {
                setPdfUrl(fileURL);
            } else {
                setImgUrl(fileURL);
            }

        } catch (error: any) {
            setLoadingScreen(false)
            // setShowAlertBox(true)
            // setShowType("warning")
            // setShowMessage(error.message)
            setTimeout(() => {
                setShowAlertBox(false);
            }, 5000);
        }
    }




    // Function for ZoomIn 
    const handleZoomIn = () => {
        if (zoomLevel < 1500) {
            setZoomLevel(zoomLevel + 200);
        }
    };

    // Function for ZoomOut
    const handleZoomOut = () => {
        if (zoomLevel > 300) {
            setZoomLevel(zoomLevel - 200);
        }
    };

    const formatAmount = (value: any) => {
        if (value) {
            const onlyNumber = value.toString().replace(/[^0-9.]/g, "");
            const parsedValue = parseFloat(onlyNumber);
            const formattedValue = isNaN(parsedValue) ? "" : parsedValue.toLocaleString('en-IN', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            });
            return formattedValue;
        }
        return "";
    };



    return (
        <>
            <div className={"vendor-payment-invoice-modal"}>
                {/* console.log(,flexWrap:"wrap",overflowY:"auto",height:"850px") */}
                <div className={"vendor-modul-sub"} style={{ display: "flex", gap: "20px", width: "98%", justifyContent: "center" }}>
                    <div className={"main-container"}>
                        <div className='document-content-main'>
                            <div className={"document-details"}>

                                {
                                    type === "vendorLoginInvoice" ?
                                        <div className={"vendor-details-main"}>
                                            <p className={"vendor-details-heading"}>{"Company Details"}</p>
                                            <div className={"vendor-details-sub"}>
                                                <div>
                                                    <p>{"Company Name"}</p>
                                                    {/* <p>{"Vendor ID"}</p> */}
                                                    <p>{"Email ID"}</p>
                                                    <p>{"Phone Number"}</p>
                                                    {/* <p>{"UPI"}</p> */}
                                                </div>
                                                <div>
                                                    <p>: {invoiceDetails.companyName}</p>
                                                    {/* <p>: {invoiceDetails.vendorNameID}</p> */}
                                                    <p>: {invoiceDetails.companyEmail}</p>
                                                    <p>: {invoiceDetails.companyContactNumber}</p>
                                                    {/* <p>: {vendorDetails.upi}</p> */}
                                                </div>
                                            </div>
                                        </div> :
                                        <div className={"vendor-details-main"}>
                                            <p className={"vendor-details-heading"}>{"Vendor Details"}</p>
                                            <div className={"vendor-details-sub"}>
                                                <div>
                                                    <p>{"Vendor Name"}</p>
                                                    {/* <p>{"Vendor ID"}</p> */}
                                                    <p>{"Account Number"}</p>
                                                    <p>{"IFSC"}</p>
                                                    {/* <p>{"UPI"}</p> */}
                                                </div>
                                                <div>
                                                    {
                                                        invoiceDetails.vendorName ?
                                                            <p>: {invoiceDetails.vendorName}</p> :
                                                            <p>: {invoiceDetails.vendorRazorpayName}</p>
                                                    }
                                                    {/* <p>: {invoiceDetails.vendorNameID}</p> */}
                                                    <p>: {invoiceDetails.accountNo}</p>
                                                    <p>: {invoiceDetails.IFSC}</p>
                                                    {/* <p>: {vendorDetails.upi}</p> */}
                                                </div>
                                            </div>
                                        </div>
                                }

                                <div className={"invoice-details-main"}>
                                    <p className={"invoice-details-heading"}>{"Invoice Details"}</p>
                                    <div className={"invoice-details-sub"}>
                                        <div>
                                            <p>{"GSTIN"}</p>
                                            <p>{"Invoice Number"}</p>
                                            <p>{"Invoice Date"}</p>
                                            <p>{"Due Date"}</p>
                                            <p>{"Base Amount"}</p>
                                            <p>{"Discount"}</p>
                                            <p>{"Sub Total"}</p>
                                            <p>{"GST"}</p>
                                            <p>{"Invoice Amount"}</p>
                                            <p>{"TDS"}</p>
                                            <p>{"Amount to Vendor"}</p>
                                        </div>
                                        <div>
                                            <p>: {invoiceDetails.GSTIN}</p>
                                            <p>: {invoiceDetails.invoiceNumber}</p>
                                            <p>: {DateConverter(invoiceDetails.invoiceDate)}</p>
                                            <p>: {DateConverter(invoiceDetails.dueDate)}</p>
                                            <p className={"amount-section"}> <span>:</span> <span>{formatAmount(invoiceDetails.baseAmount)}</span></p>
                                            <p className={"amount-section"}> <span>:</span> <span>{formatAmount(invoiceDetails.discount)}</span></p>
                                            <p className={"amount-section"}> <span>:</span> <span>{formatAmount(invoiceDetails.subTotal)}</span></p>
                                            <p className={"amount-section"}> <span>:</span> <span>{formatAmount(invoiceDetails.GST)}</span></p>
                                            <p className={"amount-section"}> <span>:</span> <span>{formatAmount(invoiceDetails.invoiceAmount)}</span></p>
                                            <p className={"amount-section"}> <span>:</span> <span>{formatAmount(invoiceDetails.TDS)}</span></p>
                                            <p className={"amount-section"}> <span>:</span> <span>{formatAmount(invoiceDetails.amountToVendor)}</span></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {
                                // approvalHistory.length !== 0 &&
                                <div className={"vendor-payment-table-main"}>
                                    <p className={"vendor-payment-table-title"}>{"Payment History"}</p>
                                    <div className='view-table-component' style={{ marginTop: "20px", marginBottom: "20px", maxHeight: "210px", overflow: "auto" }}>
                                        <table>
                                            <thead className='vendor-payment-table-head'>
                                                <th>Date</th>
                                                <th>Payment Method</th>
                                                <th style={{ textAlign: "center", paddingRight: "20px" }}>Amount</th>
                                                <th>Transaction Ref No</th>
                                                <th>Payment status</th>
                                            </thead>
                                            <tbody >
                                                {paymentHistory.length === 0 ?
                                                    <tr className='main-body-header'>
                                                        <td></td>
                                                        <td></td>
                                                        <td style={{ textAlign: "center" }}>No records to show</td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                    :
                                                    paymentHistory.map((data: any, index: number) => (
                                                        <tr className='main-body-header vendor-payment-table-body' key={index}>
                                                            <td>{DateConverter(data.date)}</td>
                                                            <td>{data.payment_type}</td>
                                                            <td style={{ textAlign: "right", paddingRight: "20px" }}>{formatAmount(data.payment_amount)}</td>
                                                            <td>{data.transaction_ref_no}</td>
                                                            <td style={{ color: TitleCase(data.transactionResult) == 'Paid' ? 'green' : 'red' }}>{TitleCase(data.transactionResult)}</td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            }
                            {
                                currentActor !== "singleUser" &&
                                <div className={"vendor-payment-table-main"}>
                                    <p className={"vendor-payment-table-title"}>{"Approval Status"}</p>
                                    <div className='view-table-component' style={{ marginTop: "20px", marginBottom: "20px", maxHeight: "210px", overflow: "auto" }}>
                                        <table>
                                            <thead className='vendor-payment-approval-status-head'>
                                                <th style={{ paddingLeft: "8px" }} >Date</th>
                                                <th>Actor</th>
                                                <th>Status</th>
                                                <th style={{ width: "30%" }}>Remarks</th>
                                            </thead>
                                            <tbody >
                                                {approvalHistory.length === 0 ?
                                                    <tr className='main-body-header'>
                                                        <td></td>
                                                        {/* <td></td>/ */}
                                                        <td colSpan={2} style={{ textAlign: "center" }}>No records to show</td>

                                                        <td></td>
                                                    </tr>
                                                    :
                                                    approvalHistory.map((data: any, index: number) => (
                                                        <tr className='main-body-header vendor-payment-table-body' key={index}>
                                                            <td>{data?.date}</td>
                                                            <td>{data?.actor}</td>
                                                            <td>{data?.status}</td>
                                                            <td>{data?.remarks}</td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>

                    <div className={"sub-container"}>
                        <div className={"close-icon"}>
                            <span className={"cross-icon"}>
                                <RxCross1 onClick={(val) => closeDocument(false)} style={{ fontSize: "40px" }} />
                            </span>
                        </div>

                        <div className='document-content-invoice'>
                            {pdfUrl ? (
                                <div style={{ height: "750px", width: "540px" }} >
                                    <object
                                        data={pdfUrl}
                                        type="application/pdf"
                                        width="100%"
                                        height="100%"
                                        aria-label="Online PDF"
                                    ></object>
                                </div>
                            ) : imgUrl ? (
                                <div style={{ overflow: "hidden", overflowY: "auto", overflowX: "auto", maxHeight: "650px", maxWidth: "540px" }}>
                                    <img src={imgUrl}
                                        alt="Image"
                                        style={{
                                            maxHeight: `${zoomLevel}px`,
                                            maxWidth: `${zoomLevel}px`,
                                        }} />
                                </div>
                            ) : (
                                <div>No Invoice copy found</div>
                            )}
                            {imgUrl &&
                                <div style={{ position: 'absolute', top: 780, right: 50, display: "flex", gap: "3px" }}>
                                    <AiOutlineMinusCircle className={"zoomOut"} onClick={handleZoomOut} />
                                    <IoIosAddCircleOutline className={"zoomIn"} onClick={handleZoomIn} />
                                </div>
                            }
                        </div>

                    </div>
                </div>
            </div >

            {/* {
                showDeclineModal &&
                <DoumentDeclinemodal
                    setDocumentDeclineStatus={setDocumentDeclineStatus}
                    documentDeclineStatus={documentDeclineStatus}
                    clearDoumentDeclinemodal={(val: boolean) => { setShowDeclineModal(val); }}
                    closeDocument={(val: boolean) => { closeDocument(val) }}
                    updateAproveStatus={() => updateAproveStatus()}
                />
            } */}

            {loadingScreen && <Loading />}
            {showAlertBox && <AlertBox type={showType} message={showMessage} />}
        </>
    )
}



