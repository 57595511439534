export const regionGroupByOptions = [
    { label: "Organisation", value: "Organisation" },
    { label: "Year", value: "Year" },
    { label: "Region", value: "Region" },
    { label: "State", value: "State" },
    { label: "City", value: "City" },
];

export const RegionVsTransactionfilterDatas: any = {
    "Organisation": [
        {
            cardTitle: "Organisation",
            cardType: "dropdown",
            selectedValue: "All",
            options: [
                { value: "All", label: "All" },
            ],
            isOpen: false
        },
        {
            cardTitle: "Y value setting",
            cardType: "dropdown",
            selectedValue: "Count",
            options: [
                { value: "Count", label: "Count" },
                { value: "Percentage", label: "Percentage" },
            ],
            isOpen: false
        },
    ],
    "Year": [
        {
            cardTitle: "Organisation",
            cardType: "dropdown",
            selectedValue: "All",
            options: [
                { value: "All", label: "All" },
            ],
            isOpen: false
        },
        {
            cardTitle: "Year",
            cardType: "dropdown",
            selectedValue: "All",
            options: [
                { value: "All", label: "All" },
            ],
            isOpen: false
        },
        {
            cardTitle: "Y value setting",
            cardType: "dropdown",
            selectedValue: "Count",
            options: [
                { value: "Count", label: "Count" },
                { value: "Percentage", label: "Percentage" },
            ],
            isOpen: false
        },
    ],
    "Region": [
        {
            cardTitle: "Organisation",
            cardType: "dropdown",
            selectedValue: "All",
            options: [
                { value: "All", label: "All" },
            ],
            isOpen: false
        },
        {
            cardTitle: "Region",
            cardType: "dropdown",
            selectedValue: "All",
            options: [
                { value: "All", label: "All" },
                { value: "North", label: "North" },
                { value: "South", label: "South" },
                { value: "East", label: "East" },
                { value: "West", label: "West" },
            ],
            isOpen: false
        },
        {
            cardTitle: "Y value setting",
            cardType: "dropdown",
            selectedValue: "Count",
            options: [
                { value: "Count", label: "Count" },
                { value: "Percentage", label: "Percentage" },
            ],
            isOpen: false
        },
    ],
    "State": [
        {
            cardTitle: "Organisation",
            cardType: "dropdown",
            selectedValue: "All",
            options: [
                { value: "All", label: "All" },
            ],
            isOpen: false
        },
        {
            cardTitle: "Region",
            cardType: "dropdown",
            selectedValue: "All",
            options: [
                { value: "All", label: "All" },
                { value: "North", label: "North" },
                { value: "South", label: "South" },
                { value: "East", label: "East" },
                { value: "West", label: "West" },
            ],
            isOpen: false
        },
        {
            cardTitle: "State",
            cardType: "dropdown",
            selectedValue: "All",
            options: [
                { value: "All", label: "All" },
            ],
            isOpen: false
        },
        {
            cardTitle: "Y value setting",
            cardType: "dropdown",
            selectedValue: "Count",
            options: [
                { value: "Count", label: "Count" },
                { value: "Percentage", label: "Percentage" },
            ],
            isOpen: false
        },
    ],
    "City": [
        {
            cardTitle: "Organisation",
            cardType: "dropdown",
            selectedValue: "All",
            options: [
                { value: "All", label: "All" },
            ],
            isOpen: false
        },
        {
            cardTitle: "Region",
            cardType: "dropdown",
            selectedValue: "All",
            options: [
                { value: "All", label: "All" },
                { value: "North", label: "North" },
                { value: "South", label: "South" },
                { value: "East", label: "East" },
                { value: "West", label: "West" },
            ],
            isOpen: false
        },
        {
            cardTitle: "State",
            cardType: "dropdown",
            selectedValue: "All",
            options: [
                { value: "All", label: "All" },
            ],
            isOpen: false
        },
        {
            cardTitle: "Y value setting",
            cardType: "dropdown",
            selectedValue: "Count",
            options: [
                { value: "Count", label: "Count" },
                { value: "Percentage", label: "Percentage" },
            ],
            isOpen: false
        },
    ],
}

const CustomEllipsisRenderer: React.FC<any> = (params: any) => {
    const value = params.value || "";
    const maxLength = 30; // Maximum characters allowed before truncation

    let displayValue = value;
    if (value.length > maxLength) {
        displayValue = value.substring(0, maxLength) + "...";
    }

    const cellStyle: React.CSSProperties = {
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden",
        paddingRight: "3px",
    };

    return <div style={cellStyle}>{displayValue}</div>;
};

export default CustomEllipsisRenderer;

export const coloumDataSample = {
    field: '', headerName: '', headerClass: "custom-header", resizable: true, suppressMovable: true, filter: true, filterParams: { filterOptions: ['contains', 'equals'] }, cellStyle: { textAlign: 'left' }, suppressSizeToFit: false, cellRendererFramework: CustomEllipsisRenderer,
}

export const leftOptions = {
    indexAxis: 'y' as const,
    maintainAspectRatio: false,
    type: 'line',
    responsive: true,
    layout: {},
    legend: {
        display: false
    },
    scales: {
        x: {
            reverse: true,
            title: {
                display: true,
                text: 'Total (Count)',
                font: {
                    color: '#333333',
                    weight: 'bold',                    
                    size: 14, // You can adjust the font size as needed
                    letterSpacing: 3, // Adjust the letter spacing
                },
            },
            grid: {
                display: false,
                stacked: true,
                barPercentage: 1.5,
                categoryPercentage: 0.8,
                barThickness: 10,
            },
        },
        y: {
            position: 'right',
            title: {
                display: false,
                beginAtZero: true,
                font: {
                    color: '#333333',
                    weight: 'bold',                    
                    size: 14, // You can adjust the font size as needed
                    letterSpacing: 3, // Adjust the letter spacing
                },
                text: 'Transaction Value(%)',
            },
            grid: {
                display: true,
                stacked: true,
                color: '#B3CAE1',
                barPercentage: 1.5,
                categoryPercentage: 0.8,
                barThickness: 10,
            },
            ticks: {
                display: false,
            },
        },
    },
    plugins: {
        tooltip: {
            enabled: false
        },
        legend: {
            display: false,
            position: 'bottom' as const,
        },
        title: {
            display: true,
            font: {
                weight: 'bold',
                size: 15,
            },
            padding: 10,
            text: 'Approved',
        },
    },
};

export const rightOptions = {
    indexAxis: 'y' as const,
    maintainAspectRatio: false,
    type: 'line',
    responsive: true,
    layout: {},
    scales: {
        x: {
            title: {
                display: true,
                text: 'Total (Count)',
                font: {
                    color: '#333333',
                    weight: 'bold',                    
                    size: 14, // You can adjust the font size as needed
                    letterSpacing: 3, // Adjust the letter spacing
                },
            },
            grid: {
                display: false,
                stacked: true,
                barPercentage: 1.5,
                categoryPercentage: 0.8,
                barThickness: 10,
            },
        },
        y: {
            title: {
                display: false,
                beginAtZero: true,
                font: {
                    color: '#333333',
                    weight: 'bold',                    
                    size: 14, // You can adjust the font size as needed
                    letterSpacing: 3, // Adjust the letter spacing
                },
                text: 'Transaction Value(%)',
            },
            grid: {
                display: true,
                stacked: true,
                color: '#B3CAE1',
                barPercentage: 1.5,
                categoryPercentage: 0.8,
                barThickness: 10,
            },
        },
    },
    plugins: {
        tooltip: {
            enabled: false
        },
        legend: {
            display: false,
            position: 'bottom' as const,
        },
        title: {
            display: true,
            font: {
                color: '#333333',
                weight: 'bold',                    
                size: 14, // You can adjust the font size as needed
                letterSpacing: 3, // Adjust the letter spacing
            },
            padding: 10,
            text: 'Pending Approval',
        },
    },
};

export const vendorPieOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        legend: {
            display: true,
            position: 'bottom' as const,
        },
        title: {
            display: true,
            font: {
                weight: 'bold',
                size: 15,
            },
            padding: 10,
            text: 'Overall Status',
        },
    },
};

export const RegionVsTransactionValueData = {
    labels: [],
    datasets: [
        {
            barPercentage: 1.1,
            barThickness: 25,
            data: [],
            backgroundColor: [],
            borderColor: [],
            borderWidth: 1
        }
    ]
};