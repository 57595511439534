import React, { useEffect, useState } from 'react'
import DropdownComponent from '../../common-component/form-elements/dropdown-component'
import { v4 as uuidv4 } from 'uuid'
import InputComponent from '../../common-component/form-elements/input-component';
import ButtonComponent from '../../common-component/form-elements/button-component';
import { axiosPrivate } from '../../../middleware/axois-api';
import { useSelector } from "react-redux";
import Changesmodal from '../../common-component/modals/changes-modal';

interface Props {
  onboardingViewfun: (val: any) => void;
  iconStatus: any
  setIconStatus: (val: any) => void;
  sendDataApi: () => void;
  isEditMode: boolean;
  mainApiData: any;
  editData: any;
  setShowPage: (val: any) => void;
  setMainApiData: (val: any) => void;

}

interface DropdownItem {
  label: string;
  value: string;
}

export default function VendorBankApproval({ isEditMode, mainApiData, editData, setMainApiData, onboardingViewfun, setShowPage, setIconStatus, sendDataApi, iconStatus }: Props) {
  const [emails, setEmails] = useState<any>([])
  const [cityId, setCityId] = useState<any>("")
  const [bankValues, setBankValues] = useState<any[]>([]);
  const [cityValues, setCityValues] = useState<any[]>([]);
  const [dropDownState, setDropDownState] = useState<DropdownItem[]>([])
  const [vendorApproverErrors, setVendorApproverErrors] = useState<Record<string, boolean>>({})



  const [vendorApproval, setVendorApproval] = useState<any>({
    State: "",
    stateId: "",
    City: "",
    cityId: "",
    bank: "",
    bankId: "",
    Branch: "",
    ifscCode: "",
    name: "",
    email: "",


  });
  const [vendorBankApproval, SetVendorBankApproval] = useState<any>(false)
  const isFormModified = useSelector((state: any) => state.isFormModified);
  const [showChangesModal, setShowChangesModal] = useState<boolean>(false);



  useEffect(() => {

    getVendorBankApprovalDetails();
    fetchBankValues()
  }, [])

  const userValidationRules: any = {
    vendorData: {
      bank: {
        regex: "",
        field: "mandatory",
        shouldNotBe: ""
      },
      State: {
        regex: "",
        field: "mandatory",
        shouldNotBe: ""
      },
      City: {
        regex: "",
        field: "mandatory",
        shouldNotBe: ""
      },
      email: {
        regex: "",
        field: "mandatory",
        shouldNotBe: ""
      },

    }
  }
  const updatedIsValueValidAll: Record<string, boolean> = {};
  const validateForm = (): boolean => {
    const updatedFieldValidity: Record<string, boolean> = {};
    const isMandatoryfalse = true;
    const isRegexfalse = true;
    Object.keys(userValidationRules.vendorData).forEach((field) => {
      const rule = userValidationRules.vendorData[field];
      const value = vendorApproval[field];
      console.log(value, rule, field)
      const isValueValid = (rule.field === "mandatory") ? (value ? (value !== rule.shouldNotBe) : false) : true;
      const isRegexValid = rule.regex !== "" ? new RegExp(rule.regex).test(value) : true;
      updatedFieldValidity[field] = !isValueValid || !isRegexValid;
      updatedIsValueValidAll[field] = !isValueValid;
      console.log(value, isValueValid, isRegexValid)
    });
    setVendorApproverErrors(updatedFieldValidity);
    const isFormValid = Object.keys(updatedFieldValidity).every((keys) => {
      return !updatedFieldValidity[keys]
    });
    return isFormValid;
  };


  const fetchBankValues = async () => {
    try {
      const response = await axiosPrivate.get("vendor/approvals/get/bank");
      if (response.status === 200) {
        const dropdownValues = response.data.data.map((item: any) => ({
          label: item.decryptedBankName,
          value: item.decryptedBankName,
          id: item.bankId,
        }));
        setBankValues(dropdownValues);
      }
    } catch (error) {
    }
  };
  const fetchCityValues = async (stateId: any, bankId: any) => {
    try {
      const response = await axiosPrivate.post("vendor/approval/get/city", { stateId: stateId, bankId: bankId });
      if (response.status === 200) {
        const dropdownValues = response.data.data.map((item: any) => ({
          label: item.decryptedCityName,
          value: item.decryptedCityName,
          id: item.cityId,
        }));
        setCityValues(dropdownValues);
      }
    } catch (error) {
    }
  };
  async function getVendorBankApprovalDetails() {

    if (isEditMode) {
      setVendorApproval({
        ...vendorApproval,
        name: editData?.CompanyData?.companyData?.vendorName,
        email: editData?.CompanyData?.companyData?.VendorEmail,
        bank: editData?.CompanyData?.companyData?.vendorBank,
        State: editData?.CompanyData?.companyData?.vendorState,
        City: editData?.CompanyData?.companyData?.vendorCity,

      });
      setMainApiData({
        ...mainApiData,
        companyData: {
          ...mainApiData.companyData,
          vendorApprovalId: editData?.CompanyData?.companyData?.vendorApprovalId,
        }
      })

    } else {
      // setMainApiData({
      //   ...mainApiData,
      //   companyData: {
      //     ...mainApiData.companyData,
      //     vendorApprovalId: response.data.data.vendorAppId
      //   }
      // })
      // setVendorApproval({
      //   State: response.data.data.state,
      //   stateId: response.data.data.StateId,
      //   City: response.data.data.city,
      //   cityId: response.data.data.cityId,
      //   bank: response.data.data.bank,
      //   bankId: response.data.data.bankId,
      //   Branch: response.data.data.branch,
      //   ifscCode: response.data.data.ifscCode,
      //   name: response.data.data.name,
      //   email: response.data.data.emailId,
      // })
    }



  }

  useEffect(() => {
    if (isEditMode) {
      getVendorBankApprovalEmailByCityId(editData?.CompanyData?.companyData?.vendorCityId, editData?.CompanyData?.companyData?.vendorBankID);
      fetchData(`vendor/approval/get/state/${editData?.CompanyData?.companyData?.vendorBankID}`, setDropDownState, 'data', "stateId", "decryptedStateName");
      fetchCityValues(editData?.CompanyData?.companyData?.vendorStateId, editData?.CompanyData?.companyData?.vendorBankID)
    }
  }, [])

  async function getVendorBankApprovalEmailByCityId(cityId: any, bankId: any) {
    try {
      const response = await axiosPrivate.post(`vendor/approvals/get-email`, {
        cityId: cityId, bankId: bankId
      });
      if (response.status === 200) {
        const dropdownValues = response.data.emails.map((item: any) => ({
          label: item.emailId,
          value: item.emailId,
          id: item.name,
          vendorApprovalId: item.vendorApprovalId

        }));
        setEmails(dropdownValues)

      }
    } catch {

    }
  }

  function next(next: any = "") {
    const isvalid = validateForm()
    if (isvalid) {
      SetVendorBankApproval(true)
      sendDataApi();

      setIconStatus({
        ...iconStatus,
        vendorBankApprovalIcon: "completed"
      })
    } else {
      setIconStatus({
        ...iconStatus,
        vendorBankApprovalIcon: "selected"
      })
    }
  }
  function cancel() {
    setShowPage("AdminDetails")
    setIconStatus({
      ...iconStatus,
      adminIcon: "selected",
    })
  }
  function returnGird() {
    onboardingViewfun("grid")
  }

  const fetchData = async (url: string, setData: (data: any[]) => void, nestedProperty: string, id: any, representName: any) => {
    try {
      const response = await axiosPrivate.get(url);
      if (response.status === 200) {
        const dropdownValues = response.data[nestedProperty].map((item: any) => ({
          label: item[representName],
          value: item[representName],
          id: item[id],
        }));
        const sortedData = [...dropdownValues].sort((a, b) => a.label.localeCompare(b.label));
        setData([...sortedData]);

      }
    } catch (error) {
    }
  };
  return (
    <>
      <div className='form-wrapper'>
        <div className='form-area-address' >
          <div className='company-heading'>
            Vendor Bank Approval
          </div>
          <div>
            <div className='form-main-wrapper'>
              <div key={uuidv4()} style={{ marginRight: "45px" }}
                className={vendorApproverErrors.bank ? 'setErrorcompany' : "dropdown-width"}>
                <DropdownComponent
                  required={true}
                  width={"300px"}
                  options={bankValues}
                  title={"Bank"}
                  defaultValue={[{ label: (vendorApproval?.bank === undefined || vendorApproval?.bank === "") ? "Select" : vendorApproval?.bank, value: vendorApproval && vendorApproval?.bank }]}
                  getData={(value) => {
                    setVendorApproval({
                      ...vendorApproval,
                      bank: value.value,
                      bankId: value.id,
                      City: "",
                      Branch: "",
                      State: "",
                      name: "",
                      email: ""

                    })
                    setEmails([])
                    setCityValues([])

                    if (value.id === "") {
                      setDropDownState([])
                    } else {
                      setDropDownState([])
                      fetchData(`vendor/approval/get/state/${value.id}`, setDropDownState, 'data', "stateId", "decryptedStateName");

                    }
                  }}
                />
              </div>
              <div key={uuidv4()} style={{ marginRight: "45px" }}
                className={vendorApproverErrors.State ? 'setErrorcompany' : "dropdown-width"}
              >
                <DropdownComponent
                  required={true}
                  width={"300px"}
                  options={dropDownState}
                  title={"State"}
                  defaultValue={[{ label: (vendorApproval?.State === undefined || vendorApproval?.State === "") ? "Select" : vendorApproval?.State, value: vendorApproval && vendorApproval?.State }]}
                  getData={(value) => {
                    setVendorApproval({
                      ...vendorApproval,
                      State: value.value,
                      stateId: value.id,
                      City: "",
                      Branch: "",
                      name: "",
                      email: ""
                    })
                    setEmails([])
                    if (value.id) {
                      fetchCityValues(value.id, vendorApproval.bankId ? vendorApproval.bankId : editData?.CompanyData?.companyData?.vendorBankID)
                    }
                  }}
                />
              </div>
              <div key={uuidv4()} style={{ marginRight: "45px" }} className={vendorApproverErrors.City ? 'setErrorcompany' : "dropdown-width"}>
                <DropdownComponent
                  required={true}
                  width={"300px"}
                  title={"ROC City"}
                  options={cityValues}
                  defaultValue={[{ label: (vendorApproval?.City === undefined || vendorApproval?.City === "") ? "Select" : vendorApproval?.City, value: vendorApproval && vendorApproval?.City }]}
                  getData={(value) => {
                    setVendorApproval({
                      ...vendorApproval,
                      City: value.value,
                      Branch: "",
                      name: "",
                      email: ""
                    })
                    setEmails([])
                    if (value.id) {
                      getVendorBankApprovalEmailByCityId(value.id, vendorApproval.bankId ? vendorApproval.bankId : editData?.CompanyData?.companyData?.vendorBankID)
                      return;
                    }
                  }}
                />
              </div>

            </div>
            <div className='form-main-end' style={{ marginTop: "30px" }}>
              <div key={uuidv4()} style={{ opacity: "0.5" }}>
                <DropdownComponent
                  width={"300px"}
                  title={"Branch"}
                  isDisabled={true}
                  defaultValue={[{ label: (vendorApproval?.Branch === undefined || vendorApproval?.Branch === "" || vendorApproval?.Branch === null) ? "Select" : vendorApproval?.Branch, value: vendorApproval && vendorApproval?.Branch }]}
                  options={[]}
                  getData={(value) => {
                    setVendorApproval({
                      ...vendorApproval,
                      Branch: value.value,
                    })
                  }}
                />
              </div>
              <div style={{ marginLeft: "45px", opacity: "0.5" }}>
                <InputComponent
                  height={"40px"}
                  width={"300px"}
                  margin={"0px 45px 30px 0px"}
                  padding={"0px 40px 0px 10px"}
                  border={"1px solid #A9C3DC"}
                  disabled={true}
                  borderRadius={"0px"}
                  backgroundColor={"white"}
                  placeHolder={'Enter IFSC Code '}
                  color={"black"}
                  type={"text"}
                  inputTitle={"IFSC Code"}
                  maxLength={11}
                  inputValue={vendorApproval && vendorApproval?.ifscCode}
                  getUser={(value: any) => {
                    const ifsc = value.toUpperCase().replace(/[^A-Z0-9]/g, "")
                    setVendorApproval({
                      ...vendorApproval,
                      ifscCode: ifsc
                    })
                  }}
                />
              </div>
              <div style={{ opacity: "0.5" }}>
                <InputComponent
                  height={"40px"}
                  width={"300px"}
                  margin={"0px 45px 30px 0px"}
                  padding={"0px 40px 0px 10px"}
                  border={"1px solid #A9C3DC"}
                  borderRadius={"0px"}
                  backgroundColor={"white"}
                  color={"black"}
                  type={"text"}
                  disabled={true}
                  inputTitle={"Name"}
                  placeHolder={"Enter Name"}
                  maxLength={100}
                  required={true}
                  inputValue={vendorApproval && vendorApproval.name}
                  getUser={(value: any) => {
                    setVendorApproval({
                      ...vendorApproval,
                      name: value
                    })
                  }}
                />
              </div>
              <div style={{ width: "300px" }} key={uuidv4()} className={vendorApproverErrors.email ? 'setErrorcompany' : "dropdown-width"}>
                <DropdownComponent
                  width={"300px"}
                  title={"Email"}
                  required={true}
                  defaultValue={[{ label: (vendorApproval?.email === undefined || vendorApproval?.email === "" || vendorApproval?.email === null) ? "Select" : vendorApproval?.email, value: vendorApproval && vendorApproval?.email }]}
                  options={emails}
                  getData={(value) => {
                    setVendorApproval({
                      ...vendorApproval,
                      email: value.value,
                      name: value.id,
                    })
                    setMainApiData({
                      ...mainApiData,
                      // vendorApprovalId: value.vendorApprovalId,
                      companyData: {
                        ...mainApiData.companyData,
                        vendorApprovalId: value.vendorApprovalId,

                      }

                    })
                  }}
                />
              </div>
            </div>
          </div>
          <div>
            <div className='footer-company'>
              {!isEditMode &&
                <ButtonComponent
                  title={"Cancel"}
                  height={"50px"}
                  width={"150px"}
                  margin={"0px 8px"}
                  backgroundColor={"#888888"}
                  color={"white"}
                  className={"button-component-hover cancel"}
                  handleClick={isFormModified ? () => { setShowChangesModal(true) } : returnGird}
                />
              }
              {
                isEditMode ?
                  <ButtonComponent
                    title={"Cancel"}
                    height={"50px"}
                    width={"150px"}
                    margin={"0px 8px"}
                    backgroundColor={"#888888"}
                    color={"white"}
                    className={"button-component-hover cancel"}
                    handleClick={returnGird}
                  /> :
                  <ButtonComponent
                    title={"Previous"}
                    height={"50px"}
                    width={"150px"}
                    margin={"0px 8px"}
                    backgroundColor={"#888888"}
                    color={"white"}
                    className={"button-component-hover cancel"}
                    handleClick={cancel}
                  />
              }
              <ButtonComponent
                title={isEditMode ? "Update" : "Save"}
                height={"50px"}
                width={"150px"}
                backgroundColor={"#0055D4"}
                color={"white"}
                margin={"0px 8px"}
                disabled={vendorBankApproval}
                className={vendorBankApproval ? "button-component disabled" : "button-component-hover common-btn"}
                handleClick={next}
              />
            </div>
          </div>
        </div>
      </div>
      {
        showChangesModal &&
        <Changesmodal
          closeModal={() => setShowChangesModal(false)}
          handleClose={() => {
            setShowChangesModal(false);
          }}
          leavePage={() => returnGird()} />
      }
    </>
  )
}
