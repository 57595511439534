import React, { useEffect, useState, useRef } from 'react'

import MainPageCard from '../../common-component/cards/main-page-card';
import NavigateHeader from '../../common-component/navigate-header';
import IconButton from '../../common-component/form-elements/icon-button';
import AgGrid, { AgGridRef } from '../../common-component/grids-and-tables/ag-grid';
import { customerColumnData, rowDatathis } from '../../../config/organisation/customer/customer-grid';
import { axiosPrivate } from '../../../middleware/axois-api';
import AlertBox from '../../common-component/alert-box';
import DeleteModal from '../../common-component/modals/delete-modal';
import { v4 as uuidv4 } from 'uuid';
import * as XLSX from 'xlsx';
import GridFilter from '../../common-component/grids-and-tables/grid-filter';
import FileUpload from '../../common-component/modals/file-upload-modal';
import axios from 'axios';
import Loading from '../../common-component/modals/loading-screen';
import { Filter } from '../../../helpers/filtterFunction';
const templateLink = "bulkupload_templates/Customer-bulkUpload.xlsx";
interface Props {
  onboardingViewfun: (val: any) => void;
  getEditData: (nav: any, data: any) => void;
  addMode: () => void;

}

interface IFilterValue {
  [key: string]: {
    columnName: string;
    filterType: string;
    filterWord: string;
  };
}
export default function CustomerGrid({ onboardingViewfun, getEditData, addMode }: Props) {
  const [curruntData, setCurruntData] = useState<any>([]);
  // const gridDataDownloadRef = useRef<AgGridRef>(null);

  const [filter, setFilter] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [rowData, setRowData] = useState<any>([]);
  const companyDownloadRef = useRef<AgGridRef>(null);
  const [orginalRowData, setOrginalRowData] = useState<any>([]);
  const [filterRestore, setFilterRestore] = useState<IFilterValue>({})
  const [filterMethod, setFilterMethod] = useState("")
  const [uploadedDataStore, setuploadedDataStore] = useState<any>([]);
  const [showType, setShowType] = useState("warning") // error message showType
  const [showMessage, setShowMessage] = useState<string>("") // error message showMessage
  const [showAlertBox, setShowAlertBox] = useState(false) // error message ShowAlertBox
  const [fileUpload, setFileUpload] = useState(false);
  const [loadingScreen, setLoadingScreen] = useState<boolean>(false);


  useEffect(() => {
    fetchData()
  }, []);




  const clearValue = (val: any) => {
    setFileUpload(val);
  }
  // handle download grid
  const handleDownloadExcel = () => {
    companyDownloadRef.current!.onBtExport();
  }

  async function getUserData(id: number) {
    await axiosPrivate.get(`companyadmin/organisation/get-detailsby-customerOverviewid/${id}`)
      .then(response => {
        if (getEditData) {
          getEditData("form", response.data)
        }
      })
      .catch(error => {
      });
  }


  // Function for Filter values
  const handleFilter = (value: any, filterType: string) => {
    Filter(value, filterType, orginalRowData, setRowData)
  }
  // clearAleart
  const clearAleart = () => {
    const timer = setTimeout(() => {
      setShowAlertBox(false);
      setShowMessage("");
      clearTimeout(timer);
    }, 5000);
  }


  // delete function
  const getDelete = async () => {
    if (curruntData.length === 1) {
      axiosPrivate.delete(`/companyadmin/organisation/delete-customer/${curruntData[0].customerOverviewId}`)
        .then(response => {
          if (response.status === 200) {
            setShowAlertBox(true)
            setShowType("success")
            setShowMessage(response.data.message)
            clearAleart()
            setShowDeleteModal(false)
            fetchData()
            setCurruntData([]);
          }
        })
        .catch(error => {
          setShowAlertBox(true);
          setShowType("warning")
          setShowMessage(error.response.data.error)
          setShowDeleteModal(false)
          clearAleart()
        });
    } else {
      let idList: any = [];
      curruntData.map((e: any, i: number) => {
        idList.push({
          id: e.customerOverviewId
        })
      })
      try {
        const res = await axiosPrivate.post("companyadmin/organisation/delete-multiple-customer", {
          data: idList
        })
        if (res.status === 200) {
          setShowAlertBox(true)
          setShowType("success")
          setShowMessage(res.data.message);
          clearAleart();
          setShowDeleteModal(false)
          fetchData()
          setCurruntData([]);
        }
      } catch (error: any) {
        setShowAlertBox(true)
        setShowType("danger")
        setShowMessage(error.response.data.error)
        clearAleart()
        setShowDeleteModal(false)
      }
    }
  };


  // current data update
  const getCurruntData = (val: any) => {
    setCurruntData(val);
  }

  // upload bank data 
  const getUploadedUserData = (val: any) => {
    setuploadedDataStore(val);

  }





  const fetchData = async () => {
    try {
      const response = await axiosPrivate.get('companyadmin/organisation/get-customer');
      setRowData(response.data.customerDetails);
      setOrginalRowData(response.data.customerDetails)
    } catch (error) {
    }
  };

  const UploadMessage = (message: any, type: any) => {
    if (type === "success") {
      setShowMessage(message)
      setShowAlertBox(true);
      setShowType("success");
      clearAleart()

    } else if (type === "warning") {
      setShowMessage(message)
      setShowAlertBox(true);
      setShowType("warning");
      clearAleart()

    }

  }


  const generateEmpty = () => {
    axios({
      url: 'http://localhost:5000/static/example.pdf',
      method: 'GET',
      responseType: 'blob', // important
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'file.pdf');
      document.body.appendChild(link);
      link.click();
    });
  }





  return (
    <MainPageCard>
      <div className='header-grid'>
        <div className='left-header'>
          <NavigateHeader firstValue={"Organisation"}
            secondValue={"Customer Details"}
          />
        </div>
        <div className='icon-buttons'>
          <IconButton
            iconName={"Filter"}
            height={"40px"}
            width={"40px"}
            fontSize={"30px"}
            margin={"0px 8px"}
            color={"white"}

            backgroundColor={"#0055D4"}
            hover={true}
            handleClick={(event: React.MouseEvent) => {
              event?.stopPropagation()
              setFilter(!filter);
            }}
          />
          <IconButton
            iconName={"Delete"}
            height={"40px"}
            width={"40px"}
            fontSize={"25px"}
            margin={"0px 8px"}
            color={"white"}
            hover={false}
            opacity={"0.5"}
            cursor={"not-allowed"}
            backgroundColor={"#D60000"}
            // hover={curruntData.length > 0}
            // opacity={curruntData.length > 0 ? "1" : "0.5"}
            // cursor={curruntData.length > 0 ? "pointer" : "default"}
            handleClick={() => {
              // if (curruntData.length > 0) {
              //   setShowDeleteModal(!showDeleteModal)
              // }
            }}
          />

          <IconButton
            iconName={"Add"}
            height={"40px"}
            width={"75px"}
            fontSize={"25px"}
            margin={"0px 8px"}
            color={"white"}
            backgroundColor={"#0055D4"}
            hover={true}
            handleClick={() => {
              onboardingViewfun("form");
              addMode();
            }}
          />
          <IconButton
            iconName={"Upload"}
            height={"40px"}
            width={"40px"}
            fontSize={"25px"}
            margin={"0px 0px 0px 8px"}
            color={"white"}
            backgroundColor={"#0055D4"}
            hover={true}
            // opacity={"0.5"}
            // cursor={"not-allowed"}
            handleClick={() => {
              setFileUpload(true)
              // generateEmptyExcel()
              // generateEmpty()
            }}
          />
          <IconButton
            iconName={"Download"}
            height={"40px"}
            width={"40px"}
            fontSize={"25px"}
            margin={"0px 8px"}
            color={"white"}
            backgroundColor={"#0055D4"}
            hover={true}
            handleClick={() => {
              handleDownloadExcel()
            }}
          />
        </div>
      </div>
      <div>
        <AgGrid
          tabelRowData={rowData}
          tableColumnData={customerColumnData}
          ref={companyDownloadRef}
          fileName={"CustomerData"}
          getCurruntData={getCurruntData}
          doubleClickUpdate={() => { getEditData && getEditData("form", "") }}
          getEdit={(data) => {
            getUserData(data.customerOverviewId)
          }
          }
        // getCurruntData={getCurruntData}
        />
      </div>
      {
        showAlertBox &&
        <div className='alert-warp'>
          <AlertBox type={showType} message={showMessage} />
        </div>
      }
      {
        showDeleteModal &&
        <DeleteModal
          modelType={"grid-delete"}
          getDelete={getDelete}
          clearValue={(value) => {
            setShowDeleteModal(value)
          }}
        />
      }
      {
        filter &&
        <div className='filter' key={uuidv4()}>
          <GridFilter
            filterRestore={filterRestore}
            filterMethod={filterMethod}
            tabelRowData={rowData}
            data={customerColumnData.slice(1)}
            handleClick={(Value: any, filterType: string) => {
              if (filterType !== "") {
                setFilterRestore(Value)
                setFilter(!filter)
                setFilterMethod(filterType)
                handleFilter(Value, filterType)
                setCurruntData([]);

              } else {
                setFilterMethod("")
                setFilterRestore({})
                setFilter(!filter)
                setRowData(orginalRowData)
                setCurruntData([]);
              }
            }}
          />
        </div>
      }
      {
        fileUpload &&
        <FileUpload
          clearValue={clearValue}
          headerData={customerColumnData}
          templateName={"Customer"}
          getUploadedUserData={getUploadedUserData}
          apiUrl={'bulk-upload/customer'}
          gridUpdate={() => { fetchData() }}
          loadingScreenFunction={(val: boolean) => { setLoadingScreen(val) }}
          setshowUploadMessage={UploadMessage}
          template={templateLink}
          excelFileName={"Customer-BulkUpload-Status"}
        />
      }
      {
        loadingScreen &&
        <Loading />
      }
    </MainPageCard>
  )
}
function list(arg0: any) {
  throw new Error('Function not implemented.');
}

