import React, { useEffect, useState } from 'react';
import { RxCross1 } from 'react-icons/rx';
import ButtonComponent from '../form-elements/button-component';
import DoumentDeclinemodal from './document-decline-modal';
import { AiOutlineMinusCircle } from 'react-icons/ai';
import { IoIosAddCircleOutline } from 'react-icons/io';
import { axiosPrivate } from '../../../middleware/axois-api';
import Loading from './loading-screen';
import AlertBox from '../alert-box';
// import "../../../styles/modals/vendor-payment-invoice.scss"
import "../../../styles/modals/vendor-payment-invoice-modal.scss"
import format from 'date-fns/format';
import { DateConverter, TitleCase } from '../../../helpers/capitalizetext';

interface props {
    vendorEyeShowmodal?: any;
    approvalHistory: any[];
    vendorSettlementowData: any;
    invoice?: any;
    currentActor: string;
    invoiceDetails?: any;
    closeDocument: (val: any) => void;
}

export default function VendorSettlementInvoice({
    closeDocument,
    currentActor,
    invoice,
    vendorEyeShowmodal,
    // paymentHistory,
    approvalHistory,
    invoiceDetails,
    vendorSettlementowData
}: props) {

    // https://www.africau.edu/images/default/sample.pdf

    const [pdfUrl, setPdfUrl] = useState<string>("");
    const [imgUrl, setImgUrl] = useState<string>("");
    const [zoomLevel, setZoomLevel] = useState(600);
    const [showDeclineModal, setShowDeclineModal] = useState(false);
    const [loadingScreen, setLoadingScreen] = useState(false)
    const [showAlertBox, setShowAlertBox] = useState<boolean>(false)
    const [showMessage, setShowMessage] = useState<string>("")
    const [showType, setShowType] = useState<string>("warning")


    const checkIfFile = (obj: any): boolean => {
        return obj instanceof File;
    }


    useEffect(() => {
        let isfile = checkIfFile(invoice);
        if (isfile) {
            if (invoice.name) {
                const fileExtension = (invoice.name.split('.').pop() || '').toLowerCase();

                const isValidExtension = fileExtension === 'pdf' || fileExtension === 'jpg' || fileExtension === 'jpeg' || fileExtension === 'png';
                const isPdf = fileExtension === 'pdf';

                if (!isValidExtension) {
                    return;
                }
                const fileurl = URL.createObjectURL(invoice)
                if (isPdf) {
                    setPdfUrl(fileurl);
                } else {
                    setImgUrl(fileurl);
                }
            }
        } else if (invoice) {
            downloadfile();
        }
    }, []);

    // Function for showing pdf or image files...
    // useEffect(() => {
    const downloadfile = async () => {
        setLoadingScreen(true)
        try {
            const response = await axiosPrivate.post(`customeronboarding/download`, { document: invoice },
                {
                    responseType: "arraybuffer"
                });

            const contentType = response.headers['content-type'];
            // contentType.startsWith('image/')
            const isPdf = contentType === 'application/pdf' ? contentType === 'application/pdf' : false;
            const blob = new Blob([response.data], { type: contentType });
            const objectURL = URL.createObjectURL(blob);

            // const fileURL = URL.createObjectURL(response.data);
            const fileURL = objectURL;

            if (response.status === 200) {
                setLoadingScreen(false)
            }
            if (isPdf) {
                setPdfUrl(fileURL);
            } else {
                setImgUrl(fileURL);
            }

        } catch (error: any) {
            setLoadingScreen(false)
            // setShowAlertBox(true)
            // setShowType("warning")
            // setShowMessage(error.message)
            setTimeout(() => {
                setShowAlertBox(false);
            }, 5000);
        }
    }




    // Function for ZoomIn 
    const handleZoomIn = () => {
        if (zoomLevel < 1500) {
            setZoomLevel(zoomLevel + 200);
        }
    };

    // Function for ZoomOut
    const handleZoomOut = () => {
        if (zoomLevel > 300) {
            setZoomLevel(zoomLevel - 200);
        }
    };

    const formatAmount = (value: any) => {
        if (value) {
            const onlyNumber = value.toString().replace(/[^0-9.]/g, "");
            const parsedValue = parseFloat(onlyNumber);
            const formattedValue = isNaN(parsedValue) ? "" : parsedValue.toLocaleString('en-IN', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            });
            return formattedValue;
        }
        return "";
    };
    let paymentHistory: any = [{

        "invoice": {
            InvoiceNumber: "INV-001/23-24",
            InvoiceDate: "25/10/2023",
            DueDate: "31/01/2024",
            ExpenceType: "Purchase",
            OverdueDays: "21 days",
            CreatedAt: "25/12/2023"
        },
        "invoiceAmount": {
            baseAmount: "1,000.00",
            discount: "0.00",
            subTotal: "1,000.00",
            GST: "180.00",
            invoicesAmount: "1,180.00",
            TDS: "100.00",
            paymentAmount: "1,080.00",
            amountpaid: "1080.00",
            outstanding: "0.00",
        },
        "VendorDetails": {
            vendorName: "FARMER PRODUCER COMPANY LIMITED LIMITED LIMITED LIMITED LIMITED",
            vendorCode: "VE-001",
            GSTIN: "33AAACH7413R1Z2",
            bank: " Axis",
            accountNumber: "30000110007457",
            IFSCcode: "UCBA0003000",
            UPI: "Upi@123",

        },
        // "invoice": {
        //     InvoiceNumber: "INV-001/23-24",
        //     InvoiceNumber: "INV-001/23-24",
        //     InvoiceNumber: "INV-001/23-24",
        //     InvoiceNumber: "INV-001/23-24",

        // },
    }
    ]

    return (
        <>
            <div className={"vendor-payment-invoice-modal"}>
                {/* console.log(,flexWrap:"wrap",overflowY:"auto",height:"850px") */}
                <div className={"vendor-modul-sub"} style={{ display: "flex", gap: "20px", width: "98%", justifyContent: "center" }}>
                    <div className={"main-container"}>
                        <div className='document-content-main'>


                            {/* <div className={"invoice-details-main"}>
                                    <p className={"invoice-details-heading"}>{"Invoice Details"}</p>
                                    <div className={"invoice-details-sub"}>
                                        <div>
                                            <p>{"GSTIN"}</p>
                                            <p>{"Invoice Number"}</p>
                                            <p>{"Invoice Date"}</p>
                                            <p>{"Due Date"}</p>
                                            <p>{"Base Amount"}</p>
                                            <p>{"Discount"}</p>
                                            <p>{"Sub Total"}</p>
                                            <p>{"GST"}</p>
                                            <p>{"Invoice Amount"}</p>
                                            <p>{"TDS"}</p>
                                            <p>{"Amount to Vendor"}</p>
                                        </div>
                                        <div>
                                            <p>: {invoiceDetails.GSTIN}</p>
                                            <p>: {invoiceDetails.invoiceNumber}</p>
                                            <p>: {DateConverter(invoiceDetails.invoiceDate)}</p>
                                            <p>: {DateConverter(invoiceDetails.dueDate)}</p>
                                            <p className={"amount-section"}> <span>:</span> <span>{formatAmount(invoiceDetails.baseAmount)}</span></p>
                                            <p className={"amount-section"}> <span>:</span> <span>{formatAmount(invoiceDetails.discount)}</span></p>
                                            <p className={"amount-section"}> <span>:</span> <span>{formatAmount(invoiceDetails.subTotal)}</span></p>
                                            <p className={"amount-section"}> <span>:</span> <span>{formatAmount(invoiceDetails.GST)}</span></p>
                                            <p className={"amount-section"}> <span>:</span> <span>{formatAmount(invoiceDetails.invoiceAmount)}</span></p>
                                            <p className={"amount-section"}> <span>:</span> <span>{formatAmount(invoiceDetails.TDS)}</span></p>
                                            <p className={"amount-section"}> <span>:</span> <span>{formatAmount(invoiceDetails.amountToVendor)}</span></p>
                                        </div>
                                    </div>
                                </div> */}
                            <div className={""}>
                                <div className='view-table-component' style={{ marginTop: "20px", marginBottom: "20px", height: "auto", overflow: "auto" }}>
                                    <table>
                                        <thead className='vendor-payment-table-head'>
                                            <th style={{ width: "350px" }}>Invoice Details</th>
                                            <th>Invoice Amount Details</th>
                                            <th>Vendor Details</th>
                                        </thead>
                                        <tbody >
                                            {/* {paymentHistory.length === 0 ? 
                                                <tr className='main-body-header'>
                                                    <td></td>
                                                    <td>No records to show</td>
                                                    <td></td> 
                                                </tr>
                                                : */}
                                            {

                                                <tr className='main-body-header vendor-payment-table-body' style={{ verticalAlign: "baseline" }} >
                                                    <td style={{ paddingRight: "20px", lineHeight: "32px", width: "350px" }}>
                                                        <p>
                                                            Invoice Number -{vendorSettlementowData.InvoiceDetails["Invoice Number"]
                                                            }
                                                        </p>
                                                        <p>
                                                            Invoice  Dtae -{vendorSettlementowData.InvoiceDetails["Invoice Date"]}
                                                        </p>
                                                        <p>
                                                            Due Date -{vendorSettlementowData.InvoiceDetails["Due Date"]}
                                                        </p>
                                                        <p>
                                                            Expence Type -{vendorSettlementowData.InvoiceDetails["Expence Type"]}
                                                        </p>
                                                        <p>
                                                            Overdue Days -{vendorSettlementowData.InvoiceDetails["Overdue Days"]}
                                                        </p>
                                                        <p>
                                                            Created At -{vendorSettlementowData.InvoiceDetails["Created At"]}
                                                        </p>
                                                    </td>

                                                    <td>
                                                        <div style={{ display: "flex", gap: "5px" }}>
                                                            <div>
                                                                <p>{"Base Amount"}</p>
                                                                <p>{"Discount"}</p>
                                                                <p>{"Sub Total"}</p>
                                                                <p>{"GST"}</p>
                                                                <p>{"Invoices Amount"}</p>
                                                                <p>{"TDS"}</p>
                                                                <p>{"Payment Amount"}</p>
                                                                <p>{"Amount paid"}</p>
                                                                <p>{"Outstanding"}</p>
                                                            </div>
                                                            <div>
                                                                <p>:</p>
                                                                <p>:</p>
                                                                <p>:</p>
                                                                <p>:</p>
                                                                <p>:</p>
                                                                <p>:</p>
                                                                <p>:</p>
                                                                <p>:</p>
                                                                <p>:</p>
                                                            </div>
                                                            <div style={{ width: "100px" }}>
                                                                <p style={{ textAlign: "right" }} className={"amount-section"}>  <span>{formatAmount(vendorSettlementowData.InvoiceAmountDetails["Base Amount"])}</span></p>

                                                                <p style={{ textAlign: "right" }} className={"amount-section"}>  <span>{formatAmount(vendorSettlementowData.InvoiceAmountDetails.Discount)}</span></p>

                                                                <p style={{ textAlign: "right" }} className={"amount-section"}>  <span>{formatAmount(vendorSettlementowData.InvoiceAmountDetails["Sub Total"])}</span></p>

                                                                <p style={{ textAlign: "right" }} className={"amount-section"}>  <span>{formatAmount(vendorSettlementowData.InvoiceAmountDetails.GST)}</span></p>

                                                                <p style={{ textAlign: "right" }} className={"amount-section"}>  <span>{formatAmount(vendorSettlementowData.InvoiceAmountDetails["invoices Amount"])}</span></p>

                                                                <p style={{ textAlign: "right" }} className={"amount-section"}>  <span>{formatAmount(vendorSettlementowData.InvoiceAmountDetails.TDS)}</span></p>

                                                                <p style={{ textAlign: "right" }} className={"amount-section"}>  <span>{formatAmount(vendorSettlementowData.InvoiceAmountDetails["Payment Amount"])}</span></p>
                                                                <p style={{ textAlign: "right" }} className={"amount-section"}>  <span>{formatAmount(vendorSettlementowData.InvoiceAmountDetails["Amount paid"])}</span></p>
                                                                <p style={{ textAlign: "right" }} className={"amount-section"}>  <span>{formatAmount(vendorSettlementowData.InvoiceAmountDetails.Outstanding)}</span></p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td >
                                                        <p style={{ whiteSpace: "normal" }}>
                                                            Vendor Name -{vendorSettlementowData.VendorAccountDetails["Vendor Name"]}
                                                        </p>
                                                        <p>
                                                            Vendor Code -{vendorSettlementowData.VendorAccountDetails["Vendor Code"]}
                                                        </p>
                                                        <p>
                                                            GSTIN -{vendorSettlementowData.VendorAccountDetails.GSTIN}
                                                        </p>
                                                        <p>
                                                            Bank -{vendorSettlementowData.VendorAccountDetails.Bank}
                                                        </p>
                                                        <p>
                                                            Account Number -{vendorSettlementowData.VendorAccountDetails["Account Number"]}
                                                        </p>
                                                        <p>
                                                            IFSC Code  -{vendorSettlementowData.VendorAccountDetails["IFSC Code"]}
                                                        </p>
                                                        <p>
                                                            UPI  -{vendorSettlementowData.VendorAccountDetails.UPI}
                                                        </p>

                                                    </td>
                                                </tr>


                                            }
                                            {/* // } */}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={"sub-container"}>
                        <div className={"close-icon"}>
                            <span className={"cross-icon"}>
                                <RxCross1 onClick={(val) => closeDocument(false)} style={{ fontSize: "40px" }} />
                            </span>
                        </div>

                        <div className='document-content-invoice'>
                            {pdfUrl ? (
                                <div style={{ height: "750px", width: "540px" }} >
                                    <object
                                        data={pdfUrl}
                                        type="application/pdf"
                                        width="100%"
                                        height="100%"
                                        aria-label="Online PDF"
                                    ></object>
                                </div>
                            ) : imgUrl ? (
                                <div style={{ overflow: "hidden", overflowY: "auto", overflowX: "auto", maxHeight: "650px", maxWidth: "540px" }}>
                                    <img src={imgUrl}
                                        alt="Image"
                                        style={{
                                            maxHeight: `${zoomLevel}px`,
                                            maxWidth: `${zoomLevel}px`,
                                        }} />
                                </div>
                            ) : (
                                <div>No Invoice copy found</div>
                            )}
                            {imgUrl &&
                                <div style={{ position: 'absolute', top: 780, right: 50, display: "flex", gap: "3px" }}>
                                    <AiOutlineMinusCircle className={"zoomOut"} onClick={handleZoomOut} />
                                    <IoIosAddCircleOutline className={"zoomIn"} onClick={handleZoomIn} />
                                </div>
                            }
                        </div>

                    </div>
                </div>
            </div >

            {/* {
                showDeclineModal &&
                <DoumentDeclinemodal
                    setDocumentDeclineStatus={setDocumentDeclineStatus}
                    documentDeclineStatus={documentDeclineStatus}
                    clearDoumentDeclinemodal={(val: boolean) => { setShowDeclineModal(val); }}
                    closeDocument={(val: boolean) => { closeDocument(val) }}
                    updateAproveStatus={() => updateAproveStatus()}
                />
            } */}

            {loadingScreen && <Loading />}
            {showAlertBox && <AlertBox type={showType} message={showMessage} />}
        </>
    )
}



