import { useCallback, useMemo, useRef, useState, useEffect, useImperativeHandle, forwardRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import '../../../styles/common-component-charts/chart-ag-grid.scss';
interface Props {
    tabelRowData?: any;
    tableColumnData?: any;
    className?: any;
    paginationPageSize?: any;
    fileName?: string;
};
export interface AgGridRef {
    onBtExport: () => void;
    clearFilters: () => void;
}

const ChartAgGrid = forwardRef(({
    tabelRowData,
    tableColumnData,
    className,
    paginationPageSize,
    fileName
}: Props, ref) => {
    const gridRef = useRef<any>(null)
    const [columnDefs, setColumnDefs] = useState(tableColumnData);

    useEffect(() => {
        if (gridRef.current.api) {
            onGridReady(null);
        }
    }, [columnDefs])

    useEffect(() => {
        window.addEventListener('error', e => {
            if (e.message === 'ResizeObserver loop limit exceeded' || e.message === 'ResizeObserver loop completed with undelivered notifications.') {
                const resizeObserverErrDiv = document.getElementById(
                    'webpack-dev-server-client-overlay-div'
                );
                const resizeObserverErr = document.getElementById(
                    'webpack-dev-server-client-overlay'
                );
                if (resizeObserverErr) {
                    resizeObserverErr.setAttribute('style', 'display: none');
                }
                if (resizeObserverErrDiv) {
                    resizeObserverErrDiv.setAttribute('style', 'display: none');
                }
            }
        });
    }, []);

    const getParams = () => {
        return {
            fileName: fileName,
            processCellCallback: (params: any) => {
                if (params.column.getColDef().field === 'selection') {
                    // Handle the multi-select cell value appropriately (assuming it's an array of objects)
                    if (Array.isArray(params.value)) {
                        const selectedValues = params.value.map((item: any) => item.name).join(', ');
                        return selectedValues;
                    } else if (params.value && typeof params.value === 'object') {
                        // Handle the case where params.value is an object (e.g., single selected value)
                        return params.value.name; // Assuming the object has a property 'name'
                    } else {
                        // Handle the case where params.value is not an array or object (e.g., return an empty string)
                        return '';
                    }
                }
                return params.value;
            },
        };
    };

    useImperativeHandle(ref, () => {
        return {
            onBtExport: onBtExport,
            clearFilters: clearFilters,
        }
    });

    const onBtExport = useCallback(() => {
        const gridApi = gridRef.current.api;
        const originalColumnDefs = gridApi.getColumnDefs();
        gridApi.setColumnDefs(originalColumnDefs.filter((colDef: any) => colDef.field !== 'selection'));
        // Export the data as CSV with modified columnDefs
        gridApi.exportDataAsCsv(getParams());
        // Revert back to the original columnDefs
        gridApi.setColumnDefs(originalColumnDefs);
    }, [gridRef]);

    const clearFilters = useCallback(() => {
        gridRef.current!.api.setFilterModel(null);
    }, []);

    const defaultColDef = useMemo(() => {
        return {
            editable: false,
            sortable: true,
            filter: true,
            resizable: true,
        };
    }, []);

    const onGridReady = useCallback((params: any) => {
        gridRef.current.api.sizeColumnsToFit();
    }, []);

    return (
        <div className={`${className ? className : 'ag-theme-alpine-chart'}`}>
            <div className={`ag-theme-alpine`}>
                <AgGridReact
                    ref={gridRef}
                    rowHeight={40}
                    groupHeaderHeight={30}
                    headerHeight={40}
                    paginationPageSize={paginationPageSize}
                    pagination={true}
                    rowData={tabelRowData}
                    columnDefs={columnDefs}
                    rowSelection={'multiple'}
                    defaultColDef={defaultColDef}
                    onGridReady={onGridReady}
                    onComponentStateChanged={onGridReady}
                    onGridSizeChanged={onGridReady}
                    suppressRowTransform={true}
                >
                </AgGridReact>
            </div>
        </div>
    );
});

export default ChartAgGrid;