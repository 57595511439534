import CryptoJS from 'crypto-js';
//bab6c45b6f33c52c84dc7a5614f82f59
const encryptionKey: string = 'bab6c45b6f33c52c84dc7a5614f82f59'; // Replace with your encryption key

export function encryptToken(token: string): string {
  const encrypted = CryptoJS.AES.encrypt(token, encryptionKey).toString();
  return encrypted;
}

export function decryptToken(encryptedToken: string): string {
  const bytes = CryptoJS.AES.decrypt(encryptedToken, encryptionKey);
  const decrypted = bytes.toString(CryptoJS.enc.Utf8);
  return decrypted;
}
