import React, { useEffect, useState, forwardRef, useImperativeHandle, } from 'react'
import InputComponent from '../../common-component/form-elements/input-component'
import RadioOrCheckbox from '../../common-component/form-elements/radio-or-checkbox'
import { ReactComponent as Previous } from '../../../assets/icons/next.svg'
import { ReactComponent as Next } from '../../../assets/icons/previous.svg'
import { ReactComponent as Correct } from '../../../assets/icons/plan_correct.svg'
import { ReactComponent as Close } from '../../../assets/icons/plan_close.svg'
import ButtonComponent from '../../common-component/form-elements/button-component'
import moment from 'moment'
import { useDispatch, useSelector } from "react-redux";
import { resetFormModified } from "../../../redux/action";
import { axiosPrivate } from '../../../middleware/axois-api'
import Loading from '../../common-component/modals/loading-screen'
import "../../../styles/product-admin/kyc/subscription-validity.scss"
import { DateConverter } from '../../../helpers/capitalizetext'

interface SubscriptionValidityprops {
    companyId: any;
    licenseKey: string;
    setShowAlertBox: (val: boolean) => void;
    setShowMessage: (val: string) => void;
    setShowType: (val: string) => void;
    setlicenseKey: any
}
export interface ChildRef {
    next: () => void;
}

const SubscriptionValidity = (({ companyId, licenseKey, setlicenseKey, setShowMessage, setShowAlertBox, setShowType }: SubscriptionValidityprops) => {

    const initData: any = {
        from: "",
        to: "",
        isActive: "Yes",
        remarks: "",
        index: 0,
    }
    const [subscriptionData, setSubscriptionData] = useState(initData)
    const [subscription, setSubscription] = useState("Silver")
    const [loadingScreen, setLoadingScreen] = useState<boolean>(false)
    const [subscriptionState, setSubscriptionState] = useState(false)
    const todayDate = moment(new Date()).format('YYYY-MM-DD');
    const [mindate, setMindate] = useState<string>(todayDate);
    const [generatedKey, setGeneratedKey] = useState<string>("");
    const dispatch = useDispatch();

    const [subscriptionValidityMap, setSubscriptionValidityMap] = useState<any>([])
    const [subscriptionErrors, setSubscriptionErrors] = useState<Record<string, boolean>>({})
    const userValidationRules: any = {
        from: {
            regex: "",
            field: "mandatory",
            shouldNotBe: ""
        },
        to: {
            regex: "",
            field: "mandatory",
            shouldNotBe: ""
        },
        isActive: {
            regex: "",
            field: "",
            shouldNotBe: ""
        },
        remarks: {
            regex: "",
            field: "",
            shouldNotBe: ""
        },
    }

    // useEffect for getting values initially...
    useEffect(() => {
        handleUpdateSubscription()
    }, []);

    const updatedIsValueValidAll: Record<string, boolean> = {};
    const validateForm = (): boolean => {
        const updatedFieldValidity: Record<string, boolean> = {};
        const isMandatoryfalse = true;
        const isRegexfalse = true;
        Object.keys(userValidationRules).forEach((field) => {
            const rule = userValidationRules[field];
            const value = subscriptionData && subscriptionData[field];
            const isValueValid = (rule.field === "mandatory") ? (value ? (value !== rule.shouldNotBe) : false) : true;
            const isRegexValid = rule.regex !== "" ? new RegExp(rule.regex).test(value) : true;
            updatedFieldValidity[field] = !isValueValid || !isRegexValid;
            updatedIsValueValidAll[field] = !isValueValid;
        });
        setSubscriptionErrors(updatedFieldValidity);
        const isFormValid = Object.keys(updatedFieldValidity).every((keys) => {
            return !updatedFieldValidity[keys]
        });
        return isFormValid;
    };

    const handleFromDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(resetFormModified(true));
        setMindate(moment(e.target.value).format("YYYY-MM-DD"))

        const fromDate = e.target.value;

        const currentDate = moment().format('YYYY-MM-DD'); // Get the current date

        if (fromDate.length > 8) {
            const year = fromDate.substring(0, 4);
            if (parseInt(year, 10) > 2100) {
                return;
            }
            if (parseInt(year) > 999) {
                if (parseInt(year, 10) < 1900) {
                    return;
                }
            }
        }
        setSubscriptionData((prevsubscriptionData: any) => ({
            ...prevsubscriptionData,
            from: fromDate,
            to: ""
        }));
    };

    const handleToDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(resetFormModified(true));

        const toDate = e.target.value;
        const currentDate = moment().format('YYYY-MM-DD'); // Get the current date

        if (toDate.length > 8) {
            const year = toDate.substring(0, 4);
            if (parseInt(year, 10) > 2100) {
                return;
            }
            if (parseInt(year) > 999) {
                if (parseInt(year, 10) < parseInt((subscriptionData.from).substring(0, 4))) {
                    return;
                }
            }
        }
        setSubscriptionData((prevsubscriptionData: any) => ({
            ...prevsubscriptionData,
            to: toDate
        }));

    };

    async function handleSaveTable() {
        setLoadingScreen(true)

        if (subscriptionData.from === "" || subscriptionData.to === "" || subscriptionData.subscription === "" || generatedKey === "") {
            setLoadingScreen(false)
            return
        }
        try {
            const response = await axiosPrivate.post("/customeronboarding/store-subscription-data", {
                "from": subscriptionData.from,
                "to": subscriptionData.to,
                "remarks": subscriptionData.remarks,
                "isActive": true,
                "subscription": subscription,
                "generated_key": generatedKey,
                "company_id": companyId
            })

            if (response.status === 200) {
                setSubscription("Silver");
                setSubscriptionState(false)
                setSubscriptionData({
                    from: "",
                    to: "",
                    isActive: "Yes",
                    remarks: "",
                    subscription: "",
                    index: 0,
                })
                setGeneratedKey("")
                getSubscriptionDetails()
                setlicenseKey(generatedKey);
                setLoadingScreen(false)
                setShowAlertBox(true)
                setShowType("success")
                setShowMessage(response.data.message)
                setLoadingScreen(false)

                setTimeout(() => {
                    setShowAlertBox(false);
                }, 5000);
            }
        }
        catch (error: any) {
            setShowAlertBox(true)
            setShowType("warning")
            setShowMessage(error.response.data.error)
            setLoadingScreen(false)

            setTimeout(() => {
                setShowAlertBox(false);
            }, 5000);
        }

    }

    async function handleUpdateSubscription() {
        try {
            const response = await axiosPrivate.patch(`/customeronboarding/kyc/update-subscription-status/${companyId}`)
            if (response.status === 200) {
                getSubscriptionDetails()
            }
        }
        catch (error: any) {
        }
    }

    const isDateValid = () => {
        // const fromDate = new Date(subscriptionData.from);
        // const toDate = new Date(subscriptionData.to);
        const fromDate = new Date(subscriptionData.from).setHours(0, 0, 0, 0);
        const toDate = new Date(subscriptionData.to).setHours(0, 0, 0, 0);

        if ((fromDate >= new Date().setHours(0, 0, 0, 0)) && (toDate >= fromDate)) {
            setSubscriptionErrors({
                from: false,
                to: false,
            });
            return true;
        } else {
            return false;
        }
    };


    async function handleGeneratekey() {

        let checkValidDate: boolean = isDateValid();

        if (checkValidDate) {
            try {
                const response = await axiosPrivate.post("/customeronboarding/generate-key/subscription", {
                    "from": subscriptionData.from,
                    "to": subscriptionData.to,
                    "remarks": subscriptionData.remarks,
                    "isActive": true,
                    "subscription": subscription,
                })
                setSubscriptionErrors({
                    from: false,
                    to: false,
                });

                setGeneratedKey(response.data.key)
            }
            catch (error: any) {
                setShowAlertBox(true)
                setShowType("warning")
                setShowMessage(error.response.data.error)
                setSubscriptionErrors({
                    from: false,
                    to: false,
                });
                setTimeout(() => {
                    setShowAlertBox(false);
                }, 5000);
            }
        } else {
            setShowAlertBox(true)
            setShowType("warning")
            setShowMessage("Some of the field(s) are not in required format.")
            setSubscriptionErrors({
                from: true,
                to: true,
            });

            setTimeout(() => {
                setShowAlertBox(false);
            }, 5000);
        }

    }

    const getSubscriptionDetails = async () => {
        // setLoadingScreen(true)
        try {
            const response = await axiosPrivate.get(`/customeronboarding/subscription-data-list/${companyId}`);   
            setSubscriptionValidityMap(response.data.decryptedSubscriptions)
        } catch (error: any) {

            setShowAlertBox(true)
            setShowType("warning")
            setShowMessage(error.message)

            setTimeout(() => {
                setShowAlertBox(false);
            }, 5000);

            // setLoadingScreen(false)
        }
    };

    return (
        <>
            <div>
                <div className='form-area'>
                    <div className='flex-form-subscription-kyc'>
                        <div className='form-subscription'>

                            <div className='company-heading' style={{ marginTop: "0px", marginBottom: "0px", paddingLeft: "20px" }}>
                                License Key Generation
                            </div>

                            <div className='subscription-main'>
                                <div className={`${subscriptionState ? "subscription-border-active" : "subscription-border"}`} style={{ height: "350px", marginTop: "40px" }} >
                                    <div
                                        className={`previous-main ${subscription !== "Platinum" ? "" : "disabled"}`}
                                        onClick={() => {
                                            if (subscription == "Platinum") return;
                                            setSubscription(prevSubscription => {
                                                if (prevSubscription === "Silver") return "Gold";
                                                if (prevSubscription === "Gold") return "Platinum";
                                                return "Silver";
                                            });
                                        }}
                                    >
                                        <Previous style={{ fill: '#065EC2' }} className='svg-background'
                                            onClick={() => {
                                                setSubscriptionState(false)
                                            }}
                                        />
                                    </div>
                                    <div
                                        className={`next-main ${subscription !== "Silver" ? "" : "disabled"}`}
                                        onClick={() => {
                                            if (subscription == "Silver") return;
                                            setSubscription(prevSubscription => {
                                                if (prevSubscription === "Platinum") return "Gold";
                                                if (prevSubscription === "Gold") return "Silver";
                                                return "Platinum";
                                            });
                                        }}
                                    >
                                        <Next style={{ fill: '#065EC2' }} className='svg-background'
                                            onClick={() => {
                                                setSubscriptionState(false)
                                            }} />
                                    </div>
                                    <div className={`${subscriptionState ? "silver-class-active" : "silver-class"}`}>
                                        <p className='silver-text' onClick={() => { setSubscriptionState(!subscriptionState) }} >{subscription}</p>
                                    </div>
                                    <div className='feature-main'
                                        onClick={() => { setSubscriptionState(!subscriptionState) }}>
                                        <div><b>Features</b></div>
                                        <div>
                                            {subscription === "Silver" || subscription === "Gold" || subscription === "Platinum" ? (
                                                <Correct />
                                            ) : (
                                                <Close />
                                            )}
                                            BI Reports
                                        </div>
                                        <div>
                                            {subscription === "Gold" || subscription === "Platinum" ? (
                                                <Correct />
                                            ) : (
                                                <Close />
                                            )}
                                            Virtual
                                        </div>
                                        <div>
                                            {subscription === "Platinum" ? (
                                                <Correct />
                                            ) : (
                                                <Close />
                                            )}
                                            Cross Bountry
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='form-width form-width-two' style={{ width: "75%" }}>
                            <div className='form-main-wrapper' style={{ gap: "40px" }} >
                                <div>
                                    <div className='title'>From<span className='required'>*</span></div>
                                    <input
                                        type='date'
                                        className='date-picker'
                                        style={{ border: subscriptionErrors.from ? '1px solid red' : '' }}
                                        value={subscriptionData.from}
                                        min={todayDate}
                                        max={moment(todayDate).add(1, 'years').format('YYYY-MM-DD')}
                                        onChange={handleFromDateChange}
                                    />
                                </div>
                                <div>
                                    <div className='title'>To<span className='required' >*</span></div>
                                    <input
                                        type='date'
                                        className='date-picker'
                                        style={{ border: subscriptionErrors.to ? '1px solid red' : '' }}
                                        min={mindate}
                                        max={moment(mindate).add(1, 'years').format('YYYY-MM-DD')}
                                        value={subscriptionData.to}
                                        onChange={handleToDateChange}
                                    />

                                </div>
                            </div>
                            <div className='radio-input organisation-radio-input margin-isactive'>
                                <div className=''>Is Active</div>
                                <div className='radio-btn-flex' >
                                    <RadioOrCheckbox
                                        value={"Yes"}
                                        name={"Yes"}
                                        type={"radio"}
                                        margin={"0px 30px 0px 0px"}
                                        checkedValue={subscriptionData?.isActive}
                                        getVal={(val) => {
                                            setSubscriptionData({
                                                ...subscriptionData,
                                                isActive: val
                                            })
                                            dispatch(resetFormModified(true));
                                        }}
                                    />
                                    {<div style={{ pointerEvents: "none", opacity: "0.5", cursor: "not-allowed" }}>
                                        <RadioOrCheckbox
                                            value={"No"}
                                            name={"No"}
                                            type={"radio"}
                                            checkedValue={subscriptionData?.isActive}
                                            disabled={true}
                                            getVal={(val) => {
                                                setSubscriptionData({
                                                    ...subscriptionData,
                                                    isActive: val
                                                })
                                                dispatch(resetFormModified(true));

                                            }}
                                        />
                                    </div>
                                    }
                                </div>
                            </div>
                            <div className='input-btn' style={{ opacity: "0.5" }}>

                                <div style={{ width: "100%" }}>
                                    <InputComponent
                                        height={"40px"}
                                        width={"100%"}
                                        margin={"0px 0px 30px 0px"}
                                        padding={"0px 0px 0px 10px"}
                                        border={"1px solid #A9C3DC"}
                                        borderRadius={"0px"}
                                        backgroundColor={"white"}
                                        color={"black"}
                                        type={"text"}
                                        inputTitle={"Remarks"}
                                        placeHolder={"Reason"}
                                        disabled={true}
                                        points={""}
                                        maxLength={100}
                                        inputValue={subscriptionData?.remarks}
                                        getUser={(value: any) => {
                                            const val = value.replace(/[^A-Za-z]/g, "")
                                            setSubscriptionData({
                                                ...subscriptionData,
                                                remarks: val
                                            })
                                            dispatch(resetFormModified(true));

                                        }}
                                    />
                                </div>

                            </div>
                            <ButtonComponent
                                title={'Generate'}
                                height={"50px"}
                                width={"150px"}
                                backgroundColor={"#0055D4"}
                                color={"white"}
                                margin={"0px 0px"}
                                disabled={subscriptionData.from === "" || subscriptionData.to === "" || subscriptionState === false ? true : false}
                                className={subscriptionData.from === "" || subscriptionData.to === "" || subscriptionState === false ? "button-component disabled" : "button-component-hover common-btn"}
                                handleClick={() => {
                                    handleGeneratekey()
                                }}

                            />
                        </div>
                    </div>

                    <div className={"dropdown-plus-combine-group"} style={{ paddingLeft: "20px", marginTop: "10px" }}>
                        <div style={{ height: "40px", width: "90%" }}>
                            <InputComponent
                                height={"41px"}
                                width={"100%"}
                                margin={"0px 60px 30px 0px"}
                                padding={"0px 0px 0px 10px"}
                                border={"1px solid #A9C3DC"}
                                borderRadius={"0px"}
                                backgroundColor={"white"}
                                color={"black"}
                                type={"text"}
                                maxLength={50}
                                inputTitle={"License Key"}
                                placeHolder={"License Key"}
                                readOnly
                                inputValue={generatedKey}
                                getUser={(value: any) => {
                                    // const val = value.replace(/[^A-Za-z]/g, "")
                                    setGeneratedKey(value);
                                    dispatch(resetFormModified(true));

                                }}
                            />
                        </div>
                        <div style={{ marginTop: "35px", zIndex: "1" }}>
                            <ButtonComponent
                                title={'Save'}
                                height={"40px"}
                                width={"150px"}
                                backgroundColor={"#0055D4"}
                                color={"white"}
                                margin={"0px 8px"}
                                disabled={generatedKey === "" ? true : false}
                                className={generatedKey === "" ? "button-component disabled" : "button-component-hover common-btn"}
                                handleClick={() => {
                                    handleSaveTable()
                                }}

                            />
                        </div>
                    </div>

                    <div className='view-table-component subscription-validity-table' style={{ marginTop: "30px", marginBottom: "25px", maxHeight: "146px", overflow: "auto", paddingLeft: "20px" }}>
                        <table>
                            <thead className='vendor-payment-table-head'>
                                <th style={{ paddingLeft: "10px", width: "340px" }}>Subscription</th>
                                <th style={{ width: "280px" }}>From</th>
                                <th style={{ width: "280px" }}>To</th>
                                <th>Is Active</th>
                            </thead>
                            <tbody >
                                {subscriptionValidityMap?.length === 0 ?
                                    <tr className='main-body-header'>
                                        <td></td>
                                        <td style={{ position: "relative", left: "100px" }}>No records to show</td>
                                        <td></td>
                                        <td></td>
                                    </tr> : subscriptionValidityMap.length !== 0 && subscriptionValidityMap.map((data: any, index: number) => (
                                        <tr className='main-body-header'>
                                            <td style={{ width: "340px", paddingLeft: "10px" }}>{data.Subscription}</td>
                                            <td style={{ width: "280px" }}>{DateConverter(data.from)}</td>
                                            <td style={{ width: "280px" }}>{DateConverter(data.to)}</td>
                                            <td>{data.isActive}</td>
                                        </tr> 
                                    ))}

                            </tbody>
                        </table>
                    </div>
                </div>
                {loadingScreen && <Loading />}

            </div>
        </>
    )
})
export default SubscriptionValidity
