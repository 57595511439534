import React, { useEffect, useState } from 'react'
import { RxCross1 } from 'react-icons/rx'
import '../../../styles/gst/gst-view-challan-modal.scss'
import GstEyeIconGrid from '../../../config/gst/gst-eye-icon-grid';
import GstPaymentHistory from '../../../config/gst/gst-payment-history';
import GstApprovalStatus from '../../../config/gst/gst-approval-status';
import GstMainEyeIconGrid from '../../../config/gst/gst-main-eye-icon-grid';
interface Props {
    clearValue: (value: any) => void;
    currentChallanDetails?: any;
    mainChallanDetails?: any;
    mainData?: any;
    approvalStatus?: any;
    pbNameValue?: any;

}

function GstViewChallanModal({ clearValue, currentChallanDetails, mainChallanDetails, mainData, approvalStatus, pbNameValue }: Props) {
    const [pbName, setPbName] = useState(pbNameValue);

    return (
        <div>
            <div className={"department-add-modal-gst"}>
                <div className={"container-gst"}>
                    <div className={"close-icon"}>
                        <span className={"cross-icon"}>
                            <RxCross1 onClick={() => {
                                clearValue(false)
                            }
                            } style={{ fontSize: "40px" }} />
                        </span>
                    </div>
                    <div className='main-wrapper-gst-challan'>
                        <div className='challan-details-text'>Challan Details</div>
                        {
                            pbName !== "Direct-Tax"  &&
                            <>
                                {
                                    mainData && mainData === "main" ?
                                        <div>
                                            <GstMainEyeIconGrid
                                                mainChallanDetails={mainChallanDetails}
                                            />
                                        </div>
                                        :
                                        <div>
                                            <GstEyeIconGrid
                                                currentChallanDetails={currentChallanDetails}
                                            />
                                        </div>
                                }
                            </>
                        }

                        <div style={{ marginTop: "30px" }}>
                            <GstPaymentHistory />
                        </div>
                        <div>
                            <GstApprovalStatus
                                approvalStatus={approvalStatus}
                            />
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default GstViewChallanModal