import React, { useEffect, useState } from 'react'
import ButtonComponent from '../form-elements/button-component';
import { AiOutlineMinusCircle } from 'react-icons/ai';
import { IoIosAddCircleOutline } from 'react-icons/io';
import { RxCross1 } from 'react-icons/rx';
import { axiosPrivate } from '../../../middleware/axois-api';

interface props {
    closeDocument: (val: any) => void;
    companyId: any
    comparisonData: any
}
export default function OverAllHyperVergeDetailsModel({ closeDocument, companyId, comparisonData}: props) {
    const [isChecked, setIsChecked] = useState(false);
    const [showDeclineModal, setShowDeclineModal] = useState(false);

    return (
        <>
            <div className={"document-show-modal"}>
                <div style={{ display: "flex", gap: "20px" }} className={"main-document"}>
                    <div className={"sub-container-document-show-model"}>
                        <div className={"close-icon"}>
                            <span className={"cross-icon"}>
                                <RxCross1 onClick={(val) => closeDocument(false)} style={{ fontSize: "40px" }} />
                            </span>
                        </div>
                        <div className='document-content'>
                            <div className='view-table-component' style={{ marginTop: "10px", marginBottom: "40px" }}>
                                <table>
                                    <thead>
                                        <tr>
                                            <th className='document-type'>Details</th>
                                            <th className='proof'>Hyperverge</th>
                                            <th className='file-status'>Fingertip (user edited)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {comparisonData?.length === 0 ? (
                                            <div className='main-body-header'>
                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td>No records to show</td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                            </div>
                                        ) : (
                                            comparisonData?.map((data: any, index: number) => (
                                                <div className='main-body-header'>
                                                                <tr>
                                                                    <td className='document-type'>{data.details}
                                                                    </td>
                                                                    <td className='proof'>{data.Hyperverge}</td>
                                                                    <td className='file-status'>
                                                                    {data.Fingertip}
                                                                    </td>
                                                                </tr>
                                                            </div>
                                                  ))
                                        )}

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}
