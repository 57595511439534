import React, { useEffect, useState, forwardRef, useImperativeHandle, useRef, } from 'react'
import RadioOrCheckbox from '../../../common-component/form-elements/radio-or-checkbox'
import InputComponent from '../../../common-component/form-elements/input-component'
import ButtonComponent from '../../../common-component/form-elements/button-component'
import BusinessContactDetails from '../../../../config/onboarding/other-business-contact-grid'
import useAxiosPrivate from "../../../../services/hooks/useaxios-private";
import AlertBox from '../../../common-component/alert-box'
import { useDispatch, useSelector } from "react-redux";
import { resetFormModified } from "../../../../redux/action";
import Changesmodal from '../../../common-component/modals/changes-modal';
import Chart from 'chart.js/auto';

interface props {
  mainApiData: any;
  onboardingViewfun: (val: any) => void;
  iconStatus: any
  editData: any
  businessContactMain: any
  setShowPage: (val: any) => void;
  setMainApiData: (val: any) => void;
  setIconStatus: (val: any) => void;
  sendDataApi: () => void;
  setBusinessContactMain: (val: any) => void;
  isEditMode: boolean;
  isDisabled: boolean;
  vendorDetails: any;
  setVendorDetails: any;
  stepperStatus: any;
  setStepperStatus: any;
  saveVendorDatas: any;
}
export interface ChildRef {
  next: () => void;
}
const PointOfContact = forwardRef<ChildRef, props>(({ onboardingViewfun, setMainApiData, mainApiData, iconStatus, isDisabled, editData, isEditMode, sendDataApi, setShowPage, setIconStatus, businessContactMain, setBusinessContactMain, vendorDetails, setVendorDetails, stepperStatus,
  setStepperStatus, saveVendorDatas }, ref) => {
  const initData: any = {
    contactPersonName: "",
    designation: "",
    phoneNumber: "",
    email: "",
    IsActive: "Yes",
    Remarks: "",
    index: 0
  }
  const [radioActive, setRadioActive] = useState("Yes")
  const [pointOfContactErrors, setPointOfContactErrors] = useState<Record<string, boolean>>({})
  // const [isDuplicate, setIsDuplicate] = useState(false)
  const [showType, setShowType] = useState("warning") // error message showType
  const [showMessage, setShowMessage] = useState<string>("") // error message showMessage
  const [showAlertBox, setShowAlertBox] = useState(false) // error message ShowAlertBox
  const [isFormDirty, setIsFormDirty] = useState(false)
  const isFormModified = useSelector((state: any) => state.isFormModified);
  const [showChangesModal, setShowChangesModal] = useState<boolean>(false);
  const [pointOfContactPercentage, setPointOfContactPercentage] = useState<any>(0);
  const [saveBtnDisabledStatus, setSaveBtnDisabledStatus] = useState<any>(true);
  const dispatch = useDispatch();



  const userValidationRules: any = {
    contactPersonName: {
      regex: /^[a-zA-Z][A-Za-z. ]+$/,
      field: "mandatory",
      shouldNotBe: ""
    },
    contactNumber: {
      regex: vendorDetails?.pointOfContact?.contactNumber !== "" ? /^[0-9]{10}$/ : "",
      field: "mandatory",
      shouldNotBe: ""
    },
    email: {
      regex: vendorDetails?.pointOfContact?.email !== "" ? /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/ : "",
      field: "mandatory",
      shouldNotBe: ""
    },
    // designation: {
    //   regex: businessContactMain?.designation ? /^[A-Za-z][A-Za-z\s]*$/ : "",
    //   field: "",
    //   shouldNotBe: ""
    // },

  }


  const updatedIsValueValidAll: Record<string, boolean> = {};
  const validateForm = (): boolean => {
    const updatedFieldValidity: Record<string, boolean> = {};
    const isMandatoryfalse = true;
    const isRegexfalse = true;
    Object.keys(userValidationRules).forEach((field) => {
      const rule = userValidationRules[field];
      const value = vendorDetails?.pointOfContact[field];
      const isValueValid = (rule.field === "mandatory") ? (value ? (value !== rule.shouldNotBe) : false) : true;
      const isRegexValid = rule.regex !== "" ? new RegExp(rule.regex).test(value) : true;
      updatedFieldValidity[field] = !isValueValid || !isRegexValid;
      updatedIsValueValidAll[field] = !isValueValid;
    });
    setPointOfContactErrors(updatedFieldValidity);
    const isFormValid = Object.keys(updatedFieldValidity).every((keys) => {
      return !updatedFieldValidity[keys]
    });
    return isFormValid;
  };



  function returnGird() {
    onboardingViewfun("grid")
    dispatch(resetFormModified(false));
  }

  const axiosPrivate = useAxiosPrivate();

  async function duplicateCheck() {
    const finalData = {
      "pointOfContact": {
        "contactNumber": vendorDetails?.pointOfContact?.contactNumber,
        "email": vendorDetails?.pointOfContact?.email,
        'razorpayAccountId': vendorDetails?.primaryKeyOfCurrentDatas
      }
    }
    let statusMessage: boolean = false;
    try {
      const res = await axiosPrivate.post("razorpay/vendor/user-creation/duplicateChecking", finalData)

      if (res.status === 200) {
        statusMessage = true;
        return true;
      }
    }
    catch (error: any) {
      setShowAlertBox(true)
      setShowType("danger")
      setShowMessage(error.response.data.error)
      clearAlert("")
      statusMessage = false;
      return false;
    }
  }

  const clearAlert = (status: any) => {
    const timer = setTimeout(() => {
      setShowAlertBox(false);
      setShowMessage("");
      if (status === 400) {

      }
      clearTimeout(timer);
    }, 5000);
  }


  async function next() {
    const isFormValid = validateForm()
    if (isFormValid) {
      if (await duplicateCheck()) {
        setShowPage('Address')
      }
    } else {
      setShowAlertBox(true);
      setShowType("danger");
      setShowMessage(Object.values(updatedIsValueValidAll).every((values) => !values) ?
        "Some of the field(s) are not in required format." : "Mandatory field(s) should not be left blank.")
      clearAlert('');
    }

    // if (isFormValid) {
    //   let isDuplicate = await duplicateCheck()
    //   if (isDuplicate) {

    //     if (isEditMode) {
    //       sendDataApi();

    //     } else {
    //       sendDataApi();
    //       setShowPage("PointOfContact")
    //       setIconStatus({
    //         ...iconStatus,
    //         PointOfContactIcon: "completed",
    //       })
    //     }
    //   } else {
    //     // setShowPage("PointOfContact")
    //     // setIconStatus({
    //     //   ...iconStatus,
    //     //   PointOfContactIcon: "completed",
    //     // })
    //   }
    // } else {
    //   setShowAlertBox(true);
    //   setShowType("danger");
    //   setShowMessage(Object.values(updatedIsValueValidAll).every((values) => !values) ?
    //     "Some of the field(s) are not in required format." : "Mandatory field(s) should not be left blank.")
    //   clearAlert('');
    // }
  }
  useImperativeHandle(ref, () => {
    return {
      next: next
    }
  });
  function cancel() {
    setShowPage("StatutoryDetails")
    setIconStatus({ ...iconStatus, statutoryIcon: "selected", })
  }
  // percentage chart
  const chartRef: any = useRef(null);
  useEffect(() => {
    const data = {
      value: pointOfContactPercentage,
      max: 100,
      label: "Progress"
    };

    const config: any = {
      type: 'doughnut',
      data: {
        datasets: [{
          data: [data.value, data.max - data.value],
          backgroundColor: [pointOfContactPercentage == 100 ? "green" : '#D2A100', '#0055D4'],
          borderWidth: 0
        }]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        cutoutPercentage: 85,
        rotation: -90,
        circumference: 180,
        tooltips: {
          enabled: false
        },
        legend: {
          display: false
        },
        animation: {
          animateRotate: true,
          animateScale: false
        },
        title: {
          display: true,
          text: data.label,
          fontSize: 16
        }
      }
    };

    if (chartRef.current) {
      const chartCtx = chartRef.current.getContext('2d');
      const gaugeChart = new Chart(chartCtx, config);

      // Cleanup on unmount
      return () => {
        gaugeChart.destroy();
      };
    }
  }, [pointOfContactPercentage]);
  // percentage chart

  useEffect(() => {
    let count = 0;
    let mandatoryList = ['contactPersonName', 'contactNumber', 'email']
    Object.keys(vendorDetails?.pointOfContact)?.forEach((item: any) => {
      let dropdownValueStatus = typeof (vendorDetails?.pointOfContact[item]) == 'object' ? vendorDetails?.pointOfContact[item].label !== "Select" : true;
      if (vendorDetails?.pointOfContact[item] !== "" && mandatoryList.includes(item) && dropdownValueStatus) {
        setStepperStatus({ ...stepperStatus, pointofContact: "working" })
        count++;
      }
    });
    let percentage: any = (count / 3) * 100
    if (percentage.toFixed(0) == 100) {
      setStepperStatus({ ...stepperStatus, pointofContact: "completed" })
    }
    setPointOfContactPercentage(percentage.toFixed(0))
  }, [vendorDetails?.pointOfContact])
  return (
    <>
      <div style={{ background: "#F8F8F8", height: "110px", width: "190px", position: "absolute", right: "0px", top: "0px", display: "flex", borderRadius: "0px 10px" }}>
        <div style={{ position: 'relative', height: '95px', width: '135px', left: "30px", top: "0px" }}>
          <canvas ref={chartRef}></canvas>
          <div style={{ width: "40px", position: "absolute", left: "50px", top: "58px", textAlign: "center" }}><strong style={{ fontSize: "16px" }}>{pointOfContactPercentage}%</strong></div>
          <div style={{ position: "absolute", left: "10px", fontSize: "16px", bottom: "-12px" }}>0%</div>
          <div style={{ position: "absolute", right: "-4px", fontSize: "16px", bottom: "-11px" }}>100%</div>
        </div>
      </div>
      <div className='form-wrapper'>
        <div className='form-area-address'>
          <div style={vendorDetails?.basicDetails?.merchantId == "" ? {} : { opacity: "0.5", pointerEvents: "none" }}>
            <div className='company-heading'>
              Point of Contact
            </div>
            <div className='form-main-admin'>
              <div style={{ marginRight: "45px" }}>
                <InputComponent
                  height={"40px"}
                  width={"300px"}
                  margin={"0px 0px 30px 0px"}
                  padding={"0px 0px 0px 10px"}
                  border={pointOfContactErrors.contactPersonName ? "1px solid red" : "1px solid #A9C3DC"}
                  borderRadius={"0px"}
                  backgroundColor={"white"}
                  // disabled={isEditMode && (mainApiData?.status === "Pending Approval") ? true : false}
                  placeHolder={"Enter Contact Person Name"}
                  autoFocus={vendorDetails?.basicDetails?.merchantId !== "" ? false : true}
                  color={"black"}
                  type={"text"}
                  inputTitle={"Contact Person Name"}
                  maxLength={100}
                  required={true}
                  inputValue={vendorDetails?.pointOfContact?.contactPersonName}
                  getUser={(value: any) => {
                    setSaveBtnDisabledStatus(false)
                    const removeInitialSpaceAndAlphabetsOnly = value.replace(/^\s+/, "").replace(/[^a-zA-Z\s]/g, "");
                    setVendorDetails({
                      ...vendorDetails,
                      pointOfContact: {
                        ...vendorDetails.pointOfContact,
                        contactPersonName: removeInitialSpaceAndAlphabetsOnly,
                      }
                    })
                    // setIconStatus({
                    //   ...iconStatus,
                    //   businessIcon: "selected",
                    // })
                    setIsFormDirty(true)
                    dispatch(resetFormModified(true));
                  }}
                />
              </div>
              <div style={{ marginRight: "45px" }}>
                <InputComponent
                  height={"40px"}
                  width={"300px"}
                  margin={"0px 0px 30px 0px"}
                  padding={"0px 0px 0px 70px"}
                  borderRadius={"0px"}
                  backgroundColor={"white"}
                  // disabled={isEditMode && (mainApiData?.status === "Pending Approval") ? true : false}
                  color={"black"}
                  type={"mobile"}
                  placeHolder={"Enter Contact Number"}
                  maxLength={10}
                  inputTitle={"Contact Number"}
                  required={true}
                  className={"mobilenumber-input"}
                  border={pointOfContactErrors?.contactNumber ? "1px solid red" : "1px solid #A9C3DC"}
                  inputValue={vendorDetails?.pointOfContact?.contactNumber}
                  getUser={(value: any) => {
                    setSaveBtnDisabledStatus(false)
                    const val = value.replace(/[^0-9]/g, "")
                    setVendorDetails({
                      ...vendorDetails,
                      pointOfContact: {
                        ...vendorDetails.pointOfContact,
                        contactNumber: val,
                      }
                    })
                    setIsFormDirty(true)
                    dispatch(resetFormModified(true));
                  }}
                />
              </div>
              <div className='mail-icon-vendor-input' style={{ marginRight: "45px" }}>
                <InputComponent
                  height={"40px"}
                  width={"300px"}
                  margin={"0px 0px 45px 0px"}
                  padding={"0px 40px 0px 10px"}
                  borderRadius={"0px"}
                  backgroundColor={"white"}
                  // disabled={isEditMode && (mainApiData?.status === "Pending Approval") ? true : false}
                  color={"black"}
                  type={"text"}
                  placeHolder={"Enter Email"}
                  inputTitle={"Email"}
                  required={true}
                  maxLength={254}
                  className={"gmail-input"}
                  inputField={"gmail"}
                  border={pointOfContactErrors?.email ? "1px solid red" : "1px solid #A9C3DC"}
                  inputValue={vendorDetails?.pointOfContact?.email}
                  getUser={(value: any) => {
                    setSaveBtnDisabledStatus(false)
                    const removeInitialSpace = value.replace(/^\s+/, "")
                    setVendorDetails({
                      ...vendorDetails,
                      pointOfContact: {
                        ...vendorDetails.pointOfContact,
                        email: removeInitialSpace,
                      }
                    })
                    // setIconStatus({
                    //   ...iconStatus,
                    //   businessIcon: "selected",
                    // })
                    setIsFormDirty(true)
                    dispatch(resetFormModified(true));
                  }}
                />
              </div>

            </div>
            <div className='form-main-admin'>
              <div style={{ marginRight: "45px" }}>
                <InputComponent
                  height={"40px"}
                  width={"300px"}
                  margin={"0px 0px 30px 0px"}
                  padding={"0px 0px 0px 10px"}
                  border={pointOfContactErrors.designation ? "1px solid red" : "1px solid #A9C3DC"}
                  borderRadius={"0px"}
                  backgroundColor={"white"}
                  placeHolder={"Enter Designation"}
                  // disabled={isEditMode && (mainApiData?.status === "Pending Approval") ? true : false}
                  color={"black"}
                  type={"text"}
                  inputTitle={"Designation"}
                  maxLength={100}
                  required={false}
                  inputValue={vendorDetails?.pointOfContact?.designation}
                  getUser={(value: any) => {
                    setSaveBtnDisabledStatus(false)
                    const val = value.replace(/^\s+/, "");

                    setVendorDetails({
                      ...vendorDetails,
                      pointOfContact: {
                        ...vendorDetails.pointOfContact,
                        designation: val,
                      }
                    })
                    setIsFormDirty(true)
                    dispatch(resetFormModified(true));
                  }}
                />
              </div>
            </div>
          </div>
          <div>
            <div className='footer-company'>
              <ButtonComponent
                title={"Cancel"}
                height={"50px"}
                width={"150px"}
                margin={"0px 8px"}
                backgroundColor={"#888888"}
                color={"white"}
                className={"button-component-hover cancel"}
                handleClick={isFormModified ? () => { setShowChangesModal(true) } : returnGird}
              />
              <ButtonComponent
                title={"Previous"}
                height={"50px"}
                width={"150px"}
                disabled={false}
                backgroundColor={"#0055D4"}
                color={"white"}
                margin={"0px 8px"}
                className={"button-component-hover common-btn"}
                handleClick={() => { setShowPage('StatutoryDetails') }}
              />
              <ButtonComponent
                // title={isEditMode ? "Update" : "Save"}
                title={"Next"}
                height={"50px"}
                width={"150px"}
                // disabled={saveBtnDisabledStatus}
                backgroundColor={"#0055D4"}
                color={"white"}
                margin={"0px 8px"}
                // className={saveBtnDisabledStatus ? "button-component disabled" : "button-component-hover common-btn"}
                className={"button-component-hover common-btn"}
                handleClick={async () => {
                  if (vendorDetails?.basicDetails?.merchantId == "") {
                    const isFormValid = validateForm();
                    if (isFormValid) {
                      if (await duplicateCheck()) {
                        setIsFormDirty(false);
                        saveVendorDatas()
                        setShowPage('Address')
                        setSaveBtnDisabledStatus(true)
                      }
                    } else {
                      setShowAlertBox(true);
                      setShowType("danger");
                      setShowMessage(Object.values(updatedIsValueValidAll).every((values) => !values) ?
                        "Some of the field(s) are not in required format." : "Mandatory field(s) should not be left blank.")
                      clearAlert('');
                    }
                  } else {
                    setShowPage('Address')
                  }

                }}
              />
              {/* <ButtonComponent
                title={"Next"}
                height={"50px"}
                width={"150px"}
                disabled={showMessage && showType === "success" ? true : false}
                backgroundColor={"#0055D4"}
                color={"white"}
                margin={"0px 8px"}
                className={
                  showMessage && showType === "success" ?
                    "button-component-hover disabled" : "button-component-hover common-btn"}
                handleClick={next}
              /> */}
            </div>
          </div>
        </div>
        {
          showAlertBox &&
          <div className='alert-warp'>
            <AlertBox type={showType} message={showMessage} />
          </div>
        }
        {
          showChangesModal &&
          <Changesmodal
            closeModal={() => setShowChangesModal(false)}
            handleClose={() => {
              setShowChangesModal(false);
            }}
            leavePage={() => returnGird()} />
        }
      </div>
    </>
  )
})
export default PointOfContact