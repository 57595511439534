
import React, { useEffect, useRef, useState } from 'react'
import IconButton from '../../common-component/form-elements/icon-button';
import MainPageCard from '../../common-component/cards/main-page-card';
import NavigateHeader from '../../common-component/navigate-header';
import '../../../styles/approval/excise-approval/excise-grid.scss'
import { v4 as uuidv4 } from 'uuid'
import { excisePaymentColumnData, rowDatathis } from '../../../config/payable/excise-payment/excise-payment-config-aggrid';
import AgGrid, { AgGridRef } from '../../common-component/grids-and-tables/ag-grid'
import GridFilter from '../../common-component/grids-and-tables/grid-filter';
import { Filter } from '../../../helpers/filtterFunction'
import CustomEllipsisRenderer from '../../../config/product-admin/users/users-aggrid-column-config';
import { DateConverterAgGrid } from "../../../helpers/capitalizetext";
import cellEyeIcon from "../../../components/common-component/grids-and-tables/cell-eye-icon";
import GstViewChallanModal from '../../common-component/modals/gst-view-challan-modal';
import { axiosPrivate } from '../../../middleware/axois-api';
import ButtonComponent from '../../common-component/form-elements/button-component';
import AlertBox from '../../common-component/alert-box';
import Loading from '../../common-component/modals/loading-screen';

interface Props {
  excisePaymentView: (val: any) => void;
  addMode: () => void;
  getEditData: (nav: any, data: any) => void;
  transaction: (val: any) => void;
  makePaymentDataFun: (val: any) => void;
  getEditMode: (value: boolean) => void;
  modeFunction: (val: boolean) => void;


}
interface IFilterValue {
  [key: string]: {
    columnName: string;
    filterType: string;
    filterWord: string;
  };
}
function ExcsieApprovalGrid({ excisePaymentView, getEditMode, modeFunction, transaction, makePaymentDataFun, getEditData }: Props) {


  const companyDownloadRef = useRef<AgGridRef>(null);
  const [filter, setFilter] = useState(false);
  const [filterRestore, setFilterRestore] = useState<IFilterValue>({})
  const [filterMethod, setFilterMethod] = useState("")
  const [curruntData, setCurruntData] = useState<any>([]);
  const [rowData, setRowData] = useState<any>([]);
  const [orginalRowData, setOrginalRowData] = useState<any>([]);
  const [rowExpandDataExpand, setRowExpandDataExpand] = useState<any>([]);
  const [gstEyeShowModal, setGstEyeShowModal] = useState<boolean>(false);
  const [totalAmountData, setTotalAmountData] = useState('');
  const [mainChallanDetails, setMainChallanDetails] = useState([]);
  const [approvalStatus, setApprovalStatus] = useState([]);
  const [mainData, setMainData] = useState('');
  const [currentData, setCurrentData] = useState([]);

  const [showType, setShowType] = useState("warning") // error message showType
  const [showMessage, setShowMessage] = useState<string>("") // error message showMessage
  const [showAlertBox, setShowAlertBox] = useState(false) // error message ShowAlertBox
  const [loadingScreen, setLoadingScreen] = useState(false);


  //functions

  const handleFilter = (value: any, filterType: string) => {
    Filter(value, filterType, orginalRowData, setRowData)
  }
  const calculateTotal = () => {
    let sum = 0;
    rowExpandDataExpand.forEach((item: any) => {
      const total = parseFloat(item?.Total.replace(/,/g, ''));
      sum += total;
    });
    return sum.toFixed(2); // Round to two decimal places if needed
  };

  const formatAmountWithCommas = (amount: any) => {
    return parseFloat(amount).toLocaleString('en-US', {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };
  const handleDownloadExcel = () => {
    companyDownloadRef.current!.onBtExport();
  }
  //useEffects

  useEffect(() => {
    const total = calculateTotal();
    const formattedTotal = formatAmountWithCommas(total);
    setTotalAmountData(formattedTotal)
  }, [rowExpandDataExpand]);

  useEffect(() => {
    getExciseDataMainGrid()
  }, []);

  async function getExciseDataMainGrid() {
    try {
      const response = await axiosPrivate.get('company-admin/excise-payable/get-excide-all-details');
      setRowData(response.data.decryptedPayableDetails);
      setOrginalRowData(response.data.decryptedPayableDetails)
    } catch (error) {
    }
  }

  async function getUserData(data: any) {
    modeFunction(true)
    getEditData && getEditData("excise-form", data);

  }

  const clearAlert = (status: any) => {
    const timer = setTimeout(() => {
      setShowAlertBox(false);
      setShowMessage("");
      clearTimeout(timer);
    }, 5000);
  }

  async function makePayment() {
    setLoadingScreen(true)
    const isPaidChallan = currentData.some((item: any) => item.PaymentStatus === 'Paid');
    if (isPaidChallan) {
      setShowAlertBox(true)
      setLoadingScreen(false)
      setShowType('danger')
      setShowMessage("Selected Challan has already been paid.");
      clearAlert('');
    } else {
      const data = {
        payableExciseIds: currentData.map((val: any) => {
          return (val?.payableExciseId);
        })
      };
      // setMakePaymentData(data)
      makePaymentDataFun(data)
      await axiosPrivate.post(`excise-payable/multiple/records`, data)
        .then((response: any) => {
          setLoadingScreen(false)
          transaction(response.data.decryptedExcisePayableDetails)
          excisePaymentView("form");
        })
        .catch(error => {
          setLoadingScreen(false)
          setShowType('danger')
          setShowMessage(`Error : ${error}`);
          clearAlert('');
          console.error(error);
        });
    }

  }



  return (
    <MainPageCard>
      <div className='header-grid-gst'>
        <div className='left-header'>
          <NavigateHeader firstValue={"Approval"}
            secondValue={"Excise Payment"} />
        </div>
        <IconButton
          iconName={"Filter"}
          height={"40px"}
          width={"40px"}
          fontSize={"30px"}
          margin={"0px 8px"}
          color={"white"}
          backgroundColor={"#0055D4"}
          hover={true}
          handleClick={(event: React.MouseEvent) => {
            event?.stopPropagation()
            setFilter(!filter);
          }}
        />
        <IconButton
          iconName={"Delete"}
          height={"40px"}
          width={"40px"}
          fontSize={"25px"}
          margin={"0px 8px"}
          color={"white"}
          disabled={true}
          opacity={"0.5"}
          cursor={"not-allowed"}
          backgroundColor={"#D60000"}
          hover={curruntData.length > 0}
          handleClick={() => {
          }}
        />
        <IconButton
          iconName={"Add"}
          height={"40px"}
          width={"75px"}
          fontSize={"25px"}
          margin={"0px 8px"}
          color={"white"}
          backgroundColor={"#0055D4"}
          hover={false}
          disabled={true}
          opacity={"0.5"}
          cursor={"not-allowed"}
          handleClick={() => {
            // excisePaymentView("form");
            // addMode();
            // handleTransactionGenerate()
          }}
        />
        <IconButton
          iconName={"Upload"}
          height={"40px"}
          width={"40px"}
          fontSize={"25px"}
          margin={"0px 0px 0px 8px"}
          color={"white"}
          backgroundColor={"#0055D4"}
          hover={false}
          disabled={true}
          opacity={"0.5"}
          cursor={"not-allowed"}
          handleClick={() => {
          }}
        />
        <IconButton
          iconName={"Download"}
          height={"40px"}
          width={"40px"}
          fontSize={"25px"}
          margin={"0px 8px"}
          color={"white"}
          backgroundColor={"#0055D4"}
          hover={true}
          handleClick={() => {
            handleDownloadExcel()
          }}
        />
      </div>
      <div className='excise-payment-group-grid'>
        <AgGrid
          tabelRowData={rowData}
          tableColumnData={excisePaymentColumnData}
          ref={companyDownloadRef}
          fileName={"GSTApprovalData"}
          getCurruntData={(val) => {
            setCurrentData(val);
          }}
          doubleClickUpdate={() => {
            modeFunction(false);
          }}
          getEdit={(data: any) => {
            getUserData(data)
          }}
        />
      </div>
      {/* {
                rowExpandDataExpand && rowExpandDataExpand.length > 0 ?
                    <div className='gst-individual-grid'>
                        <div className='challan-area'>
                            <div className='challan-text'>Total Challan: {rowExpandDataExpand ? rowExpandDataExpand.length : 0}</div>
                            <div className='total-text'>Total Amount: ₹{totalAmountData}</div>
                        </div>
                        <div className='gst-grid-two'>
                            <AgGrid
                                fileName={"GST Challan"}
                                tabelRowData={rowExpandDataExpand}
                                tableColumnData={gstExpandColumnData}
                                hidePaginationDD={true}
                                doubleClickUpdate={() => {
                                }}
                            />
                        </div>
                    </div>
                    :
                    ""
            } */}
      <div className='footer-gst-makepayment'>
        <div style={currentData && currentData.length > 1 ? {} : { cursor: "not-Allowed" }}>
          <ButtonComponent
            title={"Make Payment"}
            height={"50px"}
            width={"150px"}
            margin={"0px 8px"}
            backgroundColor={"#0055D4"}
            color={"white"}
            disabled={currentData && currentData.length > 1 ? false : true}
            className={currentData && currentData.length > 1 ? "button-component-hover common-btn" : "button-component-hover disabled"}
            handleClick={() => {
              makePayment()

            }}
          />
        </div>


      </div>

      {
        filter &&
        <div className='filter' key={uuidv4()}>
          <GridFilter
            filterRestore={filterRestore}
            filterMethod={filterMethod}
            tabelRowData={rowData}
            data={excisePaymentColumnData.slice(1)}
            handleClick={(Value: any, filterType: string) => {
              if (filterType !== "") {
                setFilterRestore(Value)
                setFilter(!filter)
                setFilterMethod(filterType)
                handleFilter(Value, filterType)
                setCurruntData([]);

              } else {
                setFilterMethod("")
                setFilterRestore({})
                setFilter(!filter)
                setRowData(orginalRowData)
                setCurruntData([]);
              }
            }}
          />
        </div>
      }
      {
        showAlertBox &&
        <div className='alert-warp'>
          <AlertBox type={showType} message={showMessage} />
        </div>

      }
      {
        loadingScreen &&
        <div className='alert-warp'>
          <Loading />
        </div>

      }
      {/* {
                gstEyeShowModal &&
                <div className='alert-warp'>
                    <GstViewChallanModal
                        clearValue={() => {
                            setGstEyeShowModal(false)
                        }}
                        mainChallanDetails={mainChallanDetails}
                        mainData={mainData}
                        approvalStatus={approvalStatus}

                    />
                </div>
            } */}
    </MainPageCard >
  )
}

export default ExcsieApprovalGrid