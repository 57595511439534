import React, { useEffect, useState } from 'react'
import logo from '../../assets/images/loginlogo.png'
import '../../styles/documentation/documentation.scss'
import InputComponent from '../../components/common-component/form-elements/input-component';
import { ReactComponent as Lessthan } from '../../assets/icons/lessarrow.svg'
import { ReactComponent as Signup } from '../../assets/icons/docSignup.svg'
import { ReactComponent as Organisation } from '../../assets/icons/docorganisatiom.svg'
import { ReactComponent as Company } from '../../assets/icons/doccompany.svg'
import { ReactComponent as Kyc } from '../../assets/icons/docKYC-1.svg'
import { ReactComponent as CustomerOnboarding } from '../../assets/icons/docKYC.svg'
import { ReactComponent as User } from '../../assets/icons/docusermanagement.svg'
import { ReactComponent as Bank } from '../../assets/icons/docbank.svg'
import { ReactComponent as Card } from '../../assets/icons/doccard.svg'
import { ReactComponent as VirtualCard } from '../../assets/icons/docvirtualcard.svg'
import { ReactComponent as Vendor } from '../../assets/icons/docvendor.svg'
import { ReactComponent as Customer } from '../../assets/icons/doccustomer.svg'
import { ReactComponent as Payable } from '../../assets/icons/docpayable.svg'
import { ReactComponent as BulkPayment } from '../../assets/icons/docbulkpayment.svg'
import { ReactComponent as Notification } from '../../assets/icons/docnotification.svg'
import { ReactComponent as Filter } from '../../assets/icons/docfilter.svg'
import { ReactComponent as Dashboard } from '../../assets/icons/docdashboard.svg'
import { ReactComponent as Report } from '../../assets/icons/docreport.svg'
import { ReactComponent as Link } from '../../assets/icons/linkicon.svg'
import { ReactComponent as BankAdmin } from '../../assets/icons/bankadminicon.svg'
import { ReactComponent as CompanyAdmin } from '../../assets/icons/companyadminicon.svg'
import { ReactComponent as Maker } from '../../assets/icons/makericon.svg'
import { ReactComponent as Checker } from '../../assets/icons/checkericon.svg'
import { ReactComponent as Recommender } from '../../assets/icons/recommendericon.svg'
import { ReactComponent as Approver } from '../../assets/icons/approvericon.svg'
import { ReactComponent as MasterDataCreator } from '../../assets/icons/masterdatacreator.svg'
import { ReactComponent as MasterDataApprover } from '../../assets/icons/masterdataapprover.svg'
import { ReactComponent as ReportUser } from '../../assets/icons/reportuser.svg'
import { ReactComponent as Email } from '../../assets/icons/gmail.svg'
import { ReactComponent as Mobile } from '../../assets/icons/phonenumber.svg'
// import { ReactComponent as UseCase } from '../../assets/icons/usecase.svg'
import { ReactComponent as GetFamiliar } from '../../assets/icons/getfamiliar.svg'
import { ReactComponent as Support } from '../../assets/icons/contactus.svg'
import { ReactComponent as Faq } from '../../assets/icons/faq.svg'
import { ReactComponent as ProductAdmin } from '../../assets/icons/productadminicon.svg'

// import vtt from '../../assets/vtt/signup.vtt'
import { MdPlayArrow, MdExpandMore } from 'react-icons/md';

import { Navigate, useNavigate } from 'react-router-dom';
import { HiArrowNarrowRight } from 'react-icons/hi';
interface Props {
    documentationNav: string;
    setDocumentationNav: (val: string) => void;
}

function Documentation({ documentationNav, setDocumentationNav }: Props) {

    const [selectedName, setSelectedName] = useState("")


    function handleSignup(iconName: any) {
        setSelectedName(iconName)
    }

    function dashboard() {
        navigate("/dashboard")
        setDocumentationNav("")
    }
    function documentation() {
        navigate("/documentation")
    }

    const navigate = useNavigate();
    const userGuidesData = [
        { name: 'Sign Up', id: "1", icon: <Signup />, text: 'Activate your account and manage your password and profile.' },
        { name: 'Organisation', id: "2", icon: <Organisation />, text: 'Manage records of partner organizations.' },
        { name: 'Company', id: "3", icon: <Company />, text: 'Create and manage corporate and SME records.' },
        { name: 'KYC', id: "4", icon: <Kyc />, text: 'Upload your KYC documents for verification.' },
        { name: 'Customer Onboarding', id: "5", icon: <CustomerOnboarding />, text: 'Onboard customers and welcome them aboard!' },
        { name: 'User Management', id: "6", icon: <User />, text: 'Manage your users and their roles in this platform!' },
        { name: 'Bank', id: "7", icon: <Bank />, text: 'Add and manage your bank accounts.' },
        { name: 'Card', id: "8", icon: <Card />, text: 'Add and manage your commercial cards.' },
        { name: 'Virtual Card', id: "9", icon: <VirtualCard />, text: 'Generate virtual cards to make your payments with enhanced security.' },
        { name: 'Vendor', id: "10", icon: <Vendor />, text: 'Manage your vendor records efficiently.' },
        { name: 'Customer', id: "11", icon: <Customer />, text: 'Streamline the addition and management of customer records.' },
        { name: 'Payable', id: "12", icon: <Payable />, text: 'Streamline vendor payments with increased efficiency and accuracy.' },
        { name: 'Bulk Uploads', id: "13", icon: <BulkPayment />, text: 'Process your records efficiently with  bulk upload feature.' },
        { name: 'Notification', id: "14", icon: <Notification />, text: 'Get real-time updates delivered to your personalized channel.' },
        { name: 'Grid Filter Component', id: "15", icon: <Filter />, text: 'Get empowered with filters and refine your data!' },
        { name: 'Dashboard', id: "16", icon: <Dashboard />, text: 'A centralized and real-time visual representation of your data!' },
        { name: 'Reports', id: "17", icon: <Report />, text: 'Detailed summaries of data for your analysis!' },
    ];

    const userPriviledgeData = [
        { name: 'Product Admin', icon: <Signup />, text: 'Manage organizations and manage creation and onboarding of corporates and SMEs.' },
        { name: 'Bank Admin', icon: <BankAdmin />, text: 'Manage corporates and SMEs under your organization.' },
        { name: 'Company Admin', icon: <CompanyAdmin />, text: 'Submit your KYC proofs, manage your users and do many more.' },
        { name: 'Maker', icon: <Maker />, text: 'Initiate vendor addition, virtual card generation, vendor payments, and other types of payments for your company.' },
        { name: 'Checker', icon: <Checker />, text: 'Verify all types of payment requests and forward them for approval.' },
        { name: 'Recommender', icon: <Recommender />, text: 'Add a second layer of verification for high value transactions.' },
        { name: 'Approver', icon: <Approver />, text: 'Approve payment requests and complete transactions.' },
        { name: 'Master Data Creator', icon: <MasterDataCreator />, text: "Manage your company's master data with ease." },
        { name: 'Master Data Approver', icon: <MasterDataApprover />, text: "Verify and approve your company's master data with ease." },
        { name: 'Report User', icon: <ReportUser />, text: 'Empower users with privileges to generate different types of reports. ' },
    ];

    const userGuides = userGuidesData.map((iconData, index) => (
        <a href={`#${iconData.id}`} key={index} style={{ textDecoration: "none" }}>
            <div className='icon-main-doc' key={index} onClick={() => handleSignup(iconData.name)}>
                <div>{iconData.icon}</div>
                <div className='icon-text'>
                    <p className='icon-heading'>{iconData.name}</p>
                    <p style={{ color: "black" }}>{iconData.text}</p>
                </div>
            </div>
        </a >
    ));

    const userPriviledge = userPriviledgeData.map((iconData, index) => (
        <div className='icon-main-doc' key={index} onClick={() => handleSignup(iconData.name)}>
            <div> {iconData.icon}</div>
            <div className='icon-text'>
                <p className='icon-heading'>{iconData.name}</p>
                <p style={{ color: "black" }}>{iconData.text}</p>
            </div>
        </div>
    ));

    const SignupLinks = [
        "Setup",
        "Login",
        "Forgot Password",
        "Set PIN",
        "Two Factor Authentication",
        "View current Password / PIN",
        "Reset current Password / PIN",
        "Set Profile Photo",
    ];
    const OrganisationLinks = [
        "Add Organisation",
        "Edit Organisation",
        "Delete Organisation",
    ];
    const CompanyLinks = [
        "Add Company",
        "Edit Company",
        "Delete Company",
        "Add Department",
        "Add Account Manager",
        "Set Single User Approval Process",
        "Set Group User Approval Process",
        "Set Subscription Validity",
    ];
    const KycLinks = [
        "Upload KYC",
        "Resubmit the declined KYC document",
    ];
    const CustomerOnboardingLinks = [
        "Approve individual document",
        "Decline individual document",
        "Approve KYC",
        "Decline KYC",
        "Generate License Key",
    ];
    const UserManagementLinks = [
        "Add Fingertip User",
        "Add Organisation User",
        "Add Company User",
    ];
    const BankLinks = [
        "Add Bank",
        "Decline Bank",
        "Approve Bank",
    ];
    const CardLinks = [
        "Add Card",
        "Decline Card",
        "Approve Card",
    ];
    const VirtualCardLinks = [
        "Add Virtual Card",
        "Decline Virtual Card",
        "Approve Virtual Card",
    ];
    const VendorLinks = [
        "Add Vendor",
        "Decline Vendor",
        "Approve Vendor",
        "Decline Vendor by Bank admin",
        "Approve Vendor by Bank admin",
    ];
    const CustomerLinks = [
        "Add Customer",
        "Decline Customer",
        "Approve Customer",
    ];
    const PayableLinks = [
        "Add Invoice",
        "Decline Invoice",
        "Approve Invoice",
        "Make Payment",
        "Make Bulk Payment",
    ];
    const BulkUploadLinks = [
        "Bulk Upload Fingertip User",
        "Bulk Upload Organisation User",
        "Bulk Upload Company User",
        "Bulk Upload Company",
        "Bulk Upload Customer",
        "Bulk Upload Vendor",
        "Bulk Upload Invoice",
    ];
    const NotificationLinks = [
        "Notification",
    ];
    const FilterLinks = [
        "Filter",
    ];
    const DashboardLinks = [
        "Dashboard",
    ];
    const ReportsLinks = [
        "Reports",
    ];
    const FamiliarLink = [
        "Home screen",
        "Set up for personal preferences",
        "Navigation across Fingertip's platform",
        "Data entry",
        "Reports",
    ];
    const FaqLink = [
        "Is Fingertip's PCI-DSS compliant?",
        "Where can I find Fingertip's PCI Certification?",
        "How much does Fingertip's charge per transaction?",
        "A Vendor payment status is marked as 'failed' on my Vendor payment grid view but money is debited from the customer’s account. What do I do?",
        "I have submitted my KYC form. When will my company get activated?",
    ];

    function handleSignupLink(text: any, module: any) {
        navigate("/documentation/videos", { state: { title: text, main: module } })
    };

    return (
        <div className='responsive-doc'>
            <div>
                <div className='navigator-icon-wrapper'>
                    <div className='navigator-main'>
                        <div onClick={dashboard} className='navigator-main-title'>FingerTip</div><Lessthan style={{ marginTop: "2px" }} />
                        <div className='navigator-title' onClick={documentation}><a href='#0' style={{ textDecoration: "none", color: "white" }}>Documentation</a></div>
                        {/* <Lessthan style={{ marginTop: "2px" }} /> */}
                        {/* {
                        selectedName !== "" ?
                            <div className='navigator-title'>{selectedName}</div>
                            :
                            ""
                    } */}


                    </div>
                    <div className='right-icons-doc'>
                        <a href='#18'><GetFamiliar />{"Get Familiar"}</a>
                        <a href='#20'><Faq />{"FAQ"}</a>
                        <a href='#21'><Support />{"Support"}</a>
                    </div>
                </div>
            </div>
            <div>
                <div className='background-image-doc'>
                    <div>
                        <div className='documentation-container' id='0'>
                            <div className='welcome-text'>Welcome to Fingertip Documentation</div>
                            <div style={{ marginTop: "10px" }}>Find User guide sample videos and more</div>
                            <div className='featured-container'>
                                <div className='featured-text'>User Guide</div>
                                <div className='wrapper-doc'>
                                    {userGuides}
                                </div>
                            </div>
                            <div className='featured-container'>
                                <div className='featured-text'>User stories</div>
                                <div className='wrapper-doc'>
                                    {userPriviledge}
                                </div>
                            </div>
                        </div>

                        <div className='signup-background' id="1">
                            <div className='signup-container'  >
                                <div className='signup-head'>{"Sign Up"}</div>
                                <div className='signup-para'>{"Get to know how you can manage your essential tasks like setting up your account password, log-in process, recovering forgotten password, configuring a security PIN, and personalizing your two factor authentication and your their profile with a photo. It's the gateway to a secure and personalized financial experience within the application."}</div>
                                <div className='link-wrapper'>
                                    <div>
                                        {"QUICK LINKS"}
                                    </div>
                                    <div className="links-nav">
                                        {SignupLinks.map((text, index) => (
                                            <div style={{ cursor: "pointer" }} key={index} onClick={() => handleSignupLink(text, "Signup")}>
                                                <Link fill='#fff' />
                                                <span className="text-decoration">{text}</span>
                                            </div>
                                        ))}
                                    </div>
                                </div>

                            </div>

                        </div>

                        <div className='organisation-background'>
                            <div className='organisation-container' id="2" >
                                <div className='signup-head'>{"Organisation"}</div>
                                <div className='signup-para'>{"Effortlessly add, edit, or remove organization records with simple steps and provide them access to manage companies under them."}</div>
                                <div className='link-wrapper'>
                                    <div>
                                        {"QUICK LINKS"}
                                    </div>
                                    <div className="links-nav">
                                        {OrganisationLinks.map((text, index) => (
                                            <div style={{ cursor: "pointer" }} key={index} onClick={() => handleSignupLink(text, "Organisation")}>
                                                <Link fill='#0046FE' />
                                                <span style={{ color: "#0046FE" }} className="text-decoration">{text}</span>
                                            </div>
                                        ))}
                                    </div>
                                </div>

                            </div>

                        </div>

                        <div className='company-background'>
                            <div style={{ width: "50%" }}></div>
                            <div className='company-container' id='3'>
                                <div className='signup-head'>{"Company"}</div>
                                <div className='signup-para'>{"Efficiently manage companies, handle tasks like setting up departments and account manager. Configure single or group user approval process, set-up subscription validity to maintain organizational control and compliance and start your journey with Fingertip platform."}</div>
                                <div className='link-wrapper'>
                                    <div>
                                        {"QUICK LINKS"}
                                    </div>
                                    <div className="links-nav">
                                        {CompanyLinks.map((text, index) => (
                                            <div style={{ cursor: "pointer" }} key={index} onClick={() => handleSignupLink(text, "Company")}>
                                                <Link fill='#fff' />
                                                <span className="text-decoration">{text}</span>
                                            </div>
                                        ))}
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className='kyc-background'>
                            <div className='kyc-container' id='4'>
                                <div className='signup-head'>{"KYC"}</div>
                                <div className='signup-para'>{"Upload your KYC documents to ensure compliance verification. With seamless communication with the verification team, address the needs and complete the KYC process with ease."}</div>
                                <div className='link-wrapper'>
                                    <div>
                                        {"QUICK LINKS"}
                                    </div>
                                    <div className="links-nav">
                                        {KycLinks.map((text, index) => (
                                            <div style={{ cursor: "pointer" }} key={index} onClick={() => handleSignupLink(text, "KYC")}>
                                                <Link />
                                                <span className="text-decoration">{text}</span>
                                            </div>
                                        ))}
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className='customer-onboarding-background'>
                            <div style={{ width: "50%" }}></div>
                            <div className='customer-onboarding-container' id='5'>
                                <div className='signup-head'>{"Customer Onboarding"}</div>
                                <div className='signup-para'>{"Efficiently verify and approve customer KYC documents and generate license keys to facilitate smooth and secure onboarding process for the customers."}</div>
                                <div className='link-wrapper'>
                                    <div>
                                        {"QUICK LINKS"}
                                    </div>
                                    <div className="links-nav">
                                        {CustomerOnboardingLinks.map((text, index) => (
                                            <div style={{ cursor: "pointer" }} key={index} onClick={() => handleSignupLink(text, "CustomerOnboarding")}>
                                                <Link fill='#fff' />
                                                <span className="text-decoration">{text}</span>
                                            </div>
                                        ))}
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className='user-background'>
                            <div className='user-container' id="6" >
                                <div className='signup-head'>{"User Management"}</div>
                                <div className='signup-para'>{"Streamline user operations with user creation, editing, and deletion functionalities, enhancing control and security. Set them up with different roles and utilize all features of this platform."}</div>
                                <div className='link-wrapper'>
                                    <div>
                                        {"QUICK LINKS"}
                                    </div>
                                    <div className="links-nav">
                                        {UserManagementLinks.map((text, index) => (
                                            <div style={{ cursor: "pointer" }} key={index} onClick={() => handleSignupLink(text, "UserManagement")}>
                                                <Link fill='#0046FE' />
                                                <span className="text-decoration" style={{ color: "#0046FE" }}>{text}</span>
                                            </div>
                                        ))}
                                    </div>
                                </div>

                            </div>

                        </div>

                        <div className='bank-background'>
                            <div className='bank-container' id='7'>
                                <div className='signup-head'>{"Bank"}</div>
                                <div className='signup-para'>{"Effortlessly manage your bank accounts and receive funds from your customers ensuring accuracy and reliability in financial operations."}</div>
                                <div className='link-wrapper'>
                                    <div>
                                        {"QUICK LINKS"}
                                    </div>
                                    <div className="links-nav">
                                        {BankLinks.map((text, index) => (
                                            <div style={{ cursor: "pointer" }} key={index} onClick={() => handleSignupLink(text, "Bank")}>
                                                <Link fill='#fff' />
                                                <span className="text-decoration">{text}</span>
                                            </div>
                                        ))}
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className='card-background'>
                            <div style={{ width: "50%" }}></div>
                            <div className='card-container' id='8'>
                                <div className='signup-head'>{"Card"}</div>
                                <div className='signup-para'>{"Effortlessly manage your commercial cards, make payments and get intelligent recommendations and insights from the platform."}</div>
                                <div className='link-wrapper'>
                                    <div>
                                        {"QUICK LINKS"}
                                    </div>
                                    <div className="links-nav">
                                        {CardLinks.map((text, index) => (
                                            <div style={{ cursor: "pointer" }} key={index} onClick={() => handleSignupLink(text, "Card")}>
                                                <Link />
                                                <span className="text-decoration">{text}</span>
                                            </div>
                                        ))}
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className='virtual-card-background'>
                            <div className='virtual-card-container' id="9" >
                                <div className='signup-head'>{"Virtual Card"}</div>
                                <div className='signup-para'>{"Manage requests, approval and generation of virtual cards for single or multi use payments with enhanced security and protection of primary cards."}</div>
                                <div className='link-wrapper'>
                                    <div>
                                        {"QUICK LINKS"}
                                    </div>
                                    <div className="links-nav">
                                        {VirtualCardLinks.map((text, index) => (
                                            <div style={{ cursor: "pointer" }} key={index} onClick={() => handleSignupLink(text, "VirtualCard")}>
                                                <Link fill='#fff' />
                                                <span className="text-decoration">{text}</span>
                                            </div>
                                        ))}
                                    </div>
                                </div>

                            </div>

                        </div>

                        <div className='vendor-background'>
                            <div style={{ width: "50%" }}></div>
                            <div className='vendor-container' id='10'>
                                <div className='signup-head'>{"Vendor"}</div>
                                <div className='signup-para'>{"Efficiently add, manage, and seek banker approval for new vendors, ensuring seamless vendor relationship management and compliance."}</div>
                                <div className='link-wrapper'>
                                    <div>
                                        {"QUICK LINKS"}
                                    </div>
                                    <div className="links-nav">
                                        {VendorLinks.map((text, index) => (
                                            <div style={{ cursor: "pointer" }} key={index} onClick={() => handleSignupLink(text, "Vendor")}>
                                                <Link />
                                                <span className="text-decoration">{text}</span>
                                            </div>
                                        ))}
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className='customer-background'>
                            <div className='customer-container' id="11" >
                                <div className='signup-head'>{"Customer"}</div>
                                <div className='signup-para'>{"Streamline the addition and management of customer records to facilitate seamless account receivable processes, enhancing financial efficiency and customer relationship management."}</div>
                                <div className='link-wrapper'>
                                    <div>
                                        {"QUICK LINKS"}
                                    </div>
                                    <div className="links-nav">
                                        {CustomerLinks.map((text, index) => (
                                            <div style={{ cursor: "pointer" }} key={index} onClick={() => handleSignupLink(text, "Customer")}>
                                                <Link fill='#fff' />
                                                <span className="text-decoration">{text}</span>
                                            </div>
                                        ))}
                                    </div>
                                </div>

                            </div>

                        </div>

                        <div className='payable-background'>
                            <div className='payable-container' id="12" >
                                <div className='signup-head'>{"Payable"}</div>
                                <div className='signup-para'>{"With single or multi-user (maker-checker-recommender-approver) workflow, upload invoices individually or in bulk, follow the approval process and streamline vendor payments with increased efficiency and accuracy."}</div>
                                <div className='link-wrapper'>
                                    <div>
                                        {"QUICK LINKS"}
                                    </div>
                                    <div className="links-nav">
                                        {PayableLinks.map((text, index) => (
                                            <div style={{ cursor: "pointer", textAlign: "center" }} key={index} onClick={() => handleSignupLink(text, "Payable")}>
                                                <Link fill='#0046FE' />
                                                <span className="text-decoration" style={{ color: "#0046FE" }}>{text}</span>
                                            </div>
                                        ))}
                                    </div>
                                </div>

                            </div>

                        </div>

                        <div className='bulk-payment-background'>
                            <div style={{ width: "50%" }}></div>
                            <div className='bulk-payment-container' id='13'>
                                <div className='signup-head'>{"Bulk Uploads"}</div>
                                <div className='signup-para'>{"Simplify data entry with batch processing of data using the bulk upload template in the different modules of this platform."}</div>
                                <div className='link-wrapper'>
                                    <div>
                                        {"QUICK LINKS"}
                                    </div>
                                    <div className="links-nav">
                                        {BulkUploadLinks.map((text, index) => (
                                            <div style={{ cursor: "pointer" }} key={index} onClick={() => handleSignupLink(text, "BulkUploads")}>
                                                <Link fill='#fff' />
                                                <span className="text-decoration">{text}</span>
                                            </div>
                                        ))}
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className='notification-background'>
                            <div className='notification-container' id="14" >
                                <div className='signup-head'>{"Notification"}</div>
                                <div className='signup-para'>{"With notifications feature, you receive messages and emails with real-time updates, transaction confirmation, reminders, etc."}</div>
                                <div className='link-wrapper'>
                                    <div>
                                        {"QUICK LINKS"}
                                    </div>
                                    <div className="links-nav">
                                        {NotificationLinks.map((text, index) => (
                                            <div style={{ cursor: "pointer" }} key={index} >
                                                <Link />
                                                <span className="text-decoration">{text}</span>
                                            </div>
                                        ))}
                                    </div>
                                </div>

                            </div>

                        </div>

                        <div className='filter-background'>
                            <div style={{ width: "50%" }}></div>
                            <div className='filter-container' id='15'>
                                <div className='signup-head'>{"Grid Filter Component"}</div>
                                <div className='signup-para'>{"With filters on all dashboard components, sort and refine your data with single or multiple criteria. Save the filters set for future use as well!"}</div>
                                <div className='link-wrapper'>
                                    <div>
                                        {"QUICK LINKS"}
                                    </div>
                                    <div className="links-nav">
                                        {FilterLinks.map((text, index) => (
                                            <div style={{ cursor: "pointer" }} key={index} >
                                                <Link fill='#fff' />
                                                <span className="text-decoration">{text}</span>
                                            </div>
                                        ))}
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className='dashboard-background'>
                            <div className='dashboard-container' id="16" >
                                <div className='signup-head'>{"Dashboard"}</div>
                                <div className='signup-para'>{"A dashboard built for each user role with centralized and real-time visual representation of different data offering insights into transactions and reminders. Take greater control and informed decisions."}</div>
                                <div className='link-wrapper'>
                                    <div>
                                        {"QUICK LINKS"}
                                    </div>
                                    <div className="links-nav">
                                        {DashboardLinks.map((text, index) => (
                                            <div style={{ cursor: "pointer" }} key={index} >
                                                <Link style={{ fill: "black" }} />
                                                <span className="text-decoration">{text}</span>
                                            </div>
                                        ))}
                                    </div>
                                </div>

                            </div>

                        </div>

                        <div className='report-background'>
                            <div className='report-container' id="17" >
                                <div className='signup-head'>{"Report"}</div>
                                <div className='signup-para'>{"Use reports to get detailed summaries to enable your analysis, decision making, compliance, and performance assessment."}</div>
                                <div className='link-wrapper'>
                                    <div>
                                        {"QUICK LINKS"}
                                    </div>
                                    <div className="links-nav">
                                        {ReportsLinks.map((text, index) => (
                                            <div style={{ cursor: "pointer" }} key={index} >
                                                <Link fill='#fff' />
                                                <span className="text-decoration">{text}</span>
                                            </div>
                                        ))}
                                    </div>
                                </div>

                            </div>

                        </div>

                        <div className='get-familiar-background'>
                            <div style={{ width: "50%" }}></div>
                            <div className='get-familiar-container' id='18'>
                                <div className='signup-head'>{"Get Familiar"}</div>
                                <div className='signup-para'>{"Let's take a quick look at the features and functionalities of our platform. Going through the information below will help you to work efficiently in the Fingertip platform with minimal learning. You can always deep dive into a specific topic using our help documentation."}</div>
                                <div className='link-wrapper'>
                                    <div>
                                        {"QUICK LINKS"}
                                    </div>
                                    <div className="links-nav">
                                        {FamiliarLink.map((text, index) => (
                                            <div style={{ cursor: "pointer" }} key={index} >
                                                <Link fill='black' />
                                                <span className="text-decoration">{text}</span>
                                            </div>
                                        ))}
                                    </div>
                                </div>

                            </div>
                        </div>


                        <div className='faq-background'>
                            <div className='faq-container' id="20" >
                                <div className='signup-head'>{"Frequently Asked Questions"}</div>
                                <div className='signup-para'>{"We have curated a set of Product and Technical FAQs for you. Browse through these FAQs to find answers to commonly raised questions."}</div>
                                <div className='link-wrapper'>
                                    <div className="links-nav">
                                        {FaqLink.map((text, index) => (
                                            <div style={{ cursor: "pointer" }} key={index}>
                                                <Link fill='#fff' />
                                                <span className="text-decoration">{text}</span>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='support-background'>
                            <div className='support-container' id="21" >
                                <div className='signup-head'>{"Contact Us"}</div>
                                <div className='signup-para' style={{ marginTop: "30px" }}>{"We value your feedback, questions, and inquiries."}</div>
                                <div className='signup-para' style={{ marginTop: "30px" }}>{"If you have general questions about our company, partnerships, or other matters, or for any assistance or questions related to your account, transactions, or our services, we are here to help."}</div>
                                <div className='signup-para' style={{ marginTop: "30px" }}>{"You can reach us by"}</div>
                                <div className='signup-para' style={{ marginTop: "30px" }}><b>{"Address :"}</b>{" 11043, Tower 1 Building 1, Prestige Falcon City, Doddakallasandra, Bengaluru - 560062"}</div>

                                <div className={"support-system"} >
                                    <div>
                                        <Email />
                                        <p>bharani.narasimhan@thefingertip.in</p>
                                    </div>
                                    <div>
                                        <Mobile />
                                        <p>+91 - 7010151088</p>
                                    </div>
                                </div>

                                <div style={{ display: "flex", justifyContent: "center", marginTop: "50px" }}>
                                    <div className='button-overlay'><button className='get-in'><p>Send Request</p><Lessthan style={{ marginTop: "2px" }} /></button></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Documentation