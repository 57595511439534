import React, { useEffect, useState } from 'react'
import MainPageCard from '../common-component/cards/main-page-card';
import ButtonComponent from '../common-component/form-elements/button-component';
import InputComponent from '../common-component/form-elements/input-component';
import DropdownComponent from '../common-component/form-elements/dropdown-component';
import PaymentCard from '../../../src/assets/images/virtual_card.png';
import RadioOrCheckbox from '../common-component/form-elements/radio-or-checkbox';
import useAxiosPrivate from '../../services/hooks/useaxios-private';
import VendorCardPayment from './vendor-payment';
import Changesmodal from '../common-component/modals/changes-modal';
import AlertBox from '../common-component/alert-box';
import Loading from '../common-component/modals/loading-screen';
import { useDispatch } from "react-redux";
import { resetFormModified } from "../../redux/action";
import { BsPlusLg } from 'react-icons/bs';
import { v4 as uuidv4 } from 'uuid';
import VirtualCardForm from '../common-component/modals/virtualcard-form-model';
import { format } from "date-fns";
import useAuth from '../../services/hooks/useauth';
import moment from 'moment';
import Approvemodal from './approve-modal';
import '../../styles/virtualcard/form.scss'
import NavigateHeader from '../common-component/navigate-header';


interface Props {
    virtualCard: (val: string) => void;
    isEditMode: boolean;
    modeFunction: (val: boolean) => void;
    getEditMode: (value: boolean) => void;
    editData: any
}
export interface userValidationRulesInterface {
    [key: string]: {
        [key: string]: {
            regex: RegExp | string;
            field: string;
            shouldNotBe: string;
        };
    }
}

export default function Form({ virtualCard, getEditMode, isEditMode, editData, modeFunction }: Props) {
    const todayDate = moment(new Date()).format('YYYY-MM-DD');
    const currentyear = new Date().getFullYear();
    const [mindate, setMindate] = useState<string>(todayDate);
    const axiosPrivate = useAxiosPrivate();
    const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
    const [userDataErrors, setUserDataErrors] = useState<Record<string, boolean>>({});
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [showType, setShowType] = useState("warning") // error message showType
    const [showMessage, setShowMessage] = useState<string>("") // error message showMessage
    const [showAlertBox, setShowAlertBox] = useState(false) // error message ShowAlertBox
    const [showChangesModal, setShowChangesModal] = useState(false);
    const [showChangesModalNavigate, setShowChangesModalNavigate] = useState(false);
    const [isFormDirty, setIsFormDirty] = useState(false);
    const [navigatePage, setNavigatePage] = useState("");
    const [navigateIsEdited, setNavigateIsEdited] = useState(false);
    const [componentNavigator, setComponentNavigator] = useState<boolean>(false);
    const [employeeNameDD, setEmployeeNameDD] = useState<any>([])
    const [vendorDetailsDD, setVendorDetailsDD] = useState<any>([])
    const [primaryCardDD, setprimaryCardDD] = useState<any>([])
    const [employeeDetailsAll, setEmployeeDetailsAll] = useState<any>([])
    // const [vendarValueAll, setVendarValueAll] = useState<any>([]) 
    const [currrenSelectedVender, setcurrrenSelectedVender] = useState<any>({})
    const [currrenSelectedEmployee, setcurrrenSelectedEmployee] = useState<any>({})
    const [currentDate, setCurrentDate] = useState("");
    const [vendorCurrentDate, setVendorCurrentDate] = useState("");

    const [approvalStatus, setApprovalStatus] = useState<any>({
        status: "Approved",
        declineRemarks: ''
    });

    const [vendorForm, setVendorForm] = useState<any>({
        request: "Vendor's Payment",
        date: "",
        particulars: "",
        primaryAccountNo: "",
        primaryID: "",
        Transaction: "1",
        amount: "",
        Validfrom: "",
        Validupto: "",
        remarks: "",
        token: "",
        id: ""
    })


    const [virtualForm, setVirtualForm] = useState<any>({
        request: "Employee",
        date: currentDate,
        vendorName: '',
        employeeName: '',
        unicEmployeeId: '',
        unicVendorId: '',
        particulars: '',
        primaryAccountNo: '',
        primaryID: '',
        Transaction: "1",
        amount: '',
        Validfrom: "",
        Validupto: "",
        remarks: "",
        token: "",
        id: '',
        status: ''
    })
    const [empolyeeDetails, setEmpolyeeDetails] = useState({
        name: '',
        cardNumber: '',
        validTill: '',
        amount: '',
    });
    const [changeFormEmployee, setChangeFormEmployee] = useState<any>("Employee")
    const dispatch = useDispatch();
    const { auth } = useAuth();

    console.log(employeeDetailsAll, "121212122")
    const userValidationRules: userValidationRulesInterface = {
        virtualCard_form: {
            employeeName: {
                regex: "",
                field: "mandatory",
                shouldNotBe: ""
            },
            // vendorName: {
            //     regex: "",
            //     field: "mandatory",
            //     shouldNotBe: ""
            // },
            primaryAccountNo: {
                regex: "",
                field: "mandatory",
                shouldNotBe: ""
            },
            particulars: {
                regex: /^[a-zA-Z][a-zA-Z /]*$/,
                field: "mandatory",
                shouldNotBe: ""
            },

            amount: {
                regex: "",
                field: "mandatory",
                shouldNotBe: ""
            },
            Transaction: {
                regex: "",
                field: "mandatory",
                shouldNotBe: ""
            },
            Validfrom: {
                regex: "",
                field: "mandatory",
                shouldNotBe: ""
            },
            Validupto: {
                regex: "",
                field: "mandatory",
                shouldNotBe: ""
            },
            date: {
                regex: "",
                field: "",
                shouldNotBe: ""
            },

        }
    }
    const updatedIsValueValidAll: Record<string, boolean> = {};
    const validateForm = (): boolean => {
        const updatedFieldValidity: Record<string, boolean> = {};
        Object.keys(userValidationRules.virtualCard_form).forEach((field) => {
            const rule = userValidationRules.virtualCard_form[field];
            const value = virtualForm[field];
            // Check if the field value meets the conditions based on rule.field and rule.shouldNotBe
            const isValueValid = (rule.field === "mandatory") ? (value !== rule.shouldNotBe) : true;
            // If a regular expression is provided, test the field value against it
            const isRegexValid = rule.regex !== "" ? new RegExp(rule.regex).test(value) : true;
            updatedFieldValidity[field] = !isValueValid || !isRegexValid;
            updatedIsValueValidAll[field] = !isValueValid
        });
        setUserDataErrors(updatedFieldValidity);
        // Check if any field has validation errors
        const isFormValid = Object.keys(updatedFieldValidity).every((keys) => {
            return !updatedFieldValidity[keys]
        });
        return isFormValid;
    };

    const isDateValid = () => {
        const fromDate = new Date(virtualForm.Validfrom).setHours(0, 0, 0, 0);
        const toDate = new Date(virtualForm.Validupto).setHours(0, 0, 0, 0);
        if ((fromDate >= new Date().setHours(0, 0, 0, 0)) && (toDate >= fromDate)) {
            setUserDataErrors({
                Validfrom: false,
                Validupto: false,
            });

            return true;
        } else {
            return false;
        }
    };

    useEffect(() => {
        employeegetdata()
        primaryData()
        // VendorDropdown()
    }, [])

    useEffect(() => {
        setNavigateIsEdited(true)
    }, [virtualForm])

    useEffect(() => {
        if (!isEditMode) {
            setVirtualForm((prevState: any) => ({
                ...prevState,
                date: currentDate
            }));
        }
    }, [currentDate])

    // date function
    useEffect(() => {
        const interval = setInterval(() => {
            const newDate = new Date();
            // Reset time to midnight 
            const onlydate = format(newDate, "dd-MM-yyyy")
            setCurrentDate(onlydate);
            clearInterval(interval);
        }, 1000);  // Update every second

    }, []);


    useEffect(() => {
        console.log(editData)
        if (isEditMode && editData) {
            if (Object.keys(editData).length > 0) {
                setVirtualForm({
                    request: editData.isEmployeeRecord ? "Employee" : "Vendor's Payment",
                    date: editData.date,
                    vendorName: editData.vendorName,
                    unicVendorId: editData.unicVendorId,
                    employeeName: editData.employeeName,
                    unicEmployeeId: editData.unicEmployeeId,
                    particulars: editData.particulars,
                    primaryAccountNo: editData.primaryAccountNo,
                    primaryID: editData.primaryID,
                    amount: editData.amount,
                    Transaction: editData.Transaction,
                    Validfrom: editData.Validfrom,
                    Validupto: editData.Validupto,
                    remarks: editData.remarks,
                    declineRemarks: editData.declineRemarks || "",
                    status: editData.status,
                    id: editData.id
                })

                setApprovalStatus({
                    status: editData.status,
                    declineRemarks: editData.reason_for_declining
                })
            }

            setChangeFormEmployee(editData.isEmployeeRecord ? "Employee" : "Vendor's Payment");
            if (editData.isEmployeeRecord === true) {
                employeegetdata();
                VendorDropdown(editData.unicVendorId);
            }

        } else {
            return;
        }

    }, [editData]);

    // cancel button function
    const returnGird = () => {
        if (isFormDirty) {
            setShowChangesModal(true)
        } else {
            setVirtualForm({
                request: "Employee",
                date: "",
                employeeName: '',
                vendorName: '',
                particulars: '',
                primaryAccountNo: '',
                Transaction: '',
                amount: '',
                Validfrom: "",
                Validupto: "",
                remarks: '',
                status: '',
                declineRemarks: '',
                id: ''
            })
            modeFunction(false)
            virtualCard("grid")
            getEditMode(false)
        }
    }


    // warningMsg 
    const clearAleart = (status: any) => {
        const timer = setTimeout(() => {
            setShowAlertBox(false)
            setShowMessage("");
            if (status === 200) {
                virtualCard("grid")
                getEditMode(false)
            }
            clearTimeout(timer);
        }, 5000);
    }


    const clearAleartemployeeName = (status: any) => {
        const timer = setTimeout(() => {
            setShowAlertBox(false)
            setShowMessage("");
            // if (status === 200 && currentPage !== "Organisation Details") {
            //     organisationFun("grid")
            //     getEditMode(false)
            // }
            clearTimeout(timer);
        }, 5000);
    }


    // submit tha form page api post and patch 
    const updateData = async (id: any) => {
        setIsLoading(true)
        try {
            const res = isEditMode ? await axiosPrivate.patch(`/virtual-card/form/update/${virtualForm.id}`, virtualForm) :
                await axiosPrivate.post('/virtual-card/new-recard/create', virtualForm)
            if (res.status === 200) {
                setShowAlertBox(true)
                setShowType("success")
                setShowMessage(res.data.message)
                setIsFormDirty(false)
                setIsLoading(false)
                clearAleart(res.status)
                dispatch(resetFormModified(false));
                setcurrrenSelectedEmployee({})
                setcurrrenSelectedVender({})
                setVendorForm({
                    request: "Vendor's Payment",
                    date: "",
                    particulars: "",
                    primaryAccountNo: "",
                    primaryID: "",
                    Transaction: "1",
                    amount: "",
                    Validfrom: "",
                    Validupto: "",
                    remarks: "",
                    id: ""
                })
                setVirtualForm({
                    request: "Employee",
                    date: "",
                    employeeName: '',
                    vendorName: '',
                    particulars: '',
                    primaryAccountNo: '',
                    Transaction: '1',
                    amount: '',
                    Validfrom: "",
                    Validupto: "",
                    remarks: '',
                    status: '',
                    declineRemarks: '',
                    id: ''
                })
            }
        } catch (error: any) {
            setIsLoading(false)
            setIsLoading(false)
            if (error.status === 500) {
                setShowAlertBox(true);
                setShowType("danger")
                setShowMessage(error.response.data.error)
                clearAleart("")
            } else if (error.code === "ERR_NETWORK") {
                setShowAlertBox(true);
                setShowType("danger")
                setShowMessage(error.message)
                clearAleart("")
            } else {

                if (error.response.data.isAmount === false) {
                    setUserDataErrors(
                        {
                            amount: true
                        }
                    )
                }
                setShowAlertBox(true);
                setShowType("danger")
                setShowMessage(error.response.data.error)
                clearAleart("")
            }
        }
    }

    // save button and send  api 
    async function virtualCardSave() {

        let checkValidDate: boolean = isDateValid();
        const isValid = validateForm();

        if (isValid) {
            if (!checkValidDate) {
                setUserDataErrors({
                    ...userDataErrors,
                    Validfrom: true,
                    Validupto: true,
                });
                setShowAlertBox(true);
                setShowType("danger");
                setShowMessage("Some of the field(s) are not in required format.");
                setTimeout(() => {
                    setShowAlertBox(false);
                }, 5000);
                return; // Return early and don't proceed
            } else if (checkValidDate) {
                await updateData(virtualForm.id);
                return;
            }
        }
        else {
            setShowAlertBox(true);
            setShowType("danger")
            setShowMessage(Object.values(updatedIsValueValidAll).every((values) => !values) ?
                "Some of the field(s) are not in required format." : "Mandatory field(s) should not be left blank.")
            clearAleart('');
        }
    }

    // primary Number Card dropdown get API  method
    const primaryData = async () => {
        try {
            const response = await axiosPrivate.post('/virtual-card/card/dropdown/get', { token: auth.token });
            const primaryGroupDropDownValues = response.data.getCardDetails.map((item: any) => ({

                label: item.number,
                value: item.number,
                id: item.id,
                primaryID: item.id,
            }
            ));
            const sortedDataPrimary = [...primaryGroupDropDownValues].sort((a, b) => a.label.localeCompare(b.label));

            setprimaryCardDD(sortedDataPrimary)
        } catch (error: any) {

        }
    }
    // employee  get method API 
    const employeegetdata = async () => {
        try {
            const response = await axiosPrivate.get('/virtual-card/employee-details/get');

            let groupdropdownValues: any = []
            response.data.employeeDetails.map((item: any) => {

                if (item.status === "Yes") {
                    groupdropdownValues.push(
                        {
                            label: item.employeeName,
                            value: item.employeeName,
                            id: item.employeeId,
                            unicEmployeeId: item.id,
                        }
                    )
                }
            }
            );

            const sortedData = groupdropdownValues.sort((a: any, b: any) => a.label.localeCompare(b.label));
            setEmployeeNameDD(sortedData);
            const groupdropdownValuesVendor = response.data.vendorDetails.map((item: any) => ({
                label: item.vendorName,
                value: item.vendorName,
                id: item.id,
                unicVendorId: item.id,
            }

            ));
            const sortedDataVendor = [...groupdropdownValuesVendor].sort((a, b) => a.label.localeCompare(b.label));
            setVendorDetailsDD(sortedDataVendor)
            setEmployeeDetailsAll(response.data.employeeDetails)

            if (isEditMode && editData.unicEmployeeId) {
                const foundedObj = response.data.employeeDetails.find((Obj: any) => Obj.id
                    === editData.unicEmployeeId);
                setcurrrenSelectedEmployee(foundedObj);
                if (!foundedObj) {
                    setVirtualForm({
                        ...virtualForm,
                        employeeName: ""
                    })
                }

                // const foundedVendorObj = response.data.vendorDetails.find((Obj: any) => Obj.id
                //     === editData.unicVendorId);
                // setcurrrenSelectedVender(foundedVendorObj);
                // setcurrrenSelectedVender(response.data.vendorDetails);
            } else if (virtualForm.unicEmployeeId) {
                const foundedObj = response.data.employeeDetails.find((Obj: any) => Obj.id
                    === virtualForm.unicEmployeeId);

                setcurrrenSelectedEmployee(foundedObj);
                if (!foundedObj) {
                    setVirtualForm({
                        ...virtualForm,
                        employeeName: ""
                    })
                }
            }
        } catch (error) {
            setEmployeeDetailsAll([])
        }
    };
    const VendorDropdown = async (id: any) => {
        try {
            const response = await axiosPrivate.get(`/virtual-card/vendor-details/${id}`);
            setcurrrenSelectedVender(response.data.vendorDetails);
        } catch (error) {
        }
    };


    //   /virtual-card/vendor-details/:id
    //  validation data function
    const handleToDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        // setMindate(moment(event.target.value).format("YYYY-MM-DD")
        const toDate = event.target.value;
        const currentDate = moment().format('YYYY-MM-DD'); // Get the current date
        if (toDate.length > 8) {
            const year = toDate.substring(0, 4);
            if (parseInt(year, 10) > currentyear) {
                return;
            }
            if (parseInt(year) > 999) {
                if (parseInt(year, 10) < parseInt((virtualForm.Validfrom).substring(0, 4))) {
                    return;
                }
            }
        }
        setVirtualForm((prevState: any) => ({
            ...prevState,
            Validupto: toDate,
        }));
        setIsFormDirty(true)
        dispatch(resetFormModified(true));
    };

    const handleFromDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMindate(moment(event.target.value).format("YYYY-MM-DD"))
        setIsFormDirty(true)
        dispatch(resetFormModified(true));
        // setVirtualForm((prevState: any) => ({
        //     ...prevState,
        //     Validfrom: event.target.value,
        //     Validupto: "",
        // }));
        const fromDate = event.target.value;
        const currentDate = moment().format('YYYY-MM-DD'); // Get the current date.
        // const fromDate = event.target.value;
        if (fromDate.length > 8) {
            const year = fromDate.substring(0, 4);
            if (parseInt(year, 10) > currentyear) { return; }
            if (parseInt(year) > 999) {
                if (parseInt(year, 10) < 1900) {
                    return;
                }
            }
        }
        setVirtualForm((prevsubscriptionData: any) => ({
            ...prevsubscriptionData,
            Validfrom: fromDate,
            Validupto: ""
        }))
    };


    return (
        <>
            <MainPageCard>
                <div className='header-main'>
                    <div className='navigater'>
                        <div className='navigate-header-component'>
                            <NavigateHeader
                                style={{ cursor: "pointer" }}
                                firstValue={"Organisation"}
                                secondValue={"Virtual Card Details"}
                                navigatePage={() => { returnGird() }}
                            />
                            {/* <ul className='bread-crumbs' >
                                <li style={{ cursor: "pointer" }}
                                    onClick={() => {
                                        returnGird()
                                    }}><span>Virtual Card</span>

                                </li>
                            </ul> */}
                        </div>
                    </div>
                </div>
                <div>
                    <div className='Request-flex' >
                        <div className='radio-input virtualcard-radio-input'>
                            <div className='main-Request-virtual' >
                                <div className='Request-name' >Request on behalf of</div>
                                <div className='radio-btn-flex-virtual' >
                                    <div style={isEditMode && !editData.isEmployeeRecord ?
                                        { pointerEvents: "none", opacity: 0.5, width: "130px" } : { pointerEvents: "auto", opacity: 1, width: "130px" }}>
                                        <RadioOrCheckbox
                                            value={"Employee"}
                                            type={"radio"}
                                            name={"Employee"}
                                            checkedValue={virtualForm.request}
                                            getVal={(val: any) => {
                                                dispatch(resetFormModified(true));
                                                if (!isFormDirty) {
                                                    setChangeFormEmployee("Employee");
                                                    setVirtualForm((prevState: any) => ({
                                                        ...prevState,
                                                        request: "Employee",
                                                    }));
                                                    setcurrrenSelectedEmployee({});
                                                    setcurrrenSelectedVender({});
                                                    setVendorForm({
                                                        request: "Employee",
                                                        date: "",
                                                        particulars: "",
                                                        primaryAccountNo: "",
                                                        primaryID: "",
                                                        Transaction: "1",
                                                        amount: "",
                                                        Validfrom: "",
                                                        Validupto: "",
                                                        remarks: "",
                                                        id: ""
                                                    })
                                                } else {
                                                    setShowChangesModalNavigate(true)
                                                    setNavigatePage("Employee")
                                                }
                                            }}
                                        />
                                    </div>

                                    <div style={isEditMode && editData.isEmployeeRecord ?
                                        { pointerEvents: "none", opacity: 0.5, } : { pointerEvents: "auto", opacity: 1, }}>
                                        <RadioOrCheckbox
                                            value={"Vendor's Payment"}
                                            name={"Vendor's Payment"}
                                            type={"radio"}

                                            checkedValue={virtualForm.request}
                                            getVal={(val: any) => {
                                                dispatch(resetFormModified(true));
                                                setUserDataErrors({});

                                                if (!isFormDirty) {
                                                    setChangeFormEmployee("Vendor's Payment");
                                                    setVirtualForm((prevState: any) => ({
                                                        ...prevState,
                                                        request: "Vendor's Payment",
                                                    }));
                                                    setcurrrenSelectedEmployee({});
                                                    setcurrrenSelectedVender({});
                                                    setVendorForm({
                                                        request: "Vendor's Payment",
                                                        date: "",
                                                        particulars: "",
                                                        primaryAccountNo: "",
                                                        primaryID: "",
                                                        Transaction: "1",
                                                        amount: "",
                                                        Validfrom: "",
                                                        Validupto: "",
                                                        remarks: "",
                                                        id: ""
                                                    })
                                                } else {
                                                    setShowChangesModalNavigate(true)
                                                    setNavigatePage("Vendor's Payment")
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='date-regnumber' style={isEditMode ? { display: "flex" } : { display: "block" }} >
                                <div style={{ margin: "0px" }} >
                                    {
                                        isEditMode &&
                                        <p className='requestNumber'>
                                            Request Number : {editData.requestNumber}
                                        </p>
                                    }
                                </div>
                                <div className='virtualcard-date' >
                                    <p> Date:  {
                                        isEditMode ?
                                            (changeFormEmployee == "Employee" ? virtualForm.date : vendorForm.date)
                                            :
                                            (changeFormEmployee == "Employee" ? currentDate.toLocaleString() : vendorCurrentDate)
                                    }</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    {changeFormEmployee === "Employee" &&

                        <div className='main-form-card' style={isEditMode && approvalStatus.status !== "Pending Approval" ? {} : {}} >
                            <div className='form-main-details'>
                                <div className='form-main-employee'>
                                    <div className='employee-main-form'>
                                        <div className='form-title-inputbox'>
                                            <div className='form-title-vitrual'>
                                                <label htmlFor="">Employee Name<span className='requrid-status'>*</span></label>
                                            </div>
                                            <div className='dropdown-plus-flex'>
                                                <div key={uuidv4()} className={userDataErrors.employeeName ? 'setError-virtual-plus' : 'dropdown-user-with-plus'} >
                                                    <div className='dropdown' style={{ width: "246px" }}  >
                                                        <DropdownComponent
                                                            // isDisabled={isEditMode && editData.status === "Pending Approval" ? true : false}

                                                            options={employeeNameDD}
                                                            width='300px'
                                                            getData={(val) => {
                                                                setIsFormDirty(true)
                                                                setVirtualForm((prevState: any) => ({
                                                                    ...prevState,
                                                                    employeeName: val.value,
                                                                    unicEmployeeId: val.unicEmployeeId,
                                                                }));
                                                                const foundedObj = employeeDetailsAll.find((Obj: any) => Obj.employeeId
                                                                    === val.id);
                                                                setcurrrenSelectedEmployee(foundedObj);
                                                                dispatch(resetFormModified(true));
                                                            }}
                                                            defaultValue={[
                                                                {
                                                                    label: virtualForm.employeeName !== "" ? virtualForm.employeeName : "Select",
                                                                    value: virtualForm.employeeName !== "" ? virtualForm.employeeName : "Select",
                                                                }
                                                            ]}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='with-plus' onClick={() => {
                                                    setComponentNavigator(true)

                                                }} >
                                                    <BsPlusLg color='#004E9C' height={"14px"} />
                                                </div>
                                            </div>

                                            <div className='empolyee-card'>
                                                <div >
                                                    <div className='card-main' >
                                                        <div className='empolyee-details'>
                                                            <div>
                                                                <p> Employee ID</p>
                                                                <p> Department</p>
                                                                <p> Designation</p>
                                                                <p> Email ID</p>
                                                                <p>  Contact Number</p>
                                                            </div>
                                                            <div>
                                                                <p>: {currrenSelectedEmployee?.employeeId ? currrenSelectedEmployee.employeeId : ""} </p>
                                                                <p>: {currrenSelectedEmployee?.department ? currrenSelectedEmployee.department : ""} </p>
                                                                <p>: {currrenSelectedEmployee?.designation ? currrenSelectedEmployee.designation : ""} </p>
                                                                <p>: {currrenSelectedEmployee?.emailId ? currrenSelectedEmployee.emailId : ""} </p>
                                                                <p>: {currrenSelectedEmployee?.contactNumber ? currrenSelectedEmployee.contactNumber : ""} </p>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                        <div className='form-title-inputbox'>
                                            <div className='form-title-vitrual'>
                                                <label htmlFor="">Vendor Name</label>
                                            </div>
                                            <div className={userDataErrors.vendorName ? 'setError-virtual' : 'dropdown-user'} key={uuidv4()}  >
                                                <div className='dropdown'  >
                                                    <DropdownComponent
                                                        // isDisabled={isEditMode && editData.status === "Pending Approval" ? true : false}

                                                        options={vendorDetailsDD}
                                                        width='300px'
                                                        getData={(val) => {
                                                            VendorDropdown(val.unicVendorId)
                                                            setIsFormDirty(true)
                                                            setVirtualForm((prevState: any) => ({
                                                                ...prevState,
                                                                vendorName: val.value,
                                                                unicVendorId: val.unicVendorId,
                                                            }));
                                                            // const foundedObj = vendarValueAll?.find((Obj: any) => Obj.id === val.id);
                                                            // setcurrrenSelectedVender(foundedObj);
                                                            dispatch(resetFormModified(true));
                                                        }}
                                                        defaultValue={[
                                                            {
                                                                label: virtualForm.vendorName !== "" ? virtualForm.vendorName : "Select",
                                                                value: virtualForm.vendorName !== "" ? virtualForm.vendorName : "Select",
                                                            }
                                                        ]}
                                                    />
                                                </div>
                                            </div>
                                            <div className='empolyee-card'>
                                                <div className='vendor-dropdown'>
                                                    <div className='card-main'  >
                                                        <div className='vendor-details'>
                                                            <div>
                                                                <p> Vendor Code</p>
                                                                <p> GSTIN</p>
                                                                <p> Account Number</p>
                                                                <p> IFSC Code</p>
                                                            </div>
                                                            <div className=''>
                                                                <p>:  {currrenSelectedVender && currrenSelectedVender.vendorCode
                                                                    ? currrenSelectedVender.vendorCode
                                                                    : ""} </p>
                                                                <p>: {currrenSelectedVender && currrenSelectedVender.gstin
                                                                    ? currrenSelectedVender.gstin
                                                                    : ""}</p>
                                                                <p>:  {currrenSelectedVender && currrenSelectedVender.accountNumber
                                                                    ? currrenSelectedVender.accountNumber
                                                                    : ""}</p>
                                                                <p>:  {currrenSelectedVender && currrenSelectedVender.ifscCode
                                                                    ? currrenSelectedVender.ifscCode
                                                                    : ""}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div>
                                    </div>

                                </div>
                                <div className='form-main'>
                                    <div className='Primary-amount-flex'>

                                        <div className='form-title'>
                                            <div className='form-title-vitrual'>
                                                <label htmlFor="">Primary Account No<span className='requrid-status'>*</span></label>
                                            </div>
                                            <div className={userDataErrors.primaryAccountNo ? 'setError-virtual' : 'dropdown-user'} key={uuidv4()}   >
                                                <div className='dropdown'  >
                                                    <DropdownComponent
                                                        // isDisabled={isEditMode && editData.status === "Pending Approval" ? true : false}
                                                        options={primaryCardDD}
                                                        width='300px'
                                                        getData={(val) => {
                                                            setIsFormDirty(true)
                                                            setVirtualForm((prevState: any) => ({
                                                                ...prevState,
                                                                primaryAccountNo: val.value,
                                                                primaryID: val.primaryID,

                                                            }));
                                                            dispatch(resetFormModified(true));
                                                        }}
                                                        defaultValue={[
                                                            {
                                                                label: virtualForm.primaryAccountNo !== "" ? virtualForm.primaryAccountNo : "Select",
                                                                value: virtualForm.primaryAccountNo !== "" ? virtualForm.primaryAccountNo : "Select",
                                                            }
                                                        ]}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='form-title'>
                                            <div className='form-title-vitrual'>
                                                <label htmlFor="">Amount<span className='requrid-status'>*</span></label>
                                            </div>
                                            <InputComponent
                                                // disabled={isEditMode && editData.status === "Pending Approval" ? true : false}
                                                height={"40px"}
                                                width={"300px"}
                                                padding={"10px 10px"}
                                                border={userDataErrors.amount ?
                                                    "1px solid red" : "1px solid #A9C3DC"}
                                                backgroundColor={"white"}
                                                borderRadius={"0px"}
                                                type={"mobile"}
                                                color={"black"}
                                                placeHolder={"0"}
                                                textAlign={"right"}
                                                inputValue={virtualForm.amount}
                                                maxLength={14}
                                                getUser={(val: any) => {
                                                    setIsFormDirty(true)
                                                    const onlyNumber = val.replace(/[^0-9]/g, "");
                                                    const parsedValue = parseInt(onlyNumber);
                                                    const formattedValue = isNaN(parsedValue) ? "" :
                                                        parsedValue.toLocaleString('en-IN');
                                                    setVirtualForm((prevState: any) => ({
                                                        ...prevState,
                                                        amount: formattedValue,
                                                    }));
                                                    dispatch(resetFormModified(true));

                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className='Primary-amount-flex' style={{ marginTop: "15px" }}>

                                        <div className='form-title'>
                                            <div className='form-title-vitrual'>
                                                <label htmlFor="">Particulars<span className='requrid-status'>*</span></label>
                                            </div>
                                            <div className='Particulars-input' >
                                                <InputComponent
                                                    height={"40px"}
                                                    // width={"850px"} 
                                                    width={"300px"}
                                                    padding={"10px 10px"}
                                                    border={userDataErrors.particulars ?
                                                        "1px solid red" : "1px solid #A9C3DC"}
                                                    backgroundColor={"white"}
                                                    // disabled={isEditMode && editData.status === "Pending Approval" ? true : false}

                                                    borderRadius={"0px"}
                                                    type={"text"}
                                                    color={"black"}
                                                    maxLength={100}
                                                    placeHolder={"Enter Particulars of Invoice"}
                                                    inputValue={virtualForm.particulars}
                                                    getUser={(val: any) => {
                                                        setIsFormDirty(true)
                                                        setVirtualForm((prevState: any) => ({
                                                            ...prevState,
                                                            particulars: val,
                                                        }));
                                                        dispatch(resetFormModified(true));
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className='form-title'>
                                            <div className='form-title-vitrual'>
                                                <label htmlFor="">Number of Transaction <span className='requrid-status'>*</span></label>
                                            </div>
                                            <InputComponent
                                                // disabled={isEditMode && editData.status === "Pending Approval" ? true : false}
                                                height={"40px"}
                                                width={"300px"}
                                                padding={"10px 10px"}
                                                border={userDataErrors.Transaction ?
                                                    "1px solid red" : "1px solid #A9C3DC"}
                                                backgroundColor={"white"}
                                                borderRadius={"0px"}
                                                type={"mobile"}
                                                color={"black"}
                                                placeHolder={"Enter Number of Transaction"}
                                                inputValue={virtualForm.Transaction}
                                                maxLength={2}
                                                getUser={(val: any) => {
                                                    setIsFormDirty(true)
                                                    const onlyNumber = val.replace(/[^0-9]/g, "");
                                                    setVirtualForm((prevState: any) => ({
                                                        ...prevState,
                                                        Transaction: onlyNumber,
                                                    }));
                                                    dispatch(resetFormModified(true));

                                                }}
                                            />
                                        </div>
                                    </div>





                                    {/* <div className='Particulars-inputbox' style={isEditMode && editData.status !== "Declined" ?
                                    { pointerEvents: "none", opacity: 0.5 } : { pointerEvents: "auto", opacity: 1 }}>
                                    <div className='heading'>
                                        <label htmlFor="">Particulars<span className='requrid-status'>*</span></label>
                                    </div>
                                    <div className='Particulars-input' >

                                        <InputComponent
                                            height={"40px"}
                                            // width={"850px"} 
                                            width={windowWidth > 1870 ? "850px" : windowWidth > 1500 ? "750px" : windowWidth > 1190 ? "500px" : "350px"}
                                            padding={"10px 10px"}
                                            border={userDataErrors.particulars ?
                                                "1px solid red" : "1px solid #A9C3DC"}
                                            backgroundColor={"white"}
                                            disabled={isEditMode && editData.status !== "Declined" ? true : false}

                                            borderRadius={"0px"}
                                            type={"text"}
                                            color={"black"}
                                            maxLength={100}
                                            placeHolder={"Enter Particulars of Invoice"}
                                            inputValue={virtualForm.particulars}
                                            getUser={(val: any) => {
                                                setIsFormDirty(true)
                                                setVirtualForm((prevState: any) => ({
                                                    ...prevState,
                                                    particulars: val,
                                                }));
                                                dispatch(resetFormModified(true));

                                            }}
                                        />
                                    </div>
                                </div> */}

                                    <div className='date-main'>
                                        <div className='data-input-flex'   >
                                            <div className='title'> Valid From<span className='requrid-status'>*</span></div>
                                            <div>
                                                <div className='date-input' >
                                                    <input type='date' className='date-picker'
                                                        style={{ border: userDataErrors.Validfrom ? "1px solid red" : "" }}
                                                        value={virtualForm.Validfrom && moment(virtualForm.Validfrom).format('YYYY-MM-DD')}
                                                        // disabled={isEditMode && editData.status === "Pending Approval" ? true : false}
                                                        min={todayDate}
                                                        max={moment(todayDate).add(1, 'years').format('YYYY-MM-DD')}
                                                        onChange={(e: any) => {
                                                            handleFromDateChange(e)
                                                        }} />
                                                </div>
                                            </div>
                                        </div>

                                        <div className='data-input-flex' style={virtualForm.Validfrom !== "" ? { pointerEvents: "auto", opacity: 1 } : { pointerEvents: "none", opacity: 0.5 }}>
                                            <div className='title'> Valid Upto<span className='requrid-status'>*</span></div>
                                            <div>
                                                <input type='date' className='date-picker'
                                                    style={{ border: userDataErrors.Validupto && virtualForm.Validfrom !== "" ? "1px solid red" : "" }}
                                                    value={virtualForm.Validupto}
                                                    // value={virtualForm.Validfrom === "" ? "" : virtualForm.Validupto}
                                                    min={mindate}
                                                    max={moment(mindate).add(1, 'years').format('YYYY-MM-DD')}
                                                    // disabled={isEditMode && editData.status === "Pending Approval" ? true : false}
                                                    onChange={handleToDateChange} />
                                                {/* <input
                                                type='date'
                                                className='date-picker'
                                                style={{ border: userDataErrors.Validupto ? '1px solid red' : '' }}
                                                min={mindate}
                                                max={moment(mindate).add(1, 'years').format('YYYY-MM-DD')}
                                                value={virtualForm.to}
                                                onChange={handleToDateChange}
                                            /> */}
                                            </div>
                                        </div>
                                    </div>

                                    <div className='Particulars-inputbox'>
                                        <div className='Remarks-heading'>
                                            <label htmlFor="">Remarks</label>
                                        </div>
                                        <InputComponent
                                            height={"40px"}
                                            width={windowWidth > 1900 ? "820px" : windowWidth > 1500 ? "750px" : windowWidth > 1190 ? "500px" : "350px"}
                                            padding={"10px 10px"}
                                            border={userDataErrors.remarks ?
                                                "1px solid red" : "1px solid #A9C3DC"}
                                            backgroundColor={"white"}
                                            borderRadius={"0px"}
                                            // disabled={isEditMode && editData.status === "Pending Approval" ? true : false}
                                            type={"text"}
                                            color={"black"}
                                            maxLength={250}
                                            placeHolder={"Reason"}
                                            inputValue={virtualForm.remarks}
                                            getUser={(val: any) => {
                                                setIsFormDirty(true)
                                                setVirtualForm((prevState: any) => ({
                                                    ...prevState,
                                                    remarks: val,
                                                }));
                                                dispatch(resetFormModified(true));
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className='card-Payment-main'>
                                <div className='card-contant'>

                                    <div className='card-main'>
                                        <img src={PaymentCard} alt="PaymentCard" className='payment-card' />
                                        {/* 
                                    <p className='virtual-card-number'>
                                        **** **** **** 4511
                                        <p className='card-content' >
                                            MONTH/YEAR
                                        </p>
                                        <div className='virtual-card-name-main'>
                                            <p className='font-size'>
                                                Arun
                                            </p>
                                            <div className='date-valid'>
                                                <div>
                                                    <p className='font-size-valid'>
                                                        VALID
                                                    </p>
                                                    <p className='font-size-valid'> THRU</p>
                                                </div>
                                                <p className='date'>
                                                    01/2000
                                                </p>
                                            </div>
                                        </div>
                                    </p> */}


                                    </div>

                                    {/* <p className='card-status' >
                                    Status : 
                                </p> */}
                                    <div className='card-details'>
                                        <div className='card-title'>
                                            <p> Status </p>
                                            <p> Name on Card </p>
                                            <p> Card Number </p>
                                            <p> Valid till </p>
                                            <p> Amount </p>
                                        </div>
                                        <div className='card-value'>
                                            <p> :{ } </p>
                                            <p> :{empolyeeDetails.name} </p>
                                            <p> :{empolyeeDetails.cardNumber} </p>
                                            <p> :{empolyeeDetails.validTill} </p>
                                            <p> :{empolyeeDetails.amount} </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {
                                isEditMode && (approvalStatus.status === "Approved" || approvalStatus.status === "Declined") &&
                                <Approvemodal
                                    approvalStatus={approvalStatus}
                                    approvaldata={(val: any, type: string) => {
                                        setApprovalStatus({
                                            ...approvalStatus,
                                            [type]: val
                                        })
                                    }}
                                />
                            }
                            {/* && approvalStatus.status !== "Processing"  */}

                        </div>
                    }
                </div>
                {changeFormEmployee === "Employee" &&
                    <div className='footer-virtual'>
                        <ButtonComponent
                            title={"Cancel"}
                            height={"50px"}
                            width={"150px"}
                            margin={"0px 8px"}
                            backgroundColor={"#888888"}
                            color={"white"}
                            className={"button-component-hover cancel"}
                            handleClick={() => { returnGird() }}
                        />
                        <div>

                            <ButtonComponent
                                title={"Submit for approval"}
                                height={"50px"}
                                width={"200px"}
                                backgroundColor={"#0055D4"}
                                disabled={(showMessage && showType === "success") ? true : false}
                                color={"white"}
                                margin={"0px 8px"}
                                className={(showMessage && showType === "success") ? "button-component-hover disabled" : "button-component-hover common-btn"}
                                handleClick={() => {
                                    virtualCardSave();
                                }
                                }
                            />
                        </div>
                    </div>
                }


                {
                    changeFormEmployee === "Vendor's Payment" &&
                    <VendorCardPayment
                        primaryCardDD={primaryCardDD}
                        vendorForm={vendorForm}
                        virtualCard={virtualCard}

                        vendorfunValue={(val: any) => {
                            setVendorForm(val);
                        }}
                        formDirtyFunction={(val: any) => {
                            setIsFormDirty(val);
                        }}
                        currentdateFunction={(val: any) => {
                            setVendorCurrentDate(val);
                        }}
                        modeFunction={modeFunction}
                        getEditMode={getEditMode}
                        isEditMode={isEditMode}
                        editData={editData}
                        approvalStatus={approvalStatus}
                        approvaldata={(val: any, type: string) => {
                            setApprovalStatus({
                                ...approvalStatus,
                                [type]: val
                            })
                        }}
                        setApprovalStatus={(status: any, declineRemarks: any) => setApprovalStatus({
                            ...approvalStatus,
                            status: status,
                            declineRemarks: declineRemarks
                        })}

                    />
                }
                {
                    showAlertBox &&
                    <div className='alert-warp'>
                        <AlertBox type={showType} message={showMessage} />
                    </div>
                }
            </MainPageCard >
            {
                isLoading &&
                <Loading />
            }

            {/* model box form */}
            {
                componentNavigator === true &&
                <div>

                    <VirtualCardForm
                        employeeDetailsAll={employeeDetailsAll}
                        setEmployeeDetailsAll={(val: any) => {
                            setEmployeeDetailsAll(val)
                        }}
                        virtualCard={virtualCard}
                        virtualForm={virtualForm}
                        clearValue={(value) => {
                            setComponentNavigator(value)
                        }}
                        employeedetails={(val: any) => {
                            setcurrrenSelectedEmployee(val);
                        }}
                        clearEmployeeData={(val: any) => {
                            setVirtualForm(val)
                        }}
                        // adminDataDelete={adminDataDelete}
                        postdata={employeegetdata}


                    />
                </div>

            }

            {
                showChangesModalNavigate &&
                <Changesmodal
                    modelType={"navigate-radiobtn"}
                    closeModal={() => setShowChangesModalNavigate(false)}
                    leavePage={() => {
                        setChangeFormEmployee(navigatePage);
                        setShowChangesModalNavigate(false)
                        // setIsFormDirty(false)
                        setVirtualForm((prevState: any) => ({
                            ...prevState,
                            request: navigatePage,
                            date: "",
                            employeeName: '',
                            vendorName: '',
                            particulars: '',
                            primaryAccountNo: '',
                            amount: '',
                            Transaction: "1",
                            Validfrom: "",
                            Validupto: "",
                            remarks: '',
                            status: '',
                            declineRemarks: ''
                        }));
                        setVendorForm((prevState: any) => ({
                            ...prevState,
                            request: navigatePage,
                            date: "",
                            particulars: "",
                            primaryAccountNo: "",
                            primaryID: "",
                            amount: "",
                            Transaction: "1",
                            Validfrom: "",
                            Validupto: "",
                            remarks: "",
                            token: "",
                            id: ""
                        }));
                        // getEditMode(false)
                    }}
                    handleClose={() => setShowChangesModalNavigate(false)} />
            }
            {
                showChangesModal &&
                <Changesmodal
                    closeModal={() => setShowChangesModal(false)}
                    leavePage={() => {
                        setShowChangesModal(false);
                        setIsFormDirty(false)
                        virtualCard("grid")
                        // getEditMode(false)
                    }}
                    handleClose={() => setShowChangesModal(false)} />
            }
        </>

    )


}



