import React, { useEffect, useState, forwardRef, useImperativeHandle, useCallback } from 'react'
import InputComponent from '../../../common-component/form-elements/input-component'
import DropdownComponent from '../../../common-component/form-elements/dropdown-component'
import ButtonComponent from '../../../common-component/form-elements/button-component'
import { v4 as uuidv4 } from 'uuid'
import useAxiosPrivate from "../../../../services/hooks/useaxios-private";
import AlertBox from '../../../common-component/alert-box'
import { useDispatch, useSelector } from "react-redux";
import { resetFormModified } from "../../../../redux/action";
import Changesmodal from '../../../common-component/modals/changes-modal';

export interface ChildRef {
  next: (val: any) => void;
}
interface DropdownItem {
  label: string;
  value: string;
}
interface props {
  mainApiData: any
  setMainApiData: (val: any) => void;
  onboardingViewfun: (val: any) => void;
  isEditMode: boolean;
  iconStatus: any;
  editData: any;
  setShowPage: (val: any) => void;
  sendDataApi: () => void;
  setIconStatus: (val: any) => void;
}
const Address = forwardRef<ChildRef, props>(({ mainApiData, isEditMode, onboardingViewfun, setMainApiData, sendDataApi, iconStatus, editData, setShowPage, setIconStatus }, ref) => {

  const [companyOverviewErrors, setCompanyOverviewErrors] = useState<Record<string, boolean>>({})
  const [addressState, setAddressState] = useState<any[]>([]);
  const [addressCities, setAddressCities] = useState<any[]>([]);
  const [isFormDirty, setIsFormDirty] = useState(false)
  const [showType, setShowType] = useState("warning") // error message showType
  const [showMessage, setShowMessage] = useState<string>("") // error message showMessage
  const [showAlertBox, setShowAlertBox] = useState(false) // error message ShowAlertBox
  const isFormModified = useSelector((state: any) => state.isFormModified);
  const dispatch = useDispatch();
  const [showChangesModal, setShowChangesModal] = useState<boolean>(false);
  const [dropDownState, setDropDownState] = useState<DropdownItem[]>([])
  const [dropDownCities, setDropDownCities] = useState<DropdownItem[]>([])
  const [dropDownRocCities, setDropDownRocCities] = useState<DropdownItem[]>([])


  const userValidationRules: any = {
    companyData: {
      Country: {
        regex: "",
        field: "",
        shouldNotBe: ""
      },
      CompanyRegState: {
        regex: "",
        field: "mandatory",
        shouldNotBe: ""
      },
      CompanyRocCity: {
        regex: "",
        field: "mandatory",
        shouldNotBe: ""
      },
      CompanyRegCity: {
        regex: "",
        field: "mandatory",
        shouldNotBe: ""
      },
      CompanyRegPinCode: {
        regex: /^[0-9]{6}$/,
        field: "mandatory",
        shouldNotBe: ""
      },
      CompanyMcaStatus: {
        regex: "",
        field: "mandatory",
        shouldNotBe: ""
      },
      CompanyEmail: {
        regex: /^[a-z0-9._-]+@[a-z0-9-]+\.[a-z]{2,}$/,
        field: "mandatory",
        shouldNotBe: ""
      },
      CompanyWebsite: {
        regex: /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?\/?$/,
        field: "",
        shouldNotBe: ""
      },
      ContactNumber: {
        regex: /^[0-9]{10}$/,
        field: "mandatory",
        shouldNotBe: ""
      },
      ContactPersonName: {
        regex: "",
        field: "mandatory",
        shouldNotBe: ""
      },
      // CompanyFullAddress: {
      //   regex: "",
      //   field: "mandatory",
      //   shouldNotBe: ""
      // },
      street_1: {
        regex: "",
        field: "mandatory",
        shouldNotBe: ""
      },
      street_2: {
        regex: "",
        field: "mandatory",
        shouldNotBe: ""
      },
    }
  }

  const updatedIsValueValidAll: Record<string, boolean> = {};
  const validateForm = (): boolean => {
    const updatedFieldValidity: Record<string, boolean> = {};
    const isMandatoryfalse = true;
    const isRegexfalse = true;
    Object.keys(userValidationRules.companyData).forEach((field) => {
      const rule = userValidationRules.companyData[field];
      const value = mainApiData.companyData[field];
      const isValueValid = (rule.field === "mandatory") ? (value ? (value !== rule.shouldNotBe) : false) : true;
      const isRegexValid = rule.regex !== "" ? new RegExp(rule.regex).test(value) : true;
      updatedFieldValidity[field] = !isValueValid || !isRegexValid;
      updatedIsValueValidAll[field] = !isValueValid;
    });
    setCompanyOverviewErrors(updatedFieldValidity);
    const isFormValid = Object.keys(updatedFieldValidity).every((keys) => {
      return !updatedFieldValidity[keys]
    });
    return isFormValid;
  };

  function returnGird() {
    onboardingViewfun("grid")
    dispatch(resetFormModified(false));
  }


  useEffect(() => {
    fetchData("state", setDropDownState, "states", "stateId", "name");
    // if (isEditMode) {
    // "CompanyRegState": mainApiData.companyData?.CompanyRegState,
    // fetchData(`state/cities/${editData.companyData?.CompanyRegState}`, setDropDownCities, 'city', "cityId", "name");
    // fetchData(`/state/roc-cities/${editData.companyData?.CompanyRegState}`, setDropDownRocCities, 'RocCity', "rocCityId", "name");
    // }
  }, [])

  useEffect(() => {
    fetchAddressValues()
    fetchAddressCity()
  }, [])
  const axiosPrivate = useAxiosPrivate();
  const fetchAddressValues = async () => {
    try {
      const response = await axiosPrivate.get("state");
      if (response.status === 200) {
        const dropdownValues = response.data.states.map((item: any) => ({
          label: item.name,
          value: item.name,
          id: item.stateId,
        }));
        setAddressState(dropdownValues);
      }
    } catch (error) {
    }
  };
  const fetchAddressCity = async () => {
    try {
      const response = await axiosPrivate.get("cities");
      if (response.status === 200) {
        const dropdownValues = response.data.cities.map((item: any) => ({
          label: item.name,
          value: item.name,
          id: item.cityId,
        }));
        setAddressCities(dropdownValues);
      }
    } catch (error) {
    }
  };

  // const fetchData = async (url: string, setData: (data: any[]) => void, nestedProperty: string, id: any, representName: any) => {
  //   try {
  //     const response = await axiosPrivate.get(url);
  //     if (response.status === 200) {
  //       const dropdownValues = response.data[nestedProperty].map((item: any) => ({
  //         label: item[representName],
  //         value: item[representName],
  //         id: item[id],
  //       }));
  //       const sortedData = [...dropdownValues].sort((a, b) => a.label.localeCompare(b.label));
  //       setData([...sortedData]);

  //     }
  //   } catch (error) {
  //   }
  // };

  const fetchData = async (url: string, setData: (data: any[]) => void, nestedProperty: string, id: any, representName: any) => {
    try {
      const response = await axiosPrivate.get(url);
      if (response.status === 200) {
        const dropdownValues = response.data[nestedProperty].map((item: any) => ({
          label: item[representName],
          value: item[representName],
          id: item[id],
        }));
        const sortedData = [...dropdownValues].sort((a, b) => a.label.localeCompare(b.label));
        setData([...sortedData]);

        if (isEditMode && nestedProperty !== 'city' && nestedProperty !== 'RocCity') {
          const foundPrivilege: any = sortedData.find((privilegeObj: any) => {
            return privilegeObj.value === editData?.CompanyData?.companyData?.CompanyRegState;
          });
          if (foundPrivilege) {
            fetchData(`state/cities/${foundPrivilege?.id}`, setDropDownCities, 'city', "cityId", "name");
            fetchData(`/state/roc-cities/${foundPrivilege?.id}`, setDropDownRocCities, 'RocCity', "rocCityId", "name");
          }
        }
      }
    } catch (error) {
    }
  };
  async function duplicateCheck() {
    const finalData = {
      "CompanyEmail": mainApiData.companyData?.CompanyEmail,
      "CompanyContactNumber": mainApiData.companyData?.ContactNumber
    }

    let statusMessage: boolean = false;
    try {
      const res = await axiosPrivate.post(`bank-admin/company/duplicate-checking/${editData?.CompanyData?.companyData?.id}`, { "address": finalData })
      if (res.status === 200) {
        statusMessage = true;
      }
    }
    catch (error: any) {
      setShowAlertBox(true)
      setShowType("danger")
      setShowMessage(error.response.data.error)
      clearAlert("")
      statusMessage = false;
    }
    return statusMessage;
  }
  const clearAlert = (status: any) => {
    const timer = setTimeout(() => {
      setShowAlertBox(false);
      setShowMessage("");
      if (status === 400) {

      }
      clearTimeout(timer);
    }, 5000);
  }

  const next = async (next: any = "") => {
    const isFormValid = validateForm();
    if (isFormValid) {
      const Duplicate = await duplicateCheck();
      if (Duplicate) {
        if (isEditMode) {
          sendDataApi();
          setIconStatus({
            ...iconStatus,
            companyIcon: "completed",
            addressIcon: "selected"
          })
          dispatch(resetFormModified(false));
        } else {
          setShowPage(next != "" ? next : "DirectorsInfo")
          if (next != "") {
            switch (next) {
              case "CompanyOverview":
                setIconStatus({
                  ...iconStatus,
                  companyIcon: "selected",
                })
                break;
              case "Address":
                setIconStatus({
                  ...iconStatus,
                  companyIcon: "completed",
                  addressIcon: "selected",
                })
                break;
              case "DirectorsInfo":
                setIconStatus({
                  ...iconStatus,
                  directorIcon: "selected",
                  addressIcon: "completed"
                })
                break;
              case "StatutoryDetails":
                setIconStatus({
                  ...iconStatus,
                  statutoryIcon: "selected",
                  directorIcon: "completed"
                })
                break;
              case "AdminDetails":
                setIconStatus({
                  ...iconStatus,
                  adminIcon: "selected",
                  statutoryIcon: "completed"
                })
                break;

              default:
                break;
            }
          } else {
            setIconStatus({
              ...iconStatus,
              addressIcon: "completed",
              directorIcon: "selected"
            })
          }
        }
      } else {
      }

      setIsFormDirty(false);
    } else {
      setShowAlertBox(true);
      setShowType("danger");
      setShowMessage(Object.values(updatedIsValueValidAll).every((values) => !values) ?
        "Some of the field(s) are not in required format." : "Mandatory field(s) should not be left blank.")
      clearAlert('');
    }
  }

  useImperativeHandle(ref, () => {
    return {
      next: next
    }
  });


  return (
    <>
      <div className='form-wrapper'>
        <div className='form-area-address' style={mainApiData?.kycApprovalStatus === "Approved" ? { overflowY: "scroll" } : { overflowY: "scroll" }}>

          <div className='form-area-address' style={mainApiData?.kycApprovalStatus === "Approved" ? { opacity: 0.5, pointerEvents: "none", } : {}}>
            <div className='company-heading'>
              Address
            </div>
            <div className='form-main-wrapper'>
              <div style={{ opacity: "0.7", marginRight: "45px", marginBottom: "30px" }} className={companyOverviewErrors.Country ? 'setErrorcompany' : "dropdown-margin dropdown-width"}>
                <DropdownComponent
                  width={"300px"}
                  required={true}
                  title={"Country"}
                  defaultValue={[{ label: "India", value: "India" }]}
                  options={[{ label: "India", value: "India" }]}
                  isDisabled={true}
                  getData={(value: any) => {
                    setMainApiData({
                      ...mainApiData,
                      companyData: {
                        ...mainApiData.companyData,
                        Country: value.value,
                      }
                    })
                    setIsFormDirty(true)
                    dispatch(resetFormModified(true));
                    setIconStatus({
                      ...iconStatus,
                      addressIcon: "selected"
                    })
                  }}
                />
              </div>
              <div style={{ marginRight: "45px", marginBottom: "30px" }} key={uuidv4()} className={companyOverviewErrors.CompanyRegState ? 'setErrorcompany' : "dropdown-width"}>
                <DropdownComponent
                  width={"300px"}
                  required={true}
                  title={"Company Reg State"}
                  defaultValue={[{ label: mainApiData?.companyData?.CompanyRegState === undefined ? "Select" : (mainApiData.companyData.CompanyRegState), value: (mainApiData && mainApiData.companyData.CompanyRegState) }]}
                  options={dropDownState}
                  isDisabled={mainApiData?.kycApprovalStatus === "Approved"}
                  getData={(value: any) => {
                    setIsFormDirty(true)
                    dispatch(resetFormModified(true));
                    setMainApiData({
                      ...mainApiData,
                      companyData: {
                        ...mainApiData.companyData,
                        CompanyRegState: value.value,
                        CompanyRocCity: undefined,
                        CompanyRegCity: undefined,
                      }
                    })
                    if (value.id == "") {
                      setDropDownCities([]);
                      setDropDownRocCities([]);
                    } else {
                      setDropDownCities([]);
                      setDropDownRocCities([]);
                      fetchData(`state/cities/${value.id}`, setDropDownCities, 'city', "cityId", "name");
                      fetchData(`/state/roc-cities/${value.id}`, setDropDownRocCities, 'RocCity', "rocCityId", "name");
                    }
                    setIconStatus({
                      ...iconStatus,
                      addressIcon: "selected"
                    })
                  }}
                />
              </div>
              <div style={{ marginRight: "45px", marginBottom: "30px" }} key={uuidv4()} className={companyOverviewErrors.CompanyRocCity ? 'setErrorcompany' : "dropdown-width"}>
                <DropdownComponent
                  width={"300px"}
                  title={"Company Roc City"}
                  required={true}
                  isDisabled={mainApiData?.kycApprovalStatus === "Approved"}
                  options={dropDownRocCities}
                  // mainApiData?.companyData?.CompanyRocCity === undefined ? "Select" : mainApiData?.companyData?.CompanySubCategory
                  defaultValue={[{ label: mainApiData?.companyData?.CompanyRocCity === undefined ? "Select" : (mainApiData.companyData.CompanyRocCity), value: mainApiData?.companyData?.CompanyRocCity === undefined ? "" : (mainApiData.companyData.CompanyRocCity) }]}
                  getData={(value: any) => {
                    setIsFormDirty(true)
                    dispatch(resetFormModified(true));
                    setMainApiData({
                      ...mainApiData,
                      companyData: {
                        ...mainApiData.companyData,
                        CompanyRocCity: value.value,
                      }
                    })
                    setIconStatus({
                      ...iconStatus,
                      addressIcon: "selected"
                    })
                  }}
                />
              </div>
            </div>
            <div className='form-main-wrapper'>
              <div style={{ marginRight: "45px", marginBottom: "30px" }} key={uuidv4()} className={companyOverviewErrors.CompanyRegCity ? 'setErrorcompany' : "dropdown-width"}>
                <DropdownComponent
                  width={"300px"}
                  title={"Company Reg City"}
                  required={true}
                  isDisabled={mainApiData?.kycApprovalStatus === "Approved"}
                  options={dropDownCities}
                  defaultValue={[{ label: mainApiData?.companyData?.CompanyRegCity === undefined ? "Select" : (mainApiData.companyData.CompanyRegCity), value: mainApiData && (mainApiData.companyData.CompanyRegCity) }]}
                  getData={(value: any) => {
                    setIsFormDirty(true)
                    dispatch(resetFormModified(true));
                    setMainApiData({
                      ...mainApiData,
                      companyData: {
                        ...mainApiData.companyData,
                        CompanyRegCity: value.value,
                      }
                    })
                    // if (value.id != "") {
                    //   fetchData(`region/state/${value.id}`, setDropDownState, 'states', "stateId", "name");
                    // }
                    setIconStatus({
                      ...iconStatus,
                      addressIcon: "selected"
                    })
                  }}
                />
              </div>
              <div>
                <InputComponent
                  height={"40px"}
                  width={"300px"}
                  margin={"0px 45px 30px 0px"}
                  padding={"0px 0px 0px 10px"}
                  border={companyOverviewErrors.CompanyRegPinCode ? "1px solid red" : "1px solid #A9C3DC"}
                  borderRadius={"0px"}
                  backgroundColor={"white"}
                  color={"black"}
                  disabled={mainApiData?.kycApprovalStatus === "Approved"}
                  type={"text"}
                  maxLength={6}
                  placeHolder={"Enter Reg Pin Code"}
                  inputValue={mainApiData && mainApiData.companyData.CompanyRegPinCode}
                  inputTitle={"Company Reg Pin Code"}
                  required={true}
                  getUser={(value: any) => {
                    setIsFormDirty(true)
                    dispatch(resetFormModified(true));
                    const val = value.replace(/[^0-9]/g, "")
                    setMainApiData({
                      ...mainApiData,
                      companyData: {
                        ...mainApiData.companyData,
                        CompanyRegPinCode: val
                      }
                    })
                    setIconStatus({
                      ...iconStatus,
                      addressIcon: "selected"
                    })
                  }}
                />
              </div>
              <div style={{ marginRight: "45px", marginBottom: "30px" }} key={uuidv4()} className={companyOverviewErrors.CompanyMcaStatus ? 'setErrorcompany' : "dropdown-width"}>
                <DropdownComponent
                  width={"300px"}
                  isDisabled={mainApiData?.kycApprovalStatus === "Approved"}
                  title={"Company MCA Status"}
                  required={true}
                  options={[{ label: "Active", value: "Active" }, { label: "InActive", value: "InActive" }]}
                  defaultValue={[{ label: mainApiData?.companyData?.CompanyMcaStatus === undefined ? "Select" : (mainApiData.companyData.CompanyMcaStatus), value: mainApiData && (mainApiData.companyData.CompanyMcaStatus) }]}
                  getData={(value: any) => {
                    setIsFormDirty(true)
                    dispatch(resetFormModified(true));
                    setMainApiData({
                      ...mainApiData,
                      companyData: {
                        ...mainApiData.companyData,
                        CompanyMcaStatus: value.value,
                      }
                    })
                    setIconStatus({
                      ...iconStatus,
                      addressIcon: "selected"
                    })
                  }}
                />
              </div>
            </div>
            <div className='form-main-wrapper'>
              <div>
                <InputComponent
                  height={"40px"}
                  width={"300px"}
                  disabled={mainApiData?.kycApprovalStatus === "Approved"}
                  margin={"0px 45px 30px 0px"}
                  padding={"0px 40px 0px 10px"}
                  borderRadius={"0px"}
                  backgroundColor={"white"}
                  color={"black"}
                  type={"text"}
                  placeHolder={"Enter Company Email"}
                  inputTitle={"Company Email"}
                  required={true}
                  maxLength={254}
                  className={"address-input"}
                  inputValue={mainApiData && (mainApiData.companyData.CompanyEmail !== "" ? mainApiData.companyData.CompanyEmail : "")}
                  inputField={"gmail"}
                  border={companyOverviewErrors.CompanyEmail ? "1px solid red" : "1px solid #A9C3DC"}
                  getUser={(value: any) => {
                    setIsFormDirty(true)
                    dispatch(resetFormModified(true));
                    setMainApiData({
                      ...mainApiData,
                      companyData: {
                        ...mainApiData.companyData,
                        CompanyEmail: value,
                      }
                    })
                    setIconStatus({
                      ...iconStatus,
                      addressIcon: "selected"
                    })
                  }}
                />
              </div>
              <div>
                <InputComponent
                  height={"40px"}
                  width={"300px"}
                  margin={"0px 45px 30px 0px"}
                  padding={"0px 0px 0px 10px"}
                  border={companyOverviewErrors.CompanyWebsite ? "1px solid red" : "1px solid #A9C3DC"}
                  borderRadius={"0px"}
                  backgroundColor={"white"}
                  color={"black"}
                  disabled={mainApiData?.kycApprovalStatus === "Approved"}
                  type={"text"}
                  maxLength={100}
                  placeHolder={"Enter Company Website"}
                  inputTitle={"Company Website"}
                  inputValue={mainApiData && (mainApiData.companyData.CompanyWebsite !== "null" ? mainApiData.companyData.CompanyWebsite : "")}
                  getUser={(value: any) => {
                    const val = value.replace(/^[^A-Za-z0-9]+|[^\w\s\W]/g, "");
                    setIsFormDirty(true)
                    dispatch(resetFormModified(true));
                    setMainApiData({
                      ...mainApiData,
                      companyData: {
                        ...mainApiData.companyData,
                        CompanyWebsite: val,
                      }
                    })
                    setIconStatus({
                      ...iconStatus,
                      addressIcon: "selected"
                    })
                  }}
                />
              </div>
              <div>
                <InputComponent
                  height={"40px"}
                  width={"300px"}
                  margin={"0px 45px 30px 0px"}
                  padding={"0px 0px 0px 70px"}
                  borderRadius={"0px"}
                  backgroundColor={"white"}
                  disabled={mainApiData?.kycApprovalStatus === "Approved"}
                  color={"black"}
                  type={"mobile"}
                  maxLength={10}
                  inputTitle={"Contact Number"}
                  required={true}
                  placeHolder={"Enter Contact Number"}
                  className={"mobilenumber-input"}
                  border={companyOverviewErrors.ContactNumber ? "1px solid red" : "1px solid #A9C3DC"}
                  inputValue={mainApiData && (mainApiData.companyData.ContactNumber !== "" ? mainApiData.companyData.ContactNumber : "")}
                  getUser={(value: any) => {
                    setIsFormDirty(true)
                    dispatch(resetFormModified(true));
                    const val = value.replace(/[^0-9]/g, "")
                    setMainApiData({
                      ...mainApiData,
                      companyData: {
                        ...mainApiData.companyData,
                        ContactNumber: val,
                      }
                    })
                    setIconStatus({
                      ...iconStatus,
                      addressIcon: "selected"
                    })
                  }}
                />
              </div>
              <div>
                <InputComponent
                  height={"40px"}
                  width={"300px"}
                  margin={"0px 45px 30px 0px"}
                  padding={"0px 0px 0px 10px"}
                  border={companyOverviewErrors.ContactPersonName ? "1px solid red" : "1px solid #A9C3DC"}
                  borderRadius={"0px"}
                  backgroundColor={"white"}
                  color={"black"}
                  type={"text"}
                  required={true}
                  maxLength={100}
                  placeHolder={"Enter Contact Person Name"}
                  inputTitle={"Contact Person Name"}
                  inputValue={mainApiData && (mainApiData.companyData.ContactPersonName !== "null" ? mainApiData.companyData.ContactPersonName : "")}
                  getUser={(value: any) => {
                    const val = value.replace(/^[^A-Za-z0-9]+|[^\w\s\W]/g, "");
                    setIsFormDirty(true)
                    dispatch(resetFormModified(true));
                    setMainApiData({
                      ...mainApiData,
                      companyData: {
                        ...mainApiData.companyData,
                        ContactPersonName: val,
                      }
                    })
                    setIconStatus({
                      ...iconStatus,
                      addressIcon: "selected"
                    })
                  }}
                />
              </div>
              <div>
                <InputComponent
                  height={"40px"}
                  width={"300px"}
                  margin={"0px 45px 30px 0px"}
                  padding={"0px 0px 0px 10px"}
                  border={companyOverviewErrors.street_1 ? "1px solid red" : "1px solid #A9C3DC"}
                  borderRadius={"0px"}
                  required={true}
                  backgroundColor={"white"}
                  color={"black"}
                  type={"text"}
                  maxLength={100}
                  placeHolder={"Enter Street 1"}
                  inputTitle={"Street 1"}
                  inputValue={mainApiData && (mainApiData.companyData.street_1 !== "null" ? mainApiData.companyData.street_1 : "")}
                  getUser={(value: any) => {
                    const val = value.replace(/^[^A-Za-z0-9]+|[^\w\s\W]/g, "").replace(" ", "");
                    setIsFormDirty(true)
                    dispatch(resetFormModified(true));
                    setMainApiData({
                      ...mainApiData,
                      companyData: {
                        ...mainApiData.companyData,
                        street_1: val,
                        CompanyFullAddress: ""
                      }
                    })
                    setIconStatus({
                      ...iconStatus,
                      addressIcon: "selected",

                    })
                  }}
                />
              </div>
              <div>
                <InputComponent
                  height={"40px"}
                  width={"300px"}
                  margin={"0px 45px 30px 0px"}
                  padding={"0px 0px 0px 10px"}
                  border={companyOverviewErrors.street_2 ? "1px solid red" : "1px solid #A9C3DC"}
                  borderRadius={"0px"}
                  required={true}
                  backgroundColor={"white"}
                  color={"black"}
                  type={"text"}
                  maxLength={100}
                  placeHolder={"Enter Street 2"}
                  inputTitle={"Street 2"}
                  inputValue={mainApiData && (mainApiData.companyData.street_2 !== "null" ? mainApiData.companyData.street_2 : "")}
                  getUser={(value: any) => {
                    const val = value.replace(/^[^A-Za-z0-9]+|[^\w\s\W]/g, "").replace(" ", "");
                    setIsFormDirty(true)
                    dispatch(resetFormModified(true));
                    setMainApiData({
                      ...mainApiData,
                      companyData: {
                        ...mainApiData.companyData,
                        street_2: val,
                        CompanyFullAddress: ""
                      }
                    })
                    setIconStatus({
                      ...iconStatus,
                      addressIcon: "selected",

                    })
                  }}
                />
              </div>
            </div>

            {/* <div>
              <div className='title'>Address<span className='required'>*</span></div>
              <textarea className='address-textarea' placeholder='Enter Address'
                disabled={mainApiData?.kycApprovalStatus === "Approved"}
                style={{ paddingLeft: "10px", border: (companyOverviewErrors.CompanyFullAddress) ? "1px solid red" : "" }}
                value={mainApiData && (mainApiData.companyData.CompanyFullAddress !== "" ? mainApiData.companyData.CompanyFullAddress : "")}
                maxLength={500} onChange={(e) => {
                  const val = e.target.value.replace(/^\s+/, "");
                  setIsFormDirty(true)
                  dispatch(resetFormModified(true));
                  setMainApiData({
                    ...mainApiData,
                    companyData: {
                      ...mainApiData.companyData,
                      CompanyFullAddress: val,
                    }
                  })
                  setIconStatus({
                    ...iconStatus,
                    addressIcon: "selected"
                  })
                }} />
            </div> */}
            <div className='footer-company'>
              <div >
                {/* <ButtonComponent
                title={"Cancel"}
                height={"50px"}
                width={"150px"}
                margin={"0px 8px"}
                backgroundColor={"#888888"}
                color={"white"}
                className={"button-component-hover cancel"}
                handleClick={isFormModified ? () => { setShowChangesModal(true) } : returnGird}
              /> */}


                <ButtonComponent
                  title={isEditMode ? "Update" : "Next"}
                  height={"50px"}
                  width={"150px"}
                  disabled={mainApiData?.kycApprovalStatus === "Approved"}
                  backgroundColor={"#0055D4"}
                  color={"white"}
                  margin={"0px 8px"}
                  className={mainApiData?.kycApprovalStatus === "Approved" ? "button-component disabled" : "button-component-hover common-btn"}
                  handleClick={next}
                />
              </div>
            </div>
          </div>
        </div>

      </div>
      {
        showAlertBox &&
        <div className='alert-warp'>
          <AlertBox type={showType} message={showMessage} />
        </div>
      }
      {
        showChangesModal &&
        <Changesmodal
          closeModal={() => setShowChangesModal(false)}
          handleClose={() => {
            setShowChangesModal(false);
          }}
          leavePage={() => returnGird()} />
      }
    </>
  )
})
export default Address
