import React, { useEffect, useRef, useState, useCallback } from 'react';
import ButtonComponent from '../common-component/form-elements/button-component';
import ReactCrop, { type Crop } from 'react-image-crop'
import 'react-image-crop/src/ReactCrop.scss'
import useAxiosPrivate from "../../services/hooks/useaxios-private";
import useAuth from '../../services/hooks/useauth';
import { FaUser, FaCamera, FaFolderOpen } from 'react-icons/fa';
import AlertBox from '../common-component/alert-box';
import Webcam from "react-webcam";
import { useDispatch } from 'react-redux';
import { resetFormModified } from '../../redux/action';
import Loading from '../common-component/modals/loading-screen';
import '../../styles/intial-setup/myprofile.scss';
import IconButton from '../common-component/form-elements/icon-button';
import DeleteModal from '../common-component/modals/delete-modal';
import profile from "../../assets/icons/profile.svg"


interface userDetails {
  name: string;
  designation: string;
  mobile: string;
  email: string;
  location: string;
}
interface Props {
  setIsFormDirty: (val: any) => void;
  email: string;
  userGroup: string;
}

export default function MyProfile({ setIsFormDirty, email, userGroup }: Props) {

  // Browse Files Use Ref
  const fileInputRef = useRef<HTMLInputElement>(null);


  const videoConstraints = {
    width: 0,
    height: 0,
    facingMode: "user",
  };

  const [userProfileUrl, setUserProfileUrl] = useState<string>()
  const [userProfileString, setUserProfileString] = useState<string>('')
  const [isImageChanged, setIsImageChanged] = useState<boolean>(false)
  const [loadingScreen, setLoadingScreen] = useState(false)
  const [userDetails, setUserDetails] = useState<userDetails>()
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [crop, setCrop] = useState<Crop>({
    unit: 'px', // Can be 'px' or '%'
    x: 25,
    y: 25,
    width: 100,
    height: 100
  });
  const [showAlertBox, setShowAlertBox] = useState<boolean>(false)
  const [showMessage, setShowMessage] = useState<string>("")
  const [showType, setShowType] = useState<string>("warning")
  const [cameras, setCameras] = useState([]);

  const webcamRef = useRef<any>(null);
  const [imgSrc, setImgSrc] = useState<any>(null);

  const axiosPrivate = useAxiosPrivate();
  const { auth } = useAuth();
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchCameras = async () => {
      try {
        const devices = await navigator.mediaDevices.enumerateDevices();
        const cameras: any = devices.filter(
          (device) => device.kind === 'videoinput'
        );
        setCameras(cameras);
      } catch (error) { }
    };
    fetchCameras();
  }, []);


  // Show User Details Function
  useEffect(() => {
    const getUserDetails = async () => {
      try {
        const resUser = await axiosPrivate.get(`/user/${email}`)
        if (resUser.status === 200) {
          setUserDetails(resUser?.data.user);
        }
      }
      catch (error) {
        console.log(error)
      }
    }
    if (email) {
      getUserDetails()
    }
  }, [email]);

  // Show User Image Function
  useEffect(() => {
    getUserImage()
  }, []);

  const getUserImage = async () => {
    try {
      const resProfile = await axiosPrivate.get(`/user/profile/image/${email}`)
      if (resProfile.status === 200) {
        setUserProfileUrl(resProfile?.data.image);
      }
    }
    catch (error) {
    }
  }

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    dispatch(resetFormModified(true));
    if (cameras.length === 0 && imageSrc === null) {
      setShowAlertBox(true)
      setShowType("warning")
      setShowMessage("No camera detected.")

      setTimeout(() => {
        setShowAlertBox(false);
      }, 5000);
    } else {
      setUserProfileUrl(imageSrc)
      getCroppedImage(imageSrc);
    }
  }, [webcamRef, setImgSrc]);

  // Browse Files Function Button
  const handleFileUpload = async () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    };
  }

  // Browse Files and upload Function Input
  // const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
  //   const fileList = event.target.files;
  //   if (fileList) {
  //     Array.from(fileList).forEach((file) => {
  //       if (file.type.toLocaleLowerCase() === "image/jpeg" || file.type.toLocaleLowerCase() === "image/jpg" || file.type.toLocaleLowerCase() === "image/png") {

  //         const reader = new FileReader();
  //         reader.onload = async () => {
  //           const base64String = reader.result as string;
  //           setUserProfileUrl(base64String)
  //           getCroppedImage(base64String);

  //           event.target.value = '';
  //         };
  //         reader.readAsDataURL(file);
  //       } else {
  //         setShowAlertBox(true);
  //         setShowType('warning');
  //         setShowMessage('File type not allowed.');

  //         setTimeout(() => {
  //           setShowAlertBox(false);
  //         }, 3000);
  //       }
  //     });
  //   }
  // };

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    const fileList = event.target.files;
    if (fileList) {
      const file = fileList[0]; // Assuming only one file is selected
      if (file.type.toLowerCase() === "image/jpeg" || file.type.toLowerCase() === "image/jpg" || file.type.toLowerCase() === "image/png") {
        const reader = new FileReader();
        reader.onload = async () => {
          const base64String = reader.result as string;
          setUserProfileUrl(base64String);
          getCroppedImage(base64String);

          // Clear the selected file by resetting the input value
          event.target.value = ''; // This should clear the input value and allow selecting the same file again
        };
        reader.readAsDataURL(file);
      } else {
        setShowAlertBox(true);
        setShowType('warning');
        setShowMessage('File type not allowed');

        setTimeout(() => {
          setShowAlertBox(false);
        }, 3000);
      }
    }
  };

  // Function for upload user profile image
  const handleUploadImage = async () => {
    setLoadingScreen(true)
    dispatch(resetFormModified(true));
    try {
      const res = await axiosPrivate.post("/user/profile/image", {
        "email": email,
        "image": userProfileString
      })

      if (res.status === 200) {
        setUserProfileUrl(userProfileString);
        setIsImageChanged(false);
        setLoadingScreen(false)
        setIsFormDirty(false);
        dispatch(resetFormModified(false));

        if (typeof userProfileString !== 'undefined') {
          const element: HTMLImageElement | null = document.querySelector(".user-profile-image");
          if (element) {
            element.src = userProfileString;
          }
        }
      }

      if (res.data.message) {
        setShowAlertBox(true)
        setShowType("success")
        setShowMessage(res.data.message)

        setTimeout(() => {
          setShowAlertBox(false);
        }, 3000);
      }

      setLoadingScreen(false)
    }
    catch (error: any) {
      setShowAlertBox(true)
      setShowType("warning")
      setShowMessage(error.response.data.error)
      setLoadingScreen(false)

      setTimeout(() => {
        setShowAlertBox(false);
      }, 3000);
    }

  }

  const handleRemoveImage = async () => {
    try {
      const res = await axiosPrivate.patch(`/user/profile/image-delete/${email}`)

      console.log(res.data)
      if (res.status === 200) {
        setUserProfileUrl("");
        setIsImageChanged(false);
        setLoadingScreen(false)
        setIsFormDirty(false);
        dispatch(resetFormModified(false));
        setUserProfileString("")
        setShowDeleteModal(false)
        setShowAlertBox(true)
        setShowType("success")
        setShowMessage(res.data.message)
        setTimeout(() => {
          setShowAlertBox(false);
        }, 3000);

        getUserImage()

        // if (typeof userProfileString !== 'undefined') {
        const element: HTMLImageElement | null = document.querySelector(".user-profile-image");
        if (element) {
          element.src = profile;
        }
        // } 

      }
    } catch (error: any) {
    }

  }

  // Function for Image Cropped
  const getCroppedImage = async (url = userProfileUrl) => {
    if (url && crop.width && crop.height) {
      const image = new Image();
      image.src = url;
      image.onload = async () => {
        const containerSize = 150;
        const containerWidth = containerSize;
        const containerHeight = containerSize;

        const width = image.width * (crop.width! / containerWidth);
        const height = image.height * (crop.height! / containerHeight);
        const x = (crop.x! / containerWidth) * image.width;
        const y = (crop.y! / containerHeight) * image.height;

        const canvas = document.createElement('canvas');
        canvas.width = containerWidth;
        canvas.height = containerHeight;

        const context = canvas.getContext('2d');
        context?.drawImage(image, x, y, width, height, 0, 0, containerWidth, containerHeight);

        const blobPromise = new Promise<Blob | null>((resolve) => {
          canvas.toBlob((blob) => resolve(blob), 'image/jpeg', 0.8);
        });

        const blob = await blobPromise;
        if (!blob) return;

        const base64 = await getBase64FromBlob(blob);
        setUserProfileString(base64);
        setIsImageChanged(true);
        setIsFormDirty(true);
        dispatch(resetFormModified(true));
      }
    }
  };

  // Function for Set a Base64 Blob
  const getBase64FromBlob = (blob: Blob): Promise<string> => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => resolve(reader.result as string);
    });
  };

  return (
    <>
      <div className='myprofile-container'>

        <h5>{"My Profile"}</h5>
        <h6>{"Add my photo"}</h6>
        <div className='take-photo'>
          <div>
            <FaCamera className='camera-icon' />
            {"Take a photo"}
          </div>
          <ButtonComponent
            title={"Open Camera"}
            height={"5.5vh"}
            width={"16vh"}
            backgroundColor={"#0055D4"}
            color={"white"}
            className="button-component"
            handleClick={capture}
          />
        </div>

        <div className='file-choosing'>
          <div className='file-formats'>
            <div>
              <FaFolderOpen className='folder-icon' />
              {"Choose a file"}
            </div>
            <div>{"(Formats allowed:"} <b>JPG, PNG</b>.{")"}</div>
          </div>
          <ButtonComponent
            title={"Browse Files"}
            height={"5.5vh"}
            width={"16vh"}
            backgroundColor={"#0055D4"}
            color={"white"}
            className="button-component"
            handleClick={handleFileUpload}
          />
          <input
            id={'input-file'}
            type={"file"}
            ref={fileInputRef}
            key={userProfileUrl}
            style={{ display: 'none' }}
            onChange={handleFileChange}
            accept={"image/jpeg, image/png"}
          />
        </div>
        <h6>My Info</h6>
        <div className='my-info'>
          <Webcam
            audio={false}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            videoConstraints={videoConstraints}
            minScreenshotWidth={180}
            minScreenshotHeight={180}
            style={{ height: "0px", width: "0px" }}
          />
          <div style={{ position: "relative" }}>

            <div className='user-profile'>
              {userProfileUrl && isImageChanged ?
                <ReactCrop
                  crop={crop}
                  onChange={c => setCrop(c)}
                  locked
                  onComplete={() => getCroppedImage()}
                >
                  <img src={userProfileUrl} draggable={"false"} />
                </ReactCrop>
                :
                (userProfileUrl ?
                  <img src={userProfileUrl} className='user-face-icon user-profile-icon' alt={"Profile Image"} draggable={"false"} /> :
                  <FaUser className='user-face-icon' />)
              }
            </div>

            {
              userProfileUrl &&
              <div style={{ position: "absolute", bottom: "0px", left: "121px" }}>
                <IconButton
                  iconName={"Delete"}
                  height={"28px"}
                  width={"28px"}
                  fontSize={"20"}
                  className={"icons"}
                  cursor={userProfileUrl ? "pointer" : "not-allowed"}
                  disabled={userProfileUrl ? false : true}
                  opacity={userProfileUrl ? "1" : "0.5"}
                  color={"white"}
                  backgroundColor={"red"}
                  hover={userProfileUrl ? true : false}
                  handleClick={() => {
                    if (userProfileUrl) {
                      setShowDeleteModal(true)
                    }
                  }}
                />
              </div>
            }


          </div>
          <div className='user-details'>
            <li>
              <div className='info-title'>{"Name"}</div>:
              <div>{userDetails?.name}</div>
            </li>
            <li>
              <div className='info-title'>{"Group"}</div>:
              <div>{userGroup}</div>
            </li>
            <li>
              <div className='info-title'>{"Mobile"}</div>:
              <div>{userDetails?.mobile}</div>
            </li>
            <li>
              <div className='info-title'>{"E-mail"}</div>:
              <div>{userDetails?.email}</div>
            </li>
          </div>
        </div>
      </div>

      <div className={"my-profile-buttons"}>
        <div className={"buttons save-Button"} >
          <ButtonComponent
            title={"Save"}
            height={"50px"}
            width={"130px"}
            backgroundColor={"#0055D4"}
            color={"white"}
            className={isImageChanged ? "button-component common-btn" : "button-component disabled"}
            handleClick={handleUploadImage}
            disabled={!isImageChanged}
          />
        </div>
      </div>

      {showAlertBox && <AlertBox type={showType} message={showMessage} />}
      {loadingScreen && <Loading />}
      {showDeleteModal && <DeleteModal     //Delete Button Component
        clearValue={(val: boolean) => { setShowDeleteModal(val); }}
        getDelete={handleRemoveImage}
        modelType={"profile-image"}
      />}
    </>
  )
}
