import React, { useState, useRef, ChangeEvent, KeyboardEvent, useEffect } from 'react'
import '../../../styles/modals/pin-reset-modal.scss';
import { RxCross1 } from 'react-icons/rx';
import useAuth from '../../../services/hooks/useauth';
import useAxiosPrivate from "../../../services/hooks/useaxios-private";
import { IoIosEye, IoIosEyeOff } from 'react-icons/io';
import ButtonComponent from '../form-elements/button-component';
import AlertBox from '../alert-box';


interface Props {
    getUser: (val: any) => void;
    resetpinNotify: (val: string) => void;
    isLocked: boolean
    setIsLocked: (val: any) => void;
    email: string;
}

export default function Pinresetmodal({
    getUser,
    resetpinNotify,
    isLocked,
    setIsLocked,
    email 
}: Props) {

    const length = 6; 
    const { auth, setAuth } = useAuth();
    const axiosPrivate = useAxiosPrivate();

    const [currentModal, setCurrentModal] = useState<string | null>("manage-pin")
    const [currentProcess, setCurrentProcess] = useState<string | null>("")
    const [userPin, setUserPin] = useState<string[]>(Array(length).fill('#'))
    const [enterPin, setEnterPin] = useState<string>("")
    const [newPin, setNewPin] = useState<string[]>(Array(length).fill(''))
    const [switches, setSwitches] = useState<string>("password")
    const [pinVisible, setPinVisible] = useState<boolean>(false)
    const [showPassword, setShowPassword] = useState<boolean>()
    const [otp, setOTP] = useState<string[]>(Array(length).fill(''));
    const [showAlertBox, setShowAlertBox] = useState<boolean>(false)  //AlertBox State
    const [showMessage, setShowMessage] = useState<string>("")   //AlertBox Message State
    const [showType, setShowType] = useState<string>("warning")   //AlertBox Type State
    const [attemptsRemaining, setAttemptsRemaining] = useState(3);
    const [errorMessage, setErrorMessage] = useState("otp-number");
    const [disabledResentotp, setDisabledResentotp] = useState<boolean>(false);

    useEffect(() => {
        if (auth.otpCountRemaining) {
            setAttemptsRemaining(auth.otpCountRemaining)
        }
    }, [auth.otpCountRemaining])

    // Functions for PIN field 
    const inputRefs = useRef<HTMLInputElement[]>([]);

    const handleChange = (e: ChangeEvent<HTMLInputElement>, index: number, inputName: string) => {
        if (inputName === "new-pin") {
            const value = e.target.value;
            if (!(/^\d*$/.test(value))) return; // Regex for only  Number in input field
            if (e.target.value.length > 1) return;
            const updatedPIN = [...newPin];
            updatedPIN[index] = e.target.value;
            setNewPin(updatedPIN);

            if (e.target.value !== '' && index < length - 1) {
                inputRefs.current[index + 1].focus();
            }
        } else if (inputName === "otp") {
            const value = e.target.value;
            if (!(/^\d*$/.test(value))) return; // Regex for only  Number in input field
            if (e.target.value.length > 1) return;
            const updatedOTP = [...otp];
            updatedOTP[index] = e.target.value;
            setOTP(updatedOTP);
            if (e.target.value !== '' && index < length - 1) {
                inputRefs.current[index + 1].focus();
            }
        }
    }

    const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>, index: number, inputName: string) => {
        if (inputName === "new-pin") {
            if (e.key === 'Backspace' && index > 0 && newPin[index] === '') {
                inputRefs.current[index - 1].focus();
            }
        } else if (inputName === "otp") {
            if (e.key === 'Backspace' && index > 0 && otp[index] === '') {
                inputRefs.current[index - 1].focus();
            }
        }

        if (e.key == 'Enter') {
            if (currentModal === "enter-otp") {
                otpSubmit()
            } else if (currentModal === "reset-password") {
                handleUpdatePin()
            }
        }
    }

    const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>, inputName: string) => {
        e.preventDefault();
        if (inputName === "new-pin") {
            const pasteData = e.clipboardData.getData('text/plain');
            const numbersOnly = pasteData.replace(/\D/g, '');
            const newPIN = numbersOnly.slice(0, length).split('');
            const updatedPIN = newPIN.concat(Array(length - newPIN.length).fill(''));
            setNewPin(updatedPIN);
            inputRefs.current[0].focus();
        }
        else if (inputName === "otp") {
            const pasteData = e.clipboardData.getData('text/plain');
            const numbersOnly = pasteData.replace(/\D/g, '');
            const newOTP = numbersOnly.slice(0, length).split('');

            const updatedOTP = newOTP.concat(Array(length - newOTP.length).fill(''));

            setOTP(updatedOTP);
            inputRefs.current[0].focus();
        }
    }

    // Async Function for Show Pin
    async function otpSubmit() {

        if (currentProcess === "view-pin") {
            const combinedOtp = otp.join("")
            try {
                const res = await axiosPrivate.post("/verify/otp/show-pin", {
                    "email": email,
                    "otp": combinedOtp
                })

                if (res.status === 200) {
                    const currentPin = res.data.userPin.split('');
                    setUserPin(currentPin)
                    setCurrentModal("manage-pin");
                    setPinVisible(true)
                    setShowPassword(false)
                    setShowAlertBox(true)
                    setShowType("success")
                    setShowMessage(res.data.message)

                    setTimeout(() => {
                        setShowAlertBox(false);
                    }, 3000);
                }
            }
            catch (error: any) {
                if (error.response.data.error) {
                    setShowAlertBox(true)
                    setShowType("warning")
                    setShowMessage(error.response.data.error)
                }
                setErrorMessage("wrong-otp")

                if (error?.response?.data?.isLocked === "true") {
                    setIsLocked(true)
                }

                setAuth({
                    ...auth,
                    otpCountRemaining: error.response.data.otpRemainingCount
                })
                setAttemptsRemaining(error.response.data.otpRemainingCount)

                setTimeout(() => {
                    setShowAlertBox(false);
                }, 3000);
            }
        } else if (currentProcess === "reset-pin") {
            const combinedOtp = otp.join("")
            try {
                const res = await axiosPrivate.post("/verify/otp/sign-in", {
                    "email": email,
                    "otp": combinedOtp
                })

                if (res.status === 200) {
                    setCurrentModal("reset-password");
                    setPinVisible(true)
                    setShowPassword(false)
                    setShowAlertBox(true)
                    setShowType("success")
                    setShowMessage(res.data.message)

                    setTimeout(() => {
                        setShowAlertBox(false);
                    }, 3000);
                }
            }
            catch (error: any) {
                if (error.response.data.error) {
                    setShowAlertBox(true)
                    setShowType("warning")
                    setShowMessage(error.response.data.error)
                }
                setErrorMessage("wrong-otp")

                if (error?.response?.data?.isLocked === "true") {
                    setIsLocked(true)
                }

                setAuth({
                    ...auth,
                    otpCountRemaining: error.response.data.otpRemainingCount
                })
                setAttemptsRemaining(error.response.data.otpRemainingCount)

                setTimeout(() => {
                    setShowAlertBox(false);
                }, 3000);
            }
        }
    }

    // Function for Resend OTP
    async function resendOtpVerify() {

        if (currentProcess === "view-pin") {
            const updatedOtp = Array(length).fill('');
            setOTP(updatedOtp);
            setErrorMessage("otp-number")
            setDisabledResentotp(true)
            try {
                const res = await axiosPrivate.post("/resend/otp/sign-in/pin/manage", { "email": email, })

                if (res.status === 200) {
                    setShowAlertBox(true)
                    setShowType("success")
                    setShowMessage(res.data.message)

                    setTimeout(() => {
                        setShowAlertBox(false);
                    }, 3000);
                }
            }
            catch (error: any) {
                if (error.response.data.error) {
                    setShowAlertBox(true)
                    setShowType("warning")
                    setShowMessage(error.response.data.error)
                }
                setErrorMessage("wrong-otp")

                if (error?.response?.data?.isLocked === "true") {
                    setIsLocked(true)
                }

                setTimeout(() => {
                    setShowAlertBox(false);
                }, 3000);
            } finally {
                setDisabledResentotp(false)
            }
        } else if (currentProcess === "reset-pin") {
            const updatedOtp = Array(length).fill('');
            setOTP(updatedOtp);
            setErrorMessage("otp-number")
            setDisabledResentotp(true)
            try {
                const res = await axiosPrivate.post("/resend/otp/sign-in/pin/reset", { "email": email, })

                if (res.status === 200) {
                    setShowAlertBox(true)
                    setShowType("success")
                    setShowMessage(res.data.message)

                    setTimeout(() => {
                        setShowAlertBox(false);
                    }, 3000);
                }
            }
            catch (error: any) {
                if (error.response.data.error) {
                    setShowAlertBox(true)
                    setShowType("warning")
                    setShowMessage(error.response.data.error)
                }
                setErrorMessage("wrong-otp")

                if (error?.response?.data?.isLocked === "true") {
                    setIsLocked(true)
                }

                setTimeout(() => {
                    setShowAlertBox(false);
                }, 3000);
            }
            finally {
                setDisabledResentotp(false)
            }
        }

    }

    // Function for identify the Pin number rules 
    const isValidArray = (newPin: string[]): boolean => {
        for (let i = 0; i < newPin.length - 2; i++) {
            const num1 = parseInt(newPin[i], 10);
            const num2 = parseInt(newPin[i + 1], 10);
            const num3 = parseInt(newPin[i + 2], 10);

            // Check for more than 2 sequential ascending or descending index values
            if ((num2 - num1 === 1 && num3 - num2 === 1) || (num1 - num2 === 1 && num2 - num3 === 1)) {
                return false; // More than 2 sequential index values (ascending or descending)
            }

            // Check for repeating numbers in nearest index values
            if (num1 === num2 || num2 === num3) {
                return false; // Repeating numbers in nearest index values
            }
        }
        return true;
    };

    // Async Function for Enter password to Update Password
    async function handleUpdatePin() {
        const isValidPin = isValidArray(newPin)
        if (isValidPin) {
            let combinedPin = newPin.join("")
            try {
                const res = await axiosPrivate.patch("/sigin-in/options/update/pin", {
                    "email": email,
                    "currentPassword": enterPin,
                    "newPin": combinedPin,
                })
                if (res.status === 200) {
                    resetpinNotify("success");
                    getUser(false);
                    setShowPassword(false)  //toggle control state to show password
                }
            }
            catch (error: any) {
                if (error.response.data.error) {
                    setShowAlertBox(true)
                    setShowType("warning")
                    setShowMessage(error.response.data.error)
                }

                setTimeout(() => {
                    setShowAlertBox(false);
                }, 3000);
            }
        } else {
            setShowAlertBox(true)
            setShowType("warning")
            setShowMessage("Your PIN cannot have sequential numbers or repeated numbers.")
            setTimeout(() => {
                setShowAlertBox(false);
            }, 3000);
        }
    }

    // Function for Show PIN
    const handleShowPin = async () => {

        if (pinVisible) return;
        setCurrentModal("enter-otp")
        setSwitches("text")
        setShowMessage("")

        try {
            const res = await axiosPrivate.post("/otp/pin/manage", {
                "email": email
            })

            if (res.status === 200) {
                setCurrentProcess("view-pin")
                setAuth({
                    ...auth,
                    otpCountRemaining: res.data.otpRemainingCount
                })
                setAttemptsRemaining(res.data.otpRemainingCount)
                setShowAlertBox(true)
                setShowType("success")
                setShowMessage(res.data.message)
                setTimeout(() => {
                    setShowAlertBox(false);
                }, 3000);
            }
        }
        catch (error: any) {
            if (error.response.data.error) {
                setShowAlertBox(true)
                setShowType("warning")
                setShowMessage(error.response.data.error)
            }
            setErrorMessage("wrong-otp")

            setTimeout(() => {
                setShowAlertBox(false);
            }, 3000);
        }
    }

    // Function for Reset Button
    const handleResetButton = async () => {
        setCurrentModal("enter-otp")
        setErrorMessage("otp-number")
        const updatedOtp = Array(length).fill('');
        setOTP(updatedOtp);
        try {
            const res = await axiosPrivate.post("/otp/pin/showPin", {
                "email": email
            })

            if (res.status === 200) {
                setCurrentProcess("reset-pin")

                setAuth({
                    ...auth,
                    otpCountRemaining: res.data.otpRemainingCount
                })
                setAttemptsRemaining(res.data.otpRemainingCount)

                setShowAlertBox(true)
                setShowType("success")
                setShowMessage(res.data.message)
                setTimeout(() => {
                    setShowAlertBox(false);
                }, 3000);
            }
        }
        catch (error: any) {
            if (error.response.data.error) {
                setShowAlertBox(true)
                setShowType("warning")
                setShowMessage(error.response.data.error)
            }
            setErrorMessage("wrong-otp")

            setTimeout(() => {
                setShowAlertBox(false);
            }, 3000);
        }
    }

    // Function for toggle to show password
    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };


    return (
        <>

            {/* After Manage Pin is Clicked */}
            {currentModal === "manage-pin" &&
                <div className={"pin-setup-modal"}>
                    <div className={"pin-setup-container"}>
                        <div className={"close-icon"}>
                            <span className={"cross-icon"}>
                                <RxCross1 style={{ fontSize: "40px" }} onClick={() => { getUser(false) }} />
                            </span>
                        </div>
                        <div className={'pin-setup-title'}>
                            <div className={"title"}>{"Manage PIN"}</div>
                            <div className={"sub-title"}>{"Current PIN"}</div>
                            <div className={"content"}>
                                <div className={'pin-number'}>
                                    {userPin.map((digit, index) => (
                                        <input
                                            key={index}
                                            type="text"
                                            value={digit}
                                            maxLength={1}
                                            onChange={(e) => handleChange(e, index, "pin")}
                                            onKeyDown={(e) => handleKeyPress(e, index, "pin")}
                                            onPaste={(e) => handlePaste(e, "pin")}
                                            ref={(ref) => (inputRefs.current[index] = ref as HTMLInputElement)}
                                            data-testid="pin-digit-input"
                                            readOnly
                                        />
                                    ))}
                                </div>

                                {pinVisible ? <div className='eyeCloseIcon' style={{ cursor: pinVisible ? "default" : "pointer" }}>
                                    <IoIosEyeOff
                                        style={{ fontSize: "35px", color: "#0055D4", padding: "5px" }}
                                    /></div> : <div className='eyeCloseIcon' style={{ cursor: pinVisible ? "default" : "pointer" }}>
                                    <IoIosEye
                                        style={{ fontSize: "35px", color: "#0055D4", padding: "5px" }}
                                        onClick={handleShowPin}
                                    /></div>}
                            </div>
                            <div className='resetPin'>
                                <a href='#' onClick={handleResetButton}>Reset PIN</a>
                            </div>
                            <div className={"buttons"} >
                                <ButtonComponent
                                    title={"Cancel"}
                                    height={"50px"}
                                    width={"130px"}
                                    backgroundColor={"#888888"}
                                    color={"white"}
                                    className={"button-component-hover cancel"}
                                    handleClick={() => { getUser(false) }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            }

            {/* After reset & Eye Button Is Clicked */}
            {currentModal === "enter-otp" &&
                <div className={"otp-verification-modal"}>
                    <div className={"container"}>
                        <div className={"close-icon"}>
                            <span className={"cross-icon"}>
                                <RxCross1 style={{ fontSize: "40px" }} onClick={() => { getUser(false) }} />
                            </span>
                        </div>
                        <div className={'pin-setup-title'}>
                            <div className={"title"}>{"Making sure it's you"}</div>
                            <div className={"main-title"}>
                                <p className={'sub-title-otp'}>{"OTP verification"}</p>
                                <p>{"Enter first 3 digits of the OTP sent to your Mobile and last 3 digits sent to your Email."}</p>
                                <p>{"This OTP will expire in 3 mins."}</p>
                            </div>
                            <div className={"content"}>
                                <div className={'input-Main'}>
                                    <div className={errorMessage === "wrong-otp" ? "otp-number wrong-otp" : "otp-number"}>

                                        {otp.map((digit, index) => (
                                            <input
                                                key={index}
                                                type="text"
                                                className={`${digit === "" ? (index < 3 ? 'mobile-placeholder' : 'email-placeholder') : ''}`}
                                                value={digit}
                                                maxLength={1}
                                                onChange={(e) => handleChange(e, index, "otp")}
                                                onKeyDown={(e) => handleKeyPress(e, index, "otp")}
                                                onPaste={(e) => handlePaste(e, "otp")}
                                                ref={(ref) => (inputRefs.current[index] = ref as HTMLInputElement)}

                                            />
                                        ))}

                                    </div></div>
                            </div>

                            <div className='resend-text'>Didn't get a code?<button className='resend-button'
                                disabled={disabledResentotp || isLocked}
                                onClick={resendOtpVerify}>Click to resend.</button></div>

                            <div className={"buttons"} >
                                <ButtonComponent
                                    title={"Submit"}
                                    height={"50px"}
                                    width={"130px"}
                                    backgroundColor={"#0055D4"}
                                    color={"white"}
                                    handleClick={otpSubmit}
                                    disabled={otp.filter((digit) => { return digit === "" }).length > 0 || isLocked}
                                    className={otp.filter((digit) => { return digit === "" }).length > 0 || isLocked ? "button-component-hover disabled" : "button-component common-btn"}
                                />
                            </div>
                            <div className='resend-timing'>{"*Attempts remaining: " + attemptsRemaining}</div>
                        </div>
                    </div>
                </div>
            }

            {/* Reset Pin Component */}
            {currentModal === "reset-password" &&
                <div className={"pin-resetup-modal"}>
                    <div className={"pin-resetup-container"}>
                        <div className={"close-icon"}>
                            <span className={"cross-icon"}>
                                <RxCross1 style={{ fontSize: "40px" }} onClick={() => { getUser(false) }} />
                            </span>
                        </div>
                        <div className={'pin-resetup-title'}>
                            <div className={"title"}>{"Manage PIN"}</div>
                            <div className={"sub-title"}>{"Enter New PIN"}</div>
                            <div className={"content"}>
                                <div className='pin-number'>
                                    {newPin.map((digit, index) => (
                                        <input
                                            key={index}
                                            type={showPassword ? 'text' : 'password'}
                                            value={digit}
                                            maxLength={1}
                                            onChange={(e) => handleChange(e, index, "new-pin")}
                                            onKeyDown={(e) => handleKeyPress(e, index, "new-pin")}
                                            onPaste={(e) => handlePaste(e, "new-pin")}
                                            ref={(ref) => (inputRefs.current[index] = ref as HTMLInputElement)}
                                            data-testid="pin-digit-input"
                                        />
                                    ))}
                                </div>
                                {showPassword ? <div className='eyeCloseIcon' style={{ cursor: "pointer" }}>
                                    <IoIosEyeOff
                                        style={{ fontSize: "35px", color: "#0055D4", padding: "5px" }}
                                        onClick={toggleShowPassword}
                                    /></div> : <div className='eyeCloseIcon' style={{ cursor: "pointer" }}>
                                    <IoIosEye
                                        style={{ fontSize: "35px", color: "#0055D4", padding: "5px" }}
                                        onClick={toggleShowPassword}
                                    /></div>}

                            </div>
                            <div className={"buttons"} >
                                <ButtonComponent
                                    title={"Cancel"}
                                    height={"50px"}
                                    width={"130px"}
                                    backgroundColor={"#888888"}
                                    color={"white"}
                                    className={"button-component-hover cancel"}
                                    handleClick={() => { getUser(false) }}
                                />

                                <ButtonComponent
                                    title={"Save"}
                                    height={"50px"}
                                    width={"130px"}
                                    backgroundColor={"#0055D4"}
                                    color={"white"}
                                    handleClick={handleUpdatePin}
                                    disabled={newPin.filter((digit) => { return digit === "" }).length > 0}
                                    className={newPin.filter((digit) => { return digit === "" }).length > 0 ? "button-component-hover disabled" : "button-component common-btn"}
                                />
                            </div>
                        </div>

                    </div>
                </div>
            }
            {showAlertBox && <AlertBox type={showType} message={showMessage} />}

        </>
    )
}

