import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS
import App from './app';
import { AuthProvider } from './services/context/auth-provider';
import { ChangemodalProvider } from './services/context/changemodal-provider';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <AuthProvider>
      <ChangemodalProvider>
        <App />
      </ChangemodalProvider>
    </AuthProvider>
  </React.StrictMode>
);