import React from "react";

const CustomEllipsisRenderer: React.FC<any> = (params: any) => {
    const value = params.value || "";
    const maxLength = 30; // Maximum characters allowed before truncation

    let displayValue = value;
    if (value.length > maxLength) {
        displayValue = value.substring(0, maxLength) + "...";
    }

    const cellStyle: React.CSSProperties = {
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden",
        paddingRight: "3px",
    };

    return <div style={cellStyle}>{displayValue}</div>;
};

export default CustomEllipsisRenderer;

export const bankColumnData = [
    {
        field: 'selection', headerName: '', minWidth: 60, maxWidth: 60, resizable: false, suppressMovable: true, filter: "agSetColumnFilter", suppressSizeToFit: false, headerCheckboxSelection: true, checkboxSelection: true, showDisabledCheckboxes: true, suppressRowTransform: true, cellStyle: { textAlign: 'center' }
    },
    {
        field: 'customerId', headerName: 'Customer ID', minWidth: 200, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "number", cellRendererFramework: CustomEllipsisRenderer
    },
    {
        field: 'accountNumber', headerName: 'Account Number', minWidth: 200, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "number", cellRendererFramework: CustomEllipsisRenderer
    },
    {
        field: 'bank', headerName: 'Bank', minWidth: 200, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer
    },
    {
        field: 'ifsc', headerName: 'IFSC Code', minWidth: 200, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer
    },
    {
        field: 'branch', headerName: 'Branch', minWidth: 200, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer
    },
    // {
    //     field: 'address', headerName: 'Address', minWidth: 200, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype : "text"
    // },

    {
        field: 'status', headerName: 'Status', minWidth: 200, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellClassRules: {
            'success-cell': (params: any) => params.value === 'Approved',
            'registered-cell': (params: any) => params.value === 'Pending Approval',
            'denied-cell': (params: any) => params.value === 'Declined',
        }, cellRendererFramework: CustomEllipsisRenderer
    },
    {
        field: 'approvedBy', headerName: 'Approved / Declined by', minWidth: 200, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer
    },
    {
        field: 'priority', headerName: 'Priority', minWidth: 200, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer
    },
    {
        field: 'isActive', headerName: 'Is Active', minWidth: 200, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype: "text", cellRendererFramework: CustomEllipsisRenderer
    },

    // {
    //     field: 'remarks', headerName: 'Remarks', minWidth: 200, resizable: false, suppressMovable: true, filter: false, suppressSizeToFit: false, cellStyle: { textAlign: 'left' }, filtertype : "text"
    // },



]


export const rowDatathis = [
    {
        accountNumber: "4561237890",
        bank: "ICICI",
        ifscCode: "UTICICI01",
        branch: "Bhubaneswar",
        address: "Odisha",
        priority: "01",
        isActive: "Yes",
        status: "Approved",
        remarks: "",
        approvedBy: "Nagarajan",

    },
    {
        accountNumber: "6554342322121",
        bank: "Axis",
        ifscCode: "UTIB0045",
        branch: "Bhubaneswar",
        address: "Odisha",
        priority: "02",
        isActive: "Yes",
        status: "Approved",
        remarks: "",
        approvedBy: "Nagarajan",
    },
    {
        accountNumber: "54512124212143",
        bank: "SBI",
        ifscCode: "BNNK325",
        branch: "Bhubaneswar",
        address: "Odisha",
        priority: "03",
        isActive: "Yes",
        status: "Approved",
        remarks: "",
        approvedBy: "Nagarajan",
    },
    {
        accountNumber: "254242542214",
        bank: "HSBC",
        ifscCode: "UTICICI01",
        branch: "Bhubaneswar",
        address: "Odisha",
        priority: "04",
        isActive: "Yes",
        status: "Approved",
        remarks: "",
        approvedBy: "Nagarajan",
    },

]