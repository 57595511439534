import React, { useEffect, useState, forwardRef, useImperativeHandle, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { resetFormModified } from '../../../../redux/action';
import useAxiosPrivate from '../../../../services/hooks/useaxios-private';
import DropdownComponent from '../../../common-component/form-elements/dropdown-component';
import { v4 as uuidv4 } from 'uuid'
import InputComponent from '../../../common-component/form-elements/input-component';
import ButtonComponent from '../../../common-component/form-elements/button-component';
import Changesmodal from '../../../common-component/modals/changes-modal';
import AlertBox from '../../../common-component/alert-box';



export interface ChildRef {
  next: (val: any) => void;
}

interface DropdownItem {
  label: string;
  value: string;
}


interface props {
  mainApiData: any
  setMainApiData: (val: any) => void;
  onboardingViewfun: (val: any) => void;
  isEditMode: boolean;
  iconStatus: any;
  editData: any;
  setShowPage: (val: any) => void;
  sendDataApi: () => void;
  setIconStatus: (val: any) => void;
}

const Address = forwardRef<ChildRef, props>(({ mainApiData, isEditMode, onboardingViewfun, setMainApiData, sendDataApi, iconStatus, editData, setShowPage, setIconStatus }, ref) => {
  const [companyOverviewErrors, setCompanyOverviewErrors] = useState<Record<string, boolean>>({})
  const [addressState, setAddressState] = useState<any[]>([]);
  const [addressCities, setAddressCities] = useState<any[]>([]);
  const [isFormDirty, setIsFormDirty] = useState(false)
  const [showType, setShowType] = useState("warning") // error message showType
  const [showMessage, setShowMessage] = useState<string>("") // error message showMessage
  const [showAlertBox, setShowAlertBox] = useState(false) // error message ShowAlertBox
  const isFormModified = useSelector((state: any) => state.isFormModified);
  const dispatch = useDispatch();
  const [showChangesModal, setShowChangesModal] = useState<boolean>(false);
  const [dropDownState, setDropDownState] = useState<DropdownItem[]>([])
  const [dropDownCities, setDropDownCities] = useState<DropdownItem[]>([])
  const [dropDownRocCities, setDropDownRocCities] = useState<DropdownItem[]>([])



  useEffect(() => {
    setMainApiData({
      ...mainApiData,
      country: "India",
    })
  }, [mainApiData?.country])


  const userValidationRules: any = {
    companyData: {
      country: {
        regex: "",
        field: "",
        shouldNotBe: ""
      },
      state: {
        regex: "",
        field: "mandatory",
        shouldNotBe: ""
      },
      city: {
        regex: "",
        field: "mandatory",
        shouldNotBe: ""
      },


      pinCode: {
        regex: /^[0-9]{6}$/,
        field: "mandatory",
        shouldNotBe: ""
      },

      website: {
        regex: "",
        field: "",
        shouldNotBe: ""
      },

      address: {
        regex: "",
        field: "mandatory",
        shouldNotBe: ""
      },
    }
  }

  const updatedIsValueValidAll: Record<string, boolean> = {};
  const validateForm = (): boolean => {
    const updatedFieldValidity: Record<string, boolean> = {};
    const isMandatoryfalse = true;
    const isRegexfalse = true;
    Object.keys(userValidationRules.companyData).forEach((field) => {
      const rule = userValidationRules.companyData[field];
      const value = mainApiData[field];
      const isValueValid = (rule.field === "mandatory") ? (value ? (value !== rule.shouldNotBe) : false) : true;
      const isRegexValid = rule.regex !== "" ? new RegExp(rule.regex).test(value) : true;
      updatedFieldValidity[field] = !isValueValid || !isRegexValid;
      updatedIsValueValidAll[field] = !isValueValid;
    });
    setCompanyOverviewErrors(updatedFieldValidity);
    const isFormValid = Object.keys(updatedFieldValidity).every((keys) => {
      return !updatedFieldValidity[keys]
    });
    return isFormValid;
  };

  function returnGird() {
    onboardingViewfun("grid")
    dispatch(resetFormModified(false));
  }

  useEffect(() => {
    fetchData("state", setDropDownState, "states", "stateId", "name");
 if (isEditMode) {
    fetchData(`state/cities/${editData?.customerDetails?.address?.stateId}`, setDropDownRocCities, 'city', "cityId", "name");
}
  }, [])

  useEffect(() => {
    fetchAddressValues()
    fetchAddressCity()
  }, [])

  const axiosPrivate = useAxiosPrivate();

  const fetchAddressValues = async () => {
    try {
      const response = await axiosPrivate.get("state");
      if (response.status === 200) {
        const dropdownValues = response.data.states.map((item: any) => ({
          label: item.name,
          value: item.name,
          id: item.stateId,
        }));
        setAddressState(dropdownValues);
      }
    } catch (error) {
    }
  };

  const fetchAddressCity = async () => {
    try {
      const response = await axiosPrivate.get("cities");
      if (response.status === 200) {
        const dropdownValues = response.data.cities.map((item: any) => ({
          label: item.name,
          value: item.name,
          id: item.cityId,
        }));
        setAddressCities(dropdownValues);
      }
    } catch (error) {
    }
  };

  const fetchData = async (url: string, setData: (data: any[]) => void, nestedProperty: string, id: any, representName: any) => {
    try {
      const response = await axiosPrivate.get(url);
      if (response.status === 200) {
        const dropdownValues = response.data[nestedProperty].map((item: any) => ({
          label: item[representName],
          value: item[representName],
          id: item[id],
        }));
        const sortedData = [...dropdownValues].sort((a, b) => a.label.localeCompare(b.label));
        // { label: "Select", value: "Select", id: "" }, 
        setData([...sortedData]);

      }
    } catch (error) {
    }
  };


  const clearAlert = (status: any) => {
    const timer = setTimeout(() => {
      setShowAlertBox(false);
      setShowMessage("");
      if (status === 400) {

      }
      clearTimeout(timer);
    }, 5000);
  }

  // next function 
  async function next(next: any = "") {
    setShowPage(next != "" ? next : "pointofContact")
    if (next != "") {
      switch (next) {
        case "CompanyOverview":
          setIconStatus({
            ...iconStatus,
            CustomerIcon: "selected",
          })
          break;
        case "Address":
          setIconStatus({
            ...iconStatus,
            CustomerIcon: "completed",
            addressIcon: "selected",
          })
          break;
        case "pointofContact":
          setIconStatus({
            ...iconStatus,
            pointofContact: "completed",

          })
          break;
        default:
          break;
      }

    } else {
      setIconStatus({
        ...iconStatus,
        CustomerIcon: "completed",
        addressIcon: "completed"
      })
    }
  }

  useImperativeHandle(ref, () => {
    return {
      next: next
    }
  });


  return (
    <div className='form-wrapper'>
      <div className='form-area-address'>
        <div className='company-heading' style={isEditMode ?
          { pointerEvents: "none", opacity: 0.5 } : { pointerEvents: "auto", opacity: 1 }}>
          Registered Address
        </div>
        <div className='form-main-wrapper' style={isEditMode ?
          { pointerEvents: "none", opacity: 0.5 } : { pointerEvents: "auto", opacity: 1 }}>
          <div style={{ opacity: "0.7", marginRight: "45px" }} className={companyOverviewErrors.country ? 'setErrorcompany' : "dropdown-margin dropdown-width"}>
            <DropdownComponent
              width={"300px"}
              required={true}

              title={"Country"}
              defaultValue={[{ label: "India", value: "India" }]}
              options={[{ label: "India", value: "India" }]}
              isDisabled={true}
              getData={(value: any) => {
                setMainApiData({
                  ...mainApiData,
                  // ...mainApiData.customerOverView,
                  country: value.value,
                })
                setIsFormDirty(true)
                dispatch(resetFormModified(true));
                setIconStatus({
                  ...iconStatus,
                  addressIcon: "selected"
                })
              }}
            />
          </div>
          <div key={uuidv4()} style={{ marginRight: "45px", marginBottom: "30px" }} className={companyOverviewErrors.state ? 'setErrorcompany' : "dropdown-width"}>
            <DropdownComponent
              width={"300px"}
              required={true}
              title={"State"}
              isDisabled={isEditMode ? true : false}

              defaultValue={[{ label: (mainApiData?.state === undefined || mainApiData?.state === "") ? "Select" : (mainApiData.state), value: (mainApiData && mainApiData.state) }]}
              options={dropDownState}
              getData={(value: any) => {
                setIsFormDirty(true)
                dispatch(resetFormModified(true));
                setMainApiData({
                  ...mainApiData,
                  // ...mainApiData?.customerOverView,
                  state: value.value,
                  stateId: value.id,
                  city: undefined,
                  // city:""

                })
                if (value.id == "") {
                  setDropDownCities([]);
                  setDropDownRocCities([]);
                } else {
                  setDropDownCities([]);
                  setDropDownRocCities([]);
                  fetchData(`state/cities/${value.id}`, setDropDownCities, 'city', "cityId", "name");
                  fetchData(`/state/roc-cities/${value.id}`, setDropDownRocCities, 'RocCity', "rocCityId", "name");
                }
                setIconStatus({
                  ...iconStatus,
                  addressIcon: "selected",

                })
              }}
            />
          </div>
          <div key={uuidv4()} className={companyOverviewErrors.city ? 'setErrorcompany' : "dropdown-width"} style={{ marginBottom: "30px" }}>
            <DropdownComponent
              width={"300px"}
              title={" City"}
              required={true}
              options={dropDownRocCities}
              isDisabled={isEditMode ? true : false}

              // mainApiData?.companyData?.city === undefined ? "Select" : mainApiData?.companyData?.CompanySubCategory
              defaultValue={[{ label: (mainApiData?.city === undefined || mainApiData?.city === "") ? "Select" : (mainApiData?.city), value: mainApiData?.city === undefined ? "" : (mainApiData?.city) }]}
              getData={(value: any) => {
                setIsFormDirty(true)
                dispatch(resetFormModified(true));
                setMainApiData({
                  ...mainApiData,
                  city: value.value,
                  cityId: value.id,

                })
                setIconStatus({
                  ...iconStatus,
                  addressIcon: "selected"
                })
              }}
            />
          </div>
        </div>

        <div className='form-main-wrapper' style={isEditMode ?
          { pointerEvents: "none", opacity: 0.5 } : { pointerEvents: "auto", opacity: 1 }}>
          <div>
            <InputComponent
              height={"40px"}
              width={"300px"}
              margin={"0px 45px 30px 0px"}
              padding={"0px 0px 0px 10px"}
              border={companyOverviewErrors.
                pinCode ? "1px solid red" : "1px solid #A9C3DC"}
              borderRadius={"0px"}
              disabled={isEditMode ? true : false}

              backgroundColor={"white"}
              color={"black"}
              type={"text"}
              maxLength={6}
              placeHolder={"Enter Pin Code"}
              inputValue={mainApiData && mainApiData?.
                pinCode}
              inputTitle={" Pin Code"}
              required={true}
              getUser={(value: any) => {
                const val = value.replace(/[^0-9]/g, "")
                setMainApiData({
                  ...mainApiData,
                  // ...mainApiData.customerOverView,
                  pinCode: val
                })
                setIconStatus({
                  ...iconStatus,
                  addressIcon: "selected"
                })
                setIsFormDirty(true)
                dispatch(resetFormModified(true));
              }}
            />
          </div>
          <div className='mail-icon-vendor-input'>
            <InputComponent
              height={"40px"}
              width={"300px"}
              margin={"0px 45px 30px 0px"}
              padding={"0px 0px 0px 10px"}
              border={companyOverviewErrors.website ? "1px solid red" : "1px solid #A9C3DC"}
              borderRadius={"0px"}
              backgroundColor={"white"}
              color={"black"}
              type={"text"}
              maxLength={100}
              placeHolder={"Enter Website"}
              disabled={isEditMode ? true : false}
              inputTitle={" Website"}
              inputValue={mainApiData && (mainApiData?.website !== "null" ? mainApiData?.website : "")}
              getUser={(value: any) => {
                const val = value.replace(/^[^A-Za-z0-9]+|[^\w\s\W]/g, "");
                setIsFormDirty(true)
                dispatch(resetFormModified(true));
                setMainApiData({
                  ...mainApiData,
                  // ...mainApiData.customerOverView,
                  website: val,

                })
                setIconStatus({
                  ...iconStatus,
                  addressIcon: "selected"
                })
              }}
            />
          </div>
        </div>
        <div style={isEditMode ?
          { pointerEvents: "none", opacity: 0.5 } : { pointerEvents: "auto", opacity: 1 }}>
          <div className='title'>Address<span className='required'>*</span></div>
          <textarea className='address-textarea' placeholder='Enter Address'
            style={{ border: (companyOverviewErrors.address) ? "1px solid red" : "" }}
            value={mainApiData && (mainApiData?.address !== "" ? mainApiData?.address : "")}
            disabled={isEditMode ? true : false}
            maxLength={500} onChange={(e) => {
              const val = e.target.value.replace(/^\s+/, "");
              setIsFormDirty(true)
              dispatch(resetFormModified(true));
              setMainApiData({
                ...mainApiData,
                // ...mainApiData.customerOverView,
                address: val,

              })
              setIconStatus({
                ...iconStatus,
                addressIcon: "selected"
              })
            }} />
        </div>


        <div>
          <div className='footer-company'>
            <ButtonComponent
              title={"Cancel"}
              height={"50px"}
              width={"150px"}
              margin={"0px 8px"}
              backgroundColor={"#888888"}
              color={"white"}
              className={"button-component-hover cancel"}
              handleClick={isFormModified ? () => { setShowChangesModal(true) } : returnGird}
            />

            <div>

              <ButtonComponent
                title={"Next"}
                height={"50px"}
                width={"150px"}
                backgroundColor={"#0055D4"}
                color={"white"}
                disabled={false}
                margin={"0px 8px"}
                className={"button-component-hover common-btn"}
                handleClick={next}
              />
            </div>
          </div>
        </div>

      </div>
      {
        showAlertBox &&
        <div className='alert-warp'>
          <AlertBox type={showType} message={showMessage} />
        </div>
      }
      {
        showChangesModal &&
        <Changesmodal
          closeModal={() => setShowChangesModal(false)}
          handleClose={() => {
            setShowChangesModal(false);
          }}
          leavePage={() => returnGird()} />
      }
    </div >

  )
})
export default Address