// utilizationCard
export const utilizationCardOptions = {
    indexAxis: 'y' as const,
    maintainAspectRatio: false,
    type: 'bar',
    responsive: true,
    layout: {},
    scales: {
        x: {
            title: {
                display: true,
                text: 'Value in Crores',
                font: {
                    weight: 'bold',
                },
            },
            grid: {
                display: false,
                stacked: true,
                barPercentage: 1.5,
                categoryPercentage: 0.8,
                barThickness: 10,
            },

        },
        y: {
            title: {
                display: true,
                beginAtZero: true,
                font: {
                    weight: 'bold',
                },
                text: 'No of Companies',
            },
            grid: {
                display: true,
                stacked: true,
                color: '#B3CAE1',
                barPercentage: 1.5,
                categoryPercentage: 0.8,
                barThickness: 10,
            },
            reversed: true,
        },
    },
    plugins: {
        tooltip: {
            enabled: false
        },
        legend: {
            display: false,
            position: 'bottom' as const,
        },
        title: {
            display: true,
            font: {
                weight: 'bold',
                size: 25,
            },
            padding: 10,
            text: 'Utilization - Card',
        },
    },
};

export const utilizationCardData = {
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    datasets: [{
        barPercentage: 1.1,
        barThickness: 20,
        data: [45, 66, 22, 80, 89, 45, 45, 65, 70, 68, 43, 24],
        // data1: [45, 66, 22, 80, 89, 45, 45, 65, 70, 68, 43, 24],
        backgroundColor: ["#0C7B00", "#02548F", "#C2498F", "#009A9A", "#0C7B00", "#02548F", "#C2498F", "#009A9A", "#0C7B00", "#02548F", "#C2498F", "#009A9A",],
        borderColor: ["#0C7B00", "#02548F", "#C2498F", "#009A9A", "#0C7B00", "#02548F", "#C2498F", "#009A9A", "#0C7B00", "#02548F", "#C2498F", "#009A9A",],
        borderWidth: 5
    }]
};

// Bill Generation Date
export const billGenerationDateOptions = {
    indexAxis: 'y' as const,
    maintainAspectRatio: false,
    type: "line",
    responsive: true,
    layout: {},
    scales: {
        x: {
            title: {
                display: true,
                text: 'Due in(Days)',
                font: {
                    weight: 'bold',
                },
            },
            grid: {
                display: false,
                stacked: true,
                barPercentage: 1.5,
                categoryPercentage: 0.8,
                barThickness: 10,
            },
        },
        y: {
            title: {
                display: true,
                beginAtZero: true,
                font: {
                    weight: 'bold',
                },
                text: 'Cards',
            },
            grid: {
                display: true,
                stacked: true,
                color: '#B3CAE1',
                barPercentage: 1.5,
                categoryPercentage: 0.8,
                barThickness: 10,
            },
        },
    },
    plugins: {
        tooltip: {
            enabled: false
        },
        legend: {
            display: false,
            position: 'bottom' as const,
        },
        title: {
            display: false,
            font: {
                weight: 'bold',
                size: 25,
            },
            padding: 10,
            text: 'Bill Generation Date',
        },
    },
};
export const billGenerationDateData = {
    labels: ["****2345", "****2345", "****2345", "****2345", "****2345"],
    datasets: [{
        barPercentage: 1.1,
        barThickness: 30,
        data: [20, 22, 31, 18, 20],
        backgroundColor: ["#0C7B00", "#02548F", "#C2498F", "#009A9A", "#0C7B00"],
        borderColor: ["#0C7B00", "#02548F", "#C2498F", "#009A9A", "#0C7B00"],
        borderWidth: 5
    }]
};

// Pie Chart
export const paymentData = {
    labels: ['Corporate Card', 'Net Banking'],
    datasets: [
        {
            data: [60, 40],
            backgroundColor: ['red', 'blue'],
            hoverBackgroundColor: ['darkred', 'darkblue'],
        },
    ],
};

export const paymentOptions = {
    // Customize chart options here, such as title, legend, etc.
    // For example:
    title: {
        exportEnabled: true,
        animationEnabled: true,
        display: true,
        text: 'My Pie Chart',
    },
    legend: {
        display: true, // Set to true to enable legends
        position: 'top', // You can specify the position: 'top', 'bottom', 'left', 'right', etc.
        align: 'center', // Alignment of the legend. You can use 'start', 'center', or 'end'.
        labels: {
            fontColor: 'black', // Customize legend label font color
        },
    },
    // animation: {
    //     animateScale: true, // Enable scale animation (line animation)
    //     animateRotate: true, // Enable rotation animation (line animation)
    //     duration: 1000, // Animation duration in milliseconds
    //     easing: 'easeInOutQuart', // Easing function (linear, easeInQuad, easeOutQuad, easeInOutQuad, etc.)
    // },
    // More options can be added as needed.
};

//utilization Segments
export const utilizationSegmentsOptions = {
    maintainAspectRatio: false,
    type: 'line',
    responsive: true,
    layout: {},
    scales: {
        x: {
            title: {
                display: false,
                text: 'Organization',
                font: {
                    weight: 'bold',
                },
            },
            grid: {
                display: false,
                stacked: true,
                barPercentage: 1.5,
                categoryPercentage: 0.8,
                barThickness: 10,
            },
        },
        y: {
            title: {
                display: true,
                beginAtZero: true,
                font: {
                    weight: 'bold',
                },
                text: 'Transaction Value(%)',
            },
            grid: {
                display: true,
                stacked: true,
                color: '#B3CAE1',
                barPercentage: 1.5,
                categoryPercentage: 0.8,
                barThickness: 10,
            },
        },
    },
    plugins: {
        tooltip: {
            enabled: false
        },
        legend: {
            display: false,
            position: 'bottom' as const,
        },
        title: {
            display: false,
            font: {
                weight: 'bold',
                size: 25,
            },
            padding: 10,
            text: 'Utilization - Segments',
        },
    },
};

export const utilizationSegmentsData = {
    labels: ["Vendor Payment"],
    datasets: [{
        barPercentage: 1.1,
        barThickness: 40,
        data: [100],
        // data1: [45, 66, 22, 80, 89, 45, 45, 65, 70, 68, 43, 24],
        backgroundColor: ["#0C7B00", "#02548F", "#C2498F"],
        borderColor: ["#0C7B00", "#02548F", "#C2498F"],
        borderWidth: 5
    }]
};
export const utilizationSegmentsEmptyData = {
    datasets: [{
        barPercentage: 1.1,
        barThickness: 40,
        data: [100],
        // data1: [45, 66, 22, 80, 89, 45, 45, 65, 70, 68, 43, 24],
        backgroundColor: ["#0C7B00", "#02548F", "#C2498F"],
        borderColor: ["#0C7B00", "#02548F", "#C2498F"],
        borderWidth: 5
    }]
};

// Utilization – Vendor
export const utilizationVendorOptions = {
    maintainAspectRatio: false,
    type: 'line',
    responsive: true,
    layout: {},
    scales: {
        x: {
            title: {
                display: false,
                text: 'Organization',
                font: {
                    weight: 'bold',
                },
            },
            grid: {
                display: false,
                stacked: true,
                barPercentage: 1.5,
                categoryPercentage: 0.8,
                barThickness: 10,
            },
        },
        y: {
            title: {
                display: true,
                beginAtZero: true,
                font: {
                    weight: 'bold',
                },
                text: 'Transaction Value(%)',
            },
            grid: {
                display: true,
                stacked: true,
                color: '#B3CAE1',
                barPercentage: 1.5,
                categoryPercentage: 0.8,
                barThickness: 10,
            },
        },
    },
    plugins: {
        tooltip: {
            enabled: false
        },
        legend: {
            display: false,
            position: 'bottom' as const,
        },
        title: {
            display: false,
            font: {
                weight: 'bold',
                size: 25,
            },
            padding: 10,
            text: 'Utilization – Vendor',
        },
    },
};

export const utilizationVendorData = {
    labels: ["Statutory", "Utility", "Vendor Payment"],
    datasets: [{
        barPercentage: 1.1,
        barThickness: 40,
        data: [45, 66, 22],
        // data1: [45, 66, 22, 80, 89, 45, 45, 65, 70, 68, 43, 24],
        backgroundColor: ["#0C7B00", "#02548F", "#C2498F"],
        borderColor: ["#0C7B00", "#02548F", "#C2498F"],
        borderWidth: 5
    }]
};